import React from 'react';

const CardAndReceipt = ({ width = '62', height = '64', vbWidth = '62', vbHeight = '64', ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      {...other}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1689 46.3141L45.989 46.3917C46.6638 46.394 47.0456 46.0454 47.0572 45.4164L47.6717 12.8194C47.6739 12.7087 47.6243 12.5647 47.5294 12.4061C47.4344 12.2475 47.2985 12.0816 47.1394 11.9301C46.9802 11.7786 46.8052 11.6484 46.637 11.5565C46.4689 11.4647 46.3155 11.4154 46.1967 11.415L26.2896 11.3386C25.6131 11.3363 25.2311 11.6865 25.2211 12.3182L24.6952 44.9124C24.6938 45.0233 24.7439 45.1672 24.839 45.3254C24.9341 45.4837 25.0698 45.649 25.2285 45.8C25.3873 45.951 25.5618 46.0807 25.7294 46.1724C25.8971 46.264 26.0502 46.3134 26.1689 46.3141Z"
        fill="#D0E7F4"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M28.7643 16.8647C28.5561 17.0468 28.5349 17.3631 28.7169 17.5713C28.899 17.7795 29.2153 17.8007 29.4235 17.6187C29.6317 17.4366 29.6529 17.1203 29.4709 16.9121C29.2888 16.7039 28.9725 16.6827 28.7643 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M31.7026 16.8647C31.4944 17.0468 31.4732 17.3631 31.6553 17.5713C31.8373 17.7795 32.1536 17.8007 32.3618 17.6187C32.57 17.4366 32.5912 17.1203 32.4092 16.9121C32.2272 16.7039 31.9108 16.6827 31.7026 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M30.2335 16.8647C30.0253 17.0468 30.0041 17.3631 30.1861 17.5713C30.3682 17.7795 30.6845 17.8007 30.8927 17.6187C31.1009 17.4366 31.1221 17.1203 30.9401 16.9121C30.758 16.7039 30.4417 16.6827 30.2335 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M33.1718 16.8647C32.9636 17.0468 32.9424 17.3631 33.1244 17.5713C33.3065 17.7795 33.6228 17.8007 33.831 17.6187C34.0392 17.4366 34.0604 17.1203 33.8784 16.9121C33.6963 16.7039 33.38 16.6827 33.1718 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M36.1101 16.8647C35.9019 17.0468 35.8807 17.3631 36.0628 17.5713C36.2448 17.7795 36.5611 17.8007 36.7693 17.6187C36.9775 17.4366 36.9987 17.1203 36.8167 16.9121C36.6347 16.7039 36.3183 16.6827 36.1101 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M34.6409 16.8647C34.4327 17.0468 34.4115 17.3631 34.5936 17.5713C34.7756 17.7795 35.0919 17.8007 35.3001 17.6187C35.5083 17.4366 35.5295 17.1203 35.3475 16.9121C35.1654 16.7039 34.8491 16.6827 34.6409 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M37.5792 16.8647C37.371 17.0468 37.3498 17.3631 37.5319 17.5713C37.7139 17.7795 38.0303 17.8007 38.2385 17.6187C38.4466 17.4366 38.4678 17.1203 38.2858 16.9121C38.1038 16.7039 37.7874 16.6827 37.5792 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M40.5175 16.8647C40.3093 17.0468 40.2881 17.3631 40.4702 17.5713C40.6522 17.7795 40.9686 17.8007 41.1767 17.6187C41.3849 17.4366 41.4061 17.1203 41.2241 16.9121C41.0421 16.7039 40.7257 16.6827 40.5175 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M39.0484 16.8647C38.8402 17.0468 38.819 17.3631 39.0011 17.5713C39.1831 17.7795 39.4994 17.8007 39.7076 17.6187C39.9158 17.4366 39.937 17.1203 39.755 16.9121C39.5729 16.7039 39.2566 16.6827 39.0484 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M41.9867 16.8647C41.7785 17.0468 41.7573 17.3631 41.9394 17.5713C42.1214 17.7795 42.4377 17.8007 42.6459 17.6187C42.8541 17.4366 42.8753 17.1203 42.6933 16.9121C42.5112 16.7039 42.1949 16.6827 41.9867 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M44.925 16.8647C44.7168 17.0468 44.6956 17.3631 44.8777 17.5713C45.0597 17.7795 45.3761 17.8007 45.5842 17.6187C45.7924 17.4366 45.8136 17.1203 45.6316 16.9121C45.4496 16.7039 45.1332 16.6827 44.925 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M43.4559 16.8647C43.2477 17.0468 43.2265 17.3631 43.4085 17.5713C43.5905 17.7795 43.9069 17.8007 44.1151 17.6187C44.3233 17.4366 44.3445 17.1203 44.1624 16.9121C43.9804 16.7039 43.664 16.6827 43.4559 16.8647Z"
        fill="#EEA530"
      />
      <path
        d="M37.0111 57.8222C37.0111 59.2695 32.6448 60.4422 27.2591 60.4422C21.8735 60.4422 17.5072 59.2695 17.5072 57.8222V56.615C18.7412 55.5483 22.8826 55.2021 27.2591 55.2021C31.6357 55.2021 35.7669 55.5522 37.0111 56.615V57.8222Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M27.2591 59.2342C32.645 59.2342 37.0111 58.0612 37.0111 56.6142C37.0111 55.1672 32.645 53.9941 27.2591 53.9941C21.8733 53.9941 17.5072 55.1672 17.5072 56.6142C17.5072 58.0612 21.8733 59.2342 27.2591 59.2342Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M27.2592 58.741C31.7618 58.741 35.4118 57.7601 35.4118 56.5502C35.4118 55.3402 31.7618 54.3594 27.2592 54.3594C22.7566 54.3594 19.1066 55.3402 19.1066 56.5502C19.1066 57.7601 22.7566 58.741 27.2592 58.741Z"
        fill="#8F5CCF"
      />
      <path
        d="M35.4118 56.5564C35.4118 57.7661 31.7622 58.7472 27.2592 58.7472C22.7562 58.7472 19.1053 57.7661 19.1053 56.5564C19.1053 55.9036 22.8865 54.8203 27.3895 54.8203C31.8925 54.8203 35.4118 55.901 35.4118 56.5564Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M24.2853 58.2717H22.6336V57.9243L23.6389 55.0117H24.9445C24.9445 55.3643 25.4146 55.5597 26.8108 55.5597H27.171L27.2094 55.0117H28.8764L28.9416 55.5559H29.6876C31.5207 55.5559 32.6845 55.7258 33.0434 56.3313L33.092 56.9956C33.092 57.8438 32.1901 58.3497 30.2816 58.3497L30.0018 57.49V57.1425C30.6955 57.1029 31.3828 57.2702 31.3828 56.9956C31.3828 56.6353 30.813 56.4527 29.6876 56.4527H28.962L29.0157 56.85V57.1974H27.0752V56.85L27.1238 56.4463L26.8389 56.4527C25.7991 56.4527 25.306 56.3492 24.9445 56.0694H24.9164L24.2853 58.2717Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M24.2853 57.9251H22.6336L23.6389 54.665H24.9445C24.9445 55.0189 25.4146 55.2143 26.8108 55.2143H27.171L27.2094 54.665H28.8764L28.9416 55.2092H29.6876C31.987 55.2092 33.092 55.7202 33.092 56.6476C33.092 57.4958 32.1901 58.0017 30.2816 58.0017L30.0018 57.142C30.8961 57.142 31.3828 56.9593 31.3828 56.6476C31.3828 56.2887 30.813 56.1047 29.6876 56.1047H28.962L29.0157 56.8495H27.0752L27.1238 56.0983L26.8389 56.1047C25.7991 56.1047 25.306 56.0012 24.9445 55.7215H24.9164L24.2853 57.9251Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M37.0111 50.8534C37.0111 52.3008 32.6448 53.4747 27.2591 53.4747C21.8735 53.4747 17.5072 52.3008 17.5072 50.8534V49.6475C18.7412 48.5809 22.8826 48.2334 27.2591 48.2334C31.6357 48.2334 35.7669 48.5847 37.0111 49.6475V50.8534Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M27.2591 52.2684C32.645 52.2684 37.0111 51.0954 37.0111 49.6484C37.0111 48.2013 32.645 47.0283 27.2591 47.0283C21.8733 47.0283 17.5072 48.2013 17.5072 49.6484C17.5072 51.0954 21.8733 52.2684 27.2591 52.2684Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M27.2592 51.7722C31.7618 51.7722 35.4118 50.7914 35.4118 49.5814C35.4118 48.3715 31.7618 47.3906 27.2592 47.3906C22.7566 47.3906 19.1066 48.3715 19.1066 49.5814C19.1066 50.7914 22.7566 51.7722 27.2592 51.7722Z"
        fill="#8F5CCF"
      />
      <path
        d="M35.4118 49.5817C35.4118 50.7928 31.7622 51.7726 27.2592 51.7726C22.7562 51.7726 19.1053 50.7928 19.1053 49.5817C19.1053 48.929 22.8865 47.8457 27.3895 47.8457C31.8925 47.8457 35.4118 48.9328 35.4118 49.5817Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M28.6286 48.1664V47.7832C29.984 47.8713 31.6727 49.2075 31.6727 49.2075V49.6891C31.6727 50.6012 30.5141 51.1697 28.382 51.314L28.4587 51.6372L27.5645 51.6117L27.53 51.3562H26.9028L26.8555 51.6449L26.089 51.5746L26.1184 51.3C23.9225 51.1569 22.6476 50.6076 22.6476 49.6713V49.288C22.9146 48.7298 24.4284 47.9058 25.5921 47.7832V48.1664L25.4963 49.0121C24.6787 49.0721 24.1678 49.2995 24.1678 49.6713C24.1678 50.0966 24.9994 50.3905 26.2372 50.4863L26.4569 49.1909L27.0395 48.8575V49.2408L26.9577 50.5054H27.5198L27.4687 49.2816V48.8984L28.0895 49.2446L28.3054 50.4684C29.4806 50.3726 30.1513 50.0966 30.1513 49.6891C30.1513 49.3174 29.6275 49.0721 28.7844 49.0121L28.6286 48.1664Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M28.6286 47.7832C30.417 47.8547 31.6727 48.4309 31.6727 49.3059C31.6727 50.218 30.5141 50.7865 28.382 50.9308L28.4587 51.254L27.5645 51.2285L27.53 50.973H26.9028L26.8555 51.2617L26.089 51.1914L26.1184 50.9168C23.9225 50.7737 22.6476 50.2244 22.6476 49.288C22.6476 48.3938 23.8292 47.8369 25.5921 47.7832L25.4963 48.6289C24.6787 48.6889 24.1678 48.9163 24.1678 49.288C24.1678 49.7134 24.9994 50.0072 26.2372 50.103L26.4569 48.8077L27.0395 48.8575L26.9577 50.1222H27.5198L27.4687 48.8984L28.0895 48.8614L28.3054 50.0851C29.4806 49.9893 30.1513 49.7134 30.1513 49.3059C30.1513 48.9342 29.6275 48.6889 28.7844 48.6289L28.6286 47.7832Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M35.0158 56.3352C35.0158 57.8605 30.6814 59.097 25.3354 59.097C25.0339 59.097 24.7358 59.0932 24.4412 59.0855C19.5128 58.9578 17.5979 58.1198 17.5136 56.6827C17.4114 54.9249 22.419 54.2632 24.7465 54.0831C27.5019 53.8698 30.3506 53.8736 32.0956 54.359C33.8929 54.8611 35.0158 55.5611 35.0158 56.3352Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M5.96681 43.9175L21.6614 50.7262C22.1961 50.958 22.7988 50.978 23.3477 50.7825L51.8026 40.6511C51.9023 40.6168 51.9887 40.5522 52.0496 40.4663C52.1106 40.3803 52.1431 40.2774 52.1424 40.172C52.1424 39.9817 52.1424 39.4464 52.1424 39.4464L36.0697 32.8625C35.5338 32.6302 34.9295 32.6106 34.3796 32.8076L5.6615 42.7346C5.6615 42.7346 5.66917 43.2302 5.6615 43.4206C5.6545 43.5248 5.68013 43.6287 5.73484 43.7178C5.78955 43.8068 5.87063 43.8766 5.96681 43.9175V43.9175Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M23.5367 52.0267L34.8446 47.9976C32.2974 46.8824 26.9066 47.5275 26.3279 47.4471C19.1053 46.4558 12.1075 50.854 23.5367 52.0267Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M5.96683 43.1509L21.6615 49.9596C22.1961 50.1914 22.7988 50.2114 23.3477 50.0159L51.8026 39.8845C51.8993 39.8501 51.9834 39.7875 52.044 39.7046C52.1046 39.6218 52.1388 39.5227 52.1423 39.4201C52.1457 39.3176 52.1182 39.2164 52.0633 39.1297C52.0084 39.043 51.9287 38.9748 51.8346 38.9341L36.0697 32.0959C35.5338 31.8636 34.9295 31.844 34.3796 32.041L5.99748 42.2018C5.90125 42.2365 5.81765 42.2993 5.75751 42.382C5.69737 42.4648 5.66345 42.5637 5.66015 42.6659C5.65685 42.7682 5.68431 42.8691 5.73899 42.9555C5.79366 43.042 5.87303 43.11 5.96683 43.1509Z"
        fill="#D0E7DA"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M30.4975 47.4501C29.9405 45.9172 31.1132 42.6712 27.9234 36.7746C26.8325 34.7575 24.4156 35.4971 25.6943 38.6154C29.0744 46.8549 34.3732 46.7616 33.7626 41.1166C33.5378 39.0369 31.4057 33.0879 31.4057 33.0879"
        stroke="#FFE8C3"
        strokeWidth="8"
        strokeMiterlimit="10"
      />
      <path
        d="M17.3756 45.7363L17.3973 46.2409L17.824 46.5079L17.3526 46.6842L17.23 47.1734L16.9157 46.7787L16.4137 46.8132L16.6922 46.3929L16.5031 45.9254L16.9898 46.0608L17.3756 45.7363Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M18.8242 45.209L18.8459 45.7123L19.2726 45.9793L18.7999 46.1556L18.6773 46.6448L18.3643 46.2501L17.8623 46.2846L18.1408 45.8643L17.9517 45.3968L18.4372 45.5322L18.8242 45.209Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M20.2729 44.6816L20.2946 45.185L20.7213 45.4532L20.2486 45.6295L20.126 46.1175L19.813 45.7228L19.3097 45.7572L19.5882 45.3382L19.4004 44.8707L19.8858 45.0048L20.2729 44.6816Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M21.7215 44.1562L21.7432 44.6596L22.1698 44.9265L21.6972 45.1028L21.5746 45.5921L21.2616 45.1974L20.7583 45.2319L21.0368 44.8116L20.849 44.344L21.3344 44.4794L21.7215 44.1562Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M24.6175 43.1016L24.6392 43.6049L25.0671 43.8731L24.5945 44.0494L24.4718 44.5387L24.1589 44.1427L23.6555 44.1784L23.934 43.7582L23.7462 43.2906L24.2317 43.4248L24.6175 43.1016Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M26.066 42.5742L26.0878 43.0775L26.5144 43.3445L26.043 43.5208L25.9204 44.0101L25.6062 43.6153L25.1041 43.6498L25.3826 43.2295L25.1948 42.762L25.6803 42.8974L26.066 42.5742Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M27.5146 42.0469L27.5364 42.5502L27.963 42.8184L27.4917 42.9947L27.369 43.4827L27.0548 43.088L26.5527 43.1225L26.8312 42.7022L26.6422 42.2347L27.1276 42.3701L27.5146 42.0469Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M28.9633 41.5186L28.985 42.0231L29.4117 42.2901L28.9403 42.4664L28.8164 42.9557L28.5034 42.5609L28.0014 42.5954L28.2799 42.1752L28.0908 41.7076L28.5762 41.843L28.9633 41.5186Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M31.8605 40.4668L31.8822 40.9701L32.3089 41.2384L31.8363 41.4147L31.7136 41.9026L31.4007 41.5079L30.8973 41.5424L31.1758 41.1234L30.988 40.6559L31.4735 40.79L31.8605 40.4668Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M33.3079 39.9395L33.3296 40.4428L33.7575 40.7097L33.2849 40.886L33.1622 41.3753L32.8493 40.9806L32.3459 41.0151L32.6244 40.5948L32.4366 40.1272L32.9221 40.2626L33.3079 39.9395Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M34.7565 39.4121L34.7782 39.9154L35.2061 40.1837L34.7335 40.3587L34.6108 40.848L34.2979 40.4532L33.7946 40.4877L34.073 40.0674L33.8853 39.5999L34.3707 39.7353L34.7565 39.4121Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M36.2051 38.8838L36.2268 39.3871L36.6535 39.6554L36.1821 39.8316L36.0595 40.3196L35.7452 39.9249L35.2432 39.9594L35.5217 39.5404L35.3339 39.0728L35.8193 39.207L36.2051 38.8838Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M39.1023 37.8291L39.1241 38.3324L39.5507 38.6007L39.0794 38.777L38.9567 39.2649L38.6425 38.8702L38.1392 38.9047L38.4176 38.4844L38.2299 38.0169L38.7153 38.1523L39.1023 37.8291Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M40.5509 37.3037L40.5726 37.807L40.9993 38.0753L40.5267 38.2516L40.404 38.7408L40.091 38.3461L39.5877 38.3806L39.8662 37.9603L39.6784 37.4928L40.1639 37.6282L40.5509 37.3037Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M41.9996 36.7764L42.02 37.2797L42.4479 37.5467L41.9753 37.723L41.8527 38.2122L41.5397 37.8175L41.0364 37.852L41.3149 37.4317L41.1271 36.9642L41.6125 37.0996L41.9996 36.7764Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M43.4469 36.249L43.4686 36.7523L43.8966 37.0206L43.4239 37.1969L43.3013 37.6849L42.9883 37.2901L42.485 37.3246L42.7635 36.9056L42.5757 36.4381L43.0611 36.5722L43.4469 36.249Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M9.41847 42.7177L13.4117 44.4499C13.5525 44.5104 13.7032 44.5441 13.8563 44.5496V44.5496C14.0106 44.5546 14.1645 44.5303 14.3098 44.478L18.6927 42.9106C18.729 42.8976 18.7607 42.8739 18.7834 42.8427C18.8062 42.8115 18.8191 42.7742 18.8204 42.7356V42.7356C18.8216 42.6968 18.8112 42.6586 18.7905 42.6258C18.7698 42.593 18.7397 42.5672 18.7042 42.5516L14.7045 40.8194C14.5582 40.7559 14.4015 40.7195 14.2421 40.7121V40.7121C14.0935 40.7053 13.945 40.7279 13.8052 40.7786L9.42996 42.3588C9.39359 42.3718 9.36197 42.3954 9.3392 42.4266C9.31642 42.4578 9.30354 42.4952 9.30222 42.5338V42.5338C9.30101 42.5725 9.31143 42.6108 9.33215 42.6436C9.35287 42.6763 9.38293 42.7022 9.41847 42.7177V42.7177Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M41.951 40.2437L42.5744 40.4915C42.7112 40.5463 42.8564 40.577 43.0036 40.5822V40.5822C43.1635 40.5882 43.3231 40.5635 43.4737 40.5094L47.8528 38.9433C47.8889 38.9307 47.9205 38.9075 47.9433 38.8768C47.9661 38.846 47.979 38.8091 47.9805 38.7708C47.9816 38.7319 47.9708 38.6936 47.9496 38.6609C47.9284 38.6283 47.8977 38.6029 47.8617 38.5881L47.2345 38.3326C47.0923 38.2759 46.9416 38.2436 46.7887 38.2368V38.2368C46.6332 38.229 46.4776 38.2524 46.3314 38.3058L41.9587 39.8835C41.9225 39.896 41.891 39.9192 41.8682 39.95C41.8454 39.9807 41.8324 40.0177 41.8309 40.0559V40.0559C41.8287 40.0959 41.8391 40.1355 41.8606 40.1692C41.8822 40.2029 41.9138 40.229 41.951 40.2437V40.2437Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M47.5564 37.079C47.3197 37.2076 47.0799 37.3354 46.8372 37.4623C42.057 39.9456 38.24 40.3352 36.9741 39.6339C36.7926 39.5229 36.6449 39.3643 36.547 39.1754C36.4491 38.9865 36.4046 38.7745 36.4184 38.5621C36.6228 37.2758 37.8964 36.2474 41.3608 34.3926L47.5564 37.079Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M16.0156 62.4621C15.0847 63.5705 10.987 61.6594 6.86348 58.1947C2.74 54.73 0.15238 51.0231 1.08206 49.9149L1.85844 48.9906C3.49042 48.9687 6.88351 51.368 10.2342 54.1823C13.5849 56.9965 16.5215 59.9219 16.7908 61.5392L16.0156 62.4621Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M16.033 62.7307C15.0973 63.8444 11.0058 61.9317 6.88076 58.462C2.75568 54.9923 0.169663 51.2904 1.09934 50.1821L1.87581 49.2591C3.50771 49.236 6.90079 51.6353 10.2516 54.4508C13.6023 57.2663 16.5389 60.1904 16.8081 61.8065L16.033 62.7307Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M35.7158 31.9819C36.762 32.9822 34.8101 35.8589 31.5603 39.2569C28.3105 42.6549 24.8269 44.5992 23.7807 43.6002L22.9082 42.761C22.8827 41.2446 25.131 38.3921 27.7727 35.6303C29.1779 34.1599 30.6891 33.3999 32.0087 32.8825C33.1584 32.4303 34.1676 32.1505 34.865 31.9219L35.7158 31.9819Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M7.63733 57.2712C11.7603 60.7365 15.8574 62.6477 16.7885 61.54C17.7195 60.4323 15.1318 56.7251 11.0088 53.2598C6.88579 49.7945 2.78869 47.8833 1.85768 48.9911C0.926674 50.0988 3.51431 53.8059 7.63733 57.2712Z"
        fill="#D0E7F4"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M7.9556 56.8917C11.4024 59.7887 14.8277 61.3862 15.6062 60.46C16.3847 59.5337 14.2216 56.4344 10.7748 53.5374C7.32793 50.6405 3.90263 49.0429 3.12414 49.9691C2.34565 50.8954 4.50877 53.9947 7.9556 56.8917Z"
        fill="#8F5CCF"
      />
      <path
        d="M15.6105 60.4604C14.8316 61.3862 11.4057 59.789 7.95629 56.8928C4.50689 53.9966 2.34489 50.8959 3.12374 49.9702C3.54338 49.4695 7.1361 51.0726 10.583 53.9702C14.03 56.8679 16.0277 59.9625 15.6105 60.4604Z"
        fill="#D0E7F4"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M5.44946 52.7492L4.5887 52.0251L4.83477 51.7315L5.05157 51.7585L5.76187 52.3528C6.40107 51.8226 8.08832 52.0827 9.4214 53.1246L9.17533 53.4182L8.50143 53.9263C7.73142 53.2795 7.20105 53.1139 6.85663 53.2718L8.85277 54.9495C9.54345 54.722 10.3928 54.5137 11.73 55.6385C12.8652 56.5895 13.3013 57.6367 12.8951 58.3464L13.4973 58.8517L13.5782 59.0756L13.3322 59.3692L12.5621 58.7212C11.9028 59.218 11.051 58.9913 9.68293 57.8428L10.0299 57.1676L10.276 56.8753C10.8294 57.2979 11.2023 57.9011 11.4781 57.8104L9.63383 56.265C8.92053 56.5388 8.10336 56.7898 6.61474 55.539C5.459 54.5676 5.03978 53.504 5.44946 52.7492ZM6.52394 53.6516C6.37561 54.0119 6.59457 54.4305 7.08173 54.8395C7.53649 55.2224 7.87616 55.2492 8.29702 55.1453L6.52394 53.6516ZM10.1901 56.0769L11.8303 57.4562C11.9394 57.1612 11.7766 56.7799 11.3205 56.3971C10.8645 56.0143 10.5545 55.9715 10.1901 56.0769V56.0769Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M5.6967 52.457L4.83467 51.7329L5.29754 51.4663L6.00912 52.0606C6.69207 51.4943 7.97322 51.9085 9.42098 53.121L8.74716 53.6303C7.97707 52.9822 7.44637 52.8116 7.10187 52.9682L9.098 54.6459C9.79004 54.4195 10.6394 54.2113 11.9765 55.3348C13.1104 56.2859 13.5465 57.3331 13.1403 58.0428L13.7426 58.5494L13.5778 59.072L12.8078 58.4252C12.1485 58.9208 11.2967 58.694 9.93495 57.5451L10.2756 56.8717C10.9441 57.4328 11.4129 57.6164 11.725 57.5131L9.88203 55.9676C9.16745 56.2415 8.35156 56.4925 6.86166 55.2417C5.70633 54.2767 5.28703 53.2118 5.6967 52.457ZM6.76992 53.3595C6.62286 53.7197 6.84182 54.1383 7.3277 54.5474C7.78374 54.9302 8.12222 54.9583 8.54299 54.8531L6.76992 53.3595ZM10.4361 55.7848L12.0776 57.164C12.1866 56.8689 12.0238 56.4877 11.5678 56.1049C11.1117 55.7221 10.8018 55.6793 10.4361 55.7848Z"
        fill="#D0E7F4"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M49.0314 43.6387C50.3442 44.2448 49.5775 48.7001 47.3186 53.5892C45.0598 58.4782 42.161 61.9499 40.8472 61.3423L39.7525 60.8382C39.3015 59.2704 40.7235 55.3657 42.5596 51.3931C44.3957 47.4206 46.4482 43.8178 47.9354 43.1344L49.0314 43.6387Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M47.9353 43.1309C46.6218 42.5238 43.7253 45.995 41.4657 50.8839C39.206 55.7729 38.4391 60.2283 39.7526 60.8354C41.0661 61.4424 43.9626 57.9713 46.2222 53.0824C48.4818 48.1934 49.2488 43.738 47.9353 43.1309Z"
        fill="#EED9F7"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M47.2044 44.5564C46.1061 44.0488 43.6844 46.9505 41.7954 51.0377C39.9063 55.1248 39.2653 58.8496 40.3637 59.3572C41.462 59.8649 43.8837 56.9631 45.7727 52.8759C47.6617 48.7888 48.3027 45.064 47.2044 44.5564Z"
        fill="#8F5CCF"
      />
      <path
        d="M47.206 44.5547C48.3045 45.0596 47.6624 48.7863 45.7736 52.8728C43.8848 56.9594 41.4625 59.8627 40.3646 59.3553C39.7711 59.0813 40.3703 55.1933 42.2632 51.1064C44.156 47.0194 46.6162 44.2815 47.206 44.5547Z"
        fill="#EED9F7"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M42.4433 56.386L41.9713 57.4067L41.6235 57.246L41.5926 57.0311L41.9823 56.1871C41.303 55.7096 41.1108 54.0132 41.7625 52.4527L42.1015 52.6115L42.772 53.129C42.3501 54.0417 42.3246 54.6 42.5735 54.888L43.6665 52.5224C43.2668 51.9105 42.8414 51.1491 43.5746 49.5634C44.1968 48.2188 45.0901 47.523 45.882 47.7284L46.2123 47.0165L46.4051 46.8787L46.7529 47.0394L46.331 47.9521C46.9844 48.4579 46.9893 49.3396 46.2391 50.9607L45.4995 50.7969L45.1544 50.642C45.4159 49.9986 45.9012 49.4794 45.7415 49.2373L44.7339 51.4242C45.1835 52.0471 45.6426 52.7624 44.8313 54.5296C44.1896 55.8974 43.2727 56.5815 42.4433 56.386ZM43.0315 55.1124C43.4136 55.1591 43.7645 54.839 44.0309 54.2621C44.2813 53.7223 44.218 53.3877 44.0033 53.0104L43.0315 55.1124ZM44.4084 50.9387L45.3078 48.9921C44.9937 48.9631 44.6694 49.2223 44.4202 49.7624C44.1711 50.3026 44.2046 50.6122 44.4021 50.9373L44.4084 50.9387Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M42.0867 56.2238L41.6147 57.2445L41.2361 56.8689L41.6257 56.0249C40.9 55.5149 40.9625 54.1693 41.7624 52.4532L42.4329 52.9707C42.011 53.8834 41.9855 54.4416 42.2344 54.7297L43.3277 52.3628C42.928 51.751 42.5024 50.9908 43.2355 49.4051C43.8577 48.0605 44.7511 47.3647 45.5429 47.5701L45.8733 46.8582L46.4138 46.8811L45.9919 47.7938C46.6453 48.2996 46.6502 49.1813 45.9001 50.8024L45.1605 50.6386C45.527 49.8458 45.5777 49.3454 45.3998 49.0732L44.3922 51.2601C44.843 51.8832 45.3022 52.5986 44.4896 54.3655C43.8418 55.7372 42.9249 56.4212 42.0867 56.2238ZM42.6749 54.9502C43.0571 54.9969 43.408 54.6768 43.6744 54.0999C43.9248 53.5601 43.8615 53.2255 43.6467 52.8482L42.6749 54.9502ZM44.0518 50.7765L44.9513 48.8299C44.6371 48.8009 44.3128 49.0601 44.0637 49.6003C43.8145 50.1404 43.8568 50.452 44.0543 50.777L44.0518 50.7765Z"
        fill="#EED9F7"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M41.7475 21.0919L28.1204 21.5966C27.9362 21.6053 27.7622 21.6039 27.6185 21.5926C27.4749 21.5812 27.3674 21.5604 27.3086 21.5325L26.4202 20.8536C26.3655 20.812 26.6393 20.7572 26.9549 20.7461L40.582 20.2414C40.7678 20.2323 40.9435 20.2336 41.0876 20.2452C41.2318 20.2568 41.3382 20.2782 41.3939 20.3067L42.2886 20.984C42.3304 21.0266 42.0631 21.0809 41.7475 21.0919Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M32.4051 24.1799L27.1684 24.4589C27.0978 24.4645 27.03 24.4603 26.9729 24.4467C26.9158 24.4332 26.8716 24.4108 26.8455 24.3822L26.4215 23.6949C26.3954 23.6528 26.4954 23.6029 26.6168 23.5971L31.8535 23.3181C31.9246 23.3121 31.9931 23.3162 32.0504 23.3301C32.1078 23.3439 32.1516 23.3668 32.1766 23.396L32.6029 24.0817C32.6241 24.1246 32.5265 24.1741 32.4051 24.1799Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M32.4051 26.7239L27.1684 27.0028C27.0978 27.0085 27.03 27.0043 26.9729 26.9907C26.9158 26.9771 26.8716 26.9547 26.8455 26.9261L26.4215 26.2389C26.3954 26.1967 26.4954 26.1469 26.6168 26.141L31.8535 25.8621C31.9246 25.856 31.9931 25.8602 32.0504 25.874C32.1078 25.8878 32.1516 25.9108 32.1766 25.94L32.6029 26.6257C32.6241 26.6685 32.5265 26.718 32.4051 26.7239Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
      <path
        d="M32.4051 29.2668L27.1684 29.5458C27.0978 29.5514 27.03 29.5472 26.9729 29.5337C26.9158 29.5201 26.8716 29.4977 26.8455 29.4691L26.4215 28.7818C26.3954 28.7397 26.4954 28.6899 26.6168 28.684L31.8535 28.405C31.9246 28.399 31.9931 28.4031 32.0504 28.417C32.1078 28.4308 32.1516 28.4537 32.1766 28.4829L32.6029 29.1687C32.6241 29.2115 32.5265 29.261 32.4051 29.2668Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default CardAndReceipt;
