import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

import { ampTrackEvent } from 'amplitude';
import { registerUtmParameters } from 'utility/utm';

const useSignUp = () => {
  ReactPixel.pageView();

  registerUtmParameters();

  useEffect(() => ampTrackEvent('onboarding: signup: page'), []);
};

export default useSignUp;
