import React from 'react';
import cx from 'classnames';

const CurrentBalancesSkeleton = ({ pageTitle, isPreFunded }: { pageTitle: string; isPreFunded: boolean }) => {
  const balancesLength = 4;

  return (
    <div className="tw-bg-neutral-light tw-rounded lg:tw-rounded-none tw-pt-6 tw-pb-4 tw-px-4">
      <div className="tw-flex tw-flex-nowrap tw-items-center tw-gap-6 tw-mb-4">
        <h2>{pageTitle}</h2>
        {!isPreFunded && (
          <div className="tw-hidden lg:tw-block tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-h-6 tw-w-64" />
        )}
      </div>
      <div className="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-rounded-md lg:tw-rounded-none tw-overflow-hidden tw-gap-0 lg:tw-gap-2 xl:tw-gap-6 tw-w-full tw-animate-pulse tw-mb-3">
        {Array(balancesLength)
          .fill('')
          .map((_, i) => (
            <div
              key={i}
              className={cx(
                'lg:tw-rounded-md tw-bg-neutral-grey-4 tw-w-full',
                isPreFunded ? 'tw-h-4 lg:tw-h-14' : 'tw-h-12 lg:tw-h-36'
              )}
            />
          ))}
      </div>
    </div>
  );
};

export default CurrentBalancesSkeleton;
