import React, { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import Layout from 'components/onboarding/Layout';
import { Loaders } from 'components/cards/Loader';
import CreditReportConsent from 'components/CreditReportConsent';

const CreditReportConsentTerms = () => {
  const { me, meLoading } = useContext(AuthContext);
  const { firstName, lastName, name } = me || {};

  return (
    <Layout>
      {meLoading ? <Loaders.Light /> : <Agreement firstName={firstName} lastName={lastName} name={name} />}
    </Layout>
  );
};

const Agreement = ({ firstName, lastName, name }) => (
  <div className="tw-p-16 tw-space-y-8 tw-flex tw-flex-col tw-justify-center">
    <h1>Credit Report Terms</h1>
    <div className="tw-border tw-border-neutral-grey-3 tw-rounded-md tw-flex tw-flex-col tw-justify-center tw-p-8">
      <CreditReportConsent companyName={name} granteeName={`${firstName} ${lastName}`} />
    </div>
  </div>
);

export default CreditReportConsentTerms;
