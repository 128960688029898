import React, { useContext, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { IoRadioButtonOn, IoRadioButtonOff, IoFilterOutline } from 'react-icons/io5';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { get } from 'lodash';

import { GET_TRANSACTION_CATEGORIES } from 'graphql/transactionCategories';
import { GET_CREDIT_CARDS_FOR_FILTER, GET_WALLETS_FOR_FILTER } from 'graphql/transactionsFilter';
import { AuthContext } from 'context/Auth';
import { TransactionsContext } from 'context/Transactions';
import { FilterContext } from 'components/Transactions/context/FilterContext';
import { CATEGORIES_ICONS } from 'constants/categories';
import { TRANSACTION_PRODUCT_TYPES, TRANSACTION_PRODUCT_TYPE_NAMES } from 'constants/index';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import Button from 'components/Button';
import { Loaders } from 'components/cards/Loader';
import { ExportsEmailModal } from '..';
import { ExportsDropdown, getPeriodOptions } from '../Dropdowns';

const AVAILABLE_CURRENCIES = ['CAD', 'USD', 'EUR', 'GBP'];

const MobileFilter = ({ accountNames }) => {
  const { productType } = useContext(TransactionsContext);
  const { me } = useContext(AuthContext);
  const email = me?.email;
  const { data: creditCardData, loading: loadingCreditCards } = useQuery(GET_CREDIT_CARDS_FOR_FILTER);
  const { data: walletsData, loading: loadingWallets } = useQuery(GET_WALLETS_FOR_FILTER);

  const creditCards = get(creditCardData, 'me.account.creditCards') || [];
  const wallets = get(walletsData, 'me.account.wallets') || [];

  const {
    // setSearch,
    // showOnlyPending,
    // setShowOnlyPending,
    period,
    setPeriod,
    currentBillingCycle,
    previousBillingCycle,
    setCurrentBillingCycle,
    setPreviousBillingCycle,
    products,
    setProducts,
    currencies,
    setCurrencies,
    categories,
    setCategories,
    // handleClearFilters,
  } = useContext(FilterContext);

  const handleSubmit = () => {
    onClose();
  };

  const history = useHistory();
  const [showFilters, setShowFilters] = useState(false);
  const onClose = () => setShowFilters(false);
  const onNavigateBack = () => history.goBack();
  const onShowFilters = () => setShowFilters(true);

  // showModal values: false, CAUSES_EXPORT_FAILS.tooMany, CAUSES_EXPORT_FAILS.retrieving
  const [showModal, setShowModal] = useState(false);
  const [fileType, setFileType] = useState('');

  if (loadingCreditCards || loadingWallets) return <Loaders.Spinner />;

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-items-center tw-justify-between tw-px-0 tw-mb-4">
        <BiArrowBack size={24} onClick={onNavigateBack} />
        <ExportsDropdown
          mobileOrientation
          accountNames={accountNames}
          onShowModal={(value) => setShowModal(value)}
          setFileType={(type) => setFileType(type)}
        />
        <ExportsEmailModal
          showModal={showModal !== false}
          handleClose={() => setShowModal(false)}
          fileType={fileType}
          email={email}
          cause={showModal}
        />
      </div>
      <div className="tw-flex tw-items-center tw-justify-between">
        <h2 className="tw-font-semibold">{`${TRANSACTION_PRODUCT_TYPE_NAMES[productType]} Transactions`}</h2>
        <IoFilterOutline data-testid="filters-button" size={24} onClick={onShowFilters} />
      </div>
      <Transition
        show={showFilters}
        as="div"
        unmount={false}
        className="tw-fixed tw-h-screen tw-w-full tw-top-0 tw-right-0 tw-shadow-notification tw-z-10 tw-bg-neutral-grey-3.5 tw-transform tw-overflow-y-scroll"
        enter="tw-ease-in-out tw-duration-300"
        enterFrom="tw-translate-x-96"
        enterTo="tw-translate-x-0"
        leave="tw-ease-in-out tw-duration-300"
        leaveFrom="tw-translate-x-0"
        leaveTo="tw-translate-x-96"
      >
        <div className="tw-bg-neutral-grey-3.5 tw-p-4">
          <div className="tw-flex tw-items-center tw-mb-8">
            <BiArrowBack size={24} className="tw-cursor-pointer" onClick={onClose} />
          </div>
          <h2>Transactions Filters</h2>

          <div className="tw-mt-4">
            <PeriodOptions
              currentBillingCycle={currentBillingCycle}
              previousBillingCycle={previousBillingCycle}
              selectedPeriod={period}
              setSelectedPeriod={setPeriod}
            />
          </div>
          <div className="tw-mt-4">
            {productType !== TRANSACTION_PRODUCT_TYPES.lineOfCredit && (
              <ProductsOptions
                creditCards={creditCards}
                wallets={wallets}
                productType={productType}
                selectedProducts={products}
                seSelectedProducts={setProducts}
                setCurrentBillingCycle={setCurrentBillingCycle}
                setPreviousBillingCycle={setPreviousBillingCycle}
              />
            )}
          </div>
          <div className="tw-mt-4">
            <CurrencyOptions selectedCurrencies={currencies} seSelectedCurrencies={setCurrencies} />
          </div>

          {productType === TRANSACTION_PRODUCT_TYPES.creditCard && (
            <div className="tw-mt-4">
              <CategoryOptions selectedCategories={categories} setSelectedCategories={setCategories} />
            </div>
          )}

          <Button primary onClick={handleSubmit} className="tw-w-full tw-mt-8">
            Apply Filters
          </Button>
        </div>
      </Transition>
    </div>
  );
};

const PeriodOptions = ({ currentBillingCycle, previousBillingCycle, selectedPeriod, setSelectedPeriod }) => {
  const periodOptions = getPeriodOptions({ currentBillingCycle, previousBillingCycle });

  return (
    <>
      <div className="tw-text-sm tw-text-neutral-light-3">Period</div>
      <div className="tw-p-4 tw-mt-4 tw-bg-neutral-light tw-rounded-md">
        {periodOptions.map((option) => {
          const isSelected = selectedPeriod && selectedPeriod.key === option.key;
          const handleSelectOption = () => {
            const isSelected = selectedPeriod && selectedPeriod.key === option.key;

            isSelected ? setSelectedPeriod(null) : setSelectedPeriod(option.value);
          };

          return (
            <div
              key={option.key}
              value={option.value}
              className="tw-flex tw-items-center tw-p-2 tw-text-neutral-grey-2"
              onClick={handleSelectOption}
            >
              {isSelected ? (
                <IoRadioButtonOn size={18} className="tw-text-primary-dark-green" />
              ) : (
                <IoRadioButtonOff size={18} className="tw-text-neutral-grey-2" />
              )}
              <div className="tw-text-sm tw-ml-3 tw-text-neutral-dark">{option.key}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const ProductsOptions = ({
  selectedProducts,
  seSelectedProducts,
  creditCards,
  wallets,
  productType,
  setCurrentBillingCycle,
  setPreviousBillingCycle,
}) => {
  // building options from data
  const cardsOptions = creditCards.map((cc) => {
    return { label: cc.displayName, value: cc.id };
  });
  const walletsOptions = wallets.map((wallet) => {
    return { label: wallet.displayName, value: wallet.id };
  });
  let options = [];
  switch (productType) {
    case TRANSACTION_PRODUCT_TYPES.creditCard:
      options = cardsOptions;
      break;
    case TRANSACTION_PRODUCT_TYPES.wallet:
      options = walletsOptions;
      break;
    default:
      options = [];
  }

  const selectedIds = selectedProducts.map((product) => product.value);

  return (
    <>
      <div className="tw-text-sm tw-text-neutral-light-3">Product</div>
      <div className="tw-p-4 tw-mt-4 tw-bg-neutral-light tw-rounded-md">
        {options.map((option) => {
          const isSelected = selectedIds.includes(option.value);

          const onCheck = () => {
            const optionIndex = selectedIds.indexOf(option.value);

            if (optionIndex === -1) {
              setCurrentBillingCycle(
                option.currentBillingCycle && {
                  from: new Date(option.currentBillingCycle.from),
                  to: new Date(option.currentBillingCycle.to),
                }
              );
              setPreviousBillingCycle(
                option.previousBillingCycle && {
                  from: new Date(option.previousBillingCycle.from),
                  to: new Date(option.previousBillingCycle.to),
                }
              );
              seSelectedProducts([...selectedProducts, option]);
            } else {
              setCurrentBillingCycle(null);
              setPreviousBillingCycle(null);
              seSelectedProducts(selectedProducts.filter((product) => product.value !== option.value));
            }
          };

          return (
            <div
              key={option.label}
              className={`tw-flex tw-items-center tw-justify-start tw-mb-2 ${option.parentLabel ? 'tw-pl-2' : ''}`}
            >
              <input className="tw-mr-2" checked={isSelected} onChange={onCheck} type="checkbox" />
              {option.currency ? (
                <>
                  <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
                    <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                      <MobileCurrencyFlag size={14} currency={option.currency} />
                    </div>
                  </div>
                  <div className="tw-text-sm">{option.label}</div>
                </>
              ) : (
                <div className="tw-text-sm">{option.label}</div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const CurrencyOptions = ({ selectedCurrencies, seSelectedCurrencies }) => (
  <>
    <div className="tw-text-sm tw-text-neutral-light-3">Currency</div>
    <div className="tw-p-4 tw-mt-4 tw-bg-neutral-light tw-rounded-md">
      {AVAILABLE_CURRENCIES.map((currency) => {
        const isSelected = selectedCurrencies.includes(currency);
        const onChange = () => {
          const optionIndex = selectedCurrencies.indexOf(currency);

          if (optionIndex === -1) {
            seSelectedCurrencies([...selectedCurrencies, currency]);
          } else {
            seSelectedCurrencies([
              ...selectedCurrencies.slice(0, optionIndex),
              ...selectedCurrencies.slice(optionIndex + 1),
            ]);
          }
        };

        return (
          <div key={currency} className="tw-flex tw-justify-start tw-items-center tw-mb-2">
            <input
              id="filter-pending-status-checkbox"
              type="checkbox"
              value={isSelected}
              onChange={onChange}
              className="tw-mr-4"
            />
            <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
              <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                <MobileCurrencyFlag size={14} currency={currency} />
              </div>
            </div>
            <div className="tw-text-sm">{currency}</div>
          </div>
        );
      })}
    </div>
  </>
);

const CategoryOptions = ({ selectedCategories, setSelectedCategories }) => {
  const { data, error, loading } = useQuery(GET_TRANSACTION_CATEGORIES);
  const { transactionCategories } = data || { transactionCategories: [] };

  if (loading) return <Loaders.Spinner />;
  if (error) {
    console.error(error);
    return 'Error...';
  }

  return (
    <>
      <div className="tw-text-sm tw-text-neutral-light-3">Category</div>
      <div className="tw-p-4 tw-mt-4 tw-bg-neutral-light tw-rounded-md">
        {transactionCategories.map((category) => {
          const isSelected = selectedCategories.some(
            (selectedCategory) => selectedCategory.transactionCategoryId === category.transactionCategoryId
          );

          const onChange = () => {
            const optionIndex = selectedCategories.indexOf(category);

            if (optionIndex === -1) {
              setSelectedCategories([...selectedCategories, category]);
            } else {
              setSelectedCategories([
                ...selectedCategories.slice(0, optionIndex),
                ...selectedCategories.slice(optionIndex + 1),
              ]);
            }
          };

          return (
            <div key={category.transactionCategoryId} className="tw-flex tw-justify-start tw-items-center tw-mb-2">
              <input
                id="filter-pending-status-checkbox"
                type="checkbox"
                value={isSelected}
                onChange={onChange}
                className="tw-mr-4"
              />
              <div className="tw-mx-1">{CATEGORIES_ICONS[category.transactionCategoryId]}</div>
              <div className="tw-text-sm">{category.description}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MobileFilter;
