export const MESSAGES = {
  banner: {
    openAccount:
      'Your identity needs to be verified before your account can be opened. Please have your government-issued ID ready.',
    continueUsingAccount:
      'Your identity needs to be verified to continue using your account. Please have your government-issued ID ready.',
  },
  modal: {
    openAccount: 'We are required to verify your identity before your account can be opened.',
    continueUsingAccount: 'We are required to verify your identity before continuing to use your account.',
  },
};
