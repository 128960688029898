import React from 'react';

import Button from 'components/Button';
import { Modal } from 'components/UI';
import { useActionApprovalRequestModal } from './hooks';
import { ApprovalRequestActionModalProps } from './ApprovalRequestActionModal.types';
import { APPROVAL_ACTION_TYPE } from 'components/Approvals/constants';
import DetailElement from './components';
import { FormProvider } from 'react-hook-form';
import { TextArea } from 'components/FormFields/v2';

const ApprovalRequestActionModal = ({
  show,
  onClose,
  approvalRequest,
  actionType,
}: ApprovalRequestActionModalProps) => {
  const {
    handleDeclineRequest,
    handleApproveRequest,
    handleCancelRequest,
    isUpdateLoading,
    formattedAmountRequested,
    formattedTaxAmount,
    formattedOriginalAmount,
    formattedTransactionDate,
    requesterName,
    merchantName,
    description,
    showAmountInCAD,
    isAdmin,
    isMember,
    form,
    handleSubmit,
    declineReasonValue,
    onSubmit,
    register,
  } = useActionApprovalRequestModal({
    actionType,
    approvalRequest,
    onClose,
  });

  return (
    <Modal show={show} onClose={onClose} title="Confirm Expense Reimbursement">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isUpdateLoading ? (
            <div className="tw-px-12 tw-py-4 tw-border-b-1 tw-flex tw-flex-row tw-place-content-center tw-border-neutral-grey-4">
              <div className="spinner-border tw-h-8 tw-w-8 m-5" role="status"></div>
            </div>
          ) : (
            <div className="tw-px-12 tw-py-4 tw-border-b tw-border-neutral-grey-4">
              <div className="tw-flex tw-flex-row tw-gap-40">
                <div className="tw-flex tw-flex-col tw-gap-4">
                  <DetailElement label="Requester" value={requesterName} />
                  <DetailElement label="Merchant Name" value={merchantName} />
                  <DetailElement label="Description" value={description} />
                </div>
                <div className="tw-flex tw-flex-col tw-gap-4">
                  <DetailElement label="Amount" value={formattedOriginalAmount} />
                  <DetailElement label="Tax Amount" value={formattedTaxAmount} />
                  {showAmountInCAD && <DetailElement label="Amount in CAD" value={formattedAmountRequested} />}
                  <DetailElement label="Expense Date" value={formattedTransactionDate} />
                </div>
              </div>
              {actionType === APPROVAL_ACTION_TYPE.decline && (
                <div className="tw-pt-4">
                  <TextArea
                    name="declineReason"
                    data-testid="declineReason"
                    label="Decline Reason"
                    placeholder="Please enter a reason for declining this expense. This member will receive an email notifying them this expense has been declined."
                    ref={register({ required: true })}
                    rows={3}
                    maxLength={250}
                    required={actionType === APPROVAL_ACTION_TYPE.decline}
                  />
                </div>
              )}
            </div>
          )}
          <div className="tw-py-4 tw-px-8 tw-flex tw-justify-between">
            <Button
              className="tw-bg-neutral-grey-5 tw-border-neutral-grey-4 tw-border tw-rounded-md tw-px-6 tw-py-2"
              onClick={onClose}
              secondary
            >
              Back
            </Button>
            {isAdmin && actionType === APPROVAL_ACTION_TYPE.approve && (
              <Button
                className="tw-rounded-md tw-px-6 tw-py-2 tw-bg-primary-dark-green tw-font-semibold tw-text-neutral-light"
                onClick={handleApproveRequest}
                isFetching={isUpdateLoading}
              >
                Approve
              </Button>
            )}
            {isAdmin && actionType === APPROVAL_ACTION_TYPE.decline && (
              <Button
                className={`tw-rounded-md tw-px-6 tw-py-2 tw-font-semibold tw-text-neutral-light ${
                  declineReasonValue ? 'tw-bg-semantic-error' : 'tw-bg-neutral-grey-3'
                }`}
                onClick={handleDeclineRequest}
                isDisabled={!declineReasonValue}
                isFetching={isUpdateLoading}
              >
                Decline
              </Button>
            )}
            {isMember && actionType == APPROVAL_ACTION_TYPE.cancel && (
              <Button
                className="tw-rounded-md tw-px-6 tw-py-2 tw-bg-neutral-grey-3 tw-font-semibold "
                onClick={handleCancelRequest}
                isFetching={isUpdateLoading}
              >
                Cancel
              </Button>
            )}
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ApprovalRequestActionModal;
