import React from 'react';
import { BsList } from 'react-icons/bs';

import { PaymentRequestScheduleFrequency } from 'types/invoicing';

export const payorsTabs = [
  {
    label: 'View Payors',
    icon: <BsList />,
  },
];

export const MIN_PAYMENT_REQUEST_AMOUNT_CENTS = 1;
export const MAX_PAYMENT_REQUEST_AMOUNT_CENTS = 2500000;

export const MAX_DUE_DATE_RANGE_DAYS = 90;
export const MAX_END_DATE_RANGE_DAYS = 365;

export const MAX_NUMBER_OF_PAYMENTS = 50;

export const REQUESTS_RECURRING_TYPE = {
  once: {
    name: 'Once',
    value: PaymentRequestScheduleFrequency.once,
  },
  ongoing: {
    name: 'Ongoing',
    value: 'ongoing',
  },
};

export const REQUESTS_RECURRING_OPTIONS = Object.values(REQUESTS_RECURRING_TYPE).reduce(
  (acc, { name, value }) => [...acc, { name, value }],
  [] as { name: string; value: string }[]
);

export const ONGOING_FREQUENCY_OPTIONS = [
  { name: 'Weekly', value: PaymentRequestScheduleFrequency.weekly },
  { name: 'Bi-weekly', value: PaymentRequestScheduleFrequency.bi_weekly },
  { name: 'Month-end', value: PaymentRequestScheduleFrequency.month_end },
  { name: 'Monthly', value: PaymentRequestScheduleFrequency.monthly },
  { name: 'Semi-monthly', value: PaymentRequestScheduleFrequency.semi_monthly },
  { name: 'Quarterly', value: PaymentRequestScheduleFrequency.quarterly },
];

export const REQUESTS_RECURRING_STOP_TYPE = {
  endDate: {
    label: 'Specific date',
    value: 'end_date',
  },
  numberOfOccurrences: {
    label: 'Number of payments',
    value: 'number_of_occurrences',
  },
  byCancel: {
    label: 'When I cancel',
    value: 'by_cancel',
  },
};

export const REQUESTS_RECURRING_STOP_OPTIONS = Object.values(REQUESTS_RECURRING_STOP_TYPE).reduce(
  (acc, { label, value }) => [...acc, { label, value }],
  [] as { label: string; value: string }[]
);
