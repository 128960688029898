import React from 'react';
import { MdError } from 'react-icons/md';

import Loader from '../../cards/Loader';
import CircleCheck from '../../svg/CircleCheck';
import RedShield from '../../svg/RedShield';
import styles from './cards.module.scss';

export const Error = ({ message }) => {
  return (
    <div className={`card container text-center ${styles.statusCard} ${styles.errorCard}`}>
      <div className="p-5 mx-3">
        <MdError size={32} className="text-danger d-block mx-auto mt-4" />
        <p className="mt-2">{message}</p>
      </div>
    </div>
  );
};

export const Loading = () => {
  return (
    <div className={`card container text-center ${styles.statusCard}`}>
      <Loader />
    </div>
  );
};

export const Success = ({ formName }) => {
  return (
    <div className={`card container text-center ${styles.statusCard}`}>
      <div className="d-flex flex-column align-items-center p-5 mx-3">
        <div className="d-block mx-auto mb-5 mt-4">
          <CircleCheck />
        </div>
        <p>{`Thank you for completing the ${formName} form.`}</p>
        <div className="d-block mx-auto my-4">
          <RedShield />
        </div>
        <small>Safe banking online, guaranteed</small>
      </div>
    </div>
  );
};

export const Title = ({ title }) => {
  return (
    <div className="w-100">
      <div className={`bg-primary text-white ${styles.titleCard}`}>
        <div className="col text-center">
          <h2 className="h2">{title}</h2>
        </div>
      </div>
    </div>
  );
};
