import React from 'react';

import DashboardLayout from 'components/dashboard/DashboardLayout';
import { CardsListContextProvider } from './CardsListContext';
import CardsListContent from './components/CardsListContent';
import { ConnectBankOrContactLoopBanner } from 'components/UI';
import { CREDIT_CARD_EMPTY_STATES } from 'constants/index';
import MemberNoCards from 'components/creditCards/MemberNoCards';
import PendingBankAccountVerificationBanner from 'components/creditCards/PendingBankAccountVerificationBanner';
import ReviewInProgressBanner from 'components/creditCards/ReviewInProgressBanner';
import { useCardsAccountState } from './hooks';

const CardsList = () => {
  return (
    <DashboardLayout title="Cards List">
      <CardsListContextProvider>
        <Content />
      </CardsListContextProvider>
    </DashboardLayout>
  );
};

const Content = () => {
  const { accountState, isMember, canCreateCard, creditAgreementSigned } = useCardsAccountState();

  switch (accountState) {
    case CREDIT_CARD_EMPTY_STATES.connectBankOrContactLoop:
    case CREDIT_CARD_EMPTY_STATES.connectBankOrContactLoopAsLOCUser:
      if (isMember && !canCreateCard) {
        return <MemberNoCards title="Cards" />;
      }
      // TODO: add proper isMember && CanCreateCards behaviour which functions with our new card pages
      return (
        <ConnectBankOrContactLoopBanner
          title="Cards"
          message="Connect the business bank account you will be using to transfer funds to your Loop Card. Once connected, you'll be able to create Loop cards and start spending."
        />
      );
    case CREDIT_CARD_EMPTY_STATES.waitingBankAccountVerification:
      return <PendingBankAccountVerificationBanner title={'Verification Pending'} />;

    case CREDIT_CARD_EMPTY_STATES.reviewInProgress:
      return <ReviewInProgressBanner title="Cards" />;
    default:
      return <CardsListContent canCreateNewCard={canCreateCard && creditAgreementSigned} />;
  }
};

export default CardsList;
