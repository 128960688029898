import React, { memo } from 'react';
import { BsTwitterX } from 'react-icons/bs';

import SocialShareButton from '../../SocialShareButton';
import { PostContent, SocialShareType } from '../../SocialShareButton.types';

const TwitterShareButton = ({ url, text }: Pick<PostContent, 'url' | 'text'>) => {
  const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}${
    text ? `&text=${encodeURIComponent(text)}` : ''
  }`;

  return (
    <SocialShareButton postUrl={twitterUrl} type={SocialShareType.Twitter}>
      <BsTwitterX />
    </SocialShareButton>
  );
};

export default memo(TwitterShareButton);
