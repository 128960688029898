import { ApolloError } from '@apollo/client';
import { Currencies } from 'constants/currencies';

export type FundsContextType = {
  addFundsInfo: FundsPayloadType | null;
  setAddFundsInfo: React.Dispatch<React.SetStateAction<FundsPayloadType | null>>;
  addFundsTransaction: FundsTransactionType | null;
  setAddFundsTransaction: React.Dispatch<React.SetStateAction<FundsTransactionType | null>>;
  withdrawFundsInfo: FundsPayloadType | null;
  setWithdrawFundsInfo: React.Dispatch<React.SetStateAction<FundsPayloadType | null>>;
  withdrawFundsTransaction: FundsTransactionType | null;
  setWithdrawFundsTransaction: React.Dispatch<React.SetStateAction<FundsTransactionType | null>>;
  convertFundsInfo: ConvertFundsPayloadType | null;
  setConvertFundsInfo: React.Dispatch<React.SetStateAction<ConvertFundsPayloadType | null>>;
  convertFundsTransaction: FundsTransactionType | null;
  setConvertFundsTransaction: React.Dispatch<React.SetStateAction<FundsTransactionType | null>>;
  error: ApolloError | null;
  setError: React.Dispatch<React.SetStateAction<ApolloError | null>>;
  resetFundsContext: () => void;
};

export type FundsPayloadType = {
  fromAccount: string;
  toAccount: string;
  amount: {
    amount: number;
    currency: Currencies;
  };
};

export enum ConvertFundsActionTypes {
  buy = 'buy',
  sell = 'sell',
}

export type FundsTransactionType = {
  fromAccount: {
    displayName: string;
    currency: Currencies;
  };
  toAccount: {
    displayName: string;
    currency: Currencies;
  };
  amount: {
    amount: number;
    currency: Currencies;
  };
  transactionGroupId: string;
  initiatedAt: Date;
  transferMethod: string;
};

export type ConvertFundsPayloadType = {
  fromAccount: {
    id: string;
    displayName?: string;
    currency: Currencies;
  };
  toAccount: {
    id: string;
    displayName?: string;
    currency: Currencies;
  };
  originalAmount: {
    amount: number;
    currency: Currencies;
  };
  chargedAmount: {
    amount: number;
    currency: Currencies;
  };
  buyOrSell: ConvertFundsActionTypes;
  rate: number | null;
  rateExpiresAt?: string;
  exchangeRateReference?: string;
};
