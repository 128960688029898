import React from 'react';
import { PiUsersThreeLight, PiTrashLight } from 'react-icons/pi';

import { useCardMembers } from './hooks';
import { DetailsCard } from 'components/UI';
import { SectionSkeleton } from '..';
import {
  getCustomOptionBarItemLabel,
  canCardMembersBeDeleted,
  getMemberRoleLabel,
} from 'components/creditCards/components/cardsSettings/Members/Members.utils';
import { EditCardMembersModal } from './components';

const CardMembers = () => {
  const {
    isLoading,
    isError,
    isOpenModal,
    openModal,
    closeModal,
    handleUpdateMembers,
    isUpdatingMembers,
    members,
    internalContacts,
    handleRemoveMember,
  } = useCardMembers();

  if (isLoading) return <SectionSkeleton />;

  return (
    <>
      <DetailsCard
        label="Members"
        LabelIcon={PiUsersThreeLight}
        action={isError ? undefined : openModal}
        actionLabel="Add Teammate"
      >
        {!isError ? (
          <ul className="tw-flex tw-flex-col tw-gap-4">
            {members.map((member) => {
              const { id, firstName, lastName, role } = member;
              const memberLabel = getCustomOptionBarItemLabel(`${firstName} ${lastName}`);
              const canBeDeleted = canCardMembersBeDeleted(member);
              const removeMember = () => handleRemoveMember(id);
              const roleLabel = getMemberRoleLabel(role);

              return (
                <li key={id} className="tw-flex tw-items-start tw-justify-between tw-w-full tw-gap-2">
                  <div className="tw-flex tw-items-center tw-gap-1">
                    {memberLabel}
                    {roleLabel && <span className="tw-text-sm tw-text-neutral-grey-2">({roleLabel})</span>}
                  </div>
                  {canBeDeleted && (
                    <button
                      type="button"
                      title="Remove member"
                      className="tw-transition tw-ease-in-out tw-duration-250 hover:tw-text-semantic-error"
                      onClick={removeMember}
                    >
                      <PiTrashLight size={20} />
                    </button>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="tw-text-neutral-grey-2">Can't load credit card members settings</div>
        )}
      </DetailsCard>

      {isOpenModal && (
        <EditCardMembersModal
          isOpenModal={isOpenModal}
          closeModal={closeModal}
          handleUpdateMembers={handleUpdateMembers}
          members={members}
          internalContacts={internalContacts}
          isLoading={isUpdatingMembers}
        />
      )}
    </>
  );
};

export default CardMembers;
