import React from 'react';
import { FaRegSnowflake } from 'react-icons/fa';

import { CREDIT_CARD_STATUS } from 'constants/index';
import { useToggle } from 'hooks';
import CardActionButton from '../CardActionButton';
import ConfirmActionModal from '../ConfirmActionModal';

const ToggleFreezeCard = ({ creditCard }) => {
  const { isOpen: showFreezeModal, open: onFreezeCard, close: closeFreezeModal } = useToggle();
  const { isOpen: showUnfreezeModal, open: onUnfreezeCard, close: closeUnfreezeModal } = useToggle();

  const isFrozen = creditCard.status === CREDIT_CARD_STATUS.suspended;

  return (
    <>
      <CardActionButton
        label={isFrozen ? 'Unfreeze Card' : 'Freeze Card'}
        Icon={FaRegSnowflake}
        onClick={isFrozen ? onUnfreezeCard : onFreezeCard}
        className="tw-bg-secondary-light-blue tw-text-primary-blue"
      />
      <ConfirmActionModal
        show={showFreezeModal}
        onClose={closeFreezeModal}
        creditCardId={creditCard.id}
        status={CREDIT_CARD_STATUS.suspended.toUpperCase()}
        title={
          <div className="tw-flex tw-items-center tw-text-primary-blue">
            <FaRegSnowflake size={16} />
            <strong className="tw-ml-2">Freeze card</strong>
          </div>
        }
        description="The card will be frozen temporarily. You will be able to unfreeze it at any time."
        confirmLabel="Freeze card"
      />
      <ConfirmActionModal
        show={showUnfreezeModal}
        onClose={closeUnfreezeModal}
        creditCardId={creditCard.id}
        status={CREDIT_CARD_STATUS.active.toUpperCase()}
        title={
          <div className="tw-flex tw-items-center tw-text-primary-blue">
            <FaRegSnowflake size={16} />
            <strong className="tw-ml-2">Unfreeze card</strong>
          </div>
        }
        description="The card will be reactivated. You will be able to freeze it again at any time."
        confirmLabel="Unfreeze card"
      />
    </>
  );
};

export default ToggleFreezeCard;
