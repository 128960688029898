import React, { useState, useContext } from 'react';

import Button from 'components/Button';
import { AddPayeeContext } from 'components/payments/AddPayee/AddPayeeContext';
import { PAYEE_TYPES } from 'components/payments/AddPayee/constants';
import { PayeeOption } from './components';
import { PAYEE_OPTION_TITLE, PAYEE_OPTION_DESCRIPTION, PAYEE_OPTION_SUB_DESCRIPTION } from './constants';

const { SUPPLIER } = PAYEE_TYPES;

const PayeeType = ({ onNextStep }) => {
  const { payeeType, setPayeeType } = useContext(AddPayeeContext);
  const [type, setType] = useState(payeeType || SUPPLIER);

  const onNext = () => {
    setPayeeType(type);
    onNextStep();
  };

  return (
    <>
      <div className="tw-px-8 tw-py-8">
        {Object.values(PAYEE_TYPES).map((payeeType) => {
          const multipleCurrencies = payeeType === SUPPLIER;
          return (
            <PayeeOption
              key={payeeType}
              selected={type === payeeType}
              onSelect={() => setType(payeeType)}
              title={PAYEE_OPTION_TITLE[payeeType]}
              description={PAYEE_OPTION_DESCRIPTION[payeeType]}
              subDescription={PAYEE_OPTION_SUB_DESCRIPTION[payeeType]}
              multipleCurrencies={multipleCurrencies}
            />
          );
        })}
      </div>
      <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex tw-justify-end">
        <Button primary onClick={onNext} className="tw-px-8">
          Next
        </Button>
      </div>
    </>
  );
};

export default PayeeType;
