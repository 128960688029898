import React, { useState, useMemo, useEffect, memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { Select } from 'components/FormFields/v2';
import { ToggleDropdown, Forms } from 'components/UI';
import { ErrorLabel } from 'components/UI/Forms/components';
import { SpendManagementProps } from './SpendManagement.types';
import { Currencies } from 'constants/currencies';
import { mapMerchantToOptions } from './SpendManagement.utils';

const { CurrencyCheckboxGroup, CheckboxGroup } = Forms;

// The component should be used inside a FormProvider
const SpendManagement = ({
  merchantCategoriesOptions,
  selectedMerchantCategoriesOptions,
  setSelectedMerchantCategoriesOptions,
  isMerchantCategoriesError,
  currencies = [],
  merchants = [],
}: SpendManagementProps) => {
  const [isMerchantLocked, setIsMerchantLocked] = useState(false);

  const { register, watch } = useFormContext();
  const watchLockedOnMerchant = watch('lockOnMerchant') === 'true';

  const merchantsSelectOptions = useMemo(() => mapMerchantToOptions(merchants), [merchants]);

  useEffect(() => {
    setIsMerchantLocked(watchLockedOnMerchant);
  }, [watchLockedOnMerchant]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <div>
        <label htmlFor="limitType" className="tw-text-sm tw-text-primary-dark-green">
          Allowed Merchant Categories
        </label>
        <ToggleDropdown
          label="Selected Categories"
          options={merchantCategoriesOptions}
          selectedOptions={selectedMerchantCategoriesOptions}
          setSelectedOptions={setSelectedMerchantCategoriesOptions}
          idKey="value"
          optionKey="label"
          isControlFieldView
          isDisabled={isMerchantLocked}
        />
        {isMerchantCategoriesError && <ErrorLabel message="Select at least one merchant category" />}
      </div>

      <CurrencyCheckboxGroup
        name="allowedCurrencies"
        label="Allowed Currencies"
        options={currencies as unknown as Currencies[]}
        ref={register}
        labelClass="tw-text-primary-dark-green"
        multiple
      />

      <div>
        <CheckboxGroup
          name="lockOnMerchant"
          label="Merchant Locked"
          ref={register}
          labelClass="tw-text-primary-dark-green"
        />

        <Select
          name="allowedMerchant"
          options={merchantsSelectOptions}
          ref={register}
          placeholder="Select Merchant"
          rootClass="tw-mt-2"
          disabled={!isMerchantLocked}
          unselected
        />
      </div>
    </div>
  );
};

export default memo(SpendManagement);
