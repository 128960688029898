import React from 'react';
import Warning from 'components/svg/Warning';

const InsufficientRevenue = () => {
  return (
    <div className="tw-rounded-md tw-bg-secondary-pastel-yellow-80 tw-p-4 tw-mt-4 tw-mb-8 tw-flex">
      <Warning className="tw-mr-2" />
      <div>
        <small className="tw-text-primary-dark-yellow">
          The sales channel connected doesn't have sufficient revenue - try connecting another.
        </small>
        <ul className="tw-list-disc">
          <li className="tw-text-primary-dark-yellow">
            <small>If you don't see your sales channel listed, go to the waitlist.</small>
          </li>
          <li className="tw-text-primary-dark-yellow">
            <small>
              If you don't earn $1,000 through any of the channels we support, go to our pre-paid card waitlist.
            </small>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InsufficientRevenue;
