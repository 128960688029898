import React from 'react';

type BoxWithCoinsStarsAndCardsProps = {
  width?: number;
  height?: number;
};

const BoxWithCoinsStarsAndCards = ({ width = 122, height = 134 }: BoxWithCoinsStarsAndCardsProps) => (
  <svg width={width} height={height} viewBox="0 0 122 134" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.2205 95.7535L26.7944 113.384V88.2551L46.2205 71.0938V95.7535Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M96.4605 69.8711H46.2207V90.097H96.4605V69.8711Z"
      fill="#D0E7DA"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M96.4602 95.3464L77.0342 113.387V88.2583L96.4602 69.8711V95.3464Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M71.2071 72.9484L73.1558 65.1381L67.2265 59.2414L67.5326 58.3774L75.9498 58.5232L80.246 51.5413L83.6891 58.9096L92.5778 59.6267L92.2736 60.4914L85.679 65.7639L86.9917 72.8571L86.6874 73.7218L79.1699 69.6123L71.2071 72.9484Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.449959"
      strokeMiterlimit="10"
    />
    <path
      d="M71.5129 72.0859L73.4598 64.275L67.5323 58.3789L76.2538 57.6602L80.55 50.6783L83.9931 58.0465L92.5776 59.6283L85.983 64.9009L86.9914 72.8587L79.4739 68.7493L71.5129 72.0859Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.449959"
      strokeMiterlimit="10"
    />
    <path
      d="M67.6077 71.3167L53.3795 70.6812C53.0276 70.6661 52.4625 69.9973 52.5677 69.6912L61.3568 45.5663C61.4108 45.4117 61.5191 45.2777 61.6647 45.1851C61.8104 45.0924 61.9853 45.0462 62.1626 45.0537L76.5408 45.5677C76.939 46.0396 76.9402 46.1343 76.8738 46.3243L68.4144 70.7914C68.3636 70.949 68.2565 71.0864 68.1102 71.1816C67.964 71.2768 67.787 71.3244 67.6077 71.3167Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M71.8723 74.1088L57.6441 73.4733C57.2922 73.4583 56.7272 72.7894 56.8323 72.4833L65.6215 48.3585C65.6755 48.2039 65.7837 48.0699 65.9294 47.9772C66.075 47.8845 66.25 47.8384 66.4272 47.8459L70.0524 57.9177C70.4505 58.3897 76.0812 57.9177 76.0812 57.9177L80.1004 51.1756C80.1004 51.1756 80.304 51.1502 80.4353 51.1501C80.7702 51.15 72.679 73.5836 72.679 73.5836C72.6283 73.7411 72.5212 73.8785 72.3749 73.9738C72.2286 74.069 72.0516 74.1165 71.8723 74.1088Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M67.2915 70.917L53.0634 70.2815C52.9816 70.2777 52.902 70.2568 52.8305 70.2203C52.759 70.1839 52.6976 70.133 52.6511 70.0714C52.6045 70.0098 52.574 69.9393 52.562 69.8653C52.5499 69.7913 52.5566 69.7157 52.5814 69.6444L61.0385 45.1762C61.0929 45.0218 61.2012 44.888 61.3468 44.7954C61.4924 44.7028 61.6672 44.6565 61.8443 44.6636L76.0725 45.299C76.1545 45.3025 76.2346 45.3232 76.3064 45.3596C76.3783 45.3959 76.4401 45.447 76.4869 45.5087C76.5337 45.5704 76.5644 45.6412 76.5765 45.7155C76.5886 45.7898 76.5818 45.8657 76.5567 45.9372L68.0973 70.4044C68.0436 70.5592 67.9355 70.6935 67.7898 70.7862C67.644 70.8789 67.4688 70.9249 67.2915 70.917Z"
      fill="#D0E7DA"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M62.4145 61.8592C64.8739 62.9505 67.8345 62.011 69.0272 59.7608C70.22 57.5106 69.1932 54.8017 66.7338 53.7104C64.2745 52.6191 61.3139 53.5586 60.1211 55.8088C58.9284 58.059 59.9552 60.7679 62.4145 61.8592Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M62.5867 50.4648L60.5927 50.3796C60.5749 50.3792 60.5574 50.375 60.5417 50.3672C60.526 50.3595 60.5125 50.3484 60.5023 50.335C60.4922 50.3216 60.4857 50.3062 60.4833 50.29C60.4809 50.2738 60.4827 50.2573 60.4886 50.2419L61.9299 46.0735C61.9414 46.0397 61.965 46.0105 61.9968 45.9905C62.0286 45.9705 62.0669 45.9609 62.1054 45.9632L64.097 46.0474C64.1148 46.0483 64.1321 46.053 64.1477 46.0609C64.1632 46.0688 64.1766 46.0799 64.1869 46.0932C64.1971 46.1065 64.2039 46.1218 64.2068 46.1379C64.2096 46.154 64.2085 46.1704 64.2035 46.1861L62.7622 50.3545C62.7503 50.388 62.7266 50.417 62.6949 50.4369C62.6632 50.4569 62.6251 50.4667 62.5867 50.4648Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M67.1008 70.0529L65.1069 69.9677C65.0891 69.9668 65.0718 69.9622 65.0562 69.9542C65.0407 69.9463 65.0273 69.9352 65.017 69.9219C65.0068 69.9086 65 69.8933 64.9971 69.8772C64.9942 69.8611 64.9954 69.8447 65.0004 69.8291L66.4451 65.6595C66.4563 65.626 66.4795 65.5968 66.5109 65.5766C66.5422 65.5565 66.5801 65.5465 66.6184 65.5482L68.6123 65.6334C68.6299 65.6343 68.6471 65.6388 68.6625 65.6467C68.678 65.6546 68.6912 65.6656 68.7013 65.6789C68.7113 65.6922 68.7179 65.7074 68.7205 65.7234C68.7232 65.7394 68.7217 65.7557 68.7164 65.7711L67.2751 69.9395C67.2639 69.9735 67.2407 70.003 67.2091 70.0235C67.1776 70.044 67.1395 70.0544 67.1008 70.0529Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M55.9968 69.5164L54.0029 69.4312C53.9853 69.4304 53.9681 69.4258 53.9526 69.4179C53.9372 69.4101 53.924 69.3991 53.9139 69.3858C53.9039 69.3725 53.8973 69.3573 53.8947 69.3413C53.892 69.3253 53.8935 69.309 53.8988 69.2936L55.3401 65.1304C55.3514 65.0965 55.3746 65.0669 55.4061 65.0464C55.4377 65.0259 55.4758 65.0155 55.5145 65.017L57.5084 65.1022C57.5262 65.1031 57.5435 65.1077 57.559 65.1157C57.5746 65.1236 57.588 65.1347 57.5982 65.148C57.6085 65.1613 57.6153 65.1766 57.6181 65.1927C57.621 65.2088 57.6199 65.2252 57.6148 65.2408L56.1668 69.4168C56.1535 69.4476 56.1299 69.4739 56.0994 69.4918C56.0689 69.5097 56.033 69.5183 55.9968 69.5164Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M73.6888 51.0049L71.6949 50.9197C71.677 50.9193 71.6595 50.9151 71.6438 50.9073C71.6281 50.8996 71.6146 50.8886 71.6045 50.8751C71.5943 50.8617 71.5878 50.8463 71.5854 50.8301C71.583 50.8139 71.5849 50.7975 71.5908 50.782L73.032 46.6136C73.0432 46.58 73.0664 46.5509 73.0977 46.5307C73.1291 46.5106 73.167 46.5006 73.2052 46.5023L75.1992 46.5875C75.217 46.5879 75.2345 46.5921 75.2502 46.5999C75.2659 46.6076 75.2794 46.6186 75.2896 46.6321C75.2997 46.6455 75.3063 46.6609 75.3086 46.6771C75.311 46.6933 75.3092 46.7098 75.3033 46.7252L73.862 50.8936C73.8508 50.9272 73.8277 50.9563 73.7963 50.9765C73.7649 50.9967 73.7271 51.0066 73.6888 51.0049Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M65.1463 67.6307L54.4346 76.1823C54.1705 76.3933 53.257 76.2859 53.111 76.0094L41.351 53.8033C41.2721 53.6608 41.2484 53.4981 41.2837 53.3417C41.319 53.1854 41.4113 53.0446 41.5454 52.9424L52.2787 44.209C52.9182 44.2837 52.9878 44.3484 53.0831 44.5216L65.3429 66.7656C65.4199 66.9092 65.4424 67.0723 65.4068 67.229C65.3712 67.3857 65.2795 67.5271 65.1463 67.6307Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M70.7081 68.6646L59.9964 77.2162C59.7323 77.4272 58.8188 77.3198 58.6728 77.0433L46.9128 54.8372C46.8339 54.6947 46.8102 54.532 46.8455 54.3756C46.8808 54.2193 46.9731 54.0785 47.1072 53.9763C48.3233 52.9869 56.7336 55.4031 57.2737 56.3851L60.2221 48.4172L70.9047 67.7995C70.9817 67.9431 71.0042 68.1062 70.9686 68.2629C70.933 68.4196 70.8413 68.561 70.7081 68.6646Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M64.625 67.5532L53.9133 76.1048C53.853 76.1541 53.7812 76.1902 53.7034 76.2105C53.6256 76.2307 53.5438 76.2345 53.4642 76.2216C53.3845 76.2087 53.3091 76.1795 53.2436 76.1361C53.1781 76.0926 53.1242 76.0362 53.086 75.9709L40.8286 53.7279C40.7497 53.5855 40.726 53.4227 40.7613 53.2664C40.7967 53.11 40.8889 52.9692 41.0231 52.8671L51.7323 44.3145C51.7925 44.2648 51.8642 44.2282 51.9421 44.2077C52.02 44.1872 52.102 44.1833 52.1818 44.1962C52.2616 44.2091 52.3371 44.2385 52.4026 44.2822C52.4681 44.3259 52.5218 44.3828 52.5596 44.4484L64.8194 66.6923C64.8956 66.8353 64.9178 66.9973 64.8826 67.1531C64.8474 67.3089 64.7568 67.4495 64.625 67.5532Z"
      fill="#D0E7DA"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M54.9864 64.1289C57.427 63.0029 58.4374 60.3334 57.2432 58.1664C56.049 55.9994 53.1024 55.1555 50.6618 56.2815C48.2212 57.4075 47.2108 60.077 48.405 62.244C49.5992 64.411 52.5458 65.2549 54.9864 64.1289Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M45.9087 56.4098L44.4145 57.5999C44.4018 57.611 44.3865 57.6192 44.3697 57.624C44.353 57.6287 44.3353 57.6298 44.318 57.6272C44.3007 57.6246 44.2843 57.6183 44.2701 57.6089C44.2559 57.5995 44.2443 57.5872 44.2361 57.573L42.1518 53.7857C42.1355 53.7542 42.131 53.7188 42.1391 53.6848C42.1471 53.6508 42.1672 53.6201 42.1962 53.5977L43.6881 52.4067C43.7011 52.3957 43.7166 52.3875 43.7336 52.3829C43.7506 52.3782 43.7685 52.3772 43.7859 52.3799C43.8034 52.3826 43.8199 52.3889 43.8343 52.3984C43.8487 52.4079 43.8605 52.4202 43.8688 52.4345L45.9531 56.2219C45.9694 56.2533 45.9739 56.2888 45.9659 56.3228C45.9578 56.3568 45.9377 56.3874 45.9087 56.4098Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M63.8373 67.0783L62.3401 68.28C62.3272 68.2912 62.3116 68.2995 62.2946 68.3041C62.2775 68.3088 62.2595 68.3097 62.242 68.3067C62.2245 68.3038 62.208 68.2972 62.1938 68.2873C62.1797 68.2774 62.1682 68.2647 62.1605 68.25L60.0762 64.4627C60.0584 64.432 60.0528 64.3966 60.0603 64.3626C60.0677 64.3285 60.0879 64.2979 60.1172 64.2759L61.609 63.0849C61.6222 63.0741 61.6378 63.0663 61.6548 63.0619C61.6717 63.0575 61.6896 63.0568 61.7069 63.0597C61.7242 63.0626 61.7406 63.069 61.7548 63.0786C61.7689 63.0882 61.7805 63.1006 61.7887 63.1149L63.873 66.9022C63.8876 66.9313 63.8921 66.9639 63.8857 66.9954C63.8793 67.027 63.8624 67.0559 63.8373 67.0783Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M55.4483 73.7216L53.9512 74.9234C53.9381 74.9341 53.9224 74.942 53.9055 74.9464C53.8885 74.9507 53.8707 74.9515 53.8534 74.9486C53.836 74.9457 53.8197 74.9392 53.8055 74.9297C53.7913 74.9201 53.7797 74.9077 53.7716 74.8934L51.6862 71.1082C51.6689 71.077 51.6637 71.0413 51.6713 71.007C51.6789 70.9727 51.699 70.9418 51.7283 70.9193L53.2254 69.7175C53.2385 69.7068 53.2542 69.6989 53.2711 69.6946C53.2881 69.6902 53.3059 69.6894 53.3232 69.6923C53.3406 69.6952 53.3569 69.7017 53.3711 69.7113C53.3853 69.7208 53.3969 69.7332 53.405 69.7475L55.4893 73.5349C55.5062 73.5657 55.5113 73.6009 55.5038 73.6347C55.4964 73.6685 55.4769 73.6991 55.4483 73.7216Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M54.2983 49.7654L52.8041 50.9555C52.7915 50.9666 52.7762 50.9748 52.7594 50.9795C52.7427 50.9843 52.7249 50.9854 52.7076 50.9828C52.6903 50.9802 52.6739 50.9739 52.6597 50.9645C52.6455 50.9551 52.6339 50.9428 52.6258 50.9286L50.5415 47.1412C50.5252 47.1098 50.5207 47.0744 50.5287 47.0404C50.5367 47.0064 50.5568 46.9757 50.5859 46.9533L52.083 45.7515C52.0963 45.7412 52.112 45.7337 52.1289 45.7296C52.1457 45.7255 52.1634 45.7249 52.1806 45.7277C52.1978 45.7306 52.214 45.7369 52.2282 45.7462C52.2424 45.7555 52.2542 45.7676 52.2626 45.7816L54.347 49.5689C54.3652 49.6017 54.3704 49.6393 54.3615 49.6752C54.3526 49.7111 54.3303 49.743 54.2983 49.7654Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M72.667 14.9404L62.8039 20.6586L62.3595 31.3339L61.1831 31.8925L53.369 24.5181L42.1335 27.4317L46.4411 17.4999L38.765 9.18174L39.9416 8.6255L51.5937 9.30446L57.635 1.46257L58.8114 0.90398L61.7079 11.2543L72.667 14.9404Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M71.4905 15.4982L61.6274 21.2163L61.1832 31.894L52.1925 25.0759L40.957 27.9895L45.2646 18.0577L38.7651 9.18327L50.4172 9.86223L57.6351 1.46409L60.5314 11.812L71.4905 15.4982Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M98.8065 60.9311C100.637 62.1845 97.9893 68.2531 92.8923 74.4869C87.7954 80.7208 82.1813 84.7566 80.3504 83.5055L78.8229 82.4598C78.6425 80.1079 82.1214 75.015 86.2621 69.9492C90.4027 64.8833 94.7572 60.4068 97.2789 59.8854L98.8065 60.9311Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M90.9458 73.4856C96.0421 67.2523 98.6894 61.1833 96.8587 59.9303C95.028 58.6772 89.4125 62.7145 84.3161 68.9479C79.2198 75.1812 76.5725 81.2502 78.4032 82.5032C80.2339 83.7563 85.8494 79.719 90.9458 73.4856Z"
      fill="#D0E7DA"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M90.3175 73.0575C94.5782 67.8462 96.7913 62.7723 95.2605 61.7246C93.7298 60.6769 89.035 64.0521 84.7743 69.2634C80.5136 74.4746 78.3005 79.5486 79.8312 80.5963C81.3619 81.644 86.0568 78.2688 90.3175 73.0575Z"
      fill="#045B3F"
    />
    <path
      d="M95.263 61.7281C96.7931 62.7744 94.5837 67.8502 90.3191 73.0597C86.0546 78.2692 81.3611 81.6474 79.8266 80.5978C79.0019 80.0326 81.2081 74.7179 85.4677 69.5074C89.7272 64.2969 94.4403 61.1657 95.263 61.7281Z"
      fill="#D0E7DA"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M84.0109 77.0169L82.946 78.3195L82.4614 77.9815L82.4865 77.6688L83.3653 76.5936C82.4714 75.7136 82.7317 73.2577 84.255 71.2504L84.737 71.5879L85.6001 72.5195C84.6499 73.6838 84.4291 74.4507 84.7154 74.9463L87.1832 71.9299C86.7632 70.9501 86.3584 69.7423 88.0115 67.7201C89.415 66.0041 91.0228 65.2895 92.1799 65.8179L92.9242 64.9083L93.2672 64.7726L93.7492 65.1101L92.7991 66.2745C93.6334 67.1834 93.3599 68.4331 91.6695 70.5008L90.5743 70.0546L90.0923 69.7171C90.7092 68.8781 91.6273 68.297 91.4603 67.9072L89.1817 70.6944C89.6768 71.7013 90.1461 72.8595 88.3063 75.1114C86.8781 76.8537 85.2384 77.5475 84.0109 77.0169ZM85.3368 75.3936C85.9187 75.5779 86.5605 75.2278 87.1607 74.4914C87.7256 73.8032 87.7362 73.3095 87.5291 72.7143L85.3368 75.3936ZM88.8369 69.9054L90.8633 67.4239C90.3877 67.2899 89.8026 67.5575 89.2402 68.2461C88.6778 68.9347 88.6378 69.3865 88.8369 69.9054Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M83.5243 76.6875L82.4578 77.997L81.9966 77.3531L82.8754 76.2779C81.9223 75.3379 82.4607 73.4554 84.253 71.2589L85.1166 72.1882C84.1639 73.3521 83.9423 74.1332 84.2288 74.6168L86.6972 71.5981C86.2772 70.6183 85.8724 69.4105 87.5255 67.3884C88.9284 65.6746 90.5388 64.9605 91.6933 65.4885L92.4377 64.5788L93.2669 64.7742L92.3142 65.9381C93.1485 66.8471 92.8755 68.0945 91.1852 70.1621L90.0919 69.7187C90.9185 68.7076 91.1676 68.0091 90.9743 67.5755L88.6951 70.3649C89.1902 71.3719 89.6621 72.5305 87.8203 74.7796C86.3941 76.5248 84.7524 77.2157 83.5243 76.6875ZM84.8528 75.0647C85.4353 75.2466 86.0745 74.896 86.6766 74.1624C87.2391 73.4738 87.2522 72.9805 87.0431 72.3825L84.8528 75.0647ZM88.3503 69.576L90.3793 67.0949C89.9042 66.9586 89.3186 67.2285 88.7536 67.9166C88.1887 68.6047 88.1513 69.057 88.3503 69.576Z"
      fill="#D0E7DA"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M67.4337 86.5523L55.7675 87.0531L49.6055 96.1285L48.2822 96.0767L45.4813 86.1314L34.144 83.5734L43.2611 76.8961L41.0949 66.1914L42.4157 66.2408L52.1752 72.1092L61.6674 68.0323L62.9908 68.0817L59.9046 78.3868L67.4337 86.5523Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M75.4887 86.5546L63.8251 87.0531L57.6606 96.1285L56.3398 96.0791L53.5389 86.1314L42.2017 83.5734L51.3162 76.8961L49.15 66.1914L50.4733 66.2408L60.2302 72.1092L69.7224 68.0323L71.0458 68.0817L67.9597 78.3868L75.4887 86.5546Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M66.1108 86.5029L54.4472 87.0037L48.2826 96.0767L44.1584 86.082L32.8237 83.524L41.9382 76.8468L41.0954 66.1914L50.8523 72.0598L61.6679 68.0323L58.5817 78.3374L66.1108 86.5029Z"
      fill="#D0E7DA"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M73.9586 92.9014L59.7486 93.2793C59.3984 93.2873 58.7683 92.6832 58.8453 92.3752L65.1847 68.3901C65.2246 68.2348 65.32 68.0961 65.4557 67.996C65.5914 67.8959 65.7598 67.8401 65.9341 67.8373L80.2811 67.3362C80.7238 67.7637 80.7347 67.8557 80.6871 68.048L74.707 92.3508C74.6667 92.5055 74.5713 92.6436 74.4358 92.7432C74.3003 92.8429 74.1324 92.8985 73.9586 92.9014Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M76.3499 93.4435L62.1519 93.8257C61.8017 93.8337 61.1717 93.2296 61.2486 92.9216L67.5655 68.9311C67.6038 68.7754 67.6986 68.6361 67.8346 68.5361C67.9705 68.4361 68.1395 68.3813 68.314 68.3805L82.2211 67.8911C82.3804 67.8827 82.5392 67.914 82.6804 67.9818C82.8217 68.0496 82.9401 68.1514 83.023 68.2761C83.0556 68.3214 83.0766 68.3729 83.0846 68.4267C83.0925 68.4804 83.0871 68.5352 83.0688 68.5868L77.0888 92.8896C77.0516 93.0445 76.9585 93.1834 76.8244 93.2839C76.6903 93.3844 76.5232 93.4406 76.3499 93.4435Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M73.6082 92.5381L59.3982 92.9161C59.3174 92.9189 59.2371 92.905 59.1631 92.8754C59.0891 92.8457 59.0234 92.8011 58.9709 92.7448C58.9185 92.6886 58.8807 92.6222 58.8603 92.5507C58.8399 92.4792 58.8376 92.4043 58.8533 92.3318L64.8343 68.0268C64.8726 67.8711 64.9674 67.7319 65.1034 67.6319C65.2393 67.5319 65.4083 67.477 65.5827 67.4763L79.7928 67.0983C79.8736 67.095 79.9543 67.1086 80.0285 67.1381C80.1028 67.1676 80.1687 67.2123 80.2212 67.2686C80.2738 67.3249 80.3116 67.3915 80.3317 67.4633C80.3519 67.535 80.3539 67.61 80.3376 67.6826L74.3576 91.9854C74.3176 92.1406 74.2223 92.2793 74.0865 92.3794C73.9508 92.4795 73.7825 92.5354 73.6082 92.5381Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M68.5348 84.2992C71.2013 84.8486 73.8354 83.3747 74.4181 81.0072C75.0009 78.6396 73.3116 76.2748 70.6451 75.7254C67.9786 75.1759 65.3445 76.6498 64.7617 79.0174C64.179 81.385 65.8682 83.7497 68.5348 84.2992Z"
      fill="#D0E7DA"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M66.8915 73.0549L64.9024 73.1117C64.8846 73.1127 64.8668 73.1098 64.8504 73.1035C64.834 73.0971 64.8195 73.0874 64.8079 73.075C64.7963 73.0626 64.788 73.048 64.7836 73.0322C64.7792 73.0164 64.7788 72.9999 64.7825 72.984L65.8003 68.8461C65.8095 68.8123 65.8304 68.7822 65.86 68.7603C65.8895 68.7384 65.9261 68.726 65.9641 68.7248L67.9556 68.6688C67.9731 68.6682 67.9904 68.6713 68.0064 68.6778C68.0224 68.6842 68.0366 68.6939 68.0479 68.7061C68.0592 68.7184 68.0674 68.7328 68.0717 68.7483C68.0761 68.7638 68.0765 68.78 68.073 68.7957L67.0615 72.9383C67.0519 72.9725 67.0298 73.0028 66.9989 73.024C66.968 73.0452 66.9301 73.0561 66.8915 73.0549Z"
      fill="#D0E7DA"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M73.3313 91.7167L71.3422 91.7736C71.3244 91.7745 71.3067 91.7717 71.2903 91.7653C71.2739 91.759 71.2593 91.7492 71.2477 91.7369C71.2361 91.7245 71.2278 91.7098 71.2234 91.6941C71.219 91.6783 71.2187 91.6618 71.2224 91.6458L72.2354 87.5062C72.2445 87.4725 72.2655 87.4424 72.295 87.4205C72.3246 87.3986 72.3611 87.3861 72.3991 87.3849L74.3906 87.3289C74.4081 87.3284 74.4255 87.3314 74.4415 87.3379C74.4575 87.3444 74.4717 87.3541 74.483 87.3663C74.4943 87.3785 74.5024 87.3929 74.5068 87.4084C74.5111 87.4239 74.5115 87.4401 74.508 87.4558L73.4905 91.6064C73.4798 91.6376 73.4587 91.6651 73.4302 91.6848C73.4017 91.7046 73.3671 91.7157 73.3313 91.7167Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M62.2399 91.9689L60.2484 92.0249C60.2308 92.0259 60.2131 92.0232 60.1969 92.0169C60.1806 92.0106 60.1662 92.0009 60.1548 91.9885C60.1434 91.9762 60.1353 91.9616 60.1311 91.9459C60.127 91.9301 60.127 91.9138 60.131 91.898L61.1441 87.7711C61.1529 87.7371 61.1737 87.7068 61.2034 87.6848C61.233 87.6629 61.2698 87.6506 61.3079 87.6498L63.297 87.5929C63.3145 87.5929 63.3318 87.5963 63.3477 87.6029C63.3636 87.6096 63.3778 87.6193 63.3892 87.6315C63.4006 87.6436 63.4089 87.6579 63.4137 87.6733C63.4185 87.6887 63.4196 87.7048 63.4168 87.7207L62.3958 91.8599C62.3857 91.8908 62.3651 91.918 62.3372 91.9375C62.3092 91.9571 62.2751 91.9681 62.2399 91.9689Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M77.9864 72.8012L75.9949 72.8572C75.9774 72.8578 75.96 72.8547 75.944 72.8483C75.928 72.8418 75.9139 72.8321 75.9026 72.8199C75.8912 72.8076 75.8831 72.7932 75.8788 72.7777C75.8744 72.7622 75.874 72.746 75.8775 72.7303L76.8953 68.5924C76.9032 68.5581 76.9238 68.5273 76.9536 68.5052C76.9834 68.4831 77.0206 68.4711 77.059 68.4711L79.0481 68.4143C79.0656 68.4137 79.083 68.4168 79.099 68.4233C79.115 68.4297 79.1292 68.4394 79.1405 68.4517C79.1518 68.4639 79.1599 68.4783 79.1643 68.4938C79.1686 68.5093 79.169 68.5255 79.1655 68.5412L78.1469 72.6813C78.1388 72.7149 78.1185 72.745 78.0894 72.7668C78.0603 72.7885 78.024 72.8007 77.9864 72.8012Z"
      fill="#D0E7DA"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.2"
      d="M79.0441 110.32V84.5781H37.8474L29.4741 87.0297V110.32H79.0441Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M77.0342 88.2578H26.7944V113.387H77.0342V88.2578Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.5"
      d="M86.3544 119.516H53.4397C53.2481 119.516 53.0605 119.571 52.8992 119.674L35.0201 131.158C34.1802 131.698 34.5623 133 35.5606 133H68.4753C68.6669 133 68.8545 132.945 69.0157 132.841L86.8948 121.357C87.7347 120.818 87.3526 119.516 86.3544 119.516Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M33.9759 65.319C32.3313 67.5173 23.7513 64.7594 14.8116 59.159C5.87182 53.5586 -0.0409139 47.2364 1.60365 45.038L2.97584 43.2042C6.23671 42.8703 13.5113 46.6486 20.768 51.198C28.0246 55.7475 34.4924 60.5791 35.3481 63.4874L33.9759 65.319Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M35.3494 63.4855C36.9938 61.288 31.0803 54.9666 22.141 49.3663C13.2018 43.7659 4.62202 41.0073 2.97756 43.2047C1.3331 45.4022 7.24668 51.7236 16.1859 57.324C25.1251 62.9243 33.7049 65.6829 35.3494 63.4855Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M32.7664 61.7253C34.1414 59.8878 29.1979 54.6028 21.7248 49.9209C14.2516 45.239 7.07869 42.9332 5.70363 44.7706C4.32857 46.6081 9.27207 51.8931 16.7452 56.575C24.2184 61.2569 31.3913 63.5627 32.7664 61.7253Z"
      fill="#045B3F"
    />
    <path
      d="M32.7582 61.723C31.3834 63.5616 24.209 61.2528 16.7365 56.5716C9.26404 51.8905 4.32006 46.6051 5.68711 44.7666C6.42716 43.7767 13.9356 46.062 21.4081 50.7432C28.8806 55.4243 33.506 60.7379 32.7582 61.723Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M12.3453 52.7708L9.60352 51.0521L9.99924 50.5255L14.9766 47.1445L17.1454 48.5035C16.742 49.0396 17.3021 49.8248 19.6174 51.2778L20.2161 51.6517L20.9048 50.8593L23.6697 52.5921L23.1558 53.4856L24.3892 54.2685C27.4291 56.1753 29.1687 57.6424 29.0762 58.9355L28.403 59.9959C27.4393 61.2819 25.3682 61.1127 22.1999 59.1283L22.7138 57.5319L23.1095 57.0029C24.307 57.6635 25.2603 58.6299 25.5712 58.216C25.9798 57.6682 25.2423 56.8054 23.3742 55.6298L22.169 54.8868L21.8067 55.5452L21.4136 56.0624L18.1912 54.0428L18.587 53.5161L19.124 52.9542L18.6461 52.6697C16.9193 51.5882 16.21 50.9111 15.9325 50.1234L15.8837 50.0952L12.3453 52.7708Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <path
      d="M12.7408 52.2411L9.99902 50.5224L15.3721 46.6172L17.5383 47.9761C17.1375 48.5122 17.6951 49.2975 20.0129 50.7505L20.6116 51.1243L21.2977 50.3296L24.0652 52.0648L23.5513 52.9606L24.7847 53.7435C28.5903 56.1275 29.8597 58.0531 28.7985 59.4685C27.8349 60.7569 25.7637 60.5877 22.5954 58.6033L23.1093 57.0069C24.5997 57.9473 25.6121 58.1636 25.9642 57.6911C26.3753 57.1456 25.6352 56.2804 23.7697 55.1048L22.5645 54.3501L21.7936 55.5257L18.5739 53.5084L19.5067 52.4198L19.0287 52.133C17.3019 51.0514 16.5927 50.3767 16.3126 49.589L16.2664 49.5608L12.7408 52.2411Z"
      fill="#FFE8C3"
      stroke="#045B3F"
      strokeWidth="0.6"
      strokeMiterlimit="10"
    />
    <g opacity="0.5">
      <path
        d="M17.5712 121.671C17.4779 122.783 13.7361 123.427 9.21357 123.108C4.69104 122.79 1.10088 121.63 1.19422 120.518L1.27217 119.59C2.37807 118.845 5.88 118.821 9.55185 119.081C13.2237 119.341 16.6741 119.854 17.6497 120.744L17.5712 121.671Z"
        fill="#045B3F"
        stroke="#045B3F"
        strokeWidth="0.6"
        strokeMiterlimit="10"
      />
    </g>
    <path
      d="M94.9325 43.3873L94.7897 35.5633L87.6656 31.2334L87.7335 30.3485L95.7559 28.692L98.0548 21.1546L103.195 27.4097L111.809 26.196L111.741 27.0809L106.827 33.487L109.88 39.9361L109.812 40.8211L101.633 38.5257L94.9325 43.3873Z"
      fill="#045B3F"
      stroke="#045B3F"
      strokeWidth="0.43949"
      strokeMiterlimit="10"
    />
    <path
      d="M95.001 42.5026L94.8607 34.6777L87.734 30.3488L95.8244 27.8073L98.1232 20.27L103.264 26.525L111.809 26.1963L106.898 32.6015L109.88 39.9364L101.703 37.6417L95.001 42.5026Z"
      fill="#D0E7DA"
      stroke="#045B3F"
      strokeWidth="0.43949"
      strokeMiterlimit="10"
    />
    <g opacity="0.5">
      <path
        d="M110.68 122.879C110.265 123.187 109.675 123.145 109.314 122.78L107.771 121.223C107.604 121.054 107.379 120.948 107.137 120.924L101.523 120.366C101.467 120.361 101.416 120.333 101.383 120.289C101.302 120.182 101.355 120.03 101.488 119.993L105.157 118.951C105.685 118.801 105.994 118.278 105.856 117.768L105.837 117.696C105.639 116.966 106.343 116.305 107.101 116.508L110.451 117.406C110.623 117.452 110.804 117.454 110.976 117.412L115.942 116.21C116.056 116.183 116.175 116.224 116.245 116.315C116.309 116.399 116.318 116.511 116.268 116.604L115.464 118.092C115.195 118.59 115.417 119.201 115.951 119.431L118.906 120.703C118.933 120.715 118.957 120.733 118.974 120.756C119.056 120.863 118.978 121.013 118.84 121.017L113.326 121.154C113.109 121.159 112.898 121.231 112.726 121.359L110.68 122.879Z"
        fill="#045B3F"
        stroke="#045B3F"
        strokeWidth="0.43949"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
);

export default BoxWithCoinsStarsAndCards;
