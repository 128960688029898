import React from 'react';
import { useFormContext } from 'react-hook-form';

import { RadioField } from 'components/FormFields/v2';
import { payeeDetailsSourceOptions } from './constants';
import { PAYEE_DETAILS_SOURCE } from 'components/payments/AddPayee/constants';

const PayeeDetailsSourceOptions = ({ isDisabled, selectedValue }: { isDisabled: boolean; selectedValue: string }) => {
  const { register } = useFormContext();

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <div className="tw-text-neutral-grey-1 tw-text-sm">
        How would you like to provide banking details for this Payee?
        <span className="tw-text-semantic-error"> *</span>
      </div>

      <RadioField
        name="payeeDetailsSource"
        data-testid="payeeDetailsSource"
        options={payeeDetailsSourceOptions}
        ref={register({ required: !isDisabled })}
        disabled={isDisabled}
      />

      <aside className="tw-text-neutral-grey-1 tw-text-sm">
        {selectedValue == PAYEE_DETAILS_SOURCE.MANUAL && 'Enter your Payee’s banking details during the next step.'}
        {selectedValue == PAYEE_DETAILS_SOURCE.BY_REQUEST &&
          'Don’t know their banking information? Send an information request to your payee.'}
      </aside>
    </div>
  );
};

export default PayeeDetailsSourceOptions;
