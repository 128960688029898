import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { formatDateTime } from 'utility/date';
import { toUnit } from 'utility/currency';
import { SUBMIT_PAYMENT, MODIFY_PAYMENT, MOVE_FUNDS } from 'graphql/payments';
import Button from 'components/Button';
import { ErrorLabel } from 'components/FormFields/v2';
import { ReadOnly } from './shared';
import { PayLocBalanceContext } from '../PayLocBalanceContext';

const Review = () => {
  const {
    step,
    setStep,
    payload,
    formattedPaymentAmount,
    formattedFromAccountOption,
    formattedTransactionFees,
    recipientAccount,
    transferMethod,
    setPayment,
    lineOfCredit,
  } = useContext(PayLocBalanceContext);

  const [submitPaymentMutation, { loading: isSubmitPaymentLoading, error: submitPaymentError }] =
    useMutation(SUBMIT_PAYMENT);
  const [modifyPaymentMutation, { loading: isModifyPaymentLoading, error: modifyPaymentError }] =
    useMutation(MODIFY_PAYMENT);
  const [moveFunds, { loading: isMovingFunds, error: moveFundsError }] = useMutation(MOVE_FUNDS);

  const onSubmitPayment = async () => {
    try {
      const { data } = await submitPaymentMutation({
        variables: {
          amount: toUnit(payload.paymentAmount).toString(),
          dueDate: payload.paymentDate,
        },
      });

      if (data) {
        setPayment(data.submitPayment);
        setStep(step + 1);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onMoveFunds = async () => {
    try {
      const amount = { amount: payload.paymentAmount, currency: lineOfCredit.currency };

      const { data } = await moveFunds({
        variables: {
          from: payload.fromAccount,
          to: lineOfCredit.ledgerAccount.id,
          originalAmount: amount,
          chargedAmount: amount,
        },
      });
      if (data && data.moveFunds) {
        setPayment({
          transaction: {
            groupId: data.moveFunds.transactionGroupId,
            createdAt: data.moveFunds.initiatedAt,
          },
        });

        setStep(step + 1);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onModifyPayment = async () => {
    try {
      const { data } = await modifyPaymentMutation({
        variables: {
          amount: toUnit(payload.paymentAmount).toString(),
          dueDate: payload.paymentDate,
        },
      });

      if (data) {
        setStep(step + 1);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = async () => {
    switch (payload.paymentDetails) {
      case 'outstandingBalancePayment':
      case 'oneTimePayment':
        await onSubmitPayment();
        break;
      case 'internalTransfer':
        await onMoveFunds();
        break;
      case 'modifyPayment':
        await onModifyPayment();
        break;
      default:
    }
  };
  const isLoading = isMovingFunds || isSubmitPaymentLoading || isModifyPaymentLoading;
  const error = submitPaymentError || modifyPaymentError || moveFundsError;

  return (
    <>
      <small className="tw-text-neutral-grey-2">Please confirm your payment details.</small>

      <div className="tw-flex tw-flex-col tw-gap-8">
        <ReadOnly label="Recipient Account" value={recipientAccount} />

        <ReadOnly label="From Account" value={formattedFromAccountOption} />

        <ReadOnly label="Payment Amount" value={`${payload.currency} ${formattedPaymentAmount}`} />

        <ReadOnly label="Payment Date" value={formatDateTime(payload.paymentDate, 'MMMM d, yyyy')} />

        <ReadOnly label="Method" value={transferMethod} />

        <ReadOnly label="Fees" value={`${payload.currency} ${formattedTransactionFees}`} />

        {error && (
          <ErrorLabel data-testid="error-label" error={{ message: 'Something went wrong. Please try again later.' }} />
        )}
      </div>

      <div className="lg:tw-flex lg:tw-flex-row lg:tw-justify-between lg:tw-gap-8 tw-flex tw-flex-col tw-gap-3">
        <Button secondary isDisabled={isLoading} className="tw-w-full lg:tw-w-max" onClick={() => setStep(step - 1)}>
          Edit Payment Details
        </Button>

        <Button primary isDisabled={isLoading} className="tw-w-full lg:tw-w-max" onClick={onSubmit}>
          Submit Payment
        </Button>
      </div>
    </>
  );
};

export default Review;
