import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Select } from 'components/FormFields/v2';
import FormFooter from 'components/payments/AddPayee/components/FormFooter/FormFooter';
import { BankingFields, CountrySelect } from './components';
import { useSupplierBankingDetails } from './hooks';

const SupplierBankingDetails = ({ onNextStep, onPreviousStep, error }) => {
  const { form, register, handleSubmit, onSubmit, availableCurrencies } = useSupplierBankingDetails({ onNextStep });

  return (
    <FormProvider {...form} graphQLErrors={error?.graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-px-8 tw-py-8 tw-space-y-6">
          <strong>Please enter the payee’s banking details</strong>

          <Select
            name="bankAccount.currency"
            label="Bank Account Currency"
            ref={register({ required: true })}
            options={availableCurrencies}
            required
          />

          <CountrySelect />

          <BankingFields />
        </div>
        <FormFooter onPreviousStep={onPreviousStep} submitLabel="Next" />
      </form>
    </FormProvider>
  );
};

export default SupplierBankingDetails;
