import React from 'react';

import { Loaders } from 'components/cards/Loader';
import WalletAgreementModal from './WalletAgreementModal';
import { Layout } from 'components/UI';
import { useDashboardLayout } from './hooks';
import { USBankAccountVerificationModal } from 'components/Accounts/components';

const DashboardLayout = ({
  children,
  className,
  title,
}: {
  children: React.ReactNode;
  className?: string;
  title?: string;
}) => {
  const {
    topDropdownNavigation,
    sideNavItems,
    bottomSideNavItems,
    tabNavItems,
    showWalletAgreementModal,
    setShowWalletAgreementModal,
    showUSBankAccountVerificationsModal,
    closeUSBankAccountVerificationsModal,
  } = useDashboardLayout();

  return (
    <Layout
      sideNavItems={sideNavItems}
      bottomSideNavItems={bottomSideNavItems}
      tabNavItems={tabNavItems}
      topDropdownNavigation={topDropdownNavigation}
      classNames={className}
      title={title}
    >
      {children}
      <WalletAgreementModal show={showWalletAgreementModal} setShow={setShowWalletAgreementModal} />

      {showUSBankAccountVerificationsModal && (
        <USBankAccountVerificationModal
          show={showUSBankAccountVerificationsModal}
          onClose={closeUSBankAccountVerificationsModal}
        />
      )}
    </Layout>
  );
};

export const Loading = () => (
  <div className="tw-flex tw-flex-col tw-justify-center tw-flex-grow" data-testid="loader">
    <Loaders.FullScreen />
  </div>
);

export default DashboardLayout;
