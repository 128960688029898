import { useMediaQuery } from 'react-responsive';

// using default tailwind breakpoints
// https://v2.tailwindcss.com/docs/breakpoints
// TODO: Get breakpoints from theme (See #462)

const useIsMobile = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return isTabletOrMobile;
};

export default useIsMobile;
