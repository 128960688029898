import { AuthContext } from 'context/Auth';
import { useContext } from 'react';
import { PiCreditCardLight, PiSealCheckLight, PiWarningLight, PiCheckLight } from 'react-icons/pi';
import { IconType } from 'react-icons';
import { CreditCardStatus } from 'types/creditCard';
import Frozen from 'components/svg/Frozen';

const FrozenIcon = Frozen as unknown as IconType;

export const getVisibleCardActions = ({
  status,
  isVirtual,
  onActivateCard,
  onViewDetails,
  onFreezeCard,
  onUnfreezeCard,
  onReportLost,
}: {
  status?: CreditCardStatus;
  isVirtual: boolean | undefined;
  onActivateCard: () => void;
  onViewDetails: () => void;
  onFreezeCard: () => void;
  onUnfreezeCard: () => void;
  onReportLost: () => void;
}) => {
  const requiresActivation = status === CreditCardStatus.inactive && !isVirtual;
  const isFrozen = status === CreditCardStatus.suspended;
  const isLost = status === CreditCardStatus.lost;
  const { isImpersonating } = useContext(AuthContext) as unknown as {
    isImpersonating: boolean;
  };
  const disableViewCards = isLost || isImpersonating;

  return [
    requiresActivation
      ? {
          id: 'activateCard',
          Icon: PiCheckLight,
          label: 'Activate Card',
          className: 'tw-bg-secondary-light-green tw-text-primary-dark-green',
          action: onActivateCard,
          actionDisabled: isLost,
        }
      : {
          id: 'viewDetails',
          Icon: PiCreditCardLight,
          label: 'View Card',
          action: onViewDetails,
          className: 'tw-bg-secondary-light-green tw-text-primary-dark-green',
          actionDisabled: disableViewCards,
        },
    ...(!requiresActivation
      ? [
          isFrozen
            ? {
                id: 'unfreezeCard',
                Icon: PiSealCheckLight,
                label: 'Unfreeze Card',
                action: onUnfreezeCard,
                className: 'tw-bg-secondary-light-blue tw-text-primary-blue',
                actionDisabled: isLost,
              }
            : {
                id: 'freezeCard',
                Icon: FrozenIcon,
                label: 'Freeze Card',
                action: onFreezeCard,
                className: 'tw-bg-secondary-light-blue tw-text-primary-blue',
                actionDisabled: isLost,
              },
        ]
      : []),
    {
      id: 'reportLost',
      Icon: PiWarningLight,
      label: 'Report Lost',
      action: onReportLost,
      className: 'tw-bg-secondary-light-yellow tw-text-primary-dark-yellow',
      actionDisabled: isLost,
    },
  ];
};
