import React from 'react';

import { useGetUserInfo } from 'hooks';
import { usePADAgreement } from './hooks';
import { Checkbox } from 'components/FormFields/v2';
import { Loaders } from 'components/cards/Loader';
import { PADAgreementModal } from './components';
import { Tooltip } from 'components/UI';

const PADAgreement = () => {
  const { isLoading, fullName } = useGetUserInfo();
  const { register, isShowPADModal, openPADModal, closePADModal, fromAccountId } = usePADAgreement();

  if (isLoading) return <Loaders.Light />;

  return (
    <>
      <div className="tw-flex tw-items-start">
        <Checkbox name="autopayAgreement" ref={register({ required: true })} rootClass="tw-mt-1" required />
        <div className="tw-text-neutral-grey-2 tw-text-sm">
          I, {fullName}, of the Payor, hereby agree to enter into this{' '}
          {fromAccountId ? (
            <span role="button" aria-label="Open PAD Agreement modal" className="text-underline" onClick={openPADModal}>
              Pre-Authorized Debit (PAD) Agreement
            </span>
          ) : (
            <Tooltip
              target="Pre-Authorized Debit (PAD) Agreement"
              id="padAgreementTooltip"
              effect="float"
              type="error"
              place="top"
              wrapperClassName="tw-inline tw-underline tw-cursor-pointer"
            >
              Please select Account to Pay From
            </Tooltip>
          )}{' '}
          on behalf of the Payor and authorize Loop Financial Inc. to debit the Payor’s deposit account in accordance
          with the Pre-Authorized Debit Agreement. I hereby agree to waive the right to receive pre-notification of the
          amount of the PAD.
        </div>
      </div>
      <PADAgreementModal show={isShowPADModal} onClose={closePADModal} />
    </>
  );
};

export default PADAgreement;
