import { useContext } from 'react';
import { get } from 'lodash';

import { StepsProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { FundsContext } from 'components/Accounts/AccountsContent/contexts/FundsContext';
import { Currencies } from 'constants/currencies';
import { formatMoneyV2 } from 'utility/currency';
import { formatDateTime } from 'utility/date';
import useIsMobile from 'hooks/useIsMobile';

const useComplete = ({ onResetSteps }: Pick<StepsProps, 'onResetSteps'>) => {
  const { withdrawFundsTransaction, setWithdrawFundsInfo } = useContext(FundsContext);
  const isMobile = useIsMobile();

  const toAccount = get(withdrawFundsTransaction, 'toAccount.displayName', '');
  const fromAccount = get(withdrawFundsTransaction, 'fromAccount.displayName', '');
  const amount = get(withdrawFundsTransaction, 'amount', 0);
  const currency = get(withdrawFundsTransaction, 'amount.currency', Currencies.CAD);
  const transferMethod = get(withdrawFundsTransaction, 'transferMethod', '');
  const transactionGroupId = get(withdrawFundsTransaction, 'transactionGroupId', '');
  const initiatedAt = get(withdrawFundsTransaction, 'initiatedAt');

  const formattedAmount = `${currency} ${formatMoneyV2(amount)}`;
  const fees = `${currency} ${formatMoneyV2({ amount: 0, currency })}`;
  const formattedInitiatedAtDate = formatDateTime(initiatedAt);

  const handleNewWithdrawal = () => {
    setWithdrawFundsInfo(null);
    onResetSteps();
  };

  return {
    toAccount,
    fromAccount,
    formattedAmount,
    transferMethod,
    fees,
    transactionGroupId,
    formattedInitiatedAtDate,
    handleNewWithdrawal,
    isMobile,
  };
};

export default useComplete;
