import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const CheckmarkAndPerson2 = ({ width = '210', height = '175' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 349 243" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M187.604 47.2519C187.791 47.1259 188.014 47.3496 187.888 47.5362L178.011 62.1756C177.964 62.2449 177.964 62.3355 178.011 62.4048L187.888 77.0442C188.014 77.2308 187.791 77.4545 187.604 77.3285L172.964 67.4509C172.895 67.4042 172.805 67.4042 172.735 67.4509L158.096 77.3285C157.909 77.4545 157.686 77.2308 157.812 77.0442L167.689 62.4048C167.736 62.3355 167.736 62.2449 167.689 62.1756L157.812 47.5362C157.686 47.3496 157.909 47.1259 158.096 47.2519L172.735 57.1295C172.805 57.1763 172.895 57.1762 172.964 57.1295L187.604 47.2519Z"
        fill="#EEA530"
      />
      <path
        d="M97.3273 156.204C97.514 156.079 97.7376 156.302 97.6117 156.489L77.3499 186.518C77.3032 186.588 77.3032 186.678 77.3499 186.747L97.6117 216.777C97.7376 216.963 97.514 217.187 97.3273 217.061L67.2979 196.799C67.2287 196.753 67.138 196.753 67.0688 196.799L37.0393 217.061C36.8527 217.187 36.629 216.964 36.755 216.777L57.0167 186.747C57.0635 186.678 57.0635 186.588 57.0167 186.518L36.755 156.489C36.629 156.302 36.8527 156.079 37.0393 156.204L67.0688 176.466C67.138 176.513 67.2287 176.513 67.2979 176.466L97.3273 156.204Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M280.553 0.266935C280.74 0.140995 280.964 0.364641 280.838 0.551294L260.576 30.5807C260.529 30.65 260.529 30.7406 260.576 30.8099L280.838 60.8393C280.964 61.026 280.74 61.2496 280.553 61.1237L250.524 40.8619C250.455 40.8152 250.364 40.8152 250.295 40.8619L220.265 61.1237C220.079 61.2496 219.855 61.026 219.981 60.8393L240.243 30.8099C240.289 30.7406 240.289 30.65 240.243 30.5807L219.981 0.551296C219.855 0.364643 220.079 0.140994 220.265 0.266934L250.295 20.5287C250.364 20.5754 250.455 20.5754 250.524 20.5287L280.553 0.266935Z"
        fill={primaryDarkGreenColor}
      />
      <circle opacity="0.5" cx="309.145" cy="130.709" r="38.9844" fill={primaryDarkGreenColor} />
      <path
        opacity="0.5"
        d="M237.652 124.475C236.488 140.175 224.586 152.982 211.52 152.982C198.454 152.982 186.531 140.178 185.388 124.475C184.2 108.142 195.781 95.9674 211.52 95.9674C227.258 95.9674 238.839 108.439 237.652 124.475Z"
        fill="#EEA530"
      />
      <path
        opacity="0.5"
        d="M211.518 171.987C185.684 171.987 159.463 186.24 154.611 213.144C154.017 216.387 155.861 219.499 159.258 219.499H263.782C267.179 219.499 269.014 216.387 268.429 213.144C263.574 186.24 237.353 171.987 211.518 171.987Z"
        fill="#EEA530"
      />
      <path
        d="M222.057 132.272C220.893 147.971 208.991 160.779 195.925 160.779C182.859 160.779 170.937 147.974 169.793 132.272C168.606 115.939 180.187 103.764 195.925 103.764C211.664 103.764 223.245 116.236 222.057 132.272Z"
        stroke="#4F5154"
        strokeWidth="1.27892"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M195.925 179.784C170.09 179.784 143.869 194.037 139.017 220.941C138.423 224.184 140.267 227.296 143.664 227.296H248.188C251.586 227.296 253.421 224.184 252.836 220.941C247.981 194.037 221.76 179.784 195.925 179.784Z"
        stroke="#4F5154"
        strokeWidth="1.27892"
        strokeMiterlimit="10"
      />
      <path
        d="M330.309 140.733C330.309 121.363 314.593 105.647 295.223 105.647C275.853 105.647 260.137 121.363 260.137 140.733C260.137 160.103 275.853 175.819 295.223 175.819C314.593 175.819 330.309 160.103 330.309 140.733Z"
        stroke="#4F5154"
        strokeWidth="1.27892"
        strokeMiterlimit="10"
      />
      <path
        d="M295.222 126.114V155.352"
        stroke="#4F5154"
        strokeWidth="1.27892"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M309.841 140.733H280.603"
        stroke="#4F5154"
        strokeWidth="1.27892"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="83.8032" cy="80.3467" r="54.9519" fill="#D0E7DA" />
      <path
        d="M115.858 71.7268C115.858 44.5118 93.7784 22.4317 66.5634 22.4317C39.3484 22.4317 17.2683 44.5118 17.2683 71.7268C17.2683 98.9418 39.3484 121.022 66.5634 121.022C93.7784 121.022 115.858 98.9418 115.858 71.7268Z"
        stroke={primaryDarkGreenColor}
        strokeWidth="2.2268"
        strokeMiterlimit="10"
      />
      <path
        d="M91.211 51.1872L56.7044 92.2664L41.9159 75.8347"
        stroke={primaryDarkGreenColor}
        strokeWidth="2.2268"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckmarkAndPerson2;
