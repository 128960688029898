import React from 'react';

type RocketWithSparklesProps = {
  width?: number;
  height?: number;
};

const RocketWithSparkles = ({ width = 55, height = 49, ...props }: RocketWithSparklesProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 55 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={'tw-w-full tw-h-full tw-relative'}
    {...props}
  >
    <path
      d="M52.636 42.0331C52.8371 42.1218 52.7687 42.423 52.5489 42.416L48.2665 42.2805C48.185 42.2779 48.1101 42.3251 48.0772 42.3997L46.3485 46.3201C46.2598 46.5213 45.9586 46.4528 45.9656 46.2331L46.1011 41.9506C46.1037 41.8691 46.0565 41.7942 45.9819 41.7613L42.0615 40.0326C41.8603 39.9439 41.9287 39.6428 42.1485 39.6497L46.431 39.7852C46.5125 39.7878 46.5874 39.7406 46.6203 39.666L48.349 35.7456C48.4377 35.5444 48.7388 35.6129 48.7319 35.8326L48.5964 40.1151C48.5938 40.1966 48.6409 40.2715 48.7156 40.3044L52.636 42.0331Z"
      fill="#045B3F"
    />
    <path
      d="M22.8834 10.9065C23.1033 10.9069 23.1616 11.2102 22.9575 11.2921L15.2448 14.3875C15.1691 14.4178 15.1195 14.4911 15.1193 14.5727L15.1026 22.8833C15.1022 23.1032 14.7989 23.1614 14.717 22.9574L11.6216 15.2447C11.5913 15.169 11.518 15.1194 11.4364 15.1192L3.1258 15.1025C2.90594 15.102 2.84767 14.7988 3.05171 14.7169L10.7644 11.6215C10.8401 11.5911 10.8897 11.5179 10.8899 11.4363L10.9066 3.12567C10.9071 2.90581 11.2103 2.84754 11.2922 3.05158L14.3876 10.7643C14.418 10.84 14.4913 10.8896 14.5728 10.8898L22.8834 10.9065Z"
      fill="#EEA530"
    />
    <path
      opacity="0.5"
      d="M48.4076 7.57575C48.391 7.5276 48.3609 7.48521 48.321 7.45356C48.2811 7.42191 48.233 7.40232 48.1824 7.39709C44.8031 7.01895 37.6059 10.8544 34.0329 15.4893C33.3954 16.3099 32.8305 17.1844 32.3445 18.103C31.0647 18.1521 29.8123 18.4121 28.7961 19.0216C25.9279 20.7533 25.4951 24.363 25.4422 25.8954C25.4395 25.9794 25.4561 26.0628 25.4907 26.1393C25.5254 26.2158 25.5772 26.2833 25.6421 26.3366C25.707 26.3898 25.7833 26.4274 25.8651 26.4463C25.9469 26.4653 26.0319 26.4652 26.1136 26.446L30.9274 25.2779C30.9785 25.6461 31.0482 26.0115 31.1364 26.3726C31.1946 26.6225 31.3381 26.8443 31.5421 26.9998L33.6776 28.6476C33.8799 28.8056 34.131 28.888 34.3875 28.8807C34.7566 28.8742 35.1252 28.849 35.4918 28.8053L35.5878 33.7575C35.5901 33.8414 35.6117 33.9236 35.6509 33.9977C35.69 34.0719 35.7457 34.1361 35.8135 34.1853C35.8814 34.2345 35.9597 34.2675 36.0423 34.2816C36.1249 34.2957 36.2096 34.2907 36.29 34.2668C37.7586 33.8317 41.1437 32.4935 42.0795 29.2784C42.4098 28.1385 42.3433 26.8667 42.0719 25.6208C42.8385 24.9169 43.542 24.1473 44.1746 23.3207C47.7632 18.6887 49.6418 10.9153 48.4076 7.57575ZM39.8267 18.4838C39.4204 18.1703 39.116 17.7433 38.9521 17.2568C38.7883 16.7703 38.7722 16.2461 38.906 15.7506C39.0399 15.255 39.3175 14.8103 39.7039 14.4727C40.0903 14.1351 40.5681 13.9198 41.0768 13.854C41.5855 13.7883 42.1024 13.8749 42.5619 14.1031C43.0215 14.3313 43.4031 14.6907 43.6586 15.1359C43.9141 15.5811 44.032 16.0922 43.9973 16.6043C43.9626 17.1165 43.7769 17.6069 43.4637 18.0134C43.256 18.2836 42.9971 18.5101 42.7018 18.68C42.4065 18.8499 42.0806 18.9599 41.7428 19.0036C41.405 19.0473 41.0618 19.0238 40.7331 18.9347C40.4043 18.8455 40.0963 18.6922 39.8267 18.4838Z"
      fill="#045B3F"
    />
    <path
      d="M45.7229 7.92292C45.7063 7.87477 45.6762 7.83238 45.6363 7.80073C45.5964 7.76908 45.5483 7.74949 45.4977 7.74426C42.1184 7.36612 34.9212 11.2016 31.3482 15.8365C30.7107 16.6571 30.1458 17.5316 29.6598 18.4502C28.38 18.4992 27.1276 18.7593 26.1114 19.3688C23.2432 21.1004 22.8104 24.7102 22.7575 26.2426C22.7548 26.3265 22.7714 26.41 22.806 26.4865C22.8407 26.563 22.8925 26.6305 22.9574 26.6837C23.0224 26.737 23.0986 26.7745 23.1804 26.7935C23.2622 26.8125 23.3472 26.8124 23.4289 26.7932L28.2427 25.6251C28.2938 25.9933 28.3635 26.3586 28.4517 26.7197C28.5099 26.9697 28.6534 27.1915 28.8574 27.347L30.9929 28.9948C31.1952 29.1528 31.4463 29.2352 31.7028 29.2278C32.0719 29.2214 32.4405 29.1962 32.8071 29.1524L32.9031 34.1047C32.9055 34.1886 32.927 34.2707 32.9662 34.3449C33.0053 34.4191 33.061 34.4833 33.1288 34.5325C33.1967 34.5817 33.275 34.6147 33.3576 34.6288C33.4402 34.6429 33.5249 34.6378 33.6053 34.614C35.0739 34.1788 38.459 32.8407 39.3949 29.6256C39.7251 28.4857 39.6586 27.2139 39.3873 25.968C40.1538 25.2641 40.8573 24.4945 41.4899 23.6679C45.0785 19.0359 46.9571 11.2625 45.7229 7.92292ZM37.142 18.8309C36.7357 18.5175 36.4313 18.0905 36.2675 17.604C36.1036 17.1175 36.0875 16.5933 36.2214 16.0977C36.3552 15.6022 36.6328 15.1575 37.0192 14.8199C37.4056 14.4823 37.8834 14.267 38.3921 14.2012C38.9008 14.1354 39.4177 14.2221 39.8772 14.4503C40.3368 14.6784 40.7185 15.0379 40.9739 15.4831C41.2294 15.9283 41.3473 16.4393 41.3126 16.9515C41.2779 17.4637 41.0922 17.9541 40.779 18.3606C40.5713 18.6308 40.3124 18.8573 40.0171 19.0272C39.7218 19.1971 39.3959 19.3071 39.0581 19.3507C38.7203 19.3944 38.3772 19.371 38.0484 19.2818C37.7196 19.1926 37.4116 19.0394 37.142 18.8309Z"
      stroke="#4F5154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.5"
      d="M28.4217 28.9221C27.8745 29.074 27.3939 29.436 27.0563 29.9505C26.22 31.2316 26.6792 35.5164 26.6792 35.5164C26.6792 35.5164 30.3297 34.9335 31.1669 33.6549C31.5055 33.1404 31.6803 32.5015 31.663 31.8413"
      fill="#EEA530"
    />
    <path
      d="M26.5161 28.2595C25.9688 28.4114 25.4882 28.7734 25.1506 29.2879C24.3143 30.569 24.7735 34.8538 24.7735 34.8538C24.7735 34.8538 28.424 34.2709 29.2612 32.9923C29.5999 32.4778 29.7747 31.8389 29.7574 31.1787"
      stroke="#4F5154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RocketWithSparkles;
