import React from 'react';

import { ReviewProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/ConvertFunds/components/Steps/Steps.types';
import { useReview } from './hooks';
import { Loaders } from 'components/cards/Loader';
import { Footer } from 'components/Accounts/AccountsContent/components/AccountsModal/components';
import { ExchangeRateInfo } from 'components/Accounts/AccountsContent/components/AccountsModal/components/ConvertFunds/components/Steps/components';

const Review = ({ onNextStep, onPrevStep }: ReviewProps) => {
  const {
    fromAccountDisplayName,
    toAccountDisplayName,
    formattedOriginalAmount,
    rate,
    rateExpiresAt,
    setExpired,
    chargedAmount,
    originalAmount,
    expired,
    onRefreshRate,
    loadingRate,
    loading,
    handleSubmit,
    today,
    buyOrSell,
  } = useReview({
    onNextStep,
    onPrevStep,
  });

  if (loading)
    return (
      <div className="tw-text-center tw-p-20">
        <Loaders.Spinner />
      </div>
    );

  return (
    <>
      <div className="tw-px-8 tw-pt-6 tw-pb-4">
        <p>Please confirm your transfer details.</p>
        <div className="tw-flex tw-flex-col tw-pt-8 tw-pb-4">
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-2">From Account</div>
            <p className="tw-mt-1">{fromAccountDisplayName}</p>
          </div>
          <div className="tw-my-4">
            <div className="tw-text-sm tw-text-neutral-grey-2">To Account</div>
            <p className="tw-mt-1">{toAccountDisplayName}</p>
          </div>
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-2">Amount</div>
            <p className="tw-mt-1">{loadingRate ? <Loaders.Spinner /> : formattedOriginalAmount}</p>
          </div>
          <div className="tw-mt-4">
            <div className="tw-text-sm tw-text-neutral-grey-2">Date</div>
            <p className="tw-mt-1">{today}</p>
          </div>
          <ExchangeRateInfo
            chargedAmount={chargedAmount}
            originalAmount={originalAmount}
            rate={rate}
            rateExpiresAt={rateExpiresAt}
            onRefreshRate={onRefreshRate}
            expired={expired}
            setExpired={setExpired}
            buyOrSell={buyOrSell}
            loadingRate={loadingRate}
          />
          <div className="tw-mt-4">
            <div className="tw-text-sm tw-text-neutral-grey-2">Method</div>
            <p className="tw-mt-1">Internal Transfer</p>
          </div>
        </div>
      </div>
      <Footer
        onNavigateBack={onPrevStep}
        onSubmit={handleSubmit}
        navigateBackLabel="Edit Details"
        submitLabel="Confirm"
        loading={loading}
        isDisabled={expired || loadingRate}
      />
    </>
  );
};

export default Review;
