import { DirectUpload } from '@rails/activestorage';

import { getToken } from './auth';
import config from '../config';

const activeStorageURL = `${config.coreAppUrl}/borrowers/direct_uploads`;

export const uploadFile = async (file, progressCallback) => {
  const token = await getToken();

  return new Promise((resolve, reject) => {
    const onProgress = ({ total, loaded }) => {
      progressCallback && progressCallback({ total, loaded, file });
    };
    const directUploadWillCreateBlobWithXHR = (req) => {
      req.setRequestHeader('Authorization', `Bearer ${token}`);
    };

    const directUploadWillStoreFileWithXHR = (request) => {
      request.upload.addEventListener('progress', onProgress);
    };

    const upload = new DirectUpload(file, activeStorageURL, {
      directUploadWillStoreFileWithXHR,
      directUploadWillCreateBlobWithXHR,
    });
    upload.create((error, blob) => {
      if (error) return reject(error);
      resolve(blob);
    });
  });
};
