import { KYC_STATUS } from '../constants';

export const parseKYCStatus = ({ status, onOk, onWait, onDeclined, onManualKYCReview }) => {
  switch (status) {
    case KYC_STATUS.APPROVED:
      onOk();
      break;
    case KYC_STATUS.WAITING_FOR_USER_DATA:
    case KYC_STATUS.PENDING_USER_ACTION:
    case KYC_STATUS.MANUAL_REVIEW:
    case KYC_STATUS.READY_TO_REVIEW:
      onManualKYCReview();
      break;
    case KYC_STATUS.REJECTED:
      onDeclined();
      break;
    case KYC_STATUS.DRAFT:
    case KYC_STATUS.NOT_STARTED:
      onWait();
      break;
    default:
      onWait();
      break;
  }
};
