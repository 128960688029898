import React from 'react';
import { Controller } from 'react-hook-form';

import DropZone from 'components/uploader/Dropzone';
import { ReceiptUploadProps } from './ReceiptUpload.types';
import useReceiptUpload from './hooks/useReceiptUpload';

const ReceiptUpload = ({ required = true, name, label, rules, disabled = false }: ReceiptUploadProps) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    hasDroppedEmptyFile,
    hasDroppedMultipleFiles,
    file,
    onChangeFile,
    isReceiptUploadInvalid,
  } = useReceiptUpload({
    fieldName: name,
  });

  return (
    <Controller
      name={name}
      defaultValue={null}
      rules={rules}
      disabled={disabled}
      render={() => (
        <div>
          <div className="tw-text-neutral-grey-1 tw-text-sm tw-mb-2">
            {label} {required && <span className="tw-text-semantic-error">*</span>}
          </div>
          {!file && (
            <DropZone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              isDragActive={isDragActive}
              hasDroppedEmptyFile={hasDroppedEmptyFile}
              hasError={isReceiptUploadInvalid}
            />
          )}
          {hasDroppedMultipleFiles && (
            <div className="tw-m-3 tw-text-semantic-error">Only one file can be uploaded at a time.</div>
          )}
          <div>
            {file && (
              <div className="tw-my-2">
                <div>{file.name}</div>
                <button className="tw-text-primary-dark-green" onClick={onChangeFile}>
                  Change file
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default ReceiptUpload;
