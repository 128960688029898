import { scorePasswordStrength } from 'utility/password';

import { PASSWORD_MIN_LENGTH, REGEX_LETTER, REGEX_NUM, REGEX_SPEC_SYMBOLS } from '../constants';

const usePasswordStrengthMeter = ({ password }) => {
  const score = scorePasswordStrength(password);
  const color = (score <= 2 && 'error') || (score < 4 && 'warning') || 'success';
  const status = (score <= 2 && 'Weak') || (score < 4 && 'Medium') || 'Strong';
  const isPasswordEightCharsLong = password.length >= PASSWORD_MIN_LENGTH;
  const doesPasswordContainLetter = password.match(REGEX_LETTER);
  const doesPasswordContainNum = password.match(REGEX_NUM);
  const doesPasswordContainSpecSymbols = password.match(REGEX_SPEC_SYMBOLS);

  return {
    score,
    color,
    status,
    isPasswordEightCharsLong,
    doesPasswordContainLetter,
    doesPasswordContainNum,
    doesPasswordContainSpecSymbols,
  };
};

export default usePasswordStrengthMeter;
