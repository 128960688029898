import { ApolloError } from '@apollo/client';

type GetStepsWithErrorProps = {
  error?: ApolloError;
  attributesByStep: { [key: string]: string[] };
};

const getStepsWithError = ({ error, attributesByStep }: GetStepsWithErrorProps): number[] => {
  const errorSteps: number[] = [];
  const graphQLErrors = error?.graphQLErrors;

  if (!graphQLErrors) return [];

  if (!graphQLErrors?.length) return [0];

  for (const [step, stepAttributes] of Object.entries(attributesByStep)) {
    graphQLErrors.forEach((error) => {
      const pathString = error.path?.join(' ');

      if (pathString && stepAttributes?.some((attribute) => pathString.includes(attribute))) {
        errorSteps.push(parseInt(step, 10));
      }
    });
  }

  return errorSteps.filter((value, index) => errorSteps.indexOf(value) === index);
};

export default getStepsWithError;
