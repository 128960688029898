import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';

export const QboTaxCode = ({ className = '', selectedTaxCode, onChange, accountingIntegrationTaxCodes }) => {
  if (!selectedTaxCode && accountingIntegrationTaxCodes?.length > 0) selectedTaxCode = accountingIntegrationTaxCodes[0];

  return (
    <Listbox className={className} value={selectedTaxCode} onChange={onChange}>
      <div>
        <Listbox.Button
          className="qboTaxCode tw-cursor-pointer hover:tw-text-primary-dark-green tw-px-3 tw-py-2 tw-rounded-md tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input"
          name="category"
        >
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
            <div className="tw-truncate">{selectedTaxCode.name}</div>

            <strong className="tw-ml-2">
              <FaChevronDown />
            </strong>
          </div>
        </Listbox.Button>

        <Transition
          as={React.Fragment}
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Listbox.Options
            className={`tw-absolute tw-z-10 tw-overflow-y-scroll tw-bg-neutral-light tw-rounded-md tw-shadow-2xl tw-shadow-inner tw-border-solid tw-border-2 tw-border-primary-light-green tw-scrollbar-hide focus-visible: tw-ouline-none focus:tw-outline-none 
            `}
          >
            {accountingIntegrationTaxCodes &&
              accountingIntegrationTaxCodes.map((qboRate) => (
                <Listbox.Option
                  key={qboRate.id}
                  value={qboRate}
                  className={`tw-px-2 tw-py-1 tw-cursor-pointer tw-text-neutral-grey-2 hover:tw-text-neutral-light hover:tw-bg-primary-dark-green ${
                    selectedTaxCode.id === qboRate.id && 'tw-text-primary-dark-green tw-bg-primary-light-green'
                  }`}
                  selected={selectedTaxCode.id === qboRate.id}
                >
                  {({ active }) => (
                    <span className={`tw-inline-flex tw-items-center ${active ? 'tw-font-medium' : 'tw-font-normal'}`}>
                      <span className="tw-ml-2 tw-mr-1">{qboRate.name}</span>
                    </span>
                  )}
                </Listbox.Option>
              ))}
            {/* newRate && <div onClick={(evt) => evt.stopPropagation()}>{newRate()}</div> */}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
