import React from 'react';

import { ONBOARDING_STEPS } from 'components/onboarding/constants';
import ArrowRight from 'components/svg/ArrowRight';
import Button, { IconAnimation } from 'components/Button';
import { usePersonaEmbeddedData } from 'hooks';
import useScrollViewToPosition from 'hooks/useScrollViewToPosition';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { PersonaVerification } from 'components/IdentityVerification/components';
import Layout from '../Layout';
import Progress from '../Progress';
import { useIdentityVerification } from './hooks';

const IdentityVerification = () => {
  useScrollViewToPosition();

  useSetPageTitle(ONBOARDING_STEPS.idVerification.label);

  const {
    contactId,
    isVerificationCompleted,
    error: contactError,
    loading: isContactLoading,
    handleComplete,
    handleSkip,
  } = useIdentityVerification();

  const { environmentId, inquiryId, referenceId, sessionToken, templateId, fields, isLoading, error } =
    usePersonaEmbeddedData({
      contactId,
      withContactFields: true,
    });

  return (
    <Layout>
      <div className="tw-w-full lg:tw-w-4/5 tw-flex tw-flex-col tw-items-center tw-flex-grow">
        <Progress currentStep={7} />
        <div className="tw-w-full xl:tw-w-2/5 tw-mt-16 tw-flex tw-flex-col tw-gap-4">
          <h1>{ONBOARDING_STEPS.idVerification.title}</h1>
          <p>
            We are required to verify your identity before your account can be opened. Please have your
            government-issued ID ready to complete verification below.
          </p>
          <PersonaVerification
            inputDataError={contactError || error}
            isInputDataLoading={isContactLoading || isLoading}
            environmentId={environmentId}
            inquiryId={inquiryId}
            referenceId={referenceId}
            sessionToken={sessionToken}
            templateId={templateId}
            fields={fields}
            onCompleted={handleComplete}
          />
          <div className="tw-flex tw-justify-between tw-mt-6">
            <Button secondary onClick={handleSkip}>
              Verify Later
            </Button>
            <Button
              primary
              className="tw-w-max"
              isDisabled={!isVerificationCompleted}
              onClick={handleComplete}
              iconAnimation={IconAnimation.rollOut}
            >
              <div className="tw-flex tw-justify-center tw-items-center">
                Submit
                <ArrowRight className="tw-ml-2" />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IdentityVerification;
