import React from 'react';

import DashboardLayout from 'components/dashboard/DashboardLayout';
import { CardDetailsContextProvider } from './CardDetailsContext';
import { CardDetails } from './components';
import { CardDetailsPageProps } from './CardDetailsPage.types';

const CardDetailsPage = ({ match }: CardDetailsPageProps) => {
  const { id } = match.params;

  return (
    <DashboardLayout title="Credit Card">
      <CardDetailsContextProvider creditCardId={id}>
        <CardDetails />
      </CardDetailsContextProvider>
    </DashboardLayout>
  );
};

export default CardDetailsPage;
