import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_LINE_OF_CREDIT_STATEMENTS } from 'graphql/lineOfCredit';
import StatementsModal from 'components/StatementsModal';
import { StatementsPropsType } from './Statements.types';

const Statements = ({ show, setShow, type }: StatementsPropsType) => {
  const [loading, setLoading] = useState(true);
  const [statements, setStatements] = useState([]);

  const [getLinesOfCreditStatements, { loading: loadingStatements, data }] = useLazyQuery(
    GET_LINE_OF_CREDIT_STATEMENTS,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (show) {
      getLinesOfCreditStatements();
    }
  }, [show]);

  useEffect(() => {
    setLoading(loadingStatements);

    const lineOfCreditStatements = get(data, 'me.account.lineOfCredit.statements', []);
    setStatements(lineOfCreditStatements);
  }, [loadingStatements, data]);

  return <StatementsModal loading={loading} statements={statements} show={show} setShow={setShow} type={type} />;
};

export default Statements;
