import React from 'react';
import cx from 'classnames';

const CardActionButton = ({ onClick, className, label, Icon }) => (
  <div
    className={cx('tw-flex tw-items-center tw-cursor-pointer tw-mt-2 tw tw-px-4 tw-py-1 tw-rounded', className)}
    onClick={onClick}
  >
    <Icon size={16} />
    <span className="tw-ml-2 tw-text-sm tw-font-semibold">{label}</span>
  </div>
);

export default CardActionButton;
