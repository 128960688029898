import React from 'react';

import { StatementDetailsSkeleton } from '../StatementDetails/components';

const StatementBalancesSkeleton = ({ pageTitle }: { pageTitle: string }) => {
  return (
    <div className="tw-bg-neutral-light md:tw-shadow-default tw-rounded tw-py-6 tw-pb-4 tw-px-4">
      <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between md:tw-items-center tw-gap-4 tw-mb-4">
        <h2>{pageTitle}</h2>
        <div className="tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-h-10 tw-w-80 tw-mx-auto md:tw-mx-0" />
      </div>
      <StatementDetailsSkeleton />
    </div>
  );
};

export default StatementBalancesSkeleton;
