import React from 'react';
import { IoSearchOutline } from 'react-icons/io5';

import { Loaders } from 'components/cards/Loader';
import usePayeesFilters from 'components/payments/usePayeesFilters';
import PayeesList from './PayeesList';

const SendMoneyContent = (props) => {
  const { payees, lineOfCredit, wallets, loading, bankAccounts, onShowAddPayeeModal } = props;
  const payeeCount = payees?.length || 0;
  const { onChangeSearch, visiblePayees } = usePayeesFilters(payees);

  return (
    <div className="tw-my-8">
      {!!payeeCount && (
        <div className="tw-flex tw-mb-12">
          <div className="tw-relative tw-flex-grow">
            <div className="tw-absolute tw-py-2.5 tw-pl-3 tw-flex tw-items-center">
              <IoSearchOutline size={24} className="tw-mr-2" />
            </div>
            <input
              className="tw-block tw-px-3 tw-py-2 tw-pl-12 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input"
              onChange={onChangeSearch}
              placeholder="Search for payee"
            />
          </div>
        </div>
      )}
      {loading ? (
        <Loaders.Light />
      ) : (
        <PayeesList
          payees={visiblePayees}
          payeeCount={payeeCount}
          wallets={wallets}
          bankAccounts={bankAccounts}
          lineOfCredit={lineOfCredit}
          onAddPayee={onShowAddPayeeModal}
        />
      )}
    </div>
  );
};

export default SendMoneyContent;
