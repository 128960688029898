import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { CANCEL_PAYMENT_REQUEST, GET_PAYMENT_REQUESTS } from 'graphql/invoicing';
import { DeleteRequestModalProps } from '../DeleteRequestModal.types';

const useDeleteRequestModal = ({
  paymentRequest,
  onClose,
}: Pick<DeleteRequestModalProps, 'paymentRequest' | 'onClose'>) => {
  const { id } = paymentRequest || {};

  const [cancelPaymentRequest, { loading }] = useMutation(CANCEL_PAYMENT_REQUEST, {
    refetchQueries: [{ query: GET_PAYMENT_REQUESTS }],
    awaitRefetchQueries: true,
  });

  const handleDeleteRequest = useCallback(async () => {
    try {
      if (!id) throw new Error("Payment request ID couldn't be empty");

      const response = await cancelPaymentRequest({
        variables: { paymentRequestId: id },
      });
      toast.success('Payment request successfully canceled');

      if (!response?.data?.cancelPaymentRequest) {
        throw new Error("Can't cancel payment request");
      }
    } catch (err) {
      console.error(err);
      toast.error('Error canceling Payment request - please try again');
    } finally {
      onClose();
    }
  }, [id, cancelPaymentRequest, onClose]);

  return {
    loading,
    handleDeleteRequest,
  };
};

export default useDeleteRequestModal;
