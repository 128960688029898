import { useState, useEffect, useCallback } from 'react';

import { useToggle } from 'hooks';
import { PersonaVerificationProps } from '../PersonaVerification.types';

const usePersonaVerification = ({
  inputDataError,
  isInputDataLoading,
  templateId,
  inquiryId,
  sessionToken,
  onError,
}: Pick<
  PersonaVerificationProps,
  'inputDataError' | 'isInputDataLoading' | 'templateId' | 'inquiryId' | 'sessionToken' | 'onError'
>) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(inputDataError?.message || null);
  const [validationMessage, setValidationMessage] = useState<string | null>(
    !(templateId || inquiryId) ? 'Either "templateId" or "inquiryId" must be specified to start a flow' : null
  );

  const { isOpen: isLoaded, open: onLoad } = useToggle();

  const handleError = useCallback(
    (error: { status: number; code: string }) => {
      const { code } = error;
      setErrorMessage(code);
      onError?.(code);
    },
    [setErrorMessage, onError]
  );

  // resuming an existing inquiry validation
  useEffect(() => {
    if (isInputDataLoading) return;

    let message = null;

    if (inquiryId) {
      if (!sessionToken) {
        message = 'You must pass a "sessionToken" when resuming the inquiry.';
      }

      if (templateId) {
        message = 'You can not pass "templateId" and "inquiryId" together.';
      }
    } else {
      if (!templateId) {
        message = 'You must pass a "templateId" when creating a new inquiry.';
      }
    }

    if (message) {
      console.error(message);
    }

    setValidationMessage(message);
  }, [isInputDataLoading, templateId, inquiryId, sessionToken]);

  return { isLoaded, onLoad, errorMessage, validationMessage, handleError };
};

export default usePersonaVerification;
