import React from 'react';

import Banner, { BANNER_TYPES } from 'components/Banner';
import config from 'config';

const CardMaintenance = () => {
  return (
    config.cardApiDown && (
      <div className="tw-flex tw-justify-between tw-mb-2">
        <Banner isShowIcon type={BANNER_TYPES.danger} message={config.cardApiDown} />
      </div>
    )
  );
};

export default CardMaintenance;
