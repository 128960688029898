import { useContext } from 'react';

import {
  ACCEPTED_DOMAINS_REGEX,
  ADMIN_DOMAINS_REGEX,
  FEATURES_LIST,
  EXPERIMENT_EXCLUDED_EMAILS,
} from 'constants/featureToggles';
import { AuthContext } from 'context/Auth';
import { isIncludedInExperiment } from 'utility/featureToggles';

const useFeatureToggle = (onlyAdmin = false, feature = '') => {
  const { me, meLoading } = useContext(AuthContext);
  const { email } = me || {};

  if (email && EXPERIMENT_EXCLUDED_EMAILS.includes(email)) {
    return { isExperimentEnabled: false, loading: meLoading };
  }

  const defaultDomainsRegexp = onlyAdmin ? ADMIN_DOMAINS_REGEX : ACCEPTED_DOMAINS_REGEX;

  let acceptedDomains;

  if (feature) {
    if (feature === 'approvals') {
      const { features } = me?.account || {};
      const isApprovalEnabled = features?.includes('Card Expenses') || features?.includes('Expense Reimbursement');

      return { isExperimentEnabled: isApprovalEnabled, loading: meLoading };
    } else {
      const normalizedFeature = feature.toLowerCase();

      const currentFeatureUsersList = FEATURES_LIST.find(
        ({ featureName }) => featureName.toLowerCase() === normalizedFeature
      )?.allowedUserEmails;
      acceptedDomains = currentFeatureUsersList?.length
        ? RegExp(`(${[...currentFeatureUsersList].join('|')})`)
        : defaultDomainsRegexp;
    }
  } else {
    acceptedDomains = defaultDomainsRegexp;
  }

  const isExperimentEnabled = isIncludedInExperiment(email, acceptedDomains);

  return { isExperimentEnabled, loading: meLoading };
};

export default useFeatureToggle;
