import React from 'react';

import { Transition } from '@headlessui/react';
import { FormProvider } from 'react-hook-form';

import { Select, TextField } from 'components/FormFields/v2';
import useUpdateAddress from './hooks/useUpdateAddress';
import { FormFooter } from 'components/UI';

const UpdateCardDeliveryAddress: React.FC = () => {
  const {
    selectedCard,
    form,
    goToConfirmSelectionStep,
    onSubmit,
    register,
    countryOptions,
    countrySubdivisions,
    streetRef,
  } = useUpdateAddress();

  return (
    <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-flex-grow tw-items-center">
      <div className="tw-flex tw-flex-col tw-gap-4 tw-py-4 tw-w-full lg:tw-w-3/5 xl:tw-w-2/3 tw-max-w-screen-lg tw-mx-auto tw-px-4 lg:tw-px-0">
        <strong>{selectedCard?.nickName || 'All Cards'}</strong>
        <span className="tw-text-primary-dark-green">Updated Delivery Address:</span>
        <Transition
          show={true}
          enter="tw-transition-opacity tw-duration-75"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-transition-opacity tw-duration-150"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <FormProvider {...form}>
            <form className="tw-px-2 tw-mr-12 tw-my-4">
              <div className="tw-container">
                <div className="tw-flex tw-mt-4">
                  <Select
                    name="address.country"
                    label="Country"
                    placeholder="Select Country"
                    rootClass="tw-w-1/2 tw-mr-2"
                    ref={register({ required: true })}
                    options={countryOptions()}
                    required
                  />
                </div>
                <div className="tw-flex tw-mt-4">
                  <TextField
                    name="address.street"
                    data-testid="address.street"
                    label="Business Street Address"
                    placeholder="Business Street Address"
                    rootClass="tw-w-1/2 tw-mr-2"
                    ref={(e) => {
                      streetRef.current = e;
                      register({ required: true })(e);
                    }}
                    required
                  />
                  <TextField
                    name="address.unitNumber"
                    data-testid="address.unitNumber"
                    label="Address Line 2 (Suite, Apartment, etc.)"
                    placeholder="Unit Number"
                    rootClass="tw-w-1/2"
                    ref={register({ required: true })}
                    required
                  />
                </div>
                <div className="tw-flex tw-gap-1 tw-mt-4">
                  <div className="tw-w-full tw-flex">
                    <Select
                      name="address.countrySubdivision"
                      label="Province"
                      placeholder="Province"
                      rootClass="tw-w-1/3 "
                      ref={register({ required: true })}
                      options={countrySubdivisions}
                      required
                    />
                    <TextField
                      name="address.city"
                      data-testid="address.city"
                      label="City"
                      rootClass="tw-mx-6 tw-w-1/3"
                      placeholder="Toronto"
                      ref={register({ required: true })}
                      required
                    />
                    <TextField
                      name="address.postalCode"
                      data-testid="address.postalCode"
                      label="Postal Code"
                      rootClass="tw-w-1/3"
                      placeholder="M5V1S8"
                      ref={register({ required: true })}
                      required
                    />
                  </div>
                </div>
              </div>
            </form>
          </FormProvider>
        </Transition>
      </div>
      <div className="tw-w-full tw-border-b tw-border-neutral-grey-4 tw-bg-neutral-grey-4.5 tw-bottom-0 tw-rounded-b-md tw-flex tw-justify-center">
        <FormFooter
          onCancel={goToConfirmSelectionStep}
          cancelButtonLabel="Go Back"
          onSubmit={onSubmit}
          submitButtonLabel="Update"
          rootClass="tw-w-2/3 tw-py-6 tw-flex tw-justify-between"
        />
      </div>
    </div>
  );
};

export default UpdateCardDeliveryAddress;
