import React from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import { SCOPE } from 'constants/index';
import useHasPermission from 'hooks/useHasPermission';
import useIsMobile from 'hooks/useIsMobile';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import CashOutline from 'components/svg/CashOutline';

const PaymentsSplash = () => {
  const isMobile = useIsMobile();
  const { hasPermission: canManagePayments } = useHasPermission({ scope: SCOPE.managePayments });

  const history = useHistory();
  if (!isMobile) history.push('/dashboard/payments');

  return (
    <DashboardLayout title="Payments">
      <div className="tw-flex tw-flex-col tw-px-4 tw-space-y-8">
        <h2>Payments</h2>
        <div className="tw-space-y-4">
          <NavigationItem
            Icon={FiUsers}
            iconColor="tw-text-primary-lilac"
            iconBgColor="tw-bg-secondary-light-lilac"
            title="Manage & Pay Payee"
            route="/dashboard/payments/payees"
          />
          {canManagePayments && (
            <NavigationItem
              Icon={CashOutline}
              iconColor="tw-text-primary-blue"
              iconBgColor="tw-bg-secondary-light-blue"
              title="Transfer Between Accounts"
              route="/dashboard/payments"
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

const NavigationItem = ({ Icon, title, route, iconColor, iconBgColor }) => (
  <Link
    to={route}
    className="tw-flex tw-items-center tw-justify-between tw-rounded-md tw-bg-neutral-light tw-px-4 tw-py-5"
  >
    <div className="tw-flex tw-justify-start tw-items-center">
      <div className={`tw-flex tw-justify-center tw-items-center ${iconBgColor} tw-rounded tw-py-0.5 tw-px-1 tw-mr-4`}>
        <Icon className={`${iconColor} tw-h-6`} />
      </div>
      <div className="tw-flex tw-flex-col tw-justify-between">
        <strong>{title}</strong>
      </div>
    </div>
    <BsChevronRight />
  </Link>
);

export default PaymentsSplash;
