import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_CREDIT_LIMIT } from 'graphql/cards';
import { ACCOUNT_STATE } from 'graphql/wallets';
import { GET_BANK_ACCOUNTS } from 'graphql/integrations';
import { KYC_STATUS, CREDIT_CARD_EMPTY_STATES } from 'constants/index';

export const getAccountState = ({
  connectedBankAccount,
  hasAtLeastOneBankAccountVerified,
  kycAssessment,
  transactions,
  loadingCreditCardLimit,
  hasLineOfCredit,
  creditCards,
  creditCardsIds,
  creditCardLoading,
  creditCardIdLoading,
  loadingTransactions,
}) => {
  const hasCreditCards = creditCards > 0;
  const isExistingLocUser = hasLineOfCredit && !hasCreditCards;
  const KYCNotApproved = kycAssessment !== KYC_STATUS.APPROVED;
  const hasCreditCardIds = creditCardsIds && creditCardsIds.length > 0;

  const loadingCards = creditCardLoading || creditCardIdLoading;
  if (loadingTransactions) return 'loading';

  // Transactions Page
  if (transactions && transactions.length >= 1) return 'hasTransactions';

  if (!connectedBankAccount && !loadingCreditCardLimit) {
    return isExistingLocUser
      ? CREDIT_CARD_EMPTY_STATES.connectBankOrContactLoopAsLOCUser
      : CREDIT_CARD_EMPTY_STATES.connectBankOrContactLoop;
  }

  // Both Cards and Transactions Page
  if ((connectedBankAccount && hasAtLeastOneBankAccountVerified && kycAssessment && KYCNotApproved) || KYCNotApproved) {
    return CREDIT_CARD_EMPTY_STATES.reviewInProgress;
  }

  if (
    (connectedBankAccount && !hasAtLeastOneBankAccountVerified && transactions && transactions.length === 0) ||
    (connectedBankAccount && !hasAtLeastOneBankAccountVerified)
  ) {
    return CREDIT_CARD_EMPTY_STATES.waitingBankAccountVerification;
  }

  // Cards Page
  if (!loadingCards && hasCreditCardIds && !hasCreditCards) return CREDIT_CARD_EMPTY_STATES.errorFetchingCards;

  if (!connectedBankAccount || (isExistingLocUser && !hasCreditCards)) {
    return CREDIT_CARD_EMPTY_STATES.isExistingLocUser
      ? CREDIT_CARD_EMPTY_STATES.connectBankOrContactLoopAsLOCUser
      : CREDIT_CARD_EMPTY_STATES.connectBankOrContactLoop;
  }

  if (!KYCNotApproved && connectedBankAccount && hasAtLeastOneBankAccountVerified && !hasCreditCards) {
    return CREDIT_CARD_EMPTY_STATES.createCreditCard;
  }
};

export const getAccountStateWithQueries = ({ loadingTransactions, transactions } = { loadingTransactions: false }) => {
  const { data: accountStateData, loading: loadingProducts } = useQuery(ACCOUNT_STATE);
  const { data: creditLimitData, loading: loadingCreditCardLimit } = useQuery(GET_CREDIT_LIMIT);
  const { data: bankAccountsData, loadingBankAccountsData } = useQuery(GET_BANK_ACCOUNTS);

  const creditCardsCount = get(accountStateData, 'creditCardsCount');
  const kycAssessment = get(creditLimitData, 'me.account.kycAssessment.status');
  const hasExpressApplication = get(creditLimitData, 'me.account.hasExpressApplication', false);
  const globalCreditLimit = get(creditLimitData, 'me.account.globalCreditLimit');
  const bankAccounts = get(bankAccountsData, 'bankInfo.bankAccounts') || [];
  const connectedBankAccount = get(creditLimitData, 'me.account.bankAccount.connected', false);

  const hasLineOfCredit = get(accountStateData, 'hasLineOfCredit');
  const hasAtLeastOneBankAccountVerified = bankAccounts.some((ba) => ba.verified);
  const hasCredit = hasExpressApplication && globalCreditLimit;

  // no passing transactions on purpose. this never returns 'loading'
  return {
    accountState: getAccountState({
      connectedBankAccount,
      hasAtLeastOneBankAccountVerified,
      kycAssessment,
      hasCredit,
      hasLineOfCredit,
      creditCards: creditCardsCount,
      loadingCreditCardLimit,
      loadingTransactions,
      transactions,
    }),
    loadingProducts,
    loadingCreditCardLimit,
    loadingBankAccountsData,
  };
};
