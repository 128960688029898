import React from 'react';
import { FormProvider } from 'react-hook-form';

import { EMAIL_REGEX } from 'constants/regex';
import { TextField } from 'components/FormFields/v2';
import { StepsProps } from '../../Steps.types';
import { FormFooter } from '..';
import { usePayorDetails } from './hooks';

const PayorDetails = ({ onNextStep, onFinish }: Pick<StepsProps, 'onNextStep' | 'onFinish'>) => {
  const { form, register, handleOnNextStep, handleSubmit, watchEmail, error } = usePayorDetails({ onNextStep });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleOnNextStep)}>
        <div className="tw-px-8 tw-py-8 tw-space-y-8">
          <strong>Please enter new payor details</strong>
          <TextField
            name="name"
            label="Payor Legal Name"
            placeholder="Customer contact name"
            ref={register({ required: true })}
            required
          />
          <TextField
            name="email"
            label="Email Address"
            placeholder="Customer contact email address"
            ref={register({ required: true, pattern: { value: EMAIL_REGEX, message: 'Invalid Email' } })}
            value={watchEmail}
            required
          />
          <TextField
            name="referenceId"
            label="Payor Nickname ID"
            placeholder="(Optional) Unique customer ID for future reconciliation"
            ref={register()}
          />

          {error?.message && <div className="tw-my-8 tw-text-semantic-error tw-text-xs">{error.message}</div>}
        </div>
        <FormFooter onCancel={onFinish} submitLabel="Next" />
      </form>
    </FormProvider>
  );
};

export default PayorDetails;
