import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const PersonOutline = ({ width = '21', height = '17', ...other }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M11.0939 4.5625C10.9561 6.42121 9.54703 7.9375 8.00015 7.9375C6.45328 7.9375 5.04175 6.42156 4.9064 4.5625C4.76578 2.62891 6.13687 1.1875 8.00015 1.1875C9.86343 1.1875 11.2345 2.66406 11.0939 4.5625Z"
        stroke={primaryDarkGreenColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00009 10.1875C4.94149 10.1875 1.83719 11.875 1.26274 15.0602C1.19348 15.4441 1.41075 15.8125 1.81259 15.8125H14.1876C14.5898 15.8125 14.807 15.4441 14.7378 15.0602C14.163 11.875 11.0587 10.1875 8.00009 10.1875Z"
        stroke={primaryDarkGreenColor}
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default PersonOutline;
