import React from 'react';

import Button from 'components/Button';
import LoopLogo from 'components/svg/LoopLogo';
import { IntroProps } from './Intro.types';

const Intro = ({ onNextStep }: IntroProps) => {
  return (
    <div>
      <div className="tw-px-8 tw-mt-16">
        <div className="tw-flex tw-justify-center tw-mb-10">
          <LoopLogo width="130" height="46" />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-6 tw-mb-12">
          <p>
            We need you to take a moment to confirm a few details about your business and the activity in your US bank
            account. Updating this information is a requirement to continue using your Loop US Dollar Bank account.
          </p>
          <p>As part of the change, you will be required to confirm:</p>
          <ul className="tw-list-disc tw-list-inside">
            <li>Industry</li>
            <li>Transaction Volumes</li>
          </ul>
          <p>It should take less than 2 minutes to ensure your information is up-to-date.</p>
        </div>
      </div>

      <div className="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-4 tw-flex tw-justify-end">
        <Button primary onClick={onNextStep}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default Intro;
