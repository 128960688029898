import { useQuery, useMutation } from '@apollo/client';

import { CATEGORIES_ICONS } from 'constants/categories';
import { GET_TRANSACTION_CATEGORIES, UPDATE_TRANSACTION_CATEGORY } from 'graphql/transactionCategories';
import { GET_TRANSACTIONS_PAGE } from 'graphql/transactions';

export const useTransactionCategories = () => {
  const { loading, data, error } = useQuery(GET_TRANSACTION_CATEGORIES);
  const { transactionCategories = [] } = data || {};

  const [updateTransactionCategory, { loading: isUpdatingTransactionCategory }] = useMutation(
    UPDATE_TRANSACTION_CATEGORY,
    {
      refetchQueries: [GET_TRANSACTIONS_PAGE, GET_TRANSACTION_CATEGORIES],
    }
  );

  const getIcon = (loopId) => CATEGORIES_ICONS[loopId] || CATEGORIES_ICONS[0];

  return {
    transactionCategories,
    loading,
    error,
    defaultCategory: transactionCategories && transactionCategories.find((cat) => cat.loopId === '0'),
    getIcon,
    updateTransactionCategory,
    isUpdatingTransactionCategory,
  };
};
