import React from 'react';
import cx from 'classnames';
import { Disclosure, Transition, Switch } from '@headlessui/react';
import { IoCaretDownCircleOutline } from 'react-icons/io5';
import { PermissionToggleSectionType } from './PermissionToggleSection.types';
import usePermissionToggleSection from './hooks/usePermissionToggleSection';

export const PermissionToggleSection = ({
  sectionTitle,
  permissions,
  setSelectedPermissions,
  isPermissionChecked,
  selectedPermissions,
  isTitleChecked,
  setIsTitleChecked,
}: PermissionToggleSectionType) => {
  const { isSectionChecked, onSectionToggle, togglePermission, selectAllTitle } = usePermissionToggleSection({
    permissions,
    isTitleChecked,
    setIsTitleChecked,
    setSelectedPermissions,
    isPermissionChecked,
    selectedPermissions,
  });
  return (
    <div>
      <Disclosure as="div">
        {({ open }) => (
          <>
            <Disclosure.Button className="tw-flex tw-justify-between tw-items-center tw-w-full tw-text-sm tw-py-2 tw-text-neutral-grey-1">
              <strong>{sectionTitle}</strong>
              <IoCaretDownCircleOutline
                size={24}
                className={cx(
                  'tw-text-primary-dark-green tw-transition tw-duration-100 tw-ease-out tw-transform',
                  open ? 'tw-rotate-180' : ''
                )}
              />
            </Disclosure.Button>
            <Transition
              show={open}
              enter="tw-transition tw-duration-100 tw-ease-out"
              enterFrom="tw-transform tw-scale-95 tw-opacity-0"
              enterTo="tw-transform tw-scale-100 tw-opacity-100"
              leave="tw-transition tw-duration-75 tw-ease-out"
              leaveFrom="tw-transform tw-scale-100 tw-opacity-100"
              leaveTo="tw-transform tw-scale-95 tw-opacity-0"
            >
              <div className="tw-pl-4">
                <Disclosure.Panel unmount>
                  <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-pt-4 tw-pb-2">
                    <strong>{selectAllTitle}</strong>
                    <Switch
                      checked={isSectionChecked()}
                      onChange={onSectionToggle}
                      name={sectionTitle}
                      className={cx(
                        'tw-flex tw-items-center tw-bg-neutral-light tw-border tw-relative tw-inline-flex tw-h-4 tw-w-7 tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus-visible:tw-ring-2  focus-visible:tw-ring-neutral-light focus-visible:tw-ring-opacity-75',
                        isSectionChecked() ? 'tw-border-primary-dark-green tw-fill-current' : 'tw-border-neutral-grey-3'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={cx(
                          'tw-pointer-events-none tw-inline-block tw-h-4 tw-w-4 tw-rounded-full tw-shadow-lg tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200',
                          isSectionChecked()
                            ? 'tw-translate-x-3 tw-bg-primary-dark-green'
                            : 'tw-translate-x-0 tw-bg-neutral-grey-3'
                        )}
                      />
                    </Switch>
                  </div>
                  {permissions.map((permission) =>
                    permission.label !== selectAllTitle ? (
                      <div
                        key={permission.value}
                        className="tw-flex tw-flex-row tw-content-center tw-items-center tw-justify-between tw-py-3"
                      >
                        <p>{permission.label}</p>
                        <Switch
                          checked={isPermissionChecked(permission.value)}
                          onChange={() => togglePermission(permission.value)}
                          name={permission.label}
                          className={cx(
                            'tw-flex tw-items-center tw-bg-neutral-light tw-border tw-relative tw-inline-flex tw-h-4 tw-w-7 tw-rounded-full tw-cursor-pointer tw-transition-colors tw-ease-in-out tw-duration-200 focus:tw-outline-none focus-visible:tw-ring-2  focus-visible:tw-ring-neutral-light focus-visible:tw-ring-opacity-75',
                            isPermissionChecked(permission.value)
                              ? 'tw-border-primary-dark-green tw-fill-current'
                              : 'tw-border-neutral-grey-3'
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={cx(
                              'tw-pointer-events-none tw-inline-block tw-h-4 tw-w-4 tw-rounded-full tw-shadow-lg tw-transform tw-ring-0 tw-transition tw-ease-in-out tw-duration-200',
                              isPermissionChecked(permission.value)
                                ? 'tw-translate-x-3 tw-bg-primary-dark-green'
                                : 'tw-translate-x-0 tw-bg-neutral-grey-3'
                            )}
                          />
                        </Switch>
                      </div>
                    ) : (
                      <></>
                    )
                  )}
                </Disclosure.Panel>
              </div>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};
