export const ACCOUNT_BALANCES = [
  {
    amount: 0,
    currency: 'CAD',
  },
  {
    amount: 0,
    currency: 'USD',
  },
  {
    amount: 0,
    currency: 'EUR',
  },
  {
    amount: 0,
    currency: 'GBP',
  },
];
