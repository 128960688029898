import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { ampTrackEvent } from 'amplitude';
import qs from 'query-string';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';

import history from 'history.js';
import { CREATE_PAYOR_FLINKS_CONNECTION } from 'graphql/flinks';
import { GET_PAYOR_BANK_ACCOUNTS } from 'graphql/invoicing';
import { decryptString } from 'utility/string';
import Layout from 'components/onboarding/Layout';
import waitingRoomJson from 'components/lottie/waitingRoom.json';

const BANK_ACCOUNT_REFETCHING_INTERVAL = 3 * 1000;
const BANK_ACCOUNT_REFETCHING_TIMER = 2 * 60 * 1000;

const FlinksConnection = () => {
  const [isConnectionProcessing, setIsConnectionProcessing] = useState(false);
  const [isConnectionReady, setIsConnectionReady] = useState(false);
  const [isConnectionTimeoutReached, setIsConnectionTimeoutReached] = useState(false);
  const [error, setError] = useState();

  const processingToast = useRef(null);

  const [createPayorFlinksConnection] = useMutation(CREATE_PAYOR_FLINKS_CONNECTION);

  const [getPayorBankAccounts, { loading: isLoadingBankAccounts }] = useLazyQuery(GET_PAYOR_BANK_ACCOUNTS, {
    fetchPolicy: 'network-only',
  });

  const { accountId, loginId, redirectUrl: encryptedRedirectUrl } = qs.parse(location.search);

  const redirectUrl = useMemo(
    () => encryptedRedirectUrl && decryptString(encryptedRedirectUrl),
    [encryptedRedirectUrl]
  );

  const redirectUrlConnector = redirectUrl?.includes('?') ? '&' : '?';

  const { country } = qs.parseUrl(redirectUrl)?.query;

  const fetchPayorBankAccountsConnectionData = useCallback(async () => {
    if (isLoadingBankAccounts) return;

    try {
      const response = await getPayorBankAccounts();

      const isConnected = get(response, 'data.payorBankAccounts.connectionsReady', false);

      setIsConnectionReady(isConnected);
    } catch (e) {
      setIsConnectionReady(false);
      setError(e);
    }
  }, [getPayorBankAccounts, isLoadingBankAccounts]);

  const createBankAccountConnection = useCallback(async () => {
    processingToast.current = toast.loading('Establishing bank account connection...');

    try {
      if (!country) throw new Error('Could not create Flinks connection. Country is missing');

      const response = await createPayorFlinksConnection({
        variables: { accountId, loginId, country, enqueueDataPull: true },
      });

      if (!response?.data?.createPayorFlinksConnection) throw new Error('Could not create Flinks connection');

      setIsConnectionProcessing(true);
    } catch (e) {
      setIsConnectionProcessing(false);
      setError(e);
    }
  }, [createPayorFlinksConnection, accountId, loginId, country]);

  // refetching bank accounts data
  useEffect(() => {
    if (!isConnectionProcessing) return;

    const connectionInterval = setInterval(async () => {
      await fetchPayorBankAccountsConnectionData();
    }, BANK_ACCOUNT_REFETCHING_INTERVAL);

    return () => clearInterval(connectionInterval);
  }, [fetchPayorBankAccountsConnectionData, isConnectionProcessing]);

  useEffect(() => {
    const connectionTimer = setTimeout(async () => {
      setIsConnectionTimeoutReached(true);
    }, BANK_ACCOUNT_REFETCHING_TIMER);

    return () => clearTimeout(connectionTimer);
  }, []);

  useEffect(() => {
    createBankAccountConnection();
  }, [createBankAccountConnection]);

  // unhappy path
  useEffect(() => {
    if (!error) return;

    ampTrackEvent('createPayorFlinksConnection: error');

    console.error(error);

    toast.update(processingToast.current, {
      render: 'Something went wrong. Please try again.',
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 3000,
    });

    history.replace(`${redirectUrl}${redirectUrlConnector}failed=Flinks`);
  }, [error, redirectUrl, redirectUrlConnector]);

  // happy path
  useEffect(() => {
    if (!isConnectionReady) return;

    ampTrackEvent('createPayorFlinksConnection: submit: success');

    toast.update(processingToast.current, {
      render: 'Bank account connection has been established.',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 3000,
    });

    history.replace(`${redirectUrl}${redirectUrlConnector}success=Flinks`);
  }, [isConnectionReady, redirectUrl, redirectUrlConnector]);

  // reaching timeout
  useEffect(() => {
    if (!isConnectionTimeoutReached) return;

    ampTrackEvent('createPayorFlinksConnection: error');

    toast.update(processingToast.current, {
      render: 'Unfortunately, we are having trouble retrieving your banking details to complete this payment request.',
      type: toast.TYPE.WARNING,
      isLoading: false,
      autoClose: 3000,
    });

    history.replace(`${redirectUrl}${redirectUrlConnector}warning=Flinks`);
  }, [isConnectionTimeoutReached, redirectUrl, redirectUrlConnector]);

  return (
    <Layout>
      <div className="tw-w-full lg:tw-w-2/5 tw-mt-16 tw-flex tw-flex-col tw-items-center tw-flex-grow">
        <Lottie loop animationData={waitingRoomJson} play style={{ width: 150, height: 150 }} />
        <h1 className="tw-mb-4">Connecting the bank account...</h1>
        <p>
          We are retrieving the details of the selected bank account. This may take up to 2 minutes as we establish the
          connection with your bank. Please do not close this browser window.
        </p>
      </div>
    </Layout>
  );
};

export default FlinksConnection;
