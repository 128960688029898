import React, { useContext, useState } from 'react';

import Modal from 'components/Modal/v2';
import useFeatureToggle from 'hooks/useFeatureToggle';
import ConnectShopifyCard from 'components/cards/ConnectShopify';
import ConnectWooCommerceCard from 'components/cards/ConnectWooCommerce';
import Close from 'components/svg/Close';
import { salesChannels } from 'components/settings/Integrations/components/Modals/constants';
import { SettingsContext } from 'context/Settings';
import styles from 'components/settings/Integrations/components/Modals/Modals.module.scss';

const IntegrationsModal = (props) => {
  const { show, onClose } = props;
  const [integration, setIntegration] = useState();

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-flex-col tw-justify-center tw-py-4">
        <Content {...props} integration={integration} setIntegration={setIntegration} />
      </div>
    </Modal>
  );
};

const Content = (props) => {
  const { onClose, integration, setIntegration } = props;
  const { isExperimentEnabled } = useFeatureToggle();

  const handleClose = () => {
    setIntegration(null);
    onClose();
  };

  switch (integration) {
    case 'Shopify':
      return <ConnectShopifyCard onCancel={handleClose} onSuccess={handleClose} layout="V2" />;
    case 'WooCommerce':
      return <ConnectWooCommerceCard onCancel={handleClose} onSuccess={handleClose} layout="V2" />;
    default:
      return <IntegrationsSelector {...props} isExperimentEnabled={isExperimentEnabled} />;
  }
};

const IntegrationsSelector = ({ setIntegration, onClose }) => {
  const { refetchIntegrationsRef } = useContext(SettingsContext);

  return (
    <>
      <div className="tw-flex tw-flex-row tw-justify-between tw-px-8 tw-py-4 tw-mb-2 tw-border-b tw-border-neutral-grey-4">
        <b>Add Integration</b>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-px-8 tw-pb-4">
        <h5 className="tw-mb-6">Increase your chances of raising your limit</h5>
        <p className="tw-mb-6 tw-text-sm">
          Your credit limit is based off of your sales processed through sales channels. Connecting more sales channels
          increase your credit limit over time.
        </p>
        <p className="tw-mb-8 tw-text-sm">Connect another sales channel.</p>
        <div className="channel-integrations tw-grid tw-grid-cols-2 tw-flow-rows tw-gap-4">
          {salesChannels(refetchIntegrationsRef.current).map((channel) => {
            return channel.testId === 'rutter-buttons' ? (
              channel.component
            ) : (
              <div
                key={channel.name}
                data-testid={channel.testId}
                className={`tw-bg-neutral-grey-4 tw-rounded-md tw-flex tw-items-center tw-justify-center ${styles.salesChannel} tw-cursor-pointer`}
              >
                <div className="tw-flex tw-items-center tw-justify-center" onClick={() => setIntegration(channel.name)}>
                  {channel.component}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default IntegrationsModal;
