import React from 'react';

type RequireActivationProps = {
  width?: number;
  height?: number;
};

const RequireActivation = ({ width = 64, height = 64 }: RequireActivationProps) => (
  <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="#8F5CCF" />
    <g clipPath="url(#clip0_18325_58945)">
      <path
        d="M24.2499 43.6673V37.834M24.2499 26.1673V20.334M21.3333 23.2507H27.1666M21.3333 40.7507H27.1666M34.1666 21.5007L32.1434 26.761C31.8144 27.6164 31.6499 28.0441 31.394 28.4039C31.1673 28.7228 30.8887 29.0014 30.5699 29.2281C30.2101 29.4839 29.7824 29.6484 28.9269 29.9774L23.6666 32.0007L28.9269 34.0239C29.7824 34.3529 30.2101 34.5174 30.5699 34.7732C30.8887 34.9999 31.1673 35.2785 31.394 35.5974C31.6499 35.9572 31.8144 36.3849 32.1434 37.2403L34.1666 42.5007L36.1898 37.2403C36.5188 36.3849 36.6833 35.9572 36.9391 35.5974C37.1659 35.2785 37.4445 34.9999 37.7633 34.7732C38.1231 34.5174 38.5508 34.3529 39.4062 34.0239L44.6666 32.0007L39.4062 29.9774C38.5508 29.6484 38.1231 29.4839 37.7633 29.2281C37.4445 29.0014 37.1659 28.7228 36.9391 28.4039C36.6833 28.0441 36.5188 27.6164 36.1898 26.761L34.1666 21.5007Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_18325_58945">
        <rect width="28" height="28" fill="white" transform="translate(19 18)" />
      </clipPath>
    </defs>
  </svg>
);

export default RequireActivation;
