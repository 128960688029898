import { useCallback } from 'react';
import { useApolloClient, DocumentNode, StoreObject, Reference } from '@apollo/client';

type UpdateApolloCachePayload = {
  id: string;
  __typename: string;
  [key: string]: unknown;
};

type UseUpdateApolloCache = {
  entity: StoreObject | Reference;
  fragment: DocumentNode;
  data: UpdateApolloCachePayload;
};

const useUpdateApolloCache = () => {
  const client = useApolloClient();

  const updateCache = useCallback(
    ({ entity, fragment, data }: UseUpdateApolloCache) => {
      const cacheId = client.cache.identify(entity);

      client.writeFragment({
        id: cacheId,
        fragment,
        data,
      });
    },
    [client]
  );

  return {
    updateCache,
  };
};

export default useUpdateApolloCache;
