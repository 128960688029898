import React, { useContext } from 'react';

import { formatTransactionName, mapTransactionsByDate } from 'utility/transactions';
import { formatDateTime } from 'utility/date';
import { PointsTransactionsContext } from 'context/PointsTransactions';
import { TableSectionProps } from '../../PointsTransaction.types';
import { PointsTransaction } from 'types/transactions';

const TransactionsByDate = () => {
  const { transactions } = useContext(PointsTransactionsContext);

  const transactionsByDate = mapTransactionsByDate(transactions);
  const today = formatDateTime(new Date());

  return (
    <div className="tw-p-4 tw-rounded-md tw-bg-neutral-light">
      {Object.keys(transactionsByDate).map((date) => (
        <TransactionsForDate key={date} transactions={transactionsByDate[date]} date={date} isToday={today === date} />
      ))}
    </div>
  );
};

const TransactionsForDate = ({ date, transactions, isToday }: TableSectionProps) => (
  <div key={date} className="tw-w-full tw-flex-grow tw-mb-2">
    <div className="tw-text-sm tw-text-neutral-grey-2 tw-mb-4">{isToday ? 'Today' : date}</div>
    {transactions.map((transaction, i) => (
      <TransactionEntry key={i} transaction={transaction} />
    ))}
  </div>
);

const TransactionEntry = ({ transaction }: { transaction: PointsTransaction }) => {
  const isPositive = transaction.points >= 0;

  return (
    <div className="tw-flex tw-items-start tw-space-between tw-py-2 tw-flex-grow">
      <div className="tw-flex tw-items-start">
        <div>
          <div className="tw-flex">
            <div className="tw-text-sm">{formatTransactionName(transaction.label)}</div>
          </div>
        </div>
      </div>
      <div
        className={`tw-flex-grow tw-flex tw-justify-end ${
          isPositive ? 'tw-text-semantic-success' : 'tw-text-semantic-error'
        }`}
      >
        <div className="tw-text-sm tw-font-semibold">{transaction.points}</div>
      </div>
    </div>
  );
};

export default TransactionsByDate;
