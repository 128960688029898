import { PaymentRequestStatus } from 'types/invoicing';

export const getRequestStatusDescription = (status: PaymentRequestStatus) => {
  switch (status) {
    case PaymentRequestStatus.pending:
      return 'Payment request from your vendor partner is waiting for your acceptance. Please review and approve.';
    case PaymentRequestStatus.scheduled:
      return 'Payment will be drawn from your bank account on the scheduled date. Your vendor partner will receive the funds 4-5 business days after.';
    case PaymentRequestStatus.rejected:
      return 'You have rejected this payment request from your vendor partner. To process this payment, please have your vendor partner initiate a new payment request with Loop.';
    case PaymentRequestStatus.completed:
      return 'Payment request has been completed successfully and funds have been debited from your connected bank account.';
    case PaymentRequestStatus.settled:
      return 'Payment request has been completed successfully and funds have been debited from your connected bank account.';
    case PaymentRequestStatus.failed:
      return 'Attempt to retrieve funds from your connected bank account on the scheduled date has failed. To retry this transaction, please confirm funds availability in your bank account and have your vendor partner initiate a new payment request with Loop.';
    case PaymentRequestStatus.cancelled:
      return 'Payment request has been cancelled. To process this payment, please have your vendor partner initiate a new payment request with Loop.';
    case PaymentRequestStatus.expired:
      return 'Payment request is past the due date and has expired. To process this payment via Loop, please have your vendor partner initiate a new payment request with Loop.';
    case PaymentRequestStatus.held:
    case PaymentRequestStatus.flagged_for_review:
    case PaymentRequestStatus.processing:
      return 'Payment withdrawal request has been initiated with your bank. If processed successfully, your bank account should be debited in the next 1-2 business days and your vendor partner can expect to receive the payment in their account in the next 4-5 business days.';
    case PaymentRequestStatus.overdue:
      return 'Payment request is past the due date and has been marked as overdue. To process this payment via Loop, please ensure your account is connected and immediately confirm the payment.';
    default:
      return '';
  }
};
