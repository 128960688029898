import { CreditCardBalancesQueryResponse, PreFundedCardBalancesQueryResponse, CardBalances } from 'types/creditCard';
import { DEFAULT_CARD_BALANCES } from './constants';

export const transformCardBalances = ({
  balances,
  isPreFunded,
}: {
  balances?: CreditCardBalancesQueryResponse | PreFundedCardBalancesQueryResponse;
  isPreFunded?: boolean;
}): CardBalances => {
  if (isPreFunded) {
    const { groupBalances, groupOngoingPayments } = (balances as PreFundedCardBalancesQueryResponse) || {};

    return {
      groupBalances: groupBalances ? groupBalances : DEFAULT_CARD_BALANCES.groupBalances,
      groupOngoingPayments: groupOngoingPayments ? groupOngoingPayments : DEFAULT_CARD_BALANCES.groupOngoingPayments,
    };
  }

  const { billingCycle, groupBalances, groupPendingBalances, groupPostedBalances } =
    (balances as CreditCardBalancesQueryResponse) || {};

  return {
    billingCycle,
    groupBalances: groupBalances ? groupBalances : DEFAULT_CARD_BALANCES.groupBalances,
    groupPendingBalances: groupPendingBalances ? groupPendingBalances : DEFAULT_CARD_BALANCES.groupPendingBalances,
    groupPostedBalances: groupPostedBalances ? groupPostedBalances : DEFAULT_CARD_BALANCES.groupPostedBalances,
  };
};
