import React from 'react';

import { useTransactionsContent } from './hooks';
import { TableSkeleton, PaginationSkeleton, MobileTableSkeleton } from '../Skeletons';
import { TransactionsTable } from '../..';
import { PaginationControl } from 'components/UI';
import { Filter } from '../desktop';
import { MobileFilter, TransactionsByDate } from '../mobile';

const TransactionsContent = () => {
  const { totalCount, page, numPerPage, setPage, setNumPerPage, isMobile, isLoading, hasTransactions } =
    useTransactionsContent();

  return (
    <div className={`tw-flex tw-flex-col tw-w-full tw-pb-16`}>
      {isMobile ? <MobileFilter /> : <Filter className="tw-my-4" />}

      {isLoading && (
        <>
          {isMobile ? <MobileTableSkeleton /> : <TableSkeleton />}
          <PaginationSkeleton />
        </>
      )}

      {!isLoading && (isMobile ? <TransactionsByDate /> : <TransactionsTable />)}

      {hasTransactions && (
        <>
          <div className="tw-mt-12 tw-px-2">
            <PaginationControl
              page={page}
              onChangePage={(val) => setPage(val)}
              onChangeNumberPerPage={(perPage) => setNumPerPage(perPage)}
              numPerPage={numPerPage}
              numberOfItems={totalCount}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TransactionsContent;
