import React, { useContext } from 'react';
import { BiFile, BiHourglass } from 'react-icons/bi';

import { Href } from 'components/UI';
import { CardMigrationContext } from 'components/CardMigrationV2/context/CardMigrationContext';

const ReportSummary = () => {
  const { summaryLoading, summary } = useContext(CardMigrationContext);

  if (summaryLoading) {
    return (
      <div>
        <div className="tw-flex tw-text-primary-dark-green tw-items-center">
          <span className="tw-pr-2">
            <BiHourglass />
          </span>
          Your Merchant Report is being generated...
        </div>
        <div className="tw-flex tw-text-primary-dark-green tw-items-center">
          <span className="tw-pr-2">
            <BiHourglass />
          </span>
          Your Card Migration Summary is being generated...
        </div>
      </div>
    );
  }

  return (
    <div>
      {summary.map((entry, idx) => (
        <div key={idx} className="tw-flex tw-text-primary-dark-green tw-items-center">
          <span className="tw-pr-2">
            <BiFile />
          </span>
          <Href href={entry.url} target="_blank" rel="noopener noreferrer">
            {entry.type === 'merchant-report' ? 'Loop Merchant Report' : 'Card Migration Summary Report'}
          </Href>
        </div>
      ))}
    </div>
  );
};

export default ReportSummary;
