import { GroupOngoingAmount } from 'types/creditCard';

export enum StatementStatus {
  FAILED = 'FAILED',
  MINIMUM_PAID = 'MINIMUM_PAID',
  MISSED = 'MISSED',
  PAID = 'PAID',
  PROCESSING = 'PROCESSING',
  UNPAID = 'UNPAID',
}

export type Statement = {
  id: string;
  title: string;
  createdAt?: Date;
  dueDate?: Date;
  startDate: Date;
  endDate: Date;
  previousStartDate?: Date;
  previousEndDate?: Date;
  previousBalanceByCurrency?: GroupOngoingAmount;
  endingBalanceByCurrency: GroupOngoingAmount;
  debits: GroupOngoingAmount;
  credits: GroupOngoingAmount;
  payment: {
    status: StatementStatus;
  } | null;
};

export type StatementQueryResponse = {
  statement: Pick<
    Statement,
    | 'id'
    | 'title'
    | 'dueDate'
    | 'startDate'
    | 'previousStartDate'
    | 'previousEndDate'
    | 'previousBalanceByCurrency'
    | 'endingBalanceByCurrency'
    | 'endDate'
    | 'debits'
    | 'credits'
    | 'payment'
  >;
};
