import React from 'react';

import { ChartDatasetItem } from 'components/creditCards/components/CardBalances/components/GlobalLimit/components/GlobalLimitChart/GlobalLimitChart.types';

const Label = ({ label, labelColor }: Pick<ChartDatasetItem, 'label' | 'labelColor'>) => (
  <div
    className="tw-flex tw-items-center tw-h-8 md:tw-h-12 tw-px-4 tw-font-bold tw-truncate"
    style={{ color: labelColor }}
  >
    {label}
  </div>
);

export default Label;
