import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ampTrackEvent } from 'amplitude';

import { UPDATE_CARD_ADDRESS, GET_CREDIT_CARD_BY_ID } from 'graphql/cards';
import { ADDRESS_CATEGORY } from 'constants/index';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import AddressFields from 'components/onboarding/AddressFields';
import { SubmitButton } from 'components/FormFields/v2';
import { Loaders } from 'components/cards/Loader';

const { OA, CA } = ADDRESS_CATEGORY;

const UpdateAddressModal = ({ show, onClose, address, creditCard }) => {
  const [updateCardAddress, { loading }] = useMutation(UPDATE_CARD_ADDRESS, {
    refetchQueries: [{ query: GET_CREDIT_CARD_BY_ID, variables: { creditCardId: creditCard?.id } }],
    awaitRefetchQueries: true,
  });

  const { street, city, countrySubdivision, postalCode, category, unitNumber } = address;

  const currentCountry = category === OA || category === CA ? 'CA' : 'US';

  const onUpdateCardAddress = async (data) => {
    try {
      const { street, city, countrySubdivision, postalCode, unitNumber } = data.address;
      await updateCardAddress({
        variables: {
          creditCardId: creditCard.id,
          address: {
            street,
            city,
            countrySubdivision,
            postalCode,
            category,
            country: currentCountry,
            unitNumber,
          },
        },
      });
      toast.success('Address updated successfully');
      ampTrackEvent('updateCardAddress: success');
    } catch (error) {
      console.error(error);
      toast.error('Unable to update card address - please try again');
      ampTrackEvent('updateCardAddress: error');
    } finally {
      onClose();
    }
  };

  const form = useForm({
    defaultValues: {
      address: {
        street,
        city,
        countrySubdivision,
        postalCode,
        country: currentCountry,
        category,
        unitNumber,
      },
    },
  });
  const { handleSubmit, watch, error } = form;

  return (
    <Modal show={show} onClose={() => {}}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4">
        Update {currentCountry} Address
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-pt-8 tw-pb-4 tw-border-t tw-border-neutral-grey-4">
        {loading ? (
          <div className="tw-flex tw-justify-center tw-items-center tw-h-32">
            <Loaders.Spinner />
          </div>
        ) : (
          <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
            <form onSubmit={handleSubmit(onUpdateCardAddress)}>
              <AddressFields
                name="address"
                streetAddressLabel="Personal Street Address"
                selectedCountry={watch('address.country')}
                rootClass="tw-px-8"
                autocompleteCountryRestrictions={[currentCountry]}
              />
              <div className="tw-flex tw-justify-end tw-px-8 tw-py-4 tw-border-t tw-border-neutral-grey-4">
                <SubmitButton>Update</SubmitButton>
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </Modal>
  );
};

export default UpdateAddressModal;
