import React, { useState, useEffect, useRef } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { BsChevronDown, BsCalendar4 } from 'react-icons/bs';
import { IoRadioButtonOn, IoRadioButtonOff } from 'react-icons/io5';
import { startOfMonth, endOfMonth, subMonths } from 'date-fns';

import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { useToggle } from 'hooks';

const DatePicker = (props) => {
  const { rootClass, className, value, onChange, ...otherProps } = props;
  const calendarRef = useRef();

  const handleDateChange = (date) => {
    onChange(date);
  };

  const onShowCalendar = () => {
    if (calendarRef.current) {
      calendarRef.current.setOpen(true);
    }
  };

  return (
    <div className={`${rootClass}`}>
      <div className="tw-relative">
        <ReactDatePicker
          ref={calendarRef}
          selected={value}
          dateFormat="dd/MM/yyyy"
          showMonthDropdown
          showYearDropdown
          className={`tw-block tw-px-3 tw-py-2 tw-rounded-md tw-w-max tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input ${className}`}
          dropdownMode="select"
          onChange={handleDateChange}
          showPopperArrow={false}
          popperClassName="tw-z-50"
          {...otherProps}
        />
        <div
          onClick={onShowCalendar}
          className="tw-cursor-pointer tw-absolute tw-inset-y-0 tw-right-0 tw-pb-1 tw-pr-3 tw-flex tw-items-center tw-text-sm"
        >
          <BsCalendar4 />
        </div>
      </div>
    </div>
  );
};

export const PeriodDropdown = ({
  label,
  selectedValue,
  setSelectedValue,
  className,
  testId,
  portalId,
  currentBillingCycle,
  previousBillingCycle,
}) => {
  const { isOpen, toggle: toggleOpen } = useToggle();

  const [customPeriod, setCustomPeriod] = useState({
    key: 'Other',
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });

  const periodOptions = getPeriodOptions({ customPeriod, currentBillingCycle, previousBillingCycle });

  const handleChangeFrom = (date) =>
    handleChangeCustomPeriod({
      ...customPeriod,
      from: date,
    });

  const handleChangeTo = (date) =>
    handleChangeCustomPeriod({
      ...customPeriod,
      to: date,
    });
  const handleChangeCustomPeriod = (newPeriod) => {
    setCustomPeriod(newPeriod);
    setSelectedValue(newPeriod);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      const periodModal = document.getElementsByClassName('periodModal');
      const dataPicker = document.getElementsByClassName('react-datepicker');

      if (isOpen && !periodModal[0].contains(e.target)) {
        if (dataPicker[0] && dataPicker[0].contains(e.target)) {
          return;
        }
        toggleOpen();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen, toggleOpen]);

  return (
    <Popover value={selectedValue}>
      {() => (
        <div className={`tw-relative tw-p-2 ${className}`}>
          <Popover.Button>
            <div
              onClick={toggleOpen}
              data-testid={testId}
              className="tw-flex tw-items-center tw-cursor-pointer tw-p-2 tw-rounded-md"
            >
              <div className="tw-text-sm tw-mr-2">
                {label} {selectedValue?.key ? `(${selectedValue.key})` : ''}
              </div>
              <BsChevronDown size={14} className="tw-text-neutral-grey-2" />
            </div>
          </Popover.Button>
          <Transition
            unmount={false}
            show={isOpen}
            as={React.Fragment}
            leave="tw-transition tw-ease-in tw-duration-100"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Popover.Panel
              static
              className="periodModal tw-absolute tw-z-40 tw-w-max tw-py-1 tw-overflow-auto tw-bg-neutral-light tw-rounded-md tw-shadow-notification"
            >
              {periodOptions.map((option, index) => {
                const isSelected = selectedValue?.key === option.key;
                const handleSelectOption = () => {
                  const isSelected = selectedValue?.key === option.key;

                  isSelected ? setSelectedValue(null) : setSelectedValue(option.value);
                };

                return (
                  <div
                    key={option.key}
                    value={option.value}
                    className={`tw-flex tw-items-center tw-p-2 tw-cursor-pointer tw-text-neutral-grey-2 ${
                      index === 0 ? '' : 'tw-border-t tw-border-neutral-grey-5'
                    }`}
                    onClick={handleSelectOption}
                  >
                    {isSelected ? (
                      <IoRadioButtonOn size={18} className="tw-text-primary-dark-green" />
                    ) : (
                      <IoRadioButtonOff size={18} className="tw-text-neutral-grey-2" />
                    )}
                    <div className="tw-text-sm tw-ml-3 tw-text-neutral-dark">{option.key}</div>
                  </div>
                );
              })}
              <div className="tw-mt-2">
                <div className="tw-flex tw-items-center tw-justify-between tw-p-2">
                  <div className="tw-text-sm tw-text-neutral-grey-2 tw-mr-4">From</div>
                  <DatePicker
                    onChange={handleChangeFrom}
                    maxDate={customPeriod.to}
                    value={customPeriod.from}
                    portalId={portalId}
                  />
                </div>
                <div className="tw-flex tw-items-center tw-justify-between tw-p-2">
                  <div className="tw-text-sm tw-text-neutral-grey-2 tw-mr-4">To</div>
                  <DatePicker
                    onChange={handleChangeTo}
                    minDate={customPeriod.from}
                    value={customPeriod.to}
                    portalId={portalId}
                  />
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};

export const getPeriodOptions = ({ customPeriod, currentBillingCycle, previousBillingCycle }) => {
  const options = [
    {
      key: 'Current month',
      value: { key: 'Current month', from: startOfMonth(new Date()), to: endOfMonth(new Date()) },
    },
    {
      key: 'Last month',
      value: {
        key: 'Last month',
        from: startOfMonth(subMonths(new Date(), 1)),
        to: endOfMonth(subMonths(new Date(), 1)),
      },
    },
  ];
  if (currentBillingCycle) {
    options.push({
      key: 'Current billing period',
      value: {
        key: 'Current billing period',
        ...currentBillingCycle,
      },
    });
  }
  if (previousBillingCycle) {
    options.push({
      key: 'Previous billing period',
      value: {
        key: 'Previous billing period',
        ...previousBillingCycle,
      },
    });
  }
  if (customPeriod) {
    options.push({
      key: 'Other',
      value: customPeriod,
    });
  }

  return options;
};
