import React from 'react';

import useIsContactPermission from 'hooks/useIsContactPermission';
import Button, { ButtonSize } from 'components/Button';
import { EmptyStateProps } from './EmptyState.types';

const EmptyState = ({ handleAddPayor }: EmptyStateProps) => {
  const { managePayors: isAllowedToManagePayors } = useIsContactPermission();

  return (
    <div className="tw-flex tw-mt-8">
      <p className="tw-pr-2">You have no payors.</p>
      {isAllowedToManagePayors && (
        <Button tertiary size={ButtonSize.text} onClick={handleAddPayor}>
          Add a Payor
        </Button>
      )}
    </div>
  );
};

export default EmptyState;
