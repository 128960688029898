import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

import { ampTrackEvent } from 'amplitude';
import { registerUtmParameters } from 'utility/utm';
import { SignUpProps, RegistrationType } from '../SignUp.types';
import { getRegistrationPromoText, getRegistrationForm } from '../SignUp.utils';

const useSignUp = ({ type }: SignUpProps) => {
  useEffect(() => {
    if (type !== RegistrationType.USER) return;

    ReactPixel.pageView();

    registerUtmParameters();

    ampTrackEvent('onboarding: signup: page');
  }, [type]);

  const promo = getRegistrationPromoText(type);

  const form = getRegistrationForm(type);

  return {
    promo,
    form,
  };
};

export default useSignUp;
