import { gql } from '@apollo/client';

export const CREATE_XERO_BANK_FEED_CONNECTION = gql`
  mutation CreateXeroBankFeedConnection($currency: String!) {
    createXeroBankFeedConnection(currency: $currency)
  }
`;

export const DELETE_XERO_BANK_FEED_CONNECTION = gql`
  mutation DeleteXeroBankFeedConnection($feedConnectionId: String!) {
    deleteXeroBankFeedConnection(feedConnectionId: $feedConnectionId)
  }
`;

export const GET_XERO_BANK_FEED_CONNECTIONS = gql`
  query XeroBankFeedConnections {
    xeroAccountingBankFeeds {
      feedConnectionId
      currency
      lastSyncedAt
    }
  }
`;
