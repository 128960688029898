import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];
const secondaryPastelGreenColor = theme.colors.secondary['pastel-green'][100];

const Calculator = (props) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="4" y="4" width="48" height="48" rx="24" fill={secondaryPastelGreenColor} />
      <g clipPath="url(#clip0_10098_130572)">
        <path
          d="M33.25 16.25H22.75C21.9216 16.25 21.25 16.9216 21.25 17.75V34.25C21.25 35.0784 21.9216 35.75 22.75 35.75H33.25C34.0784 35.75 34.75 35.0784 34.75 34.25V17.75C34.75 16.9216 34.0784 16.25 33.25 16.25Z"
          stroke={primaryDarkGreenColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.4995 19.25H23.5V22.25H32.4995V19.25Z"
          stroke={primaryDarkGreenColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.875 26.75C24.4963 26.75 25 26.2463 25 25.625C25 25.0037 24.4963 24.5 23.875 24.5C23.2537 24.5 22.75 25.0037 22.75 25.625C22.75 26.2463 23.2537 26.75 23.875 26.75Z"
          fill={primaryDarkGreenColor}
        />
        <path
          d="M28 26.75C28.6213 26.75 29.125 26.2463 29.125 25.625C29.125 25.0037 28.6213 24.5 28 24.5C27.3787 24.5 26.875 25.0037 26.875 25.625C26.875 26.2463 27.3787 26.75 28 26.75Z"
          fill={primaryDarkGreenColor}
        />
        <path
          d="M32.125 26.75C32.7463 26.75 33.25 26.2463 33.25 25.625C33.25 25.0037 32.7463 24.5 32.125 24.5C31.5037 24.5 31 25.0037 31 25.625C31 26.2463 31.5037 26.75 32.125 26.75Z"
          fill={primaryDarkGreenColor}
        />
        <path
          d="M23.875 30.5C24.4963 30.5 25 29.9963 25 29.375C25 28.7537 24.4963 28.25 23.875 28.25C23.2537 28.25 22.75 28.7537 22.75 29.375C22.75 29.9963 23.2537 30.5 23.875 30.5Z"
          fill={primaryDarkGreenColor}
        />
        <path
          d="M28 30.5C28.6213 30.5 29.125 29.9963 29.125 29.375C29.125 28.7537 28.6213 28.25 28 28.25C27.3787 28.25 26.875 28.7537 26.875 29.375C26.875 29.9963 27.3787 30.5 28 30.5Z"
          fill={primaryDarkGreenColor}
        />
        <path
          d="M23.875 34.25C24.4963 34.25 25 33.7463 25 33.125C25 32.5037 24.4963 32 23.875 32C23.2537 32 22.75 32.5037 22.75 33.125C22.75 33.7463 23.2537 34.25 23.875 34.25Z"
          fill={primaryDarkGreenColor}
        />
        <path
          d="M28 34.25C28.6213 34.25 29.125 33.7463 29.125 33.125C29.125 32.5037 28.6213 32 28 32C27.3787 32 26.875 32.5037 26.875 33.125C26.875 33.7463 27.3787 34.25 28 34.25Z"
          fill={primaryDarkGreenColor}
        />
        <path
          d="M33.25 29.375C33.25 28.7537 32.7463 28.25 32.125 28.25C31.5037 28.25 31 28.7537 31 29.375V33.125C31 33.7463 31.5037 34.25 32.125 34.25C32.7463 34.25 33.25 33.7463 33.25 33.125V29.375Z"
          fill={primaryDarkGreenColor}
        />
      </g>
      <rect x="4" y="4" width="48" height="48" rx="24" stroke="#E8F3ED" strokeWidth="8" />
      <defs>
        <clipPath id="clip0_10098_130572">
          <rect width="24" height="24" fill="white" transform="translate(16 16)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Calculator;
