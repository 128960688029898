import { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import { USER_ROLE_TYPES } from 'constants/index';

const useIsMember = () => {
  const { me, meLoading } = useContext(AuthContext);

  const isMember = !!(me && me?.internalContact?.role === USER_ROLE_TYPES.member);

  return { isLoading: meLoading, isMember };
};

export default useIsMember;
