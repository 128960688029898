import React from 'react';

const MobileTableSkeleton = () => {
  return (
    <div data-testid="list-skeleton">
      <div className="tw-flex tw-flex-col tw-space-y-4 tw-p-4 tw-w-full tw-rounded-md tw-bg-neutral-light">
        {Array(2)
          .fill(0)
          .map((_, i) => (
            <div key={i} className="tw-flex tw-flex-col tw-space-y-2">
              <div className="tw-rounded-md tw-animate-pulse tw-bg-neutral-grey-4 tw-h-4 tw-w-12" />
              {Array(5)
                .fill(0)
                .map((_, i) => (
                  <div key={i * 10} className="tw-w-full tw-rounded-md tw-animate-pulse tw-bg-neutral-grey-4 tw-h-8" />
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default MobileTableSkeleton;
