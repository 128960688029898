import React from 'react';

import { Loaders } from 'components/cards/Loader';
import { EmptyState, Table } from './components';
import { usePaymentRequests } from './hooks';

const PaymentRequests = () => {
  const { isEmpty, loading, paymentRequests } = usePaymentRequests();

  if (isEmpty) return <EmptyState />;

  return <div className="tw-mt-8">{loading ? <Loaders.Light /> : <Table paymentRequests={paymentRequests} />}</div>;
};

export default PaymentRequests;
