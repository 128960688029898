import { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import countryData from 'country-region-data';
import { isEmpty } from 'lodash';

import { CardMigrationContext } from 'components/CardMigrationV2/context/CardMigrationContext';
import { getCountrySubdivisions } from 'utility/countries';
import { useAddressAutoComplete } from 'hooks';

const useUpdateAddress = () => {
  const { selectedCard, goToConfirmSelectionStep, handleAddressUpdate } = useContext(CardMigrationContext);

  const { address: autoCompleteAddress, inputRef } = useAddressAutoComplete({
    countryRestrictions: ['CA', 'US'],
  });
  const mainAddress = selectedCard?.address || {};

  const address = autoCompleteAddress || mainAddress;

  const form = useForm({
    defaultValues: {
      address: {
        street: '',
        unitNumber: '',
        city: '',
        postalCode: '',
        countrySubdivision: '',
        country: '',
      },
    },
  });
  const { handleSubmit, register, watch, setValue } = form;

  useEffect(() => {
    if (isEmpty(address)) return;

    const { street, unitNumber, city, postalCode, countrySubdivision, country } = address;
    setValue('address.street', street);
    setValue('address.unitNumber', unitNumber);
    setValue('address.city', city);
    setValue('address.postalCode', postalCode);
    setValue('address.countrySubdivision', countrySubdivision);
    setValue('address.country', country);
  }, [address, setValue]);

  const countryOptions = () => {
    return countryData
      .filter((country) => country.countryName === 'Canada' || country.countryName === 'United States')
      .map((country) => ({
        name: country.countryName,
        value: country.countryShortCode,
        regions: country.regions,
      }));
  };
  const watchCountry = watch('address.country');
  const countrySubdivisions = useMemo(() => getCountrySubdivisions(watchCountry), [watchCountry]);

  const onSubmit = () => {
    handleAddressUpdate({ cardId: selectedCard?.cardId || null, address: form.getValues().address });
    goToConfirmSelectionStep();
  };

  return {
    selectedCard,
    goToConfirmSelectionStep,

    form,
    register,
    watch,
    onSubmit,
    handleSubmit,
    countryOptions,
    countrySubdivisions,

    streetRef: inputRef,
  };
};

export default useUpdateAddress;
