import React from 'react';

import WarningAndStars from 'components/svg/WarningAndStars';
import { Header } from '../';
import { FormFooter } from 'components/UI';
import useCardCreationLimit from './hooks/useCardCreationLimit';

const CardCreationLimit = () => {
  const { onClose } = useCardCreationLimit();
  return (
    <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-flex-grow tw-items-center">
      <Header onClose={onClose} title={'Create Card Limit'} />
      <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-flex-grow tw-items-center">
        <div className="tw-px-10 tw-py-10 tw-flex tw-flex-col tw-items-center tw-flex-grow tw-place-content-center">
          <WarningAndStars className="tw-mb-6" />
          <p>You have reached the limit of virtual and physical credit cards.</p>
        </div>
        <div className="tw-bg-neutral-grey-4.5 tw-w-full tw-pt-4 tw-pb-8 tw-rounded-b-md tw-overflow-hidden tw-mt-auto">
          <FormFooter
            submitButtonLabel="Exit"
            onSubmit={onClose}
            rootClass="lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0"
          />
        </div>
      </div>
    </div>
  );
};

export default CardCreationLimit;
