import React, { useContext } from 'react';

import { PayLocBalanceContext } from '../PayLocBalanceContext';

export const ReadOnly = ({ fieldName = '', label = '', value = '' }) => {
  const { payload } = useContext(PayLocBalanceContext);

  // Prioritize prop if passed down
  // - Example use case: wanting to values before displaying
  const _value = value || payload[fieldName];

  return (
    <div>
      <div className="tw-text-sm tw-text-neutral-grey-1 tw-mb-1">{label}</div>
      <div>{_value}</div>
    </div>
  );
};
