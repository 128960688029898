import { gql } from '@apollo/client';

export const ADD_SUPPLIER = gql`
  mutation AddSupplier(
    $address: SupplierAddressInput
    $bankAccount: SupplierBankAccountInput
    $bankAccountProvided: Boolean
    $email: String!
    $legalName: String
    $firstName: String
    $lastName: String
    $displayName: String
    $type: SupplierTypeEnum!
  ) {
    addSupplier(
      address: $address
      bankAccount: $bankAccount
      bankAccountProvided: $bankAccountProvided
      email: $email
      legalName: $legalName
      type: $type
      firstName: $firstName
      lastName: $lastName
      displayName: $displayName
    ) {
      id
    }
  }
`;

export const ADD_SUPPLIER_BANK_ACCOUNT = gql`
  mutation AddSupplierBankAccount(
    $address: SupplierAddressInput!
    $bankAccount: SupplierBankAccountInput!
    $supplierId: String!
  ) {
    addSupplierBankAccount(address: $address, bankAccount: $bankAccount, supplierId: $supplierId) {
      id
      bankAccount {
        code
        maskedNumber
        accountNumber
        verified
      }
      companyName
      currency
      firstName
      lastName
      name
      type
      displayName
      hasCurrencyCloud
      email
      status
    }
  }
`;
