import { useState, useCallback, useMemo } from 'react';

import { Option, SearchDropdownProps } from '../SearchDropdown.types';

const useSearchDropdown = ({
  optionsList,
  selectedOptions,
  onSelectOptions,
}: Pick<SearchDropdownProps, 'optionsList' | 'selectedOptions' | 'onSelectOptions'>) => {
  const [query, setQuery] = useState('');

  const filteredOptions = useMemo(
    () => optionsList.filter((option) => option.label.toLowerCase().includes(query.toLowerCase())),
    [optionsList, query]
  );

  const handleSelectOption = useCallback(
    (newOptions: Option | Option[] | null) => {
      if (!newOptions) {
        onSelectOptions(null);
        return;
      }

      if (Array.isArray(newOptions)) {
        // fix an issue with https://headlessui.com/v1/react/combobox#combobox
        // where the selected options are not being updated correctly
        const options = newOptions.reduce((acc, option) => {
          if (acc?.some((prevOption) => prevOption.value === option.value)) {
            return acc.filter((prevOption) => prevOption.value !== option.value);
          }

          return [...acc, option];
        }, [] as Option[]);

        onSelectOptions(options);
        return;
      }

      onSelectOptions([newOptions]);
    },
    [onSelectOptions]
  );

  const handleRemoveOption = useCallback(
    (value: string) => {
      if (Array.isArray(selectedOptions)) {
        onSelectOptions(selectedOptions.filter((prevOption) => prevOption.value !== value));
        return;
      }

      onSelectOptions(null);
    },
    [selectedOptions, onSelectOptions]
  );

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  const resetSearch = useCallback(() => {
    setQuery('');
  }, []);

  return {
    filteredOptions,
    selectedOptions,
    handleSelectOption,
    handleRemoveOption,
    handleSearch,
    resetSearch,
  };
};

export default useSearchDropdown;
