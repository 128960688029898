import { useContext, useState } from 'react';

import { AddFundsToBalanceContext } from 'components/creditCards/context/AddFundsToBalance';
import { addFundsToBalanceSteps } from '../constants';
import { AddFundsToBalanceModalProps } from '../AddFundsToBalanceModal.types';

const useAddFundsToBalanceModal = ({ onClose }: Pick<AddFundsToBalanceModalProps, 'onClose'>) => {
  const { transaction, setError, setTransaction } = useContext(AddFundsToBalanceContext);
  const { originalAmount } = transaction || {};
  const { currency } = originalAmount || {};

  const [initialStep] = addFundsToBalanceSteps.Details;
  const [currentStep, setCurrentStep] = useState(initialStep);

  const onNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    setError(null);
  };

  const onResetSteps = () => {
    setCurrentStep(initialStep);
    setError(null);
    setTransaction(null);
    onClose();
  };

  return {
    currentStep,
    onNextStep,
    onResetSteps,
    currency,
  };
};

export default useAddFundsToBalanceModal;
