import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const CheckmarkAndPerson = ({ width = '131', height = '93', ...other }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 131 93" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M65.7921 47.3922C65.7921 50.6278 65.0181 53.8166 63.5346 56.6922C62.0512 59.5677 59.9013 62.0467 57.2646 63.9221C54.6278 65.7975 51.5807 67.015 48.3776 67.4728C45.1745 67.9307 41.9083 67.6156 38.8517 66.5539"
        stroke="#4F5154"
        strokeLinecap="round"
      />
      <path
        d="M33.3874 31.1284C36.1135 29.0966 39.3066 27.7826 42.6731 27.3072C46.0396 26.8318 49.4717 27.2102 52.6538 28.4077C55.8359 29.6051 58.6659 31.5832 60.8838 34.1602C63.1017 36.7371 64.6364 39.8301 65.3466 43.1551"
        stroke="#4F5154"
        strokeLinecap="round"
      />
      <path
        d="M35.3675 64.96C32.7912 63.4726 30.5736 61.4367 28.8718 58.9966C27.1701 56.5566 26.0258 53.7721 25.5202 50.8405C25.0146 47.9089 25.16 44.902 25.9461 42.0328C26.7322 39.1637 28.1397 36.5026 30.0689 34.2381"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.8595 31.7664L33.2812 31.1855L33.8621 26.6073"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.3977 60.8413L36.1133 65.4004L31.5542 66.116"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.6251 49.5701L65.7422 46.167L69.1453 49.2841"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.605 16.5296C121.817 16.471 121.955 16.7475 121.78 16.8811L110.409 25.5877C110.344 25.6373 110.316 25.7212 110.337 25.7998L114.154 39.6038C114.213 39.8157 113.936 39.9533 113.803 39.7787L105.096 28.4071C105.047 28.3423 104.963 28.3141 104.884 28.3359L91.08 32.1528C90.8681 32.2114 90.7306 31.9349 90.9052 31.8012L102.277 23.0947C102.342 23.0451 102.37 22.9612 102.348 22.8825L98.531 9.07857C98.4724 8.86666 98.7489 8.72912 98.8826 8.90368L107.589 20.2753C107.639 20.3401 107.723 20.3682 107.801 20.3465L121.605 16.5296Z"
        fill="#8F5CCF"
      />
      <path
        d="M17.5092 74.6018C17.6914 74.4789 17.9098 74.6972 17.7868 74.8795L14.3085 80.0346C14.2629 80.1022 14.2629 80.1907 14.3085 80.2583L17.7868 85.4135C17.9098 85.5957 17.6914 85.8141 17.5092 85.6911L12.3541 82.2128C12.2865 82.1672 12.1979 82.1672 12.1303 82.2128L6.9752 85.6911C6.79295 85.8141 6.57458 85.5957 6.69755 85.4135L10.1759 80.2583C10.2215 80.1907 10.2215 80.1022 10.1759 80.0346L6.69755 74.8795C6.57458 74.6972 6.79295 74.4789 6.9752 74.6018L12.1303 78.0801C12.1979 78.1258 12.2865 78.1258 12.3541 78.0801L17.5092 74.6018Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M94.0629 54.4543C93.7238 59.027 90.2574 62.7573 86.4518 62.7573C82.6463 62.7573 79.1738 59.0279 78.8408 54.4543C78.4948 49.6974 81.8679 46.1514 86.4518 46.1514C91.0358 46.1514 94.4088 49.7839 94.0629 54.4543Z"
        fill="#EED9F7"
      />
      <path
        d="M86.4506 68.293C78.9261 68.293 71.2891 72.4444 69.8758 80.2804C69.7029 81.2248 70.24 82.1312 71.2294 82.1312H101.673C102.662 82.1312 103.197 81.2248 103.026 80.2804C101.612 72.4444 93.9752 68.293 86.4506 68.293Z"
        fill="#EED9F7"
      />
      <path
        d="M88.5199 58.4543C88.1809 63.027 84.7144 66.7573 80.9089 66.7573C77.1034 66.7573 73.6308 63.0279 73.2978 58.4543C72.9519 53.6974 76.325 50.1514 80.9089 50.1514C85.4928 50.1514 88.8659 53.7839 88.5199 58.4543Z"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.9115 72.292C73.387 72.292 65.75 76.4435 64.3368 84.2794C64.1638 85.2238 64.7009 86.1302 65.6903 86.1302H96.1336C97.1231 86.1302 97.6576 85.2238 97.4872 84.2794C96.0731 76.4435 88.4361 72.292 80.9115 72.292Z"
        stroke="#4F5154"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default CheckmarkAndPerson;
