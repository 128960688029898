import { gql } from '@apollo/client';

export const TRANSACTION_FIELDS = gql`
  fragment TransactionFields on Transaction {
    payeePayment {
      payee {
        record {
          ... on Supplier {
            displayName
          }
          ... on CreditCardPayee {
            displayName
          }
          ... on CRAAccount {
            displayName
          }
        }
      }
    }
    groupId
    transactionId
    amount {
      amount
      currency
    }
    createdAt
    completedAt
    status
    type
    note
    transferMethod
  }
`;

export const GET_LINE_OF_CREDIT_INFO = gql`
  ${TRANSACTION_FIELDS}
  query LineOfCreditInfo {
    me {
      ... on User {
        account {
          hasDischargedLinesOfCredit
          lineOfCredit {
            id
            currency
            ledgerAccount {
              id
              displayName
              balance {
                amount
                currency
              }
              availableBalance {
                amount
                currency
              }
              pendingAmount {
                amount
                currency
              }
              currency
            }
            outstandingBalance {
              amount
              currency
            }
            currentBalance {
              amount
              currency
            }
            availableCredit {
              amount
              currency
            }
            creditLimit {
              amount
              currency
            }
            postedBalance {
              amount
              currency
            }
            pendingBalance {
              amount
              currency
            }
            transactions {
              ...TransactionFields
              from
              to
              fxExchangeRate
              cardTransaction
              transferMethod
            }
            billingCycle {
              startDate
              endDate
              previousStartDate
              previousEndDate
            }
            paymentDate
            amountDue {
              amount
              currency
            }
          }
          wallets {
            id
            displayName
            pendingAmount {
              amount
              currency
            }
            balance {
              amount
              currency
            }
            availableBalance {
              amount
              currency
            }
            currency
          }
          bankAccounts {
            id
            displayName
            maskedNumber
            institutionName
            currency
            preDepositAuthorization
            institutionNumber
            transitNumber
            country
          }
        }
      }
    }
    accountInfo {
      number
      hasLineOfCredit
      fundInfo {
        previousPayment {
          dueDate
        }
        nextPayment {
          amount {
            amount
            currency
          }
          dueDate
          status
          modifiedPayment
        }
        paymentSchedule {
          amountDue {
            amount
            currency
          }
          dueDate
        }
        billingCycle {
          nextStartDate
          endDate
          previousEndDate
        }
      }
    }
  }
`;

export const GET_LINE_OF_CREDIT_INFO_FOR_PAYEE_PAYMENT = gql`
  query LineOfCreditInfoForPayeePayment {
    me {
      ... on User {
        account {
          lineOfCredit {
            id
            availableCredit {
              amount
              currency
            }
            ledgerAccount {
              displayName
            }
          }
        }
      }
    }
  }
`;

export const GET_LINE_OF_CREDIT_STATEMENTS = gql`
  query LineOfCreditStatements {
    me {
      ... on User {
        account {
          lineOfCredit {
            id
            statements {
              id
              title
              createdAt
            }
          }
        }
      }
    }
  }
`;
