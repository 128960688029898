import { CreditCard, CreditCardStatus } from 'types/creditCard';
import { CREDIT_CARD_STATES } from 'constants/index';

const isActive = (card: CreditCard) => card.status === CreditCardStatus.active;
const isActivationRequired = (card: CreditCard) => !card.virtual && card.status === CreditCardStatus.inactive;
const isFrozen = (card: CreditCard) => card.status === CreditCardStatus.suspended;
const isClosed = (card: CreditCard) =>
  card.status &&
  [
    CreditCardStatus.stolen,
    CreditCardStatus.fraudulent,
    CreditCardStatus.damaged,
    CreditCardStatus.admin_suspended,
    CreditCardStatus.block,
    CreditCardStatus.lost,
  ].includes(card.status);

export const filterCardsByStatus = (cards: CreditCard[], selectedStatuses: string[]): CreditCard[] => {
  const filteredCards: CreditCard[] = [];

  if (selectedStatuses.includes(CREDIT_CARD_STATES.active)) {
    filteredCards.push(...cards.filter(isActive));
  }
  if (selectedStatuses.includes(CREDIT_CARD_STATES.activationRequired)) {
    filteredCards.push(...cards.filter(isActivationRequired));
  }
  if (selectedStatuses.includes(CREDIT_CARD_STATES.frozen)) {
    filteredCards.push(...cards.filter(isFrozen));
  }
  if (selectedStatuses.includes(CREDIT_CARD_STATES.closed)) {
    filteredCards.push(...cards.filter(isClosed));
  }

  return filteredCards;
};
