import { gql } from '@apollo/client';

export const GET_TRANSACTION_CATEGORIES = gql`
  query TransactionCategories {
    transactionCategories {
      transactionCategoryId
      description
      loopId
    }
  }
`;

export const UPDATE_TRANSACTION_CATEGORY = gql`
  mutation UpdateTransactionCategory(
    $transactionId: String!
    $oldCategoryId: String
    $newCategoryId: String
    $description: String
  ) {
    updateTransactionCategory(
      transactionId: $transactionId
      oldCategoryId: $oldCategoryId
      newCategoryId: $newCategoryId
      description: $description
    )
  }
`;
