import React from 'react';

import { Progress } from 'components/UI';
import Steps from './components/Steps/Steps';
import { useAddPayee } from './hooks';

const AddPayee = ({ onFinish }) => {
  const { steps, errorSteps, payeeType, step, setStep, handleNextStep, handlePrevStep } = useAddPayee();

  return (
    <>
      <Progress currentStepIndex={step} stepLabelsAndIndexes={steps} errorSteps={errorSteps} />
      <Steps
        payeeType={payeeType}
        step={step}
        setStep={setStep}
        handlePrevStep={handlePrevStep}
        handleNextStep={handleNextStep}
        onFinish={onFinish}
      />
    </>
  );
};

export default AddPayee;
