import React from 'react';
import { useDropzone } from 'react-dropzone';
import { RiErrorWarningFill } from 'react-icons/ri';
import cx from 'classnames';

import Close from 'components/svg/Close';
import Attachment from 'components/svg/Attachment';
import { useIsMobile } from 'hooks';

const DEFAULT_ACCEPTED_FILE_EXTENSIONS = 'pdf, doc, docx ppt, pptx, xls, xlsx, jpeg, png, gif, csv, txt';
const DEFAULT_ACCEPTED_MIME_TYPES =
  'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, 	application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/jpeg, image/png, image/gif, text/csv, text/plain';
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 50 * 10 ** 6;

const sanitizeErrorMessage = (error) => {
  const message = error ? error.message : 'Invalid file';

  return message.includes('File type must be')
    ? `File type must be one of: ${DEFAULT_ACCEPTED_FILE_EXTENSIONS}`
    : message;
};

export const DropZoneError = ({ errors }) => {
  const message = sanitizeErrorMessage(errors && errors[0]);

  return (
    <div className="tw-flex tw-items-center tw-bg-semantic-error-background tw-rounded-md tw-p-2 tw-mt-2">
      <RiErrorWarningFill size={18} className="tw-text-semantic-error" />
      <p className="tw-ml-2 tw-text-semantic-error">{message}</p>
    </div>
  );
};

const fileSizeValidator = (file) => {
  if (file && file.size !== 0) return null;

  return {
    code: 'file-empty',
    message: `The file cannot be empty`,
  };
};

export const HookDropZone = ({ onFilesAdded, size = 'base' }) => {
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: onFilesAdded,
    validator: fileSizeValidator,
    accept: DEFAULT_ACCEPTED_MIME_TYPES,
    maxSize: DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  });

  const isMobile = useIsMobile();

  const isRegularSize = size === 'base';
  const isSmallSize = size === 'sm';

  const ariaLabel = isSmallSize || isMobile ? 'Upload file' : 'Click area or drag and drop your files to upload';
  const iconSize = isSmallSize || isMobile ? 20 : 32;

  return (
    <>
      <div
        {...getRootProps()}
        className={cx(
          'tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-border-2 tw-border-dashed tw-border-neutral-grey-2 tw-rounded-md tw-bg-neutral-grey-5 tw-cursor-pointer',
          `tw-text-${size}`,
          isRegularSize && 'tw-gap-4 tw-py-8',
          isSmallSize && 'tw-gap-1 tw-p-2 tw-h-10'
        )}
      >
        <input {...getInputProps()} />
        <div className={cx('tw-flex tw-gap-1 tw-justify-center tw-items-center', isRegularSize && 'tw-flex-col')}>
          <div>
            <Attachment className="tw-text-neutral-dark" size={iconSize} />
          </div>
          <div className="tw-text-center">{ariaLabel}</div>
        </div>
      </div>
      {fileRejections[0] && <DropZoneError errors={fileRejections[0].errors} />}
    </>
  );
};

export const AddNextFile = ({ onFilesAdded }) => {
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: onFilesAdded,
    validator: fileSizeValidator,
    accept: DEFAULT_ACCEPTED_MIME_TYPES,
    maxSize: DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  });

  return (
    <>
      <div {...getRootProps()} className="tw-flex tw-items-center tw-w-full tw-px-4 tw-cursor-pointer">
        <input {...getInputProps()} />
        <div className="tw-flex tw-justify-center tw-items-center tw-rounded-xl tw-w-5 tw-h-5 tw-border tw-border-neutral-grey-1">
          <p className="tw-text-neutral-grey-1">+</p>
        </div>
        <p className="tw-text-neutral-grey-1 tw-ml-4">Add next file</p>
      </div>
      {fileRejections[0] && <DropZoneError message={fileRejections[0].errors} />}
    </>
  );
};

export const AddedFile = ({ file, onRemoveFile, size = 'base' }) => {
  const handleRemove = () => onRemoveFile(file);

  return (
    <div
      className={`tw-flex tw-items-center tw-justify-between tw-flex-shrink-0 tw-bg-primary-light-green tw-rounded-md tw-p-2 tw-text-${size} tw-border-2 tw-border-primary-light-green`}
    >
      <div className="tw-flex tw-items-center tw-overflow-hidden">
        <div>
          <Attachment className="tw-text-primary-dark-green" size={20} />
        </div>
        <div className="tw-ml-2 tw-text-primary-dark-green tw-truncate">{file.path}</div>
      </div>
      <div>
        <Close className="tw-cursor-pointer tw-text-primary-dark-green" onClick={handleRemove} />
      </div>
    </div>
  );
};
