import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import ArrowRight from 'components/svg/ArrowRight';
import { TextField, SubmitButton } from 'components/FormFields/v2';

const SecurityQuestionFields = ({ register, mfaQuestions }) => {
  return (
    <div className="tw-flex tw-flex-col tw-px-8 tw-pt-8">
      <strong>Security Questions</strong>
      <div className="tw-flex tw-flex-col tw-justify-between tw-w-full tw-mt-4">
        {mfaQuestions &&
          mfaQuestions.map((mfaQuestion, index) => (
            <TextField
              rootClass="tw-mb-8"
              key={'securityAnswer.' + index}
              name={'securityAnswer.' + index}
              label={mfaQuestion}
              ref={register({ required: true })}
              data-private
            />
          ))}
      </div>
    </div>
  );
};

const SecurityQuestionForm = (props) => {
  const { onSubmit, error, isSubmitting, mfaQuestions } = props;
  const form = useForm();
  const { handleSubmit, register } = form;

  const handleSubmitForm = (formData) => onSubmit({ formData });

  return (
    <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <SecurityQuestionFields register={register} mfaQuestions={mfaQuestions} />
        <hr className="tw-bg-neutral-grey-4" />
        <div>
          <div className="tw-flex tw-flex-row tw-justify-end tw-mx-8">
            <div className="tw-py-4">
              <SubmitButton className="tw-w-max" disabled={isSubmitting}>
                <div className="tw-flex tw-justify-center tw-items-center">
                  <p className="tw-text-neutral-light tw-mr-2">{isSubmitting ? 'Submitting...' : 'Submit'}</p>
                  <ArrowRight />
                </div>
              </SubmitButton>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default SecurityQuestionForm;
