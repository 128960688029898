import { useContext } from 'react';

import { useRebuildTooltipForModal } from 'components/InfoTooltip';
import { CADBankAccountOnboardingContext } from 'components/wallets/OpenCADAccountModal/contexts/CADBankAccountOnboardingContext';

const useComplete = () => {
  const { externalAccount } = useContext(CADBankAccountOnboardingContext);

  const onCopy = (value?: string) => value && navigator.clipboard.writeText(value);

  const { accountNumber, institutionNumber, transitNumber } = externalAccount || {};

  useRebuildTooltipForModal();

  return {
    onCopy,
    accountNumber,
    institutionNumber,
    transitNumber,
  };
};

export default useComplete;
