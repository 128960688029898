import React from 'react';

import { formatMoneyV2 } from 'utility/currency';
import { AttributeRow } from '.';

const FxTransactionRows = ({ transaction, accountFXTransaction }) => {
  const feeAmount = transaction.fxConvertedAmount.amount - transaction.fxChargedAmount.amount;

  const bought = formatMoneyV2(transaction.fxBillingAmount);
  const boughtCurrency = transaction.fxBillingAmount.currency;

  const sold = formatMoneyV2(transaction.fxChargedAmount);
  const soldCurrency = transaction.fxChargedAmount.currency;

  const exchange = `${formatMoneyV2({
    amount: 100,
    currency: soldCurrency,
  })}`;

  const formattedRate = `${formatMoneyV2({
    amount: 100 * (transaction.fxExchangeRate > 0 ? 1 / transaction.fxExchangeRate : transaction.fxExchangeRate),
    currency: boughtCurrency,
  })}`;

  return (
    <>
      <AttributeRow
        label="Bought"
        value={
          <>
            <span className="tw-font-bold tw-mr-1">{bought}</span>
            {boughtCurrency}
          </>
        }
      />
      <AttributeRow
        label="Sold"
        value={
          <>
            <span className="tw-font-bold tw-mr-1">{sold}</span>
            {soldCurrency}
          </>
        }
      />
      <AttributeRow
        label="Exchange rate"
        value={
          <>
            <span className="tw-font-bold">{exchange}</span> <span>{soldCurrency} =</span>
            <span className="tw-font-bold tw-mx-1">{formattedRate}</span>
            {boughtCurrency}
          </>
        }
      />
      {!accountFXTransaction && (
        <AttributeRow
          label="Fee"
          value={
            feeAmount === 0 ? (
              'No fees'
            ) : (
              <>
                <span className="tw-font-bold tw-mr-1">
                  {formatMoneyV2({ amount: feeAmount, currency: soldCurrency }, true)}
                </span>
                {soldCurrency}
              </>
            )
          }
        />
      )}
    </>
  );
};

export default FxTransactionRows;
