import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const Lighting = (props) => {
  // Ka-chow!
  return (
    <svg width="77" height="80" viewBox="0 0 77 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.4147 25.3408C13.5607 25.2423 13.7355 25.4172 13.6371 25.5631L11.0159 29.4478C10.9794 29.5019 10.9794 29.5728 11.0159 29.627L13.6371 33.5117C13.7355 33.6576 13.5607 33.8325 13.4147 33.734L9.53001 31.1129C9.47588 31.0764 9.40498 31.0764 9.35085 31.1129L5.46614 33.734C5.32019 33.8325 5.14532 33.6576 5.2438 33.5117L7.86493 29.627C7.90146 29.5728 7.90146 29.5019 7.86493 29.4478L5.2438 25.5631C5.14532 25.4172 5.3202 25.2423 5.46614 25.3408L9.35085 27.9619C9.40498 27.9984 9.47587 27.9984 9.53001 27.9619L13.4147 25.3408Z"
        fill="#EEA530"
      />
      <path
        d="M66.9469 46.2272C67.0929 46.1287 67.2677 46.3036 67.1692 46.4495L61.4532 54.9211C61.4167 54.9753 61.4167 55.0461 61.4532 55.1003L67.1692 63.5719C67.2677 63.7178 67.0929 63.8927 66.9469 63.7942L58.4753 58.0782C58.4212 58.0416 58.3503 58.0416 58.2962 58.0782L49.8246 63.7942C49.6786 63.8927 49.5038 63.7178 49.6022 63.5719L55.3183 55.1003C55.3548 55.0461 55.3548 54.9753 55.3183 54.9211L49.6022 46.4495C49.5038 46.3036 49.6786 46.1287 49.8246 46.2272L58.2962 51.9432C58.3503 51.9798 58.4212 51.9798 58.4753 51.9432L66.9469 46.2272Z"
        fill="#8F5CCF"
      />
      <path
        d="M24.6603 10.1306C24.8287 10.0791 24.9448 10.2975 24.8079 10.4083L18.5998 15.4321C18.549 15.4731 18.5282 15.5409 18.5473 15.6034L20.882 23.2407C20.9335 23.4091 20.7151 23.5251 20.6044 23.3883L15.5806 17.1801C15.5395 17.1293 15.4717 17.1086 15.4093 17.1277L7.77192 19.4624C7.60355 19.5138 7.48749 19.2955 7.62435 19.1847L13.8325 14.1609C13.8833 14.1199 13.904 14.0521 13.8849 13.9896L11.5503 6.35228C11.4988 6.18391 11.7172 6.06785 11.8279 6.20471L16.8517 12.4129C16.8928 12.4636 16.9606 12.4844 17.023 12.4653L24.6603 10.1306Z"
        fill="#8F5CCF"
      />
      <path
        d="M16.7257 67.7368C16.8717 67.6383 17.0466 67.8131 16.9481 67.9591L14.7556 71.2085C14.7191 71.2627 14.7191 71.3336 14.7556 71.3877L16.9481 74.6372C17.0466 74.7831 16.8717 74.958 16.7257 74.8595L13.4763 72.667C13.4222 72.6305 13.3513 72.6305 13.2971 72.667L10.0477 74.8595C9.90174 74.958 9.72687 74.7831 9.82535 74.6372L12.0178 71.3877C12.0544 71.3336 12.0544 71.2627 12.0179 71.2085L9.82535 67.9591C9.72687 67.8131 9.90174 67.6383 10.0477 67.7368L13.2971 69.9293C13.3513 69.9658 13.4222 69.9658 13.4763 69.9293L16.7257 67.7368Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M44.3837 5.26399L18.1367 37.7031H33.4585L29.6867 58.439C29.6795 58.4796 29.6814 58.5214 29.6921 58.5613C29.7028 58.6012 29.7221 58.6383 29.7487 58.6699C29.7753 58.7015 29.8085 58.7269 29.846 58.7443C29.8835 58.7617 29.9243 58.7706 29.9656 58.7705C30.0096 58.7705 30.0529 58.7602 30.0922 58.7404C30.1315 58.7207 30.1656 58.6921 30.1919 58.6568L56.4412 26.2117H41.1194L44.9091 5.47347C44.9142 5.43215 44.9105 5.39022 44.8981 5.35047C44.8858 5.31072 44.8651 5.27406 44.8375 5.24292C44.8098 5.21178 44.7759 5.18688 44.7379 5.16987C44.6999 5.15287 44.6587 5.14415 44.6171 5.14429C44.5715 5.14447 44.5266 5.1554 44.486 5.1762C44.4455 5.197 44.4104 5.22708 44.3837 5.26399Z"
        fill="#FFE8C3"
      />
      <path
        d="M37.7574 25.1612L17.9326 49.6631H29.5055L26.6565 65.3253C26.6511 65.356 26.6525 65.3876 26.6606 65.4177C26.6687 65.4478 26.6833 65.4758 26.7034 65.4997C26.7235 65.5236 26.7486 65.5428 26.7769 65.5559C26.8052 65.569 26.836 65.5758 26.8672 65.5757V65.5757C26.9004 65.5757 26.9331 65.5679 26.9628 65.553C26.9925 65.5381 27.0183 65.5165 27.0381 65.4898L46.8647 40.9835H35.2919L38.1543 25.3194C38.1582 25.2882 38.1554 25.2566 38.146 25.2265C38.1367 25.1965 38.1211 25.1688 38.1002 25.1453C38.0793 25.1218 38.0537 25.103 38.025 25.0901C37.9963 25.0773 37.9652 25.0707 37.9337 25.0708V25.0708C37.8993 25.0709 37.8654 25.0792 37.8348 25.0949C37.8041 25.1106 37.7776 25.1333 37.7574 25.1612V25.1612Z"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Lighting;
