import React, { useContext } from 'react';
import { FilterRequestsContext, ApprovalRequestsContext } from 'components/Approvals/ApprovalsContent/context';
import { PeriodDropdown, ToggleDropdown } from 'components/UI';
import { capitalize } from 'utility/string';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { Currencies } from 'constants/currencies';
import { InternalContactFilterType } from './FilterBar.types';
import { IoCloseCircleOutline } from 'react-icons/io5';
import useIsMember from 'hooks/useIsMember';
import { APPROVAL_TAB } from 'components/Approvals/constants';

const FilterBar = ({ className = '' }) => {
  const {
    period,
    setPeriod,
    loadingCardProducts,
    cardProducts,
    setSelectedProducts,
    selectedProducts,
    loadingMemberList,
    setSelectedMembers,
    selectedMembers,
    memberList,
    selectedCurrencies,
    setSelectedCurrencies,
    currencyList,
    expenseTypeList,
    selectedExpenseTypes,
    setSelectedExpenseTypes,
    hasDropdownFilters,
    handleClearFilters,
  } = useContext(FilterRequestsContext);
  const { isMember } = useIsMember();
  const { selectedTab } = useContext(ApprovalRequestsContext);

  return (
    <div className={`${className} tw-flex tw-justify-between tw-items-center`}>
      <div className="tw-flex tw-items-center tw-gap-2">
        <div className="tw-text-sm tw-text-neutral-grey-2">Filter</div>
        <PeriodDropdown
          testId="filter-period-dropdown"
          label="Period"
          selectedValue={period}
          setSelectedValue={setPeriod}
          className="tw-ml-2"
          portalId="desktop-root"
          maxDate={new Date()}
        />
        {!isMember && (
          <ToggleDropdown
            testId="filter-cardholder-dropdown"
            label={(() => {
              switch (selectedTab) {
                case APPROVAL_TAB.expenseReimbursements:
                  return 'Requester';
                case APPROVAL_TAB.completed:
                  return 'Cardholder/Requester';
                case APPROVAL_TAB.cardExpenses:
                  return 'Cardholder';
              }
            })()}
            options={memberList}
            selectedOptions={selectedMembers}
            setSelectedOptions={setSelectedMembers}
            idKey="id"
            optionElement={(option: InternalContactFilterType) => (
              <span>{capitalize(`${option.firstName} ${option.lastName}`)}</span>
            )}
            loadingOptions={loadingMemberList}
          />
        )}
        {selectedTab !== APPROVAL_TAB.expenseReimbursements && (
          <ToggleDropdown
            testId="filter-product-dropdown"
            label="Product"
            options={cardProducts}
            selectedOptions={selectedProducts}
            setSelectedOptions={setSelectedProducts}
            idKey="id"
            optionKey="displayName"
            loadingOptions={loadingCardProducts}
          />
        )}
        <ToggleDropdown
          testId="filter-currency-dropdown"
          label="Currency"
          options={currencyList}
          selectedOptions={selectedCurrencies}
          setSelectedOptions={setSelectedCurrencies}
          optionElement={(option: Currencies) => (
            <>
              <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
                <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                  <MobileCurrencyFlag size={14} currency={option} />
                </div>
              </div>
              <div className="tw-text-sm">{option}</div>
            </>
          )}
        />
        {selectedTab === APPROVAL_TAB.completed && (
          <ToggleDropdown
            testId="filter-type-dropdown"
            label="Type"
            options={expenseTypeList}
            selectedOptions={selectedExpenseTypes}
            setSelectedOptions={setSelectedExpenseTypes}
          />
        )}
        {hasDropdownFilters && (
          <div
            onClick={() => handleClearFilters()}
            data-testid="filter-clear-button"
            className="tw-flex tw-items-center tw-cursor-pointer tw-text-neutral-grey-2 tw-ml-2"
          >
            <IoCloseCircleOutline size={18} className="tw-mr-2" />
            <div className="tw-text-sm">Clear all</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterBar;
