import { IFSC_REGEX } from 'constants/regex';

const validateINRBankCode = (value: string) => {
  if (!IFSC_REGEX.test(value)) return 'Invalid Bank Code';

  const hdfcRegex = /^.*HDFC.*$|^.*hdfc.*$/;
  if (hdfcRegex.test(value)) return 'Unfortunately, HDFC Bank is currently not supported.';

  return true;
};

export default validateINRBankCode;
