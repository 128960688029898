import { documentCategories } from 'constants/index';

type DocumentCategoryValueMap = {
  [key: string]: string;
};

export const DOCUMENT_CATEGORY_VALUES: DocumentCategoryValueMap = documentCategories.reduce(
  (acc, { value }) => ({ ...acc, [value]: value }),
  {}
);
