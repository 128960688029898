import React, { useContext } from 'react';

import { useAccountsModal } from '../../hooks';
import PayPayeeFlow from 'components/payments/PayPayee';
import { AccountsContext } from 'context/Accounts';

type PayPayeeProps = {
  onClose: () => void;
};

const PayPayee = ({ onClose }: PayPayeeProps) => {
  const { wallets, bankAccounts, lineOfCreditAccount, payees, selectedWalletId } = useContext(AccountsContext);
  const { refetchWalletTransactions } = useAccountsModal();

  const handleOnClose = () => {
    refetchWalletTransactions();
    onClose();
  };

  const walletOptions = wallets.filter((wallet) => wallet.id === selectedWalletId);

  return (
    <div className="tw-py-4">
      <PayPayeeFlow
        wallets={walletOptions}
        bankAccounts={bankAccounts}
        lineOfCredit={lineOfCreditAccount}
        payees={payees}
        onFinish={handleOnClose}
        initialFromAccountId={selectedWalletId}
      />
    </div>
  );
};

export default PayPayee;
