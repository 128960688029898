import React from 'react';
import cx from 'classnames';
import { BsArrowRight } from 'react-icons/bs';

import Button, { IconAnimation } from 'components/Button';
import Close from 'components/svg/Close';
import { BannerProps, BannerType, BannerSize } from './Banner.types';
import { useBanner } from './hooks';

import styles from './Banner.module.scss';

const Banner = ({
  onSubmit,
  type = BannerType.primary,
  size = BannerSize.regular,
  title,
  message,
  icon,
  submitButton = true,
  submitButtonLabel = 'Continue',
  isClosable = false,
  iconAlignment,
  arrowIcon = true,
  centerAlignContent = false,
  classNames,
  hasBorder = false,
}: BannerProps) => {
  const { isBannerOpen, closeBanner } = useBanner();

  if (!isBannerOpen) return null;

  return (
    <aside
      className={cx(
        styles.banner,
        styles[`banner--${type}`],
        styles[`banner--${size}`],
        hasBorder && styles[`banner--${type}--border`],
        classNames
      )}
      onClick={onSubmit}
      role="button"
      aria-label={submitButtonLabel}
      tabIndex={1}
    >
      <div className={cx(styles.content, centerAlignContent && styles.centerContent)}>
        <div className={cx(styles.icon, iconAlignment === 'center' && styles.center)}>{icon}</div>
        <div>
          {title && (
            <div className={styles.title} tabIndex={2}>
              {title}
            </div>
          )}
          <div className={styles.message}>
            <div tabIndex={3}>{message}</div>
          </div>
          {submitButton && (
            <Button tertiary className={cx(styles.bottomAction, styles.actionButton)}>
              {submitButtonLabel}
              <BsArrowRight />
            </Button>
          )}
        </div>
      </div>
      {isClosable && (
        <Button
          tertiary
          onClick={closeBanner}
          className={styles.close}
          iconAnimation={IconAnimation.rolling}
          tabIndex={4}
        >
          <Close className={styles.close} onClick={closeBanner} />
        </Button>
      )}

      {arrowIcon && (
        <Button className={cx(styles.rightAction, styles.actionButton)} aria-label={submitButtonLabel}>
          <BsArrowRight size={20} />
        </Button>
      )}
    </aside>
  );
};

export { BannerType };

export default Banner;
