import React from 'react';

import Details from './steps/Details';
import Review from './steps/Review';
import Complete from './steps/Complete';

export const PAY_LOC_STEPS = {
  Details: [0],
  Review: [1],
  Complete: [2],
};

export const STEP_COMPONENT_MAP = {
  0: <Details />,
  1: <Review />,
  2: <Complete />,
};
