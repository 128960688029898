import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = (props) => {
  const {
    children,
    value,
    strokeWidth = 8,
    size = 200,
    pathColor = '#55AC56',
    trailColor = '#EFF0F7',
    clockWise = false,
  } = props;
  return (
    <div style={{ height: `${size}px`, width: `${size}px` }}>
      <CircularProgressbarWithChildren
        strokeWidth={strokeWidth}
        styles={buildStyles({ pathColor, trailColor })}
        value={value}
        counterClockwise={!clockWise}
      >
        {children}
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircularProgress;
