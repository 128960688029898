import React, { useState } from 'react';

import useSetCardFromQueryString from 'components/creditCards/useSetCardFromQueryString';
import CreateCardModal from 'components/creditCards/createCardFlow/CreateCardModal';
import CardsList from 'components/creditCards/CardsList';
import { CurrencyContextProvider } from 'components/creditCards/CurrencyContext';
import { CreateCardContextProvider } from 'components/creditCards/createCardFlow/CreateCardContext';
import CardMaintenance from 'components/creditCards/CardMaintenance';
import CreditCardDetailOverlay from 'components/creditCards/CreditCardDetailOverlay';

const CardsPage = ({ creditCards, visibleCards, globalCreditLimit, creditCardLoading, accountState }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  useSetCardFromQueryString({ creditCards });

  const onCloseCreateModal = () => setShowCreateModal(false);
  const onShowCreateModal = () => setShowCreateModal(true);

  return (
    <CurrencyContextProvider>
      <CreateCardContextProvider>
        <div className="tw-flex tw-w-3/4">
          <div className="tw-pr-24 tw-pb-16">
            <CardMaintenance />

            <CreateCardModal
              show={showCreateModal}
              onClose={onCloseCreateModal}
              creditCards={creditCards}
              globalCreditLimit={globalCreditLimit}
            />

            <CardsList
              creditCardLoading={creditCardLoading}
              creditCards={visibleCards}
              errorFetchingCards={accountState === 'errorFetchingCards'}
              onCreateCard={onShowCreateModal}
            />
          </div>
          <CreditCardDetailOverlay />
        </div>
      </CreateCardContextProvider>
    </CurrencyContextProvider>
  );
};

export default CardsPage;
