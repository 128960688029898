import React from 'react';

import { BiArrowBack } from 'react-icons/bi';
import { IoGiftOutline } from 'react-icons/io5';
import { BsChevronRight } from 'react-icons/bs';

import Modal from 'components/Modal/v2';
import { useRewardsModal } from './hooks';
import Offers from './Offers';
import { Rewards } from './Rewards';
import { ReferAndEarn } from 'components/rewardsAndOffers/ReferAndEarn';
import PointsTransactions from 'components/PointsTransactions/components/PointsTransactions';

const RewardsModal = ({ show, onClose, rewardsAccount, refetch }) => {
  const {
    showRewards,
    onCloseRewards,
    showHistory,
    onCloseHistory,
    showOffers,
    onCloseOffers,
    showReferAndEarn,
    onCloseReferAndEarn,
    rewardsModalButtons,
  } = useRewardsModal();

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-p-4 tw-pb-8 tw-min-h-screen tw-bg-neutral-grey-3.5">
        <BiArrowBack size={24} className="tw-cursor-pointer tw-mb-4" onClick={onClose} />
        <h2>Rewards & Offers</h2>

        {rewardsModalButtons.map(({ title, icon, onClick }) => (
          <button
            key={title}
            className="tw-flex tw-items-center tw-justify-between tw-rounded-md tw-bg-neutral-light tw-p-4 tw-mt-4 tw-w-full tw-text-left"
            onClick={onClick}
          >
            <div className="tw-flex tw-justify-start tw-items-center">
              <div className="tw-flex tw-justify-center tw-items-center tw-bg-secondary-pastel-green-100 tw-rounded tw-py-0.5 tw-px-1 tw-mr-4">
                {icon}
              </div>
              <strong>{title}</strong>
            </div>
            <BsChevronRight />
          </button>
        ))}

        <RewardsListModal rewardsAccount={rewardsAccount} show={showRewards} onClose={onCloseRewards} />
        <HistoryModal show={showHistory} onClose={onCloseHistory} />
        <OffersModal rewardsAccount={rewardsAccount} show={showOffers} onClose={onCloseOffers} refetch={refetch} />
        <ReferAndEarnModal show={showReferAndEarn} onClose={onCloseReferAndEarn} />
      </div>
    </Modal>
  );
};

const OffersModal = ({ show, onClose, rewardsAccount, refetch }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-p-4 tw-pb-8 tw-min-h-screen tw-bg-neutral-grey-3.5">
        <BiArrowBack size={24} className="tw-cursor-pointer tw-mb-4" onClick={onClose} />
        <h2>Offers</h2>
        <div className="tw-mt-4 tw-spacing-y-4">
          <Offers offers={rewardsAccount && rewardsAccount.offers} refetch={refetch} />
        </div>
      </div>
    </Modal>
  );
};

const RewardsListModal = ({ show, onClose, rewardsAccount }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-p-4 tw-pb-8 tw-min-h-screen tw-bg-neutral-grey-3.5">
        <BiArrowBack size={24} className="tw-cursor-pointer tw-mb-4" onClick={onClose} />
        <h2>Rewards</h2>
        <TotalPoints balance={rewardsAccount && rewardsAccount.balance} />
        <div className="tw-mt-4 tw-spacing-y-4">
          <Rewards />
        </div>
      </div>
    </Modal>
  );
};

const HistoryModal = ({ show, onClose }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-p-4 tw-pb-8 tw-min-h-screen tw-bg-neutral-grey-3.5">
        <BiArrowBack size={24} className="tw-cursor-pointer tw-mb-4" onClick={onClose} />
        <h2>Points Transaction History</h2>
        <div className="tw-mt-4 tw-spacing-y-4">
          <PointsTransactions />
        </div>
      </div>
    </Modal>
  );
};

const TotalPoints = ({ balance }) => (
  <div className="tw-bg-neutral-light tw-rounded-md tw-mt-4 tw-p-4">
    <span className="tw-text-neutral-grey-1">Total Points Balance</span>
    <div className="tw-flex tw-items-center tw-mt-2">
      <h3 className="tw-mr-2">{balance || 0}</h3>
      <IoGiftOutline size={24} className="tw-text-neutral-grey-1" />
    </div>
  </div>
);

const ReferAndEarnModal = ({ show, onClose }) => (
  <Modal show={show} onClose={onClose}>
    <div className="tw-p-4 tw-pb-8 tw-min-h-screen tw-bg-neutral-grey-3.5">
      <BiArrowBack size={24} className="tw-cursor-pointer tw-mb-4" onClick={onClose} />
      <h2>Earn 10,000 Points For Each Business You Refer</h2>
      <div className="tw-mt-4 tw-spacing-y-4">
        <ReferAndEarn />
      </div>
    </div>
  </Modal>
);

export default RewardsModal;
