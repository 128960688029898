import React from 'react';

const LimitSettingsItem = ({ label, value }: { label: String; value: String }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-between">
      <small className="tw-text-neutral-grey-2">{label}</small>
      <div className="tw-flex tw-items-center">
        <p>{value}</p>
      </div>
    </div>
  );
};

export default LimitSettingsItem;
