import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import VerifyBankAccountEmail from 'components/settings/bankAccounts/VerifyBankAccountEmail';
import { GET_LINE_OF_CREDIT_INFO } from 'graphql/lineOfCredit';

const VerifyBankAccountEmailContainer = () => {
  const { loading, data } = useQuery(GET_LINE_OF_CREDIT_INFO);
  const { hasLineOfCredit } = _.get(data, 'accountInfo') || {};
  const { hasDischargedLinesOfCredit } = _.get(data, 'me.account') || {};

  return (
    <VerifyBankAccountEmail
      hasDischargedLinesOfCredit={hasDischargedLinesOfCredit}
      hasLineOfCredit={hasLineOfCredit}
      loading={loading}
    />
  );
};

export default VerifyBankAccountEmailContainer;
