import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];
const secondaryPastelGreenColor = theme.colors.secondary['pastel-green'][100];

const LightingOutline = (props) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="4" y="4" width="48" height="48" rx="24" fill={secondaryPastelGreenColor} />
      <path
        d="M29.0008 18L20.0943 28.6879C19.7455 29.1064 19.5711 29.3157 19.5684 29.4925C19.5661 29.6461 19.6346 29.7923 19.7541 29.8889C19.8916 30 20.164 30 20.7089 30H28.0008L27.0008 38L35.9074 27.3121C36.2562 26.8936 36.4306 26.6843 36.4333 26.5075C36.4356 26.3539 36.3671 26.2077 36.2476 26.1111C36.1101 26 35.8377 26 35.2928 26H28.0008L29.0008 18Z"
        stroke={primaryDarkGreenColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="4" y="4" width="48" height="48" rx="24" stroke="#E8F3ED" strokeWidth="8" />
    </svg>
  );
};

export default LightingOutline;
