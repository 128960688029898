import React from 'react';
import { FormProvider } from 'react-hook-form';
import Button from 'components/Button';

import { SubmitButton, ErrorLabel, Select, MoneyInputField } from 'components/FormFields/v2';
import {
  INITIAL_CARD_PERMISSIONS,
  INITIAL_ACCOUNT_PERMISSIONS,
  INITIAL_PAYMENT_PERMISSIONS,
  INITIAL_TEAMS_AND_SETTINGS_PERMISSIONS,
  BILLING_PERMISSIONS,
} from 'constants/index';
import { PermissionToggleSection } from './components/PermissionToggleSection';
import useSetUserPermissionsForm from './hooks/useSetUserPermissionsForm';
import { SetUserPermissionsFormProps } from './SetUserPermissionsForm.types';
import { formatMoneyV2 } from 'utility/currency';
import { InternalContactRole } from 'types/user';
import { InfoTooltipForModals } from 'components/InfoTooltip';

const SetUserPermissionsForm = ({
  role,
  memberId,
  onSuccess,
  memberPermissions,
  allMembers,
  handleBack,
}: SetUserPermissionsFormProps) => {
  const {
    form,
    register,
    handleSubmit,
    onSubmit,
    setSelectedPermissions,
    isPermissionChecked,
    selectedPermissions,
    isCardsChecked,
    setIsCardsChecked,
    isAccountsChecked,
    setIsAccountsChecked,
    isPaymentsChecked,
    setIsPaymentsChecked,
    isTeamsAndSettingsChecked,
    setIsTeamsAndSettingsChecked,
    isBillingChecked,
    setIsBillingChecked,
    hasRequestError,
    loading,
    onSelectImportMember,
    options,
    isMemberContact,
    formattedAmount,
    setFormattedAmount,
  } = useSetUserPermissionsForm({
    role,
    memberId,
    onSuccess,
    memberPermissions,
    allMembers,
  });

  const permissionSet = [
    {
      title: 'Cards',
      values: INITIAL_CARD_PERMISSIONS,
      isTitleChecked: isCardsChecked,
      setIsTitleChecked: setIsCardsChecked,
    },
    {
      title: 'Accounts',
      values: INITIAL_ACCOUNT_PERMISSIONS,
      isTitleChecked: isAccountsChecked,
      setIsTitleChecked: setIsAccountsChecked,
    },
    {
      title: 'Payments',
      values: INITIAL_PAYMENT_PERMISSIONS,
      isTitleChecked: isPaymentsChecked,
      setIsTitleChecked: setIsPaymentsChecked,
    },
    {
      title: 'Teams and Settings',
      values: INITIAL_TEAMS_AND_SETTINGS_PERMISSIONS,
      isTitleChecked: isTeamsAndSettingsChecked,
      setIsTitleChecked: setIsTeamsAndSettingsChecked,
    },
    {
      title: 'Billing',
      values: BILLING_PERMISSIONS,
      isTitleChecked: isBillingChecked,
      setIsTitleChecked: setIsBillingChecked,
    },
  ];
  const shouldAllowPermissionUpdates = role === InternalContactRole.member;
  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="tw-space-y-8">
        <div className="tw-space-y-6 tw-px-8 tw-h-96 tw-overflow-auto">
          <div>
            {shouldAllowPermissionUpdates && (
              <Select
                options={options}
                name="Import Permissions"
                label="Import Permissions"
                placeholder="Select a Member"
                rootClass="tw-mt-3 tw-mb-4"
                onChange={onSelectImportMember}
                defaultValue="None"
              />
            )}
            {isMemberContact && [InternalContactRole.member, InternalContactRole.bookkeeper].includes(role) && (
              <div className="tw-py-2">
                <div className="tw-flex tw-flex-row tw-justify-between">
                  <p>Approval Limit</p>
                  <InfoTooltipForModals
                    message="This member can independently initiate payments up to the specified amount without requiring approvals"
                    onlyIcon
                  />
                </div>
                <MoneyInputField
                  name="individualLimit"
                  label="Per Payment"
                  value={formattedAmount}
                  currency="CAD"
                  moneyFormatter={formatMoneyV2}
                  setValue={setFormattedAmount}
                  rootClass="tw-flex-grow"
                  onChange={setFormattedAmount}
                  ref={register({ required: false })}
                />
              </div>
            )}
          </div>
          <div className="tw-border-t-2 tw-border-neutral-grey-4"></div>
          {shouldAllowPermissionUpdates &&
            permissionSet.map((section) => (
              <PermissionToggleSection
                key={section.title}
                sectionTitle={section.title}
                permissions={section.values}
                setSelectedPermissions={setSelectedPermissions}
                isPermissionChecked={isPermissionChecked}
                selectedPermissions={selectedPermissions}
                isTitleChecked={section.isTitleChecked}
                setIsTitleChecked={section.setIsTitleChecked}
              />
            ))}
        </div>
        {hasRequestError && (
          <div>
            <ErrorLabel error={{ message: 'Something went wrong. Please try again later.' }} className={undefined} />
          </div>
        )}
        <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex tw-justify-between">
          <Button secondary type="button" onClick={handleBack}>
            Back
          </Button>
          <SubmitButton disabled={loading}>Save Permissions</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default SetUserPermissionsForm;
