import React from 'react';
import { get } from 'lodash';

import BasePayeeItem from './BasePayeeItem';

const DetailsSection = ({ name, currency, cardNumber, cardType }) => (
  <div className="tw-my-6 tw-overflow-hidden">
    <div className="tw-w-1/2 tw-float-left">
      <div className="tw-text-sm tw-text-neutral-grey-1">Name</div>
      <div>{name}</div>
      <div className="tw-text-sm tw-text-neutral-grey-1 tw-mt-6">Account number</div>
      <div>{cardNumber}</div>
    </div>
    <div className="tw-w-1/2 tw-float-right">
      <div className="tw-text-sm tw-text-neutral-grey-1">Card Type</div>
      <div>{cardType.charAt(0).toUpperCase() + cardType.slice(1)}</div>
      <div className="tw-text-sm tw-text-neutral-grey-1 tw-mt-6">Currency</div>
      <div>{currency}</div>
    </div>
  </div>
);

const CreditCardPayeeItem = ({ payee, onPayPayee }) => {
  const creditCardPayee = get(payee, 'record', {});
  const { displayName, cardType, cardNumber, currency, verified } = creditCardPayee;

  const isVerified = verified;

  return (
    <BasePayeeItem
      payee={payee}
      onPayPayee={onPayPayee}
      payeeName={displayName}
      payeeCurrency={currency}
      isVerified={isVerified}
      isDeletable={true}
      isEditable={true}
    >
      <DetailsSection name={displayName} currency={currency} cardNumber={cardNumber} cardType={cardType} />
    </BasePayeeItem>
  );
};

export default CreditCardPayeeItem;
