import React from 'react';
import { BiCheck } from 'react-icons/bi';

import { InfoTooltipForModals } from 'components/InfoTooltip';
import { Banner, ReferAndEarnBanner } from 'components/UI';
import { BannerType } from 'components/UI/Banner';
import InfoTip2 from 'components/svg/InfoTip2';
import { FormFooter } from '..';
import { useComplete } from './hooks';
import { CompleteProps } from './Complete.types';

const Complete = ({ onFinish, onResetSteps, onComplete }: CompleteProps) => {
  const {
    payorName,
    payorEmail,
    payorReferenceId,
    accountName,
    formattedInitDate,
    formattedDueDate,
    formattedAmount,
    currency,
    invoiceNumber,
    formattedPaymentRequestFee,
    showPromo,
    handleClickPromo,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
    frequencyLabel,
    formattedEndDate,
    numberOfOccurrences,
    isMobile,
  } = useComplete({ onComplete });

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <div className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-secondary-light-green tw-mb-8">
          <div className="tw-mr-2 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
            <BiCheck className="tw-text-secondary-light-green" />
          </div>
          <div className="tw-text-sm tw-text-semantic-success">Your Payment Request has been sent to your payor.</div>
        </div>

        <div className="tw-flex tw-gap-8 tw-py-8 tw-pb-4">
          <div className="tw-flex tw-flex-col tw-space-y-8 tw-w-1/2">
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">Payor</div>
              <p className="tw-mt-1">{payorName}</p>
            </div>
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">Payor Email</div>
              <p className="tw-mt-1">{payorEmail}</p>
            </div>
            {payorReferenceId && (
              <div>
                <div className="tw-text-sm tw-text-neutral-grey-1">Payor Nickname ID</div>
                <p className="tw-mt-1">{payorReferenceId}</p>
              </div>
            )}
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">Deposit Into</div>
              <p className="tw-mt-1">{accountName}</p>
            </div>
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">Amount</div>
              <p className="tw-mt-1">{`${currency} ${formattedAmount}`}</p>
            </div>
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">
                Fee
                <InfoTooltipForModals
                  message="Fees will not be charged if your customer does not approve the payment request."
                  onlyIcon
                />
              </div>

              <p className="tw-mt-1">{`${currency} ${formattedPaymentRequestFee}`}</p>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-space-y-8 tw-w-1/2">
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">Initiated at</div>
              <p className="tw-mt-1">{formattedInitDate}</p>
            </div>
            {!isOngoing ? (
              <div>
                <div className="tw-text-sm tw-text-neutral-grey-1">Due Date</div>
                <p className="tw-mt-1">{formattedDueDate}</p>
              </div>
            ) : (
              <>
                <div>
                  <div className="tw-text-sm tw-text-neutral-grey-1">Frequency</div>
                  <p className="tw-mt-1">{frequencyLabel}</p>
                </div>
                <div>
                  <div className="tw-text-sm tw-text-neutral-grey-1">Start Date</div>
                  <p className="tw-mt-1">{formattedDueDate}</p>
                </div>
                {isStoppedByEndDate && formattedEndDate && (
                  <div>
                    <div className="tw-text-sm tw-text-neutral-grey-1">End Date</div>
                    <p className="tw-mt-1">{formattedEndDate}</p>
                  </div>
                )}
                {isStoppedByNumberOfOccurrences && numberOfOccurrences && (
                  <div>
                    <div className="tw-text-sm tw-text-neutral-grey-1">Number of Payments</div>
                    <p className="tw-mt-1">{numberOfOccurrences}</p>
                  </div>
                )}
                {isStoppedByCancel && <div>These payments will stop upon cancellation</div>}
              </>
            )}
            {invoiceNumber && (
              <div>
                <div className="tw-text-sm tw-text-neutral-grey-1">Invoice Number</div>
                <p className="tw-mt-1">{invoiceNumber}</p>
              </div>
            )}
          </div>
        </div>
        {!isMobile && <ReferAndEarnBanner horizontal />}
        {showPromo && (
          <Banner
            type={BannerType.secondary}
            title="Low fees can get even lower!"
            message="Save even more money by upgrading to Loop Plus ⚡"
            submitButtonLabel="Click here to upgrade"
            onSubmit={handleClickPromo}
            icon={<InfoTip2 />}
          />
        )}
      </div>
      <FormFooter
        onCancel={onFinish}
        onSubmit={onResetSteps}
        cancelButtonLabel="Close"
        submitButtonLabel="Request Another Payment"
      />
    </>
  );
};

export default Complete;
