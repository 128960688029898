import { useContext, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import useIsMember from 'hooks/useIsMember';
import useIsAdmin from 'hooks/useIsAdmin';
import { useToggle } from 'hooks';
import { ApprovalRequestsContext, FilterRequestsContext } from '../context';
import { GET_REIMBURSEMENT_REQUESTS } from 'graphql/reimbursementRequest';
import { APPROVAL_TAB, APPROVAL_REQUEST_STATUS } from 'components/Approvals/constants';
import {
  ReimbursementApprovalRequestDataType,
  CardExpenseRequestDataType,
  CompletedRequestDataType,
} from '../components/ApprovalRequest.types';
import { GET_CARD_EXPENSES } from 'graphql/cardApprovals';
import { GET_COMPLETED_EXPENSES } from 'graphql/completedExpenses';

const useApprovalRequestsContent = () => {
  const { selectedTab, setSelectedTab, setSelectedRequestId } = useContext(ApprovalRequestsContext);
  const {
    page,
    setPage,
    numPerPage,
    setNumPerPage,
    handleClearPagination,
    period,
    selectedCurrencies,
    selectedProducts,
    selectedMembers,
    selectedExpenseTypes,
  } = useContext(FilterRequestsContext);

  const { isMember } = useIsMember();

  const { isAdmin } = useIsAdmin();

  const [pendingTotalCount, setPendingTotalCount] = useState(0);
  const [completedTotalCount, setCompletedTotalCount] = useState(0);
  const [cardExpenseTotalCount, setCardExpenseTotalCount] = useState(0);

  const {
    isOpen: isOpenAddReimbursementRequestModal,
    open: openAddReimbursementRequestModal,
    close: closeAddReimbursementRequestModal,
  } = useToggle();

  const [pendingReimbursementRequests, setPendingReimbursementRequests] = useState<
    ReimbursementApprovalRequestDataType[]
  >([]);
  const [completedReimbursementRequests, setCompletedReimbursementRequests] = useState<CompletedRequestDataType[]>([]);
  const [cardExpenseRequests, setCardExpenseRequests] = useState<CardExpenseRequestDataType[]>([]);

  const [getCompletedReimbursementRequests, { loading: isCompletedLoading }] = useLazyQuery(GET_COMPLETED_EXPENSES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const completedReimbursementItems = get(data, 'completedExpenses.items', []);
      setCompletedReimbursementRequests(completedReimbursementItems);
      setCompletedTotalCount(get(data, 'completedExpenses.pageData.totalCount', 0));
      setSelectedRequestId(null);
    },
  });

  const [getPendingReimbursementRequests, { loading: isPendingLoading }] = useLazyQuery(GET_REIMBURSEMENT_REQUESTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const pendingReimbursementItems = get(data, 'expenseReimbursements.items', []);
      setPendingTotalCount(get(data, 'expenseReimbursements.pageData.totalCount', 0));
      setPendingReimbursementRequests(pendingReimbursementItems);
      if (pendingReimbursementItems?.length === 0 && page !== 0) {
        setPage(page - 1);
      }
      setSelectedRequestId(null);
    },
  });

  const [getCardExpenses, { loading: isCardExpensesLoading }] = useLazyQuery(GET_CARD_EXPENSES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const cardExpense = get(data, 'cardExpenses.items', []);
      setCardExpenseTotalCount(get(data, 'cardExpenses.pageData.totalCount', 0));
      setCardExpenseRequests(cardExpense);
      if (cardExpense.length === 0 && page !== 0) {
        setPage(page - 1);
      }
      setSelectedRequestId(null);
    },
  });

  useEffect(() => {
    getCardExpenses({
      variables: {
        page: `${page}`,
        numPerPage: `${numPerPage}`,
        startDate: period?.from,
        endDate: period?.to,
        products: selectedProducts.map((product) => product.id),
        currencies: selectedCurrencies,
        members: selectedMembers.map((member) => member.id),
      },
    });
  }, []);

  useEffect(() => {
    switch (selectedTab) {
      case APPROVAL_TAB.expenseReimbursements:
        getPendingReimbursementRequests({
          variables: {
            status: APPROVAL_REQUEST_STATUS.pending,
            page: `${page}`,
            numPerPage: `${numPerPage}`,
            startDate: period?.from,
            endDate: period?.to,
            currencies: selectedCurrencies,
            members: selectedMembers.map((member) => member.id),
          },
        });
        break;
      case APPROVAL_TAB.cardExpenses:
        getCardExpenses({
          variables: {
            page: `${page}`,
            numPerPage: `${numPerPage}`,
            startDate: period?.from,
            endDate: period?.to,
            products: selectedProducts.map((product) => product.id),
            currencies: selectedCurrencies,
            members: selectedMembers.map((member) => member.id),
          },
        });
        break;
      case APPROVAL_TAB.completed:
        getCompletedReimbursementRequests({
          variables: {
            page: `${page}`,
            numPerPage: `${numPerPage}`,
            startDate: period?.from,
            endDate: period?.to,
            products: selectedProducts.map((product) => product.id),
            currencies: selectedCurrencies,
            members: selectedMembers.map((member) => member.id),
            expenseTypes: selectedExpenseTypes.map((type) => type.replace(/\s/g, '')),
          },
        });
    }
  }, [
    selectedTab,
    page,
    numPerPage,
    getPendingReimbursementRequests,
    getCompletedReimbursementRequests,
    period,
    selectedCurrencies,
    selectedProducts,
    selectedMembers,
    selectedExpenseTypes,
  ]);

  return {
    selectedTab,
    setSelectedTab,
    pendingTotalCount,
    completedTotalCount,
    cardExpenseTotalCount,
    pendingReimbursementRequests,
    completedReimbursementRequests,
    cardExpenseRequests,
    isCardExpensesLoading,
    isPendingLoading,
    isCompletedLoading,
    isOpenAddReimbursementRequestModal,
    handleOpenAddReimbursementRequestModal: openAddReimbursementRequestModal,
    handleCloseAddReimbursementRequestModal: closeAddReimbursementRequestModal,
    setPage,
    setNumPerPage,
    handleClearPagination,
    page,
    numPerPage,
    isMember,
    isAdmin,
  };
};

export default useApprovalRequestsContent;
