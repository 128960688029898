import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const LockAndStar = ({ width = '148', height = '98', ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 74 49" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.6899 45.1247C13.9041 45.0161 13.3536 44.2833 13.4611 43.489L15.6022 27.6611C15.7097 26.8668 16.4346 26.3103 17.2203 26.4189L37.149 29.1736C37.9347 29.2822 38.4853 30.015 38.3778 30.8093L36.2366 46.6372C36.1292 47.4315 35.4043 47.988 34.6185 47.8794L14.6899 45.1247Z"
        fill="#85ACAA"
      />
      <path
        d="M13.2462 41.6364C12.452 41.5278 11.8955 40.795 12.0041 40.0007L14.1685 24.1728C14.2771 23.3785 15.0099 22.822 15.8041 22.9306L35.9488 25.6853C36.7431 25.7939 37.2996 26.5267 37.191 27.321L35.0266 43.1489C34.918 43.9432 34.1852 44.4997 33.3909 44.3911L13.2462 41.6364Z"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M33.0701 25.2921C33.1729 24.5402 33.656 21.0077 33.7588 20.2559C34.3021 16.2824 31.5216 12.6209 27.548 12.0776C23.5745 11.5342 19.9131 14.3147 19.3697 18.2883C19.2669 19.0401 18.7838 22.5726 18.681 23.3244"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.9513 32.5172C27.1143 31.3251 26.2802 30.2267 25.088 30.0637C23.8959 29.9007 22.7976 30.7348 22.6345 31.9269C22.5475 32.5636 22.7513 33.169 23.1354 33.6239L22.8621 35.6226C22.7535 36.4168 23.31 37.1496 24.1042 37.2582C24.8985 37.3668 25.6313 36.8103 25.7399 36.0161L26.0132 34.0174C26.5054 33.6824 26.8642 33.1539 26.9513 32.5172Z"
        fill="#4F5154"
      />
      <path
        d="M64.4764 8.83759C64.8441 8.58955 65.2845 9.03003 65.0365 9.39764L59.5373 17.5479C59.4453 17.6842 59.4453 17.8628 59.5373 17.9991L65.0365 26.1493C65.2845 26.517 64.8441 26.9574 64.4764 26.7094L56.3262 21.2102C56.1899 21.1182 56.0113 21.1182 55.8749 21.2102L47.7247 26.7094C47.3571 26.9574 46.9166 26.517 47.1647 26.1493L52.6639 17.9991C52.7559 17.8628 52.7559 17.6842 52.6639 17.5479L47.1647 9.39764C46.9166 9.03003 47.3571 8.58955 47.7247 8.83759L55.8749 14.3368C56.0113 14.4288 56.1899 14.4288 56.3262 14.3368L64.4764 8.83759Z"
        fill={primaryDarkGreenColor}
      />
    </svg>
  );
};

export default LockAndStar;
