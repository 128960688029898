import React from 'react';

import config from 'config';

const Footer = () => (
  <div className="tw-hidden lg:tw-flex tw-flex-grow-0 tw-items-center tw-justify-start">
    <small className="tw-text-neutral-grey-2">{config.year} © Loop. ALL Rights Reserved. </small>
    <a className="tw-ml-8" href={config.baseLegalUrl} target="_blank" rel="noreferrer">
      <small className="tw-underline tw-text-neutral-grey-2">Legal </small>
    </a>
  </div>
);

export default Footer;
