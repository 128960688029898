export const tableHeaders = [
  'Payor',
  'Deposit To',
  'Date Initiated',
  'Due Date',
  'Amount',
  'Frequency',
  'Status',
  'Action',
  'Confirmation',
];
