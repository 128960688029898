import { useCallback, useState } from 'react';

/**
 * A custom hook for handling any toggle logic.
 * E.g. modals, popups, tooltips, etc.
 *
 * @param {boolean: false} defaultValue
 * @returns
 */

const useToggle = (defaultValue = false) => {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((prevState) => !prevState), []);

  return { isOpen, open, close, toggle };
};

export default useToggle;
