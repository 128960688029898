import React, { memo } from 'react';

import config from 'config';
import { PopupButton } from '@typeform/embed-react';

const TypeFormSalesChannel = ({ businessName, onSubmit }) => {
  return (
    <div className="tw-my-8">
      Don't see your sales channel?{' '}
      <PopupButton
        id={config.typeformSalesChannelID}
        hidden={{
          business_name: businessName,
        }}
        onSubmit={onSubmit}
        className="tw-text-primary-dark-green tw-font-bold"
      >
        Click here
      </PopupButton>
      .
    </div>
  );
};

export default memo(TypeFormSalesChannel);
