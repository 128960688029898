import React from 'react';

import DashboardLayout from 'components/dashboard/DashboardLayout';
import { AccountsContextProvider } from 'context/Accounts';
import AccountsContent from 'components/Accounts/AccountsContent/AccountsContent';

const Accounts = () => {
  return (
    <DashboardLayout title="Accounts">
      <AccountsContextProvider>
        <AccountsContent />
      </AccountsContextProvider>
    </DashboardLayout>
  );
};

export default Accounts;
