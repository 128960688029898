import { PayBalanceCurrency } from 'types/payments';

export const DEFAULT_BALANCE = {
  amount: 0,
  currency: PayBalanceCurrency.CAD as const,
};

export const DEFAULT_BALANCE_IN_CENTS = {
  cents: 0,
  currency_iso: PayBalanceCurrency.CAD as const,
};

export const DEFAULT_GROUP_BALANCES = [
  {
    amount: 0,
    currency: PayBalanceCurrency.CAD as const,
  },
  {
    amount: 0,
    currency: PayBalanceCurrency.USD as const,
  },
  {
    amount: 0,
    currency: PayBalanceCurrency.EUR as const,
  },
  {
    amount: 0,
    currency: PayBalanceCurrency.GBP as const,
  },
];

export const DEFAULT_GLOBAL_LIMIT_INFO = {
  groupAmountDue: DEFAULT_BALANCE,
  groupAvailableBalance: DEFAULT_BALANCE,
  groupOverlimit: DEFAULT_BALANCE,
  groupTotalSpent: DEFAULT_BALANCE,
};

export const DEFAULT_CARD_BALANCES = {
  groupBalances: DEFAULT_GROUP_BALANCES,
  groupPendingBalances: DEFAULT_GROUP_BALANCES,
  groupPostedBalances: DEFAULT_GROUP_BALANCES,
  groupOngoingPayments: DEFAULT_GROUP_BALANCES,
};
