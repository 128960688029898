import React from 'react';
import CurrencyFlag from 'components/svg/CurrencyFlag';

const CombinedCurrencyFlag = () => {
  return (
    <div className="tw-relative">
      <div className="tw-absolute tw-p-px tw-bg-neutral-grey-5 tw-rounded-lg">
        <CurrencyFlag currency={'CAD'} size={16} />
      </div>

      <div className="tw-absolute tw-left-2 tw-top-2 tw-p-px tw-bg-neutral-grey-5 tw-rounded-lg">
        <CurrencyFlag currency={'GBP'} size={16} />
      </div>
    </div>
  );
};

export default CombinedCurrencyFlag;
