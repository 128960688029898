import React from 'react';

import config from 'config';
import Button from 'components/Button';
import { Modal } from 'components/UI';
import { Loaders } from 'components/cards/Loader';
import { DeleteRequestModalProps } from './DeleteRequestModal.types';
import { useDeleteRequestModal } from './hooks';

const DeleteRequestModal = ({ show, onClose, paymentRequest }: DeleteRequestModalProps) => {
  const { loading, handleDeleteRequest } = useDeleteRequestModal({ paymentRequest, onClose });

  return (
    <Modal show={show} onClose={onClose} title="Cancel Payment Request">
      {loading ? (
        <Loaders.Light />
      ) : (
        <div className="tw-px-12 tw-py-8 tw-border-t-2 tw-border-neutral-grey-4">
          <div className="tw-text-lg tw-mb-4">Are you sure you want to cancel this payment request?</div>
          <div className="tw-text-sm tw-text-neutral-grey-1">
            The payment and the following related payments (if applicable) will be canceled. Contact us at{' '}
            <a
              className="tw-font-semibold tw-text-primary-dark-green"
              target="_blank"
              href={`mailto:${config.supportEmail}`}
              rel="noreferrer"
            >
              {config.supportEmail}
            </a>{' '}
            if you need additional support.
          </div>
        </div>
      )}

      <div className="tw-py-4 tw-px-8 tw-flex tw-justify-between">
        <Button
          className="tw-bg-neutral-grey-5 tw-border-neutral-grey-4 tw-border tw-rounded-md tw-px-6 tw-py-2"
          onClick={onClose}
          isDisabled={loading}
          secondary
        >
          Back
        </Button>
        <Button
          className="tw-rounded-md tw-px-6 tw-py-2"
          onClick={handleDeleteRequest}
          isDisabled={loading}
          warning={true}
        >
          {loading ? 'Canceling...' : 'Cancel Payment Request'}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteRequestModal;
