import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_BUSINESS_DETAILS } from 'graphql/onboarding';
import { useToggle } from 'hooks';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { AuthContext } from 'context/Auth';
import { ONBOARDING_STEPS } from 'components/onboarding/constants';
import { useCheckPreApproval, usePreApprovalProgress } from '.';
import getSalesChannels from '../getSalesChannels';
import { PreApprovalStatus } from '../constants';

const useSelectAccountType = ({ loadingExternalAccounts, reload, externalAccounts }) => {
  useSetPageTitle(ONBOARDING_STEPS.accountType.label);

  const history = useHistory();
  const { meLoading } = useContext(AuthContext);

  const salesChannels = getSalesChannels({ reload });

  const connectedAccounts = externalAccounts?.filter((account) => {
    for (const sc of salesChannels) {
      if (sc.name.includes(account.name)) return true;
      if (sc?.platforms?.indexOf(account.name) > -1) return true;
    }
    return false;
  });

  const hasConnectedAccounts = !!connectedAccounts.length;

  const { checkStatus, preApprovalStatus } = useCheckPreApproval();

  const { progress, hasStatus } = usePreApprovalProgress({
    preApprovalStatus,
    checkStatus,
    hasConnectedAccounts,
    loadingExternalAccounts,
  });

  const loading = loadingExternalAccounts || meLoading;

  const { isOpen: isSelectSalesModalOpen, open: openSelectSalesModal, close: closeSelectSalesModal } = useToggle();
  const { isOpen: isEligibilityModalOpen, open: openEligibilityModal, close: closeEligibilityModal } = useToggle();

  const isApproved = preApprovalStatus === PreApprovalStatus.APPROVED;
  const isRejected = preApprovalStatus === PreApprovalStatus.REJECTED;

  const onContinue = () => history.push('/onboarding/business-details');

  const onAddMoreChannels = () => {
    closeEligibilityModal();
    openSelectSalesModal();
  };

  useEffect(() => {
    hasConnectedAccounts && !isRejected && openEligibilityModal();
  }, [isRejected, hasConnectedAccounts, openEligibilityModal]);

  useEffect(() => {
    isRejected && openSelectSalesModal();
  }, [isRejected, openSelectSalesModal]);

  const { data: businessData } = useQuery(GET_BUSINESS_DETAILS);
  const businessName = get(businessData, 'me.account.name');
  return {
    loading,
    progress,
    preApprovalStatus,
    isApproved,
    isRejected,
    hasStatus,
    salesChannels,
    connectedAccounts,
    isSelectSalesModalOpen,
    openSelectSalesModal,
    closeSelectSalesModal,
    isEligibilityModalOpen,
    closeEligibilityModal,
    onContinue,
    onAddMoreChannels,
    businessName,
  };
};

export default useSelectAccountType;
