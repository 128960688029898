import React, { useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { PasswordField, SubmitButton } from '../FormFields/v2';
import { AuthContext } from '../../context/Auth';
import Layout from './Layout';

const SignIn = () => {
  const form = useForm();
  const { submittingNewPassword, newPasswordError, completeNewPassword } = useContext(AuthContext);

  const { register, handleSubmit, clearErrors, setError } = form;

  const onSubmit = async ({ password }) => {
    completeNewPassword(password);
  };

  const validatePassword = ({ password, confirmPassword }) => {
    clearErrors(['password', 'confirmPassword']);

    if (password === confirmPassword) {
      clearErrors(['password', 'confirmPassword']);
      onSubmit({ password });
    } else {
      ['password', 'confirmPassword'].forEach((field) =>
        setError(field, { type: 'manual', message: 'Password and Password confirmation must match' })
      );
    }
  };

  return (
    <Layout>
      <h1 className="tw-mb-3">Set your password</h1>
      <p>Please set the new password for your account in the fields below</p>
      <FormProvider {...form}>
        <form className="tw-mt-8" onSubmit={handleSubmit(validatePassword)}>
          <div className="tw-mb-2">
            <PasswordField
              name="password"
              label="Password"
              placeholder="∗∗∗∗∗∗∗∗"
              ref={register({ required: true })}
              autoComplete="current-password"
              tabIndex={1}
            />
          </div>
          <PasswordField
            name="confirmPassword"
            label="Confirm new password"
            placeholder="∗∗∗∗∗∗∗∗"
            ref={register({ required: true })}
            autoComplete="current-password"
            tabIndex={2}
          />
          {newPasswordError && <span className="tw-text-semantic-error tw-text-xs">{newPasswordError}</span>}
          <SubmitButton
            type="submit"
            className="tw-w-full tw-mt-8 tw-font-bold"
            isFetching={loading}
            tabIndex={3}
            disabled={submittingNewPassword}
          >
            {submittingNewPassword ? 'Submitting' : 'Set and Sign in'}
          </SubmitButton>
        </form>
      </FormProvider>
    </Layout>
  );
};

export default SignIn;
