import React from 'react';

import { SettlementBannerProps } from './SettlementBanner.types';
import CombinedCurrencyFlag from './components/CombinedCurrencyFlag';

const SettlementBanner = ({ content, icon = <CombinedCurrencyFlag /> }: SettlementBannerProps) => {
  return (
    <div className="tw-bg-secondary-pastel-yellow-50 tw-p-3 tw-rounded-md tw-text-sm tw-flex tw-border-solid tw-border-2 tw-border-neutral-grey-2">
      <div className="tw-min-w-8 tw-mr-3">{icon}</div>

      <div className="message">{content}</div>
    </div>
  );
};

export default SettlementBanner;
