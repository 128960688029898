import React, { useEffect } from 'react';

import config from 'config';
import Banner from 'components/Banner';
import Button from 'components/Button';
import { CompleteProps } from './Complete.types';

const Complete = ({ onFinish, onComplete }: CompleteProps) => {
  useEffect(() => {
    onComplete();
  }, [onComplete]);

  return (
    <>
      <Banner isShowIcon message="Account information successfully updated" className="tw-my-6 tw-mx-8" />
      <div className="tw-px-8 tw-my-20">
        <p>
          Thank you for updating your account information. If you have any questions please reach out to{' '}
          <a
            className="tw-font-semibold"
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:${config.supportEmail}`}
          >
            <span className="tw-text-primary-dark-green">{config.supportEmail}</span>
          </a>
        </p>
      </div>
      <div className="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-4 tw-flex tw-justify-end">
        <Button primary onClick={onFinish}>
          Close
        </Button>
      </div>
    </>
  );
};

export default Complete;
