import React from 'react';

const SidebarLightningOutline = (props) => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.2783 0.546876L1 13.25H7L5.52297 21.3702C5.52016 21.3861 5.52088 21.4024 5.52507 21.4181C5.52927 21.4337 5.53684 21.4482 5.54725 21.4606C5.55767 21.473 5.57067 21.4829 5.58535 21.4897C5.60002 21.4965 5.61601 21.5 5.63219 21.5V21.5C5.6494 21.5 5.66637 21.4959 5.68175 21.4882C5.69713 21.4805 5.7105 21.4693 5.72078 21.4555L16 8.75H10L11.4841 0.628907C11.4861 0.612728 11.4846 0.596309 11.4797 0.580742C11.4749 0.565175 11.4668 0.550817 11.456 0.538623C11.4452 0.526429 11.4319 0.516678 11.417 0.510019C11.4021 0.50336 11.386 0.499945 11.3697 0.500001V0.500001C11.3518 0.500072 11.3343 0.504354 11.3184 0.512499C11.3025 0.520644 11.2888 0.532422 11.2783 0.546876V0.546876Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SidebarLightningOutline;
