import React, { useState, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { IoPencilOutline } from 'react-icons/io5';
import { useQuery } from '@apollo/client';
import { get, isEmpty } from 'lodash';

import { GET_BUSINESS_ADDRESS } from 'graphql/user';
import { SubmitButton } from 'components/FormFields/v2';
import { CreateCardContext } from './CreateCardContext';
import ConfirmPhysicalCardPayment from './ConfirmPhysicalCardPayment';
import NewCardDetailsStep from './NewCardDetailsStep';
import useCreateCreditCards from 'hooks/useCreateCreditCards';
import { useDeepEffect } from 'hooks';
import Button from 'components/Button';
import AddressFields from 'components/onboarding/AddressFields';
import { Loaders } from 'components/cards/Loader';

const ConfirmDeliveryAddressStep = ({ onClose, refetch, creditCards }) => {
  const { data, loading } = useQuery(GET_BUSINESS_ADDRESS);

  const address = get(data, 'me.account.address');

  const [isDisabled, setIsDisabled] = useState(true);
  const [navigateBack, setNavigateBack] = useState(false);
  const { cardFormData, setCardFormData } = useContext(CreateCardContext);
  const { isSubmitting, hasSubmitted, setHasSubmitted, error } = useCreateCreditCards();

  const { street, unitNumber, city, postalCode, countrySubdivision, country } = address || {};

  const form = useForm({
    defaultValues: {
      address: {
        street,
        unitNumber: unitNumber || '',
        city,
        postalCode,
        countrySubdivision,
        country,
      },
    },
  });

  const { handleSubmit, setValue } = form;

  const handleSubmitForm = async (data) => {
    const addressData = isDisabled ? address : data.address;
    const { street, unitNumber, city, postalCode, countrySubdivision, country } = addressData;
    setCardFormData({
      ...cardFormData,
      address: { street, unitNumber, city, postalCode, countrySubdivision, country },
    });
    setHasSubmitted(true);
  };

  const editDeliveryAddress = () => {
    setIsDisabled(false);
  };

  const onNavigateBack = () => {
    setNavigateBack(true);
  };

  useDeepEffect(() => {
    if (isEmpty(address)) return;

    setValue('address', address, { shouldDirty: true, shouldTouch: true });
  }, [setValue, address]);

  if (loading) return <Loaders.Light />;

  if (hasSubmitted) return <ConfirmPhysicalCardPayment onClose={onClose} refetch={refetch} creditCards={creditCards} />;

  if (navigateBack) return <NewCardDetailsStep onClose={onClose} refetch={refetch} creditCards={creditCards} />;

  return (
    <div className="tw-py-6">
      <div className="tw-flex tw-justify-between tw-px-10">
        <strong>Where do you want your physical card delivered?</strong>
        <div className="tw-flex tw-text-primary-dark-green tw-cursor-pointer" onClick={editDeliveryAddress}>
          <IoPencilOutline size={20} />
          <p>Edit</p>
        </div>
      </div>
      <div>
        <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="tw-px-10">
              <div className="tw-mt-8">
                <AddressFields
                  name="address"
                  streetAddressLabel="Business Street Address"
                  initialAddress={address}
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="tw-flex tw-justify-between tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-pt-6">
              <Button
                className="tw-w-max tw-bg-neutral-grey-5 tw-border tw-border-neutral-grey-3 tw-text-neutral-dark tw-py-2 tw-px-4 tw-rounded-md tw-text-center"
                onClick={onNavigateBack}
              >
                Back to Card Details
              </Button>
              <SubmitButton
                className={`tw-w-max ${isSubmitting ? 'tw-bg-neutral-grey-3' : 'tw-bg-primary-dark-green'}`}
              >
                <p className="tw-text-neutral-light">{isSubmitting ? 'Submitting...' : 'Next Step'}</p>
              </SubmitButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ConfirmDeliveryAddressStep;
