import { useShowSupplierBankDetailsProps } from '../ShowSupplierBankDetails.types';

const useShowSupplierBankDetails = ({ bankName, bankAccount }: useShowSupplierBankDetailsProps) => {
  const { accountNumber, institutionNumber, transitNumber } = bankAccount;
  const bankDetails = [
    { label: 'Institution Name', value: bankName },
    { label: 'Account Number', value: accountNumber },
    { label: 'Institution Number', value: institutionNumber },
    { label: 'Transit Number', value: transitNumber },
  ];

  return { bankDetails };
};

export default useShowSupplierBankDetails;
