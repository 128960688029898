import React from 'react';

type CardFXProps = {
  width?: number;
  height?: number;
};

const CardFX = ({ width = 25, height = 20 }: CardFXProps) => (
  <svg width={width} height={height} viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.125 0.5H3.875C2.42525 0.5 1.25 1.67525 1.25 3.125V12.875C1.25 14.3247 2.42525 15.5 3.875 15.5H18.125C19.5747 15.5 20.75 14.3247 20.75 12.875V3.125C20.75 1.67525 19.5747 0.5 18.125 0.5Z"
      fill="white"
      stroke="#26262C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.25 5H20.75" stroke="#26262C" strokeLinejoin="round" />
    <path d="M7.25 10.0625H5V11H7.25V10.0625Z" stroke="#26262C" strokeLinejoin="round" />
    <rect x="13.75" y="8.75" width="10.5" height="10.5" rx="5.25" fill="#FFFAEB" />
    <path
      d="M19.875 12.1406L20.5312 12.7969L19.875 13.4531"
      stroke="#FCB918"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.3125 12.7969H17.4688C17.1789 12.7977 16.9012 12.9133 16.6963 13.1182C16.4914 13.3231 16.3759 13.6008 16.375 13.8906V14.1094"
      stroke="#FCB918"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.125 15.8594L17.4688 15.2031L18.125 14.5469"
      stroke="#FCB918"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6875 15.2031H20.5312C20.8211 15.2023 21.0988 15.0867 21.3037 14.8818C21.5086 14.6769 21.6241 14.3992 21.625 14.1094V13.8906"
      stroke="#FCB918"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="13.75" y="8.75" width="10.5" height="10.5" rx="5.25" stroke="black" strokeWidth="0.5" />
  </svg>
);

export default CardFX;
