import React from 'react';
import ReactModal from 'react-modal';

import styles from './Modal.module.scss';

const lightModalStyle = {
  overlay: {
    zIndex: 99,
    background: 'rgba(0, 0, 0, 0.75)',
    border: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'relative',
    border: 0,
    background: 'rgba(0, 0, 0, 0)',
    width: '100%',
    maxWidth: '900px',
    maxHeight: '100%',
    padding: '0px',
    inset: '0px',
  },
};

const LightModal = (props) => {
  const { show, onClose, children } = props;
  return (
    <ReactModal
      isOpen={show}
      onRequestClose={onClose}
      onAfterClose={onClose}
      shouldFocusAfterRender={true}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      parentSelector={() => document.body}
      ariaHideApp={false}
      style={lightModalStyle}
    >
      {children}
    </ReactModal>
  );
};

const darkModalStyles = {
  content: {
    position: 'relative',
    border: '1px #14142B',
    borderRadius: '10px',
    background: '#14142B',
    color: 'white',
    width: '100%',
    maxWidth: '424px',
    maxHeight: '100%',
    padding: '0px',
    inset: '0px',
  },
};
const darkModalWithBorderStyles = { content: { ...darkModalStyles.content, border: '1px solid #d9dbe9' } };
const darkModalWithWideStyles = { content: { ...darkModalWithBorderStyles.content, maxWidth: '600px' } };

const DarkModal = (props) => {
  const { show, onClose, children, bordered, wide, ...otherProps } = props;

  const borderStyles = wide ? darkModalWithWideStyles : darkModalWithBorderStyles;

  return (
    <ReactModal
      style={bordered ? borderStyles : darkModalStyles}
      isOpen={show}
      onRequestClose={onClose}
      onAfterClose={onClose}
      shouldFocusAfterRender={true}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      parentSelector={() => document.body}
      ariaHideApp={false}
      overlayClassName={{
        base: styles.transparentOverlay,
        beforeClose: styles.beforeClose,
        afterOpen: styles.afterOpen,
      }}
      closeTimeoutMS={200}
      {...otherProps}
    >
      {children}
    </ReactModal>
  );
};

export const Modals = {
  Light: LightModal,
  Dark: DarkModal,
};

export default LightModal;
