export const ONBOARDING_STEPS = {
  accountType: {
    title: 'Would you like to apply for business credit?',
    label: 'Account Type',
  },
  businessDetails: {
    title: 'About Your Business',
    label: 'About Your Business',
  },
  businessAddress: { title: 'Business Address', label: 'Business Address' },
  ownersProfile: { title: 'Owner’s Profile', label: 'Owner’s Profile' },
  businessContacts: { title: 'Business Contacts', label: 'Business Contacts' },
  businessVerification: {
    title: 'Business Verification',
    label: 'Business Verification',
    preRevenue: {
      title: 'Proof of Operations',
      label: 'Proof of Operations',
    },
    revenue: {
      title: 'Proof of Revenue',
      label: 'Proof of Revenue',
    },
  },
  idVerification: { title: 'Identity Verification', label: 'ID Verification' },
};
