import React from 'react';
import cx from 'classnames';

import { SummaryProps } from '../../Summary';

const Header = ({ title, icon, iconClassName }: Pick<SummaryProps, 'title' | 'icon' | 'iconClassName'>) => (
  <div
    className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-border-b
      tw-border-neutral-grey-4"
  >
    <div className="tw-flex tw-gap-2">
      <div className="tw-w-6">
        <div className={cx('tw-flex tw-justify-center tw-items-center tw-rounded tw-w-full tw-h-full', iconClassName)}>
          {icon}
        </div>
      </div>
      <strong>{title}</strong>
    </div>
  </div>
);

export default Header;
