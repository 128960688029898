import React from 'react';
import cx from 'classnames';
import { Loaders } from 'components/cards/Loader';
import { toast } from 'react-toastify';

import { PricingPlanDetails } from './PricingPlan.types';
import { CURRENT_PLANS, ACTIONS } from '../constants';
import Button from 'components/Button';
import FreeFeatures from './FreeFeatures';
import PlusFeatures from './PlusFeatures';
import PowerFeatures from './PowerFeatures';
import useUpdateSubscription from '../hooks/useUpdateSubscription';
import { useGetSubscription } from 'hooks/';

import styles from './PricingPlanCard.module.scss';

const PricingPlanCard = ({ name, price, description, active = false }: PricingPlanDetails) => {
  const { updateSubscription, loading } = useUpdateSubscription();
  const { isPaidSubscription, name: currentSubscriptionName } = useGetSubscription();

  const cancelAllowed = name === CURRENT_PLANS.free && isPaidSubscription;

  const isPlusPlan = name === CURRENT_PLANS.plus;
  const isPowerPlan = name === CURRENT_PLANS.power;
  const isFreePlan = name === CURRENT_PLANS.free;

  const updatePlan = async (action: String, subscriptionName: String) => {
    const response = await updateSubscription({
      variables: {
        action: action,
        subscriptionName: subscriptionName,
      },
    });
    if (response.data?.updateSubscription === true) {
      toast.success('Subscription updated successfully');
      window.location.href = '/dashboard/subscriptions/active';
    } else {
      toast.error('Something went wrong. Please try again or contact support.');
    }
  };

  const buttonLabel = (cancelAllowed && 'Cancel') || (active && 'Current Plan') || 'Choose Plan';

  return (
    <div
      className={cx(
        'tw-w-full tw-bg-neutral-light tw-border tw-border-neutral-grey-4 tw-rounded-md',
        isPlusPlan && styles.isPrimary
      )}
    >
      <div className="tw-w-full tw-flex tw-flex-col">
        <div className="tw-w-full tw-p-6">
          <div className="tw-flex tw-justify-between">
            <div className="tw-text-base tw-font-semibold tw-mb-2">{name}</div>
            {active && (
              <div>
                <div className="tw-bg-secondary-light-green tw-rounded-md">
                  <div className="tw-text-xs tw-text-primary-dark-green tw-font-semibold tw-p-1">Active Plan</div>
                </div>
              </div>
            )}
          </div>
          <div className="tw-flex tw-items-baseline">
            <div className="tw-text-4xl tw-font-semibold">${price}</div>
            <div className="tw-text-sm tw-text-gray-500 tw-ml-2">per month</div>
          </div>
          <div className="tw-text-sm tw-text-gray-500 tw-mb-4 tw-pt-2">{description}</div>
          <div className="tw-mt-6 tw-text-center">
            <Button
              className={cx(
                'tw-font-semibold tw-w-3/4 tw-text-neutral-light tw-py-2 tw-px-4 tw-rounded-md tw-text-center',
                cancelAllowed && 'tw-bg-semantic-error',
                !cancelAllowed && (active ? 'tw-bg-neutral-grey-3' : 'tw-bg-primary-dark-green')
              )}
              disabled={active}
              onClick={() => {
                return cancelAllowed
                  ? updatePlan(ACTIONS.cancel, currentSubscriptionName)
                  : updatePlan(ACTIONS.create, name);
              }}
            >
              {loading ? <Loaders.Spinner /> : buttonLabel}
            </Button>
          </div>
        </div>
        <div className="tw-border tw-border-neutral-grey-4" />
        {isPlusPlan && <PlusFeatures singleView={false} />}
        {isPowerPlan && <PowerFeatures singleView={false} />}
        {isFreePlan && <FreeFeatures />}
      </div>
    </div>
  );
};

export default PricingPlanCard;
