import React, { useState } from 'react';
import { useLazyQuery, ApolloError } from '@apollo/client';
import { get } from 'lodash';

import { PayBalanceCurrency } from 'types/payments';
import { BankAccount } from 'types/bankAccount';
import { AccountWallet } from 'types/wallet';
import { GET_WALLETS_AND_BANK_ACCOUNTS } from 'graphql/wallets';
import { useDeepEffect } from 'hooks';

type Transaction = {
  originalAmount: {
    amount: number;
    currency: PayBalanceCurrency;
  };
  fromAccount?: {
    id: string;
    displayName: string;
    type: string;
  };
  transactionGroupId?: string;
  initiatedAt?: Date;
};

type AddFundsToBalanceType = {
  error: ApolloError | null;
  setError: React.Dispatch<React.SetStateAction<ApolloError | null>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  transaction: Transaction | null;
  setTransaction: React.Dispatch<React.SetStateAction<Transaction | null>>;
  wallets: AccountWallet[];
  bankAccounts: BankAccount[];
  getWalletsAndBankAccounts: () => void;
};

export const AddFundsToBalanceContext = React.createContext<AddFundsToBalanceType>({} as AddFundsToBalanceType);

export const AddFundsToBalanceContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<ApolloError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<Transaction | null>(null);
  const [wallets, setWallets] = useState<AccountWallet[]>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);

  const [getWalletsAndBankAccounts, { loading: isWalletsAndBankAccountsInfoLoading }] = useLazyQuery(
    GET_WALLETS_AND_BANK_ACCOUNTS,
    {
      fetchPolicy: 'cache-and-network',
      initialFetchPolicy: 'network-only',
      onCompleted: (data) => {
        const wallets = get(data, 'me.account.wallets') || [];
        const bankAccounts = get(data, 'me.account.bankAccounts') || [];

        setWallets(wallets);
        setBankAccounts(bankAccounts);
      },
      onError(error) {
        setError(error);
      },
    }
  );

  useDeepEffect(() => {
    setIsLoading(isWalletsAndBankAccountsInfoLoading);
  }, [isWalletsAndBankAccountsInfoLoading]);

  return (
    <AddFundsToBalanceContext.Provider
      value={{
        error,
        setError,
        isLoading,
        setIsLoading,
        transaction,
        setTransaction,
        wallets,
        bankAccounts,
        getWalletsAndBankAccounts,
      }}
    >
      {children}
    </AddFundsToBalanceContext.Provider>
  );
};
