import { useToggle } from 'hooks';
import { TRANSACTION_STATUS, TRANSACTION_PRODUCT_TYPES, SUPPORTED_CURRENCIES } from 'constants/index';
import { formatTransactionTableAmount } from 'utility/currency';
import { formatDateTime } from 'utility/date';
import { TRANSACTION_FORMATTED_DATE } from '../constants';

const useTransactionDetail = ({ transaction, accountNames, productType }) => {
  const { isOpen: isOpenOverwriteReceipt, open: openOverwriteReceipt } = useToggle();
  const { isOpen: isOpenUnlinkDialog, open: openUnlinkDialog, close: closeUnlinkDialog } = useToggle();

  const accountName = accountNames.find((name) => name === transaction.to);
  const formattedAmount = formatTransactionTableAmount({ transaction, accountName });
  const formattedDate = formatDateTime(transaction.createdAt, TRANSACTION_FORMATTED_DATE);
  const isPending =
    transaction.status === TRANSACTION_STATUS.PENDING ||
    transaction.status === TRANSACTION_STATUS.PROCESSING ||
    transaction.status === TRANSACTION_STATUS.FLAGGED_FOR_REVIEW ||
    transaction.status === TRANSACTION_STATUS.HELD;

  // TODO: Update condition when the card is Single Currency Card
  const cardFXTransaction =
    productType === TRANSACTION_PRODUCT_TYPES.creditCard &&
    transaction?.fxBillingAmount &&
    !SUPPORTED_CURRENCIES.includes(transaction.fxBillingAmount.currency);

  const accountFXTransaction = productType === TRANSACTION_PRODUCT_TYPES.wallet && transaction?.fxBillingAmount;
  const isFXTransaction = cardFXTransaction || accountFXTransaction;
  const deniedTransaction = transaction.status === TRANSACTION_STATUS.DENIED;
  const flaggedForReview = transaction.status === TRANSACTION_STATUS.FLAGGED_FOR_REVIEW;

  return {
    formattedAmount,
    formattedDate,
    accountFXTransaction,
    isPending,
    isFXTransaction,
    deniedTransaction,
    flaggedForReview,
    isOpenOverwriteReceipt,
    openOverwriteReceipt,
    isOpenUnlinkDialog,
    openUnlinkDialog,
    closeUnlinkDialog,
  };
};

export default useTransactionDetail;
