import React from 'react';

import { ConvertFundsCurrencySelectorModalProps } from './ConvertFundsCurrencySelectorModal.types';
import { Modal } from 'components/UI';
import ConvertFundsCurrencySelectorContent from './components/ConvertFundsCurrencySelectorContent/ConvertFundsCurrencySelectorContent';

const ConvertFundsCurrencySelectorModal = ({ show, onClose }: ConvertFundsCurrencySelectorModalProps) => (
  <Modal show={show} onClose={onClose} title="Convert Funds">
    <div className="tw-p-8">
      <ConvertFundsCurrencySelectorContent />
    </div>
  </Modal>
);

export default ConvertFundsCurrencySelectorModal;
