import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { IoCloseCircleOutline, IoSearchOutline } from 'react-icons/io5';
import { debounce } from 'lodash';
import qs from 'query-string';

import { GET_EXTERNAL_ACCOUNTS } from 'graphql/integrations';
import { AuthContext } from 'context/Auth';
import { SettingsContext } from 'context/Settings';
import { TransactionsContext } from 'context/Transactions';
import { FilterContext } from 'components/Transactions/context/FilterContext';
import { TRANSACTION_PRODUCT_TYPES, TRANSACTION_PRODUCT_TYPE_NAMES } from 'constants/index';
import { useDeepEffect } from 'hooks';

import { PushQboLink, PushWalletAccountingServiceLink, ExportsEmailModal, ProductsDropDown } from '..';
import { PeriodDropdown, CurrenciesDropdown, CategoriesDropdown, ExportsDropdown } from '../Dropdowns';
import { validAccountingIntegrations } from 'components/settings/Integrations/constants';
import useIsContactPermission from 'hooks/useIsContactPermission';

const Filter = ({ accountNames, className = '' }) => {
  // push to qbo link
  const { data: integrationsData } = useQuery(GET_EXTERNAL_ACCOUNTS);
  const { externalAccounts } = integrationsData || {};
  const { me } = useContext(AuthContext);
  const email = me?.email;
  const { manageAccountingIntegrations } = useIsContactPermission();
  const isNotMember = me?.internalContact?.role !== 'member';
  // -- push to qbo link

  // showModal values: false, CAUSES_EXPORT_FAILS.tooMany, CAUSES_EXPORT_FAILS.retrieving
  const [showModal, setShowModal] = useState(false);

  const [fileType, setFileType] = useState('');
  const { productType } = useContext(TransactionsContext);
  const accountingIntegrationEnabled = externalAccounts?.some((ea) => validAccountingIntegrations().includes(ea.name));
  const pushCardTransactionsLinkAvailable =
    productType === TRANSACTION_PRODUCT_TYPES.creditCard && accountingIntegrationEnabled && isNotMember;
  const pushWalletTransactionsLinkAvailable =
    productType === TRANSACTION_PRODUCT_TYPES.wallet && accountingIntegrationEnabled && isNotMember;
  const {
    search,
    setSearch,
    // showOnlyPending,
    // setShowOnlyPending,
    period,
    setPeriod,
    currentBillingCycle,
    previousBillingCycle,
    setCurrentBillingCycle,
    setPreviousBillingCycle,
    products,
    setProducts,
    currencies,
    setCurrencies,
    categories,
    setCategories,
    hasDropdownFilters,
    handleClearFilters,
    resetFilters,
  } = useContext(FilterContext);

  const searchInputRef = useRef(null);

  useEffect(() => {
    searchInputRef.current.value = search;
  }, [search]);

  const { setExternalAccounts } = useContext(SettingsContext);

  useDeepEffect(() => {
    if (externalAccounts) setExternalAccounts(externalAccounts);
  }, [externalAccounts]);

  // reset filters when product type changes
  useDeepEffect(() => {
    const { productType: productTypeFromSearch } = qs.parse(location?.search);

    if (productTypeFromSearch === productType) return;

    resetFilters();
  }, [productType, resetFilters, location?.search]);

  return (
    <>
      <h2 className="tw-font-semibold tw-mr-8">{`${TRANSACTION_PRODUCT_TYPE_NAMES[productType]} Transactions`}</h2>

      <div className={`${className} tw-flex tw-justify-between tw-items-center`}>
        <div className="tw-flex tw-items-center">
          <div className="tw-text-sm tw-text-neutral-grey-2">Filter</div>
          <PeriodDropdown
            testId="filter-period-dropdown"
            label="Period"
            selectedValue={period}
            setSelectedValue={setPeriod}
            className="tw-ml-2"
            portalId="desktop-root"
            currentBillingCycle={currentBillingCycle}
            previousBillingCycle={previousBillingCycle}
          />
          {productType !== TRANSACTION_PRODUCT_TYPES.lineOfCredit && (
            <ProductsDropDown
              testId="filter-products-dropdown"
              label="Product"
              selectedValues={products}
              setSelectedValues={setProducts}
              onChangeCurrentBillingCycle={setCurrentBillingCycle}
              onChangePreviousBillingCycle={setPreviousBillingCycle}
            />
          )}
          <CurrenciesDropdown
            testId="filter-currency-dropdown"
            label="Currency"
            selectedValues={currencies}
            setSelectedValues={setCurrencies}
          />
          {productType === TRANSACTION_PRODUCT_TYPES.creditCard && (
            <CategoriesDropdown
              testId="filter-category-dropdown"
              label="Category"
              selectedValues={categories}
              setSelectedValues={setCategories}
            />
          )}
          <div className="tw-relative tw-flex tw-flex-col">
            <div className="tw-absolute tw-py-2.5 tw-pl-3 tw-flex tw-items-center">
              <IoSearchOutline size={24} className="tw-mr-2" />
            </div>
            <input
              ref={searchInputRef}
              data-testid="filter-search-text-input"
              placeholder="Search Transactions"
              className="tw-block tw-px-3 tw-py-2 tw-pl-12 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input"
              onChange={useMemo(
                () =>
                  debounce((evt) => {
                    setSearch(evt.target.value);
                  }, 500),
                []
              )}
            />
          </div>
          {hasDropdownFilters && (
            <div
              onClick={handleClearFilters}
              data-testid="filter-clear-button"
              className="tw-flex tw-items-center tw-cursor-pointer tw-text-neutral-grey-2 tw-ml-2"
            >
              <IoCloseCircleOutline size={18} className="tw-mr-2" />
              <div className="tw-text-sm">Clear all</div>
            </div>
          )}
        </div>

        <div className="tw-flex tw-flex-col">
          {pushCardTransactionsLinkAvailable && manageAccountingIntegrations && (
            <PushQboLink
              accountName={externalAccounts?.find((ea) => validAccountingIntegrations().includes(ea.name))?.name}
            />
          )}
          {pushWalletTransactionsLinkAvailable && (
            <PushWalletAccountingServiceLink
              accountName={externalAccounts?.find((ea) => validAccountingIntegrations().includes(ea.name))?.name}
            />
          )}
          <ExportsDropdown
            accountNames={accountNames}
            onShowModal={(value) => setShowModal(value)}
            setFileType={(type) => setFileType(type)}
          />
          <ExportsEmailModal
            showModal={showModal !== false}
            handleClose={() => setShowModal(false)}
            email={email}
            fileType={fileType}
            cause={showModal}
          />
        </div>
      </div>
    </>
  );
};

export default Filter;
