import React, { useState, createContext } from 'react';

import { Payor } from 'types/invoicing';
import { Wallet } from 'types/wallet';
import { PayorsContextType } from 'components/Invoices/Invoices.types';

export const PayorsContext = createContext<PayorsContextType>({} as PayorsContextType);

export const PayorsContextProvider = ({ wallets, children }: { wallets: Wallet[]; children: React.ReactNode }) => {
  const [selectedPayorId, setSelectedPayorId] = useState('');
  const [payors, setPayors] = useState<Payor[]>([]);
  const [loading, setLoading] = useState(false);

  return (
    <PayorsContext.Provider
      value={{
        payors,
        setPayors,
        wallets,
        loading,
        setLoading,
        selectedPayorId,
        setSelectedPayorId,
      }}
    >
      {children}
    </PayorsContext.Provider>
  );
};
