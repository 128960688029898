import React from 'react';

import AddPayeePromotionPrompt from './AddPayeePromotionPrompt';

const EmptyState = () => {
  return (
    <div className="tw-p-4">
      <AddPayeePromotionPrompt />
    </div>
  );
};

export default EmptyState;
