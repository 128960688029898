import React from 'react';

import { BankDetailsProps } from './BankDetails.types';
import { useBankDetails } from './hooks';
import CCBankAccountDetailsContainer from 'containers/accounts/CurrencyCloudBankAccountDetails.js';
import USDBankAccountDetailsContainer from 'containers/accounts/USDBankAccountDetails';

const BankDetails = ({ selectedWalletCurrency, solidInternalId }: BankDetailsProps) => {
  const { isCurrencyCloudAccount, isSolidAccount } = useBankDetails({ selectedWalletCurrency });

  return (
    <>
      {isCurrencyCloudAccount && <CCBankAccountDetailsContainer currency={selectedWalletCurrency} />}
      {isSolidAccount && solidInternalId && <USDBankAccountDetailsContainer accountInternalId={solidInternalId} />}
    </>
  );
};

export default BankDetails;
