import { gql } from '@apollo/client';

export const GET_EXTERNAL_ACCOUNTS = gql`
  query ExternalAccounts {
    externalAccounts: externalAccounts {
      connected
      id
      name
      uid
      ownerImage
      ownerName
    }
  }
`;

export const GET_BANK_ACCOUNTS = gql`
  query BankAccounts {
    bankInfo {
      complete
      allowBankAccountCreation
      bankConnectionError
      bankAccounts {
        connected
        id
        institutionName
        maskedNumber
        reconnectionRequired
        flinksConnectionId
        verified
        default
        currency
        email
        mfaQuestions
        mfaAnswersRequired
        depositVerificationStatus
      }
    }
  }
`;

export const DELETE_EXTERNAL_ACCOUNT = gql`
  mutation DeleteExternalAccount($externalAccountId: ID!) {
    deleteExternalAccount(externalAccountId: $externalAccountId)
  }
`;

export const ANSWER_FLINKS_MFA_QUESTIONS = gql`
  mutation AnswerFlinksMFAQuestions($bankAccount: String!, $answers: [FlinksMFAAnswerInput!]!) {
    answerFlinksMfaQuestions(bankAccount: $bankAccount, answers: $answers) {
      id
      reconnectionRequired
    }
  }
`;

export const DELETE_BANK_ACCOUNT = gql`
  mutation DeleteBankAccount($bankAccountId: ID!) {
    deleteBankAccount(bankAccountId: $bankAccountId)
  }
`;

export const GET_AUTH_URL = gql`
  query AuthUrl($integration: String!, $onboarding: Boolean, $preApproval: Boolean) {
    integrationsAuth(integration: $integration, onboarding: $onboarding, preApproval: $preApproval)
  }
`;

export const SUBMIT_WOO_COMMERCE = gql`
  mutation SubmitWooCommerce($storeUrl: String!, $onboarding: Boolean, $preApproval: Boolean) {
    submitWooCommerce(storeUrl: $storeUrl, onboarding: $onboarding, preApproval: $preApproval)
  }
`;

export const SET_DEFAULT_BANK_ACCOUNT = gql`
  mutation SetDefaultBankAccount($bankAccountId: ID!) {
    setDefaultBankAccount(bankAccountId: $bankAccountId)
  }
`;

export const VERIFY_BANK_ACCOUNT_DEPOSIT = gql`
  mutation VerifyBankAccountDeposit($bankAccountId: ID!, $amount1: Int!, $amount2: Int!) {
    verifyBankAccountDeposit(bankAccountId: $bankAccountId, amount1: $amount1, amount2: $amount2) {
      verified
      remainingAttempts
    }
  }
`;
