import React from 'react';
import { useHistory } from 'react-router-dom';

import { useGetUserInfo } from 'hooks';

import Button from 'components/Button';
import Warning2 from 'components/svg/Warning2';

const BankAccountErrorBanner = () => {
  const history = useHistory();

  const { isKYCApproved } = useGetUserInfo();

  const documentSettingsUrl = isKYCApproved
    ? '/dashboard/settings/documents'
    : '/onboarding/dashboard/settings/documents';

  const redirectToDocuments = () => history.push(documentSettingsUrl);

  return (
    <div className="tw-mx-8 tw-px-6 tw-py-4 tw-mb-8 tw-w-2/3 tw-flex tw-flex-row tw-items-center tw-bg-secondary-pastel-yellow-50">
      <Warning2 size="160" />
      <div className="tw-mx-8 tw-px-6 tw-py-4 tw-mb-4 tw-flex tw-flex-col">
        <h5 className="tw-text-neutral-grey-1 tw-font-semibold tw-pl-4">
          Unable to Automatically Connect Your Bank Account
        </h5>
        <span className="tw-text-neutral-grey-1 tw-pl-4">
          To complete the connection, please upload a bank statement exported from your online banking platform. Our
          team will verify the document and complete the connection.
        </span>
        <Button className="tw-mt-4 tw-ml-4 tw-mr-12 tw-w-1/3 tw-font-semibold" onClick={redirectToDocuments} primary>
          Upload Bank Statement
        </Button>
      </div>
    </div>
  );
};

export default BankAccountErrorBanner;
