import { ADMIN_PERMISSIONS, BOOKKEEPER_PERMISSIONS, READ_ONLY_PERMISSIONS } from 'constants/index';
import { InternalContactRole } from 'types/user';

export const getPermissionSet = (role: string): { label: string; value: string }[] => {
  switch (role.toLowerCase()) {
    case InternalContactRole.admin:
      return ADMIN_PERMISSIONS;
    case InternalContactRole.read_only:
      return READ_ONLY_PERMISSIONS;
    case InternalContactRole.bookkeeper:
      return BOOKKEEPER_PERMISSIONS;
    default:
      return [];
  }
};
