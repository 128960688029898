import React from 'react';

import useIsMobile from 'hooks/useIsMobile';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import PaymentsDesktop from './desktop/Payments';
import PaymentsMobile from './mobile/Payments';

const Payments = () => {
  const isMobile = useIsMobile();

  return <DashboardLayout>{isMobile ? <PaymentsMobile /> : <PaymentsDesktop />}</DashboardLayout>;
};

export default Payments;
