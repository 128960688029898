import React, { useContext } from 'react';
import GiftOutline from 'components/svg/GiftOutline';

import { RewardsContext } from '../contexts/RewardsContext';

export const Summary = ({ className }) => {
  const { rewardsAccount } = useContext(RewardsContext);
  return (
    <>
      <div className={`${className} tw-flex tw-justify-between tw-w-80 tw-p-4 tw-border tw-border-neutral-grey-4`}>
        <span className="tw-text-neutral-grey-1 tw-mr-4 tw-self-center">Total Points Balance</span>
        <div className="  tw-flex tw-flex-row tw-items-center">
          <span className="tw-font-semibold tw-ml-3 tw-mr-2 tw-text-lg">
            {(rewardsAccount && Intl.NumberFormat().format(rewardsAccount.balance)) || 0}
          </span>
          <GiftOutline className="tw-text-neutral-grey-1" />
        </div>
      </div>
    </>
  );
};
