import { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ampTrackEvent } from 'amplitude';

import { UPDATE_CUSTOMER_FILES_UPLOAD_EMAIL } from 'graphql/settings';
import { GET_ME } from 'graphql/user';
import { EMAIL_LOCAL_PART_REGEX } from 'constants/regex';

const useCustomModal = ({ show, existingLoopDomainEmail }: { show: boolean; existingLoopDomainEmail?: string }) => {
  const existingLoopDomainEmailLocalPart = (existingLoopDomainEmail || '').split('@')[0];
  const inputRef = useRef<HTMLDivElement | null>(null);

  const focusMailInput = () => {
    inputRef.current?.focus();
  };

  const form = useForm<{ loopEmailLocalPart: string }>({
    defaultValues: {
      loopEmailLocalPart: existingLoopDomainEmailLocalPart,
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    reset({
      loopEmailLocalPart: existingLoopDomainEmailLocalPart,
    });
  }, [existingLoopDomainEmailLocalPart, reset, show]);

  const ref = register({ required: true, pattern: EMAIL_LOCAL_PART_REGEX });

  const [updateLoopEmailAlias, { loading: isSubmitting, error }] = useMutation<{
    updateLoopEmailAlias: (loopEmailLocalPart: string) => {
      data: { updateLoopEmailAlias: { success: boolean } };
    };
  }>(UPDATE_CUSTOMER_FILES_UPLOAD_EMAIL, {
    refetchQueries: [{ query: GET_ME, fetchPolicy: 'network-only' }],
    awaitRefetchQueries: true,
  });

  const loopEmailLocalPart = watch('loopEmailLocalPart');

  const onSubmit = async () => {
    try {
      await updateLoopEmailAlias({
        variables: {
          loopEmailLocalPart: loopEmailLocalPart,
        },
      });
      ampTrackEvent('loopEmail: Update success');
      toast.success('Successfully updated the custom email.');
    } catch (err) {
      console.error(err);
      toast.error('Error updating the custom email.');
      ampTrackEvent('loopEmail: error');
    }
  };

  return {
    existingLoopDomainEmail,
    existingLoopDomainEmailLocalPart,
    inputRef,
    focusMailInput,
    form,
    register,
    handleSubmit,
    watch,
    ref,
    updateLoopEmailAlias,
    isSubmitting,
    error,
    loopEmailLocalPart,
    isDirty,
    onSubmit,
  };
};

export default useCustomModal;
