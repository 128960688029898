import React, { useContext } from 'react';

import { formatMoneyV2 } from 'utility/currency';
import { PaymentContext } from 'context/Payment';
import Button from 'components/Button';

const Review = ({ bankAccounts, onPreviousStep, onNextStep, loading, footerBorder }) => {
  const { addMoneyInfo } = useContext(PaymentContext);
  const { toAccount, fromAccount, amount } = addMoneyInfo;

  const onSubmit = () => {
    const isFromBankAccount = bankAccounts.some((ba) => ba.id === fromAccount.id);
    const preDepositAuthorization = isFromBankAccount ? fromAccount.preDepositAuthorization : true;

    onNextStep(preDepositAuthorization);
  };

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <small className="tw-text-neutral-grey-2">Please confirm your transfer details.</small>
        <div className="tw-flex tw-flex-col tw-pt-8 tw-pb-4">
          <small>Recipient Account</small>
          <p className="tw-mt-1">{toAccount.displayName}</p>
          <small className="tw-mt-4">From Account</small>
          <p className="tw-mt-1">{fromAccount.displayName}</p>
          <small className="tw-mt-4">Amount</small>
          <p className="tw-mt-1">{`${amount.currency} ${formatMoneyV2(amount)}`}</p>
          <small className="tw-mt-4">Method</small>
          <p className="tw-mt-1">Domestic Transfer</p>
          <small className="tw-mt-4">Fees</small>
          <p className="tw-mt-1">{`${amount.currency} ${formatMoneyV2({ amount: 0, currency: amount.currency })}`}</p>
        </div>
      </div>
      <div
        className={`${
          footerBorder ? 'tw-border-t tw-border-neutral-grey-3' : ''
        } tw-px-8 tw-py-4 tw-flex tw-flex-col tw-space-y-4 lg:tw-space-y-0 lg:tw-flex-row tw-justify-between`}
      >
        <Button secondary onClick={onPreviousStep} className="tw-w-full lg:tw-w-max">
          Edit Transfer Details
        </Button>
        <Button
          disabled={loading}
          onClick={onSubmit}
          className={`${
            loading ? 'tw-bg-neutral-grey-3' : 'tw-bg-primary-dark-green'
          } tw-text-neutral-light tw-py-2 tw-px-4 tw-rounded-md tw-text-center tw-w-full lg:tw-w-max`}
        >
          {loading ? 'Submitting...' : 'Submit Transfer'}
        </Button>
      </div>
    </>
  );
};

export default Review;
