import React, { useContext } from 'react';

import { formatDateTime } from 'utility/date';
import { mapTransactionsByDate } from 'utility/transactions';
import { PointsTransactionsContext } from 'context/PointsTransactions';

import { TableSectionProps } from './PointsTransaction.types';
import { PointsTransaction } from 'types/transactions';

const TransactionsTable = () => {
  const { transactions } = useContext(PointsTransactionsContext);

  const today = formatDateTime(new Date());
  const transactionsByDate = mapTransactionsByDate(transactions);

  return (
    <table className="tw-table tw-table-auto tw-w-full">
      <thead>
        <tr>
          <th className="tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Description</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-right">Amount</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-right">Balance</th>
        </tr>
      </thead>
      {Object.keys(transactionsByDate).map((date) => {
        const isToday = today === date;

        return <TableSection key={date} date={date} transactions={transactionsByDate[date]} isToday={isToday} />;
      })}
    </table>
  );
};

const TableSection = ({ date, transactions, isToday }: TableSectionProps) => (
  <tbody>
    <tr>
      <th className="tw-bg-neutral-grey-5 tw-py-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal" colSpan={6}>
        {isToday ? 'Today' : date}
      </th>
    </tr>
    {transactions.map((transaction, i) => (
      <TransactionRow key={i} transaction={transaction} />
    ))}
  </tbody>
);

const TransactionRow = ({ transaction }: { transaction: PointsTransaction }) => {
  const isPositive = transaction.points >= 0;

  return (
    <tr className="tw-cursor-pointer">
      <td className="tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{transaction.label}</td>
      <td
        className={`tw-px-3 tw-py-4 tw-text-sm tw-text-right tw-border-b tw-border-neutral-grey-5 ${
          isPositive ? 'tw-text-semantic-success' : 'tw-text-semantic-error'
        }`}
      >
        {transaction.points}
      </td>
      <td className={`tw-px-3 tw-py-4 tw-text-sm tw-text-right tw-border-b tw-border-neutral-grey-5`}>
        {transaction.balance}
      </td>
    </tr>
  );
};

export default TransactionsTable;
