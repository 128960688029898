import React, { Dispatch } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TextField, SubmitButton } from 'components/FormFields/v2';
import { InitialContactType } from './SetUserInfoForm.types';

const SetUserInfoForm = ({
  initialContact,
  setCurrentStep,
  setFirstName,
  setLastName,
  setEmail,
}: {
  initialContact: InitialContactType;
  setCurrentStep: Dispatch<React.SetStateAction<number>>;
  setFirstName: Dispatch<React.SetStateAction<string>>;
  setLastName: Dispatch<React.SetStateAction<string>>;
  setEmail: Dispatch<React.SetStateAction<string>>;
}) => {
  const form = useForm({
    defaultValues: {
      firstName: initialContact?.firstName || '',
      lastName: initialContact?.lastName || '',
      email: initialContact?.email || '',
    },
  });
  const { handleSubmit, register } = form;

  const onSubmit = (data: { firstName: string; lastName: string; email: string }) => {
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);
    setCurrentStep(1);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="tw-space-y-8">
        <div className="tw-space-y-6 tw-px-8">
          <div className="tw-flex tw-space-x-4 tw-w-full">
            <TextField
              label="First Name"
              name="firstName"
              rootClass="tw-flex-grow"
              required
              ref={register({ required: true })}
            />
            <TextField
              label="Last Name"
              name="lastName"
              rootClass="tw-flex-grow"
              required
              ref={register({ required: true })}
            />
          </div>
          <TextField label="Email address" name="email" required ref={register({ required: true })} />
        </div>
        <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex tw-justify-end">
          <SubmitButton>Next Step</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default SetUserInfoForm;
