import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

import { SOURCE_LOC } from 'constants/sources';
import { Modals } from 'components/wallets/modals';
import CardAndStar2 from '../svg/CardAndStar2';
import useIsMobile from 'hooks/useIsMobile';
import Button from 'components/Button';

const MoveMoneyPromotionPrompt = ({
  hasLineOfCredit,
  lineOfCredit,
  lineOfCreditLedgerAccount,
  wallets,
  bankAccounts,
  creditCards,
}) => {
  const [showMoveMoneyModal, setShowMoveMoneyModal] = useState(false);

  const onShowMoveMoneyModal = () => setShowMoveMoneyModal(true);

  const isMobile = useIsMobile();
  const history = useHistory();

  const navigateToPayments = () => history.push('/dashboard/payments');

  return (
    <div className="tw-bg-primary-dark-green tw-flex tw-justify-between tw-rounded tw-my-6 tw-px-4 tw-pt-5 tw-pb-4">
      <div className="tw-flex tw-items-center">
        <div className="tw-ml-2 tw-mr-6">
          <CardAndStar2 width="80" height="70" vbWidth="80" vbHeight="70" />
        </div>
        <div>
          <small className="tw-font-semibold tw-text-primary-light-green tw-block">Move your money</small>
          <small className="tw-text-primary-light-green tw-mt-1 tw-mb-3 tw-block">
            Start using your Line of Credit by moving money to any other product.
          </small>
          <Button onClick={isMobile ? navigateToPayments : onShowMoveMoneyModal} data-testid="move-money-btn">
            <small className="tw-font-bold tw-text-neutral-light">
              Move money <BsArrowRight className="tw-inline-block" />
            </small>
          </Button>
        </div>
        <Modals.MoveMoney
          show={showMoveMoneyModal}
          setShow={setShowMoveMoneyModal}
          finishButtonLabel="Back to Line of Credit"
          hasLineOfCredit={hasLineOfCredit}
          lineOfCredit={lineOfCredit}
          lineOfCreditLedgerAccount={lineOfCreditLedgerAccount}
          wallets={wallets}
          bankAccounts={bankAccounts}
          creditCards={creditCards}
          source={SOURCE_LOC}
          isModal
        />
      </div>
    </div>
  );
};

export default MoveMoneyPromotionPrompt;
