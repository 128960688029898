import React, { useEffect, useContext } from 'react';
import { BsArrowRight } from 'react-icons/bs';

import { AccountsContext } from 'context/Accounts';
import CashAndStars from 'components/svg/CashAndStars';
import Button from 'components/Button';
import { useAccountsModal } from '../../AccountsModal/hooks';
import { Currencies } from 'constants/currencies';
import { Modals } from 'components/wallets/modals';
import OpenCADAccountModal from 'components/wallets/OpenCADAccountModal';
import { useToggle } from 'hooks';

type OpenFreeAccountBannerProps = { search?: string };

const OpenFreeAccountBanner = ({ search }: OpenFreeAccountBannerProps) => {
  const { refetchAccountsPageData } = useContext(AccountsContext);

  const searchParams = new URLSearchParams(search);
  const openAccountParam = searchParams.get('open-account');
  const currencyParam = searchParams.get('currency');

  const {
    isOpen: showAddUSAccountModal,
    open: setShowAddUSAccountModal,
    close: setHideAddUSAccountModal,
  } = useToggle();
  const {
    isOpen: showAddEURGBPAccountModal,
    open: setShowAddEURGBPAccountModal,
    close: setHideAddEURGBPAccountModal,
  } = useToggle();
  const {
    isOpen: showAddCADAccountModal,
    open: setShowAddCADAccountModal,
    close: setHideAddCADAccountModal,
  } = useToggle();

  const { currency, coreExternalWalletAccountId, coreExternalWalletAccountStatus } = useAccountsModal();
  const isCCAccount = currency === Currencies.EUR || currency === Currencies.GBP;
  const isUSDAccount = currency === Currencies.USD;
  const isCADAccount = currency === Currencies.CAD;

  const setCurrency = () => {
    setHideAddEURGBPAccountModal();
  };

  useEffect(() => {
    if (!openAccountParam) return;

    if (currencyParam === Currencies.EUR || currencyParam === Currencies.GBP) {
      setShowAddEURGBPAccountModal();
    } else if (currencyParam === Currencies.USD) {
      setShowAddUSAccountModal();
    }
  }, [openAccountParam, currencyParam]);

  const handleOpenAccount = () => {
    if (isCCAccount) {
      setShowAddEURGBPAccountModal();
    } else if (isUSDAccount) {
      setShowAddUSAccountModal();
    } else if (isCADAccount) {
      setShowAddCADAccountModal();
    }
  };

  const getbannerTitle = () => {
    if (isCADAccount) {
      if (coreExternalWalletAccountId && coreExternalWalletAccountStatus === 'inactive') {
        return 'Finish Opening a CAD Business Operating Account';
      } else if (!coreExternalWalletAccountId) {
        return (
          <div>
            <div className="tw-bg-secondary-bright-green-50 tw-text-primary-dark-green tw-rounded-md tw-inline tw-px-2 tw-mr-3">
              Beta
            </div>
            Open a Free CAD Business Operating Account
          </div>
        );
      }
    } else if (isCCAccount || isUSDAccount) {
      return `Open a Free Local ${currency} Account`;
    }
  };
  const bannerTitle = getbannerTitle();

  const getBannerContent = () => {
    if (isCADAccount) {
      if (coreExternalWalletAccountId && coreExternalWalletAccountStatus === 'inactive') {
        return 'You’re almost there! Finish opening an account so you can send and receive payments like a local. Use account details to get paid from your customer, payment processors or marketplaces like Amazon, Shopify, Stripe and Paypal.';
      } else if (!coreExternalWalletAccountId) {
        return 'Get a local Canadian account number and transit number so you can send and receive payments locally. Use account details to get paid from your customers, payment processors or marketplaces like Amazon, Shopify, Stripe, PayPal and more.';
      }
    } else if (isCCAccount || isUSDAccount) {
      return 'Get local banking details so you can send and receive payments like a local. Use account details to get paid from your customers, payment processors or marketplaces like Amazon, Shopify, Stripe and PayPal.';
    }
  };
  const bannerContent = getBannerContent();

  const actionButtonLabel = isCCAccount ? 'Upgrade Your Account' : 'Open Account';

  return (
    <>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-items-center tw-border tw-rounded-md tw-p-4 tw-bg-primary-dark-green tw-text-neutral-light">
        <div>
          <CashAndStars />
        </div>
        <div>
          <strong>{bannerTitle}</strong>
          <div className="tw-text-sm">{bannerContent}</div>
        </div>
        <div className="tw-min-w-max">
          <Button onClick={handleOpenAccount} tertiary className="tw-text-neutral-light tw-underline">
            {actionButtonLabel} <BsArrowRight size={22} className="tw-ml-2" />
          </Button>
        </div>
      </div>
      <Modals.OpenUSAccount
        show={showAddUSAccountModal}
        onClose={setHideAddUSAccountModal}
        onAddAccount={refetchAccountsPageData}
      />
      <Modals.OpenEURGBPAccount
        show={showAddEURGBPAccountModal}
        onClose={setHideAddEURGBPAccountModal}
        currency={currency}
        setCurrency={setCurrency}
        onAddAccount={refetchAccountsPageData}
      />
      {showAddCADAccountModal && (
        <OpenCADAccountModal show={showAddCADAccountModal} onClose={setHideAddCADAccountModal} />
      )}
    </>
  );
};

export default OpenFreeAccountBanner;
