import React from 'react';
import cx from 'classnames';

import { DetailsSectionProps } from './DetailsSection.types';

const DetailsSection = ({ title, columns }: DetailsSectionProps) => {
  return (
    <div>
      <div className="tw-underline tw-font-bold tw-mb-1">{title}</div>
      <div>
        {columns.map(({ label, value }, index) => {
          const isFirst = index === 0;

          return (
            <div
              key={index}
              className={cx(
                'tw-flex tw-items-center tw-border-l tw-border-r tw-border-b tw-border-neutral-grey-1',
                isFirst && 'tw-border-t'
              )}
            >
              <div className="tw-w-1/3 tw-border-r tw-border-neutral-grey-1 tw-py-1 tw-px-2 tw-h-full">{label}</div>
              <div className="tw-w-2/3 tw-py-1 tw-px-2 tw-h-full">{value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DetailsSection;
