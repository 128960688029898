import React from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_CARD_RECURRING_PAYMENT } from 'graphql/recurringPayment';
import { ActivateButton, ManageButton } from './components';
import { Loaders } from 'components/cards/Loader';
import { formatDateTime } from 'utility/date';

const AutomaticPayments = () => {
  const { data, loading: loadingRecurringPaymentData } = useQuery(GET_CARD_RECURRING_PAYMENT, {
    fetchPolicy: 'network-only',
  });

  const isAutopayEnabled = get(data, 'cardRecurringPayment.enabled', false);
  const processingPaymentDate = get(data, 'cardRecurringPayment.processingPaymentDate');

  const formattedNextPaymentDate = formatDateTime(processingPaymentDate);

  if (loadingRecurringPaymentData)
    return (
      <div className="tw-my-10">
        <Loaders.Spinner />
      </div>
    );

  return (
    <div className="tw-mt-8 tw-px-4 lg:tw-px-0">
      <h3 className="tw-hidden lg:tw-block tw-mb-6">Automatic Payments</h3>
      <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-x-8 tw-gap-y-3">
        <p>Automatic Payment</p>
        {isAutopayEnabled ? <ManageButton formattedNextPaymentDate={formattedNextPaymentDate} /> : <ActivateButton />}
      </div>
    </div>
  );
};

export default AutomaticPayments;
