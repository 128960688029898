import { PaymentRequest } from 'types/invoicing';
import { formatDateTime } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { getPaymentRequestSchedule } from 'components/Invoices/Invoices.utils';

const usePaymentRequestFieldValues = ({ paymentRequest }: { paymentRequest: PaymentRequest }) => {
  const {
    payor,
    account,
    createdAt,
    dueDate,
    amount: { amount, currency },
    status,
    invoiceNumber,
    fee,
    schedule,
  } = paymentRequest;

  const payorName = payor.name;
  const accountName = account.name;
  const formattedInitDate = formatDateTime(createdAt);
  const formattedAmount = formatMoneyV2({ amount, currency });
  const formattedDueDate = formatDateTime(dueDate);

  const formattedPaymentRequestFee = fee ? formatMoneyV2(fee) : 0;

  const {
    frequencyType,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  } = getPaymentRequestSchedule(schedule);

  return {
    payorName,
    accountName,
    formattedAmount,
    currency,
    formattedInitDate,
    formattedDueDate,
    status,
    formattedPaymentRequestFee,
    invoiceNumber,
    frequencyType,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  };
};

export default usePaymentRequestFieldValues;
