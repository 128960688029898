import React, { useContext } from 'react';
import { get } from 'lodash';
import { Route, Redirect } from 'react-router-dom';

import config from 'config';
import { KYC_STATUS, TEAM_MEMBER_STATUS, onboardingStatus } from 'constants/index';
import { onboardingList } from 'components/accountSummary/OnboardingSummary';
import { Loaders } from 'components/cards/Loader';
import NotFoundFallback from 'components/NotFoundFallback';
import { AuthContext } from 'context/Auth';
import useHasPermission from 'hooks/useHasPermission';
import useIsMember from 'hooks/useIsMember';
import useFeatureToggle from 'hooks/useFeatureToggle';
import { useUnderDevelopmentRoutes, useGetUserInfo, useIsMobile, useGetSubscription } from 'hooks';
import useIsContactPermission from '../hooks/useIsContactPermission';
import { UserTypes } from 'types/user';

export const RoutePrivate = ({ ...rest }) => {
  const { isLoading, meLoading, isSignedIn } = useContext(AuthContext);

  // routes which are under development and allowed only for the internal users
  const { isRouteAvailable, loading: isRouteAvailabilityLoading } = useUnderDevelopmentRoutes({ route: rest.path });

  if (isLoading || meLoading || isRouteAvailabilityLoading) return <Loaders.FullScreen />;

  if (!isRouteAvailable) return <Redirect to="/signin" />;

  if (!isSignedIn) return <Redirect to={`/signin?redirect=${rest.location.pathname}${rest.location.search || ''}`} />;

  return <Route {...rest} />;
};

export const RouteOnboardingDashboard = (props) => {
  const { isLoading, isRegularUser, isPayorUser, isKYCApproved, isOnboardingCompleted, userOnboardingStatus } =
    useGetUserInfo();

  // routes which are under development and allowed only for the internal users
  const { isRouteAvailable, loading: isRouteAvailabilityLoading } = useUnderDevelopmentRoutes({ route: props.path });

  if (isLoading || isRouteAvailabilityLoading) return <Loaders.FullScreen />;

  if (isPayorUser) return <Redirect to="/payor/portal" />;

  if (isRegularUser) {
    if (isKYCApproved) return <Redirect to="/dashboard/home" />;

    if (!isOnboardingCompleted) {
      const redirectLink = onboardingList[userOnboardingStatus]?.link;
      if (redirectLink) return <Redirect to={redirectLink} />;
    }
  }

  if (!isRouteAvailable) return <NotFoundFallback />;

  return <RoutePrivate {...props} />;
};

export const RouteOnboardingApplication = (props) => {
  const { isLoading, isRegularUser, isKYCApproved } = useGetUserInfo();

  if (isLoading) return <Loaders.FullScreen />;

  if (isRegularUser && isKYCApproved) return <Redirect to="/dashboard/home" />;

  return <RoutePrivate {...props} />;
};

export const RouteDashboard = (props) => {
  const { isLoading, isPayorUser, isRegularUser, isKYCApproved } = useGetUserInfo();
  const { isMember } = useIsMember();
  const isMobile = useIsMobile();
  const {
    viewAccountBalances,
    viewPayees,
    viewPayors,
    managePayors,
    viewPayeePayments,
    createPayee,
    createPayeePayment,
    manageRewards,
  } = useIsContactPermission();
  const canViewPayors = viewPayors || managePayors;
  const canViewPayees = viewPayees || viewPayeePayments || createPayee || createPayeePayment;

  // routes which are under development and allowed only for the internal users
  const { isRouteAvailable, loading: isRouteAvailabilityLoading } = useUnderDevelopmentRoutes({ route: props.path });

  // routes which are in beta and allowed only for the certain users
  const { isExperimentEnabled, loading: isExperimentEnabledLoading } = useFeatureToggle();
  const { isExperimentEnabled: isApprovalsEnabled, loading: isApprovalsEnabledLoading } = useFeatureToggle(
    false,
    'approvals'
  );
  const { isExperimentEnabled: isEmailReceiptsEnabled, loading: isEmailReceiptsEnabledLoading } = useFeatureToggle(
    true,
    'email_receipts'
  );

  if (
    isLoading ||
    isRouteAvailabilityLoading ||
    isExperimentEnabledLoading ||
    isApprovalsEnabledLoading ||
    isEmailReceiptsEnabledLoading
  )
    return <Loaders.FullScreen />;

  if (!isRouteAvailable) return <NotFoundFallback />;
  if (!isExperimentEnabled && config.featureFlagRoutes.split(',').includes(props.path)) return <NotFoundFallback />;
  if (!isApprovalsEnabled && '/dashboard/approvals' === props.path) return <NotFoundFallback />;
  if (!isEmailReceiptsEnabled && '/dashboard/settings/expense-management' === props.path) return <NotFoundFallback />;

  if (isPayorUser) return <Redirect to="/payor/portal" />;

  if (isRegularUser) {
    if (!isKYCApproved) return <Redirect to="/onboarding/dashboard/home" />;

    if (isMember && props.path === '/dashboard/home' && !isMobile) return <Redirect to="/dashboard/cards/list" />;
    if (!manageRewards && props.path === '/dashboard/rewards') return <Redirect to="/dashboard/home" />;
    if (!viewAccountBalances && props.path === '/dashboard/accounts') return <Redirect to="/dashboard/home" />;
    if (!canViewPayees && props.path === '/dashboard/payments/payees') return <Redirect to="/dashboard/home" />;
    if (!canViewPayors && props.path === '/dashboard/invoices') return <Redirect to="/dashboard/home" />;
    if (!viewAccountBalances && props.path === '/dashboard/transactions?productType=wallet')
      return <Redirect to="/dashboard/home" />;
  }

  return <RouteForUserStatus default={RoutePrivate} {...props} />;
};

export const RouteTeamInvitation = (props) => {
  const { me, meLoading } = useContext(AuthContext);
  const status = get(me, 'internalContact.status');

  if (!meLoading && status === TEAM_MEMBER_STATUS.active) return <Redirect to="/dashboard/home" />;
  if (!meLoading && status === TEAM_MEMBER_STATUS.inactive) return <Redirect to="/suspended" />;
  return <RoutePrivate {...props} />;
};

export const RoutePublic = ({ component: Component, ...rest }) => {
  const { isLoading, isSignedIn } = useContext(AuthContext);
  let redirect = '/';
  if (rest.location.search.startsWith('?redirect=')) {
    redirect = rest.location.search.replace('?redirect=', '');
  }
  return (
    <Route
      render={(props) => {
        if (isLoading) return <Loaders.FullScreen />;
        if (isSignedIn) return <Redirect to={redirect} />;
        if (!isSignedIn) return <Component {...props} />;
      }}
      {...rest}
    />
  );
};

export const RedirectToCore = (props) => {
  const { pathname, search } = props.location;
  return <Redirect to={`${config.coreAppUrl}${pathname}${search}`} />;
};

export const RouteScoped = ({ component: Component, scope, ...rest }) => {
  const { isLoading, hasPermission } = useHasPermission({ scope });
  const { me, meLoading } = useContext(AuthContext);

  const status = get(me, 'account.onboardingStatus');
  const kycAssessmentStatus = get(me, 'account.kycAssessment.status');

  const Scoped = () => (
    <Route
      render={(props) => {
        if (isLoading || meLoading) return <Loaders.FullScreen />;
        if (kycAssessmentStatus !== KYC_STATUS.APPROVED) {
          if (status === onboardingStatus.COMPLETED)
            return <Redirect to={onboardingList[onboardingStatus.COMPLETED_PENDING_KYC].link} />;

          const redirectLink = onboardingList[status]?.link;

          if (redirectLink) return <Redirect to={redirectLink} />;
        }
        if (!hasPermission) return <Redirect to="/dashboard/home" />;

        return <Component {...props} />;
      }}
      {...rest}
    />
  );

  return <RouteForUserStatus default={Scoped} {...rest} />;
};

const RouteForUserStatus = ({ default: DefaultRoute, ...rest }) => {
  const { me, meLoading } = useContext(AuthContext);
  const status = get(me, 'internalContact.status');

  if (!meLoading && status === TEAM_MEMBER_STATUS.pending_info) return <Redirect to="/team/personal-info" />;
  if (!meLoading && status === TEAM_MEMBER_STATUS.pending_verification) return <Redirect to="/team/waiting-room" />;
  if (!meLoading && status === TEAM_MEMBER_STATUS.inactive) return <Redirect to="/suspended" />;
  return <DefaultRoute {...rest} />;
};

export const RouteSubscription = (props) => {
  const { isPaidSubscription, loading } = useGetSubscription();

  if (!loading && !isPaidSubscription) return <Redirect to="/dashboard/subscriptions" />;
  return <RouteDashboard {...props} />;
};

export const RoutePayorPortal = (props) => {
  const { me, meLoading, isLoading } = useContext(AuthContext);
  const type = get(me, '__typename');

  // routes which are under development and allowed only for the internal users
  const { isRouteAvailable, loading: isRouteAvailabilityLoading } = useUnderDevelopmentRoutes({ route: props.path });

  if (isLoading || meLoading || isRouteAvailabilityLoading) return <Loaders.FullScreen />;

  if (type === UserTypes.REGULAR_USER) return <Redirect to="/dashboard/home" />;

  if (!isRouteAvailable) return <NotFoundFallback />;

  return <RoutePrivate {...props} />;
};
