import { useForm } from 'react-hook-form';

import { composeFullAddress } from 'utility/address';
import { Currencies } from 'constants/currencies';
import { SUPPLIER_SPECIFIC_COUNTRIES } from 'constants/index';

import { DetailsFormProps } from '../DetailsForm.types';

const useDetailsForm = ({ sumbittedBankDetails }: Pick<DetailsFormProps, 'sumbittedBankDetails'>) => {
  const form = useForm({
    defaultValues: {
      address: {
        country: SUPPLIER_SPECIFIC_COUNTRIES.CA.code,
      },
      bankAccount: {
        country: Currencies.CAD,
      },
    },
  });

  const {
    handleSubmit,
    formState: { isValid, isSubmitted },
  } = form;

  const businessAddress = sumbittedBankDetails?.address ? composeFullAddress(sumbittedBankDetails.address) : '';
  const bankDetails = {
    'Account Number': sumbittedBankDetails?.bankAccount?.maskedNumber,
  };

  return {
    form,
    handleSubmit,
    isFormInvalid: !isValid && isSubmitted,
    businessAddress,
    bankDetails,
  };
};

export default useDetailsForm;
