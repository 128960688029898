import React from 'react';
import cx from 'classnames';

import { IconAnimation } from 'components/Button';
import ArrowRight from 'components/svg/ArrowRight';
import { SubmitButton } from 'components/FormFields/v2';
import { FormFooterProps } from './FormFooter.types';

const FormFooter = ({
  onSubmit,
  submitButtonLabel = 'Submit',
  loading = false,
  isDisabled = false,
}: FormFooterProps) => (
  <div className={cx('tw-mt-12 tw-flex tw-justify-end')}>
    <SubmitButton
      isDisabled={loading || isDisabled}
      onClick={onSubmit}
      className="tw-ml-auto"
      iconAnimation={IconAnimation.rollOut}
    >
      <div className="tw-flex tw-justify-center tw-items-center">
        <p className="tw-text-neutral-light tw-mr-2">{loading ? 'Submitting...' : submitButtonLabel}</p>
        <ArrowRight />
      </div>
    </SubmitButton>
  </div>
);

export default FormFooter;
