import React from 'react';

import Button from 'components/Button';
import BrokenClock from 'components/svg/BrokenClock';

const Complete = ({ onFinish }) => {
  return (
    <>
      <AccountVerificationInProgress />
      <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex lg:tw-justify-end">
        <Button primary onClick={onFinish} className="tw-w-full lg:tw-w-max">
          Go To Accounts
        </Button>
      </div>
    </>
  );
};

const AccountVerificationInProgress = () => {
  return (
    <div className="tw-px-8 tw-pt-8 tw-mb-8 tw-space-y-8">
      <div className="tw-flex tw-flex-col tw-space-y-8">
        <div className="tw-flex tw-items-center tw-justify-center tw-mt-8">
          <BrokenClock />
        </div>
        <div className="tw-flex tw-items-center tw-justify-center tw-mt-8">
          <p>
            <strong>Your Loop USD account verification is now in progress.</strong>
          </p>
        </div>
        <div className="tw-flex tw-items-center tw-justify-center tw-mt-8">
          <small className="tw-text-center">
            Verification can take up to two business days. You will receive an email when your USD account has been
            verified and you can begin to make transactions to and from the account.
          </small>
        </div>
        <div className="tw-flex tw-items-center tw-justify-center tw-mt-8">
          <small className="tw-text-center">
            Please ensure that you uploaded clear and readable images for the passport document you uploaded for each
            owner. If you want to review the documents you uploaded or add new ones, you can do so{' '}
            <span className="tw-text-primary-dark-green tw-underline tw-font-semibold">
              <a href="/dashboard/settings/documents">here</a>
            </span>
            .
          </small>
        </div>
      </div>
    </div>
  );
};

export default Complete;
