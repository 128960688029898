import React from 'react';
import { Transition } from '@headlessui/react';

import { formatDate } from 'utility/date';
import { useToggle } from 'hooks';
import CardActionButton from '../CardActionButton';
import EyeOpen from 'components/svg/EyeOpen';
import EyeClosed from 'components/svg/EyeClosed';
import CopyableField from '../CopyableField';

const ToggleCardDetails = ({ creditCard, onCopyValueToClipboard, isImpersonating }) => {
  const { isOpen: showDetails, toggle: onToggleDetails } = useToggle();
  const { isOpen: cardNumberVisible, toggle: handleToggleCardNumber } = useToggle();
  const { isOpen: cvcVisible, toggle: handleToggleCvc } = useToggle();
  const { isOpen: expiryVisible, toggle: handleToggleExpiry } = useToggle();

  const onCopyCardNumber = () => {
    if (!isImpersonating) onCopyValueToClipboard(creditCard.cardNumber());
  };
  const onCopyName = () => {
    onCopyValueToClipboard(creditCard.cardHolderName);
  };
  const onCopyCVC = () => {
    if (!isImpersonating) onCopyValueToClipboard(creditCard.cvc2());
  };
  const onCopyExpiryDate = () => onCopyValueToClipboard(formatDate(creditCard.expirationDate(), 'MM/yy'));

  return (
    <>
      <CardActionButton
        label={`${showDetails ? 'Hide' : 'Show'} Card Details`}
        Icon={showDetails ? EyeClosed : EyeOpen}
        onClick={onToggleDetails}
        className="tw-bg-secondary-light-green tw-text-primary-dark-green"
      />
      <Transition
        as="div"
        className="tw-mt-4 tw-mb-2"
        show={showDetails}
        enter="tw-ease-in-out tw-duration-200 tw-transition-all"
        enterFrom="tw-transform tw-opacity-0"
        enterTo="tw-transform tw-opacity-100"
        leave="tw-ease-in-out tw-duration-200 tw-transition-all"
        leaveFrom="tw-transform tw-opacity-100"
        leaveTo="tw-transform tw-opacity-0"
      >
        <CopyableField label="Card Holder Name" value={creditCard.cardHolderName} onCopy={onCopyName} />
        <CopyableField
          label="Card Number"
          value={cardNumberVisible ? creditCard.cardNumber() : `∗∗∗∗ ∗∗∗∗ ∗∗∗∗ ${creditCard.lastFourDigits}`}
          onCopy={onCopyCardNumber}
          show={cardNumberVisible}
          handleToggle={handleToggleCardNumber}
        />
        <CopyableField
          handleToggle={handleToggleCvc}
          show={cvcVisible}
          label="CVC2 Code"
          value={cvcVisible ? creditCard.cvc2() : '∗∗∗'}
          onCopy={onCopyCVC}
        />
        <CopyableField
          label="Expiry"
          show={expiryVisible}
          handleToggle={handleToggleExpiry}
          value={expiryVisible ? formatDate(creditCard.expirationDate(), 'MM/yy') : '∗∗/∗∗'}
          onCopy={onCopyExpiryDate}
        />
      </Transition>
    </>
  );
};

export default ToggleCardDetails;
