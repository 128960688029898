import React, { useState, useMemo } from 'react';
import { ApolloError } from '@apollo/client';

import { PayBalance, PayBalanceTransaction, PayBalanceCurrency, Money } from 'types/payments';
import { GroupedCardsInfo } from 'types/creditCard';
import { BankAccount } from 'types/bankAccount';
import { AccountWallet } from 'types/wallet';
import { sortBalancesByCurrency } from 'utility/creditCards';

export type CardRepaymentError = ApolloError & { step?: number[] };

type CardRepaymentType = {
  payBalanceInfo: PayBalance | null;
  setPayBalanceInfo: React.Dispatch<React.SetStateAction<PayBalance | null>>;
  payBalanceTransactions: PayBalanceTransaction[];
  setPayBalanceTransactions: React.Dispatch<React.SetStateAction<PayBalanceTransaction[]>>;
  error: CardRepaymentError | null;
  setError: React.Dispatch<React.SetStateAction<CardRepaymentError | null>>;
  wallets: AccountWallet[];
  setWallets: React.Dispatch<React.SetStateAction<AccountWallet[]>>;
  bankAccounts: BankAccount[];
  setBankAccounts: React.Dispatch<React.SetStateAction<BankAccount[]>>;

  statementBalanceInCad?: Money;
  setStatementBalanceInCad: React.Dispatch<React.SetStateAction<Money | undefined>>;

  statementBalanceInUsd?: Money<PayBalanceCurrency.USD>;
  setStatementBalanceInUsd: React.Dispatch<React.SetStateAction<Money<PayBalanceCurrency.USD> | undefined>>;
  eurStatementBalanceInCad?: Money;
  setEurStatementBalanceInCad: React.Dispatch<React.SetStateAction<Money | undefined>>;
  gbpStatementBalanceInCad?: Money;
  setGbpStatementBalanceInCad: React.Dispatch<React.SetStateAction<Money | undefined>>;
  usdStatementBalanceInCad?: Money;
  setUsdStatementBalanceInCad: React.Dispatch<React.SetStateAction<Money | undefined>>;

  groupedCardsInfo?: GroupedCardsInfo;
  setGroupedCardsInfo: React.Dispatch<React.SetStateAction<GroupedCardsInfo | undefined>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CardRepaymentContext = React.createContext<CardRepaymentType>({} as CardRepaymentType);

export const CardRepaymentContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<CardRepaymentError | null>(null);
  const [payBalanceInfo, setPayBalanceInfo] = useState<PayBalance | null>(null);
  const [payBalanceTransactions, setPayBalanceTransactions] = useState<PayBalanceTransaction[]>([]);
  const [wallets, setWallets] = useState<AccountWallet[]>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [statementBalanceInCad, setStatementBalanceInCad] = useState<Money | undefined>();
  const [statementBalanceInUsd, setStatementBalanceInUsd] = useState<Money<PayBalanceCurrency.USD> | undefined>();
  const [eurStatementBalanceInCad, setEurStatementBalanceInCad] = useState<Money | undefined>();
  const [gbpStatementBalanceInCad, setGbpStatementBalanceInCad] = useState<Money | undefined>();
  const [usdStatementBalanceInCad, setUsdStatementBalanceInCad] = useState<Money | undefined>();

  const [groupedCardsInfo, setGroupedCardsInfo] = useState<GroupedCardsInfo | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sortedGroupedCardsInfo = useMemo(() => {
    if (!groupedCardsInfo) return;

    const {
      groupBalances,
      groupOngoingAmountDueByCurrency,
      groupOngoingMinimumAmountDueByCurrency,
      groupConvertedMinimumAmountDueByCurrency,
    } = groupedCardsInfo;

    const sortedGroupBalances = sortBalancesByCurrency({ items: groupBalances });

    const sortedGroupOngoingAmountDueByCurrency = sortBalancesByCurrency({
      items: Object.entries(groupOngoingAmountDueByCurrency),
      currencyField: '0',
    });

    const sortedGroupOngoingMinimumAmountDueByCurrency = sortBalancesByCurrency({
      items: Object.entries(groupOngoingMinimumAmountDueByCurrency),
      currencyField: '0',
    });

    const sortedGroupConvertedMinimumAmountDueByCurrency = sortBalancesByCurrency({
      items: Object.entries(groupConvertedMinimumAmountDueByCurrency),
      currencyField: '0',
    });

    return {
      ...groupedCardsInfo,
      groupBalances: sortedGroupBalances,
      groupOngoingAmountDueByCurrency: Object.fromEntries(sortedGroupOngoingAmountDueByCurrency),
      groupOngoingMinimumAmountDueByCurrency: Object.fromEntries(sortedGroupOngoingMinimumAmountDueByCurrency),
      groupConvertedMinimumAmountDueByCurrency: Object.fromEntries(sortedGroupConvertedMinimumAmountDueByCurrency),
    };
  }, [groupedCardsInfo]);

  return (
    <CardRepaymentContext.Provider
      value={{
        payBalanceInfo,
        setPayBalanceInfo,
        payBalanceTransactions,
        setPayBalanceTransactions,
        error,
        setError,

        wallets,
        setWallets,
        bankAccounts,
        setBankAccounts,

        statementBalanceInCad,
        setStatementBalanceInCad,
        statementBalanceInUsd,
        setStatementBalanceInUsd,

        eurStatementBalanceInCad,
        setEurStatementBalanceInCad,
        gbpStatementBalanceInCad,
        setGbpStatementBalanceInCad,
        usdStatementBalanceInCad,
        setUsdStatementBalanceInCad,

        groupedCardsInfo: sortedGroupedCardsInfo,
        setGroupedCardsInfo,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </CardRepaymentContext.Provider>
  );
};
