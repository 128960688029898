import React, { useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { subYears } from 'date-fns';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

import config from 'config';
import { AuthContext } from 'context/Auth';
import { ACCEPT_USER_INVITE } from 'graphql/user';
import ArrowRight from 'components/svg/ArrowRight';
import InfoTipIdentify from 'components/svg/InfoTipIdentify';
import Loader from 'components/cards/Loader';
import { TextField, PasswordField, PhoneField, SubmitButton, DatePicker, RadioField } from 'components/FormFields/v2';
import Button from 'components/Button';
import AddressFields from 'components/onboarding/AddressFields';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import Layout from './Layout';

const Form = ({ contact, onSubmit, isSubmitting, error }) => {
  const { firstName, lastName, email } = contact || {};

  const form = useForm({
    defaultValues: {
      firstName,
      lastName,
      email,
      address: { country: 'CA' },
      otherAddress: { country: 'CA' },
    },
  });
  const { register, handleSubmit, watch } = form;
  const currentPassword = watch('password');
  const livedInTheSameAddressRecently = watch('livedInTheSameAddressRecently', 'true') === 'true';

  return (
    <div className="tw-mb-8">
      <h1 className="tw-mb-3">Set up your profile</h1>
      <p className="tw-mb-8">
        You are just a few clicks away from creating a Loop account for your company. But first please set up your
        profile.
      </p>
      <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            rootClass="tw-mb-8"
            name="firstName"
            label="Legal First Name"
            placeholder="John"
            required
            ref={register({ required: true })}
            tabIndex={1}
          />
          <TextField
            rootClass="tw-mb-8"
            name="lastName"
            label="Legal Last Name"
            placeholder="Doe"
            required
            ref={register({ required: true })}
            tabIndex={2}
          />
          <TextField
            rootClass="tw-mb-8"
            name="email"
            placeholder="example@example.com"
            label="Work Email Address"
            required
            ref={register({ required: true })}
            tabIndex={5}
          />
          <PhoneField
            rootClass="tw-mb-8"
            name="mobilePhoneNumber"
            label="Mobile Phone Number"
            required
            autoComplete="tel-national"
            rules={{ required: true }}
          />
          <DatePicker
            name="birthdate"
            rootClass="tw-mb-8"
            label="Date of Birth"
            maxDate={subYears(new Date(), 18)}
            required
            ref={register({ required: true })}
          />
          <AddressFields name="address" streetAddressLabel="Personal Street Address" />
          <div className="tw-mb-20">
            <label className="tw-text-neutral-grey-1 tw-mb-4" htmlFor="isAccountOwner">
              Have you lived at this address for two years or more?
            </label>
            <RadioField
              className="tw-overflow-hidden"
              rootClass="tw-mt-2 tw-mb-4"
              label="Have you lived at this address for two years or more?"
              name="livedInTheSameAddressRecently"
              options={[
                { label: 'Yes', value: 'true', defaultChecked: true },
                { label: 'No', value: 'false' },
              ]}
              ref={register({ required: true })}
            />
          </div>
          <Transition
            show={!livedInTheSameAddressRecently}
            enter="tw-ease-in-out tw-duration-200"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in-out tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <AddressFields name="otherAddress" streetAddressLabel="Previous Personal Street Address" />
          </Transition>

          <TextField
            name="socialInsuranceNumber"
            label="Social Insurance Number"
            placeholder="376-281-002"
            data-private
            ref={register({
              pattern: {
                value: /^[0-9]*$/,
                message: 'Invalid SIN number',
              },
              minLength: {
                value: 9,
                message: 'SIN number must be nine-digits long',
              },
              maxLength: {
                value: 9,
                message: 'SIN number must be nine-digits long',
              },
            })}
          />
          <div className="tw-flex tw-items-center tw-mb-4">
            <InfoTipIdentify />
            <small>This is optional but will help us to more easily identify you</small>
          </div>
          <PasswordField
            rootClass="tw-mb-8"
            type="password"
            name="password"
            placeholder="∗∗∗∗∗∗∗∗"
            required
            label="Password"
            ref={register({ required: true })}
          />
          <PasswordStrengthMeter password={currentPassword} showScore={false} />
          <SubmitButton type="submit" className="tw-w-full tw-mt-8" tabIndex={8} disabled={isSubmitting}>
            {isSubmitting ? (
              'Submitting...'
            ) : (
              <div className="tw-flex tw-justify-center tw-items-center">
                <p className="tw-text-neutral-light tw-mr-2">Join Loop</p>
                <ArrowRight />
              </div>
            )}
          </SubmitButton>
          <div className="tw-mt-12 tw-flex tw-justify-center">
            <small className="tw-text-neutral-grey-2">
              By clicking "Join Loop" I confirm I have read and agreed to the{' '}
              <a className="tw-underline" target="_blank" rel="noopener noreferrer" href={config.platformAgreementUrl}>
                {'Platform Agreement'}
              </a>{' '}
              and{' '}
              <a className="tw-underline" target="_blank" rel="noopener noreferrer" href={config.privacyPolicyUrl}>
                {'Privacy Policy'}
              </a>
              .
            </small>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const InvitationError = () => (
  <div className="tw-space-y-8">
    <h1>Invalid invitation</h1>
    <p>This invitation is expired or no longer valid.</p>
    <div>
      <Link to="/signin">
        <Button primary>Return to Login</Button>
      </Link>
    </div>
  </div>
);

const Content = (props) => {
  const { sgid, error, ...formProps } = props;
  const { signIn, isSigningIn, meRefetch } = useContext(AuthContext);
  const [createAccount, { loading: isSubmitting, error: submitError }] = useMutation(ACCEPT_USER_INVITE);

  const handleSubmit = async (data) => {
    try {
      await createAccount({
        variables: {
          ...data,
          livedInTheSameAddressRecently: data.livedInTheSameAddressRecently === 'true',
          signedId: sgid,
          occupation: 'Owner/Director',
        },
      });

      await signIn(data.email, data.password);

      await meRefetch();

      history.push('/dashboard');
    } catch (err) {
      console.error(err);
    }
  };

  return error ? (
    <InvitationError />
  ) : (
    <Form
      onSubmit={handleSubmit}
      isSubmitting={isSigningIn || isSubmitting}
      error={error || submitError}
      {...formProps}
    />
  );
};

const Loading = () => (
  <div className="tw-flex tw-flex-col tw-justify-center tw-flex-grow" data-testid="loader">
    <Loader />
  </div>
);

const ProfileSetup = (props) => {
  const { loading, ...contentProps } = props;

  return <Layout>{loading ? <Loading /> : <Content {...contentProps} />}</Layout>;
};

export default ProfileSetup;
