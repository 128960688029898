import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_EXCHANGE_RATE_FOR_PAYMENT } from '../graphql/payments';

const useGetExchangeRate = () => {
  const [buy, setBuy] = useState(null);
  const [sell, setSell] = useState(null);
  const [rate, setRate] = useState(null);
  const [reference, setReference] = useState();
  const [rateExpiresAt, setRateExpiresAt] = useState();
  const [loadingRate, setLoadingRate] = useState(false);
  const [error, setError] = useState('');

  const handleGetExchangeRate = (data) => {
    setLoadingRate(true);
    getExchangeRate(data);
  };

  const [getExchangeRate] = useLazyQuery(GET_EXCHANGE_RATE_FOR_PAYMENT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { rate, expiresAt, reference, buy, sell } = data?.exchangeRate || {};

      if (rate) {
        setRate(rate);
        setBuy(buy);
        setSell(sell);
        setRateExpiresAt(expiresAt);
        setLoadingRate(false);
        setReference(reference);
        setError('');
      }
    },
    onError: (e) => {
      setLoadingRate(false);
      setError(e.message);
    },
  });

  return {
    rate,
    buy,
    sell,
    rateExpiresAt,
    loadingRate,
    error,
    exchangeRateReference: reference,
    getExchangeRate: handleGetExchangeRate,
  };
};

export default useGetExchangeRate;
