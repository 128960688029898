import React from 'react';
import { useHistory } from 'react-router-dom';

import Summary from '../Summary';
import { Banner } from 'components/UI';
import { BannerType } from 'components/UI/Banner';
import CardOutline from 'components/svg/CardOutline';
import CardAndCoins from 'components/svg/CardAndCoins';
import { useIsMobile } from 'hooks';

const CreditCardsSummary = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const handleClick = () => {
    if (isMobile) return;

    history.push('/onboarding/dashboard/settings/bank-accounts-settings');
  };

  return (
    <Summary
      title="Cards (0)"
      icon={<CardOutline height={18} width={18} />}
      iconClassName="tw-text-primary-blue tw-bg-secondary-light-blue"
    >
      <Banner
        type={BannerType.secondary}
        title="Connect Your Bank Account"
        message="Connect the bank account you will be using to transfer funds to your Loop Card. Once connected and your account is verified, you'll be able to create Loop cards and start spending."
        submitButton={!isMobile}
        submitButtonLabel="Connect Bank Account"
        onSubmit={handleClick}
        icon={<CardAndCoins width="100" height="100" />}
        iconAlignment="center"
        classNames="tw-mb-0"
      />
    </Summary>
  );
};

export default CreditCardsSummary;
