import React from 'react';

type CoinSwapProps = {
  width?: number;
  height?: number;
};

const CoinSwap = ({ width = 56, height = 57 }: CoinSwapProps) => (
  <svg width={width} height={height} viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4.5" width="48" height="48" rx="24" fill="#D0E7DA" />
    <g clipPath="url(#clip0_11049_131433)">
      <path
        d="M22 22L24 20M24 20L22 18M24 20H22C19.7909 20 18 21.7909 18 24M34 34L32 36M32 36L34 38M32 36H34C36.2091 36 38 34.2091 38 32M29.4172 29.4172C30.1994 29.7908 31.0753 30 32 30C35.3137 30 38 27.3137 38 24C38 20.6863 35.3137 18 32 18C28.6863 18 26 20.6863 26 24C26 24.9247 26.2092 25.8006 26.5828 26.5828M30 32C30 35.3137 27.3137 38 24 38C20.6863 38 18 35.3137 18 32C18 28.6863 20.6863 26 24 26C27.3137 26 30 28.6863 30 32Z"
        stroke="#245951"
        strokeOpacity="0.9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <rect x="4" y="4.5" width="48" height="48" rx="24" stroke="#E8F3ED" strokeWidth="8" />
    <defs>
      <clipPath id="clip0_11049_131433">
        <rect width="24" height="24" fill="white" transform="translate(16 16.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default CoinSwap;
