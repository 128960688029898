import React from 'react';

import CircularProgress from 'components/CircularProgress';

const ProgressLoader = ({ progress }: { progress: number }) => (
  <div className="tw-flex tw-justify-center tw-items-center tw-h-96">
    <CircularProgress strokeWidth={6} value={progress} clockWise>
      <div className="tw-flex tw-flex-col tw-text-center">
        <strong>{progress}%</strong>
        <strong>Connecting</strong>
      </div>
    </CircularProgress>
  </div>
);

export default ProgressLoader;
