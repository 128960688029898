import React from 'react';
import cx from 'classnames';
import { PiArrowRightLight } from 'react-icons/pi';

import { CardImage } from 'components/creditCards/components';
import { CreditCard, CreditCardContact } from 'types/creditCard';

import styles from './CardsSearch.module.scss';

export const getDetailedCreditCardLabel = ({
  displayName,
  lastFourDigits,
  cardHolder,
  isVirtual,
  type = 'Credit',
}: {
  displayName?: string;
  lastFourDigits?: string;
  cardHolder?: CreditCardContact;
  isVirtual?: boolean;
  type?: string;
}) => {
  if (!displayName || !lastFourDigits) return 'Credit Card';

  const { firstName, lastName } = cardHolder || {};
  const cardHolderName = firstName && lastName ? `${firstName} ${lastName}` : 'Other';

  return `${cardHolderName} | •• ${lastFourDigits} ${displayName} | ${isVirtual ? 'Virtual' : 'Physical'} ${type}`;
};

export const customCreditCardOptionLabel = ({
  label,
  isVirtual,
  isActive,
  isSelected,
  isVisa,
}: {
  label: string;
  isVirtual?: boolean;
  isActive?: boolean;
  isSelected?: boolean;
  isVisa?: boolean;
}) => {
  return (
    <div
      className={cx(
        styles.customCreditCardOptionLabel,
        'tw-flex tw-items-center tw-justify-start tw-gap-2 tw-p-2 tw--m-2 tw-w-full'
      )}
    >
      <div className="tw-w-10">
        <CardImage isVirtual={isVirtual} isActive={isActive} isVisa={isVisa} />
      </div>
      <div className="tw-truncate">{label}</div>
      {!isSelected && (
        <PiArrowRightLight
          size={20}
          className={cx(styles.customCreditCardOptionLabelIcon, 'tw-text-neutral-dark tw-ml-auto')}
        />
      )}
    </div>
  );
};

export const getCreditCardLabelForSorting = ({ cardA, cardB }: { cardA: CreditCard; cardB: CreditCard }) => {
  const { lastFourDigits: lastFourDigitsA, contacts: contactsA } = cardA;
  const { lastFourDigits: lastFourDigitsB, contacts: contactsB } = cardB;

  const cardHolderA = contactsA?.[0];
  const cardHolderB = contactsB?.[0];

  const cardHolderNameA = `${cardHolderA?.firstName} ${cardHolderA?.lastName}`;
  const cardHolderNameB = `${cardHolderB?.firstName} ${cardHolderB?.lastName}`;

  const labelA = `${cardHolderNameA} ${lastFourDigitsA}`;
  const labelB = `${cardHolderNameB} ${lastFourDigitsB}`;

  return {
    labelA,
    labelB,
  };
};
