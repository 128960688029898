import React from 'react';
import { useHistory } from 'react-router-dom';
import { IoCloudUploadOutline } from 'react-icons/io5';

import Button from 'components/Button';

const PushWalletAccountingServiceLink = ({ accountName }) => {
  const history = useHistory();
  const goToPushTransactions = (e) => {
    e.preventDefault();
    history.push('/dashboard/transactions/accountsTransactionsPush');
  };

  return (
    <Button tertiary onClick={goToPushTransactions}>
      <IoCloudUploadOutline size={24} className="tw-mr-2 tw-text-primary-dark-green" />
      <div className="tw-text-sm tw-text-primary-dark-green">Push transactions to {accountName}</div>
    </Button>
  );
};

export default PushWalletAccountingServiceLink;
