import React from 'react';

import { useDetails } from './hooks';
import { DetailsProps } from './Details.types';

const Details = ({
  formattedDate,
  userName,
  userEmail,
  businessName,
  formattedBusinessAddress,
  externalBankAccount,
  internalBankAccount,
}: DetailsProps) => {
  const {
    externalBankName,
    externalBankAccountNumber,
    externalBankRoutingNumber,
    internalBankName,
    internalBankAccountNumber,
    internalBankRoutingNumber,
  } = useDetails({ externalBankAccount, internalBankAccount });

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-py-4 tw-text-sm">
      <div className="tw-font-bold tw-mb-3">Electronic Authorization Agreement for ACH Transactions</div>
      <div>
        <span className="tw-font-semibold">Date:</span> {formattedDate}
      </div>
      <div>
        <span className="tw-font-semibold">Authorized Signer:</span> {userName}
      </div>
      <div>
        <span className="tw-font-semibold">Authorized Signer Email:</span> {userEmail}
      </div>
      {businessName && (
        <div>
          <span className="tw-font-semibold">Customer Name:</span> {businessName}
        </div>
      )}
      <div>
        <span className="tw-font-semibold">Customer Address:</span> {formattedBusinessAddress}
      </div>

      <div>
        <div className="tw-font-semibold tw-mb-2">Originating Account:</div>
        <div className="tw-border tw-border-b-0 tw-border-neutral-grey-3">
          <div className="tw-flex tw-border-b tw-border-neutral-grey-3">
            <div className="tw-w-1/2 tw-border-r tw-border-neutral-grey-3 tw-p-1">
              Name of Bank (the “Processing Institution”):
            </div>
            <div className="tw-w-1/2 tw-p-1">{externalBankName}</div>
          </div>
          {externalBankAccountNumber && (
            <div className="tw-flex tw-border-b tw-border-neutral-grey-3">
              <div className="tw-w-1/2 tw-border-r tw-border-neutral-grey-3 tw-p-1">Account Number:</div>
              <div className="tw-w-1/2 tw-p-1">{externalBankAccountNumber}</div>
            </div>
          )}
          {externalBankRoutingNumber && (
            <div className="tw-flex tw-border-b tw-border-neutral-grey-3">
              <div className="tw-w-1/2 tw-border-r tw-border-neutral-grey-3 tw-p-1">Routing Number:</div>
              <div className="tw-w-1/2 tw-p-1">{externalBankRoutingNumber}</div>
            </div>
          )}
        </div>
      </div>

      <div>
        <div className="tw-font-semibold tw-mb-2">Receiving Account:</div>
        <div className="tw-border tw-border-b-0 tw-border-neutral-grey-3">
          <div className="tw-flex tw-border-b tw-border-neutral-grey-3">
            <div className="tw-w-1/2 tw-border-r tw-border-neutral-grey-3 tw-p-1">
              Name of Bank (the “Processing Institution”):
            </div>
            <div className="tw-w-1/2 tw-p-1">{internalBankName}</div>
          </div>
          {internalBankAccountNumber && (
            <div className="tw-flex tw-border-b tw-border-neutral-grey-3">
              <div className="tw-w-1/2 tw-border-r tw-border-neutral-grey-3 tw-p-1">Account Number:</div>
              <div className="tw-w-1/2 tw-p-1">{internalBankAccountNumber}</div>
            </div>
          )}
          {internalBankRoutingNumber && (
            <div className="tw-flex tw-border-b tw-border-neutral-grey-3">
              <div className="tw-w-1/2 tw-border-r tw-border-neutral-grey-3 tw-p-1">Routing Number:</div>
              <div className="tw-w-1/2 tw-p-1">{internalBankRoutingNumber}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Details;
