import React from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';

import { DEBUG_GET_BANK_ACCOUNTS, DEBUG_GET_WALLETS, DEBUG_GET_LOC } from 'graphql/cards';
import useGetExchangeRate from 'hooks/useGetExchangeRate';
import MoveMoneyFlow from 'components/payments/MoveMoney/MoveMoneyFlow/MoveMoney';

const MoveMoneyContainer = (props) => {
  const { loading: loadingLOC, data: dataLOC, refetch: refetchLOC } = useQuery(DEBUG_GET_LOC);
  const {
    loading: loadingBankAccounts,
    data: dataBankAccounts,
    refetch: refetchBankAccounts,
  } = useQuery(DEBUG_GET_BANK_ACCOUNTS);
  const { loading: loadingWallets, data: dataWallets, refetch: refetchWallets } = useQuery(DEBUG_GET_WALLETS);

  const wallets = _.get(dataWallets, 'me.account.wallets') || [];
  const bankAccounts = _.get(dataBankAccounts, 'me.account.bankAccounts') || [];
  // const creditCards = _.get(dataCreditCards, 'me.account.creditCards') || [];
  // const creditCardAccounts = (creditCards[0] && creditCards[0]['accounts']) || [];
  const lineOfCredit = _.get(dataLOC, 'me.account.lineOfCredit');
  const lineOfCreditLedgerAccount = _.get(dataLOC, 'me.account.lineOfCredit.ledgerAccount');

  const { rate, rateExpiresAt, loadingRate, getExchangeRate } = useGetExchangeRate();

  const handleRefetch = () => {
    refetchLOC();
    refetchWallets();
    refetchBankAccounts();
  };

  return (
    <MoveMoneyFlow
      wallets={wallets}
      bankAccounts={bankAccounts}
      creditCardAccounts={[]}
      hasLineOfCredit={!!lineOfCredit}
      lineOfCredit={lineOfCredit}
      lineOfCreditLedgerAccount={lineOfCreditLedgerAccount}
      loading={loadingLOC || loadingBankAccounts || loadingWallets}
      refetch={handleRefetch}
      rateExpiresAt={rateExpiresAt}
      loadingRate={loadingRate}
      getExchangeRate={getExchangeRate}
      rate={rate}
      {...props}
    />
  );
};

export default MoveMoneyContainer;
