import React from 'react';
import useBankFeeds from './hooks/useBankFeeds';
import { Loaders } from 'components/cards/Loader';
import BankFeedSwitch from './BankFeedSwitch';
import { AccountingBankFeedType } from 'types/accountingBankFeed';

const BankFeeds = () => {
  const { isFeedConnectionLoading, currencyList, feedConnections } = useBankFeeds();

  if (isFeedConnectionLoading) {
    return (
      <div>
        <Loaders.Spinner />
      </div>
    );
  }
  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-1 tw-mb-4">
        Select an account that you wish to sync.
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2">
        {currencyList.map((currency) => (
          <BankFeedSwitch
            key={currency}
            currency={currency}
            feedConnection={feedConnections.find((feed: AccountingBankFeedType) => feed.currency === currency)}
          />
        ))}
      </div>
    </div>
  );
};

export default BankFeeds;
