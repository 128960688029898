import React, { useState } from 'react';

export const CreditCardContext = React.createContext({});

export const CreditCardContextProvider = (props) => {
  const [creditCard, setCreditCard] = useState(null);
  const [activateCard, setActivateCard] = useState(false);

  return (
    <CreditCardContext.Provider value={{ creditCard, setCreditCard, activateCard, setActivateCard }}>
      {props.children}
    </CreditCardContext.Provider>
  );
};
