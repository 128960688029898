import React from 'react';

import { Progress } from 'components/UI';
import { Steps } from './components';
import { useAddBankDetails } from './hooks';
import { ADD_SUPPLIER_STEPS } from './constants';

const AddBankDetails = ({ onFinish, supplierId }: { onFinish: () => void; supplierId: string }) => {
  const { error, errorSteps, step, setStep, handleNextStep, handlePrevStep, submitBankDetails, isSubmitting } =
    useAddBankDetails({
      supplierId,
      onFinish,
    });

  return (
    <div className="tw-mt-4">
      <Progress currentStepIndex={step} stepLabelsAndIndexes={ADD_SUPPLIER_STEPS} errorSteps={errorSteps} />
      <Steps
        step={step}
        setStep={setStep}
        error={error}
        handlePrevStep={handlePrevStep}
        handleNextStep={handleNextStep}
        handleFinish={submitBankDetails}
        handleClose={onFinish}
        isLoading={isSubmitting}
      />
    </div>
  );
};

export default AddBankDetails;
