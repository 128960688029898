import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import { userRoles } from 'constants/index';
import { validateInteger } from 'utility/validators';
import { ADD_INDIVIDUAL_OWNER, EDIT_INDIVIDUAL_OWNER, COMPLETE_ACCOUNT_APPLICATION } from 'graphql/onboarding';
import { ampTrackEvent } from 'amplitude';
import { TextField, Checkbox, SubmitButton, MaskedTextField } from 'components/FormFields/v2';
import Close from 'components/svg/Close';
import Remove from 'components/svg/Remove';
import Modal from 'components/Modal/v2';
import Button from 'components/Button';
import LastStepModal from 'components/onboarding/LastStepModal';

const BaseContactModal = (props) => {
  const { show, onClose, children, title, subTitle } = props;

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <p>{title}</p>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-px-8 tw-pt-8 tw-p-4 tw-border-t tw-border-neutral-grey-4">
        <strong className="tw-mb-8">{subTitle}</strong>
        {children}
      </div>
    </Modal>
  );
};

const IndividualOwnerForm = (props) => {
  const {
    onSubmit,
    initialValues = {},
    loading,
    graphQLErrors,
    isOwnerOrManager = false,
    buttonLabel,
    isPartnership,
  } = props;
  const form = useForm({ defaultValues: initialValues });
  const { register, handleSubmit } = form;

  return (
    <FormProvider {...form} graphQLErrors={graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-flex tw-justify-between tw-mb-4">
          <TextField
            required
            rootClass="tw-mr-2"
            name="firstName"
            placeholder="John"
            label="First Name"
            disabled={isOwnerOrManager}
            readOnly={isOwnerOrManager}
            ref={register({ required: true })}
          />
          <TextField
            required
            name="lastName"
            placeholder="Doe"
            label="Last Name"
            disabled={isOwnerOrManager}
            readOnly={isOwnerOrManager}
            ref={register({ required: true })}
          />
        </div>
        <TextField
          required
          rootClass="tw-mb-8"
          placeholder="example@example.com"
          name="email"
          label="Email Address"
          disabled={isOwnerOrManager}
          readOnly={isOwnerOrManager}
          ref={register({ required: true })}
        />
        <MaskedTextField
          required
          rootClass="tw-mb-4"
          name="ownership"
          label="Percentage Ownership"
          placeholder="%"
          suffix="%"
          allowNegative={false}
          isNumericString={true}
          isAllowed={validateInteger}
          rules={register({ required: true })}
        />
        {!isPartnership && <Checkbox rootClass="tw-mb-4" name="isDirector" label="Is a Director" ref={register({})} />}
        <div className="tw-flex tw-justify-end">
          <SubmitButton disabled={loading}>{loading ? 'Submitting...' : buttonLabel}</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

const RegistryIndividualOwnerForm = (props) => {
  const { onSubmit, initialValues = {}, loading, graphQLErrors, buttonLabel } = props;
  const form = useForm({ defaultValues: initialValues });
  const { register, handleSubmit } = form;
  return (
    <FormProvider {...form} graphQLErrors={graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-flex tw-justify-between tw-mb-4">
          <TextField
            required
            name="firstName"
            placeholder="John"
            label="First Name"
            readOnly={true}
            disabled={true}
            ref={register({ required: true })}
          />
          <TextField
            required
            name="lastName"
            placeholder="Doe"
            label="Last Name"
            readOnly={true}
            disabled={true}
            ref={register({ required: true })}
          />
        </div>
        <TextField
          required
          rootClass="tw-mb-8"
          placeholder="example@example.com"
          name="email"
          label="Email Address"
          ref={register({ required: true })}
        />
        <MaskedTextField
          required
          rootClass="tw-mb-4"
          name="ownership"
          label="Percentage Ownership"
          placeholder="%"
          suffix="%"
          allowNegative={false}
          isNumericString={true}
          isAllowed={validateInteger}
          rules={register({ required: true })}
        />
        {<Checkbox rootClass="tw-mb-4" name="isDirector" label="Is a Director" ref={register({})} />}
        <div className="tw-flex tw-justify-end">
          <SubmitButton disabled={loading}>{loading ? 'Submitting...' : buttonLabel}</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

export const AddIndividualOwnerModal = ({ show, setShow, onSuccess, isPartnership }) => {
  const onClose = () => setShow(false);
  const [addIndividualOwner, { loading, error }] = useMutation(ADD_INDIVIDUAL_OWNER);
  const graphQLErrors = error && error.graphQLErrors;

  const onSubmit = async (data) => {
    try {
      await addIndividualOwner({
        variables: { ...data, roles: data.isDirector ? [userRoles.DIRECTOR] : [], ownership: parseInt(data.ownership) },
      });
      ampTrackEvent('onboarding: add_individual_owner: success');
      onSuccess && onSuccess();
      onClose();
      toast.success('Contact successfully added');
    } catch (err) {
      ampTrackEvent('onboarding: add_corporate_owner: error');
      console.error(err);
    }
  };

  const defaultValues = {
    address: {
      countrySubdivision: 'AB',
      country: 'CA',
    },
  };

  return (
    <BaseContactModal show={show} onClose={onClose} title="Add Contact" subTitle="New Individual Owner Details">
      <IndividualOwnerForm
        onSubmit={onSubmit}
        loading={loading}
        graphQLErrors={graphQLErrors}
        buttonLabel="Add Contact"
        isPartnership={isPartnership}
        initialValues={defaultValues}
      />
    </BaseContactModal>
  );
};

export const EditIndividualOwnerModal = ({ show, setShow, contact, onSuccess, onClose, isPartnership }) => {
  const handleClose = () => {
    setShow(false);
    onClose && onClose();
  };
  const [editIndividualOwner, { loading, error }] = useMutation(EDIT_INDIVIDUAL_OWNER);
  const graphQLErrors = error && error.graphQLErrors;

  const onSubmit = async (data) => {
    const newRoles = [
      ...contact.roles.filter((r) => r !== userRoles.DIRECTOR),
      ...(data.isDirector ? [userRoles.DIRECTOR] : []),
    ];
    const extraData = isOwnerOrManager
      ? { email: contact.email, firstName: contact.firstName, lastName: contact.lastName }
      : {};

    try {
      await editIndividualOwner({
        variables: {
          ...data,
          ...extraData,
          contactId: contact.id,
          roles: newRoles,
          ownership: parseInt(data.ownership),
        },
      });

      ampTrackEvent('onboarding: update_individual_owner: success');
      onSuccess && onSuccess();
      handleClose();
      toast.success('Contact successfully updated');
    } catch (err) {
      ampTrackEvent('onboarding: update_corporate_owner: error');
      console.error(err);
    }
  };
  const roles = get(contact, 'roles') || [];
  const defaultValues = {
    ...contact,
    isDirector: roles.includes(userRoles.DIRECTOR),
  };
  const isOwnerOrManager = roles.includes(userRoles.ACCOUNT_OWNER) || roles.includes(userRoles.ACCOUNT_MANAGER);

  return (
    <BaseContactModal show={show} onClose={handleClose} title="Edit Contact" subTitle="Individual Owner Details">
      {contact && (
        <IndividualOwnerForm
          onSubmit={onSubmit}
          initialValues={defaultValues}
          loading={loading}
          graphQLErrors={graphQLErrors}
          buttonLabel="Update Contact"
          isPartnership={isPartnership}
        />
      )}
    </BaseContactModal>
  );
};

export const EditIndividualRegistryOwnerModal = ({ show, setShow, contact, onSuccess, onClose, isPartnership }) => {
  const handleClose = () => {
    setShow(false);
    onClose?.();
  };
  const [editIndividualOwner, { loading, error }] = useMutation(EDIT_INDIVIDUAL_OWNER);
  const graphQLErrors = error?.graphQLErrors;
  const onSubmit = async (data) => {
    const newRoles = [...(data.isDirector ? [userRoles.DIRECTOR] : [])];
    try {
      await editIndividualOwner({
        variables: {
          ...data,
          ...{ firstName: contact.firstName, lastName: contact.lastName },
          contactId: contact.id,
          roles: newRoles,
          ownership: parseInt(data.ownership),
        },
      });

      ampTrackEvent('onboarding: update_individual_owner: success');
      onSuccess?.();
      handleClose();
    } catch (err) {
      ampTrackEvent('onboarding: update_corporate_owner: error');
      console.error(err);
    }
  };
  const roles = get(contact, 'roles', []);
  const defaultValues = {
    ...contact,
    isDirector: roles.includes(userRoles.DIRECTOR),
  };

  return (
    <BaseContactModal show={show} onClose={handleClose} title="Edit Contact" subTitle="Individual Owner Details">
      {contact && (
        <RegistryIndividualOwnerForm
          onSubmit={onSubmit}
          initialValues={defaultValues}
          loading={loading}
          graphQLErrors={graphQLErrors}
          buttonLabel="Update Contact"
          isPartnership={isPartnership}
        />
      )}
    </BaseContactModal>
  );
};

export const ConfirmOwnerDeletionModal = (props) => {
  const { show, setShow, onClose, onConfirm, owner, contact, loading } = props;

  const handleClose = () => {
    setShow(false);
    onClose && onClose();
  };

  const OwnerCard = ({ owner }) => (
    <div className="tw-w-full tw-border tw-border-neutral-grey-3 tw-p-4 tw-rounded-md">
      <strong>{owner.name}</strong>
      <div className="tw-flex tw-my-4">
        <p className="tw-mr-8">Ownership %</p>
        <p>{owner.ownership}</p>
      </div>
      <div className="tw-flex">
        <p className="tw-mr-8">Is a Director</p>
        {owner.isDirector && <IoCheckmarkOutline size={24} className="tw-text-neutral-grey-1" />}
      </div>
    </div>
  );

  return (
    <Modal show={show} onClose={handleClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <p>Confirm</p>
        <Close className="tw-cursor-pointer" onClick={handleClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-items-center tw-px-8 tw-pt-8 tw-p-4 tw-border-t tw-border-neutral-grey-4">
        <Remove />
        {!contact && (
          <strong className="tw-my-8">
            Are you sure you want to remove this person from the list of owners and directors?
          </strong>
        )}
        {contact && (
          <p className="tw-my-8">
            Are you sure you want to remove{' '}
            <strong>
              {contact.firstName} {contact.lastName}
            </strong>{' '}
            from the list of owners and directors?
          </p>
        )}
        {owner && <OwnerCard owner={owner} />}
      </div>
      <div className="tw-w-full tw-flex tw-justify-between tw-border-t tw-border-neutral-grey-4 tw-mt-8 tw-px-8 tw-py-4">
        <Button secondary className="tw-w-1/3 tw-bg-neutral-grey-5 tw-text-neutral-dark" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          className="tw-w-1/3 tw-text-neutral-light tw-py-2 tw-px-4 tw-rounded-md tw-text-center"
          isDisabled={loading}
          warning={true}
        >
          {loading ? 'Removing...' : 'Remove'}
        </Button>
      </div>
    </Modal>
  );
};

export const ConfirmationModal = (props) => {
  const { show, setShow, onSuccess } = props;
  const [completeAccountApplication, { loading }] = useMutation(COMPLETE_ACCOUNT_APPLICATION);

  const onConfirm = async ({ exposedInPolitics, fundsInThirdParty }) => {
    try {
      const response = await completeAccountApplication({ variables: { exposedInPolitics, fundsInThirdParty } });

      if (!response?.data?.completeAccountApplication) throw new Error('Failed to submit business contacts data');

      ampTrackEvent('onboarding: business_contacts: success');
      onSuccess();
    } catch (error) {
      ampTrackEvent('onboarding: business_contacts: error');
      console.error(error);
      toast.error(error?.message || error);
    }
  };

  return <LastStepModal show={show} setShow={setShow} onSuccess={onConfirm} loading={loading} />;
};
