import React from 'react';
import { FaRegSnowflake } from 'react-icons/fa';
import { IoCloseCircleOutline } from 'react-icons/io5';

import SandClock from 'components/svg/SandClock';
import { BadgeStatus } from 'components/UI/Badge';
import { CreditCardStatus } from 'types/creditCard';

export const mapCardStatusToBadgeProps = (status: CreditCardStatus) => {
  switch (status) {
    case CreditCardStatus.active:
      return {
        badgeStatus: BadgeStatus.success,
        title: 'Active',
      };
    case CreditCardStatus.suspended:
      return {
        badgeStatus: BadgeStatus.primary,
        title: 'Frozen',
        icon: <FaRegSnowflake />,
      };
    case CreditCardStatus.admin_suspended:
      return {
        badgeStatus: BadgeStatus.primary,
        title: 'Suspended by Admin',
        icon: <FaRegSnowflake />,
      };
    case CreditCardStatus.lost:
    case CreditCardStatus.stolen:
    case CreditCardStatus.fraudulent:
    case CreditCardStatus.damaged:
    case CreditCardStatus.block:
      return {
        badgeStatus: BadgeStatus.info,
        title: 'Closed',
        icon: <IoCloseCircleOutline />,
      };
    default:
      return {
        badgeStatus: BadgeStatus.info,
        title: 'Inactive',
        icon: <SandClock />,
      };
  }
};
