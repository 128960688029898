import React from 'react';

import CheckmarksAndStars from 'components/svg/CheckmarksAndStars';

const Success = () => (
  <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-max-w-md tw-m-auto tw-py-16">
    <CheckmarksAndStars />
    <div className="tw-font-bold tw-text-lg">Certification of Beneficial Ownership Complete</div>
    <div className="tw-text-center">
      Thank you for submitting your Certification of Beneficial Ownership. Please proceed to the next page to complete
      your US bank account verification.
    </div>
  </div>
);

export default Success;
