import React from 'react';
import cx from 'classnames';
import { RxCross2 } from 'react-icons/rx';
import { FaCheck } from 'react-icons/fa6';
import { Disclosure, Transition } from '@headlessui/react';
import { IoCaretDownCircleOutline } from 'react-icons/io5';

const ViewPermissionSection = ({
  title,
  memberPermissions,
  sectionPermissions,
}: {
  title: string;
  memberPermissions: string[];
  sectionPermissions: { label: string; value: string }[];
}) => {
  return (
    <div className="tw-ml-3 tw-mt-4 tw-mb-4">
      <Disclosure as="div">
        {({ open }) => (
          <>
            <Disclosure.Button className="tw-flex tw-justify-between tw-items-center tw-w-full tw-text-sm tw-py-2 tw-text-neutral-grey-1">
              <strong className="tw-text-xl tw-text-primary-dark-green">{title}</strong>
              <IoCaretDownCircleOutline
                size={24}
                className={cx(
                  'tw-text-primary-dark-green tw-transition tw-duration-100 tw-ease-out tw-transform tw-mr-4 tw-text-2xl',
                  open && 'tw-rotate-180'
                )}
              />
            </Disclosure.Button>
            <Transition
              show={open}
              enter="tw-transition tw-duration-100 tw-ease-out"
              enterFrom="tw-transform tw-scale-95 tw-opacity-0"
              enterTo="tw-transform tw-scale-100 tw-opacity-100"
              leave="tw-transition tw-duration-75 tw-ease-out"
              leaveFrom="tw-transform tw-scale-100 tw-opacity-100"
              leaveTo="tw-transform tw-scale-95 tw-opacity-0"
              unmount={false}
            >
              <div className="tw-pl-4">
                <Disclosure.Panel unmount={true}>
                  {sectionPermissions.map((permission) => (
                    <div
                      key={permission.value}
                      className="tw-flex tw-flex-row tw-py-2 tw-items-center tw-content-between tw-ml-3"
                    >
                      <div className="tw-mr-4">
                        {memberPermissions.includes(permission.value) ? <FaCheck /> : <RxCross2 />}
                      </div>
                      <div>{permission.label}</div>
                    </div>
                  ))}
                </Disclosure.Panel>
              </div>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default ViewPermissionSection;
