import React, { useState } from 'react';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import config from 'config';
import WaitingRoom from 'components/onboarding/WaitingRoom';
import { GET_KYC_AND_CREDIT_ASSESSMENT_STATUS } from 'graphql/onboarding';
import { parseKYCStatus } from 'utility/kyc';

export const useGetCreditAssessmentStatus = () => {
  const [isDeclined, setIsDeclined] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const [isMissingContactDetails, setIsMissingContactDetails] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isManualKYCReview, setIsManualKYCReview] = useState(false);
  const [tries, setRetries] = useState(1);

  const handleDataReceived = (data) => {
    const status = get(data, 'me.account.kycAssessment.status');
    let statusMissingEmail = get(data, 'me.account.contactsWithoutEmail');

    // TODO: Remove the following filtering after https://getloop.atlassian.net/browse/LBP-2314 will be completed
    if (config.env === 'testing') {
      statusMissingEmail = statusMissingEmail.filter(
        ({ firstName, lastName }) => !(firstName === 'John' && lastName === 'Doe')
      );
    }

    setRetries(tries + 1);
    if (tries === 6) {
      setIsManualKYCReview(true);
    }

    if (statusMissingEmail?.length) {
      setIsMissingContactDetails(true);
    }
    parseKYCStatus({
      status,
      onOk: () => setIsOk(true),
      onDeclined: () => setIsDeclined(true),
      onWait: () => setIsVerifying(true),
      onManualKYCReview: () => setIsManualKYCReview(true),
    });
  };

  const { loading } = useQuery(GET_KYC_AND_CREDIT_ASSESSMENT_STATUS, {
    onCompleted: handleDataReceived,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: 10000,
  });

  return {
    isDeclined,
    isOk,
    isVerifying,
    isManualKYCReview,
    isMissingContactDetails,
    loading,
  };
};

const WaitingRoomContainer = () => {
  const { isDeclined, isVerifying, isOk, loading, isManualKYCReview, isMissingContactDetails } =
    useGetCreditAssessmentStatus();

  return (
    <WaitingRoom
      isDeclined={isDeclined}
      isVerifying={isVerifying}
      isOk={isOk}
      loading={loading}
      isManualKYCReview={isManualKYCReview}
      isMissingContactDetails={isMissingContactDetails}
    />
  );
};

export default WaitingRoomContainer;
