import React, { useState, useCallback } from 'react';

import { BiCheck } from 'react-icons/bi';
import Modal from 'components/Modal/v2';
import Button from 'components/Button';
import Close from 'components/svg/Close';
import ArrowRight from 'components/svg/ArrowRight';
import { ConnectedChannel, InsufficientRevenue, SalesChannelButton, TypeFormSalesChannel } from './components';

const SelectSalesModal = ({
  show,
  onClose,
  onSubmit,
  salesChannels,
  connectedAccounts,
  hasStatus,
  isApproved,
  isRejected,
  businessName,
}) => {
  const [typeFormSubmitted, setTypeFormSubmitted] = useState(false);
  const isDisabled = !isApproved && !typeFormSubmitted;

  const onTypeFormSalesChannelSubmit = useCallback(() => {
    // Remove inline styles from body and html that is added after the Typeform is opened up
    // https://getloop.atlassian.net/browse/AO-1087
    document.documentElement.removeAttribute('style');
    document.body.removeAttribute('style');
    setTypeFormSubmitted(true);
  }, []);

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <b>Choose Your Account Type</b>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>

      <div className="tw-pt-8 tw-pb-4 tw-px-8">
        {connectedAccounts?.map((account) => {
          const channel =
            salesChannels.find((channel) => channel.name.includes(account.name)) ||
            salesChannels.find((channel) => channel.platforms && channel.platforms.indexOf(account.name) > -1);

          return <ConnectedChannel key={account.id} channel={channel} account={account} platform={account.name} />;
        })}

        {hasStatus && isRejected && <InsufficientRevenue />}

        {typeFormSubmitted && (
          <div className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-secondary-light-green tw-mb-8">
            <div className="tw-mr-4 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
              <BiCheck className="tw-text-secondary-light-green" />
            </div>
            <div className="tw-text-sm tw-text-semantic-success">Thank you for providing your business details.</div>
          </div>
        )}

        <p className="tw-mb-8">Connect more channels</p>
        <div className="channel-integrations tw-flex tw-flex-row tw-flex-wrap">
          {salesChannels?.map((channel) => (
            <SalesChannelButton
              key={channel.name}
              channel={channel}
              connectedPlatforms={connectedAccounts.map((x) => x.connected && x.name.toUpperCase())}
            />
          ))}
        </div>
        {!typeFormSubmitted && (
          <TypeFormSalesChannel businessName={businessName} onSubmit={onTypeFormSalesChannelSubmit} />
        )}
      </div>
      <div className="tw-flex tw-justify-end tw-items-center tw-px-8 tw-py-4 tw-border-t tw-border-neutral-grey-4">
        <Button type="submit" primary onClick={onSubmit} isDisabled={isDisabled}>
          <div className="tw-flex tw-justify-center tw-items-center">
            <p className="tw-text-neutral-light tw-mr-2">Continue</p>
            <ArrowRight />
          </div>
        </Button>
      </div>
    </Modal>
  );
};

export default SelectSalesModal;
