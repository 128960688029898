import React from 'react';

import { Modal } from 'components/UI';
import { PaymentRequestStatus } from 'types/invoicing';
import { getDueDateNote } from 'components/Invoices/Invoices.utils';
import { RequestInfoModalProps } from './RequestInfoModal.types';
import { usePaymentRequestFieldValues } from '../../hooks';
import { getRequestStatusDescription } from './RequestInfoModal.utils';

const RequestInfoModal = ({ show, onClose, paymentRequest }: RequestInfoModalProps) => {
  const {
    payorName,
    accountName,
    formattedAmount,
    currency,
    formattedInitDate,
    formattedDueDate,
    status,
    formattedPaymentRequestFee,
    invoiceNumber,
    isOngoing,
    frequencyLabel,
    isStoppedByEndDate,
    formattedEndDate,
    isStoppedByNumberOfOccurrences,
    numberOfOccurrences,
    isStoppedByCancel,
  } = usePaymentRequestFieldValues({ paymentRequest });

  const statusDescription = getRequestStatusDescription(status);

  const showAdditionalStatusInfo = status === PaymentRequestStatus.pending;
  const dueDateNote = getDueDateNote(!isOngoing ? 'Due Date' : 'Start Date');

  return (
    <Modal show={show} onClose={onClose} title="Payment Request Confirmation">
      <div className="tw-flex tw-flex-col tw-px-8 tw-pt-6 tw-pb-8 tw-space-y-4">
        <div className="tw-text-sm">{statusDescription}</div>
        {showAdditionalStatusInfo && <div className="tw-text-xs tw-text-neutral-grey-1">{dueDateNote}</div>}
        <div className="tw-flex tw-pt-4">
          <span className="tw-text-sm tw-w-2/3 tw-text-neutral-grey-1">Payor Legal Name</span>
          <span className="tw-text-sm tw-w-1/3">{payorName}</span>
        </div>
        <div className="tw-flex">
          <span className="tw-text-sm tw-w-2/3 tw-text-neutral-grey-1">Deposit To</span>
          <span className="tw-text-sm tw-w-1/3">{accountName}</span>
        </div>
        <div className="tw-flex">
          <span className="tw-text-sm tw-w-2/3 tw-text-neutral-grey-1">Amount</span>
          <span className="tw-text-sm tw-w-1/3">{`${formattedAmount} ${currency}`}</span>
        </div>
        <div className="tw-flex">
          <span className="tw-text-sm tw-w-2/3 tw-text-neutral-grey-1">Currency</span>
          <span className="tw-text-sm tw-w-1/3">{currency}</span>
        </div>
        <div className="tw-flex">
          <span className="tw-text-sm tw-w-2/3 tw-text-neutral-grey-1">Fee</span>
          <span className="tw-text-sm tw-w-1/3">{`${formattedPaymentRequestFee} ${currency}`}</span>
        </div>
        <div className="tw-flex">
          <span className="tw-text-sm tw-w-2/3 tw-text-neutral-grey-1">Date Initiated</span>
          <span className="tw-text-sm tw-w-1/3">{formattedInitDate}</span>
        </div>
        {!isOngoing ? (
          <div className="tw-flex">
            <span className="tw-text-sm tw-w-2/3 tw-text-neutral-grey-1">Due Date</span>
            <span className="tw-text-sm tw-w-1/3">{formattedDueDate}</span>
          </div>
        ) : (
          <>
            <div className="tw-flex">
              <span className="tw-text-sm tw-text-neutral-grey-1 tw-w-2/3">Frequency</span>
              <span className="tw-text-sm tw-w-1/3">{frequencyLabel}</span>
            </div>
            <div className="tw-flex">
              <span className="tw-text-sm tw-w-2/3 tw-text-neutral-grey-1">Start Date</span>
              <span className="tw-text-sm tw-w-1/3">{formattedDueDate}</span>
            </div>
            {isStoppedByEndDate && formattedEndDate && (
              <div className="tw-flex">
                <span className="tw-text-sm tw-w-2/3 tw-text-neutral-grey-1">End Date</span>
                <span className="tw-text-sm tw-w-1/3">{formattedEndDate}</span>
              </div>
            )}
            {isStoppedByNumberOfOccurrences && numberOfOccurrences && (
              <div className="tw-flex">
                <div className="tw-text-sm tw-w-2/3 tw-text-neutral-grey-1">Number of Payments</div>
                <span className="tw-text-sm tw-w-1/3">{numberOfOccurrences}</span>
              </div>
            )}
            {isStoppedByCancel && <div className="tw-text-sm">These payments will stop upon cancellation</div>}
          </>
        )}
        {invoiceNumber && (
          <div className="tw-flex">
            <span className="tw-text-sm tw-w-2/3 tw-text-neutral-grey-1">Invoice Number</span>
            <span className="tw-text-sm tw-w-1/3">{invoiceNumber}</span>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RequestInfoModal;
