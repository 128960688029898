import React, { useState } from 'react';

import config from '../../config';
import { ampTrackEvent } from '../../amplitude';
import Close from '../svg/Close';
import { validUrl } from '../../utility/string';
import Button from 'components/Button';

const V1 = (props) => {
  const { onChange, uid, error, isSubmitting, onSubmit, onCancel } = props;

  return (
    <div>
      <div className="bg-neutral-dark text-white rounded-xl p-5 shadow-sm mb-4 overflow-hidden">
        <h6 className="mb-4 text-white">Connect your Shopify store</h6>
        <div>
          <label>Shopify URL</label>
          <input
            onChange={onChange}
            type="text"
            placeholder="your-shop-url.myshopify.com"
            className="form-control mt-2"
            value={uid}
            readOnly={uid}
            name="shop"
          />
        </div>

        {error ? <div className="text-danger my-2 small">{error}</div> : null}

        <div className="mt-4 overflow-hidden">
          <Button
            type="button"
            className="btn btn-main rounded-xl float-right"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
          <Button type="button" className="btn btn-secondary rounded-xl mr-4 float-right" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

const V2 = (props) => {
  const { onChange, uid, error, isSubmitting, onSubmit, onCancel } = props;
  const isValid = !isSubmitting && uid;

  return (
    <div className="tw-rounded-md tw-bg-neutral-light">
      <div className="tw-flex tw-justify-between tw-px-8 tw-py-4 tw-border-b tw-border-neutral-grey-4">
        <p>Connect sales channel</p>
        <Close className="tw-cursor-pointer" onClick={onCancel} />
      </div>
      <div className="tw-px-8 tw-py-8">
        <img className="tw-w-24 tw-mb-4" src="https://loop-public.s3.ca-central-1.amazonaws.com/shopify_logo.png" />
        <h3 className="tw-mb-8">Connect your Shopify store</h3>
        <div>
          <div className="tw-flex tw-flex-col">
            <label htmlFor="uid" className="tw-text-neutral-grey-1">
              Shopify URL
            </label>
            <input
              id="uid"
              name="uid"
              className={`tw-block tw-px-3 tw-py-2 tw-rounded-md tw-w-full tw-bg-white tw-border-2 ${
                error ? 'tw-border-semantic-error' : 'tw-border-neutral-grey-3'
              } tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input`}
              onChange={onChange}
              label="Shopify URL"
              placeholder="your-shop-url.myshopify.com"
              value={uid}
            />
            {error ? <small className="tw-text-semantic-error tw-my-2">{error}</small> : null}
          </div>
        </div>

        <Button
          className={`tw-w-full tw-mt-12 ${
            isValid ? 'tw-bg-primary-dark-green' : 'tw-bg-neutral-grey-3 tw-cursor-not-allowed'
          } tw-bg-primary-dark-green tw-text-neutral-light tw-py-2 tw-px-4 tw-rounded-md tw-text-center`}
          disabled={!isValid}
          onClick={onSubmit}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </div>
    </div>
  );
};

const ConnectShopify = (props) => {
  const { onCancel, isOnboarding, isPreApproval, uid, layout } = props;
  const [isSubmitting, setIsSubmitting] = useState();
  const [shopifyUrl, setShopifyUrl] = useState(uid);
  const [error, setError] = useState();

  const handleInputChange = (e) => {
    setShopifyUrl(e.target.value);
  };

  const handleSubmit = async () => {
    if (!shopifyUrl || !shopifyUrl.trim()) return setError('Shopify URL Required');
    if (!validUrl(shopifyUrl)) {
      return setError('Shopify URL is invalid');
    }

    setError();
    setIsSubmitting(true);
    ampTrackEvent('Clicked: Connect Shopify - Integrations');

    const state = Math.floor(Math.random() * 1e7);

    let redirectPath = '';
    if (isOnboarding) redirectPath = 'onboarding/';
    if (isPreApproval) redirectPath = 'pre-approval/';

    const redirectURI = encodeURIComponent(`${window.location.origin}/integration/callback/${redirectPath}shopify`);
    const scope = encodeURIComponent(
      'read_inventory,read_orders,read_all_orders,read_products,read_shipping,read_analytics,read_shopify_payments_payouts'
    );

    const queryParams = `client_id=${config.shopifyClientId}&redirect_uri=${redirectURI}&response_type=code&scope=${scope}&state=${state}`;
    const shopifyBaseUrl = shopifyUrl.startsWith('https://') ? shopifyUrl : `https://${shopifyUrl}`;
    window.location.href = new URL(`/admin/oauth/authorize?${queryParams}`, shopifyBaseUrl).href;
  };

  const Layout = isPreApproval || layout === 'V2' ? V2 : V1;

  return (
    <Layout
      onChange={handleInputChange}
      onSubmit={handleSubmit}
      error={error}
      isSubmitting={isSubmitting}
      onCancel={onCancel}
      uid={shopifyUrl}
    />
  );
};

export default ConnectShopify;
