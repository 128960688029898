import React from 'react';
import cx from 'classnames';

import Button from 'components/Button';
import { ErrorLabel } from 'components/FormFields/v2';
import { useOwnershipDisclosure } from './hooks';
import { Content } from './components';
import { OwnershipDisclosureProps } from './OwnershipDisclosure.types';

const OwnershipDisclosure = ({
  onPreviousStep,
  onNextStep,
  enableFullScreen,
  disableFullScreen,
}: OwnershipDisclosureProps) => {
  const {
    ownershipDisclosureUrl,
    isLoading,
    error,
    isDisclosurePreSigned,
    isDisclosureSigningFailed,
    isDisclosureSigningConfirmed,
  } = useOwnershipDisclosure({
    enableFullScreen,
    disableFullScreen,
  });

  return (
    <div>
      <div className="tw-px-8 tw-my-8">
        <div className="tw-text-neutral-grey-2">Please sign the ownership disclosure agreement below.</div>
        <div className="tw-my-4" style={{ minHeight: '40rem' }}>
          <Content
            iframeUrl={ownershipDisclosureUrl}
            isLoading={isLoading || isDisclosurePreSigned}
            isError={isDisclosureSigningFailed}
            isCompleted={isDisclosureSigningConfirmed}
          />
        </div>

        {error && (
          <ErrorLabel
            className="tw-px-8"
            error={error.message ? error : { message: 'Something went wrong. Please try again later.' }}
          />
        )}
      </div>
      <div
        className={cx(
          'tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex tw-flex-col lg:tw-flex-row tw-gap-4 tw-mt-8',
          onPreviousStep ? 'lg:tw-justify-between' : 'lg:tw-justify-end'
        )}
      >
        {onPreviousStep && (
          <Button
            secondary
            className="tw-w-full lg:tw-w-40"
            onClick={onPreviousStep}
            isDisabled={isDisclosureSigningConfirmed}
          >
            Sign Later
          </Button>
        )}
        <Button
          primary
          className="tw-w-full lg:tw-w-40"
          onClick={onNextStep}
          isDisabled={!isDisclosureSigningConfirmed}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default OwnershipDisclosure;
