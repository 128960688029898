import React, { useMemo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import cx from 'classnames';

import { useToggle } from 'hooks';
import { ExpandableNavItemProps } from './ExpandableNavItem.types';
import { FeaturedLabel, BetaLabel } from '..';

const ExpandableNavItem = ({ item, selectedSubRoute = false, isFeatured = false, isBeta }: ExpandableNavItemProps) => {
  const location = useLocation();
  const history = useHistory();

  const selectedChild = useMemo(
    () => item?.childItems?.find((childItem) => childItem.route === location.pathname + location.search),
    [item, location.pathname, location.search]
  );

  const { isOpen: isSelected, toggle: toggleSelection } = useToggle(!!selectedChild || selectedSubRoute);

  const handleOnClick = () => {
    if (item.redirectRoute) history.push(item.redirectRoute);

    toggleSelection();
  };

  return (
    <div className="tw-py-3">
      <div
        className={cx(
          'tw-cursor-pointer tw-flex tw-pl-12 tw-items-center hover:tw-text-primary-dark-green',
          isSelected && 'tw-text-primary-dark-green'
        )}
        onClick={handleOnClick}
      >
        <item.Icon className="tw-flex-shrink-0 tw-w-21-px" />
        <small className="tw-mx-4 tw-font-semibold">{item.label}</small>
        {isFeatured && <FeaturedLabel />}
        {isBeta && <BetaLabel />}
      </div>
      <Transition
        enter="tw-ease-out tw-duration-300"
        enterFrom="tw-opacity-0"
        enterTo="tw-opacity-100"
        leave="tw-ease-in tw-duration-200"
        leaveFrom="tw-opacity-100"
        leaveTo="tw-opacity-0"
        show={isSelected}
        className="tw-space-y-4 tw-mt-4"
      >
        {item?.childItems?.map((childItem) => {
          const isSelectedChild = selectedChild && selectedChild.label === childItem.label;

          return (
            <Link
              key={childItem.route}
              className={cx(
                'tw-flex tw-pl-20 tw-items-center hover:tw-text-primary-dark-green',
                isSelectedChild && 'tw-text-primary-dark-green'
              )}
              to={childItem.route}
            >
              <small className="tw-font-semibold tw-pl-2">{childItem.label}</small>
            </Link>
          );
        })}
      </Transition>
    </div>
  );
};

export default ExpandableNavItem;
