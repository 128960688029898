import React, { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import useIsMember from 'hooks/useIsMember';
import ToggleCardDetails from './ToggleCardDetails';
import ToggleAddresses from './ToggleAddresses';
import ToggleFreezeCard from './ToggleFreezeCard';
import ReportLostOrStolen from './ReportLostOrStolen/ReportLostOrStolen';
import ShareCardWithTeamMember from './ShareCardWithTeamMember';
import TogglePin from './TogglePin';
import ResetPinAttempts from './ResetPinAttempts/ResetPinAttempts';
import ToggleUpdateCardLimitSettings from './ToggleUpdateCardLimitSettings';
import useFeatureToggle from 'hooks/useFeatureToggle';

const ActiveOrFrozenCardActions = ({ creditCard }) => {
  const { isCreator } = creditCard;
  const { isMember } = useIsMember();

  const { isImpersonating } = useContext(AuthContext);

  const onCopyValueToClipboard = (value) => navigator.clipboard.writeText(value);

  const { isExperimentEnabled: isUpdateCardLimitSettingsEnabled } = useFeatureToggle(
    false,
    'update_card_limit_settings'
  );

  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-py-4" data-private>
      <ToggleCardDetails
        creditCard={creditCard}
        onCopyValueToClipboard={onCopyValueToClipboard}
        isImpersonating={isImpersonating}
      />
      <ToggleAddresses
        creditCard={creditCard}
        onCopyValueToClipboard={onCopyValueToClipboard}
        isImpersonating={isImpersonating}
      />
      {isUpdateCardLimitSettingsEnabled && isCreator && <ToggleUpdateCardLimitSettings creditCard={creditCard} />}
      {isCreator && <ToggleFreezeCard creditCard={creditCard} />}
      {isCreator && <ReportLostOrStolen creditCard={creditCard} />}
      {!isMember && isCreator && <ShareCardWithTeamMember creditCard={creditCard} />}
      {!creditCard.virtual && creditCard.pin && (
        <TogglePin
          creditCard={creditCard}
          onCopyValueToClipboard={onCopyValueToClipboard}
          isImpersonating={isImpersonating}
        />
      )}
      <ResetPinAttempts creditCard={creditCard} />
    </div>
  );
};

export default ActiveOrFrozenCardActions;
