import React from 'react';

import { useGetLineOfCreditInfo } from 'hooks';
import LineOfCredit from '../components/lineOfCredit/LineOfCredit';

const LineOfCreditContainer = () => {
  const {
    loading,
    lineOfCredit,
    lineOfCreditLedgerAccount,
    wallets,
    creditCards,
    bankAccounts,
    transactions,
    lineOfCreditAccNum,
    hasLineOfCredit,
    fundInfo,
  } = useGetLineOfCreditInfo();

  return (
    <LineOfCredit
      hasLineOfCredit={hasLineOfCredit}
      lineOfCredit={lineOfCredit}
      lineOfCreditLedgerAccount={lineOfCreditLedgerAccount}
      lineOfCreditAccNum={lineOfCreditAccNum}
      fundInfo={fundInfo}
      wallets={wallets}
      creditCards={creditCards}
      bankAccounts={bankAccounts}
      transactions={transactions}
      loading={loading}
    />
  );
};

export default LineOfCreditContainer;
