import { useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateCard } from '../../../../../hooks/useCreateCard';
import { CreateCardContext } from 'components/creditCards/components/CreateCardModal/context/CreateCardContext';

const useCardChargeFee = () => {
  const { onNextStep, onPrevStep, currentStep, loadingMembers, steps, chargePhysicalCardFee } =
    useContext(CreateCardContext);

  const form = useForm({});
  const { handleCreateCard, isSubmitting } = useCreateCard();

  const { handleSubmit } = form;

  const handleSubmitForm = useCallback(async () => {
    const result = await handleCreateCard();
    if (result?.success) onNextStep();
  }, [handleCreateCard, onNextStep]);

  const handleOnNextStep = () => {
    handleSubmitForm();
  };

  const handleOnPreviousStep = () => {
    form.reset();
    onPrevStep();
  };

  return {
    form,
    handleSubmit,
    isSubmitting,
    handleOnNextStep,
    handleOnPreviousStep,
    currentStep,
    loadingMembers,
    chargePhysicalCardFee,
    steps,
  };
};

export default useCardChargeFee;
