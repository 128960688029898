import { gql } from '@apollo/client';

export const GET_REDEEMED_GIFTS = gql`
  query RedeemedGifts {
    redeemedGifts {
      internalId
      category
      provider
      pointsRequired
      giftValue {
        amount
        currency
      }
      giftLink
      status
      redeemedAt
    }
  }
`;

export const GET_GIFT_OFFERS = gql`
  query GiftOffers {
    giftOffers {
      internalId
      category
      provider
      description
      pointsRequired
      giftValue {
        amount
        currency
      }
      imageUrl
      redemptionDetails
      available
    }
  }
`;

export const REDEEM_POINTS = gql`
  mutation RedeemPoints($rewardsAccount: String!, $giftOffer: String!) {
    redeemPoints(rewardsAccount: $rewardsAccount, giftOffer: $giftOffer) {
      internalId
      category
      provider
      pointsRequired
      giftValue {
        amount
        currency
      }
      giftLink
      status
      redeemedAt
    }
  }
`;

export const GET_BORROWER_REFERRAL_CODE_AND_POINTS = gql`
  query BorrowerReferralCodeAndPoints {
    me {
      ... on User {
        account {
          referralCode
          registrationReferralPoints
        }
      }
    }
  }
`;

export const GET_REGISTRATION_REFERRAL = gql`
  query RegistrationReferral {
    me {
      ... on User {
        account {
          registrationReferral {
            id
            referrerBorrowerName
          }
        }
      }
    }
  }
`;
