import { useState, useEffect, useMemo } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { isAfter } from 'date-fns';

import {
  CreditCardStatementsQueryResponse,
  CurrentBillingCycleInfoQueryResponse,
  GroupOngoingAmount,
  CardBalance,
} from 'types/creditCard';
import { StatementQueryResponse, Statement } from 'types/statement';
import { GET_CREDIT_CARD_STATEMENT, GET_CREDIT_CARD_STATEMENTS, GET_CURRENT_BILLING_CYCLE_INFO } from 'graphql/cards';
import useIsAdmin from 'hooks/useIsAdmin';
import { useToggle } from 'hooks';
import { CURRENT_CYCLE } from '../constants';
import { parseCurrentBillingCycleInfo2Statement } from '../StatementBalances.utils';

const useStatementBalances = () => {
  const pageTitle = 'Statement Balances';

  const [selectedStatementId, setSelectedStatementId] = useState<string>();
  const [statement, setStatement] = useState<Statement & { nextStatementGenerationDate?: Date }>();
  const [statementsList, setStatementsList] = useState<
    Pick<Statement, 'id' | 'title' | 'createdAt' | 'startDate' | 'endDate'>[]
  >([]);
  const [currentCycleStartDate, setCurrentCycleStartDate] = useState<Date>();
  const [ongoingAmountDueByCurrency, setOngoingAmountDueByCurrency] = useState<GroupOngoingAmount>();
  const [ongoingPayments, setOngoingPayments] = useState<CardBalance[]>();

  const isCurrentCycle = selectedStatementId === CURRENT_CYCLE;
  const isLatestStatement = !!selectedStatementId && selectedStatementId === get(statementsList, '[0].id');

  const { loading: isStatementsListLoading, error: statementsListError } = useQuery<CreditCardStatementsQueryResponse>(
    GET_CREDIT_CARD_STATEMENTS,
    {
      onCompleted: (data) => {
        const statements: Statement[] = get(data, 'primaryCreditCard.statements', []);
        const groupOngoingAmountDueByCurrency: GroupOngoingAmount | undefined = get(
          data,
          'primaryCreditCard.groupOngoingAmountDueByCurrency'
        );
        const groupOngoingPayments: CardBalance[] | undefined = get(data, 'primaryCreditCard.groupOngoingPayments');
        const cycleStartDate: Date = get(data, 'primaryCreditCard.latestCycleStartDate')!;

        setCurrentCycleStartDate(cycleStartDate);
        setStatementsList(statements);
        setOngoingAmountDueByCurrency(groupOngoingAmountDueByCurrency);
        setOngoingPayments(groupOngoingPayments);
        setSelectedStatementId(get(statements, '0.id') || CURRENT_CYCLE);
      },
    }
  );

  const { isAdmin } = useIsAdmin();

  const [getStatementById, { loading: isLoadingStatementDetails, error: statementDetailsError }] =
    useLazyQuery<StatementQueryResponse>(GET_CREDIT_CARD_STATEMENT, {
      onCompleted: (data) => {
        const statement = get(data, 'statement');
        setStatement(statement);
      },
    });

  const [
    getCurrentBillingCycleInfo,
    { loading: isLoadingCurrentBillingCycleInfo, error: currentBillingCycleInfoError },
  ] = useLazyQuery<CurrentBillingCycleInfoQueryResponse>(GET_CURRENT_BILLING_CYCLE_INFO, {
    onCompleted: (data) => {
      const currentBillingCycleInfo = get(data, 'primaryCreditCard');

      const statement = parseCurrentBillingCycleInfo2Statement(currentBillingCycleInfo);
      setStatement(statement);
    },
  });

  const statementStatus = get(statement, 'payment.status');
  const dueDate = get(statement, 'dueDate');
  const isDueDatePassed = useMemo(() => !!dueDate && isAfter(new Date(), new Date(dueDate)), [dueDate]);

  useEffect(() => {
    if (!selectedStatementId) return;

    if (isCurrentCycle) {
      getCurrentBillingCycleInfo();
      return;
    }

    getStatementById({
      variables: {
        statementId: selectedStatementId,
      },
    });
  }, [selectedStatementId, isCurrentCycle]);

  const {
    isOpen: isOpenAddFundsToBalanceModal,
    open: openAddFundsToBalanceModal,
    close: closeAddFundsToBalanceModal,
  } = useToggle();

  return {
    pageTitle,
    selectedStatementId,
    setSelectedStatementId,
    statement,
    currentCycleStartDate,
    statementsList,
    isOpenAddFundsToBalanceModal,
    openAddFundsToBalanceModal,
    closeAddFundsToBalanceModal,
    isLoading: isStatementsListLoading,
    isError: !!statementsListError,
    isLoadingStatementDetails: isCurrentCycle ? isLoadingCurrentBillingCycleInfo : isLoadingStatementDetails,
    isStatementDetailsError: isCurrentCycle ? !!currentBillingCycleInfoError : !!statementDetailsError,
    isCurrentCycle,
    isLatestStatement,
    ongoingAmountDueByCurrency,
    ongoingPayments,
    isDueDatePassed,
    statementStatus,
    isAdmin,
  };
};

export default useStatementBalances;
