import React, { memo } from 'react';
import cx from 'classnames';
import { IoIosReturnRight } from 'react-icons/io';

import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { formatMoneyV2 } from 'utility/currency';
import { AmountCardWithFxProps } from './AmountCardWithFx.types';

import styles from './AmountCardWithFx.module.scss';

const AmountCardWithFx = ({ originalAmount, fxAmount, isDisabled }: AmountCardWithFxProps) => {
  const { currency } = originalAmount;
  const formattedAmount = formatMoneyV2(originalAmount);

  const { currency: fxCurrency } = fxAmount || {};
  const isFX = !!fxCurrency;
  const formattedFxAmount = isFX ? formatMoneyV2(fxAmount) : 0;

  return (
    <div className={cx(styles.wrapper, isDisabled && styles.disabled)}>
      <div className={styles.card}>
        <div className={styles.iconWrapper}>
          <MobileCurrencyFlag currency={currency} size={16} />
        </div>
        <div className={styles.amount}>
          {formattedAmount} <span className={styles.currency}>{currency}</span>
        </div>
      </div>
      {isFX && (
        <div className={cx(styles.card, styles.cardFx)}>
          <IoIosReturnRight size={20} />
          <div className={styles.iconWrapper}>
            <MobileCurrencyFlag currency={fxCurrency} size={14} />
          </div>
          <div className={styles.amount}>
            {formattedFxAmount} <span className={styles.currency}>{fxCurrency}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(AmountCardWithFx);
