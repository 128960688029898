import React, { useContext } from 'react';
import { BiCheck, BiTransferAlt } from 'react-icons/bi';

import { formatMoneyV2 } from 'utility/currency';
import { formatDateTime } from 'utility/date';
import { PaymentContext } from 'context/Payment';
import Button from 'components/Button';

const Complete = ({ onNewTransfer, onFinish, footerBorder, finishButtonLabel, rate, isModal }) => {
  const { moveMoneyTransaction, moveMoneyInfo } = useContext(PaymentContext);
  const { transactionGroupId, transferMethod, initiatedAt } = moveMoneyTransaction;
  const { fromAccount, toAccount, originalAmount, chargedAmount } = moveMoneyInfo;
  const hadConversion = originalAmount.currency !== chargedAmount.currency;
  const isInternalTransfer = transferMethod === 'Internal Transfer';
  const fromAccountDisplayName = fromAccount.displayName
    ? fromAccount.displayName
    : fromAccount.ledgerAccount.displayName;
  const isLocWithdrawal = fromAccountDisplayName.includes('Line Of Credit');

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <div className="tw-p-2 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-secondary-light-green tw-mb-8">
          <div className="tw-mr-4 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
            <BiCheck className="tw-text-secondary-light-green" />
          </div>
          <small className="tw-text-semantic-success">
            {isInternalTransfer
              ? 'Your transfer is complete.'
              : 'Your transfer has been initiated. Completing will take 1 business day.'}
          </small>
        </div>
        <div className={`tw-flex ${isModal ? 'tw-flex-col-reverse' : 'tw-gap-x-8'}`}>
          <div className={`${isModal ? 'tw-w-full' : ' tw-w-2/3'}`}>
            <small>Recipient Account</small>
            <p className="tw-mt-1 tw-mb-4">{toAccount.displayName}</p>
            <small>From Account</small>
            <p className="tw-mt-1 tw-mb-4">{fromAccountDisplayName}</p>
            <small>Amount</small>
            <p className="tw-mt-1 tw-mb-4">{`${originalAmount.currency} ${formatMoneyV2(originalAmount)}`}</p>
            {hadConversion && (
              <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4 tw-mt-4">
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                  <small className="tw-font-semibold">Amount to withdraw</small>
                  <div className="tw-flex tw-items-center">
                    <small className="tw-mr-2">{formatMoneyV2(chargedAmount)}</small>
                    <span className="tw-text-xs tw-text-neutral-grey-2 tw-w-8 tw-text-right">
                      {chargedAmount.currency}
                    </span>
                  </div>
                </div>
                <div className="tw-flex tw-justify-between tw-items-center">
                  <small className="tw-font-semibold">Your exchange rate</small>
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <small className="tw-mr-2">{rate && rate.toFixed(5)}</small>
                    <BiTransferAlt className="tw-text-neutral-grey-2 tw-transform tw-rotate-90 tw-w-8" />
                  </div>
                </div>
              </div>
            )}
            <small>Method</small>
            <p className="tw-mt-1 tw-mb-4">{transferMethod}</p>
            {isLocWithdrawal && (
              <>
                <small className="tw-mt-4">Fees</small>
                <p className="tw-mt-1">
                  You are making a withdrawal from your Line of Credit. Interest and fees will be charged in accordance
                  with your credit agreement.
                </p>
              </>
            )}
          </div>
          <div className={`${isModal ? 'tw-w-full tw-mb-4' : ' tw-w-1/3'}`}>
            <small>Reference ID</small>
            <p className="tw-mt-1 tw-mb-4">{transactionGroupId}</p>
            <small>Initiated at</small>
            <p className="tw-mt-1">{formatDateTime(initiatedAt, "MMMM d, yyyy 'at' h:m a")}</p>
          </div>
        </div>
      </div>
      <div
        className={`${
          footerBorder ? 'tw-border-t tw-border-neutral-grey-3 tw-pt-4' : 'tw-pb-4'
        } tw-px-8 tw-flex tw-flex-col lg:tw-flex-row tw-space-y-4 lg:tw-space-y-0 lg:tw-justify-between`}
      >
        <Button onClick={onFinish} secondary className="tw-w-full lg:tw-w-max">
          {finishButtonLabel}
        </Button>
        <Button onClick={onNewTransfer} primary className="tw-w-full lg:tw-w-max">
          Make Another Transfer
        </Button>
      </div>
    </>
  );
};

export default Complete;
