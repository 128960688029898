import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { ampTrackEvent } from 'amplitude';
import { validateInteger } from 'utility/validators';
import { SUBMIT_SHAREHOLDERS_STEP } from 'graphql/onboarding';
import { TextField, SubmitButton, MaskedTextField, RadioField } from 'components/FormFields/v2';
import Button from 'components/Button';

const InitialOwnership = ({ contacts, refetchContacts }) => {
  const form = useForm();
  const { register, handleSubmit, setError } = form;
  const [submitShareholderStep, { loading: isSubmitting, error }] = useMutation(SUBMIT_SHAREHOLDERS_STEP);
  const graphQLErrors = error && error.graphQLErrors;

  const history = useHistory();
  const onGoToPreviousStep = () => history.push('/onboarding/owners-profile');

  const validateTotalOwnership = (shareholders) => {
    const total = shareholders.reduce((sum, shareholder) => sum + parseInt(shareholder.ownership), 0);
    if (total <= 100) return true;

    for (const i in shareholders) {
      setError(`shareholders[${i}].ownership`, { type: 'manual', message: 'Ownership total must be less than 100%' });
    }

    return false;
  };

  const onSubmit = async (formData) => {
    const shareholders = formData.shareholders.map((data) => ({
      ...data,
      ownership: parseInt(data.ownership),
      isDirector: data.isDirector === 'true',
    }));

    if (validateTotalOwnership(shareholders)) {
      submitInitialOwnership(shareholders);
    }
  };

  const submitInitialOwnership = async (shareholders) => {
    try {
      await submitShareholderStep({ variables: { shareholders } });

      ampTrackEvent('onboarding: ownership: success');
      refetchContacts();
    } catch (err) {
      console.error(err);
      ampTrackEvent('onboarding: ownership: error');
    }
  };

  return (
    <FormProvider {...form} graphQLErrors={graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)} className="tw-mt-8">
        {contacts.map((contact, index) => (
          <div key={contact.id} className="tw-mb-16">
            <input
              className="tw-hidden"
              readOnly
              value={contact.id}
              name={`shareholders[${index}].id`}
              ref={register({ required: true })}
            />
            <div className="tw-flex tw-justify-between tw-mb-8">
              <TextField
                readOnly={true}
                rootClass="tw-mr-2"
                name={`shareholders[${index}].firstName`}
                label="Legal First Name"
                value={contact.firstName}
                disabled={true}
                ref={register({ required: true })}
              />
              <TextField
                name={`shareholders[${index}].lastName`}
                label="Legal Last Name"
                value={contact.lastName}
                disabled={true}
                ref={register({ required: true })}
              />
            </div>
            <MaskedTextField
              required
              rootClass="tw-mb-8"
              name={`shareholders[${index}].ownership`}
              label="Percentage Ownership"
              placeholder="%"
              suffix="%"
              allowNegative={false}
              isNumericString={true}
              isAllowed={validateInteger}
              rules={register({ required: true })}
            />
            <div className="tw-mb-8">
              <label className="tw-text-neutral-grey-1 tw-mb-4">
                Is a Director?
                <span className="tw-text-semantic-error">{' *'}</span>
              </label>
              <RadioField
                className="tw-overflow-hidden"
                name={`shareholders[${index}].isDirector`}
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                ref={register({ required: true })}
              />
            </div>
          </div>
        ))}
        <div className="tw-flex tw-justify-between tw-mt-12">
          <Button
            type="button"
            secondary
            className="tw-w-max tw-bg-neutral-grey-5 tw-text-neutral-dark tw-py-2"
            onClick={onGoToPreviousStep}
          >
            Previous step
          </Button>
          <SubmitButton disabled={isSubmitting} className="tw-w-max">
            {isSubmitting ? 'Submitting...' : 'Continue'}
          </SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default InitialOwnership;
