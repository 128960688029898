import { useMemo, useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { compact, get, isUndefined } from 'lodash';

import { FXOperationType } from 'types/fxRates';
import { PayPayeeContext } from '../../../PayPayeeContext';
import { centsFromMoneyString, formatMoneyV2 } from 'utility/currency';
import { useDeepEffect } from 'hooks';
import useIsContactPermission from 'hooks/useIsContactPermission';
import useGetExchangeRate from 'hooks/useGetExchangeRate';
import { checkIfCreditFunds } from 'components/payments/Payments.utils';
import { getDropdownOptions } from '../Details.utils';
import {
  DEFAULT_EXCHANGE_RATE_AMOUNT,
  MIN_EXCHANGE_RATE_AMOUNT,
  MIN_CONVERSION_AMOUNT,
  CURRENCIES_WITH_CONVERSION_DATE_LIMITS,
} from 'constants/index';
import { PAY_PAYEE_STEPS } from 'components/payments/PayPayee/constants';

const useDetails = ({
  payees,
  wallets,
  bankAccounts,
  lineOfCredit,
  onNextStep,
  initialPayeeId,
  initialFromAccountId,
}) => {
  const { paymentInfo, setPaymentInfo, reimbursementInfo } = useContext(PayPayeeContext);
  const {
    fromAccount: initialFromAccount,
    toAccount: initialToAccount,
    originalAmount: initialOriginAmount,
    chargedAmount: initialChargedAmount,
    buyOrSell: initialBuyOrSell,
    reason: initialReason,
    notes: initialNotes,
    sendConfirmationEmail: initialSendConfirmationEmail,
  } = paymentInfo || {};

  const form = useForm({
    defaultValues: {
      toAccount: initialToAccount?.id || initialPayeeId,
      fromAccount: initialFromAccountId || initialFromAccount?.id,
      buyOrSell: initialBuyOrSell,
      reason: initialReason,
      notes: initialNotes,
      sendConfirmationEmail: initialSendConfirmationEmail,
    },
  });
  const { register, handleSubmit, watch, setValue, setError: setFormError, clearErrors: clearFormErrors } = form;

  const watchToAccount = watch('toAccount');
  const watchFromAccount = watch('fromAccount');
  const watchNotes = watch('notes');
  const watchSendConfirmationEmail = watch('sendConfirmationEmail');
  const watchBuyOrSell = watch('buyOrSell');

  const toAccount = useMemo(() => payees.find(({ id }) => id === watchToAccount), [payees, watchToAccount]);
  const fromAccount = useMemo(
    () => compact([...wallets, ...bankAccounts, lineOfCredit]).find(({ id }) => id === watchFromAccount),
    [lineOfCredit, wallets, bankAccounts, watchFromAccount]
  );

  const toAccountCurrency = toAccount?.record?.currency || '';
  const fromAccountCurrency = fromAccount?.currency || '';

  const {
    rate,
    buy,
    sell,
    loadingRate,
    getExchangeRate,
    rateExpiresAt,
    exchangeRateReference,
    error: rateError,
  } = useGetExchangeRate();

  const [error, setError] = useState('');
  const [invoiceFile, setInvoiceFile] = useState(paymentInfo?.invoiceFile || null);
  const [showNotes, setShowNotes] = useState(true);
  const [isCreditFunds, setIsCreditFunds] = useState(false);
  const [maxSellAmount, setMaxSellAmount] = useState(0);
  const [maxBuyAmount, setMaxBuyAmount] = useState(0);
  const [needsConversion, setNeedsConversion] = useState(false);
  const [invalidAccount, setInvalidAccount] = useState(false);

  const [formattedFromAmount, setFormattedFromAmount] = useState(
    formatMoneyV2(
      initialChargedAmount
        ? initialChargedAmount
        : { amount: DEFAULT_EXCHANGE_RATE_AMOUNT, currency: fromAccountCurrency }
    )
  );
  const [formattedToAmount, setFormattedToAmount] = useState(
    formatMoneyV2(
      initialOriginAmount ? initialOriginAmount : { amount: DEFAULT_EXCHANGE_RATE_AMOUNT, currency: toAccountCurrency }
    )
  );

  const { createPayeePayment, approvePayeePayment } = useIsContactPermission();
  const createMemberPayment = createPayeePayment && !approvePayeePayment;

  useEffect(() => {
    setNeedsConversion(toAccountCurrency && fromAccountCurrency && toAccountCurrency !== fromAccountCurrency);
  }, [toAccountCurrency, fromAccountCurrency]);

  const showSentEmailConfirmation = toAccount?.record?.email;
  const isExternalAccount = fromAccount?.__typename == 'BankAccount';

  useEffect(() => {
    setInvalidAccount(isExternalAccount && CURRENCIES_WITH_CONVERSION_DATE_LIMITS.includes(toAccountCurrency));
  }, [toAccountCurrency, isExternalAccount]);

  const { toOptions, fromOptions } = getDropdownOptions({
    payees,
    lineOfCredit,
    wallets,
    bankAccounts,
    toAccount,
  });

  const buyOrSellOptions = [
    { label: 'You Send', value: FXOperationType.sell },
    { label: 'Payee Receives', value: FXOperationType.buy },
  ];

  useDeepEffect(() => {
    if (
      !needsConversion ||
      !watchBuyOrSell ||
      !toAccountCurrency ||
      !fromAccountCurrency ||
      toAccountCurrency === fromAccountCurrency
    )
      return;

    const payload = {};

    if (watchBuyOrSell === FXOperationType.sell) {
      const amount = centsFromMoneyString(formattedFromAmount);

      if (amount < MIN_EXCHANGE_RATE_AMOUNT) {
        const minimumAmount = formatMoneyV2({
          amount: MIN_EXCHANGE_RATE_AMOUNT,
          currency: fromAccountCurrency,
        });

        setFormError('fromAmount', {
          type: 'manual',
          message: `Amount can't be less than ${minimumAmount}`,
        });
        return;
      }

      payload.buy = { currency: toAccountCurrency };
      payload.sell = {
        currency: fromAccountCurrency,
        amount,
      };
    } else {
      const amount = centsFromMoneyString(formattedToAmount);

      if (amount < MIN_EXCHANGE_RATE_AMOUNT) {
        const minimumAmount = formatMoneyV2({
          amount: MIN_EXCHANGE_RATE_AMOUNT,
          currency: toAccountCurrency,
        });

        setFormError('toAmount', {
          type: 'manual',
          message: `Amount can't be less than ${minimumAmount}`,
        });
        return;
      }

      payload.buy = { currency: toAccountCurrency, amount };
      payload.sell = { currency: fromAccountCurrency };
    }

    clearFormErrors(['fromAmount', 'toAmount']);

    getExchangeRate({
      variables: payload,
      includeDate: isExternalAccount,
    });
  }, [
    needsConversion,
    watchBuyOrSell,
    needsConversion,
    toAccountCurrency,
    fromAccountCurrency,
    formattedFromAmount,
    formattedToAmount,
    isExternalAccount,
  ]);

  useEffect(() => {
    if (!fromAccount?.availableBalance) {
      setMaxSellAmount(0);
      return;
    }

    setMaxSellAmount(fromAccount.availableBalance.amount);
  }, [fromAccount?.availableBalance]);

  useEffect(() => {
    if ((needsConversion && !rate) || !maxSellAmount) {
      setMaxBuyAmount(0);
      return;
    }

    const calculatedMaxBuyAmount = maxSellAmount / rate;

    setMaxBuyAmount(calculatedMaxBuyAmount.toFixed(2));
  }, [needsConversion, rate, maxSellAmount]);

  useEffect(() => {
    if (!watchFromAccount) return;

    setIsCreditFunds(checkIfCreditFunds(watchFromAccount));
  }, [watchFromAccount]);

  useDeepEffect(() => {
    if (!isCreditFunds) return;

    const invoiceBlobSignedId = get(paymentInfo, 'invoiceBlobSignedId');
    setValue('invoiceBlobSignedId', invoiceBlobSignedId);
  }, [paymentInfo, isCreditFunds]);

  useEffect(() => {
    if (isUndefined(watchSendConfirmationEmail)) return;

    setShowNotes(watchSendConfirmationEmail === 'true');
    setValue('notes', watchNotes);
  }, [watchSendConfirmationEmail, watchNotes]);

  useDeepEffect(() => {
    if (!fromAccountCurrency || !toAccountCurrency) {
      setValue('buyOrSell', null);
      return;
    }

    if (fromAccountCurrency === toAccountCurrency) {
      setValue('buyOrSell', FXOperationType.sell);
    }
  }, [fromAccountCurrency, toAccountCurrency]);

  useEffect(() => {
    clearFormErrors(['fromAccount']);
    if (invalidAccount) {
      setFormError('fromAccount', {
        type: 'manual',
        message: 'External Bank Account option not supported for this currency',
      });
      setError(true);
    }
  }, [invalidAccount]);

  useDeepEffect(() => {
    if (loadingRate) return;
    if (
      !fromAccountCurrency ||
      !toAccountCurrency ||
      !needsConversion ||
      !rate ||
      !sell ||
      !buy ||
      !rateExpiresAt ||
      !exchangeRateReference
    )
      return;

    const rateAmounts = [buy, sell];
    const currentChargedAmount = rateAmounts.find(({ currency }) => currency === fromAccountCurrency);
    const currentOriginalAmount = rateAmounts.find(({ currency }) => currency === toAccountCurrency);

    if (!currentChargedAmount || !currentOriginalAmount) return;

    const currentRate = 1 / rate;

    setPaymentInfo((prevState) => ({
      ...prevState,
      originalAmount: { amount: currentOriginalAmount.amount, currency: currentOriginalAmount.currency },
      chargedAmount: { amount: currentChargedAmount.amount, currency: currentChargedAmount.currency },
      rate: Number(currentRate.toFixed(5)),
      rateExpiresAt,
      exchangeRateReference,
    }));

    setError(invalidAccount || '');
  }, [
    loadingRate,
    rate,
    buy,
    sell,
    needsConversion,
    fromAccountCurrency,
    toAccountCurrency,
    rateExpiresAt,
    exchangeRateReference,
    invalidAccount,
  ]);

  useEffect(() => {
    const isSelling = watchBuyOrSell === FXOperationType.sell;
    const isBuying = watchBuyOrSell === FXOperationType.buy;

    if (isSelling && initialOriginAmount) {
      setFormattedToAmount(formatMoneyV2(initialOriginAmount));
    }

    if (isBuying && initialChargedAmount) {
      setFormattedFromAmount(formatMoneyV2(initialChargedAmount));
    }
  }, [watchBuyOrSell, initialChargedAmount, initialOriginAmount]);

  useEffect(() => {
    if (needsConversion) {
      setError(invalidAccount || rateError);
      return;
    }

    setError(invalidAccount || '');
  }, [needsConversion, rateError, invalidAccount]);

  const handleOnFileUpload = ({ blob, file }) => {
    if (!blob) return;

    setValue('invoiceBlobSignedId', blob.signed_id);
    setInvoiceFile(file);
  };

  const onSubmit = (data) => {
    const { buyOrSell, reason, notes, sendConfirmationEmail, invoiceBlobSignedId, invoiceNumber, invoiceDate } = data;

    const isBuying = buyOrSell === FXOperationType.buy;

    const chargedAmount = { amount: centsFromMoneyString(formattedFromAmount), currency: fromAccountCurrency };
    const originalAmount = { amount: centsFromMoneyString(formattedToAmount), currency: toAccountCurrency };

    const sufficientFunds = !isExternalAccount ? fromAccount.availableBalance.amount >= chargedAmount.amount : true;

    if (!sufficientFunds) {
      setFormError(isBuying ? 'toAmount' : 'fromAmount', {
        type: 'manual',
        message: 'Insufficient funds',
      });
      return;
    }

    const isMinimumConversionAmount = needsConversion && chargedAmount.amount < MIN_CONVERSION_AMOUNT;

    if (isMinimumConversionAmount) {
      const amount = `${formatMoneyV2({
        amount: MIN_CONVERSION_AMOUNT,
        currency: chargedAmount.currency,
      })} ${chargedAmount.currency}`;

      const message = isBuying
        ? `The amount converted must be greater than ${amount}`
        : `The amount you send must be greater than ${amount}`;

      setFormError(isBuying ? 'toAmount' : 'fromAmount', {
        type: 'manual',
        message,
      });

      return;
    }

    setPaymentInfo((prevState) => ({
      ...prevState,
      fromAccount,
      toAccount,
      chargedAmount,
      // if no FX, original and changed amounts are the same
      originalAmount: needsConversion ? originalAmount : chargedAmount,
      reason,
      notes,
      sendConfirmationEmail,
      createMemberPayment,
      invoiceBlobSignedId,
      isExternalAccount,
      needsConversion,
      buyOrSell,
      invoiceNumber,
      invoiceDate,

      // next fields is used only for local purposes
      invoiceFile,
    }));

    if (isExternalAccount && !fromAccount?.preDepositAuthorization) {
      onNextStep(PAY_PAYEE_STEPS.Review[0]); // PAD agreement
    } else {
      onNextStep(PAY_PAYEE_STEPS.Review[1]); // Review
    }
  };

  return {
    form,
    register,
    handleSubmit,
    error,
    fromAccountCurrency,
    formattedFromAmount,
    setFormattedFromAmount,
    toAccountCurrency,
    formattedToAmount,
    setFormattedToAmount,
    isCreditFunds,
    createMemberPayment,
    needsConversion,
    toOptions,
    fromOptions,
    showSentEmailConfirmation,
    handleOnFileUpload,
    onSubmit,
    invoiceFile,
    isExternalAccount,
    showNotes,
    formattedMaxSellAmount:
      maxSellAmount &&
      `${formatMoneyV2({ amount: maxSellAmount, currency: fromAccount?.currency })} ${fromAccount?.currency}`,
    formattedMaxBuyAmount:
      maxBuyAmount &&
      `${formatMoneyV2({ amount: maxBuyAmount, currency: toAccount?.record?.currency })} ${
        toAccount?.record?.currency
      }`,
    buyOrSellOptions,
    isSelling: watchBuyOrSell === FXOperationType.sell,
    isBuying: watchBuyOrSell === FXOperationType.buy,
    rate: paymentInfo?.rate,
    loadingRate,
    reimbursementInfo,
  };
};

export default useDetails;
