import React, { useContext } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

import { User } from 'types/user';
import { AuthContext } from 'context/Auth';
import LoopLogo from 'components/svg/LoopLogo';
import SignOut from 'components/svg/SignOut';
import PersonOutline from 'components/svg/PersonOutline';
import PrimaryInitialsCircles from 'components/PrimaryInitialsCircle';
import Button from 'components/Button';
import { HeaderProps } from '../../Header.types';
import { MenuSkeleton } from './components';
import ThemeToggle from '../ThemeToggle';

import styles from './DesktopHeader.module.scss';

const DesktopHeader = ({ dropDownNavigation }: HeaderProps) => {
  // TODO update after https://getloop.atlassian.net/browse/LBP-2615
  const { me } = useContext(AuthContext) as unknown as { me: User };

  const isReady = !!me;

  const { firstName, lastName, account } = me || {};
  const fullName = `${firstName} ${lastName}`;
  const accountName = account?.displayName || account?.name;

  return (
    <div className="tw-hidden lg:tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full tw-py-8 tw-px-12 tw-border-b tw-border-neutral-grey-4 tw-bg-neutral-light">
      <Link to="/dashboard/home" title="Go to homepage">
        <LoopLogo />
      </Link>

      {isReady ? (
        <Menu as="div" className="layout-header__menu tw-relative tw-inline-block" data-testid="user-dropdown">
          <Menu.Button>
            <div className="tw-flex tw-items-center tw-cursor-pointer">
              <PrimaryInitialsCircles.Regular firstName={firstName} lastName={lastName} />
              <small className="tw-mx-4 tw-font-semibold">{accountName || fullName}</small>
              <BsChevronDown />
            </div>
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
            enterTo="tw-transform tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
          >
            {/* Return `tw-w-56` after removing the BETA label https://getloop.atlassian.net/browse/LBP-5794 */}
            <Menu.Items className="tw-absolute tw-right-0 tw-w-72 tw-mt-2 tw-origin-top-right tw-z-50">
              <div className="tw-flex tw-px-4 tw-justify-end">
                <div className={styles.bigArrowUp} />
              </div>
              <Menu.Item as="div" className="tw-cursor-pointer tw-bg-neutral-light tw-p-4 tw-rounded-t-md tw-shadow-lg">
                <div className="tw-flex tw-items-center">
                  <PersonOutline className="tw-mr-2" />
                  {fullName}
                </div>
              </Menu.Item>
              {dropDownNavigation?.map(({ name, id, Icon, link, action }) => {
                const isLinkItem = !!link && !action;
                const isActionItem = !!action && !link;

                return (
                  <Menu.Item key={id} as="div" className="tw-cursor-pointer tw-bg-neutral-light tw-p-4 tw-shadow-lg">
                    {isLinkItem && (
                      <Link to={link} className="tw-flex tw-items-center hover:tw-text-neutral-dark">
                        <Icon className="tw-mr-2" />
                        <div>{name}</div>
                      </Link>
                    )}

                    {isActionItem && (
                      <Button
                        type="submit"
                        className="tw-flex tw-justify-start tw-w-full tw-text-left"
                        onClick={action}
                      >
                        <Icon className="tw-mr-2" />
                        <div>{name}</div>
                      </Button>
                    )}

                    {!isLinkItem && !isActionItem && (
                      <div className="tw-bg-neutral-light tw-p-4 tw-shadow-lg">
                        <Icon className="tw-mr-2" />
                        <div>{name}</div>
                      </div>
                    )}
                  </Menu.Item>
                );
              })}

              <Menu.Item key="theme" as="div" className="tw-cursor-pointer tw-bg-neutral-light tw-p-4 tw-shadow-lg">
                <ThemeToggle />
              </Menu.Item>

              <Menu.Item
                as="div"
                className="tw-cursor-pointer tw-bg-neutral-light tw-p-4 tw-border-t tw-border-neutral-grey-4 tw-rounded-b-md tw-shadow-lg"
              >
                <Link to="/signout" className="tw-flex tw-items-center">
                  <SignOut className="tw-mr-2 tw-text-semantic-error" />
                  <p>Sign Out</p>
                </Link>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : (
        <MenuSkeleton />
      )}
    </div>
  );
};

export default DesktopHeader;
