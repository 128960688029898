import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import ExternalWalletVerificationStatus from 'domain/externalWalletVerificationStatus';
import { GET_EXTERNAL_WALLET_ACCOUNT_VERIFICATION_STATUS } from 'graphql/wallets';
import USDBankAccountVerificationStatus from 'components/wallets/USDBankAccountVerificationStatus';

const USDBankAccountDetailsContainer = ({ accountInternalId }) => {
  const { loading, data } = useQuery(GET_EXTERNAL_WALLET_ACCOUNT_VERIFICATION_STATUS, {
    variables: { accountInternalId },
  });
  const accountVerificationStatus = new ExternalWalletVerificationStatus(
    _.get(data, 'walletExternalAccountVerificationStatus')
  );

  return <USDBankAccountVerificationStatus loading={loading} accountVerificationStatus={accountVerificationStatus} />;
};

export default USDBankAccountDetailsContainer;
