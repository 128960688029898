import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import CreditApplication from '../components/onboarding/creditApplication/CreditApplication';
import { GET_CREDIT_APPLICATION } from '../graphql/creditApplication';
import { userRoles } from '../constants';

const CreditApplicationContainer = () => {
  const { loading, data } = useQuery(GET_CREDIT_APPLICATION, { fetchPolicy: 'network-only' });
  const {
    averageProductMargin,
    employeeType,
    employeesMonthlyPayroll,
    inventoryStorage,
    otherInventoryStorage,
    ownersMonthlyPayroll,
    ownersPaymentMethod,
    residentialStatus,
  } = _.get(data, 'me.account.creditApplication') || {};
  const contacts = _.get(data, 'me.account.contacts') || [];
  const accountOwner = contacts.find((c) => c.roles.includes(userRoles.ACCOUNT_OWNER));
  const accountOwnerName = accountOwner && `${accountOwner.firstName} ${accountOwner.lastName}`;

  return (
    <CreditApplication
      loading={loading}
      averageProductMargin={averageProductMargin}
      employeeType={employeeType}
      employeesMonthlyPayroll={employeesMonthlyPayroll}
      inventoryStorage={inventoryStorage || []}
      otherInventoryStorage={otherInventoryStorage}
      ownersMonthlyPayroll={ownersMonthlyPayroll}
      ownersPaymentMethod={ownersPaymentMethod}
      residentialStatus={residentialStatus}
      accountOwnerName={accountOwnerName}
    />
  );
};

export default CreditApplicationContainer;
