import React from 'react';

import { Modal, Progress } from 'components/UI';
import { Steps } from './components';
import { useUSBankAccountOwnershipDisclosureModal } from './hooks';
import { bankAccountOwnershipDisclosureSteps } from './constants';
import { USBankAccountOwnershipDisclosureModalProps } from './USBankAccountOwnershipDisclosureModal.types';

const USBankAccountOwnershipDisclosureModal = ({
  show,
  onClose,
  onFinish,
}: USBankAccountOwnershipDisclosureModalProps) => {
  const { currentStep, onNextStep, errorSteps, isCompleted } = useUSBankAccountOwnershipDisclosureModal({
    onFinish,
  });

  return (
    <Modal show={show} onClose={onClose} title="Sign Ownership Disclosure" isFullScreen>
      <Progress
        currentStepIndex={currentStep}
        stepLabelsAndIndexes={bankAccountOwnershipDisclosureSteps}
        errorSteps={errorSteps}
        isCompleted={isCompleted}
        classNames="tw-mt-8 tw-px-8"
      />
      <div className="tw-pb-4">
        <Steps currentStep={currentStep} onNextStep={onNextStep} onFinish={onClose} />
      </div>
    </Modal>
  );
};

export default USBankAccountOwnershipDisclosureModal;
