import React, { useContext } from 'react';
import { get } from 'lodash';
import { BiBarChart } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import { User } from 'types/user';
import { CREDIT_ASSESSMENT_STATUS } from 'constants/index';
import { AuthContext } from 'context/Auth';

const CreditApplication = () => {
  const { me } = useContext(AuthContext) as unknown as { me: User; meLoading: boolean };
  const externalAccountsConnected: User['externalAccountsConnected'] = get(me, 'account.externalAccountsConnected');
  const creditAssessmentStatus: User['creditAssessment']['status'] | undefined = get(
    me,
    'account.creditAssessment.status'
  );

  const rejectedCreditReview =
    !!externalAccountsConnected &&
    !!creditAssessmentStatus &&
    creditAssessmentStatus === CREDIT_ASSESSMENT_STATUS.REJECTED;

  const waitingForCreditReview =
    !!externalAccountsConnected &&
    !!creditAssessmentStatus &&
    creditAssessmentStatus !== CREDIT_ASSESSMENT_STATUS.APPROVED;

  if (rejectedCreditReview) return null;

  if (waitingForCreditReview)
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-p-2 tw-rounded-md tw-bg-secondary-light-yellow">
        <span className="tw-text-xs tw-text-primary-dark-yellow">Credit Limit Calculation in Progress</span>
      </div>
    );

  return (
    <Link
      to="/dashboard/settings/integrations?referrer=cards"
      className="tw-inline-flex tw-items-center tw-justify-center tw-gap-1 tw-text-primary-dark-green hover:tw-text-primary-dark-green tw-font-bold tw-py-1 tw-px-3 tw-text-sm"
    >
      <BiBarChart />
      Apply For Credit Limit
    </Link>
  );
};

export default CreditApplication;
