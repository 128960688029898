import React from 'react';

import Banner, { BANNER_TYPES } from 'components/Banner';

const ConversionRatesMaintenance = ({ maintenanceMessageSource }: { maintenanceMessageSource: string }) => {
  if (!maintenanceMessageSource) {
    return null;
  }

  return (
    <div className="tw-flex tw-justify-between tw-mb-2">
      <Banner className="tw-my-5" isShowIcon type={BANNER_TYPES.danger} message={maintenanceMessageSource} />
    </div>
  );
};
export default ConversionRatesMaintenance;
