import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';

import { FORGOT_PASSWORD } from 'graphql/user';
import { EMAIL_REGEX } from 'constants/regex';
import { TextField, SubmitButton } from '../FormFields/v2';
import { ampTrackEvent } from '../../amplitude';
import Layout from './Layout';

const Success = () => {
  return (
    <>
      <h1 data-test-id="success-title" className="tw-mb-3">
        Please check your email inbox
      </h1>
      <p>If an account exists, an email will be sent with further instructions on how to reset your password.</p>
    </>
  );
};

const Content = () => {
  const [mutation, { loading: isSubmitting, error }] = useMutation(FORGOT_PASSWORD);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const onSubmit = async (data) => {
    try {
      const result = await mutation({ variables: data });

      if (result.data && result.data.forgotPassword) {
        ampTrackEvent('forgotPassword: submit: success');
        setIsSuccessful(true);
      }
    } catch (e) {
      console.log(e);
      ampTrackEvent('forgotPassword: submit: error');
    }
  };
  const form = useForm();
  const { register, handleSubmit } = form;

  if (isSuccessful) return <Success />;

  return (
    <>
      <h1 className="tw-mb-3">Forgot your password?</h1>
      <div className="tw-flex">
        <p>
          Please enter the email address you used to create your account. We will send you a link to reset your
          password.
        </p>
      </div>
      <FormProvider {...form}>
        <form className="tw-mt-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-mb-4">
            <TextField
              data-test-id="forgot-password-email"
              name="email"
              type="name"
              label="Email Address"
              placeholder="example@example.com"
              ref={register({
                required: true,
                pattern: { value: EMAIL_REGEX, message: 'Please enter a valid email address' },
              })}
              tabIndex={1}
            />
          </div>
          {error?.message && (
            <div>
              <span className="tw-text-semantic-error tw-text-xs">{error.message}</span>
            </div>
          )}
          <SubmitButton type="submit" className="tw-w-full tw-mt-8" tabIndex={2} disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Send Email'}
          </SubmitButton>
        </form>
      </FormProvider>
    </>
  );
};

const ForgotPassword = () => {
  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default ForgotPassword;
