import React from 'react';

import { PAYEE_PAYMENT_APPROVAL_STATUS } from 'constants/index';

const PaymentApprovalStatusBadge = ({ status, approvalsNeeded, approvalsMade }) => {
  switch (status) {
    case PAYEE_PAYMENT_APPROVAL_STATUS.approved:
      return <Badge className="tw-bg-secondary-light-green tw-text-semantic-success" title="Approved" />;
    case PAYEE_PAYMENT_APPROVAL_STATUS.rejected:
      return <Badge className="tw-bg-semantic-error-background tw-text-semantic-error" title="Denied" />;
    case PAYEE_PAYMENT_APPROVAL_STATUS.pendingApproval:
      if (approvalsNeeded) {
        return (
          <span className="tw-text-sm">
            {approvalsMade} of {approvalsNeeded}
          </span>
        );
      }
      return <Badge className="tw-bg-secondary-pastel-yellow-50 tw-text-primary-yellow" title="Pending Approval" />;
    default:
      return null;
  }
};

const Badge = ({ className, title }) => (
  <div className={`tw-py-1 tw-px-2 tw-w-max tw-rounded-md tw-flex tw-items-center tw-justify-center ${className}`}>
    <small>{title}</small>
  </div>
);

export default PaymentApprovalStatusBadge;
