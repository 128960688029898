import { useContext } from 'react';

import { PaymentRequestsContext } from 'components/PayorPortal/PaymentRequests/contexts/PaymentRequestsContext';

const usePaymentsList = () => {
  const { paymentRequests } = useContext(PaymentRequestsContext);

  const isEmpty = !paymentRequests.length;

  return { isEmpty };
};

export default usePaymentsList;
