import { useCallback, useState, useContext, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation, ApolloError } from '@apollo/client';

import { AccountWallet } from 'types/wallet';
import { ampTrackEvent } from 'amplitude';
import { PAY_BALANCE } from 'graphql/payments';
import { GET_GROUPED_CARD_INFO } from 'graphql/cards';
import { PayBalanceCurrency } from 'types/payments';
import { Currencies } from 'constants/currencies';
import { formatMoneyV2, centsFromMoneyString } from 'utility/currency';
import { AddFundsToBalanceContext } from 'components/creditCards/context/AddFundsToBalance';
import { DetailsProps } from '../Details.types';
import { BankAccount } from 'types/bankAccount';

const useDetails = ({ onNextStep }: DetailsProps) => {
  const { transaction, setTransaction, wallets, bankAccounts, getWalletsAndBankAccounts, isLoading, error, setError } =
    useContext(AddFundsToBalanceContext);

  const [payBalance, { loading: isPayingBalance }] = useMutation(PAY_BALANCE, {
    refetchQueries: [{ query: GET_GROUPED_CARD_INFO }],
    awaitRefetchQueries: true,
  });

  const { originalAmount, fromAccount } = transaction || {};
  const { amount, currency } = originalAmount || ({} as { amount: number; currency: PayBalanceCurrency });
  const { id } = fromAccount || {};

  const [formattedAmount, setFormattedAmount] = useState(formatMoneyV2({ amount, currency }));
  const [formattedWalletBalance, setFormattedWalletBalance] = useState(formatMoneyV2());

  const form = useForm({
    defaultValues: {
      amount: formattedAmount,
      fromAccountId: id,
    },
  });

  const { register, handleSubmit, setError: setGraphQlError, clearErrors: clearGraphQlErrors, watch, getValues } = form;

  const watchFromAccountId = watch('fromAccountId');

  const availableFromAccounts = useMemo(() => {
    return [...wallets, ...bankAccounts].filter(
      (account) => currency && Currencies[currency] === account.currency && (account as BankAccount).country !== 'US'
    ); // TODO: Remove filter after adding support for US-based accounts for Card
  }, [wallets, bankAccounts, currency]);

  const fromAccountOptions = useMemo(() => {
    return availableFromAccounts.map((account) => ({
      name: account.displayName,
      value: account.id,
    }));
  }, [availableFromAccounts]);

  const selectedFromAccount = useMemo(
    () => availableFromAccounts?.find((account) => account.id === watchFromAccountId),
    [availableFromAccounts, watchFromAccountId]
  );

  const isFromBankAccount = selectedFromAccount?.__typename === 'BankAccount';

  const handleNextStep = useCallback(
    async (data) => {
      const { amount, fromAccountId } = data || {};

      if (!amount || !fromAccountId) {
        toast.error('Please fill in all fields');
        return;
      }

      if (!selectedFromAccount) {
        toast.error('Please select a valid account');
        return;
      }

      const amountInCents = centsFromMoneyString(amount);
      const currentAmount = { amount: amountInCents, currency };

      const isInsufficientFunds = isFromBankAccount
        ? false
        : (selectedFromAccount as AccountWallet).availableBalance.amount < amountInCents;

      if (isInsufficientFunds) {
        setGraphQlError('fromAccountId', { type: 'manual', message: 'Insufficient funds' });
        return;
      }

      clearGraphQlErrors();

      const processingToast = toast.loading('Processing transaction...');

      try {
        const response = await payBalance({
          variables: {
            from: fromAccountId,
            to: currency,
            originalAmount: currentAmount,
            chargedAmount: currentAmount,
          },
        });

        const transactionResponse = response?.data?.moveFunds;

        const { transactionGroupId, initiatedAt } = transactionResponse || {};

        setTransaction((prevState) => ({
          ...prevState,
          originalAmount: currentAmount,
          fromAccount: {
            id: fromAccountId,
            displayName: selectedFromAccount.displayName,
            type: selectedFromAccount.__typename,
          },
          transactionGroupId,
          initiatedAt,
        }));

        toast.update(processingToast, {
          render: 'Transaction has been submitted',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });

        ampTrackEvent('payBalance: customAmount: success');
        onNextStep();
      } catch (err) {
        console.error(err);

        ampTrackEvent('payBalance: customAmount: failed');

        toast.update(processingToast, {
          render: `Something went wrong. ${(err as ApolloError)?.message || err}. Please try again.`,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });

        setError(err as ApolloError);
      }
    },
    [
      onNextStep,
      setTransaction,
      availableFromAccounts,
      currency,
      setError,
      setGraphQlError,
      clearGraphQlErrors,
      payBalance,
      selectedFromAccount,
      isFromBankAccount,
    ]
  );

  const validateAmountPositive = (value?: string) => centsFromMoneyString(value) > 0 || 'Invalid amount';

  const validateFromAccountBalance = useCallback(
    (value: string) => {
      const selectedFromAccount = availableFromAccounts.find(({ id }) => id === value);
      const isFromBankAccount = selectedFromAccount?.__typename === 'BankAccount';

      if (!selectedFromAccount || isFromBankAccount) return true;

      const selectedAmount = getValues('amount');

      return (
        (selectedFromAccount as AccountWallet).availableBalance?.amount >= centsFromMoneyString(selectedAmount) ||
        'Insufficient funds'
      );
    },
    [availableFromAccounts, getValues]
  );

  useEffect(() => {
    if (isFromBankAccount) return;

    setFormattedWalletBalance(formatMoneyV2((selectedFromAccount as AccountWallet)?.availableBalance));
  }, [selectedFromAccount, isFromBankAccount]);

  useEffect(() => {
    getWalletsAndBankAccounts();
  }, []);

  return {
    form,
    register,
    handleSubmit,
    handleNextStep,
    isLoading,
    error,
    formattedAmount,
    setFormattedAmount,
    currency,
    fromAccountOptions,
    isProcessing: isPayingBalance,
    validateFromAccountBalance,
    validateAmountPositive,
    isFromBankAccount,
    formattedWalletBalance,
  };
};

export default useDetails;
