import React from 'react';

import { ProgressLoader } from './components';
import { useFlinksConnection } from './hooks';

const FlinksConnectionCallback = () => {
  const { Layout, progress } = useFlinksConnection();

  return (
    <Layout>
      <ProgressLoader progress={progress} />
    </Layout>
  );
};

export default FlinksConnectionCallback;
