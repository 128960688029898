import React, { createContext, useState } from 'react';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useLocation } from 'react-router-dom';

export const FilterContext = createContext();

const { Provider } = FilterContext;

export const FilterContextProvider = ({ children }) => {
  const now = new Date();
  const firstDayThisMonth = startOfMonth(now);
  const lastDayThisMonth = endOfMonth(now);
  const [startDate, setStartDate] = useState(firstDayThisMonth);
  const [endDate, setEndDate] = useState(lastDayThisMonth);

  // const [key, setKey] = useState(defaultPeriod.key);
  const [transactionType, setTransactionType] = useState('purchase');
  const [postedStatus, setPostedStatus] = useState('all');

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const currencyParam = query.get('currency');

  const [currency, setCurrency] = useState(currencyParam || 'CAD');
  const [isModified, setIsModified] = useState(false);

  const resetFilter = () => {
    setStartDate(firstDayThisMonth);
    setEndDate(lastDayThisMonth);
    setPostedStatus('pending');
    setCurrency('CAD');
    setTransactionType('purchase');
    setIsModified(false);
  };

  return (
    <Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        transactionType,
        setTransactionType,
        postedStatus,
        setPostedStatus,
        currency,
        setCurrency,
        isModified,
        setIsModified,
        resetFilter,
      }}
    >
      {children}
    </Provider>
  );
};
