import React, { useState, createContext } from 'react';

import { PointsTransaction } from 'types/transactions';

type PointsTransactionsContextType = {
  transactions: PointsTransaction[];
  setTransactions: React.Dispatch<React.SetStateAction<never[]>>;
};

export const PointsTransactionsContext = createContext<PointsTransactionsContextType>(
  {} as PointsTransactionsContextType
);

export const PointsTransactionsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [transactions, setTransactions] = useState([]);

  return (
    <PointsTransactionsContext.Provider
      value={{
        transactions,
        setTransactions,
      }}
    >
      {children}
    </PointsTransactionsContext.Provider>
  );
};
