import React, { forwardRef, useRef } from 'react';
import _ from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';
import { dates, months } from '../../constants';
import { AiFillQuestionCircle } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';

import { numberToCurrency, currencyToNumber } from '../../utility/currency';
import styles from './FormFields.module.scss';

const BaseTextField = (props, ref) => {
  const { label, name, rootClass, isCurrency, className, inputGroupText, inputStyle, labelElement, ...other } = props;
  const { errors, setValue, ...context } = useFormContext();
  const hasError = _.has(errors, name);
  const error = _.get(errors, name);

  let graphQLError;

  if (context.graphQLErrors) {
    context.graphQLErrors.map(({ message, path }) => {
      const fieldName = path && path.slice(1).join('.');
      if (fieldName === name) graphQLError = message;
    });
  }

  const handleOnChange = (e) => {
    if (isCurrency) {
      const intValue = currencyToNumber(e.target.value);
      const value = numberToCurrency(isNaN(intValue) ? '0' : intValue);
      setValue(name, value);
    }
  };

  const inputElement = (
    <input
      type="text"
      name={name}
      style={inputStyle}
      onChange={handleOnChange}
      id={name}
      className={`form-control rounded ${hasError || !!graphQLError ? 'is-invalid' : ''} ${className}`}
      {...other}
      ref={ref}
    />
  );

  return (
    <div className={`${rootClass} form-group`}>
      {label && labelElement}

      <div className="input-group rounded">
        {inputGroupText && (
          <div className="input-group-prepend">
            <div className="input-group-text rounded">{inputGroupText}</div>
          </div>
        )}
        {inputElement}
      </div>

      {hasError ? (
        <span className="text-danger fw-500 tk-futura-pt">
          {error && (error.type === 'required' && !error.message ? 'This field is required' : error.message)}
        </span>
      ) : null}
      {graphQLError ? <span className="text-danger fw-500 tk-futura-pt">{graphQLError}</span> : null}
    </div>
  );
};

const TextFieldComponent = (props, ref) => {
  const { label, infoText, name } = props;

  const labelElement = (
    <label
      className="input-label fw-500 tk-futura-pt fs-1 mb-1 d-flex justify-content-between align-items-center"
      htmlFor={name}
    >
      {label}

      {infoText ? (
        <>
          <AiFillQuestionCircle data-tip={infoText} /> <ReactTooltip />
        </>
      ) : null}
    </label>
  );

  return BaseTextField({ ...props, labelElement }, ref);
};

const TextField = forwardRef(TextFieldComponent);

const DateFieldComponent = (props, ref) => {
  const monthEl = useRef(null);
  const dayEl = useRef(null);
  const yearEl = useRef(null);
  const { label, name, rootClass, rules, minYear } = props;
  const { errors, setValue, graphQLErrors, control } = useFormContext();
  const hasError = _.has(errors, name);
  const error = _.get(errors, name);

  const currentYear = new Date().getFullYear();
  const years = Array.from(Array(100), (i, j) => currentYear - (minYear || 0) - j);

  const value = control.defaultValuesRef.current[name];
  const [defaultYear, defaultMonth, defaultDay] = value ? value.split('-').map((i) => parseInt(i)) : [];
  let graphQLError;

  if (graphQLErrors) {
    graphQLErrors.map(({ message, path }) => {
      const fieldName = path && path.slice(1).join('.');
      if (fieldName === name) graphQLError = message;
    });
  }

  const handleDateChange = () => {
    const year = yearEl.current.value;
    let month = parseInt(monthEl.current.value);
    let day = parseInt(dayEl.current.value);
    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;
    if (!Number(day) || !Number(month) || !Number(year)) return setValue(name, null);
    const newDate = `${year}-${month}-${day}`;
    setValue(name, newDate);
  };

  return (
    <div className={`${rootClass} form-group`}>
      <label className="input-label small fw-500 tk-futura-pt fs-1 mb-1" htmlFor={name}>
        {label}
      </label>

      <Controller
        render={() => (
          <div className={`d-flex justify-content-between ${hasError ? 'is-invalid' : ''}`} ref={ref}>
            <select
              data-testid="birthdate-day"
              className="flex-fill m-1 bg-light border-0 rounded"
              ref={dayEl}
              onChange={handleDateChange}
              defaultValue={defaultDay}
            >
              {getSelectOptions(dates, 'Day')}
            </select>
            <select
              data-testid="birthdate-month"
              className="flex-fill m-1 bg-light border-0 rounded"
              ref={monthEl}
              onChange={handleDateChange}
              defaultValue={defaultMonth}
            >
              {getSelectOptions(months, 'Month')}
            </select>
            <select
              data-testid="birthdate-year"
              className="flex-fill m-1 bg-light border-0 rounded"
              ref={yearEl}
              onChange={handleDateChange}
              defaultValue={defaultYear}
            >
              {getSelectOptions(years, 'Year')}
            </select>
          </div>
        )}
        control={control}
        name={name}
        rules={rules}
      />

      {hasError ? (
        <span className="text-danger fw-500 tk-futura-pt">
          {error && (error.type === 'required' && !error.message ? 'This field is required' : error.message)}
        </span>
      ) : null}
      {graphQLError ? <span className="text-danger fw-500 tk-futura-pt">{graphQLError}</span> : null}
    </div>
  );
};

const BaseSelectComponent = (props, ref) => {
  const { label, name, options = [], rootClass, className, placeholder, inputStyle, labelElement, ...other } = props;
  const { errors, graphQLErrors } = useFormContext();
  const hasError = _.has(errors, name);
  const error = _.get(errors, name);

  let graphQLError;

  if (graphQLErrors) {
    graphQLErrors.map(({ message, path }) => {
      if (path && path.includes(name)) graphQLError = message;
    });
  }

  return (
    <div className={`${rootClass} form-group`}>
      {label && labelElement}
      <select
        name={name}
        style={inputStyle}
        ref={ref}
        className={`custom-select form-control rounded ${hasError || !!graphQLError ? 'is-invalid' : ''} ${className}`}
        id={name}
        {...other}
      >
        {placeholder ? (
          <option key="default" value="">
            {placeholder}
          </option>
        ) : null}

        {options.map((o, i) => (
          <option key={i} value={o.value}>
            {o.name}
          </option>
        ))}
      </select>

      {hasError ? (
        <span className="text-danger fw-500 tk-futura-pt">
          {error && (error.type === 'required' && !error.message ? 'This field is required' : error.message)}
        </span>
      ) : null}
      {graphQLError ? <span className="text-danger fw-500 tk-futura-pt">{graphQLError}</span> : null}
    </div>
  );
};

const SelectFieldComponent = (props, ref) => {
  const { label, name } = props;

  const labelElement = (
    <label className="input-label small fw-500 tk-futura-pt fs-1 mb-1" htmlFor={name}>
      {label}
    </label>
  );

  return BaseSelectComponent({ ...props, labelElement }, ref);
};

const RadioFieldComponent = (props, ref) => {
  const { name, options = [], rootClass, ...other } = props;

  return (
    <div className={`${rootClass} form-group`}>
      {options.map((o) => (
        <label className="container d-flex fw-500 tk-futura-pt" key={o.label}>
          <input
            type="radio"
            className={styles.radioButton}
            name={name}
            value={o.value}
            defaultChecked={!!o.defaultChecked}
            ref={ref}
            {...other}
          />
          <span className="font-size-1 ml-4">{o.label}</span>
        </label>
      ))}
    </div>
  );
};

const CheckboxComponent = (props, ref) => {
  const { label, name, rootClass, ...other } = props;

  const { errors, graphQLErrors } = useFormContext();
  const hasError = _.has(errors, name);
  const error = _.get(errors, name);

  let graphQLError;

  if (graphQLErrors) {
    graphQLErrors.map(({ message, path }) => {
      const fieldName = path && path.slice(1).join('.');
      if (fieldName === name) graphQLError = message;
    });
  }

  return (
    <div className={`${rootClass} form-group`}>
      <div className="custom-control custom-checkbox">
        <input id={name} type="checkbox" className="custom-control-input" name={name} {...other} ref={ref}></input>
        <label className="custom-control-label fw-500 tk-futura-pt" htmlFor={name}>
          {label}
        </label>
        {hasError ? <span className="ml-3 text-danger small tk-futura-pt">{error && error.message}</span> : null}
        {graphQLError ? <span className="ml-3 text-danger small tk-futura-pt">{graphQLError}</span> : null}
      </div>
    </div>
  );
};

const LightCheckboxComponent = (props, ref) => {
  const { label, name, rootClass, ...other } = props;

  const { errors, graphQLErrors } = useFormContext();
  const hasError = _.has(errors, name);
  const error = _.get(errors, name);

  let graphQLError;

  if (graphQLErrors) {
    graphQLErrors.map(({ message, path }) => {
      const fieldName = path && path.slice(1).join('.');
      if (fieldName === name) graphQLError = message;
    });
  }

  return (
    <div className={`${rootClass} form-group`}>
      <div className={`custom-control custom-checkbox ${styles.lightCheckbox}`}>
        <input
          id={name}
          type="checkbox"
          className={`custom-control-input ${styles.lightControlInput}`}
          name={name}
          {...other}
          ref={ref}
        ></input>
        <label className={`custom-control-label fw-500 tk-futura-pt ${styles.lightControlLabel}`} htmlFor={name}>
          {label}
        </label>
        {hasError ? <span className="ml-3 text-danger small tk-futura-pt">{error && error.message}</span> : null}
        {graphQLError ? <span className="ml-3 text-danger small tk-futura-pt">{graphQLError}</span> : null}
      </div>
    </div>
  );
};

const Select = forwardRef(SelectFieldComponent);
const DateField = forwardRef(DateFieldComponent);
const RadioField = forwardRef(RadioFieldComponent);
const Checkbox = forwardRef(CheckboxComponent);
const LightCheckbox = forwardRef(LightCheckboxComponent);

export { TextField, Select, DateField, RadioField, Checkbox, LightCheckbox };

const getSelectOptions = (options, label = 'Select...') => {
  let result;
  if (Array.isArray(options)) {
    if (options[0] && typeof options[0] === 'object') {
      const groups = [...new Set(options.map((o) => o.group))];
      result = [];
      groups.forEach((g) => {
        const groupOptions = options
          .filter((o) => o.group === g)
          .map((item) => (
            <option key={item.value} value={item.value}>
              {item.label || item.value}
            </option>
          ));

        result.push(
          <optgroup key={g} label={g}>
            {groupOptions}
          </optgroup>
        );
      });
    } else {
      result = options.map((item, i) => (
        <option key={i} value={item}>
          {item}
        </option>
      ));
    }
  } else if (typeof options === 'object') {
    result = Object.keys(options).map((item, i) => (
      <option key={i} value={options[item]}>
        {item}
      </option>
    ));
  }
  result.unshift(
    <option key={-1} value={0}>
      {label}
    </option>
  );
  return result;
};
