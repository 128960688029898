import React, { useContext } from 'react';

import { EXTERNAL_TRANSACTION_CLEARING_MESSAGE } from 'constants/index';
import Button from 'components/Button';
import { PayLocBalanceContext } from '../PayLocBalanceContext';
import Banner, { BANNER_TYPES } from 'components/Banner';
import { ReadOnly } from './shared';
import { formatDateTime } from 'utility/date';
import { classNames } from 'utility/string';

const Complete = () => {
  const {
    isModal,
    payload,
    formattedFromAccountOption,
    formattedTransactionFees,
    recipientAccount,
    transferMethod,
    paymentFromWallet,
    payment,
    formattedPaymentAmount,
    onResetForm,
    onFinish,
    finishButtonLabel,
  } = useContext(PayLocBalanceContext);
  const { transaction } = payment;
  const { groupId: transactionId, createdAt } = transaction || {};

  const gridClasses = classNames({
    'tw-grid tw-gap-6': isModal,
    'tw-grid lg:tw-grid-rows-6 lg:tw-grid-flow-col tw-gap-8': !isModal,
  });
  const bannerMessage = paymentFromWallet ? 'Your transfer is complete.' : EXTERNAL_TRANSACTION_CLEARING_MESSAGE;

  return (
    <>
      <Banner isShowIcon type={BANNER_TYPES.success} message={bannerMessage} />

      <div className={gridClasses}>
        <ReadOnly label="Recipient Account" value={recipientAccount} />

        <ReadOnly label="From Account" value={formattedFromAccountOption} />

        <ReadOnly label="Payment Amount" value={formattedPaymentAmount} />

        <ReadOnly label="Payment Date" value={formatDateTime(payload.paymentDate, 'MMMM d, yyyy')} />

        <ReadOnly label="Method" value={transferMethod} />

        <ReadOnly label="Fees" value={`${payload.currency} ${formattedTransactionFees}`} />

        {transactionId && <ReadOnly label="Reference ID" value={transactionId} />}

        {createdAt && <ReadOnly label="Initiated at" value={formatDateTime(createdAt)} />}
      </div>

      <div className="lg:tw-flex lg:tw-flex-row lg:tw-justify-between lg:tw-gap-8 tw-flex tw-flex-col tw-gap-3">
        <Button secondary className="tw-w-full lg:tw-w-max" onClick={onFinish}>
          {finishButtonLabel}
        </Button>

        <Button primary className="tw-w-full lg:tw-w-max" onClick={onResetForm}>
          Make another Payment
        </Button>
      </div>
    </>
  );
};

export default Complete;
