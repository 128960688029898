import React, { useContext } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';

import { ExternalAccountingAccount } from 'types/externalAccountingAccount';
import { TableRowProps } from './TableRow.types';
import { QuickBooksAccount } from 'components/settings/AccountingServices/Account';
import { WalletCategoryMappingRequestContext } from '../../../../contexts/WalletCategoryMappingRequestContext';
import { accountingServicesAccountCreationURLs } from 'components/settings/Integrations/constants';

const TableRow = ({ walletTransactionsCategoryDetailsMapping, accountName }: TableRowProps) => {
  const { transactionCategory, externalAccount } = walletTransactionsCategoryDetailsMapping;
  const { availableExternalAccountingAccounts, setWalletCategoryMappingRequestInfo } = useContext(
    WalletCategoryMappingRequestContext
  );

  const handleNewAccount = () => {
    if (accountName === 'Xero') {
      window.open(accountingServicesAccountCreationURLs.xero, '_blank', 'noreferrer');
    } else if (accountName === 'QuickBooks') {
      window.open(accountingServicesAccountCreationURLs.quickbooks, '_blank', 'noreferrer');
    }
  };

  const handleMappingUpdate = (externalAccount: ExternalAccountingAccount) => {
    setWalletCategoryMappingRequestInfo({
      id: transactionCategory.transactionCategoryId,
      externalAccountId: externalAccount.id,
    });
  };

  return (
    <>
      <tr>
        <td className="tw-px-3 tw-py-4 tw-border-b tw-border-neutral-grey-5">{transactionCategory?.description}</td>
        <td className="tw-px-3 tw-py-4 tw-border-b tw-border-neutral-grey-5">
          <QuickBooksAccount
            qbCategories={availableExternalAccountingAccounts}
            qbCategory={externalAccount}
            onChange={handleMappingUpdate}
            listOptionStyles={'tw-h-80'}
            className="tw-relative"
            newAccount={() => (
              <div className="tw-p-1 tw-h-12 tw-flex tw-box-border tw-items-center tw-cursor-pointer tw-text-neutral-light tw-bg-primary-dark-green hover:tw-opacity-90">
                <IoAddCircleOutline className="tw-ml-1" />
                <span className="tw-mx-1" onClick={handleNewAccount}>
                  Create {accountName} Account
                </span>
              </div>
            )}
          />
        </td>
        <td className="tw-px-3 tw-py-4 tw-border-b tw-border-neutral-grey-5 tw-text-center">
          {externalAccount?.taxCodeRefId || '-'}
        </td>
      </tr>
    </>
  );
};

export default TableRow;
