import React from 'react';

import { Loaders } from 'components/cards/Loader';
import { Error, Success } from '../Complete/components';
import { ContentProps } from './Content.types';

const Content = ({ iframeUrl, isLoading, isError, isCompleted }: ContentProps) => {
  if (isError) return <Error />;

  if (isCompleted) return <Success />;

  if (isLoading) return <Loaders.Light />;

  if (iframeUrl) {
    return <iframe src={iframeUrl} className="tw-w-full tw-h-full" style={{ minHeight: '40rem' }} />;
  }

  return null;
};

export default Content;
