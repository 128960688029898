import React, { useState, useContext, useEffect } from 'react';
import { IoPersonAddOutline } from 'react-icons/io5';

import { TeamContext } from 'components/settings/Team/TeamContext';
import CardActionButton from '../CardActionButton';
import AddTeamMemberModal from './AddTeamMemberModal';

const ShareCardWithTeamMember = ({ creditCard }) => {
  const [showAddTeamMemberModal, setShowAddTeamMemberModal] = useState(false);
  const onAddTeamMember = () => setShowAddTeamMemberModal(true);

  const { members, loadingMembers, getMembers } = useContext(TeamContext);
  const shareString = `Share ${creditCard.displayName} - ${creditCard.lastFourDigits} with Team Member`;

  useEffect(() => {
    getMembers();
  }, []);

  return (
    <>
      <CardActionButton
        label="Share Card with Team Member"
        Icon={IoPersonAddOutline}
        onClick={onAddTeamMember}
        className="tw-bg-secondary-light-green tw-text-primary-dark-green"
      />
      <AddTeamMemberModal
        show={showAddTeamMemberModal}
        setShow={setShowAddTeamMemberModal}
        shareString={shareString}
        loadingMembers={loadingMembers}
        members={members}
        creditCard={creditCard}
      />
    </>
  );
};

export default ShareCardWithTeamMember;
