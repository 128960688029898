import React, { useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import { GET_MEMBERS } from 'graphql/cards';

export const TeamContext = React.createContext({});

export const TeamContextProvider = (props) => {
  const [member, setMember] = useState(null);

  const [getMembers, { data: membersData, loading: loadingMembers, error: membersDataError }] =
    useLazyQuery(GET_MEMBERS);
  const members = useMemo(() => _.get(membersData, 'me.account.internalContacts') || [], [membersData]);

  const contextValues = useMemo(
    () => ({
      member,
      setMember,
      members,
      loadingMembers,
      getMembers,
      membersDataError,
    }),
    [member, setMember, members, loadingMembers, getMembers, membersDataError]
  );

  return <TeamContext.Provider value={contextValues}>{props.children}</TeamContext.Provider>;
};
