import React, { useState } from 'react';
import { IoDownloadOutline } from 'react-icons/io5';

import AvailableToSpend from './AvailableToSpend';
import Button from 'components/Button';
import CurrentBalance from './CurrentBalance';
import LineOfCreditLimit from './LineOfCreditLimit';
import NoLineOfCreditBanner from './NoLineOfCreditBanner';
import RecentTransactions from '../RecentTransactions';
import MoveMoneyPromotionPrompt from './MoveMoneyPromotionPrompt';
import SidebarBalances from './SidebarBalances';
import { useRebuildTooltipForModal } from '../InfoTooltip';
import { SCOPE, TRANSACTION_PRODUCT_TYPES } from 'constants/index';
import PermissionChecker from 'components/PermissionChecker';
import { Statements } from 'components/lineOfCredit/components';
import useFeatureToggle from 'hooks/useFeatureToggle';

const DesktopLayout = ({
  lineOfCredit,
  hasLineOfCredit,
  transactions,
  wallets,
  bankAccounts,
  creditCards,
  lineOfCreditLedgerAccount,
  fundInfo,
}) => {
  if (!hasLineOfCredit) return <NoLineOfCreditBanner />;

  const [showStatementsModal, setShowStatementsModal] = useState(false);
  const onShowStatementsModal = () => setShowStatementsModal(true);
  const { isExperimentEnabled } = useFeatureToggle(false, 'loc_statements');

  return (
    <div className="tw-flex">
      <div className="tw-w-3/4 tw-pr-24">
        <div className="tw-flex tw-justify-between tw-mb-2">
          <h2 className="tw-font-semibold tw-mb-4">Line of Credit</h2>
          {isExperimentEnabled && (
            <>
              <Button
                tertiary
                onClick={onShowStatementsModal}
                className="tw-text-primary-dark-green tw-font-semibold tw-text-sm"
              >
                <IoDownloadOutline size={18} className="tw-mr-1" />
                Download Line of Credit Statements
              </Button>
              <Statements show={showStatementsModal} setShow={setShowStatementsModal} type="Line of Credit" />
            </>
          )}
        </div>

        <LineOfCreditSummary lineOfCredit={lineOfCredit} />
        <PermissionChecker scope={SCOPE.managePayments}>
          <div className="tw-mt-10">
            <MoveMoneyPromotionPrompt
              hasLineOfCredit={hasLineOfCredit}
              lineOfCredit={lineOfCredit}
              lineOfCreditLedgerAccount={lineOfCreditLedgerAccount}
              wallets={wallets}
              bankAccounts={bankAccounts}
              creditCards={creditCards}
            />
          </div>
        </PermissionChecker>
        <div className="tw-mt-10">
          <RecentTransactions
            transactions={transactions}
            transactionsUrl={`/dashboard/transactions?productType=${TRANSACTION_PRODUCT_TYPES.lineOfCredit}`}
          />
        </div>
      </div>
      <div className="tw-w-1/4">
        <SidebarBalances lineOfCredit={lineOfCredit} fundInfo={fundInfo} />
      </div>
    </div>
  );
};

export const LineOfCreditSummary = ({ lineOfCredit }) => {
  useRebuildTooltipForModal();
  return (
    <div className="tw-bg-neutral-light tw-rounded-md tw-p-4 tw-mb-8 lg:tw-p-0">
      <AvailableToSpend lineOfCredit={lineOfCredit} />
      <CurrentBalance lineOfCredit={lineOfCredit} />
      <LineOfCreditLimit lineOfCredit={lineOfCredit} />
    </div>
  );
};

export default DesktopLayout;
