import { useState, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { Option } from 'components/UI/SearchDropdown/v2/SearchDropdown.types';
import { mapMerchantCatagoriesToOptions } from '../EditSpendManagementModal.utils';
import { FormData, UseEditSpendManagementModalProps } from '../EditSpendManagementModal.types';

const useEditSpendManagementModal = ({
  closeModal,
  handleUpdateSettings,
  currencies,
  selectedCurrencies,
  selectedMerchants,
  merchantCategories,
  selectedMerchantCategories,
}: UseEditSpendManagementModalProps) => {
  const [selectedMerchantCategoriesOptions, setSelectedMerchantCategoriesOptions] = useState<Option[]>(
    selectedMerchantCategories.map((value) => ({ label: value, value }))
  );
  const [isMerchantCategoriesError, setIsMerchantCategoriesError] = useState(false);

  const form = useForm<FormData>({
    defaultValues: {
      allowedCurrencies: selectedCurrencies,
      lockOnMerchant: selectedMerchants?.length ? 'true' : 'false',
      allowedMerchant: selectedMerchants?.[0],
    },
  });
  const { handleSubmit, setError, clearErrors, setValue } = form;

  const merchantCategoriesOptions = useMemo(
    () => mapMerchantCatagoriesToOptions(merchantCategories || []),
    [merchantCategories]
  );

  const handleCloseModal = useCallback(() => {
    setIsMerchantCategoriesError(false);
    clearErrors();
    closeModal();
  }, [clearErrors, closeModal]);

  const onSubmit = useCallback(
    async ({ allowedCurrencies: formAllowedCurrencies, lockOnMerchant, allowedMerchant }: FormData) => {
      setIsMerchantCategoriesError(false);
      clearErrors();

      let blockedMerchantCategories: string[] = [];
      let allowedMerchants: string[] = [];

      if (lockOnMerchant === 'true') {
        if (!allowedMerchant) {
          setError('allowedMerchant', { type: 'manual', message: 'Select a merchant' });
          return;
        }

        allowedMerchants = [allowedMerchant];
      } else {
        if (!selectedMerchantCategoriesOptions.length) {
          setIsMerchantCategoriesError(true);
          return;
        }

        const selectedMerchantCategoryValues = selectedMerchantCategoriesOptions.map(({ value }) => value);

        blockedMerchantCategories =
          merchantCategories?.filter((category) => !selectedMerchantCategoryValues.includes(category)) || [];
      }

      const blockedCurrencies = currencies?.filter((currency) => !formAllowedCurrencies.includes(currency));

      if (!blockedCurrencies || blockedCurrencies.length === currencies?.length) {
        setError('allowedCurrencies', { type: 'manual', message: 'Select at least one currency' });
        return;
      }

      await handleUpdateSettings({
        blockedMerchantCategories,
        blockedCurrencies,
        allowedMerchants,
      });
    },
    [selectedMerchantCategoriesOptions, merchantCategories, currencies, handleUpdateSettings]
  );

  useEffect(() => {
    const allowedMerchant = selectedMerchants?.[0];
    setValue('lockOnMerchant', allowedMerchant ? 'true' : 'false', { shouldDirty: true });
    setValue('allowedMerchant', allowedMerchant, { shouldDirty: true });
    setValue('allowedCurrencies', selectedCurrencies, { shouldDirty: true });
  }, [setValue, selectedMerchants, selectedCurrencies]);

  return {
    handleCloseModal,
    form,
    handleSubmit,
    onSubmit,
    isMerchantCategoriesError,
    merchantCategoriesOptions,
    selectedMerchantCategoriesOptions,
    setSelectedMerchantCategoriesOptions,
  };
};

export default useEditSpendManagementModal;
