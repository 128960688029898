import React from 'react';

import CheckmarksAndStars from 'components/svg/CheckmarksAndStars';
import { SuccessProps } from './Success.types';

const Success = ({ title, message }: SuccessProps) => (
  <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-max-w-md tw-m-auto tw-py-16">
    <CheckmarksAndStars />
    <div className="tw-font-bold tw-text-lg">{title}</div>
    <div className="tw-text-center">{message}</div>
  </div>
);

export default Success;
