import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import Button from 'components/Button';

import { accountingServicesConnectButtonStyleMap } from 'components/settings/Integrations/constants';
import { GET_AUTH_URL } from 'graphql/integrations';

const AccountingServiceConnectButton = ({ integration }) => {
  const [getAuthUrl, { loading, data }] = useLazyQuery(GET_AUTH_URL, {
    variables: { integration: integration.toLowerCase() },
  });

  const handleClick = () => {
    if (!loading) getAuthUrl();
  };

  useEffect(() => {
    if (data) {
      const { authUrl } = _.get(data, 'integrationsAuth') || {};

      if (authUrl) window.location.href = authUrl;
    }
  }, [data]);

  return (
    <Button
      className="tw-mt-8 tw-rounded-md tw-p-8 tw-w-max"
      style={accountingServicesConnectButtonStyleMap[integration.toLowerCase()]}
      name={integration}
      onClick={handleClick}
    ></Button>
  );
};

export default AccountingServiceConnectButton;
