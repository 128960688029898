import { useEffect, useState } from 'react';

import { useToggle } from 'hooks';
import { DatePeriod, PeriodDropdownProps } from '../PeriodDropdown.types';
import { getPeriodOptions } from '../utility/getPeriodOptions';
import { DEFAULT_CUSTOM_PERIOD_OPTION } from '../constants';

const usePeriodDropdown = ({ setSelectedValue }: Pick<PeriodDropdownProps, 'setSelectedValue'>) => {
  const { isOpen, toggle: toggleOpen } = useToggle();

  const [customPeriod, setCustomPeriod] = useState(DEFAULT_CUSTOM_PERIOD_OPTION);

  const periodOptions = getPeriodOptions({ customPeriod });

  const handleChangeFrom = (date: Date) =>
    handleChangeCustomPeriod({
      ...customPeriod,
      from: date,
    });

  const handleChangeTo = (date: Date) =>
    handleChangeCustomPeriod({
      ...customPeriod,
      to: date,
    });

  const handleChangeCustomPeriod = (newPeriod: DatePeriod) => {
    setCustomPeriod(newPeriod);
    setSelectedValue(newPeriod);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      const periodModal = document.getElementsByClassName('periodModal');
      const dataPicker = document.getElementsByClassName('react-datepicker');

      if (isOpen && !periodModal[0].contains(e.target as Node)) {
        if (dataPicker[0] && dataPicker[0].contains(e.target as Node)) {
          return;
        }
        toggleOpen();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen, toggleOpen]);

  return {
    isOpen,
    toggleOpen,
    periodOptions,
    handleChangeFrom,
    handleChangeTo,
    customPeriod,
  };
};
export default usePeriodDropdown;
