import React from 'react';
import Button from 'components/Button';
import config from 'config';

import CardAndCoins from 'components/svg/CardAndCoins';

const ErrorFetchingCreditCards = ({ handleRetry }) => {
  return (
    <div className="tw-m-auto tw-bg-neutral-light tw-py-5 tw-px-6 tw-flex tw-flex-col tw-items-center">
      <div>
        <CardAndCoins height="300" width="100%" />
      </div>
      <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-my-5">
        <div className="tw-flex">
          <div className="">
            <div className="tw-text-4xl tw-font-semibold tw-mt-4">Oops! something seems to be wrong</div>

            <div className="tw-text-base tw-my-5 tw-text-neutral-grey-1">
              <p data-testid="error-message">
                Unfortunately, we weren't able to display your Loop Cards. Please try again. If you continue to
                encounter this issue, please reach out to our team at{' '}
                <a
                  className="tw-font-semibold tw-text-primary-dark-green"
                  target="_blank"
                  href={`mailto:${config.supportEmail}`}
                  rel="noreferrer"
                >
                  {config.supportEmail}
                </a>
              </p>
            </div>
            <Button tertiary onClick={handleRetry}>
              <div className="tw-flex tw-justify-center tw-items-center">
                <small className="tw-text-neutral-light">Try again</small>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorFetchingCreditCards;
