import { Currencies } from 'constants/currencies';

export enum FXRateSource {
  CurrencyCloud = 'currency_cloud',
}

export type FXRate = {
  id: string;
  sellCurrency: Currencies;
  buyCurrency: Currencies;
  entity: FXRateSource;
  rate: number;
};

export type FXRatesByCurrenciesResponse = {
  fxRates: FXRate[];
};

export enum FXOperationType {
  buy = 'buy',
  sell = 'sell',
}
