import React, { useContext } from 'react';

import { FormFooter } from 'components/UI';
import { CardMigrationContext } from 'components/CardMigrationV2/context/CardMigrationContext';
import migrationInProgressSrc from 'images/MigrationInProgress.png';

const UpgradeConfirmation = () => {
  const { onNextStep } = useContext(CardMigrationContext);

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-items-center">
      <div className="tw-flex tw-w-2/3 tw-flex-col tw-gap-4 tw-py-6 tw-pl-8 tw-pr-4 tw-my-6">
        <div className="tw-w-full tw-flex tw-justify-center">
          <div className="tw-w-3/5">
            <img src={migrationInProgressSrc} alt="Migration In Progress" className="tw-w-full" />
          </div>
        </div>
        <p className="tw-mb-4">
          Migration in Progress: Your cards are currently being re-created. We will email you once this has been
          completed.
        </p>
      </div>

      <div className="tw-w-full tw-border-b tw-border-neutral-grey-4 tw-bg-neutral-grey-4.5 tw-bottom-0 tw-rounded-b-md tw-flex tw-justify-center">
        <FormFooter
          onSubmit={onNextStep}
          submitButtonLabel={'Back To Dashboard'}
          rootClass="tw-w-2/3 tw-py-6 tw-px-8"
        />
      </div>
    </div>
  );
};

export default UpgradeConfirmation;
