import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import Button from 'components/Button';

export const OfferDetailsModal = ({ show, setShow, offer }) => {
  const onClose = () => setShow(false);
  const { fullDetails, partner, termsUrl } = offer || {};
  const { name, link, about } = partner || {};

  return (
    <Modal show={show} onClose={() => {}}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <strong className="tw-ml-2">Offer Details</strong>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-px-8 tw-pt-6 tw-pb-8 tw-space-y-4">
        <h3>{name}</h3>
        <div className="tw-flex tw-flex-col">
          <strong className="tw-mb-2">About</strong>
          <div dangerouslySetInnerHTML={{ __html: about }} />
        </div>
        <div className="tw-flex tw-flex-col">
          <strong className="tw-mb-2">Offer Details</strong>
          <div dangerouslySetInnerHTML={{ __html: fullDetails }} />
        </div>
        <small>
          View our general offer terms{' '}
          <a href={termsUrl} target="_blank" rel="noopener noreferrer">
            <span className="tw-cursor-pointer tw-underline">here</span>
          </a>
          .
        </small>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <Button primary className="tw-w-max">
            <div className="tw-flex tw-items-center tw-space-x-2">
              <p className="tw-text-neutral-light">Visit {name}</p>
              <BsArrowRight />
            </div>{' '}
          </Button>
        </a>
      </div>
    </Modal>
  );
};
