import React from 'react';

import { TEAM_MEMBER_STATUS, TEAM_INVITATION_STATUS } from 'constants/index';

const MemberStatusBadge = ({ status }) => {
  switch (status) {
    case TEAM_MEMBER_STATUS.active:
      return <Badge className="tw-bg-secondary-light-green tw-text-semantic-success" title="Verified" />;
    case TEAM_MEMBER_STATUS.inactive:
      return <Badge className="tw-bg-semantic-error-background tw-text-semantic-error" title="Inactive" />;
    case TEAM_MEMBER_STATUS.not_invited:
      return <Badge className="tw-bg-neutral-grey-4 tw-text-neutral-grey-1" title="Not Invited" />;
    case TEAM_INVITATION_STATUS.pending:
      return <Badge className="tw-bg-secondary-pastel-yellow-50 tw-text-primary-yellow" title="Invite Sent" />;
    case TEAM_INVITATION_STATUS.pending_info:
      return <Badge className="tw-bg-secondary-pastel-yellow-50 tw-text-primary-yellow" title="Pending User Info" />;
    case TEAM_INVITATION_STATUS.pending_verification:
      return <Badge className="tw-bg-secondary-pastel-yellow-50 tw-text-primary-yellow" title="Pending Verification" />;
    default:
      return null;
  }
};

const Badge = ({ className, title }) => (
  <div className={`tw-py-1 tw-px-2 tw-w-max tw-rounded-md tw-flex tw-items-center tw-justify-center ${className}`}>
    <small>{title}</small>
  </div>
);

export default MemberStatusBadge;
