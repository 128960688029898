import React from 'react';

import { AccountDetail } from './AccountDetail';

const CustomFeatures = () => {
  return (
    <div className="tw-w-full tw-bg-white tw-p-6">
      <div className="tw-h-1/5 tw-mb-4">
        <div className="tw-font-semibold">Features</div>
        <div className="tw-text-sm">&nbsp;</div>
      </div>
      <ul className="tw-w-full tw-space-y-4">
        <AccountDetail>Discounted FX</AccountDetail>
        <AccountDetail>Earn 2 Loop Point on Card Spend</AccountDetail>
        <AccountDetail>Free USD, EUR and GBP Accounts</AccountDetail>
        <AccountDetail>Free Global Payments</AccountDetail>
        <AccountDetail>Unlimited Team Members</AccountDetail>
      </ul>
    </div>
  );
};

export default CustomFeatures;
