import React from 'react';
import { HiPencil } from 'react-icons/hi';
import { RiDeleteBin6Line } from 'react-icons/ri';

import Button from 'components/Button';
import { ActionsProps } from './Actions.types';
import DocumentTextOutline from 'components/svg/DocumentTextOutline';

const Actions = ({
  openEditModal,
  openDeleteModal,
  openAddPaymentRequestModal,
  isAllowedToManagePayors,
  isAllowedToRequestPayments,
}: ActionsProps) => {
  return (
    <div className="tw-flex tw-gap-x-8 tw-flex-col lg:tw-flex-row tw-space-y-2 lg:tw-space-y-0 tw-justify-end tw-mb-2">
      {isAllowedToManagePayors && (
        <Button
          className="tw-flex tw-justify-center tw-items-center tw-text-primary-dark-green tw-py-2.5 tw-w-full lg:tw-w-max"
          onClick={openEditModal}
        >
          <HiPencil className="tw-mr-2" />
          Edit Details
        </Button>
      )}

      {isAllowedToManagePayors && (
        <Button
          onClick={openDeleteModal}
          className="tw-flex tw-justify-center tw-items-center tw-text-semantic-error tw-py-2.5 tw-w-full lg:tw-w-max"
        >
          <RiDeleteBin6Line className="tw-mr-2" />
          Delete Payor
        </Button>
      )}

      {isAllowedToRequestPayments && (
        <Button primary onClick={openAddPaymentRequestModal} className="tw-py-2.5 tw-px-12 tw-w-full lg:tw-w-max">
          <DocumentTextOutline className="tw-mr-2" />
          Request Payment
        </Button>
      )}
    </div>
  );
};

export default Actions;
