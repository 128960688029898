import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

import { FirstConversionModalProps } from './FirstConversionModal.types';
import { useFirstConversionModal } from './hooks';
import { Modal } from 'components/UI';
import CashStarsAndRepeat from 'components/svg/CashStarsAndRepeat';
import Button, { IconAnimation } from 'components/Button';
import ConvertFundsCurrencySelectorContent from 'components/home/Header/components/QuickActions/components/ConvertFundsCurrencySelectorModal/components/ConvertFundsCurrencySelectorContent/ConvertFundsCurrencySelectorContent';

const FirstConversionModal = ({ show, onClose }: FirstConversionModalProps) => {
  const { showNextSection, handleClick } = useFirstConversionModal({ show });

  return (
    <Modal show={show} onClose={onClose} title="Make Your First Conversion">
      <div className="tw-p-8">
        {showNextSection ? (
          <ConvertFundsCurrencySelectorContent />
        ) : (
          <>
            <div className="tw-flex tw-justify-center">
              <CashStarsAndRepeat />
            </div>
            <h3 className="tw-my-6">Make Your First Conversion With Loop</h3>
            <p>
              Loop allows you to convert funds between your accounts and send payments around the world at market
              leading FX rates! Click the link below to convert funds between your Loop accounts today.
            </p>
            <p className="tw-my-6">Select a Loop account and convert funds:</p>
            <Button primary onClick={handleClick} iconAnimation={IconAnimation.rollOut}>
              Make Your First Conversion <BsArrowRight className="tw-ml-2" />
            </Button>
            <div className="tw-text-xs tw-mt-6">*Points may take up to 24 hours to appear on your account</div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default FirstConversionModal;
