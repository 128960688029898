import { useEffect } from 'react';

const DEFAULT_TITLE = 'Financial Services for eCommerce Brands | Loop';

const useSetPageTitle = (title = DEFAULT_TITLE) => {
  useEffect(() => {
    document.title = `${title} | Loop`;
  }, [title]);
};

export default useSetPageTitle;
