import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { Collapse } from 'react-collapse';
import { BsChevronDown } from 'react-icons/bs';

import { balancesOrder } from 'constants/index';
import { formatMoneyV2 } from 'utility/currency';
import CurrencyFlag from 'components/svg/CurrencyFlag';
import Bank from 'components/svg/Bank';
import { Loaders } from 'components/cards/Loader';
import { PendingDepositsInfo } from 'components/Accounts/components';

const WalletsSummary = ({ wallets, walletLoading }) => {
  const [expanded, setIsExpanded] = useState(false);

  const onToggleExpand = () => setIsExpanded(!expanded);

  return (
    <>
      <div className="tw-bg-neutral-light tw-border-neutral-grey-4 tw-shadow-md tw-border tw-rounded-md tw-mt-4 tw-w-full">
        <Header onExpand={onToggleExpand} isExpanded={expanded} />
        <Collapse isOpened={expanded}>
          <div className="tw-w-full">
            <ExpandedBalances wallets={wallets} />
          </div>
        </Collapse>
        <Transition
          as="div"
          show={!expanded}
          enter="tw-ease-in-out tw-duration-200 tw-transition-all"
          enterFrom="tw-transform tw-opacity-0"
          enterTo="tw-transform tw-opacity-100"
          leave="tw-ease-in-out tw-duration-200 tw-transition-all"
          leaveFrom="tw-transform tw-opacity-100"
          leaveTo="tw-transform tw-opacity-0"
        >
          <CollapsedBalances walletLoading={walletLoading} wallets={wallets} />
        </Transition>
      </div>
    </>
  );
};

const Header = ({ onExpand, isExpanded }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-border-b tw-border-neutral-grey-4">
      <div className="tw-flex">
        <div className="tw-flex tw-justify-center tw-items-center tw-bg-secondary-light-lilac tw-text-primary-lilac tw-rounded tw-p-1 tw-mr-2">
          <Bank width={12} />
        </div>
        <strong>Accounts</strong>
      </div>
      <div
        onClick={onExpand}
        data-testid="wallets-summary-expand-button"
        className={`${
          isExpanded ? 'tw-bg-neutral-grey-4' : 'tw-bg-neutral-light'
        } tw-rounded-md tw-flex tw-justify-center tw-items-center tw-bg-neutral-light tw-border tw-border-neutral-grey-4 tw-p-2 tw-cursor-pointer hover:tw-bg-primary-light-green`}
      >
        <BsChevronDown
          className={`tw-transform tw-rotate-${
            isExpanded ? '180' : '0'
          } tw-ease-in-out tw-duration-200 tw-transition-all`}
        />
      </div>
    </div>
  );
};

const CollapsedBalances = ({ wallets, walletLoading }) => {
  const balances = wallets.map((wallet) => wallet.availableBalance);

  return (
    <div className="tw-p-4">
      <small>Account Balances</small>
      {walletLoading ? (
        <Loaders.Spinner />
      ) : (
        <div className="tw-flex tw-mt-4">
          {balancesOrder.map((currency) => {
            const balance = balances.find((walletBalance) => walletBalance.currency === currency);
            if (!balance) return null;

            return (
              <div key={balance.currency} className="tw-flex-grow">
                <div className="tw-flex tw-items-center">
                  <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
                    <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                      {<CurrencyFlag currency={balance.currency} size={14} />}
                    </div>
                  </div>
                  <small>{`${formatMoneyV2(balance)} ${balance.currency}`}</small>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const ExpandedBalances = ({ wallets }) => {
  return (
    <div className="tw-flex tw-flex-wrap tw-p-4">
      {balancesOrder.map((currency) => {
        const wallet = wallets.find((w) => w.currency === currency);
        if (!wallet) return null;

        const { availableBalance, ongoingPaymentsBalance } = wallet;

        return (
          <div
            key={currency}
            className="tw-flex tw-w-1/2 tw-p-4 tw-flex-grow tw-items-start tw-rounded-md tw-transition-shadow tw-duration-500 tw-ease-in-out tw-cursor-pointer hover:tw-shadow-notification"
          >
            <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mt-1 tw-mr-2">
              <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                <CurrencyFlag currency={currency} size={28} />
              </div>
            </div>
            <div className="tw-flex tw-flex-col">
              <p className="tw-mb-1">{currency}</p>
              <strong>
                {formatMoneyV2(availableBalance)} {currency}
              </strong>
              {!!ongoingPaymentsBalance.amount && (
                <div className="tw-flex tw-gap-2 tw-text-sm tw-mt-2">
                  <span className="tw-whitespace-nowrap">Pending Deposits: </span>
                  <b>{formatMoneyV2(ongoingPaymentsBalance)}</b>
                  <PendingDepositsInfo />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WalletsSummary;
