import { useContext } from 'react';
import { get } from 'lodash';

import { StepsProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { FundsContext } from 'components/Accounts/AccountsContent/contexts/FundsContext';
import { Currencies } from 'constants/currencies';
import { formatMoneyV2 } from 'utility/currency';
import { formatDateTime } from 'utility/date';
import useIsMobile from 'hooks/useIsMobile';

const useComplete = ({ onResetSteps }: Pick<StepsProps, 'onResetSteps'>) => {
  const { addFundsTransaction, setAddFundsInfo } = useContext(FundsContext);
  const isMobile = useIsMobile();

  const toAccount = get(addFundsTransaction, 'toAccount.displayName', '');
  const fromAccount = get(addFundsTransaction, 'fromAccount.displayName', '');
  const amount = get(addFundsTransaction, 'amount', 0);
  const currency = get(addFundsTransaction, 'amount.currency', Currencies.CAD);
  const transferMethod = get(addFundsTransaction, 'transferMethod', '');
  const transactionGroupId = get(addFundsTransaction, 'transactionGroupId', '');
  const initiatedAt = get(addFundsTransaction, 'initiatedAt');

  const formattedAmount = `${currency} ${formatMoneyV2(amount)}`;
  const formattedInitiatedAtDate = formatDateTime(initiatedAt);

  const handleNewDeposit = () => {
    setAddFundsInfo(null);
    onResetSteps();
  };

  return {
    toAccount,
    fromAccount,
    formattedAmount,
    transferMethod,
    transactionGroupId,
    formattedInitiatedAtDate,
    handleNewDeposit,
    isMobile,
  };
};

export default useComplete;
