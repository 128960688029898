import React from 'react';

const ConfigureInstruction = () => {
  return (
    <div className="tw-mt-4 tw-rounded-md tw-p-2 tw-text-neutral-grey-2">
      <div className="tw-font-semibold">How it works</div>
      <ol className="tw-list-decimal tw-ml-4">
        <li>Take a picture of your receipt or download a copy of it.</li>
        <li>Each image or file can contain only one receipt; you can attach multiple files to a single email.</li>
        <li>Email the receipts to your custom Loop Receipts email address</li>
        <li>
          Loop will send you a confirmation email and notify you if the receipt was successfully attached to a
          transaction.
        </li>
      </ol>
    </div>
  );
};

export default ConfigureInstruction;
