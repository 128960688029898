import { formatMoneyV2 } from 'utility/currency';

class LineOfCredit {
  constructor(attributes) {
    const { id, availableCredit, ledgerAccount } = attributes || {};

    this.id = id;
    this.availableCredit = availableCredit;
    this.availableBalance = availableCredit;
    this.currency = this.availableCredit.currency;
    this.displayName = ledgerAccount && ledgerAccount.displayName;
  }

  formattedAvailableCredit() {
    return `${this.currency} ${formatMoneyV2(this.availableCredit)}`;
  }
}

export default LineOfCredit;
