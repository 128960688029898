import React from 'react';

type CashStarsAndRepeatProps = {
  width?: number;
  height?: number;
};

const CashStarsAndRepeat = ({ width = 290, height = 160 }: CashStarsAndRepeatProps) => (
  <svg width={width} height={height} viewBox="0 0 290 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_12817_134406)">
      <path
        d="M9.27828 75.9931H57.5365C58.5616 75.9931 59.3926 76.8259 59.3926 77.8532V103.894C59.3926 104.921 58.5616 105.754 57.5365 105.754H9.27828C8.25319 105.754 7.42219 104.921 7.42219 103.894V77.8532C7.42219 76.8259 8.25319 75.9931 9.27828 75.9931Z"
        fill="#D0E7DA"
      />
      <path
        d="M4.46381 71.1668H52.722C53.7471 71.1668 54.5781 71.9996 54.5781 73.0269V99.0676C54.5781 100.095 53.7471 100.928 52.722 100.928H4.46381C3.43872 100.928 2.60773 100.095 2.60773 99.0676V73.0269C2.60773 71.9996 3.43872 71.1668 4.46381 71.1668Z"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinejoin="round"
      />
      <path
        d="M31.5929 78.7473C36.7184 78.7473 40.8734 82.9112 40.8734 88.0476C40.8734 93.184 36.7184 97.3478 31.5929 97.3478C26.4675 97.3478 22.3125 93.184 22.3125 88.0476C22.3125 82.9112 26.4675 78.7473 31.5929 78.7473Z"
        fill="#FFE8C3"
      />
      <path
        d="M28.5929 76.7473C33.7184 76.7473 37.8734 80.9112 37.8734 86.0476C37.8734 91.184 33.7184 95.3478 28.5929 95.3478C23.4675 95.3478 19.3125 91.184 19.3125 86.0476C19.3125 80.9112 23.4675 76.7473 28.5929 76.7473Z"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.5783 91.6279C52.117 91.6279 49.7564 92.6077 48.016 94.3519C46.2756 96.096 45.2979 98.4616 45.2979 100.928"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.60742 91.6279C3.82615 91.6279 5.03293 91.8684 6.15889 92.3358C7.28484 92.8032 8.30791 93.4883 9.16968 94.3519C10.0314 95.2155 10.715 96.2407 11.1814 97.3691C11.6478 98.4975 11.8878 99.7068 11.8878 100.928"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.5783 80.4672C52.117 80.4672 49.7564 79.4873 48.016 77.7432C46.2756 75.999 45.2979 73.6335 45.2979 71.1669"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.60742 80.4672C3.82615 80.4672 5.03293 80.2266 6.15889 79.7592C7.28484 79.2918 8.30791 78.6068 9.16968 77.7432C10.0314 76.8796 10.715 75.8543 11.1814 74.726C11.6478 73.5976 11.8878 72.3882 11.8878 71.1669"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g clipPath="url(#clip1_12817_134406)">
      <path
        d="M85.2783 75.9931H133.536C134.562 75.9931 135.393 76.8259 135.393 77.8532V103.894C135.393 104.921 134.562 105.754 133.536 105.754H85.2783C84.2532 105.754 83.4222 104.921 83.4222 103.894V77.8532C83.4222 76.8259 84.2532 75.9931 85.2783 75.9931Z"
        fill="#D0E7DA"
      />
      <path
        d="M80.4638 71.1668H128.722C129.747 71.1668 130.578 71.9996 130.578 73.0269V99.0676C130.578 100.095 129.747 100.928 128.722 100.928H80.4638C79.4387 100.928 78.6077 100.095 78.6077 99.0676V73.0269C78.6077 71.9996 79.4387 71.1668 80.4638 71.1668Z"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinejoin="round"
      />
      <path
        d="M107.593 78.7473C112.718 78.7473 116.873 82.9112 116.873 88.0476C116.873 93.184 112.718 97.3478 107.593 97.3478C102.467 97.3478 98.3125 93.184 98.3125 88.0476C98.3125 82.9112 102.467 78.7473 107.593 78.7473Z"
        fill="#FFE8C3"
      />
      <path
        d="M104.593 76.7473C109.718 76.7473 113.873 80.9112 113.873 86.0476C113.873 91.184 109.718 95.3478 104.593 95.3478C99.4675 95.3478 95.3125 91.184 95.3125 86.0476C95.3125 80.9112 99.4675 76.7473 104.593 76.7473Z"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.578 91.6279C128.117 91.6279 125.756 92.6077 124.016 94.3519C122.276 96.096 121.298 98.4616 121.298 100.928"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.6074 91.6279C79.8261 91.6279 81.0329 91.8684 82.1589 92.3358C83.2848 92.8032 84.3079 93.4883 85.1697 94.3519C86.0314 95.2155 86.715 96.2407 87.1814 97.3691C87.6478 98.4975 87.8878 99.7068 87.8878 100.928"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.578 80.4672C128.117 80.4672 125.756 79.4873 124.016 77.7432C122.276 75.999 121.298 73.6335 121.298 71.1669"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.6074 80.4672C79.8261 80.4672 81.0329 80.2266 82.1589 79.7592C83.2848 79.2918 84.3079 78.6068 85.1697 77.7432C86.0314 76.8796 86.715 75.8543 87.1814 74.726C87.6478 73.5976 87.8878 72.3882 87.8878 71.1669"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g clipPath="url(#clip2_12817_134406)">
      <path
        d="M161.278 75.9931H209.536C210.562 75.9931 211.393 76.8259 211.393 77.8532V103.894C211.393 104.921 210.562 105.754 209.536 105.754H161.278C160.253 105.754 159.422 104.921 159.422 103.894V77.8532C159.422 76.8259 160.253 75.9931 161.278 75.9931Z"
        fill="#FFE8C3"
      />
      <path
        d="M156.464 71.1668H204.722C205.747 71.1668 206.578 71.9996 206.578 73.0269V99.0676C206.578 100.095 205.747 100.928 204.722 100.928H156.464C155.439 100.928 154.608 100.095 154.608 99.0676V73.0269C154.608 71.9996 155.439 71.1668 156.464 71.1668Z"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinejoin="round"
      />
      <path
        d="M183.593 78.7473C188.718 78.7473 192.873 82.9112 192.873 88.0476C192.873 93.184 188.718 97.3478 183.593 97.3478C178.467 97.3478 174.312 93.184 174.312 88.0476C174.312 82.9112 178.467 78.7473 183.593 78.7473Z"
        fill="#D0E7DA"
      />
      <path
        d="M180.593 76.7473C185.718 76.7473 189.873 80.9112 189.873 86.0476C189.873 91.184 185.718 95.3478 180.593 95.3478C175.467 95.3478 171.312 91.184 171.312 86.0476C171.312 80.9112 175.467 76.7473 180.593 76.7473Z"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M206.578 91.6279C204.117 91.6279 201.756 92.6077 200.016 94.3519C198.276 96.096 197.298 98.4616 197.298 100.928"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.607 91.6279C155.826 91.6279 157.033 91.8684 158.159 92.3358C159.285 92.8032 160.308 93.4883 161.17 94.3519C162.031 95.2155 162.715 96.2407 163.181 97.3691C163.648 98.4975 163.888 99.7068 163.888 100.928"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M206.578 80.4672C204.117 80.4672 201.756 79.4873 200.016 77.7432C198.276 75.999 197.298 73.6335 197.298 71.1669"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.607 80.4672C155.826 80.4672 157.033 80.2266 158.159 79.7592C159.285 79.2918 160.308 78.6068 161.17 77.7432C162.031 76.8796 162.715 75.8543 163.181 74.726C163.648 73.5976 163.888 72.3882 163.888 71.1669"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g clipPath="url(#clip3_12817_134406)">
      <path
        d="M236.464 76.1668H284.722C285.747 76.1668 286.578 76.9996 286.578 78.0269V104.068C286.578 105.095 285.747 105.928 284.722 105.928H236.464C235.439 105.928 234.608 105.095 234.608 104.068V78.0269C234.608 76.9996 235.439 76.1668 236.464 76.1668Z"
        fill="#FFE8C3"
      />
      <path
        d="M232.464 71.1668H280.722C281.747 71.1668 282.578 71.9996 282.578 73.0269V99.0676C282.578 100.095 281.747 100.928 280.722 100.928H232.464C231.439 100.928 230.608 100.095 230.608 99.0676V73.0269C230.608 71.9996 231.439 71.1668 232.464 71.1668Z"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinejoin="round"
      />
      <path
        d="M259.78 79.011C264.906 79.011 269.061 83.1749 269.061 88.3113C269.061 93.4477 264.906 97.6116 259.78 97.6116C254.655 97.6116 250.5 93.4477 250.5 88.3113C250.5 83.1749 254.655 79.011 259.78 79.011Z"
        fill="#D0E7DA"
      />
      <path
        d="M256.593 76.7473C261.718 76.7473 265.873 80.9112 265.873 86.0476C265.873 91.184 261.718 95.3478 256.593 95.3478C251.467 95.3478 247.312 91.184 247.312 86.0476C247.312 80.9112 251.467 76.7473 256.593 76.7473Z"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M282.578 91.6279C280.117 91.6279 277.756 92.6077 276.016 94.3519C274.276 96.096 273.298 98.4616 273.298 100.928"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M230.607 91.6279C231.826 91.6279 233.033 91.8684 234.159 92.3358C235.285 92.8032 236.308 93.4883 237.17 94.3519C238.031 95.2155 238.715 96.2407 239.181 97.3691C239.648 98.4975 239.888 99.7068 239.888 100.928"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M282.578 80.4672C280.117 80.4672 277.756 79.4873 276.016 77.7432C274.276 75.999 273.298 73.6335 273.298 71.1669"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M230.607 80.4672C231.826 80.4672 233.033 80.2266 234.159 79.7592C235.285 79.2918 236.308 78.6068 237.17 77.7432C238.031 76.8796 238.715 75.8543 239.181 74.726C239.648 73.5976 239.888 72.3882 239.888 71.1669"
        stroke="#4F5154"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M143 55.011C125.057 55.011 110.5 69.5683 110.5 87.511C110.5 105.454 125.057 120.011 143 120.011C160.943 120.011 175.5 105.454 175.5 87.511C175.5 69.5683 160.943 55.011 143 55.011Z"
      fill="white"
      stroke="#4F5154"
      strokeWidth="0.55"
      strokeMiterlimit="10"
    />
    <g clipPath="url(#clip4_12817_134406)">
      <path
        d="M145.333 108.415L148.621 107.534C159.514 104.615 165.976 93.3944 163.05 82.4754C162.258 79.504 160.805 76.7497 158.801 74.4173C156.797 72.0849 154.293 70.2346 151.474 69.0042M141.288 67.1772L138 68.0583C127.094 70.9804 120.638 82.1947 123.565 93.1181C124.363 96.0788 125.811 98.8243 127.805 101.154C129.798 103.484 132.286 105.34 135.088 106.587"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.692 63.1104L142.848 66.6644L139.353 73.0408"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.937 112.542L143.782 108.988L147.276 102.611"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M97.5651 12.5276C97.6697 12.457 97.7951 12.5824 97.7245 12.687L93.2506 19.3177C93.2244 19.3566 93.2244 19.4074 93.2506 19.4462L97.7245 26.0769C97.7951 26.1816 97.6697 26.307 97.5651 26.2364L90.9344 21.7625C90.8955 21.7363 90.8447 21.7363 90.8059 21.7625L84.1752 26.2364C84.0705 26.307 83.9451 26.1816 84.0157 26.0769L88.4896 19.4462C88.5158 19.4074 88.5158 19.3566 88.4896 19.3177L84.0157 12.687C83.9451 12.5824 84.0705 12.457 84.1752 12.5276L90.8059 17.0015C90.8447 17.0277 90.8955 17.0277 90.9344 17.0015L97.5651 12.5276Z"
      fill="#EEA530"
    />
    <path
      d="M114.565 120.528C114.67 120.457 114.795 120.582 114.725 120.687L110.251 127.318C110.224 127.357 110.224 127.407 110.251 127.446L114.725 134.077C114.795 134.182 114.67 134.307 114.565 134.236L107.934 129.762C107.896 129.736 107.845 129.736 107.806 129.762L101.175 134.236C101.071 134.307 100.945 134.182 101.016 134.077L105.49 127.446C105.516 127.407 105.516 127.357 105.49 127.318L101.016 120.687C100.945 120.582 101.071 120.457 101.175 120.528L107.806 125.002C107.845 125.028 107.896 125.028 107.934 125.002L114.565 120.528Z"
      fill="#EEA530"
    />
    <path
      d="M135.362 36.7616C135.488 36.7468 135.542 36.9158 135.431 36.9764L128.413 40.8152C128.372 40.8377 128.349 40.8828 128.354 40.9293L129.287 48.8736C129.302 48.9991 129.133 49.0529 129.072 48.9422L125.234 41.9246C125.211 41.8835 125.166 41.8602 125.119 41.8657L117.175 42.7985C117.05 42.8132 116.996 42.6442 117.107 42.5836L124.124 38.7449C124.165 38.7224 124.189 38.6772 124.183 38.6307L123.25 30.6864C123.236 30.561 123.405 30.5071 123.465 30.6179L127.304 37.6354C127.326 37.6765 127.372 37.6999 127.418 37.6944L135.362 36.7616Z"
      fill="#8F5CCF"
    />
    <path
      d="M193.846 153.485C193.869 153.61 193.704 153.675 193.636 153.568L186.979 143.139C186.954 143.1 186.907 143.08 186.861 143.088L174.699 145.357C174.575 145.38 174.51 145.215 174.616 145.147L185.045 138.491C185.085 138.466 185.105 138.419 185.096 138.373L182.827 126.21C182.804 126.086 182.969 126.021 183.037 126.128L189.694 136.557C189.719 136.596 189.766 136.616 189.812 136.608L201.974 134.339C202.098 134.316 202.163 134.481 202.057 134.549L191.628 141.205C191.588 141.23 191.568 141.277 191.577 141.323L193.846 153.485Z"
      fill="#8F5CCF"
    />
    <path
      d="M194.54 34.6851C194.646 34.7538 194.58 34.9183 194.456 34.8942L177.626 31.6211C177.58 31.6122 177.534 31.632 177.508 31.6713L168.173 46.0521C168.105 46.158 167.94 46.0916 167.964 45.9676L171.237 29.1381C171.246 29.0922 171.226 29.0454 171.187 29.0199L156.806 19.6851C156.7 19.6163 156.767 19.4519 156.891 19.476L173.72 22.749C173.766 22.758 173.813 22.7381 173.839 22.6988L183.173 8.31807C183.242 8.21215 183.407 8.27859 183.382 8.40254L180.109 25.232C180.1 25.278 180.12 25.3248 180.16 25.3503L194.54 34.6851Z"
      fill="#EEA530"
    />
    <defs>
      <clipPath id="clip0_12817_134406">
        <rect width="61" height="37" fill="white" transform="matrix(1 0 0 -1 0.5 107.011)" />
      </clipPath>
      <clipPath id="clip1_12817_134406">
        <rect width="61" height="37" fill="white" transform="matrix(1 0 0 -1 76.5 107.011)" />
      </clipPath>
      <clipPath id="clip2_12817_134406">
        <rect width="61" height="37" fill="white" transform="matrix(1 0 0 -1 152.5 107.011)" />
      </clipPath>
      <clipPath id="clip3_12817_134406">
        <rect width="61" height="37" fill="white" transform="matrix(1 0 0 -1 228.5 107.011)" />
      </clipPath>
      <clipPath id="clip4_12817_134406">
        <rect width="58.4856" height="58.4856" fill="white" transform="translate(163.992 52.011) rotate(75)" />
      </clipPath>
    </defs>
  </svg>
);

export default CashStarsAndRepeat;
