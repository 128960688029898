import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Loaders } from 'components/cards/Loader';
import { useGetUserInfo } from 'hooks';

const AccountSummary = (props) => {
  const { pendingInformation, pendingVerification, inactive, loading } = props;

  const history = useHistory();

  const { isLoading, isRegularUser } = useGetUserInfo();

  const route = routeForAccountState({
    pendingInformation,
    pendingVerification,
    inactive,
    isRegularUser,
  });

  useEffect(() => {
    if (loading || isLoading || !route || history.location.pathname === route) return;

    history.replace(route);
  }, [loading, route, history.location.pathname]);

  return <Loaders.FullScreen />;
};

const routeForAccountState = ({ pendingInformation, pendingVerification, inactive, isRegularUser }) => {
  if (pendingInformation) return '/team/personal-info';
  if (pendingVerification) return '/team/waiting-room';
  if (inactive) return '/suspended';
  if (isRegularUser) return '/dashboard/home';
};

export default AccountSummary;
