import React from 'react';

import SettingsLayout from 'components/OnboardingDashboard/components/Settings/components/SettingsLayout/SettingsLayout';
import { PreviewScreen } from 'components/OnboardingDashboard/components';
import teamSettingsScreenshotBlur from 'images/screenshot-settings-team-blur.png';

const TeamSettings = () => {
  return (
    <SettingsLayout>
      <div className="tw-my-10">
        <PreviewScreen bgImage={teamSettingsScreenshotBlur} />
      </div>
    </SettingsLayout>
  );
};

export default TeamSettings;
