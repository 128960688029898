import { useCallback, useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import { CreateCardContext } from 'components/creditCards/components/CreateCardModal/context/CreateCardContext';
import { getTerms } from '../KeyTerms.utils';
import { GET_GLOBAL_CREDIT_LIMIT } from 'graphql/creditOffer';
import { GlobalCreditLimitType } from '../KeyTerms.types';
import { useCreateCard } from '../../../../../hooks/useCreateCard';

const useKeyTerms = () => {
  const { currentStep, onPrevStep, onNextStep, steps, me } = useContext(CreateCardContext);
  const [globalCreditLimits, setGlobalCreditLimits] = useState<GlobalCreditLimitType>();

  const form = useForm({
    defaultValues: {},
  });
  const { register, handleSubmit } = form;

  const { data: globalCreditLimitData, loading: isGlobalCreditLimitLoading } = useQuery(GET_GLOBAL_CREDIT_LIMIT, {
    onCompleted: () => {
      const fetchedGlobalCreditLimit = get(globalCreditLimitData, 'me.account.globalCreditLimit', {});
      setGlobalCreditLimits(fetchedGlobalCreditLimit.amount);
    },
  });

  const { handleCreateCard, isSubmitting, error } = useCreateCard();

  const terms = useMemo(
    () => getTerms({ globalCreditLimit: globalCreditLimits, isGlobalCreditLimitLoading }),
    [globalCreditLimits, isGlobalCreditLimitLoading]
  );

  const handleSubmitForm = useCallback(async () => {
    const result = await handleCreateCard();
    if (result?.success) onNextStep();
  }, [handleCreateCard, error, onNextStep]);

  return {
    handleOnPreviousStep: onPrevStep,
    handleOnNextStep: handleSubmitForm,
    currentStep,
    steps,
    terms,
    form,
    register,
    handleSubmit,
    isSubmitting,
    error,
    firstName: me.firstName,
    lastName: me.lastName,
  };
};

export default useKeyTerms;
