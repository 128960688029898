import React from 'react';

import { Money, PayBalanceCurrency } from 'types/payments';
import { formatMoneyV2 } from 'utility/currency';

const TotalCreditLimit = ({ limit, isLoading }: { limit: Money<PayBalanceCurrency>; isLoading: boolean }) => {
  const formattedLimit = formatMoneyV2(limit);

  return (
    <div className="tw-flex tw-items-center tw-justify-start md:tw-justify-end tw-gap-4">
      <div className="tw-font-bold">Total Credit Limit</div>
      {isLoading ? (
        <div className="tw-flex tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-w-32 tw-h-5 tw-rounded" />
      ) : (
        <div>
          {formattedLimit} {limit.currency}
        </div>
      )}
    </div>
  );
};

export default TotalCreditLimit;
