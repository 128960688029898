import React from 'react';

import { SearchInput } from 'components/UI';
import { Loaders } from 'components/cards/Loader';
import { PayorsList, EmptyState } from './components';
import { usePayors } from './hooks';
import { PayorsProps } from './Payors.types';

const Payors = ({ handleAddPayor }: PayorsProps) => {
  const { isEmpty, loading, filteredPayors, search, handleChangeSearch } = usePayors();

  if (isEmpty) return <EmptyState handleAddPayor={handleAddPayor} />;

  return (
    <div className="tw-mt-8">
      <SearchInput value={search} onChange={handleChangeSearch} />

      {loading ? <Loaders.Light /> : <PayorsList payors={filteredPayors} />}
    </div>
  );
};

export default Payors;
