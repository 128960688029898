import React from 'react';

const MappingRowSkeleton = () => (
  <tbody>
    <tr>
      {Array(4)
        .fill(0)
        .map((i) => (
          <td key={i}>
            <div className="tw-rounded tw-p-2 tw-my-2 tw-bg-neutral-grey-3 tw-h-10 tw-w-full tw-animate-pulse" />
          </td>
        ))}
    </tr>
    <tr>
      {Array(4)
        .fill(0)
        .map((i) => (
          <td key={i}>
            <div className="tw-rounded tw-p-2 tw-my-2 tw-bg-neutral-grey-3 tw-h-10 tw-w-full tw-animate-pulse" />
          </td>
        ))}
    </tr>
  </tbody>
);

export default MappingRowSkeleton;
