import React, { useContext, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { QRCodeSVG } from 'qrcode.react';

import { SubmitButton } from 'components/FormFields/v2';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import { Forms } from 'components/UI';
import { AuthContext } from 'context/Auth';
import { NotificationAlertContext } from 'context/NotificationAlert';
import SuccessCheckmark from 'components/svg/SuccessCheckmark';
import Button from 'components/Button';
import Notification from 'components/Notification';

const { MFACodeInput } = Forms;

const totalDigit = 6;

const App2StepAuthorization = ({ show, closeModal }) => {
  const [code, setCode] = useState('');
  const [showCode, setShowCode] = useState(false);
  const [password, setPassword] = useState('');
  const [sendingCode, setSendingCode] = useState(false);
  const [error, setError] = useState(false);

  const form = useForm();
  const { handleSubmit } = form;
  const { me, meRefetch, setupTOTP, verifyTOTP } = useContext(AuthContext);
  const { setNotification } = useContext(NotificationAlertContext);

  useEffect(() => {
    setupTOTP?.()
      .then((res) => {
        const str = 'otpauth://totp/' + me?.email + '?secret=' + res + '&issuer=' + 'Loop';
        setPassword(str);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleCloseModal = () => {
    closeModal('2fa_APP');
    setCode('');
    setError('');
  };

  const handleSubmitPhone = () => {
    if (show) {
      setShowCode(!showCode);
      return;
    }
    try {
      // await sendVerificationCode({ variables: { attributeName: 'phoneNumber' } });
      setShowCode(!showCode);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateCode = (code) => {
    setCode(code);
  };

  const onSubmitVerificationCode = async () => {
    try {
      if (code.trim().length < 6) {
        setError('Please enter a valid code');
        setSendingCode(false);
        return;
      }
      const verified = await verifyTOTP(code);
      setSendingCode(true);
      console.log(verified);
      if (verified) {
        setNotification(
          <Notification Icon={SuccessCheckmark} message="App based Two-factor Authentication has been enabled" />
        );
        meRefetch && (await meRefetch());
        setShowCode(!showCode);
        setSendingCode(false);
        handleCloseModal();
        return;
      }
      setError('Invalid code. Please re-enter the code');
      setSendingCode(false);
      return;
    } catch (err) {
      console.error(err);
      setSendingCode(false);
      setError('Please enter a valid code');
    }
  };

  return (
    <Modal show={show} onClose={handleCloseModal}>
      <div className="tw-flex tw-justify-between tw-p-6">
        <h5 className="tw-text-left">Configure Authenticator App</h5>
        <Button onClick={handleCloseModal}>
          <Close />
        </Button>
      </div>

      <hr className="tw-mb-6" />

      {/* <h3 className="tw-px-10 tw-text-2xl tw-font-semibold">Configure Authenticator App</h3> */}
      <p className="tw-px-10 tw-mt-5">
        {' '}
        To set up your two step verification with an authenticator, complete the steps below:{' '}
      </p>
      <ol className="tw-px-10 tw-mt-5">
        <li>
          1. Install an Authenticator app such as Authy{' '}
          <a
            className="tw-underline tw-text-neutral-grey-1"
            href="https://apps.apple.com/ca/app/twilio-authy/id494168017"
          >
            (iOS
          </a>{' '}
          or{' '}
          <a
            className="tw-underline tw-text-neutral-grey-1"
            href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en_CA&gl=US"
          >
            Android)
          </a>{' '}
          or Google Authenticator on your phone.
        </li>
        <li>2. In the authenticator app, select the option to “add” by scanning a QR code </li>
        <li>3. Scan the QR code below</li>
        <li>
          <b>4. Enter the verification code generated by your authenticator app into the area below to verify:</b>
        </li>
      </ol>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleSubmitPhone)}>
          <div className="tw-flex tw-justify-center tw-p-3">
            <QRCodeSVG size={150} value={password} />
          </div>
          <div className="tw-flex tw-w-full tw-flex-col tw-justify-center tw-p-4">
            <MFACodeInput
              code={code}
              totalLength={totalDigit}
              onUpdate={handleUpdateCode}
              onSubmit={onSubmitVerificationCode}
              isLoading={sendingCode}
              isError={!!error}
              errorMessage={error}
            />
          </div>
          <hr />
          <div className="tw-p-6 tw-flex tw-justify-end">
            <SubmitButton
              disabled={sendingCode || (code.length === totalDigit && error)}
              onClick={onSubmitVerificationCode}
              className="tw-text-right tw-px-6 tw-items-center"
            >
              {sendingCode ? 'Submitting...' : 'Submit'}
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
      <ReactTooltip />
    </Modal>
  );
};

export default App2StepAuthorization;
