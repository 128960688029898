import React, { useCallback } from 'react';

import { Details, Members, CATaxDeclaration, USTaxDeclaration, Agreements, Complete } from './components';
import { StepsProps } from './Steps.types';

const Steps = ({ currentStep, setCurrentStep, enableFullScreen, disableFullScreen, onFinish }: StepsProps) => {
  const onNextStep = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const onPreviousStep = useCallback(() => {
    if (currentStep === 0) return;

    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  switch (currentStep) {
    case 0:
      return <Details onNextStep={onNextStep} />;
    case 1:
      return <Members onNextStep={onNextStep} onPreviousStep={onPreviousStep} />;
    case 2:
      return (
        <CATaxDeclaration
          onNextStep={onNextStep}
          enableFullScreen={enableFullScreen}
          disableFullScreen={disableFullScreen}
        />
      );
    case 3:
      return (
        <USTaxDeclaration
          onNextStep={onNextStep}
          enableFullScreen={enableFullScreen}
          disableFullScreen={disableFullScreen}
        />
      );
    case 4:
      return <Agreements onNextStep={onNextStep} />;
    case 5:
      return <Complete onFinish={onFinish} />;
    default:
      return null;
  }
};

export default Steps;
