import React from 'react';

import { EmptyState, Table } from './components';
import { usePaymentsList } from './hooks';
import { PaymentsListProps } from './PaymentsList.types';

const PaymentsList = ({ openBankConnectionModal }: PaymentsListProps) => {
  const { isEmpty } = usePaymentsList();

  if (isEmpty) return <EmptyState />;

  return (
    <div className="tw-mt-8">
      <Table openBankConnectionModal={openBankConnectionModal} />
    </div>
  );
};

export default PaymentsList;
