import { useMemo } from 'react';

import { formatMoneyV2 } from 'utility/currency';
import { GlobalLimitChartProps } from '../GlobalLimitChart.types';

const useGlobalLimitChart = ({ dataset, type }: Pick<GlobalLimitChartProps, 'dataset' | 'type'>) => {
  const data = useMemo(() => {
    const totalAmount = dataset.reduce((acc, item) => acc + (item.value < 0 ? 0 : item.value), 0);
    const rate = 100 / totalAmount;
    const safeRate = Number.isFinite(rate) ? rate : 1;

    return dataset.map((item) => {
      const sourceValue = item.additionalValue ?? item.value;
      const value = sourceValue < 0 ? 0 : sourceValue * safeRate;
      const label = `${formatMoneyV2({ amount: sourceValue, currency: item.currency })} ${item.currency}`;

      return {
        ...item,
        label,
        value,
      };
    });
  }, [dataset, type]);

  return {
    data,
  };
};

export default useGlobalLimitChart;
