export const HEADERS = [
  'type',
  'from',
  'to',
  'description',
  'amount',
  'currency',
  'category',
  'points',
  'status',
  'transaction date',
  'completed on',
];
