import { useContext, useState } from 'react';

import { FundsContext } from '../../../contexts/FundsContext';

const useFundsFlow = () => {
  const { error, setError } = useContext(FundsContext);

  const [currentStep, setCurrentStep] = useState(0);
  const onPrevStep = () => {
    setCurrentStep((prevStep) => (prevStep === 0 ? prevStep : prevStep - 1));
  };
  const onNextStep = (step?: number) => {
    setCurrentStep((prevStep) => (step ? step : prevStep + 1));
    setError(null);
  };
  const onResetSteps = () => {
    setCurrentStep(0);
  };

  return {
    error,
    currentStep,
    onPrevStep,
    onNextStep,
    onResetSteps,
  };
};

export default useFundsFlow;
