import { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { CURRENCIES } from 'constants/index';
import { Currencies } from 'constants/currencies';
import { AddPayeeContext } from 'components/payments/AddPayee/AddPayeeContext';

const useSupplierBankingDetails = ({ onNextStep }) => {
  const { supplierInfo, setSupplierInfo } = useContext(AddPayeeContext);

  const form = useForm({
    defaultValues: {
      ...supplierInfo,
      bankAccount: {
        ...supplierInfo.bankAccount,
        currency: Currencies.CAD,
      },
    },
  });

  const { handleSubmit, register } = form;

  const onSubmit = (data) => {
    const updatedData = {
      ...supplierInfo,
      bankAccount: {
        ...supplierInfo?.bankAccount,
        ...data?.bankAccount,
        country: data?.bankAccount?.country || data?.basedOnCurrencyCountry,
      },
    };
    setSupplierInfo(updatedData);
    onNextStep(updatedData);
  };

  const availableCurrencies = CURRENCIES;

  return {
    form,
    register,
    handleSubmit,
    onSubmit,
    availableCurrencies,
  };
};

export default useSupplierBankingDetails;
