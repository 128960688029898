import React, { useState, createContext } from 'react';
import { ApolloError } from '@apollo/client';

import { AddPayorContextType, AddPayorPayloadType } from 'components/Invoices/components/Payors/Payors.types';

export const AddPayorContext = createContext<AddPayorContextType>({} as AddPayorContextType);

export const AddPayorContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [payorInfo, setPayorInfo] = useState<AddPayorPayloadType>({
    name: '',
    email: '',
  });
  const [error, setError] = useState<ApolloError | null>(null);

  return (
    <AddPayorContext.Provider
      value={{
        payorInfo,
        setPayorInfo,
        error,
        setError,
      }}
    >
      {children}
    </AddPayorContext.Provider>
  );
};
