import React, { useState, useRef } from 'react';
import { IoTrashOutline, IoPencilOutline, IoCheckmarkOutline } from 'react-icons/io5';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { useMutation } from '@apollo/client';

import { DELETE_CORPORATE_SHAREHOLDER, DELETE_INDIVIDUAL_OWNER } from '../../../graphql/onboarding';
import { ampTrackEvent } from '../../../amplitude';
import { userRoles } from '../../../constants';
import { EditIndividualOwnerModal, ConfirmOwnerDeletionModal } from './modals';

class Owner {
  constructor({ firstName, lastName, ownership, roles = [] }) {
    this.name = `${firstName[0]}. ${lastName}`;
    this.ownership = `${ownership || 0}%`;
    this.isDirector = roles.includes(userRoles.DIRECTOR);
    this.isOwnerOrManager = roles.includes(userRoles.ACCOUNT_OWNER) || roles.includes(userRoles.ACCOUNT_MANAGER);
  }
}

const OwnerRow = ({ owner, onDelete, onEdit, loading, isPartnership }) => (
  <tr>
    <td className="tw-py-4">
      <strong className="tw-text-neutral-grey-1">{owner.name}</strong>
    </td>
    <td className="tw-py-4">{owner.ownership}</td>
    {!isPartnership && (
      <td className="tw-py-4">
        {owner.isDirector && <IoCheckmarkOutline size={24} className="tw-text-neutral-grey-1" />}
      </td>
    )}
    <td className="tw-py-4">
      <IoPencilOutline
        onClick={!loading ? onEdit : null}
        size={24}
        className={loading ? 'tw-text-neutral-grey-3' : 'tw-cursor-pointer tw-text-neutral-dark'}
      />
    </td>
    <td className="tw-py-4">
      {!owner.isOwnerOrManager && (
        <IoTrashOutline
          onClick={!loading ? onDelete : null}
          size={24}
          className={loading ? 'tw-text-neutral-grey-3' : 'tw-cursor-pointer tw-text-semantic-error'}
        />
      )}
    </td>
  </tr>
);

const CorporateOwnershipRow = ({ corporateOwnership, onOpenCorporateOwnershipModal }) => (
  <tr>
    <td className="tw-py-4">
      <strong className="tw-text-neutral-grey-1">Corporate Ownership</strong>
    </td>
    <td className="tw-py-4">{`${corporateOwnership}%`}</td>
    <td></td>
    <td className="tw-py-4">
      <IoPencilOutline
        onClick={onOpenCorporateOwnershipModal}
        size={24}
        className="tw-cursor-pointer tw-text-neutral-dark"
      />
    </td>
  </tr>
);

const OwnersTable = (props) => {
  const {
    isPartnership,
    deleteIndividualOwner,
    onDeleteOwner,
    onDeleteMethodRef,
    contacts,
    selectedContact,
    setSelectedContact,
    setSelectedOwner,
    setShowDeleteModal,
    setShowIndividualOwnerModal,
    hasCorporateOwnershipDocument,
    corporateOwnership,
    onOpenCorporateOwnershipModal,
  } = props;

  return (
    <table className="tw-hidden lg:tw-table tw-table-auto tw-w-full tw-mt-8">
      <thead>
        <tr>
          <th className="tw-font-normal tw-text-neutral-grey-2">Name</th>
          <th className="tw-font-normal tw-text-neutral-grey-2">Ownership</th>
          {!isPartnership && <th className="tw-font-normal tw-text-neutral-grey-2">Is Director</th>}
        </tr>
      </thead>
      <tbody>
        {contacts.map((contact) => {
          const owner = new Owner(contact);
          const onDelete = async () => {
            try {
              await deleteIndividualOwner({ variables: { contactId: contact.id } });

              ampTrackEvent('onboarding: delete_individual_owner: success');
              onDeleteOwner();
            } catch (err) {
              setSelectedContact(null);
              console.error(err);
            }
          };

          const handleDelete = () => {
            setSelectedOwner(owner);
            onDeleteMethodRef.current = onDelete;
            setShowDeleteModal(true);
          };

          const onEdit = () => {
            setSelectedContact(contact);
            setShowIndividualOwnerModal(true);
          };

          return (
            <OwnerRow
              key={contact.id}
              onDelete={handleDelete}
              onEdit={onEdit}
              loading={selectedContact && selectedContact.id === contact.id}
              owner={owner}
              isPartnership={isPartnership}
            />
          );
        })}
        {hasCorporateOwnershipDocument && (
          <CorporateOwnershipRow
            corporateOwnership={corporateOwnership}
            onOpenCorporateOwnershipModal={onOpenCorporateOwnershipModal}
          />
        )}
      </tbody>
    </table>
  );
};

const PartnerCard = ({ owner, onDelete, onEdit, loading }) => (
  <div className="tw-w-full tw-border tw-border-neutral-grey-3 tw-p-4 tw-mb-4 tw-rounded-md">
    <strong className="tw-text-neutral-grey-1">{owner.name}</strong>
    <div className="tw-flex tw-justify-between">
      <div className="tw-flex tw-my-4">
        <p className="tw-mr-4 tw-text-neutral-grey-2">Ownership %</p>
        <p>{owner.ownership}</p>
      </div>
      <div className="tw-flex">
        <IoPencilOutline
          onClick={!loading ? onEdit : null}
          size={24}
          className={loading ? 'tw-text-neutral-grey-3' : 'tw-cursor-pointer tw-text-neutral-dark'}
        />
        {!owner.isOwnerOrManager && (
          <IoTrashOutline
            onClick={!loading ? onDelete : null}
            size={24}
            className={`${loading ? 'tw-text-neutral-grey-3' : 'tw-cursor-pointer tw-text-semantic-error'} tw-ml-4`}
          />
        )}
      </div>
    </div>
  </div>
);

const OwnerCard = ({ owner, onDelete, onEdit, loading }) => (
  <div className="tw-w-full tw-border tw-border-neutral-grey-3 tw-p-4 tw-mb-4 tw-rounded-md">
    <strong className="tw-text-neutral-grey-1">{owner.name}</strong>
    <div className="tw-flex tw-my-4">
      <p className="tw-mr-4 tw-text-neutral-grey-2">Ownership %</p>
      <p data-testid="ownership-cell">{owner.ownership}</p>
    </div>
    <div className="tw-flex tw-justify-between">
      <div className="tw-flex tw-items-center">
        <p className="tw-mr-4 tw-text-neutral-grey-2">Is a Director</p>
        <div className={`tw-w-16 tw-rounded-md tw-flex tw-items-center tw-p-2`}>
          {owner.isDirector ? (
            <BsCheckCircle className="tw-text-primary-dark-green mr-2" />
          ) : (
            <BsXCircle className="tw-text-semantic-error mr-2" />
          )}
          <small>{owner.isDirector ? 'Yes' : 'No'}</small>
        </div>
      </div>
      <div className="tw-flex tw-items-center">
        <IoPencilOutline
          onClick={!loading ? onEdit : null}
          size={24}
          className={loading ? 'tw-text-neutral-grey-3' : 'tw-cursor-pointer tw-text-neutral-dark'}
        />
        {!owner.isOwnerOrManager && (
          <IoTrashOutline
            onClick={!loading ? onDelete : null}
            size={24}
            className={`${loading ? 'tw-text-neutral-grey-3' : 'tw-cursor-pointer tw-text-semantic-error'} tw-ml-4`}
          />
        )}
      </div>
    </div>
  </div>
);

const OwnersCards = (props) => {
  const {
    isPartnership,
    deleteIndividualOwner,
    onDeleteOwner,
    onDeleteMethodRef,
    contacts,
    selectedContact,
    setSelectedContact,
    setSelectedOwner,
    setShowDeleteModal,
    setShowIndividualOwnerModal,
    hasCorporateOwnershipDocument,
    corporateOwnership,
    onOpenCorporateOwnershipModal,
  } = props;

  const Component = isPartnership ? PartnerCard : OwnerCard;

  return (
    <div className="tw-flex lg:tw-hidden tw-flex-col tw-mt-8">
      {contacts.map((contact) => {
        const owner = new Owner(contact);
        const onDelete = async () => {
          try {
            await deleteIndividualOwner({ variables: { contactId: contact.id } });

            ampTrackEvent('onboarding: delete_individual_owner: success');
            onDeleteOwner();
          } catch (err) {
            setSelectedContact(null);
            console.error(err);
          }
        };

        const handleDelete = () => {
          setSelectedOwner(owner);
          onDeleteMethodRef.current = onDelete;
          setShowDeleteModal(true);
        };

        const onEdit = () => {
          setSelectedContact(contact);
          setShowIndividualOwnerModal(true);
        };

        return (
          <Component
            key={contact.id}
            onDelete={handleDelete}
            onEdit={onEdit}
            loading={selectedContact && selectedContact.id === contact.id}
            owner={owner}
          />
        );
      })}
      {hasCorporateOwnershipDocument && (
        <CorporateOwnershipCard
          corporateOwnership={corporateOwnership}
          onOpenCorporateOwnershipModal={onOpenCorporateOwnershipModal}
        />
      )}
    </div>
  );
};

const CorporateOwnershipCard = ({ corporateOwnership, onOpenCorporateOwnershipModal }) => {
  return (
    <div className="tw-w-full tw-border tw-border-neutral-grey-3 tw-p-4 tw-mb-4 tw-rounded-md">
      <strong className="tw-text-neutral-grey-1">Corporate Ownership</strong>
      <div className="tw-flex tw-justify-between tw-items-center tw-mt-4">
        <p className="tw-text-neutral-grey-2">{`${corporateOwnership}%`}</p>
        <IoPencilOutline
          onClick={onOpenCorporateOwnershipModal}
          size={24}
          className="tw-cursor-pointer tw-text-neutral-dark"
        />
      </div>
    </div>
  );
};

const AddedOwners = ({
  contacts,
  refetchContacts,
  isPartnership,
  hasCorporateOwnershipDocument,
  corporateOwnership,
  onOpenCorporateOwnershipModal,
}) => {
  const [showIndividualOwnerModal, setShowIndividualOwnerModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndividualOwner, { loading: isDeletingIndividual }] = useMutation(DELETE_INDIVIDUAL_OWNER);
  const [deleteCorporateShareholder, { loading: isDeletingShareholder }] = useMutation(DELETE_CORPORATE_SHAREHOLDER);
  const [selectedContact, setSelectedContact] = useState();
  const [selectedOwner, setSelectedOwner] = useState();
  const onDeleteMethodRef = useRef();

  const onCloseModal = () => {
    setSelectedContact(null);
    setSelectedOwner(null);
    onDeleteMethodRef.current = null;
  };

  const onDeleteOwner = () => {
    refetchContacts();
    setSelectedContact(null);
    setShowDeleteModal(false);
  };

  const contentProps = {
    isPartnership,
    deleteIndividualOwner,
    deleteCorporateShareholder,
    onDeleteOwner,
    onDeleteMethodRef,
    contacts,
    selectedContact,
    setSelectedContact,
    setSelectedOwner,
    setShowDeleteModal,
    setShowIndividualOwnerModal,
    hasCorporateOwnershipDocument,
    corporateOwnership,
    onOpenCorporateOwnershipModal,
  };

  return (
    <div className="tw-mt-8">
      <strong>Already added:</strong>
      <OwnersTable {...contentProps} />
      <OwnersCards {...contentProps} />
      <EditIndividualOwnerModal
        onSuccess={refetchContacts}
        contact={selectedContact}
        show={showIndividualOwnerModal}
        setShow={setShowIndividualOwnerModal}
        onClose={onCloseModal}
        isPartnership={isPartnership}
      />
      <ConfirmOwnerDeletionModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        onClose={onCloseModal}
        onConfirm={onDeleteMethodRef.current}
        owner={selectedOwner}
        loading={isDeletingIndividual || isDeletingShareholder}
      />
    </div>
  );
};

export default AddedOwners;
