import React from 'react';

import { SelectAccountOptionsProps } from './SelectAccountOptions.types';
import { Select } from 'components/FormFields/v2';

const SelectAccountOptions = ({ options, name, label, register, onChange }: SelectAccountOptionsProps) => {
  return (
    <Select
      options={options}
      name={name}
      label={label}
      placeholder="Choose"
      ref={register({ required: true })}
      rootClass="tw-mt-6 tw-mb-4"
      defaultValue={options.length ? options[0].value : ''}
      onChange={onChange}
    />
  );
};

export default SelectAccountOptions;
