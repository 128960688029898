import { useState, useEffect, useCallback } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import qs from 'query-string';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { ampTrackEvent } from 'amplitude';

import useSetPageTitle from 'hooks/useSetPageTitle';
import { SupplierStatus, SupplierType } from 'types/payees';
import { REQUESTED_SUPPLIER, ADD_REQUESTED_SUPPLIER_BANK_ACCOUNT } from 'graphql/requestedSupplier';
import { RequestedSupplier } from 'types/requestedSupplier';
import { capitalize } from 'utility/string';
import { FromBankDetails } from '../SupplierBankDetails.types';

const useSupplierBankDetails = () => {
  const { sgid: signedId } = qs.parse(location.search) as {
    sgid?: string;
  };

  useSetPageTitle('Payment Details Form');

  const [getRequestedSupplier, { data, loading: isSupplierDataLoading, error: supplierDataError }] = useLazyQuery<{
    requestedSupplier: RequestedSupplier;
  }>(REQUESTED_SUPPLIER);

  const [addSupplierBankAccount, { loading: isSubmitting, error: submitErrors }] = useMutation<{
    addRequestedSupplierBankAccount: Pick<RequestedSupplier, 'id' | 'address' | 'bankAccount'>;
  }>(ADD_REQUESTED_SUPPLIER_BANK_ACCOUNT);

  const [isCompleted, setIsCompleted] = useState(false);
  const [sumbittedBankDetails, setSubmittedBankDetails] = useState<RequestedSupplier | undefined>(undefined);

  const borrowerName = get(data, 'requestedSupplier.borrowerName');
  const payeeName = get(data, 'requestedSupplier.name');
  const payeeEmail = get(data, 'requestedSupplier.email');
  const payeeType = get(data, 'requestedSupplier.type');
  const payeeStatus = get(data, 'requestedSupplier.status');
  const isBankDetailsRequested = payeeStatus === SupplierStatus.bank_details_requested;
  const addressLabel = payeeType === SupplierType.company ? 'Business Address' : 'Personal Address';

  useEffect(() => {
    if (!signedId) {
      toast.error('Invalid invitation. This invitation is expired or no longer valid.', { autoClose: false });
      return;
    }

    getRequestedSupplier({ variables: { signedId } });
  }, [getRequestedSupplier, signedId]);

  useEffect(() => {
    if (payeeStatus && !isBankDetailsRequested) {
      toast.warn('Bank details for this supplier were already provided.', {
        autoClose: false,
      });
    }
  }, [payeeStatus, isBankDetailsRequested]);

  useEffect(() => {
    if (supplierDataError?.message) toast.error(supplierDataError.message);
  }, [supplierDataError, isBankDetailsRequested]);

  const submitBankDetails = useCallback(
    async (data: FromBankDetails) => {
      const processingToast = toast.loading('Submitting bank details...');

      const { address, bankAccount, basedOnCurrencyCountry } = data;
      const payload = {
        address,
        bankAccount: {
          ...bankAccount,
          country: bankAccount?.country || basedOnCurrencyCountry,
        },
        signedId,
      };

      try {
        const response = await addSupplierBankAccount({ variables: payload });

        const supplier = get(response, 'data.addRequestedSupplierBankAccount');

        if (!supplier) throw new Error('Something went wrong. Please try again.');

        setSubmittedBankDetails(supplier);
        setIsCompleted(true);

        toast.update(processingToast, {
          render: 'Bank details were added successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });

        ampTrackEvent('Supplier: bank details added by payee: success');
      } catch (error) {
        toast.update(processingToast, {
          render: 'Something went wrong. Please try again.',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
        console.error(error);
        ampTrackEvent('Supplier: bank details added by payee: failed');
      }
    },
    [setIsCompleted, signedId]
  );

  return {
    isLinkExpired: (!signedId || !isBankDetailsRequested) && !supplierDataError,
    isCompleted,
    isSupplierDataLoading,
    isSupplierDataError: !!supplierDataError,
    borrowerName,
    payeeName,
    payeeEmail,
    payeeType: payeeType ? capitalize(payeeType) : payeeType,
    addressLabel,
    submitBankDetails,
    submitErrors,
    isSubmitting,
    sumbittedBankDetails,
  };
};

export default useSupplierBankDetails;
