import React from 'react';

import { BankAccountCurrency } from 'types/bankAccount';

import { Modal } from 'components/UI';
import BankAccountConnection from 'components/BankAccountConnection';
import { BankConnectionModalProps } from './BankConnectionModal.types';

const BankConnectionModal = ({ show, onClose, currency, isManualForm }: BankConnectionModalProps) => {
  return (
    <Modal show={show} onClose={onClose} title="Connect your Bank Account">
      <div className="tw-px-8">
        <BankAccountConnection
          currency={currency as unknown as BankAccountCurrency}
          onCancel={onClose}
          onSubmit={onClose}
          isManualForm={isManualForm}
          isPayor={true}
        />
      </div>
    </Modal>
  );
};

export default BankConnectionModal;
