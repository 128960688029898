import { useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { CreateCardContext } from 'components/creditCards/components/CreateCardModal/context/CreateCardContext';
import { Address as CardAddress } from 'types/shared';
import { GET_BUSINESS_ADDRESS } from 'graphql/user';
import { CardDeliveryAddressFormData } from '../CardDeliveryAddress.types';

const useCardDeliveryAddress = () => {
  const { data, loading } = useQuery(GET_BUSINESS_ADDRESS);
  const { createCardFormDataRef, onNextStep, onPrevStep, currentStep, steps } = useContext(CreateCardContext);

  const address = get(data, 'me.account.address');
  const { street, unitNumber, city, postalCode, countrySubdivision, country = 'CA' } = address || {};

  const form = useForm<CardAddress>({
    defaultValues: {
      street: street || '',
      city: city || '',
      countrySubdivision: countrySubdivision || '',
      postalCode: postalCode || '',
      country,
      unitNumber: unitNumber || '',
    },
  });

  const { handleSubmit } = form;

  const handleSubmitForm = useCallback(
    async (data: CardAddress) => {
      createCardFormDataRef.current = {
        ...createCardFormDataRef.current,
        address: data,
      };

      onNextStep();
    },
    [onNextStep]
  );

  const handleOnNextStep = (data: CardDeliveryAddressFormData) => handleSubmitForm(data.address);

  const handleOnPreviousStep = () => {
    form.reset();
    onPrevStep();
  };

  return {
    address,
    country,
    currentStep,
    steps,
    form,
    handleOnNextStep,
    handleOnPreviousStep,
    handleSubmit,
    loading,
  };
};

export default useCardDeliveryAddress;
