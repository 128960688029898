import React from 'react';

const GiftOutline = ({ size = 21, ...props }) => {
  const width = size;
  const height = (22 * width) / 21;
  return (
    <svg width={width} height={height} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.5 4.76562V7.06249H12.7969C13.2512 7.06249 13.6952 6.92779 14.0729 6.6754C14.4507 6.42302 14.7451 6.0643 14.9189 5.6446C15.0927 5.2249 15.1382 4.76308 15.0496 4.31753C14.961 3.87198 14.7422 3.46271 14.421 3.14149C14.0998 2.82027 13.6905 2.60151 13.245 2.51288C12.7994 2.42426 12.3376 2.46975 11.9179 2.64359C11.4982 2.81743 11.1395 3.11183 10.8871 3.48955C10.6347 3.86727 10.5 4.31134 10.5 4.76562V4.76562Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.5 4.76562C10.5 5.39972 10.5 7.06249 10.5 7.06249H8.20313C7.74885 7.06249 7.30477 6.92779 6.92705 6.6754C6.54933 6.42302 6.25494 6.0643 6.08109 5.6446C5.90725 5.2249 5.86176 4.76308 5.95038 4.31753C6.03901 3.87198 6.25777 3.46271 6.57899 3.14149C6.90021 2.82027 7.30948 2.60151 7.75503 2.51288C8.20058 2.42426 8.6624 2.46975 9.0821 2.64359C9.5018 2.81743 9.86052 3.11183 10.1129 3.48955C10.3653 3.86727 10.5 4.31134 10.5 4.76562V4.76562Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.0625 7.0625H3.9375C3.21263 7.0625 2.625 7.65013 2.625 8.375V10.3438C2.625 11.0686 3.21263 11.6562 3.9375 11.6562H17.0625C17.7874 11.6562 18.375 11.0686 18.375 10.3438V8.375C18.375 7.65013 17.7874 7.0625 17.0625 7.0625Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0625 11.6562V17.5625C17.0625 18.0846 16.8551 18.5854 16.4859 18.9546C16.1167 19.3238 15.6159 19.5312 15.0938 19.5312H5.90625C5.38411 19.5312 4.88335 19.3238 4.51413 18.9546C4.14492 18.5854 3.9375 18.0846 3.9375 17.5625V11.6562"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.5 7.0625V19.5312" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default GiftOutline;
