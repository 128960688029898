import React, { useMemo } from 'react';
import { CiMoneyBill } from 'react-icons/ci';
import { BiArrowBack } from 'react-icons/bi';
import { Link, useHistory } from 'react-router-dom';

import useIsMobile from 'hooks/useIsMobile';
import useIsMember from 'hooks/useIsMember';
import useGetProductState from 'hooks/useGetProductState';
import CardOutline from 'components/svg/CardOutline';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import { CardLinks } from './components';
import { CardLinksProps } from './components/CardLinks/CardLinks.types';

const CardsSplash = () => {
  const isMobile = useIsMobile();
  const history = useHistory();

  const { isMember } = useIsMember();

  const { isPreFunded } = useGetProductState();

  const cardLinks = useMemo(
    () =>
      [
        !isMember && {
          label: isPreFunded ? 'Balances' : 'Balances and Limit',
          url: '/dashboard/cards/balances',
          Icon: CiMoneyBill,
        },
        {
          label: 'View Cards',
          url: '/dashboard/cards/list',
          Icon: CardOutline,
        },
      ].filter(Boolean) as CardLinksProps['items'],
    [isMember]
  );

  if (!isMobile) history.push('/dashboard/home');

  return (
    <DashboardLayout title="Cards">
      <Link to="/dashboard/home" className="tw-cursor-pointer tw-mb-3">
        <BiArrowBack size={24} />
      </Link>
      <h2 className="tw-mb-4">Cards</h2>
      <CardLinks items={cardLinks} />
    </DashboardLayout>
  );
};

export default CardsSplash;
