import React from 'react';

import { Loaders } from 'components/cards/Loader';

import { BankConnectionModal, BannersSection, PaymentsList } from './components';
import { usePaymentRequestsContent } from './hooks';

const PaymentRequestsContent = () => {
  const {
    noBankAccounts,
    requiredCADBankAccount,
    requiredUSDBankAccount,
    isBankAccountsConnectionError,
    openBankConnectionModal,
    isOpenBankConnectionModal,
    closeBankConnectionModal,
    isLoading,
    selectedCurrency,
  } = usePaymentRequestsContent();

  if (isLoading) return <Loaders.Light />;

  return (
    <>
      <BannersSection onClick={openBankConnectionModal} />

      {(isBankAccountsConnectionError || noBankAccounts || requiredCADBankAccount || requiredUSDBankAccount) && (
        <BankConnectionModal
          show={isOpenBankConnectionModal}
          onClose={closeBankConnectionModal}
          isManualForm={isBankAccountsConnectionError}
          currency={selectedCurrency}
        />
      )}

      <PaymentsList openBankConnectionModal={openBankConnectionModal} />
    </>
  );
};

export default PaymentRequestsContent;
