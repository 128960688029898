import React from 'react';

import Button from 'components/Button';
import Close from 'components/svg/Close';
import { Modal } from 'components/UI';
import useConfirmActionModal from './hooks/useConfirmActionModal';
import { ConfirmActionModalType } from './ConfirmActionModal.types';

const ConfirmActionModal = ({
  show,
  onClose,
  creditCardId,
  status,
  title,
  description,
  confirmLabel,
}: ConfirmActionModalType) => {
  const { onConfirm, loading } = useConfirmActionModal({ creditCardId, status, onClose });
  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4">
        {title}
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-px-8 tw-py-8 tw-border-t tw-border-b tw-border-neutral-grey-4">
        <p>{description}</p>
      </div>
      <div className="tw-flex tw-justify-end tw-px-8 tw-py-6">
        <Button
          className={`${
            loading ? 'tw-bg-neutral-grey-3' : 'tw-bg-primary-dark-green'
          } tw-px-4 tw-py-2 tw-rounded-md tw-ml-8 tw-text-neutral-light tw-flex tw-justify-center tw-items-center`}
          onClick={onConfirm}
          isFetching={loading}
        >
          <div className="tw-flex tw-justify-center tw-items-center">{loading ? 'Submitting...' : confirmLabel}</div>
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmActionModal;
