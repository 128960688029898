import React from 'react';
import { useQuery } from '@apollo/client';

import SelectAccountType from '../components/onboarding/SelectAccountType';
import { PreApprovalContextProvider } from '../context/PreApproval';
import { GET_EXTERNAL_ACCOUNTS } from '../graphql/integrations';
import useOnboardingRedirect from '../hooks/useOnboardingRedirect';

const SelectAccountTypeContainer = () => {
  const { loading: loadingExternalAccounts, data, refetch } = useQuery(GET_EXTERNAL_ACCOUNTS);
  const { loading } = useOnboardingRedirect();
  const { externalAccounts } = data || {};

  return (
    <PreApprovalContextProvider>
      <SelectAccountType
        externalAccounts={externalAccounts || []}
        loadingExternalAccounts={loading || loadingExternalAccounts}
        reload={refetch}
      />
    </PreApprovalContextProvider>
  );
};

export default SelectAccountTypeContainer;
