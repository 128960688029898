import React from 'react';
import cx from 'classnames';

import useIsMobile from 'hooks/useIsMobile';
import { TransactionsContextProvider } from 'context/Transactions';
import { FilterProvider } from './context/FilterContext';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import { TransactionCategoryModal, TransactionsContent } from './components';

const Transactions = ({ location }) => {
  const isMobile = useIsMobile();

  return (
    <DashboardLayout title="Transactions" className="tw-position-relative">
      <TransactionsContextProvider location={location}>
        <FilterProvider>
          <div className={cx('tw-flex', isMobile && 'tw-flex-col tw-px-4')}>
            <TransactionsContent />
            <TransactionCategoryModal />
          </div>
        </FilterProvider>
      </TransactionsContextProvider>
    </DashboardLayout>
  );
};

export default Transactions;
