import { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { SET_USER_PERMISSIONS } from 'graphql/user';
import { UPDATE_CONTACT_LIMIT_RULES } from 'graphql/team';
import { deHumanizePermission } from '../../utils';
import { toast } from 'react-toastify';
import { formatMoneyV2, centsFromMoneyString } from 'utility/currency';
import { TeamMember, InternalContactRole } from 'types/user';
import { getEntityType } from 'utility/apollo';
import { getPermissionSet } from '../SetUserPermissionsForm.utils';
import { TeamContext } from 'components/settings/Team/TeamContext';

const formattedPermissions = (allMembers: TeamMember[]): { name: string; value: string }[] => {
  return allMembers?.map((member) => ({
    name: `${member.fullName} (${member.role})`,
    value: member.fullName,
  }));
};

const defaultValue = [{ name: 'None', value: 'None' }];

const useSetUserPermissionsForm = ({
  role,
  memberId,
  onSuccess,
  memberPermissions,
  allMembers,
}: {
  role: InternalContactRole;
  memberId: string;
  onSuccess: () => void;
  allMembers: TeamMember[];
  memberPermissions: string[] | undefined;
}) => {
  const form = useForm();
  const { handleSubmit, register } = form;
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [setUserPermissions, { loading, error }] = useMutation(SET_USER_PERMISSIONS);
  const hasRequestError = error && !error.graphQLErrors;
  const [isCardsChecked, setIsCardsChecked] = useState(false);
  const [isAccountsChecked, setIsAccountsChecked] = useState(false);
  const [isPaymentsChecked, setIsPaymentsChecked] = useState(false);
  const [isTeamsAndSettingsChecked, setIsTeamsAndSettingsChecked] = useState(false);
  const [isBillingChecked, setIsBillingChecked] = useState(false);
  const [importedMember, setImportedMember] = useState<TeamMember | undefined>();
  const [updateContactLimitRules] = useMutation(UPDATE_CONTACT_LIMIT_RULES);

  const member = allMembers?.find((member) => member.id === memberId);
  const [formattedAmount, setFormattedAmount] = useState(
    formatMoneyV2({ amount: member?.rules.paymentApprovalAmount?.amount, currency: 'CAD' })
  );
  const { setMember } = useContext(TeamContext) as unknown as {
    setMember: React.Dispatch<React.SetStateAction<TeamMember | null>>;
  };

  useEffect(() => {
    const permissionSet = getPermissionSet(role).map((permission) => permission.value);
    const fullPermissionSet = permissionSet.concat(memberPermissions || []);
    const importedPermissions = importedMember?.permissions;
    const fullPermissionSetWithImported = fullPermissionSet.concat(importedPermissions || []);
    setSelectedPermissions(fullPermissionSetWithImported);
  }, []);

  const isPermissionChecked = (permission: string) => {
    return selectedPermissions?.includes(permission);
  };

  const options = defaultValue.concat(formattedPermissions(allMembers));

  const onSelectImportMember = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === defaultValue[0].value) {
      setImportedMember(undefined);
      setSelectedPermissions([]);
      return;
    }
    const selectedMember = allMembers?.find((member) => member.fullName === event.target.value);
    setImportedMember(selectedMember);
    setSelectedPermissions(selectedMember?.permissions || []);
  };

  const isMemberContact = member?.id.includes('/Contact/');

  const handleUpdateContactLimitRules = async () => {
    if (!isMemberContact) return true;
    try {
      const formattedIndividualLimit = centsFromMoneyString(formattedAmount);
      const result = await updateContactLimitRules({
        variables: { contactId: memberId, individualLimit: { amount: formattedIndividualLimit, currency: 'CAD' } },
      });
      if (!result.data?.updateContactLimitRules) {
        return false;
      }
      return true;
    } catch (err) {
      return false;
    }
  };

  const onSubmit = async () => {
    const submitToast = toast.loading('Saving permissions...');

    const updateContactLimitRulesResult = await handleUpdateContactLimitRules();

    if (!updateContactLimitRulesResult) {
      toast.update(submitToast, {
        render: 'Error Saving Permissions',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    const formattedPermissions = selectedPermissions.map((permission) => deHumanizePermission(permission));
    const idInfo = getEntityType(memberId);
    try {
      if (idInfo.idType === 'TeamInvitation') {
        const result = await setUserPermissions({
          variables: {
            permissions: formattedPermissions,
            teamInvitationId: idInfo.id,
          },
        });
        if (result.data && result.data.setUserPermissions) {
          setMember(null);
          onSuccess();
          toast.update(submitToast, {
            render: 'Contact permissions updated successfully.',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
        }
      } else if (idInfo.idType === 'Contact') {
        const result = await setUserPermissions({
          variables: {
            permissions: formattedPermissions,
            contactId: idInfo.id,
          },
        });
        if (result.data && result.data.setUserPermissions) {
          setMember(null);
          onSuccess();
          toast.update(submitToast, {
            render: 'Contact permissions updated successfully.',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          throw new Error('Failed to save permissions');
        }
      }
    } catch {
      toast.update(submitToast, {
        render: 'Failed to save permissions',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return {
    form,
    register,
    handleSubmit,
    onSubmit,
    setSelectedPermissions,
    isPermissionChecked,
    selectedPermissions,
    isCardsChecked,
    setIsCardsChecked,
    isAccountsChecked,
    setIsAccountsChecked,
    isPaymentsChecked,
    setIsPaymentsChecked,
    isTeamsAndSettingsChecked,
    setIsTeamsAndSettingsChecked,
    isBillingChecked,
    setIsBillingChecked,
    hasRequestError,
    loading,
    onSelectImportMember,
    options,
    formattedAmount,
    setFormattedAmount,
    isMemberContact,
  };
};

export default useSetUserPermissionsForm;
