import React from 'react';
import { get } from 'lodash';

import BasePayeeItem from './BasePayeeItem';
import { CRA_ACCOUNT_PAYEE_DISPLAY_NAME } from '../../constants';

const DetailsSection = ({ name, currency }) => (
  <div className="tw-my-6 tw-overflow-hidden">
    <div className="tw-w-1/2 tw-float-left">
      <div className="tw-text-sm tw-text-neutral-grey-1">Name</div>
      <div>{name}</div>
    </div>
    <div className="tw-w-1/2 tw-float-right">
      <div className="tw-text-sm tw-text-neutral-grey-1">Currency</div>
      <div>{currency}</div>
    </div>
  </div>
);

const CRAAccountItem = ({ payee, onPayPayee }) => {
  const craAccount = get(payee, 'record', {});
  const { displayName, currency, verified: isVerified } = craAccount;

  return (
    <BasePayeeItem
      payee={payee}
      onPayPayee={onPayPayee}
      payeeName={CRA_ACCOUNT_PAYEE_DISPLAY_NAME}
      payeeCurrency={currency}
      isVerified={isVerified}
      isDeletable={true}
    >
      <DetailsSection name={displayName} currency={currency} />
    </BasePayeeItem>
  );
};

export default CRAAccountItem;
