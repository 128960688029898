import React from 'react';

import { CompletedProps } from './Completed.types';

const Completed = ({ borrowerName, businessAddress, bankDetails, addressLabel }: CompletedProps) => {
  return (
    <>
      <div className="tw-text-neutral-grey-1 tw-mb-2 tw-text-sm">Banking Details submitted</div>
      <ul className="tw-flex tw-flex-col tw-gap-2 tw-bg-neutral-grey-4 tw-px-8 2xl:tw-px-16 tw-py-4 tw-w-full tw-rounded-md tw-mb-6 tw-text-sm">
        {businessAddress && (
          <li className="tw-flex tw-gap-2">
            <div className="tw-w-2/5">{addressLabel}:</div>
            <div className="tw-w-3/5">{businessAddress}</div>
          </li>
        )}
        {Object.entries(bankDetails).map(
          ([key, value]) =>
            value && (
              <li className="tw-flex tw-gap-2" key={key}>
                <div className="tw-w-2/5">{key}:</div>
                <div className="tw-w-3/5">{value}</div>
              </li>
            )
        )}
      </ul>
      <div className="tw-bg-secondary-pastel-green-80 tw-w-full tw-rounded-md tw-p-4">
        <h3 className="tw-mb-4">Payment Details Successfully Submitted!</h3>
        <div>{borrowerName} will now be able to send payments directly to the account information you submitted.</div>
      </div>
    </>
  );
};

export default Completed;
