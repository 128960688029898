import React, { useContext, useState } from 'react';

import { AuthContext } from '../../context/Auth';
import { SubmitButton } from '../FormFields/v2';
import { Forms } from 'components/UI';
import Layout from './Layout';

const { MFACodeInput } = Forms;

const totalDigit = 6;

const SMSCodeForm = () => {
  const [code, setCode] = useState('');
  const [smsCodeResent, setSMSCodeResent] = useState(false);
  const {
    mfaCodeRequested,
    smsCodeRequestedNumber,
    resendVerificationCode,
    smsCodeError,
    smsCodeVerifying,
    confirmSignIn,
  } = useContext(AuthContext);
  const nonEmptyDigits = (code && code.replace(/\s/g, '').length) || 0;
  const buttonLabel =
    nonEmptyDigits > 0
      ? (nonEmptyDigits === totalDigit && 'Login') || `Enter ${totalDigit - nonEmptyDigits} digit more`
      : `Enter ${totalDigit} digits`;

  const onSubmit = async () => {
    confirmSignIn(code);
  };

  const handleUpdateCode = (code) => {
    setCode(code);
  };

  const handleClickResend = () => {
    resendVerificationCode();
    setSMSCodeResent(true);
  };

  return (
    <Layout>
      <h1 className="tw-mb-3">Welcome back</h1>
      <p className="tw-mb-8">
        {mfaCodeRequested
          ? `Enter ${totalDigit}-digit code from your authenticator app`
          : `Enter ${totalDigit}-digit code we’ve sent to ∗∗∗ - ∗∗∗ - ${(smsCodeRequestedNumber || '').slice(-4)}`}
      </p>

      <MFACodeInput
        code={code}
        totalLength={totalDigit}
        onUpdate={handleUpdateCode}
        onSubmit={onSubmit}
        isLoading={smsCodeVerifying}
        isError={smsCodeError}
      />
      {!mfaCodeRequested && <p className="tw-mt-8 tw-mb-2">Didn’t receive a code within 60 seconds?</p>}
      {smsCodeResent ? (
        <p className="tw-text-semantic-success">Code sent!</p>
      ) : (
        <div onClick={handleClickResend} className="tw-cursor-pointer">
          {!mfaCodeRequested && <strong className="tw-text-primary-dark-green">Resend the code</strong>}
        </div>
      )}
      <SubmitButton
        onClick={onSubmit}
        type="submit"
        className="tw-w-full tw-font-bold tw-mt-8"
        tabIndex={2}
        isFetching={smsCodeVerifying}
        disabled={
          !code || nonEmptyDigits < totalDigit || (nonEmptyDigits === totalDigit && smsCodeError) || smsCodeVerifying
        }
      >
        {smsCodeVerifying ? 'Verifying' : buttonLabel}
      </SubmitButton>
    </Layout>
  );
};

export default SMSCodeForm;
