import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Modal, FormFooter } from 'components/UI';
import { SpendingLimits } from 'components/creditCards/components/cardsSettings';
import { EditSpendingLimitsModalProps } from './EditSpendingLimitsModal.types';
import { useEditSpendingLimitsModal } from './hooks';

const EditSpendingLimitsModal = ({
  isOpenModal,
  closeModal,
  handleUpdateSettings,
  isLoading,
  limitType,
  limitAmount,
  currency,
}: EditSpendingLimitsModalProps) => {
  const { handleCloseModal, form, handleSubmit, onSubmit } = useEditSpendingLimitsModal({
    closeModal,
    handleUpdateSettings,
    limitType,
    limitAmount,
    currency,
  });

  return (
    <Modal show={isOpenModal} title="Edit Limits" onClose={handleCloseModal}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-pt-4 tw-pb-4 tw-px-8">
            <SpendingLimits limitType={limitType} limitAmount={limitAmount} />
          </div>

          <FormFooter
            submitButtonLabel="Update"
            cancelButtonLabel="Cancel"
            onCancel={handleCloseModal}
            loading={isLoading}
            rootClass="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-8"
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditSpendingLimitsModal;
