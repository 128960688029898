import React from 'react';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { BsClockHistory } from 'react-icons/bs';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import cx from 'classnames';

import { RESEND_GUARANTOR_CONSENT_FORM, GET_KYC_AND_CREDIT_ASSESSMENT_STATUS } from 'graphql/onboarding';
import { Loaders } from 'components/cards/Loader';
import useIsMobile from 'hooks/useIsMobile';

const getOwner = ({
  id,
  name,
  firstName,
  lastName,
  creditCheckAuthorizationProvided,
  creditCheckAuthorizationSent,
  creditCheckAuthorizationSentAt,
  creditCheckAuthorizationExpired,
  thirdPartyVerificationComplete,
  email,
}) => {
  return {
    id,
    name: getBusinessName({ firstName, lastName, businessName: name }),
    creditCheckAuthorizationProvided,
    creditCheckAuthorizationSent,
    creditCheckAuthorizationSentAt,
    creditCheckAuthorizationExpired,
    thirdPartyVerificationComplete,
    email,
  };
};

const getBusinessName = ({ firstName, lastName, businessName }) =>
  `${firstName[0]}. ${lastName} ${businessName ? '(' + businessName + ')' : ''}`;

const RegistryOwnerRow = ({ owner }) => {
  const isMobile = useIsMobile();
  const [resendConsentForm, { loading, error }] = useMutation(RESEND_GUARANTOR_CONSENT_FORM, {
    refetchQueries: [{ query: GET_KYC_AND_CREDIT_ASSESSMENT_STATUS }],
    awaitRefetchQueries: true,
  });

  const onResendConsentForm = async () => {
    try {
      await resendConsentForm({
        variables: {
          contactId: owner.id,
        },
      });
      toast.success('Director Consent Form resent successfully');
    } catch (err) {
      console.error(error);
      toast.error('Error sending email - please try again');
    }
  };

  const consentProvided = owner.creditCheckAuthorizationProvided;
  const consentPending =
    !owner.creditCheckAuthorizationProvided &&
    owner.creditCheckAuthorizationSent &&
    !owner.creditCheckAuthorizationExpired;
  const consentExpired =
    !owner.creditCheckAuthorizationProvided &&
    owner.creditCheckAuthorizationSent &&
    owner.creditCheckAuthorizationExpired;
  const identityVerified = owner.thirdPartyVerificationComplete;

  return (
    <tr>
      <td className="tw-pt-2">
        <strong className="tw-text-neutral-grey-1 tw-text-ellipsis tw-overflow-hidden tw-pr-2">{owner.name}</strong>
      </td>
      <td
        className={cx('tw-text-ellipsis tw-pt-2 tw-pr-2', isMobile && 'tw-overflow-y-scroll tw-scrollbar-hide')}
        title={owner.email}
      >
        {owner.email}
      </td>
      <td className="tw-pt-2">
        {consentProvided && <IoCheckmarkCircleOutline className="tw-text-primary-dark-green tw-mx-auto" size={24} />}
        {consentPending && <BsClockHistory className="tw-text-semantic-error tw-mx-auto" size={20} />}
        {loading ? (
          <div className="tw-flex tw-justify-center tw-items-center">
            <Loaders.Spinner />
          </div>
        ) : (
          consentExpired && (
            <div onClick={onResendConsentForm} className="tw-text-center tw-cursor-pointer tw-text-primary-dark-green">
              <span className="tw-text-sm tw-font-semibold">Resend Consent Link</span>
            </div>
          )
        )}
      </td>
      <td className="tw-pt-2">
        {identityVerified && <IoCheckmarkCircleOutline className="tw-text-primary-dark-green tw-mx-auto" size={24} />}
      </td>
    </tr>
  );
};

const ContactConsentStatusContainer = ({ contacts, corporateShareholders }) => {
  return (
    <div className="tw-mb-8">
      <div className="tw-flex tw-items-center tw-mb-4">
        <div className="tw-rounded-full tw-w-max tw-p-0.5">
          <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-w-6 tw-h-6 tw-border-2 tw-border-primary-dark-green tw-bg-primary-light-green">
            <strong className="tw-text-primary-dark-green">1</strong>
          </div>
        </div>
        <p className="tw-ml-8">Director's Consent Status</p>
      </div>
      <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-p-1 lg:tw-p-4 tw-border-2 tw-border-dashed tw-border-neutral-grey-2 tw-rounded-md tw-bg-neutral-grey-5 tw-cursor-default">
        <table className="tw-table tw-table-fixed tw-w-full">
          <thead>
            <tr>
              <th className="tw-text-sm tw-font-normal tw-text-neutral-grey-2 tw-w-3/12">Name</th>
              <th className="tw-text-sm tw-font-normal tw-text-neutral-grey-2 tw-w-6/12">Email</th>
              <th className="tw-text-sm tw-font-normal tw-text-neutral-grey-2 tw-w-3/12 tw-text-center">
                Consent Provided
              </th>
              <th className="tw-text-sm tw-font-normal tw-text-neutral-grey-2 tw-w-3/12 tw-text-center">
                Identity Verification
              </th>
            </tr>
          </thead>
          <tbody>
            {contacts.map(
              ({
                id,
                firstName,
                lastName,
                creditCheckAuthorizationProvided,
                creditCheckAuthorizationSent,
                creditCheckAuthorizationExpired,
                thirdPartyVerificationComplete,
                email,
              }) => {
                const owner = getOwner({
                  id,
                  firstName,
                  lastName,
                  creditCheckAuthorizationProvided,
                  creditCheckAuthorizationSent,
                  creditCheckAuthorizationExpired,
                  thirdPartyVerificationComplete,
                  email,
                });
                return <RegistryOwnerRow key={id} owner={owner} />;
              }
            )}
            {/* TODO: Remove corporate shareholders from onboarding */}
            {corporateShareholders.map(
              ({
                id,
                name,
                signingOfficerContact: {
                  firstName,
                  lastName,
                  creditCheckAuthorizationProvided,
                  creditCheckAuthorizationSent,
                  creditCheckAuthorizationExpired,
                  email,
                },
              }) => {
                const owner = getOwner({
                  id,
                  name,
                  firstName,
                  lastName,
                  creditCheckAuthorizationProvided,
                  creditCheckAuthorizationSent,
                  creditCheckAuthorizationExpired,
                  email,
                });
                return <RegistryOwnerRow key={id} owner={owner} />;
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactConsentStatusContainer;
