import { useCallback, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

import {
  CreditCardProcessorSettingsLimits,
  CreditCardProcessorSettingsLimitsCadence,
  CreditCardProcessorSettings,
  CreditCardProcessorSettingsQueryResponse,
  CreditCardCurrency,
} from 'types/creditCard';
import { GET_CREDIT_CARD_PROCESSOR_SETTINGS, UPDATE_CREDIT_CARD_PROCESSOR_SETTINGS } from 'graphql/cards';
import { useToggle } from 'hooks';
import { formatMoneyV2 } from 'utility/currency';
import { formatDateTime } from 'utility/date';
import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { getLimitTypeLabel } from '../SpendingLimits.utils';

const useSpendingLimits = () => {
  const { creditCardDetails } = useContext(CardDetailsContext);
  const { id: creditCardId } = creditCardDetails || {};

  const [limitSettings, setLimitSettings] = useState<CreditCardProcessorSettingsLimits | null>(null);
  const [error, setError] = useState<ApolloError | null>(null);

  const {
    cadence: limitType,
    amount: amountObj,
    nextCadenceStartsAt,
    spentAmount: spentAmountObj,
  } = limitSettings || {};

  const { isOpen: isOpenModal, open: openModal, close: closeModal } = useToggle();

  const limitTypeLabel = getLimitTypeLabel(limitType);
  const formattedResetDate = formatDateTime(nextCadenceStartsAt, 'MMMM d, yyyy');

  const { amount: limitAmountCents = 0, currency: limitAmountCurrency = CreditCardCurrency.CAD } = amountObj || {};
  const { amount: spentAmountCents = 0, currency: spentAmountCurrency = CreditCardCurrency.CAD } = spentAmountObj || {};
  const formattedLimitAmount = formatMoneyV2({
    amount: limitAmountCents,
    currency: limitAmountCurrency,
  });
  const formattedSpentAmount = formatMoneyV2({
    amount: spentAmountCents,
    currency: spentAmountCurrency,
  });
  const availableAmountCents = limitAmountCents - spentAmountCents;
  const formattedAvailableAmount = formatMoneyV2({
    amount: availableAmountCents,
    currency: limitAmountCurrency,
  });

  const [getCreditCardSettingsSpendingLimits, { loading }] = useLazyQuery<CreditCardProcessorSettingsQueryResponse>(
    GET_CREDIT_CARD_PROCESSOR_SETTINGS,
    {
      onCompleted: (data) => {
        const spendingLimits = data?.creditCard?.processorSettings?.limitSettings || {};
        setLimitSettings(spendingLimits);
      },
      onError: setError,
    }
  );

  const [updateCreditCardProcessorSettings, { loading: isUpdatingProcessorSettings }] = useMutation<{
    setCardProcessorSettings: CreditCardProcessorSettings;
  }>(UPDATE_CREDIT_CARD_PROCESSOR_SETTINGS, {
    update: (cache, { data }) => {
      const updatedData = data?.setCardProcessorSettings;

      if (!updatedData) return;

      cache.updateQuery({ query: GET_CREDIT_CARD_PROCESSOR_SETTINGS, variables: { creditCardId } }, (existingData) => ({
        creditCard: {
          ...existingData.creditCard,
          processorSettings: updatedData,
        },
      }));
    },
  });

  const handleUpdateSettings = useCallback(
    async ({
      limitType,
      limitAmountCents,
    }: {
      limitType?: CreditCardProcessorSettingsLimitsCadence;
      limitAmountCents: number;
    }) => {
      const processingToast = toast.loading('Updating spending limits...');

      try {
        const response = await updateCreditCardProcessorSettings({
          variables: {
            creditCardId,
            processorSettings: {
              limitSettings: {
                cadence: limitType,
                currency: CreditCardCurrency.CAD,
                amountCents: limitAmountCents,
              },
            },
          },
        });

        if (!response?.data?.setCardProcessorSettings) throw new Error('Failed to update spending limits');

        toast.update(processingToast, {
          render: 'Credit card spending limits were added successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });

        closeModal();
      } catch (error) {
        toast.update(processingToast, {
          render: 'Failed to update credit card spending limits',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
        console.error(error);
      }
    },
    [creditCardId, updateCreditCardProcessorSettings, closeModal]
  );

  useEffect(() => {
    if (!creditCardId) return;

    getCreditCardSettingsSpendingLimits({ variables: { creditCardId } });
  }, [creditCardId, getCreditCardSettingsSpendingLimits]);

  return {
    isLoading: loading,
    isError: !!error,
    isOpenModal,
    openModal,
    closeModal,
    handleUpdateSettings,
    isUpdatingProcessorSettings,
    limitType,
    limitAmount: limitAmountCents,
    currency: limitAmountCurrency,
    formattedLimitAmount,
    limitTypeLabel,
    formattedResetDate,
    spentAmountCents,
    formattedSpentAmount,
    formattedAvailableAmount,
  };
};

export default useSpendingLimits;
