import React from 'react';

import { Progress } from 'components/UI';
import { useCreateReimbursementRequest } from './hooks';
import { REIMBURSEMENT_REQUEST_STEPS } from './constants';
import Steps from './components/Steps';

const CreateReimbursementRequest = ({ onFinish }: { onFinish: () => void }) => {
  const { currentStep, onPrevStep, onNextStep, onResetSteps, isCompleted, onComplete } =
    useCreateReimbursementRequest();

  return (
    <>
      <Progress
        currentStepIndex={currentStep}
        stepLabelsAndIndexes={REIMBURSEMENT_REQUEST_STEPS}
        isCompleted={isCompleted}
      />
      <Steps
        currentStep={currentStep}
        onPrevStep={onPrevStep}
        onNextStep={onNextStep}
        onFinish={onFinish}
        onResetSteps={onResetSteps}
        onComplete={onComplete}
      />
    </>
  );
};

export default CreateReimbursementRequest;
