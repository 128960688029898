import React, { useState, useEffect, useRef, ReactNode, useContext } from 'react';
import { Popover, Transition, RadioGroup } from '@headlessui/react';
import { IoRadioButtonOn, IoRadioButtonOff, IoDownloadOutline, IoInformationCircleOutline } from 'react-icons/io5';
import { useLazyQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';

import Button from 'components/Button';
import { useToggle } from 'hooks';
import { CompletedRequestDataType } from '../ApprovalRequest.types';
import { HEADERS } from 'components/Approvals/constants';
import { formatDateTime } from 'utility/date';
import { formatMoneyWithoutCurrencyLabel } from 'utility/currency';
import { GET_COMPLETED_EXPENSES } from 'graphql/completedExpenses';
import { FilterRequestsContext } from 'components/Approvals/ApprovalsContent/context';

const ExportsDropdown = ({
  completedApprovalsList,
  completedTotalCount,
}: {
  completedApprovalsList: CompletedRequestDataType[];
  completedTotalCount: number;
}) => {
  const { isOpen, toggle: toggleOpen } = useToggle();
  const options = ['CSV'];
  const [selectedOption, setSelectedOption] = useState();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        toggleOpen();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleOpen]);

  return (
    <Popover>
      {() => (
        <div className="tw-relative tw-py-2">
          <Popover.Button onClick={toggleOpen}>
            <div className="tw-flex tw-items-center tw-cursor-pointer tw-px-4">
              <IoDownloadOutline size={24} className="tw-mr-2 tw-text-primary-dark-green" />
              <div className="tw-text-sm tw-text-primary-dark-green">Export Expense Report</div>
            </div>
          </Popover.Button>
          <Transition
            unmount={false}
            show={isOpen}
            leave="tw-transition tw-ease-in tw-duration-100"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Popover.Panel
              ref={dropdownRef}
              className="tw-absolute tw-z-40 tw-right-0 tw-pt-3 tw-pb-2 tw-px-3 tw-w-60 tw-overflow-auto tw-bg-neutral-light tw-rounded-md tw-shadow-notification"
            >
              <RadioGroup value={selectedOption} onChange={setSelectedOption}>
                <RadioGroup.Label>
                  <div className="tw-text-sm">Choose an export type</div>
                </RadioGroup.Label>
                <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-mt-2">
                  {options.map((option) => (
                    <RadioGroup.Option
                      key={option}
                      value={option}
                      className={({ checked }) => `
                      ${checked && 'tw-bg-primary-light-green'}
                        tw-w-full
                      `}
                    >
                      {({ checked }) => (
                        <div className="tw-flex tw-justify-center tw-items-center tw-p-2 tw-border tw-rounded-md tw-border-neutral-grey-3 tw-cursor-pointer">
                          {checked ? (
                            <IoRadioButtonOn size={18} className="tw-text-primary-dark-green tw-ml-1 tw-mr-2" />
                          ) : (
                            <IoRadioButtonOff size={18} className="tw-text-neutral-grey-2 tw-ml-1 tw-mr-2" />
                          )}
                          <RadioGroup.Label as="small" className="tw-mr-2">
                            {option}
                          </RadioGroup.Label>
                        </div>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
              <div className="tw-flex tw-items-center tw-justify-center tw-bg-neutral-grey-4 tw-rounded-md tw-my-4 tw-p-1">
                <IoInformationCircleOutline size={24} className="tw-mr-2" />
                <span className="tw-text-xs">
                  Expense Report will be exported with the filters applied in Completed Tab.
                </span>
              </div>
              {selectedOption &&
                (completedApprovalsList && completedApprovalsList.length > 0 ? (
                  <Wrapper completedTotalCount={completedTotalCount}>
                    <Button primary className="tw-w-full tw-mb-1">
                      Export
                    </Button>
                  </Wrapper>
                ) : (
                  <div>No Data</div>
                ))}
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};

const Wrapper = ({ children, completedTotalCount }: { children: ReactNode; completedTotalCount: number }) => {
  const [expenses, setExpenses] = useState<CompletedRequestDataType[]>([]);
  const { period, selectedProducts, selectedCurrencies, selectedMembers, selectedExpenseTypes } =
    useContext(FilterRequestsContext);

  const filter = {
    startDate: period?.from,
    endDate: period?.to,
    products: selectedProducts.map((product) => product.id),
    currencies: selectedCurrencies,
    members: selectedMembers.map((member) => member.id),
    expenseTypes: selectedExpenseTypes.map((type) => type.replace(/\s/g, '')),
  };

  const [getExpenses, { loading }] = useLazyQuery(GET_COMPLETED_EXPENSES, {
    variables: {
      ...filter,
      page: '0',
      numPerPage: `${completedTotalCount}`,
    },
    onCompleted: (data) => {
      setExpenses(data?.completedExpenses?.items);
    },
  });

  useEffect(() => {
    if (completedTotalCount > 0) {
      getExpenses();
    }
  }, [completedTotalCount, getExpenses]);

  const expensesList = expenses.map((expense) => [
    expense.type,
    expense.requester.name,
    expense.approver.name,
    formatMoneyWithoutCurrencyLabel(expense.amountRequested),
    expense.amountRequested.currency,
    formatMoneyWithoutCurrencyLabel(expense.originalAmount),
    expense.originalAmount.currency,
    expense.type === 'CardExpense' ? '' : formatMoneyWithoutCurrencyLabel(expense.taxAmount),
    expense.fromLabel,
    expense.merchantName,
    expense.description,
    formatDateTime(expense.type === 'CardExpense' ? expense.transactionDate : expense.createdAt),
    expense.status,
    formatDateTime(expense.dateApproved),
  ]);
  const csvData = [HEADERS, ...expensesList];

  if (loading) return <div>Calculating Data...</div>;

  return (
    <div>
      <CSVLink data={csvData} filename={`expense-report.csv`} target="_blank">
        {children}
      </CSVLink>
    </div>
  );
};

export default ExportsDropdown;
