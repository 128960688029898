import React from 'react';
import { useHistory } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { BiArrowBack } from 'react-icons/bi';

import Button from 'components/Button';
import PayLocBalanceFlow from 'containers/PayLocBalanceFlow';
import DashboardLayout from 'components/dashboard/DashboardLayout';

const PayLocBalance = () => {
  const history = useHistory();

  const onNavigateToPayments = () => history.push('/dashboard/payments');

  return (
    <DashboardLayout title="Payments">
      <PayLocBalanceFlow
        onFinish={onNavigateToPayments}
        finishButtonLabel="Back to Payments"
        header={<Header title="Make a Payment" />}
      />
    </DashboardLayout>
  );
};

const Header = ({ title }) => {
  const history = useHistory();

  const onNavigateBack = () => history.goBack();

  return (
    <div className="tw-flex lg:tw-flex-row lg:tw-items-center tw-flex-col">
      <BiArrowBack
        size={24}
        className="tw-mb-2 tw-ml-8 tw-mt-8 lg:tw-hidden tw-cursor-pointer"
        onClick={onNavigateBack}
      />

      <Button
        className="tw-flex tw-items-center tw-text-base tw-ml-6 lg:tw-mr-20 tw-cursor-pointer"
        onClick={onNavigateBack}
      >
        <BsChevronLeft className="tw-mr-4 tw-hidden lg:tw-inline-flex" size={14} />
        <span className="tw-hidden lg:tw-inline-flex tw-text-lg tw-font-semibold">Back</span>
      </Button>

      <h5 className="lg:tw-font-bold lg:tw-text-2xl tw-ml-8 lg:tw-ml-0">{title}</h5>
    </div>
  );
};

export default PayLocBalance;
