import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import cx from 'classnames';

import { CurrencyOptionsProps } from './CurrencyOptions.types';
import { RadioField, Select } from 'components/FormFields/v2';
import InfoTooltip from 'components/InfoTooltip';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { useIsMobile } from 'hooks';
import { PAYMENT_CURRENCY_INFO } from 'components/payments/CardRepayment/constants';
import { getCurrencyOptions } from './CurrencyOptions.utils';

const CurrencyOptions = ({
  options,
  infoTooltipMessage,
  labelClassName = 'xl:tw-w-1/6',
  contentClassName = 'tw-w-5/6',
}: CurrencyOptionsProps) => {
  const { register } = useFormContext();

  const isMobile = useIsMobile();

  const paymentCurrencySelectOptions = options.map((type) => {
    return {
      name: PAYMENT_CURRENCY_INFO[type].name,
      value: type,
    };
  });

  const paymentCurrencyRadioOptions = options.map((type) => {
    const currency = getCurrencyOptions(type);

    return {
      label: (
        <div className="tw-flex tw-gap-2 tw-items-center">
          {PAYMENT_CURRENCY_INFO[type].name}
          {currency && (
            <div className="tw-w-5">
              <MobileCurrencyFlag currency={currency} size={20} />
            </div>
          )}
        </div>
      ),
      value: type,
    };
  });

  return (
    <div className="tw-flex xl:tw-gap-4 tw-items-start">
      <div className={cx('tw-flex tw-items-center tw-gap-1 tw-w-40 tw-h-12', labelClassName)}>
        Payment Currency
        <InfoTooltip message={infoTooltipMessage} />
      </div>

      {isMobile ? (
        <Select
          name="paymentCurrencyOption"
          options={paymentCurrencySelectOptions}
          placeholder="Select currency"
          ref={register({ required: true })}
        />
      ) : (
        <RadioField
          name="paymentCurrencyOption"
          options={paymentCurrencyRadioOptions}
          ref={register({ required: true })}
          rootClass={(cx('tw-flex-wrap'), contentClassName)}
          size="sm"
        />
      )}
    </div>
  );
};

export default memo(CurrencyOptions);
