import { PayBalanceCurrency } from 'types/payments';

export const DEFAULT_BALANCE = {
  amount: 0,
  currency: PayBalanceCurrency.CAD as const,
};

export const DEFAULT_GLOBAL_LIMIT_INFO = {
  groupAmountDue: DEFAULT_BALANCE,
  groupAvailableBalance: DEFAULT_BALANCE,
  groupCreditLimit: DEFAULT_BALANCE,
  groupOverlimit: DEFAULT_BALANCE,
  groupTotalSpent: DEFAULT_BALANCE,
};
