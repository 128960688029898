// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React from 'react';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

import { formatMoneyV2 } from 'utility/currency';
import { formatDate } from 'utility/date';
import { DEFAULT_AMOUNT } from 'constants/defaultValues';
import InfoTooltip from 'components/InfoTooltip';
import { Loaders } from 'components/cards/Loader';
import Button, { IconAnimation } from 'components/Button';
import useGetProductState from 'hooks/useGetProductState';

const CreditSummary = (props) => {
  const { isLoading: meLoading, isCreditCard } = useGetProductState();

  if (meLoading) return <LoadingState />;

  return isCreditCard ? <CreditCardSummary {...props} /> : <PreFundedCardSummary {...props} />;
};

const Wrapper = ({ children }) => <div className="tw-rounded-md tw-bg-neutral-light tw-p-4 tw-mt-4">{children}</div>;

const LoadingState = () => (
  <Wrapper>
    <div className="tw-flex tw-items-center tw-justify-center" data-testid="credit-summary-loader">
      <Loaders.Spinner />
    </div>
  </Wrapper>
);

const CreditCardSummary = ({ groupedCardsInfo }) => {
  const { groupAvailableBalance, groupCreditLimit, billingCycle } = groupedCardsInfo || {};
  const balance = groupAvailableBalance || DEFAULT_AMOUNT;
  const limit = groupCreditLimit || DEFAULT_AMOUNT;
  const startDate = billingCycle && billingCycle.startDate;
  const endDate = billingCycle && billingCycle.endDate;

  return (
    <Wrapper>
      <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-end tw-mb-4">
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-2 lg:tw-mb-0 lg:tw-justify-start lg:tw-w-44">
          <small className="tw-mr-1">Available to Spend</small>
          <InfoTooltip testId="cards-available-to-spend" message="Sum of spending limits on all cards" />
        </div>
        <div className="tw-flex tw-items-end">
          <span className="tw-text-2xl tw-mr-2 tw-font-semibold">
            {`${formatMoneyV2(balance)} `} <small className="tw-text-neutral-grey-2">{balance.currency}</small>
          </span>
        </div>
      </div>
      <div className="tw-mt-4">
        <CardsLimit limit={limit} />
      </div>
      <div className="tw-my-4 tw-flex tw-justify-between">
        <div>
          <small>Current Cycle</small>
        </div>
        <div>
          <small>
            {formatDate(startDate, 'MMMM d')} - {formatDate(endDate)}
          </small>
        </div>
      </div>
      <Link to="/dashboard/payments/pay-balance">
        <Button primary className="tw-font-bold tw-w-full" iconAnimation={IconAnimation.rollOut}>
          Pay Card Balance <BsArrowRight className="tw-ml-2" />
        </Button>
      </Link>
    </Wrapper>
  );
};

const PreFundedCardSummary = () => (
  <Wrapper>
    <CardsLimit limit={DEFAULT_AMOUNT} />
  </Wrapper>
);

const CardsLimit = ({ limit }) => (
  <>
    <div className="tw-flex tw-items-center tw-justify-between tw-mb-2">
      <small>Cards Limit</small>
      <InfoTooltip message="The total amount of credit that your business is authorized to access through Loop." />
    </div>
    <h3>{`${formatMoneyV2(limit)} ${limit.currency}`}</h3>
  </>
);

export default CreditSummary;
