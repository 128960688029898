import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { GET_CREDIT_LIMIT, GET_CREDIT_CARDS_FOR_LIST, GET_CREDIT_CARDS_IDS } from 'graphql/cards';
import { GET_BANK_ACCOUNTS } from 'graphql/integrations';
import Cards from 'components/creditCards/v2/Cards';

const CardsContainer = () => {
  const { loading: globalCreditLimitLoading, data } = useQuery(GET_CREDIT_LIMIT);
  const {
    data: creditCardData,
    loading: creditCardLoading,
    refetch,
    error: creditCardDataError,
  } = useQuery(GET_CREDIT_CARDS_FOR_LIST);
  const {
    data: creditCardIds,
    loading: creditCardIdLoading,
    refetch: creditCardIdRefetch,
    error: creditCardIdsError,
  } = useQuery(GET_CREDIT_CARDS_IDS);
  const {
    data: bankAccountsData,
    loading: bankAccountsLoading,
    error: bankAccountsDataError,
  } = useQuery(GET_BANK_ACCOUNTS);

  const { me } = data || {};
  const creditCards = _.get(creditCardData, 'me.account.creditCards') || [];
  const creditCardsIds = _.get(creditCardIds, 'me.account.creditCards') || [];
  const bankAccounts = _.get(bankAccountsData, 'bankInfo.bankAccounts') || [];
  const connectedBankAccount = _.get(me, 'account.bankAccount.connected') || false;
  const bankAccountVerified = _.get(me, 'account.bankAccount.verified');
  const globalCreditLimit = _.get(me, 'account.globalCreditLimit') || null;
  const kycAssessment = _.get(creditCardIds, 'me.account.kycAssessment.status') || null;
  const lineOfCredit = _.get(me, 'account.lineOfCredit');

  const hasLineOfCredit = !!lineOfCredit;
  const hasAtLeastOneBankAccountVerified = bankAccounts.some((ba) => ba.verified);

  const isError = creditCardDataError || creditCardIdsError || bankAccountsDataError;

  // this refetch is used only for mobile layout
  const handleCardRefetch = () => {
    refetch();
    creditCardIdRefetch();
  };

  useEffect(() => {
    if (isError) toast.error("Can't fetch card details");
  }, [isError]);

  return (
    <Cards
      creditCards={creditCards}
      creditCardLoading={creditCardLoading}
      hasAtLeastOneBankAccountVerified={hasAtLeastOneBankAccountVerified}
      connectedBankAccount={connectedBankAccount}
      bankAccountVerified={bankAccountVerified}
      bankAccountsLoading={bankAccountsLoading}
      globalCreditLimitLoading={globalCreditLimitLoading}
      globalCreditLimit={globalCreditLimit}
      refetchCreditCards={handleCardRefetch}
      creditCardsIds={creditCardsIds}
      creditCardIdLoading={creditCardIdLoading}
      kycAssessment={kycAssessment}
      hasLineOfCredit={hasLineOfCredit}
    />
  );
};

export default CardsContainer;
