import { gql } from '@apollo/client';

export const GET_DOCUMENTS = gql`
  query Documents {
    documents {
      id
      name
      category
      size
      createdAt
    }
  }
`;

export const ADD_DOCUMENT = gql`
  mutation AddDocument($blobSignedId: String!, $category: DocumentCategoryEnum!, $contactId: ID) {
    addDocument(blobSignedId: $blobSignedId, category: $category, contactId: $contactId)
  }
`;

export const GET_DOCUMENT_DOWNLOAD_URL = gql`
  query GetDocumentDownloadUrl($documentId: ID!) {
    documentsDownload(documentId: $documentId)
  }
`;
