import React from 'react';

import FlinksConnection from 'containers/FlinksConnection';
import { useFlinksIframe } from './hooks';
import { FlinksIframeProps } from './FlinksIframe.types';

const FlinksIframe = ({
  redirectRoute,
  country,
  currencies,
  isPayor,
  inIntegrations = false,
  allowBankAccountCreation = false,
}: FlinksIframeProps) => {
  const { flinksRedirectUrl, flinksCountry, flinksCurrency } = useFlinksIframe({
    redirectRoute,
    country,
    currencies,
    isPayor,
  });

  return (
    <FlinksConnection
      isPayor={isPayor}
      redirectUrl={flinksRedirectUrl}
      country={flinksCountry}
      currency={flinksCurrency}
      integrations={inIntegrations}
      allowBankAccountCreation={allowBankAccountCreation}
    />
  );
};

export default FlinksIframe;
