import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Select, SubmitButton } from 'components/FormFields/v2';
import { solidTransactionsVolumeOptions, solidTransactionsAmountOptions } from 'constants/index';
import { useTransactionsVolume } from './hooks';
import { TransactionsVolumeProps } from './TransactionsVolume.types';

const Projections = ({ onNextStep, onSubmitNaicsAndProjections }: TransactionsVolumeProps) => {
  const { form, register, handleSubmit, onSubmit, error, isLoading } = useTransactionsVolume({
    onNextStep,
    onSubmitNaicsAndProjections,
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-pt-6 tw-px-8 tw-mb-10">
          <div className="tw-flex tw-flex-col tw-gap-y-8">
            <div className="tw-text-sm">
              Please estimate the volume of ACH Transactions that will take place in your Loop US Dollar Bank Account
              each year.
            </div>
            <Select
              name="transactionsSentAmount"
              label="Total Value of Outgoing Payments/Transfers"
              placeholder="Select Option"
              required
              ref={register({ required: true })}
              options={solidTransactionsAmountOptions}
            />
            <Select
              name="transactionsSentVolume"
              label="Total Number of Outgoing Payments/Transfers"
              placeholder="Select Option"
              required
              ref={register({ required: true })}
              options={solidTransactionsVolumeOptions}
            />
            <Select
              name="transactionsReceivedAmount"
              label="Total Value of Incoming Payments/Transfers"
              placeholder="Select Option"
              required
              ref={register({ required: true })}
              options={solidTransactionsAmountOptions}
            />
            <Select
              name="transactionsReceivedVolume"
              label="Total Number of Incoming Payments/Transfers"
              placeholder="Select Option"
              required
              ref={register({ required: true })}
              options={solidTransactionsVolumeOptions}
            />

            {error?.message && !isLoading && <div className="tw-text-semantic-error tw-text-xs">{error.message}</div>}
          </div>
        </div>
        <div className="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-4 tw-flex tw-justify-end">
          <SubmitButton primary isFetching={isLoading}>
            Next
          </SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default Projections;
