import { useContext, useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { DetailsProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/ConvertFunds/components/Steps/Steps.types';
import { useAccountsModal } from 'components/Accounts/AccountsContent/components/AccountsModal/hooks';
import { Currencies } from 'constants/currencies';
import { FundsContext } from 'components/Accounts/AccountsContent/contexts/FundsContext';
import { formatMoneyV2, centsFromMoneyString } from 'utility/currency';
import { useDeepEffect } from 'hooks';
import useGetExchangeRate from 'hooks/useGetExchangeRate';
import { ConvertFundsActionTypes } from 'components/Accounts/AccountsContent/components/AccountsModal/types/funds';
import { MIN_EXCHANGE_RATE_AMOUNT, DEFAULT_EXCHANGE_RATE_AMOUNT, balancesOrder } from 'constants/index';
import config from 'config';

const useDetails = ({ onNextStep }: DetailsProps) => {
  const { convertFundsInfo, setConvertFundsInfo, error } = useContext(FundsContext);
  const { originalAmount, chargedAmount, buyOrSell: initialBuyOrSell, fromAccount, toAccount } = convertFundsInfo || {};

  const { walletId, wallets, availableBalance } = useAccountsModal();

  const currencyCloudMaintenance = config.currencyCloudApiDown;

  const sortedWallets = useMemo(
    () => [...wallets].sort((a, b) => balancesOrder.indexOf(a.currency) - balancesOrder.indexOf(b.currency)),
    [wallets, balancesOrder]
  );

  const { rate, buy, sell, loadingRate, getExchangeRate, rateExpiresAt, exchangeRateReference } = useGetExchangeRate();

  const [maxBuyAmount, setMaxBuyAmount] = useState(0);
  const [needsConversion, setNeedsConversion] = useState(false);

  const [formattedAmount, setFormattedAmount] = useState(
    formatMoneyV2({
      amount:
        initialBuyOrSell === ConvertFundsActionTypes.sell
          ? chargedAmount?.amount || DEFAULT_EXCHANGE_RATE_AMOUNT
          : originalAmount?.amount || DEFAULT_EXCHANGE_RATE_AMOUNT,
      currency: chargedAmount?.currency || '',
    })
  );

  const formattedOriginalAmount = formatMoneyV2(originalAmount);
  const formattedChargedAmount = formatMoneyV2(chargedAmount);

  const formattedMaxBuyAmount = `${formatMoneyV2({ amount: maxBuyAmount, currency: toAccount?.currency })} ${
    toAccount?.currency
  }`;

  const formattedBalance = `${formatMoneyV2(availableBalance)} ${availableBalance?.currency}`;

  const form = useForm({
    defaultValues: {
      fromAccount: fromAccount?.id,
      toAccount: toAccount?.id,
      buyOrSell: initialBuyOrSell,
      fromAmount: formattedAmount,
      toAmount: formattedAmount,
    },
  });

  const { handleSubmit, register, watch, setError, setValue } = form;
  const buyOrSell = watch('buyOrSell');
  const watchToAccount = watch('toAccount');

  useEffect(() => {
    setNeedsConversion(
      fromAccount !== undefined &&
        toAccount !== undefined &&
        toAccount.currency !== fromAccount.currency &&
        centsFromMoneyString(formattedAmount) > 0
    );
  }, [fromAccount, toAccount, formattedAmount]);

  const fromOptions = useMemo(() => {
    if (!fromAccount?.id) return [];

    return wallets.reduce((acc, { id, displayName }) => {
      if (fromAccount.id === id) return [...acc, { name: displayName, value: id }];
      return acc;
    }, [] as { name: string; value: string }[]);
  }, [wallets, fromAccount?.id]);

  const toOptions = useMemo(() => {
    const toAccountOptions = sortedWallets.map(({ id, displayName, currency }) => {
      return { name: displayName, value: id, currency };
    });

    if (!fromAccount?.currency) return toAccountOptions;

    return toAccountOptions.filter(({ currency }) => currency !== fromAccount.currency);
  }, [wallets, fromAccount?.currency]);

  const handleOnNextStep = (data: { fromAccount: string; toAccount: string; buyOrSell: ConvertFundsActionTypes }) => {
    const currentToAccount = wallets.find(({ id }) => data.toAccount === id);
    const currentFromAccount = wallets.find(({ id }) => data.fromAccount === id);
    const isSellOperation = data.buyOrSell === ConvertFundsActionTypes.sell;

    if (!currentFromAccount) {
      setError('fromAccount', { type: 'manual', message: 'This field is required' });
      return;
    }

    if (!currentToAccount) {
      setError('toAccount', { type: 'manual', message: 'This field is required' });
      return;
    }

    const currentAmount = isSellOperation ? availableBalance?.amount : maxBuyAmount;

    const sufficientFunds = currentAmount && currentAmount >= centsFromMoneyString(formattedAmount);

    if (!sufficientFunds) {
      setError(isSellOperation ? 'fromAmount' : 'toAmount', {
        type: 'manual',
        message: 'Insufficient funds',
      });
      return;
    }

    setConvertFundsInfo((prevState) => ({
      ...prevState!,
      fromAccount: {
        id: currentFromAccount.id,
        currency: currentFromAccount.currency,
        displayName: currentFromAccount.displayName,
      },
      toAccount: {
        id: currentToAccount.id,
        currency: currentToAccount.currency,
        displayName: currentToAccount.displayName,
      },
      buyOrSell: data.buyOrSell,
    }));
    onNextStep();
  };

  useEffect(() => {
    if (!watchToAccount) return;

    const selectedWallet = wallets.find(({ id }) => id === watchToAccount);

    if (!selectedWallet) return;

    const { id, currency, displayName } = selectedWallet;

    setConvertFundsInfo((prevState) => ({
      ...prevState!,
      toAccount: { id, currency, displayName },
    }));
  }, [watchToAccount]);

  useDeepEffect(() => {
    if (!fromAccount || !toAccount) return;
    if (loadingRate || !buyOrSell || !rate || !sell || !buy || !rateExpiresAt || !exchangeRateReference) return;

    const rateAmounts = [buy, sell];
    const currentChargedAmount = rateAmounts.find(({ currency }) => currency === fromAccount.currency);
    const currentOriginalAmount = rateAmounts.find(({ currency }) => currency === toAccount.currency);

    if (!currentChargedAmount || !currentOriginalAmount) return;

    const currentRate = 1 / rate;

    setConvertFundsInfo((prevState) => ({
      ...prevState!,
      originalAmount: currentOriginalAmount,
      chargedAmount: currentChargedAmount,
      rate: Number(currentRate.toFixed(5)),
      rateExpiresAt,
      exchangeRateReference,
    }));
  }, [loadingRate, rate, buy, sell, buyOrSell, fromAccount, toAccount, exchangeRateReference]);

  useEffect(() => {
    if (!walletId) return;

    const selectedWallet = wallets.find((wallet) => wallet.id === walletId);
    if (!selectedWallet) return;

    const { id, currency, displayName } = selectedWallet;

    setConvertFundsInfo((prevState) => ({
      ...prevState!,
      fromAccount: {
        id,
        currency,
        displayName,
      },
    }));
    setValue('fromAccount', id);
  }, [walletId]);

  useDeepEffect(() => {
    const amount = centsFromMoneyString(formattedAmount);

    if (
      !toAccount?.currency ||
      !fromAccount?.currency ||
      toAccount.currency === fromAccount.currency ||
      !buyOrSell ||
      amount == 0
    )
      return;

    const payload = {} as {
      buy: { currency: Currencies; amount?: number };
      sell: { currency: Currencies; amount?: number };
    };

    const payloadAmount = amount > MIN_EXCHANGE_RATE_AMOUNT ? amount : MIN_EXCHANGE_RATE_AMOUNT;

    if (buyOrSell === ConvertFundsActionTypes.sell) {
      payload.buy = { currency: toAccount.currency };
      payload.sell = {
        currency: fromAccount.currency,
        amount: payloadAmount,
      };
    } else {
      payload.buy = { currency: toAccount.currency, amount: payloadAmount };
      payload.sell = { currency: fromAccount.currency };
    }

    getExchangeRate({
      variables: payload,
    });
  }, [toAccount, fromAccount, buyOrSell, formattedAmount]);

  useEffect(() => {
    if (!rate || !availableBalance?.amount) return;

    const balanceAmount = availableBalance.amount;
    const calculatedMaxBuyAmount = balanceAmount / rate;

    setMaxBuyAmount(Number(`${calculatedMaxBuyAmount.toFixed(2)}`));
  }, [rate, availableBalance]);

  return {
    fromOptions,
    toOptions,
    form,
    register,
    formattedAmount,
    setFormattedAmount,
    formatMoneyV2,
    formattedBalance,
    rate: convertFundsInfo?.rate,
    loadingRate,
    formattedOriginalAmount,
    formattedChargedAmount,
    buyOrSell,
    needsConversion,
    formattedMaxBuyAmount,
    fromAccountCurrency: fromAccount?.currency,
    toAccountCurrency: toAccount?.currency,
    handleSubmit,
    handleOnNextStep,
    errorMessage: error?.message,
    currencyCloudMaintenance,
  };
};

export default useDetails;
