import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import config from 'config';
import { GET_BORROWER_REFERRAL_CODE_AND_POINTS } from 'graphql/rewards';
import { useRebuildTooltipForModal } from 'components/InfoTooltip';
import { AuthContext } from 'context/Auth';

const useReferAndEarn = () => {
  const { data, loading } = useQuery(GET_BORROWER_REFERRAL_CODE_AND_POINTS);

  const referralCode = get(data, 'me.account.referralCode', '');
  const inviteLink = `${config.rootUrl}/apply?Invitation-Code=${referralCode}&campaign=${encodeURIComponent(
    config.borrowerReferralCampaign
  )}`;
  const rootUrl = config.rootUrl.replace('https://', '');
  const inAppReferralUrl = config.inAppReferralUrl;
  const termsAndConditionsUrl = config.baseLegalUrl;
  const numberOfPoints = get(data, 'me.account.registrationReferralPoints', 0);
  const formattedNumberOfPoints = numberOfPoints.toLocaleString();

  const { isImpersonating } = useContext(AuthContext) as unknown as {
    isImpersonating: boolean;
  };
  const onCopyValueToClipboard = (value: string) => navigator.clipboard.writeText(value);
  const onCopyReferralCode = () => {
    if (!isImpersonating) onCopyValueToClipboard(inviteLink);
  };

  return {
    loading,
    referralCode,
    onCopyReferralCode,
    formattedNumberOfPoints,
    useRebuildTooltipForModal,
    inviteLink,
    rootUrl,
    inAppReferralUrl,
    termsAndConditionsUrl,
  };
};

export default useReferAndEarn;
