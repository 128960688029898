import { Currencies } from 'constants/currencies';
import { Transaction, PayBalanceType, PayBalanceCurrency, PayBalanceCurrencyOptions, Money } from 'types/payments';
import { GroupOngoingAmount } from 'types/creditCard';
import { BankAccount, BankAccountCountry } from 'types/bankAccount';
import { AccountWallet } from 'types/wallet';
import getUniqueId from 'utility/getUniqueId';

const getFxAmount = (individualStatementBalanceInCad: Money) => {
  if (!individualStatementBalanceInCad?.amount) return;

  return {
    amount: individualStatementBalanceInCad.amount,
    currency: individualStatementBalanceInCad.currency,
  };
};

const getTransactionFromBalanceGroups = ({
  balanceGroups,
  fromAccounts,
  selectedFromAccounts,
  currencyName = 'currency',
  amountName = 'amount',
  individualBalancesInCad,
  totalAmount,
}: {
  balanceGroups?: { [key: string]: string | number }[];
  fromAccounts?: (AccountWallet | BankAccount)[];
  selectedFromAccounts?: { [key: string]: string };
  currencyName?: string;
  amountName?: string;
  individualBalancesInCad?: {
    [PayBalanceCurrency.EUR]?: Money;
    [PayBalanceCurrency.GBP]?: Money;
    [PayBalanceCurrency.USD]?: Money;
  };
  totalAmount?: Money<PayBalanceCurrency>;
}) => {
  const transactions: Transaction[] =
    balanceGroups?.map((balance) => {
      const txnId = getUniqueId();

      const currency = balance[currencyName] as PayBalanceCurrency;
      const amount = balance[amountName] as number;

      let fxAmountData: Transaction['fxAmount'];

      const selectedFromAccountId = selectedFromAccounts?.[currency] || '';
      const selectedFromAccount = fromAccounts?.find((account) => account.id === selectedFromAccountId);
      const selectedFromAccountCurrency = selectedFromAccount?.currency;

      const isFXAmountAvailable = selectedFromAccountCurrency && selectedFromAccountCurrency !== Currencies[currency];

      if (
        (currency === PayBalanceCurrency.EUR ||
          currency === PayBalanceCurrency.GBP ||
          currency === PayBalanceCurrency.USD) &&
        individualBalancesInCad?.[currency]
      ) {
        const balance = individualBalancesInCad[currency];
        fxAmountData = balance && getFxAmount(balance);
      }

      const availableFromAccountIds =
        fromAccounts?.reduce((acc, account) => {
          if (
            (account as BankAccount).country !== 'US' &&
            (account.currency === Currencies[currency] ||
              // show from accounts with the different currency only if fxAmount is available
              (fxAmountData && account.currency === Currencies.CAD))
          ) {
            acc.push(account.id);
          }
          return acc;
        }, [] as string[]) || [];

      const isFromBankAccount = selectedFromAccount?.__typename === 'BankAccount';

      return {
        id: txnId,
        originalAmount: {
          amount,
          currency,
        },
        fxAmount: isFXAmountAvailable ? fxAmountData : undefined,
        availableFromAccountIds,
        selectedFromAccountId,
        isDisabled: amount <= 0,
        isFromBankAccount,
      };
    }) || [];

  // Single transaction
  // in case this transaction is present, the previous transactions will be used only for displaying purposes
  let singleTransaction: Transaction | undefined;

  if (totalAmount) {
    const selectedFromAccountId = selectedFromAccounts?.[PayBalanceCurrency.CAD] || '';
    const selectedFromAccount = fromAccounts?.find((account) => account.id === selectedFromAccountId);
    const isFromBankAccount = selectedFromAccount?.__typename === 'BankAccount';

    const availableFromAccountIds =
      fromAccounts
        ?.filter(
          (account) =>
            account.currency === (totalAmount.currency as unknown as Currencies) &&
            (account as BankAccount).country !== BankAccountCountry.US // TODO: Remove filter after adding support for US-based accounts for Card
        )
        ?.map((account) => account.id) || [];

    singleTransaction = {
      id: getUniqueId(),
      originalAmount: {
        amount: totalAmount.amount,
        currency: totalAmount.currency,
      },
      availableFromAccountIds,
      selectedFromAccountId,
      isDisabled: totalAmount.amount <= 0,
      isFromBankAccount,
    };
  }

  return { transactions, singleTransaction };
};

export const populateTransactions = ({
  watchPaymentCurrencyOption,
  watchPaymentType,
  groupBalances,
  groupOngoingAmountDueByCurrency,
  groupOngoingMinimumAmountDueByCurrency,
  groupConvertedMinimumAmountDueByCurrency,
  statementBalanceInCad,
  statementBalanceInUsd,
  eurStatementBalanceInCad,
  gbpStatementBalanceInCad,
  usdStatementBalanceInCad,
  allFromAccounts,
  wallets,
  selectedFromAccounts,
}: {
  watchPaymentCurrencyOption?: PayBalanceCurrencyOptions;
  watchPaymentType?: PayBalanceType;
  groupBalances?: {
    amount: number;
    currency: PayBalanceCurrency;
  }[];
  groupOngoingAmountDueByCurrency?: GroupOngoingAmount;
  groupOngoingMinimumAmountDueByCurrency?: GroupOngoingAmount;
  groupConvertedMinimumAmountDueByCurrency?: GroupOngoingAmount<PayBalanceCurrency.CAD>;
  statementBalanceInCad?: Money;
  statementBalanceInUsd?: Money<PayBalanceCurrency.USD>;
  eurStatementBalanceInCad?: Money;
  gbpStatementBalanceInCad?: Money;
  usdStatementBalanceInCad?: Money;
  allFromAccounts: (AccountWallet | BankAccount)[];
  wallets: AccountWallet[];
  selectedFromAccounts?: { [key: string]: string };
}): { transactions: Transaction[]; singleTransaction?: Transaction } => {
  if (watchPaymentCurrencyOption === PayBalanceCurrencyOptions.INDIVIDUAL) {
    switch (watchPaymentType) {
      case PayBalanceType.CURRENT_BALANCE: {
        return getTransactionFromBalanceGroups({
          balanceGroups: groupBalances,
          fromAccounts: allFromAccounts,
          selectedFromAccounts,
        });
      }
      case PayBalanceType.STATEMENT_BALANCE: {
        return getTransactionFromBalanceGroups({
          balanceGroups: Object.values(groupOngoingAmountDueByCurrency || {}),
          fromAccounts: allFromAccounts,
          selectedFromAccounts,
          currencyName: 'currency_iso',
          amountName: 'cents',
          individualBalancesInCad: {
            [PayBalanceCurrency.EUR]: eurStatementBalanceInCad,
            [PayBalanceCurrency.GBP]: gbpStatementBalanceInCad,
            [PayBalanceCurrency.USD]: usdStatementBalanceInCad,
          },
        });
      }
      case PayBalanceType.MINIMUM_DUE: {
        const onlyCadFromAccounts = allFromAccounts.filter(
          (account) => account.currency === Currencies[PayBalanceCurrency.CAD]
        );

        const individualBalancesInCad = [PayBalanceCurrency.EUR, PayBalanceCurrency.GBP, PayBalanceCurrency.USD].reduce(
          (acc, currency) => {
            const balance = groupConvertedMinimumAmountDueByCurrency?.[currency];
            if (balance) {
              acc[currency] = { currency: PayBalanceCurrency.CAD, amount: balance.cents };
            }
            return acc;
          },
          {} as Record<PayBalanceCurrency, Money>
        );

        const totalMinDueAmount = groupConvertedMinimumAmountDueByCurrency?.total && {
          currency: groupConvertedMinimumAmountDueByCurrency.total.currency_iso,
          amount: groupConvertedMinimumAmountDueByCurrency.total.cents,
        };

        return getTransactionFromBalanceGroups({
          balanceGroups: Object.values(groupOngoingMinimumAmountDueByCurrency || {}),
          fromAccounts: onlyCadFromAccounts,
          selectedFromAccounts,
          currencyName: 'currency_iso',
          amountName: 'cents',
          individualBalancesInCad,
          totalAmount: totalMinDueAmount,
        });
      }
    }
  }

  if (watchPaymentCurrencyOption === PayBalanceCurrencyOptions.ALL_IN_CAD && statementBalanceInCad) {
    return getTransactionFromBalanceGroups({
      balanceGroups: [statementBalanceInCad],
      fromAccounts: allFromAccounts,
      selectedFromAccounts,
    });
  }

  if (watchPaymentCurrencyOption === PayBalanceCurrencyOptions.ALL_IN_USD && statementBalanceInUsd) {
    return getTransactionFromBalanceGroups({
      balanceGroups: [statementBalanceInUsd],
      fromAccounts: wallets,
      selectedFromAccounts,
    });
  }

  return { transactions: [] };
};
