import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const Payments4 = ({ size = '146', ...other }) => {
  const width = size;
  const height = (106 * width) / 146;

  return (
    <svg width={width} height={height} viewBox="0 0 144 107" fill="none" {...other}>
      <path
        d="M116.775 105.999V80.9115C116.775 80.2517 116.241 79.7168 115.581 79.7168H110.802C110.142 79.7168 109.607 80.2517 109.607 80.9115V105.999"
        fill="#D0E7F4"
      />
      <path
        d="M107.219 106V88.229C107.219 87.7617 106.684 87.3828 106.024 87.3828H101.245C100.586 87.3828 100.051 87.7617 100.051 88.229V106"
        fill="#D0E7F4"
      />
      <path
        d="M97.6621 105.999V94.5007C97.6621 94.1983 97.1272 93.9531 96.4675 93.9531H91.6888C91.029 93.9531 90.4941 94.1983 90.4941 94.5007V105.999"
        fill="#D0E7F4"
      />
      <path
        d="M113.191 105.999V83.0014C113.191 82.3965 112.657 81.9062 111.997 81.9062H107.218C106.558 81.9062 106.023 82.3965 106.023 83.0014V105.999"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.635 106V90.6684C103.635 90.0635 103.1 89.5732 102.44 89.5732H97.6615C97.0017 89.5732 96.4668 90.0635 96.4668 90.6684V106"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.0781 105.999V98.3334C94.0781 97.7286 93.5433 97.2383 92.8835 97.2383H88.1048C87.445 97.2383 86.9102 97.7286 86.9102 98.3334V105.999"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.5195 105.999H115.581"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.3952 19.8807C50.3952 21.2779 50.0307 22.6569 49.3294 23.9031C48.6278 25.1497 47.6086 26.2288 46.3532 27.0473C45.0976 27.8659 43.6437 28.3991 42.1124 28.5997C40.5812 28.8004 39.0201 28.6622 37.5604 28.1974"
        stroke="#4F5154"
        strokeLinecap="round"
      />
      <path
        d="M34.9656 12.8045C36.2569 11.9222 37.7717 11.3501 39.372 11.1429C40.9724 10.9358 42.6061 11.1005 44.1225 11.6235C45.6389 12.1466 46.9873 13.0107 48.0436 14.1357C49.0999 15.2607 49.8292 16.6094 50.1664 18.0564"
        stroke="#4F5154"
        strokeLinecap="round"
      />
      <path
        d="M35.9309 27.519C34.7031 26.8692 33.6468 25.9801 32.8367 24.9153C32.0267 23.8506 31.4832 22.6372 31.2432 21.3617C31.0033 20.0863 31.0726 18.78 31.4447 17.5351C31.8167 16.2902 32.4828 15.136 33.3957 14.1537"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.0043 12.8822L34.7676 12.6221L35.0514 10.5717"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8008 25.9035L36.1504 27.9453L33.923 28.2658"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.1021 20.8561L50.625 19.332L52.2876 20.728"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.196 46.0017C135.366 46.1399 135.196 46.4019 134.983 46.3318L123.585 42.5909C123.507 42.5651 123.418 42.5871 123.36 42.6464L115.281 50.9632C115.127 51.122 114.858 50.976 114.937 50.7758L119.032 40.2916C119.061 40.2168 119.04 40.1345 118.977 40.0837L109.936 32.7039C109.767 32.5657 109.937 32.3038 110.15 32.3738L121.547 36.1148C121.626 36.1406 121.715 36.1186 121.773 36.0592L129.851 27.7424C130.006 27.5837 130.274 27.7297 130.196 27.9298L126.1 38.4141C126.071 38.4889 126.093 38.5711 126.155 38.622L135.196 46.0017Z"
        fill="#8F5CCF"
      />
      <g clipPath="url(#clip0_6135_69728)">
        <path
          d="M88.873 34.9473H59.1624C56.8182 34.9473 54.918 36.6892 54.918 38.838V81.6356C54.918 83.7844 56.8182 85.5263 59.1624 85.5263H88.873C91.2171 85.5263 93.1174 83.7844 93.1174 81.6356V38.838C93.1174 36.6892 91.2171 34.9473 88.873 34.9473Z"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path opacity="0.5" d="M87.9748 41.1592H62.5098V48.9406H87.9748V41.1592Z" fill="#EED9F7" />
        <path
          d="M64.5404 59.2637C64.5404 60.5591 63.3809 61.6817 61.8571 61.6817C60.3333 61.6817 59.1738 60.5591 59.1738 59.2637C59.1738 57.9683 60.3333 56.8457 61.8571 56.8457C63.3809 56.8457 64.5404 57.9683 64.5404 59.2637Z"
          stroke="#4F5154"
        />
        <path
          d="M75.2419 60.6124C77 60.6124 78.4252 59.306 78.4252 57.6944C78.4252 56.0828 77 54.7764 75.2419 54.7764C73.4838 54.7764 72.0586 56.0828 72.0586 57.6944C72.0586 59.306 73.4838 60.6124 75.2419 60.6124Z"
          fill="#FFE8C3"
        />
        <path
          d="M86.9138 60.6124C88.6718 60.6124 90.097 59.306 90.097 57.6944C90.097 56.0828 88.6718 54.7764 86.9138 54.7764C85.1557 54.7764 83.7305 56.0828 83.7305 57.6944C83.7305 59.306 85.1557 60.6124 86.9138 60.6124Z"
          fill="#D0E7DA"
        />
        <path
          d="M63.57 70.339C65.3281 70.339 66.7533 69.0325 66.7533 67.421C66.7533 65.8094 65.3281 64.5029 63.57 64.5029C61.8119 64.5029 60.3867 65.8094 60.3867 67.421C60.3867 69.0325 61.8119 70.339 63.57 70.339Z"
          fill="#D0E7DA"
        />
        <path
          d="M76.2123 68.9903C76.2123 70.2857 75.0528 71.4083 73.529 71.4083C72.0052 71.4083 70.8457 70.2857 70.8457 68.9903C70.8457 67.6949 72.0052 66.5723 73.529 66.5723C75.0528 66.5723 76.2123 67.6949 76.2123 68.9903Z"
          stroke="#4F5154"
        />
        <path
          d="M64.5404 78.7169C64.5404 80.0122 63.3809 81.1349 61.8571 81.1349C60.3333 81.1349 59.1738 80.0122 59.1738 78.7169C59.1738 77.4215 60.3333 76.2988 61.8571 76.2988C63.3809 76.2988 64.5404 77.4215 64.5404 78.7169Z"
          stroke="#4F5154"
        />
        <path
          d="M76.2123 78.7169C76.2123 80.0122 75.0528 81.1349 73.529 81.1349C72.0052 81.1349 70.8457 80.0122 70.8457 78.7169C70.8457 77.4215 72.0052 76.2988 73.529 76.2988C75.0528 76.2988 76.2123 77.4215 76.2123 78.7169Z"
          stroke="#4F5154"
        />
        <path
          d="M90.097 67.421C90.097 65.8094 88.6718 64.5029 86.9138 64.5029C85.1557 64.5029 83.7305 65.8094 83.7305 67.421V77.1477C83.7305 78.7593 85.1557 80.0657 86.9138 80.0657C88.6718 80.0657 90.097 78.7593 90.097 77.1477V67.421Z"
          fill="#FFE8C3"
        />
        <path
          d="M86.2619 42.7285H60.7969V50.5099H86.2619V42.7285Z"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M76.2123 59.2637C76.2123 60.5591 75.0528 61.6817 73.529 61.6817C72.0052 61.6817 70.8457 60.5591 70.8457 59.2637C70.8457 57.9683 72.0052 56.8457 73.529 56.8457C75.0528 56.8457 76.2123 57.9683 76.2123 59.2637Z"
          stroke="#4F5154"
        />
        <path
          d="M87.8841 59.2637C87.8841 60.5591 86.7246 61.6817 85.2009 61.6817C83.6771 61.6817 82.5176 60.5591 82.5176 59.2637C82.5176 57.9683 83.6771 56.8457 85.2009 56.8457C86.7246 56.8457 87.8841 57.9683 87.8841 59.2637Z"
          stroke="#4F5154"
        />
        <path
          d="M64.5404 68.9903C64.5404 70.2857 63.3809 71.4083 61.8571 71.4083C60.3333 71.4083 59.1738 70.2857 59.1738 68.9903C59.1738 67.6949 60.3333 66.5723 61.8571 66.5723C63.3809 66.5723 64.5404 67.6949 64.5404 68.9903Z"
          stroke="#4F5154"
        />
        <path
          d="M85.2009 66.5723C86.7246 66.5723 87.8841 67.6949 87.8841 68.9903V78.717C87.8841 80.0124 86.7246 81.1351 85.2009 81.1351C83.6771 81.1351 82.5176 80.0124 82.5176 78.717V68.9903C82.5176 67.6949 83.6771 66.5723 85.2009 66.5723Z"
          stroke="#4F5154"
        />
        <path
          d="M43.3067 71.7742C43.3049 71.5593 43.6022 71.5043 43.6916 71.703L45.7754 76.3358C45.809 76.4106 45.8847 76.4592 45.9666 76.4587L51.3512 76.4221C51.5756 76.4206 51.6433 76.7282 51.4359 76.8066L46.4577 78.6881C46.382 78.7168 46.3328 78.7884 46.3335 78.8694L46.3739 83.8804C46.3757 84.0953 46.0784 84.1503 45.9891 83.9516L43.9052 79.3188C43.8716 79.244 43.796 79.1954 43.714 79.196L38.3295 79.2325C38.1051 79.234 38.0373 78.9264 38.2448 78.848L43.2229 76.9665C43.2987 76.9379 43.3478 76.8662 43.3472 76.7853L43.3067 71.7742Z"
          fill="#EEA530"
        />
        <path
          d="M36.8666 38.317C37.074 38.2315 37.2638 38.478 37.1064 38.6285L27.8462 47.481C27.7881 47.5365 27.7732 47.62 27.8086 47.692L33.3096 58.902C33.4044 59.0952 33.1471 59.2617 32.9803 59.1151L23.3016 50.6076C23.2394 50.553 23.1486 50.5378 23.0721 50.5694L10.8701 55.6008C10.6627 55.6863 10.4729 55.4398 10.6303 55.2894L19.8906 46.4368C19.9486 46.3813 19.9635 46.2978 19.9282 46.2258L14.4271 35.0158C14.3323 34.8226 14.5897 34.6562 14.7565 34.8028L24.4352 43.3102C24.4974 43.3649 24.5882 43.38 24.6647 43.3485L36.8666 38.317Z"
          fill={primaryDarkGreenColor}
        />
      </g>
      <path
        d="M109.129 2.06643C109.201 1.86158 109.501 1.89457 109.518 2.10919L110.096 9.39582C110.102 9.47669 110.157 9.54463 110.235 9.56758L117.74 11.7801C117.953 11.843 117.912 12.1557 117.689 12.1706L109.826 12.694C109.745 12.6995 109.673 12.7535 109.646 12.8307L107.206 19.7857C107.134 19.9906 106.834 19.9576 106.817 19.7429L106.24 12.4563C106.234 12.3754 106.178 12.3075 106.101 12.2845L98.5956 10.072C98.3822 10.0091 98.4231 9.69645 98.6467 9.68156L106.509 9.15809C106.591 9.15266 106.662 9.0986 106.689 9.02141L109.129 2.06643Z"
        fill="#EEA530"
      />
      <defs>
        <clipPath id="clip0_6135_69728">
          <rect width="93.8041" height="61.1567" fill="white" transform="translate(0 25.0801)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Payments4;
