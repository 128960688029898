import React from 'react';

import Layout from 'components/auth/Layout';
import { DetailsForm, DetailsFormSkeleton, LeftSection } from './components';
import { useSupplierBankDetails } from './hooks';

const SupplierBankDetails = () => {
  const {
    isCompleted,
    borrowerName,
    payeeName,
    payeeEmail,
    payeeType,
    addressLabel,
    isSupplierDataLoading,
    isLinkExpired,
    isSupplierDataError,
    submitBankDetails,
    submitErrors,
    isSubmitting,
    sumbittedBankDetails,
  } = useSupplierBankDetails();

  return (
    <Layout
      leftSectionCustomContent={
        <LeftSection requesterName={borrowerName} addressLabel={addressLabel} isCompleted={isCompleted} />
      }
      contentClassName="lg:tw-w-4/5 xl:tw-w-3/5 xl:tw-mt-20 tw-mb-10"
    >
      {isSupplierDataLoading ? (
        <DetailsFormSkeleton />
      ) : (
        <>
          {isLinkExpired && (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-py-16">
              <h1 className="tw-text-2xl tw-font-bold tw-mb-4">Link Expired</h1>
              <p className="tw-text-center tw-text-neutral-grey-1">
                The link you are trying to access has expired. Please contact your Payor to request a new link.
              </p>
            </div>
          )}
          {isSupplierDataError && (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full tw-py-16">
              <h1 className="tw-text-2xl tw-font-bold tw-mb-4">Error</h1>
              <p className="tw-text-center tw-text-neutral-grey-1">
                Something went wrong. Please contact your Payor to request a new link.
              </p>
            </div>
          )}
          {!isLinkExpired && !isSupplierDataError && (
            <DetailsForm
              borrowerName={borrowerName}
              payeeName={payeeName}
              payeeEmail={payeeEmail}
              payeeType={payeeType}
              isCompleted={isCompleted}
              submitBankDetails={submitBankDetails}
              submitErrors={submitErrors}
              isSubmitting={isSubmitting}
              sumbittedBankDetails={sumbittedBankDetails}
              addressLabel={addressLabel}
            />
          )}
        </>
      )}
    </Layout>
  );
};

export default SupplierBankDetails;
