import React from 'react';

const MappingTableHeader = ({ accountName }: { accountName: string }) => (
  <thead>
    <tr className="tw-text-neutral-grey-2">
      <th className="tw-font-normal tw-pb-2">Merchant</th>
      <th className="tw-font-normal tw-pb-2">{accountName} Vendor</th>
      <th className="tw-font-normal tw-pb-2">{accountName} Expense Accounts</th>
      <th className="tw-font-normal tw-pb-2" colSpan={2}>
        Loop Category
      </th>
    </tr>
  </thead>
);

export default MappingTableHeader;
