import { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';

import { PayPayeeContext } from 'components/payments/PayPayee/PayPayeeContext';
import useGetExchangeRate from 'hooks/useGetExchangeRate';
import { formatMoneyV2 } from 'utility/currency';
import { getTransferMethod, getInrReasonLabel } from 'utility/payments';
import { FXOperationType } from 'types/fxRates';
import { Money } from 'types/payments';
import { useDeepEffect } from 'hooks';
import { Currencies } from 'constants/currencies';
import { useGetCurrencyLabel } from '../../hooks';

const useReview = () => {
  const { paymentInfo, setPaymentInfo, error, setError } = useContext(PayPayeeContext);
  const {
    originalAmount,
    chargedAmount,
    buyOrSell,
    fromAccount,
    toAccount,
    isExternalAccount,
    reason,
    notes,
    needsConversion,
    sendConfirmationEmail,
    invoiceNumber,
    invoiceDate,
  } = paymentInfo || {};

  const fromAccountDisplayName = get(fromAccount, 'displayName', '');
  const toAccountDisplayName = get(toAccount?.record, 'displayName', '');
  const toAccountName = get(toAccount?.record, 'name', '');
  const toAccountEmail = get(toAccount?.record, 'email', '');
  const toAccountBankSwiftCode = get(toAccount, 'record.bankAccount.code');

  const transferMethod = getTransferMethod({
    fromCurrency: fromAccount?.currency,
    toCurrency: toAccount?.record?.currency,
    isSwiftCode: !!toAccountBankSwiftCode,
  });

  const formattedOriginalAmount = `${useGetCurrencyLabel(originalAmount?.currency)} ${formatMoneyV2(originalAmount)}`;

  const [expired, setExpired] = useState(false);

  const {
    rate,
    rateExpiresAt,
    buy,
    sell,
    getExchangeRate,
    loadingRate,
    exchangeRateReference,
    error: rateError,
  } = useGetExchangeRate();

  useDeepEffect(() => {
    if (!needsConversion) return;

    if (!fromAccount || !toAccount) return;
    if (loadingRate || !buyOrSell || !rate || !sell || !buy || !rateExpiresAt || !exchangeRateReference) return;

    const rateAmounts = [buy, sell] as Money<Currencies>[];
    const currentChargedAmount = rateAmounts.find(({ currency }) => currency === fromAccount.currency);
    const currentOriginalAmount = rateAmounts.find(({ currency }) => currency === toAccount.record.currency);

    if (!currentChargedAmount || !currentOriginalAmount) return;
    const currentRate = 1 / rate;

    setPaymentInfo((prevState) => ({
      ...prevState!,
      originalAmount: { amount: currentOriginalAmount.amount, currency: currentOriginalAmount.currency },
      chargedAmount: { amount: currentChargedAmount.amount, currency: currentChargedAmount.currency },
      rate: Number(currentRate.toFixed(5)),
      rateExpiresAt,
      exchangeRateReference,
    }));
  }, [fromAccount, toAccount, loadingRate, rate, buy, sell, buyOrSell, rateExpiresAt, exchangeRateReference]);

  const onRefreshRate = () => {
    if (!needsConversion || !toAccount || !fromAccount) return;

    const payload = {} as {
      buy: { currency: Currencies; amount?: number };
      sell: { currency: Currencies; amount?: number };
    };

    if (buyOrSell === FXOperationType.sell) {
      const amount = Number(chargedAmount?.amount);

      payload.buy = { currency: toAccount.record.currency };
      payload.sell = {
        currency: fromAccount.currency,
        amount,
      };
    } else {
      const amount = Number(originalAmount?.amount);

      payload.buy = { currency: toAccount.record.currency, amount };
      payload.sell = { currency: fromAccount.currency };
    }

    getExchangeRate({
      variables: payload,
      includeDate: isExternalAccount,
    });

    setExpired(false);
  };

  useEffect(() => {
    setError(rateError);
  }, [rateError]);

  const reasonLabel = originalAmount?.currency === 'INR' ? getInrReasonLabel(reason || '') : reason;

  return {
    fromAccountDisplayName,
    toAccountDisplayName,
    toAccountName,
    toAccountEmail,
    formattedOriginalAmount,
    rate: paymentInfo?.rate,
    rateExpiresAt: paymentInfo?.rateExpiresAt,
    reason: reasonLabel,
    notes,
    needsConversion,
    sendConfirmationEmail,
    chargedAmount,
    setExpired,
    originalAmount,
    invoiceNumber,
    invoiceDate,
    expired,
    onRefreshRate,
    loadingRate,
    buyOrSell,
    isRateLoading: loadingRate,
    transferMethod,
    error,
  };
};

export default useReview;
