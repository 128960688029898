import React from 'react';

type BankRefundedProps = {
  size?: number;
};

const BankRefunded = ({ size = 25 }: BankRefundedProps) => (
  <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.375"
      y="19.2122"
      width="20.1802"
      height="1.34302"
      rx="0.671512"
      fill="white"
      stroke="black"
      strokeWidth="0.75"
    />
    <rect x="2.46826" y="17.1191" width="15.9942" height="1.34302" fill="white" stroke="black" strokeWidth="0.75" />
    <rect x="2.46826" y="6.375" width="15.9942" height="1.34302" fill="white" stroke="black" strokeWidth="0.75" />
    <rect x="3.58447" y="7.96973" width="1.34302" height="8.9" fill="white" stroke="black" strokeWidth="0.75" />
    <rect x="9.60498" y="7.96973" width="1.34302" height="8.9" fill="white" stroke="black" strokeWidth="0.75" />
    <rect x="15.8633" y="7.96973" width="1.34302" height="8.9" fill="white" stroke="black" strokeWidth="0.75" />
    <path
      d="M10.0359 0.609171C10.4019 0.474321 10.8043 0.475632 11.1694 0.612863L20.0676 3.95716C20.4029 4.08319 20.625 4.40388 20.625 4.76211C20.625 5.23703 20.24 5.62203 19.7651 5.62203H1.23141C0.758427 5.62203 0.375 5.2386 0.375 4.76562C0.375 4.40682 0.598664 4.08606 0.935343 3.96202L10.0359 0.609171Z"
      fill="white"
      stroke="black"
      strokeWidth="0.75"
    />
    <g>
      <rect x="13.75" y="13.75" width="10.5" height="10.5" rx="5.25" fill="white" />
      <rect x="13.75" y="13.75" width="10.5" height="10.5" rx="5.25" stroke="black" strokeWidth="0.5" />
    </g>
    <path
      d="M15.5567 21.6576C15.6387 21.6713 15.7178 21.6151 15.7314 21.533L15.891 20.5802C16.5064 21.7062 17.7009 22.4189 19.0199 22.4189C20.9787 22.4189 22.5713 20.8507 22.5713 18.9238C22.5713 16.9969 20.9772 15.4286 19.0184 15.4286C17.6902 15.4286 16.4821 16.1489 15.8667 17.3069C15.8272 17.3814 15.856 17.4726 15.9305 17.5121C16.005 17.5516 16.0961 17.5227 16.1356 17.4482C16.6979 16.389 17.8027 15.7311 19.0184 15.7311C20.81 15.7326 22.2674 17.1641 22.2674 18.9238C22.2674 20.6835 20.81 22.115 19.0199 22.115C17.7814 22.115 16.6645 21.4297 16.1144 20.3523L17.2237 20.565C17.3058 20.5802 17.3848 20.5255 17.4 20.4419C17.4152 20.3614 17.362 20.2824 17.2814 20.2656L15.8393 19.9891C15.8302 19.9875 15.8211 19.986 15.8104 19.986C15.736 19.986 15.6737 20.0392 15.66 20.1122L15.4305 21.4798C15.4169 21.5634 15.4716 21.6424 15.5567 21.6576Z"
      fill="black"
      stroke="black"
      strokeWidth="0.3"
    />
  </svg>
);

export default BankRefunded;
