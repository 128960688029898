import React from 'react';
import { IoWarningOutline } from 'react-icons/io5';

import config from 'config';
import { CREDIT_CARD_STATUS } from 'constants/index';
import { useToggle } from 'hooks';
import CardActionButton from '../CardActionButton';
import ConfirmActionModal from '../ConfirmActionModal';

const ReportLostOrStolen = ({ creditCard }) => {
  const { isOpen: showReportModal, open: onReportCard, close: closeReportModal } = useToggle();

  const onRedirectToReportForm = () => {
    window.location.href = config.reportCardTypeFormUrl;
  };

  return (
    <>
      <CardActionButton
        label="Report Lost or Stolen"
        Icon={IoWarningOutline}
        onClick={onReportCard}
        className="tw-bg-secondary-light-yellow tw-text-primary-dark-yellow"
      />
      <ConfirmActionModal
        show={showReportModal}
        onClose={closeReportModal}
        creditCardId={creditCard.id}
        status={CREDIT_CARD_STATUS.lost.toUpperCase()}
        title={
          <div className="tw-flex tw-items-center tw-text-primary-dark-yellow">
            <IoWarningOutline size={16} />
            <strong className="tw-ml-2">Report lost or stolen</strong>
          </div>
        }
        description="The card will be reported as lost and deactivated."
        confirmLabel="Report and deactivate"
        onSuccess={onRedirectToReportForm}
      />
    </>
  );
};

export default ReportLostOrStolen;
