import React, { useContext } from 'react';

import { CardExpenseRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';
import { Currencies } from 'constants/currencies';
import { formatMoneyWithoutCurrencyLabel } from 'utility/currency';
import { formatDateTime } from 'utility/date';
import { Money } from 'types/payments';
import { CurrencyBadge } from 'components/Approvals/ApprovalsContent/components/UI';
import { capitalize } from 'utility/string';
import useIsMember from 'hooks/useIsMember';
import useIsAdmin from 'hooks/useIsAdmin';
import { ApprovalRequestsContext } from 'components/Approvals/ApprovalsContent/context/ApprovalRequests';

import { useToggle } from 'hooks';

const useCardExpenseDetail = ({ cardExpense }: { cardExpense?: CardExpenseRequestDataType }) => {
  const {
    amountRequested,
    transactionDate,
    status,
    requester,
    merchantName,
    receipt,
    description,
    fromLabel,
    dateApproved,
    declineReason,
  } = cardExpense || {};

  const formatAmountValue = (value?: Money<Currencies>) => {
    return (
      <div className="tw-flex tw-flex-row tw-gap-1">
        <div className="tw-font-semibold">{formatMoneyWithoutCurrencyLabel(value)}</div>
        <div>{value?.currency}</div>
      </div>
    );
  };

  const formattedAmountRequested = formatAmountValue(amountRequested) ?? '-';
  const formattedTransactionDate = formatDateTime(transactionDate, 'h:mm b, EEEE, MMMM d, yyyy');
  const formattedOriginalCurrency = amountRequested && <CurrencyBadge currency={amountRequested?.currency} />;
  const formattedDateApproved = formatDateTime(dateApproved, 'MMMM d, yyyy, h:mm b EEEE');

  const { isOpen: isOpenOverwriteReceipt, open: openOverwriteReceipt } = useToggle();
  const { isOpen: isOpenUnlinkDialog, open: openUnlinkDialog, close: closeUnlinkDialog } = useToggle();

  const { isMember } = useIsMember();
  const { isAdmin } = useIsAdmin();

  const { selectedTab } = useContext(ApprovalRequestsContext);

  return {
    amountRequested,
    fromLabel,
    merchantName,
    status: capitalize(status),
    transactionDate,
    receipt,
    description,
    requester,
    declineReason,
    currency: amountRequested?.currency as Currencies,
    isOpenOverwriteReceipt,
    openOverwriteReceipt,
    isOpenUnlinkDialog,
    openUnlinkDialog,
    closeUnlinkDialog,
    isAdmin,
    isMember,
    formattedAmountRequested,
    formattedTransactionDate,
    formattedOriginalCurrency,
    formattedDateApproved,
    selectedTab,
  };
};

export default useCardExpenseDetail;
