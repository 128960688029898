import React from 'react';

import { Transaction } from 'types/payments';
import { useTransactionDetails } from './hooks';

const TransactionDetails = ({ transaction }: { transaction: Transaction }) => {
  const { fromAccountName, toAccountName, methodName, formattedAmount, formattedFeesAmount } = useTransactionDetails({
    transaction,
  });

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <div>
        <div className="tw-text-sm tw-text-neutral-grey-1">Method</div>
        <p className="tw-mt-1">{methodName}</p>
      </div>
      <div>
        <div className="tw-text-sm tw-text-neutral-grey-1">Recipient Account</div>
        <p className="tw-mt-1">{toAccountName}</p>
      </div>
      <div>
        <div className="tw-text-sm tw-text-neutral-grey-1">From Account</div>
        <p className="tw-mt-1">{fromAccountName}</p>
      </div>
      <div>
        <div className="tw-text-sm tw-text-neutral-grey-1">Amount</div>
        <p className="tw-mt-1">{formattedAmount}</p>
      </div>
      <div>
        <div className="tw-text-sm tw-text-neutral-grey-1">Fees</div>
        <p className="tw-mt-1">{formattedFeesAmount}</p>
      </div>
    </div>
  );
};

export default TransactionDetails;
