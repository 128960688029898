import React, { useState } from 'react';
import { IoDownloadOutline } from 'react-icons/io5';

import StatementsContainer from 'containers/creditCards/statements/Statements';
import Button, { ButtonSize } from 'components/Button';

const DownloadStatements = () => {
  const [showStatementsDownloadModal, setShowStatementsDownloadModal] = useState(false);

  return (
    <>
      <div className="tw-flex tw-justify-center md:tw-justify-end tw-mt-6">
        <Button
          tertiary
          size={ButtonSize.sm}
          onClick={() => setShowStatementsDownloadModal(true)}
          className="tw-flex tw-items-center tw-text-primary-dark-green"
        >
          <IoDownloadOutline size={18} className="tw-mr-1" />
          Download Card Statements
        </Button>
      </div>
      <StatementsContainer type="Cards" show={showStatementsDownloadModal} setShow={setShowStatementsDownloadModal} />
    </>
  );
};

export default DownloadStatements;
