import { useContext, useCallback, useMemo } from 'react';

import useIsAdmin from 'hooks/useIsAdmin';
import useIsMember from 'hooks/useIsMember';
import { ReimbursementTableProps } from '../ReimbursementTable.types';
import { ApprovalRequestsContext } from 'components/Approvals/ApprovalsContent/context/ApprovalRequests';
import { APPROVAL_MODAL } from 'components/Approvals/constants';

const useReimbursementTable = ({ reimbursementRequestList }: ReimbursementTableProps) => {
  const {
    selectedRequestId,
    setSelectedRequestId,
    selectedTab,
    payees,
    wallets,
    lineOfCredit,
    bankAccounts,
    isOpenModal,
    openModal,
    closeModal,
    activeModal,
    setActiveModal,
  } = useContext(ApprovalRequestsContext);

  const { isAdmin } = useIsAdmin();
  const { isMember } = useIsMember();

  const noApprovalsAvailable = reimbursementRequestList.length <= 0;
  const viewOnly = !isAdmin && !isMember;

  const selectedRequest = useMemo(
    () => reimbursementRequestList.find(({ id }) => id === selectedRequestId),
    [reimbursementRequestList, selectedRequestId]
  );

  const handleOpenModal = useCallback(
    (id: String, modalType: APPROVAL_MODAL) => {
      setActiveModal(modalType);
      setSelectedRequestId(id);
      openModal();
    },
    [openModal]
  );

  const handleCloseModal = useCallback(() => {
    setSelectedRequestId(null);
    setActiveModal(null);
    closeModal();
  }, [closeModal]);

  return {
    selectedRequest,
    isOpenModal,
    handleOpenModal,
    handleCloseModal,
    activeModal,
    isMember,
    isAdmin,
    noApprovalsAvailable,
    selectedTab,
    payees,
    wallets,
    lineOfCredit,
    bankAccounts,
    viewOnly,
  };
};
export default useReimbursementTable;
