import { useContext, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_PAY_CARD_BALANCE, PAY_BALANCE_GET_GROUPED_CARD_INFO } from 'graphql/cards';
import { CardRepaymentContext } from 'context/CardRepayment';
import { cardRepaymentSteps } from 'components/payments/CardRepayment/constants';
import { useToggle } from 'hooks';

const useContent = () => {
  const {
    wallets,
    setWallets,
    bankAccounts,
    setBankAccounts,
    groupedCardsInfo,
    setGroupedCardsInfo,
    setStatementBalanceInCad,
    setStatementBalanceInUsd,
    setEurStatementBalanceInCad,
    setGbpStatementBalanceInCad,
    setUsdStatementBalanceInCad,
    setPayBalanceInfo,
    setPayBalanceTransactions,
    error,
    setError,
    isLoading,
    setIsLoading,
  } = useContext(CardRepaymentContext);

  const [initialStep] = cardRepaymentSteps.Details;

  const [currentStep, setCurrentStep] = useState(initialStep);
  const { isOpen: isCompleted, open: onComplete, close: onResetComplete } = useToggle();

  const [getPayCardBalance, { loading: isGetCardBalanceLoading }] = useLazyQuery(GET_PAY_CARD_BALANCE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { me } = data || {};
      const wallets = get(me, 'account.wallets') || [];
      const bankAccounts = get(me, 'account.bankAccounts') || [];
      const creditCard = get(me, 'account.creditCard') || {};
      const {
        statementBalanceInCad,
        statementBalanceInUsd,
        eurStatementBalanceInCad,
        gbpStatementBalanceInCad,
        usdStatementBalanceInCad,
      } = creditCard;

      setWallets(wallets);
      setBankAccounts(bankAccounts);
      setStatementBalanceInCad(statementBalanceInCad);
      setStatementBalanceInUsd(statementBalanceInUsd);
      setEurStatementBalanceInCad(eurStatementBalanceInCad);
      setGbpStatementBalanceInCad(gbpStatementBalanceInCad);
      setUsdStatementBalanceInCad(usdStatementBalanceInCad);
    },
  });

  const [getPayBalanceGroupedCardInfo, { loading: isPayBalanceGroupedCardInfoLoading }] = useLazyQuery(
    PAY_BALANCE_GET_GROUPED_CARD_INFO,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const groupedCardsInfo = get(data, 'primaryCreditCard');
        setGroupedCardsInfo(groupedCardsInfo);
      },
    }
  );

  const onPrevStep = () => {
    setCurrentStep((prevStep) => (prevStep === 0 ? prevStep : prevStep - 1));
  };

  const onNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const onResetSteps = () => {
    setCurrentStep(initialStep);
    onResetComplete();
    setError(null);
    getPayCardBalance();
    getPayBalanceGroupedCardInfo();
    setPayBalanceInfo(null);
    setPayBalanceTransactions([]);
  };

  // show error on first step
  const errorSteps = error ? error?.step || [initialStep] : [];

  const isEmptyState = !groupedCardsInfo;
  const noWallets = !wallets.length;
  const noBankAccounts = !bankAccounts.length;

  useEffect(() => {
    setIsLoading(isGetCardBalanceLoading || isPayBalanceGroupedCardInfoLoading);
  }, [setIsLoading, isGetCardBalanceLoading, isPayBalanceGroupedCardInfoLoading]);

  useEffect(() => {
    getPayCardBalance();
    getPayBalanceGroupedCardInfo();
  }, [getPayCardBalance, getPayBalanceGroupedCardInfo]);

  return {
    currentStep,
    onPrevStep,
    onNextStep,
    onResetSteps,
    isCompleted,
    onComplete,
    errorSteps,
    isLoading,
    isEmptyState,
    noWallets,
    noBankAccounts,
  };
};

export default useContent;
