import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const CardsWaitingRoom = ({ width = '199', height = '165', vbWidth = '199', vbHeight = '165', ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      {...other}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M141.143 126.2V50.1181C141.143 48.1172 139.657 46.4951 137.822 46.4951H124.538C122.704 46.4951 121.217 48.1172 121.217 50.1181V126.2"
        fill="#EED9F7"
      />
      <path
        d="M114.576 126.2V72.3084C114.576 70.8911 113.089 69.7422 111.255 69.7422H97.9704C96.1363 69.7422 94.6494 70.8911 94.6494 72.3084V126.2"
        fill="#EED9F7"
      />
      <path
        d="M88.0073 126.199V91.3285C88.0073 90.4114 86.5204 89.668 84.6862 89.668H71.4021C69.5679 89.668 68.0811 90.4114 68.0811 91.3285V126.199"
        fill="#EED9F7"
      />
      <path
        d="M131.181 126.199V56.4578C131.181 54.6236 129.694 53.1367 127.86 53.1367H114.576C112.742 53.1367 111.255 54.6236 111.255 56.4578V126.199"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.612 126.199V79.7048C104.612 77.8707 103.125 76.3838 101.291 76.3838H88.0071C86.1729 76.3838 84.686 77.8707 84.686 79.7048V126.199"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.0444 126.199V102.952C78.0444 101.118 76.5575 99.6309 74.7233 99.6309H61.4392C59.605 99.6309 58.1182 101.118 58.1182 102.952V126.199"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.4756 126.199H137.822"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.5367 38.5618C89.5367 42.9849 88.4786 47.3438 86.4507 51.2747C84.4229 55.2055 81.4841 58.5942 77.8797 61.1579C74.2753 63.7216 70.11 65.3858 65.7314 66.0116C61.3528 66.6375 56.888 66.2068 52.7098 64.7555"
        stroke="#4F5154"
        strokeLinecap="round"
      />
      <path
        d="M45.2381 16.3288C48.9646 13.5514 53.3294 11.7553 57.9314 11.1054C62.5334 10.4555 67.2249 10.9728 71.5748 12.6097C75.9246 14.2466 79.7933 16.9507 82.8251 20.4732C85.8569 23.9958 87.9547 28.224 88.9256 32.7691"
        stroke="#4F5154"
        strokeLinecap="round"
      />
      <path
        d="M47.944 62.5748C44.4222 60.5415 41.3908 57.7585 39.0645 54.423C36.7383 51.0875 35.1741 47.2812 34.483 43.2737C33.7918 39.2663 33.9906 35.1559 35.0651 31.2339C36.1397 27.3119 38.0638 23.6742 40.701 20.5787"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.421 17.3396L45.2031 16.5508L45.992 10.3329"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.0741 56.8276L49.0459 63.0195L42.854 63.9914"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.0517 41.5184L89.2852 36.8965L93.9071 41.13"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187.025 120.322C187.237 120.263 187.375 120.54 187.2 120.674L171.425 132.752C171.36 132.802 171.332 132.886 171.353 132.964L176.648 152.114C176.707 152.326 176.431 152.464 176.297 152.289L164.218 136.513C164.169 136.449 164.085 136.42 164.006 136.442L144.856 141.737C144.645 141.796 144.507 141.519 144.682 141.386L160.457 129.307C160.522 129.258 160.55 129.174 160.528 129.095L155.233 109.945C155.175 109.733 155.451 109.596 155.585 109.77L167.663 125.546C167.713 125.611 167.797 125.639 167.875 125.617L187.025 120.322Z"
        fill="#8F5CCF"
      />
      <path
        d="M24.0846 75.3095C24.2668 75.1866 24.4852 75.405 24.3622 75.5872L19.4048 82.9344C19.3592 83.002 19.3592 83.0905 19.4048 83.1581L24.3622 90.5054C24.4852 90.6876 24.2668 90.906 24.0846 90.783L16.7374 85.8256C16.6698 85.78 16.5812 85.78 16.5136 85.8256L9.16641 90.783C8.98416 90.906 8.76579 90.6876 8.88876 90.5054L13.8461 83.1581C13.8918 83.0905 13.8918 83.002 13.8461 82.9344L8.88876 75.5872C8.76579 75.4049 8.98416 75.1866 9.16641 75.3095L16.5136 80.2669C16.5812 80.3126 16.6698 80.3125 16.7374 80.2669L24.0846 75.3095Z"
        fill={primaryDarkGreenColor}
      />
    </svg>
  );
};

export default CardsWaitingRoom;
