export const PAYOR_ONBOARDING_STEPS: { [key: string]: [number] } = {
  Registration: [0],
  'Connect Your Bank': [1],
  'Confirm Payment': [2],
};

export const PAYOR_ONBOARDING_STEP_URLS = {
  connect_bank: '/payor/onboarding/connect-bank',
  confirm_payment: '/payor/onboarding/confirm-payment',
};
