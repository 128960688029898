import React from 'react';

const TableSkeleton = () => {
  const COLUMNS = 5;
  return (
    <div>
      {Array(COLUMNS)
        .fill(0)
        .map((_, i) => (
          <div className="tw-p-3 tw-w-full" key={i}>
            <div className="tw-flex tw-w-full tw-animate-pulse tw-bg-neutral-grey-4 tw-h-12 tw-rounded" />
          </div>
        ))}
    </div>
  );
};

export default TableSkeleton;
