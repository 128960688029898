import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormData, UseEditSpendingLimitsModalProps } from '../EditSpendingLimitsModal.types';
import { formatMoneyV2, centsFromMoneyString } from 'utility/currency';

const useEditSpendManagementModal = ({
  closeModal,
  handleUpdateSettings,
  limitType,
  limitAmount,
  currency,
}: UseEditSpendingLimitsModalProps) => {
  const form = useForm<FormData>({
    defaultValues: {
      limitType,
      limitAmount: currency ? formatMoneyV2({ amount: limitAmount, currency }) : undefined,
    },
  });

  const { handleSubmit, setError, clearErrors, setValue } = form;

  const handleCloseModal = useCallback(() => {
    clearErrors();
    closeModal();
  }, [clearErrors, closeModal]);

  const onSubmit = useCallback(
    async ({ limitType, limitAmount }: FormData) => {
      clearErrors();
      const limitAmountCents = centsFromMoneyString(limitAmount);

      if (!limitAmountCents && limitType) {
        setError('limitAmount', { type: 'manual', message: 'Amount must be greater than $0.00' });
        return;
      }

      if (!limitType && limitAmountCents) {
        setError('limitType', { type: 'manual', message: 'Select limit type' });
        return;
      }

      await handleUpdateSettings({
        limitType,
        limitAmountCents,
      });
    },
    [handleUpdateSettings]
  );

  useEffect(() => {
    setValue('limitType', limitType, { shouldDirty: true });
    setValue('limitAmount', currency ? formatMoneyV2({ amount: limitAmount, currency }) : undefined, {
      shouldDirty: true,
    });
  }, [setValue, limitType, limitAmount, currency]);

  return {
    handleCloseModal,
    form,
    handleSubmit,
    onSubmit,
  };
};

export default useEditSpendManagementModal;
