import React, { useContext, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';

import { AccountingServiceWalletTransactionsContext } from 'context/AccountingServicesWalletTransactions';
import WalletTransactionHeader from './WalletTransactionHeader';
import { WalletTransactionsTableSkeleton } from './AccountingServiceTxTableSkeleton';
import WalletTransactionRow from './WalletAccountingTxnRow';
import Button from 'components/Button';

import { GET_WALLET_TRANSACTIONS, PUSH_QBO_TRANSACTIONS } from 'graphql/accountingServices';
import { Checkbox } from './Checkbox';
import { WalletTransactionsFilter } from '../filter/WalletTransactionsFilter';
import './AccountingServiceTxTable.css';
import { WalletTransactionsFilterContext } from '../context/WalletTransactionsFilterContext';
import { AssetRevenueAccounts } from 'components/settings/AccountingServices/AssetRevenueAccounts';

export const AccountingServiceWalletTxTable = ({ accountName }) => {
  const [qboTransactions, setQboTransactions] = useState([]);
  const [loadingTx, setLoading] = useState(true);

  const [loadQboTransactions] = useLazyQuery(GET_WALLET_TRANSACTIONS, {
    onCompleted: (data) => {
      setQboTransactions(data.accountingIntegrationWalletTransactions);
      loadingTx && setLoading(false);
    },
  });

  const { startDate, endDate, postedStatus, currency } = useContext(WalletTransactionsFilterContext);
  useEffect(() => {
    loadingTx && loadQboTransactions({ variables: { startDate, endDate, postedStatus, currency } });
  }, [loadingTx]);

  const {
    selectedTransactionsToPost,
    setSelectedTransactionsToPost,
    parseTransactionToPost,
    selectAllToPost,
    setSelectAllToPost,
    resetContext,
    isTransactionSelectableToPost,
    getLiabilityAccountFromCurrency,
  } = useContext(AccountingServiceWalletTransactionsContext);

  const hasLiabilityAccountForCurrency = getLiabilityAccountFromCurrency(currency);

  const handleSelectAllToPost = (evt) => {
    const { checked } = evt.currentTarget;

    if (checked) {
      const allTxToPost = qboTransactions
        .filter((transaction) => isTransactionSelectableToPost(transaction))
        .map(({ transactionId }) => transactionId);
      setSelectedTransactionsToPost(allTxToPost);
      if (allTxToPost.length > 0) setSelectAllToPost(checked);
    } else {
      setSelectedTransactionsToPost([]);
      setSelectAllToPost(checked);
    }
  };

  const [pushTransactions, { loading }] = useMutation(PUSH_QBO_TRANSACTIONS, {
    variables: {
      transactions: qboTransactions
        .filter(({ transactionId }) => selectedTransactionsToPost.includes(transactionId))
        .map((transaction) => parseTransactionToPost(transaction)),
    },
    refetchQueries: [{ query: GET_WALLET_TRANSACTIONS, variables: { startDate, endDate, postedStatus, currency } }],
  });

  const handlePush = async (evt) => {
    evt.preventDefault();
    if (selectedTransactionsToPost) {
      const { data: pushAccountingIntegrationTransactions } = await pushTransactions();
      if (pushAccountingIntegrationTransactions) {
        toast.success(`Transactions Pushed to ${accountName} Successfully`);
        resetContext();
      } else {
        throw new Error('Error return false while pushing');
      }
    } else {
      console.error('no transaction selected to push, somethig is wrong');
    }
  };

  if (loadingTx) return <WalletTransactionsTableSkeleton />;

  return (
    <div className="tw-flex tw-flex-col">
      <h3 className="tw-font-semibold">Push Loop Account Transactions to {accountName}</h3>
      <small className="tw-pt-2 tw-text-neutral-grey-2">
        Select the transactions that you want to push and click the “Push to {accountName}" button when ready.{' '}
      </small>
      <small className="tw-pb-2 tw-text-neutral-grey-2">
        You can also change the {accountName} account, sales tax and date if you need to do so before posting.
      </small>
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-py-2">
        <div className="tw-text-sm tw-text-neutral-grey-2">Filter Transactions by: </div>
        <WalletTransactionsFilter className="tw-p-2" setTransactions={setQboTransactions} />
      </div>

      {!hasLiabilityAccountForCurrency && (
        <div>
          <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-1">
            For each Loop (Bank) Account, select the {accountName} asset/revenue account that you want to push
            transactions to. If you don’t have an existing account for a given (Bank) account, click the Create{' '}
            {accountName} Account button, and reload the app once you are done creating it on {accountName}.
          </div>
          <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-2 tw-mb-5">
            Changing the mapping below will only affect transactions pushed to {accountName} in the future, it will not
            override any data that has already been synced to {accountName}.
          </div>
          <div className="tw-text-semantic-warning tw-text-sm">
            Also, please make sure you complete both the Card and Account related integrations before pushing your
            transactions.
          </div>
          <AssetRevenueAccounts className="tw-w-3/4" accountName={accountName} currency={currency} showAction={false} />
        </div>
      )}

      {hasLiabilityAccountForCurrency && qboTransactions.length === 0 && (
        <div className="tw-my-20 tw-text-neutral-grey-2 tw-text-center">No Transactions with this filter</div>
      )}
      {hasLiabilityAccountForCurrency && qboTransactions.length > 0 && (
        <>
          <div className="tw-flex tw-justify-between">
            <small className="tw-flex tw-flex-row">
              You are pushing to &nbsp;<u>{hasLiabilityAccountForCurrency.name}</u>
            </small>
            <small>
              <Checkbox
                className="tw-flex tw-flex-row"
                label="Select all pending"
                checked={selectAllToPost}
                onChange={handleSelectAllToPost}
              />
            </small>
          </div>
          <div className="gridTable gridTableWalletTransactions">
            <WalletTransactionHeader accountName={accountName} className="tw-contents" />

            <ReactTooltip multiline />

            {qboTransactions.map((transaction) => {
              return (
                <WalletTransactionRow
                  transaction={transaction}
                  accountName={accountName}
                  key={transaction?.transactionId}
                  {...transaction}
                />
              );
            })}
          </div>
          <div className="tw-sticky tw-bottom-0 tw-flex tw-flex-row tw-justify-end tw-mt-2 ">
            <Button
              primary
              isDisabled={selectedTransactionsToPost.length === 0}
              onClick={handlePush}
              isFetching={loading}
            >
              Push to {accountName}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
