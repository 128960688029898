import React from 'react';

import { Completed, Details, Header } from './components';
import { LeftSectionProps } from './LeftSection.types';

const LeftSection = ({ isCompleted, requesterName, addressLabel }: LeftSectionProps) => {
  return (
    <>
      <Header />
      {isCompleted ? <Completed /> : <Details requesterName={requesterName} addressLabel={addressLabel} />}
    </>
  );
};

export default LeftSection;
