import React, { useContext, useState } from 'react';
import { MAX_EXPORT_TRANSACTIONS, CAUSES_EXPORT_FAILS } from 'constants/index';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import { EXPORT_TRANSACTIONS_API } from 'graphql/transactionsFilter';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import Button from 'components/Button';
import { TransactionsContext } from 'context/Transactions';
import { FilterContext } from 'components/Transactions/context/FilterContext';

const ExportsEmailModal = ({ showModal, handleClose, email, fileType, cause }) => {
  const { productType } = useContext(TransactionsContext);
  const { period, currencies, categories, loopProducts } = useContext(FilterContext);
  const [codeError, setCodeError] = useState();

  const [exportByEmail, { loading, error }] = useMutation(EXPORT_TRANSACTIONS_API);

  const handleSendEmail = (evt) => {
    evt.preventDefault();

    exportByEmail({
      variables: {
        startDate: period?.from,
        endDate: period?.to,
        currencies,
        categories: categories?.map((cat) => cat.transactionCategoryId),
        loopProducts,
        products: [productType],
        exportType: fileType?.toLowerCase(),
      },
      onError: (e) => {
        toast.error('Error sending email', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error(error, e);
        setCodeError(error);
      },
      onCompleted: (data) => {
        const done = get(data, 'addTransactionsExportRequest');
        if (done) {
          toast.success('Sent email with transactions export ', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleClose();
        }
      },
    });
  };

  return (
    <Modal show={showModal} onClose={handleClose}>
      <div className="tw-flex tw-flex-row tw-justify-between tw-p-4 tw-border-b tw-border-neutral-grey-4">
        <div>Export Transactions</div>
        <Close role="button" className="tw-cursor-pointer" onClick={handleClose} />
      </div>
      <div className="tw-p-4">
        {cause === CAUSES_EXPORT_FAILS.tooMany && (
          <>
            <p>{`There are currently more than ${MAX_EXPORT_TRANSACTIONS}  transactions displayed with the current filters applied. You can add more filters to reduce the number of transactions.`}</p>
            <p className="tw-my-4">
              <strong>OR</strong>
            </p>
            <p>Loop can email you the export (may take up to 1 hour).</p>
          </>
        )}

        {cause === CAUSES_EXPORT_FAILS.retrieving && (
          <>
            <p>Sorry. We are having problems downloading the transactions. </p>
            <p>As another option, Loop can email you the export (may take up to 1 hour). </p>
          </>
        )}

        <br></br>
        <p>
          The email with your export will be sent to
          <strong> {email}</strong>
        </p>
      </div>
      <div className="tw-flex tw-p-4 tw-justify-between tw-border-t tw-border-neutral-grey-4">
        <Button secondary onClick={handleClose}>
          Try Filtering
        </Button>
        <Button primary onClick={handleSendEmail} isDisabled={loading}>
          Send By Email
        </Button>
      </div>
      {codeError && <div className="tw-text-sm tw-text-semantic-error">{codeError.message}</div>}
    </Modal>
  );
};

export default ExportsEmailModal;
