import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { GET_PAYEES } from 'graphql/payees';
import { Loaders } from 'components/cards/Loader';
import Header from './Header';
import PayeesContent from './PayeesContent';
import EmptyState from './EmptyState';
import LineOfCredit from 'domain/lineOfCredit';

const PaymentsSummary = ({ wallets, lineOfCredit, loadingProducts }) => {
  const { loading, data } = useQuery(GET_PAYEES, { fetchPolicy: 'network-only' });
  const payees = _.get(data, 'payees') || [];

  const hasPayees = payees?.length > 0;

  return (
    <>
      <div className="tw-bg-neutral-light tw-border-neutral-grey-4 tw-shadow-md tw-border tw-rounded-md tw-mt-4 tw-w-full">
        <Header />
        {loading && (
          <div className="tw-p-4 tw-flex tw-justify-center">
            <Loaders.Spinner />
          </div>
        )}
        {!loading &&
          (hasPayees ? (
            <PayeesContent
              payees={payees}
              wallets={wallets}
              loadingProducts={loadingProducts}
              lineOfCredit={lineOfCredit && new LineOfCredit(lineOfCredit)}
            />
          ) : (
            <EmptyState />
          ))}
      </div>
    </>
  );
};

export default PaymentsSummary;
