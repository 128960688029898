import React from 'react';

import { Modal } from 'components/UI';
import { AddPayorModalProps } from './AddPayorModal.types';
import { AddPayor } from './components';
import { AddPayorContextProvider } from 'components/Invoices/contexts/AddPayorContext';

const AddPayorModal = ({ show, onClose }: AddPayorModalProps) => {
  return (
    <Modal show={show} onClose={onClose} title="Add Payor">
      <div className="tw-flex tw-flex-col tw-pt-4 tw-border-t tw-border-neutral-grey-4">
        <AddPayorContextProvider>
          <AddPayor onFinish={onClose} />
        </AddPayorContextProvider>
      </div>
    </Modal>
  );
};

export default AddPayorModal;
