import React from 'react';

import { Details, Complete } from './components';
import { StepsProps } from './Steps.types';

const Steps = ({ currentStep, onNextStep, onResetSteps }: StepsProps) => {
  switch (currentStep) {
    case 0:
      return <Details onNextStep={onNextStep} />;
    case 1:
      return <Complete onResetSteps={onResetSteps} />;
    default:
      return null;
  }
};

export default Steps;
