import React, { useEffect, useState } from 'react';
import qs from 'query-string';

import { useToggle } from 'hooks';
import { TRANSACTION_PRODUCT_TYPES } from 'constants/index';

export const TransactionsContext = React.createContext({});

export const TransactionsContextProvider = ({ children, location }) => {
  const { productType: typeFromQuery } = qs.parse(location?.search);

  const [transactions, setTransactions] = useState(null);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [productType, setProductType] = useState(typeFromQuery || TRANSACTION_PRODUCT_TYPES.creditCard);
  const [transactionToCategorize, setTransactionToCategorize] = useState();

  const { isOpen: isOpenCategoryModal, open: openCategoryModal, close: closeCategoryModal } = useToggle();

  useEffect(() => {
    const { productType } = qs.parse(location?.search);
    setProductType(productType || TRANSACTION_PRODUCT_TYPES.creditCard);
  }, [location]);

  return (
    <TransactionsContext.Provider
      value={{
        transactions,
        setTransactions,
        selectedTransactionId,
        setSelectedTransactionId,
        productType,
        setProductType,
        isOpenCategoryModal,
        openCategoryModal,
        closeCategoryModal,
        transactionToCategorize,
        setTransactionToCategorize,
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
};
