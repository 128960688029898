import React from 'react';

import AddReimbursementRequestDetails from './AddReimbursementRequestDetails';
import { StepsProps } from './Steps.types';
import ReviewReimbursementRequestDetails from './ReviewReimbursementRequestDetails';
import CompleteReimbursementRequestDetails from './CompleteReimbursementRequestDetails/CompleteReimbursementRequestDetails';

const Steps = ({ currentStep, onNextStep, onPrevStep, onFinish, onResetSteps }: StepsProps) => {
  switch (currentStep) {
    case 1:
      return <ReviewReimbursementRequestDetails onNextStep={onNextStep} onPrevStep={onPrevStep} />;
    case 2:
      return <CompleteReimbursementRequestDetails onFinish={onFinish} onResetSteps={onResetSteps} />;
    default:
      return <AddReimbursementRequestDetails onNextStep={onNextStep} onFinish={onFinish} />;
  }
};

export default Steps;
