import React from 'react';

type CreditCardPlusProps = {
  width?: number;
  height?: number;
};

const CreditCardPlus = ({ width = 56, height = 57 }: CreditCardPlusProps) => (
  <svg width={width} height={height} viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4.5" width="48" height="48" rx="24" fill="#D0E7DA" />
    <path
      d="M35 37.5V31.5M32 34.5H38M38 26.5H18M38 28.5V24.7C38 23.5799 38 23.0198 37.782 22.592C37.5903 22.2157 37.2843 21.9097 36.908 21.718C36.4802 21.5 35.9201 21.5 34.8 21.5H21.2C20.0799 21.5 19.5198 21.5 19.092 21.718C18.7157 21.9097 18.4097 22.2157 18.218 22.592C18 23.0198 18 23.5799 18 24.7V32.3C18 33.4201 18 33.9802 18.218 34.408C18.4097 34.7843 18.7157 35.0903 19.092 35.282C19.5198 35.5 20.0799 35.5 21.2 35.5H28"
      stroke="#245951"
      strokeOpacity="0.9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="4" y="4.5" width="48" height="48" rx="24" stroke="#E8F3ED" strokeWidth="8" />
  </svg>
);

export default CreditCardPlus;
