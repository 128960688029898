import React from 'react';

import Carousel from 'components/Carousel';
import { useIsMobile } from 'hooks';

const ContentWrapper = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className="tw-mt-4 tw--ml-2 tw--mr-4">
        <Carousel slidesPerView={1.3} alignment="left" showBottomControls showArrows={false}>
          {children}
        </Carousel>
      </div>
    );
  }

  return <div className="tw-flex tw-gap-2 xl:tw-gap-6 tw-mt-6 tw-mb-3">{children}</div>;
};

export default ContentWrapper;
