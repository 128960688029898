import React, { useEffect } from 'react';
import qs from 'query-string';
import { ApolloError, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import { AUTH_EMAIL_VERIFICATION } from 'graphql/user';
import Layout from '../Layout';

const emailVerifiedMessage = (isSubmitting: boolean, error?: ApolloError) => {
  if (isSubmitting) {
    return 'Verifying email address';
  } else if (error) {
    return "We can't verify your email as this verification link expired.";
  } else {
    return 'Email successfully verified';
  }
};

const EmailVerification = () => {
  const { sgid: signedId } = qs.parse(location.search) as {
    sgid?: string;
  };

  const [mutation, { loading: isSubmitting, error }] = useMutation(AUTH_EMAIL_VERIFICATION);

  useEffect(() => {
    (async () => {
      await mutation({ variables: { sgid: signedId } });
    })();
  }, []);

  const message = emailVerifiedMessage(isSubmitting, error);
  const emailVerified = message == 'Email successfully verified';

  return (
    <Layout leftSectionText="">
      <h1 className="tw-mb-3">Email verification</h1>
      <div>
        <p>{message}</p>
        {emailVerified && (
          <Link to="/">
            <strong className="tw-text-primary-dark-green">Go to the dashboard</strong>
          </Link>
        )}
      </div>
    </Layout>
  );
};

export default EmailVerification;
