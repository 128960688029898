import React from 'react';
import cx from 'classnames';

const FieldLabel = ({
  name,
  label,
  required,
  className = 'tw-text-neutral-grey-1',
}: {
  name?: string;
  label: string;
  required?: boolean;
  className?: string;
}) => (
  <label htmlFor={name} className={cx('tw-text-sm tw-cursor-pointer', className)}>
    {label}
    {required && <span className="tw-text-semantic-error">&nbsp;*</span>}
  </label>
);

export default FieldLabel;
