import React, { useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { ADD_CREDIT_CARD_PAYEE, GET_PAYEES } from 'graphql/payees';
import { AddedFile, HookDropZone } from 'components/uploader/HookDropzone';
import { AddPayeeContext } from 'components/payments/AddPayee/AddPayeeContext';
import Button from 'components/Button';
import { Loaders } from 'components/cards/Loader';

const AddCreditCardDocument = ({ onFinish, onPreviousStep, setError }) => {
  const { creditCard, setCreditCard } = useContext(AddPayeeContext);

  const form = useForm();

  const { handleSubmit } = form;

  const [addPayee, { loading: isAdding, error }] = useMutation(ADD_CREDIT_CARD_PAYEE, {
    refetchQueries: [{ query: GET_PAYEES }],
    awaitRefetchQueries: true,
  });
  const [file, setFile] = useState(creditCard.file ? creditCard.file : null);

  const onSubmit = async () => {
    try {
      await addPayee({ variables: { ...creditCard, blobSignedId: file, currency: 'CAD' } });
      toast.success('Payee was added successfully');
      onFinish();
    } catch (err) {
      toast.error('Error adding Payee - please try again');
      console.error(err);
      setError(err);
      onPreviousStep();
    }
  };

  const handleFilesAdded = (files) => {
    if (files && files.length > 0) {
      setFile(files[0]);
      setCreditCard({ ...creditCard, file: files[0] });
    }
  };

  const handleRemoveFile = () => setFile(null);

  return (
    <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-p-8 tw-space-y-8">
          {isAdding ? (
            <div className="tw-text-center">
              <Loaders.Spinner />
            </div>
          ) : (
            <>
              <strong>Upload a Copy of Your Most Recent Credit Card Bill</strong>
              <div>
                {file ? (
                  <AddedFile file={file} onRemoveFile={handleRemoveFile} />
                ) : (
                  <HookDropZone onFilesAdded={handleFilesAdded} />
                )}
              </div>
            </>
          )}
        </div>
        <div className="tw-flex tw-py-5 tw-px-5 tw-justify-between tw-border-t tw-border-neutral-grey-3">
          <Button type="button" secondary className="tw-px-6" onClick={onPreviousStep} data-testid="prev-step-btn">
            Back
          </Button>
          <Button
            type="submit"
            className={`${
              isAdding ? 'tw-bg-neutral-grey-3' : 'tw-bg-primary-dark-green'
            } tw-text-neutral-light tw-rounded-md tw-py-2 tw-px-6 tw-flex tw-flex-row tw-items-center`}
            disabled={isAdding}
            data-testid="add-payee-btn"
          >
            {!isAdding ? 'Add Payee' : 'Adding Payee'}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddCreditCardDocument;
