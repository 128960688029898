import React from 'react';

import { Modal } from 'components/UI';
import Close from 'components/svg/Close';
import { FixedSuffixField, SubmitButton } from 'components/FormFields/v2';
import config from 'config';
import { useConfigureModal } from '../hooks';
import { FormProvider } from 'react-hook-form';

const ConfigureModal = ({
  show,
  onClose,
  existingLoopDomainEmail,
}: {
  show: boolean;
  onClose: () => void;
  existingLoopDomainEmail?: string;
}) => {
  const { form, error, onSubmit, ref, inputRef, isSubmitting, isDirty } = useConfigureModal({
    show,
    existingLoopDomainEmail,
  });

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-flex-col tw-justify-center">
        <div className="tw-flex tw-justify-between tw-p-6">
          <div>Configure Email</div>
          <Close className="tw-cursor-pointer" onClick={onClose} />
        </div>
        <hr className="tw-bg-neutral-grey-4" />
        <div className="tw-mx-10 tw-mb-4">
          <div className="tw-my-6">Enter a custom Loop Receipts email address.</div>
          <FormProvider {...form} {...{ graphQLErrors: error?.graphQLErrors }}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="tw-mt-4">
              <div className="tw-flex tw-flex-col">
                <FixedSuffixField
                  name="loopEmailLocalPart"
                  placeholder="yourBusinessName"
                  ref={(e) => {
                    ref(e);
                    inputRef.current = e;
                  }}
                  inputSuffix={`@${config.uploadCustomerFilesEmailDomain}`}
                  className="tw-text-right tw-pr-0"
                />
                <hr className="tw-bg-neutral-grey-4" />
                <div className="tw-flex tw-flex-row tw-mt-4 tw-justify-end">
                  <SubmitButton disabled={!isDirty || isSubmitting} className="tw-font-semibold tw-h-11">
                    {isSubmitting ? 'Submitting...' : 'Configure'}
                  </SubmitButton>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </Modal>
  );
};

export default ConfigureModal;
