import React from 'react';

import Layout from 'components/onboarding/Layout';

import { Progress } from 'components/UI';
import { GUARANTOR_STEPS, GUARANTOR_STEP_LABELS } from './constants';

import GuarantorPersonaVerification from './GuarantorPersonaVerification';

const GuarantorVerification = () => {
  return (
    <Layout>
      <div className="tw-max-w-screen-sm">
        <Progress currentStepIndex={1} stepLabelsAndIndexes={GUARANTOR_STEPS} classNames="tw-w-full tw-mb-16" />
        <h1>{GUARANTOR_STEP_LABELS.idVerification.title}</h1>
        <GuarantorPersonaVerification />
      </div>
    </Layout>
  );
};

export default GuarantorVerification;
