import React, { useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { Transition, Listbox } from '@headlessui/react';
import { BsChevronDown } from 'react-icons/bs';

import { TransactionsContext } from 'context/Transactions';
import { GET_CREDIT_CARDS_FOR_FILTER, GET_WALLETS_FOR_FILTER } from 'graphql/transactionsFilter';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { TRANSACTION_PRODUCT_TYPES } from 'constants/index';
import { Loaders } from 'components/cards/Loader';

const ProductsDropdown = ({
  label,
  selectedValues,
  setSelectedValues,
  className,
  testId,
  onChangeCurrentBillingCycle,
  onChangePreviousBillingCycle,
}) => {
  // data
  const { productType } = useContext(TransactionsContext);

  const [getCards, { data: creditCardData, loading: loadingCreditCards }] = useLazyQuery(GET_CREDIT_CARDS_FOR_FILTER);
  const [getWallets, { data: walletsData, loading: loadingWallets }] = useLazyQuery(GET_WALLETS_FOR_FILTER);

  useEffect(() => {
    if (productType === TRANSACTION_PRODUCT_TYPES.creditCard) getCards();
    if (productType === TRANSACTION_PRODUCT_TYPES.wallet) getWallets();
  }, [productType]);

  const creditCards = get(creditCardData, 'me.account.creditCards') || [];
  const wallets = get(walletsData, 'me.account.wallets') || [];

  // building options from data
  const cardsOptions = creditCards.map((cc) => {
    return { label: cc.displayName, value: cc.id };
  });
  const walletsOptions = wallets.map((wallet) => {
    return { label: wallet.displayName, value: wallet.id };
  });

  // Filter options by productType
  // line of credits will remove the filter product, since we onlye have one LOC.
  let options = [];
  switch (productType) {
    case TRANSACTION_PRODUCT_TYPES.creditCard:
      options = cardsOptions;
      break;
    case TRANSACTION_PRODUCT_TYPES.wallet:
      options = walletsOptions;
      break;
    default:
      options = [];
  }

  // selected values and events
  const selectedIds = selectedValues.map((product) => product.value);

  const handleSelect = (option) => {
    const optionIndex = selectedIds.indexOf(option.value);

    if (optionIndex === -1) {
      onChangeCurrentBillingCycle(
        option.currentBillingCycle && {
          from: new Date(option.currentBillingCycle.from),
          to: new Date(option.currentBillingCycle.to),
        }
      );
      onChangePreviousBillingCycle(
        option.previousBillingCycle && {
          from: new Date(option.previousBillingCycle.from),
          to: new Date(option.previousBillingCycle.to),
        }
      );
      setSelectedValues([...selectedValues, option]);
    } else {
      onChangeCurrentBillingCycle(null);
      onChangePreviousBillingCycle(null);
      setSelectedValues(selectedValues.filter((product) => product.value !== option.value));
    }
  };

  if (loadingWallets || loadingCreditCards) return <Loaders.Spinner />;

  return (
    <Listbox value={selectedValues} onChange={handleSelect}>
      <div className={`tw-relative tw-p-2 ${className}`}>
        <Listbox.Button data-testid={testId} className="tw-flex tw-items-center tw-cursor-pointer tw-p-2">
          <div className="tw-text-sm tw-mr-2">{`${label}${
            selectedValues?.length ? ` (${selectedValues.length})` : ''
          }`}</div>
          <BsChevronDown size={14} className="tw-text-neutral-grey-2" />
        </Listbox.Button>
        <Transition
          unmount={false}
          as={React.Fragment}
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Listbox.Options className="tw-absolute tw-z-40 tw-w-max tw-max-h-80 tw-overflow-y-scroll  tw-scrollbar-hide  tw-pl-1 tw-pr-4 tw-bg-neutral-light tw-rounded-md tw-shadow-notification">
            {options.map((option) => {
              const isSelected = selectedIds.includes(option.value);

              const onCheck = () => handleSelect(option);

              return (
                <Listbox.Option
                  key={option.label}
                  value={option}
                  className="tw-flex tw-items-center tw-justify-start tw-p-2 tw-cursor-pointer"
                  data-testid={option.currency && `filter-products-${option.currency.toLowerCase()}-wallet-option`}
                >
                  <input className="tw-mr-2" checked={isSelected} onChange={onCheck} type="checkbox" />
                  {option.currency ? (
                    <>
                      <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
                        <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                          <MobileCurrencyFlag size={14} currency={option.currency} />
                        </div>
                      </div>
                      <div className="tw-text-sm">{option.label}</div>
                    </>
                  ) : (
                    <div className="tw-text-sm">{option.label}</div>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default ProductsDropdown;
