export const BUSINESS_VERIFICATION_DESCRIPTION = {
  preRevenue:
    'Thank you for registering for a Loop Account. Since you haven’t started generating revenue yet, we need you to provide us with a document that helps us verify the nature of the business you are starting up. Please upload at least one of the following documents:',
  revenue:
    'Thank you for registering for a Loop Account. In order for us to verify the nature of your business, please upload at least one of the following documents:',
};

export const PRE_REVENUE_DOCUMENT_TYPES = [
  'Proof of payment for inventory you’ve purchased',
  'A license or permit you’ve obtained from a government authority (not including articles of incorporation or name registrations)',
  'A copy of an insurance policy',
  'A professional license or certification',
  'A proposal or contract with a customer, supplier or business partner',
  'A term sheet or document for investment in the business',
];

export const REVENUE_DOCUMENT_TYPES = [
  'An executed agreement from one of your customers',
  'A detailed statement from your payment processor',
  'A sales receipt or invoice made to a customer along with proof of payment',
];
