import React, { useContext } from 'react';
import { BiCheck } from 'react-icons/bi';
import { get } from 'lodash';

import { EXTERNAL_TRANSACTION_CLEARING_MESSAGE } from 'constants/index';
import { formatMoneyV2 } from 'utility/currency';
import { formatDateTime } from 'utility/date';
import { PaymentContext } from 'context/Payment';
import Button from 'components/Button';

const Complete = ({ wallets, onNewTransfer, onFinish, footerBorder, finishButtonLabel }) => {
  const { addMoneyTransaction, addMoneyInfo } = useContext(PaymentContext);
  const { amount, fromAccount, toAccount, transactionGroupId, transferMethod, initiatedAt } = addMoneyTransaction;
  const fromIsWallet = wallets.some((w) => w.id === get(addMoneyInfo, 'fromAccount.id'));

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <div className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-secondary-light-green tw-mb-8">
          <div className="tw-mr-2 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
            <BiCheck className="tw-text-secondary-light-green" />
          </div>
          <small className="tw-text-semantic-success">
            {fromIsWallet ? 'Your transfer has been completed' : EXTERNAL_TRANSACTION_CLEARING_MESSAGE}
          </small>
        </div>
        <div className="tw-flex tw-flex-col tw-pt-8 tw-pb-4">
          <small>Recipient Account</small>
          <p className="tw-mt-1">{toAccount.displayName}</p>
          <small className="tw-mt-4">From Account</small>
          <p className="tw-mt-1">{fromAccount.displayName}</p>
          <small className="tw-mt-4">Amount</small>
          <p className="tw-mt-1">{`${amount.currency} ${formatMoneyV2(amount)}`}</p>
          <small className="tw-mt-4">Method</small>
          <p className="tw-mt-1">{transferMethod}</p>
          <small className="tw-mt-4">Fees</small>
          <p className="tw-mt-1">{`${amount.currency} ${formatMoneyV2({ amount: 0, currency: amount.currency })}`}</p>
          <small className="tw-mt-4">Reference ID</small>
          <p className="tw-mt-1">{transactionGroupId}</p>
          <small className="tw-mt-4">Initiated at</small>
          <p className="tw-mt-1">{formatDateTime(initiatedAt)}</p>
        </div>
      </div>
      <div
        className={`${
          footerBorder ? 'tw-border-t tw-border-neutral-grey-3' : ''
        } tw-px-8 tw-py-4 tw-flex tw-flex-col tw-space-y-4 lg:tw-space-y-0 lg:tw-flex-row tw-justify-between`}
      >
        <Button secondary onClick={onFinish} className="tw-w-full lg:tw-w-max">
          {finishButtonLabel}
        </Button>
        <Button primary onClick={onNewTransfer} className="tw-w-full lg:tw-w-max lg:tw-mt-0">
          Make Another Transfer
        </Button>
      </div>
    </>
  );
};

export default Complete;
