// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React, { useContext, useCallback } from 'react';
import { ampTrackEvent } from 'amplitude';

import { formatMoneyV2 } from 'utility/currency';
import { SCOPE } from 'constants/index';
import PermissionChecker from 'components/PermissionChecker';
import Button from 'components/Button';
import { AddFundsToBalanceContext } from './context/AddFundsToBalance';

const CardBalances = ({
  balance,
  minimumPayment,
  amountDue,
  postedTransactionsAmount,
  pendingTransactionsAmount,
  openAddFundsToBalanceModal,
}) => {
  const { setTransaction } = useContext(AddFundsToBalanceContext);

  const { amount, currency } = balance || {};

  const handleOpenAddFundsToBalanceModal = useCallback(() => {
    setTransaction({
      originalAmount: {
        amount,
        currency,
      },
    });
    openAddFundsToBalanceModal();
    ampTrackEvent('payBalance: customAmount: open modal');
  }, [amount, currency, openAddFundsToBalanceModal]);

  return (
    <div>
      <div className="tw-flex tw-justify-between">
        <small className="tw-text-neutral-grey-2">Pending</small>
        <small>{formatMoneyV2(pendingTransactionsAmount)}</small>
      </div>
      <div className="tw-flex tw-justify-between tw-my-2">
        <small className="tw-text-neutral-grey-2">Posted</small>
        <small>{formatMoneyV2(postedTransactionsAmount)}</small>
      </div>
      <div className="tw-flex tw-justify-between">
        <small className="tw-text-neutral-grey-2">Current</small>
        <small>{formatMoneyV2(balance)}</small>
      </div>
      {!!amountDue.cents && (
        <div className="tw-flex tw-justify-between tw-my-2">
          <small className="tw-text-neutral-grey-2">Amount due</small>
          <small>{formatMoneyV2({ amount: amountDue.cents, currency: amountDue.currency_iso })}</small>
        </div>
      )}
      {!!minimumPayment.cents && (
        <div className="tw-flex tw-justify-between">
          <small className="tw-text-neutral-grey-2">Min. payment</small>
          <small>{formatMoneyV2({ amount: minimumPayment.cents, currency: minimumPayment.currency_iso })}</small>
        </div>
      )}
      <PermissionChecker scope={SCOPE.manageCards}>
        <div className="tw-flex tw-justify-end tw-mt-4">
          <Button
            onClick={handleOpenAddFundsToBalanceModal}
            className="tw-text-sm tw-text-primary-dark-green tw-font-semibold"
          >
            Add Funds
          </Button>
        </div>
      </PermissionChecker>
    </div>
  );
};

export default CardBalances;
