import React from 'react';

import { ONBOARDING_STEPS } from 'components/onboarding/constants';

import styles from './Header.module.scss';

const Header = () => (
  <div className={styles.wrapper}>
    <h1 data-testid="connect-sales-channel-title" className={styles.header}>
      {ONBOARDING_STEPS.accountType.title}
    </h1>
    <p>
      Looking to get credit? Let’s start your credit application. You can still use Loop without credit, however you
      will need to add funds to Loop before you can use your account.
    </p>
  </div>
);

export default Header;
