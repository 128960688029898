import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SubmitButton } from 'components/FormFields/v2';

const Details = ({ onNextStep, currency, showAccount, loading }) => {
  const form = useForm();
  const { handleSubmit } = form;
  const isEUR = currency && currency === 'EUR';
  const isGBP = currency && currency === 'GBP';
  const isCAD = currency && currency === 'CAD';

  const onSubmit = async () => {
    await onNextStep();
    showAccount(true);
  };

  return (
    <div className="tw-mt-8">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-max-h-screen tw-overflow-y-scroll">
            <div className="tw-px-8">
              <small className="tw-text-neutral-grey-1">You'll get the following account details:</small>
              {isGBP && (
                <ul className="tw-list-disc tw-text-neutral-grey-1 tw-text-sm tw-pl-6 tw-space-y-2 tw-my-2">
                  <li>Sort Code</li>
                  <li>Account Number</li>
                  <li>IBAN</li>
                </ul>
              )}
              {isEUR && (
                <ul className="tw-list-disc tw-text-neutral-grey-1 tw-text-sm tw-pl-6 tw-space-y-2 tw-my-2">
                  <li>BIC</li>
                  <li>IBAN</li>
                </ul>
              )}
              {isCAD && (
                <ul className="tw-list-disc tw-text-neutral-grey-1 tw-text-sm tw-pl-6 tw-space-y-2 tw-my-2">
                  <li>Transit Number</li>
                  <li>Institution Number</li>
                  <li>Account Number</li>
                </ul>
              )}
            </div>
            <div className="tw-px-8 tw-pb-4">
              <small className="tw-text-neutral-grey-1">You'll be able to do the following with this account:</small>
              <ul className="tw-list-disc tw-text-neutral-grey-1 tw-text-sm tw-pl-6 tw-space-y-2 tw-my-2">
                <li>Get paid in {currency} from your customers, payment providers and more</li>
                <li>Pay your Payees in {currency} and other currencies from this account</li>
                <li>Connect to Services like Stripe and Shopify</li>
                {isGBP && <li>Make real-time payments in the UK using the UK’s instant Faster Payments network</li>}
                {isEUR && <li>Make local payments in Europe using the local SEPA network</li>}
                {isGBP && <li>Convert your {currency} into CAD, EUR and USD</li>}
                {isEUR && <li>Convert your {currency} into CAD, GBP and USD</li>}
              </ul>
            </div>
          </div>
          <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex lg:tw-justify-end">
            <SubmitButton className="tw-w-full lg:tw-w-32" disabled={loading}>
              Get Details
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Details;
