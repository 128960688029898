import React from 'react';
import config from 'config';
import styles from './PolicyAgreement.module.scss';

const PolicyAgreement = () => (
  <div className="tw-mt-12 tw-text-xs">
    By clicking on the Submit button below, I confirm that I have read and agree to the&nbsp;
    <a className={styles.underline} target="_blank" rel="noopener noreferrer" href={config.platformAgreementUrl}>
      Platform Agreement
    </a>
    ,&nbsp;
    <a className={styles.underline} target="_blank" rel="noopener noreferrer" href={config.privacyPolicyUrl}>
      Privacy Policy
    </a>
    ,&nbsp;
    <a className={styles.underline} target="_blank" rel="noopener noreferrer" href="/credit-report-consent-terms">
      Credit Report Consent Terms
    </a>
    &nbsp;and the&nbsp;
    <a
      className={styles.underline}
      target="_blank"
      rel="noopener noreferrer"
      href={config.multiCurrencyAccountAgreement}
    >
      Loop Multi-Currency Account Agreement
    </a>
  </div>
);

export default PolicyAgreement;
