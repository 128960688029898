/**
 * whilst generating new start and end timestamps, please use
 * new Date('2024 09 01 00:00:00 am').getTime()
 */

export const StatutoryHolidays = [
  {
    title: 'Holiday Delays - Labour Day',
    message:
      'Please note that payments may take longer to clear due to the upcoming holiday. Thank you for your understanding.',
    currencies: ['USD', 'CAD'],
    startDateTimestamp: 1724904000000, // 2024 08 29 00:00:00
    endDateTimestamp: 1725336000000, // 2024 09 03 00:00:00
  },
  {
    title: 'Holiday Delays - Thanksgiving & Columbus Day', // Canadian Thanksgiving
    message:
      'Please note that payments may take longer to clear due to the upcoming holiday. Thank you for your understanding.',
    currencies: ['CAD', 'USD'],
    startDateTimestamp: 1728619200000, // 2024 10 11 00:00:00 am
    endDateTimestamp: 1728964800000, // 2024 10 15 00:00:00 am
  },
  {
    title: 'Holiday Delays - All Saints Day', // All Saints Day - EU
    message:
      'Please note that payments may take longer to clear due to the upcoming holiday. Thank you for your understanding.',
    currencies: ['EUR'],
    startDateTimestamp: 1730347200000, // 2024 10 31 00:00:00 am
    endDateTimestamp: 1730520000000, // 2024 11 02 00:00:00 am
  },
  {
    title: 'Holiday Delays - Thanksgiving', // American Thanksgiving
    message:
      'Please note that payments may take longer to clear due to the upcoming holiday. Thank you for your understanding.',
    currencies: ['USD'],
    startDateTimestamp: 1732683600000, // 2024 11 27 00:00:00 am
    endDateTimestamp: 1732856400000, // 2024 11 29 00:00:00 am
  },
];
