import React, { createContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { GET_POINTS_AND_REWARDS_INFO } from 'graphql/creditOffer';

export const RewardsContext = createContext({});
export const RewardsContextProvider = ({ children }) => {
  const [selectedGift, setSelectedGift] = useState({});

  const { loading: loadingContext, data: creditProductsData, refetch } = useQuery(GET_POINTS_AND_REWARDS_INFO);
  const { me } = creditProductsData || {};
  const rewardsAccount = _.get(me, 'account.rewardsAccount');

  return (
    <RewardsContext.Provider value={{ rewardsAccount, selectedGift, setSelectedGift, loadingContext, refetch }}>
      {children}
    </RewardsContext.Provider>
  );
};
