import { Currencies } from 'constants/currencies';
import { ToggleDropdownProps, WithId } from '../ToggleDropdown.types';

const useToggleDropdown = <T extends WithId | Currencies | String>({
  selectedOptions,
  setSelectedOptions,
  idKey,
}: Pick<ToggleDropdownProps<T>, 'selectedOptions' | 'setSelectedOptions' | 'idKey'>) => {
  const handleToggleOption = (selected: T) => {
    const isSelected = selectedOptions.some((option) =>
      typeof option === 'object' && idKey ? option[idKey] === selected[idKey] : option === selected
    );
    if (isSelected) {
      setSelectedOptions((prevSelected) =>
        prevSelected.filter((option) =>
          typeof option === 'object' && idKey ? option[idKey] !== selected[idKey] : option !== selected
        )
      );
    } else {
      setSelectedOptions((prevSelected) => [...prevSelected, selected]);
    }
  };
  const selectedOptionsCount = selectedOptions.length;

  return {
    handleToggleOption,
    selectedOptionsCount,
  };
};
export default useToggleDropdown;
