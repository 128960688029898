import React, { useState, createContext, useEffect } from 'react';
import { useLazyQuery, ApolloError } from '@apollo/client';
import qs from 'query-string';
import { toast } from 'react-toastify';

import history from 'history.js';
import { GET_PAYOR_PAYMENT_REQUEST } from 'graphql/invoicing';
import { PayorPaymentRequest, PaymentRequestStatus } from 'types/invoicing';
import { useDeepEffect } from 'hooks';
import { PayorOnboardingContextType } from '../PayorOnboarding.types';

export const PayorOnboardingContext = createContext<PayorOnboardingContextType>({} as PayorOnboardingContextType);

export const PayorOnboardingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { sgid: signedId } = qs.parse(location.search) as {
    sgid?: string;
  };

  const [getPaymentRequest, { data, loading, error: queryError }] = useLazyQuery<{
    payorPaymentRequest: PayorPaymentRequest;
  }>(GET_PAYOR_PAYMENT_REQUEST);

  const [paymentRequest, setPaymentRequest] = useState<PayorPaymentRequest | undefined>(data?.payorPaymentRequest);

  const [error, setError] = useState<ApolloError | undefined>(queryError);

  useDeepEffect(() => {
    if (loading) return;

    if (data) {
      const { payorPaymentRequest } = data;
      const status = payorPaymentRequest?.status;

      if (status && ![PaymentRequestStatus.pending, PaymentRequestStatus.overdue].includes(status)) {
        toast.warn('This Payment Request was already processed, expired or rejected.');
        history.push('/payor/portal');
        return;
      }

      setPaymentRequest(payorPaymentRequest);
    }
    queryError && setError(queryError);
  }, [data, queryError, loading]);

  useEffect(() => {
    if (!error?.message) return;

    toast.error(`Error: ${error?.message}. Please try again`, { autoClose: false });
    console.error(error);
  }, [error]);

  return (
    <PayorOnboardingContext.Provider
      value={{
        signedId,
        paymentRequest,
        getPaymentRequest,
        error,
        isLoading: loading,
        setError,
      }}
    >
      {children}
    </PayorOnboardingContext.Provider>
  );
};
