import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const PreFundedInfo = () => (
  <div className="tw-flex tw-gap-4 tw-items-center tw-bg-neutral-grey-5 md:tw-bg-neutral-grey-4 tw-rounded-md tw-p-2 tw-m-2 md:tw-m-0">
    <AiOutlineInfoCircle size={24} className="tw-min-w-min" />
    <span className="tw-text-xs">
      Your pre-funded balance reflects the amount you've loaded onto your Loop card for each currency, minus any posted
      or pending transactions. To make a purchase with your Loop Card, please ensure you have loaded funds to your card
      balance in one of the supported currencies.
    </span>
  </div>
);

export default PreFundedInfo;
