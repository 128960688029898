import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { uploadFile } from 'utility/activeStorage';
import FileItem from './FileItem';
import DropZone from './Dropzone';

const Files = ({ files }) => {
  if (!files?.length) return null;

  return files.map((file, i) => <FileItem key={i} file={file} />);
};

const FileUploader = ({ onUpload, defaultFiles = [] }) => {
  const [files, setFiles] = useState([...defaultFiles]);
  const [hasDroppedEmptyFile, setHasDroppedEmptyFile] = useState(false);

  const onDrop = (newFiles) => {
    if (newFiles.some((file) => !(file.size > 0))) {
      setHasDroppedEmptyFile(true);
      return;
    }

    setFiles((f) => [...f, ...newFiles]);
    setHasDroppedEmptyFile(false);

    newFiles.forEach((file) => startFileUpload(file));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const updateUploadProgress = ({ total, loaded, file }) => {
    const progress = (loaded * 100) / total;
    setFiles((files) =>
      files.map((f) => {
        if (f.name === file.name) {
          f.total = total;
          f.progress = progress;
          f.loaded = loaded;
        }
        return f;
      })
    );
  };

  const startFileUpload = async (file) => {
    try {
      const blob = await uploadFile(file, updateUploadProgress);
      onUpload?.({ blob, file });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <DropZone
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        hasDroppedEmptyFile={hasDroppedEmptyFile}
        isDragActive={isDragActive}
      />
      <Files files={files} />
    </div>
  );
};

export default FileUploader;
