import React from 'react';

import { DetailsFormProps } from '../../DetailsForm.types';

const BasicInfo = ({
  borrowerName,
  payeeName,
  payeeEmail,
  payeeType,
}: Pick<DetailsFormProps, 'borrowerName' | 'payeeType' | 'payeeName' | 'payeeEmail'>) => {
  return (
    <>
      <div className="tw-text-neutral-grey-1 tw-mb-2 tw-text-sm">Details submitted by: {borrowerName}</div>
      <ul className="tw-flex tw-flex-col tw-gap-2 tw-bg-neutral-grey-4 tw-px-8 2xl:tw-px-16 tw-py-4 tw-w-full tw-rounded-md tw-mb-6 tw-text-sm">
        <li className="tw-flex tw-gap-2">
          <div className="tw-w-2/5">Payee Type:</div>
          <div className="tw-w-3/5">{payeeType}</div>
        </li>
        <li className="tw-flex tw-gap-2">
          <div className="tw-w-2/5">Payee Legal Name:</div>
          <div className="tw-w-3/5">{payeeName}</div>
        </li>
        <li className="tw-flex tw-gap-2">
          <div className="tw-w-2/5">Email Address:</div>
          <div className="tw-w-3/5">{payeeEmail}</div>
        </li>
      </ul>
    </>
  );
};

export default BasicInfo;
