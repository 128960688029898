import { useEffect } from 'react';

export const useSyncAccountCurrencyWithPaymentCurrency = ({ bankAccounts, payload, setPayload }) => {
  useEffect(() => {
    const account = bankAccounts.find((acc = {}) => acc.id === payload.fromAccount) || {};

    if (account.currency) {
      setPayload((state = {}) => ({ ...state, currency: account.currency }));
    }
  }, [payload.fromAccount]);
};

export const useSyncOutstandingBalanceAmountWithPaymentAmount = ({
  selectedPaymentDetail,
  locOutstandingBalance,
  setPayload,
}) => {
  useEffect(() => {
    if (selectedPaymentDetail !== 'internalTransfer') {
      let paymentAmount = 0;
      if (selectedPaymentDetail === 'outstandingBalancePayment') {
        paymentAmount = locOutstandingBalance;
      }
      setPayload((state = {}) => ({ ...state, paymentAmount }));
    }
  }, [selectedPaymentDetail]);
};
