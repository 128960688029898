import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { useMutation } from '@apollo/client';
import { ampTrackEvent } from 'amplitude';
import { toast } from 'react-toastify';

import { DELETE_PAYEE, GET_PAYEES } from 'graphql/payees';
import Button from 'components/Button';
import Modal from 'components/Modal/v2';
import { Loaders } from 'components/cards/Loader';

const DeactivatePayee = ({ show, hide, payee, payeeName }) => {
  const [deletePayee, { loading }] = useMutation(DELETE_PAYEE, {
    refetchQueries: [{ query: GET_PAYEES }],
    awaitRefetchQueries: true,
  });

  const handleDeletePayee = async () => {
    try {
      const response = await deletePayee({
        variables: { payeeId: payee.id },
      });
      hide();
      toast.success('Payee successfully deleted', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (response.data && response.data.updatePayee) {
        ampTrackEvent('deletePayee: success');
      }
    } catch (error) {
      ampTrackEvent('deletePayee: error');
      console.error(error);
    }
  };

  return (
    <Modal show={show} onClose={hide}>
      <div className="tw-px-8 tw-py-4 tw-flex tw-justify-between tw-items-center">
        <span>Delete Payee</span>
        <Button onClick={hide}>
          <IoMdClose />
        </Button>
      </div>

      {loading ? (
        <Loaders.Light />
      ) : (
        <div className="tw-px-12 tw-py-8 tw-text-lg tw-border-t-2 tw-border-b-2 tw-border-neutral-grey-4">
          Are you sure you want to delete <b>{payeeName}</b>?
        </div>
      )}

      <div className="tw-py-4 tw-px-8 tw-flex tw-justify-between">
        <Button
          className="tw-bg-neutral-grey-5 tw-border-neutral-grey-4 tw-border tw-rounded-md tw-px-6 tw-py-2"
          onClick={hide}
        >
          Cancel
        </Button>
        <Button
          className="tw-rounded-md tw-px-6 tw-py-2"
          onClick={handleDeletePayee}
          isDisabled={loading}
          warning={true}
        >
          {loading ? 'Deleting...' : 'Delete payee'}
        </Button>
      </div>
    </Modal>
  );
};

export default DeactivatePayee;
