import React from 'react';
import { FormProvider } from 'react-hook-form';
import cx from 'classnames';

import { MoneyInputField, RadioField } from 'components/FormFields/v2';
import { formatMoneyV2 } from 'utility/currency';
import { useUpdateCardLimitSettings } from '../hooks';
import { UpdateCardLimitSettingsFormProps } from '../CreditCardLimitSettingsContent.types';
import Button from 'components/Button';
import { Banner } from 'components/UI';
import { BannerType } from 'components/UI/Banner';
import CardAndStar from 'components/svg/CardAndStar';
import { Currencies } from 'constants/currencies';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';

const LimitSettingsForm = ({
  creditCard,
  creditCardLimitSettings,
  setCreditCardLimitSettings,
  onClose,
}: UpdateCardLimitSettingsFormProps) => {
  const { form, register, formattedAmount, setFormattedAmount, onSave, updateCardLimitSettingsError } =
    useUpdateCardLimitSettings({
      creditCard,
      creditCardLimitSettings,
      setCreditCardLimitSettings,
      onClose,
    });

  const bannerMessage = (
    <>
      <div className="tw-text-sm tw-mb-2">
        Select your freeze amount in CAD; <br />
        amounts in other currencies will convert to CAD and count against the freeze amount
      </div>
      <div className="tw-text-sm">
        Set the auto-freeze amount to $0.00 and select <b>Save</b> to remove an existing rule
      </div>
    </>
  );

  const expireTypeOptions = [
    { label: 'Monthly', value: 'monthly', defaultChecked: creditCardLimitSettings.expiresMonthly },
    { label: 'Annually', value: 'annually', defaultChecked: creditCardLimitSettings.expiresAnnually },
    {
      label: 'Never',
      value: 'never',
      defaultChecked: !creditCardLimitSettings.expiresMonthly && !creditCardLimitSettings.expiresAnnually,
    },
  ];

  return (
    <FormProvider {...form} {...{ graphQLErrors: updateCardLimitSettingsError?.graphQLErrors }}>
      <div className="tw-flex tw-flex-col tw-px-8">
        <form>
          <div className="tw-space-y-8">
            <Banner
              type={BannerType.warning}
              icon={<CardAndStar width={80} />}
              iconAlignment="center"
              message={bannerMessage}
              submitButton={false}
              classNames="tw-cursor-default"
            />
            <div className="tw-flex tw-items-start">
              <MoneyInputField
                name="limit"
                label="Auto-Freeze After"
                required
                value={formattedAmount}
                moneyFormatter={formatMoneyV2}
                setValue={setFormattedAmount}
                rootClass="tw-w-3/4"
                ref={register({ required: true })}
              />
              <div
                className={cx(
                  'tw-flex tw-items-center tw-h-11 tw-bg-neutral-grey-4 tw-rounded-md tw-p-4 tw-mt-7 tw-flex-grow-0 tw-ml-4'
                )}
              >
                <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5 tw-mr-2">
                  <MobileCurrencyFlag currency={Currencies.CAD} size={16} />
                </div>
                <p className="tw-text-neutral-grey-2">CAD</p>
              </div>
            </div>
            <div className="tw-pb-8">
              <label htmlFor="expiresMonthly" className="tw-flex tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
                <span>Reset Auto-Freeze</span>
                <span className="tw-text-semantic-error tw-pr-2">&nbsp;*</span>
              </label>
              <RadioField name="cardLimitExpireType" options={expireTypeOptions} ref={register({ required: true })} />
            </div>
          </div>
        </form>
      </div>
      <div className="tw-flex tw-justify-end tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-6">
        <Button primary onClick={onSave}>
          Save
        </Button>
      </div>
    </FormProvider>
  );
};

export default LimitSettingsForm;
