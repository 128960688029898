import { useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ampTrackEvent } from 'amplitude';

import { CardAddressFormData, UseUpdateCardAddressModalProps } from '../UpdateCardAddressModal.types';
import { UPDATE_CARD_ADDRESS, GET_CREDIT_CARD_DETAILS_BY_ID } from 'graphql/cards';
import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';

const useUpdateCardAddressModal = ({ address, currentCountry, handleCloseModal }: UseUpdateCardAddressModalProps) => {
  const { creditCardDetails } = useContext(CardDetailsContext);
  const creditCardId = creditCardDetails?.id;

  const [updateCardAddress, { loading }] = useMutation(UPDATE_CARD_ADDRESS, {
    refetchQueries: [{ query: GET_CREDIT_CARD_DETAILS_BY_ID, variables: { creditCardId: creditCardId } }],
    awaitRefetchQueries: true,
  });

  const { street, city, countrySubdivision, postalCode, unitNumber } = address || {};

  const form = useForm<CardAddressFormData>({
    defaultValues: {
      address: {
        street: street || '',
        city: city || '',
        countrySubdivision: countrySubdivision || '',
        postalCode: postalCode || '',
        country: currentCountry,
        unitNumber: unitNumber || '',
      },
    },
  });

  const { handleSubmit } = form;

  const onSubmit = useCallback(
    async (data: CardAddressFormData) => {
      const processingToast = toast.loading('Updating card address...');

      try {
        const { street, city, countrySubdivision, postalCode, unitNumber } = data.address;
        const response = await updateCardAddress({
          variables: {
            creditCardId: creditCardId,
            address: {
              street,
              city,
              countrySubdivision,
              postalCode,
              category: currentCountry,
              country: currentCountry,
              unitNumber,
            },
          },
        });

        if (!response.data?.updateCardAddress) throw new Error('Unable to update card address - please try again');

        toast.update(processingToast, {
          render: 'Address updated successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        ampTrackEvent('updateCardAddress: success');
        handleCloseModal();
      } catch (error) {
        console.error(error);
        toast.update(processingToast, {
          render: 'Unable to update card address - please try again',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
        ampTrackEvent('updateCardAddress: error');
      }
    },
    [updateCardAddress, creditCardId, currentCountry, handleCloseModal]
  );

  return {
    form,
    loading,
    handleSubmit,
    onSubmit,
  };
};

export default useUpdateCardAddressModal;
