import { gql } from '@apollo/client';

export const GET_CATEGORIES_QUICKBOOKS_MAPPING = gql`
  query TransactionCategoryAccountingIntegrationMapping {
    transactionCategoryAccountingIntegrationMapping {
      id
      transactionCategoryId
      quickBooksAccountId
    }
  }
`;

export const UPDATE_CATEGORIES_QUICKBOOKS_MAPPING = gql`
  mutation UpdateTransactionCategoryAccountingIntegrationMapping($id: String!, $quickBooksAccountId: String!) {
    updateTransactionCategoryAccountingIntegrationMapping(id: $id, quickBooksAccountId: $quickBooksAccountId)
  }
`;

export const CREATE_LOOP_CATEGORY = gql`
  mutation CreateTransactionCategory($description: String!) {
    createTransactionCategory(description: $description)
  }
`;

// accounts means accounting accounts, for us categories.
export const GET_QUICKBOOK_EXPENSE_ACCOUNTS = gql`
  query AccountingIntegrationExpenseAccounts {
    accountingIntegrationExpenseAccounts {
      id
      name
      taxCodeRefId
    }
  }
`;

// accounts means accounting accounts, for us categories.
export const GET_QUICKBOOK_LIABILITY_ACCOUNTS = gql`
  query AccountingIntegrationLiabilityAccounts {
    accountingIntegrationLiabilityAccounts {
      id
      name
      currency
    }
  }
`;

// potentialy in the future we need taxRefCode for an expense
export const CREATE_QUICKBOOK_EXPENSE_ACCOUNT = gql`
  mutation createAccountingIntegrationExpenseAccount($transactionCategoryId: String, $name: String!) {
    createAccountingIntegrationExpenseAccount(transactionCategoryId: $transactionCategoryId, name: $name)
  }
`;

export const CREATE_QUICKBOOK_LIABILITY_ACCOUNT = gql`
  mutation createAccountingIntegrationLiabilityAccount($currencyRef: CurrencyRef!, $name: String!) {
    createAccountingIntegrationLiabilityAccount(currencyRef: $currencyRef, name: $name)
  }
`;

// accounts means accounting accounts, for us categories.
export const GET_CURRENCY_QUICKBOOKS_LIABILITY_ACCOUNT_MAPPING = gql`
  query CurrencyAccountingIntegrationLiabilityAccountMapping {
    currencyAccountingIntegrationLiabilityAccountMapping {
      currency
      quickBooksAccountId
    }
  }
`;

// maps a loop card currency to quickbook accounts
// for now we have 4 mappings, 1 for each currency to each quickbook liability account
export const UPDATE_CURRENCY_QUICKBOOK_LIABILITY_MAPPING = gql`
  mutation UpdateCurrencyAccountingIntegrationLiabilityAccountMapping(
    $currency: String!
    $quickBooksAccountId: String!
  ) {
    updateCurrencyAccountingIntegrationLiabilityAccountMapping(
      currency: $currency
      quickBooksAccountId: $quickBooksAccountId
    )
  }
`;

export const GET_QBO_TRANSACTIONS = gql`
  query AccountingIntegrationTransactions(
    $startDate: String
    $endDate: String
    $currency: String
    $postedStatus: String
    $transactionType: String
  ) {
    accountingIntegrationTransactions(
      startDate: $startDate
      endDate: $endDate
      currency: $currency
      postedStatus: $postedStatus
      transactionType: $transactionType
    ) {
      transactionId
      amount {
        amount
        currency
      }
      from
      to
      qboLiabilityAccountId
      qboStatus
      qboTransactionDate
      qboExpenseAccountId
      qboTaxId
      transactionCategory {
        transactionCategoryId
        description
      }
      receiptDetails {
        gst
        hst
      }
      type
      qboResponse
      accountingIntegrationVendor {
        id
        name
      }
      merchantVendor {
        vendorId
        vendor {
          id
          name
        }
        merchant {
          name
          code
        }
        category {
          description
          providerId
          categoryId
        }
      }
    }
  }
`;

export const PUSH_QBO_TRANSACTIONS = gql`
  mutation PushAccountingIntegrationTransactions($transactions: [AccountingIntegrationTransactionInput!]) {
    pushAccountingIntegrationTransactions(transactions: $transactions)
  }
`;

export const GET_QBO_TAX_CODES = gql`
  query AccountingIntegrationTaxCodes {
    accountingIntegrationTaxCodes {
      id
      name
    }
  }
`;

export const GET_QBO_PREFERENCES_CURRENCY = gql`
  query AccountingIntegrationPreferencesCurrency {
    accountingIntegrationPreferencesCurrency {
      multiCurrencyEnabled
      homeCurrency
    }
  }
`;

export const GET_QBO_COMPANY_INFO = gql`
  query AccountingIntegrationCompanyInfo {
    accountingIntegrationCompanyInfo {
      name
      lastPushedAt
      isActionRequired
    }
  }
`;

export const GET_ASSET_ACCOUNTS = gql`
  query AccountingIntegrationAssetRevenueAccounts {
    accountingIntegrationAssetRevenueAccounts {
      id
      name
      taxCodeRefId
      currency
    }
  }
`;

export const GET_WALLET_ASSET_ACCOUNT_MAPPING = gql`
  query WalletAccountingIntegrationAssetAccountMapping {
    walletAccountingIntegrationAssetAccountMapping {
      id
      wallet {
        id
        name
        currency
      }
      externalAccountId
    }
  }
`;

export const UPDATE_WALLET_ASSET_ACCOUNT_MAPPING = gql`
  mutation UpdateWalletAccountingIntegrationAssetAccountMapping($walletId: ID!, $externalAccountId: String!) {
    updateWalletAccountingIntegrationAssetAccountMapping(walletId: $walletId, externalAccountId: $externalAccountId)
  }
`;

export const GET_WALLET_TRANSACTION_CATEGORIES = gql`
  query WalletTransactionCategories {
    walletTransactionCategories {
      transactionCategoryId
      loopId
      description
      owner {
        identifier
        identifierType
      }
    }
  }
`;

export const GET_ALL_ACCOUNTS = gql`
  query AccountingIntegrationAllAccounts {
    accountingIntegrationAllAccounts {
      id
      name
      taxCodeRefId
      accountType
    }
  }
`;

export const GET_WALLET_TRANSACTION_CATEGORY_ACCOUNTING_INTEGRATION_MAPPING = gql`
  query WalletTransactionCategoryAccountingIntegrationMapping {
    walletTransactionCategoryAccountingIntegrationMapping {
      id
      transactionCategoryId
      quickBooksAccountId
    }
  }
`;

export const UPDATE_WALLET_TRANSACTION_CATEGORY_ACCOUNTING_INTEGRATION_MAPPING = gql`
  mutation UpdateWalletTransactionCategoryAccountingIntegrationMapping($id: String!, $externalAccountId: String!) {
    updateWalletTransactionCategoryAccountingIntegrationMapping(id: $id, externalAccountId: $externalAccountId)
  }
`;

export const GET_WALLET_TRANSACTIONS = gql`
  query AccountingIntegrationWalletTransactions(
    $startDate: String
    $endDate: String
    $currency: String
    $postedStatus: String
  ) {
    accountingIntegrationWalletTransactions(
      startDate: $startDate
      endDate: $endDate
      currency: $currency
      postedStatus: $postedStatus
    ) {
      transactionId
      amount {
        amount
        currency
      }
      from
      to
      qboLiabilityAccountId
      qboStatus
      qboTransactionDate
      qboExpenseAccountId
      qboTaxId
      walletTransactionCategory {
        providerId
        transactionCategoryId
        description
      }
      type
      qboResponse
    }
  }
`;

export const GET_ACCOUNTING_INTEGRATION_VENDORS = gql`
  query AccountingIntegrationVendors($page: String, $numPerPage: String, $queryString: String) {
    accountingIntegrationVendors(page: $page, numPerPage: $numPerPage, queryString: $queryString) {
      items {
        id
        name
      }
      pageData {
        totalCount
        totalPages
        currentPage
        nextPage
        prevPage
      }
    }
  }
`;

export const GET_ACCOUNTING_INTEGRATION_VENDORS_COUNT = gql`
  query AccountingIntegrationVendors {
    accountingIntegrationVendors {
      pageData {
        totalCount
      }
    }
  }
`;

export const GET_ACCOUNTING_INTEGRATION_VENDOR_MAPPINGS = gql`
  query BorrowerMerchants($page: String, $numPerPage: String, $queryString: String, $mappingStatus: String) {
    borrowerMerchants(page: $page, numPerPage: $numPerPage, queryString: $queryString, mappingStatus: $mappingStatus) {
      items {
        id
        vendorId
        vendor {
          id
          name
        }
        merchant {
          id
          name
          code
        }
        category {
          description
          providerId
          categoryId
        }
        accountingIntegrationExpenseAccount {
          description
          providerId
          provider
        }
      }
      pageData {
        totalCount
        totalPages
        currentPage
        nextPage
        prevPage
      }
    }
  }
`;

export const UPDATE_ACCOUNTING_INTEGRATION_VENDOR_MAPPINGS = gql`
  mutation UpdateMerchantAccountingIntegrationVendorMapping(
    $borrowerMerchantId: ID!
    $vendorId: ID
    $categoryId: String
    $accountingIntegrationAccountId: String
    $accountingIntegrationAccountName: String
  ) {
    updateMerchantAccountingIntegrationVendorMapping(
      borrowerMerchantId: $borrowerMerchantId
      vendorId: $vendorId
      categoryId: $categoryId
      accountingIntegrationAccountId: $accountingIntegrationAccountId
      accountingIntegrationAccountName: $accountingIntegrationAccountName
    ) {
      vendorId
    }
  }
`;

export const SYNC_ACCOUNTING_INTEGRATION_VENDORS = gql`
  mutation SyncAccountingIntegrationVendors {
    syncAccountingIntegrationVendors
  }
`;
