import { useState, useEffect, useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { get, isEmpty } from 'lodash';

import { DetailsProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { useAccountsModal } from 'components/Accounts/AccountsContent/components/AccountsModal/hooks';
import { FundsContext } from 'components/Accounts/AccountsContent/contexts/FundsContext';
import { Currencies } from 'constants/currencies';
import { FundsPayloadType } from 'components/Accounts/AccountsContent/components/AccountsModal/types/funds';
import { formatMoneyV2, centsFromMoneyString } from 'utility/currency';

const useDetails = ({ onNextStep }: DetailsProps) => {
  const { walletId, displayName, availableBalance, currency = Currencies.CAD, bankAccounts } = useAccountsModal();

  const { withdrawFundsInfo, setWithdrawFundsInfo, error } = useContext(FundsContext);

  const formattedBalance = `${formatMoneyV2(availableBalance)} ${availableBalance?.currency}`;
  const { amount } = get(withdrawFundsInfo, 'amount', { amount: 0, currency: currency });
  const [formattedAmount, setFormattedAmount] = useState(formatMoneyV2({ amount, currency }));
  const [fromAccount, setFromAccount] = useState(walletId);
  const [zeroError, setZeroError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const isDisabled = zeroError && isSubmitted;

  useEffect(() => {
    setFromAccount(walletId);
  }, [walletId]);

  const toOptions = useMemo(
    () =>
      bankAccounts
        .filter((ba) => ba.currency === currency)
        .map((ba) => {
          return { name: ba.displayName, value: ba.id, currency: ba.currency };
        }),
    [bankAccounts, currency]
  );
  useEffect(() => {
    if (formattedAmount === '$0.00') {
      setZeroError(true);
    } else {
      setZeroError(false);
    }
  }, [formattedAmount]);

  const noBankAccountConnected = !bankAccounts.some((bankAccount) => bankAccount.currency === currency);

  const handleOnNextStep = (data: FundsPayloadType) => {
    setWithdrawFundsInfo({ ...data, amount: { amount: centsFromMoneyString(data.amount), currency } });
    onNextStep();
  };

  const form = useForm({
    defaultValues: {
      ...withdrawFundsInfo,
      amount: formattedAmount,
    },
  });

  const { handleSubmit, register, setError, clearErrors, errors } = form;

  useEffect(() => {
    const amountInCents = centsFromMoneyString(formattedAmount);

    if (!availableBalance?.amount || availableBalance.amount < amountInCents) {
      setError('amount', { type: 'manual', message: 'Insufficient funds' });
      return;
    }

    clearErrors('amount');
  }, [availableBalance?.amount, formattedAmount]);

  useEffect(() => {
    if (isDisabled) {
      setError('amount', { type: 'manual', message: 'Amount must be greater than $0.00' });
      return;
    }

    clearErrors('amount');
  }, [isDisabled]);

  return {
    displayName,
    formattedBalance,
    currency,
    formattedAmount,
    setFormattedAmount,
    fromAccount,
    toOptions,
    noBankAccountConnected,
    handleOnNextStep,
    form,
    handleSubmit,
    register,
    formatMoneyV2,
    isError: !isEmpty(errors) || isDisabled,
    setIsSubmitted,
    errorMessage: error?.message,
  };
};

export default useDetails;
