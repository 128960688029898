import React from 'react';

const Refresh = ({ size = 21, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.125 5.98828C13.125 5.98828 14.1241 5.49609 10.5 5.49609C9.20206 5.49609 7.93327 5.88098 6.85407 6.60207C5.77488 7.32317 4.93374 8.34809 4.43704 9.54723C3.94034 10.7464 3.81038 12.0659 4.0636 13.3389C4.31682 14.6119 4.94183 15.7812 5.85961 16.699C6.7774 17.6168 7.94672 18.2418 9.21972 18.495C10.4927 18.7482 11.8122 18.6183 13.0114 18.1216C14.2105 17.6249 15.2354 16.7837 15.9565 15.7045C16.6776 14.6253 17.0625 13.3565 17.0625 12.0586"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.5 2.37891L13.7812 5.66016L10.5 8.94141"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Refresh;
