import { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { GraphQLError } from 'graphql';

import { ampTrackEvent } from 'amplitude';
import { VALIDATE_SOLID_PROJECTIONS, GET_SOLID_REQUIREMENTS } from 'graphql/wallets';
import { useToggle } from 'hooks';
import { AccountProjections } from 'components/Accounts/components/USBankAccountVerificationModal/USBankAccountVerificationModal.types';
import { bankAccountVerificationSteps } from '../constants';

const useUSBankAccountVerificationModal = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [errorSteps, setErrorSteps] = useState<number[]>([]);
  const { isOpen: isCompleted, open: onComplete } = useToggle();

  const [validateSolidProjections] = useMutation(VALIDATE_SOLID_PROJECTIONS, {
    refetchQueries: [GET_SOLID_REQUIREMENTS],
    fetchPolicy: 'network-only',
    awaitRefetchQueries: true,
  });

  const onPrevStep = () => {
    setCurrentStep((prevStep) => (prevStep === 0 ? prevStep : prevStep - 1));
  };

  const onNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const onSubmitNaicsAndProjections = useCallback(
    async ({ naicsCode, projections }: { naicsCode: string; projections: AccountProjections }) => {
      const processingToast = toast.loading('Submitting account details...');

      const { transactionsSentVolume, transactionsSentAmount, transactionsReceivedVolume, transactionsReceivedAmount } =
        projections;

      try {
        const result = await validateSolidProjections({
          variables: {
            naicsCode,
            transactionsSentVolume,
            transactionsSentAmount,
            transactionsReceivedVolume,
            transactionsReceivedAmount,
          },
        });

        if (!result.data?.validateSolidProjections?.internalId) {
          throw new GraphQLError('Failed to validate account projections');
        }

        toast.update(processingToast, {
          render: 'Account details were submit successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });

        ampTrackEvent('Solid: account validation: success');

        return result.data.validateSolidProjections.internalId;
      } catch (err) {
        toast.update(processingToast, {
          render: 'Failed to validate account projections.',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });

        ampTrackEvent('Solid: account validation: error');

        setErrorSteps(bankAccountVerificationSteps['Transactions Volumes']);
        throw err;
      }
    },
    []
  );

  return {
    currentStep,
    onPrevStep,
    onNextStep,
    errorSteps,
    setErrorSteps,
    isCompleted,
    onComplete,
    showProgress: currentStep > 0,
    onSubmitNaicsAndProjections,
  };
};

export default useUSBankAccountVerificationModal;
