import React from 'react';
import { FormProvider } from 'react-hook-form';

import { useEnabled } from './hooks';
import { FormFooter } from 'components/UI';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import AutopayBanner from '../AutopayBanner';
import { Loaders } from 'components/cards/Loader';

const Enabled = () => {
  const {
    onNavigateBack,
    currency,
    recurringPaymentType,
    fromAccountLabel,
    form,
    handleSubmit,
    onDisableRecurringPayment,
    loadingDisableRecurringPayment,
    loadingRecurringPaymentData,
    formattedNextPaymentDate,
  } = useEnabled();

  if (loadingDisableRecurringPayment || loadingRecurringPaymentData) return <Loaders.Light />;

  return (
    <div className="tw-px-4 tw-mb-16 lg:tw-px-0">
      {/* TODO: Refactor this header since the NotEnabled view also uses it, just with different text and styling based on enabled boolean */}
      <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-x-16 tw-gap-y-4 lg:tw-gap-y-0 tw-mt-2 tw-mb-6 lg:tw-my-8">
        <h3>Configure Automatic Payments</h3>
        <div className="tw-rounded-md tw-bg-primary-dark-green tw-py-2 tw-px-4 tw-text-neutral-light tw-text-center">
          Currently Enabled
        </div>
      </div>
      <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row tw-justify-start tw-gap-10 tw-my-8 tw-w-full">
        <div className="tw-flex tw-flex-col tw-gap-y-6">
          {currency && (
            <div className="tw-flex tw-items-center tw-gap-x-8">
              <div className="tw-w-44">Payment Currency</div>
              <div className="tw-flex tw-items-center tw-bg-primary-light-green tw-px-4 tw-py-2 tw-w-52 tw-rounded-md tw-text-center">
                All Balances in {currency}
                <div className="tw-w-5 tw-ml-3">
                  <MobileCurrencyFlag currency={currency} size={20} />
                </div>
              </div>
            </div>
          )}
          <div className="tw-flex tw-items-center tw-gap-x-8">
            <div className="tw-w-44">Payment Type</div>
            <div className="tw-bg-primary-light-green tw-px-4 tw-py-2 tw-w-52 tw-rounded-md tw-text-center">
              {recurringPaymentType}
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-gap-x-8">
            <div className="tw-w-44">Account to Pay From</div>
            <div className="tw-bg-secondary-pastel-yellow-80 tw-px-4 tw-py-2 tw-w-52 tw-rounded-md tw-text-center">
              {fromAccountLabel}
            </div>
          </div>
          {formattedNextPaymentDate && (
            <div className="tw-flex tw-gap-x-8">
              <div>Next Automatic Payment</div>
              <div>{formattedNextPaymentDate}</div>
            </div>
          )}
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onDisableRecurringPayment)}>
              <FormFooter cancelButtonLabel="Back" onCancel={onNavigateBack} submitButtonLabel="Disable Autopay" />
            </form>
          </FormProvider>
        </div>
        {currency && (
          <div className="tw-self-start">
            <AutopayBanner currency={currency} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Enabled;
