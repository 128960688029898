import React from 'react';

const CardAndStar = ({ size = 78, ...other }) => {
  const width = size;
  const height = (66 * width) / 78;

  return (
    <svg width={width} height={height} viewBox="0 0 78 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <g clipPath="url(#clip0_3857_132327)">
        <path
          d="M32.348 25.665C37.2357 23.6717 39.5821 18.0934 37.5887 13.2057C35.5954 8.31791 30.0171 5.97154 25.1294 7.96491C20.2416 9.95828 17.8952 15.5365 19.8886 20.4243C21.882 25.312 27.4602 27.6584 32.348 25.665Z"
          fill="#FFE8C3"
        />
        <path
          d="M35.4467 14.6179C35.4467 9.16837 31.0254 4.74707 25.5759 4.74707C20.1264 4.74707 15.7051 9.16837 15.7051 14.6179C15.7051 20.0674 20.1264 24.4887 25.5759 24.4887C31.0254 24.4887 35.4467 20.0674 35.4467 14.6179Z"
          stroke="#4F5154"
          strokeWidth="0.55476"
          strokeMiterlimit="10"
        />
        <path
          d="M25.5762 10.5059V18.7315"
          stroke="#4F5154"
          strokeWidth="0.55476"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.6847 14.6182H21.459"
          stroke="#4F5154"
          strokeWidth="0.55476"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        opacity="0.5"
        d="M73.2434 36.2679L49.0834 26.7536C46.6254 25.7857 43.8481 26.9936 42.8802 29.4515L36.3704 45.9821C35.4024 48.4401 36.6103 51.2173 39.0683 52.1853L63.2284 61.6996C65.6864 62.6676 68.4636 61.4597 69.4316 59.0017L75.9414 42.4711C76.9093 40.0131 75.7014 37.2359 73.2434 36.2679Z"
        fill="#D0E7F4"
      />
      <path
        d="M68.6966 38.4271L44.5365 28.9128C42.0785 27.9448 39.3013 29.1527 38.3333 31.6107L31.8235 48.1413C30.8556 50.5993 32.0635 53.3765 34.5214 54.3445L58.6815 63.8588C61.1395 64.8267 63.9167 63.6188 64.8847 61.1609L71.3945 44.6303C72.3624 42.1723 71.1545 39.3951 68.6966 38.4271Z"
        stroke="#4F5154"
        strokeWidth="0.754003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M37.084 34.79L70.1452 47.8096" stroke="#4F5154" strokeWidth="0.754003" strokeLinejoin="round" />
      <path
        d="M43.8753 47.3792L40.0605 45.877L39.4346 47.4664L43.2494 48.9687L43.8753 47.3792Z"
        stroke="#4F5154"
        strokeWidth="0.754003"
        strokeLinejoin="round"
      />
      <path
        d="M14.4058 30.511C14.4593 30.3541 14.6899 30.3858 14.6991 30.5513L15.1717 39.1233C15.1751 39.1847 15.2154 39.2379 15.2736 39.2578L23.3993 42.0282C23.5563 42.0817 23.5245 42.3124 23.359 42.3215L14.787 42.7941C14.7256 42.7975 14.6724 42.8379 14.6525 42.8961L11.8821 51.0218C11.8286 51.1787 11.5979 51.1469 11.5888 50.9814L11.1162 42.4094C11.1128 42.348 11.0725 42.2948 11.0143 42.275L2.88852 39.5045C2.73161 39.451 2.76336 39.2204 2.92888 39.2112L11.5009 38.7386C11.5623 38.7352 11.6155 38.6949 11.6353 38.6367L14.4058 30.511Z"
        fill="#8F5CCF"
      />
      <path
        d="M75.9513 14.3955C76.1134 14.4301 76.1092 14.6629 75.9459 14.6916L65.2317 16.5728C65.1711 16.5835 65.1231 16.6298 65.1103 16.69L62.8442 27.3295C62.8096 27.4916 62.5768 27.4874 62.5482 27.3242L60.6669 16.6099C60.6562 16.5493 60.6099 16.5013 60.5497 16.4885L49.9102 14.2224C49.7481 14.1879 49.7523 13.955 49.9155 13.9264L60.6298 12.0451C60.6904 12.0345 60.7384 11.9881 60.7512 11.928L63.0173 1.28843C63.0518 1.1263 63.2847 1.13049 63.3133 1.29377L65.1946 12.008C65.2053 12.0686 65.2516 12.1166 65.3117 12.1295L75.9513 14.3955Z"
        fill="#EEA530"
      />
      <defs>
        <clipPath id="clip0_3857_132327">
          <rect width="26.3221" height="26.3221" fill="white" transform="translate(12.4121 1.45703)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CardAndStar;
