import React from 'react';

import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import { ReceiptsContainer } from 'components/receipts/unmatched/ReceiptsContainer';
import { AttributeRow } from '../..';
import { isCardExpenseRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';

const AttachReceiptModal = ({ show, setShow, transaction, formattedAmount, setSelectedReceipt }) => {
  const onClose = () => setShow(false);

  return (
    <Modal show={show} onClose={onClose}>
      <div
        className="tw-bg-neutral-light tw-rounded tw-py-5 tw-shadow-sm tw-overflow-hidden"
        data-testid="credit-consent-modal"
      >
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-10">
          <h4>Select Receipt to Attach to Transaction</h4>
          <Close className="tw-cursor-pointer" onClick={onClose} />
        </div>
        <hr className="tw-mb-6 tw-mt-5" />
        <div className="tw-px-10">
          <div className="tw-mt-8 tw-rounded-md tw-bg-neutral-light tw-p-2 lg:tw-p-0">
            <AttributeRow
              label="From"
              value={isCardExpenseRequestDataType(transaction) ? transaction.fromLabel : transaction.from}
            />
            <AttributeRow
              label="To"
              value={isCardExpenseRequestDataType(transaction) ? transaction.merchantName : transaction.to}
            />
            {transaction.description && <AttributeRow label="Description" value={transaction.description} />}
            <AttributeRow
              label="Amount"
              value={
                <>
                  <span className="tw-font-bold tw-mr-1">{formattedAmount}</span>
                  {isCardExpenseRequestDataType(transaction)
                    ? transaction.amountRequested.currency
                    : transaction.amount.currency}
                </>
              }
            />
            <AttributeRow
              label="Currency"
              value={
                <div className="tw-flex tw-items-center">
                  <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mt-1 tw-mr-2">
                    <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                      <MobileCurrencyFlag
                        currency={
                          isCardExpenseRequestDataType(transaction)
                            ? transaction.amountRequested.currency
                            : transaction.amount.currency
                        }
                        size={14}
                      />
                    </div>
                  </div>
                  <span>
                    {isCardExpenseRequestDataType(transaction)
                      ? transaction.amountRequested.currency
                      : transaction.amount.currency}
                  </span>
                </div>
              }
            />
          </div>
          <ReceiptsContainer setSelectedReceipt={setSelectedReceipt} />
        </div>
      </div>
    </Modal>
  );
};

export default AttachReceiptModal;
