import { useHistory } from 'react-router-dom';

const useNavigateToConfigureAutopay = () => {
  const history = useHistory();
  const navigateToConfigureAutopay = () => history.push('/dashboard/payments/pay-balance/configure-autopay');

  return { navigateToConfigureAutopay };
};

export default useNavigateToConfigureAutopay;
