import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BiCheck } from 'react-icons/bi';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';

import useSetPageTitle from '../../hooks/useSetPageTitle';
import Clock from '../svg/Clock';
import waitingRoomJson from '../lottie/waitingRoom.json';
import Layout from './Layout';

const WaitingRoom = ({ isDeclined, isOk, isManualKYCReview, isMissingContactDetails }) => {
  const history = useHistory();
  useSetPageTitle('Waiting room');

  useEffect(() => {
    const dashboardRedirectionTimer = setTimeout(() => {
      history.push('/onboarding/dashboard/home');
    }, 30_000);

    return () => clearTimeout(dashboardRedirectionTimer);
  }, [isDeclined, isOk, isManualKYCReview, isMissingContactDetails]);

  return (
    <Layout>
      <div className="tw-w-full lg:tw-w-2/5 tw-mt-16 tw-flex tw-flex-col tw-items-center tw-flex-grow">
        <Lottie loop animationData={waitingRoomJson} play style={{ width: 150, height: 150 }} />
        <h1 data-testid="waiting-room-title" className="tw-mb-4 tw-text-center">
          We are verifying your account...
        </h1>
        <p>
          Please wait, we are verifying the information you provided. This page should automatically re-load within the
          next 5 minutes.
        </p>
        <div className="tw-flex tw-flex-col tw-justify-center tw-mt-16">
          <div className="tw-flex tw-items-center">
            <div className="tw-mr-8 tw-w-5 tw-h-5 tw-rounded-xl tw-bg-primary-dark-green tw-border-2 tw-border-primary-light-green">
              <BiCheck className="tw-text-neutral-light" />
            </div>
            <p className="tw-text-neutral-grey-2">Filling the form</p>
          </div>

          <div className="tw-flex tw-items-center tw-mt-8">
            <Clock className="tw-mr-8 tw-w-5 tw-h-5 tw-text-primary-dark-green" />
            <p>Verification</p>
          </div>

          <div className="tw-flex tw-items-center tw-mt-8">
            <div className="tw-mr-8 tw-w-5 tw-h-5 tw-rounded-xl tw-border tw-border-dashed tw-border-neutral-grey-2" />
            <p className="tw-text-neutral-grey-2">Account opening</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WaitingRoom;
