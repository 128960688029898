import React from 'react';
import { CREDIT_CARD_EMPTY_STATES } from 'constants/index';

import { useTransactionsContent } from './hooks';
import ReviewInProgressBanner from 'components/creditCards/ReviewInProgressBanner';
import PendingBankAccountVerificationBanner from 'components/creditCards/PendingBankAccountVerificationBanner';
import { ConnectBankOrContactLoopBanner, PaginationControl } from 'components/UI';
import { TableSkeleton, PaginationSkeleton, MobileTableSkeleton } from '../Skeletons';
import { TransactionsTable } from '../';
import { EmptyTransactions } from '../NoTransactionsBanner';
import { Filter, TransactionDetailOverlay as TransactionDetailOverlayDesktop } from '../desktop';
import {
  MobileFilter,
  TransactionDetailOverlay as TransactionDetailOverlayMobile,
  TransactionsByDate,
} from '../mobile';

const TransactionsContent = () => {
  const {
    productType,
    accountState,
    accountNames,
    totalCount,
    page,
    numPerPage,
    setPage,
    setNumPerPage,
    isMobile,
    isLoading,
    hasTransactions,
  } = useTransactionsContent();

  if (accountState === CREDIT_CARD_EMPTY_STATES.reviewInProgress)
    return <ReviewInProgressBanner title="Transactions" />;

  if (accountState === CREDIT_CARD_EMPTY_STATES.waitingBankAccountVerification)
    return <PendingBankAccountVerificationBanner title="Transactions" />;

  if (
    accountState === CREDIT_CARD_EMPTY_STATES.connectBankOrContactLoop ||
    accountState === CREDIT_CARD_EMPTY_STATES.connectBankOrContactLoopAsLOCUser
  )
    return (
      <ConnectBankOrContactLoopBanner
        title="Transactions"
        message="Connect the bank account you will be using to transfer funds to your Loop Card. Once connected, you'll be able to create Loop cards and start spending."
      />
    );

  return (
    <div className={`tw-flex tw-flex-col tw-w-full tw-pb-16`}>
      {isMobile ? <MobileFilter /> : <Filter accountNames={accountNames} className="tw-my-4" />}

      {isLoading && (
        <>
          {isMobile ? <MobileTableSkeleton /> : <TableSkeleton />}
          <PaginationSkeleton />
        </>
      )}

      {!isLoading &&
        (isMobile ? (
          <TransactionsByDate accountNames={accountNames} />
        ) : (
          <TransactionsTable accountNames={accountNames} />
        ))}

      {hasTransactions ? (
        <>
          {isMobile ? (
            <TransactionDetailOverlayMobile accountNames={accountNames} productType={productType} />
          ) : (
            <TransactionDetailOverlayDesktop accountNames={accountNames} productType={productType} />
          )}

          <div className="tw-mt-12 tw-px-2">
            <PaginationControl
              page={page}
              onChangePage={(val) => setPage(val)}
              onChangeNumberPerPage={(perPage) => setNumPerPage(perPage)}
              numPerPage={numPerPage}
              numberOfItems={totalCount}
            />
          </div>
        </>
      ) : (
        !isLoading && <EmptyTransactions />
      )}
    </div>
  );
};

export default TransactionsContent;
