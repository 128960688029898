import React, { useEffect, useMemo } from 'react';
import countryData from 'country-region-data';
import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { getCountrySubdivisions } from 'utility/countries';
import { TextField, Select, ReadOnlyCountry } from 'components/FormFields/v2';
import { useAddressAutoComplete } from 'hooks';

const AddressFields = ({
  streetAddressLabel,
  cityLabel,
  name,
  teamMemberSignUp = false,
  selectedCountry,
  rootClass = '',
  autocompleteCountryRestrictions,
  disabled = false,
  initialAddress,
}) => {
  const { register, unregister, setValue, watch } = useFormContext();

  const watchStreet = watch(`${name}.street`);
  const watchCountry = selectedCountry || watch(`${name}.country`);

  const { address: autoCompleteAddress, inputRef } = useAddressAutoComplete({
    countryRestrictions: autocompleteCountryRestrictions,
  });

  const address = autoCompleteAddress || initialAddress;

  const handleChange = (e) => {
    const streetValue = e.target?.value;
    if (!streetValue) return;

    setValue(`${name}.street`, streetValue);
  };

  useEffect(() => {
    if (isEmpty(address)) return;

    const { street, unitNumber, city, countrySubdivision, country, postalCode } = address;

    setValue(`${name}.street`, street);
    setValue(`${name}.unitNumber`, unitNumber);
    setValue(`${name}.city`, city);
    setValue(`${name}.countrySubdivision`, countrySubdivision);
    setValue(`${name}.country`, country);
    setValue(`${name}.postalCode`, postalCode);
  }, [address, setValue]);

  const countrySubdivisions = useMemo(() => getCountrySubdivisions(watchCountry), [watchCountry]);

  // watchCountry and countrySubdivisions are in the deps list because
  // we need to trigger rerender each time they changed
  useEffect(() => {
    if (isEmpty(address)) return;

    const { countrySubdivision } = address;

    setValue(`${name}.countrySubdivision`, countrySubdivision);
  }, [address, setValue, watchCountry, countrySubdivisions]);

  // set the initial input value
  useEffect(() => {
    if (watchStreet && inputRef.current) inputRef.current.value = watchStreet;
  }, [watchStreet, inputRef.current]);

  // handle input field registration in the form
  useEffect(() => {
    register(`${name}.street`, { required: true });

    return () => {
      unregister(`${name}.street`);
    };
  }, []);

  const canada = watchCountry === 'CA';
  const countryOptions = () => {
    const availableCountries = [];

    countryData.map((country) => {
      if (country.countryName === 'Canada' || country.countryName === 'United States') {
        availableCountries.push({
          name: country.countryName,
          value: country.countryShortCode,
          regions: country.regions,
        });
      }
    });

    return availableCountries;
  };

  return (
    <div className={`${rootClass}`}>
      {teamMemberSignUp && (
        <Select
          name={`${name}.country`}
          data-testid={`${name}.country`}
          label="Country"
          placeholder="Select Option"
          ref={register({ required: true })}
          options={countryOptions()}
          rootClass="tw-mb-8"
        />
      )}
      <TextField
        rootClass="tw-mb-8"
        id={`${name}.street`}
        name={`${name}.street`}
        label={streetAddressLabel || 'Street Address'}
        onChange={handleChange}
        ref={(e) => {
          inputRef.current = e;
          register({ required: true })(e);
        }}
        required
        disabled={disabled}
      />
      <TextField
        rootClass="tw-mb-8"
        name={`${name}.unitNumber`}
        label="Apartment / Suite / PO Box"
        ref={register()}
        disabled={disabled}
      />
      <TextField
        rootClass="tw-mb-8"
        name={`${name}.city`}
        label={cityLabel || 'City'}
        ref={register({ required: true })}
        required
        disabled={disabled}
      />

      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between">
        <TextField
          rootClass="tw-mb-8 tw-flex tw-flex-grow tw-mr-2"
          name={`${name}.postalCode`}
          label={canada ? 'Postal Code' : 'Zip Code'}
          ref={register({ required: true })}
          required
          disabled={disabled}
        />
        {teamMemberSignUp ? (
          <Select
            rootClass="tw-mb-8 tw-flex tw-flex-grow"
            name={`${name}.countrySubdivision`}
            label={canada ? 'Province' : 'State'}
            placeholder="Select"
            ref={register({ required: true })}
            options={countrySubdivisions}
            defaultValue={countrySubdivisions[0].value}
            required
          />
        ) : (
          <Select
            rootClass="tw-mb-8 tw-flex tw-flex-grow"
            name={`${name}.countrySubdivision`}
            label={canada ? 'Province' : 'State'}
            placeholder="Select"
            ref={register({ required: true })}
            options={countrySubdivisions}
            required
            disabled={disabled}
          />
        )}
      </div>

      {!teamMemberSignUp && (
        <ReadOnlyCountry
          name={`${name}.country`}
          rootClass="tw-mb-8"
          label="Country"
          ref={register({ required: true })}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default AddressFields;
