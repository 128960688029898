import React from 'react';
import { get } from 'lodash';

import { Loaders } from 'components/cards/Loader';
import { VendorMappingPayload } from 'components/settings/AccountingServices/AccountingServices.types';
import SearchVendorDropdown from '../SearchVendorDropdown';
import SearchExpenseAccountsDropdown from '../SearchExpenseAccountsDropdown';
import SearchCategoryDropdown from '../SearchCategoryDropdown';
import { BorrowerMerchant } from 'types/accounting';

const MappingRow = ({
  borrowerMerchant,
  setMappingRequest,
  lastRowSaved,
  isUpdatingMapping,
}: {
  borrowerMerchant: BorrowerMerchant;
  setMappingRequest: (args: VendorMappingPayload) => void;
  lastRowSaved: { [key: string]: boolean };
  isUpdatingMapping: boolean;
}) => {
  return (
    <tr>
      <td>{borrowerMerchant.merchant.name}</td>
      <td>
        <SearchVendorDropdown
          id={borrowerMerchant.id}
          selectedLabel={borrowerMerchant.vendor?.name}
          selectedVal={borrowerMerchant.vendor?.id}
          handleChange={(e) =>
            e?.id &&
            setMappingRequest({
              borrowerMerchantId: borrowerMerchant.id,
              vendorId: e?.id,
              categoryId: borrowerMerchant.category?.categoryId,
              accountingIntegrationAccountId: borrowerMerchant.accountingIntegrationExpenseAccount?.providerId,
              accountingIntegrationAccountName: borrowerMerchant.accountingIntegrationExpenseAccount?.description,
            })
          }
        />
      </td>
      <td>
        <SearchExpenseAccountsDropdown
          id={borrowerMerchant.id}
          selectedVal={borrowerMerchant.accountingIntegrationExpenseAccount?.providerId}
          handleChange={(e) =>
            e?.id &&
            setMappingRequest({
              borrowerMerchantId: borrowerMerchant.id,
              vendorId: borrowerMerchant.vendor?.id,
              categoryId: borrowerMerchant.category?.categoryId,
              accountingIntegrationAccountId: e.id,
              accountingIntegrationAccountName: e.name,
            })
          }
        />
      </td>
      <td>
        <SearchCategoryDropdown
          id={borrowerMerchant.id}
          selectedVal={borrowerMerchant.category?.categoryId}
          handleChange={(e) =>
            e?.id &&
            setMappingRequest({
              categoryId: e.id,
              borrowerMerchantId: borrowerMerchant.id,
              vendorId: borrowerMerchant.vendor?.id,
              accountingIntegrationAccountId: borrowerMerchant.accountingIntegrationExpenseAccount?.providerId,
              accountingIntegrationAccountName: borrowerMerchant.accountingIntegrationExpenseAccount?.description,
            })
          }
        />
      </td>
      <td className="tw-text-primary-dark-green tw-text-sm">
        <div className="tw-w-10">
          {get(lastRowSaved, borrowerMerchant.id) && (isUpdatingMapping ? <Loaders.Spinner /> : 'Saved!')}
        </div>
      </td>
    </tr>
  );
};

export default MappingRow;
