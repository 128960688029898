import React from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';

const ClosedCardActions = () => (
  // TODO: Add button and modal to remove card
  <div className="tw-flex tw-flex-col tw-w-full tw-py-4">
    <div className="tw-flex tw-items-start tw-rounded-md tw-bg-neutral-grey-4 tw-p-2 tw-mt-2">
      <IoInformationCircleOutline size={24} className="tw-mr-2" />
      <span className="tw-text-xs tw-text-neutral-grey-1">
        This card is closed. You can remove it from your list of cards.
      </span>
    </div>
  </div>
);

export default ClosedCardActions;
