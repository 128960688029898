import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { Currency } from './Currency';
import Button from 'components/Button';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import { NewAccountModalContext } from 'context/AccountingServices';
import {
  CREATE_QUICKBOOK_LIABILITY_ACCOUNT,
  GET_CURRENCY_QUICKBOOKS_LIABILITY_ACCOUNT_MAPPING,
  GET_QUICKBOOK_LIABILITY_ACCOUNTS,
} from 'graphql/accountingServices';
import { CURRENCY_REFS } from 'constants/accountingService';

export const QuickBooksNewLiabilityModal = ({ accountName }) => {
  const { showNewLiability, currency, name, setName, resetAndClose } = useContext(NewAccountModalContext);

  const [createAccount, { loading }] = useMutation(CREATE_QUICKBOOK_LIABILITY_ACCOUNT, {
    refetchQueries: [
      { query: GET_QUICKBOOK_LIABILITY_ACCOUNTS },
      { query: GET_CURRENCY_QUICKBOOKS_LIABILITY_ACCOUNT_MAPPING },
    ],
  });

  const handleClose = () => resetAndClose();

  const handleCreate = async () => {
    try {
      await createAccount({ variables: { name, currencyRef: CURRENCY_REFS[currency] } });
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="tw-bg-gray-90">
      <Modal show={showNewLiability} onClose={handleClose}>
        <div className="tw-flex tw-flex-row tw-justify-between tw-px-8 tw-py-4 tw-border-b tw-border-neutral-grey-4">
          <span>New {accountName} Account</span>
          <Close role="button" className="tw-cursor-pointer" onClick={handleClose} />
        </div>
        <div className="tw-p-8 tw-flex tw-flex-col tw-items-center tw-space-y-10">
          <div className="tw-flex tw-flex-col tw-w-full">
            <label className="tw-flex tw-flex-row tw-items-center tw-justify-between ">
              Name
              <input
                className="tw-px-3 tw-py-2 tw-rounded-md  tw-px-3 tw-py-2 tw-rounded-md tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input"
                type="text"
                label="name"
                name="name"
                value={name}
                onChange={(evt) => setName(evt.target.value)}
              />
            </label>

            <label className="tw-flex tw-flex-row tw-items-center tw-justify-between">
              <span>AccountType</span>
              <span className="">Credit Card/Liability</span>
            </label>

            <label className="tw-flex tw-flex-row tw-items-center tw-justify-between">
              <span>Currency</span>
              <Currency currency={currency} />
            </label>

            {/* 
            <label className="tw-flex tw-flex-row tw-items-center tw-justify-between">
              TaxCodeRef
              <select
                className="tw-px-3 tw-py-2 tw-pr-10 tw-rounded-md  tw-px-3 tw-py-2 tw-rounded-md tw-bg-neutral-light tw-border-1 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input"
                errors={[]}
                selected={tax}
                onClick={(val) => setTax(val)}
              >
                {qboTaxCodes.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </label>
            */}
          </div>
          <div className="tw-flex tw-flex-row tw-justify-between tw-pt-4 tw-w-full">
            <Button secondary onClick={handleClose}>
              Cancel
            </Button>

            <Button isDisabled={name.length === 0} primary onClick={handleCreate} isFetching={loading}>
              Create
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
