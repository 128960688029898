import { useState, useEffect } from 'react';
import { union } from 'lodash';

import { CREDIT_CARD_STATES, CREDIT_CARD_STATUS, CREDIT_CARD_TYPES } from 'constants/index';

const useCardsListFilters = (creditCards) => {
  const [search, setSearch] = useState('');
  const [selectedStates, setSelectedStates] = useState([
    CREDIT_CARD_STATES.active,
    CREDIT_CARD_STATES.activationRequired,
  ]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [visibleCards, setVisibleCards] = useState(creditCards);

  const filterCardsByStatus = (filters, cards) => {
    let filtered = [];
    let active = [];
    let activationRequired = [];
    let frozen = [];
    let closed = [];

    if (filters.includes(CREDIT_CARD_STATES.active)) {
      active = cards.filter((card) => card.status === CREDIT_CARD_STATUS.active);
    }

    if (filters.includes(CREDIT_CARD_STATES.activationRequired)) {
      activationRequired = cards.filter((card) => !card.virtual && card.status === CREDIT_CARD_STATUS.inactive);
    }

    if (filters.includes(CREDIT_CARD_STATES.frozen)) {
      frozen = cards.filter((card) => card.status === CREDIT_CARD_STATUS.suspended);
    }

    if (filters.includes(CREDIT_CARD_STATES.closed)) {
      closed = cards.filter(
        (card) =>
          card.status === CREDIT_CARD_STATUS.stolen ||
          card.status === CREDIT_CARD_STATUS.fraudulent ||
          card.status === CREDIT_CARD_STATUS.damaged ||
          card.status === CREDIT_CARD_STATUS.temporarilySuspended ||
          card.status === CREDIT_CARD_STATUS.admin_suspended ||
          card.status === CREDIT_CARD_STATUS.block ||
          card.status === CREDIT_CARD_STATUS.lost
      );
    }

    filtered = union(active, activationRequired, frozen, closed);

    return filtered;
  };

  useEffect(() => {
    let filtered = creditCards;

    if (search.length > 0) {
      filtered = filtered.filter((card) =>
        `${card.displayName} - ${card.lastFourDigits}`.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (selectedStates.length > 0) {
      filtered = filterCardsByStatus(selectedStates, filtered);
    }

    if (selectedTypes.length > 0) {
      filtered = filtered.filter((card) =>
        selectedTypes.includes(card.virtual ? CREDIT_CARD_TYPES.virtual : CREDIT_CARD_TYPES.physical)
      );
    }

    setVisibleCards(filtered);
  }, [search, selectedStates, selectedTypes, creditCards]);

  const onChangeSearch = (event) => setSearch(event.target.value);
  const onChangeSelectedStates = setSelectedStates;
  const onChangeSelectedTypes = setSelectedTypes;
  const onClearFilters = () => {
    setSelectedStates([]);
    setSelectedTypes([]);
  };

  return {
    onChangeSearch,
    onChangeSelectedStates,
    onChangeSelectedTypes,
    visibleCards,
    selectedStates,
    selectedTypes,
    onClearFilters,
  };
};

export default useCardsListFilters;
