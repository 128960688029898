import { gql } from '@apollo/client';

export const GET_CREDIT_PRODUCTS = gql`
  query CreditProducts {
    me {
      ... on User {
        account {
          bankAccount {
            connected
            verified
          }
          lineOfCredit {
            id
            ledgerAccount {
              id
              currency
              displayName
              availableBalance {
                amount
                currency
              }
            }
            outstandingBalance {
              amount
              currency
            }
            currentBalance {
              amount
              currency
            }
            availableCredit {
              amount
              currency
            }
            creditLimit {
              amount
              currency
            }
            postedBalance {
              amount
              currency
            }
            pendingBalance {
              amount
              currency
            }
            billingCycle {
              startDate
              endDate
              previousStartDate
              previousEndDate
            }
            paymentDate
            amountDue {
              amount
              currency
            }
          }
          pendingTasks
        }
      }
    }
  }
`;

export const GET_GLOBAL_CREDIT_LIMIT = gql`
  query GlobalCreditLimit {
    me {
      ... on User {
        account {
          globalCreditLimit {
            amount {
              amount
              currency
            }
            creditCardDueDay
          }
        }
      }
    }
  }
`;

export const GET_WALLETS = gql`
  query Wallets {
    me {
      ... on User {
        account {
          bankAccount {
            connected
            verified
          }
          wallets {
            id
            displayName
            pendingAmount {
              amount
              currency
            }
            balance {
              amount
              currency
            }
            availableBalance {
              amount
              currency
            }
            ongoingPaymentsBalance {
              amount
              currency
            }
            currency
            activeExternalAccount
            externalAccounts {
              ... on SolidAccount {
                internalId
              }
              ... on CurrencyCloudExternalAccount {
                internalId
              }
              ... on CoreExternalAccount {
                internalId
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_POINTS_AND_REWARDS_INFO = gql`
  query PointsAndRewardsInfo {
    me {
      ... on User {
        account {
          rewardsAccount {
            internalId
            balance
            pointsInCycle
            offers {
              id
              name
              startDate
              endDate
              subtext
              mainDetails
              fullDetails
              imageUrl
              termsUrl
              activated
              partner {
                name
                about
                link
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PROMOTION_ENROLLMENT = gql`
  mutation CreatePromotionEnrollment($promotionId: ID!) {
    createPromotionEnrollment(promotionId: $promotionId) {
      id
    }
  }
`;
