import React from 'react';
import { FormProvider } from 'react-hook-form';

import { DetailsProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { useDetails } from './hooks';
import { TextField } from 'components/FormFields/v2';
import {
  AmountInput,
  Footer,
  SelectAccountOptions,
  EmptyStateConnectBankAccount,
} from 'components/Accounts/AccountsContent/components/AccountsModal/components';

const Details = ({ onNextStep }: DetailsProps) => {
  const {
    displayName,
    formattedBalance,
    form,
    handleSubmit,
    handleOnNextStep,
    toAccount,
    register,
    currency,
    formattedAmount,
    setFormattedAmount,
    fromOptions,
    noBankAccountConnected,
    formatMoneyV2,
    errorMessage,
    setIsSubmitted,
  } = useDetails({ onNextStep });

  if (noBankAccountConnected) return <EmptyStateConnectBankAccount add={true} />;
  return (
    <>
      <div className="tw-px-8">
        <div className="tw-text-xl tw-font-semibold tw-my-6">How much would you like to add?</div>
        <p>
          You are adding funds to your {displayName}. Your available balance is{' '}
          <strong className="tw-underline">{formattedBalance}</strong>.
        </p>
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleOnNextStep)}>
          <div className="tw-px-8 tw-pt-6 tw-pb-4">
            <TextField name="toAccount" value={toAccount} ref={register({ required: true })} hidden />
            <AmountInput
              currency={currency}
              formattedAmount={formattedAmount}
              formatMoneyV2={formatMoneyV2}
              setFormattedAmount={setFormattedAmount}
              register={register}
            />
            {!!errorMessage && <div className="tw-text-semantic-error">{errorMessage}</div>}
            <SelectAccountOptions options={fromOptions} name="fromAccount" label="From Account" register={register} />
          </div>
          <Footer submitLabel="Review" isDisabled={!!errorMessage} onSubmit={() => setIsSubmitted(true)} />
        </form>
      </FormProvider>
    </>
  );
};

export default Details;
