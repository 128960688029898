import React from 'react';

import BrokenClock from 'components/svg/BrokenClock';
import { ErrorProps } from './Error.types';

const Error = ({ title, message }: ErrorProps) => (
  <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-max-w-md tw-m-auto tw-py-16">
    <BrokenClock />
    <div className="tw-font-bold tw-text-lg">{title}</div>
    <div className="tw-text-center">{message}</div>
  </div>
);

export default Error;
