import React from 'react';

import config from 'config';

const Helpbar = () => {
  return (
    <div className="tw-w-full tw-bg-neutral-grey-5 tw-py-4 tw-px-6 tw-flex tw-justify-between tw-items-center tw-rounded-lg tw-mt-6 tw-mb-16 tw-rounded-md tw-gap-6">
      <div className="tw-text-lg tw-font-semibold tw-text-gray-700">Looking For Volume FX discounts?</div>
      <a
        href={`mailto:${config.supportEmail}`}
        className="tw-border-primary-light-green tw-bg-primary-light-green tw-text-lg tw-font-semibold tw-rounded-lg tw-px-6 tw-py-2"
      >
        Contact Us
      </a>
    </div>
  );
};

export default Helpbar;
