import React from 'react';

import { AccountDetail } from './AccountDetail';

const PlusFeatures = ({ singleView = true }) => {
  return (
    <div className="tw-w-full tw-bg-white tw-p-6">
      <div className="tw-h-1/5 tw-mb-4">
        <div className="tw-font-semibold">Features</div>
        {!singleView && (
          <div className="tw-text-sm">
            Everything in <strong>Free</strong> plus...
          </div>
        )}
      </div>
      <ul className="tw-w-full tw-space-y-4">
        <AccountDetail>Discounted 0.25% FX Fee</AccountDetail>
        <AccountDetail>Request payments at 0.25% + $1 per request</AccountDetail>
        <AccountDetail>Earn 2 Loop Points on Card Spend</AccountDetail>
        <AccountDetail>Unlimited Virtual Cards</AccountDetail>
        <AccountDetail>10 Free Physical Cards</AccountDetail>
        <AccountDetail>Instant Deposits for Credit Card Payments</AccountDetail>
      </ul>
    </div>
  );
};

export default PlusFeatures;
