import { useMemo } from 'react';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import history from 'history.js';
import { ampTrackEvent } from 'amplitude';
import { useIsMobile, useToggle } from 'hooks';
import useIsContactPermission from 'hooks/useIsContactPermission';
import { Currencies } from 'constants/currencies';
import { Wallet } from 'types/wallet';
import { PaymentRequestCurrency } from 'types/invoicing';
import { GET_WALLETS } from 'graphql/wallets';

const useInvoices = ({ isOnboardingPreview }: { isOnboardingPreview?: boolean }) => {
  const { viewPayors, managePayors, createPayors, viewPayorPaymentsRequested, viewAccountBalances } =
    useIsContactPermission();

  const { isOpen: showAddPayorModal, open: onShowAddPayorModal, close: onCloseAddPayorModal } = useToggle();

  const { data: walletsData, loading } = useQuery<{ wallets: Wallet[] }>(GET_WALLETS);
  const wallets = get(walletsData, 'wallets', []);

  const hasUSDAccount = useMemo(
    () => wallets?.find((w) => w.currency === Currencies[PaymentRequestCurrency.USD])?.externalAccountActive,
    [wallets]
  );

  const availableWallets = useMemo(
    () =>
      wallets.filter(
        ({ currency, externalAccountActive }) =>
          currency === Currencies[PaymentRequestCurrency.CAD] ||
          (currency === Currencies[PaymentRequestCurrency.USD] && externalAccountActive)
      ),
    [wallets]
  );

  const handleOpenUSDAccount = () =>
    history.push(isOnboardingPreview ? '/onboarding/dashboard/accounts' : '/dashboard/accounts');

  const handleAddPayor = () => {
    // TODO: temporary code for getting feature statistics
    ampTrackEvent('invoice: open add payor modal');

    onShowAddPayorModal();
  };

  const isMobile = useIsMobile();

  const goBack = () => history.goBack();

  return {
    isMobile,
    showAddPayorModal,
    onCloseAddPayorModal,
    managePayors,
    viewPayors,
    createPayors,
    handleOpenUSDAccount,
    hasUSDAccount,
    handleAddPayor,
    loading,
    wallets: availableWallets,
    goBack,
    viewPayorPaymentsRequested,
    viewAccountBalances,
  };
};

export default useInvoices;
