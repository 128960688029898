import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { SUBMIT_BUSINESS_ADDRESS } from 'graphql/onboarding';
import { ampTrackEvent } from 'amplitude';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { SubmitButton } from 'components/FormFields/v2';
import ArrowRight from 'components/svg/ArrowRight';
import AddressFields from 'components/onboarding/AddressFields';
import Loading from 'components/onboarding/Loading';
import Progress from 'components/onboarding/Progress';
import Layout from 'components/onboarding/Layout';
import Button from 'components/Button';
import { ONBOARDING_STEPS } from 'components/onboarding/constants';

const Form = (props) => {
  const { street, unitNumber, city, countrySubdivision, postalCode } = props;
  const form = useForm({
    defaultValues: {
      address: {
        street,
        unitNumber,
        city,
        countrySubdivision,
        postalCode,
        country: 'CA',
      },
    },
  });
  const { handleSubmit, watch } = form;
  const history = useHistory();
  const [submitBusinessDetail, { loading: isSubmitting, error }] = useMutation(SUBMIT_BUSINESS_ADDRESS);

  const onSubmit = async (data) => {
    try {
      await submitBusinessDetail({ variables: { address: { ...data.address } } });
      ampTrackEvent('onboarding: business_address: success');
      history.push('/onboarding/owners-profile');
    } catch (err) {
      console.error(err);
      ampTrackEvent('onboarding: business_address: error');
    }
  };

  const onGoToPreviousStep = () => history.push('/onboarding/business-details');

  return (
    <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddressFields
          streetAddressLabel="Business Street Address"
          cityLabel="Business City"
          name="address"
          selectedCountry={watch('address.country')}
        />
        <div className="tw-flex tw-justify-between tw-mt-16">
          <Button
            type="button"
            secondary
            className="tw-w-max tw-bg-neutral-grey-5 tw-text-neutral-dark"
            onClick={onGoToPreviousStep}
          >
            Previous step
          </Button>
          <SubmitButton className="tw-w-max" disabled={isSubmitting}>
            <div className="tw-flex tw-justify-center tw-items-center">
              <p className="tw-text-neutral-light tw-mr-2">{isSubmitting ? 'Submitting...' : 'Continue'}</p>
              <ArrowRight />
            </div>
          </SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

const Content = (props) => {
  return (
    <div className="tw-w-full lg:tw-w-4/5 tw-flex tw-flex-col tw-items-center tw-flex-grow">
      <Progress currentStep={3} />
      <div className="tw-w-full lg:tw-w-2/5 tw-mt-16 tw-flex tw-flex-col">
        <h1 data-testid="business-address-title" className="tw-mb-4">
          {ONBOARDING_STEPS.businessAddress.title}
        </h1>
        <p className="tw-mb-12">
          Please provide the registered address for your business that was used on your most recent annual filing.
        </p>
        <Form {...props} />
      </div>
    </div>
  );
};

const BusinessAddress = (props) => {
  const { loading, ...contentProps } = props;
  useSetPageTitle(ONBOARDING_STEPS.businessAddress.label);

  return <Layout>{loading ? <Loading /> : <Content {...contentProps} />}</Layout>;
};

export default BusinessAddress;
