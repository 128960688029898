import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const CashAndStarsSingle = (props) => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.35219 38.6552L29.2992 29.7046C29.7654 29.5145 29.9892 28.9824 29.7991 28.5162L24.9795 16.6986C24.7894 16.2324 24.2573 16.0086 23.7911 16.1988L1.84412 25.1494C1.37792 25.3395 1.15413 25.8716 1.34425 26.3377L6.16382 38.1554C6.35395 38.6216 6.886 38.8454 7.35219 38.6552Z"
        stroke="#4F5154"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M17.7444 29.7218C20.0754 28.7712 21.1944 26.1109 20.2437 23.78C19.2931 21.449 16.6328 20.33 14.3019 21.2807C11.9709 22.2313 10.8519 24.8916 11.8026 27.2225C12.7532 29.5535 15.4135 30.6725 17.7444 29.7218Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M17.2928 31.6476C19.6237 30.697 20.7427 28.0367 19.7921 25.7058C18.8414 23.3748 16.1812 22.2558 13.8502 23.2065C11.5193 24.1571 10.4003 26.8174 11.3509 29.1483C12.3015 31.4793 14.9618 32.5982 17.2928 31.6476Z"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3566 20.075C25.2373 20.5315 23.9824 20.5246 22.8681 20.0559C21.7538 19.5872 20.8713 18.6951 20.4148 17.5757"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.72127 29.7142C3.27553 29.4882 3.77983 29.1552 4.20539 28.7343C4.63096 28.3133 4.96944 27.8127 5.20152 27.2609C5.4336 26.7092 5.55474 26.1171 5.55801 25.5185C5.56128 24.92 5.44662 24.3266 5.22058 23.7724"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4222 25.1401C27.3029 25.5966 26.4107 26.479 25.942 27.5934C25.4733 28.7077 25.4664 29.9625 25.9229 31.0819"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.78687 34.7788C5.34112 34.5528 5.93447 34.4381 6.53303 34.4414C7.1316 34.4446 7.72366 34.5658 8.27541 34.7979C8.82717 35.0299 9.3278 35.3684 9.74874 35.794C10.1697 36.2196 10.5027 36.7239 10.7287 37.2781"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1494 7.50097C16.2332 7.44441 16.3336 7.54485 16.2771 7.62867L13.4773 11.7781C13.4563 11.8092 13.4563 11.8499 13.4773 11.881L16.2771 16.0305C16.3336 16.1143 16.2332 16.2147 16.1494 16.1582L11.9999 13.3584C11.9688 13.3374 11.9281 13.3374 11.897 13.3584L7.74758 16.1582C7.66376 16.2147 7.56333 16.1143 7.61988 16.0305L10.4196 11.881C10.4406 11.8499 10.4406 11.8092 10.4196 11.7781L7.61988 7.62867C7.56333 7.54485 7.66376 7.44441 7.74758 7.50097L11.897 10.3007C11.9281 10.3217 11.9688 10.3217 11.9999 10.3007L16.1494 7.50097Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M43.0989 11.0626C43.314 11.1084 43.3084 11.4172 43.0919 11.4552L35.0924 12.8598C35.012 12.8739 34.9483 12.9354 34.9313 13.0151L33.2394 20.9589C33.1936 21.1739 32.8849 21.1683 32.8468 20.9518L31.4422 12.9523C31.4281 12.872 31.3667 12.8082 31.2869 12.7912L23.3432 11.0993C23.1282 11.0535 23.1337 10.7448 23.3503 10.7067L31.3498 9.30213C31.4301 9.28803 31.4938 9.22657 31.5108 9.14681L33.2027 1.2031C33.2485 0.98807 33.5573 0.993635 33.5953 1.21018L34.9999 9.20968C35.014 9.29 35.0755 9.35372 35.1552 9.37071L43.0989 11.0626Z"
        fill="#EEA530"
      />
    </svg>
  );
};

export default CashAndStarsSingle;
