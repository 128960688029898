import React, { useContext } from 'react';

import { Header, Steps } from './components';
import { CardMigrationContext } from './context/CardMigrationContext';

const CardMigration = () => {
  const { stepTitle, onClose } = useContext(CardMigrationContext);

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-6">
      <Header onClose={onClose} title={stepTitle} />
      <Steps />
    </div>
  );
};

export { CardMigration };
