import React from 'react';
import { FormProvider } from 'react-hook-form';

import { TextField, MoneyInputField, DatePicker } from 'components/FormFields/v2';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { PADAgreement } from 'components/PayorPortal/components';
import { FormFooter } from '..';
import { useConfirmPayment } from './hooks';
import { ConfirmPaymentProps } from './ConfirmPayment.types';
import { Currencies } from 'constants/currencies';

const ConfirmPayment = ({ selectedPaymentRequestId, selectedBankAccountId, onClose }: ConfirmPaymentProps) => {
  const {
    onSubmit,
    form,
    register,
    handleSubmit,
    vendorName,
    vendorEmail,
    fullVendorAddress,
    invoiceNumber,
    formattedAmount,
    currency,
    bankName,
    routingNumber,
    institutionNumber,
    transitNumber,
    accountNumber,
    minDueDate,
    maxDueDate,
    isProcessing,
    isPADAgreementSigned,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  } = useConfirmPayment({ selectedPaymentRequestId, selectedBankAccountId, onClose });

  return (
    <div className="tw-w-full tw-flex tw-flex-col">
      <strong className="tw-mb-4 outline-hidden" tabIndex={0}>
        Confirm Payment
      </strong>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField name="vendorName" label="Vendor" ref={register({ required: true })} disabled rootClass="tw-mb-4" />
          <TextField
            name="bankInfo"
            label="Debit Bank Account Information"
            ref={register({ required: true })}
            disabled
            rootClass="tw-mb-4"
          />

          <div className="tw-flex tw-items-end tw-mb-4">
            <MoneyInputField
              name="amount"
              label="Amount"
              currency={currency}
              value={formattedAmount}
              moneyFormatter={() => {}}
              setValue={() => {}}
              rootClass="tw-flex-grow"
              ref={register({ required: true })}
              disabled
            />
            <div className="tw-flex tw-items-center tw-h-11 tw-bg-neutral-grey-3 tw-rounded-md tw-p-4 tw-flex-grow-0 tw-ml-4">
              <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5 tw-mr-2">
                <MobileCurrencyFlag currency={Currencies[currency]} size={16} />
              </div>
              <p className="tw-text-neutral-grey-2">{currency}</p>
            </div>
          </div>

          <DatePicker
            name="dueDate"
            label="Payment Debit Date"
            minDate={minDueDate}
            maxDate={maxDueDate}
            required
            ref={register({ required: true })}
            rootClass="tw-mb-4"
          />

          {isOngoing && (
            <div className="tw-mb-4">
              This is a recurring payment with the following schedule:
              <ul>
                <li>
                  - Frequency: <b>{frequencyLabel}</b>
                </li>
                <li>
                  - Start date: <b>{formattedStartDate}</b>
                </li>
                {isStoppedByEndDate && formattedEndDate && (
                  <li>
                    - End date: <b>{formattedEndDate}</b>
                  </li>
                )}
                {isStoppedByNumberOfOccurrences && numberOfOccurrences && (
                  <li>
                    - Number of payments: <b>{numberOfOccurrences}</b>
                  </li>
                )}
                {isStoppedByCancel && <li>- The payment will stop upon cancellation by vendor</li>}
              </ul>
            </div>
          )}

          {invoiceNumber && (
            <TextField
              name="invoiceNumber"
              label="Invoice Number"
              ref={register({ required: true })}
              disabled
              rootClass="tw-mb-4"
            />
          )}

          {!isPADAgreementSigned && (
            <PADAgreement
              payeeName={vendorName}
              payeeAddress={fullVendorAddress}
              payeeEmail={vendorEmail}
              payorBankName={bankName}
              payorBankAccountNumber={accountNumber}
              payorBankRoutingNumber={routingNumber}
              payorBankInstitutionNumber={institutionNumber}
              payorBankTransitNumber={transitNumber}
            />
          )}

          <FormFooter submitButtonLabel="Confirm" isDisabled={isProcessing} />
        </form>
      </FormProvider>
    </div>
  );
};

export default ConfirmPayment;
