import React, { useState, useEffect, useContext } from 'react';

import { CardsListContext } from 'components/creditCards/components/CardsList/CardsListContext';
import { CREDIT_CARD_STATES, CREDIT_CARD_TYPES } from 'constants/index';

import { filterCardsByStatus } from 'components/creditCards/components/CardsList/CardsList.utils';

import { CreditCard } from 'types/creditCard';

const useCardsList = () => {
  const { creditCards, error, isLoading } = useContext(CardsListContext);

  const [search, setSearch] = useState('');

  const [selectedStatuses, setSelectedStatuses] = useState([
    CREDIT_CARD_STATES.active,
    CREDIT_CARD_STATES.activationRequired,
  ]);

  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const [filteredCreditCards, setFilteredCreditCards] = useState(creditCards || []);

  const cardCount = creditCards?.length || 0;

  const handleChangeSearch = (e: React.FormEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);

  const onClearFilters = () => {
    setSelectedStatuses([]);
    setSelectedTypes([]);
  };

  useEffect(() => {
    let filtered = (creditCards || []).reduce<CreditCard[]>((acc, card) => {
      if (search.length && !card.displayName?.toLowerCase().includes(search.toLowerCase())) {
        return acc;
      }

      if (
        selectedTypes.length &&
        !selectedTypes.includes(card.virtual ? CREDIT_CARD_TYPES.virtual : CREDIT_CARD_TYPES.physical)
      ) {
        return acc;
      }

      return [...acc, card];
    }, []);

    if (selectedStatuses.length) {
      filtered = filterCardsByStatus(filtered, selectedStatuses);
    }

    setFilteredCreditCards(filtered);
  }, [search, selectedStatuses, selectedTypes, creditCards]);

  return {
    filteredCreditCards,
    cardCount,
    error,
    isLoading,
    search,
    handleChangeSearch,
    selectedStatuses,
    setSelectedStatuses,
    selectedTypes,
    setSelectedTypes,
    onClearFilters,
    creditCards,
  };
};

export default useCardsList;
