import React from 'react';

import { useGetUserInfo, useGetBusinessAddress } from 'hooks';
import { usePADAgreementContent } from './hooks';
import { Loaders } from 'components/cards/Loader';

const PADAgreementContent = () => {
  const { formattedTodaysDate, bankName, transitNumber, institutionNumber, accountNumber, isBankAccount, displayName } =
    usePADAgreementContent();
  const { isLoading, fullName, legalBusinessName, email } = useGetUserInfo();
  const { street, unitNumber, city, countrySubdivision, postalCode } = useGetBusinessAddress();

  if (isLoading) return <Loaders.Small />;

  return (
    <div className="tw-text-sm tw-flex tw-flex-col tw-gap-y-4 lg:tw-h-96 tw-overflow-y-scroll tw-scrollbar-hide">
      <div>This Pre-Authorized Debit (PAD) Agreement is for Business Purposes</div>
      <div>Date: {formattedTodaysDate}</div>
      <div>Authorized Signer: {fullName}</div>
      <div>
        I, the Authorized Signer of the Payor, hereby authorize Loop Financial Inc. and any of its subsidiaries or
        affiliates (collectively, “Loop”), and the Processing Institution (as defined hereinafter) or any other
        financial institution designated by Loop to debit from the Payor’s deposit account designated below (or any
        other account you may authorize at any time) any amounts due and payable under the Loop Card-Based Credit
        Facility Terms and Conditions, (the “Agreement”) entered into between the Payor, as Business Cardholder, and
        Loop Financial Inc., as lender. This authorization shall remain valid until all of the Payor’s obligations under
        the Agreement, as amended or restated, will have been satisfied. For greater certainty, I hereby authorize Loop
        to debit from the Payor’s account from time to time at its sole discretion any payments due and any other fee
        that may become payable thereunder.
      </div>
      <div>
        I understand that Loop Financial Inc. will debit my account for the full amount of my statement balance on the
        date that is seven (7) days before the statement is due. I understand that if I elect to make a payment using a
        Currency (the “Payment Currency”) that is different from the Currency amount that is due, we will convert the
        full balance for such monthly statement to the Payment Currency using the Minimum Payment Exchange Rate.
      </div>
      <div>
        I agree that EFT transactions I authorize comply with all applicable law. I confirm that I have the authority to
        authorize this PAD Agreement.
      </div>
      <div>
        <div className="tw-font-bold">Loop’s Information</div>
        <table className="tw-border tw-table tw-w-full">
          <tr>
            <td className="tw-w-1/2 tw-border-r tw-p-1">Full Legal Name:</td>
            <td className="tw-w-1/2 tw-p-1">Loop Financial Inc.</td>
          </tr>
          <tr className="tw-border-t tw-border-b">
            <td className="tw-w-1/2 tw-border-r tw-p-1">Street Address:</td>
            <td className="tw-w-1/2 tw-p-1">410 Adelaide St W, Toronto ON M5V1S8</td>
          </tr>
          <tr>
            <td className="tw-w-1/2 tw-border-r tw-p-1">Email Address:</td>
            <td className="tw-w-1/2 tw-p-1">pad@bankonloop.com</td>
          </tr>
        </table>
      </div>
      <div>
        <div className="tw-font-bold">Payor’s Information</div>
        <table className="tw-border tw-table tw-w-full">
          <tr>
            <td className="tw-w-1/2 tw-border-r tw-p-1">Full Legal Business Name:</td>
            <td className="tw-w-1/2 tw-p-1">{legalBusinessName}</td>
          </tr>
          <tr className="tw-border-t tw-border-b">
            <td className="tw-w-1/2 tw-border-r tw-p-1">Email Address:</td>
            <td className="tw-w-1/2 tw-p-1">{email}</td>
          </tr>
          <tr>
            <td className="tw-w-1/2 tw-border-r tw-p-1">Street Address:</td>
            <td className="tw-w-1/2 tw-p-1">{`${street} ${unitNumber ? `, ${unitNumber}` : ''}`}</td>
          </tr>
          <tr className="tw-border-t tw-border-b">
            <td className="tw-w-1/2 tw-border-r tw-p-1">City:</td>
            <td className="tw-w-1/2 tw-p-1">{city}</td>
          </tr>
          <tr>
            <td className="tw-w-1/2 tw-border-r tw-p-1">Province:</td>
            <td className="tw-w-1/2 tw-p-1">{countrySubdivision}</td>
          </tr>
          <tr className="tw-border-t tw-border-b">
            <td className="tw-w-1/2 tw-border-r tw-p-1">Postal Code:</td>
            <td className="tw-w-1/2 tw-p-1">{postalCode}</td>
          </tr>
        </table>
      </div>
      <div>
        <div className="tw-font-bold">Payor’s Deposit Account Information</div>
        {isBankAccount ? (
          <table className="tw-border tw-table tw-w-full">
            <tr>
              <td className="tw-w-1/2 tw-border-r tw-p-1">
                Name of Bank (the “<b>Processing Institution</b>”):
              </td>
              <td className="tw-w-1/2 tw-p-1">{bankName}</td>
            </tr>
            <tr className="tw-border-t tw-border-b">
              <td className="tw-w-1/2 tw-border-r tw-p-1">Branch Transit Number: </td>
              <td className="tw-w-1/2 tw-p-1">{transitNumber}</td>
            </tr>
            <tr>
              <td className="tw-w-1/2 tw-border-r tw-p-1">Processing Institution Number: </td>
              <td className="tw-w-1/2 tw-p-1">{institutionNumber}</td>
            </tr>
            <tr className="tw-border-t tw-border-b">
              <td className="tw-w-1/2 tw-border-r tw-p-1">Deposit Account Number:</td>
              <td className="tw-w-1/2 tw-p-1">{accountNumber}</td>
            </tr>
          </table>
        ) : (
          <table className="tw-border tw-table tw-w-full">
            <tr>
              <td className="tw-w-1/2 tw-border-r tw-p-1">Description of the Account:</td>
              <td className="tw-w-1/2 tw-p-1">{displayName}</td>
            </tr>
          </table>
        )}
      </div>
      <div>
        <div className="tw-font-bold">Change of Account:</div>
        In the event of a change in the information of the Payor’s deposit account, the Payor undertakes to notify Loop
        Financial Inc. of such change in writing at least fifteen (15) business days before the date of the next
        scheduled payment and provide it with the information of its new deposit account.
      </div>
      <div>
        <div className="tw-font-bold">Waiver to Pre-Notification:</div>
        YOU HEREBY WAIVE ALL OF THE RIGHTS YOU MAY HAVE TO BE NOTIFIED PRIOR TO A DEBIT FROM YOUR DEPOSIT ACCOUNT FOR
        ANY REGULAR, VARIABLE OR SPORADIC AMOUNT, INCLUDING ANY RECURRING PAYMENTS AND ANY NSF FEE OR LATE PAYMENT FEE.
      </div>
      <div>
        <div className="tw-font-bold">Recourse/Reimbursement Rights: </div>
        The Payor has certain recourse rights if any debit does not comply with this PAD Agreement. For example, the
        Payor has the right to receive reimbursement for any debit that is not authorized or is not consistent with this
        PAD Agreement. To obtain more information on the Payor’s recourse rights, the Payor may contact its Processing
        Institution or visit{' '}
        <a href="www.cdnpay.ca" target="_blank" rel="noopener noreferrer">
          www.cdnpay.ca
        </a>
        .
      </div>
      <div>
        <div className="tw-font-bold">Cancellation Rights:</div>
        This authority is to remain in effect until Loop Payments Inc. has received written notification from me/us of
        its change or termination. This PAD Agreement may be canceled at any time provided that notice in writing is
        received by Loop Payments Inc. no later than thirty (30) days before the next scheduled debit. To obtain a
        sample cancellation form, or for more information on the Payor’s right to cancel this PAD Agreement, contact the
        Payor’s Processing Institution or visit{' '}
        <a href="www.cdnpay.ca" target="_blank" rel="noopener noreferrer">
          www.cdnpay.ca
        </a>
        .
      </div>
      <div>
        <div className="tw-font-bold">Jurisdiction: </div>
        Any legal action or proceeding arising out of or relating to this Agreement shall be instituted in the courts of
        the Province of Ontario, and the parties hereby submit to the exclusive jurisdiction of such courts.
      </div>
    </div>
  );
};

export default PADAgreementContent;
