import React from 'react';

import config from 'config';

const CreditAgreementV2 = () => {
  return (
    <>
      <div className="tw-w-2/3 tw-text-center tw-mx-auto">
        <strong>Loop Card-Based Credit Facility Terms and Conditions</strong>
      </div>
      <p className="tw-my-4">
        This agreement and guarantee (“Card Agreement”) outlines the terms and conditions that govern the Loop credit
        facility (“Card-Based Credit Facility”) extended to you, as Business Cardholder and the individual executing
        this Card Agreement as Guarantor, by Loop Financial Inc., as the lender (“Loop”, “we” or “us”). By requesting a
        Card-Based Credit Facility through the Portal or the Card, you and the Guarantor agree to the following terms
        and conditions.
      </p>
      <p>
        This Card Agreement is supplemental to the Loop multi-currency corporate MasterCard access card agreement (the
        “Access Card Agreement”) which applies to the Card, and the Access Card Agreement continues to apply.
      </p>
      <ol className="tw-p-4">
        <strong>
          <li type="1">
            <span className="tw-ml-4">Definitions</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>In this Card Agreement, the words listed below have the following meaning:</p>
          <p className="tw-my-4">
            “<strong>Account</strong>” means the Card-Based Credit Facility account we open and maintain in the name of
            the Business Cardholder.
          </p>
          <p>
            “<strong>Account Documentation</strong>” means any one or more documents evidencing the Agreement, including
            electronic or technology-based documents.
          </p>
          <p className="tw-my-4">
            “<strong>Agreement</strong>” means collectively all agreements between you and us relating to your Account
            and any Card issued under the Account, including your application for a Card, this Card Agreement, the Loan
            Documents, the Access Card Agreement, the monthly statements, any guarantee or security agreement, Loop’s
            privacy policy, the Rewards Program Terms and Conditions, the terms and conditions relating to any Mobile
            Payments Service provided or developed by us, the terms and conditions relating to any other optional
            service, loyalty program, Rewards Program, special payment plan or promotional offer provided, offered or
            developed by us from time to time together with all amendments, modifications, supplements, and replacements
            to any of the foregoing from time to time in accordance with the terms of this Card Agreement, any
            applicable Agreement and Applicable Law.
          </p>
          <p className="tw-my-4">
            “<strong>Applicable Law</strong>” means the Personal Information Protection and Electronic Documents Act
            (Canada), the Proceeds of Crime (Money Laundering) and Terrorist Financing Act (PCMLTFA), PCI DSS or any
            other statute, regulation or operating rule of any Governmental Authority or any other regulatory authority
            that Loop is subject to, or any bylaw, operating rule or regulation of MasterCard.
          </p>
          <p>
            “<strong>ATM</strong>” means an automated teller machine.
          </p>
          <p className="tw-my-4">
            “<strong>Available Credit</strong>” means the Credit Limit less the Credit Balance and the aggregate amount
            of any authorized or pending Transactions.
          </p>
          <p>
            “<strong>Balance Transfer</strong>” means a cash advance transaction by which you borrow money on your
            Account to pay the outstanding balance on another credit card (other than a card issued by us or one of our
            affiliates) and thereby transfer the balance owing on that other credit card to the Account.
          </p>
          <p className="tw-my-4">
            “<strong>Business Cardholder</strong>” means the business entity, regardless of type, which applied for the
            opening of the Account and the issuance of a Card and in whose name the Account has been opened and to whom
            a Card has been issued under this Card Agreement.
          </p>
          <p>
            “<strong>CAD</strong>", “CA$” means the lawful currency of Canada.
          </p>
          <p className="tw-my-4">
            “<strong>Card</strong>” means the multi-currency corporate card or any other account access device issued by
            us to the Business Cardholder and any Individual Cardholder under the Account, including any renewal or
            replacement of such Card or device.
          </p>
          <p>
            “<strong>Cardholder</strong>” means the Business Cardholder and all Individual Cardholders.
          </p>
          <p className="tw-my-4">
            “<strong>Cash Advance</strong>” includes the following: (i) a cash advance obtained from an ATM or a
            financial institution that accepts the Card; (ii) use of a Card or Account for Cash-Like Transactions or
            Balance Transfers; and (iii) any other Transaction where you or an Individual Cardholder withdraws cash from
            the Account.
          </p>
          <p>
            “<strong>Cash-Like Transaction</strong>” means a Transaction involving the purchase of items that are
            directly convertible into cash or are similar to cash. Cash-Like Transactions include, but are not limited
            to, electronic fund transfers, Mastercard MoneySend transactions, Mastercard Send transactions, wire
            transfers, travellers cheques, money orders and gaming transactions, including betting, off-track betting,
            race track wagers, lottery tickets and casino gaming chips.
          </p>
          <p className="tw-my-4">
            “<strong>Credit Balance</strong>” means the total amount of all Transactions, interest, Fees and any other
            amounts charged to the Account under this Card Agreement, less any payments or credits that have been posted
            to your Account.
          </p>
          <p>
            “<strong>Credit Limit</strong>” means the maximum amount up to which credit is extended under the Account
            and that is available to you to charge Transactions and cover interest and Fees
          </p>
          <p className="tw-my-4">
            “<strong>Currency</strong>” means any one or more of CAD, USD, EUR, GBP and any additional currency that we
            may make available in connection with the Account from time to time.
          </p>
          <p>
            “<strong>EUR</strong>”, “<strong>EU€</strong>” means the lawful currency of the Eurozone.
          </p>
          <p>
            “<strong>Foreign Exchange Fee</strong>” means the fee on foreign currency exchange transactions in the
            amount disclosed at{' '}
            <a href={`${config.landingPageUrl}/pricing`} target="__blank" rel="noreferrer">
              {`${config.landingPageUrl}/pricing`}
            </a>{' '}
            or as otherwise agreed to by Loop and you.
          </p>
          <p className="tw-my-4">
            “<strong>GBP</strong>”, “<strong>GB£</strong>” means the lawful currency of Great Britain.
          </p>
          <p>
            “<strong>Governmental Authority</strong>” means any federal, provincial, territorial, regional, municipal or
            local governmental authority, quasi-governmental authority, government organization, commission, board,
            professional agency, tribunal, organization, or any regulatory, administrative or other agency, or any
            political or other subdivision, department, or branch of any of the foregoing, in each case to the extent it
            has jurisdiction over Loop or any entity, property, transaction, activity, event or other matter related to
            this Card Agreement. The above definition is deemed to include any interim or permanent transferee or
            successor of a Government Authority’s underlying mandate, function or activity.
          </p>
          <p className="tw-my-4">
            “<strong>Guarantor</strong>” means the individual who on behalf of the Business Cardholder executed this
            Card Agreement for the Account and requested the issuance of a Card.
          </p>
          <p>
            “<strong>Individual Cardholder</strong>” means an individual who has been issued a Card under the Account
            with the authorization and at the request of the Business Cardholder.
          </p>
          <p className="tw-my-4">
            “<strong>Loan Documents</strong>” means this Card Agreement, any security document, any guarantee, any
            pre-authorized debit agreement and any other present and future document relating to any of the foregoing,
            in each case, as amended, supplemented or restated.
          </p>
          <p className="tw-my-4">
            “<strong>Loop Account</strong>” means the electronic, online account established for the Business Cardholder
            to establish and authorize other individuals, receive notices, review statements, track balances, and manage
            the provision of Loop services under this Card Agreement.
          </p>
          <p>
            <strong>“Mobile Payments Service</strong>” means Apple Pay, Samsung Pay, Google Pay or any other mobile
            payments service, digital wallet mobile device application or electronic application used to make
            Transactions with any Cardholder’s mobile device.
          </p>
          <p className="tw-my-4">
            “<strong>Obligations</strong>” means, at any time and from time to time, all of the obligations,
            indebtedness and liabilities (present or future, absolute or contingent, matured or not) of any Cardholder
            to us, pursuant or relating to the Loan Documents or the Credit Limit including all principal, interest,
            Fees, charges and expenses, and other amounts payable by the Business Cardholder under this Card Agreement.
          </p>
          <p>
            “<strong>Minimum Payment Exchange Rate</strong>” means the exchange rate that will be used by us based on
            the exchange rate quoted to us by a third-party currency exchange provider we customarily use at the time a
            currency exchange is required to be made in accordance with this agreement plus 5%.
          </p>
          <p className="tw-my-4">
            “<strong>PAPs</strong>” means a pre-authorized payment charged to the Account, including pre-authorized
            payments charged to the Account after this Card Agreement ends or a Card has been cancelled or expires,
            unless written notice to cancel the pre-authorized payment has been received by the merchant or retailer
            before the pre-authorized payment has been charged to the Account.
          </p>
          <p>
            “<strong>Payment Due Date</strong>” means the date appearing on each monthly statement made available on the
            Portal to the Business Cardholder on which the payment is due.
          </p>
          <p className="tw-my-4">
            “<strong>Personal Information</strong>” means any identifiable information about any Individual Cardholder
            in our possession or control.
          </p>
          <p>
            “<strong>PIN</strong>” means a personal identification number, unique number or password which is provided
            by us or selected by a specific Cardholder and which is used in connection with the Cardholer’s Card,
            including when a Card is used at ATMs, merchant terminals or other devices which require a PIN.
          </p>
          <p className="tw-my-4">
            “<strong>Portal</strong>” means the Loop online portal which contains information regarding the Account. The
            Portal may be accessed online through any web-enabled device by visiting the Website.
          </p>
          <p>
            “<strong>Positive Balance</strong>" means where the Credit Balance for a given Currency is negative, which
            happens a result of a payment being made to Loop before the Payment Due Date or a payment being made in
            excess of the Total Balance.
          </p>
          <p className="tw-my-4">
            “<strong>Purchase</strong>” means a Transaction that is not a Cash Advance.
          </p>
          <p>
            “<strong>Rewards Program</strong>” means the applicable rewards program offered by Loop.
          </p>
          <p className="tw-my-4">
            “<strong>Rewards Program Terms and Conditions</strong>” means the terms and conditions applicable to the
            Rewards Program and available at (bankonloop.com/legal/rewards).
          </p>
          <p>
            “<strong>Third Party Conversion Rate</strong>” means the applicable rate we pay to a third party foreign
            exchange provider to convert a foreign currency to a Currency
          </p>
          <p className="tw-my-4">
            “<strong>Total Balance</strong>” means the total amount of all Credit Balances.
          </p>
          <p>
            “<strong>Total Minimum Payment</strong>” means the amount shown on each monthly statement that is required
            to be paid by the Payment Due Date.
          </p>
          <p className="tw-my-4">
            “<strong>Transaction</strong>” means any use of a Card, Card number or Account by way of the Card-Based
            Credit Facility to purchase goods or services,Cash-Like Transaction or incur other charges to the Account
            and includes unpaid interest and Fees.
          </p>
          <p>
            “<strong>USD</strong>”, “<strong>US$</strong>” means the lawful currency of the United States of America.
          </p>
          <p className="tw-my-4">
            “<strong>we</strong>”, “<strong>our</strong>” or “<strong>us</strong>” means Loop Financial Inc.
          </p>
          <p>
            “<strong>Website</strong>” means any or all of Loop’s website which is accessible at{' '}
            <a href={config.landingPageUrl} target="__blank" rel="noreferrer">
              {config.landingPageUrl}
            </a>
            .
          </p>
          <p className="tw-my-4">
            “<strong>you</strong>” or “<strong>your</strong>” means the Business Cardholder.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Using Your Card-Based Credit Facility</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(a)</span>
            <p>
              <strong>Card-Based Credit Facility:</strong> The Account may be used to obtain a Card-Based Credit
              Facility and load the proceeds of the Card-Based Credit Facility on any Card, in any applicable available
              Currency, provided we permit you to do so and you comply with this Card Agreement. A Card-Based Credit
              Facility can only be used for business or corporate purposes and not for personal, family or household
              purposes.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(b)</span>
            <p>
              <strong>Repayment Obligations:</strong> Any Transaction made by any Cardholder using the Card-Based Credit
              Facility is an extension of credit to the Business Cardholder in the amount of the Transaction, even if a
              Cardholder authorizes the Account to be charged without presenting the Card to a merchant or retailer or
              without signing a sales draft, including authorization by telephone, mail, the internet or any other
              electronic means, including contactless Transactions and Mobile Payments Services (the legal effect is the
              same as if the Card had been presented and the sales slip was signed or a PIN or password entered). The
              Business Cardholder is liable for and promises to repay the Total Balance and any other amount owing under
              this Card Agreement, including any amounts charged to the Account by all Individual Cardholders, even if
              you have used or allowed the Card or Account to be used in a manner that is not in compliance with the
              Card Agreement, or which exceeds the Credit Limit. The Business Cardholder is responsible for ensuring
              that all Individual Cardholders comply with the terms and conditions of this Card Agreement.
            </p>
          </p>
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(c)</span>
            <p>
              <strong>Unauthorized Use:</strong> You agree that each Card and related Account number will be used only
              by or on behalf of the Cardholder whose name appears on the Card. In the event that a Cardholder lends or
              allows a person to use their Card or Account number, the Business Cardholder will be responsible for any
              amounts charged to the Card or the Account by that person, even if that person was a minor or regardless
              of whether or not the Cardholder intended to limit the authorization granted to that person’s use of the
              Card or Account to a particular use, amount or time.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(d)</span>
            <p>
              <strong>Adding or Removing Additional Cardholders:</strong> The Business Cardholder may, without advance
              notice to any Individual Cardholder, add or remove any Individual Cardholder from the Account from time to
              time by contacting us or by using the Portal. An Individual Cardholder’s Card number (or the number on the
              renewed or replaced Card) may be different from the Individual Cardholder’s original Card number, but are
              all part of the same Account. We may limit the number of Individual Cardholders on an Account.
            </p>
          </p>
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(e)</span>
            <p>
              <strong>Recurring Transactions:</strong> If you have arranged for any PAPs to be charged to a Card, you
              are responsible for providing the applicable merchant or retailer with adequate, correct and up-to-date
              information, including advising such merchant or retailer if Card number or expiry date changes. We are
              not liable if any PAPs cannot be posted to your Account. You must settle any dispute you may have in
              connection with a PAP directly with the merchant or retailer who charged the Account. If you want to
              cancel a PAP, you must contact the merchant or retailer in writing to advise them that you will be
              terminating the PAP and must check your statement after the merchant or retailer has received your notice
              to ensure that the PAP has been cancelled.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(f)</span>
            <p>
              <strong>Split Tender Transactions:</strong> If the amount available from your Card-Based Credit Facility
              is insufficient to cover the full amount of a Transaction, you may request the merchant or retailer to
              conduct a split tender transaction, which is where you use the Card as partial payment of the Transaction
              amount and then pay the balance with another form of payment (e.g. cash, cheque, credit or debit). If you
              fail to inform the merchant or retailer that you would like to complete a split tender transaction prior
              to swiping or otherwise using the Card, the Card may be declined. Some merchants may require payment for
              the balance in cash. Merchants and retailers do not have to and may not agree to accept split tender
              transactions.
            </p>
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Credit Limit</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            The Credit Limit will be determined and approved by Loop, in our sole discretion, in your chosen Currency.
            Any amounts which are loaded into the Account via pre-authorized payments in accordance with this Card
            Agreement will not increase your Credit Limit. If a Transaction is in a currency that differs from the
            Currency chosen for the Credit Limit, the Transaction amount will be converted into the Currency chosen for
            your Credit Limit and the Credit Limit will be reduced by this converted amount using the Third Party
            Conversion Rate. Credit Limit will be displayed on the Portal. We may increase or decrease the Credit Limit
            at any time without notice. Approvals of Credit Limit increases are at our sole discretion. We may set a
            separate cash advance limit for cash related activities on the Account (such as Cash Advances and Balance
            Transfers). The cash advance limit is not additional credit beyond the Credit Limit but is a specific limit
            for cash related activities and interest on these items.
          </p>
          <p className="tw-my-4">
            We may from time to time, at our sole discretion, authorize Transactions that cause your Credit Balance to
            exceed the Credit Limit. In such event, you are liable for any such excess. However, you understand that
            while we may authorize any Transactions that cause the Credit Balance to exceed the Credit Limit, we are not
            required to do so even if we have done so in the past.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Monthly Statements</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            The Business Cardholder must ensure that a monthly statement is viewed each month for the Account and review
            it. If there is an error or irregularity (including any fraudulent or unauthorized Transactions) with any
            Transaction, Fee or charge on your monthly statement, you must contact us within 30 days of the last day of
            the monthly statement period shown on the applicable monthly statement. If you fail to contact us within 30
            days of the last day of the monthly statement period you may not afterwards make any claim against us
            regarding any such errors or irregularities on the Account. We reserve the right at any time to remove from
            or add to the Account any credits or debits which have been posted or omitted in error.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Payment Information</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(a)</span>
            <p>
              <strong>Total Minimum Payments:</strong> The Business Cardholder must pay and we must receive at least the
              Total Minimum Payment by the Payment Due Date. We may decide to reduce or waive the Total Minimum Payment
              for a particular statement, but if we do so, interest will still accrue on the unpaid Credit Balance and
              such interest and any applicable Fees will be added to the Credit Balance on your next monthly statement.
              A credit to your Account, for example, as a result of a return of goods to a merchant or retailer, does
              not constitute a payment to your Account and does not satisfy the requirement to pay the Total Minimum
              Payment.
              <p className="tw-mt-4">
                <span className="tw-ml-8">If</span> you obtain a Card-Based Credit Facility advance in a Currency other
                than CAD and the Total Minimum Payment in respect of such Card-Based Credit Facility is not paid within
                5 business days of the Payment Due Date, we will convert the full Credit Balance for such monthly
                statement to CAD using the Minimum Payment Exchange Rate.
              </p>
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(b)</span>
            <p>
              <strong>Making Payments:</strong> The Business Cardholder is responsible for ensuring that payments are
              received by us by the Payment Due Date. You can make a payment at any time and payments can be made by
              electronic funds transfer by pre-authorized debit agreement or from any Positive Balance in your Account.
              Payments can take several days to reach us. Please choose a payment method that results in your payment
              being received and processed by the Payment Due Date. A payment to the Account will only be credited to
              the Account and affect the Available Credit once it is received, processed and cleared. We may amend the
              methods permitted to make a payment at any time without notice, please check the Website for details of
              available loading methods.
              <p className="tw-my-4">
                If your Payment Due Date falls on a Saturday, Sunday or a statutory holiday the Payment Due Date will be
                automatically extended to the next business day that is not a Saturday, Sunday or statutory holiday. We
                will consider a payment made on such next business day as having been made on time. To determine whether
                Payment Due Date falls on a statutory holiday, we will rely upon the Business Cardholder’s address we
                have on file at the time of your monthly statement.
              </p>
              <p>
                You are responsible for determining the amount of Available Credit in each Currency and for ensuring you
                have sufficient funds for all Transactions. If you have a Positive Balance, you may allocate amounts
                between the various Currencies using the Portal and/or by such additional methods as we may make
                available to you from time to time. If there are insufficient funds in a particular Currency to pay for
                a Transaction and you have no Available Credit, the balance of the Transaction will be automatically
                processed using another Currency or Currencies held on the Card in the following order of priority: CAD,
                USD, EUR, GBP. If, following use of the available balances of all Currencies, there are still
                insufficient funds to pay for a Transaction, the Card may be declined or the merchant or retailer may
                allow you to pay the balance by some other means. If we change a Currency available to you in connection
                with the Card, then we will notify you of the new order of priority for the purposes of this Card
                Agreement directly and/or via the Website.
              </p>
              <p className="tw-my-4">
                Where any Fee is charged under the Card and there are insufficient funds in CAD and you have Available
                Credit, the amount will be funded by converting the balance of the Fee into the next available Currency
                balance on the Card in the following order of priority: USD, EUR, GBP using the Third Party Conversion
                Rate in effect on the day of the charge of the Fee plus the applicable Foreign Exchange Fee. If,
                following use of the available balances of all Currencies, there are still insufficient funds to pay for
                the Fee, you agree to reimburse us, upon request, for the amount of the Fee in excess of the Credit
                Balance.
              </p>
              <p>
                Foreign exchange rates are subject to variation and the rate that applies one day will not necessarily
                be the same on any other day.
              </p>
              <p>
                If you obtain a Card-Based Credit Facility advance in a Currency other than CAD, where you i) elect to
                make the Minimum Payment in CAD in respect of such Card-Based Credit Facility, we will convert all of
                the balances applicable to such statement to CAD using the Minimum Payment Exchange Rate, or ii) elect
                to pay your Credit Balance in full for a monthly statement in CAD in respect of such Card-Based Credit
                Facility, we will convert the Credit Balance into CAD using the Third Party Conversion Rate plus the
                Foreign Exchange Fee.
              </p>
            </p>
          </p>
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(c)</span>
            <p>
              <strong>Set-Off:</strong> We are entitled to set off any sum of money due from you to us against any
              amount due from us to you, including without limitation setting off any amount on a Currency due from you
              to us against any Positive Balance on any other Currency at the Third Party Conversion Rate.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(d)</span>
            <p>
              <strong>How We Apply Your Payments:</strong> All payments received regarding a Total Minimum Payment will
              be applied in the following order: (i) first, to any interest charges that appear on your monthly
              statement; (ii) second, to any Fees that are payable in respect of your Account; (iii) third, to any
              Transactions that appear on your monthly statement, including any amount that exceeds your Credit Limit or
              any past due amounts; and (iv) fourth, to create a Positive Balance in your Account.
              <p className="tw-my-4">
                We will apply any amount of your payment that is greater than the Total Minimum Payment to each interest
                rate category (i.e. all items that have the same annual rate will be placed into the same category) in
                the proportion that the amount in each category represents of the remaining Credit Balance.
              </p>
              <p>
                Payments received by us that exceed the amount of the Credit Balance on your statement will be applied
                to Transactions that have not yet appeared on your monthly statement but that are posted to your
                Account, using the same payment allocation described above, and are applied in the order in which the
                Transactions are posted to your Account.
              </p>
              <p className="tw-my-4">
                We may accept late payments, partial payments and payments marked “<strong>paid in full</strong>” or
                with similar wording without losing any rights we have by law or under this Card Agreement or any
                Agreement.
              </p>
            </p>
          </p>
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(e)</span>
            <p>
              <strong>Positive Balances:</strong> We do not pay interest on any Positive Balances. You acknowledge that
              Positive Balances are not deposits and are therefore not insured by the Canada Deposit Insurance
              Corporation (CDIC), the Financial Services Regulatory Authority (FSRA) or any other provincial deposit
              insurer.
            </p>
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Interest and Grace Periods</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(a)</span>
            <p>
              <strong>Interest Rates:</strong> Interest is charged at the applicable annual rates. The applicable annual
              interest rates are set out in the Portal and are subject to change from time to time, with notice to you
              in accordance with this Card Agreement and Applicable Law. The current applicable annual rates will also
              be set out on your monthly statement.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(b)</span>
            <p>
              <strong>Interest on Purchases:</strong> Interest accrues on each Purchase from the date of the Transaction
              giving rise to the particular Purchase which may be earlier than the date that the Purchase is posted to
              your Account. You can avoid interest being charged on a particular Purchase by ensuring that we always
              receive payment in full of your Credit Balance every month by the Payment Due Date. If we do not receive
              payment in full of the Credit Balance on your current monthly statement by the Payment Due Date, you will
              have to pay interest on any Purchase retroactively from the date of the Transaction that appears on your
              monthly statement until the date we receive payment that covers the full amount of such Purchase. If we do
              not receive payment in full of the Credit Balance on your current monthly statement before the date your
              next monthly statement is generated, you will have to pay interest on any new Purchases made until we
              receive full payment of the Credit Balance.
            </p>
          </p>
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(c)</span>
            <p>
              <strong>Interest on Cash Advances:</strong> There is no interest-free grace period for Cash Advances.
              Interest accrues on each Cash Advance from the date of the Cash Advance until the amount of the Cash
              Advance is paid in full.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(d)</span>
            <p>
              <strong>How We Calculate Interest:</strong> Interest on Transactions and any other charges that make up
              your Credit Balance is calculated using the “<strong>average daily balance method</strong>”. At the end of
              each billing period as stated on each statement, we calculate interest for each category of
              interest-bearing Transactions and any other charges that make up your Credit Balance and that is subject
              to interest at a different annual rate. For each category, we determine:
              <p className="tw-flex tw-my-4">
                <span className="tw-mr-4">(a)</span>
                <p>
                  the “<strong>daily interest rate</strong>” for that category for the billing period; and
                </p>
              </p>
              <p className="tw-flex tw-mb-4">
                <span className="tw-mr-4">(b)</span>
                <p>
                  the “<strong>average daily balance</strong>” of all Transactions and other charges in that category
                  for the billing period. We then multiply the daily interest rate for a particular category by the
                  average daily balance for that category, and then by the number of days in the period, and we add this
                  amount to your Credit Balance for each category.
                </p>
              </p>
              We determine the “<strong>average daily balance</strong>” for a particular category by adding together the
              Credit Balance for that category for each day during the billing period (treating any net credit balance
              as a zero balance) and dividing that sum by the number of days in the billing period. We determine the “
              <strong>daily interest rate</strong>” by dividing the applicable annual interest rate for that category by
              365 (in a regular year) or 366 (in a leap year).
            </p>
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Fees</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            The table below sets out the fees (each a <strong>“Fee”</strong>) that will be imposed with regard to the
            Card. You acknowledge being advised of the Fees and agree to pay all Fees charged under the Card Agreement
            or shown on the pricing page of the Website at bankonloop.com/pricing. You may incur additional Fees in
            connection with the Account provided for under Agreements.
          </p>
          <table className="tw-my-4 tw-w-full tw-border">
            <tr className="tw-border-b">
              <th className="tw-w-1/2 tw-border-r tw-p-2">Type of Fee</th>
              <th className="tw-p-1">Amount</th>
            </tr>
            <tr className="tw-border-b">
              <td className="tw-border-r tw-p-2">
                Standby/Inactivity Fee (starting month 13 after activation, billed monthly if your Card has not been
                used for 90 days)
              </td>
              <td className="tw-p-2">$5</td>
            </tr>
            <tr className="tw-border-b">
              <td className="tw-border-r tw-p-2">ATM Fee</td>
              <td className="tw-p-2">$10 plus any amount charged by the ATM</td>
            </tr>
            <tr className="tw-border-b">
              <td className="tw-border-r tw-p-2">Cash Advance Fee (including cash-like transactions)</td>
              <td className="tw-p-2">$10</td>
            </tr>
            <tr className="tw-border-b">
              <td className="tw-border-r tw-p-2">Non-Sufficient Funds (NSF) Fee</td>
              <td className="tw-p-2">$25</td>
            </tr>
            <tr className="tw-border-b">
              <td className="tw-border-r tw-p-2">Late Payment Fee</td>
              <td className="tw-p-2">Greater of $25 or 2.99% of any past due amounts</td>
            </tr>
            <tr className="tw-border-b">
              <td className="tw-border-r tw-p-2">Overlimit Fee</td>
              <td className="tw-p-2">
                $29.00. Charged on the day your Credit Balance first exceeds your Credit Limit and then once per
                statement period (charged on the first day of the statement period) if your Card Account remains
                overlimit from a previous statement period.
              </td>
            </tr>
          </table>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Security Interest</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p className="tw-my-4">
            To secure the performance of the Obligations, you hereby grant a security interest to Loop in all of the
            property and undertaking of the Business Cardholder now owned or hereafter acquired and all of the property
            and undertaking in which the Business Cardholder now has or hereafter acquires any interest, and, for
            greater certainty, the security interest granted by the Business Cardholder to Loop in inventory includes,
            but is not limited to, purchase money security interests in inventory of the Business Cardholder purchased
            with a Card-Based Credit Facility advance from time to time (the “<strong>Security</strong>”).
          </p>
          <p>
            Upon the occurrence of an Event of Default, the Guarantor grants Loop a security interest in all the
            Guarantor’s property and undertaking which the Guarantor now has or hereafter acquires an interest (the{' '}
            <strong>“Guarantor Security”</strong>).
          </p>
          <p>
            You hereby authorize us to file and register appropriate documents (the “<strong>Security Documents</strong>
            ”) without notice to you, with all appropriate jurisdictions and take all other steps necessary to perfect
            or protect our interests in the Security and the Guarantor Security or rights hereunder, including a notice
            that any disposition of any of the Security or Guarantor Security, by the Business Cardholder or any other
            person, shall be deemed to violate our rights under the Security Documents. Such Security Documents may
            apply to all types of collateral or apply to “all assets of the Debtor” or words of similar effect, or as
            being of an equal or lesser scope, or with greater detail, all in our sole and absolute discretion.
          </p>
        </div>
        <strong>
          <li type="1">
            <div className="tw-ml-4">
              <span>Special Payment Plan, Promotional Offers, Optional Services and Loyalty Programs</span>
            </div>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(a)</span>
            <p>
              <strong>Special Payment Plan or Promotional Offers:</strong> From time to time, you may be eligible for
              certain special payment plan or promotional offers, including, without limitation, (i) introductory
              interest rates, (ii) seasonal promotions, (iii) merchant or spend promotions, and (iv) same as cash
              programs. By participating in a special payment plan or promotional offer, you will be deemed to have
              accepted to be bound by and will be subject to the terms and conditions set out in the special payment
              plan or promotional offer. Except to the extent specifically modified by the terms and conditions of the
              special payment plan or promotional offer, the terms and conditions contained in this Card Agreement
              continue to apply to any special payment plan or promotional offer. At the end of the special payment plan
              or promotional offer or if an Event of Default has occurred, all terms and conditions of the special
              payment plan or promotional offer will cease and all terms and conditions contained within this Card
              Agreement will apply to any Transactions and any charges incurred under the special payment plan or
              promotional offer.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(b)</span>
            <p>
              <strong>Optional Services:</strong> From time to time we may offer you optional services with the Card at
              additional cost to you. Optional services will be subject to separate agreements and may be provided by
              third parties. Enrolling in optional services does not affect the terms of this Card Agreement. You
              acknowledge that optional services offered by any third party may be cancelled, modified or withdrawn by
              such third party in accordance with their terms. We are not liable for any optional services provided to
              you by a third party. Any dispute that you have with the third party provider of the optional services
              does not affect the Obligations, including but not limited to paying us the Total Balance and any other
              amounts which have been charged to the Account, including interest and any Fees, in accordance with this
              Card Agreement.
            </p>
          </p>
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(c)</span>
            <p>
              <strong>Special Card Features:</strong> From time to time, we may make special services or benefits
              available to you including insurance coverage, and memberships. Some of these services and benefits are
              features of the Card and are described in the Portal. Others must be enrolled in or purchased separately
              by you. Card services and benefits are subject to additional terms and conditions which may change from
              time to time and may be cancelled in accordance with their terms. Certain services and benefits may be
              supplied by third parties; we are not liable for any services or benefits not directly supplied by us. You
              must deal directly with the relevant third party regarding any dispute.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(d)</span>
            <p>
              <strong>Loyalty Programs:</strong> From time to time, we or a third party may offer a loyalty program with
              the Card that may be without any cost to you. A description of the terms and conditions relating to any
              existing loyalty program is included in the Portal. Loyalty programs are subject to additional terms and
              conditions which may change from time to time and may be cancelled in accordance with their terms. We are
              not liable for any loyalty programs not provided by us. Any dispute that you have with the loyalty program
              does not affect the Obligations, including but not limited to the obligation to pay us the Total Balance
              and any other amounts which has been charged to the Account, including interest and any Fees, in
              accordance with this Card Agreement. You must deal directly with the third party provider to settle any
              such disputes. By using the Card, you accept the terms and conditions of any loyalty program associated
              with the Card.
            </p>
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Lost or Stolen Card</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            You must take all reasonable steps to protect each Card against loss, theft, or unauthorized use. If a Card
            has been lost or stolen, or if you have reason to believe that someone has made an unauthorized Transaction
            with a Card, or may attempt to use a Card without your permission, you must notify us through the Portal.
            All Transactions carried out on a Card before you notify us will be considered to have been made by you.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Lost, Stolen or Unauthorized Use of Your Account</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            You must take reasonable care to safeguard each Card, PIN, password and cheques against loss, theft or
            misuse. You must immediately report the card lost or stolen and lock the Card via the portal and notify us
            by telephone within 24 hours when you learn of the loss, theft or misuse of the Card, or mobile device if
            using mobile payment services, or if you suspect that someone else knows a PIN or password, or when you
            otherwise become aware that ard is being misused. To report unauthorized use of a Card or Account, please
            notify us through the Portal. All Transactions carried out on a Card before you notify us will be considered
            to have been made by you.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Our Rights on Default</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            The Account will be considered to be in default under this Card Agreement if: (i) you do not make the Total
            Minimum Payment by the Payment Due Date; (ii) you fail to comply with any terms or obligations contained in
            this Card Agreement; (iii) the Business Cardholder provides any misleading, incorrect, false or incomplete
            information in their credit application; or (iv) the Business Cardholder becomes insolvent or bankrupt or
            insolvency proceedings are brought by or against the Business Cardholder or the Business Cardholder makes a
            proposal to its creditors (each an “<strong>Event of Default</strong>”).
          </p>
          <p className="tw-my-4">
            To the extent permitted by Applicable Law, if an Event of Default occurs we may at our sole discretion: (i)
            declare that the Total Balance will become due and payable on demand from us together with interest on such
            Total Balance at the annual interest rate(s) payable on the Account at that time; (ii) terminate or restrict
            your rights under, and amend any terms of, this Card Agreement or your Account, including suspending your
            ability to make Transactions; (iii) terminate any other special payment or promotional plan offers and
            convert any balance on such special payment or promotional plan based on the terms and conditions contained
            in this Card Agreement; (iv) require that you return all Cards to us; (v) exercise our rights with regard to
            the Guarantor as provided for in Section 8 above’ and/or (vi) exercise all other rights and remedies that
            are available to us in law.
          </p>
          <p>
            You are responsible for and must pay all costs incurred by us or any of our agents in collecting or
            attempting to collect the Total Balance or any other amount under this Card Agreement which is owed to us,
            including legal fees charged by external counsel and the reasonable costs associated with internal legal
            counsel’s time spent in such efforts.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Closing Your Account</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            The Business Cardholder may close the Account by contacting us using the Portal. We may terminate this Card
            Agreement, or close or suspend access to your Account or any Cards, or reduce the Credit Limit, immediately
            at any time prior notice at our sole unfettered discretion.
          </p>
          <p className="tw-mt-4">
            If this Card Agreement is terminated or the Account is closed or suspended, the Business Cardholder and
            Guarantor will remain responsible for all amounts owing on the Account, including any pre-authorized
            payments, Fees (including any Fees and other Transactions that do not yet appear on the Business
            Cardholder’s monthly statement but are posted to their Account) and additional interest that may be posted
            to the Account. You are required to cancel any pre-authorized payments you have arranged with any merchants
            or retailers. This Card Agreement will continue to be in effect until, and termination of this Card
            Agreement will only take effect when we have received the Total Balance and any other amounts that you owe
            to us.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Problems with a Merchant or Retailer</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            Any complaints or problems regarding any products or services purchased using the Account or a Card, must be
            settled directly with the merchant or retailer unless otherwise required by applicable law. To avoid late
            interest charges and any penalties, please continue to make payments to the Account while you are resolving
            the problem with the merchant or retailer. Any dispute that you have with a merchant or retailer does not
            absolve the Business Cardholder of its obligation to pay us the full amount that has been charged to the
            Account. In such circumstances, we may not refund any interest charged on Transactions that are credited to
            your Account by a merchant or retailer in such circumstances.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">How We Communicate with You</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            Account Documentation will be sent electronically. If we send Account Documentation to the Business
            Cardholder and it is returned because of an invalid e-mail address, we will not issue further Account
            Documentation or notices until we receive the Business Cardholder’s correct email address and we may
            restrict the use of the Account.
          </p>
          <p className="tw-mt-4">
            We are not responsible for the failure of a Business Cardholder to receive Account Documentation, if we send
            it to the address appearing in our records, or posted on the Portal or in another electronic form. Each
            Cardholder must immediately notify us of any changes in their e-mail address.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Contact and Notice Information</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            If you have questions regarding the Account or a Card, or need to report a lost or stolen Card, you may
            contact us using the Portal. For our mutual protection, we may record all telephone calls that relate to the
            Account or a Card.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Arbitration</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            Subject to all other terms of this Card Agreement, you and the Guarantor agree that any claim of any kind
            against us arising from or related to this Card Agreement or the use of the Card (i) shall be resolved by
            final and binding arbitration before a single arbitrator in Toronto, Canada and (ii) shall not be brought
            through class or individual litigation proceedings. If such a claim is advanced by class proceeding by any
            other person on your behalf, you and the Guarantor will opt out of, or not opt into, such proceedings as
            circumstances dictate.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Complaints</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            If you have a complaint or inquiry about any aspect of a Card, email us at {config.complaintsEmailAddress}.
            We will do our best to resolve your complaint or inquiry.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Privacy</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            You and the Guarantor consent to the collection, use and sharing of your personal information from time to
            time as provided in Loop’s{' '}
            <strong>
              <a
                href={config.privacyPolicyUrl}
                target="_blank"
                rel="noreferrer"
                className="tw-text-primary-dark-green tw-border-b hover:tw-text-primary-dark-green"
              >
                privacy policy
              </a>
            </strong>
            . This privacy policy may be amended, replaced or supplemented from time to time. To help protect you and us
            from fraud, we may from time to time provide merchants with verification of your address for internet,
            telephone and other remote Transactions.
          </p>
          <p className="tw-mt-4">
            You consent to the release to us by the Canada Revenue Agency, pursuant to Section 241(5)(b) of the Income
            Tax Act, of any and all information contained in any and all records kept by the Canada Revenue Agency
            pertaining to the Business Cardholder.
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Additional Provisions</span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(a)</span>
            <p>
              <strong>Headings:</strong> Headings of Articles and Sections are inserted for convenience of reference
              only and do not affect the construction or interpretation of this Card Agreement.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(b)</span>
            <p>
              <strong>Changes to Card Agreement:</strong> Unless advance notice is required by the applicable Law, we
              may make changes to each and every provision of this Card Agreement, by giving you subsequent notice of
              each change. The notice provided to you may be enclosed with your monthly statement or sent separately. If
              you sign, use or activate any Card or the Account or if any Total Balance owing on the Account remains
              unpaid after the change is made, it will mean you have accepted the change.
            </p>
          </p>
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(c)</span>
            <p>
              <strong>Criminal Interest Rate Provision:</strong> If any provision of this Card Agreement would oblige
              you to make a payment of interest or other amount payable to us in an amount or calculated at a rate which
              would be prohibited by law or would result in receipt by us of “interest” at a “criminal rate” (as such
              terms are construed under the Criminal Code (Canada)), then, notwithstanding such provision, such amount
              or rate shall be deemed to have been adjusted with retroactive effect to the maximum amount or rate of
              interest, as the case may be, as would not result in receipt by us of “interest” at a “criminal rate”,
              such adjustment to be effected, to the extent necessary (but only to the extent necessary), by reducing
              (i) any Fees, commissions, premiums, and other amounts required to be paid to or on behalf of us which
              would constitute interest for purposes of the Criminal Code (Canada), or (ii) the amount or rate of
              interest required to be paid to us, as elected by us, and any amount previously paid by you which is
              included in such reduction shall be returned to you.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(d)</span>
            <p>
              <strong>Interest Act Provision:</strong> For the purposes of this Card Agreement, whenever interest to be
              paid hereunder is to be calculated on the basis of 360 days or any other period of time that is less than
              a calendar year, the yearly rate of interest to which the rate determined pursuant to such calculation is
              equivalent is the rate so determined multiplied by the actual number of days in the calendar year in which
              the same is to be ascertained and divided by 360 or such other number of days in such period, as the case
              may be.
            </p>
          </p>
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(e)</span>
            <p>
              <strong>Entire Agreement:</strong> This Card Agreement sets forth the entire understanding and agreement
              between you, the Guarantorand us, whether written or oral, with respect to the subject matter hereof and
              supersedes any prior or contemporaneous understandings or agreements with respect to such subject matter.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(f)</span>
            <p>
              <strong>Governing Law:</strong> This Card Agreement will be governed by and interpreted in accordance with
              the Applicable Laws of the province or territory in which the Business Cardholder is located as provided
              in your application (or the Province of Ontario if you reside outside Canada). You agree to submit to and
              be bound by these laws and the courts of that province or territory in the event of any disputes arising
              in connection with your Account and this Card Agreement.
            </p>
          </p>
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(g)</span>
            <p>
              <strong>Limitations on Our Liability:</strong> We are not responsible or liable for any damages, including
              special, indirect and consequential damages, even if they were foreseeable, that may result from the use
              of the Card or Account, or obligation under this Card Agreement if, for any reason, the Card or the
              Account number is not accepted or you are unable to access the Account, including the inability to access
              the Account by reason of a business interruption, security breach, delay, loss, error, system outage, or
              failure to access any ATM, terminal or other machine or equipment with a Card.
              <p className="tw-mt-4">
                We may use third party service providers or any affiliate of ours to provided services to process your
                application, any information, and Transactions relating to the Account. A third party service provider
                or affiliate may not process or complete Transactions associated with the Account if processing or
                completing the Transaction would cause it to violate any law, regulation, rule or internal policy
                applicable to it, or cause it to suffer legal and/or reputational risks. If such event occurs, neither
                we nor our third party service providers or any affiliate will be liable in respect of any such
                unprocessed or incomplete transaction. Third party service providers or affiliates may not be located in
                Canada.
              </p>
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(h)</span>
            <p>
              <strong>Assignment:</strong> At our sole discretion, we may assign our rights and responsibilities under
              this Card Agreement at any time and without notice to you. If we do make such an assignment, then this
              Card Agreement will remain binding on you and your respective executors, administrators, successors,
              representatives and permitted assigns.
            </p>
          </p>
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(i)</span>
            <p>
              <strong>Severability:</strong> If, in any jurisdiction, any provision of this Card Agreement or its
              application to us or any Cardholder or the Guarantor, or circumstance is restricted, prohibited or
              unenforceable, the provision shall, as to that jurisdiction, be ineffective only to the extent of the
              restriction, prohibition or unenforceability without invalidating the remaining provisions of this Card
              Agreement and without affecting the validity or enforceability of such provision in any other
              jurisdiction.
            </p>
          </p>
          <p className="tw-flex">
            <span className="tw-mr-4">(j)</span>
            <p>
              <strong>Non-Waiver:</strong> We may, in our sole discretion, choose not to exercise any right under this
              Card Agreement, including the right to impose the full amount of any charge, without waiving that right.
              Any waiver of a right by us must be in writing and signed by us. You understand and agree that your
              obligation to pay all amounts owing under this Card Agreement and otherwise to perform the terms and
              conditions of this Card Agreement are absolute and unconditional.
            </p>
          </p>
          <p className="tw-flex tw-my-4">
            <span className="tw-mr-4">(k)</span>
            <p>
              <strong>Language:</strong> You confirm that you have expressly requested that this Card Agreement and all
              related documents be drafted in English. Vous confirmez avoir expressément demandé que la présente
              convention et tous les documents s’y rapportant soient rédigés en anglais.
            </p>
          </p>
        </div>
        <strong>
          <li type="1">
            <span className="tw-ml-4">Personal Guarantee </span>
          </li>
        </strong>
        <div className="tw-ml-4 tw-my-4">
          <p>
            The Guarantor hereby unconditionally guarantees payment and performance of the Obligations as defined in
            this Card Agreement, forthwith on demand by Loop upon the occurrence of an Event of Default. This guarantee
            shall be a continuing guarantee and shall guarantee the Obligations and any balance thereof, notwithstanding
            that the Business Cardholder may from time to time satisfy the Obligations in whole or in part and
            thereafter incur further Obligations.
          </p>
        </div>
      </ol>
      <div className="tw-text-center tw-mb-4">
        <strong>
          I agree to the above terms and conditions for and on behalf of the Business Cardholder and agree to the
          guarantee set out above, in my personal capacity as Guarantor.
        </strong>
      </div>
    </>
  );
};

export default CreditAgreementV2;
