import { useState, useCallback, useContext, useEffect } from 'react';
import { useLazyQuery, useMutation, ApolloError, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';

import { InternalContact, InternalContactRole } from 'types/user';
import { CreditCardContact } from 'types/creditCard';
import { TeamContext } from 'components/settings/Team/TeamContext';
import {
  GET_CREDIT_CARD_MEMBERS,
  REMOVE_CONTACT_FROM_PRODUCT_PERMISSION,
  UPDATE_CREDIT_CARD_MEMBERS,
} from 'graphql/cards';
import { useToggle } from 'hooks';
import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { getAllCreditCardMembers } from 'components/creditCards/components/cardsSettings/Members/Members.utils';

const useCardMembers = () => {
  const { creditCardDetails } = useContext(CardDetailsContext);
  const { id: creditCardId } = creditCardDetails || {};

  const {
    members: internalContacts,
    loadingMembers: isLoadingContacts,
    getMembers: getInternalContacts,
    membersDataError,
  } = useContext(TeamContext) as unknown as {
    members: InternalContact[];
    loadingMembers: boolean;
    getMembers: () => Promise<void>;
    membersDataError?: ApolloError;
  };

  const [cardMembers, setCardMembers] = useState<CreditCardContact[]>([]);
  const [error, setError] = useState<ApolloError | undefined>();

  const { isOpen: isOpenModal, open: openModal, close: closeModal } = useToggle();

  const client = useApolloClient();

  const [
    getCreditCardMembers,
    { data: creditCardMembersData, loading: isCreditCardMembersLoading, error: creditCardMembersDataError },
  ] = useLazyQuery<{
    creditCard: {
      id: string;
      contacts: CreditCardContact[];
    };
  }>(GET_CREDIT_CARD_MEMBERS);

  const [removeCardMember] = useMutation(REMOVE_CONTACT_FROM_PRODUCT_PERMISSION);

  const [updateCreditCardMembers, { loading: isUpdatingMembers }] = useMutation<{
    updateContactsInProductPermission: {
      id: string;
      contacts: CreditCardContact[];
    };
  }>(UPDATE_CREDIT_CARD_MEMBERS, {
    update: (cache, { data }) => {
      const updatedData = data?.updateContactsInProductPermission;

      if (!updatedData) return;

      cache.updateQuery({ query: GET_CREDIT_CARD_MEMBERS, variables: { creditCardId } }, (existingData) => ({
        creditCard: {
          ...existingData.creditCard,
          contacts: updatedData.contacts,
        },
      }));
    },
  });

  const handleRemoveMember = useCallback(
    async (contactId: string) => {
      if (!creditCardId || !contactId) return;

      const processingToast = toast.loading('Removing member...');

      try {
        // Optimistic update
        const cache = client.cache;
        cache.modify({
          id: cache.identify(creditCardDetails!),
          fields: {
            contacts(existingContactRefs, { readField }) {
              return existingContactRefs.filter(
                (commentRef: InternalContact) => contactId !== readField('id', commentRef)
              );
            },
          },
        });

        const response = await removeCardMember({ variables: { contactId, creditCardId } });

        if (!response?.data?.removeContactFromProductPermission) throw new Error('Failed to remove member');

        toast.update(processingToast, {
          render: 'Member was removed successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      } catch (error) {
        toast.update(processingToast, {
          render: 'Failed to remove member',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });

        // Revert optimistic update by refetching the query
        client.refetchQueries({ include: [GET_CREDIT_CARD_MEMBERS] });

        console.error(error);
      }
    },
    [creditCardDetails, creditCardId, removeCardMember, client]
  );

  const handleUpdateMembers = useCallback(
    async ({ contacts }: { contacts: string[] }) => {
      if (!creditCardId) return;

      const processingToast = toast.loading('Updating card members...');

      try {
        const memberContactIds = contacts.filter(
          (contactId) => internalContacts.find(({ id }) => id === contactId)?.role === InternalContactRole.member
        );

        const response = await updateCreditCardMembers({ variables: { creditCardId, contactIds: memberContactIds } });

        if (!response?.data?.updateContactsInProductPermission) throw new Error('Failed to update card members');

        toast.update(processingToast, {
          render: 'Credit card members were updated successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        closeModal();
      } catch (error) {
        toast.update(processingToast, {
          render: 'Failed to update credit card members',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });

        console.error(error);
      }
    },
    [creditCardId, internalContacts, updateCreditCardMembers, closeModal]
  );

  useEffect(() => {
    const { contacts: existingCardMembers } = creditCardMembersData?.creditCard || {};

    if (!existingCardMembers || !internalContacts) return;

    const allMembers = getAllCreditCardMembers({ members: existingCardMembers, internalContacts });
    setCardMembers(allMembers);
  }, [creditCardMembersData, internalContacts]);

  useEffect(() => {
    if (creditCardMembersDataError || membersDataError) {
      toast.error('Failed to load card members');
      setError(creditCardMembersDataError || membersDataError);
    }
  }, [creditCardMembersDataError, membersDataError]);

  useEffect(() => {
    if (!creditCardId) return;

    getCreditCardMembers({ variables: { creditCardId } });
    getInternalContacts();
  }, [creditCardId, getCreditCardMembers, getInternalContacts]);

  return {
    isLoading: isCreditCardMembersLoading || isLoadingContacts,
    isError: !!error,
    isOpenModal,
    openModal,
    closeModal,
    handleUpdateMembers,
    isUpdatingMembers,
    members: cardMembers,
    internalContacts,
    handleRemoveMember,
  };
};

export default useCardMembers;
