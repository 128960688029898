import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import {
  GENERATE_SMS_AUTHORIZATION,
  REPLY_SMS_AUTHORIZATION_CODE,
  GENERATE_EMAIL_AUTHORIZATION,
  REPLY_EMAIL_AUTHORIZATION_CODE,
} from 'graphql/mfa';
import { MFAContext } from 'context/MFA';
import { AuthContext } from 'context/Auth';
import { SubmitButton } from 'components/FormFields/v2';
import { Loaders } from 'components/cards/Loader';
import { Forms } from 'components/UI';
import Button from 'components/Button';

const { MFACodeInput } = Forms;

export const MFARequired = () => {
  const history = useHistory();
  const onNavigateToSettings = () => history.push('/dashboard/settings/personal');

  return (
    <>
      <div className="tw-p-8">
        <p>Please enable Two-Step Verification first for your account in order to send a payment</p>
      </div>
      <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex tw-justify-end">
        <Button primary onClick={onNavigateToSettings} className="tw-px-8 tw-w-max">
          To 2FA Settings
        </Button>
      </div>
    </>
  );
};

export const VerifySMSCode = () => {
  const [generateVerificationCode, { loading: generatingCode }] = useMutation(GENERATE_SMS_AUTHORIZATION);
  const [replySMSAuthorizationCode, { loading: verifying, error }] = useMutation(REPLY_SMS_AUTHORIZATION_CODE);
  const { setSMSToken } = useContext(MFAContext);
  const [smsAuthorizationCodeId, setSmsAuthorizationCodeId] = useState();

  const onSubmitCode = async (data) => {
    try {
      const res = await replySMSAuthorizationCode({ variables: { ...data, smsAuthorizationCodeId } });
      const { token, expiresAt } = res && res.data && res.data.replySmsAuthorizationCode;
      setSMSToken({ token, expiresAt });
    } catch (err) {
      console.error(err);
    }
  };

  const generateCode = async () => {
    const res = await generateVerificationCode();
    const { id, token, expiresAt } = (res && res.data && res.data.generateSmsAuthorizationCode) || {};
    if (token) {
      setSMSToken({ token, expiresAt });
    } else {
      setSmsAuthorizationCodeId(id);
    }
  };

  useEffect(() => {
    generateCode();
  }, []);

  return generatingCode ? (
    <div className="tw-flex tw-items-center tw-justify-center tw-p-16">
      <Loaders.Small />
    </div>
  ) : (
    <SMSCodeForm
      loading={verifying}
      error={error}
      onSubmit={onSubmitCode}
      onResendCode={generateCode}
      verifyEmail={false}
    />
  );
};

export const VerifyEmailCode = () => {
  const [generateVerificationCode, { loading: generatingCode }] = useMutation(GENERATE_EMAIL_AUTHORIZATION);
  const [replySMSAuthorizationCode, { loading: verifying, error }] = useMutation(REPLY_EMAIL_AUTHORIZATION_CODE);
  const { setSMSToken } = useContext(MFAContext);
  const [smsAuthorizationCodeId, setSmsAuthorizationCodeId] = useState();

  const onSubmitCode = async (data) => {
    try {
      const res = await replySMSAuthorizationCode({
        variables: { ...data, emailAuthorizationCodeId: smsAuthorizationCodeId },
      });
      const { token, expiresAt } = res && res.data && res.data.replyEmailAuthorizationCode;
      setSMSToken({ token, expiresAt });
    } catch (err) {
      console.error(err);
    }
  };

  const generateCode = async () => {
    const res = await generateVerificationCode();
    const { id, token, expiresAt } = (res && res.data && res.data.generateEmailAuthorizationCode) || {};
    if (token) {
      setSMSToken({ token, expiresAt });
    } else {
      setSmsAuthorizationCodeId(id);
    }
  };

  useEffect(() => {
    generateCode();
  }, []);

  return generatingCode ? (
    <div className="tw-flex tw-items-center tw-justify-center tw-p-16">
      <Loaders.Small />
    </div>
  ) : (
    <SMSCodeForm
      loading={verifying}
      error={error}
      onSubmit={onSubmitCode}
      onResendCode={generateCode}
      verifyEmail={true}
    />
  );
};

const SMSCodeForm = ({ onSubmit, onResendCode, loading, error, verifyEmail = false }) => {
  const { me } = useContext(AuthContext);
  const { mobilePhoneNumber } = me;
  const [smsCodeResent, setSMSCodeResent] = useState(false);
  const totalDigit = 6;
  const [code, setCode] = useState('');

  const nonEmptyDigits = code.length;
  const buttonLabel =
    nonEmptyDigits > 0
      ? (nonEmptyDigits === totalDigit && 'Continue') || `Enter ${totalDigit - nonEmptyDigits} digit more`
      : `Enter ${totalDigit} digits`;

  const handleUpdateCode = (code) => {
    setCode(code);
  };

  const handleClickResend = () => {
    onResendCode();
    setSMSCodeResent(true);
  };

  const handleSubmit = () => onSubmit({ code });

  return (
    <>
      <div className="tw-px-8 tw-mb-16">
        <p className="tw-mb-8">
          {!verifyEmail &&
            `Enter ${totalDigit}-digit code we've sent to ∗∗∗ - ∗∗∗ - ${(mobilePhoneNumber || '').slice(-4)}`}
          {verifyEmail && `Enter ${totalDigit}-digit code we've sent to your registered email address.`}
        </p>

        <MFACodeInput
          code={code}
          totalLength={totalDigit}
          onUpdate={handleUpdateCode}
          onSubmit={handleSubmit}
          isLoading={loading}
          isError={error}
        />

        <p className="tw-mt-8 tw-mb-2">Didn't receive a code within 60 seconds?</p>
        {smsCodeResent ? (
          <p className="tw-text-semantic-success">Code sent!</p>
        ) : (
          <div onClick={handleClickResend} className="tw-cursor-pointer">
            <strong className="tw-text-primary-dark-green">Resend the code</strong>
          </div>
        )}
      </div>
      <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex tw-justify-end">
        <SubmitButton
          onClick={handleSubmit}
          disabled={loading || !code || nonEmptyDigits < totalDigit || (nonEmptyDigits === totalDigit && error)}
        >
          {loading ? 'Verifying...' : buttonLabel}
        </SubmitButton>
      </div>
    </>
  );
};
