import { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import { USER_ROLE_TYPES } from 'constants/index';

const useIsReadOnly = () => {
  const { me, meLoading } = useContext(AuthContext);

  const isReadOnly = !!(me && [USER_ROLE_TYPES.readOnly].includes(me?.internalContact?.role));

  return { isLoading: meLoading, isReadOnly };
};

export default useIsReadOnly;
