import React from 'react';
import { BiCheck } from 'react-icons/bi';

import { EXTERNAL_TRANSACTION_CLEARING_MESSAGE } from 'constants/index';
import { StepsProps } from '../../Steps';
import { useDetails } from '../Details/hooks';
import { FormFooter } from 'components/UI';

const Complete = ({ onFinish }: Pick<StepsProps, 'onFinish'>) => {
  // TODO: refactor useDetails hook to be reusable for both steps
  const { formattedPaymentCurrency, fromAccount, formattedPaymentType, method } = useDetails();

  return (
    <div className="tw-px-8 tw-pt-6">
      <div className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-secondary-light-green">
        <div className="tw-mr-2 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
          <BiCheck className="tw-text-secondary-light-green" />
        </div>
        <small className="tw-text-semantic-success">{EXTERNAL_TRANSACTION_CLEARING_MESSAGE}</small>
      </div>
      <div className="tw-flex tw-flex-col tw-pt-8 tw-pb-4">
        <div>
          <div className="tw-text-sm tw-text-neutral-grey-2">Currency</div>
          <p className="tw-mt-1">{formattedPaymentCurrency}</p>
        </div>
        <div className="tw-my-4">
          <div className="tw-text-sm tw-text-neutral-grey-2">From Account</div>
          <p className="tw-mt-1">{fromAccount}</p>
        </div>
        <div>
          <div className="tw-text-sm tw-text-neutral-grey-2">Payment Type</div>
          <p className="tw-mt-1">{formattedPaymentType}</p>
        </div>
        <div className="tw-mt-4">
          <div className="tw-text-sm tw-text-neutral-grey-2">Method</div>
          <p className="tw-mt-1">{method}</p>
        </div>
      </div>
      <FormFooter submitButtonLabel="Return to Autopay Settings" onSubmit={onFinish} />
    </div>
  );
};

export default Complete;
