// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React from 'react';

import { useToggle } from 'hooks';
import { balancesOrder } from 'constants/index';
import CashAndStarsSingle from 'components/svg/CashAndStarsSingle';
import InfoTooltip from 'components/InfoTooltip';
import { AddFundsToBalanceModal, PreFundedExpandableBalance } from 'components/creditCards/components';
import { AddFundsToBalanceContextProvider } from 'components/creditCards/context/AddFundsToBalance';
import { PayBalanceCurrency } from 'types/payments';
import { GroupedCardsInfo } from 'types/creditCard';

const PreFundedCardSidebar = ({ groupedCardsInfo }: { groupedCardsInfo?: GroupedCardsInfo }) => {
  const {
    isOpen: isOpenAddFundsToBalanceModal,
    open: openAddFundsToBalanceModal,
    close: closeAddFundsToBalanceModal,
  } = useToggle();

  return (
    <div className="tw-px-2 tw-space-y-8">
      <div className="tw-space-y-4">
        <div className="tw-flex tw-items-center tw-justify-start tw-mb-4">
          <small className="tw-mr-2">Balance Loaded to Loop Card</small>
          <InfoTooltip message="Pre-funded Balances include amounts you have loaded onto your Loop Card for each currency minus any posted transactions or pending transactions." />
        </div>
        <AddFundsToBalanceContextProvider>
          <div className="tw-space-y-2">
            {(balancesOrder as PayBalanceCurrency[]).map((currency) => {
              const balance = groupedCardsInfo?.groupBalances?.find((b) => b.currency === currency) || {
                amount: 0,
                currency,
              };
              const invertedBalance = { amount: -balance.amount, currency: balance.currency };

              return (
                <PreFundedExpandableBalance
                  key={currency}
                  balance={invertedBalance}
                  openAddFundsToBalanceModal={openAddFundsToBalanceModal}
                />
              );
            })}
          </div>
          <AddFundsToBalanceModal show={isOpenAddFundsToBalanceModal} onClose={closeAddFundsToBalanceModal} />
        </AddFundsToBalanceContextProvider>
      </div>
      <div className="tw-flex tw-rounded tw-bg-neutral-grey-5 tw-p-4 tw-space-x-2 tw-justify-between tw-items-start">
        <CashAndStarsSingle />
        <small className="tw-w-4/5">
          In order to make a purchase using your Loop Card, you must first load funds to your card balance in one of the
          supported currencies.
        </small>
      </div>
    </div>
  );
};

export default PreFundedCardSidebar;
