import React from 'react';
import { CSVLink as ReactCSVLink } from 'react-csv';
import ReactExport from 'react-export-excel';
import _ from 'lodash';

import { formatDateTime } from 'utility/date';
import { formatTransactionTableAmount } from 'utility/currency';
import { HEADERS } from './constants';
import { csvInjectionProtector } from 'utility/string';

export const CSVLink = ({ transactions = [], accountNames = [], productType, children }) => {
  const label = productType === 'wallet' ? 'account' : productType;
  const transactionsList = transactions
    .filter((transaction) => transaction.skipTransactionForExport === false)
    .map((transaction) => {
      const accountName = accountNames.find((name) => name === transaction.to);

      return [
        _.camelCase(transaction[`${_.camelCase(label)}TransactionType`]).toUpperCase(), // getTransactionType(transaction),
        csvInjectionProtector(transaction.fromForExport),
        csvInjectionProtector(transaction.toForExport),
        csvInjectionProtector(transaction[`${_.camelCase(label)}Description`]),
        formatTransactionTableAmount({ transaction, accountName, productType }),
        transaction.amount.currency,
        csvInjectionProtector(transaction.transactionCategory.description || 'Default'),
        transaction.points || 0,
        transaction.status,
        formatDateTime(transaction.createdAt),
        formatDateTime(transaction.completedAt),
      ];
    });
  const csvData = [HEADERS, ...transactionsList];

  return (
    <ReactCSVLink data={csvData} filename={`transactions-${label}.csv`} target="_blank">
      {children}
    </ReactCSVLink>
  );
};

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

export const XLSLink = ({ transactions = [], accountNames = [], productType, children }) => {
  const label = productType === 'wallet' ? 'account' : productType;
  const dataSet = transactions
    .filter((transaction) => transaction.skipTransactionForExport === false)
    .map((transaction) => {
      const accountName = accountNames.find((name) => name === transaction.to);

      const payload = {
        type: _.camelCase(transaction[`${_.camelCase(label)}TransactionType`]).toUpperCase(),
        from: csvInjectionProtector(transaction.fromForExport),
        to: csvInjectionProtector(transaction.toForExport),
        description: csvInjectionProtector(transaction[`${_.camelCase(label)}Description`]),
        amount: formatTransactionTableAmount({ transaction, accountName, productType }),
        currency: transaction.amount.currency,
        category: csvInjectionProtector(transaction.transactionCategory.description || 'Default'),
        points: transaction.points || 0,
        status: transaction.status,
        'transaction date': formatDateTime(transaction.createdAt),
        'completed on': formatDateTime(transaction.completedAt),
      };
      return payload;
    });

  return (
    <ExcelFile filename={`transactions-${label}`} element={children}>
      <ExcelSheet data={dataSet} name={label}>
        {HEADERS.map((header) => (
          <ExcelColumn key={header} label={header} value={header} />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
};
