import axios from 'axios';

const fileDownload = async (path, name, other) => {
  const { onComplete, ...rest } = other || {};
  const request = {
    url: path,
    method: 'GET',
    responseType: 'blob',
    ...(rest || {}),
  };

  const res = await axios(request);
  onComplete && onComplete();
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.href = window.URL.createObjectURL(new Blob([res.data]));
  a.setAttribute('download', name);
  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

export const unauthenticatedFileDownload = async ({ url, fileName, onComplete, ...rest }) => {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/pdf' },
    ...(rest || {}),
  };

  const res = await fetch(url, options);
  const blob = await res.blob();

  onComplete && onComplete();

  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link);

  link.href = window.URL.createObjectURL(new Blob([blob]));
  link.setAttribute('download', fileName);
  link.click();

  window.URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
};

export default fileDownload;
