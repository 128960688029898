import React from 'react';

const Clock = (props) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.5 1C5.53125 1 1.5 5.03125 1.5 10C1.5 14.9688 5.53125 19 10.5 19C15.4688 19 19.5 14.9688 19.5 10C19.5 5.03125 15.4688 1 10.5 1Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path d="M10.5 4V10.75H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Clock;
