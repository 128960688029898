import { formatMoneyWithoutCurrencyLabel } from 'utility/currency';
import { ReimbursementApprovalRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';

const useReimbursementRequestInfo = ({ request }: { request?: ReimbursementApprovalRequestDataType }) => {
  const { amountRequested, originalAmount, requester } = request || {};

  const formattedAmountRequested = formatMoneyWithoutCurrencyLabel(amountRequested);
  const formattedOriginalAmount = formatMoneyWithoutCurrencyLabel(originalAmount);

  const showAmountInOtherCurrency = originalAmount?.currency !== amountRequested?.currency;

  return {
    formattedAmountRequested,
    formattedOriginalAmount,
    requester,
    showAmountInOtherCurrency,
    originalAmountCurrency: originalAmount?.currency,
  };
};

export default useReimbursementRequestInfo;
