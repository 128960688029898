import React from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_DASHBOARD_INFO } from 'graphql/dashboard';
import { TEAM_MEMBER_STATUS } from 'constants/index';
import AccountSummary from 'components/accountSummary/AccountSummary';

const AccountSummaryContainer = () => {
  const { loading, data } = useQuery(GET_DASHBOARD_INFO);

  const internalContactStatus = get(data, 'me.internalContact.status');

  const pendingInformation = internalContactStatus === TEAM_MEMBER_STATUS.pending_info;
  const pendingVerification = internalContactStatus === TEAM_MEMBER_STATUS.pending_verification;
  const inactive = internalContactStatus === TEAM_MEMBER_STATUS.inactive;

  return (
    <AccountSummary
      loading={loading}
      pendingInformation={pendingInformation}
      pendingVerification={pendingVerification}
      inactive={inactive}
    />
  );
};

export default AccountSummaryContainer;
