import { useState, useContext } from 'react';

import { useToggle } from 'hooks';
import { AddPaymentRequestContext } from 'components/Invoices/components/Payors/components/PayorsList/contexts/AddPaymentRequestContext';
import { addPaymentRequestSteps } from '../constants';

const useAddPaymentRequest = () => {
  const { error, setError, setPaymentRequestInfo } = useContext(AddPaymentRequestContext);

  const [initialStep] = addPaymentRequestSteps.Details;

  const [currentStep, setCurrentStep] = useState(initialStep);
  const { isOpen: isCompleted, open: onComplete, close: onResetComplete } = useToggle();

  const onPrevStep = () => {
    setCurrentStep((prevStep) => (prevStep === 0 ? prevStep : prevStep - 1));
  };

  const onNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    setError(null);
  };

  const onResetSteps = () => {
    setCurrentStep(initialStep);
    onResetComplete();
    setError(null);
    setPaymentRequestInfo(null);
  };

  const errorSteps = error ? [initialStep] : [];

  return {
    currentStep,
    onPrevStep,
    onNextStep,
    onResetSteps,
    errorSteps,
    isCompleted,
    onComplete,
  };
};

export default useAddPaymentRequest;
