import React from 'react';

import { OnboardingDashboardLayout, PreviewScreen } from '..';
import rewardsScreenshotBlur from 'images/screenshot-rewards-page-blurred.png';

const Rewards = () => {
  return (
    <OnboardingDashboardLayout>
      <PreviewScreen bgImage={rewardsScreenshotBlur} />
    </OnboardingDashboardLayout>
  );
};

export default Rewards;
