import { BsSend } from 'react-icons/bs';

import { SidebarItem } from 'components/UI/Layout/Layout.types';
import Bank from 'components/svg/Bank';
import SettingsOutline from 'components/svg/SettingsOutline';
import GridOutline from 'components/svg/GridOutline';
import CardOutline from 'components/svg/CardOutline';
import DocumentTextOutline from 'components/svg/DocumentTextOutline';
import RepeatOutline from 'components/svg/RepeatOutline';
import GiftOutline from 'components/svg/GiftOutline';

export const getOnboardingDashboardSideNavItems = (): SidebarItem[] => {
  const items: SidebarItem[] = [
    {
      callToActionLabel: 'Complete Verification',
      route: '/onboarding/additional-details',
      label: 'Complete Verification',
      Icon: GridOutline, //this icon is not used, just making sure we satisfy the Icon type
    },
    {
      route: '/onboarding/dashboard/home',
      Icon: GridOutline,
      label: 'Home',
    },
    {
      route: '/onboarding/dashboard/cards',
      Icon: CardOutline,
      label: 'Cards',
    },
    {
      route: '/onboarding/dashboard/accounts',
      Icon: Bank,
      label: 'Accounts',
    },
    {
      route: '/onboarding/dashboard/payments/payees',
      Icon: BsSend,
      label: 'Send Payments',
    },
    {
      route: '/onboarding/dashboard/invoices',
      Icon: DocumentTextOutline,
      label: 'Request Payments',
    },
    {
      route: '/onboarding/dashboard/transactions',
      Icon: RepeatOutline,
      label: 'Transactions',
    },
    {
      route: '/onboarding/dashboard/rewards',
      Icon: GiftOutline,
      label: 'Rewards & Offers',
    },
  ];

  return items;
};

export const getOnboardingBottomSidebarItems = (): SidebarItem[] => {
  const items: SidebarItem[] = [
    {
      route: '/onboarding/dashboard/settings/personal',
      Icon: SettingsOutline,
      label: 'Settings',
    },
  ];

  return items;
};
