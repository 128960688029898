import React from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import ReactPixel from 'react-facebook-pixel';

import BusinessDetails from '../components/onboarding/BusinessDetails';
import { GET_BUSINESS_DETAILS } from '../graphql/onboarding';
import { GET_REGISTRATION_REFERRAL } from 'graphql/rewards';
import useOnboardingRedirect from '../hooks/useOnboardingRedirect';

const BusinessDetailsContainer = () => {
  const { loading, data } = useQuery(GET_BUSINESS_DETAILS, { fetchPolicy: 'network-only' });
  const { loading: loadingRegistrationReferral, data: registrationReferralData } = useQuery(GET_REGISTRATION_REFERRAL, {
    fetchPolicy: 'network-only',
  });

  const {
    name,
    displayName,
    entityType,
    mainIndustry,
    industry,
    phone,
    website,
    businessDescription,
    creditApplication,
    monthlyOnlineRevenue,
    referralSource,
  } = get(data, 'me.account', {});
  const averageProductMargin = get(creditApplication, 'averageProductMargin');
  const referrerBorrowerName = get(
    registrationReferralData,
    'me.account.registrationReferral.referrerBorrowerName',
    ''
  );

  const { loading: isLoadingDetails } = useOnboardingRedirect();
  ReactPixel.pageView();

  return (
    <BusinessDetails
      loading={isLoadingDetails || loading || loadingRegistrationReferral}
      name={name}
      displayName={displayName}
      entityType={entityType}
      mainIndustry={mainIndustry}
      industry={industry}
      phone={phone}
      website={website}
      businessDescription={businessDescription}
      averageProductMargin={averageProductMargin}
      monthlyOnlineRevenue={monthlyOnlineRevenue}
      referralSource={referralSource}
      referrerBorrowerName={referrerBorrowerName}
    />
  );
};

export default BusinessDetailsContainer;
