import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { GET_BASIC_WALLETS } from 'graphql/wallets';

const useGetWallets = () => {
  const { loading, data } = useQuery(GET_BASIC_WALLETS);

  const wallets = get(data, 'wallets') || [];
  return {
    loading,
    wallets,
  };
};

export default useGetWallets;
