import React, { useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import qs from 'query-string';

import { TextField, PasswordField, SubmitButton } from '../FormFields/v2';
import { AuthContext } from 'context/Auth';
import Layout from './Layout';
import NewPassword from './NewPassword';
import SMSCodeForm from './SMSCodeForm';
import { registerUtmParameters } from '../../utility/utm';

const Form = ({ location }) => {
  const { signIn, isSigningIn, signInError } = useContext(AuthContext);

  const { email } = qs.parse(location && location?.search);

  const form = useForm({ defaultValues: { email } });
  const { register, handleSubmit } = form;

  const onSubmit = async ({ email, password }) => {
    await signIn(email, password);
  };

  return (
    <Layout>
      <h1 className="tw-mb-3">Welcome back</h1>
      <div className="tw-flex">
        <p>Don’t have an account?</p>
        <Link to="/register">
          <strong className="tw-ml-1 tw-text-primary-dark-green">Create account</strong>
        </Link>
      </div>
      <FormProvider {...form}>
        <form className="tw-mt-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-mb-4">
            <TextField
              data-testid="signin-email"
              name="email"
              label="Email Address"
              placeholder="example@example.com"
              ref={register({ required: true })}
              autoComplete="username"
              tabIndex={1}
            />
          </div>
          <div className="tw-mb-2">
            <PasswordField
              data-testid="signin-password"
              name="password"
              label="Password"
              placeholder="∗∗∗∗∗∗∗∗"
              ref={register({ required: true })}
              autoComplete="current-password"
              tabIndex={2}
            />
          </div>
          <Link to="/forgot_password">
            <strong className="tw-text-primary-dark-green">Forgot password?</strong>
          </Link>
          {signInError && (
            <div>
              <span className="tw-text-semantic-error tw-text-xs">{signInError}</span>
            </div>
          )}
          <SubmitButton
            type="submit"
            className="tw-w-full tw-font-bold tw-mt-8"
            isFetching={isSigningIn}
            tabIndex={3}
            disabled={isSigningIn}
          >
            {isSigningIn ? 'Signing in' : 'Sign in'}
          </SubmitButton>
        </form>
      </FormProvider>
    </Layout>
  );
};

const SignIn = ({ location }) => {
  const { smsCodeRequested, newPasswordRequested } = useContext(AuthContext);
  registerUtmParameters();

  if (newPasswordRequested) return <NewPassword />;
  if (smsCodeRequested) return <SMSCodeForm />;

  return <Form location={location} />;
};

export default SignIn;
