import React from 'react';

type FrozenProps = {
  width?: number;
  height?: number;
};

const Frozen = ({ width = 64, height = 64 }: FrozenProps) => (
  <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="#6CCEFF" />
    <g clipPath="url(#clip0_18325_58943)">
      <path
        d="M39.0727 27.9173L24.9276 36.084M39.0727 27.9173L40.3537 23.1362M39.0727 27.9173L43.8537 29.1984M24.9276 36.084L20.1465 34.8029M24.9276 36.084L23.6465 40.8651M39.0726 36.0838L24.9275 27.9171M39.0726 36.0838L43.8538 34.8028M39.0726 36.0838L40.3538 40.8649M24.9275 27.9171L23.6467 23.1364M24.9275 27.9171L20.1466 29.1985M32.0001 23.834L32.0001 40.1673M32.0001 23.834L28.5001 20.334M32.0001 23.834L35.5001 20.334M32.0001 40.1673L28.5001 43.6673M32.0001 40.1673L35.5001 43.6673"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_18325_58943">
        <rect width="28" height="28" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);

export default Frozen;
