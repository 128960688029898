import React from 'react';

import { StepsProps } from '../../Steps';
import { useDetails } from './hooks';
import { useCreateOrUpdateRecurringPayment } from 'components/payments/CardAutopay/hooks';
import { FormFooter } from '..';
import { Loaders } from 'components/cards/Loader';

const Details = ({ onNextStep, onFinish }: Pick<StepsProps, 'onNextStep' | 'onFinish'>) => {
  const { formattedPaymentCurrency, fromAccount, formattedPaymentType, method } = useDetails();
  const { onSubmitRecurringPayment, loadingCreateCardRecurringPayment, loadingUpdateRecurringPayment } =
    useCreateOrUpdateRecurringPayment({ onNextStep });

  if (loadingCreateCardRecurringPayment || loadingUpdateRecurringPayment) return <Loaders.Light />;

  return (
    <div className="tw-pt-6">
      <div className="tw-px-8">
        <div className="tw-text-sm tw-rounded-md tw-px-4 tw-py-2 tw-bg-secondary-pastel-yellow-50">
          It is within 7 days of your statement due date, as such your first automatic payment will be initiated{' '}
          <b>immediately</b>.
        </div>
        <div className="tw-flex tw-flex-col tw-pt-8 tw-pb-8">
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-2">Currency</div>
            <p className="tw-mt-1">{formattedPaymentCurrency}</p>
          </div>
          <div className="tw-my-4">
            <div className="tw-text-sm tw-text-neutral-grey-2">From Account</div>
            <p className="tw-mt-1">{fromAccount}</p>
          </div>
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-2">Payment Type</div>
            <p className="tw-mt-1">{formattedPaymentType}</p>
          </div>
          <div className="tw-mt-4">
            <div className="tw-text-sm tw-text-neutral-grey-2">Method</div>
            <p className="tw-mt-1">{method}</p>
          </div>
        </div>
      </div>
      <FormFooter
        onCancel={onFinish}
        onSubmit={onSubmitRecurringPayment}
        submitLabel="Enable and Pay Statement"
        loading={loadingCreateCardRecurringPayment || loadingUpdateRecurringPayment}
      />
    </div>
  );
};

export default Details;
