import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const Success = (props) => {
  return (
    <svg width="178" height="146" viewBox="0 0 178 146" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30.6206 11.9588C30.8028 11.8359 31.0212 12.0542 30.8982 12.2365L24.5005 21.7183C24.4549 21.7859 24.4549 21.8744 24.5005 21.942L30.8982 31.4239C31.0212 31.6061 30.8028 31.8245 30.6206 31.7015L21.1387 25.3038C21.0711 25.2582 20.9826 25.2582 20.915 25.3038L11.4332 31.7015C11.2509 31.8245 11.0325 31.6061 11.1555 31.4239L17.5532 21.942C17.5988 21.8744 17.5988 21.7859 17.5532 21.7183L11.1555 12.2365C11.0325 12.0542 11.2509 11.8359 11.4332 11.9588L20.915 18.3565C20.9826 18.4021 21.0711 18.4021 21.1387 18.3565L30.6206 11.9588Z"
        fill="#EEA530"
      />
      <circle cx="86.9283" cy="60.249" r="42.6728" fill="#D0E7DA" />
      <path
        d="M118.506 55.1279C118.506 33.9249 101.304 16.7224 80.1007 16.7224C58.8977 16.7224 41.6952 33.9249 41.6952 55.1279C41.6952 76.331 58.8977 93.5334 80.1007 93.5334C101.304 93.5334 118.506 76.331 118.506 55.1279Z"
        stroke={primaryDarkGreenColor}
        strokeMiterlimit="10"
      />
      <path
        d="M99.3035 39.1257L72.4196 71.1303L60.8979 58.3285"
        stroke={primaryDarkGreenColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5608 104.112C38.5608 95.631 31.6798 88.75 23.1986 88.75C14.7174 88.75 7.83643 95.631 7.83643 104.112C7.83643 112.593 14.7174 119.474 23.1986 119.474C31.6798 119.474 38.5608 112.593 38.5608 104.112Z"
        fill="#D0E7F4"
        stroke="#D0E7F4"
        strokeMiterlimit="10"
      />
      <path
        d="M30.8798 97.7112L20.1262 110.513L15.5176 105.392"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.073 89.9883C163.285 89.9297 163.422 90.2062 163.248 90.3398L143.184 105.702C143.119 105.751 143.091 105.835 143.113 105.914L149.847 130.27C149.906 130.481 149.629 130.619 149.496 130.444L134.134 110.38C134.084 110.316 134 110.287 133.922 110.309L109.566 117.044C109.354 117.102 109.216 116.826 109.391 116.692L129.455 101.33C129.52 101.281 129.548 101.197 129.526 101.118L122.792 76.7625C122.733 76.5506 123.01 76.413 123.143 76.5876L138.505 96.6517C138.555 96.7164 138.639 96.7446 138.717 96.7229L163.073 89.9883Z"
        fill="#8F5CCF"
      />
    </svg>
  );
};

export default Success;
