import { useContext } from 'react';
import { get } from 'lodash';
import { useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

import { ReviewProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { useAccountsModal } from 'components/Accounts/AccountsContent/components/AccountsModal/hooks';
import { FundsContext } from 'components/Accounts/AccountsContent/contexts/FundsContext';
import { formatMoneyV2 } from 'utility/currency';
import { MOVE_FUNDS } from 'graphql/payments';
import { GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_STATEMENTS } from 'graphql/wallets';

const useReview = ({ onNextStep, onPrevStep }: ReviewProps) => {
  const { displayName, bankAccounts } = useAccountsModal();
  const { withdrawFundsInfo, setWithdrawFundsTransaction, setError } = useContext(FundsContext);

  const fromAccount = get(withdrawFundsInfo, 'fromAccount', '');
  const toAccount = get(withdrawFundsInfo, 'toAccount', '');
  const amount = get(withdrawFundsInfo, 'amount', 0);

  const toBankAccount = bankAccounts.find((ba) => ba.id === toAccount);
  const bankAccountDisplayName = toBankAccount?.displayName;

  const formattedAmount = formatMoneyV2(amount);

  const [moveFunds, { loading }] = useMutation(MOVE_FUNDS, {
    refetchQueries: [{ query: GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_STATEMENTS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async () => {
    try {
      const response = await moveFunds({
        variables: {
          from: fromAccount,
          to: toAccount,
          originalAmount: amount,
          chargedAmount: amount,
        },
      });
      if (response?.data?.moveFunds) {
        setWithdrawFundsTransaction(response.data.moveFunds);
      }
      onNextStep();
    } catch (err) {
      console.error(err);
      toast.error('Error withdrawing funds - please try again');
      setError(err as ApolloError);
      onPrevStep();
    }
  };

  return {
    displayName,
    formattedAmount,
    bankAccountDisplayName,
    loading,
    handleSubmit,
  };
};

export default useReview;
