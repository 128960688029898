import { InMemoryCache } from '@apollo/client';

const cache = new InMemoryCache({
  typePolicies: {
    AccountInfo: {
      keyFields: ['number'],
    },
    Transaction: {
      keyFields: ['transactionId'],
    },
    /*
    for now
    TransactionCategory: {
      keyFields: ['transactionCategoryId'],
    },
    */
    walletsFilter: {
      keyFields: ['me.account.wallets.id'],
    },
    cardsFilter: {
      keyFields: ['me.account.creditCards.id'],
    },
  },
});

export default cache;
