import { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { useLazyQuery, useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { concat, sortBy, indexOf } from 'lodash';

import {
  CreditCardProcessorSettingsQueryResponse,
  CreditCardProcessorSettings,
  CreditCardCurrency,
} from 'types/creditCard';
import { GET_CREDIT_CARD_PROCESSOR_SETTINGS, UPDATE_CREDIT_CARD_PROCESSOR_SETTINGS } from 'graphql/cards';
import { useToggle } from 'hooks';
import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';

const useSpendManagement = () => {
  const { creditCardDetails } = useContext(CardDetailsContext);
  const { id: creditCardId } = creditCardDetails || {};

  const [spendSettings, setSpendSettings] = useState<Pick<
    CreditCardProcessorSettings,
    | 'allowedMccsCategories'
    | 'blockedMccsCategories'
    | 'allowedCurrencies'
    | 'blockedCurrencies'
    | 'allowedMerchants'
    | 'merchantsList'
  > | null>(null);
  const [error, setError] = useState<ApolloError | null>(null);

  const {
    allowedMccsCategories,
    blockedMccsCategories,
    allowedCurrencies,
    blockedCurrencies,
    merchantsList,
    allowedMerchants,
  } = spendSettings || {};

  const { isOpen: isOpenModal, open: openModal, close: closeModal } = useToggle();

  const [getCreditCardSpendSettings, { loading }] = useLazyQuery<CreditCardProcessorSettingsQueryResponse>(
    GET_CREDIT_CARD_PROCESSOR_SETTINGS,
    {
      onCompleted: (data) => {
        const {
          allowedMccsCategories,
          blockedMccsCategories,
          allowedCurrencies,
          blockedCurrencies,
          allowedMerchants,
          merchantsList,
        } = data?.creditCard?.processorSettings || {};

        setSpendSettings({
          allowedMccsCategories,
          blockedMccsCategories,
          allowedCurrencies,
          blockedCurrencies,
          allowedMerchants,
          merchantsList,
        });
      },
      onError: setError,
    }
  );

  const [updateCreditCardProcessorSettings, { loading: isUpdatingProcessorSettings }] = useMutation<{
    setCardProcessorSettings: CreditCardProcessorSettings;
  }>(UPDATE_CREDIT_CARD_PROCESSOR_SETTINGS, {
    update: (cache, { data }) => {
      const updatedData = data?.setCardProcessorSettings;

      if (!updatedData) return;

      cache.updateQuery({ query: GET_CREDIT_CARD_PROCESSOR_SETTINGS, variables: { creditCardId } }, (existingData) => ({
        creditCard: {
          ...existingData.creditCard,
          processorSettings: updatedData,
        },
      }));
    },
  });

  const merchantCategories = useMemo(
    () => sortBy(concat(allowedMccsCategories || [], blockedMccsCategories || [])),
    [allowedMccsCategories, blockedMccsCategories]
  );

  const currencies = useMemo(() => {
    const order = [CreditCardCurrency.CAD, CreditCardCurrency.USD, CreditCardCurrency.EUR, CreditCardCurrency.GBP];

    return sortBy(concat(allowedCurrencies || [], blockedCurrencies || []), (currency) => indexOf(order, currency));
  }, [allowedCurrencies, blockedCurrencies]);

  const selectedCurrencies = useMemo(
    () => allowedCurrencies?.filter((currency) => !blockedCurrencies?.includes(currency)),
    [allowedMerchants, blockedCurrencies]
  );
  const selectedMerchantCategories = useMemo(
    () => allowedMccsCategories?.filter((category) => !blockedMccsCategories?.includes(category)),
    [allowedMccsCategories, blockedMccsCategories]
  );
  const formattedAllowedCurrencies = useMemo(() => selectedCurrencies?.join(', ') || '', [selectedCurrencies]);
  const formattedAllowedMerchants = useMemo(() => allowedMerchants?.join(', ') || '', [allowedMerchants]);

  const handleUpdateSettings = useCallback(
    async ({
      blockedMerchantCategories,
      blockedCurrencies,
      allowedMerchants,
    }: {
      blockedMerchantCategories: string[];
      blockedCurrencies: CreditCardCurrency[];
      allowedMerchants: string[];
    }) => {
      if (!creditCardId) return;

      const processingToast = toast.loading('Updating spend settings...');

      try {
        const response = await updateCreditCardProcessorSettings({
          variables: {
            creditCardId,
            processorSettings: {
              blockedMccsCategories: blockedMerchantCategories.join(','),
              blockedCurrencies: blockedCurrencies.join(','),
              allowedMerchants: allowedMerchants.join(','),
            },
          },
        });

        if (!response?.data?.setCardProcessorSettings) throw new Error('Failed to update spend settings');

        toast.update(processingToast, {
          render: 'Credit card spend settings were added successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        closeModal();
      } catch (error) {
        toast.update(processingToast, {
          render: 'Failed to update credit card spend settings',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
        console.error(error);
      }
    },
    [creditCardId, updateCreditCardProcessorSettings, closeModal]
  );

  useEffect(() => {
    if (!creditCardId) return;

    getCreditCardSpendSettings({ variables: { creditCardId } });
  }, [creditCardId, getCreditCardSpendSettings]);

  return {
    isLoading: loading,
    isError: !!error,
    isOpenModal,
    openModal,
    closeModal,
    handleUpdateSettings,
    isUpdatingProcessorSettings,
    currencies,
    selectedCurrencies: selectedCurrencies || [],
    merchants: merchantsList || [],
    selectedMerchants: allowedMerchants || [],
    merchantCategories,
    selectedMerchantCategories: selectedMerchantCategories || [],
    selectedMerchantCategoriesCount: selectedMerchantCategories?.length || 0,
    formattedAllowedCurrencies,
    formattedAllowedMerchants,
  };
};

export default useSpendManagement;
