import { useCallback, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import type { CardVerificationCodeElement as CardVerificationCodeElementType } from '@basis-theory/basis-theory-react/types';
import { toast } from 'react-toastify';

import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { useBasisTheoryOperations } from 'components/creditCards/hooks';
import { MatchSecurityCodeError } from 'components/creditCards/creditCards.types';
import { UseActivateCardModalType } from '../ActivateCardModal.types';

const useActivateCardModal = ({ onClose }: UseActivateCardModalType) => {
  const { creditCardDetails } = useContext(CardDetailsContext);
  const { id: cardId, cardProcessorId } = creditCardDetails || {};
  const { bt, activateCard, loading, setLoading } = useBasisTheoryOperations();
  const securityCodeRef = useRef<CardVerificationCodeElementType>(null);

  const form = useForm();
  const { register, handleSubmit } = form;

  const onSubmit = useCallback(async () => {
    const processingToast = toast.loading('Activating Card...');

    if (!cardId || !cardProcessorId) {
      toast.update(processingToast, {
        render: 'Unable to activate card - please try again',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    try {
      await activateCard(cardId, cardProcessorId, securityCodeRef);

      toast.update(processingToast, {
        render: 'Card activated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      onClose();
    } catch (error) {
      console.log(error);

      const err = error as MatchSecurityCodeError;
      const errorMessage = err.data?.match_security_code_error
        ? err.data.match_security_code_error
        : 'Unable to activate card - please try again';

      toast.update(processingToast, {
        render: errorMessage,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, [cardId, cardProcessorId, activateCard, onClose, securityCodeRef]);

  return {
    lastFourDigits: creditCardDetails?.lastFourDigits,
    form,
    register,
    handleSubmit,
    bt,
    loading,
    setLoading,
    onSubmit,
    securityCodeRef,
  };
};

export default useActivateCardModal;
