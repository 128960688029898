import React from 'react';
import { FormProvider } from 'react-hook-form';
import { BsArrowRight } from 'react-icons/bs';
import { CgArrowsExchangeAltV } from 'react-icons/cg';

import { DetailsProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/ConvertFunds/components/Steps/Steps.types';
import { useDetails } from './hooks';
import { RadioField } from 'components/FormFields/v2';
import {
  AmountInput,
  Footer,
  SelectAccountOptions,
} from 'components/Accounts/AccountsContent/components/AccountsModal/components';
import { Loaders } from 'components/cards/Loader';
import { ConvertFundsActionTypes } from 'components/Accounts/AccountsContent/components/AccountsModal/types/funds';
import { MaintenanceBanner } from 'components/UI';

const Details = ({ onNextStep }: DetailsProps) => {
  const {
    fromOptions,
    toOptions,
    form,
    register,
    fromAccountCurrency,
    toAccountCurrency,
    formattedAmount,
    setFormattedAmount,
    formatMoneyV2,
    formattedBalance,
    rate,
    loadingRate,
    formattedOriginalAmount,
    formattedChargedAmount,
    buyOrSell,
    formattedMaxBuyAmount,
    handleSubmit,
    handleOnNextStep,
    errorMessage,
    needsConversion,
    currencyCloudMaintenance,
  } = useDetails({ onNextStep });

  return (
    <>
      <div className="tw-text-xl tw-font-semibold tw-mt-6 tw-px-8">How much would you like to convert?</div>
      <div>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleOnNextStep)}>
            <div className="tw-px-8">
              <SelectAccountOptions
                options={fromOptions}
                name="fromAccount"
                label={
                  <div className="tw-text-neutral-dark">
                    Which Currency Are You Converting <b>From</b>? (Selling)
                  </div>
                }
                register={register}
              />
              <SelectAccountOptions
                options={toOptions}
                name="toAccount"
                label={
                  <div className="tw-text-neutral-dark">
                    Which Currency Are You Converting <b>To</b>? (Buying)
                  </div>
                }
                register={register}
              />
            </div>
            <div className="tw-px-8 tw-pt-4 tw-pb-8">
              <div className="tw-text-sm">Do You Want to Specify the Amount to Sell or Buy?</div>
              <RadioField
                rootClass="tw-mt-4"
                name="buyOrSell"
                options={[
                  { label: 'Sell', value: ConvertFundsActionTypes.sell },
                  { label: 'Buy', value: ConvertFundsActionTypes.buy },
                ]}
                ref={register({ required: true })}
              />
              <MaintenanceBanner maintenanceMessageSource={currencyCloudMaintenance} />
              {buyOrSell === ConvertFundsActionTypes.sell && (
                <>
                  <div className="tw-my-4">
                    <AmountInput
                      name="fromAmount"
                      label="Amount to Sell"
                      currency={fromAccountCurrency!}
                      formattedAmount={formattedAmount}
                      formatMoneyV2={formatMoneyV2}
                      setFormattedAmount={setFormattedAmount}
                      register={register}
                    />
                    <div className="tw-text-sm tw-my-4">
                      You currently have <b>{formattedBalance}</b> available to sell.
                    </div>
                  </div>
                  {needsConversion && (
                    <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4">
                      <div>
                        <div className="tw-flex tw-justify-between tw-items-center">
                          <div className="tw-text-sm tw-font-semibold">Your Exchange Rate</div>
                          <div className="tw-text-sm tw-flex tw-items-center">
                            {loadingRate ? <Loaders.Spinner /> : rate}
                            <CgArrowsExchangeAltV size={20} className="tw-ml-2" />
                          </div>
                        </div>
                        <div className="tw-text-sm tw-font-semibold tw-flex tw-items-center tw-mt-1 tw-mb-4">
                          {fromAccountCurrency} <BsArrowRight className="tw-mx-2" /> {toAccountCurrency}
                        </div>
                      </div>
                      <div className="tw-flex tw-justify-between tw-items-center">
                        <div className="tw-text-sm tw-font-semibold">Amount You Will Receive</div>
                        <div className="tw-text-sm">
                          {loadingRate && <Loaders.Spinner />}
                          {!loadingRate && (
                            <>
                              {rate ? formattedOriginalAmount : ''} {toAccountCurrency}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {buyOrSell === ConvertFundsActionTypes.buy && (
                <>
                  <div className="tw-my-4">
                    <AmountInput
                      name="toAmount"
                      label="Amount to Buy"
                      currency={toAccountCurrency!}
                      formattedAmount={formattedAmount}
                      formatMoneyV2={formatMoneyV2}
                      setFormattedAmount={setFormattedAmount}
                      register={register}
                    />
                    <div className="tw-text-sm tw-my-4">
                      Your {fromAccountCurrency} balance will enable you to buy up to{' '}
                      <b>{loadingRate ? <Loaders.Spinner /> : formattedMaxBuyAmount}</b>.
                    </div>
                  </div>
                  {needsConversion && (
                    <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4">
                      <div>
                        <div className="tw-flex tw-justify-between tw-items-center">
                          <div className="tw-text-sm tw-font-semibold">Your Exchange Rate</div>
                          <div className="tw-text-sm tw-flex tw-items-center">
                            {loadingRate ? <Loaders.Spinner /> : rate}
                            <CgArrowsExchangeAltV size={20} className="tw-ml-2" />
                          </div>
                        </div>
                        <div className="tw-text-sm tw-font-semibold tw-flex tw-items-center tw-mt-1 tw-mb-4">
                          {fromAccountCurrency} <BsArrowRight className="tw-mx-2" /> {toAccountCurrency}
                        </div>
                      </div>
                      <div className="tw-flex tw-justify-between tw-items-center">
                        <div className="tw-text-sm tw-font-semibold">Amount You Will Convert</div>
                        <div className="tw-text-sm">
                          {loadingRate && <Loaders.Spinner />}
                          {!loadingRate && (
                            <>
                              {rate ? formattedChargedAmount : ''} {fromAccountCurrency}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {errorMessage && <div className="tw-text-semantic-error tw-text-xs tw-mt-8">{errorMessage}</div>}
            </div>
            <Footer submitLabel="Review" loadingRate={loadingRate} />
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default Details;
