import { useContext, useState } from 'react';

import { useToggle } from 'hooks';
import { PaymentRequestsContext } from 'components/PayorPortal/PaymentRequests/contexts/PaymentRequestsContext';
import { PaymentRequestCurrency } from 'types/invoicing';

const usePaymentRequestsContent = () => {
  const [selectedCurrency, setSelectedCurrency] = useState<PaymentRequestCurrency | undefined>();

  const { noBankAccounts, requiredCADBankAccount, requiredUSDBankAccount, isBankAccountsConnectionError, isLoading } =
    useContext(PaymentRequestsContext);

  const {
    isOpen: isOpenBankConnectionModal,
    open: openBankConnectionModal,
    close: closeBankConnectionModal,
  } = useToggle();

  const handleOpenBankConnectionModal = (currency?: PaymentRequestCurrency) => {
    setSelectedCurrency(currency);
    openBankConnectionModal();
  };

  const handleCloseBankConnectionModal = () => {
    setSelectedCurrency(undefined);
    closeBankConnectionModal();
  };

  return {
    noBankAccounts,
    requiredCADBankAccount,
    requiredUSDBankAccount,
    isBankAccountsConnectionError,
    isLoading,
    isOpenBankConnectionModal,
    openBankConnectionModal: handleOpenBankConnectionModal,
    closeBankConnectionModal: handleCloseBankConnectionModal,
    selectedCurrency,
  };
};

export default usePaymentRequestsContent;
