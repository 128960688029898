import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ampTrackEvent } from 'amplitude';

import { useToggle } from 'hooks';
import { CardRepaymentContext, CardRepaymentError } from 'context/CardRepayment';
import { cardRepaymentSteps } from 'components/payments/CardRepayment/constants';
import { TransactionResponseDetailsProps } from '../TransactionResponseDetails.types';

const useTransactionDetails = ({
  transaction,
  retryPayBalance,
}: Pick<TransactionResponseDetailsProps, 'transaction' | 'retryPayBalance'>) => {
  const { setPayBalanceTransactions, setError } = useContext(CardRepaymentContext);

  const { isOpen: isLoading, open: showLoading, close: hideLoading } = useToggle(false);

  const { id, transactionGroupId, initiatedAt, isFromBankAccount } = transaction;

  const handleRetryPayBalance = async () => {
    showLoading();
    setError(null);

    try {
      const response = await retryPayBalance(transaction);
      const responseData = response?.data?.moveFunds;

      if (!responseData) throw new Error('No response data');

      const { transactionGroupId, initiatedAt } = responseData;

      setPayBalanceTransactions((prevTransactions) =>
        prevTransactions.map((txn) => {
          if (txn.id === id) {
            txn.transactionGroupId = transactionGroupId;
            txn.initiatedAt = initiatedAt;
          }
          return txn;
        })
      );

      ampTrackEvent('payBalance: transferFunds: retry: success');
      toast.success('Transaction has been submitted successfully.');
    } catch (err) {
      console.error(err);
      ampTrackEvent('payBalance: transferFunds: retry: failed');
      toast.error('Could not submit the transaction. Please try again.');
      setError({
        message: 'Could not submit the transaction. Please try again.',
        step: cardRepaymentSteps.Complete,
      } as CardRepaymentError);
    }

    hideLoading();
  };

  return {
    isSuccessful: !!transactionGroupId,
    transactionGroupId,
    initiatedAt,
    isFromBankAccount,
    handleRetryPayBalance,
    isLoading,
  };
};

export default useTransactionDetails;
