import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_CREDIT_CARDS_IDS } from 'graphql/cards';

const useGetCreditCardsState = () => {
  const { data: creditCardIdsData, loading, error } = useQuery(GET_CREDIT_CARDS_IDS);

  const creditCardIds: { id: string }[] = get(creditCardIdsData, 'me.account.creditCards', []);

  const noCreditCards = creditCardIds.length === 0;

  return {
    isLoading: loading,
    isError: !!error,
    noCreditCards,
  };
};

export default useGetCreditCardsState;
