import React from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';

import { ToggleDropdown } from 'components/UI';

import { CardsListFilterProps } from './CardsListFilter.types';

const CardsListFilter = ({
  selectedStatuses,
  setSelectedStatuses,
  selectedTypes,
  setSelectedTypes,
  onClearFilters,
}: CardsListFilterProps) => {
  return (
    <>
      <div className="tw-flex tw-items-center">
        <small className="tw-text-neutral-grey-2">Filter</small>
        <div className="tw-w-28">
          <ToggleDropdown
            options={['Active', 'Frozen', 'Closed', 'Activation Required']}
            selectedOptions={selectedStatuses}
            setSelectedOptions={setSelectedStatuses}
            label="Status"
            loadingOptions={false}
          />
        </div>

        <div className="tw-w-32">
          <ToggleDropdown
            options={['virtual', 'physical']}
            selectedOptions={selectedTypes}
            setSelectedOptions={setSelectedTypes}
            label="Card Type"
            loadingOptions={false}
          />
        </div>

        <div
          onClick={onClearFilters}
          className="tw-flex tw-items-center tw-cursor-pointer tw-text-neutral-grey-2 tw-ml-2"
        >
          <IoCloseCircleOutline size={18} className="tw-mr-2" />
          <small>Clear all</small>
        </div>
      </div>
    </>
  );
};

export default CardsListFilter;
