import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { Loaders } from 'components/cards/Loader';
import { ConnectBankOrContactLoopBanner } from 'components/UI';
import { ErrorState, NoCardsBanner } from 'components/creditCards/components';
import {
  CurrentBalances,
  GlobalLimit,
  StatementBalances,
} from 'components/creditCards/components/CardBalances/components';
import PendingBankAccountVerificationBanner from 'components/creditCards/PendingBankAccountVerificationBanner';
import { CardBalancesContentProps } from './CardBalancesContent.types';

const CardBalancesContent = ({
  isError,
  isLoading,
  noCreditCards,
  hasBankAccounts,
  hasAtLeastOneBankAccountVerified,
  isPreFunded,
}: CardBalancesContentProps) => {
  const history = useHistory();
  const navigateToCards = () => {
    history.push('/dashboard/cards/list');
  };

  useEffect(() => {
    if (isError) toast.error("Can't fetch card balances. Please try again.");
  }, [isError]);

  if (isLoading) return <Loaders.Light />;

  if (isError) return <ErrorState />;

  if (!hasBankAccounts) {
    return (
      <ConnectBankOrContactLoopBanner
        title="Cards"
        message="Connect the bank account you will be using to transfer funds to your Loop Card. Once connected, you'll be able to create Loop cards and start spending."
      />
    );
  }

  if (!hasAtLeastOneBankAccountVerified) {
    return <PendingBankAccountVerificationBanner title="Bank account is required" />;
  }

  if (noCreditCards) return <NoCardsBanner onSubmit={navigateToCards} />;

  return (
    <div className="tw-flex tw-flex-col tw-gap-6 md:tw-gap-10 tw--mx-4 md:tw-mx-0 tw-mb-6">
      {!isPreFunded && <GlobalLimit />}

      <CurrentBalances isPreFunded={isPreFunded} />

      {!isPreFunded && <StatementBalances />}
    </div>
  );
};

export default CardBalancesContent;
