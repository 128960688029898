import React, { memo } from 'react';
import { BsCalendarWeek } from 'react-icons/bs';
import { HiOutlineArrowUpTray } from 'react-icons/hi2';

import { TableRowProps } from './TableRow.types';
import { PaymentRequestStatusBadge } from '..';
import { RowAction } from './components';
import { useTableRow } from './hooks';

const TableRow = ({
  paymentRequest,
  bankAccount,
  openInfoModal,
  openBankConnectionModal,
  openConfirmPaymentModal,
}: TableRowProps) => {
  const {
    vendorName,
    bankAccountName,
    formattedDueDate,
    formattedAmount,
    status,
    isOngoing,
    frequencyType,
    isBankAccountConnected,
    isAvailableForProcessing,
    currency,
  } = useTableRow({ paymentRequest, bankAccount });

  return (
    <tr className="tw-border-b tw-border-neutral-grey-5">
      <td className="tw-px-3 tw-py-4 tw-text-sm">{vendorName}</td>
      <td className="tw-px-3 tw-py-4 tw-text-sm">{bankAccountName}</td>
      <td className="tw-px-3 tw-py-4 tw-text-sm">{formattedDueDate}</td>
      <td className="tw-px-3 tw-py-4 tw-text-sm">{`${formattedAmount} ${currency}`}</td>
      <td className="tw-px-3 tw-py-4 tw-text-sm tw-flex tw-gap-2 tw-items-center">
        {isOngoing ? <BsCalendarWeek /> : <HiOutlineArrowUpTray />} {frequencyType}
      </td>
      <td className="tw-px-3 tw-py-4">
        <PaymentRequestStatusBadge
          status={status}
          isBankAccountConnected={isBankAccountConnected}
          isAvailableForProcessing={isAvailableForProcessing}
        />
      </td>
      <td className="tw-px-3 tw-py-4 tw-text-sm">
        <RowAction
          isBankAccountConnected={isBankAccountConnected}
          isAvailableForProcessing={isAvailableForProcessing}
          openBankConnectionModal={() => openBankConnectionModal(currency)}
          openConfirmPaymentModal={openConfirmPaymentModal}
        />
      </td>
      <td className="tw-px-3 tw-py-4 tw-text-sm">
        <button onClick={openInfoModal} className="tw-text-primary-blue tw-underline">
          View
        </button>
      </td>
    </tr>
  );
};

export default memo(TableRow);
