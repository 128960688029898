import React, { useState } from 'react';

import ExternalWalletAccount from 'domain/externalWalletAccount';

export const AddUSBankAccountContext = React.createContext({});

export const AddUSBankAccountContextProvider = (props) => {
  const [bankAccountInfo, setBankAccountInfo] = useState(null);
  const [walletExternalAccount, setWalletExternalAccount] = useState(null);

  return (
    <AddUSBankAccountContext.Provider
      value={{
        bankAccountInfo,
        setBankAccountInfo,
        walletExternalAccount: new ExternalWalletAccount(walletExternalAccount),
        setWalletExternalAccount,
      }}
    >
      {props.children}
    </AddUSBankAccountContext.Provider>
  );
};
