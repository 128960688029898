import React from 'react';
import cx from 'classnames';

import useIsMobile from 'hooks/useIsMobile';
import { PointsTransactionsContextProvider } from 'context/PointsTransactions';
import { FilterProvider } from '../context/FilterContext';
import TransactionsContent from './TransactionsContent';

const PointsTransactions = () => {
  const isMobile = useIsMobile();

  return (
    <PointsTransactionsContextProvider>
      <FilterProvider>
        <div className={cx('tw-flex', isMobile && 'tw-flex-col tw-px-4')}>
          <TransactionsContent />
        </div>
      </FilterProvider>
    </PointsTransactionsContextProvider>
  );
};

export default PointsTransactions;
