import { useContext } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ampTrackEvent } from 'amplitude';

import { CompleteProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/ConvertFunds/components/Steps/Steps.types';
import { FundsContext } from 'components/Accounts/AccountsContent/contexts/FundsContext';
import { Currencies } from 'constants/currencies';
import { formatMoneyV2 } from 'utility/currency';
import { formatDateTime } from 'utility/date';
import { useGetSubscription } from 'hooks';
import { GET_SUBSCRIPTION_SAVINGS } from 'graphql/payees';

const useComplete = ({ onResetSteps }: CompleteProps) => {
  const { convertFundsTransaction, convertFundsInfo, setConvertFundsInfo } = useContext(FundsContext);

  const { rate, toAccount, fromAccount, originalAmount } = convertFundsInfo || {};

  const toAccountName = get(toAccount, 'displayName', '');
  const fromAccountName = get(fromAccount, 'displayName', '');

  const { amount: chargedAmount, transferMethod, transactionGroupId, initiatedAt } = convertFundsTransaction || {};

  const formattedOriginalAmount = formatMoneyV2(originalAmount);
  const originalAmountCurrency = get(originalAmount, 'currency', Currencies.CAD);
  const chargedAmountCurrency = get(chargedAmount, 'currency', Currencies.CAD);

  const formattedInitiatedAtDate = formatDateTime(initiatedAt);

  const { isPaidSubscription } = useGetSubscription();
  const hadConversion = originalAmountCurrency !== chargedAmountCurrency;
  const showSavingsComponent = !isPaidSubscription && hadConversion;

  const { data, loading } = useQuery(GET_SUBSCRIPTION_SAVINGS, {
    variables: {
      buy: { amount: originalAmount?.amount, currency: originalAmountCurrency },
      sell: { amount: chargedAmount?.amount, currency: chargedAmountCurrency },
    },
  });

  const savingsAmount = get(data, 'subscriptionSavings.savingsAmount', 0);
  const formattedSavingsAmount = formatMoneyV2(savingsAmount);

  const history = useHistory();
  const navigateToSubscriptions = () => {
    ampTrackEvent('Subscriptions: Convert Funds');
    history.push('/dashboard/subscriptions');
  };

  const handleNewTransfer = () => {
    setConvertFundsInfo(null);
    onResetSteps();
  };

  return {
    toAccountName,
    fromAccountName,
    chargedAmount,
    formattedOriginalAmount,
    originalAmountCurrency,
    transferMethod,
    transactionGroupId,
    formattedInitiatedAtDate,
    handleNewTransfer,
    rate,
    navigateToSubscriptions,
    showSavingsComponent,
    loading,
    formattedSavingsAmount,
  };
};

export default useComplete;
