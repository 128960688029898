import React, { useState, useRef } from 'react';

export const SettingsContext = React.createContext({});

export const SettingsContextProvider = (props) => {
  const [members, setMembers] = useState([]);
  const [externalAccounts, setExternalAccounts] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [allowBankAccountCreation, setAllowBankAccountCreation] = useState();
  const [bankConnectionError, setBankConnectionError] = useState();
  const [bankAccountError, setBankAccountError] = useState();
  const refetchIntegrationsRef = useRef();
  const refetchBankAccountsRef = useRef();
  const refetchMembersRef = useRef();

  const [loadingMembers, setLoadingMembers] = useState(false);
  const [loadingIntegrations, setLoadingIntegrations] = useState(false);
  const [loadingBankAccounts, setLoadingBankAccounts] = useState(false);
  const [waitingForBankDetails, setWaitingForBankDetails] = useState(false);

  return (
    <SettingsContext.Provider
      value={{
        members,
        setMembers,
        externalAccounts,
        setExternalAccounts,
        bankAccounts,
        setBankAccounts,
        allowBankAccountCreation,
        setAllowBankAccountCreation,
        bankConnectionError,
        setBankConnectionError,
        bankAccountError,
        setBankAccountError,
        refetchIntegrationsRef,
        refetchBankAccountsRef,
        refetchMembersRef,
        loadingMembers,
        setLoadingMembers,
        loadingIntegrations,
        setLoadingIntegrations,
        loadingBankAccounts,
        setLoadingBankAccounts,
        waitingForBankDetails,
        setWaitingForBankDetails,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};
