import { useContext, useMemo, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ampTrackEvent } from 'amplitude';

import { GET_PAYORS, DELETE_PAYOR } from 'graphql/invoicing';
import { PayorsContext } from 'components/Invoices/contexts/PayorsContext';
import { DeletePayorModalProps } from '../DeletePayorModal.types';

const useDeletePayorModal = ({ onClose }: Pick<DeletePayorModalProps, 'onClose'>) => {
  const { payors, selectedPayorId } = useContext(PayorsContext);

  const [deletePayor, { loading }] = useMutation(DELETE_PAYOR, {
    refetchQueries: [{ query: GET_PAYORS }],
    awaitRefetchQueries: true,
  });

  const payor = useMemo(() => payors.find(({ id }) => id === selectedPayorId), [payors, selectedPayorId]);

  const handleDeletePayor = useCallback(async () => {
    try {
      const payorId = payor?.id;

      if (!payorId) throw new Error(`${payorId} couldn't be empty`);

      const response = await deletePayor({
        variables: { payorId },
      });
      toast.success('Payor successfully deleted');

      if (response?.data?.deletePayor) {
        ampTrackEvent('deletePayor: success');
      }
    } catch (err) {
      console.error(err);
      toast.error('Error deleting Payor - please try again');
      ampTrackEvent('deletePayor: error');
    } finally {
      onClose();
    }
  }, [payor, deletePayor, onClose]);

  return {
    payorName: payor?.name,
    handleDeletePayor,
    loading,
  };
};

export default useDeletePayorModal;
