import React from 'react';
import { BsPlus } from 'react-icons/bs';

import Button from 'components/Button';
import { SearchInput } from 'components/UI';
import { useToggle } from 'hooks';
import { ErrorState, CreateCardModal } from 'components/creditCards/components';
import {
  CardsListTable,
  CardsListFilter,
  CardsListSkeleton,
} from 'components/creditCards/components/CardsList/components';
import { useCardsList } from 'components/creditCards/components/CardsList/hooks';
import PermissionChecker from 'components/PermissionChecker';
import { SCOPE } from 'constants/index';

export type cardsListProps = {
  canCreateNewCard: boolean;
};

const CardsListContent = ({ canCreateNewCard }: cardsListProps) => {
  const {
    cardCount,
    filteredCreditCards,
    error,
    isLoading,
    search,
    handleChangeSearch,
    selectedStatuses,
    setSelectedStatuses,
    selectedTypes,
    setSelectedTypes,
    onClearFilters,
  } = useCardsList();

  const { isOpen: isOpenCreateCardModal, open: openCreateCardModal, close: closeCreateCardModal } = useToggle();

  if (isLoading) return <CardsListSkeleton />;

  if (error) return <ErrorState />;

  return (
    <div className="tw-mb-4">
      <div className="tw-flex tw-flex-col xl:tw-flex-row tw-justify-between xl:tw-items-center tw-w-full tw-mb-4">
        <h1>Your Cards &bull; {cardCount}</h1>

        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-2 md:tw-gap-4">
          <CardsListFilter
            selectedStatuses={selectedStatuses}
            setSelectedStatuses={setSelectedStatuses}
            selectedTypes={selectedTypes}
            setSelectedTypes={setSelectedTypes}
            onClearFilters={onClearFilters}
          />

          <SearchInput placeholder="Search Cards" value={search} onChange={handleChangeSearch} />

          <PermissionChecker scope={SCOPE.manageCards} memberScope={canCreateNewCard}>
            <Button primary className="tw-font-bold" onClick={openCreateCardModal}>
              <BsPlus size={30} />
              Create New Card
            </Button>
          </PermissionChecker>
        </div>
      </div>

      <CardsListTable creditCards={filteredCreditCards} />

      {isOpenCreateCardModal && <CreateCardModal show={isOpenCreateCardModal} onClose={closeCreateCardModal} />}
    </div>
  );
};

export default CardsListContent;
