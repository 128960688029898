import React from 'react';
import { FormProvider } from 'react-hook-form';
import { BsExclamationCircleFill } from 'react-icons/bs';

import { FormFooter } from 'components/UI';
import Banner, { BannerSize, BannerType } from 'components/UI/Banner';
import { RadioField } from 'components/FormFields/v2';
import useCardMigrationPreferences from './hooks/useCardMigrationPreferences';

const MigrateCards = () => {
  const { form, register, cardMigrationTypeOptions, isNextButtonDisabled, onPrevStep, onNextStep } =
    useCardMigrationPreferences();

  return (
    <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-flex-grow tw-items-center">
      <div className="tw-flex tw-flex-col tw-gap-4 tw-py-4 tw-w-full lg:tw-w-4/5 xl:tw-w-2/3 tw-max-w-screen-lg tw-mx-auto tw-px-4 lg:tw-px-0">
        <p>
          You can choose if you want us to automatically re-create your existing cards. When we re-create cards, you
          will still receive new card details, however re-created cards will maintain the same card names, addresses
          spend settings and ownership.
        </p>
        <div>
          <FormProvider {...form}>
            <div>
              <RadioField
                optionWrapperClass="tw-w-full tw-flex tw-flex-col"
                label="Select Migration Option"
                name="cardMigrationType"
                options={cardMigrationTypeOptions}
                ref={register({ required: true })}
                contentFirst={true}
                verticalLayout={true}
              />
            </div>
          </FormProvider>
        </div>
        <Banner
          submitButton={false}
          icon={<BsExclamationCircleFill color="var(--colors-primary-warning)" size={18} />}
          iconAlignment="center"
          submitButtonLabel="no"
          type={BannerType.warning}
          size={BannerSize.sm}
          message="Please Note: Frozen, suspended, or inactive cards will not be migrated."
          arrowIcon={false}
          centerAlignContent={true}
          classNames="tw-mb-0"
          hasBorder={true}
        />
      </div>
      <div className="tw-w-full tw-border-b tw-border-neutral-grey-4 tw-bg-neutral-grey-4.5 tw-bottom-0 tw-rounded-b-md tw-flex tw-justify-center">
        <FormFooter
          onCancel={onPrevStep}
          cancelButtonLabel="Go Back"
          onSubmit={onNextStep}
          isDisabled={isNextButtonDisabled}
          submitButtonLabel="Continue"
          rootClass="tw-w-2/3 tw-py-6 tw-flex tw-justify-between"
        />
      </div>
    </div>
  );
};

export default MigrateCards;
