import { useContext, useState } from 'react';

import { CardMigrationContext } from 'components/CardMigrationV2/context/CardMigrationContext';

import CardMigrationAccountLinked from 'images/CardMigrationAccountLinked.png';
import CardMigrationCardHolderInsurance from 'images/CardMigrationCardHolderInsurance.png';
import CardMigrationMoreEnhancementsComingSoon from 'images/CardMigrationMoreEnhancementsComingSoon.png';
import CardMigrationBalanceManagement from 'images/CardMigrationBalanceManagement.png';
import CardMigrationImprovedCardSpendingLimits from 'images/CardMigrationImprovedCardSpendingLimits.png';
import CardMigrationSmarterSpendLimits from 'images/CardMigrationSmarterSpendLimits.png';

type RadioContent = {
  id: number;
  indexForCredit: number;
  indexForPreFunded: number;
  label: string;
  description: string;
  imageUrl: string;
  destination: string;
  selected: boolean;
};

const useWhatsChanged = () => {
  const { isCreditUser, onPrevStep, onNextStep } = useContext(CardMigrationContext);

  const rawRadioContent: RadioContent[] = [
    {
      id: 0,
      indexForCredit: 6,
      indexForPreFunded: 1,
      label: 'Cards Are Now Linked To Your Account ',
      description:
        'Your cards will be linked directly to your Loop accounts! You no longer need to fund your card, simply add funds to at least one of your Loop Currency or Operating Accounts and start spending',
      imageUrl: CardMigrationAccountLinked,
      destination: '',
      selected: false,
    },
    {
      id: 1,
      indexForCredit: 7,
      indexForPreFunded: 2,
      label: 'Auto-Convert When You Spend',
      description:
        'Spending in another currency? With account-linked cards, if any one balances is running low, we will automatically convert your other currency balances to the currency of settlement at Loop’s market leading FX rates',
      imageUrl: CardMigrationBalanceManagement,
      destination: '',
      selected: false,
    },
    {
      id: 2,
      indexForCredit: 1,
      indexForPreFunded: 6,
      label: 'New Account Linked Cards',
      description:
        'You can now create a second card type: “Account-Linked Cards”. These cards will be linked directly to your Loop accounts so you can automatically spend from your Loop Currency or Operating Accounts',
      imageUrl: CardMigrationAccountLinked,
      destination: '',
      selected: false,
    },
    {
      id: 3,
      indexForCredit: 0,
      indexForPreFunded: 0,
      label: 'More Enhancements Coming Soon',
      description:
        'With the release of Loop’s new Global VISA Card, some great new features including Apple Pay & Google Wallet, Custom Categorization Tagging, Cardholder Insurance and more are coming soon!',
      imageUrl: CardMigrationMoreEnhancementsComingSoon,
      destination: '',
      selected: false,
    },
    {
      id: 4,
      indexForCredit: 3,
      indexForPreFunded: 3,
      label: 'New Card Spending Controls ',
      description:
        'New granular spend controls so you can restrict spend based on the merchant category, currency and more',
      imageUrl: CardMigrationSmarterSpendLimits,
      destination: '',
      selected: false,
    },
    {
      id: 5,
      indexForCredit: 2,
      indexForPreFunded: 6,
      label: 'Improved Card Spending Limits',
      description:
        'More customizable card spending limits so you can more reliably control the spend amounts of each of your cards or set up card sone-time use cases',
      imageUrl: CardMigrationImprovedCardSpendingLimits,
      destination: '',
      selected: false,
    },
    {
      id: 6,
      indexForCredit: 4,
      indexForPreFunded: 4,
      label: 'Cardholder Insurance',
      description:
        'A crucial safety net for employees engaged in business activities, providing essential protection against risks associated with work-related travel and expenses.',
      imageUrl: CardMigrationCardHolderInsurance,
      destination: '',
      selected: false,
    },
  ];

  const computedRadioContent = rawRadioContent
    .sort((a, b) => {
      const condition = isCreditUser;
      return condition ? a.indexForCredit - b.indexForCredit : a.indexForPreFunded - b.indexForPreFunded;
    })
    .map((item, index) => ({
      ...item,
      selected: index === 0,
    }))
    .slice(0, 5);

  const [radioContent, setRadioContent] = useState<RadioContent[]>(computedRadioContent);

  const updateRadioSelection = (id: number) => {
    const updatedRadioContent = radioContent.map((content) => {
      return {
        ...content,
        selected: content.id === id,
      };
    });
    setRadioContent(updatedRadioContent);
  };

  const selectedContent = radioContent.find((item) => item.selected);

  return {
    isCreditUser,
    selectedContent,
    radioContent,
    updateRadioSelection,

    onPrevStep,
    onNextStep,
  };
};

export default useWhatsChanged;
