import React, { memo } from 'react';
import cx from 'classnames';

import { MFACodeInputProps } from './MFACodeInput.types';
import { useMFACodeInput } from './hooks';

import styles from './MFACodeInput.module.scss';

const MFACodeInput = ({
  code,
  totalLength = 6,
  onUpdate,
  onSubmit,
  isLoading,
  isError,
  errorMessage = 'Invalid code',
}: MFACodeInputProps) => {
  const {
    inputRef,
    displayDigits,
    error,
    focused,
    selectedIndex,
    currentCodeLength,
    handleChange,
    handleClick,
    handleKeyUp,
    handleSubmit,
    handleFocus,
    handleBlur,
  } = useMFACodeInput({ code, totalLength, onSubmit, onUpdate, isLoading, isError, errorMessage });

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <div className={styles.container}>
        <label
          id="mfaCodeInputLabel"
          className={styles.row}
          onClick={handleClick}
          aria-label={`Enter a multi factor authentication ${totalLength}-digit code`}
        >
          {displayDigits.map((v, index) => {
            const selected = currentCodeLength === index;
            const filled = currentCodeLength === totalLength && index === totalLength - 1;
            return (
              <div key={index} className={cx(styles.display, (selected || filled) && focused && styles.focused)}>
                {code[index]}
              </div>
            );
          })}

          <input
            value=""
            ref={inputRef}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            className={styles.input}
            disabled={isLoading}
            style={{
              left: `${selectedIndex * 4}rem`,
            }}
            autoComplete="off"
            tabIndex={0}
            aria-labelledby="mfaCodeInputLabel"
          />
        </label>
      </div>
      {error && !isLoading && <span className="tw-text-semantic-error tw-text-xs">{error}</span>}
    </form>
  );
};

export default memo(MFACodeInput);
