import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { ACTIVATE_WALLET_EXTERNAL_ACCOUNT } from 'graphql/wallets';
import { Progress } from 'components/UI';
import { Loaders } from 'components/cards/Loader';
import { Modals } from 'components/wallets/modals';
import Details from './Details';

const STEPS = {
  Details: [0],
  'Account Details': [1],
};

const AddCCBankAccount = (props) => {
  const { loading, ...otherProps } = props;
  const [step, setStep] = useState(0);

  if (loading) return <Loading />;

  return (
    <div>
      <Progress currentStepIndex={step} stepLabelsAndIndexes={STEPS} />
      <Steps step={step} setStep={setStep} {...otherProps} />
    </div>
  );
};

const Loading = () => (
  <div className="tw-flex tw-items-center tw-justify-center tw-p-16">
    <Loaders.Small />
  </div>
);

const Steps = (props) => {
  const { step, setStep, currency, onFinish, onAddAccount } = props;
  const [onActivateExternalAccount, { loading: isActivating }] = useMutation(ACTIVATE_WALLET_EXTERNAL_ACCOUNT);
  const [showCurrencyCloudAccountModal, setShowCurrencyCloudAccountModal] = useState(false);

  const onGoToStep1 = async () => {
    const response = await onActivateExternalAccount({ variables: { currency: currency } });
    if (!response.data?.activateWalletExternalAccount) {
      toast.error("Can't activate an external account. Please try again");
      onFinish();
      return;
    }
    setStep(1);
  };

  switch (step) {
    case 0:
      return (
        <Details
          onNextStep={onGoToStep1}
          {...props}
          showAccount={setShowCurrencyCloudAccountModal}
          loading={isActivating}
        />
      );
    case 1:
      return (
        <Modals.CurrencyCloudExternalAccount
          show={showCurrencyCloudAccountModal}
          setShow={setShowCurrencyCloudAccountModal}
          initialWalletId={{ currency: currency }}
          onFinish={onFinish}
          onAddAccount={onAddAccount}
        />
      );
  }
};

export default AddCCBankAccount;
