import React from 'react';
import cx from 'classnames';

import { ampTrackEvent } from 'amplitude';
import { SocialShareButtonProps } from './SocialShareButton.types';

import styles from './SocialShareButton.module.scss';

const SocialShareButton = ({ postUrl, type, children }: SocialShareButtonProps) => {
  const handleClick = () => {
    ampTrackEvent(`Social share button click: ${type}`);
    window.open(postUrl, '_blank');
  };

  return (
    <button onClick={handleClick} aria-label={`Share on ${type}`} className={cx(styles.button, styles[type])}>
      {children}
    </button>
  );
};

export default SocialShareButton;
