import React from 'react';

import { DetailElementProps } from './DetailElement.types';

const DetailElement = ({ value, label }: DetailElementProps) => {
  return (
    <div>
      <div className="tw-text-sm">{label}</div>
      <p className="tw-mt-1">{value ?? '-'}</p>
    </div>
  );
};

export default DetailElement;
