import CryptoJS from 'crypto-js';

import config from '../config';

export const addAccountSuffix = (accountName) => {
  return accountName.endsWith(' Account') ? accountName : `${accountName} Account`;
};

export const getId = (id) => {
  if (!id) return null;

  const data = id.match(/\d+/);

  return data && data[0];
};

export const encryptString = (string) => {
  const encJson = CryptoJS.AES.encrypt(JSON.stringify(string), config.localEncryptionKey).toString();

  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
};

export const decryptString = (string) => {
  const decData = CryptoJS.enc.Base64.parse(string).toString(CryptoJS.enc.Utf8);

  return JSON.parse(CryptoJS.AES.decrypt(decData, config.localEncryptionKey).toString(CryptoJS.enc.Utf8));
};

export const validUrl = (url) => {
  const urlPattern = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

  return !!urlPattern.test(url);
};

export const capitalize = (string = '') => string.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

export const formatCamelToNormalCase = (string) => {
  if (!string || typeof string !== 'string') return '';
  string = string.replace(/[A-Z]/g, ' $&');

  return string[0].toUpperCase() + string.slice(1);
};

export const classNames = (obj = {}) => {
  const classes = [];

  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      classes.push(key);
    }
  }

  return classes.join(' ');
};

export const transferMethod = (isInternal) => (isInternal ? 'Internal Transfer' : 'Electronic Funds Transfer (EFT)');

export const sanitizeAccountNumber = (accountNumber) => accountNumber.replace(/[\s\\t.;,?%]/g, '');

export const shortenedContactName = (name) => {
  if (name.length <= 18) {
    return name;
  } else {
    const shortName = `${name.split(' ')[0]} ${name.split(' ').pop()}`;
    if (shortName.length <= 18) {
      return shortName;
    } else {
      const initialName = `${name[0]} ${name.split(' ').pop()}`;
      return initialName.length <= 18 ? initialName : name.slice(0, 18);
    }
  }
};

export const initialsContactName = (name) => {
  if (!name) return '';

  const [firstName, lastName] = name.trim().split(' ');

  return `${firstName[0]}${lastName?.[0] || ''}`.toUpperCase();
};

export const shortenedBusinessName = (businessName) => {
  if (businessName.length <= 18) return businessName;

  return (
    businessName.split(' ').reduce((acc, word) => {
      const option = acc.concat(` ${word}`);
      if (option.length <= 18) {
        return option.trim();
      }
      return acc;
    }, '') || businessName.slice(0, 18)
  );
};

export const csvInjectionProtector = (string) => {
  if (!string) return '';

  const riskyCharacter = ['=', '+', '-', '@'];
  const firstCharacter = string.charAt(0);
  const isInjected = riskyCharacter.includes(firstCharacter);

  if (!isInjected) return string;

  const slicedString = string.slice(1);

  return csvInjectionProtector(slicedString);
};
