import React from 'react';
import { useHistory } from 'react-router-dom';
import config from 'config';

import { Banner, FormFooter, Href } from 'components/UI';
import { BannerType } from 'components/UI/Banner';
import ReportSummary from './components/ReportSummary';

const CardUpgradeSummary = () => {
  const history = useHistory();

  const navigateToCardList = () => {
    // navigate to the card-list-new page
    history.push('/dashboard/cards/list-new');
  };

  return (
    <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-flex-grow tw-items-center">
      <div className="tw-flex tw-w-2/3 tw-flex-col tw-gap-4 tw-py-6 tw-pl-8 tw-pr-4 tw-my-6">
        <p>
          <strong>Your old cards will stop working on [date].</strong> Please make sure to update your cards on file
          with any vendors prior to this date.
        </p>
        <p>For convenience, here are some useful materials to help make your migration experience seamless:</p>
        <ReportSummary />
        What's Next?
        <ul className="tw-ml-6 tw--mt-2 tw-list-disc">
          <li>Start using your new cards immediately.</li>
          <li>Update any saved payment information with your new card details.</li>
          <li>Physical cards will be mailed out shortly.</li>
        </ul>
        <Banner
          type={BannerType.secondary}
          title="Bonus Points When You Switch Now"
          message="Earn a bonus Loop reward point on all purchases made on new Loop Cards until [date]!"
          submitButton={false}
          classNames="tw-mb-0"
        />
        <p>
          If you have any questions or need assistance, please contact our{' '}
          <Href target="_blank" rel="noopener noreferrer" href={`mailto:${config.supportEmail}`}>
            <span className="tw-font-semibold tw-text-neutral-dark">{config.supportEmail}</span>
          </Href>
        </p>
      </div>
      <div className="tw-w-full tw-border-b tw-border-neutral-grey-4 tw-bg-neutral-grey-4.5 tw-bottom-0 tw-rounded-b-md tw-flex tw-justify-center">
        <FormFooter onSubmit={navigateToCardList} submitButtonLabel="View Cards" rootClass="tw-w-2/3 tw-py-6 tw-px-8" />
      </div>
    </div>
  );
};

export default CardUpgradeSummary;
