import React from 'react';
import { AttributeRowProps } from './AttributeRow.types';

const AttributeRow = ({ label, value }: AttributeRowProps) => (
  <div className="tw-flex tw-items-center tw-justify-between tw-my-2 lg:tw-my-4">
    <div className="tw-mr-8 tw-text-neutral-grey-2 tw-text-sm">{label}</div>
    <div className="tw-text-sm tw-text-right">{value}</div>
  </div>
);

export default AttributeRow;
