import React, { useContext, useCallback } from 'react';
import { ampTrackEvent } from 'amplitude';
import { BsChevronDown } from 'react-icons/bs';
import { Transition } from '@headlessui/react';

import { useToggle } from 'hooks';
import { SCOPE } from 'constants/index';
import Balance from 'components/creditCards/sidebar/Balance';
import PermissionChecker from 'components/PermissionChecker';
import Button from 'components/Button';
import { AddFundsToBalanceContext } from 'components/creditCards/context/AddFundsToBalance';
import { PreFundedExpandableBalanceProps } from './PreFundedExpandableBalance.types';

const PreFundedExpandableBalance = ({ balance, openAddFundsToBalanceModal }: PreFundedExpandableBalanceProps) => {
  const { isOpen: expanded, toggle: onToggleExpand } = useToggle();

  const { setTransaction } = useContext(AddFundsToBalanceContext);

  const { amount, currency } = balance || {};

  const handleOpenAddFundsToBalanceModal = useCallback(() => {
    setTransaction({
      originalAmount: {
        amount: 1_000_00,
        currency,
      },
    });
    openAddFundsToBalanceModal();
    ampTrackEvent('preFunded: payBalance: customAmount: open modal');
  }, [amount, currency, openAddFundsToBalanceModal]);

  return (
    <div>
      <div
        className="tw-flex tw-items-center tw-justify-between tw-py-2 tw-cursor-pointer"
        onClick={onToggleExpand}
        data-testid={`${balance.currency.toLowerCase()}-balance-expand-toggle`}
      >
        <Balance balance={balance} />
        <BsChevronDown
          className={`tw-transform tw-rotate-${
            expanded ? '180' : '0'
          } tw-ease-in-out tw-duration-200 tw-transition-all`}
        />
      </div>
      <Transition
        as="div"
        className="tw-pl-12 tw-mb-4"
        show={expanded}
        enter="tw-ease-in-out tw-duration-200 tw-transition-all"
        enterFrom="tw-transform tw-opacity-0"
        enterTo="tw-transform tw-opacity-100"
        leave="tw-ease-in-out tw-duration-200 tw-transition-all"
        leaveFrom="tw-transform tw-opacity-100"
        leaveTo="tw-transform tw-opacity-0"
      >
        <PermissionChecker scope={SCOPE.manageCards}>
          <div className="tw-flex tw-justify-end">
            <Button
              onClick={handleOpenAddFundsToBalanceModal}
              className="tw-text-sm tw-text-primary-dark-green tw-font-semibold"
            >
              Add Funds
            </Button>
          </div>
        </PermissionChecker>
      </Transition>
    </div>
  );
};

export default PreFundedExpandableBalance;
