import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Collapse } from 'react-collapse';
import { BsCaretDownFill } from 'react-icons/bs';
import _ from 'lodash';

import { SUBMIT_CRA_FORM, COMPLETE_CREDIT_APPLICATION } from '../../../graphql/creditApplication';
import Layout from '../Layout';
import { TextField } from '../../FormFields';
import styles from './CRAConnection.module.scss';
import { GET_CRA_BUSINESS_NUMBER } from '../../../graphql/user';
import { ampTrackEvent } from '../../../amplitude';
import commonStyles from './common.module.scss';
import Button from 'components/Button';

const CRAConnection = () => {
  const form = useForm();
  const { register, handleSubmit } = form;
  const history = useHistory();
  const [collapse, setCollapse] = useState([false, false, false]);
  const { data } = useQuery(GET_CRA_BUSINESS_NUMBER);
  const { craBusinessNumber, creditApplication } = _.get(data, 'me.account') || {};

  const toggleCollapse = (index) => {
    collapse[index] = !collapse[index];
    setCollapse([...collapse]);
  };

  const [submitCRAForm, { loading: isSubmitting, error }] = useMutation(SUBMIT_CRA_FORM);
  const [completeCreditApplication, { loading: isSubmittingApplication }] = useMutation(COMPLETE_CREDIT_APPLICATION);

  const step1Link = 'https://loop-public.s3.ca-central-1.amazonaws.com/cra-1.jpg';
  const step2Link = 'https://loop-public.s3.ca-central-1.amazonaws.com/cra-2.jpg';
  const step3Link = 'https://loop-public.s3.ca-central-1.amazonaws.com/cra-3.jpg';
  const craLink =
    'https://www.canada.ca/en/revenue-agency/services/e-services/e-services-businesses/business-account.html';

  const handleClickBack = () => {
    history.push('/onboarding/credit-application');
  };

  const onSubmit = async (data) => {
    let { craBusinessNumber } = data || {};
    craBusinessNumber = craBusinessNumber && craBusinessNumber.trim();
    try {
      await submitCRAForm({ variables: { craBusinessNumber } });
      await completeCreditApplication({ variables: { creditApplicationId: creditApplication.id } });
      ampTrackEvent('onboarding: connect_cra: success');
      history.push('/onboarding/credit-application/completed');
    } catch (err) {
      console.error(err);
      ampTrackEvent('onboarding: connect_cra: error');
    }
  };

  return (
    <Layout title="Connect Your CRA Account">
      <div className={commonStyles.container}>
        <h1 className="tw-mt-12">Connect your CRA Account</h1>
        <p className="tw-mb-12">
          Link Loop to Your Canada Revenue Account By Adding Us To Your CRA Profile. Follow The Steps Below To Grant
          Loop With <b>Read-Only</b> Access.
        </p>
        <div className="row">
          <div className="col">
            <div className={styles.collapseItem} onClick={() => toggleCollapse(0)}>
              <p className="m-0">
                1. Click on
                <a href={craLink} target="_blank" rel="noreferrer" className="fwb text-info">
                  <u>{' this link '}</u>
                </a>
                to log in to CRA. Once Logged in, click on <b>“Business Profile”</b>
              </p>
              <BsCaretDownFill className={styles.icon} />
            </div>
            <Collapse isOpened={collapse[0]}>
              <img className="w-100" src={step1Link} />
            </Collapse>

            <div className={styles.collapseItem} onClick={() => toggleCollapse(1)}>
              <p className="m-0">
                2. Next, click <b>“Manage Authorized Representatives“</b>
              </p>
              <BsCaretDownFill className={styles.icon} />
            </div>
            <Collapse isOpened={collapse[1]}>
              <img className="w-100" src={step2Link} />
            </Collapse>

            <div className={styles.collapseItem} onClick={() => toggleCollapse(2)}>
              <p className="m-0">
                3. Click <b>“Authorize a Representative“</b> and enter in <b>"817129778"</b> which is Loop's Business
                Number. Choose level 1 (view only access) Leave expiry date blank Select "All Accounts" Click Next
              </p>
              <BsCaretDownFill className={styles.icon} />
            </div>
            <Collapse isOpened={collapse[2]}>
              <img className="w-100" src={step3Link} />
            </Collapse>

            <div className={styles.collapseItem}>
              <p className="m-0">4. Finally Enter Your 9-Dight CRA Business Number in The Box Below</p>
              <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <TextField
                      rootClass="col-12 mb-2 mt-4"
                      name="craBusinessNumber"
                      placeholder="Your CRA Business Number"
                      ref={register({ required: true })}
                      defaultValue={craBusinessNumber}
                      data-private
                    />
                    <div className="col-12 mt-4">
                      <Button
                        tabIndex={2}
                        type="submit"
                        className="btn tw-bg-primary-dark-green tw-text-neutral-light float-right"
                        disabled={isSubmitting || isSubmittingApplication}
                      >
                        {isSubmitting || isSubmittingApplication ? 'Submitting...' : 'Continue'}
                      </Button>
                      <Button type="button" tabIndex={3} className="btn btn-outline-primary" onClick={handleClickBack}>
                        Back
                      </Button>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CRAConnection;
