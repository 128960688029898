import React from 'react';
import cx from 'classnames';

import { Currencies } from 'constants/currencies';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { CurrencyOptionProps } from './CurrencyOption.types';

const CurrencyOption = ({ value, onSelect, className }: CurrencyOptionProps) => {
  return (
    <li>
      <button
        onClick={() => onSelect(value)}
        className={cx(
          'tw-flex tw-items-center tw-text-sm md:tw-text-base tw-font-semibold tw-justify-center tw-h-10 tw-transition-all tw-transition-300 tw-py-1 tw-px-2 md:tw-px-4 tw-border tw-border-neutral-grey-4',
          className
        )}
      >
        <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-1">
          <div className="tw-rounded-full tw-bg-neutral-light tw-p-1 tw-w-5">
            <MobileCurrencyFlag currency={Currencies[value]} size={18} />
          </div>
        </div>
      </button>
    </li>
  );
};

export default CurrencyOption;
