export const GAMIFICATION_CODES = {
  FIRST_CARD_PURCHASE: 'first_card_purchase',
  FIRST_ACCOUNT_DEPOSIT: 'first_account_deposit',
  FIRST_ACCOUNT_CONVERSION: 'first_account_conversion',
};

export const GAMIFICATION_TITLES = {
  FIRST_CARD_PURCHASE: 'Make Your First Purchase',
  FIRST_ACCOUNT_DEPOSIT: 'Receive Your First Deposit',
  FIRST_ACCOUNT_CONVERSION: 'Make Your First Conversion',
};
