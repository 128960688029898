import { useState, useContext } from 'react';

import { AddPayorContext } from 'components/Invoices/contexts/AddPayorContext';

const useAddPayor = () => {
  const { error, setError } = useContext(AddPayorContext);

  const [currentStep, setCurrentStep] = useState(0);

  const onPrevStep = () => {
    setCurrentStep((prevStep) => (prevStep === 0 ? prevStep : prevStep - 1));
  };

  const onNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    setError(null);
  };

  const errorSteps = error ? [0] : [];

  return {
    currentStep,
    onPrevStep,
    onNextStep,
    errorSteps,
  };
};

export default useAddPayor;
