import React from 'react';

import { AddFundsToBalanceContextProvider } from 'components/creditCards/context/AddFundsToBalance';
import { AddFundsToBalanceModal } from 'components/creditCards/components';
import { DownloadStatements, ErrorState } from 'components/creditCards/components/CardBalances/components';
import { CurrentBalancesProps } from './CurrentBalances.types';
import { useCurrentBalances } from './hooks';
import {
  BalanceCycle,
  ContentWrapper,
  CreditApplication,
  CurrentBalanceCard,
  CurrentBalancesSkeleton,
  PreFundedInfo,
} from './components';

const CurrentBalances = ({ isPreFunded }: CurrentBalancesProps) => {
  const {
    pageTitle,
    billingCycle,
    balances,
    isOpenAddFundsToBalanceModal,
    openAddFundsToBalanceModal,
    closeAddFundsToBalanceModal,
    isLoading,
    isError,
    isAdmin,
  } = useCurrentBalances({
    isPreFunded,
  });

  if (isLoading) {
    return <CurrentBalancesSkeleton pageTitle={pageTitle} isPreFunded={isPreFunded} />;
  }

  if (isError) return <ErrorState title={pageTitle} />;

  return (
    <>
      <div className="tw-bg-neutral-light md:tw-shadow-default tw-rounded tw-py-6 tw-pb-4 tw-px-4">
        <div className="tw-flex tw-flex-nowrap tw-items-center tw-gap-6 tw-mb-4">
          <h2 className="tw-flex tw-items-center tw-gap-3">
            {pageTitle}
            {isPreFunded && isAdmin && <CreditApplication />}
          </h2>
          {!isPreFunded && billingCycle && <BalanceCycle billingCycle={billingCycle} />}
        </div>

        <AddFundsToBalanceContextProvider>
          <ContentWrapper>
            {balances.map(({ current, pending, posted, ongoingPayments }) => (
              <CurrentBalanceCard
                key={current.currency}
                current={current}
                pending={pending}
                posted={posted}
                ongoingPayments={ongoingPayments}
                isPreFunded={isPreFunded}
                isAdmin={isAdmin}
                openAddFundsToBalanceModal={openAddFundsToBalanceModal}
              />
            ))}
            {isPreFunded && isAdmin && (
              <AddFundsToBalanceModal show={isOpenAddFundsToBalanceModal} onClose={closeAddFundsToBalanceModal} />
            )}
          </ContentWrapper>

          {isPreFunded && <DownloadStatements />}
        </AddFundsToBalanceContextProvider>
      </div>

      {isPreFunded && (
        <div className="tw--mt-4">
          <PreFundedInfo />
        </div>
      )}
    </>
  );
};

export default CurrentBalances;
