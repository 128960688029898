import React from 'react';

import { Complete, IndustryCode, Intro, TransactionsVolume } from './components';
import { StepsProps } from './Steps.types';

const Steps = ({ currentStep, onNextStep, onFinish, onComplete, onSubmitNaicsAndProjections }: StepsProps) => {
  switch (currentStep) {
    case 0:
      return <Intro onNextStep={onNextStep} />;
    case 1:
      return <IndustryCode onNextStep={onNextStep} />;
    case 2:
      return <TransactionsVolume onNextStep={onNextStep} onSubmitNaicsAndProjections={onSubmitNaicsAndProjections} />;
    case 3:
      return <Complete onFinish={onFinish} onComplete={onComplete} />;
    default:
      return null;
  }
};

export default Steps;
