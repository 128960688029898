import React from 'react';
import useMultiselectOptions from './hooks/useMultiselectOptions';

const MultiselectOptions = () => {
  const { onApprove, onDecline, hasSelectedRequests } = useMultiselectOptions();
  if (!hasSelectedRequests) return null;

  return (
    <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2">
      <button
        className="tw-bg-primary-dark-green tw-text-neutral-light tw-py-0.5 tw-px-2 tw-rounded-md tw-flex tw-items-center tw-justify-center"
        onClick={onApprove}
      >
        Approve Selected
      </button>
      <button
        className="tw-bg-semantic-error tw-text-neutral-light tw-py-0.5 tw-px-2 tw-rounded-md tw-flex tw-items-center tw-justify-center"
        onClick={onDecline}
      >
        Decline Selected
      </button>
    </div>
  );
};

export default MultiselectOptions;
