import { useState, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';

import history from 'history.js';
import { AuthContext } from 'context/Auth';
import { useToggle } from 'hooks';
import { humanizeSeconds } from 'utility/date';
import {
  SESSION_EXPIRATION_THRESHOLD_IN_SEC,
  SESSION_PRE_EXPIRATION_THRESHOLD_IN_SEC,
  SESSION_RECHECK_INTERVAL,
  SESSION_EXPIRATION_TITLE,
} from '../constants';

const useSessionExpirationModal = () => {
  const { isSignedIn, refreshSessionTokens } = useContext(AuthContext) as unknown as {
    isSignedIn?: boolean;
    refreshSessionTokens?: () => Promise<void>;
  };

  const [secondsToExpire, setSecondsToExpire] = useState(SESSION_PRE_EXPIRATION_THRESHOLD_IN_SEC);

  const sessionCheckingIntervalId = useRef<NodeJS.Timer | null>(null);

  const { isOpen: show, open: showModal, close: closeModal } = useToggle();

  const formattedDuration = useMemo(() => {
    if (!show) return '';

    return humanizeSeconds(secondsToExpire);
  }, [secondsToExpire, show]);

  const handleRefreshSessionTokens = useCallback(async () => {
    await refreshSessionTokens?.();
    closeModal();
  }, [refreshSessionTokens, closeModal]);

  const handleCloseSession = useCallback(async () => {
    closeModal();

    if (sessionCheckingIntervalId.current) clearInterval(sessionCheckingIntervalId.current);

    history.push('/signout');
    toast.error('Your session has expired, please sign in again.');
  }, [closeModal]);

  const checkSessionTokenExpiration = useCallback(async () => {
    try {
      const session = await Auth.currentSession();

      const accessTokenExpirationInSeconds =
        session.getAccessToken().getExpiration() - SESSION_EXPIRATION_THRESHOLD_IN_SEC;
      const currentTimestampInSeconds = Math.floor(new Date().getTime() / 1000);

      const sessionExpiredIn = accessTokenExpirationInSeconds - currentTimestampInSeconds;

      if (accessTokenExpirationInSeconds <= currentTimestampInSeconds + SESSION_PRE_EXPIRATION_THRESHOLD_IN_SEC) {
        showModal();
        setSecondsToExpire(sessionExpiredIn);

        if (sessionExpiredIn <= 0) handleCloseSession();
      } else {
        closeModal();
      }
    } catch (error) {
      console.warn('Can not calculate session expiration', error);
      handleCloseSession();
    }
  }, [showModal, closeModal, setSecondsToExpire, handleCloseSession]);

  useEffect(() => {
    if (isSignedIn) {
      sessionCheckingIntervalId.current = setInterval(checkSessionTokenExpiration, SESSION_RECHECK_INTERVAL);
    }

    return () => {
      if (sessionCheckingIntervalId.current) clearInterval(sessionCheckingIntervalId.current);
    };
  }, [isSignedIn]);

  useEffect(() => {
    if (show) {
      document.title = `${SESSION_EXPIRATION_TITLE}${document.title}`;
    } else {
      document.title = document.title.replace(SESSION_EXPIRATION_TITLE, '');
    }
  }, [show]);

  return { formattedDuration, show, handleRefreshSessionTokens, handleCloseSession };
};

export default useSessionExpirationModal;
