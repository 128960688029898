import React from 'react';

import Button, { ButtonSize } from 'components/Button';
import Loader from 'components/cards/Loader';
import { CreditCard, CreditCardLimitSettings } from 'types/creditCard';
import { formatMoneyV2 } from 'utility/currency';
import LimitSettingsModal from './components/LimitSettingsModal';
import { Currencies } from 'constants/currencies';
import LimitSettingsItem from './components/LimitSettingsItem';
import { useToggle } from 'hooks';
import useIsAdmin from 'hooks/useIsAdmin';

const CardLimitSettingsContent = ({
  creditCard,
  creditCardLimitSettings,
  setCreditCardLimitSettings,
  loading,
}: {
  creditCard: CreditCard;
  creditCardLimitSettings?: CreditCardLimitSettings | null;
  setCreditCardLimitSettings: (settings: CreditCardLimitSettings) => void;
  loading: boolean;
}) => {
  const { isOpen: showModal, toggle: toggleModal } = useToggle();

  if (!creditCardLimitSettings) return null;
  if (loading) return <Loader />;

  const getCreditCardLimitSettingsValue = (limit: number) => {
    return limit !== 0 ? `${formatMoneyV2({ amount: limit, currency: Currencies.CAD })} CAD` : 'N/A';
  };
  const { isAdmin } = useIsAdmin();
  const { expiresMonthly, expiresAnnually } = creditCardLimitSettings;
  const limitSettingsText =
    (expiresMonthly && '1st of next month') || (expiresAnnually && '1st of next year') || 'Never';

  return (
    <>
      <LimitSettingsItem
        label="Amount of Limit Used"
        value={getCreditCardLimitSettingsValue(creditCardLimitSettings.currentExpenseCents)}
      />
      <LimitSettingsItem
        label="Freeze after"
        value={getCreditCardLimitSettingsValue(creditCardLimitSettings.cardLimitCents)}
      />
      <LimitSettingsItem label="Resets" value={limitSettingsText} />
      {isAdmin && (
        <div className="tw-text-center">
          <Button primary onClick={toggleModal} size={ButtonSize.sm}>
            Change Freeze Rules
          </Button>
        </div>
      )}
      <LimitSettingsModal
        show={showModal}
        toggleModal={toggleModal}
        creditCard={creditCard}
        creditCardLimitSettings={creditCardLimitSettings}
        setCreditCardLimitSettings={setCreditCardLimitSettings}
      />
    </>
  );
};

export default CardLimitSettingsContent;
