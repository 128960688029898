import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDropzone } from 'react-dropzone';

import { documentCategories } from 'constants/index';
import { uploadFile } from 'utility/activeStorage';
import { ADD_DOCUMENT } from 'graphql/document';
import LoaderSpinner from 'components/cards/LoaderSpinner';
import Modal from 'components/Modal/v2';
import DropZone from 'components/uploader/Dropzone';
import Close from 'components/svg/Close';
import Add from 'components/svg/Add';
import DocumentAttachOutline from 'components/svg/DocumentAttachOutline';
import Button from 'components/Button';

const DocumentUploader = (props) => {
  const { reload, onUploadComplete, categoryValue, isSelectCategoryDisabled } = props;
  const [addDocument] = useMutation(ADD_DOCUMENT);
  const [documentType, setDocumentType] = useState(categoryValue || 0);
  const [showDropzone, setShowDropzone] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [hasDroppedEmptyFile, setHasDroppedEmptyFile] = useState(false);

  const handleClickUpload = async () => {
    if (!documentType || files.length === 0) return;
    files.forEach((file) => {
      file.documentType = documentType;
    });
    await startFilesUpload();
    setFiles([]);
  };
  const updateUploadProgress = ({ total, loaded, file }) => {
    const progress = (loaded * 100) / total;
    setFiles((files) =>
      files.map((f) => {
        if (f.name === file.name) {
          f.total = total;
          f.progress = progress;
          f.loaded = loaded;
        }
        return f;
      })
    );
  };

  const startFileUpload = async (file) => {
    try {
      const blob = await uploadFile(file, updateUploadProgress);
      await addDocument({ variables: { blobSignedId: blob.signed_id, category: file.documentType } });
    } catch (err) {
      console.error(err);
    }
  };

  const startFilesUpload = async () => {
    setLoading(true);
    for (let i = 0; i < files.length; i++) {
      await startFileUpload(files[i]);
    }
    reload && reload();
    setDocumentType(0);
    onUploadComplete && onUploadComplete();
  };

  const onDrop = (newFiles) => {
    if (newFiles.every((file) => file.size > 0)) {
      setFiles((f) => [...f, ...newFiles]);
      setHasDroppedEmptyFile(false);
      setShowDropzone(false);
    } else {
      setHasDroppedEmptyFile(true);
    }
  };

  const handleDocumentTypeChange = (e) => {
    setDocumentType(e.target.value);
  };

  const FileLine = (file) => {
    return (
      <div className="tw-flex tw-flex-row tw-justify-between">
        <div className="tw-flex tw-flex-row tw-justify-start tw-ml-2 tw-my-2">
          <div className="tw-mr-4">
            <DocumentAttachOutline />
          </div>
          <div className="tw-text-primary-dark-green">{file.file.name}</div>
        </div>
      </div>
    );
  };

  const FileList = () => {
    if (!files || !files.length) return null;
    const validFiles = files.filter((file) => !file.removed);
    return validFiles.map((file, i) => <FileLine key={i} file={file} />);
  };

  const toggleDropzone = () => {
    setShowDropzone(!showDropzone);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div className="form-group">
        <div className="tw-my-2">
          <div className="tw-mb-2" htmlFor="documentType">
            Document Name
          </div>
          <select
            id="documentType"
            className="tw-block tw-px-3 tw-py-2 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-text-neutral-black tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-ring-0 focus:tw-outline-none focus:tw-shadow-input"
            value={documentType}
            onChange={handleDocumentTypeChange}
            disabled={isSelectCategoryDisabled}
          >
            <option value={0} disabled>
              Choose an option
            </option>
            {documentCategories.map((c) => (
              <option key={c.value} value={c.value}>
                {c.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="tw-my-5">
        <div className="tw-mb-2">Files</div>
        <div>
          <FileList />
        </div>
      </div>
      <div className="tw-my-2">
        <div hidden={!showDropzone}>
          <DropZone
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            hasDroppedEmptyFile={hasDroppedEmptyFile}
            isDragActive={isDragActive}
          />
        </div>
        {!isLoading && (
          <div hidden={showDropzone} className="tw-text-neutral-grey-1 tw-py-2">
            <Button onClick={() => toggleDropzone()}>
              <div className="tw-flex tw-flex-row tw-justify-start">
                <Add />
                <div className="tw-ml-2">Add next file</div>
              </div>
            </Button>
          </div>
        )}
      </div>
      <div className="tw-my-2 tw-flex tw-flex-row tw-justify-end">
        <Button
          primary
          type="button"
          className="tw-w-40 tw-px-6 tw-mt-4"
          disabled={!documentType || files.length === 0 || isLoading}
          onClick={handleClickUpload}
        >
          {isLoading ? <LoaderSpinner /> : <span>Upload</span>}
        </Button>
      </div>
    </div>
  );
};

const UploadModal = (props) => {
  const { show, reload, onClose, categoryValue, onSuccessfullyUpload, isSelectCategoryDisabled } = props;
  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-flex-col tw-justify-center">
        <div className="tw-flex tw-justify-between tw-px-8 tw-py-5">
          <div>Upload new document</div>
          <Close onClick={() => onClose()} />
        </div>
        <hr className="tw-bg-neutral-grey-4" />
        <div className="tw-px-8 tw-py-5">
          <DocumentUploader
            reload={reload}
            onUploadComplete={onSuccessfullyUpload}
            categoryValue={categoryValue}
            isSelectCategoryDisabled={isSelectCategoryDisabled}
          />
        </div>
      </div>
    </Modal>
  );
};

const Modals = {
  Upload: UploadModal,
};

export default Modals;
