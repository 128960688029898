import { useMemo, useContext } from 'react';
import { get } from 'lodash';

import { AuthContext } from 'context/Auth';
import { CreditCard } from 'types/creditCard';
import { User } from 'types/user';
import { useIsMobile } from 'hooks';
import { CardsListTableProps } from '../CardsListTable.types';
import { DEFAULT_CARDHOLDER_ID } from '../constants';

const useCardsListTable = ({ creditCards }: Pick<CardsListTableProps, 'creditCards'>) => {
  const { me } = useContext(AuthContext) as unknown as { me?: User };

  const currentUserId = get(me, 'internalContact.id');

  const isMobile = useIsMobile();

  const creditCardsByCardholderId = useMemo(() => {
    const creditCardsByCardholderId = creditCards.reduce(
      (acc, creditCard) => {
        // assuming that the first contact is the cardholder
        const cardholder = creditCard?.contacts?.[0];

        if (!cardholder) {
          acc[DEFAULT_CARDHOLDER_ID].creditCards = [...acc[DEFAULT_CARDHOLDER_ID].creditCards, creditCard];
          return acc;
        }

        const cardholderId = cardholder.id;

        acc[cardholderId] = {
          creditCards: [...(acc[cardholderId]?.creditCards || []), creditCard],
          cardholderName: `${cardholder.firstName} ${cardholder.lastName}`,
          isCurrentUser: cardholderId === currentUserId,
        };
        return acc;
      },
      {
        [DEFAULT_CARDHOLDER_ID]: {
          creditCards: [],
          cardholderName: 'Other',
        },
      } as {
        [cardholderId: string]: {
          creditCards: CreditCard[];
          cardholderName: string;
          isCurrentUser?: boolean;
        };
      }
    );

    return Object.entries(creditCardsByCardholderId);
  }, [creditCards]);

  const sortedCreditCardsByCardholderId = useMemo(() => {
    return creditCardsByCardholderId.sort(([cardholderIdA, dataA], [cardholderIdB, dataB]) => {
      if (cardholderIdA === DEFAULT_CARDHOLDER_ID) return 1;
      if (cardholderIdB === DEFAULT_CARDHOLDER_ID) return -1;

      const { isCurrentUser: isCurrentUserA, cardholderName: cardholderNameA } = dataA;
      const { isCurrentUser: isCurrentUserB, cardholderName: cardholderNameB } = dataB;

      if (isCurrentUserA) return -1;
      if (isCurrentUserB) return 1;

      return cardholderNameA.localeCompare(cardholderNameB);
    });
  }, [creditCardsByCardholderId]);

  return {
    creditCardsByCardholderId: sortedCreditCardsByCardholderId,
    isMobile,
  };
};

export default useCardsListTable;
