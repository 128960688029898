import React from 'react';

import { formatTransactionName, mapTransactionsByDate, pendingAccountDepositTransactions } from 'utility/transactions';
import { formatTransactionTableAmount } from 'utility/currency';

const PendingAccountDepositsList = ({ transactions, accountNames, loadingTransactions }) => {
  if (loadingTransactions) return <ListSkeleton />;
  if (!transactions) return null;

  const pendingTransactions = pendingAccountDepositTransactions({ transactions, accountNames });
  if (pendingTransactions.length === 0) return null;

  return (
    <div className="tw-px-4">
      <TransactionsByDate
        transactionsByDate={mapTransactionsByDate(pendingTransactions, 'expectedCompletionDate')}
        accountNames={accountNames}
      />
    </div>
  );
};

const TransactionsByDate = ({ transactionsByDate, accountNames }) => {
  return (
    <div className="tw-space-y-4">
      <small className="tw-text-neutral-grey-1">Pending Deposits</small>
      <div className="tw-p-4 tw-rounded-md tw-bg-neutral-light">
        {Object.keys(transactionsByDate).map((date) => (
          <TransactionsForDate
            key={date}
            transactions={transactionsByDate[date]}
            date={date}
            accountNames={accountNames}
          />
        ))}
      </div>
    </div>
  );
};

const TransactionsForDate = ({ date, transactions, accountNames }) => (
  <div key={date} className="tw-w-full tw-flex-grow tw-mb-4 tw-space-y-2">
    {transactions.map((transaction) => (
      <TransactionEntry key={transaction.transactionId} transaction={transaction} accountNames={accountNames} />
    ))}
  </div>
);

const TransactionEntry = ({ transaction, accountNames }) => {
  const accountName = accountNames.find((name) => name === transaction.to);
  const formattedAmount = formatTransactionTableAmount({ transaction, accountName });

  return (
    <div className="tw-flex tw-items-start tw-space-between tw-flex-grow">
      <div className="tw-flex tw-items-start">
        <div>
          <div className="tw-flex">
            <small className="tw-text-neutral-grey-2 tw-mr-1">To</small>
            <small>{formatTransactionName(transaction.to)}</small>
          </div>
          <div className="tw-flex">
            <small className="tw-text-neutral-grey-2 tw-mr-1">from</small>
            <small>{formatTransactionName(transaction.from)}</small>
          </div>
        </div>
      </div>
      <div className="tw-flex-grow tw-flex tw-justify-end tw-items-center tw-space-x-4">
        <small className="tw-font-semibold tw-text-semantic-success">{formattedAmount}</small>
      </div>
    </div>
  );
};

const ListSkeleton = () => (
  <div
    data-testid="list-skeleton"
    className="tw-flex tw-flex-col tw-space-y-4 tw-p-4 tw-w-full tw-rounded-md tw-bg-neutral-light"
  >
    <div className="tw-flex tw-flex-col tw-space-y-2">
      <div className="tw-rounded-md tw-animate-pulse tw-bg-neutral-grey-4 tw-h-4 tw-w-52" />
      {Array(3)
        .fill()
        .map((_, i) => (
          <div key={i} className="tw-w-full tw-rounded-md tw-animate-pulse tw-bg-neutral-grey-4 tw-h-8" />
        ))}
    </div>
  </div>
);

export default PendingAccountDepositsList;
