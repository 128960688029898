import React from 'react';

const Styles = React.lazy(() => import('./styles.js'));

export const Theme = ({ children, className = '' }) => {
  return (
    <>
      <React.Suspense fallback={<></>}>
        {
          <>
            <Styles />
            <div className={`tailwind-container ${className}`}>{children}</div>
          </>
        }
      </React.Suspense>
    </>
  );
};
