import React, { useContext } from 'react';
import cx from 'classnames';
import { IoWalletOutline } from 'react-icons/io5';

import { ThemeContext } from 'context/ThemeContext';
import { WalletTypeLabelProps } from './WalletTypeLabel.types';
import Bank from 'components/svg/Bank';

const WalletTypeLabel = ({ activeExternalAccount, isClicked }: WalletTypeLabelProps) => {
  const { isDarkTheme } = useContext(ThemeContext);

  const blueLabelColor = isDarkTheme ? 'tw-text-natural-light' : 'tw-text-primary-blue';

  return (
    <div
      className={cx(
        'tw-min-w-max tw-flex tw-items-center tw-rounded-md tw-px-2 tw-absolute tw-right-4 tw--top-3',
        isClicked ? 'tw-border' : '',
        activeExternalAccount === true
          ? 'tw-bg-secondary-light-blue tw-border-primary-blue'
          : 'tw-bg-primary-light-green tw-border-semantic-success'
      )}
    >
      <div className={cx('tw-mr-1', activeExternalAccount === true ? blueLabelColor : 'tw-text-semantic-success')}>
        {activeExternalAccount === true ? <Bank width={14} /> : <IoWalletOutline size={18} />}
      </div>
      <p className={cx(activeExternalAccount === true ? blueLabelColor : 'tw-text-semantic-success')}>
        {activeExternalAccount === true ? 'Operating Account' : 'Currency Account'}
      </p>
    </div>
  );
};

export default WalletTypeLabel;
