import React from 'react';

import { EmptyStateProps } from './EmptyState.types';
import { useEmptyState } from './hooks';
import CashAndStars from 'components/svg/CashAndStars';
import { Banner } from 'components/UI';

const EmptyState = ({ selectedWalletCurrency }: EmptyStateProps) => {
  const { isEuroOrGbp, navigateToOpenAccountModal } = useEmptyState({ selectedWalletCurrency });

  return (
    <Banner
      title={`Open a Free Local ${selectedWalletCurrency} Account`}
      message="Get local banking details so you can send and receive payments like a local. Use account details to get
        paid from your customers, payment processors or marketplaces like Amazon, Shopify, Stripe and PayPal."
      submitButtonLabel={isEuroOrGbp ? 'Upgrade Your Account' : 'Open Account'}
      icon={<CashAndStars width="70" height="67" />}
      iconAlignment="center"
      onSubmit={navigateToOpenAccountModal}
      classNames="tw-mb-0 tw-mt-8"
    />
  );
};

export default EmptyState;
