import React from 'react';
import cx from 'classnames';

import { Theme } from 'styles/tailwind';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { Header, Footer, SideNav, TabNav } from './components';
import { LayoutProps } from './Layout.types';
import { useIsMobile } from 'hooks';

import styles from './Layout.module.scss';

const Layout = ({
  title,
  topDropdownNavigation,
  sideNavItems,
  bottomSideNavItems,
  tabNavItems,
  children,
  classNames,
}: LayoutProps) => {
  if (title) useSetPageTitle(title);

  const isMobile = useIsMobile();

  return (
    <Theme>
      <Header dropDownNavigation={topDropdownNavigation} />
      <div
        className={cx(
          'tw-flex tw-flex-grow tw-bg-neutral-grey-3.5 lg:tw-bg-neutral-light',
          isMobile ? styles.layoutMobile : styles.layout,
          classNames
        )}
      >
        {/* Desktop navigation */}
        {!!sideNavItems?.length && <SideNav sidebarItems={sideNavItems} bottomSideNavItems={bottomSideNavItems} />}

        <div className={cx('tw-flex tw-flex-grow tw-flex-col tw-p-4 lg:tw-p-12', styles.layout__content)}>
          <div className="tw-flex-grow">{children}</div>
          <Footer />

          {/* Mobile navigation */}
          {!!tabNavItems?.length && <TabNav tabs={tabNavItems} />}
        </div>
      </div>
    </Theme>
  );
};

export default Layout;
