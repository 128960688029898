import React from 'react';
import { FormProvider } from 'react-hook-form';

import { formatMoneyV2 } from 'utility/currency';
import { Select, MoneyInputField, DatePicker, TextField, RadioField } from 'components/FormFields/v2';
import { InfoTooltipForModals } from 'components/InfoTooltip';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { StepsProps } from '../../Steps.types';
import { FormFooter } from '..';
import { useDetails } from './hooks';
import { Currencies } from 'constants/currencies';
import { MAX_NUMBER_OF_PAYMENTS } from 'components/Invoices/constants';
import { getDueDateNote } from 'components/Invoices/Invoices.utils';

const Details = ({ onNextStep, onFinish }: Pick<StepsProps, 'onNextStep' | 'onFinish'>) => {
  const {
    form,
    handleOnNextStep,
    register,
    handleSubmit,
    error,
    payorsOptions,
    walletsOptions,
    currency,
    formattedAmount,
    minDueDate,
    maxDueDate,
    minEndDate,
    maxEndDate,
    setFormattedAmount,
    validateAmountValue,
    recurringTypeOptions,
    ongoingFrequencyOptions,
    recurringStopOptions,
    isOngoingFrequencyShown,
    isRecurringEndDate,
    isRecurringNumberOfOccurrences,
  } = useDetails({
    onNextStep,
  });

  const dueDateLabel = isOngoingFrequencyShown ? 'Start Date' : 'Payment Due Date';
  const dueDateNote = getDueDateNote(dueDateLabel);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleOnNextStep)}>
        <div className="tw-px-8 tw-py-8 tw-space-y-8 tw-mb-8">
          <div className="tw-text-sm tw-text-neutral-grey-2">
            Select the Payor and Deposit Account. The Payor and Deposit Account must have matching currencies.
          </div>
          <Select
            name="payorId"
            label="Payor"
            placeholder="Select Payor"
            ref={register({ required: true })}
            options={payorsOptions}
            required
          />
          <div>
            <label htmlFor="accountId" className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
              Deposit Account
              <span className="tw-text-semantic-error">&nbsp;*</span>
            </label>
            <Select
              name="accountId"
              placeholder="Choose Account"
              ref={register({ required: true })}
              options={walletsOptions}
              required
            />
          </div>

          <div className="tw-flex tw-items-start">
            <MoneyInputField
              name="amount"
              label="Amount"
              required
              currency={currency}
              value={formattedAmount}
              moneyFormatter={formatMoneyV2}
              setValue={setFormattedAmount}
              rootClass="tw-flex-grow"
              ref={register({
                required: true,
                validate: validateAmountValue,
              })}
              data-testid="amount-input"
            />
            <div className="tw-flex tw-items-center tw-h-11 tw-bg-neutral-grey-4 tw-rounded-md tw-p-4 tw-mt-7 tw-flex-grow-0 tw-ml-4">
              <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5 tw-mr-2">
                <MobileCurrencyFlag currency={Currencies[currency]} size={16} />
              </div>
              <p className="tw-text-neutral-grey-2">{currency}</p>
            </div>
          </div>

          <Select
            name="recurringType"
            label="How Often"
            ref={register({ required: true })}
            options={recurringTypeOptions}
            required
          />

          <div>
            <DatePicker
              name="dueDate"
              label={dueDateLabel}
              placeholder={`Select ${dueDateLabel}`}
              minDate={minDueDate}
              maxDate={maxDueDate}
              required
              ref={register({ required: true })}
            />
            <div className="tw-text-xs tw-text-neutral-grey-1 tw-mt-4">{dueDateNote}</div>
          </div>

          {isOngoingFrequencyShown && (
            <>
              <Select
                name="frequency"
                label="Frequency"
                placeholder="Select Frequency"
                ref={register({ required: true })}
                options={ongoingFrequencyOptions}
                required
              />

              <div>
                <label
                  htmlFor="recurringStopType"
                  className="tw-flex tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer tw-mb-3"
                >
                  <span>Payment Will End Based on</span>
                  <span className="tw-text-semantic-error tw-pr-2">&nbsp;*</span>
                </label>
                <RadioField
                  name="recurringStopType"
                  options={recurringStopOptions}
                  ref={register({ required: true })}
                  hideIcons
                  size="sm"
                />
              </div>
              {isRecurringEndDate && (
                <DatePicker
                  name="endDate"
                  label="End Date"
                  placeholder="Select End Date"
                  minDate={minEndDate}
                  maxDate={maxEndDate}
                  required
                  ref={register({ required: true })}
                />
              )}
              {isRecurringNumberOfOccurrences && (
                <TextField
                  name="numberOfOccurrences"
                  label="Number of Payments"
                  placeholder={`From 2 to ${MAX_NUMBER_OF_PAYMENTS}`}
                  type="number"
                  min={2}
                  max={MAX_NUMBER_OF_PAYMENTS}
                  required
                  ref={register({ required: true, min: 2, max: MAX_NUMBER_OF_PAYMENTS })}
                />
              )}
            </>
          )}

          <div>
            <label htmlFor="invoiceNumber" className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
              Invoice Number
              <InfoTooltipForModals message="The Invoice Number will serve as a reference for your Payor." onlyIcon />
            </label>
            <TextField name="invoiceNumber" ref={register()} />
          </div>

          {error?.message && <div className="tw-text-semantic-error tw-text-xs">{error.message}</div>}
        </div>
        <FormFooter onCancel={onFinish} cancelButtonLabel="Cancel" submitButtonLabel="Review" />
      </form>
    </FormProvider>
  );
};

export default Details;
