import React from 'react';
import { Dialog, Transition } from '@headlessui/react';

const TransactionalModal = ({ show, children }) => {
  return (
    <Transition.Root show={show}>
      {show && (
        <Dialog
          className="tw-fixed tw-z-10 tw-overflow-y-scroll tw-inset-0 tailwind-container"
          open={show}
          static
          onClose={() => null}
        >
          <div className="tw-flex tw-items-center tw-justify-center">
            <Transition.Child
              className="tw-flex tw-flex-col tw-min-h-screen tw-w-full tw-items-center tw-justify-center lg:tw-py-12"
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
              enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leave="tw-ease-in tw-duration-800"
              leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
              leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            >
              <div
                className="tw-fixed tw-inset-0 tw-bg-neutral-dark tw-bg-opacity-20 tw-transition-opacity"
                aria-hidden="true"
              />
              <div
                className={`tw-w-full lg:tw-w-1/3 tw-min-w-min tw-flex-grow lg:tw-flex-grow-0 tw-rounded-md tw-bg-neutral-light tw-transform tw-transition-all`}
              >
                {children}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      )}
    </Transition.Root>
  );
};

export default TransactionalModal;
