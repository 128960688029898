export const AGREEMENT_SECTIONS = [
  {
    title: 'Introduction',
    content:
      'This Electronic Authorization Agreement ("Agreement") governs the Automated Clearing House ("ACH") transactions initiated by Loop for the purpose of transferring funds from the Originating Account to the Receiving Account.',
  },
  {
    title: 'Authorization',
    content:
      'By agreeing electronically, the Customer authorizes Loop to initiate single or recurring ACH debit and/or credit entries to the bank account indicated by the Customer from the Originating Account, and for the bank to debit or credit the same to such account. This authorization includes any adjustments for errors, reversals, or refunds.',
  },
  {
    title: 'Transaction Information',
    content:
      'Amount of Transactions: Will be as directed by the Customer by providing electronic authorization (an “Authorization”) Frequency of Transactions: As initiated by the customer pursuant to an Authorization Transaction Purpose: Transferring, consolidating or repositioning funds between one bank account to the Receiving Account, for the same Business or closely-affiliated Businesses (e.g. transfers between a parent company and its subsidiary)',
  },
  {
    title: 'Revocation of Authorization',
    content:
      'The Customer may revoke this authorization at any time by notifying Loop in writing, with the understanding that it takes up to 30 days to process the revocation. The revocation does not affect any transactions processed before Loop has a reasonable opportunity to act on it.',
  },
  {
    title: 'Notification of Changes',
    content:
      'Any changes to the terms of this Agreement or individual transactions will be communicated to the Customer in accordance with NACHA rules.',
  },
  {
    title: 'Dispute Resolution',
    content:
      'In case of errors or questions about the transactions, the Customer should contact Loop as soon as possible.',
  },
  {
    title: 'Compliance with NACHA Rules',
    content: 'Both the Customer and Loop agree to adhere to the NACHA Operating Rules in executing ACH transactions.',
  },
  {
    title: 'Fees and Charges',
    content:
      'Any applicable fees or charges for ACH transactions will be disclosed by Loop in the applicable account holder agreements.',
  },
];
