import React from 'react';

import { Progress } from 'components/UI';
import { useAddPayor } from './hooks';
import { Steps } from './components';
import { addPayorSteps } from './constants';

const AddPayor = ({ onFinish }: { onFinish: () => void }) => {
  const { currentStep, onPrevStep, onNextStep, errorSteps } = useAddPayor();

  return (
    <>
      <Progress currentStepIndex={currentStep} stepLabelsAndIndexes={addPayorSteps} errorSteps={errorSteps} />
      <Steps currentStep={currentStep} onPrevStep={onPrevStep} onNextStep={onNextStep} onFinish={onFinish} />
    </>
  );
};

export default AddPayor;
