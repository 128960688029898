import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_CREDIT_CARDS_LIST } from 'graphql/cards';
import { CardProcessor, CreditCard } from 'types/creditCard';

const useGetCards = (): { cards: CreditCard[]; loading: boolean } => {
  const { data: creditCardData, loading } = useQuery(GET_CREDIT_CARDS_LIST, {
    nextFetchPolicy: 'network-only',
  });

  const allCreditCards: CreditCard[] = get(creditCardData, 'creditCards') || [];
  const cartaCardsForMigration = allCreditCards.filter(
    (card: CreditCard) => card.cardProcessor === CardProcessor.carta
  );

  return {
    cards: cartaCardsForMigration,
    loading,
  };
};

export default useGetCards;
