import React, { memo } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { SlArrowDown } from 'react-icons/sl';
import cx from 'classnames';

import { CollapseProps } from './Collapse.types';

const Collapse = ({ children, label, LabelIcon, subLabel, defaultOpen, unmount }: CollapseProps) => {
  return (
    <div className="tw-rounded-md tw-bg-neutral-grey-4.5 tw-px-3 lg:tw-px-4">
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <Disclosure.Button className="tw-flex tw-justify-between tw-items-center tw-w-full tw-py-4">
              <div className="tw-flex tw-items-center">
                {LabelIcon && <LabelIcon size={24} className="tw-mr-2" />}
                <div>
                  <span className="tw-font-bold">{label}</span>
                  {subLabel && <span className="tw-text-sm tw-text-neutral-grey-1 tw-ml-2">{subLabel}</span>}
                </div>
              </div>
              <SlArrowDown
                size={18}
                className={cx(
                  'tw-transition tw-duration-100 tw-ease-out tw-transform tw-ml-2',
                  open && 'tw-rotate-180'
                )}
              />
            </Disclosure.Button>
            <Transition
              show={open}
              enter="tw-transition tw-duration-100 tw-ease-out"
              enterFrom="tw-transform tw-scale-98 tw-opacity-0"
              enterTo="tw-transform tw-scale-100 tw-opacity-100"
              leave="tw-transition tw-duration-95 tw-ease-out"
              leaveFrom="tw-transform tw-scale-100 tw-opacity-100"
              leaveTo="tw-transform tw-scale-98 tw-opacity-0"
              unmount={unmount}
            >
              <Disclosure.Panel unmount={unmount} className="tw-pb-4 lg:tw-pb-6">
                {children}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default memo(Collapse);
