import React, { useState } from 'react';

import { AddCreditCardDocument, PayeeType } from '..';
import { AddCreditCard } from './components';

const CreditCardSteps = ({ step, handlePrevStep, handleNextStep, onFinish }) => {
  const [error, setError] = useState(null);

  switch (step) {
    case 2:
      return <AddCreditCardDocument setError={setError} onFinish={onFinish} onPreviousStep={handlePrevStep} />;
    case 1:
      return (
        <AddCreditCard error={error} setError={setError} onNextStep={handleNextStep} onPreviousStep={handlePrevStep} />
      );
    default:
      return <PayeeType onNextStep={handleNextStep} />;
  }
};

export default CreditCardSteps;
