import React from 'react';
import PointsTransactions from 'components/PointsTransactions/components/PointsTransactions';

import './Rewards.css';

export const History = () => {
  return (
    <div className="tw-flex tw-flex-col">
      <h4 className="tw-mt-16">Points Transaction History</h4>
      <PointsTransactions />
    </div>
  );
};
