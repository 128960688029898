import React, { useState, useContext, useEffect } from 'react';
import { IoMailOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

import { SettingsContext } from 'context/Settings';
import { USER_ROLE_TYPES, userRoles } from 'constants/index';
import InitialsCircle from 'components/InitialsCircle';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import DeactivateMember from './DeactivateMember';
import { availableRoleOptions } from './roleOptions';
import { AuthContext } from 'context/Auth';
import { CHANGE_CONTACT_ROLE } from 'graphql/team';
import Button from 'components/Button';
import SetUserPermissionsForm from './SetUserPermissionsForm';
import UserDetails from './UserDetails/UserDetails';
import UserPermissions from './UserPermissions';

const MemberDetail = ({ header, member, onClose, setMember, allMembers }) => {
  return (
    <div className="tw-bg-neutral-grey-3.5 lg:tw-bg-neutral-light tw-p-4">
      {header}
      {member && <MemberDetails member={member} onClose={onClose} setMember={setMember} allMembers={allMembers} />}
    </div>
  );
};

const MemberDetails = ({ member, onClose, setMember, allMembers }) => {
  const [showModal, setShowModal] = useState(false);
  const [isModifyingPermissions, setIsModifyingPermissions] = useState(false);
  const { refetchMembersRef } = useContext(SettingsContext);
  const [selectedOption, setSelectedOption] = useState(member.roleValue);

  useEffect(() => {
    setSelectedOption(member.roleValue);
  }, [member.roleValue]);

  const [changeRole, { loading }] = useMutation(CHANGE_CONTACT_ROLE);
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleRoleChange = async () => {
    try {
      const result = await changeRole({
        variables: { contactId: member.id, role: selectedOption },
      });
      if (!result.data?.changeContactRole) throw new Error('Error changing contact role. Please try again later.');
      onClose();
      refetchMembersRef.current && (await refetchMembersRef.current());
      toast.success('Contact role changed successfully.');
    } catch (err) {
      toast.error(err.message);
    }
  };
  const onSuccess = () => {
    refetchMembersRef.current && refetchMembersRef.current();
    setShowModal(false);
    onClose();
  };
  const onDeactivateMember = () => setShowModal(true);
  const onShowPermissions = () => setIsModifyingPermissions(true);
  const isInActive = member.status === 'inactive';
  const isActive = member.status === 'active';
  const { me } = useContext(AuthContext);
  const isAccountOwner =
    me?.internalContact?.role === USER_ROLE_TYPES.accountOwner || me?.roles?.includes(userRoles.ACCOUNT_OWNER);
  const canPerformDeactivateOrRoleChange =
    (member.roleValue === USER_ROLE_TYPES.admin && isAccountOwner) ||
    member.roleValue === USER_ROLE_TYPES.member ||
    member.roleValue === USER_ROLE_TYPES.readOnly ||
    member.roleValue === USER_ROLE_TYPES.bookkeeper;

  return (
    <div className="tw-flex tw-flex-col tw-space-y-16">
      <div className="tw-flex tw-flex-col tw-items-center tw-space-y-4">
        <div className="tw-rounded-full tw-border tw-border-neutral-grey-3 tw-p-1">
          <InitialsCircle.Large initials={member.initials} />
        </div>
        <h3>{member.fullName}</h3>
        <div className="tw-flex tw-items-center tw-space-x-2">
          <IoMailOutline />
          <small>{member.email}</small>
        </div>
      </div>
      <div className="tw-flex tw-flex-col">
        {canPerformDeactivateOrRoleChange && isActive && (
          <div>
            <label htmlFor="role" className="tw-text-neutral-dark tw-text-md">
              Change Role
            </label>
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
              <select
                className="tw-block tw-px-3 tw-py-2 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-text-neutral-dark tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-ring-0 focus:tw-outline-none focus:tw-shadow-input"
                id="role"
                placeholder="Choose one"
                label="Role"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                {availableRoleOptions.map((option) => (
                  <option key={option.name} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
              <Button
                primary
                className="tw-ml-4"
                onClick={handleRoleChange}
                isDisabled={loading || selectedOption === member.roleValue}
              >
                Update
              </Button>
            </div>
          </div>
        )}
        <UserDetails
          member={member}
          isInActive={isInActive}
          onShowPermissions={onShowPermissions}
          canPerformDeactivateOrRoleChange={canPerformDeactivateOrRoleChange}
          onDeactivateMember={onDeactivateMember}
          me={me}
          canViewRole={!(canPerformDeactivateOrRoleChange && isActive)}
        />
        <div className="tw-border-t-2 tw-border-neutral-grey-4"></div>
        <UserPermissions permissions={member.permissions} />
      </div>
      <MemberPermissionModal
        show={isModifyingPermissions}
        setShow={setIsModifyingPermissions}
        member={member}
        onSuccess={onSuccess}
        setMember={setMember}
        allMembers={allMembers}
      />
      <ConfirmDeactivationModal
        show={showModal}
        setShow={setShowModal}
        memberId={member.id}
        onSuccess={onSuccess}
        role={member.roleValue}
      />
    </div>
  );
};

const ConfirmDeactivationModal = ({ show, setShow, memberId, onSuccess, role }) => {
  const onClose = () => setShow(false);

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <strong>Deactivate user</strong>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <DeactivateMember onSuccess={onSuccess} memberId={memberId} role={role} />
    </Modal>
  );
};

const MemberPermissionModal = ({ show, setShow, member, onSuccess, setMember, allMembers }) => {
  const onClose = () => setShow(false);
  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <strong>Update User Permissions</strong>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <SetUserPermissionsForm
        memberPermissions={member.permissions}
        role={member.roleValue}
        memberId={member.id}
        setMember={setMember}
        onSuccess={onSuccess}
        allMembers={allMembers}
        handleBack={onClose}
      />
    </Modal>
  );
};

export default MemberDetail;
