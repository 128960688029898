import { useContext, useState } from 'react';

import { useToggle } from 'hooks';
import { REIMBURSEMENT_REQUEST_STEPS } from '../constants';
import { CreateReimbursementRequestContext } from '../context/CreateReimbursementRequest';

const useCreateReimbursementRequest = () => {
  const [initialStep] = REIMBURSEMENT_REQUEST_STEPS.Details;
  const { setReimbursementRequestInfo } = useContext(CreateReimbursementRequestContext);

  const [currentStep, setCurrentStep] = useState(initialStep);

  const { isOpen: isCompleted, open: onComplete, close: onResetComplete } = useToggle();

  const onPrevStep = () => {
    setCurrentStep((prevStep) => (prevStep === 0 ? prevStep : prevStep - 1));
  };

  const onNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const onResetSteps = () => {
    setCurrentStep(initialStep);
    onResetComplete();
    setReimbursementRequestInfo(null);
  };

  return {
    currentStep,
    onPrevStep,
    onNextStep,
    onResetSteps,
    isCompleted,
    onComplete,
  };
};
export default useCreateReimbursementRequest;
