import React from 'react';
import { subYears } from 'date-fns';
import { Transition } from '@headlessui/react';
import { useFormContext } from 'react-hook-form';

import AddressFields from 'components/onboarding/AddressFields';
import { RadioField, Checkbox, DatePicker } from 'components/FormFields/v2';

const AccountManagerContactFields = ({ onTogglePersonalAddressSameAsBusiness, personalAddressSameAsBusiness }) => {
  const { watch, register } = useFormContext();
  const livedInTheSameAddressRecently = watch('livedInTheSameAddressRecently') === 'true';

  return (
    <>
      <Checkbox
        rootClass="tw-mb-8"
        label="My personal address is the same as my business address"
        name="personalAddressSameAsBusiness"
        value={personalAddressSameAsBusiness}
        onChange={onTogglePersonalAddressSameAsBusiness}
        defaultChecked={personalAddressSameAsBusiness}
        data-testid="address-checkbox"
        size="xl"
      />
      <Transition
        show={!personalAddressSameAsBusiness}
        enter="tw-ease-in-out tw-duration-200"
        enterFrom="tw-opacity-0"
        enterTo="tw-opacity-100"
        leave="tw-ease-in-out tw-duration-200"
        leaveFrom="tw-opacity-100"
        leaveTo="tw-opacity-0"
      >
        <AddressFields
          name="address"
          streetAddressLabel="Personal Street Address"
          selectedCountry={watch('address.country')}
        />
      </Transition>
      <div className="tw-mb-8">
        <label className="tw-text-neutral-grey-1 tw-mb-4" htmlFor="isAccountOwner">
          Have you lived at this address for two years or more?
        </label>
        <RadioField
          className="tw-overflow-hidden"
          label="Have you lived at this address for two years or more?"
          name="livedInTheSameAddressRecently"
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          ref={register({ required: true })}
        />
      </div>
      <Transition
        show={!livedInTheSameAddressRecently}
        enter="tw-ease-in-out tw-duration-200"
        enterFrom="tw-opacity-0"
        enterTo="tw-opacity-100"
        leave="tw-ease-in-out tw-duration-200"
        leaveFrom="tw-opacity-100"
        leaveTo="tw-opacity-0"
      >
        <AddressFields
          name="otherAddress"
          streetAddressLabel="Previous Personal Street Address"
          selectedCountry={watch('address.country')}
        />
      </Transition>
      <DatePicker
        name="birthdate"
        rootClass="tw-mb-8"
        label="Your Date of Birth"
        maxDate={subYears(new Date(), 18)}
        required
        ref={register({ required: true })}
      />
    </>
  );
};

export default AccountManagerContactFields;
