import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';
// import { Switch } from '@headlessui/react';

import { SubmitButton } from 'components/FormFields/v2';
import useUpdateSubscription from '../hooks/useUpdateSubscription';
import { CURRENT_PLANS, ACTIONS, PRICING_PLANS } from '../constants';
import PlusFeatures from './PlusFeatures';
import PowerFeatures from './PowerFeatures';
import { useGetSubscription } from 'hooks/';

const UpgradeSubscription = ({ selectedPlan = '' }) => {
  const { updateSubscription, loading } = useUpdateSubscription();
  const { name: currentSubscriptionName } = useGetSubscription();
  const [name, setName] = useState(selectedPlan || CURRENT_PLANS.plus);
  const [enabled, setEnabled] = useState(selectedPlan === CURRENT_PLANS.power);
  const [price, setPrice] = useState(49);

  const plusPlan = PRICING_PLANS.find((plan) => plan.name === CURRENT_PLANS.plus);
  const powerPlan = PRICING_PLANS.find((plan) => plan.name === CURRENT_PLANS.power);

  const plusPlanPrice = plusPlan?.price || 0;
  const powerPlanPrice = powerPlan?.price || 0;

  useEffect(() => {
    setPrice(enabled ? powerPlanPrice : plusPlanPrice);
    setName(enabled ? CURRENT_PLANS.power : CURRENT_PLANS.plus);
  }, [enabled]);

  const submitPlan = async () => {
    const planName = enabled ? CURRENT_PLANS.power : CURRENT_PLANS.plus;
    const response = await updateSubscription({
      variables: {
        action: ACTIONS.create,
        subscriptionName: planName,
      },
    });
    if (response.data?.updateSubscription === true) {
      toast.success('Subscription created successfully');
      window.location.href = '/dashboard/subscriptions/active';
    } else {
      toast.error('Something went wrong. Please try again or contact support.');
    }
  };

  return (
    <div className="tw-bg-neutral-light tw-border tw-border-neutral-grey-4 tw-rounded-md">
      <div className="tw-w-full tw-flex tw-flex-col">
        <div className="tw-w-full tw-p-6">
          <div className="tw-flex tw-items-center tw-justify-end">
            <div
              onClick={() => setEnabled(false)}
              className={cx(
                'tw-border tw-p-2 tw-font-semibold tw-rounded-l-md tw-cursor-pointer tw-text-xs',
                !enabled
                  ? 'tw-bg-primary-dark-green tw-text-neutral-light'
                  : 'tw-text-primary-dark-green tw-bg-neutral-grey-5'
              )}
            >
              Loop Plus
            </div>
            <div
              onClick={() => setEnabled(true)}
              className={cx(
                'tw-border tw-font-semibold tw-p-2 tw-rounded-r-md tw-cursor-pointer tw-text-xs',
                enabled
                  ? 'tw-bg-primary-dark-green tw-text-neutral-light'
                  : 'tw-text-primary-dark-green tw-bg-neutral-grey-5'
              )}
            >
              Loop Power
            </div>
          </div>

          <div className="tw-flex tw-items-center tw-justify-between">
            <div>
              <div className="tw-text-2xl tw-font-semibold tw-mb-2">{name}</div>
              <div className="tw-text-sm tw-text-gray-500 tw-mb-4">
                {enabled ? 'Our most popular plan for power users' : 'Our most popular plan for growing businesses'}
              </div>
            </div>
            <div className="tw-flex tw-items-baseline tw-px-6">
              <div className="tw-text-4xl tw-font-semibold">${price}</div>
              <div className="tw-text-sm tw-text-gray-500 tw-ml-1">per month</div>
            </div>
          </div>
        </div>
        <div className="tw-border tw-border-neutral-grey-4" />
        {name === CURRENT_PLANS.plus ? <PlusFeatures /> : <PowerFeatures />}
        <div className="tw-border-b tw-border-neutral-grey-4" />
        <div className="tw-mt-6 tw-text-center tw-py-4">
          <SubmitButton
            className="tw-font-semibold tw-w-3/4"
            type="submit"
            onClick={submitPlan}
            disabled={loading || currentSubscriptionName === name}
          >
            {currentSubscriptionName === CURRENT_PLANS.power && name === CURRENT_PLANS.plus
              ? 'Downgrade Now'
              : 'Upgrade Now'}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default UpgradeSubscription;
