import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ampTrackEvent } from 'amplitude';

import {
  GET_CARD_RECURRING_PAYMENT,
  CREATE_CARD_RECURRING_PAYMENT,
  UPDATE_CARD_RECURRING_PAYMENT,
  GET_CARD_RECURRING_PAYMENT_INFO,
} from 'graphql/recurringPayment';
import { CardAutopayContext } from 'components/payments/CardAutopay/context/CardAutopay';
import useGetRecurringPaymentInfo from './useGetRecurringPaymentInfo';

const useCreateOrUpdateRecurringPayment = ({ onNextStep }: { onNextStep?: () => void } = {}) => {
  const [createCardRecurringPayment, { loading: loadingCreateCardRecurringPayment }] = useMutation(
    CREATE_CARD_RECURRING_PAYMENT,
    {
      refetchQueries: [{ query: GET_CARD_RECURRING_PAYMENT }, { query: GET_CARD_RECURRING_PAYMENT_INFO }],
      awaitRefetchQueries: true,
      fetchPolicy: 'network-only',
    }
  );
  const [updateCardRecurringPayment, { loading: loadingUpdateRecurringPayment }] = useMutation(
    UPDATE_CARD_RECURRING_PAYMENT,
    {
      refetchQueries: [{ query: GET_CARD_RECURRING_PAYMENT }, { query: GET_CARD_RECURRING_PAYMENT_INFO }],
      awaitRefetchQueries: true,
      fetchPolicy: 'network-only',
    }
  );

  const { hasRecurringPayment, recurringPaymentId } = useGetRecurringPaymentInfo();
  const { fromAccountId, paymentType } = useContext(CardAutopayContext);

  const onCreateCardRecurringPayment = async () => {
    try {
      await createCardRecurringPayment({
        variables: {
          fromAccount: fromAccountId,
          paymentType,
        },
      });

      toast.success('Autopay successfully enabled');
      onNextStep?.();
      ampTrackEvent('onCreateCardRecurringPayment: success');
    } catch (error) {
      console.error(error);
      toast.error('Unable to enable autopay');
      ampTrackEvent('onCreateCardRecurringPayment: error');
    }
  };

  const onUpdateRecurringPayment = async () => {
    try {
      await updateCardRecurringPayment({
        variables: {
          recurringPaymentId,
          enabled: true,
          fromAccount: fromAccountId,
        },
      });

      toast.success('Autopay successfully enabled');
      onNextStep?.();
      ampTrackEvent('onUpdateRecurringPayment: success');
    } catch (error) {
      console.error(error);
      toast.error('Unable to enable autopay');
      ampTrackEvent('onUpdateRecurringPayment: error');
    }
  };

  const onSubmitRecurringPayment = async () => {
    if (hasRecurringPayment) {
      await onUpdateRecurringPayment();
      return;
    }

    await onCreateCardRecurringPayment();
  };

  return {
    loadingCreateCardRecurringPayment,
    loadingUpdateRecurringPayment,
    onSubmitRecurringPayment,
  };
};

export default useCreateOrUpdateRecurringPayment;
