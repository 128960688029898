import React from 'react';
import { useHistory } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

import CashAndStarsMultiple from 'components/svg/CashAndStarsMultiple';
import Button from 'components/Button';

const AddPayeePromotionPrompt = () => {
  const history = useHistory();

  const navigateToPayments = () => history.push('/dashboard/payments/payees');

  return (
    <div className="tw-bg-primary-dark-green tw-flex tw-justify-between tw-rounded tw-my-6 tw-px-4 tw-pt-5 tw-pb-4">
      <div className="tw-flex tw-items-center">
        <div className="tw-ml-2 tw-mr-6">
          <CashAndStarsMultiple />
        </div>
        <div>
          <small className="tw-font-semibold tw-text-primary-light-green tw-block">Send Money Around the World</small>
          <small className="tw-text-primary-light-green tw-mt-1 tw-mb-3 tw-block">
            Make fast and secure payments around the world. Get market leading FX rates with Loop Payments - included
            free with your Loop account.
          </small>
          <Button onClick={navigateToPayments} data-testid="add-payees-btn">
            <small className="tw-font-bold tw-text-neutral-light">
              Add Payees <BsArrowRight className="tw-inline-block" />
            </small>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddPayeePromotionPrompt;
