import React from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import config from 'config';
import BusinessContacts from 'components/onboarding/BusinessContacts';
import { GET_CONTACTS_AND_CORPORATE_SHAREHOLDER, GET_CORPORATE_OWNERSHIP_INFO } from 'graphql/onboarding';
import { entityTypes } from 'constants/index';

const BusinessContactsContainer = () => {
  const { loading, data, refetch } = useQuery(GET_CONTACTS_AND_CORPORATE_SHAREHOLDER);
  const { loading: loadingCorporateOwnershipData, data: corporateOwnershipData } =
    useQuery(GET_CORPORATE_OWNERSHIP_INFO);

  let contacts = get(data, 'me.account.contacts', []);

  // TODO: Remove the following filtering after https://getloop.atlassian.net/browse/LBP-2314 will be completed
  if (config.env === 'testing' && contacts.length > 1) {
    contacts = contacts.filter(({ firstName, lastName }) => !(firstName === 'John' && lastName === 'Doe'));
  }

  const accountType = get(data, 'me.account.entityType');

  const isCorporation = accountType === entityTypes.CORPORATION;
  const isPartnership = accountType === entityTypes.PARTNERSHIP;

  const hasCorporateOwnershipDocument = get(corporateOwnershipData, 'me.account.hasCorporateOwnershipDocument', false);
  const corporateOwnership = get(corporateOwnershipData, 'me.account.corporateOwnership', 0);

  return (
    <BusinessContacts
      loading={loading || loadingCorporateOwnershipData}
      contacts={contacts}
      refetchContacts={refetch}
      isCorporation={isCorporation}
      isPartnership={isPartnership}
      hasCorporateOwnershipDocument={hasCorporateOwnershipDocument}
      corporateOwnership={corporateOwnership}
    />
  );
};

export default BusinessContactsContainer;
