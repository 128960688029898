import React from 'react';

const Warning2 = ({ size = '75' }) => (
  <svg width={size} height={size} viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.7369 9.25C34.8538 5.58333 40.1462 5.58333 42.2631 9.25L64.3468 47.5C66.4637 51.1667 63.8175 55.75 59.5836 55.75H15.4164C11.1824 55.75 8.53626 51.1667 10.6532 47.5L32.7369 9.25Z"
      fill="#E8F3ED"
      stroke="#0B5955"
    />
    <path
      d="M35.948 40.222L34.352 25.606V18.97H40.316V25.606L38.72 40.222H35.948ZM34.268 49V42.91H40.4V49H34.268Z"
      fill="#0B5955"
    />
  </svg>
);

export default Warning2;
