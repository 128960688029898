import { useState, useEffect } from 'react';

type useFirstConversionModalProps = {
  show: boolean;
};

const useFirstConversionModal = ({ show }: useFirstConversionModalProps) => {
  const [showNextSection, setShowNextSection] = useState(false);

  const handleClick = () => setShowNextSection(true);
  useEffect(() => {
    if (!show) {
      setShowNextSection(false);
    }
  }, [show]);

  return { showNextSection, handleClick };
};

export default useFirstConversionModal;
