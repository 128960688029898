export enum Currencies {
  CAD = 'CAD',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  AED = 'AED',
  AUD = 'AUD',
  BGN = 'BGN',
  CHF = 'CHF',
  CNY = 'CNY',
  CZK = 'CZK',
  DKK = 'DKK',
  ILS = 'ILS',
  INR = 'INR',
  JPY = 'JPY',
  KES = 'KES',
  MXN = 'MXN',
  NZD = 'NZD',
  PHP = 'PHP',
  QAR = 'QAR',
  SAR = 'SAR',
  THB = 'THB',
  TRY = 'TRY',
  UGX = 'UGX',
  ZAR = 'ZAR',
}
