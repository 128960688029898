import React from 'react';

import { Loaders } from 'components/cards/Loader';

import styles from '../../PersonaVerification.module.scss';

const Loader = () => (
  <div className={styles.loader}>
    <Loaders.Light />
  </div>
);

export default Loader;
