import React, { useContext } from 'react';
import { Tab } from '@headlessui/react';
import cx from 'classnames';

import useApprovalRequestsContent from './hooks';
import {
  ReimbursementTable,
  ReimbursementRequestModal,
  ApprovalsTableSkeleton,
  CardExpenseTable,
  FilterBar,
  CompletedTable,
} from './components';
import { APPROVAL_TAB } from '../constants';
import { PaginationControl } from 'components/UI';
import { ExportsDropdown } from './components/UI';
import MultiselectOptions from './components/MultiselectOptions';
import { FilterRequestsContext } from 'components/Approvals/ApprovalsContent/context';
import { AuthContext } from 'context/Auth';
import { User } from 'types/user';

const ApprovalsContent = () => {
  const {
    pendingReimbursementRequests,
    completedReimbursementRequests,
    cardExpenseRequests,
    isOpenAddReimbursementRequestModal,
    handleOpenAddReimbursementRequestModal,
    handleCloseAddReimbursementRequestModal,
    selectedTab,
    setSelectedTab,
    isPendingLoading,
    isCardExpensesLoading,
    isCompletedLoading,
    setPage,
    setNumPerPage,
    page,
    numPerPage,
    pendingTotalCount,
    completedTotalCount,
    cardExpenseTotalCount,
    handleClearPagination,
    isMember,
    isAdmin,
  } = useApprovalRequestsContent();
  const { handleClearFilters } = useContext(FilterRequestsContext);

  const getTabLabel = (tab: APPROVAL_TAB, selected: boolean) => {
    switch (tab) {
      case APPROVAL_TAB.expenseReimbursements:
        return isPendingLoading ? (
          <div className="tw-flex tw-items-center">
            Expense Reimbursements
            <div
              className="spinner-border spinner-border-width-sm tw-text-secondary-green tw-w-3.5 tw-h-3.5 tw-ml-1"
              role="status"
            ></div>
          </div>
        ) : (
          <div className="tw-flex tw-items-center tw-justify-center">
            Expense Reimbursements
            {pendingTotalCount > 0 && (
              <div
                className={cx(
                  selected ? 'tw-bg-primary-dark-green tw-opacity-70 tw-text-neutral-light' : 'tw-bg-neutral-grey-3',
                  'tw-ml-1 tw-w-6 tw-h-6 tw-rounded-full tw-flex tw-items-center tw-justify-center'
                )}
              >
                <span>{pendingTotalCount}</span>
              </div>
            )}
          </div>
        );
      case APPROVAL_TAB.cardExpenses:
        return isCardExpensesLoading ? (
          <div className="tw-flex tw-items-center">
            Card Expenses
            <div
              className="spinner-border spinner-border-width-sm tw-text-secondary-green tw-w-3.5 tw-h-3.5 tw-ml-1"
              role="status"
            ></div>
          </div>
        ) : (
          <div className="tw-flex tw-items-center tw-justify-center">
            Card Expenses
            {cardExpenseTotalCount > 0 && (
              <div
                className={cx(
                  selected ? 'tw-bg-primary-dark-green tw-opacity-70 tw-text-neutral-light' : 'tw-bg-neutral-grey-3',
                  'tw-ml-1 tw-w-6 tw-h-6 tw-rounded-full tw-flex tw-items-center tw-justify-center'
                )}
              >
                <span>{cardExpenseTotalCount}</span>
              </div>
            )}
          </div>
        );
      case APPROVAL_TAB.completed:
        return `Completed`;
    }
  };

  const handleTabChange = (index: number) => {
    switch (index) {
      case 0:
        setSelectedTab(APPROVAL_TAB.expenseReimbursements);
        break;
      case 1:
        setSelectedTab(APPROVAL_TAB.cardExpenses);
        break;
      case 2:
        setSelectedTab(APPROVAL_TAB.completed);
        break;
    }
    handleClearPagination();
    handleClearFilters();
  };

  const renderTabs = () => {
    const { me } = useContext(AuthContext) as unknown as { me: User };

    const tabsToRender = [
      {
        tab: APPROVAL_TAB.expenseReimbursements,
        show: (me?.account?.features || []).includes('Expense Reimbursement'),
      },
      {
        tab: APPROVAL_TAB.cardExpenses,
        show: (me?.account?.features || []).includes('Card Expenses'),
      },
      {
        tab: APPROVAL_TAB.completed,
        show: true,
      },
    ].filter((tab) => {
      return tab.show;
    });
    return tabsToRender.map((approvalTab) => (
      <Tab
        key={approvalTab.tab}
        className={({ selected }) =>
          `tw-py-2 tw-px-6 tw-border-2 ${
            selected
              ? 'tw-border-secondary-pastel-green-80 tw-bg-primary-light-green tw-text-primary-dark-green hover:tw-text-primary-dark-green'
              : 'tw-border-neutral-grey-4 tw-bg-neutral-grey-5'
          } tw-rounded-md tw-inline-flex tw-items-center tw-mr-4 tw-gap-x-2`
        }
      >
        {({ selected }) => getTabLabel(approvalTab.tab, selected)}
      </Tab>
    ));
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-w-full tw-pb-16">
        <h2 className="tw-text-2xl tw-mb-4">Approvals</h2>
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-9">
          <p className="tw-text-sm tw-text-neutral-grey-2">Review your expense reimbursements and card expenses.</p>
          {(isAdmin || isMember) && (
            <button
              className="tw-bg-primary-dark-green tw-py-2 tw-px-4 tw-rounded-md tw-ml-8 tw-inline-flex"
              onClick={handleOpenAddReimbursementRequestModal}
            >
              <div className="tw-text-neutral-light tw-font-semibold">Request Reimbursement</div>
            </button>
          )}
        </div>
        <Tab.Group onChange={handleTabChange}>
          <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-pb-8 tw-border-neutral-grey-3">
            <Tab.List>{renderTabs()}</Tab.List>
            {isAdmin && selectedTab === 'Completed' && (
              <ExportsDropdown
                completedApprovalsList={completedReimbursementRequests}
                completedTotalCount={completedTotalCount}
              />
            )}
          </div>
          <Tab.Panels className="tw-mb-16">
            <Tab.Panel>
              <FilterBar />
              {isPendingLoading ? (
                <ApprovalsTableSkeleton />
              ) : (
                <>
                  <ReimbursementTable reimbursementRequestList={pendingReimbursementRequests} />
                  {pendingTotalCount > 0 && (
                    <div className="tw-mt-12 tw-px-2">
                      <PaginationControl
                        page={page}
                        onChangePage={(val) => setPage(val)}
                        onChangeNumberPerPage={(perPage) => setNumPerPage(perPage)}
                        numPerPage={numPerPage}
                        numberOfItems={pendingTotalCount}
                      />
                    </div>
                  )}
                </>
              )}
            </Tab.Panel>
            <Tab.Panel>
              <div className="tw-flex tw-flex-row tw-justify-between">
                <FilterBar />
                <MultiselectOptions />
              </div>

              {isCardExpensesLoading ? (
                <ApprovalsTableSkeleton />
              ) : (
                <>
                  <CardExpenseTable cardExpenseRequestList={cardExpenseRequests} />
                  {cardExpenseTotalCount > 0 && (
                    <div className="tw-mt-12 tw-px-2">
                      <PaginationControl
                        page={page}
                        onChangePage={(val) => setPage(val)}
                        onChangeNumberPerPage={(perPage) => setNumPerPage(perPage)}
                        numPerPage={numPerPage}
                        numberOfItems={cardExpenseTotalCount}
                      />
                    </div>
                  )}
                </>
              )}
            </Tab.Panel>
            <Tab.Panel>
              <FilterBar />
              {isCompletedLoading ? (
                <ApprovalsTableSkeleton />
              ) : (
                <>
                  <CompletedTable completedApprovalsList={completedReimbursementRequests} />
                  {completedTotalCount > 0 && (
                    <div className="tw-mt-12 tw-px-2">
                      <PaginationControl
                        page={page}
                        onChangePage={(val) => setPage(val)}
                        onChangeNumberPerPage={(perPage) => setNumPerPage(perPage)}
                        numPerPage={numPerPage}
                        numberOfItems={completedTotalCount}
                      />
                    </div>
                  )}
                </>
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        <ReimbursementRequestModal
          show={isOpenAddReimbursementRequestModal}
          onClose={handleCloseAddReimbursementRequestModal}
        />
      </div>
    </>
  );
};
export default ApprovalsContent;
