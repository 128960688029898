import React from 'react';
import { FormProvider } from 'react-hook-form';

import { useDepositInformation } from './hooks';
import { Loaders } from 'components/cards/Loader';
import { BankDetails, EmptyState } from './components';
import { Select } from 'components/FormFields/v2';

const DepositInformation = () => {
  const {
    walletLoading,
    walletOptions,
    selectedWallet,
    selectedWalletCurrency,
    showEmptyState,
    form,
    register,
    solidInternalId,
  } = useDepositInformation();

  if (walletLoading) return <Loaders.Light />;

  return (
    <FormProvider {...form}>
      <form>
        <h5 className="tw-font-semibold">Select an Account to View Deposit Information</h5>
        <div className="tw-my-4">
          <Select placeholder="Select an Account" name="wallet" options={walletOptions} ref={register()} />
        </div>
        <div>
          {!!selectedWallet && (
            <div>
              {showEmptyState ? (
                <EmptyState selectedWalletCurrency={selectedWalletCurrency} />
              ) : (
                <BankDetails selectedWalletCurrency={selectedWalletCurrency} solidInternalId={solidInternalId} />
              )}
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default DepositInformation;
