import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';

import { GET_ACCOUNTING_INTEGRATION_VENDORS } from 'graphql/accountingServices';
import { SearchDropdownComponentProps } from 'components/settings/AccountingServices/AccountingServices.types';
import { Option } from 'components/UI/SearchDropdown/SearchDropdown.types';
import { AccountingIntegrationVendor } from 'types/accounting';
import { PageData } from 'types/pagination';
import SearchDropdown from 'components/UI/SearchDropdown';

const SearchVendorDropdown = ({ selectedVal, selectedLabel, handleChange }: SearchDropdownComponentProps) => {
  const [searchVendors, { data: vendors, loading: searchingVendors }] = useLazyQuery<{
    accountingIntegrationVendors: { items: AccountingIntegrationVendor[]; pageData: PageData };
  }>(GET_ACCOUNTING_INTEGRATION_VENDORS);

  const [queryString, setQueryString] = useState<string | null>(null);
  const [optionsList, setOptionsList] = useState<AccountingIntegrationVendor[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option>({
    value: selectedVal || '',
    label: selectedLabel || '',
  });

  const selectOption = (option: Option) => {
    setQueryString(null);
    handleChange({ id: option.value, name: option.label });
  };

  const getOptionFromItem = (item: AccountingIntegrationVendor) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  };

  useEffect(() => {
    if (queryString?.length) {
      searchVendors({ variables: { queryString } });
    }

    if (selectedVal == null && !queryString?.length) {
      setOptionsList([]);
    }

    if (vendors) {
      setOptionsList(vendors?.accountingIntegrationVendors?.items || []);
    }

    if (selectedVal && !queryString?.length) {
      const matchingOption = optionsList.find((item) => item.id === selectedVal);
      const validOption = matchingOption && getOptionFromItem(matchingOption);
      if (validOption?.label) {
        setSelectedOption(validOption);
      } else {
        setSelectedOption({
          value: selectedVal || '',
          label: selectedLabel || '',
        });
      }
    }
  }, [vendors, queryString, selectedVal]);

  return (
    <SearchDropdown
      optionsList={optionsList.map(getOptionFromItem)}
      onSelectOption={selectOption}
      onSearch={debounce((value) => {
        setQueryString(value);
      }, 500)}
      selectedOption={selectedOption || null}
      displayLabel={''}
      isSearching={searchingVendors}
    />
  );
};

export default SearchVendorDropdown;
