import React from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { Link, useHistory } from 'react-router-dom';

import { TRANSACTION_PRODUCT_TYPES } from 'constants/index';
import useHasLineOfCredit from 'hooks/useHasLineOfCredit';
import useIsMember from 'hooks/useIsMember';
import useIsMobile from 'hooks/useIsMobile';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import CardOutline from 'components/svg/CardOutline';
import Bank from 'components/svg/Bank';
import CoinStack from 'components/svg/CoinStack';

const TransactionsSplash = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const { isMember } = useIsMember();

  if (!isMobile) history.push('/dashboard/transactions');

  const { hasLineOfCredit } = useHasLineOfCredit();

  return (
    <DashboardLayout title="Transactions">
      <div className="tw-flex tw-flex-col tw-px-4 tw-space-y-8">
        <h2>Transactions</h2>
        <div className="tw-space-y-4">
          <NavigationItem
            Icon={CardOutline}
            iconColor="tw-text-primary-blue"
            iconBgColor="tw-bg-secondary-light-blue"
            title="Card Transactions"
            productType={TRANSACTION_PRODUCT_TYPES.creditCard}
          />
          {!isMember && (
            <NavigationItem
              Icon={Bank}
              iconColor="tw-text-primary-lilac"
              iconBgColor="tw-bg-secondary-light-lilac"
              title="Account Transactions"
              productType={TRANSACTION_PRODUCT_TYPES.wallet}
            />
          )}
          {!isMember && hasLineOfCredit && (
            <NavigationItem
              Icon={CoinStack}
              iconColor="tw-text-primary-dark-yellow"
              iconBgColor="tw-bg-secondary-light-yellow"
              title="Capital Transactions"
              productType={TRANSACTION_PRODUCT_TYPES.lineOfCredit}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

const NavigationItem = ({ Icon, title, productType, iconColor, iconBgColor }) => (
  <Link
    to={`/dashboard/transactions?productType=${productType}`}
    className="tw-flex tw-items-center tw-justify-between tw-rounded-md tw-bg-neutral-light tw-px-4 tw-py-5"
  >
    <div className="tw-flex tw-justify-start tw-items-center">
      <div
        className={`tw-flex tw-justify-center tw-items-center ${iconBgColor} ${iconColor} tw-rounded tw-p-1 tw-mr-4`}
      >
        <Icon className="tw-h-6" />
      </div>
      <div className="tw-flex tw-flex-col tw-justify-between">
        <strong>{title}</strong>
      </div>
    </div>
    <BsChevronRight />
  </Link>
);

export default TransactionsSplash;
