import { useEffect, useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_SOLID_OWNERSHIP_DISCLOSURE_URL } from 'graphql/wallets';
import { OwnershipDisclosureProps, OwnershipDisclosureState } from '../OwnershipDisclosure.types';
import { useDisclosureReadiness, useDisclosureState } from './';

const useOwnershipDisclosure = ({
  enableFullScreen,
  disableFullScreen,
}: Pick<OwnershipDisclosureProps, 'enableFullScreen' | 'disableFullScreen'>) => {
  const [ownershipDisclosureUrl, setOwnershipDisclosureUrl] = useState<string | undefined>();

  const { isDisclosureReadyToBeRequested } = useDisclosureReadiness();
  const {
    isDisclosurePreSigned,
    setIsDisclosurePreSigned,
    isDisclosureSigningFailed,
    setIsDisclosureSigningFailed,
    isDisclosureSigningConfirmed,
    disclosureStateError,
  } = useDisclosureState();

  const [getSolidOwnershipDisclosureUrl, { error, loading }] = useLazyQuery(GET_SOLID_OWNERSHIP_DISCLOSURE_URL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const url = get(data, 'solidRequirements.ownershipDisclosureUrl');
      if (!url) return;

      setOwnershipDisclosureUrl(url);
    },
    onError: () => {
      setOwnershipDisclosureUrl(undefined);
    },
  });

  useEffect(() => {
    if (
      !isDisclosureReadyToBeRequested ||
      ownershipDisclosureUrl ||
      isDisclosurePreSigned ||
      isDisclosureSigningFailed ||
      isDisclosureSigningConfirmed
    )
      return;

    getSolidOwnershipDisclosureUrl();
  }, [
    isDisclosureReadyToBeRequested,
    ownershipDisclosureUrl,
    isDisclosurePreSigned,
    isDisclosureSigningFailed,
    isDisclosureSigningConfirmed,
  ]);

  useEffect(() => {
    enableFullScreen?.();
    return () => disableFullScreen?.();
  }, [enableFullScreen, disableFullScreen]);

  const handleIframeMessage = useCallback((e: MessageEvent) => {
    const originUrl = window.location.origin;
    if (!e?.origin?.startsWith(originUrl)) return;

    const ownershipDisclosureState = e.data?.ownershipDisclosure;
    if (!ownershipDisclosureState) return;

    if (ownershipDisclosureState === OwnershipDisclosureState.completed) {
      setIsDisclosurePreSigned(true);
      setIsDisclosureSigningFailed(false);
    } else {
      setIsDisclosurePreSigned(false);
      setIsDisclosureSigningFailed(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);

  return {
    ownershipDisclosureUrl,
    isLoading: loading || !isDisclosureReadyToBeRequested,
    error: error || disclosureStateError,
    isDisclosurePreSigned,
    isDisclosureSigningFailed,
    isDisclosureSigningConfirmed,
  };
};

export default useOwnershipDisclosure;
