import React from 'react';
import cx from 'classnames';

import DocumentAttachOutline from 'components/svg/DocumentAttachOutline';

const DropZone = ({ getRootProps, getInputProps, hasDroppedEmptyFile, isDragActive, hasError = false }) => {
  return (
    <div
      className={cx(
        hasError ? `tw-border-semantic-error` : `tw-border-neutral-grey-3`,
        `tw-border-4 tw-border-rounded tw-border-dashed`
      )}
    >
      <div {...getRootProps()} data-drag-active={isDragActive} className="tw-p-10 tw-margin-auto tw-cursor-pointer">
        <input {...getInputProps()} />
        <div className="tw-flex tw-flex-col tw-items-center tw-space-y-2">
          <DocumentAttachOutline size="40" />
          <div className="tw-flex tw-flex-row tw-justify-center">
            <div>Drag and drop here or </div>&nbsp;
            <div className="tw-text-primary-dark-green"> Browse Files</div>
          </div>
          {hasDroppedEmptyFile && (
            <span className="tw-flex tw-justify-center tw-text-semantic-error">{'Uploaded files cannot be empty'}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default DropZone;
