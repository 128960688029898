// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React, { useState } from 'react';

import { SCOPE } from 'constants/index';
import { formatDueDate } from 'utility/date';
import PendingBankAccountVerificationBanner from 'components/creditCards//PendingBankAccountVerificationBanner';
import { useRebuildTooltipForModal } from 'components/InfoTooltip';
import CreateFirstCardBanner from 'components/creditCards//CreateFirstCardBanner';
import CardsListError from 'components/creditCards//CardsListError';
import Button from 'components/Button';
import { ConnectBankOrContactLoopBanner } from 'components/UI';
import PermissionChecker from 'components/PermissionChecker';
import CardsList from 'components/creditCards/mobile/CardsList';
import Balances from 'components/home/mobile/creditCards/Balances';
import CreditSummary from 'components/home/mobile/creditCards/CreditSummary';
import useIsMember from 'hooks/useIsMember';
import useIsContactPermission from 'hooks/useIsContactPermission';
import { NOT_ALLOWED_CARD_CREATION_INFO } from 'constants/messages';
import { CreditCardDetailOverlay, CreateCardOverlay } from 'components/creditCards/mobile/CreditCardOverlays';

const CardsPage = ({
  cardsDataAvailable,
  connectedBankAccount,
  hasAtLeastOneBankAccountVerified,
  creditCards,
  creditCardIds,
  groupedCardsInfo,
  globalCreditLimit,
  hasLineOfCredit,
  refetchCreditCards,
}) => {
  const [showCreateCard, setShowCreateCard] = useState(false);
  const onCreateCard = () => setShowCreateCard(true);

  const isPending = connectedBankAccount && !hasAtLeastOneBankAccountVerified;
  const isVerified = connectedBankAccount && hasAtLeastOneBankAccountVerified && creditCardIds.length === 0;
  const isExistingLocUser = hasLineOfCredit && creditCardIds && creditCardIds.length === 0;

  const { isMember } = useIsMember();

  const { createVirtualCard, createPhysicalCard, creditAgreementSigned } = useIsContactPermission();
  const canCreateCard = (createVirtualCard || createPhysicalCard) && creditAgreementSigned;
  const isMemberAndCanCreateCard = isMember && canCreateCard;

  useRebuildTooltipForModal();

  if (isPending) return <PendingBankAccountVerificationBanner />;

  if (isExistingLocUser) {
    return (
      <ConnectBankOrContactLoopBanner
        title="Cards"
        message="Connect the bank account you will be using to transfer funds to your Loop Card. Once connected, you'll be able to create Loop cards and start spending."
      />
    );
  }

  if (isVerified) {
    return (
      <PermissionChecker
        scope={SCOPE.manageCards}
        memberScope={isMemberAndCanCreateCard}
        placeholder={NOT_ALLOWED_CARD_CREATION_INFO}
      >
        <CreateFirstCardBanner creditCards={creditCards} globalCreditLimit={globalCreditLimit} />
      </PermissionChecker>
    );
  }

  if (!cardsDataAvailable) return <CardsListError />;

  return (
    <div className="tw-space-y-6">
      {/* TODO: implement cards intermediate screen here
      https://getloop.atlassian.net/browse/LBP-4353 */}

      {/* TODO: exclude cards list to the separate component 
      https://getloop.atlassian.net/browse/LBP-4354*/}
      <div>
        <CardsList creditCards={creditCards} />
      </div>
      <PermissionChecker
        scope={SCOPE.manageCards}
        memberScope={isMemberAndCanCreateCard}
        placeholder={NOT_ALLOWED_CARD_CREATION_INFO}
      >
        <Button primary onClick={onCreateCard} className="tw-w-full">
          Add new Card
        </Button>
      </PermissionChecker>
      <CreditCardDetailOverlay refetchCreditCards={refetchCreditCards} />
      <CreateCardOverlay
        show={showCreateCard}
        setShow={setShowCreateCard}
        creditCards={creditCards}
        refetchCreditCards={refetchCreditCards}
      />
      {/* TODO: exclude card balances to the separate component 
      https://getloop.atlassian.net/browse/LBP-4355 */}
      {!isMember && (
        <div>
          <div>
            <small className="tw-text-neutral-grey-2">Overview</small>
            <CreditSummary groupedCardsInfo={groupedCardsInfo} />
          </div>
          {groupedCardsInfo.paymentDate && <LastStatementCard paymentDate={groupedCardsInfo.paymentDate} />}
          <Balances groupedCardsInfo={groupedCardsInfo} />
        </div>
      )}
    </div>
  );
};

const LastStatementCard = ({ paymentDate }) => (
  <div className="tw-rounded-md tw-bg-neutral-light tw-p-4 tw-mt-4">
    <div className="tw-flex tw-items-center tw-justify-between tw-mb-2">
      <small>Amount Due</small>
    </div>

    <small className="tw-text-neutral-grey-2">{formatDueDate(paymentDate)}</small>
  </div>
);

export default CardsPage;
