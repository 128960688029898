import React from 'react';

import { REDEEMED_GIFT_STATUS } from 'constants/index';

const GiftStatusBadge = ({ status }) => {
  switch (status) {
    case REDEEMED_GIFT_STATUS.redeemed:
      return <Badge className="tw-bg-secondary-light-green tw-text-semantic-success" title="Redeemed" />;
    case REDEEMED_GIFT_STATUS.pending:
      return <Badge className="tw-bg-secondary-pastel-yellow-50 tw-text-primary-yellow" title="Pending" />;
    case REDEEMED_GIFT_STATUS.error:
      return <Badge className="tw-bg-semantic-error-background tw-text-semantic-error" title="Error" />;
    default:
      return null;
  }
};

const Badge = ({ className, title }) => (
  <div className={`tw-py-1 tw-px-2 tw-w-max tw-rounded-md tw-flex tw-items-center tw-justify-center ${className}`}>
    <small>{title}</small>
  </div>
);

export default GiftStatusBadge;
