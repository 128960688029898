import React from 'react';
import cx from 'classnames';

import { SCOPE } from 'constants/index';
import CreditCard from 'domain/creditCard';
import Loader from 'components/cards/Loader';
import { Header } from '../Header';
import RecentTransactions from 'components/RecentTransactions';
import CreditCardsSummary from 'components/home/desktop/CreditCardsSummary';
import LineOfCreditSummary from 'components/home/desktop/LineOfCreditSummary';
import PaymentsSummary from 'components/home/desktop/PaymentsSummary/PaymentsSummary';
import AccountsSummary from 'components/home/desktop/AccountsSummary';
import DelinquentPaymentBanner from 'components/home/DelinquentPaymentBanner';
import PendingAccountDepositsTable from 'components/home/desktop/PendingAccountDepositsTable';
import PermissionChecker from 'components/PermissionChecker';
import { ProductCommunicationCards } from './ProductCommunicationCards';
import { BannerAndModalContainer } from './BannerAndModalContainer';
import { useDesktopHome } from './hooks';

const DesktopLayout = () => {
  const {
    accountCompletedEvents,
    allowCardCreation,
    bankAccountsLoading,
    bankAccountVerified,
    connectedBankAccount,
    creditCardIds,
    creditCardLoading,
    creditCards,
    globalCreditLimit,
    globalCreditLimitLoading,
    groupedCardsInfo,
    hasAtLeastOneBankAccountVerified,
    hasLineOfCredit,
    lineOfCredit,
    loadingCompletedEvents,
    loadingCreditInfo,
    loadingProducts,
    loadingTransactions,
    transactions,
    walletLoading,
    wallets,
  } = useDesktopHome();

  const accountNames = wallets.map((wallet) => wallet.displayName);

  return (
    <div className="tw-w-full tw-pb-16">
      <Header />
      <BannerAndModalContainer />

      <div className="tw-mt-10 tw-flex tw-flex-col">
        <div>{!!groupedCardsInfo && <DelinquentPaymentBanner primaryCard={new CreditCard(groupedCardsInfo)} />}</div>
        <CreditCardsSummary
          creditCardLoading={creditCardLoading}
          creditCards={creditCards}
          creditCardIds={creditCardIds}
          groupedCardsInfo={groupedCardsInfo}
          globalCreditLimit={globalCreditLimit}
          globalCreditLimitLoading={globalCreditLimitLoading}
          hasAtLeastOneBankAccountVerified={hasAtLeastOneBankAccountVerified}
          connectedBankAccount={connectedBankAccount}
          bankAccountVerified={bankAccountVerified}
          bankAccountsLoading={bankAccountsLoading}
          hasLineOfCredit={hasLineOfCredit}
          allowCardCreation={allowCardCreation}
        />
        <div>
          {hasLineOfCredit && (
            <div className="tw-mt-10">
              <LineOfCreditSummary
                loadingCreditInfo={loadingCreditInfo}
                hasLineOfCredit={hasLineOfCredit}
                lineOfCredit={lineOfCredit}
              />
            </div>
          )}
        </div>

        <div className={cx(accountCompletedEvents.length >= 2 && 'tw-order-last ', 'tw-mt-12')}>
          <ProductCommunicationCards
            accountCompletedEvents={accountCompletedEvents}
            loadingCompletedEvents={loadingCompletedEvents}
          />
        </div>
        <div className="tw-mt-10">
          <AccountsSummary wallets={wallets} walletLoading={walletLoading} />
        </div>
        <div className="tw-mt-10">
          <PermissionChecker scope={SCOPE.managePayments}>
            <PaymentsSummary lineOfCredit={lineOfCredit} wallets={wallets} loadingProducts={loadingProducts} />
          </PermissionChecker>
        </div>
        <div>
          <div className="tw-mt-16">
            <PendingAccountDepositsTable
              transactions={transactions}
              accountNames={accountNames}
              loadingTransactions={loadingTransactions}
            />
          </div>
        </div>
        <div>
          <div className="tw-mt-16">
            {loadingTransactions ? (
              <div data-testid="loader-transactions">
                <Loader />
              </div>
            ) : (
              <RecentTransactions transactions={transactions} accountNames={accountNames} includePayments={false} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopLayout;
