import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { IoAddCircleOutline } from 'react-icons/io5';
import { FaExclamationCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import {
  GET_ASSET_ACCOUNTS,
  GET_WALLET_ASSET_ACCOUNT_MAPPING,
  UPDATE_WALLET_ASSET_ACCOUNT_MAPPING,
} from 'graphql/accountingServices';

import Button from 'components/Button';
import { QuickBooksAccount } from './Account';
import { Loaders } from 'components/cards/Loader';
import { MapButton } from './MapButton';
import { accountingServicesAccountCreationURLs } from 'components/settings/Integrations/constants';

export const AssetRevenueAccounts = ({ accountName, currency, showAction = true, className = '' }) => {
  const history = useHistory();

  const [updateMapping] = useMutation(UPDATE_WALLET_ASSET_ACCOUNT_MAPPING, {
    refetchQueries: [GET_WALLET_ASSET_ACCOUNT_MAPPING, GET_ASSET_ACCOUNTS],
  });

  const { loading, error, data: accountingIntegrationAssetRevenueAccounts } = useQuery(GET_ASSET_ACCOUNTS);
  const unsortedQuickBooksLiabilityAccounts = get(
    accountingIntegrationAssetRevenueAccounts,
    'accountingIntegrationAssetRevenueAccounts',
    []
  );
  const accountingIntegrationLiabilityAccounts = [...unsortedQuickBooksLiabilityAccounts].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const { loading: loadingMapping, data: dataMappings } = useQuery(GET_WALLET_ASSET_ACCOUNT_MAPPING);
  let { walletAccountingIntegrationAssetAccountMapping } = dataMappings || {
    walletAccountingIntegrationAssetAccountMapping: [],
  };

  // if we pass currency as prop,  we only show  mapping for this currency
  if (currency)
    walletAccountingIntegrationAssetAccountMapping = walletAccountingIntegrationAssetAccountMapping.filter(
      (map) => map.wallet.currency === currency
    );

  if (loading) return <Loaders.Spinner />;
  if (error) {
    console.error(error);
    return <div>error</div>;
  }

  const handleNewAccount = () => {
    let redirectUrl = '';
    if (accountName === 'Xero') redirectUrl = accountingServicesAccountCreationURLs.xero;
    if (accountName === 'QuickBooks') redirectUrl = accountingServicesAccountCreationURLs.quickbooks;

    redirectUrl && window.open(redirectUrl, '_blank', 'noreferrer');
  };

  const onChangeLiability = (currency, category) => {
    try {
      updateMapping({ variables: { currency, quickBooksAccountId: category.id } });
    } catch (e) {
      console.error(e);
      toast.error('Account mapping could not be updated. Please try again.');
    }
  };

  return (
    <div className={`${className} tw-flex tw-flex-col`}>
      {!loadingMapping &&
        !!walletAccountingIntegrationAssetAccountMapping?.length &&
        walletAccountingIntegrationAssetAccountMapping.map(({ wallet, externalAccountId }) => {
          const qboLiabilityAccount = accountingIntegrationLiabilityAccounts?.find(
            (item) => item.id === externalAccountId
          );
          return (
            <div key={currency} className="tw-flex tw-items-center tw-space-x-4">
              <MapButton
                className="tw-w-1/2"
                key={currency}
                leftSide={<div className="tw-text-sm">{wallet.name}</div>}
                rightSide={
                  accountingIntegrationLiabilityAccounts?.length > 0 ? (
                    <QuickBooksAccount
                      qbCategories={accountingIntegrationLiabilityAccounts.filter(
                        (liability) => liability.currency === currency
                      )}
                      qbCategory={qboLiabilityAccount}
                      onChange={(category) => onChangeLiability(currency, category)}
                      className="tw-relative"
                      newAccount={() => (
                        <div className="tw-p-1 tw-h-12 tw-flex tw-box-border tw-items-center tw-cursor-pointer tw-text-neutral-light tw-bg-primary-dark-green hover:tw-opacity-90">
                          <IoAddCircleOutline className="tw-ml-1" />
                          <span className="tw-mx-1" onClick={handleNewAccount}>
                            Create {accountName} Account
                          </span>
                        </div>
                      )}
                    />
                  ) : (
                    <div className="tw-p-1 tw-h-12 tw-flex tw-box-border tw-items-center tw-cursor-pointer tw-text-neutral-light tw-bg-primary-dark-green hover:tw-opacity-90">
                      <IoAddCircleOutline className="tw-ml-1" />
                      <span className="tw-mx-1" onClick={handleNewAccount}>
                        Create {accountName} Account
                      </span>
                    </div>
                  )
                }
              />

              {qboLiabilityAccount && showAction && (
                <Button
                  primary
                  onClick={() => history.push(`/dashboard/transactions/cardTransactionsPush/?currency=${currency}`)}
                >
                  <small>{`Push ${wallet.currency} transactions to ${accountName}`}</small>
                </Button>
              )}
              {!qboLiabilityAccount && showAction && (
                <div className="tw-bg-semantic-warning tw-text-light tw-flex tw-items-center tw-space-x-2 tw-p-2">
                  <FaExclamationCircle /> <small>Select an account for this currency</small>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};
