import { gql } from '@apollo/client';

export const GENERATE_SMS_AUTHORIZATION = gql`
  mutation GenerateSMSAuthorizationCode {
    generateSmsAuthorizationCode {
      ... on SMSAuthorizationCode {
        expiresAt
        id
        maskedPhoneNumber
      }
      ... on AuthorizationResponse {
        token
        expiresAt
      }
    }
  }
`;

export const REPLY_SMS_AUTHORIZATION_CODE = gql`
  mutation ReplySMSAuthorizationCode($code: String!, $smsAuthorizationCodeId: ID!) {
    replySmsAuthorizationCode(code: $code, smsAuthorizationCodeId: $smsAuthorizationCodeId) {
      token
      expiresAt
    }
  }
`;

export const GENERATE_EMAIL_AUTHORIZATION = gql`
  mutation GenerateEmailAuthorizationCode {
    generateEmailAuthorizationCode {
      ... on SMSAuthorizationCode {
        expiresAt
        id
      }
      ... on AuthorizationResponse {
        token
        expiresAt
      }
    }
  }
`;

export const REPLY_EMAIL_AUTHORIZATION_CODE = gql`
  mutation ReplyEmailAuthorizationCode($code: String!, $emailAuthorizationCodeId: ID!) {
    replyEmailAuthorizationCode(code: $code, emailAuthorizationCodeId: $emailAuthorizationCodeId) {
      token
      expiresAt
    }
  }
`;
