// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React, { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { Transition } from '@headlessui/react';
import { some } from 'lodash';

import { balancesOrder } from 'constants/index';
import { formatDueDate } from 'utility/date';
import InfoTooltip from 'components/InfoTooltip';
import CardBalances from '../CardBalances';
import Balance from './Balance';
import { AddFundsToBalanceContextProvider } from '../context/AddFundsToBalance';
import { AddFundsToBalanceModal } from '../components';
import { useToggle } from 'hooks';

const CreditCardSidebar = ({ groupedCardsInfo }) => {
  if (!groupedCardsInfo) return null;

  const { paymentDate, groupOngoingAmountDueByCurrency } = groupedCardsInfo;

  const showPaymentDue = some(groupOngoingAmountDueByCurrency, 'cents');

  const {
    isOpen: isOpenAddFundsToBalanceModal,
    open: openAddFundsToBalanceModal,
    close: closeAddFundsToBalanceModal,
  } = useToggle();

  return (
    <div className="tw-px-2">
      <div>
        {/* TODO: Add back once backend is returning the correct amount for this field
        <div className="tw-flex tw-flex-row tw-items-end tw-justify-between tw-mb-2">
          <div className="tw-flex tw-items-center tw-justify-start">
            <small className="tw-mr-2">Amount Due</small>
            <InfoTooltip
              message={`The Amount Due is shown on a converted basis in Canadian Dollars and represents the total balance that is due for your Loop Card on your next Payment Due Date. The specific amounts due for each currency are shown below under the "Amount due" label which is shown by expanding the Currency Balances. Note that if you have balances in currencies other than Canadian Dollars, the Amount Due in Canadian dollars that is shown will fluctuate due to changes in market exchange rates.`}

            />
          </div>
          <div className="tw-flex tw-items-end">
            <h5 className="tw-mr-2 tw-font-semibold">{`${formatMoneyV2(mainCreditCard.amountDue || DEFAULT_AMOUNT)} ${
              mainCreditCard.amountDue.currency
            }`}</h5>
          </div>
        </div> */}
        {showPaymentDue && (
          <div className="tw-mb-8">
            <small className="tw-text-neutral-grey-2">{formatDueDate(paymentDate)}</small>
          </div>
        )}
      </div>
      <>
        <div className="tw-flex tw-items-center tw-justify-start tw-mb-4">
          <small className="tw-mr-2">Current Balances</small>
          <InfoTooltip message="The Current Balances section shows the current value of your pending and posted transactions on your Loop Card for each currency. You can review the full breakdown of these balances by expanding each currency element." />
        </div>
        <AddFundsToBalanceContextProvider>
          {balancesOrder.map((currency) => (
            <ExpandableBalance
              key={currency}
              groupedCardsInfo={groupedCardsInfo}
              currency={currency}
              openAddFundsToBalanceModal={openAddFundsToBalanceModal}
            />
          ))}
          <AddFundsToBalanceModal show={isOpenAddFundsToBalanceModal} onClose={closeAddFundsToBalanceModal} />
        </AddFundsToBalanceContextProvider>
      </>
    </div>
  );
};

const ExpandableBalance = ({ groupedCardsInfo, currency, openAddFundsToBalanceModal }) => {
  const [expanded, setExpanded] = useState(false);

  const onToggleExpand = () => setExpanded(!expanded);
  const balanceForCurrency = (b) => b.currency === currency;
  const defaultAmount = { amount: 0, currency };
  const minimumPaymentByCurrency = groupedCardsInfo.groupOngoingMinimumAmountDueByCurrency;
  const endingBalances = groupedCardsInfo.groupOngoingAmountDueByCurrency;
  const balance = groupedCardsInfo.groupBalances.find(balanceForCurrency) || defaultAmount;
  const postedTransactionsAmount = groupedCardsInfo.groupPostedBalances.find(balanceForCurrency) || defaultAmount;
  const pendingTransactionsAmount = groupedCardsInfo.groupPendingBalances.find(balanceForCurrency) || defaultAmount;
  const minimumPayment = minimumPaymentByCurrency[balance.currency] || defaultAmount;
  const amountDue = endingBalances[balance.currency] || defaultAmount;

  return (
    <div>
      <div
        className="tw-flex tw-items-center tw-justify-between tw-py-2 tw-cursor-pointer"
        onClick={onToggleExpand}
        data-testid={`${balance.currency.toLowerCase()}-balance-expand-toggle`}
      >
        <Balance balance={balance} />
        <BsChevronDown
          className={`tw-transform tw-rotate-${
            expanded ? '180' : '0'
          } tw-ease-in-out tw-duration-200 tw-transition-all`}
        />
      </div>
      <Transition
        as="div"
        className="tw-pl-12 tw-mb-4"
        show={expanded}
        enter="tw-ease-in-out tw-duration-200 tw-transition-all"
        enterFrom="tw-transform tw-opacity-0"
        enterTo="tw-transform tw-opacity-100"
        leave="tw-ease-in-out tw-duration-200 tw-transition-all"
        leaveFrom="tw-transform tw-opacity-100"
        leaveTo="tw-transform tw-opacity-0"
      >
        <CardBalances
          balance={balance}
          postedTransactionsAmount={postedTransactionsAmount}
          pendingTransactionsAmount={pendingTransactionsAmount}
          minimumPayment={minimumPayment}
          amountDue={amountDue}
          openAddFundsToBalanceModal={openAddFundsToBalanceModal}
        />
      </Transition>
    </div>
  );
};

export default CreditCardSidebar;
