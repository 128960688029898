import { useState } from 'react';

import { PayorOnboardingProps } from '../PayorOnboarding.types';

const usePayorOnboarding = ({ initialStep }: PayorOnboardingProps) => {
  const [currentStep, setCurrentStep] = useState(initialStep);

  const onPrevStep = () => {
    setCurrentStep((prevStep) => (prevStep === 1 ? prevStep : prevStep - 1));
  };

  const onNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  return {
    currentStep,
    onPrevStep,
    onNextStep,
  };
};

export default usePayorOnboarding;
