import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { AccountWallet } from 'types/wallet';
import { CardRepaymentContext } from 'context/CardRepayment';
import { FromAccountProps } from '../FromAccount.types';

const useFromAccount = ({
  availableFromAccountIds,
  transactionOriginalCurrency,
  selectedAmountInThisCurrency,
  isDisabled,
}: Omit<FromAccountProps, 'isFxAmount' | 'isMinDuePayment'>) => {
  const { wallets, bankAccounts } = useContext(CardRepaymentContext);

  const { register, watch, setError, clearErrors } = useFormContext();
  const watchFromAccount = watch(`fromAccounts.${transactionOriginalCurrency}`);

  const availableFromAccounts = useMemo(
    () => [...wallets, ...bankAccounts].filter(({ id }) => availableFromAccountIds.includes(id)),
    [wallets, bankAccounts, availableFromAccountIds]
  );

  const fromAccountsOptions = useMemo(() => {
    return availableFromAccounts.map(({ id, displayName }) => {
      return {
        value: id,
        name: displayName,
      };
    });
  }, [availableFromAccounts]);

  const validateFromAccountBalance = useCallback(
    (selectedFromAccountId?: string) => {
      if (isDisabled || !selectedFromAccountId) return true;

      const selectedFromAccount = availableFromAccounts.find(({ id }) => id === selectedFromAccountId);

      if (!selectedFromAccount || selectedFromAccount.__typename === 'BankAccount') return true;

      return (selectedFromAccount as AccountWallet).availableBalance?.amount >= selectedAmountInThisCurrency;
    },
    [selectedAmountInThisCurrency, availableFromAccounts, isDisabled]
  );

  useEffect(() => {
    const isValid = validateFromAccountBalance(watchFromAccount);

    if (isValid) {
      clearErrors(`fromAccounts.${transactionOriginalCurrency}`);
    } else {
      setError(`fromAccounts.${transactionOriginalCurrency}`, {
        type: 'manual',
        message: 'Insufficient funds',
      });
    }
  }, [watchFromAccount, validateFromAccountBalance, setError, clearErrors]);

  return {
    fromAccountsOptions,
    register,
  };
};

export default useFromAccount;
