import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const Payments3 = ({ size = '146', ...other }) => {
  const width = size;
  const height = (106 * width) / 146;

  return (
    <svg width={width} height={height} viewBox="0 0 146 99" fill="none" {...other}>
      <path
        opacity="0.5"
        d="M113.148 63.0765L83.5706 51.4288C80.5614 50.2438 77.1614 51.7226 75.9764 54.7317L68.007 74.9689C66.822 77.978 68.3007 81.378 71.3098 82.563L100.887 94.2107C103.896 95.3957 107.296 93.917 108.481 90.9079L116.451 70.6706C117.636 67.6615 116.157 64.2615 113.148 63.0765Z"
        fill="#D0E7F4"
      />
      <path
        d="M107.581 65.7201L78.0032 54.0724C74.9941 52.8874 71.5941 54.3661 70.4091 57.3752L62.4396 77.6125C61.2546 80.6216 62.7333 84.0216 65.7424 85.2066L95.3199 96.8543C98.3291 98.0393 101.729 96.5606 102.914 93.5514L110.884 73.3142C112.069 70.3051 110.59 66.9051 107.581 65.7201Z"
        stroke="#4F5154"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M68.8765 61.2676L109.351 77.2065" stroke="#4F5154" strokeWidth="0.7" strokeLinejoin="round" />
      <path
        d="M77.1923 76.679L72.5222 74.8398L71.7559 76.7857L76.4261 78.6248L77.1923 76.679Z"
        stroke="#4F5154"
        strokeWidth="0.7"
        strokeLinejoin="round"
      />
      <path
        d="M58.5973 57.3722L89.5987 24.6558C90.2572 23.9608 90.2276 22.8636 89.5327 22.2051L71.9161 5.51206C71.2212 4.85354 70.124 4.88308 69.4654 5.57803L38.4641 38.2945C37.8056 38.9894 37.8351 40.0867 38.5301 40.7452L56.1466 57.4382C56.8416 58.0967 57.9388 58.0672 58.5973 57.3722Z"
        stroke="#4F5154"
        strokeWidth="0.7"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M69.5929 33.7472C72.8855 30.2724 72.7378 24.7864 69.2631 21.4938C65.7883 18.2012 60.3023 18.3488 57.0096 21.8236C53.717 25.2984 53.8647 30.7844 57.3395 34.077C60.8142 37.3696 66.3003 37.222 69.5929 33.7472Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M70.3212 37.4376C73.6138 33.9629 73.4661 28.4768 69.9913 25.1842C66.5166 21.8916 61.0305 22.0393 57.7379 25.514C54.4453 28.9888 54.593 34.4749 58.0677 37.7675C61.5425 41.0601 67.0286 40.9124 70.3212 37.4376Z"
        stroke="#4F5154"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.949 10.2815C75.3678 11.9501 73.1885 12.9223 70.8906 12.9842C68.5926 13.046 66.3642 12.1925 64.6956 10.6113"
        stroke="#4F5154"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.5626 45.5145C44.3455 44.6883 44.958 43.716 45.3652 42.653C45.7723 41.5901 45.9661 40.4573 45.9355 39.3195C45.9048 38.1817 45.6504 37.061 45.1867 36.0215C44.723 34.982 44.059 34.044 43.2328 33.2611"
        stroke="#4F5154"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.4989 17.4359C82.9177 19.1046 82.0642 21.333 82.1261 23.6309C82.1879 25.9289 83.1601 28.1082 84.8287 29.6893"
        stroke="#4F5154"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.1121 52.6699C51.895 51.8437 52.833 51.1798 53.8725 50.716C54.912 50.2523 56.0326 49.9979 57.1705 49.9672C58.3083 49.9366 59.441 50.1304 60.504 50.5375C61.5669 50.9447 62.5393 51.5572 63.3655 52.3401"
        stroke="#4F5154"
        strokeWidth="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6656 56.328C26.7494 56.2714 26.8499 56.3719 26.7933 56.4557L21.1992 64.7466C21.1782 64.7777 21.1782 64.8184 21.1992 64.8495L26.7933 73.1405C26.8499 73.2243 26.7494 73.3247 26.6656 73.2682L18.3747 67.674C18.3436 67.653 18.3029 67.653 18.2718 67.674L9.98086 73.2682C9.89704 73.3247 9.79661 73.2243 9.85316 73.1405L15.4473 64.8495C15.4683 64.8184 15.4683 64.7777 15.4473 64.7466L9.85316 56.4557C9.79661 56.3719 9.89704 56.2714 9.98086 56.328L18.2718 61.9221C18.3029 61.9431 18.3436 61.9431 18.3747 61.9221L26.6656 56.328Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M135.629 49.4654C140.886 47.3218 143.409 41.3231 141.265 36.0669C139.122 30.8107 133.123 28.2875 127.867 30.4311C122.611 32.5748 120.087 38.5735 122.231 43.8296C124.375 49.0858 130.373 51.609 135.629 49.4654Z"
        fill="#FFE8C3"
      />
      <path
        d="M139.375 37.6362C139.375 31.9005 134.463 27.2471 128.409 27.2471C122.354 27.2471 117.442 31.9005 117.442 37.6362C117.442 43.3718 122.354 48.0253 128.409 48.0253C134.463 48.0253 139.375 43.3718 139.375 37.6362Z"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeMiterlimit="10"
      />
      <path
        d="M127.831 33.0186V42.2533"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.449 37.6367H124.368"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.4693 76.6217L56.765 75.1236L55.2669 70.8278"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.0405 74.7734L42.7327 68.3477C40.073 67.0634 39.0128 63.798 40.2951 61.1423L40.7323 60.2371"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Payments3;
