import React, { useEffect, useState, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { RadioGroup } from '@headlessui/react';
import { IoRadioButtonOn, IoRadioButtonOff } from 'react-icons/io5';
import { toast } from 'react-toastify';

import Button from 'components/Button';
import { SubmitButton, TextField } from 'components/FormFields/v2';
import { INVITE_TEAM_MEMBER } from 'graphql/team';
import { InternalContactRole, InternalContact } from 'types/user';
import { SetUserRoleFormProps } from './SetUserRoleForm.types';
import { getPermissionSet, deHumanizePermission } from '../utils';
import { getContent } from './SetUserRoleForm.utils';
import { TeamContext } from 'components/settings/Team/TeamContext';

const availableRoles = [
  { key: InternalContactRole.member, value: 'Member' },
  { key: InternalContactRole.read_only, value: 'Read Only' },
  { key: InternalContactRole.bookkeeper, value: 'Bookkeeper' },
  { key: InternalContactRole.admin, value: 'Admin' },
];

const SetUserRoleForm = ({ setCurrentStep, onSuccess, userData, setMemberId, setRole }: SetUserRoleFormProps) => {
  const [inviteTeamMember, { loading, error }] = useMutation(INVITE_TEAM_MEMBER);
  const defaultValues = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
  };
  const form = useForm({ defaultValues });
  const { handleSubmit, register } = form;
  const contactId = userData.contactId;
  const [selectedRole, setSelectedRole] = useState<InternalContactRole>(InternalContactRole.member);
  const [permissions, setPermissions] = useState<string[]>([]);
  const { setMember } = useContext(TeamContext) as unknown as {
    setMember: React.Dispatch<React.SetStateAction<InternalContact | null>>;
  };

  useEffect(() => {
    const permissionSet = getPermissionSet(selectedRole);
    setPermissions(permissionSet);
  }, [selectedRole]);

  const onSubmit = async () => {
    const inviteToast = toast.loading('Inviting Team Member...');
    try {
      const formattedPermissions = permissions.map((permission) => deHumanizePermission(permission));
      const requestData = { ...userData, role: selectedRole, permissions: formattedPermissions };
      const result = await inviteTeamMember({ variables: requestData });
      if (!result?.data?.inviteTeamMember) {
        throw new Error('Failed to invite team member');
      }
      toast.update(inviteToast, {
        render: 'Team Invite Successful!',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      if (selectedRole === InternalContactRole.member) {
        setCurrentStep(2);
        setMemberId(result.data.inviteTeamMember.id);
        setRole(selectedRole);
      } else {
        onSuccess();
        setMember(null);
      }
    } catch (err) {
      toast.update(inviteToast, {
        render: 'Failed to invite team member',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleBackClick = () => {
    setCurrentStep(0);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="tw-space-y-8">
        <div className="tw-space-y-6 tw-px-8">
          {contactId && <TextField name="contactId" value={contactId} ref={register({ required: true })} hidden />}
          <RadioGroup value={selectedRole} onChange={setSelectedRole}>
            <div className="tw-flex tw-flex-col tw-gap-y-4 tw-justify-between">
              {availableRoles.map((role) => (
                <RadioGroup.Option key={role.key} value={role.key} className="tw-w-full">
                  {({ checked }) => (
                    <div
                      className={`tw-flex tw-justify-center tw-items-center tw-p-2 tw-border tw-rounded-md tw-border-neutral-grey-3 tw-cursor-pointer tw-gap-y-4 ${
                        checked && 'tw-bg-primary-light-green'
                      }`}
                      data-testid="role"
                    >
                      {checked ? (
                        <IoRadioButtonOn
                          size={24}
                          className="tw-text-primary-dark-green tw-ml-1 tw-mr-2 tw-flex-none"
                        />
                      ) : (
                        <IoRadioButtonOff size={24} className="tw-text-neutral-grey-2 tw-ml-1 tw-mr-2 tw-flex-none" />
                      )}
                      <RadioGroup.Label className="tw-mr-2 tw-flex tw-flex-col tw-ml-1 tw-text-sm">
                        <span className="tw-font-bold">{role.value}</span>
                        <div className="tw-text-xs">{getContent(role.key)}</div>
                      </RadioGroup.Label>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          {error?.message && !loading && <div className="tw-text-semantic-error tw-text-xs">{error.message}</div>}
        </div>
        <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex tw-justify-between">
          <Button secondary type="button" onClick={handleBackClick}>
            Back
          </Button>
          <SubmitButton disabled={loading}>Send Invite</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default SetUserRoleForm;
