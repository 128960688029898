import React from 'react';

import InfoTipLine from '../svg/InfoTipLine';
import styles from './InfoTip.module.scss';

const InfoTip = ({ icon, text, forceBottom = false }) => {
  return (
    <div className={forceBottom ? styles.rootBottom : styles.root}>
      <InfoTipLine className={styles.line} />
      {icon}
      <p className="tw-flex-1">
        <small>{text}</small>
      </p>
    </div>
  );
};

export default InfoTip;
