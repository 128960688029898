// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React from 'react';
import { BiArrowBack } from 'react-icons/bi';

import { CreditCardContextProvider } from 'context/CreditCard';
import Modal from 'components/Modal/v2';
import CardsPage from 'components/home/mobile/creditCards/CardsPage';

const CreditCardsModal = ({
  cardsDataAvailable,
  connectedBankAccount,
  hasAtLeastOneBankAccountVerified,
  creditCards,
  creditCardIds,
  groupedCardsInfo,
  globalCreditLimit,
  show,
  onClose,
  refetchCreditCards,
  hasLineOfCredit,
}) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-p-4 tw-pb-8 tw-min-h-screen tw-bg-neutral-grey-3.5">
        <BiArrowBack size={24} className="tw-cursor-pointer tw-mb-3" onClick={onClose} />
        <h2 className="tw-mb-4">Cards</h2>
        <CreditCardContextProvider>
          <CardsPage
            cardsDataAvailable={cardsDataAvailable}
            connectedBankAccount={connectedBankAccount}
            hasAtLeastOneBankAccountVerified={hasAtLeastOneBankAccountVerified}
            creditCards={creditCards}
            creditCardIds={creditCardIds}
            groupedCardsInfo={groupedCardsInfo}
            globalCreditLimit={globalCreditLimit}
            hasLineOfCredit={hasLineOfCredit}
            refetchCreditCards={refetchCreditCards}
          />
        </CreditCardContextProvider>
      </div>
    </Modal>
  );
};

export default CreditCardsModal;
