import React, { useContext } from 'react';

import { CreateCardContext } from '../../context/CreateCardContext';
import Close from 'components/svg/Close';
import { ProgressBarSkeleton } from 'components/UI';

const LoadingScreen = () => {
  const { onClose, steps, hasNoCards } = useContext(CreateCardContext);
  const stepCount = Object.keys(steps).length;

  return (
    <div className="tw-flex-col tw-h-full tw-flex-grow tw-flex tw-items-center">
      <div className="tw-flex tw-justify-end tw-items-center tw-w-full tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-flex-grow tw-items-center">
        <div className="tw-flex tw-flex-col tw-gap-4 tw-py-8 tw-w-full lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0">
          <ProgressBarSkeleton className="tw-mb-4" stepCount={stepCount} />
          <div className="tw-relative tw-flex tw-flex-row tw-gap-4 tw-justify-center tw-w-full tw-h-48">
            <div className="tw-h-48 tw-w-full tw-rounded-md tw-bg-neutral-grey-4 tw-animate-pulse" />
            {!hasNoCards && <div className="tw-h-48 tw-w-full tw-rounded-md tw-bg-neutral-grey-4 tw-animate-pulse" />}
          </div>
          <div className="tw-mt-2 tw-flex tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-w-full tw-h-12" />
          <div className="tw-mt-2 tw-flex tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-w-full tw-h-12" />
        </div>
        <div className="tw-bg-neutral-grey-4.5 tw-animate-pulse tw-w-full tw-rounded-b-md tw-mt-auto tw-py-8 tw-h-28"></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
