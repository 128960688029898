import React from 'react';

import { DesktopHeader, MobileHeader } from './components';
import { HeaderProps } from './Header.types';
import { DEFAULT_DROPDOWN_MENU } from './constants';

const Header = ({ dropDownNavigation }: HeaderProps) => (
  <div className="layout-header">
    <DesktopHeader dropDownNavigation={dropDownNavigation || DEFAULT_DROPDOWN_MENU} />
    <MobileHeader />
  </div>
);

export default Header;
