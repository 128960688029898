import React from 'react';

const EyeClosed = ({ size = '24', ...other }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M20.2501 20.9996C20.1516 20.9998 20.054 20.9804 19.963 20.9427C19.872 20.905 19.7894 20.8496 19.7199 20.7798L3.21994 4.27976C3.08522 4.13797 3.01123 3.94915 3.01373 3.75358C3.01624 3.55801 3.09504 3.37115 3.23334 3.23285C3.37164 3.09455 3.5585 3.01575 3.75407 3.01325C3.94964 3.01074 4.13845 3.08473 4.28025 3.21945L20.7803 19.7194C20.8851 19.8243 20.9564 19.9579 20.9854 20.1034C21.0143 20.2488 20.9994 20.3996 20.9427 20.5366C20.8859 20.6736 20.7899 20.7907 20.6666 20.8731C20.5433 20.9555 20.3984 20.9995 20.2501 20.9996Z"
        fill="currentColor"
      />
      <path
        d="M11.984 18.0003C10.0392 18.0003 8.16371 17.4247 6.40965 16.2893C4.81261 15.2581 3.37496 13.7811 2.25183 12.0237V12.02C3.18652 10.6807 4.21027 9.54824 5.30996 8.63512C5.31991 8.6268 5.32802 8.61651 5.33379 8.6049C5.33956 8.59329 5.34285 8.5806 5.34347 8.56765C5.34409 8.5547 5.34201 8.54176 5.33737 8.52965C5.33273 8.51755 5.32563 8.50653 5.31652 8.49731L4.38277 7.56496C4.36618 7.54823 4.34389 7.53837 4.32035 7.53732C4.29682 7.53628 4.27375 7.54414 4.25574 7.55934C3.08761 8.54371 2.0034 9.75121 1.01761 11.165C0.848014 11.4084 0.754608 11.6967 0.749266 11.9934C0.743924 12.29 0.826888 12.5815 0.987615 12.8309C2.22558 14.7682 3.81886 16.4 5.59449 17.5489C7.59371 18.844 9.74527 19.5003 11.984 19.5003C13.1924 19.4965 14.3923 19.2974 15.5371 18.9106C15.5522 18.9055 15.5658 18.8966 15.5765 18.8848C15.5872 18.8729 15.5947 18.8586 15.5983 18.843C15.602 18.8275 15.6015 18.8113 15.5972 18.796C15.5928 18.7807 15.5845 18.7667 15.5732 18.7554L14.5617 17.7439C14.5384 17.7212 14.5096 17.7049 14.4781 17.6967C14.4466 17.6884 14.4136 17.6886 14.3821 17.697C13.5987 17.8987 12.793 18.0006 11.984 18.0003Z"
        fill="currentColor"
      />
      <path
        d="M23.0081 11.1844C21.7678 9.26625 20.1586 7.63688 18.3548 6.47203C16.3594 5.18203 14.1562 4.5 11.9841 4.5C10.7884 4.50212 9.60172 4.70543 8.47358 5.10141C8.45855 5.10664 8.4451 5.11561 8.4345 5.12747C8.42389 5.13934 8.41648 5.15371 8.41296 5.16923C8.40945 5.18475 8.40994 5.20091 8.41439 5.21619C8.41885 5.23147 8.42712 5.24536 8.43843 5.25656L9.44858 6.26672C9.4721 6.28984 9.5013 6.30634 9.53323 6.31457C9.56516 6.3228 9.5987 6.32246 9.63046 6.31359C10.3978 6.10602 11.1891 6.00058 11.9841 6C13.8914 6 15.7612 6.58266 17.5411 7.73438C19.1681 8.78438 20.6226 10.26 21.7486 12C21.7494 12.0011 21.7499 12.0024 21.7499 12.0037C21.7499 12.0051 21.7494 12.0064 21.7486 12.0075C20.9313 13.2942 19.9171 14.4446 18.743 15.4167C18.7329 15.425 18.7247 15.4353 18.7188 15.4469C18.713 15.4586 18.7096 15.4713 18.7089 15.4843C18.7083 15.4973 18.7103 15.5103 18.715 15.5225C18.7196 15.5347 18.7268 15.5457 18.7359 15.555L19.6687 16.4873C19.6852 16.504 19.7074 16.5139 19.7308 16.515C19.7543 16.5161 19.7773 16.5084 19.7953 16.4934C21.0487 15.4381 22.1337 14.1975 23.0128 12.8147C23.1682 12.571 23.2503 12.2878 23.2495 11.9988C23.2487 11.7098 23.1649 11.4272 23.0081 11.1844Z"
        fill="currentColor"
      />
      <path
        d="M12 7.5C11.6629 7.49982 11.3269 7.53756 10.9983 7.6125C10.9817 7.61595 10.9663 7.62383 10.9538 7.63531C10.9414 7.64679 10.9322 7.66144 10.9274 7.6777C10.9226 7.69395 10.9223 7.71121 10.9265 7.72763C10.9308 7.74405 10.9393 7.75901 10.9514 7.77094L16.2291 13.0472C16.241 13.0592 16.256 13.0678 16.2724 13.072C16.2888 13.0763 16.306 13.076 16.3223 13.0711C16.3386 13.0663 16.3532 13.0572 16.3647 13.0447C16.3762 13.0323 16.3841 13.0169 16.3875 13.0003C16.5378 12.3413 16.5376 11.6568 16.3871 10.9979C16.2365 10.3389 15.9395 9.72227 15.518 9.19385C15.0965 8.66542 14.5613 8.23874 13.9523 7.9455C13.3432 7.65226 12.676 7.49999 12 7.5Z"
        fill="currentColor"
      />
      <path
        d="M7.77091 10.953C7.75899 10.9409 7.74402 10.9323 7.7276 10.9281C7.71118 10.9239 7.69392 10.9242 7.67767 10.929C7.66141 10.9338 7.64676 10.943 7.63528 10.9554C7.6238 10.9679 7.61592 10.9833 7.61247 10.9999C7.44248 11.7425 7.46382 12.5162 7.67451 13.2483C7.88519 13.9805 8.27832 14.6472 8.81704 15.1859C9.35577 15.7246 10.0225 16.1178 10.7546 16.3284C11.4868 16.5391 12.2604 16.5605 13.0031 16.3905C13.0197 16.387 13.0351 16.3792 13.0475 16.3677C13.06 16.3562 13.0691 16.3415 13.0739 16.3253C13.0788 16.309 13.0791 16.2918 13.0748 16.2754C13.0706 16.2589 13.062 16.244 13.05 16.232L7.77091 10.953Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EyeClosed;
