import { useContext } from 'react';

import useIsAdmin from 'hooks/useIsAdmin';
import useIsMember from 'hooks/useIsMember';
import { CompletedTableProps } from '../CompletedTable.types';
import { ApprovalRequestsContext } from 'components/Approvals/ApprovalsContent/context/ApprovalRequests';

const useCompletedTable = ({ completedApprovalsList }: CompletedTableProps) => {
  const { selectedTab, payees, wallets, lineOfCredit, bankAccounts } = useContext(ApprovalRequestsContext);

  const { isAdmin } = useIsAdmin();
  const { isMember } = useIsMember();

  const noApprovalsAvailable = completedApprovalsList.length <= 0;
  const viewOnly = !isAdmin && !isMember;

  return {
    isMember,
    isAdmin,
    noApprovalsAvailable,
    selectedTab,
    payees,
    wallets,
    lineOfCredit,
    bankAccounts,
    viewOnly,
  };
};
export default useCompletedTable;
