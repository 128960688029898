import React from 'react';

import config from 'config';
import LoopLogo from 'components/svg/LoopLogo';

const Header = () => (
  <div className="tw-flex tw-justify-start tw-items-center tw-w-full tw-gap-4 tw-text-neutral-light tw-text-sm">
    Powered by
    <a href={config.landingPageUrl} target="_blank" rel="noopener noreferrer">
      <LoopLogo color="#ffffff" width="6rem" />
    </a>
  </div>
);

export default Header;
