import React from 'react';

import { Modal } from 'components/UI';
import { ConfirmPayment } from './components';
import { ConfirmPaymentModalProps } from './ConfirmPaymentModal.types';

const ConfirmPaymentModal = ({
  show,
  onClose,
  selectedPaymentRequestId,
  selectedBankAccountId,
}: ConfirmPaymentModalProps) => {
  return (
    <Modal show={show} onClose={onClose} title="Payment Request Details">
      <div className="tw-flex tw-flex-col tw-px-8 tw-pt-6 tw-pb-8 tw-space-y-4">
        <ConfirmPayment
          selectedPaymentRequestId={selectedPaymentRequestId}
          selectedBankAccountId={selectedBankAccountId}
          onClose={onClose}
        />
      </div>
    </Modal>
  );
};

export default ConfirmPaymentModal;
