import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import {
  GET_ASSET_ACCOUNTS,
  GET_ALL_ACCOUNTS,
  GET_WALLET_ASSET_ACCOUNT_MAPPING,
  GET_WALLET_TRANSACTION_CATEGORY_ACCOUNTING_INTEGRATION_MAPPING,
  GET_QBO_TAX_CODES,
} from 'graphql/accountingServices';

export const AccountingServiceWalletTransactionsContext = React.createContext({});

export const WalletTransactionsProvider = (props) => {
  // ---- Liability Account Context Section ---- //
  const { data: assetAccounts } = useQuery(GET_ASSET_ACCOUNTS);
  const unsortedQuickBooksLiabilityAccounts = get(assetAccounts, 'accountingIntegrationAssetRevenueAccounts', []) || [];
  const accountingIntegrationLiabilityAccounts = [...unsortedQuickBooksLiabilityAccounts].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  //

  // ---- Expense Account Context Section ---- //
  const { data: allAccounts } = useQuery(GET_ALL_ACCOUNTS);
  const unsortedQuickBooksExpenseAccounts = get(allAccounts, 'accountingIntegrationAllAccounts', []) || [];
  const accountingIntegrationExpenseAccounts = [...unsortedQuickBooksExpenseAccounts].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const [selectedExpenseAccounts, setSelectedExpenseAccounts] = useState({});
  const handleChangeExpenseAccount = (expenseAccount, transactionId) => {
    const accounts = { ...selectedExpenseAccounts };
    accounts[transactionId] = expenseAccount;
    setSelectedExpenseAccounts(accounts);
    expenseAccount?.taxCodeRefId !== null &&
      handleChangeTaxCode(getTaxCodeById(expenseAccount?.taxCodeRefId), transactionId);
  };

  const resetExpense = (transactionId) => {
    const expenses = { ...selectedExpenseAccounts };
    delete expenses[transactionId];
    setSelectedExpenseAccounts(expenses);
  };
  const { data: walletMappings } = useQuery(GET_WALLET_TRANSACTION_CATEGORY_ACCOUNTING_INTEGRATION_MAPPING);
  const transactionCategoryAccountingIntegrationMapping =
    get(walletMappings, 'walletTransactionCategoryAccountingIntegrationMapping', []) || [];

  const getFromMapping = (categoryId) => {
    const map = transactionCategoryAccountingIntegrationMapping?.find(
      (mapping) => mapping.transactionCategoryId === categoryId
    );
    if (!map) return null;
    return accountingIntegrationExpenseAccounts?.find((account) => account.id === map?.quickBooksAccountId);
  };

  const getExpenseAccountById = (qboExpenseAccountId) =>
    accountingIntegrationExpenseAccounts?.find((item) => item.id === qboExpenseAccountId);

  const NULL_EXPENSE = { id: '-1', name: '- Select' };
  const getSelectedExpense = (transactionId, qboExpenseAccountId, transactionCategory) =>
    getExpenseAccountById(selectedExpenseAccounts?.[transactionId]?.id) ||
    getExpenseAccountById(qboExpenseAccountId) ||
    getFromMapping(transactionCategory?.transactionCategoryId) ||
    NULL_EXPENSE;

  // ---- END Expense Account Context Section ---- //

  // ---- Tax Code Context Section ---- //
  const { data: dataTaxRates, loading: loadingTaxCodes, error: errorTaxCodes } = useQuery(GET_QBO_TAX_CODES);
  const unsortedQboTaxCodes = get(dataTaxRates, 'accountingIntegrationTaxCodes', []) || [];
  const accountingIntegrationTaxCodes = [...unsortedQboTaxCodes].sort((a, b) => a.name.localeCompare(b.name));

  const [selectedTaxCodes, setSelectedTaxCodes] = useState({});
  const handleChangeTaxCode = (taxCode, transactionId) => {
    const codes = { ...selectedTaxCodes };
    codes[transactionId] = taxCode;
    setSelectedTaxCodes(codes);
  };
  const resetTaxCode = (transactionId) => {
    const codes = { ...selectedTaxCodes };
    delete codes[transactionId];
    setSelectedTaxCodes(codes);
  };

  const getTaxCodeById = (taxCodeId) => accountingIntegrationTaxCodes?.find((taxCode) => taxCode.id === taxCodeId);

  const getSelectedTaxCode = (transactionId, qboTaxId) => {
    const NULL_TAXCODE = { id: null, name: '- Select' };

    if (loadingTaxCodes) return NULL_TAXCODE;

    return selectedTaxCodes?.[transactionId] || getTaxCodeById(qboTaxId) || NULL_TAXCODE;
  };

  // ---- END Tax Code Context Section ---- //

  // ---- Dates Context Section ---- //
  const [selectedDates, setSelectedDates] = useState({});
  const handleChangeDate = (date, transactionId) => {
    const dates = { ...selectedDates };
    dates[transactionId] = date;
    setSelectedDates(dates);
  };

  const resetDate = (transactionId) => {
    const dates = { ...selectedDates };
    delete dates[transactionId];
    setSelectedDates(dates);
  };
  // ---- END Dates Context Section ---- //

  // ---- Internal Helpers ---- //

  /*
  const getExpenseFromCategory = (categoryId) => {
    const mapped = transactionCategoryQuickBooksMapping.find((map) => map.transactionCategoryId === categoryId);
    if (mapped) return mapped.quickBooksAccountId;
  };
  */

  const { data: dataMappings } = useQuery(GET_WALLET_ASSET_ACCOUNT_MAPPING);
  const currencyAccountingIntegrationLiabilityAccountMapping =
    get(dataMappings, 'walletAccountingIntegrationAssetAccountMapping', []) || [];

  // TODO: WE should memoize this function since always cad => same account.
  const getLiabilityAccountFromCurrency = (currency) => {
    const mapping = currencyAccountingIntegrationLiabilityAccountMapping?.find(
      (elem) => elem.wallet.currency === currency
    );
    if (!mapping) return {};
    if (mapping)
      return accountingIntegrationLiabilityAccounts?.find((account) => account.id === mapping.externalAccountId);
  };

  const resetContext = () => {
    setSelectedDates({});
    setSelectedExpenseAccounts({});
    setSelectedTaxCodes({});
    setSelectedTransactionsToPost([]);
    setSelectAllToPost(false);
  };

  const isTransactionSelectableToPost = (transaction) => {
    const { transactionId, qboExpenseAccountId, walletTransactionCategory, qboStatus } = transaction;
    const selectedExpense = getSelectedExpense(transactionId, qboExpenseAccountId, walletTransactionCategory);

    return qboStatus === 'pending' && selectedExpense.id !== NULL_EXPENSE.id;
  };

  // ---- END Internal Helpers --//

  // ---- Transactions Selected To Post ---- //
  const [selectedTransactionsToPost, setSelectedTransactionsToPost] = useState([]);

  const handleSelectToPost = (evt, transactionId) => {
    setSelectAllToPost(false);
    const { checked } = evt.currentTarget;

    const transactionsToPost = checked
      ? [...selectedTransactionsToPost, transactionId]
      : selectedTransactionsToPost.filter((tid) => tid !== transactionId);

    setSelectedTransactionsToPost(transactionsToPost);
  };

  const [selectAllToPost, setSelectAllToPost] = useState(false);

  // this helper parses the selectedDates, selectedExpenseAccount and selectedTaxCode and
  // injects that data in the transactions data. This is used for push to QuickBooks.
  const parseTransactionToPost = (transaction) => {
    const { transactionId, amount, walletTransactionCategory, qboTransactionDate, qboExpenseAccountId, qboTaxId } =
      transaction;
    const expenseAccount =
      transactionId in selectedExpenseAccounts
        ? selectedExpenseAccounts[transactionId]
        : getExpenseAccountById(qboExpenseAccountId) ||
          getFromMapping(walletTransactionCategory?.transactionCategoryId);
    const liabilityAccount = getLiabilityAccountFromCurrency(amount?.currency);

    return {
      forProductType: 'Wallet',
      transactionId: `${transactionId}`,
      qboExpenseAccountId: expenseAccount?.id,
      qboExpenseAccountName: expenseAccount?.name,
      qboLiabilityAccountId: liabilityAccount?.id,
      qboLiabilityAccountName: liabilityAccount?.name,
      qboTransactionDate: transactionId in selectedDates ? selectedDates[transactionId] : qboTransactionDate,
      qboTaxId:
        transactionId in selectedTaxCodes
          ? selectedTaxCodes[transactionId]?.id || expenseAccount?.taxCodeRefId
          : qboTaxId || expenseAccount?.taxCodeRefId || accountingIntegrationTaxCodes[0]?.id || '2',
      // this assumes that qboTaxCodes[0] is the default, 'Exempt'.  Also as fallback, if not tax codes retrieves '2' is the id of Exempt.
    };
  };
  // ---- END Transactions Selected to Post --//

  // ---- Filter Qbo Transactions  --//

  // ---- End Filter Qbo Transactions  --//

  // Provider values
  return (
    <AccountingServiceWalletTransactionsContext.Provider
      value={{
        resetContext,

        selectedTransactionsToPost,
        setSelectedTransactionsToPost,
        handleSelectToPost,
        parseTransactionToPost,
        selectAllToPost,
        setSelectAllToPost,
        isTransactionSelectableToPost,

        getFromMapping,
        accountingIntegrationExpenseAccounts,
        resetExpense,
        getSelectedExpense,

        accountingIntegrationTaxCodes,
        loadingTaxCodes,
        errorTaxCodes,
        selectedTaxCodes,
        handleChangeTaxCode,
        resetTaxCode,
        getSelectedTaxCode,

        selectedDates,
        handleChangeDate,
        resetDate,

        selectedExpenseAccounts,
        handleChangeExpenseAccount,

        getLiabilityAccountFromCurrency,
      }}
    >
      {props.children}
    </AccountingServiceWalletTransactionsContext.Provider>
  );
};
