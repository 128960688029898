import React from 'react';

import { PendingIdentityVerificationBanner, PersonaVerificationModal } from './components';
import { useIdentityVerification } from './hooks';
import { IdentityVerificationProps } from './IdentityVerification.types';

const IdentityVerification = ({ onlyIfRequested, bannerMessage, modalMessage }: IdentityVerificationProps) => {
  const {
    loadingInternalContactData,
    internalContactId,
    thirdPartyVerificationRequested,
    thirdPartyVerificationComplete,
    showPersonaVerificationModal,
    openPersonaVerificationModal,
    closePersonaVerificationModal,
    handleClosePersonaVerificationModal,
  } = useIdentityVerification();

  if (loadingInternalContactData || thirdPartyVerificationComplete) return null;

  if (onlyIfRequested && !thirdPartyVerificationRequested) return null;

  return (
    <>
      <PendingIdentityVerificationBanner onSubmit={openPersonaVerificationModal} message={bannerMessage} />

      {showPersonaVerificationModal && (
        <PersonaVerificationModal
          show={showPersonaVerificationModal}
          onClose={closePersonaVerificationModal}
          internalContactId={internalContactId}
          onCompleted={handleClosePersonaVerificationModal}
          message={modalMessage}
        />
      )}
    </>
  );
};

export default IdentityVerification;
