import React from 'react';

import useIsMobile from 'hooks/useIsMobile';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import DesktopHomeContainer from 'containers/DesktopHome';
import MobileHomeContainer from 'containers/MobileHome';

const Home = () => {
  const isMobile = useIsMobile();

  return (
    <DashboardLayout title="Home">
      <div className="tw-hidden lg:tw-flex">{!isMobile && <DesktopHomeContainer />}</div>
      <div className="tw-flex tw-flex-col lg:tw-hidden">{isMobile && <MobileHomeContainer />}</div>
    </DashboardLayout>
  );
};

export default Home;
