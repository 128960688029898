import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Transition } from '@headlessui/react';

import { FormFooter } from 'components/UI';
import { CurrencyOptions, PaymentTypes, Transactions } from './components';
import { useDetails } from './hooks';
import { DetailsProps } from './Details.types';
import { PAYMENT_CURRENCY_OPTIONS } from './components/CurrencyOptions/constants';
import { INTO_TOOLTIP } from './constants';

import styles from './Details.module.scss';

const Details = ({ onNextStep }: DetailsProps) => {
  const {
    form,
    handleSubmit,
    handleNextStep,
    transactions,
    singleTransaction,
    paymentTypeOptions,
    isNothingToPay,
    error,
    isShowPaymentTypes,
    isShowTransactions,
    isMinDuePayment,
  } = useDetails({
    onNextStep,
  });

  return (
    <>
      <div className="tw-text-sm tw-mb-8">
        Pay your balances individually per currency, or altogether in Canadian or U.S. Dollars. Select the option below,
        then choose the account you would like to make the payment from.
        <div className="tw-mt-2">
          Make a custom amount for an individual card balance on the <b>Balances and Limit</b> page under the{' '}
          <b>Statements</b> section. Select the current statement cycle, and then the <b>Add</b> button below the
          desired currency.
        </div>
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleNextStep)} className={styles.form}>
          <CurrencyOptions options={PAYMENT_CURRENCY_OPTIONS} infoTooltipMessage={INTO_TOOLTIP.paymentCurrency} />

          <Transition
            show={isShowPaymentTypes}
            enter="tw-transition-opacity tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
          >
            <PaymentTypes paymentTypeOptions={paymentTypeOptions} />
          </Transition>

          <Transition
            show={isShowTransactions}
            enter="tw-transition-opacity tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
          >
            <Transactions
              transactions={transactions}
              singleTransaction={singleTransaction}
              isMinDuePayment={isMinDuePayment}
            />
          </Transition>

          {error?.message && <div className="tw-text-semantic-error tw-text-xs">{error.message}</div>}

          <div className="tw-flex xl:tw-gap-4 tw-justify-end xl:tw-justify-start tw-w-full tw-mt-4">
            <div className="tw-flex tw-items-center tw-gap-1 tw-w-40 xl:tw-w-1/6 tw-h-12"></div>
            <FormFooter
              submitButtonLabel={isNothingToPay ? 'Select Payment Type' : 'Review'}
              isDisabled={isNothingToPay || !!error?.message}
              hideSubmitButtonIcon={isNothingToPay}
              rootClass="xl:tw-justify-start"
            />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default Details;
