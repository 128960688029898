import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';

import { VERIFY_ATTRIBUTE } from 'graphql/settings';
import { TextField } from 'components/FormFields/v2';
import Button from 'components/Button';

const VerifyPhone = (props) => {
  const form = useForm();
  const { register, handleSubmit, reset } = form;

  const { onSuccess, handleCloseModal } = props;

  const [success, setSuccess] = useState();

  const [verifyAttribute, { loading: verifying, error }] = useMutation(VERIFY_ATTRIBUTE);

  const onSubmit = async ({ verificationCode }) => {
    setSuccess();
    try {
      await verifyAttribute({
        variables: { attributeName: 'phoneNumber', verificationCode },
      });

      handleCloseModal();
      setSuccess(true);
      setTimeout(() => onSuccess && onSuccess(), 4000);
    } catch (err) {
      console.error(err);
    } finally {
      reset();
    }
  };

  return (
    <>
      <h3 className="tw-px-10 tw-text-2xl tw-font-semibold mb-3">Verify Your phone number</h3>
      <p className="tw-px-10 tw-text-neutral-grey-2">
        Please enter the verification code that was sent to your phone number
      </p>

      <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-flex tw-w-full tw-flex-col tw-justify-center tw-p-10">
            <TextField
              type="text"
              name="verificationCode"
              label="Verification Code"
              ref={register({ required: true })}
            />
          </div>
          <hr />
          <div className="tw-p-6 tw-flex tw-justify-end">
            {success ? <div className="text-success my-2 small float-left">{success}</div> : null}
            <Button
              primary
              type="submit"
              className="text-right tw-px-6"
              onClick={handleSubmit}
              required
              disabled={verifying}
            >
              {verifying ? 'Verifying...' : 'Verify'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default VerifyPhone;
