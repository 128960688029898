import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Theme } from 'styles/tailwind';
import { Loaders } from 'components/cards/Loader';
import { OwnershipDisclosureState } from '../../OwnershipDisclosure.types';

const Complete = () => {
  const { search } = useLocation();

  const event = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('event') as OwnershipDisclosureState | null;
  }, [search]);

  useEffect(() => {
    if (!event) {
      throw new Error('Event is not provided');
    }

    window.parent.postMessage({ ownershipDisclosure: event }, '*');
  }, [event]);

  return (
    <Theme>
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-max-w-md tw-m-auto tw-py-16">
        <Loaders.Light />
      </div>
    </Theme>
  );
};

export default Complete;
