import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

import config from 'config';
import { FirstDepositModalProps } from './FirstDepositModal.types';
import { useFirstDepositModal } from './hooks';
import { Modal } from 'components/UI';
import WalletAndCash from 'components/svg/WalletAndCash';
import Button, { IconAnimation } from 'components/Button';
import { DepositInformation } from './components';

const FirstDepositModal = ({ show, onClose }: FirstDepositModalProps) => {
  const { showNextSection, handleClick } = useFirstDepositModal({ show });

  const saleChannelsUrl = `${config.landingPageUrl}/blog/shopify-stores-save-with-loop`;

  return (
    <Modal show={show} onClose={onClose} title={showNextSection ? 'Deposit To' : 'Receive Your First Deposit'}>
      <div className="tw-p-8">
        {showNextSection ? (
          <DepositInformation />
        ) : (
          <>
            <div className="tw-flex tw-justify-center">
              <WalletAndCash />
            </div>
            <h3 className="tw-my-6">Receive Your First Deposit With Loop</h3>
            <p>
              You can receive deposits into your Loop accounts the same way as a traditional bank account or you can
              connect your sales channels to receive payouts directly into your accounts.
            </p>
            <p className="tw-my-6">
              Read more about connecting your sales channels (ex. Shopify) here:
              <a
                href={saleChannelsUrl}
                className="hover:tw-text-primary-dark-green tw-font-bold tw-block tw-mt-2"
                target="_blank"
                rel="noreferrer"
              >
                {saleChannelsUrl}
              </a>
            </p>
            <Button primary onClick={handleClick} iconAnimation={IconAnimation.rollOut}>
              View Direct Deposit Information <BsArrowRight className="tw-ml-2" />
            </Button>
            <div className="tw-text-xs tw-mt-6">*Points may take up to 24 hours to appear on your account</div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default FirstDepositModal;
