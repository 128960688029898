import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const CashAndStarsAlt = ({ width = '86', height = '79', vbWidth = '86', vbHeight = '79', ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M20.0268 8.53607C20.209 8.4131 20.4274 8.63147 20.3044 8.81372L16.2597 14.8084C16.2141 14.876 16.2141 14.9645 16.2597 15.0321L20.3044 21.0267C20.4274 21.209 20.209 21.4273 20.0268 21.3044L14.0322 17.2596C13.9646 17.214 13.876 17.214 13.8084 17.2596L7.8138 21.3044C7.63154 21.4273 7.41317 21.209 7.53614 21.0267L11.5809 15.0321C11.6265 14.9645 11.6265 14.876 11.5809 14.8083L7.53614 8.81372C7.41317 8.63147 7.63154 8.4131 7.8138 8.53607L13.8084 12.5808C13.876 12.6264 13.9646 12.6264 14.0322 12.5808L20.0268 8.53607Z"
        fill="#D0E7F4"
      />
      <path
        d="M55.4341 9.62586C55.6352 9.71457 55.5668 10.0157 55.347 10.0088L50.4751 9.8546C50.3936 9.85202 50.3187 9.89919 50.2858 9.97381L48.3192 14.4338C48.2305 14.635 47.9293 14.5665 47.9363 14.3468L48.0904 9.47489C48.093 9.39338 48.0459 9.31846 47.9712 9.28556L43.5112 7.31894C43.3101 7.23023 43.3785 6.92908 43.5982 6.93604L48.4702 7.0902C48.5517 7.09278 48.6266 7.04561 48.6595 6.97099L50.6261 2.51098C50.7148 2.30981 51.016 2.37825 51.009 2.598L50.8548 7.46991C50.8523 7.55142 50.8994 7.62634 50.974 7.65924L55.4341 9.62586Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M77.6464 57.076C77.8287 56.953 78.0471 57.1714 77.9241 57.3536L73.515 63.8882C73.4694 63.9558 73.4694 64.0443 73.515 64.1119L77.9241 70.6465C78.0471 70.8287 77.8287 71.0471 77.6464 70.9241L71.1119 66.5151C71.0443 66.4694 70.9558 66.4694 70.8882 66.5151L64.3536 70.9241C64.1714 71.0471 63.953 70.8287 64.0759 70.6465L68.485 64.1119C68.5306 64.0443 68.5306 63.9558 68.485 63.8882L64.0759 57.3536C63.953 57.1714 64.1714 56.953 64.3536 57.076L70.8882 61.485C70.9558 61.5307 71.0443 61.5307 71.1119 61.485L77.6464 57.076Z"
        fill="#EED9F7"
      />
      <path
        d="M17.5001 48.75L20.5001 51.75L23.5001 48.75"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5001 51L20.5001 37.2188C20.5001 34.4644 22.8125 32.25 25.5626 32.25L26.5001 32.25"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.5001 48.25L57.5001 45.25L54.5001 48.25"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.5001 46L57.5001 59.7812C57.5001 62.5356 55.1878 64.75 52.4376 64.75L51.5001 64.75"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_3347_86508)">
        <path
          d="M33.4689 38.2813L63.5314 38.2812C64.17 38.2812 64.6876 37.7636 64.6876 37.125V20.9375C64.6876 20.2989 64.17 19.7812 63.5314 19.7812L33.4689 19.7813C32.8303 19.7813 32.3126 20.2989 32.3126 20.9375V37.125C32.3126 37.7636 32.8303 38.2813 33.4689 38.2813Z"
          fill="#FFE8C3"
        />
        <path
          d="M30.4689 41.2813L60.5314 41.2812C61.17 41.2812 61.6876 40.7636 61.6876 40.125V23.9375C61.6876 23.2989 61.17 22.7812 60.5314 22.7812L30.4689 22.7813C29.8303 22.7813 29.3126 23.2989 29.3126 23.9375V40.125C29.3126 40.7636 29.8303 41.2813 30.4689 41.2813Z"
          stroke="#4F5154"
          strokeLinejoin="round"
        />
        <path
          d="M45.5001 37.8125C48.693 37.8125 51.2814 35.2241 51.2814 32.0312C51.2814 28.8384 48.693 26.25 45.5001 26.25C42.3072 26.25 39.7189 28.8384 39.7189 32.0312C39.7189 35.2241 42.3072 37.8125 45.5001 37.8125Z"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.6876 28.5625C60.1543 28.5625 58.6839 27.9534 57.5997 26.8692C56.5155 25.785 55.9064 24.3145 55.9064 22.7812"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.3126 28.5625C30.0718 28.5625 30.8236 28.413 31.525 28.1224C32.2264 27.8319 32.8637 27.406 33.4006 26.8692C33.9374 26.3324 34.3633 25.6951 34.6538 24.9936C34.9443 24.2922 35.0939 23.5405 35.0939 22.7812"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.6876 35.5C60.1543 35.5 58.6839 36.1091 57.5997 37.1933C56.5155 38.2775 55.9064 39.748 55.9064 41.2812"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.3126 35.5C30.0718 35.5 30.8236 35.6495 31.525 35.9401C32.2264 36.2306 32.8637 36.6565 33.4006 37.1933C33.9374 37.7301 34.3633 38.3674 34.6538 39.0689C34.9443 39.7703 35.0939 40.522 35.0939 41.2812"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip1_3347_86508)">
        <path
          d="M17.4689 74.2812H47.5314C48.17 74.2812 48.6876 73.7636 48.6876 73.125V56.9375C48.6876 56.2989 48.17 55.7812 47.5314 55.7812H17.4689C16.8303 55.7812 16.3126 56.2989 16.3126 56.9375V73.125C16.3126 73.7636 16.8303 74.2812 17.4689 74.2812Z"
          stroke="#4F5154"
          strokeLinejoin="round"
        />
        <path
          d="M33.5001 69.8125C36.693 69.8125 39.2814 67.2241 39.2814 64.0312C39.2814 60.8384 36.693 58.25 33.5001 58.25C30.3072 58.25 27.7189 60.8384 27.7189 64.0312C27.7189 67.2241 30.3072 69.8125 33.5001 69.8125Z"
          fill="#D0E7DA"
        />
        <path
          d="M32.5001 70.8125C35.693 70.8125 38.2814 68.2241 38.2814 65.0312C38.2814 61.8384 35.693 59.25 32.5001 59.25C29.3072 59.25 26.7189 61.8384 26.7189 65.0312C26.7189 68.2241 29.3072 70.8125 32.5001 70.8125Z"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.6876 61.5625C47.1543 61.5625 45.6839 60.9534 44.5997 59.8692C43.5155 58.785 42.9064 57.3145 42.9064 55.7812"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3126 61.5625C17.0718 61.5625 17.8236 61.413 18.525 61.1224C19.2264 60.8319 19.8637 60.406 20.4006 59.8692C20.9374 59.3324 21.3633 58.6951 21.6538 57.9936C21.9443 57.2922 22.0939 56.5405 22.0939 55.7812"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.6876 68.5C47.1543 68.5 45.6839 69.1091 44.5997 70.1933C43.5155 71.2775 42.9064 72.748 42.9064 74.2812"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3126 68.5C17.0718 68.5 17.8236 68.6495 18.525 68.9401C19.2264 69.2306 19.8637 69.6565 20.4006 70.1933C20.9374 70.7301 21.3633 71.3674 21.6538 72.0689C21.9443 72.7703 22.0939 73.522 22.0939 74.2812"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3347_86508">
          <rect width="38" height="23" fill="white" transform="translate(28.0001 19)" />
        </clipPath>
        <clipPath id="clip1_3347_86508">
          <rect width="35" height="20" fill="white" transform="translate(15.0001 55)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CashAndStarsAlt;
