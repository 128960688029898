import React, { useState } from 'react';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';

import config from 'config';
import createFirstCardJson from 'components/lottie/createFirstCard.json';

import { CreateCardContextProvider } from 'components/creditCards/createCardFlow/CreateCardContext';
import Button from 'components/Button';
import CreateCardModal from 'components/creditCards/createCardFlow/CreateCardModal';
import CardMaintenance from 'components/creditCards/CardMaintenance';
import useIsMember from 'hooks/useIsMember';
import useIsContactPermission from 'hooks/useIsContactPermission';

const CreateFirstCardBanner = ({ creditCards, globalCreditLimit }) => {
  const [show, setShow] = useState(false);
  const disabledCreateCardButton = config.cardApiDown;

  const { createVirtualCard, createPhysicalCard, creditAgreementSigned } = useIsContactPermission();
  const canCreateCard = (createVirtualCard || createPhysicalCard) && creditAgreementSigned;
  const { isMember } = useIsMember();

  const onClose = () => {
    setShow(false);
  };

  return (
    <>
      <CardMaintenance />
      <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-bg-neutral-light tw-pb-20">
        <Lottie loop animationData={createFirstCardJson} play />
        <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-w-4/5 lg:tw-w-1/3">
          <span className="tw-text-2xl tw-mt-2">Cards</span>
          {!canCreateCard && (
            <p className="tw-my-8">
              Please contact your team manager to have cards assigned to you or to create cards.
            </p>
          )}
          {(canCreateCard || !isMember) && (
            <p className="tw-my-8">Create your first card instantly and use it to pay any of your expenses.</p>
          )}
          {canCreateCard && (
            <Button
              isDisabled={disabledCreateCardButton}
              className={`${disabledCreateCardButton ? 'tw-bg-secondary-pastel-green-100' : 'tw-bg-primary-dark-green'}
                tw-text-neutral-light tw-py-3 tw-px-12 tw-rounded-md tw-font-semibold`}
              onClick={() => setShow(true)}
            >
              Create Card
            </Button>
          )}
        </div>
        <CreateCardContextProvider>
          <CreateCardModal
            show={show}
            onClose={onClose}
            creditCards={creditCards}
            globalCreditLimit={globalCreditLimit}
          />
        </CreateCardContextProvider>
      </div>
    </>
  );
};

export default CreateFirstCardBanner;
