import { useCallback, useContext, useEffect } from 'react';
import { useQuery, ApolloError } from '@apollo/client';
import qs from 'query-string';
import { get } from 'lodash';

import history from 'history.js';
import { useToggle } from 'hooks';
import { formatMoneyV2 } from 'utility/currency';
import { formatDate } from 'utility/date';
import { GET_PAYOR_BANK_ACCOUNTS } from 'graphql/invoicing';
import { PayorBankAccount } from 'types/invoicing';
import { StepsProps } from '../../../Steps.types';
import { PayorOnboardingContext } from 'components/onboarding/PayorOnboarding/contexts/PayorOnboardingContext';
import { PAYOR_ONBOARDING_STEP_URLS } from 'components/onboarding/PayorOnboarding/constants';
import { getPaymentRequestSchedule } from 'components/Invoices/Invoices.utils';

const useConnectAccount = ({ onNextStep }: Pick<StepsProps, 'onNextStep'>) => {
  const {
    signedId,
    paymentRequest,
    getPaymentRequest,
    error,
    setError,
    isLoading: isPaymentRequestLoading,
  } = useContext(PayorOnboardingContext);

  const { data: bankAccountsData, loading: isBankAccountsLoading } = useQuery<{
    payorBankAccounts: { connectionsReady: boolean; accounts: PayorBankAccount[] };
  }>(GET_PAYOR_BANK_ACCOUNTS);

  const bankAccountsConnectionsReady = get(bankAccountsData, 'payorBankAccounts.connectionsReady', false);
  const bankAccounts = get(bankAccountsData, 'payorBankAccounts.accounts', []);

  const redirectRoute = `${PAYOR_ONBOARDING_STEP_URLS.connect_bank}?sgid=${signedId}`;

  const { amount, dueDate, schedule } = paymentRequest || {};
  const vendorName = get(paymentRequest, 'vendor.name', '');

  const currency = amount?.currency;

  const formattedAmount = formatMoneyV2(amount);
  const formattedDueDate = formatDate(dueDate);

  const {
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  } = getPaymentRequestSchedule(schedule);

  const {
    failed: failedConnection,
    success: successConnection,
    warning: warningConnection,
  } = qs.parse(location.search);

  const isBankAccountConnected = bankAccountsConnectionsReady && !!bankAccounts.length;
  const isBankAccountsConnectionError = !bankAccountsConnectionsReady;

  const {
    isOpen: showSkipConfirmationModal,
    open: openSkipConfirmationModal,
    close: closeSkipConfirmationModal,
  } = useToggle();

  const { isOpen: isManualForm, open: openManualForm } = useToggle(false);

  const handleConfirmSkipping = useCallback(() => {
    closeSkipConfirmationModal();

    history.push('/payor/portal');
  }, [closeSkipConfirmationModal]);

  const onSubmit = useCallback(() => {
    if (error) return;

    history.push(`${PAYOR_ONBOARDING_STEP_URLS.confirm_payment}?sgid=${signedId}`);
    onNextStep();
  }, [onNextStep, error, isBankAccountConnected, signedId]);

  useEffect(() => {
    if (successConnection === 'Flinks') onSubmit();
  }, [successConnection, onSubmit]);

  useEffect(() => {
    if (failedConnection === 'Flinks') {
      setError({ message: 'Unable to pull Bank Account details from Flinks' } as ApolloError);
      openManualForm();
    }
  }, [failedConnection, setError, openManualForm]);

  useEffect(() => {
    if (warningConnection === 'Flinks') {
      openManualForm();
    }
  }, [warningConnection, openManualForm]);

  useEffect(() => {
    if (!signedId) {
      setError({ message: 'Invalid signedId' } as ApolloError);
      return;
    }

    getPaymentRequest({ variables: { signedId } });
  }, [signedId, getPaymentRequest, setError]);

  return {
    isLoading: isPaymentRequestLoading || isBankAccountsLoading,
    isError: !!error,
    vendorName,
    formattedAmount,
    currency,
    formattedDueDate,
    onSkip: openSkipConfirmationModal,
    onSubmit,
    showSkipConfirmationModal,
    handleConfirmSkipping,
    closeSkipConfirmationModal,
    isBankAccountConnected,
    isBankAccountsConnectionError,
    redirectRoute,
    isManualForm,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  };
};

export default useConnectAccount;
