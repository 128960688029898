import React from 'react';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

import Button, { IconAnimation } from 'components/Button';
import { AddFundsToBalanceContextProvider } from 'components/creditCards/context/AddFundsToBalance';
import { AddFundsToBalanceModal } from 'components/creditCards/components';
import { DownloadStatements, ErrorState } from 'components/creditCards/components/CardBalances/components';
import { StatementBalancesSkeleton, StatementDetails, PeriodSelector, StatementStatus } from './components';
import { useStatementBalances } from './hooks';

const StatementBalances = () => {
  const {
    pageTitle,
    selectedStatementId,
    setSelectedStatementId,
    currentCycleStartDate,
    statementsList,
    statement,
    isOpenAddFundsToBalanceModal,
    openAddFundsToBalanceModal,
    closeAddFundsToBalanceModal,
    isLoading,
    isError,
    isLoadingStatementDetails,
    isStatementDetailsError,
    isCurrentCycle,
    isLatestStatement,
    ongoingAmountDueByCurrency,
    ongoingPayments,
    isDueDatePassed,
    statementStatus,
    isAdmin,
  } = useStatementBalances();

  if (isLoading) return <StatementBalancesSkeleton pageTitle={pageTitle} />;

  if (isError || isStatementDetailsError) return <ErrorState title={pageTitle} showShadow />;

  return (
    <>
      <section className="tw-bg-neutral-light md:tw-shadow-default tw-rounded tw-py-6 tw-pb-4 tw-px-4">
        <div className="tw-flex tw-flex-col xl:tw-flex-row xl:tw-justify-between tw-items-start xl:tw-items-center tw-gap-4 tw-mb-4">
          <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between md:tw-items-center tw-gap-4">
            <h2>{pageTitle}</h2>

            {!isLoadingStatementDetails && !isCurrentCycle && statementStatus && (
              <StatementStatus status={statementStatus} isDueDatePassed={isDueDatePassed} />
            )}
          </div>

          {isAdmin && (
            <Button primary className="tw-font-bold xl:tw-ml-auto" iconAnimation={IconAnimation.rollOut}>
              <Link
                to="/dashboard/payments/pay-balance"
                className="tw-flex tw-gap-2 tw-items-center hover:tw-text-neutral-light tw-whitespace-nowrap"
              >
                Pay Balance <BsArrowRight />
              </Link>
            </Button>
          )}

          <PeriodSelector
            selectedStatementId={selectedStatementId}
            onSelect={setSelectedStatementId}
            statementsList={statementsList}
            currentCycleStartDate={currentCycleStartDate}
          />
        </div>

        <AddFundsToBalanceContextProvider>
          <StatementDetails
            statement={statement}
            openAddFundsToBalanceModal={openAddFundsToBalanceModal}
            isLoading={isLoadingStatementDetails}
            isCurrentCycle={isCurrentCycle}
            isDueDatePassed={isDueDatePassed}
            isLatestStatement={isLatestStatement}
            ongoingAmountDueByCurrency={ongoingAmountDueByCurrency}
            ongoingPayments={ongoingPayments}
            isAdmin={isAdmin}
          />

          {isAdmin && (
            <AddFundsToBalanceModal show={isOpenAddFundsToBalanceModal} onClose={closeAddFundsToBalanceModal} />
          )}
        </AddFundsToBalanceContextProvider>

        <DownloadStatements />
      </section>
    </>
  );
};

export default StatementBalances;
