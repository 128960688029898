import React from 'react';
import { useMutation } from '@apollo/client';

import { DEACTIVATE_MEMBER } from 'graphql/team';
import Remove from 'components/svg/Remove';
import Button from 'components/Button';
import { ErrorLabel } from 'components/FormFields/v2';
import { USER_ROLE_TYPES } from 'constants/index';

const DeactivateMember = ({ memberId, onSuccess, role }) => {
  const [deactivateMember, { loading, error }] = useMutation(DEACTIVATE_MEMBER);
  const [deleteCard, setDeleteCard] = React.useState(false);
  const canDeleteCard = role === USER_ROLE_TYPES?.member || role === USER_ROLE_TYPES?.admin;

  const onCheck = () => {
    setDeleteCard(!deleteCard);
  };
  const onConfirm = async () => {
    try {
      const result = await deactivateMember({ variables: { contactId: memberId, suspendCard: deleteCard } });
      if (result.data && result.data.deactivateMember) {
        onSuccess();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="tw-flex tw-flex-col tw-px-8 tw-pt-8 tw-p-4 tw-border-t tw-border-b tw-border-neutral-grey-4 tw-space-y-4">
        <div className="tw-flex tw-flex-grow tw-justify-center tw-items-center">
          <Remove />
        </div>
        <h3>Are you sure you want to deactivate this user?</h3>
        <p>This action will prevent the selected user from having access to your Loop account.</p>
        {canDeleteCard && (
          <label>
            <input type="checkbox" id="deactivate" onChange={onCheck} className="tw-mr-2" />
            Delete all cards created by this user
          </label>
        )}
        {error && <ErrorLabel error={{ message: 'Something went wrong. Please try again later.' }} />}
      </div>
      <div className="tw-flex tw-justify-end tw-px-8 tw-pt-8 tw-p-4 tw-border-t tw-border-t tw-border-neutral-grey-4">
        <Button
          className="tw-px-4 tw-py-2 tw-rounded-md tw-text-neutral-light"
          warning={true}
          onClick={onConfirm}
          isDisabled={loading}
        >
          <div className="tw-flex tw-justify-center tw-items-center">{loading ? 'Deactivating...' : 'Deactivate'}</div>
        </Button>
      </div>
    </>
  );
};

export default DeactivateMember;
