import React from 'react';

import { SearchDropdownProps } from 'components/UI/SearchDropdown/v2/SearchDropdown.types';

const SelectedOptionBadges = ({
  selectedOptions,
  customSelectedOptionsBadgeLabel,
}: Pick<SearchDropdownProps, 'selectedOptions' | 'customSelectedOptionsBadgeLabel'>) => {
  if (!Array.isArray(selectedOptions)) return null;

  return (
    <ul className="tw-flex tw-items-center tw-justify-start tw-flex-shrink-0 tw-flex-grow-0 tw-gap-2 tw-text-neutral-dark tw-bg-neutral-light tw-text-sm tw-z-10">
      {selectedOptions.map(({ label, value }) => {
        const selectedLabel = customSelectedOptionsBadgeLabel?.(value) || label;

        return (
          <li
            key={value}
            className="tw-flex tw-items-center tw-py-1 tw-px-2 tw-rounded-2xl tw-font-semibold tw-bg-neutral-grey-3.5"
          >
            {selectedLabel}
          </li>
        );
      })}
    </ul>
  );
};

export default SelectedOptionBadges;
