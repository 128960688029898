import React, { useContext } from 'react';
import cx from 'classnames';
import { ampTrackEvent } from 'amplitude';

import { ThemeContext, ThemeMode } from 'context/ThemeContext';
import { BetaLabel } from 'components/UI/Layout/components/SideNav/components';

import styles from './ThemeToggle.module.scss';

const ThemeToggle = () => {
  const { themeMode, toggleThemeMode } = useContext(ThemeContext);

  const handleToggle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    toggleThemeMode();
    ampTrackEvent(`Theme Toggle: ${themeMode === ThemeMode.DARK ? 'light' : 'dark'} mode`);
  };

  const label = `Switch to ${themeMode === ThemeMode.DARK ? 'light' : 'dark'} mode`;

  return (
    <div className="theme-toggle">
      <button onClick={handleToggle} className="tw-flex tw-items-center tw-gap-2 tw-p-0">
        <div
          className={cx(styles.toggle, {
            [styles.day]: themeMode === ThemeMode.LIGHT,
          })}
        >
          <div
            className={cx(styles.moon, {
              [styles.sun]: themeMode === ThemeMode.LIGHT,
            })}
          ></div>
        </div>
        <div>{label}</div>
        {/* Remove the BETA label https://getloop.atlassian.net/browse/LBP-5794 */}
        <BetaLabel />
      </button>
    </div>
  );
};

export default ThemeToggle;
