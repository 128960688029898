import { gql } from '@apollo/client';

export const INVITE_TEAM_MEMBER = gql`
  mutation InviteTeamMember(
    $email: String!
    $role: String!
    $firstName: String!
    $lastName: String!
    $contactId: String
    $permissions: [String!]
    $managerId: String
  ) {
    inviteTeamMember(
      email: $email
      role: $role
      firstName: $firstName
      lastName: $lastName
      contactId: $contactId
      permissions: $permissions
      managerId: $managerId
    ) {
      id
    }
  }
`;

export const RESEND_TEAM_INVITATION = gql`
  mutation ResendTeamInvitation($teamInvitationId: ID!) {
    resendTeamInvitation(teamInvitationId: $teamInvitationId)
  }
`;

export const REVOKE_TEAM_INVITATION = gql`
  mutation RevokeTeamInvitation($teamInvitationId: ID!) {
    revokeTeamInvitation(teamInvitationId: $teamInvitationId)
  }
`;

export const TEAM_INVITATION = gql`
  query TeamInvitation($sgid: String!) {
    teamInvitation(signedId: $sgid) {
      firstName
      lastName
      email
      role
      internalContactId
      contactRole
    }
  }
`;

export const COMPLETE_TEAM_INVITATION = gql`
  mutation CompleteTeamInvitation(
    $birthdate: ISO8601Date!
    $address: AddressInput!
    $otherAddress: AddressInput
    $livedInTheSameAddressRecently: Boolean!
    $socialInsuranceNumber: String
  ) {
    completeTeamInvitation(
      birthdate: $birthdate
      address: $address
      otherAddress: $otherAddress
      livedInTheSameAddressRecently: $livedInTheSameAddressRecently
      socialInsuranceNumber: $socialInsuranceNumber
    )
  }
`;

export const DEACTIVATE_MEMBER = gql`
  mutation DeactivateMember($contactId: ID!, $suspendCard: Boolean!) {
    deactivateMember(contactId: $contactId, suspendCard: $suspendCard)
  }
`;

export const ADD_CONTACT_LEVEL_PERMISSION = gql`
  mutation AddContactLevelPermission($contactId: ID!, $permissionType: String!) {
    addContactLevelPermission(contactId: $contactId, permissionType: $permissionType)
  }
`;

export const REMOVE_CONTACT_LEVEL_PERMISSION = gql`
  mutation RemoveContactLevelPermission($contactId: ID!, $permissionType: String!) {
    removeContactLevelPermission(contactId: $contactId, permissionType: $permissionType)
  }
`;

export const UPDATE_CONTACT_LIMIT_RULES = gql`
  mutation UpdateContactLimitRules($contactId: ID!, $individualLimit: MoneyInput!) {
    updateContactLimitRules(contactId: $contactId, individualLimit: $individualLimit) {
      id
    }
  }
`;
export const CHANGE_CONTACT_ROLE = gql`
  mutation ChangeContactRole($contactId: ID!, $role: String!) {
    changeContactRole(contactId: $contactId, role: $role)
  }
`;

export const ADD_MANAGER_TO_CONTACT = gql`
  mutation AddManagerToContact($contactId: ID!, $managerId: ID) {
    addManagerToContact(contactId: $contactId, managerId: $managerId)
  }
`;
