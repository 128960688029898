import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { LOCAL_STORAGE_KEYS } from 'constants/index';
import { AuthContext } from './context/Auth';
import { clearDomainCookies } from 'utility/cookies';

const LOCAL_STORAGE_DELETION_WHITELIST = [LOCAL_STORAGE_KEYS.connectBankAccountModal, LOCAL_STORAGE_KEYS.themeMode];

const SignOut = () => {
  const { signOut } = useContext(AuthContext);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      await signOut();
      clearLocalStorage();
      clearDomainCookies();
      history.replace('/signin');
    })();
  }, []);

  return null;
};

const clearLocalStorage = () => {
  const allKeys = Object.keys(window.localStorage);
  const toBeDeleted = allKeys.filter((value) => !LOCAL_STORAGE_DELETION_WHITELIST.includes(value));

  toBeDeleted.forEach((value) => window.localStorage.removeItem(value));
};

export default SignOut;
