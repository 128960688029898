import React from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_CREDIT_CARD_STATEMENTS } from 'graphql/cards';
import StatementsModal from 'components/StatementsModal';

const StatementsContainer = (props) => {
  const { loading, data } = useQuery(GET_CREDIT_CARD_STATEMENTS, { fetchPolicy: 'network-only' });

  const statements = get(data, 'primaryCreditCard.statements', []);

  return <StatementsModal loading={loading} statements={statements} {...props} />;
};

export default StatementsContainer;
