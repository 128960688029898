import React from 'react';

import {
  BankingFields,
  CountrySelect,
} from 'components/payments/AddPayee/components/AddSupplierSteps/components/SupplierBankingDetails/components';
import { Select } from 'components/FormFields/v2';
import { useBankDetails } from './hooks';

const BankDetails = () => {
  const { register, availableCurrencies } = useBankDetails();

  return (
    <div className="tw-px-6 tw-py-4 tw-space-y-6">
      <Select
        name="bankAccount.currency"
        label="Bank Account Currency"
        ref={register({ required: true })}
        options={availableCurrencies}
        required
      />

      <CountrySelect />
      <BankingFields />
    </div>
  );
};

export default BankDetails;
