import React from 'react';

import { Modal } from 'components/UI';
import { AddPayeeContextProvider } from 'components/payments/AddPayee/AddPayeeContext';
import { AddBankDetails } from './components';
import { AddSupplierBankDetailsProps } from './AddSupplierBankDetails.types';

const AddSupplierBankDetails = ({ show, onClose, supplierId }: AddSupplierBankDetailsProps) => (
  <Modal show={show} onClose={onClose} title="Edit Banking Details">
    <AddPayeeContextProvider>
      <AddBankDetails onFinish={onClose} supplierId={supplierId} />
    </AddPayeeContextProvider>
  </Modal>
);

export default AddSupplierBankDetails;
