import React from 'react';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import { NewAccountModalContextProvider, QuickBooksTransactionsProvider } from 'context/AccountingServices';
import { FilterContextProvider } from 'components/accountingServiceTransactions/context/FilterContext';
import { AccountingIntegrationCompanyPreferenceContextProvider } from '../../context/AccountingIntegrationCompanyPreferenceContext';
import QboPush from 'components/Transactions/AccountingServicePush';

const CardTransactionsPushLanding = () => {
  return (
    <DashboardLayout>
      <FilterContextProvider>
        <NewAccountModalContextProvider>
          <QuickBooksTransactionsProvider>
            <AccountingIntegrationCompanyPreferenceContextProvider>
              <QboPush />
            </AccountingIntegrationCompanyPreferenceContextProvider>
          </QuickBooksTransactionsProvider>
        </NewAccountModalContextProvider>
      </FilterContextProvider>
    </DashboardLayout>
  );
};

export default CardTransactionsPushLanding;
