import React from 'react';
import { useMutation } from '@apollo/client';

import { DELETE_EXTERNAL_ACCOUNT } from 'graphql/integrations';
import SettingsLightning from 'components/svg/SettingsLightning';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import Button from 'components/Button';
import { validAccountingIntegrations } from 'components/settings/Integrations/constants';

const DisconnectModal = ({ show, onClose, externalAccount, onDisconnect }) => {
  const [deleteExternalAccount, { loading }] = useMutation(DELETE_EXTERNAL_ACCOUNT);
  const accountName = externalAccount ? externalAccount.name : '';

  const handleDisconnectAccount = async () => {
    try {
      await deleteExternalAccount({ variables: { externalAccountId: externalAccount.id } });
      onDisconnect();
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-flex-col tw-justify-center">
        {validAccountingIntegrations().includes(accountName) && (
          <>
            <div className="tw-flex tw-flex-row tw-justify-between tw-my-2 tw-py-4 tw-px-8 tw-border-b tw-border-neutral-grey-3">
              <p>Disconnect Accounting Integration</p>
              <Close className="tw-cursor-pointer" onClick={onClose} />
            </div>
            <div className="tw-flex tw-flex-col tw-items-center tw-px-10 tw-py-5">
              <SettingsLightning />
            </div>
            <div className="tw-px-8">
              <h5 className="tw-my-5 tw-font-semibold">Are you sure you want to disconnect your {accountName}?</h5>
              <p className="tw-mb-8 tw-text-sm">
                You will not be able to push data from Loop to {accountName} if you disconnect the integration. Any
                automated workflows that you have configured which use this integration will also stop working.
              </p>
            </div>
          </>
        )}

        {!validAccountingIntegrations().includes(accountName) && (
          <>
            <div className="tw-flex tw-flex-row tw-justify-between tw-my-2 tw-py-4 tw-px-8 tw-border-b tw-border-neutral-grey-3">
              <p>Disconnect Sales Channel</p>
              <Close className="tw-cursor-pointer" onClick={onClose} />
            </div>
            <div className="tw-flex tw-flex-col tw-items-center tw-px-10 tw-py-5">
              <SettingsLightning />
            </div>
            <div className="tw-px-8">
              <h5 className="tw-my-5 tw-font-semibold">Disconnecting Will Impact Your Credit Limit</h5>
              <p className="tw-mb-6 tw-text-sm">{`Disconnecting your ${accountName} account may reduce your credit limit. Loop determines you credit limit based on the sales processed through your sales channels; if we can’t see your business performance, we can’t make a credit limit assessment.`}</p>
              <p className="tw-mb-8 tw-text-sm tw-font-semibold">
                If no Sales Channels are connected, you may lose access to your Loop Card Credit Limit.
              </p>
            </div>
          </>
        )}

        <div className="tw-flex tw-justify-between tw-border-t tw-border-neutral-grey-3 tw-py-4 tw-px-8">
          <Button tertiary disabled={loading} onClick={onClose} className="tw-w-max tw-pl-0">
            Keep Connection
          </Button>
          <Button
            primary
            data-testid="modalDisconnectButton"
            disabled={loading}
            onClick={handleDisconnectAccount}
            className="tw-w-max tw-bg-semantic-warning"
          >
            {loading ? 'Disconnecting...' : 'Continue'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DisconnectModal;
