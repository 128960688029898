import React from 'react';
import cx from 'classnames';
import { BiChevronRight } from 'react-icons/bi';

import creditCardVirtualSrc from 'images/creditCardVirtual.png';
import { useWhatsChanged } from '../hooks';

import styles from './ChangesRadioComponent.module.scss';

const ChangesRadioComponent = () => {
  const { selectedContent, radioContent, updateRadioSelection } = useWhatsChanged();

  return (
    <div className={cx(styles.changesRadioComponent, 'tw-flex tw-w-full tw-py-4')}>
      <div className="tw-w-1/2 tw-mr-8">
        {radioContent.map(({ id, label, selected }) => (
          <div
            key={id}
            data-selected={selected}
            className={cx(
              'card-row tw-flex tw-items-center tw-justify-between tw-w-full tw-py-4 tw-border-neutral-grey-4 tw-cursor-pointer',
              selected && 'tw-bg-secondary-light-blue'
            )}
            onClick={() => updateRadioSelection(id)}
          >
            <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
              <div className="text-container tw-flex tw-flex-col tw-ml-6 tw-justify-center">
                <span className="title tw-font-bold">{label}</span>
              </div>
              <div className="tw-pr-4">
                <BiChevronRight />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="tw-w-1/2">
        <div className="tw-flex tw-flex-col tw-items-center">
          <img
            src={selectedContent!.imageUrl || creditCardVirtualSrc}
            alt={selectedContent!.label}
            className="tw-h-full tw-object-contain"
          />
          <p className="tw-mt-4 tw-text-center">{selectedContent!.description} </p>
        </div>
      </div>
    </div>
  );
};

export default ChangesRadioComponent;
