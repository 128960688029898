import { gql } from '@apollo/client';

export const GET_COMPLETED_EXPENSES = gql`
  query CompletedExpenses(
    $page: String
    $numPerPage: String
    $startDate: String
    $endDate: String
    $products: [ID!]
    $currencies: [String!]
    $members: [ID!]
    $expenseTypes: [String!]
  ) {
    completedExpenses(
      page: $page
      numPerPage: $numPerPage
      startDate: $startDate
      endDate: $endDate
      products: $products
      currencies: $currencies
      members: $members
      expenseTypes: $expenseTypes
    ) {
      items {
        id
        requester {
          name
        }
        approver {
          name
        }
        amountRequested {
          amount
          currency
        }
        originalAmount {
          amount
          currency
        }
        taxAmount {
          amount
          currency
        }
        fromLabel
        type
        merchantName
        status
        description
        dateApproved
        transactionDate
        createdAt
        updatedAt
        receipt
        transactionId
        declineReason
      }
      pageData {
        totalPages
        currentPage
        nextPage
        prevPage
        totalCount
      }
    }
  }
`;
