import { gql } from '@apollo/client';

export const GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_STATEMENTS = gql`
  query WalletsBankAccountsAndCreditStatements {
    me {
      ... on User {
        account {
          wallets {
            id
            displayName
            availableBalance {
              amount
              currency
            }
            ongoingPaymentsBalance {
              amount
              currency
            }
            currency
            activeExternalAccount
            recentTransactions {
              transactionId
              amount {
                amount
                currency
              }
              to
              from
              status
              createdAt
              transferMethod
              type
              status
            }
            externalAccounts {
              ... on SolidAccount {
                internalId
              }
              ... on CurrencyCloudExternalAccount {
                internalId
              }
              ... on CoreExternalAccount {
                internalId
              }
            }
          }
          bankAccounts {
            id
            displayName
            maskedNumber
            institutionName
            currency
            preDepositAuthorization
            institutionNumber
            transitNumber
          }
          lineOfCredit {
            id
            currency
            ledgerAccount {
              id
              displayName
              balance {
                amount
                currency
              }
              availableBalance {
                amount
                currency
              }
              pendingAmount {
                amount
                currency
              }
              currency
            }
            availableCredit {
              amount
              currency
            }
          }
        }
      }
    }
  }
`;

export const GET_WALLETS_AND_BANK_ACCOUNTS = gql`
  query WalletsAndBankAccounts {
    me {
      ... on User {
        account {
          wallets {
            id
            displayName
            currency
            availableBalance {
              amount
              currency
            }
          }
          bankAccounts {
            id
            displayName
            accountNumber
            maskedNumber
            institutionName
            currency
            preDepositAuthorization
            institutionNumber
            transitNumber
            country
          }
        }
      }
    }
  }
`;

export const ACCOUNT_STATE = gql`
  query AccountState {
    accountState {
      creditCardsCount
      hasLineOfCredit
    }
  }
`;

export const SOLID_DESIGNATION_ROLES = gql`
  query SolidDesignationRoles {
    solidDesignationRoles {
      name
      value
    }
  }
`;

export const SUBMIT_SOLID_ACCOUNT = gql`
  mutation CreateSolidAccount(
    $craBusinessNumber: String!
    $formationDate: String!
    $members: [SolidMemberDataInput!]!
    $validate: Boolean
    $transactionsSentVolume: String
    $transactionsSentAmount: String
    $transactionsReceivedVolume: String
    $transactionsReceivedAmount: String
    $naicsCode: String
  ) {
    createSolidAccount(
      craBusinessNumber: $craBusinessNumber
      formationDate: $formationDate
      members: $members
      validate: $validate
      transactionsSentVolume: $transactionsSentVolume
      transactionsSentAmount: $transactionsSentAmount
      transactionsReceivedVolume: $transactionsReceivedVolume
      transactionsReceivedAmount: $transactionsReceivedAmount
      naicsCode: $naicsCode
    ) {
      internalId
      label
      routingNumber
      accountNumber
      status
      type
      isVerified
      ready
    }
  }
`;

export const VALIDATE_SOLID_PROJECTIONS = gql`
  mutation ValidateSolidProjections(
    $naicsCode: String!
    $transactionsSentVolume: String!
    $transactionsSentAmount: String!
    $transactionsReceivedVolume: String!
    $transactionsReceivedAmount: String!
  ) {
    validateSolidProjections(
      naicsCode: $naicsCode
      transactionsSentVolume: $transactionsSentVolume
      transactionsSentAmount: $transactionsSentAmount
      transactionsReceivedVolume: $transactionsReceivedVolume
      transactionsReceivedAmount: $transactionsReceivedAmount
    ) {
      internalId
    }
  }
`;

export const GET_EXTERNAL_WALLET_ACCOUNT_DETAILS = gql`
  query ExternalWalletAccountDetails($accountInternalId: String!) {
    walletExternalAccount(accountInternalId: $accountInternalId) {
      ... on SolidAccount {
        internalId
        routingNumber
        accountNumber
        isVerified
        ready
        type
        address {
          street
          unitNumber
          city
          countrySubdivision
          postalCode
          country
        }
      }
      ... on CurrencyCloudExternalAccount {
        internalId
        ready
      }
    }
  }
`;

export const GET_EXTERNAL_WALLET_ACCOUNT_VERIFICATION_STATUS = gql`
  query ExternalWalletAccountVerificationStatus($accountInternalId: String!) {
    walletExternalAccountVerificationStatus(accountInternalId: $accountInternalId) {
      isVerified
      members {
        status
        firstName
        lastName
        idType
        idNumber
      }
    }
  }
`;

export const ACCEPT_WALLET_AGREEMENT = gql`
  mutation AcceptWalletAgreement {
    acceptWalletAgreement
  }
`;

export const GET_WALLET_STATEMENTS_BY_CURRENCY = gql`
  query Wallet($currency: String!) {
    wallet(currency: $currency) {
      id
      name
      currency
      statements {
        id
        createdAt
        title
      }
      availableBalance {
        amount
        currency
      }
    }
  }
`;

export const GET_WALLETS = gql`
  query Wallets {
    wallets {
      id
      name
      currency
      statements {
        id
        createdAt
        title
      }
      availableBalance {
        amount
        currency
      }
      externalAccountActive
    }
  }
`;

export const GET_WALLET_STATEMENTS = gql`
  query WalletStatements {
    me {
      ... on User {
        account {
          wallets {
            id
            currency
            statements {
              id
              createdAt
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_WALLET_NAMES = gql`
  query WalletNames {
    me {
      ... on User {
        account {
          wallets {
            displayName
          }
        }
      }
    }
  }
`;

export const GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_FOR_ACCOUNTS_PAGE = gql`
  query WalletsBankAccountsAndCreditForAccountsPage {
    me {
      ... on User {
        account {
          wallets {
            id
            displayName
            availableBalance {
              amount
              currency
            }
            ongoingPaymentsBalance {
              amount
              currency
            }
            currency
            activeExternalAccount
            externalAccounts {
              ... on SolidAccount {
                internalId
                accountNumber
                routingNumber
              }
              ... on CurrencyCloudExternalAccount {
                internalId
              }
              ... on CoreExternalAccount {
                id
                internalId
                status
                isVerified
              }
            }
          }
          bankAccounts {
            id
            displayName
            maskedNumber
            institutionName
            currency
            preDepositAuthorization
            institutionNumber
            transitNumber
            accountNumber
            routingNumber
            country
          }
          lineOfCredit {
            id
            currency
            ledgerAccount {
              id
              displayName
              balance {
                amount
                currency
              }
              availableBalance {
                amount
                currency
              }
              pendingAmount {
                amount
                currency
              }
              currency
            }
            availableCredit {
              amount
              currency
            }
          }
        }
      }
    }
  }
`;

export const GET_CORE_EXTERNAL_ACCOUNTS = gql`
  query CoreExternalAccounts {
    me {
      ... on User {
        account {
          wallets {
            id
            currency
            activeExternalAccount
            externalAccounts {
              ... on CoreExternalAccount {
                id
                internalId
                status
                isVerified
              }
            }
          }
        }
      }
    }
  }
`;

// This is temporary until we have a proper separation of queries for the accounts page and accounting pages
export const GET_WALLET_TRANSACTIONS_ON_ACCOUNTS_PAGE = gql`
  query WalletTransactionsOnAccountsPage {
    me {
      ... on User {
        account {
          wallets {
            id
            recentTransactions {
              transactionId
              amount {
                amount
                currency
              }
              to
              from
              status
              createdAt
              transferMethod
              type
              status
            }
          }
        }
      }
    }
  }
`;

export const GET_WALLET_TRANSACTIONS = gql`
  query WalletTransactions {
    me {
      ... on User {
        account {
          wallets {
            id
            transactions {
              transactionId
              amount {
                amount
                currency
              }
              to
              from
              status
              createdAt
              transferMethod
              type
              status
            }
          }
        }
      }
    }
  }
`;

export const GET_CURRENCY_CLOUD_EXTERNAL_ACCOUNTS = gql`
  query CurrencyCloudExternalAccount($currency: String!) {
    currencyCloudExternalAccount(currency: $currency) {
      externalAccountsInfo {
        accountNumber
        routingCode
        bankName
        bankAddress
        accountHolderName
        accountNumberType
        paymentType
      }
    }
  }
`;

export const ACTIVATE_WALLET_EXTERNAL_ACCOUNT = gql`
  mutation ActivateWalletExternalAccount($currency: String!) {
    activateWalletExternalAccount(currency: $currency)
  }
`;

export const GET_SOLID_REQUIREMENTS = gql`
  query SolidRequirements {
    me {
      ... on User {
        account {
          solidRequirements {
            naicsProjectionsProvided
            solidAccountExists
            ownershipDisclosureSigned
          }
        }
      }
    }
  }
`;

export const SOLID_OWNERSHIP_DISCLOSURE_READINESS = gql`
  query SolidOwnershipDisclosureReadiness {
    solidRequirements {
      controlPersonReady
    }
  }
`;

export const SOLID_OWNERSHIP_DISCLOSURE_STATE = gql`
  query SolidOwnershipDisclosureState {
    solidRequirements {
      solidAccountExists
      ownershipDisclosureSigned
    }
  }
`;

export const GET_SOLID_OWNERSHIP_DISCLOSURE_URL = gql`
  query SolidOwnershipDisclosureUrl {
    solidRequirements {
      ownershipDisclosureUrl
    }
  }
`;

export const CREATE_CORE_ACCOUNT = gql`
  mutation CreateCoreAccount($craBusinessNumber: String!, $members: [CoreMemberDataInput!]!) {
    createCoreAccount(craBusinessNumber: $craBusinessNumber, members: $members) {
      id
    }
  }
`;

export const ACTIVATE_CORE_ACCOUNT = gql`
  mutation ActivateCoreAccount($externalWalletAccountId: ID!, $skippedTaxDeclaration: Boolean) {
    activateCoreAccount(
      externalWalletAccountId: $externalWalletAccountId
      skippedTaxDeclaration: $skippedTaxDeclaration
    ) {
      id
      accountNumber
      institutionNumber
      transitNumber
      status
    }
  }
`;

export const WALLET_ACTIVE_EXTERNAL_ACCOUNT_FRAGMENT = gql`
  fragment WalletActiveExternalAccount on LedgerAccount {
    id
    activeExternalAccount
  }
`;

export const CORE_EXTERNAL_ACCOUNT_AGREEMENTS = gql`
  query CoreExternalAccountAgreements($externalWalletAccountId: ID!) {
    coreExternalAccountAgreements(externalWalletAccountId: $externalWalletAccountId) {
      id
      documentType
      name
      status
      envelopeId
    }
  }
`;

export const CORE_EXTERNAL_ACCOUNT_AGREEMENT_STATUS = gql`
  query CoreExternalAccountAgreementStatus($externalWalletAccountId: ID!, $agreementId: String!) {
    coreExternalAccountAgreementStatus(externalWalletAccountId: $externalWalletAccountId, agreementId: $agreementId)
  }
`;

export const CORE_EXTERNAL_ACCOUNT_AGREEMENT_SIGNING_URL = gql`
  query CoreExternalAccountAgreementSigningUrl($externalWalletAccountId: ID!, $agreementId: String!) {
    coreExternalAccountAgreementSigningUrl(externalWalletAccountId: $externalWalletAccountId, agreementId: $agreementId)
  }
`;

export const CREATE_CORE_EXTERNAL_ACCOUNT_AGREEMENT_SIGNING_ENVELOPE = gql`
  mutation CreateCoreExternalAccountAgreementSigningEnvelope($externalWalletAccountId: ID!, $agreementId: String!) {
    createCoreExternalAccountAgreementSigningEnvelope(
      externalWalletAccountId: $externalWalletAccountId
      agreementId: $agreementId
    )
  }
`;

export const GET_BASIC_WALLETS = gql`
  query Wallets {
    wallets {
      id
      name
      currency
    }
  }
`;

// This will be removed when CAD Business Operating Account is available to everyone
export const GET_CAD_ACCOUNT_FEATURE_STATUS = gql`
  query CadAccountFeatureStatus {
    me {
      ... on User {
        account {
          cadAccountFeatureDisabled
        }
      }
    }
  }
`;
