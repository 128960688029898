import React from 'react';

import useIsAdmin from 'hooks/useIsAdmin';
import useIsMember from 'hooks/useIsMember';
import { CompletedRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';
import { capitalize } from 'utility/string';
import { formatDateTime } from 'utility/date';
import { formatMoneyWithoutCurrencyLabel } from 'utility/currency';

const useCompletedRow = ({ completedExpense }: { completedExpense: CompletedRequestDataType }) => {
  const { isAdmin } = useIsAdmin();
  const { isMember } = useIsMember();

  const { requester, originalAmount, status, transactionDate, merchantName, type, receipt, dateApproved, fromLabel } =
    completedExpense || {};

  const formattedTransactionDate = transactionDate ? formatDateTime(transactionDate, 'MMM d, yyyy') : '-';
  const formattedAmount = formatMoneyWithoutCurrencyLabel(originalAmount);
  const formattedDateApproved = dateApproved ? formatDateTime(dateApproved, 'MMM d, yyyy') : '-';
  const formattedFromLabel = fromLabel ? capitalize(completedExpense?.fromLabel) : '-';

  const handleStopClickRow = (e: React.MouseEvent<HTMLTableCellElement>) => {
    e.stopPropagation();
  };

  const formattedRequesterName = capitalize(requester?.name) || '-';

  return {
    isAdmin,
    isMember,
    requester: requester?.name,
    status,
    formattedRequesterName,
    formattedTransactionDate,
    formattedAmount,
    formattedDateApproved,
    currency: originalAmount?.currency,
    merchantName,
    handleStopClickRow,
    receipt,
    type,
    formattedFromLabel,
  };
};
export default useCompletedRow;
