import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_QUICKBOOK_EXPENSE_ACCOUNTS } from 'graphql/accountingServices';
import {
  SearchDropdownComponentProps,
  ExternalExpenseAccountListProps,
} from 'components/settings/AccountingServices/AccountingServices.types';
import { ExternalExpenseAccount } from 'types/accounting';
import SearchDropdown, { useCommonSearchDropdown } from 'components/UI/SearchDropdown';

const SearchExpenseAccountsDropdown = ({ selectedVal, handleChange }: SearchDropdownComponentProps) => {
  const { data: expenseAccounts, loading } = useQuery<ExternalExpenseAccountListProps>(GET_QUICKBOOK_EXPENSE_ACCOUNTS);

  const getOptionFromItem = (item: ExternalExpenseAccount) => {
    return {
      value: item.id,
      label: item.name || item.id,
    };
  };

  const { optionsList, onSelectOption, onSearch, selectedOption, displayLabel } = useCommonSearchDropdown({
    unsortedItemsList: expenseAccounts?.accountingIntegrationExpenseAccounts?.map(getOptionFromItem),
    selectedVal: selectedVal,
    handleChange: handleChange,
  });

  return (
    <SearchDropdown
      optionsList={optionsList}
      onSelectOption={onSelectOption}
      onSearch={onSearch}
      selectedOption={selectedOption}
      displayLabel={displayLabel}
      isSearching={loading}
    />
  );
};

export default SearchExpenseAccountsDropdown;
