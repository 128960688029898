import { gql } from '@apollo/client';

export const GET_CREDIT_CARDS_FOR_FILTER = gql`
  query CreditCardsForFilter {
    me {
      ... on User {
        account {
          creditCards {
            id
            displayName
            contacts {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_WALLETS_FOR_FILTER = gql`
  query WalletsForFilter {
    me {
      ... on User {
        account {
          wallets {
            id
            displayName
          }
        }
      }
    }
  }
`;

export const EXPORT_TRANSACTIONS_API = gql`
  mutation AddTransactionsExportRequest(
    $products: [String!]
    $startDate: String
    $endDate: String
    $currencies: [String!]
    $categories: [String!]
    $search: String
    $loopProducts: [String!]
    $exportType: String!
  ) {
    addTransactionsExportRequest(
      products: $products
      startDate: $startDate
      endDate: $endDate
      currencies: $currencies
      categories: $categories
      search: $search
      loopProducts: $loopProducts
      exportType: $exportType
    )
  }
`;
