import React from 'react';

import coinWithBoxSrc from 'images/coin_with_box.png';

type GiftBoxWithCoinProps = {
  width?: number;
  height?: number;
};

const GiftBoxWithCoin = ({ width = 70, height = 60 }: GiftBoxWithCoinProps) => (
  <img src={coinWithBoxSrc} width={width} height={height} />
);

export default GiftBoxWithCoin;
