import React from 'react';

import { getId } from 'utility/string';
import SuccessCheckmark from 'components/svg/SuccessCheckmark';
import { rutterStyle, icons } from 'components/onboarding/integrations/Rutter';

const ConnectedChannel = ({ channel, account, platform }) => {
  const overrideClick = (e) => e.stopPropagation();

  if (!channel) return null;

  return (
    <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4 tw-mb-4 tw-flex tw-justify-between">
      <div className="tw-flex tw-items-center">
        {account.connected ? <SuccessCheckmark className="tw-mr-2" /> : ''}
        <div className="tw-w-28" onClickCapture={overrideClick}>
          {channel.name === 'Rutter' ? (
            <div key={platform} data-testid={platform} className={rutterStyle('connected')}>
              <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                <img className="tw-w-1/2" src={icons[platform.toUpperCase()]} />
              </div>
            </div>
          ) : (
            channel.component
          )}
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-items-end">
        <small
          className={account.connected ? 'tw-text-semantic-success tw-mb-2' : 'tw-text-semantic-neutral-grey tw-mb-2'}
        >
          {account.connected ? 'Connected' : 'Disconnected'}
        </small>
        <small className="tw-text-neutral-grey-2 tw-mb-2">{`${
          !account.ownerName ? '' : `${account.ownerName} `
        }ID ${getId(account.id)}`}</small>
        <small className="tw-text-neutral-grey-2 tw-mb-2">{account.uid}</small>
      </div>
    </div>
  );
};

export default ConnectedChannel;
