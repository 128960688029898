import { gql } from '@apollo/client';

export const GET_UNMATCHED_RECEIPTS_PAGE = gql`
  query UnmatchedReceiptsPage($startDate: String, $endDate: String, $page: String, $numPerPage: String) {
    unmatchedReceiptsPage(startDate: $startDate, endDate: $endDate, page: $page, numPerPage: $numPerPage) {
      items {
        id
        blobId
        contact {
          phone
          firstName
          lastName
        }
        receipt
        createdAt
        sourceType
        sourceAddress
      }
      pageData {
        totalCount
        totalPages
        currentPage
        nextPage
        prevPage
      }
    }
  }
`;
