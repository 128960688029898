import { useContext } from 'react';
import { get } from 'lodash';
import { useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

import { ReviewProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { useAccountsModal } from 'components/Accounts/AccountsContent/components/AccountsModal/hooks';
import { FundsContext } from 'components/Accounts/AccountsContent/contexts/FundsContext';
import { formatMoneyV2 } from 'utility/currency';
import { MOVE_FUNDS } from 'graphql/payments';
import { GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_STATEMENTS } from 'graphql/wallets';
import { BankAccountCountry } from 'types/bankAccount';

const useReview = ({ onNextStep, onPrevStep }: ReviewProps) => {
  const { displayName, bankAccounts } = useAccountsModal();
  const { addFundsInfo, setAddFundsTransaction, setError } = useContext(FundsContext);

  const fromAccount = get(addFundsInfo, 'fromAccount', '');
  const toAccount = get(addFundsInfo, 'toAccount', '');
  const amount = get(addFundsInfo, 'amount', 0);

  const fromBankAccount = bankAccounts.find((ba) => ba.id === fromAccount);
  const bankAccountDisplayName = fromBankAccount?.displayName;
  const preDepositAuthorization = fromBankAccount?.preDepositAuthorization;
  const showUSBankAccountConfirmation = fromBankAccount?.country === BankAccountCountry.US && preDepositAuthorization;

  const formattedAmount = formatMoneyV2(amount);

  const [moveFunds, { loading }] = useMutation(MOVE_FUNDS, {
    refetchQueries: [{ query: GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_STATEMENTS, fetchPolicy: 'network-only' }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async () => {
    if (preDepositAuthorization) {
      try {
        const response = await moveFunds({
          variables: {
            from: fromAccount,
            to: toAccount,
            originalAmount: amount,
            chargedAmount: amount,
          },
        });
        if (response?.data?.moveFunds) {
          setAddFundsTransaction(response.data.moveFunds);
        }
        onNextStep(3);
      } catch (err) {
        console.error(err);
        toast.error('Error adding funds - please try again');
        setError(err as ApolloError);
        onPrevStep();
      }
    } else {
      onNextStep(2);
    }
  };

  return {
    displayName,
    formattedAmount,
    bankAccountDisplayName,
    loading,
    handleSubmit,
    showUSBankAccountConfirmation,
    submitLabel: 'Confirm',
  };
};

export default useReview;
