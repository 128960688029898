import React from 'react';
import { BiCheck } from 'react-icons/bi';

import { EXTERNAL_TRANSACTION_CLEARING_MESSAGE } from 'constants/index';
import { CompleteProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { useComplete } from './hooks';
import { Footer } from 'components/Accounts/AccountsContent/components/AccountsModal/components';
import { ReferAndEarnBanner } from 'components/UI';

const Complete = ({ onFinish, onResetSteps }: CompleteProps) => {
  const {
    toAccount,
    fromAccount,
    formattedAmount,
    transferMethod,
    fees,
    transactionGroupId,
    formattedInitiatedAtDate,
    handleNewWithdrawal,
    isMobile,
  } = useComplete({ onResetSteps });

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <div className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-secondary-light-green tw-mb-8">
          <div className="tw-mr-2 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
            <BiCheck className="tw-text-secondary-light-green" />
          </div>
          <small className="tw-text-semantic-success">{EXTERNAL_TRANSACTION_CLEARING_MESSAGE}</small>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-flex tw-flex-col tw-py-2">
            <small>Recipient Account</small>
            <p className="tw-mt-1">{toAccount}</p>
            <small className="tw-mt-4">From Account</small>
            <p className="tw-mt-1">{fromAccount}</p>
            <small className="tw-mt-4">Amount</small>
            <p className="tw-mt-1">{formattedAmount}</p>
            <small className="tw-mt-4">Method</small>
            <p className="tw-mt-1">{transferMethod}</p>
            <small className="tw-mt-4">Fees</small>
            <p className="tw-mt-1">{fees}</p>
            <small className="tw-mt-4">Reference ID</small>
            <p className="tw-mt-1">{transactionGroupId}</p>
            <small className="tw-mt-4">Initiated at</small>
            <p className="tw-mt-1">{formattedInitiatedAtDate}</p>
          </div>
          {!isMobile && <ReferAndEarnBanner />}
        </div>
      </div>
      <Footer
        onNavigateBack={handleNewWithdrawal}
        onSubmit={onFinish}
        navigateBackLabel="Withdraw More Funds"
        submitLabel="Close"
      />
    </>
  );
};

export default Complete;
