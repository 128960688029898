import { useMemo } from 'react';

import { formatDate } from 'utility/date';
import { CURRENT_CYCLE } from 'components/creditCards/components/CardBalances/components/StatementBalances/constants';
import { UsePeriodSelectorProps } from '../PeriodSelector.types';

const usePeriodSelector = ({ statementsList, currentCycleStartDate }: UsePeriodSelectorProps) => {
  const statementOptions = [
    {
      id: CURRENT_CYCLE,
      label: currentCycleStartDate ? `${formatDate(currentCycleStartDate, 'd MMM')} - Present` : 'Current Cycle',
    },
    ...statementsList.map(({ id, startDate, endDate }) => ({
      id,
      label: `${formatDate(startDate, 'd MMM')} - ${formatDate(endDate, 'd MMM')}`,
    })),
  ];

  const maxVisibleOptionsLength = 2;

  const visibleOptions = useMemo(
    () => statementOptions.slice(0, maxVisibleOptionsLength),
    [statementOptions, maxVisibleOptionsLength]
  );
  const collapsedOptions = useMemo(
    () => statementOptions.slice(maxVisibleOptionsLength),
    [statementOptions, maxVisibleOptionsLength]
  );

  return {
    statementOptions,
    visibleOptions,
    collapsedOptions,
  };
};

export default usePeriodSelector;
