import React from 'react';

import Summary from '../Summary';
import Bank from 'components/svg/Bank';
import { AccountBalances } from './components';

const AccountsSummary = () => {
  return (
    <Summary
      title="Accounts"
      icon={<Bank width={16} height={16} />}
      iconClassName="tw-text-primary-lilac tw-bg-secondary-light-lilac"
    >
      <AccountBalances />
    </Summary>
  );
};

export default AccountsSummary;
