import React from 'react';
import { useQuery } from '@apollo/client';
import qs from 'query-string';

import GuarantorConsent from '../components/consentForms/GuarantorConsent';
import { GUARANTOR_CONTACT } from '../graphql/user';

const GuarantorConsentContainer = (props) => {
  const { sgid } = qs.parse(props.location.search);

  const { loading, data, error } = useQuery(GUARANTOR_CONTACT, { variables: { sgid } });

  return <GuarantorConsent loading={loading} error={error} contact={data && data.guarantorContact} sgid={sgid} />;
};

export default GuarantorConsentContainer;
