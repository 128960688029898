import React, { useState } from 'react';
import axios from 'axios';
import { useRutterLink } from 'react-rutter-link';
import cx from 'classnames';

import { toast } from 'react-toastify';
import { ampTrackEvent } from 'amplitude';
import { Loaders } from '../../cards/Loader';
import config from 'config';

import styles from 'components/settings/Integrations/components/Modals/Modals.module.scss';

export const icons = {
  AMAZON: 'https://loop-public.s3.ca-central-1.amazonaws.com/amazon_logo.png',
  SQUARE: 'https://loop-public.s3.ca-central-1.amazonaws.com/square-logo.png',
  PAYPAL: 'https://loop-public.s3.ca-central-1.amazonaws.com/paypal-logo.png',
};

export const rutterStyle = (from) =>
  cx({
    'tw-mr-4 tw-mb-4 tw-flex tw-justify-center tw-shadow-xs-2 tw-items-center tw-cursor-pointer tw-w-32 tw-h-14 tw-rounded-md tw-border tw-border-neutral-grey-4 tw-bg-neutral-grey-4':
      from === 'onboarding',
    [`tw-rounded-md tw-flex tw-items-center tw-justify-center ${styles.salesChannel} tw-cursor-pointer`]:
      from === 'connected',
    [`tw-bg-neutral-grey-4 tw-rounded-md tw-flex tw-items-center tw-justify-center ${styles.salesChannel} tw-cursor-pointer`]:
      from !== 'onboarding' && from !== 'connected',
  });

const Rutter = (props) => {
  const {
    onSuccess,
    platforms = ['PAYPAL'],
    isOnboarding,
    isPreApproval,
    from = 'onboarding',
    connectedChannels = [],
  } = props;

  const [loading, setLoading] = useState(false);
  const [loadingPlatform, setLoadingPlatform] = useState(null);
  const onAuthSuccess = async (publicToken) => {
    setLoading(true);

    try {
      const purpose = (isOnboarding && 'onboarding') || (isPreApproval && 'pre_approval') || null;

      const response = await axios.post(`${config.coreAppUrl}/auth/rutter/callback`, {
        public_token: publicToken,
        purpose,
      });

      if (response.data && response.data.message) {
        onSuccess();
        setLoading(false);
        setLoadingPlatform(null);
      }
    } catch (err) {
      console.error(err);
      toast.error('Error adding integration - please contact support');
      setLoading(false);
      setLoadingPlatform(null);
    }
  };

  const rutterConfig = {
    publicKey: config.rutterPublicKey,
    onSuccess: onAuthSuccess,
  };

  const { open } = useRutterLink({ ...rutterConfig });

  const imgClass = from === 'onboarding' ? 'tw-w-2/3' : 'tw-w-1/2';

  return platforms.map((platform) => (
    <div key={platform} data-testid={platform} className={rutterStyle(from)}>
      <div
        className="tw-flex tw-flex-col tw-items-center tw-justify-center"
        onClick={() => {
          ampTrackEvent(`Clicked: Connect Rutter ${platform} Marketplace - Integrations`);
          setLoadingPlatform(platform);
          !connectedChannels.includes(platform) && open({ platform });
        }}
      >
        {loading && platform === loadingPlatform ? (
          <Loaders.Spinner />
        ) : (
          <img className={imgClass} src={icons[platform]} />
        )}
      </div>
    </div>
  ));
};

export default Rutter;
