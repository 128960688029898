import React, { useContext, useEffect, useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { formatDistanceToNowStrict } from 'date-fns';
import { BiCheck, BiTransferAlt, BiXCircle } from 'react-icons/bi';

import Warning from 'components/svg/Warning';
import InfoTooltip from 'components/InfoTooltip';
import { AuthContext } from '../../context/Auth';
import { PAYEE_PAYMENT_APPROVAL_STATUS } from 'constants/index';
import { MFARequired, VerifySMSCode, VerifyEmailCode } from './PayPayee/MFA';
import { Loaders } from 'components/cards/Loader';
import Button from 'components/Button';
import { PAY_PAYEE, REJECT_PAYEE_PAYMENT, GET_PAYEE_PAYMENTS } from '../../graphql/payees';
import { formatMoneyV2 } from 'utility/currency';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import { PayPayeeContext } from 'components/payments/PayPayee/PayPayeeContext';
import { MFAContext } from 'context/MFA';
import useGetExchangeRate from 'hooks/useGetExchangeRate';
import { PayeePaymentApprovals } from './components';

const PaymentConfirmationModal = ({
  show,
  payment,
  setShow,
  payeePayment,
  payeeName,
  paymentStatus,
  refetchPayeePayments,
}) => {
  const payee = payeePayment?.payee;
  const reason = payeePayment?.reason;

  const { getSMSToken } = useContext(MFAContext);
  const authorizationToken = getSMSToken();
  const { me } = useContext(AuthContext);
  const { smsMfaEnabled, totpMfaEnabled } = me || {};
  const onCloseMFA = () => {
    setShow(false);
  };

  if (!smsMfaEnabled && !totpMfaEnabled && show)
    return (
      <Modal show={show} onClose={onCloseMFA}>
        <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
          <strong>Pay Payee</strong>
          <Close className="tw-cursor-pointer" onClick={onCloseMFA} />
        </div>
        <MFARequired />
      </Modal>
    );

  if (smsMfaEnabled && !totpMfaEnabled && show) {
    if (!authorizationToken)
      return (
        <Modal show={show} onClose={onCloseMFA}>
          <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
            <strong>Pay Payee</strong>
            <Close className="tw-cursor-pointer" onClick={onCloseMFA} />
          </div>
          <VerifySMSCode />
        </Modal>
      );
  }
  if (totpMfaEnabled && show) {
    if (!authorizationToken)
      return (
        <Modal show={show} onClose={onCloseMFA}>
          <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
            <strong>Pay Payee</strong>
            <Close className="tw-cursor-pointer" onClick={onCloseMFA} />
          </div>
          <VerifyEmailCode />
        </Modal>
      );
  }

  const [payPayee, { loading: isSubmitting }] = useMutation(PAY_PAYEE, {
    refetchQueries: [{ query: GET_PAYEE_PAYMENTS }],
  });
  const [rejectPayeePayment, { loading: isRejecting }] = useMutation(REJECT_PAYEE_PAYMENT, {
    refetchQueries: [{ query: GET_PAYEE_PAYMENTS }],
  });

  const onRefreshRate = () => {
    getExchangeRate({
      variables: {
        buy: { currency: originalAmount.currency, amount: originalAmount.amount },
        sell: { currency: sellCurrency, amount: 1000 },
      },
    });
  };

  const { rate, rateExpiresAt, exchangeRateReference, getExchangeRate, loadingRate } = useGetExchangeRate();
  const { paymentInfo } = useContext(PayPayeeContext);
  const [expired, setExpired] = useState(false);
  const [sufficientFunds, setSufficientFunds] = useState(false);
  const [paymentApproved, setPaymentApproved] = useState(false);
  const [paymentRejected, setPaymentRejected] = useState(false);
  const [decisionMade, setDecisionMade] = useState(false);
  const [error, setError] = useState(null);
  var isSufficientFunds = false;

  const needsConversion = paymentInfo?.needsConversion;
  const sellCurrency = paymentInfo?.payeePayment?.chargedPayment?.currency;

  const originalAmount = {
    amount: paymentInfo?.originalAmount?.amount,
    currency: paymentInfo?.originalAmount?.currency,
  };
  const fromAccount = paymentInfo?.fromAccount;
  const creditCard = paymentInfo?.creditCard;
  const groupAvailableBalance = creditCard?.groupAvailableBalance?.amount;
  const { approvalRequiredFromUser, approvalsNeeded, payeePaymentApprovals, approvalsMade } = payeePayment || {};
  const [requiredApprovalsCount, setRequiredApprovalsCount] = useState(approvalsNeeded - approvalsMade);

  if (!needsConversion) {
    if (fromAccount?.id?.includes('CreditCard')) {
      isSufficientFunds = groupAvailableBalance >= originalAmount?.amount;
    } else if (fromAccount?.id?.includes('BankAccount')) {
      isSufficientFunds = true;
    } else {
      isSufficientFunds = fromAccount?.availableBalance?.amount >= originalAmount?.amount;
    }
  }
  const insufficientFundsAlert =
    (needsConversion && !loadingRate && !sufficientFunds) || (!needsConversion && !isSufficientFunds);

  useEffect(() => {
    if (needsConversion && show) {
      const sellCurrency = paymentInfo?.payeePayment?.chargedPayment?.currency;
      getExchangeRate({
        variables: {
          buy: { currency: originalAmount.currency, amount: originalAmount.amount },
          sell: { currency: sellCurrency, amount: 10000 },
        },
      });
      setExpired(false);
    }
  }, [show]);

  useEffect(() => {
    if (needsConversion && rate) {
      if (fromAccount?.id.includes('CreditCard')) {
        setSufficientFunds(groupAvailableBalance >= rate * originalAmount.amount);
      } else if (fromAccount?.id.includes('BankAccount')) {
        setSufficientFunds(true);
      } else {
        setSufficientFunds(fromAccount.availableBalance.amount >= rate * originalAmount.amount);
      }
    }
  }, [rate, loadingRate]);

  const variables = {
    authorizationToken,
    payeeId: paymentInfo?.payeePayment?.payee?.id,
    payeePaymentId: paymentInfo?.payeePayment?.id,
    from: paymentInfo?.isLoC ? null : paymentInfo?.fromAccount?.id,
    reason: paymentInfo?.payeePayment?.reason,
    notes: paymentInfo?.payeePayment?.notes,
    originalPayment: originalAmount,
    exchangeRateReference: needsConversion ? exchangeRateReference : null,
  };

  const onClose = () => {
    setShow(false);
    if (paymentApproved || paymentRejected) {
      refetchPayeePayments();
    }
    setPaymentApproved(false);
    setPaymentRejected(false);
  };
  const onSubmit = async () => {
    setDecisionMade(true);
    setRequiredApprovalsCount(requiredApprovalsCount - 1);
    try {
      const response = await payPayee({ variables });
      if (response && response.data.payPayee) {
        setPaymentApproved(true);
      } else {
        console.log('Something went wrong. Please try again later.');
      }
    } catch (err) {
      console.error(err);
      setError(err);
    }
  };

  const onReject = async () => {
    setDecisionMade(true);
    try {
      const response = await rejectPayeePayment({
        variables: {
          payeePaymentId: paymentInfo?.payeePayment?.id,
          authorizationToken,
        },
      });
      if (response && response.data.rejectPayeePayment) {
        setPaymentRejected(true);
      } else {
        console.log('Something went wrong. Please try again later.');
      }
    } catch (err) {
      console.error(err);
      setError(err);
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <strong>Payment Approval</strong>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      {isSubmitting || isRejecting ? (
        <div className="tw-flex tw-flex-col tw-px-8 tw-pt-6 tw-pb-8 tw-space-y-4 tw-flex tw-items-center tw-justify-center">
          <Loaders.Small />
        </div>
      ) : (
        <div className="tw-flex tw-flex-col tw-px-8 tw-pt-6 tw-pb-8 tw-space-y-4">
          {paymentApproved && (
            <div className="tw-p-2 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-secondary-light-green tw-mb-8">
              <div className="tw-mr-4 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
                <BiCheck className="tw-text-secondary-light-green" />
              </div>
              <small className="tw-text-semantic-success">Your transfer has been completed</small>
            </div>
          )}
          {paymentRejected && (
            <div className="tw-p-2 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-semantic-error-background tw-mb-8">
              <div className="tw-mr-4 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-error-background">
                <BiXCircle className="tw-text-semantic-error" />
              </div>
              <small className="tw-text-semantic-error">Your payment has been rejected</small>
            </div>
          )}
          {insufficientFundsAlert && (
            <div className="tw-p-2 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-mb-8">
              <small className="tw-text-semantic-warning">Your funds are insufficient. Please load funds.</small>
            </div>
          )}
          {error && (
            <div className="tw-p-2 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-white tw-mb-8">
              <small className="tw-text-semantic-warning">{error.message}</small>
            </div>
          )}
          <div className="tw-text-xl tw-font-semibold">Payment Details</div>

          {!paymentRejected && paymentStatus === PAYEE_PAYMENT_APPROVAL_STATUS.pendingApproval && (
            <>
              <div className="tw-rounded-md tw-bg-secondary-pastel-yellow-80 tw-p-4 tw-mb-4 tw-flex tw-justify-center tw-items-center tw-text-primary-dark-yellow tw-text-sm">
                Please note that this payment still requires {requiredApprovalsCount} approval
                {requiredApprovalsCount === 1 ? '' : 's'}. If the transaction involves a currency conversion, the
                applicable rate will be determined at the time of final approval.
              </div>

              <div className="tw-text-sm">
                Please review the details below for this payment that is pending approval. Loop will send the payment to{' '}
                {payeeName} as soon as you approve this payment instruction
              </div>
            </>
          )}

          {(loadingRate || isSubmitting || isRejecting) && (
            <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4 tw-mt-4 tw-flex tw-items-center tw-justify-center">
              <Loaders.Small />
            </div>
          )}
          {needsConversion && !isSubmitting && rate && !loadingRate && (
            <ExchangeRateInfo
              currentChargedAmount={{ amount: rate * originalAmount.amount, currency: sellCurrency }}
              originalAmount={originalAmount}
              rate={rate}
              rateExpiresAt={rateExpiresAt}
              onRefreshRate={onRefreshRate}
              expired={expired}
              setExpired={setExpired}
            />
          )}
          <div className="tw-flex">
            <small className="tw-w-2/3">Reason</small>
            <small className="tw-w-1/3">{reason}</small>
          </div>
          {!needsConversion && (
            <div className="tw-flex">
              <small className="tw-w-2/3">Payment Amount</small>
              <small className="tw-w-1/3">{payment}</small>
            </div>
          )}
          <div className="tw-flex">
            <small className="tw-w-2/3">From Account</small>
            <small className="tw-w-1/3">{fromAccount?.displayName}</small>
          </div>
          <div className="tw-flex">
            <strong>Payee's Details</strong>
          </div>
          <PayeeDetails payee={payee} />

          <PayeePaymentApprovals payeePaymentApprovals={payeePaymentApprovals} />
        </div>
      )}

      {!decisionMade && approvalRequiredFromUser && (
        <div className="tw-border-t tw-border-neutral-grey-3 tw-px-6 tw-pt-4 tw-flex lg:tw-justify-between tw-mb-4">
          <Button
            primary
            type="submit"
            isDisabled={isRejecting || isSubmitting || paymentApproved || paymentRejected}
            className="tw-bg-semantic-error"
            onClick={() => onReject()}
          >
            Deny
          </Button>
          <Button
            type="submit"
            primary
            isDisabled={
              loadingRate ||
              expired ||
              (needsConversion && !sufficientFunds) ||
              (!needsConversion && !isSufficientFunds) ||
              isSubmitting ||
              paymentApproved ||
              isRejecting ||
              paymentRejected
            }
            onClick={onSubmit}
          >
            Approve Payment
          </Button>
        </div>
      )}
    </Modal>
  );
};

const PayeeDetails = ({ payee }) => {
  switch (payee.record.__typename) {
    case 'Supplier':
      return <SupplierDetails payee={payee} />;
    case 'CreditCardPayee':
      return <CreditCardPayeeDetails payee={payee} />;
    case 'CRAAccount':
      return <CRAAccountDetails payee={payee} />;
    default:
      return null;
  }
};

const ExchangeRateInfo = ({
  currentChargedAmount,
  originalAmount,
  rate,
  onRefreshRate,
  rateExpiresAt,
  expired,
  setExpired,
}) => {
  const [expiresInSeconds, setExpiresInSeconds] = useState();
  const timerRef = useRef();

  const startTimer = () => {
    clearTimer();
    setExpired(false);
    timerRef.current = setInterval(() => {
      const pastExpiresAt = new Date(rateExpiresAt) <= new Date();

      if (pastExpiresAt) {
        setExpiresInSeconds();
        setExpired(true);
        clearInterval(timerRef.current);
      } else {
        setExpiresInSeconds(formatDistanceToNowStrict(new Date(rateExpiresAt)));
      }
    }, 500);
  };

  const clearTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current);
  };

  useEffect(() => {
    startTimer();

    return clearTimer;
  }, [rateExpiresAt]);

  return (
    <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4 tw-mt-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <div className="tw-flex tw-items-center">
          <small className="tw-font-semibold tw-mr-2">Amount to withdraw</small>
          <InfoTooltip message="The amount you are selling" />
        </div>
        <div className="tw-flex tw-items-center">
          <small className="tw-mr-2">{formatMoneyV2(currentChargedAmount)}</small>
          <span className="tw-text-xs tw-text-neutral-grey-2 tw-w-8 tw-text-right">
            {currentChargedAmount.currency}
          </span>
        </div>
      </div>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <small className="tw-font-semibold">Your exchange rate</small>
        {!expired && rate && (
          <div className="tw-flex tw-items-center tw-justify-between">
            <small className="tw-mr-2">{rate.toFixed(5)}</small>
            <BiTransferAlt className="tw-text-neutral-grey-2 tw-transform tw-rotate-90 tw-w-8" />
          </div>
        )}
      </div>
      {expired ? (
        <div className="tw-rounded-md tw-bg-secondary-pastel-yellow-80 tw-p-4 tw-mb-4 tw-flex tw-justify-between tw-items-center">
          <Warning className="tw-mr-2" />
          <span className="tw-text-primary-dark-yellow tw-text-xs tw-w-3/5 tw-mr-2">
            The exchange rate expired. Refresh to see your final exchange rate.
          </span>
          <span
            onClick={onRefreshRate}
            className="tw-cursor-pointer tw-font-semibold tw-text-xs tw-text-primary-dark-green"
          >
            Refresh Rate
          </span>
        </div>
      ) : (
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <span className="tw-text-neutral-grey-2 tw-text-xs tw-w-2/3">
            {`Rates expires in ${expiresInSeconds} seconds. Refresh to see your final exchange rate.`}
          </span>
          <span
            onClick={onRefreshRate}
            className="tw-cursor-pointer tw-font-semibold tw-text-neutral-grey-2 tw-text-xs"
          >
            Refresh Rate
          </span>
        </div>
      )}
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center">
          <small className="tw-font-semibold tw-mr-2">Payment amount</small>
          <InfoTooltip message="The amount you are buying" />
        </div>
        <div className="tw-flex tw-items-center">
          <small className="tw-mr-2">{formatMoneyV2(originalAmount)}</small>
          <span className="tw-text-xs tw-text-neutral-grey-2 tw-w-8 tw-text-right">{originalAmount.currency}</span>
        </div>
      </div>
    </div>
  );
};

// TODO: refactor Supplier, CreditCardPayee and CRAAccount to have their own domain class

const SupplierDetails = (props) => {
  const { payee } = props;
  const { record: supplier } = payee || {};
  const { displayName, name, address, bankName, bankAccount } = supplier || {};
  const { street, unitNumber, city, countrySubdivision, postalCode } = address;
  const payeeAddress = `${street}, ${unitNumber || ''} ${
    unitNumber ? ',' : ''
  } ${city}, ${countrySubdivision} ${postalCode}`;
  const { maskedNumber } = bankAccount || {};

  return (
    <>
      <div className="tw-flex">
        <small className="tw-w-2/3">Payee Name</small>
        <small className="tw-w-1/3">{displayName || name}</small>
      </div>
      <div className="tw-flex">
        <small className="tw-w-2/3">Payee address</small>
        <div className="tw-w-1/3">
          <small>{payeeAddress}</small>
        </div>
      </div>
      <div className="tw-flex">
        <small className="tw-w-2/3">Bank name</small>
        <small className="tw-w-1/3">{bankName}</small>
      </div>
      <div className="tw-flex tw-pb-4">
        <small className="tw-w-2/3">Account number</small>
        <small className="tw-w-1/3">{maskedNumber}</small>
      </div>
    </>
  );
};

const CreditCardPayeeDetails = (props) => {
  const { payee } = props;
  const { record: creditCardPayee } = payee || {};
  const { displayName, cardNumber, cardType, currency } = creditCardPayee || {};

  return (
    <>
      <div className="tw-flex">
        <small className="tw-w-2/3">Payee Name</small>
        <small className="tw-w-1/3">{displayName}</small>
      </div>
      <div className="tw-flex">
        <small className="tw-w-2/3">Card number</small>
        <div className="tw-w-1/3">
          <small>{cardNumber}</small>
        </div>
      </div>
      <div className="tw-flex">
        <small className="tw-w-2/3">Card type</small>
        <small className="tw-w-1/3">{cardType.charAt(0).toUpperCase() + cardType.slice(1)}</small>
      </div>
      <div className="tw-flex tw-pb-4">
        <small className="tw-w-2/3">Currency</small>
        <small className="tw-w-1/3">{currency}</small>
      </div>
    </>
  );
};

const CRAAccountDetails = (props) => {
  const { payee } = props;
  const { record: craAccount } = payee || {};
  const { displayName, businessNumber, currency } = craAccount;

  return (
    <>
      <div className="tw-flex">
        <small className="tw-w-2/3">Payee Name</small>
        <small className="tw-w-1/3">{displayName}</small>
      </div>
      <div className="tw-flex">
        <small className="tw-w-2/3">Business number</small>
        <div className="tw-w-1/3">
          <small>{businessNumber}</small>
        </div>
      </div>
      <div className="tw-flex tw-pb-4">
        <small className="tw-w-2/3">Currency</small>
        <small className="tw-w-1/3">{currency}</small>
      </div>
    </>
  );
};

export default PaymentConfirmationModal;
