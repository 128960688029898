import React, { useState } from 'react';

export const TabIndexContext = React.createContext({ defaultIndex: null });

export const TabIndexContextProvider = (props) => {
  const [defaultIndex, setDefaultIndex] = useState(0);

  return (
    <TabIndexContext.Provider value={{ defaultIndex, setDefaultIndex }}>{props.children}</TabIndexContext.Provider>
  );
};
