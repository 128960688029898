import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Modal, FormFooter } from 'components/UI';
import AddressFields from 'components/onboarding/AddressFields';
import { UpdateCardAddressModalProps } from './UpdateCardAddressModal.types';
import useUpdateCardAddressModal from './hooks/useUpdateCardAddressModal';

const UpdateCardAddressModal = ({
  isOpenModal,
  handleCloseModal,
  address,
  currentCountry,
}: UpdateCardAddressModalProps) => {
  const { form, loading, handleSubmit, onSubmit } = useUpdateCardAddressModal({
    address,
    currentCountry,
    handleCloseModal,
  });

  if (!isOpenModal) return null;

  return (
    <Modal show={isOpenModal} onClose={handleCloseModal} title={`Update ${currentCountry} Address`}>
      <div className="tw-flex tw-flex-col tw-pt-8 tw-pb-4 tw-border-t tw-border-neutral-grey-4">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AddressFields
              name="address"
              streetAddressLabel="Personal Street Address"
              cityLabel="City"
              selectedCountry={form.watch('address.country')}
              rootClass="tw-px-8"
              autocompleteCountryRestrictions={[currentCountry]}
              initialAddress={address}
              disabled={loading}
            />
            <FormFooter
              submitButtonLabel="Update"
              cancelButtonLabel="Cancel"
              onCancel={handleCloseModal}
              loading={loading}
              rootClass="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-8"
            />
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default UpdateCardAddressModal;
