import { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { get, isUndefined } from 'lodash';
import { ampTrackEvent } from 'amplitude';

import { ADD_SUPPLIER } from 'graphql/suppliers';
import { GET_PAYEES } from 'graphql/payees';
import { useToggle } from 'hooks';
import { AddPayeeContext } from 'components/payments/AddPayee/AddPayeeContext';
import getStepsWithError from 'utility/getStepsWithError';
import { SUPPLIER_ATTRIBUTES_BY_STEP } from '../constants';
import { PAYEE_DETAILS_SOURCE } from 'components/payments/AddPayee/constants';

const useAddSupplierStep = ({ setStep, onFinish }) => {
  const { setErrorSteps } = useContext(AddPayeeContext);

  const { isOpen: isOpenCompleteSection, open: openCompleteSection } = useToggle();

  const [addSupplier, { loading: isAddSupplierLoading, error: addSupplierMutationError }] = useMutation(ADD_SUPPLIER, {
    refetchQueries: [{ query: GET_PAYEES }],
    awaitRefetchQueries: true,
  });

  const handleFinish = async (supplierInfo) => {
    const processingToast = toast.loading('Creating supplier...');

    const isRequestedPayeeDetails = supplierInfo?.payeeDetailsSource === PAYEE_DETAILS_SOURCE.BY_REQUEST;

    let createdSupplierId;

    try {
      const response = await addSupplier({
        variables: { ...supplierInfo, bankAccountProvided: !isRequestedPayeeDetails },
      });

      createdSupplierId = get(response, 'data.addSupplier.id');

      if (!createdSupplierId) {
        throw new Error("Can't create the supplier");
      }

      if (isRequestedPayeeDetails) {
        openCompleteSection();
      } else {
        onFinish();
      }

      toast.update(processingToast, {
        render: 'Supplier was added successfully',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      ampTrackEvent('Supplier: created');
      ampTrackEvent(`Supplier: created: ${isRequestedPayeeDetails ? 'bank details requested' : 'with bank details'}`);
    } catch (err) {
      toast.update(processingToast, {
        render: `Something went wrong. Please try again.`,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      console.error(err);

      ampTrackEvent('Supplier: creation failed');
    }
  };

  useEffect(() => {
    const fallbackSteps = getStepsWithError({
      attributesByStep: SUPPLIER_ATTRIBUTES_BY_STEP,
      error: addSupplierMutationError,
    });
    setErrorSteps(fallbackSteps);
    const errorStep = fallbackSteps[0];

    if (!isUndefined(errorStep)) setStep(errorStep);
  }, [addSupplierMutationError]);

  return {
    handleFinish,
    loading: isAddSupplierLoading,
    mutationError: addSupplierMutationError,
    isOpenCompleteSection,
  };
};

export default useAddSupplierStep;
