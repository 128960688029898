import React, { useCallback, useEffect, useRef } from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';

import { getUniqueId } from 'utility';

import './InfoTooltip.scss';

const InfoTooltipWithMessage = ({ message, testId, size }) => {
  if (!message) return null;

  return (
    <div className="tw-flex tw-items-center" data-tip={message} data-for={testId}>
      <IoInformationCircleOutline
        className="tw-text-neutral-grey-2 tw-cursor-pointer focus:tw-outline-none focus:tw-ring-0"
        data-testid={testId}
        size={size}
      />
      <ReactTooltip
        id={testId}
        className="tw-w-1/2 lg:tw-w-64 tw-text-center tw-p-4 tw-text-sm tw-rounded-md tw-shadow-lg tw-opacity-100"
        textColor="var(--colors-natural-light)"
        backgroundColor="var(--colors-natural-grey-1)"
      />
    </div>
  );
};

const InfoTooltipWithChildren = ({ testId, size, name, children }) => {
  if (!children) return null;

  return (
    <div className="tw-flex tw-items-center">
      <IoInformationCircleOutline
        className="tw-text-neutral-grey-2 tw-cursor-pointer focus:tw-outline-none focus:tw-ring-0"
        data-testid={testId}
        size={size}
        data-for={name}
        data-tip
      />
      <ReactTooltip
        className="tw-w-96 tw-p-4 tw-text-sm tw-rounded-md tw-shadow-lg"
        backgroundColor="var(--colors-natural-grey-1)"
        id={name}
      >
        {children}
      </ReactTooltip>
    </div>
  );
};

const InfoTooltip = (props) => {
  const { message } = props;
  return <>{message ? <InfoTooltipWithMessage {...props} /> : <InfoTooltipWithChildren {...props} />}</>;
};

export const IconTooltip = ({ message, icon, rootClass = 'tw-flex tw-text-neutral-grey-2' }) => {
  return (
    <div
      className={`tw-items-center tw-cursor-pointer focus:tw-outline-none focus:tw-ring-0 ${
        !rootClass ? '' : rootClass
      }`}
      data-tip={message}
    >
      {icon}
      <ReactTooltip
        className="tw-w-1/2 lg:tw-w-64 tw-text-center tw-p-4 tw-text-sm tw-rounded-md tw-shadow-lg tw-opacity-100"
        textColor="var(--colors-natural-light)"
        backgroundColor="var(--colors-natural-grey-1)"
      />
    </div>
  );
};

/*
 * This tooltip should be used only to handle issues
 * of binding tooltip's scripts for dynamic nodes.
 * E.g., Modals.
 */
export const InfoTooltipForModals = ({
  message = '',
  html = false,
  rootClass = '',
  onlyIcon = false,
  icon = '',
  iconSize = 16,
  children = null,
}) => {
  const target = useRef(null);
  const id = useRef(getUniqueId()).current;

  const handleMouseEnter = useCallback(() => {
    ReactTooltip.show(target.current);
  }, [target]);

  const handleMouseLeave = useCallback(() => {
    ReactTooltip.hide(target.current);
  }, [target]);

  if (!message) return null;

  return (
    <>
      <div
        className={`tw-cursor-pointer tw-inline ${!rootClass ? '' : rootClass}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-tip={message}
        data-for={id}
        data-html={html}
        ref={(ref) => {
          target.current = ref;
        }}
      >
        {onlyIcon
          ? icon || (
              <IoInformationCircleOutline
                className="tw-text-neutral-grey-2 tw-cursor-pointer focus:tw-outline-none focus:tw-ring-0 tw-inline-block tw-ml-1"
                size={iconSize}
              />
            )
          : children}
      </div>
      <ReactTooltip
        id={id}
        className="tw-w-1/2 lg:tw-w-64 tw-p-4 tw-text-sm tw-rounded-md tw-shadow-lg tw-opacity-100"
        textColor="var(--colors-natural-light)"
        backgroundColor="var(--colors-natural-grey-1)"
      />
    </>
  );
};

// Necessary for tooltip to work on modals
export const useRebuildTooltipForModal = () => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
};

export default InfoTooltip;
