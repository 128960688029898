import { useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { addDays } from 'date-fns';
import { get } from 'lodash';

import { formatMoneyV2, centsFromMoneyString } from 'utility/currency';
import { AddPaymentRequestDataType } from 'components/Invoices/components/Payors/components/PayorsList/PayorsList.types';
import { PayorsContext } from 'components/Invoices/contexts/PayorsContext';
import { AddPaymentRequestContext } from 'components/Invoices/components/Payors/components/PayorsList/contexts/AddPaymentRequestContext';
import {
  MAX_PAYMENT_REQUEST_AMOUNT_CENTS,
  MIN_PAYMENT_REQUEST_AMOUNT_CENTS,
  MAX_DUE_DATE_RANGE_DAYS,
  MAX_END_DATE_RANGE_DAYS,
  REQUESTS_RECURRING_TYPE,
  REQUESTS_RECURRING_OPTIONS,
  ONGOING_FREQUENCY_OPTIONS,
  REQUESTS_RECURRING_STOP_TYPE,
  REQUESTS_RECURRING_STOP_OPTIONS,
} from 'components/Invoices/constants';
import { PaymentRequestCurrency } from 'types/invoicing';
import { StepsProps } from '../../../Steps.types';
import { Currencies } from 'constants/currencies';
import { useDeepEffect } from 'hooks';

const useDetails = ({ onNextStep }: Pick<StepsProps, 'onNextStep'>) => {
  const DEFAULT_CURRENCY = PaymentRequestCurrency.CAD;

  const { selectedPayorId, payors, wallets } = useContext(PayorsContext);
  const { paymentRequestInfo, setPaymentRequestInfo, error } = useContext(AddPaymentRequestContext);

  const { amount, currency } = get(paymentRequestInfo, 'amount', { amount: 0, currency: DEFAULT_CURRENCY });
  const { accountId } = paymentRequestInfo || {};
  const [formattedAmount, setFormattedAmount] = useState(formatMoneyV2({ amount, currency }));

  const payorsOptions = useMemo(() => payors.map((payor) => ({ name: payor.name, value: payor.id }), []), [payors]);

  const defaultWalletId = useMemo(
    () => wallets.find(({ currency }) => currency === Currencies[DEFAULT_CURRENCY])?.id,
    [wallets]
  );

  const walletsOptions = useMemo(() => wallets.map(({ name, id }) => ({ name, value: id })), [wallets]);

  const recurringTypeOptions = useMemo(() => REQUESTS_RECURRING_OPTIONS, []);

  const ongoingFrequencyOptions = useMemo(() => ONGOING_FREQUENCY_OPTIONS, []);

  const recurringStopOptions = useMemo(() => REQUESTS_RECURRING_STOP_OPTIONS, []);

  const form = useForm({
    defaultValues: {
      payorId: selectedPayorId,
      ...paymentRequestInfo,
      accountId: accountId || defaultWalletId,
      amount: formattedAmount,
      recurringType: paymentRequestInfo?.recurringType || REQUESTS_RECURRING_TYPE.once.value,
    },
  });

  const handleOnNextStep = (data: AddPaymentRequestDataType) => {
    setPaymentRequestInfo({
      ...data,
      amount: { amount: centsFromMoneyString(data.amount), currency: selectedWalletCurrency },
    });
    onNextStep();
  };

  const formattedMinAmount = useMemo(
    () => formatMoneyV2({ amount: MIN_PAYMENT_REQUEST_AMOUNT_CENTS, currency }),
    [currency]
  );
  const formattedMaxAmount = useMemo(
    () => formatMoneyV2({ amount: MAX_PAYMENT_REQUEST_AMOUNT_CENTS, currency }),
    [currency]
  );

  const validateAmountValue = (value: string) => {
    const valueInCents = centsFromMoneyString(value);
    return (
      (valueInCents >= MIN_PAYMENT_REQUEST_AMOUNT_CENTS && valueInCents <= MAX_PAYMENT_REQUEST_AMOUNT_CENTS) ||
      `Amount should be in the range from ${formattedMinAmount} to ${formattedMaxAmount}`
    );
  };

  const { handleSubmit, register, watch } = form;

  const watchRecurringType = watch('recurringType');
  const watchRecurringStopType = watch('recurringStopType');
  const watchDuetDate = watch('dueDate');
  const watchAccountId = watch('accountId');

  const isOngoingFrequencyShown = watchRecurringType === REQUESTS_RECURRING_TYPE.ongoing.value;

  const isRecurringEndDate = watchRecurringStopType === REQUESTS_RECURRING_STOP_TYPE.endDate.value;
  const isRecurringNumberOfOccurrences =
    watchRecurringStopType === REQUESTS_RECURRING_STOP_TYPE.numberOfOccurrences.value;

  const minDueDate = useMemo(() => new Date(), []);
  const maxDueDate = useMemo(() => addDays(new Date(), MAX_DUE_DATE_RANGE_DAYS), []);

  const minEndDate = useMemo(() => addDays(new Date(watchDuetDate || minDueDate), 1), [watchDuetDate, minDueDate]);
  const maxEndDate = useMemo(
    () => addDays(new Date(watchDuetDate || minDueDate), MAX_END_DATE_RANGE_DAYS),
    [watchDuetDate, minDueDate]
  );

  const selectedWalletCurrency = useMemo(
    () => wallets.find(({ id }) => id === watchAccountId)?.currency || currency,
    [wallets, watchAccountId, currency]
  ) as PaymentRequestCurrency;

  useDeepEffect(() => {
    const amount = centsFromMoneyString(formattedAmount);
    setFormattedAmount(formatMoneyV2({ amount, currency: selectedWalletCurrency }));
  }, [selectedWalletCurrency, formattedAmount]);

  return {
    form,
    register,
    handleOnNextStep,
    handleSubmit,
    payorsOptions,
    walletsOptions,
    currency: selectedWalletCurrency,
    formattedAmount,
    setFormattedAmount,
    minDueDate,
    maxDueDate,
    minEndDate,
    maxEndDate,
    error,
    validateAmountValue,
    recurringTypeOptions,
    ongoingFrequencyOptions,
    recurringStopOptions,
    isOngoingFrequencyShown,
    isRecurringEndDate,
    isRecurringNumberOfOccurrences,
  };
};

export default useDetails;
