import { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { Loader } from '@googlemaps/js-api-loader';

import { GOOGLE_ADDRESS_ENTITIES } from 'constants/index';
import config from 'config';

/**
 * A custom hook for handling address autocompletion.
 *
 * @param { Object } object
 * @param { String[]= } object.countryRestrictions Array of country codes used for component restrictions
 * @returns
 */

const DEFAULT_COUNTRIES = ['CA'];

const useAddressAutoComplete = ({ countryRestrictions = DEFAULT_COUNTRIES }) => {
  const [address, setAddress] = useState(null);
  const inputRef = useRef(null);
  const autoComplete = useRef(null);

  const getFullAddress = () => {
    const place = autoComplete.current?.getPlace();
    if (!place) return;

    let streetNumber;
    let street;
    let unitNumber = '';
    let city;
    let city2;
    let countrySubdivision;
    let countrySubdivision2;
    let country;
    let postalCode;

    for (const component of place.address_components) {
      const [componentType] = component.types;

      switch (componentType) {
        case GOOGLE_ADDRESS_ENTITIES.streetNumber:
          streetNumber = component.short_name;
          break;
        case GOOGLE_ADDRESS_ENTITIES.street:
          street = component.short_name;
          break;
        case GOOGLE_ADDRESS_ENTITIES.unitNumber:
          unitNumber = component.short_name;
          break;
        case GOOGLE_ADDRESS_ENTITIES.city:
          city = component.short_name;
          break;
        case GOOGLE_ADDRESS_ENTITIES.city2:
          city2 = component.short_name;
          break;
        case GOOGLE_ADDRESS_ENTITIES.countrySubdivision:
          countrySubdivision = component.short_name;
          break;
        case GOOGLE_ADDRESS_ENTITIES.countrySubdivision2:
          countrySubdivision2 = component.short_name;
          break;
        case GOOGLE_ADDRESS_ENTITIES.country:
          country = component.short_name;
          break;
        case GOOGLE_ADDRESS_ENTITIES.postalCode:
          postalCode = component.short_name;
          break;
      }
    }

    const newAddress = {
      street: streetNumber ? `${streetNumber} ${street}` : street,
      unitNumber,
      city: city || city2,
      countrySubdivision: countrySubdivision || countrySubdivision2,
      country,
      postalCode,
    };

    if (!isEmpty(newAddress) && inputRef.current) inputRef.current.value = newAddress.street;

    setAddress(newAddress);
  };

  useEffect(() => {
    const loader = new Loader({
      apiKey: config.googleAddressAPIKey,
      libraries: ['places'],
    });

    loader.load().then(() => {
      const normalizedCountries = countryRestrictions.map((code) => code?.toLowerCase());

      autoComplete.current = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['address'],
        fields: ['address_component'],
        componentRestrictions: {
          country: normalizedCountries,
        },
      });

      autoComplete.current?.addListener('place_changed', getFullAddress);
    });
  }, []);

  return {
    address,
    inputRef,
  };
};

export default useAddressAutoComplete;
