import { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { ampTrackEvent } from 'amplitude';
import { useBasisTheory } from '@basis-theory/basis-theory-react';
import type {
  CardNumberElement,
  CardExpirationDateElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-react/types';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { isAfter } from 'date-fns';
import { useHistory } from 'react-router-dom';

import config from 'config';
import { saveToLocalStorage, loadFromLocalStorage } from 'utility/localStorage';
import { AUTHORIZE_BASIS_THEORY_SESSION } from 'graphql/basisTheory';
import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { BasisTheorySessionData } from '../CardDetails.types';

const basisTheorySessionKey = 'basisTheorySession';

const useCardDetails = () => {
  const [isLoadingCardDetails, setIsLoadingCardDetails] = useState(false);

  const { creditCardDetails } = useContext(CardDetailsContext);
  const { id: cardId, cardProcessorId, cardType } = creditCardDetails || {};
  // initialize Basis Theory
  const { bt: basisTheory } = useBasisTheory(config.basisTheoryApiKey, { elements: true });

  const cardNumberRef = useRef<CardNumberElement>(null);
  const cardExpiryRef = useRef<CardExpirationDateElement>(null);
  const cardCVCRef = useRef<CardVerificationCodeElement>(null);

  const history = useHistory();

  const [authorizeBasisTheorySession] = useMutation(AUTHORIZE_BASIS_THEORY_SESSION);

  useEffect(() => {
    cardNumberRef.current?.clear();
    cardExpiryRef.current?.clear();
    cardCVCRef.current?.clear();
  }, [creditCardDetails]);

  const createBasisTheorySession = useCallback(async () => {
    setIsLoadingCardDetails(true);

    try {
      if (!cardProcessorId || !cardId || !basisTheory) {
        throw new Error('Missing cardProcessorId, cardId or basisTheory');
      }

      const session = await basisTheory.sessions.create();

      if (!session?.nonce) throw new Error('Failed to create Basis Theory session');

      const { nonce, sessionKey } = session;

      const allSavedSessions =
        (loadFromLocalStorage<BasisTheorySessionData>(basisTheorySessionKey) as BasisTheorySessionData) || {};
      saveToLocalStorage(basisTheorySessionKey, { ...allSavedSessions, [cardProcessorId]: session });

      const response = await authorizeBasisTheorySession({ variables: { creditCardId: cardId, nonce } });

      if (response?.data?.authorizeBasisTheorySession) ampTrackEvent('createBasisTheorySession: success');

      return sessionKey;
    } catch (err) {
      console.error('Failed to establish card details session.', err);
      ampTrackEvent('createBasisTheorySession: error');
      toast.error('Failed to retrieve card details. Please reload the page and try again.');
    } finally {
      setIsLoadingCardDetails(false);
    }
  }, [cardProcessorId, cardId, basisTheory]);

  const getCardDetails = useCallback(
    async ({ sessionKey }: { sessionKey?: string }) => {
      try {
        if (!cardProcessorId || !basisTheory || !sessionKey) {
          throw new Error('Missing cardProcessorId, basisTheory or sessionKey');
        }

        const token = await basisTheory.tokens.retrieve(String(cardProcessorId), { apiKey: sessionKey });

        if (!token?.data) throw new Error('Failed to retrieve card details');

        const { number, expiration_year, expiration_month, cvc } = token.data;

        cardNumberRef?.current?.setValue(number);
        cardExpiryRef?.current?.setValue({ year: expiration_year, month: expiration_month });
        cardCVCRef?.current?.setValue(cvc);
      } catch (err) {
        console.error('Failed to retrieve card details.', err);
        toast.error('Failed to retrieve card details. Please reload the page and try again.');
      }
    },
    [cardProcessorId, basisTheory, cardNumberRef?.current, cardExpiryRef?.current, cardCVCRef?.current]
  );

  const showCardDetails = useCallback(async () => {
    const allSavedSessions =
      (loadFromLocalStorage<BasisTheorySessionData>(basisTheorySessionKey) as BasisTheorySessionData) || {};
    const savedSession = allSavedSessions?.[cardProcessorId!];

    let sessionKey = savedSession?.sessionKey;

    if (!sessionKey || isAfter(new Date(), new Date(savedSession!.expiresAt))) {
      const basisTheorySessionKey = await createBasisTheorySession();
      if (basisTheorySessionKey) {
        sessionKey = basisTheorySessionKey;
      }
    }

    if (!sessionKey) {
      console.error('Failed to establish card details session.');
      return;
    }

    await getCardDetails({ sessionKey });
  }, [cardProcessorId, getCardDetails, createBasisTheorySession]);

  const goToCardsListPage = useCallback(() => {
    history.push('/dashboard/cards/list-new');
  }, []);

  return {
    basisTheory,
    cardCVCRef,
    cardExpiryRef,
    cardNumberRef,
    showCardDetails,
    isLoadingCardDetails,
    setIsLoadingCardDetails,
    authorizeBasisTheorySession,
    goToCardsListPage,
    creditCardDetails,
    cardType,
  };
};

export default useCardDetails;
