import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import config from 'config';
import { pendingTask, KYC_STATUS, TRANSACTION_PRODUCT_TYPES } from 'constants/index';
import { GET_CREDIT_CARDS_FOR_LIST, GET_CREDIT_CARDS_IDS, GET_GROUPED_CARD_INFO } from 'graphql/cards';
import { GET_CREDIT_PRODUCTS, GET_GLOBAL_CREDIT_LIMIT, GET_WALLETS } from 'graphql/creditOffer';
import { GET_COMPLETED_ACCOUNT_GAMIFICATION_EVENTS } from 'graphql/gamification';
import { GET_BANK_ACCOUNTS } from 'graphql/integrations';
import { GET_TRANSACTIONS_PAGE } from 'graphql/transactions';
import { CreditCard, GlobalCreditLimit, GroupedCardsInfo } from 'types/creditCard';
import { PendingTransaction } from 'types/transactions';
import { AccountWallet } from 'types/wallet';
import { LineOfCredit } from 'types/lineOfCredit';
import { AuthContext } from 'context/Auth';
import { Event } from 'types/event';

type useDesktopHomeType = {
  accountCompletedEvents: Event[];
  allowCardCreation: boolean;
  bankAccountsLoading: boolean;
  bankAccountVerified: boolean;
  connectedBankAccount: boolean;
  creditCard: CreditCard;
  creditCardIds: { __typename: string; id: string }[];
  creditCardLoading: boolean;
  creditCards: CreditCard[];
  globalCreditLimit: GlobalCreditLimit;
  globalCreditLimitLoading: boolean;
  groupedCardsInfo: GroupedCardsInfo;
  hasAtLeastOneBankAccountVerified: boolean;
  hasLineOfCredit: boolean;
  lineOfCredit: LineOfCredit;
  loadingCompletedEvents: boolean;
  loadingCreditInfo: boolean;
  loadingProducts: boolean;
  loadingTransactions: boolean;
  pendingTasks: { id: string }[];
  transactions: PendingTransaction[];
  walletLoading: boolean;
  wallets: AccountWallet[];
  welcomeModalSeen: boolean;
  newSolidAccountNumberSeen: boolean;
  meLoading: boolean;
};

const useDesktopHome = (): useDesktopHomeType => {
  const { loading: loadingCreditInfo, data: creditProductsData } = useQuery(GET_CREDIT_PRODUCTS);
  const { loading: loadingTransactions, data: transactionsData } = useQuery(GET_TRANSACTIONS_PAGE, {
    variables: {
      currencies: [],
      categories: [],
      loopProducts: [],
      products: [
        TRANSACTION_PRODUCT_TYPES.creditCard,
        TRANSACTION_PRODUCT_TYPES.wallet,
        TRANSACTION_PRODUCT_TYPES.lineOfCredit,
      ],
      page: `1`,
      numPerPage: `10`,
    },
  });

  const { transactionsPage } = transactionsData || {
    transactionsPage: {
      items: [],
      pageData: { totalPage: 0, currentPage: 0, nextPage: 0, prevPage: 0 },
    },
  };

  const { items: transactions } = transactionsPage;

  const { data: walletData, loading: walletLoading } = useQuery(GET_WALLETS);
  const { data: creditCardData, loading: creditCardLoading } = useQuery(GET_CREDIT_CARDS_FOR_LIST);
  const { data: creditCardIdsData, loading: creditCardIdLoading } = useQuery(GET_CREDIT_CARDS_IDS);
  const { data: groupedCardsData, loading: groupedCardsDataLoading } = useQuery(GET_GROUPED_CARD_INFO);
  const { data: globalCreditLimitData, loading: globalCreditLimitLoading } = useQuery(GET_GLOBAL_CREDIT_LIMIT);
  const { data: bankAccountsData, loading: bankAccountsLoading } = useQuery(GET_BANK_ACCOUNTS);
  const { data: completedEventsData, loading: completedEventsLoading } = useQuery(
    GET_COMPLETED_ACCOUNT_GAMIFICATION_EVENTS
  );

  const { me: creditProductsDataSet } = creditProductsData || {};
  const { me, meLoading } = useContext(AuthContext) as unknown as { me: object; meLoading: boolean };

  const completedEvents = get(completedEventsData, 'me.account.completedGamificationEvents', []);
  const bankAccounts = get(bankAccountsData, 'bankInfo.bankAccounts') || [];
  const connectedBankAccount = get(walletData, 'me.account.bankAccount.connected');
  const bankAccountVerified = get(walletData, 'me.account.bankAccount.verified');
  const lineOfCredit = get(creditProductsDataSet, 'account.lineOfCredit');
  const globalCreditLimit = get(globalCreditLimitData, 'me.account.globalCreditLimit') || false;
  const creditCards = get(creditCardData, 'me.account.creditCards') || [];
  const creditCardIds = get(creditCardIdsData, 'me.account.creditCards') || [];
  const kycAssessment = get(creditCardIdsData, 'me.account.kycAssessment.status') || false;

  const groupedCardsInfo = get(groupedCardsData, 'primaryCreditCard');
  const wallets = get(walletData, 'me.account.wallets') || [];

  let pendingTasks = get(creditProductsDataSet, 'account.pendingTasks') || [];
  const hasLineOfCredit = !!lineOfCredit;
  const isExistingLocUser = hasLineOfCredit && creditCards.length === 0;
  const hasAtLeastOneBankAccountVerified = bankAccounts.some((ba: { verified: boolean }) => ba.verified);
  const allowCardCreation =
    kycAssessment === KYC_STATUS.APPROVED &&
    hasAtLeastOneBankAccountVerified &&
    !isExistingLocUser &&
    !config.cardApiDown;

  if (!allowCardCreation) {
    pendingTasks = pendingTasks.filter((task: { id: string }) => task.id !== pendingTask.card);
  }

  const creditCard = creditCards[0];
  const loadingProducts = walletLoading || creditCardLoading || loadingCreditInfo;

  type ModalTypes = { welcomeModalSeen: boolean; newSolidAccountNumberSeen: boolean };

  const { welcomeModalSeen, newSolidAccountNumberSeen }: ModalTypes = get(me, 'account') ?? {
    welcomeModalSeen: true,
    newSolidAccountNumberSeen: true,
  };

  return {
    accountCompletedEvents: completedEvents,
    allowCardCreation,
    bankAccountsLoading,
    bankAccountVerified,
    connectedBankAccount,
    creditCard,
    creditCardIds,
    creditCardLoading: creditCardLoading || creditCardIdLoading || groupedCardsDataLoading,
    creditCards,
    globalCreditLimit,
    globalCreditLimitLoading,
    groupedCardsInfo,
    hasAtLeastOneBankAccountVerified,
    hasLineOfCredit,
    lineOfCredit,
    loadingCompletedEvents: completedEventsLoading,
    loadingCreditInfo,
    loadingProducts,
    loadingTransactions,
    pendingTasks,
    transactions,
    walletLoading,
    wallets,
    welcomeModalSeen,
    newSolidAccountNumberSeen,
    meLoading,
  };
};
export default useDesktopHome;
