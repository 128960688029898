import React from 'react';

import { Currencies } from 'constants/currencies';
import { formatMoneyV2 } from 'utility/currency';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import RepeatPendingOutline from 'components/svg/RepeatPendingOutline';
import { PendingPaymentProps } from './PendingPayment.types';

const PendingPayment = ({ balance }: PendingPaymentProps) => {
  const { amount, currency } = balance;

  if (amount === 0) return null;

  const formattedBalance = formatMoneyV2({ amount: Math.abs(amount), currency });

  return (
    <div className="tw-flex tw-items-center tw-justify-start tw-gap-2 tw-cursor-default tw-text-sm">
      <div className="tw-flex tw-gap-2 tw-items-center tw-h-6 tw-bg-primary-dark-green tw-px-2 tw-rounded-md">
        <RepeatPendingOutline size={21} className="tw-text-primary-blue tw-mr-1" />

        <div className="tw-rounded-full">
          <MobileCurrencyFlag currency={Currencies[currency]} size={18} />
        </div>

        <div className="tw-text-neutral-light">Payment of {formattedBalance} in Transit</div>
      </div>
    </div>
  );
};

export default PendingPayment;
