import React from 'react';

type BankProps = {
  width?: number;
  height?: number;
};

const Bank = ({ width = 17, height = 17, ...props }: BankProps) => (
  <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="0.375"
      y="19.2122"
      width="20.1802"
      height="1.34302"
      rx="0.671512"
      fill="none"
      stroke="currentColor"
      strokeWidth="0.75"
    />
    <rect
      x="2.46826"
      y="17.1191"
      width="15.9942"
      height="1.34302"
      fill="none"
      stroke="currentColor"
      strokeWidth="0.75"
    />
    <rect x="2.46826" y="6.375" width="15.9942" height="1.34302" fill="none" stroke="currentColor" strokeWidth="0.75" />
    <rect x="3.58447" y="7.96973" width="1.34302" height="8.9" fill="none" stroke="currentColor" strokeWidth="0.75" />
    <rect x="9.60498" y="7.96973" width="1.34302" height="8.9" fill="none" stroke="currentColor" strokeWidth="0.75" />
    <rect x="15.8633" y="7.96973" width="1.34302" height="8.9" fill="none" stroke="currentColor" strokeWidth="0.75" />
    <path
      d="M10.0359 0.609171C10.4019 0.474321 10.8043 0.475632 11.1694 0.612863L20.0676 3.95716C20.4029 4.08319 20.625 4.40388 20.625 4.76211C20.625 5.23703 20.24 5.62203 19.7651 5.62203H1.23141C0.758427 5.62203 0.375 5.2386 0.375 4.76562C0.375 4.40682 0.598664 4.08606 0.935343 3.96202L10.0359 0.609171Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="0.75"
    />
  </svg>
);

export default Bank;
