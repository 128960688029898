import React, { memo } from 'react';

import { useGlobalLimitChart } from './hooks';
import { ChartItem } from './components';
import { GlobalLimitChartProps } from './GlobalLimitChart.types';

const GlobalLimitChart = ({ dataset, type, isLoading }: GlobalLimitChartProps) => {
  const { data } = useGlobalLimitChart({ dataset, type });

  return (
    <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-3 md:tw-gap-0 tw-w-full md:tw-rounded tw-overflow-hidden tw-mb-3 tw-cursor-default">
      {data.map(({ id, label, value, labelColor, bgColor }) => (
        <ChartItem
          key={id}
          label={label}
          value={value}
          labelColor={labelColor}
          bgColor={bgColor}
          isLoading={isLoading}
        />
      ))}
    </div>
  );
};

export default memo(GlobalLimitChart);
