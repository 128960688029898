export const PAYEE_TYPES = {
  SUPPLIER: 'SUPPLIER',
  CREDIT_CARD: 'CREDIT_CARD',
  CRA: 'CRA',
};

const defaultSteps = {
  'Payee Type': [0],
  Details: [1],
};

export const SELECT_PAYEE_TYPE = {
  ...defaultSteps,
};

export const ADD_CRA_STEPS = {
  ...defaultSteps,
};

export const ADD_CREDIT_CARD_STEPS = {
  ...defaultSteps,
  Documents: [2],
};

export const ADD_SUPPLIER_STEPS = {
  ...defaultSteps,
  Banking: [2],
  Address: [3],
};

export const enum PAYEE_DETAILS_SOURCE {
  MANUAL = 'MANUAL',
  BY_REQUEST = 'BY_REQUEST',
}
