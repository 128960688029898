import { useContext } from 'react';
import { get } from 'lodash';

import { AuthContext } from 'context/Auth';

const useHasLineOfCredit = () => {
  const { me } = useContext(AuthContext);

  const lineOfCredit = get(me, 'account.lineOfCredit');

  return { hasLineOfCredit: !!lineOfCredit };
};

export default useHasLineOfCredit;
