import { useContext, useCallback } from 'react';
import { ampTrackEvent } from 'amplitude';

import { formatDate } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { AddFundsToBalanceContext } from 'components/creditCards/context/AddFundsToBalance';
import { UseStatementBalanceCardStylesProps } from '../StatementBalanceCard.types';

const useStatementBalanceCard = ({
  currency,
  balance,
  prevStartDate,
  prevEndDate,
  prevBalance,
  credit,
  debit,
  amountDue,
  openAddFundsToBalanceModal,
  isAdmin,
}: UseStatementBalanceCardStylesProps) => {
  const { setTransaction } = useContext(AddFundsToBalanceContext);

  const formattedBalance = formatMoneyV2({
    amount: balance.cents,
    currency,
  });
  const formattedPrevBalance = formatMoneyV2({
    amount: prevBalance?.cents,
    currency,
  });
  const formattedDebitAmount = formatMoneyV2({
    amount: debit?.cents,
    currency,
  });
  const formattedCreditAmount = formatMoneyV2({
    amount: credit?.cents && credit.cents > 0 ? -credit.cents : credit?.cents,
    currency,
  });

  const formattedAmountDue =
    amountDue &&
    formatMoneyV2({
      amount: amountDue?.cents,
      currency,
    });

  const formattedPrevCycle =
    prevStartDate && prevEndDate ? `${formatDate(prevStartDate, 'd MMM')} - ${formatDate(prevEndDate, 'd MMM')}` : null;

  const handleOpenAddFundsToBalanceModal = useCallback(() => {
    if (!isAdmin) return;

    setTransaction({
      originalAmount: {
        amount: balance.cents > 0 ? balance.cents : 0,
        currency: balance.currency_iso,
      },
    });
    openAddFundsToBalanceModal();
    ampTrackEvent('payBalance: customAmount: open modal');
  }, [isAdmin, balance, openAddFundsToBalanceModal]);

  return {
    formattedBalance,
    formattedPrevBalance,
    formattedCreditAmount,
    formattedDebitAmount,
    formattedPrevCycle,
    formattedAmountDue,
    handleOpenAddFundsToBalanceModal,
  };
};

export default useStatementBalanceCard;
