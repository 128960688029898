import React from 'react';

type DeactivatedCardProps = {
  width?: number;
  height?: number;
};

const DeactivatedCard = ({ width = 64, height = 64 }: DeactivatedCardProps) => (
  <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.8" cx="32" cy="32" r="32" fill="black" />
    <g clipPath="url(#clip0_18325_58947)">
      <path
        d="M27.3333 32.0007H36.6666M43.6666 32.0007C43.6666 38.444 38.4432 43.6673 31.9999 43.6673C25.5566 43.6673 20.3333 38.444 20.3333 32.0007C20.3333 25.5573 25.5566 20.334 31.9999 20.334C38.4432 20.334 43.6666 25.5573 43.6666 32.0007Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_18325_58947">
        <rect width="28" height="28" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);

export default DeactivatedCard;
