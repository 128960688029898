import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import { CHANGE_PHONE } from 'graphql/settings';
import { TextField, PasswordField } from 'components/FormFields/v2';
import { AuthContext } from 'context/Auth';
import VerifyPhoneNumber from 'components/settings/VerifyPhoneNumber';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import Button from 'components/Button';

const UpdateMobilePhoneNumber = ({ show, closeModal, mobilePhoneNumber, meRefetch }) => {
  const { me } = useContext(AuthContext);
  const { smsMfaEnabled } = me || {};
  const form = useForm();
  const { register, handleSubmit, reset } = form;

  const [success, setSuccess] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [changePhone, { loading: changingPhone, error }] = useMutation(CHANGE_PHONE);

  const onSubmit = async ({ currentPassword }) => {
    setSuccess();
    try {
      await changePhone({ variables: { newPhoneNumber: phoneNumber, currentPassword } });
      await meRefetch();
      setSuccess(true);
    } catch (err) {
      console.error(err);
      reset();
    }
  };

  const handleOnPhoneVerifySuccess = () => {
    setSuccess(false);
  };

  const handleTogglePassword = ({ newPhoneNumber }) => {
    setShowPassword(!showPassword);
    setPhoneNumber(newPhoneNumber);
  };

  const handleCloseModal = () => {
    closeModal('phone');
    setShowPassword(false);
    setSuccess();
    reset();
  };

  return (
    <Modal show={show} onClose={handleCloseModal}>
      <div className="tw-flex tw-justify-between tw-p-6">
        <h5 className="tw-text-left">Edit details</h5>
        <Button onClick={handleCloseModal}>
          <Close />
        </Button>
      </div>

      {success ? (
        <VerifyPhoneNumber handleCloseModal={handleCloseModal} onSuccess={handleOnPhoneVerifySuccess} />
      ) : (
        <>
          <hr className="tw-mb-6" />
          <h3 className="tw-px-10 tw-text-2xl tw-font-semibold">
            {smsMfaEnabled
              ? 'Please disable Multi-Factor Authentication first in order to update the phone number.'
              : (!showPassword && 'Enter your updated phone number.') || 'Enter password to save changes.'}
          </h3>

          <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
            <form onSubmit={handleSubmit(showPassword ? onSubmit : handleTogglePassword)}>
              <div className="tw-flex tw-w-full tw-flex-col tw-justify-center tw-px-10 tw-pt-6 tw-pb-4">
                {!showPassword && (
                  <TextField
                    type="text"
                    name="newPhoneNumber"
                    placeholder="647-490-4901"
                    label="New Phone"
                    inputGroupText="+1"
                    rootClass="tw-mb-6"
                    autoComplete="tel-national"
                    defaultValue={mobilePhoneNumber}
                    required
                    ref={register({ required: true })}
                    disabled={smsMfaEnabled}
                  />
                )}
                {showPassword && (
                  <PasswordField
                    data-testid="signin-password"
                    label="Password"
                    placeholder="∗∗∗∗∗∗∗∗"
                    autoComplete="current-password"
                    tabIndex={2}
                    type="password"
                    name="currentPassword"
                    required
                    ref={register({ required: true })}
                  />
                )}
              </div>
              <hr />
              <div className="tw-p-6 tw-flex tw-justify-end">
                <Button
                  primary
                  type="submit"
                  className="tw-text-right tw-px-6"
                  onClick={handleSubmit}
                  isDisabled={changingPhone || smsMfaEnabled}
                >
                  {showPassword ? 'Confirm' : 'Save'}
                </Button>
              </div>
            </form>
          </FormProvider>
        </>
      )}
      <ReactTooltip />
    </Modal>
  );
};

export default UpdateMobilePhoneNumber;
