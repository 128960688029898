import React from 'react';

const StarOutline = ({ width = '14', height = '14', stroke = 'white' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.125 5.6875H8.42188L7 1.3125L5.57812 5.6875H0.875L4.70312 8.3125L3.22656 12.6875L7 9.95312L10.7734 12.6875L9.29688 8.3125L13.125 5.6875Z"
        stroke={stroke}
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarOutline;
