import React from 'react';
import ReactCountryFlag from 'react-country-flag';

const CountryCircle = (props) => {
  const { countryName, countryShortCode, ...otherProps } = props;

  return (
    <ReactCountryFlag
      svg
      countryCode={countryShortCode}
      aria-label={countryName}
      style={{
        height: '1.5em',
        width: '1.5em',
        borderRadius: '100%',
        border: '1px solid #e0e0e0',
      }}
      {...otherProps}
    />
  );
};

export default CountryCircle;
