import React from 'react';

import { formatMoneyV2 } from '../../utility/currency';

const CurrentBalance = ({ lineOfCredit }) => {
  const currentBalance = lineOfCredit && lineOfCredit.currentBalance;
  const currency = currentBalance && currentBalance.currency;

  return (
    <div className="tw-flex tw-flex-col tw-my-4 lg:tw-my-2 lg:tw-flex-row">
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-1 lg:tw-mb-0 lg:tw-justify-start lg:tw-w-44">
        <small className="tw-mr-1">Current Balance</small>
      </div>
      <div className="tw-flex tw-items-center tw-items-end">
        <strong>{formatMoneyV2(currentBalance, true)}</strong>
        <small className="tw-text-neutral-grey-2 tw-ml-2">{currency}</small>
      </div>
    </div>
  );
};

export default CurrentBalance;
