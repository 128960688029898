import React, { memo } from 'react';
import { Inquiry } from 'persona';
import cx from 'classnames';

import { usePersonaVerification } from './hooks';
import { Content, Loader } from './components';
import { PersonaVerificationProps } from './PersonaVerification.types';

import styles from './PersonaVerification.module.scss';

const PersonaVerification = ({
  inputDataError,
  isInputDataLoading,
  templateId,
  environmentId,
  referenceId,
  inquiryId,
  sessionToken,
  fields,
  onCompleted,
  onError,
}: PersonaVerificationProps) => {
  const { isLoaded, onLoad, errorMessage, validationMessage, handleError } = usePersonaVerification({
    inputDataError,
    isInputDataLoading,
    templateId,
    inquiryId,
    sessionToken,
    onError,
  });

  return (
    <div className={styles.personaWrapper}>
      <Content isLoading={!!isInputDataLoading} error={errorMessage} isValid={!validationMessage}>
        {!isLoaded && <Loader />}

        <div className={cx(!isLoaded && styles.visuallyHidden)}>
          <Inquiry
            templateId={templateId}
            environmentId={environmentId}
            referenceId={referenceId}
            inquiryId={inquiryId}
            sessionToken={sessionToken}
            fields={fields}
            onLoad={onLoad}
            onComplete={onCompleted}
            onError={handleError}
          />
        </div>
      </Content>
    </div>
  );
};

export default memo(PersonaVerification);
