import React, { useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { CREDIT_CARD_LIMITS } from 'constants/index';
import { SubmitButton } from 'components/FormFields/v2';
import { AuthContext } from 'context/Auth';
import { CreateCardContext } from './CreateCardContext';
import CreateCardSuccess from './CreateCardSuccess';
import ConfirmDeliveryAddressStep from './ConfirmDeliveryAddressStep';
import PhysicalCardClean from 'components/svg/PhysicalCardClean';
import useCreateCreditCards from 'hooks/useCreateCreditCards';
import Button from 'components/Button';
import useGetSubscription from 'hooks/useGetSubscription';

const ConfirmPhysicalCardPayment = ({ onClose, refetch, creditCards }) => {
  const { me } = useContext(AuthContext);
  const { maxPhysicalCards } = useGetSubscription();

  if (!me) return null;
  const chargePhysicalCardFee = me?.account?.physicalCardCount > (maxPhysicalCards || CREDIT_CARD_LIMITS.PHYSICAL);

  const [navigateBack, setNavigateBack] = useState(false);
  const { cardFormData } = useContext(CreateCardContext);
  const { isSubmitting, createCreditCard, hasSubmitted, error } = useCreateCreditCards();

  const form = useForm({});
  const { handleSubmit } = form;

  const handleSubmitForm = async () => {
    const { virtual, displayName, pin, businessNameOnCard, contactId, address } = cardFormData;
    await createCreditCard({
      virtual,
      nameOnCard: businessNameOnCard,
      displayName,
      address: address,
      pin,
      refetch,
      contactId,
    });
  };

  const onNavigateBack = () => {
    setNavigateBack(true);
  };

  if (hasSubmitted) return <CreateCardSuccess onClose={onClose} refetch={refetch} creditCards={creditCards} />;

  if (navigateBack) return <ConfirmDeliveryAddressStep onClose={onClose} refetch={refetch} creditCards={creditCards} />;

  return (
    <div className="tw-py-6">
      <div className="tw-flex tw-justify-between tw-px-10">
        <strong>Payment</strong>
      </div>
      <div className="tw-my-4 tw-mx-10 tw-py-1 tw-px-2 tw-flex tw-items-center tw-space-x-4 tw-bg-neutral-grey-4 tw-rounded-md">
        <AiOutlineInfoCircle size={26} />
        <span className="tw-text-xs">
          Your card will be shipped to the address provided. Please allow up to 7 business days for your Loop card to
          arrive.
        </span>
      </div>
      <div>
        <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="tw-px-10 tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between tw-py-1 tw-border-b tw-border-neutral-grey-4">
              <div className="tw-my-4 lg:tw-my-0 lg:tw-text-sm">
                <div className="tw-flex tw-justify-between">
                  Physical Card Fee
                  <span className="tw-font-bold lg:tw-ml-6">{chargePhysicalCardFee ? '$5.00' : 'Free'}</span>
                </div>
                <div className="tw-flex tw-justify-between">
                  Shipping
                  <span className="tw-font-bold lg:tw-ml-6">Free</span>
                </div>
              </div>
              <PhysicalCardClean className="tw-self-center tw-mb-6" />
            </div>
            <div className="tw-flex tw-flex-col tw-px-10 tw-pt-6 tw-py-1 tw-mb-40">
              <div className="tw-flex tw-justify-between">
                Total
                <strong className="tw-text-primary-dark-green">{chargePhysicalCardFee ? '$5.00' : 'Free'}</strong>
              </div>
              <span className="tw-py-1"> The Physical card fee will be charged to your Loop card.</span>
            </div>
            <div className="tw-flex tw-justify-between tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-pt-6">
              <Button
                className="tw-w-max tw-bg-neutral-grey-5 tw-border tw-border-neutral-grey-3 tw-text-neutral-dark tw-py-2 tw-px-4 tw-rounded-md tw-text-center"
                onClick={onNavigateBack}
              >
                Back to Address Details
              </Button>
              <SubmitButton
                className={`tw-w-max ${isSubmitting ? 'tw-bg-neutral-grey-3' : 'tw-bg-primary-dark-green'}`}
                disabled={isSubmitting}
              >
                <p className="tw-text-neutral-light">{isSubmitting ? 'Submitting...' : 'Order Card'}</p>
              </SubmitButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ConfirmPhysicalCardPayment;
