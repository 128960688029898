import React, { memo } from 'react';

import { useStatementBalanceCard } from './hooks';
import { BalanceCard } from 'components/creditCards/components/CardBalances/components';
import { StatementBalanceCardProps } from './StatementBalanceCard.types';

const StatementBalanceCard = ({
  currency,
  balance,
  prevStartDate,
  prevEndDate,
  prevBalance,
  debit,
  credit,
  amountDue,
  openAddFundsToBalanceModal,
  isCurrentCycle,
  className,
  isAdmin,
}: StatementBalanceCardProps) => {
  const {
    formattedBalance,
    formattedPrevBalance,
    formattedCreditAmount,
    formattedDebitAmount,
    formattedPrevCycle,
    formattedAmountDue,
    handleOpenAddFundsToBalanceModal,
  } = useStatementBalanceCard({
    currency,
    balance,
    prevStartDate,
    prevEndDate,
    prevBalance,
    debit,
    credit,
    amountDue,
    openAddFundsToBalanceModal,
    isAdmin,
  });

  return (
    <BalanceCard
      currency={currency}
      formattedBalance={formattedBalance}
      className={className}
      isAddFunds={isCurrentCycle && isAdmin}
      handleAddFunds={handleOpenAddFundsToBalanceModal}
    >
      <div className="tw-flex tw-items-start tw-justify-between tw-w-full tw-gap-1">
        <div className="tw-flex tw-flex-col">
          Last Statement Balance
          {formattedPrevCycle && <div>({formattedPrevCycle})</div>}
        </div>
        {formattedPrevBalance}
      </div>
      <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-gap-1">
        <div>New Charges</div>
        {formattedDebitAmount}
      </div>
      <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-gap-1">
        <div>Payments & Credits</div>
        <span className="tw-text-primary-dark-green">{formattedCreditAmount}</span>
      </div>
      {formattedAmountDue && (
        <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-gap-1 tw-pt-1.5 tw-border-t tw-border-neutral-grey-3">
          <div>Amount Due</div>
          {formattedAmountDue}
        </div>
      )}
    </BalanceCard>
  );
};

export default memo(StatementBalanceCard);
