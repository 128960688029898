import React from 'react';

type AdminSuspendedProps = {
  width?: number;
  height?: number;
};

const AdminSuspended = ({ width = 64, height = 64 }: AdminSuspendedProps) => (
  <svg width={width} height={height} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="#ED0004" />
    <g clipPath="url(#clip0_18325_58946)">
      <path
        d="M23.7516 23.7523L40.2483 40.249M20.3333 27.9438V36.0575C20.3333 36.3428 20.3333 36.4855 20.3655 36.6198C20.3941 36.7388 20.4412 36.8526 20.5052 36.957C20.5773 37.0747 20.6782 37.1756 20.88 37.3774L26.6232 43.1206C26.825 43.3224 26.9259 43.4232 27.0436 43.4954C27.148 43.5594 27.2618 43.6065 27.3808 43.6351C27.5151 43.6673 27.6578 43.6673 27.9431 43.6673H36.0567C36.3421 43.6673 36.4848 43.6673 36.619 43.6351C36.7381 43.6065 36.8519 43.5594 36.9562 43.4954C37.074 43.4232 37.1749 43.3224 37.3767 43.1206L43.1198 37.3774C43.3216 37.1756 43.4225 37.0747 43.4947 36.957C43.5586 36.8526 43.6058 36.7388 43.6343 36.6198C43.6666 36.4855 43.6666 36.3428 43.6666 36.0575V27.9438C43.6666 27.6585 43.6666 27.5158 43.6343 27.3815C43.6058 27.2625 43.5586 27.1487 43.4947 27.0443C43.4225 26.9266 43.3216 26.8257 43.1198 26.6239L37.3767 20.8807C37.1749 20.6789 37.074 20.5781 36.9562 20.5059C36.8519 20.4419 36.7381 20.3948 36.619 20.3662C36.4848 20.334 36.3421 20.334 36.0567 20.334H27.9431C27.6578 20.334 27.5151 20.334 27.3808 20.3662C27.2618 20.3948 27.148 20.4419 27.0436 20.5059C26.9259 20.5781 26.825 20.6789 26.6232 20.8807L20.88 26.6239C20.6782 26.8257 20.5773 26.9266 20.5052 27.0443C20.4412 27.1487 20.3941 27.2625 20.3655 27.3815C20.3333 27.5158 20.3333 27.6585 20.3333 27.9438Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_18325_58946">
        <rect width="28" height="28" fill="white" transform="translate(18 18)" />
      </clipPath>
    </defs>
  </svg>
);

export default AdminSuspended;
