import React, { useContext, useState } from 'react';
import { Tab } from '@headlessui/react';
import { useQuery } from '@apollo/client';

import { Loaders } from 'components/cards/Loader';
import SettingsLayout from 'containers/settings/SettingsLayout';
import { QuickBooksMapping } from 'components/settings/AccountingServices/Mapping';
import { QuickBooksNewLiabilityModal } from 'components/settings/AccountingServices/NewLiabilityModal';
import { QuickBooksNewExpenseModal } from 'components/settings/AccountingServices/NewExpenseModal';
import { QuickBooksLiabilityAccounts } from 'components/settings/AccountingServices/LiabilityAccounts';
import { MultiCurrencyDisabled } from 'components/settings/AccountingServices/MultiCurrencyDisabled';
import AssetAccountSettings from 'components/AssetAccountSettings';
import WalletCategorySettings from 'components/WalletCategorySettings';
import { MappingTable } from 'components/settings/AccountingServices/components/MerchantVendorMapping';

import { AuthContext } from 'context/Auth'; // TODO: we have to wrap the route with the provider
import { SettingsContext } from 'context/Settings';
import { NewAccountModalContextProvider } from 'context/AccountingServices';
import { AddPaymentRequestContextProvider } from 'components/AssetAccountSettings/contexts/AssetAccountMappingRequestContext';
import { AddWalletCategoryMappingRequestContextProvider } from 'components/WalletCategorySettings/contexts/WalletCategoryMappingRequestContext';
import { MerchantVendorMappingContextProvider } from 'components/settings/AccountingServices/contexts/MerchantVendorMappingContext';
import { useHistory, Link } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import NewCategoryModal from 'components/settings/AccountingServices/NewCategoryModal';
import Button from 'components/Button';
import { GET_QBO_PREFERENCES_CURRENCY } from 'graphql/accountingServices';
import { validAccountingIntegrations } from 'components/settings/Integrations/constants';
import Banner, { BANNER_TYPES } from 'components/Banner';
import { BankFeeds } from './XeroIntegration';
import useFeatureToggle from 'hooks/useFeatureToggle';

const AccountingIntegrationServiceSettings = () => {
  const history = useHistory();
  const { meLoading } = useContext(AuthContext);
  const { externalAccounts } = useContext(SettingsContext);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const { isExperimentEnabled: isXeroBankFeedEnabled } = useFeatureToggle(false, 'xero_bank_feed');

  const tabClassNameOne = ({ selected }) =>
    `tw-bg-neutral-grey-5 tw-py-2 tw-px-6 tw-border-2 tw-rounded-md tw-inline-flex tw-items-center tw-mr-4 tw-gap-x-2 ${
      selected
        ? 'tw-border-primary-light-green tw-bg-primary-light-green tw-text-primary-dark-green hover:tw-text-primary-dark-green'
        : 'tw-border-neutral-grey-4'
    }`;

  const tabClassNameTwo = ({ selected }) =>
    `tw-items-center tw-flex tw-border tw-rounded-md hover:tw-text-primary-dark-green hover:tw-border-secondary-pastel-green-80 hover:tw-bg-secondary-light-green tw-p-2 ${
      selected
        ? 'tw-bg-secondary-light-green tw-border-secondary-pastel-green-80 tw-text-primary-dark-green'
        : 'tw-border-neutral-grey-4 tw-neutral-grey-1'
    }`;

  // TODO: move into a hook. But for now, we dont have a scaffolding for qbo hooks
  const { data: preferencesData, loading } = useQuery(GET_QBO_PREFERENCES_CURRENCY);
  const { accountingIntegrationPreferencesCurrency } = preferencesData || {
    accountingIntegrationPreferencesCurrency: { homeCurrency: '', multiCurrencyEnabled: false },
  };
  const { homeCurrency, multiCurrencyEnabled } = accountingIntegrationPreferencesCurrency;

  const connectedAccountingService = externalAccounts.filter((ea) =>
    validAccountingIntegrations().includes(ea.name)
  )[0];
  if (!connectedAccountingService) history.push('/dashboard/settings/accounting-integrations');

  return (
    <SettingsLayout>
      {meLoading ? (
        <Loaders.Light />
      ) : (
        <NewAccountModalContextProvider>
          <div className="tw-p-4">
            {!loading && !multiCurrencyEnabled && (
              <Banner
                className="tw-mb-4"
                type={BANNER_TYPES.error}
                isShowIcon
                message={`Your ${connectedAccountingService?.name} account doesn't have Multi Currency enabled.`}
              />
            )}

            <Link
              to="/dashboard/settings/accounting-integrations"
              className="tw-flex tw-items-center tw-space-x-3 hover:tw-text-neutral-dark"
            >
              <FaChevronLeft />
              <span>Back to Accounting Integrations</span>
            </Link>
            {loading && <Loaders.Light />}
            {!loading && !multiCurrencyEnabled && (
              <MultiCurrencyDisabled accountName={connectedAccountingService?.name} homeCurrency={homeCurrency} />
            )}
            {!loading && multiCurrencyEnabled && (
              <>
                <h5 className="tw-pt-6">Configure Loop to {connectedAccountingService?.name} Integration</h5>
                <small className="tw-text-neutral-grey-2">
                  Once you have configured the integration settings, you can push your transaction data from Loop
                  directly to {connectedAccountingService?.name}!
                </small>
                <small className="tw-text-semantic-warning tw-block">
                  Please make sure you complete both the Card and Account related integrations before pushing your
                  transactions.
                </small>
                <Tab.Group>
                  <Tab.List className="tw-flex tw-mt-4">
                    <Tab className={tabClassNameOne} key="card">
                      Card Transactions Mapping
                    </Tab>
                    <Tab className={tabClassNameOne} key="account">
                      Account Transactions Mapping
                    </Tab>
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel className="tw-bg-neutral-grey-5 tw-rounded-md tw-p-4 tw-mt-4">
                      <Tab.Group>
                        <Tab.List className="tw-flex tw-space-x-2 tw-mt-2">
                          <Tab className={tabClassNameTwo} key="merchant">
                            Merchant Vendor Mapping
                          </Tab>
                          <Tab className={tabClassNameTwo} key="expense">
                            Expense Account Mapping
                          </Tab>
                          <Tab className={tabClassNameTwo} key="liability">
                            Liability Account Mapping
                          </Tab>
                          {isXeroBankFeedEnabled && connectedAccountingService.name === 'Xero' && (
                            <Tab className={tabClassNameTwo} key="liability">
                              Bank Feeds
                            </Tab>
                          )}
                        </Tab.List>
                        <Tab.Panels className="tw-mt-4">
                          <Tab.Panel className="tw-bg-neutral-grey-5 tw-p-4">
                            <MerchantVendorMappingContextProvider>
                              <MappingTable accountName={connectedAccountingService?.name} />
                            </MerchantVendorMappingContextProvider>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-1">
                              For each Loop expense category, select the {connectedAccountingService?.name} expense
                              account that you want to push transactions to. You can also create new Loop expense
                              categories or {connectedAccountingService?.name} expense accounts below if you {''}
                              haven’t set them up yet.
                            </div>
                            <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-2 tw-mb-3">
                              Changing the mapping below will only affect transactions pushed to {''}
                              {connectedAccountingService?.name} in the future, it will not override any data that has
                              already been synced to {connectedAccountingService?.name}.
                            </div>
                            <QuickBooksMapping accountName={connectedAccountingService?.name} />
                            <QuickBooksNewExpenseModal accountName={connectedAccountingService?.name} />

                            <Button primary onClick={() => setShowCategoryModal(true)}>
                              Create Loop Category{' '}
                            </Button>
                            <NewCategoryModal show={showCategoryModal} onClose={() => setShowCategoryModal(false)} />
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-1">
                              For each Loop Card currency, select the {connectedAccountingService?.name} liability
                              account that you want to push transactions to. If you don’t have an existing account for a
                              given currency,
                              {connectedAccountingService?.name === 'Xero'
                                ? ' click the Create Xero Account button, and reload the app once you are done creating it on Xero.'
                                : ' Loop can create one for you below.'}
                            </div>
                            <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-2 tw-mb-3">
                              Changing the mapping below will only affect transactions pushed to{' '}
                              {connectedAccountingService?.name} in the future, it will not override any data that has
                              already been synced to {connectedAccountingService?.name}.
                            </div>
                            <QuickBooksLiabilityAccounts accountName={connectedAccountingService?.name} />
                            <QuickBooksNewLiabilityModal accountName={connectedAccountingService?.name} />
                          </Tab.Panel>
                          {isXeroBankFeedEnabled && connectedAccountingService.name === 'Xero' && (
                            <Tab.Panel>
                              <BankFeeds />
                            </Tab.Panel>
                          )}
                        </Tab.Panels>
                      </Tab.Group>
                    </Tab.Panel>
                    <Tab.Panel className="tw-bg-neutral-grey-5 tw-rounded-md tw-p-4 tw-mt-4">
                      <Tab.Group>
                        <Tab.List className="tw-flex tw-space-x-2 tw-mt-2">
                          <Tab className={tabClassNameTwo} key="asset_revenue">
                            Asset/Revenue Account Mapping
                          </Tab>
                          <Tab className={tabClassNameTwo} key="account_category">
                            Account Transactions Category Mapping
                          </Tab>
                        </Tab.List>
                        <Tab.Panels className="tw-mt-4">
                          <Tab.Panel>
                            <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-1">
                              For each Loop (Bank) Account, select the {connectedAccountingService?.name} asset/revenue
                              account that you want to push transactions to. If you don’t have an existing account for a
                              given (Bank) account, click the Create
                              {connectedAccountingService?.name} Account button, and reload the app once you are done
                              creating it on {connectedAccountingService?.name}.
                            </div>
                            <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-2 tw-mb-3">
                              Changing the mapping below will only affect transactions pushed to{' '}
                              {connectedAccountingService?.name} in the future, it will not override any data that has
                              already been synced to {connectedAccountingService?.name}.
                            </div>
                            <AddPaymentRequestContextProvider>
                              <AssetAccountSettings accountName={connectedAccountingService?.name} />
                            </AddPaymentRequestContextProvider>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-1">
                              For each Loop Account Transaction Category, select the {connectedAccountingService?.name}{' '}
                              account that you want to push transactions to. If you don’t have an existing account for a
                              given category, click the Create {connectedAccountingService?.name} Account button, and
                              reload the app once you are done creating it on {connectedAccountingService?.name}.
                            </div>
                            <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-2 tw-mb-3">
                              Changing the mapping below will only affect transactions pushed to{' '}
                              {connectedAccountingService?.name} in the future, it will not override any data that has
                              already been synced to {connectedAccountingService?.name}.
                            </div>
                            <AddWalletCategoryMappingRequestContextProvider>
                              <WalletCategorySettings accountName={connectedAccountingService?.name} />
                            </AddWalletCategoryMappingRequestContextProvider>
                          </Tab.Panel>
                        </Tab.Panels>
                      </Tab.Group>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </>
            )}
          </div>
        </NewAccountModalContextProvider>
      )}
    </SettingsLayout>
  );
};

export default AccountingIntegrationServiceSettings;
