import { useState, useCallback } from 'react';

import { useToggle } from 'hooks';
import { USBankAccountOwnershipDisclosureModalProps } from '../USBankAccountOwnershipDisclosureModal.types';

const useUSBankAccountOwnershipDisclosureModal = ({
  onFinish,
}: Pick<USBankAccountOwnershipDisclosureModalProps, 'onFinish'>) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [errorSteps, setErrorSteps] = useState<number[]>([]);
  const { isOpen: isCompleted, open: onComplete } = useToggle();

  const onNextStep = useCallback(() => {
    setCurrentStep((prevStep) => prevStep + 1);
    onComplete();
    onFinish?.();
  }, [onFinish, onComplete]);

  return {
    currentStep,
    onNextStep,
    errorSteps,
    setErrorSteps,
    isCompleted,
  };
};

export default useUSBankAccountOwnershipDisclosureModal;
