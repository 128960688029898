import { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { AddPayorPayloadType } from 'components/Invoices/components/Payors/Payors.types';
import { AddPayorContext } from 'components/Invoices/contexts/AddPayorContext';

const usePayorDetails = ({ onNextStep }: { onNextStep: () => void }) => {
  const { payorInfo, setPayorInfo, error } = useContext(AddPayorContext);

  const form = useForm({
    defaultValues: { ...payorInfo },
  });

  const handleOnNextStep = (data: AddPayorPayloadType) => {
    setPayorInfo({ ...data });
    onNextStep();
  };

  const { handleSubmit, register, watch } = form;
  const watchEmail = watch('email');

  return {
    form,
    register,
    handleOnNextStep,
    handleSubmit,
    watchEmail,
    error,
  };
};

export default usePayorDetails;
