import React from 'react';
import { useToggle } from 'hooks';
import { IoGiftOutline, IoCashOutline, IoListOutline, IoRibbonOutline } from 'react-icons/io5';

const useRewardsModal = () => {
  const { isOpen: showRewards, open: onShowRewards, close: onCloseRewards } = useToggle();
  const { isOpen: showHistory, open: onShowHistory, close: onCloseHistory } = useToggle();
  const { isOpen: showOffers, open: onShowOffers, close: onCloseOffers } = useToggle();
  const { isOpen: showReferAndEarn, open: onShowReferAndEarn, close: onCloseReferAndEarn } = useToggle();

  const rewardsModalButtons = [
    {
      title: 'Rewards',
      icon: <IoGiftOutline size={20} className="tw-text-primary-dark-green" />,
      onClick: onShowRewards,
    },
    {
      title: 'History',
      icon: <IoListOutline size={20} className="tw-text-primary-dark-green" />,
      onClick: onShowHistory,
    },
    {
      title: 'Offers',
      icon: <IoCashOutline size={20} className="tw-text-primary-dark-green" />,
      onClick: onShowOffers,
    },
    {
      title: 'Refer & Earn',
      icon: <IoRibbonOutline size={20} className="tw-text-primary-dark-green" />,
      onClick: onShowReferAndEarn,
    },
  ];

  return {
    showRewards,
    onCloseRewards,
    showHistory,
    onCloseHistory,
    showOffers,
    onCloseOffers,
    showReferAndEarn,
    onCloseReferAndEarn,
    rewardsModalButtons,
  };
};

export default useRewardsModal;
