import React, { useContext } from 'react';

import Modal from 'components/Modal/v2/index';
import Close from 'components/svg/Close';
import { CreateCardContext } from './CreateCardContext';
import { CreditCardContextProvider } from 'context/CreditCard';
import CreateCardFlow from '.';
import { Loaders } from 'components/cards/Loader';

const CreateCardModal = ({ show, onClose, creditCards, globalCreditLimit, isLoadingCardsData }) => {
  const { setInitialTypeOfCard, isModalFetchDataOnUnmount, isFetchingCardData, setIsFetchingCardData } =
    useContext(CreateCardContext);

  const handleClose = async () => {
    if (isModalFetchDataOnUnmount && !isFetchingCardData) {
      setIsFetchingCardData(true);
      setIsFetchingCardData(false);
    }

    setInitialTypeOfCard('virtual');
    onClose();
  };

  const loading = isFetchingCardData || isLoadingCardsData;

  return (
    <Modal show={show} onClose={handleClose}>
      <CreditCardContextProvider>
        {loading ? (
          <div className="tw-flex tw-justify-center">
            <Loaders.Small />
          </div>
        ) : (
          <div className="tw-pt-6">
            <div className="tw-px-8 tw-flex tw-justify-between tw-pb-4 tw-border-b tw-border-neutral-grey-4">
              <p>Create Card</p>
              <Close className="tw-cursor-pointer" onClick={handleClose} />
            </div>
            <CreateCardFlow onFinish={handleClose} creditCards={creditCards} globalCreditLimit={globalCreditLimit} />
          </div>
        )}
      </CreditCardContextProvider>
    </Modal>
  );
};

export default CreateCardModal;
