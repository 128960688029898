import React from 'react';

import { CardExpenseTableProps } from './CardExpenseTable.types';
import { useCardExpenseTable } from './hooks';
import { CardExpenseRow, CardExpenseDeclineModal } from './components';
import ApprovalRequestDetailOverlay from '../ApprovalRequestDetailOverlay';

const CardExpenseTable = ({ cardExpenseRequestList }: CardExpenseTableProps) => {
  const {
    noApprovalsAvailable,
    selectedRequests,
    toggleSelectedExpense,
    isAdmin,
    handleToggleSelectAll,
    isAllSelected,
  } = useCardExpenseTable({
    cardExpenseRequestList,
  });

  if (noApprovalsAvailable) {
    return (
      <div className="tw-border-t tw-border-neutral-grey-3 tw-pt-4">
        You have no card expenses available at this moment.
      </div>
    );
  }

  return (
    <>
      <table className="tw-table tw-table-auto tw-w-full">
        <thead>
          <tr>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Approval Type</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Cardholder</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">From</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">To</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Amount</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Currency</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Receipt</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Request Date</th>
            {!isAdmin && (
              <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Status</th>
            )}
            {isAdmin && (
              <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Action</th>
            )}
            <th>{isAdmin && <input type="checkbox" checked={isAllSelected} onChange={handleToggleSelectAll} />}</th>
          </tr>
        </thead>
        <tbody>
          {cardExpenseRequestList.map((cardExpense) => (
            <CardExpenseRow
              key={cardExpense.transactionId}
              cardExpense={cardExpense}
              selectedExpenses={selectedRequests}
              toggleSelectedExpense={toggleSelectedExpense}
            />
          ))}
        </tbody>
      </table>
      <ApprovalRequestDetailOverlay requestList={cardExpenseRequestList} />
      {isAdmin && <CardExpenseDeclineModal />}
    </>
  );
};
export default CardExpenseTable;
