import React, { createContext, useState } from 'react';
import { useQuery, ApolloQueryResult } from '@apollo/client';
import { get } from 'lodash';

import { AccountWallet } from 'types/wallet';
import { BankAccount } from 'types/bankAccount';
import {
  GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_FOR_ACCOUNTS_PAGE,
  GET_CAD_ACCOUNT_FEATURE_STATUS,
} from 'graphql/wallets';
import LineOfCredit from 'domain/lineOfCredit';
import { GET_PAYEES } from 'graphql/payees';

type AccountsContextType = {
  wallets: AccountWallet[];
  bankAccounts: BankAccount[];
  lineOfCreditAccount: LineOfCredit | null;
  loading: boolean;
  selectedWalletId: string;
  setSelectedWalletId: React.Dispatch<React.SetStateAction<string>>;
  loadingPayees: boolean;
  payees: {}[];
  refetchAccountsPageData: () => Promise<
    ApolloQueryResult<{
      wallets: AccountWallet[];
      bankAccounts: BankAccount[];
    }>
  >;
  cadAccountFeatureDisabled: boolean;
};

export const AccountsContext = createContext<AccountsContextType>({} as AccountsContextType);

export const AccountsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    data,
    loading,
    refetch: refetchWalletsAccountsAndCredit,
  } = useQuery<{ wallets: AccountWallet[]; bankAccounts: BankAccount[] }>(
    GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_FOR_ACCOUNTS_PAGE
  );
  const { data: payeesData, loading: loadingPayees } = useQuery(GET_PAYEES, { fetchPolicy: 'network-only' });
  const { data: cadAccountFeatureStatusData, loading: cadAccountFeatureStatusLoading } =
    useQuery(GET_CAD_ACCOUNT_FEATURE_STATUS);

  const wallets: AccountWallet[] = get(data, 'me.account.wallets', []);
  const [selectedWalletId, setSelectedWalletId] = useState('');
  const bankAccounts: BankAccount[] = get(data, 'me.account.bankAccounts', []);
  const lineOfCredit = get(data, 'me.account.lineOfCredit', null);
  const lineOfCreditAccount: LineOfCredit | null = lineOfCredit && new LineOfCredit(lineOfCredit);
  const payees = get(payeesData, 'payees') || [];
  const cadAccountFeatureDisabled = get(cadAccountFeatureStatusData, 'me.account.cadAccountFeatureDisabled', false);

  return (
    <AccountsContext.Provider
      value={{
        wallets,
        bankAccounts,
        lineOfCreditAccount,
        loading: loading || cadAccountFeatureStatusLoading,
        selectedWalletId,
        setSelectedWalletId,
        loadingPayees,
        payees,
        refetchAccountsPageData: refetchWalletsAccountsAndCredit,
        cadAccountFeatureDisabled,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};
