import ReactPixel from 'react-facebook-pixel';

import config from './config';

const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init(config.facebookPixelId, {}, options);
