import React from 'react';
import { FormProvider } from 'react-hook-form';
import { BsArrowRight } from 'react-icons/bs';
import { CgArrowsExchangeAltV } from 'react-icons/cg';

import { EXTERNAL_TRANSACTION_CLEARING_MESSAGE, INR_REASONS } from 'constants/index';
import { formatMoneyV2 } from 'utility/currency';
import { Select, SubmitButton, TextField, RadioField, DatePicker } from 'components/FormFields/v2';
import { Loaders } from 'components/cards/Loader';
import FileUpload from 'components/uploader/FileUpload';
import { InfoTooltipForModals } from 'components/InfoTooltip';
import { useDetails } from './hooks';
import { AmountInput } from 'components/Accounts/AccountsContent/components/AccountsModal/components';
import { PAY_PAYEE_SOURCE } from 'components/payments/PayPayee/constants';
import { ReimbursementRequestInfo } from 'components/payments/PayPayee/components/Details/components';
import styles from './Details.module.scss';
import { MaintenanceBanner } from 'components/UI';
import { ALPHANUMERIC_REGEX } from 'constants/regex';
import config from 'config';

const Details = ({
  payees,
  wallets,
  bankAccounts = [],
  lineOfCredit,
  onNextStep,
  initialPayeeId,
  initialFromAccountId,
  source,
}) => {
  const {
    form,
    register,
    handleSubmit,
    error,
    fromAccountCurrency,
    formattedFromAmount,
    setFormattedFromAmount,
    toAccountCurrency,
    formattedToAmount,
    setFormattedToAmount,
    isCreditFunds,
    createMemberPayment,
    needsConversion,
    toOptions,
    fromOptions,
    showSentEmailConfirmation,
    handleOnFileUpload,
    onSubmit,
    invoiceFile,
    isExternalAccount,
    showNotes,
    formattedMaxSellAmount,
    formattedMaxBuyAmount,
    buyOrSellOptions,
    isSelling,
    isBuying,
    rate,
    loadingRate,
    reimbursementInfo,
  } = useDetails({
    payees,
    wallets,
    bankAccounts,
    lineOfCredit,
    onNextStep,
    initialPayeeId,
    initialFromAccountId,
  });

  return (
    <div className="tw-mt-8">
      {source === PAY_PAYEE_SOURCE.reimbursements && <ReimbursementRequestInfo request={reimbursementInfo} />}
      <div className="tw-px-8">
        {createMemberPayment ? (
          <div className="tw-text-sm tw-text-neutral-grey-2">
            This will create a payment pending approval from an admin. Payment will be initiated once approved. If the
            accounts don't have matching currencies, you will be prompted to perform a currency conversion. The rates
            provided are estimates and will only lock once approved.
          </div>
        ) : (
          <div className="tw-text-sm tw-text-neutral-grey-2">
            Transfer money to a payee. If the accounts don't have matching currencies, you will be prompted to perform a
            currency conversion.
          </div>
        )}
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-p-8">
            <Select
              options={toOptions}
              name="toAccount"
              label="Payee"
              placeholder="Choose"
              ref={register({ required: true })}
              rootClass="tw-mb-4"
              required
              data-testid="to-account-select"
            />
            <Select
              options={fromOptions}
              name="fromAccount"
              label="From Account"
              placeholder="Choose"
              ref={register({ required: true })}
              rootClass="tw-mb-4"
              required
              data-testid="from-account-select"
            />

            {isExternalAccount && (
              <div className="tw-rounded-md tw-bg-secondary-pastel-yellow-80 tw-p-4 tw-mb-4 tw-flex tw-justify-center tw-items-center">
                <span className="tw-text-primary-dark-yellow tw-text-xs">{EXTERNAL_TRANSACTION_CLEARING_MESSAGE}</span>
              </div>
            )}
            {toAccountCurrency === 'INR' && (
              <Select
                options={INR_REASONS}
                name="reason"
                label="Payment Reason"
                placeholder="Choose"
                ref={register({ required: true })}
                rootClass="tw-mb-4"
                required
              />
            )}
            {toAccountCurrency !== 'INR' && (
              <div>
                <label htmlFor="reason" className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
                  Payment Ref #<span className="tw-text-semantic-error">&nbsp;*</span>
                  <InfoTooltipForModals
                    message="Included in the payment confirmation sent to your Payee’s financial institution"
                    onlyIcon
                  />
                </label>
                <TextField
                  ref={register({ required: true, maxLength: { value: 18, message: 'Maximum 18 characters' } })}
                  name="reason"
                  required
                  placeholder="Purchase Order #10056"
                  maxLength="18"
                  rootClass="tw-flex-grow tw-mb-4"
                  data-testid="reason"
                />
              </div>
            )}

            {isCreditFunds && (
              <div className="tw-flex tw-flex-col tw-mb-4">
                <label htmlFor="invoiceBlobSignedId" className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
                  Add Invoice File
                  <span className="tw-text-semantic-error">&nbsp;*</span>
                </label>
                <div className={styles.uploadFile}>
                  <FileUpload onUpload={handleOnFileUpload} defaultFiles={invoiceFile ? [invoiceFile] : []} />
                  <TextField type="text" name="invoiceBlobSignedId" ref={register({ required: true })} hidden />
                </div>
              </div>
            )}

            {needsConversion && (
              <>
                <div className="tw-my-4" data-testid="buy-or-sell">
                  <div className="tw-text-sm tw-text-neutral-grey-1">Enter the amount</div>
                  <RadioField
                    rootClass="tw-mt-2"
                    name="buyOrSell"
                    options={buyOrSellOptions}
                    ref={register({ required: true })}
                  />
                </div>
                <MaintenanceBanner maintenanceMessageSource={config.currencyCloudApiDown} />
              </>
            )}

            <div className="tw-mb-4">
              {(isSelling || (!needsConversion && fromAccountCurrency)) && (
                <>
                  <div className="tw-my-4">
                    <AmountInput
                      name="fromAmount"
                      label={needsConversion ? 'Amount to Send' : 'Amount Your Payee Will Receive'}
                      currency={fromAccountCurrency}
                      formattedAmount={formattedFromAmount}
                      formatMoneyV2={formatMoneyV2}
                      setFormattedAmount={setFormattedFromAmount}
                      register={register}
                      dataTestId="amount-input"
                    />
                  </div>

                  {needsConversion && (
                    <>
                      {!!formattedMaxSellAmount && (
                        <div className="tw-text-sm tw-my-4">
                          You currently have <b>{formattedMaxSellAmount}</b> available to sell.
                        </div>
                      )}
                      <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4">
                        <div>
                          <div className="tw-flex tw-justify-between tw-items-center">
                            <div className="tw-text-sm tw-font-semibold">Your Exchange Rate</div>
                            <div className="tw-text-sm tw-flex tw-items-center">
                              {loadingRate ? <Loaders.Spinner /> : rate}
                              <CgArrowsExchangeAltV size={20} className="tw-ml-2" />
                            </div>
                          </div>
                          <div className="tw-text-sm tw-font-semibold tw-flex tw-items-center tw-mt-1 tw-mb-4">
                            {fromAccountCurrency} <BsArrowRight className="tw-mx-2" /> {toAccountCurrency}
                          </div>
                        </div>
                        <div className="tw-flex tw-justify-between tw-items-center">
                          <div className="tw-text-sm tw-font-semibold">Amount Your Payee Will Receive</div>
                          <div className="tw-text-sm">
                            {loadingRate ? (
                              <Loaders.Spinner />
                            ) : (
                              <>
                                {rate ? formattedToAmount : ''} {toAccountCurrency}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {isBuying && (
                <>
                  <div className="tw-my-4">
                    <AmountInput
                      name="toAmount"
                      label="Amount Your Payee Will Receive"
                      currency={toAccountCurrency}
                      formattedAmount={formattedToAmount}
                      formatMoneyV2={formatMoneyV2}
                      setFormattedAmount={setFormattedToAmount}
                      register={register}
                    />
                    {!!formattedMaxBuyAmount && (
                      <div className="tw-text-sm tw-my-4">
                        Your {fromAccountCurrency} balance will enable you to buy up to <b>{formattedMaxBuyAmount}</b>.
                      </div>
                    )}
                  </div>
                  {needsConversion && (
                    <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4">
                      <div>
                        <div className="tw-flex tw-justify-between tw-items-center">
                          <div className="tw-text-sm tw-font-semibold">Your Exchange Rate</div>
                          <div className="tw-text-sm tw-flex tw-items-center">
                            {loadingRate ? <Loaders.Spinner /> : rate}
                            <CgArrowsExchangeAltV size={20} className="tw-ml-2" />
                          </div>
                        </div>
                        <div className="tw-text-sm tw-font-semibold tw-flex tw-items-center tw-mt-1 tw-mb-4">
                          {fromAccountCurrency} <BsArrowRight className="tw-mx-2" /> {toAccountCurrency}
                        </div>
                      </div>
                      <div className="tw-flex tw-justify-between tw-items-center">
                        <div className="tw-text-sm tw-font-semibold">Amount You Will Convert</div>
                        <div className="tw-text-sm">
                          {loadingRate ? (
                            <Loaders.Spinner />
                          ) : (
                            <>
                              {rate ? formattedFromAmount : ''} {fromAccountCurrency}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {toAccountCurrency === 'INR' && (
              <div>
                <label htmlFor="invoice_number" className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
                  Invoice Number
                  <span className="tw-text-semantic-error">&nbsp;*</span>
                  <InfoTooltipForModals
                    message="Please enter the invoice code below. Only letters and numbers are accepted."
                    onlyIcon
                  />
                </label>
                <TextField
                  ref={register({
                    required: true,
                    pattern: {
                      value: ALPHANUMERIC_REGEX,
                      message: 'Only letters and numbers are accepted, no spaces.',
                    },
                    maxLength: { value: 30, message: 'Maximum 30 characters' },
                  })}
                  name="invoiceNumber"
                  placeholder="IN1001"
                  rootClass="tw-flex-grow tw-mb-4"
                />
                <DatePicker
                  name="invoiceDate"
                  rootClass="tw-mb-8"
                  label="Date of Invoice"
                  required
                  ref={register({ required: true })}
                />
              </div>
            )}

            {showSentEmailConfirmation && (
              <div className="tw-my-4">
                <label htmlFor="sendConfirmationEmail" className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
                  Send Email Confirmation to Payee:
                  <span className="tw-text-semantic-error"> *</span>
                </label>
                <RadioField
                  rootClass="tw-mt-2"
                  name="sendConfirmationEmail"
                  options={[
                    { label: 'Yes', value: 'true', defaultChecked: true },
                    { label: 'No', value: 'false' },
                  ]}
                  ref={register()}
                />
              </div>
            )}

            {showNotes && (
              <div>
                <label htmlFor="notes" className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
                  Notes
                  <InfoTooltipForModals
                    message="Included in the email confirmation sent by Loop to your Payee"
                    onlyIcon
                  />
                </label>
                <TextField
                  ref={register()}
                  name="notes"
                  placeholder="Detailed notes for reference"
                  rootClass="tw-flex-grow tw-mb-4"
                />
              </div>
            )}
          </div>
          <div className="tw-px-6 tw-mb-4 tw-text-semantic-error">
            <span data-testid="error-msg">{error}</span>
          </div>
          <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex lg:tw-justify-end">
            <SubmitButton className="tw-w-full lg:tw-w-max" disabled={!!error || loadingRate}>
              Review
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Details;
