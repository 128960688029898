export enum FlinksEvents {
  APP_MOUNTED = 'APP_MOUNTED',
  APP_ONLINE = 'APP_ONLINE',
  APP_OFFLINE = 'APP_OFFLINE',
  ACCOUNT_SELECTED = 'ACCOUNT_SELECTED',
  COMPONENT_ACCEPT_CONSENT = 'COMPONENT_ACCEPT_CONSENT',
  COMPONENT_ACCEPT_TERMS = 'COMPONENT_ACCEPT_TERMS',
  COMPONENT_CLICK_RESET_PASSWORD = 'COMPONENT_CLICK_RESET_PASSWORD',
  COMPONENT_CLICK_TERMS_URL = 'COMPONENT_CLICK_TERMS_URL',
  COMPONENT_CLOSE_SESSION = 'COMPONENT_CLOSE_SESSION',
  COMPONENT_DENY_CONSENT = 'COMPONENT_DENY_CONSENT',
  COMPONENT_DENY_TERMS = 'COMPONENT_DENY_TERMS',
  COMPONENT_HELP2FA_INFO = 'COMPONENT_HELP2FA_INFO',
  COMPONENT_HELP2FA_TROUBLESHOOTING = 'COMPONENT_HELP2FA_TROUBLESHOOTING',
  COMPONENT_LOAD_ACCOUNT_SELECTION = 'COMPONENT_LOAD_ACCOUNT_SELECTION',
  COMPONENT_LOAD_CONSENT = 'COMPONENT_LOAD_CONSENT',
  COMPONENT_LOAD_CREDENTIAL = 'COMPONENT_LOAD_CREDENTIAL',
  COMPONENT_LOAD_CREDENTIAL_RETRY = 'COMPONENT_LOAD_CREDENTIAL_RETRY',
  COMPONENT_LOAD_INSTITUTION_SELECTOR = 'COMPONENT_LOAD_INSTITUTION_SELECTOR',
  COMPONENT_LOAD_MFA = 'COMPONENT_LOAD_MFA',
  COMPONENT_LOAD_MFA_RETRY = 'COMPONENT_LOAD_MFA_RETRY',
  COMPONENT_PROVIDER_CONSENT_ABOUT = 'COMPONENT_PROVIDER_CONSENT_ABOUT',
  COMPONENT_PROVIDER_CONSENT_INFO = 'COMPONENT_PROVIDER_CONSENT_INFO',
  COMPONENT_PROVIDER_CONSENT_LEGAL = 'COMPONENT_PROVIDER_CONSENT_LEGAL',
  DISABLED_INSTITUTION = 'DISABLED_INSTITUTION',
  ENHANCED_MFA_HAS_QUESTIONS = 'ENHANCED_MFA_HAS_QUESTIONS',
  ENHANCED_MFA_NO_QUESTIONS = 'ENHANCED_MFA_NO_QUESTIONS',
  ENHANCED_MFA_SUCCESS = 'ENHANCED_MFA_SUCCESS',
  INSTITUTION_SELECTED = 'INSTITUTION_SELECTED',
  INVALID_INSTITUTION = 'INVALID_INSTITUTION',
  INSTITUTION_NOT_AVAILABLE = 'INSTITUTION_NOT_AVAILABLE',
  INVALID_SECURITY_RESPONSE = 'INVALID_SECURITY_RESPONSE',
  MAXIMUM_RETRY_REACHED = 'MAXIMUM_RETRY_REACHED',
  QUESTION_NOT_FOUND = 'QUESTION_NOT_FOUND',
  REDIRECT = 'REDIRECT',
  RETRY_COUNT = 'RETRY_COUNT',
  SESSION_NONEXISTENT = 'SESSION_NONEXISTENT',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  SESSION_STORAGE_BLOCKED = 'SESSION_STORAGE_BLOCKED',
  SESSION_STORAGE_BLOCKED_RETRY = 'SESSION_STORAGE_BLOCKED_RETRY',
  SKIP_ENHANCED_MFA = 'SKIP_ENHANCED_MFA',
  SUBMIT_ANSWER_MFA_QUESTIONS = 'SUBMIT_ANSWER_MFA_QUESTIONS',
  SUBMIT_CREDENTIAL = 'SUBMIT_CREDENTIAL',
  SUBMIT_GET_MFA_QUESTIONS = 'SUBMIT_GET_MFA_QUESTIONS',
  SUBMIT_MFA = 'SUBMIT_MFA',
}
// https://docs.flinks.com/docs/events
export const FLINKS_IFRAME_EVENTS = {
  [FlinksEvents.APP_MOUNTED]: {
    label: 'Flinks Connect has retrieved configuration values from the API, and has successfully loaded',
  },
  [FlinksEvents.APP_ONLINE]: {
    label: 'The internet connection was restored',
  },
  [FlinksEvents.APP_OFFLINE]: {
    label: 'The internet connection was lost',
  },
  [FlinksEvents.ACCOUNT_SELECTED]: {
    label: 'The user selects an account',
  },
  [FlinksEvents.COMPONENT_ACCEPT_CONSENT]: {
    label: 'The user accepts to give consent',
  },
  [FlinksEvents.COMPONENT_ACCEPT_TERMS]: {
    label:
      'The user has checked the box to approve the terms and conditions linked in the URL termsUrl={https://example.com}.',
  },
  [FlinksEvents.COMPONENT_CLICK_RESET_PASSWORD]: {
    label: 'The user clicks on the Reset Password button.',
  },
  [FlinksEvents.COMPONENT_CLICK_TERMS_URL]: {
    label: 'The user clicks on the terms and conditions link.',
  },
  [FlinksEvents.COMPONENT_CLOSE_SESSION]: {
    label:
      'The use clicks on the _X in the top right corner. Related to the Layout Customization parameters : closeEnable.',
  },
  [FlinksEvents.COMPONENT_DENY_CONSENT]: {
    label: 'The user denies consent',
  },
  [FlinksEvents.COMPONENT_DENY_TERMS]: {
    label:
      'The user unchecked the box on the left of the terms and conditions using the parameter termsUrl={https://example.com}.',
  },
  [FlinksEvents.COMPONENT_HELP2FA_INFO]: {
    label:
      'When Flinks Connect loads the Help2FA component, or if the users goes back from the troubleshooting screen via the back button.',
  },
  [FlinksEvents.COMPONENT_HELP2FA_TROUBLESHOOTING]: {
    label:
      'When Flinks Connect navigates to the troubleshooting screen due to the user clicking the troubleshooting tips link on the info screen.',
  },
  [FlinksEvents.COMPONENT_LOAD_ACCOUNT_SELECTION]: {
    label:
      "When Flinks Connect loads the account selection page. Contains metadata regarding the user's accounts (totalAccounts & displayedAccounts)",
  },
  [FlinksEvents.COMPONENT_LOAD_CONSENT]: {
    label: 'The consent page is loaded using the parameter consentEnable=true',
  },
  [FlinksEvents.COMPONENT_LOAD_CREDENTIAL]: {
    label: 'The user is faced with a prompt for FI username and password.',
  },
  [FlinksEvents.COMPONENT_LOAD_CREDENTIAL_RETRY]: {
    label: 'The user clicks on retry after an authorize error.',
  },
  [FlinksEvents.COMPONENT_LOAD_INSTITUTION_SELECTOR]: {
    label: 'The FI list page is loaded',
  },
  [FlinksEvents.COMPONENT_LOAD_MFA]: {
    label: 'The MFA page is loaded. Contains metadata that indicates what MFA challenges the user received (mfaTypes)',
  },
  [FlinksEvents.COMPONENT_LOAD_MFA_RETRY]: {
    label: 'The user clicks on retry after a failed MFA challenge error.',
  },
  [FlinksEvents.COMPONENT_PROVIDER_CONSENT_ABOUT]: {
    label: "When the user visits the about screen by clicking the 'tell me more...' link on the info screen.",
  },
  [FlinksEvents.COMPONENT_PROVIDER_CONSENT_INFO]: {
    label:
      'When Flinks Connects loads the US OAuth consent component (or the user goes back to it from one of the following screens)',
  },
  [FlinksEvents.COMPONENT_PROVIDER_CONSENT_LEGAL]: {
    label: "When the user visits the legal screen by clicking the 'privacy policy' link on the info screen.",
  },
  [FlinksEvents.DISABLED_INSTITUTION]: {
    label: 'An Institution is disabled.',
  },
  [FlinksEvents.ENHANCED_MFA_HAS_QUESTIONS]: {
    label: 'When there is unanswered question using enhancedMFA=true',
  },
  [FlinksEvents.ENHANCED_MFA_NO_QUESTIONS]: {
    label: 'When there is no unanswered question using enhancedMFA=true',
  },
  [FlinksEvents.ENHANCED_MFA_SUCCESS]: {
    label: 'Successfully completed the enhancedMFA flow using enhancedMFA=true',
  },
  [FlinksEvents.INSTITUTION_SELECTED]: {
    label: 'The user selected his desired Financial Institution.',
  },
  [FlinksEvents.INVALID_INSTITUTION]: {
    label: 'An institution is not valid.',
  },
  [FlinksEvents.INSTITUTION_NOT_AVAILABLE]: {
    label: 'An institution is not available.',
  },
  [FlinksEvents.INVALID_SECURITY_RESPONSE]: {
    label: 'The MFA challenge failed.',
  },
  [FlinksEvents.MAXIMUM_RETRY_REACHED]: {
    label:
      'The maximum amount of retries is reached using the parameter maximumRetry={number}. It is set in the URL as a parameter.',
  },
  [FlinksEvents.QUESTION_NOT_FOUND]: {
    label:
      'The MFA challenge is returned by the API. Contains metadata that indicates what MFA challenges the user received (mfaTypes)',
  },
  [FlinksEvents.REDIRECT]: {
    label:
      'When the user successfully completed the connection. This steps includes the URL, the loginId, requestId and institution.',
  },
  [FlinksEvents.RETRY_COUNT]: {
    label: 'The authorization failed and the retry count is incremented. Staring at 0.',
  },
  [FlinksEvents.SESSION_NONEXISTENT]: {
    label: 'When the session expired.',
  },
  [FlinksEvents.SESSION_EXPIRED]: {
    label: 'When the session expired.',
  },
  [FlinksEvents.SESSION_STORAGE_BLOCKED]: {
    label: 'Legacy cookie management (deprecated)',
  },
  [FlinksEvents.SESSION_STORAGE_BLOCKED_RETRY]: {
    label: 'Legacy cookie management (deprecated)',
  },
  [FlinksEvents.SKIP_ENHANCED_MFA]: {
    label: 'The user skips the unanswered MFA question using skipEnhancedMFA=true',
  },
  [FlinksEvents.SUBMIT_ANSWER_MFA_QUESTIONS]: {
    label: 'The user submits the unanswered MFA questions',
  },
  [FlinksEvents.SUBMIT_CREDENTIAL]: {
    label: 'The form is valid and the user clicks on continue.',
  },
  [FlinksEvents.SUBMIT_GET_MFA_QUESTIONS]: {
    label: 'When Flinks Connect gets unanswered MFA questions using enhancedMFA=true',
  },
  [FlinksEvents.SUBMIT_MFA]: {
    label: 'The user submits the MFA',
  },
};
