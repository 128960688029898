import React from 'react';
import { Link } from 'react-router-dom';

import { PricingPlanDetails } from './PricingPlan.types';
import { BsArrowUpRight } from 'react-icons/bs';
import CustomFeatures from './CustomFeatures';
import { CURRENT_PLANS } from '../constants';
import { AccountDetail } from './AccountDetail';

const ActiveSubscription = ({ name, price, endDate, description, billingDate }: PricingPlanDetails) => {
  const customPlan = name !== CURRENT_PLANS.power && name !== CURRENT_PLANS.plus;

  return (
    <div>
      <div className="tw-bg-neutral-light tw-border tw-border-neutral-grey-4 tw-rounded-t-md">
        <div className="tw-w-full tw-flex tw-flex-col">
          <div className="tw-flex tw-items-baseline">
            <div className="tw-w-full tw-p-6">
              <div className="tw-flex tw-justify-between">
                <div className="tw-flex">
                  <div className="tw-text-base tw-font-semibold tw-mb-2">{name}</div>
                  <div>
                    <div className="tw-bg-secondary-light-green tw-w-max tw-px-2 tw-rounded-md tw-ml-2">
                      <div className="tw-text-xs tw-text-primary-dark-green tw-font-semibold tw-p-1">Active Plan</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-text-sm tw-text-gray-500 tw-mb-4 tw-pt-1">{description}</div>
            </div>
            <div className="tw-flex tw-p-6">
              <div className="tw-text-4xl tw-font-semibold">${price}</div>
              <div className="tw-text-sm tw-text-gray-500 tw-ml-2">per month</div>
            </div>
          </div>
          <div className="tw-border tw-border-neutral-grey-5" />
          {!customPlan && !endDate && (
            <Link className="tw-flex tw-ml-auto tw-px-3 tw-p-1" to="/dashboard/subscriptions/plans">
              <div className="tw-text-xs tw-text-primary-dark-green tw-font-semibold tw-px-1">Change Plan</div>
              <BsArrowUpRight />
            </Link>
          )}
        </div>
      </div>
      <div className="tw-bg-neutral-grey-4">
        <div className="tw-w-full tw-bg-white tw-p-6">
          <div className="tw-h-1/5 tw-mb-4">
            <div className="tw-font-semibold">Your current plan includes</div>
          </div>
          {(name === CURRENT_PLANS.plus || name === CURRENT_PLANS.power) && (
            <ul className="tw-w-full tw-space-y-4">
              {name === CURRENT_PLANS.plus && (
                <>
                  <AccountDetail>Discounted 0.25% FX Fee</AccountDetail>
                  <AccountDetail>Request payments at 0.25% + $1 per request</AccountDetail>
                </>
              )}
              {name === CURRENT_PLANS.power && (
                <>
                  <AccountDetail>Discounted 0.10% FX Fee</AccountDetail>
                  <AccountDetail>Request payments at 0.25% ($10 max per request)</AccountDetail>
                </>
              )}
              <AccountDetail>Earn 2 Loop Points on Card Spend</AccountDetail>
              <AccountDetail>Unlimited Virtual Cards</AccountDetail>
              <AccountDetail>10 Free Physical Cards</AccountDetail>
              <AccountDetail>Instant Deposits for Credit Card Payments</AccountDetail>
            </ul>
          )}
          {customPlan && <CustomFeatures />}
        </div>
      </div>
      <div className="tw-bg-neutral-light tw-border tw-border-neutral-grey-4 tw-rounded-b-md tw-p-6">
        <div className="tw-h-1/5 tw-mb-4">
          <div className="tw-font-semibold">Your Next Billing Date</div>
          <div className="py-2">{billingDate}</div>
          {endDate && (
            <div className="tw-text-sm tw-text-semantic-error tw-text-xs tw-mb-4 tw-pt-1">
              Your {name} subscription will end on {endDate}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveSubscription;
