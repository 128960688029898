import React from 'react';
import { Collapse } from 'react-collapse';
import { AiFillWarning, AiOutlineEye } from 'react-icons/ai';
import { BsChevronDown, BsSend } from 'react-icons/bs';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { IoMdTime } from 'react-icons/io';
import { HiPencil } from 'react-icons/hi';
import { IoCreateOutline } from 'react-icons/io5';
import cx from 'classnames';

import useIsMobile from 'hooks/useIsMobile';
import DeactivatePayeeModal from './DeactivatePayee';
import EditPayeeModal from './EditPayee';
import Button, { IconAnimation } from 'components/Button';
import { SCOPE, PAYEE_RECORD_TYPES } from 'constants/index';
import PermissionChecker from 'components/PermissionChecker';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { useToggle } from 'hooks';
import useIsContactPermission from 'hooks/useIsContactPermission';
import useIsAdmin from 'hooks/useIsAdmin';
import { SupplierStatus } from 'types/payees';
import DetailsRequestedBadge from './DetailsRequestedBadge';
import { AddSupplierBankDetails, ShowSupplierBankDetails } from './components';

const BasePayeeItem = ({
  payee,
  onPayPayee,
  payeeName,
  payeeCurrency,
  bankName,
  bankAccount,
  children: detailsSection,
  isVerified = false,
  isDeletable = false,
  isEditable = false,
  isEditBankDetails = false,
  showSupplierBankDetailsButton = false,
}) => {
  const { isOpen: showDetails, toggle: toggleShowDetails } = useToggle();
  const { isOpen: showDeactivation, open: showDeactivationModal, close: closeDeactivationModal } = useToggle();
  const { isOpen: showEdit, open: showEditModal, close: hideEditModal } = useToggle();
  const { isOpen: showEditBankDetails, open: showEditBankDetailsModal, close: hideEditBankDetailsModal } = useToggle();
  const {
    isOpen: showSupplierBankDetails,
    open: showSupplierBankDetailsModal,
    close: hideSupplierBankDetailsModal,
  } = useToggle();

  const isMobile = useIsMobile();
  const handlePayPayee = (e) => {
    e.stopPropagation();
    onPayPayee(payee.id);
  };
  const { createPayeePayment } = useIsContactPermission();
  const { isAdmin } = useIsAdmin();

  const isSupplierBankDetailsRequested =
    payee.record.__typename === PAYEE_RECORD_TYPES.Supplier &&
    payee.record.status === SupplierStatus.bank_details_requested;

  const allowEditBankDetails = isEditBankDetails && isAdmin && isSupplierBankDetailsRequested;
  const allowEditDetails = isEditable && isAdmin;
  const allowDelete = isDeletable && isAdmin;

  return (
    <div
      className={cx(
        'tw-py-3 tw-px-4 tw-border-b',
        isMobile
          ? 'tw-border-neutral-light'
          : 'tw-border-neutral-grey-4 hover:tw-bg-neutral-grey-5 tw-transition-all tw-duration-200 tw-ease-in-out'
      )}
    >
      <div
        className="tw-flex tw-justify-between tw-items-center tw-cursor-pointer"
        onClick={toggleShowDetails}
        data-testid="toggle-open-close"
        aria-label="Toggle payee details"
        aria-expanded={showDetails}
        aria-controls={`payee-details-${payee.id}`}
      >
        <div className="tw-w-full tw-flex tw-flex-col tw-place-items-start tw-gap-y-2 lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
          <div className="tw-flex tw-items-center">
            <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
              {payeeCurrency ? (
                <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                  <MobileCurrencyFlag currency={payeeCurrency} size={16} />
                </div>
              ) : (
                <div className="tw-bg-secondary-pastel-yellow-50 tw-rounded-full tw-p-px">
                  <IoMdTime size={18} className="tw-text-neutral-grey-1" />
                </div>
              )}
            </div>
            <span>{payeeName}</span>
          </div>
          {!showDetails && (
            <>
              {isVerified ? (
                <PermissionChecker scope={SCOPE.managePayments} memberScope={createPayeePayment}>
                  <Button
                    onClick={handlePayPayee}
                    className="tw-flex tw-items-center tw-text-primary-dark-green tw-bg-primary-light-green tw-px-4 tw-py-1 tw-rounded-md tw-cursor-pointer"
                    iconAnimation={IconAnimation.rotateAndRollOut}
                  >
                    <BsSend className="tw-mr-3" />
                    Send Payment
                  </Button>
                </PermissionChecker>
              ) : (
                isSupplierBankDetailsRequested && <DetailsRequestedBadge />
              )}
            </>
          )}
        </div>
        <div
          className={cx(
            'tw-rounded-md tw-flex tw-justify-center tw-items-center tw-bg-neutral-light tw-border tw-border-neutral-grey-4 tw-p-2 tw-ml-4 tw-max-h-9 hover:tw-bg-primary-light-green',
            showDetails ? 'tw-bg-neutral-grey-4' : 'tw-bg-neutral-light'
          )}
        >
          <BsChevronDown
            className={`tw-transform tw-rotate-${
              showDetails ? '180' : '0'
            } tw-ease-in-out tw-duration-200 tw-transition-all`}
          />
        </div>
      </div>

      <Collapse isOpened={showDetails} aria-labelledby={`payee-details-${payee.id}`}>
        {detailsSection}

        <div className="tw-flex tw-gap-x-8 tw-flex-col lg:tw-flex-row tw-space-y-2 lg:tw-space-y-0 tw-justify-end tw-mb-2">
          <PermissionChecker scope={SCOPE.managePayments} memberScope={createPayeePayment}>
            {allowEditBankDetails && (
              <Button
                onClick={showEditBankDetailsModal}
                className="tw-flex tw-justify-center tw-items-center tw-py-2.5 tw-w-full lg:tw-w-max"
              >
                <IoCreateOutline className="tw-mr-2" />
                Enter banking details
              </Button>
            )}

            {showSupplierBankDetailsButton && (
              <Button
                onClick={showSupplierBankDetailsModal}
                className="tw-flex tw-justify-center tw-items-center tw-py-2.5 tw-w-full lg:tw-w-max"
              >
                <AiOutlineEye className="tw-mr-2" />
                Bank Details
              </Button>
            )}

            {allowDelete && (
              <Button
                onClick={showDeactivationModal}
                className="tw-flex tw-justify-center tw-items-center tw-text-semantic-error tw-py-2.5 tw-w-full lg:tw-w-max"
              >
                <RiDeleteBin6Line className="tw-mr-2" />
                Delete Payee
              </Button>
            )}

            {allowEditDetails && (
              <Button
                className="tw-flex tw-justify-center tw-items-center tw-text-primary-dark-green tw-py-2.5 tw-w-full lg:tw-w-max"
                onClick={showEditModal}
              >
                <HiPencil className="tw-mr-2" />
                Edit Details
              </Button>
            )}

            {isVerified
              ? createPayeePayment && (
                  <Button primary onClick={handlePayPayee} className="tw-py-2.5 tw-px-12 tw-w-full lg:tw-w-max">
                    <BsSend className="tw-mr-2" />
                    Send Money
                  </Button>
                )
              : isSupplierBankDetailsRequested && <DetailsRequestedBadge />}
          </PermissionChecker>
        </div>

        {!isVerified && !isSupplierBankDetailsRequested && (
          <div className="tw-p-2 tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-rounded-md tw-bg-secondary-pastel-yellow-50">
            <AiFillWarning size="30" className="mr-1 tw-text-primary-yellow" />
            <span className="tw-ml-2 tw-text-primary-dark-yellow">
              This payee is flagged for verification by our compliance team.
            </span>
          </div>
        )}
      </Collapse>

      {showEditBankDetails && <AddSupplierBankDetails show={showEditBankDetails} onClose={hideEditBankDetailsModal} />}

      {showSupplierBankDetails && (
        <ShowSupplierBankDetails
          show={showSupplierBankDetails}
          onClose={hideSupplierBankDetailsModal}
          bankName={bankName}
          bankAccount={bankAccount}
        />
      )}

      {allowDelete && (
        <DeactivatePayeeModal
          show={showDeactivation}
          hide={closeDeactivationModal}
          payee={payee}
          payeeName={payeeName}
        />
      )}

      {allowEditDetails && (
        <EditPayeeModal
          show={showEdit}
          hide={hideEditModal}
          payee={payee}
          allowEditEmail={!isSupplierBankDetailsRequested}
        />
      )}
    </div>
  );
};

export default BasePayeeItem;
