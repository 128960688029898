import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useQuery, useLazyQuery, useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { addDays, format } from 'date-fns';

import history from 'history.js';
import { PayorBankAccount, PaymentRequestStatus, PayorPaymentRequestVendor, PayorUser } from 'types/invoicing';
import { formatMoneyV2 } from 'utility/currency';
import { composeFullAddress } from 'utility/address';
import { AuthContext } from 'context/Auth';
import { PayorOnboardingContext } from 'components/onboarding/PayorOnboarding/contexts/PayorOnboardingContext';
import {
  GET_PAYOR_BANK_ACCOUNTS,
  PROCESS_PAYMENT_REQUEST,
  GET_PAYOR_PAYMENT_REQUEST_VENDOR,
  PAYOR_SIGN_PRE_DEPOSIT_AUTHORIZATION,
} from 'graphql/invoicing';
import { useDeepEffect } from 'hooks';
import { getPaymentRequestSchedule } from 'components/Invoices/Invoices.utils';

const useConfirmPayment = () => {
  const { me } = useContext(AuthContext) as unknown as {
    me: PayorUser;
  };

  const { signedId, paymentRequest, getPaymentRequest, error, setError, isLoading } =
    useContext(PayorOnboardingContext);

  const { id: paymentRequestId, amount, dueDate, invoiceNumber, schedule } = paymentRequest || {};

  const vendorName = get(paymentRequest, 'vendor.name', '');

  const currency = amount?.currency;

  const {
    data: bankAccountsData,
    loading: bankAccountsLoading,
    error: bankAccountsError,
  } = useQuery<{ payorBankAccounts: { connectionsReady: boolean; accounts: PayorBankAccount[] } }>(
    GET_PAYOR_BANK_ACCOUNTS
  );

  const [getPaymentRequestVendor, { data: paymentRequestVendorData, loading: isPaymentRequestVendorDataLoading }] =
    useLazyQuery<{
      payorPaymentRequest: { vendor: PayorPaymentRequestVendor };
    }>(GET_PAYOR_PAYMENT_REQUEST_VENDOR);

  const bankAccounts = get(bankAccountsData, 'payorBankAccounts.accounts', []);

  const bankAccount = currency && bankAccounts.find((bank) => bank.currency === currency);

  const {
    id: payorBankAccountId,
    bankName,
    maskedAccountNumber,
    routingNumber,
    institutionNumber,
    transitNumber,
    accountNumber,
  } = bankAccount || {};

  const vendorEmail = get(paymentRequestVendorData, 'payorPaymentRequest.vendor.email', '');
  const vendorAddress = get(paymentRequestVendorData, 'payorPaymentRequest.vendor.address');
  const { country, countrySubdivision, postalCode, city, street, unitNumber } = vendorAddress || {};

  const fullVendorAddress = composeFullAddress({ country, countrySubdivision, postalCode, city, street, unitNumber });

  const [payorSignPreDepositAuthorization, { loading: isPADProcessing }] = useMutation(
    PAYOR_SIGN_PRE_DEPOSIT_AUTHORIZATION
  );
  const [processPaymentRequest, { loading: isPaymentProcessing }] = useMutation(PROCESS_PAYMENT_REQUEST);

  const formattedAmount = formatMoneyV2(amount);

  const minDueDate = useMemo(() => new Date(), []);
  const maxDueDate = useMemo(() => {
    const now = new Date();
    const maxDate = dueDate ? new Date(dueDate) : now;

    return maxDate < minDueDate ? minDueDate : addDays(maxDate, 1);
  }, [dueDate, minDueDate]);

  const availableDueDate = useMemo(
    () => (dueDate && new Date(dueDate) < minDueDate ? format(minDueDate, 'yyyy-MM-dd') : dueDate),
    [dueDate, minDueDate]
  );

  const {
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  } = getPaymentRequestSchedule(schedule);

  const bankInfo = `${bankName} - ${maskedAccountNumber}`;

  const { firstName: payorFirstName, lastName: payorLastName, preAuthorizationForms } = me || {};

  const isPADAgreementSigned = preAuthorizationForms?.find((pad) => pad.bankAccount.id === payorBankAccountId) || false;

  const form = useForm({
    defaultValues: {
      vendorName,
      dueDate: availableDueDate,
      amount: formattedAmount,
      currency,
      bankInfo,
      invoiceNumber,
    },
  });

  const { register, handleSubmit, setValue } = form;

  const onSubmit = useCallback(
    async (data) => {
      if (error?.message) return;

      const processingToast = toast.loading('Processing payment request...');

      try {
        if (!isPADAgreementSigned) {
          const payorSignPreDepositAuthorizationResponse = await payorSignPreDepositAuthorization({
            variables: { firstName: payorFirstName, lastName: payorLastName, bankAccountId: payorBankAccountId },
          });

          if (!payorSignPreDepositAuthorizationResponse?.data?.payorSignPreDepositAuthorization) {
            throw new Error('Could not sign pre-deposit authorization.');
          }
        }

        const processPaymentResponse = await processPaymentRequest({
          variables: { paymentRequestId, payorBankAccountId, dueDate: data.dueDate },
        });

        if (processPaymentResponse?.data?.processPaymentRequest?.status !== PaymentRequestStatus.scheduled) {
          throw new Error('Could not process payment request');
        }

        toast.update(processingToast, {
          render: 'Payment Request has been confirmed and scheduled',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });

        history.push('/payor/portal');
      } catch (err) {
        toast.update(processingToast, {
          render: `Something went wrong. ${(err as ApolloError)?.message || err}. Please try again.`,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });

        console.error(err);
      }
    },
    [
      error?.message,
      paymentRequestId,
      payorBankAccountId,
      payorFirstName,
      payorLastName,
      processPaymentRequest,
      payorSignPreDepositAuthorization,
      isPADAgreementSigned,
    ]
  );

  useEffect(() => {
    if (!signedId) {
      setError({ message: 'Invalid signedId' } as ApolloError);
      return;
    }

    getPaymentRequest({ variables: { signedId } });
  }, [signedId, getPaymentRequest, setError]);

  useEffect(() => {
    if (!signedId) return;

    getPaymentRequestVendor({ variables: { signedId } });
  }, [signedId, getPaymentRequestVendor]);

  useDeepEffect(() => {
    setValue('vendorName', vendorName);
    setValue('bankInfo', bankInfo);
    setValue('dueDate', availableDueDate);
    setValue('amount', formattedAmount);
    setValue('currency', currency);
    setValue('invoiceNumber', invoiceNumber);
  }, [setValue, vendorName, bankInfo, availableDueDate, formattedAmount, currency, invoiceNumber]);

  useEffect(() => {
    if (!bankAccountsError) return;

    setError(bankAccountsError);
  }, [bankAccountsError, setError]);

  return {
    onSubmit,
    form,
    register,
    handleSubmit,
    isLoading: isLoading || bankAccountsLoading,
    isError: !!error,
    vendorName,
    vendorEmail,
    fullVendorAddress,
    invoiceNumber,
    formattedAmount,
    currency,
    bankName,
    routingNumber,
    institutionNumber,
    transitNumber,
    accountNumber,
    minDueDate,
    maxDueDate,
    isProcessing: isPaymentProcessing || isPADProcessing,
    isPaymentRequestVendorDataLoading,
    isPADAgreementSigned,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  };
};

export default useConfirmPayment;
