import React from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';

import ReactTooltip from 'react-tooltip';

export const ClearChange = ({ onClick }) => (
  <div className="tw-w-4 tw-h-4 tw-cursor-pointer tw-pr-6">
    <p data-tip="reset to original value">
      <IoCloseCircleOutline className="tw-text-semantic-error tw-text-base tw-ml-2" onClick={onClick} />
    </p>
    <ReactTooltip />
  </div>
);
