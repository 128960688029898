import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_POINTS_TRANSACTIONS_PAGE } from 'graphql/pointsTransactions';
import { useDeepEffect } from 'hooks';
import { PointsTransactionsContext } from 'context/PointsTransactions';
import { FilterContext } from 'components/PointsTransactions/context/FilterContext';
import useIsMobile from 'hooks/useIsMobile';

const useTransactionsContent = () => {
  const isMobile = useIsMobile();

  const { setTransactions } = useContext(PointsTransactionsContext);
  const { period, page, numPerPage, setPage, setNumPerPage } = useContext(FilterContext);

  const { loading, data: transactionsData } = useQuery(GET_POINTS_TRANSACTIONS_PAGE, {
    variables: {
      startDate: period?.from,
      endDate: period?.to,
      page: `${page}`,
      numPerPage: `${numPerPage}`,
    },
  });

  const transactions = get(transactionsData, 'pointsTransactionsPage.items', []);
  const totalCount = get(transactionsData, 'pointsTransactionsPage.pageData.totalCount', 0);
  const hasTransactions = !!transactions?.length;

  useDeepEffect(() => {
    setTransactions(transactions);
  }, [transactions, setTransactions]);

  return {
    totalCount,
    page,
    numPerPage,
    setPage,
    setNumPerPage,
    isMobile,
    isLoading: loading,
    hasTransactions,
  };
};

export default useTransactionsContent;
