import React from 'react';

import { OnboardingDashboardLayout, PreviewScreen } from '../';
import transactionsScreenshotBlur from 'images/screenshot-transactions-page-blurred.png';

const Transactions = () => {
  return (
    <OnboardingDashboardLayout>
      <PreviewScreen bgImage={transactionsScreenshotBlur} />
    </OnboardingDashboardLayout>
  );
};

export default Transactions;
