import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import Layout from '../Layout';

const CompletedCreditApplication = () => {
  const onContinue = () => {
    window.location.href = '/';
  };

  return (
    <Layout title="Thank You!">
      <div className="bg-white rounded my-4 text-dark p-5 overflow-hidden">
        <h1 className="tw-mt-12">The Line of Credit Application has been created.</h1>
        <p className="tw-mb-12">To complete your application, we'll need you to complete the following tasks:</p>
        <div className="card p-4 m-4">
          <h6 className="mb-4">Tasks</h6>
          <div className="row">
            <div className="col-12">
              <Link
                to="/dashboard/settings/bank-accounts-settings"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-link"
              >
                Connect your Bank Account
              </Link>
            </div>
            <hr />
            <div className="col-12">
              <Link
                to="/dashboard/settings/documents"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-link"
              >
                Upload Supporting Documents
              </Link>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-4 offset-lg-8">
          <Button
            type="button"
            className="btn tw-bg-primary-dark-green tw-text-neutral-light btn-block"
            onClick={onContinue}
          >
            Continue to Dashboard
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default CompletedCreditApplication;
