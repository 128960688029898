import React from 'react';

import {
  SupplierAddress,
  SupplierBankingDetails,
} from 'components/payments/AddPayee/components/AddSupplierSteps/components';

import { StepsProps } from './Steps.types';

const Steps = ({ error, step, handleNextStep, handlePrevStep, handleFinish, handleClose, isLoading }: StepsProps) => {
  switch (step) {
    case 0:
      return <SupplierBankingDetails onNextStep={handleNextStep} onPreviousStep={handlePrevStep} error={error} />;
    case 1:
      return <SupplierAddress onFinish={handleFinish} onPreviousStep={handleClose} loading={isLoading} error={error} />;
    default:
      return null;
  }
};

export default Steps;
