import { useContext } from 'react';

import { formatDateTime } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { AddFundsToBalanceContext } from 'components/creditCards/context/AddFundsToBalance';

const useComplete = () => {
  const { transaction } = useContext(AddFundsToBalanceContext);

  const { initiatedAt, transactionGroupId, originalAmount, fromAccount } = transaction || {};
  const { amount, currency } = originalAmount || {};

  const formattedAmount = `${currency} ${formatMoneyV2({ amount, currency })}`;

  const isFromBankAccount = fromAccount?.type === 'BankAccount';
  const methodName = isFromBankAccount ? 'External Transfer' : 'Internal Transfer';
  const fromAccountName = fromAccount?.displayName;

  const toAccountName = `Loop Card - ${currency} Balance`;

  const formattedInitiatedAt = formatDateTime(initiatedAt);

  const isSuccessful = !!transactionGroupId;

  return {
    formattedAmount,
    isFromBankAccount,
    methodName,
    fromAccountName,
    toAccountName,
    formattedInitiatedAt,
    transactionGroupId,
    isSuccessful,
  };
};

export default useComplete;
