import React from 'react';

import { EmptyStateConnectBankAccountProps } from './EmptyStateConnectBankAccount.types';
import { ConnectBankOrContactLoopBanner } from 'components/UI';
import { useIsMobile } from 'hooks';

const EmptyStateConnectBankAccount = ({ add }: EmptyStateConnectBankAccountProps) => {
  const isMobile = useIsMobile();

  const message = `${
    isMobile ? 'Log in on your computer and connect' : 'Connect'
  } the business bank account you will be using to ${add ? 'add' : 'withdraw'} funds ${
    add ? 'to' : 'from'
  } your Loop Accounts. Once connected, you'll also be able to create Loop cards and start spending.`;

  return <ConnectBankOrContactLoopBanner title="Accounts" message={message} />;
};

export default EmptyStateConnectBankAccount;
