import { useState } from 'react';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SUBMIT_OWNERS_PROFILE } from 'graphql/onboarding';
import { onboardingList } from 'components/accountSummary/OnboardingSummary';
import { ampTrackEvent } from 'amplitude';
import { isAddressSame } from 'utility/address';
import { onboardingStatus } from 'constants/index';

const useOwnersProfile = ({ accountManager, accountOwner, isSoleProprietor, businessAddress }) => {
  const isBusinessAndPersonalAddressSame = isAddressSame(get(accountManager, 'address'), businessAddress);
  const formInitialValues = {
    address: { ...get(accountManager, 'address'), country: 'CA' },
    otherAddress: { ...get(accountManager, 'otherAddress'), country: 'CA' },
    livedInTheSameAddressRecently:
      get(accountManager, 'livedInTheSameAddressRecently') === undefined
        ? 'true'
        : `${get(accountManager, 'livedInTheSameAddressRecently')}`,
    isAccountOwner: isSoleProprietor ? 'true' : null,
    accountOwnerContact: {
      firstName: get(accountOwner, 'firstName'),
      lastName: get(accountOwner, 'lastName'),
      email: get(accountOwner, 'email'),
      address: { ...get(accountOwner, 'address'), country: 'CA' },
    },
  };

  const [profileData, setProfileData] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [submitOwnersProfile, { error, loading: isSubmitting }] = useMutation(SUBMIT_OWNERS_PROFILE);
  const history = useHistory();

  const handleSubmit = (data) => {
    if (isSoleProprietor) {
      setProfileData(data);
      setShowConfirmationModal(true);
    } else {
      submitOwnerProfile(data);
    }
  };

  const submitOwnerProfile = async ({
    formData,
    personalAddressSameAsBusiness,
    exposedInPolitics,
    fundsInThirdParty,
  }) => {
    const variables = {
      ...formData,
      livedInTheSameAddressRecently: formData.livedInTheSameAddressRecently === 'true',
      isAccountOwner: formData.isAccountOwner === 'true',
      // following fields are optional
      exposedInPolitics,
      fundsInThirdParty,
    };

    if (isSoleProprietor) variables.isAccountOwner = true;
    if (personalAddressSameAsBusiness) variables.address = businessAddress;

    try {
      const response = await submitOwnersProfile({ variables });

      if (!response?.data?.submitOwnerProfile) throw new Error("Failed to submit owner's profile data");

      ampTrackEvent('onboarding: owners_profile: success');

      history.push(
        isSoleProprietor
          ? onboardingList.businessVerification.link
          : onboardingList[onboardingStatus.BUSINESS_CONTACTS].link
      );
    } catch (err) {
      console.error(err);
      ampTrackEvent('onboarding: owners_profile: error');
      toast.error(err?.message || err);
    }
  };

  return {
    formInitialValues,
    error,
    isSubmitting,
    handleSubmit,
    isBusinessAndPersonalAddressSame,
    profileData,
    showConfirmationModal,
    setShowConfirmationModal,
    submitOwnerProfile,
  };
};

export default useOwnersProfile;
