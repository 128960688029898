import React from 'react';
import { get } from 'lodash';

import BasePayeeItem from './BasePayeeItem';
import { Currencies } from 'constants/currencies';
import { PAYEE_RECORD_TYPES } from 'constants/index';

const DetailsSection = ({ name, currency, maskedNumber, type, email }) => (
  <div className="tw-my-6 tw-overflow-hidden">
    <div className="tw-w-1/2 tw-float-left">
      <div className="tw-text-sm tw-text-neutral-grey-1">Name</div>
      <div>{name}</div>
      {maskedNumber && (
        <>
          <div className="tw-text-sm tw-text-neutral-grey-1 tw-mt-6">Account number</div>
          <div>{maskedNumber}</div>
        </>
      )}
      {email && (
        <>
          <div className="tw-text-sm tw-text-neutral-grey-1 tw-mt-6">Email</div>
          <div>{email}</div>
        </>
      )}
    </div>
    <div className="tw-w-1/2 tw-float-right">
      <div className="tw-text-sm tw-text-neutral-grey-1">Type</div>
      <div>{type?.charAt(0)?.toUpperCase() + type?.slice(1)}</div>
      {currency && (
        <>
          <div className="tw-text-sm tw-text-neutral-grey-1 tw-mt-6">Currency</div>
          <div>{currency}</div>
        </>
      )}
    </div>
  </div>
);

const SupplierItem = ({ payee, onPayPayee }) => {
  const supplier = get(payee, 'record', {});
  const { name, displayName, firstName, lastName, bankName, bankAccount, type, currency, email, __typename } = supplier;

  const { maskedNumber } = bankAccount || {};
  const fullName = name || `${firstName} ${lastName}`;
  const payeeName = displayName || name;

  const hasVerifiedBankAccount = bankAccount?.verified;

  const showSupplierBankDetailsButton =
    hasVerifiedBankAccount && __typename === PAYEE_RECORD_TYPES.Supplier && currency === Currencies.CAD;

  return (
    <BasePayeeItem
      payee={payee}
      onPayPayee={onPayPayee}
      payeeName={payeeName}
      payeeCurrency={currency}
      bankName={bankName}
      bankAccount={bankAccount}
      isVerified={hasVerifiedBankAccount}
      isDeletable={true}
      isEditable={true}
      isEditBankDetails={true}
      showSupplierBankDetailsButton={showSupplierBankDetailsButton}
    >
      <DetailsSection name={fullName} currency={currency} maskedNumber={maskedNumber} type={type} email={email} />
    </BasePayeeItem>
  );
};

export default SupplierItem;
