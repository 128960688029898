import { gql } from '@apollo/client';

import { TRANSACTION_FIELDS } from './transactions';

export const MODIFY_PAYMENT = gql`
  mutation ModifyPayment($amount: String!, $dueDate: String!) {
    modifyPayment(amount: $amount, dueDate: $dueDate)
  }
`;

export const SUBMIT_PAYMENT = gql`
  ${TRANSACTION_FIELDS}
  mutation SubmitPayment($amount: String!, $dueDate: String!) {
    submitPayment(amount: $amount, dueDate: $dueDate) {
      transaction {
        ...TransactionFields
      }
      amount {
        amount
        currency
      }
      dueDate
    }
  }
`;

export const GET_EXCHANGE_RATE = gql`
  query ExchangeRate($buy: MoneyInput!, $sell: MoneyInput!) {
    exchangeRate(buy: $buy, sell: $sell) {
      buy {
        amount
        currency
      }
      sell {
        amount
        currency
      }
      expiresAt
      rate
    }
  }
`;

export const GET_EXCHANGE_RATE_FOR_PAYMENT = gql`
  query ExchangeRateForPayment($buy: MoneyInput!, $sell: MoneyInput!, $includeDate: Boolean) {
    exchangeRate(buy: $buy, sell: $sell, includeDate: $includeDate) {
      buy {
        amount
        currency
      }
      sell {
        amount
        currency
      }
      expiresAt
      rate
      reference
    }
  }
`;

export const MOVE_FUNDS = gql`
  mutation MoveFunds(
    $from: String!
    $to: String!
    $originalAmount: MoneyInput!
    $chargedAmount: MoneyInput!
    $exchangeRateReference: String
  ) {
    moveFunds(
      from: $from
      to: $to
      originalAmount: $originalAmount
      chargedAmount: $chargedAmount
      exchangeRateReference: $exchangeRateReference
    ) {
      transactionGroupId
      initiatedAt
      amount {
        amount
        currency
      }
      fromAccount {
        displayName
        currency
      }
      toAccount {
        displayName
        currency
      }
      transferMethod
    }
  }
`;

export const PAY_BALANCE = gql`
  mutation MoveFunds(
    $from: String!
    $to: String!
    $originalAmount: MoneyInput!
    $chargedAmount: MoneyInput!
    $payAll: Boolean
    $payMin: Boolean
  ) {
    moveFunds(
      from: $from
      to: $to
      originalAmount: $originalAmount
      chargedAmount: $chargedAmount
      payAll: $payAll
      payMin: $payMin
    ) {
      transactionGroupId
      initiatedAt
    }
  }
`;
