import countryData from 'country-region-data';

const RESTRICTED_CODES = ['BY', 'LY', 'SS', 'SD', 'CU', 'MM', 'SY', 'KP', 'VE', 'IR', 'RU'];

export const getCountries = () => {
  const topCountries = [];
  const bottomCountries = [];

  countryData
    .filter((country) => !RESTRICTED_CODES.includes(country.countryShortCode))
    .map((country) => {
      if (country.countryName === 'Canada' || country.countryName === 'United States') {
        topCountries.push({ name: country.countryName, value: country.countryShortCode, regions: country.regions });
      } else {
        bottomCountries.push({ name: country.countryName, value: country.countryShortCode, regions: country.regions });
      }
    });

  const allCountries = topCountries.concat(bottomCountries);

  return allCountries;
};

export const getCountrySubdivisions = (country) => {
  if (country)
    return countryData
      .find((c) => c.countryShortCode === country)
      .regions.map((s) => ({ name: s.name, value: s.shortCode }));
  else return [];
};
