import React from 'react';

import { Progress } from 'components/UI';
import Layout from '../Layout';
import { Steps } from './components';
import { PAYOR_ONBOARDING_STEPS } from './constants';
import { usePayorOnboarding } from './hooks';
import { PayorOnboardingContextProvider } from './contexts/PayorOnboardingContext';
import { PayorOnboardingProps } from './PayorOnboarding.types';

const PayorOnboarding = ({ initialStep }: PayorOnboardingProps) => {
  const { currentStep, onPrevStep, onNextStep } = usePayorOnboarding({ initialStep });

  return (
    <Layout>
      <div className="tw-w-full lg:tw-w-4/5 tw-flex tw-flex-col tw-items-center tw-flex-grow tw-mt-8">
        <Progress
          currentStepIndex={initialStep}
          stepLabelsAndIndexes={PAYOR_ONBOARDING_STEPS}
          classNames="tw-w-full tw-mb-16"
        />
        <PayorOnboardingContextProvider>
          <Steps currentStep={currentStep} onPrevStep={onPrevStep} onNextStep={onNextStep} />
        </PayorOnboardingContextProvider>
      </div>
    </Layout>
  );
};

export default PayorOnboarding;
