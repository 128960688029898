import React, { useEffect, useState, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import config from 'config';
import { useFlinksIframeEvent, useGetUserInfo } from 'hooks';
import { useHistory } from 'react-router-dom';
import { GET_BORROWER_FLINKS_URL, GET_PAYOR_FLINKS_URL } from 'graphql/flinks';
import FlinksConnection from '../components/FlinksConnection';

const FlinksServiceInfo = ({ isPayor }) => {
  if (!isPayor) {
    return (
      <div className="tw-m-8">
        <span className="tw-text-sm tw-text-neutral-grey-1">
          Loop uses a secure connection to authenticate your account and does not store your login credentials.
          <br />
          <br />
          If you have any questions please contact{' '}
          <a
            className="tw-font-semibold tw-text-primary-dark-green"
            target="_blank"
            href={`mailto:${config.supportEmail}`}
            rel="noreferrer"
          >
            {config.supportEmail}
          </a>{' '}
          for assistance connecting your account.
        </span>
      </div>
    );
  }
  return (
    <div className="tw-m-8">
      <span className="tw-text-sm tw-text-neutral-grey-1">
        Loop uses a secure connection to authenticate your account and does not store your login credentials. If you
        can't find your bank or are having trouble connecting your account, please contact{' '}
        <a
          className="tw-font-semibold tw-text-primary-dark-green"
          target="_blank"
          href={`mailto:${config.supportEmail}`}
          rel="noreferrer"
        >
          {config.supportEmail}
        </a>
        .
      </span>
    </div>
  );
};

const FlinksConnectionContainer = ({
  isPayor = false,
  redirectUrl,
  country = '',
  currency = '',
  integrations = false,
  allowBankAccountCreation = false,
  connectionId = '',
}) => {
  const [loading, setLoading] = useState(false);
  const [flinksUrl, setFlinksUrl] = useState();
  const history = useHistory();
  const [fetchFlinksBorrowerUrl] = useLazyQuery(GET_BORROWER_FLINKS_URL);
  const [fetchFlinksPayorUrl] = useLazyQuery(GET_PAYOR_FLINKS_URL);

  const { isKYCApproved } = useGetUserInfo();

  const bankAccountsSettingsUrl = isKYCApproved
    ? '/dashboard/settings/bank-accounts-settings'
    : '/onboarding/dashboard/settings/bank-accounts-settings';

  const getFlinksBorrowerUrl = useCallback(async () => {
    const res = await fetchFlinksBorrowerUrl({
      variables: {
        redirectUrl,
        country,
        currency,
        connectionId,
        integrations,
      },
    });

    return res?.data?.flinksConnectionUrl?.url;
  }, [redirectUrl, country, currency, connectionId, integrations]);

  const getFlinksPayorUrl = useCallback(async () => {
    const res = await fetchFlinksPayorUrl({
      variables: {
        redirectUrl,
        country,
        currency,
      },
    });

    return res?.data?.payorFlinksUrl?.url;
  }, [redirectUrl, country, currency]);

  const {
    isInstitutionInvalid,
    isInstitutionNotAvailable,
    isAppOffline,
    isMaximumRetryReached,
    isComponentDenyConsent,
    isComponentDenyTerms,
    isDisabledInstitution,
  } = useFlinksIframeEvent();

  if (
    isInstitutionInvalid ||
    isInstitutionNotAvailable ||
    isAppOffline ||
    isMaximumRetryReached ||
    isComponentDenyConsent ||
    isComponentDenyTerms ||
    isDisabledInstitution
  ) {
    history.push({
      pathname: `${bankAccountsSettingsUrl}/add`,
    });
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        const url = isPayor ? await getFlinksPayorUrl() : await getFlinksBorrowerUrl();

        if (!url) throw new Error('Link for the bank connection is incorrect');

        setFlinksUrl(url);
        setLoading(false);
      } catch (err) {
        toast.error(`Something went wrong: ${err}`);
        console.error(err);
      }
    }

    fetchData();
  }, [isPayor, getFlinksBorrowerUrl, getFlinksPayorUrl]);

  return (
    <div className="tw-flex tw-flex-col tw-items-center">
      <div className="tw-px-4 tw-items-center">
        <FlinksConnection loading={loading} flinksUrl={flinksUrl} />
      </div>
      <FlinksServiceInfo
        isPayor={isPayor}
        allowBankAccountCreation={allowBankAccountCreation}
        bankAccountsSettingsUrl={bankAccountsSettingsUrl}
      />
    </div>
  );
};

export default FlinksConnectionContainer;
