import React from 'react';
import { useHistory } from 'react-router-dom';

import { AddFundsCurrencySelectorModalProps } from './AddFundsCurrencySelectorModal.types';
import { possibleCurrencies } from './constants';
import { Modal } from 'components/UI';
import CurrencyBox from '../CurrencyBox';

const AddFundsCurrencySelectorModal = ({ show, onClose }: AddFundsCurrencySelectorModalProps) => {
  const history = useHistory();
  const navigateToAddFundsModal = (currency: string) =>
    history.push(`/dashboard/accounts?add-funds=true&currency=${currency}`);

  return (
    <Modal show={show} onClose={onClose} title="Add Funds">
      <div className="tw-p-8">
        <span className="tw-font-semibold">Which Currency Account would you like to Add Funds to?</span>
        <div className="tw-flex tw-justify-center tw-mt-8 tw-mb-4 tw-gap-x-4">
          {possibleCurrencies?.map(({ currency, currencyLabel }) => {
            return (
              <CurrencyBox
                key={currency}
                currency={currency}
                currencyLabel={currencyLabel}
                onClick={() => navigateToAddFundsModal(currency)}
              />
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default AddFundsCurrencySelectorModal;
