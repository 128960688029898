import React from 'react';
import cx from 'classnames';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { Currencies } from 'constants/currencies';
import { balancesOrder } from 'constants/index';

const PayeeOption = ({ selected, onSelect, title, description, subDescription, multipleCurrencies = false }) => {
  const selectedClasses =
    'tw-border-primary-dark-green tw-ring tw-ring-secondary-pastel-green-100 tw-bg-secondary-pastel-green-80';

  return (
    <div
      onClick={onSelect}
      className={cx(
        'add-payee-option tw-p-4 tw-mb-8 tw-min-h-36 tw-flex tw-flex-col tw-cursor-pointer tw-rounded-md tw-border',
        selected ? selectedClasses : 'tw-border-neutral-grey-3'
      )}
    >
      <small className="tw-mb-2 tw-text-neutral-grey-1">{title}</small>
      <div className="tw-text-xs tw-text-neutral-black">
        {description}
        <br />
      </div>
      <div className="tw-text-xs">
        {multipleCurrencies ? (
          <div className="tw-flex pt-2">
            <div className="tw-relative tw-flex tw-w-20 tw-h-8 tw-flex-shrink-0">
              {balancesOrder.map((currency, index) => (
                <div
                  key={currency}
                  className={`${
                    currency === 'CAD' ? 'tw-z-40' : `tw-z-${(4 - index) * 10}`
                  } tw-absolute tw-top-0 tw-left-${index * 4} tw-border tw-border-neutral-grey-3 tw-rounded-full`}
                >
                  <MobileCurrencyFlag currency={currency} size={28.5} />
                </div>
              ))}
            </div>
            <div className="ml-2 tw-flex tw-items-center tw-text-neutral-black">{subDescription}</div>
          </div>
        ) : (
          <div className="tw-flex tw-items-center pt-2">
            <div className="tw-border tw-border-neutral-grey-3 tw-rounded-full">
              <MobileCurrencyFlag currency={Currencies.CAD} size={28.5} />
            </div>
            <div className="ml-3 tw-text-neutral-black">{subDescription}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PayeeOption;
