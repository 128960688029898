import React from 'react';
import { Link } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';

import { CardLinksProps } from './CardLinks.types';

const CardLinks = ({ items }: CardLinksProps) => {
  return (
    <>
      {items.map(({ label, Icon, url }, index) => (
        <Link
          key={index}
          className="tw-flex tw-items-center tw-justify-between tw-rounded-md tw-bg-neutral-light hover:tw-text-neutral-dark tw-p-4 tw-mt-4 tw-w-full tw-text-left"
          to={url}
        >
          <div className="tw-flex tw-justify-start tw-items-center">
            <div className="tw-flex tw-justify-center tw-items-center tw-bg-secondary-light-blue tw-rounded tw-py-0.5 tw-px-1 tw-mr-4">
              <Icon className="tw-text-primary-blue tw-h-6 tw-w-6" />
            </div>
            <strong>{label}</strong>
          </div>
          <BsChevronRight />
        </Link>
      ))}
    </>
  );
};

export default CardLinks;
