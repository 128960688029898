import React from 'react';

import { CREDIT_CARD_LIMITS } from 'constants/index';
import WarningAndStars from 'components/svg/WarningAndStars';
import NewCardDetailsStep from 'components/creditCards/createCardFlow/NewCardDetailsStep';
import Button from 'components/Button';
import useGetSubscription from 'hooks/useGetSubscription';

const CreateCardFlow = ({ onFinish, creditCards, globalCreditLimit, refetch }) => {
  const { maxVirtualCards } = useGetSubscription();

  const virtualCardCount = creditCards.filter((creditCard) => creditCard.virtual).length;
  const maxCardLimitsReached = virtualCardCount >= (maxVirtualCards || CREDIT_CARD_LIMITS.VIRTUAL);

  return (
    <>
      {maxCardLimitsReached ? (
        <>
          <div className="tw-px-10 tw-pb-6 tw-flex tw-flex-col tw-items-center">
            <WarningAndStars className="tw-mb-6" />
            <p>You have reached the limit of virtual and physical credit cards.</p>
          </div>
          <div className="tw-flex tw-justify-end tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-6">
            <Button primary className="tw-w-full lg:tw-w-32" onClick={onFinish}>
              Close
            </Button>
          </div>
        </>
      ) : (
        <NewCardDetailsStep
          onClose={onFinish}
          creditCards={creditCards}
          globalCreditLimit={globalCreditLimit}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default CreateCardFlow;
