import { gql } from '@apollo/client';

export const GET_FX_RATES_BY_CURRENCIES = gql`
  query FXRatesByCurrencies($buyCurrencies: [String!]!, $sellCurrency: String, $entity: String) {
    fxRates(buyCurrencies: $buyCurrencies, sellCurrency: $sellCurrency, entity: $entity) {
      id
      sellCurrency
      buyCurrency
      entity
      rate
    }
  }
`;
