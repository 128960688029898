import React from 'react';

import BrokenClock from 'components/svg/BrokenClock';
import { Loaders } from 'components/cards/Loader';
import { Modal, Progress } from 'components/UI';
import { CADBankAccountOpeningProvider } from './contexts/CADBankAccountOnboardingContext';
import { OpenCADAccountModalProps } from './OpenCADAccountModal.types';
import { Steps } from './components';
import { CAD_ONBOARDING_STEPS } from './constants';
import { useOpenCADAccountModal } from './hooks';

const OpenCADAccountModal = ({ show, onClose, onAddAccount }: OpenCADAccountModalProps) => {
  const {
    step,
    setStep,
    isFullScreen,
    enableFullScreen,
    disableFullScreen,
    isCompleted,
    handleFinish,
    isLoading,
    isError,
    externalWalletAccountId,
  } = useOpenCADAccountModal({ onAddAccount, onClose });

  if (isLoading) return <Loaders.Light />;

  if (isError)
    return (
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-max-w-md tw-m-auto tw-py-16">
        <BrokenClock />
        <div className="tw-font-bold tw-text-lg">Something went wrong</div>
        <div className="tw-text-center">Cannot initiate the process</div>
      </div>
    );

  return (
    <Modal
      show={show}
      onClose={handleFinish}
      title="CAD Business Operating Account Opening"
      isFullScreen={isFullScreen}
    >
      <CADBankAccountOpeningProvider externalWalletAccountId={externalWalletAccountId}>
        <div className="tw-flex tw-flex-col tw-py-4">
          <Progress currentStepIndex={step} stepLabelsAndIndexes={CAD_ONBOARDING_STEPS} isCompleted={isCompleted} />
          <Steps
            currentStep={step}
            setCurrentStep={setStep}
            onFinish={handleFinish}
            enableFullScreen={enableFullScreen}
            disableFullScreen={disableFullScreen}
          />
        </div>
      </CADBankAccountOpeningProvider>
    </Modal>
  );
};

export default OpenCADAccountModal;
