import React from 'react';

import config from 'config';

const EnterpriseHelpbar = () => {
  return (
    <div className="tw-w-full tw-bg-neutral-dark tw-py-4 tw-px-6 tw-flex tw-justify-between tw-items-center tw-rounded-lg tw-mt-6 tw-rounded-md tw-mb-16">
      <div>
        <div className="tw-text-lg tw-font-semibold tw-text-neutral-light">Loop for Enterprise</div>
        <div className="tw-text-sm tw-text-neutral-light">
          Get a custom all-in-one solution. Plans start at $500/month
        </div>
      </div>
      <a
        href={`mailto:${config.supportEmail}`}
        className="tw-border-primary-light-green tw-bg-primary-light-green tw-text-lg tw-font-semibold tw-rounded-lg tw-px-6 tw-py-2"
      >
        Contact Us
      </a>
    </div>
  );
};

export default EnterpriseHelpbar;
