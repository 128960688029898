import React from 'react';

import { ReviewProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { useReview } from './hooks';
import { Loaders } from 'components/cards/Loader';
import { Footer } from 'components/Accounts/AccountsContent/components/AccountsModal/components';

const Review = ({ onNextStep, onPrevStep }: ReviewProps) => {
  const { displayName, formattedAmount, bankAccountDisplayName, loading, handleSubmit } = useReview({
    onNextStep,
    onPrevStep,
  });

  if (loading)
    return (
      <div className="tw-text-center tw-p-20">
        <Loaders.Spinner />
      </div>
    );

  return (
    <>
      <div className="tw-px-8 tw-pt-6 tw-pb-4">
        <p>Please confirm your withdrawal details.</p>
        <div className="tw-flex tw-flex-col tw-pt-8 tw-pb-4">
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-2">From Account</div>
            <p className="tw-mt-1">{displayName}</p>
          </div>
          <div className="tw-my-4">
            <div className="tw-text-sm tw-text-neutral-grey-2">Amount</div>
            <p className="tw-mt-1">{formattedAmount}</p>
          </div>
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-2">To Account</div>
            <p className="tw-mt-1">{bankAccountDisplayName}</p>
          </div>
        </div>
      </div>
      <Footer
        onNavigateBack={onPrevStep}
        onSubmit={handleSubmit}
        navigateBackLabel="Edit Details"
        submitLabel="Confirm"
        loading={loading}
      />
    </>
  );
};

export default Review;
