import React from 'react';

import { useAccountsModal } from 'components/Accounts/AccountsContent/components/AccountsModal/hooks';
import RecentTransactions from 'components/RecentTransactions';
import { TRANSACTION_PRODUCT_TYPES } from 'constants/index';
import { encryptString } from 'utility/string';
import { Loaders } from 'components/cards/Loader';

const AccountRecentTransactions = () => {
  const { transactions, displayName, walletId, loadingWalletTransactionsData } = useAccountsModal();

  if (loadingWalletTransactionsData) return <Loaders.Light />;

  return (
    <div className="tw-mt-10">
      <RecentTransactions
        transactions={transactions}
        accountNames={[displayName]}
        transactionsUrl={`/dashboard/transactions?productType=${
          TRANSACTION_PRODUCT_TYPES.wallet
        }&productId=${encryptString(walletId)}`}
        productType={TRANSACTION_PRODUCT_TYPES.wallet}
      />
    </div>
  );
};

export default AccountRecentTransactions;
