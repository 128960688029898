// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getAccountState } from 'utility/accountState';
import { CREDIT_CARD_EMPTY_STATES } from 'constants/index';
import useIsMobile from 'hooks/useIsMobile';
import useIsMember from 'hooks/useIsMember';
import { CreditCardContextProvider } from 'context/CreditCard';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import { ConnectBankOrContactLoopBanner } from 'components/UI';
import { Loaders } from 'components/cards/Loader';
import PendingBankAccountVerificationBanner from 'components/creditCards/PendingBankAccountVerificationBanner';
import CreateFirstCardBanner from 'components/creditCards/CreateFirstCardBanner';
import ReviewInProgressBanner from 'components/creditCards/ReviewInProgressBanner';
import CardsPage from 'components/creditCards/CardsPage';
import MemberNoCards from './MemberNoCards';
import useIsContactPermission from 'hooks/useIsContactPermission';

const Cards = (props) => {
  const { globalCreditLimitLoading, creditCardIdLoading, creditCardLoading, bankAccountsLoading } = props;

  const history = useHistory();
  const isMobile = useIsMobile();

  if (isMobile) history.push('/dashboard/home');

  return (
    <DashboardLayout title="Cards">
      <CreditCardContextProvider>
        {globalCreditLimitLoading || creditCardIdLoading || creditCardLoading || bankAccountsLoading ? (
          <Loaders.Light />
        ) : (
          <Content {...props} />
        )}
      </CreditCardContextProvider>
    </DashboardLayout>
  );
};

const Content = (props) => {
  const {
    creditCards,
    globalCreditLimit,
    connectedBankAccount,
    hasAtLeastOneBankAccountVerified,
    creditCardLoading,
    creditCardsIds,
    hasLineOfCredit,
    creditCardIdLoading,
    creditCard,
    setCreditCard,
    kycAssessment,
    transactions,
    loadingCreditCardLimit,
  } = props;
  const [visibleCards, setVisibleCards] = useState(creditCards);
  const { isMember } = useIsMember();
  const accountState = getAccountState({
    connectedBankAccount,
    hasAtLeastOneBankAccountVerified,
    kycAssessment,
    transactions,
    loadingCreditCardLimit,
    hasLineOfCredit,
    creditCards: creditCards.length,
    creditCardsIds,
    creditCardLoading,
    creditCardIdLoading,
  });
  const { createVirtualCard, createPhysicalCard } = useIsContactPermission();
  const canCreateCard = createVirtualCard || createPhysicalCard;

  useEffect(() => {
    if (creditCard) {
      const updatedCard = creditCards.find((card) => card.id === creditCard.id);
      setCreditCard(updatedCard);
    }

    setVisibleCards(creditCards);
  }, [creditCards]);

  useEffect(() => {
    if (creditCard) {
      const contextCardNotPresentInCardsList = visibleCards.findIndex((card) => card.id === creditCard.id) === -1;

      if (contextCardNotPresentInCardsList) setVisibleCards([...visibleCards, creditCard]);
    }
  }, [creditCard]);

  // LoC with no cards or no bank account
  if (
    accountState === CREDIT_CARD_EMPTY_STATES.connectBankOrContactLoop ||
    accountState === CREDIT_CARD_EMPTY_STATES.connectBankOrContactLoopAsLOCUser
  ) {
    if (isMember && !canCreateCard) {
      return <MemberNoCards title="Cards" />;
    }
    if (isMember && canCreateCard) {
      return <CreateFirstCardBanner creditCards={creditCards} globalCreditLimit={globalCreditLimit} />;
    }
    return (
      <ConnectBankOrContactLoopBanner
        title="Cards"
        message="Connect the business bank account you will be using to transfer funds to your Loop Card. Once connected, you'll be able to create Loop cards and start spending."
      />
    );
  }

  // bank account connected but not verified
  if (accountState === CREDIT_CARD_EMPTY_STATES.waitingBankAccountVerification)
    return <PendingBankAccountVerificationBanner />;

  // kyc not approved
  if (accountState === CREDIT_CARD_EMPTY_STATES.reviewInProgress) return <ReviewInProgressBanner title="Cards" />;

  // kyc approved, at least one bank account connected and verified, no cards
  if (accountState === CREDIT_CARD_EMPTY_STATES.createCreditCard)
    return <CreateFirstCardBanner creditCards={creditCards} globalCreditLimit={globalCreditLimit} />;

  return <CardsPage {...props} visibleCards={visibleCards} accountState={accountState} />;
};

export default Cards;
