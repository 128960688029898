import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const CardAndCoins = ({ width = '378', height = '522', vbHeight = '522', vbWidth = '378', ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      {...other}
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.5327 482.689L132.964 520.043C135.874 521.314 139.155 521.424 142.143 520.351L297.034 464.769C299.711 463.843 302.819 460.921 298.883 458.161C294.947 455.401 211.394 422.041 211.394 422.041C208.476 420.766 205.187 420.658 202.194 421.739L45.8707 476.2C43.0656 477.984 45.0441 481.623 47.5327 482.689Z"
        fill={primaryDarkGreenColor}
        opacity="0.2"
      />
      <path
        d="M47.5327 482.689L132.964 520.043C135.874 521.314 139.155 521.424 142.143 520.351L297.034 464.769C299.711 463.843 302.819 460.921 298.883 458.161C294.947 455.401 211.394 422.041 211.394 422.041C208.476 420.766 205.187 420.658 202.194 421.739L45.8707 476.2C43.0656 477.984 45.0441 481.623 47.5327 482.689Z"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.674717"
        strokeMiterlimit="10"
        opacity="0.2"
      />
      <path
        d="M251.496 408.421C251.496 418.393 221.413 426.473 184.306 426.473C147.199 426.473 117.115 418.393 117.115 408.421V400.095C125.617 392.746 154.152 390.352 184.306 390.352C214.459 390.352 242.923 392.772 251.496 400.095V408.421Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M184.306 418.155C221.414 418.155 251.496 410.073 251.496 400.104C251.496 390.134 221.414 382.052 184.306 382.052C147.197 382.052 117.115 390.134 117.115 400.104C117.115 410.073 147.197 418.155 184.306 418.155Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M184.306 414.75C215.328 414.75 240.477 407.992 240.477 399.656C240.477 391.319 215.328 384.561 184.306 384.561C153.283 384.561 128.135 391.319 128.135 399.656C128.135 407.992 153.283 414.75 184.306 414.75Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M240.477 399.655C240.477 407.99 215.331 414.749 184.306 414.749C153.281 414.749 128.126 408.016 128.126 399.655C128.126 395.157 154.178 387.685 185.203 387.685C216.229 387.685 240.477 395.184 240.477 399.655Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M152.709 404.021H144.963V401.381L146.222 400.571H152.621C153.642 394.947 163.693 388.838 175.346 388.416V391.056L174.043 396.733C167.117 396.733 163.561 398.177 162.469 400.571H180.433C183.074 396.302 186.594 391.444 198.661 391.444C208.88 391.444 215.806 395.026 216.81 400.571H222.231L223.648 401.381V404.021H216.722C215.445 409.566 209.945 412.145 197.64 412.145L196.478 407.049V404.408C201.266 404.188 205.913 405.728 206.961 404.021H190.37C187.818 408.633 184.623 413.579 171.218 413.579C160.824 413.579 153.897 409.821 152.709 404.021ZM162.39 404.021C163.218 406.582 166.228 407.823 170.602 407.823C174.704 407.823 176.622 406.459 178.347 404.021H162.39ZM192.482 400.571H207.251C206.521 398.52 203.968 397.244 199.867 397.244C195.765 397.244 193.926 398.397 192.465 400.571H192.482Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M152.709 401.381H144.963L146.222 397.93H152.621C153.712 391.919 162.302 388.416 175.346 388.416L174.043 394.093C167.117 394.093 163.561 395.536 162.469 397.93H180.433C183.074 393.662 186.594 388.803 198.661 388.803C208.88 388.803 215.806 392.385 216.81 397.93H222.231L223.648 401.381H216.722C215.445 406.925 209.945 409.504 197.64 409.504L196.478 404.408C202.499 404.408 205.781 403.299 206.961 401.381H190.37C187.818 405.993 184.623 410.939 171.218 410.939C160.824 410.939 153.897 407.181 152.709 401.381ZM162.39 401.381C163.218 403.942 166.228 405.183 170.602 405.183C174.704 405.183 176.622 403.819 178.347 401.381H162.39ZM192.482 397.93H207.251C206.521 395.88 203.968 394.603 199.867 394.603C195.765 394.603 193.926 395.756 192.465 397.93H192.482Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M251.496 360.41C251.496 370.382 221.413 378.462 184.306 378.462C147.199 378.462 117.115 370.382 117.115 360.41V352.093C125.617 344.743 154.152 342.358 184.306 342.358C214.459 342.358 242.923 344.77 251.496 352.093V360.41Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M184.306 370.144C221.414 370.144 251.496 362.062 251.496 352.092C251.496 342.122 221.414 334.04 184.306 334.04C147.197 334.04 117.115 342.122 117.115 352.092C117.115 362.062 147.197 370.144 184.306 370.144Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M184.306 366.738C215.328 366.738 240.477 359.98 240.477 351.643C240.477 343.307 215.328 336.549 184.306 336.549C153.283 336.549 128.135 343.307 128.135 351.643C128.135 359.98 153.283 366.738 184.306 366.738Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M240.477 351.687C240.477 360.022 215.331 366.782 184.306 366.782C153.281 366.782 128.126 360.022 128.126 351.687C128.126 347.19 154.178 339.726 185.203 339.726C216.229 339.726 240.477 347.172 240.477 351.687Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M163.816 363.508H152.436V361.114L159.362 341.047H168.357C168.357 343.476 171.596 344.822 181.216 344.822H183.698L183.962 341.047H195.448L195.897 344.796H201.037C213.667 344.796 221.685 345.967 224.159 350.139L224.493 354.715C224.493 360.559 218.279 364.045 205.13 364.045L203.202 358.121V355.727C207.982 355.455 212.717 356.608 212.717 354.715C212.717 352.233 208.791 350.975 201.037 350.975H196.038L196.408 353.712V356.106H183.038V353.712L183.373 350.931L181.41 350.975C174.246 350.975 170.848 350.262 168.357 348.334H168.164L163.816 363.508Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M163.816 361.114H152.436L159.362 338.653H168.357C168.357 341.091 171.596 342.438 181.216 342.438H183.698L183.962 338.653H195.448L195.897 342.403H201.037C216.88 342.403 224.493 345.923 224.493 352.313C224.493 358.157 218.279 361.642 205.13 361.642L203.202 355.719C209.363 355.719 212.717 354.46 212.717 352.313C212.717 349.84 208.791 348.572 201.037 348.572H196.038L196.408 353.704H183.038L183.373 348.528L181.41 348.572C174.246 348.572 170.848 347.859 168.357 345.932H168.164L163.816 361.114Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M237.748 398.388C237.748 408.897 207.885 417.417 171.051 417.417C168.974 417.417 166.923 417.417 164.89 417.329C130.934 416.449 117.74 410.675 117.16 400.782C116.455 388.672 150.957 384.113 166.993 382.872C185.978 381.402 205.605 381.428 217.628 384.773C230.012 388.232 237.748 393.055 237.748 398.388Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M251.496 312.398C251.496 322.371 221.413 330.459 184.306 330.459C147.199 330.459 117.115 322.371 117.115 312.398V304.09C125.617 296.741 154.152 294.347 184.306 294.347C214.459 294.347 242.923 296.767 251.496 304.09V312.398Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M184.306 322.14C221.414 322.14 251.496 314.058 251.496 304.089C251.496 294.119 221.414 286.037 184.306 286.037C147.197 286.037 117.115 294.119 117.115 304.089C117.115 314.058 147.197 322.14 184.306 322.14Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M184.306 318.726C215.328 318.726 240.477 311.968 240.477 303.632C240.477 295.295 215.328 288.537 184.306 288.537C153.283 288.537 128.135 295.295 128.135 303.632C128.135 311.968 153.283 318.726 184.306 318.726Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M240.477 303.632C240.477 311.976 215.331 318.727 184.306 318.727C153.281 318.727 128.126 311.976 128.126 303.632C128.126 299.135 154.178 291.671 185.203 291.671C216.229 291.671 240.477 299.161 240.477 303.632Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M193.741 293.88V291.24C203.08 291.847 214.715 301.054 214.715 301.054V304.372C214.715 310.656 206.732 314.573 192.042 315.567L192.571 317.794L186.41 317.618L186.172 315.858H181.85L181.525 317.847L176.244 317.363L176.446 315.47C161.317 314.485 152.533 310.7 152.533 304.249V301.608C154.372 297.762 164.802 292.085 172.82 291.24V293.88L172.16 299.707C166.527 300.121 163.006 301.687 163.006 304.249C163.006 307.179 168.736 309.204 177.265 309.864L178.779 300.939L182.792 298.642V301.282L182.229 309.996H186.101L185.749 301.564V298.924L190.027 301.309L191.514 309.741C199.612 309.081 204.232 307.179 204.232 304.372C204.232 301.811 200.624 300.121 194.815 299.707L193.741 293.88Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M193.741 291.24C206.063 291.733 214.715 295.702 214.715 301.731C214.715 308.016 206.732 311.932 192.042 312.927L192.571 315.154L186.41 314.978L186.172 313.217H181.85L181.525 315.206L176.244 314.722L176.446 312.83C161.317 311.844 152.533 308.06 152.533 301.608C152.533 295.447 160.674 291.61 172.82 291.24L172.16 297.067C166.527 297.48 163.006 299.047 163.006 301.608C163.006 304.539 168.736 306.563 177.265 307.223L178.779 298.299L182.792 298.642L182.229 307.355H186.101L185.749 298.924L190.027 298.668L191.514 307.1C199.612 306.44 204.232 304.539 204.232 301.731C204.232 299.17 200.624 297.48 194.815 297.067L193.741 291.24Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M237.748 350.165C237.748 360.674 207.885 369.194 171.051 369.194C168.974 369.194 166.92 369.167 164.89 369.114C130.934 368.234 117.74 362.46 117.16 352.559C116.455 340.448 150.957 335.889 166.993 334.648C185.978 333.178 205.605 333.204 217.628 336.549C230.012 340.008 237.748 344.831 237.748 350.165Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M37.603 264.606L145.738 311.517C149.421 313.114 153.574 313.252 157.356 311.905L353.408 242.1C354.095 241.864 354.69 241.419 355.11 240.827C355.53 240.235 355.754 239.526 355.749 238.8C355.749 237.488 355.749 233.8 355.749 233.8L245.009 188.438C241.317 186.837 237.154 186.702 233.365 188.059L35.4995 256.455C35.4995 256.455 35.5523 259.87 35.4995 261.182C35.4513 261.9 35.6279 262.616 36.0048 263.229C36.3818 263.843 36.9403 264.324 37.603 264.606Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M158.658 320.477L236.569 292.717C219.019 285.034 181.877 289.479 177.89 288.924C128.126 282.094 79.9118 312.398 158.658 320.477Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M37.6031 259.325L145.738 306.237C149.422 307.834 153.574 307.972 157.356 306.624L353.408 236.82C354.074 236.583 354.654 236.152 355.071 235.581C355.489 235.01 355.725 234.327 355.748 233.621C355.772 232.914 355.582 232.217 355.204 231.619C354.826 231.022 354.277 230.552 353.628 230.272L245.01 183.157C241.317 181.557 237.154 181.422 233.365 182.779L37.8143 252.786C37.1513 253.025 36.5753 253.458 36.1609 254.028C35.7466 254.598 35.5129 255.279 35.4901 255.984C35.4674 256.688 35.6566 257.383 36.0333 257.979C36.41 258.575 36.9569 259.044 37.6031 259.325Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M206.618 288.951C202.78 278.39 210.86 256.025 188.883 215.398C181.366 201.5 164.714 206.596 173.524 228.081C196.813 284.85 233.321 284.207 229.114 245.314C227.565 230.985 212.875 189.997 212.875 189.997"
        stroke="#EED9F7"
        strokeWidth="15.5789"
        strokeMiterlimit="10"
      />
      <path
        d="M116.209 277.139L116.359 280.616L119.298 282.455L116.051 283.67L115.206 287.041L113.041 284.321L109.582 284.559L111.5 281.663L110.198 278.442L113.551 279.375L116.209 277.139Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M126.19 273.513L126.339 276.98L129.279 278.82L126.022 280.035L125.177 283.406L123.021 280.686L119.562 280.924L121.481 278.028L120.178 274.807L123.523 275.739L126.19 273.513Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M136.17 269.877L136.32 273.345L139.26 275.194L136.003 276.408L135.158 279.77L133.002 277.051L129.534 277.288L131.453 274.401L130.159 271.18L133.504 272.104L136.17 269.877Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M146.151 266.251L146.301 269.719L149.241 271.558L145.984 272.773L145.139 276.144L142.983 273.424L139.515 273.662L141.434 270.766L140.14 267.545L143.485 268.478L146.151 266.251Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M166.105 258.982L166.254 262.45L169.203 264.298L165.946 265.513L165.101 268.884L162.945 266.155L159.477 266.402L161.396 263.506L160.102 260.285L163.447 261.209L166.105 258.982Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M176.085 255.356L176.235 258.824L179.174 260.664L175.927 261.878L175.082 265.249L172.916 262.53L169.458 262.767L171.376 259.872L170.082 256.65L173.427 257.583L176.085 255.356Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M186.066 251.72L186.216 255.188L189.155 257.036L185.908 258.251L185.063 261.613L182.897 258.893L179.438 259.131L181.357 256.235L180.055 253.014L183.399 253.947L186.066 251.72Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M196.047 248.086L196.197 251.562L199.136 253.402L195.889 254.616L195.035 257.987L192.878 255.268L189.419 255.505L191.338 252.61L190.036 249.388L193.38 250.321L196.047 248.086Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M216.009 240.824L216.158 244.292L219.098 246.14L215.842 247.355L214.997 250.717L212.84 247.997L209.373 248.235L211.291 245.348L209.997 242.127L213.342 243.051L216.009 240.824Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M225.981 237.198L226.13 240.666L229.079 242.506L225.822 243.72L224.977 247.091L222.821 244.371L219.353 244.609L221.272 241.713L219.978 238.492L223.322 239.425L225.981 237.198Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M235.961 233.563L236.111 237.031L239.06 238.879L235.803 240.085L234.958 243.456L232.802 240.736L229.334 240.974L231.253 238.078L229.959 234.857L233.303 235.79L235.961 233.563Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M245.942 229.928L246.092 233.396L249.032 235.245L245.784 236.459L244.939 239.821L242.774 237.102L239.315 237.339L241.234 234.452L239.94 231.231L243.284 232.155L245.942 229.928Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M265.904 222.667L266.054 226.135L268.994 227.983L265.746 229.197L264.901 232.56L262.736 229.84L259.268 230.078L261.187 227.182L259.893 223.961L263.238 224.894L265.904 222.667Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M275.885 219.032L276.034 222.5L278.974 224.348L275.718 225.563L274.873 228.934L272.716 226.214L269.249 226.452L271.167 223.556L269.873 220.335L273.218 221.268L275.885 219.032Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M285.866 215.406L286.007 218.874L288.955 220.713L285.699 221.928L284.854 225.299L282.697 222.579L279.229 222.817L281.148 219.921L279.854 216.7L283.199 217.633L285.866 215.406Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M295.838 211.771L295.988 215.238L298.936 217.087L295.68 218.301L294.835 221.663L292.678 218.944L289.21 219.181L291.129 216.294L289.835 213.073L293.18 213.997L295.838 211.771Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M61.3846 256.333L88.898 268.268C89.8677 268.684 90.9062 268.917 91.9609 268.954C93.0242 268.989 94.0844 268.821 95.0854 268.461L125.283 257.662C125.534 257.572 125.752 257.409 125.909 257.194C126.065 256.979 126.154 256.722 126.163 256.456C126.172 256.189 126.1 255.926 125.957 255.7C125.814 255.474 125.607 255.296 125.362 255.189L97.805 243.254C96.7967 242.816 95.717 242.566 94.6189 242.515C93.5952 242.467 92.5722 242.623 91.6088 242.972L61.4638 253.86C61.2132 253.949 60.9954 254.112 60.8384 254.327C60.6815 254.542 60.5928 254.799 60.5837 255.065C60.5753 255.333 60.6472 255.596 60.7899 255.822C60.9327 256.048 61.1398 256.226 61.3846 256.333Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M285.532 239.293L289.827 241C290.769 241.378 291.77 241.589 292.784 241.625C293.886 241.667 294.985 241.497 296.023 241.124L326.194 230.333C326.444 230.246 326.661 230.087 326.818 229.875C326.975 229.663 327.064 229.408 327.074 229.145C327.082 228.877 327.008 228.613 326.862 228.388C326.715 228.163 326.504 227.988 326.256 227.886L321.934 226.126C320.955 225.735 319.916 225.512 318.863 225.466C317.791 225.412 316.72 225.573 315.712 225.941L285.584 236.811C285.335 236.897 285.118 237.057 284.961 237.269C284.804 237.481 284.714 237.735 284.704 237.999C284.689 238.274 284.76 238.547 284.909 238.78C285.057 239.012 285.275 239.192 285.532 239.293Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M372.71 105.995C377.304 114.84 354.333 135.92 321.406 152.986C288.48 170.052 258.036 176.75 253.45 167.948L249.622 160.573C253.785 150.125 277.998 134.855 304.763 120.966C331.528 107.078 357.897 96.0669 368.89 98.6105L372.71 105.995Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M324.152 217.492C322.521 218.378 320.869 219.258 319.197 220.132C286.262 237.242 259.963 239.927 251.241 235.095C249.991 234.329 248.973 233.237 248.298 231.936C247.624 230.634 247.317 229.173 247.413 227.71C248.821 218.847 257.596 211.762 281.465 198.982L324.152 217.492Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M317.574 145.582C350.504 128.476 373.474 107.436 368.878 98.5892C364.283 89.7419 333.862 96.4369 300.931 113.543C268.001 130.649 245.031 151.688 249.627 160.535C254.223 169.383 284.644 162.688 317.574 145.582Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M315.999 142.563C343.529 128.263 362.731 110.672 358.888 103.275C355.045 95.8767 329.612 101.472 302.083 115.773C274.553 130.073 255.351 147.663 259.194 155.061C263.037 162.459 288.469 156.864 315.999 142.563Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M358.892 103.294C362.738 110.687 343.533 128.281 316.002 142.583C288.471 156.886 263.035 162.475 259.189 155.081C257.112 151.086 276.792 132.453 304.323 118.115C331.854 103.778 356.832 99.3242 358.892 103.294Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M312.878 116.196L311.681 113.855C320.245 110.088 334.811 112.896 334.811 112.896L336.325 115.809C339.221 121.38 333.948 128.536 321.362 136.193L322.859 137.953L317.349 140.594L316.31 139.133L314.057 140.321L312.49 141.131L313.124 143.049L308.222 145.047L307.535 143.287C293.647 149.386 284.106 150.073 281.131 144.352L279.907 142.011C279.767 137.751 286.412 127.928 293.11 123.457L294.333 125.799L296.428 131.273C291.622 134.239 289.219 137.249 290.408 139.529C291.754 142.125 297.766 141.289 305.643 137.927L302.879 129.354L305.379 125.464L306.594 127.814L310.114 135.797L311.355 135.146L313.547 134.01L309.348 126.696L308.16 124.355L313.045 124.496L318.255 131.299C325.138 126.978 328.36 123.167 327.057 120.667C325.877 118.397 321.899 118.555 316.548 120.87L312.878 116.196Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M311.681 113.855C322.841 108.574 332.346 108.143 335.128 113.503C338.023 119.074 332.751 126.23 320.165 133.878L321.662 135.639L316.143 138.279L315.113 136.809L312.86 138.006L311.293 138.816L311.918 140.726L307.025 142.733L306.338 140.972C292.449 147.063 282.909 147.758 279.934 142.037C277.117 136.607 282.574 129.407 293.136 123.484L295.24 128.958C290.434 131.916 288.031 134.935 289.211 137.205C290.566 139.846 296.577 138.966 304.455 135.612L301.647 127.022L305.361 125.473L308.882 133.465L310.132 132.813L312.323 131.678L308.16 124.355L311.83 122.155L317.041 128.95C323.915 124.637 327.145 120.817 325.842 118.326C324.663 116.056 320.676 116.214 315.333 118.52L311.681 113.855Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M233.048 136.166C240.257 143.058 225.303 170.386 199.656 197.204C174.008 224.022 147.375 240.173 140.167 233.29L134.155 227.543C134.727 216.312 152.726 194.045 173.559 172.252C194.392 150.46 215.806 131.563 227.046 130.428L233.048 136.166Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M234.905 136.166C242.149 143.093 227.187 170.378 201.504 197.204C175.821 224.031 149.223 240.173 142.015 233.29L136.012 227.543C136.576 216.312 154.574 194.045 175.416 172.252C196.258 150.46 217.663 131.563 228.894 130.428L234.905 136.166Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M242.572 182.366C249.78 189.257 236.331 209.078 213.94 232.49C191.55 255.902 167.548 269.298 160.34 262.415L154.328 256.632C154.152 246.185 169.643 226.531 187.844 207.503C197.526 197.372 207.938 192.135 217.03 188.571C224.951 185.455 231.904 183.527 236.71 181.952L242.572 182.366Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M193.644 191.476C219.295 164.661 234.25 137.337 227.045 130.445C219.841 123.553 193.206 139.704 167.555 166.519C141.903 193.333 126.949 220.658 134.153 227.549C141.357 234.441 167.992 218.29 193.644 191.476Z"
        fill="#D0E7F4"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M191.182 189.119C212.627 166.702 225.127 143.858 219.103 138.096C213.079 132.333 190.812 145.834 169.367 168.251C147.923 190.668 135.422 213.512 141.446 219.274C147.47 225.037 169.738 211.536 191.182 189.119Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M219.107 138.067C225.127 143.832 212.629 166.68 191.189 189.115C169.748 211.55 147.472 225.043 141.451 219.278C138.195 216.171 150.807 192.178 172.257 169.761C193.706 147.344 215.868 134.978 219.107 138.067Z"
        fill="#D0E7F4"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M161.598 204.518L156.238 210.116L154.328 208.294L154.61 206.815L159.01 202.194C155.648 197.565 158.183 186.079 165.937 177.374L167.847 179.196L171.042 184.054C166.254 189.063 164.881 192.636 165.814 195.074L178.233 182.092C176.974 177.242 175.918 171.31 184.244 162.614C191.285 155.23 198.679 152.695 203.379 155.802L207.119 151.885L208.695 151.427L210.605 153.249L205.808 158.257C208.932 163.01 206.996 168.766 198.494 177.665L194.005 174.98L192.104 173.158C195.255 169.541 199.568 167.244 199.066 165.307L187.624 177.304C189.191 182.329 190.555 188.059 181.296 197.741C174.105 205.257 166.606 207.669 161.598 204.518ZM168.278 197.53C170.69 198.709 173.665 197.389 176.693 194.22C179.527 191.263 179.861 188.939 179.333 186L168.278 197.53ZM186.577 173.396L196.786 162.729C194.806 161.848 192.113 162.799 189.279 165.765C186.445 168.731 186.013 170.844 186.577 173.396Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M159.688 202.687L154.328 208.293L152.7 204.993L157.1 200.363C153.509 195.417 156.924 186.791 165.902 177.374L169.106 182.232C164.309 187.24 162.901 190.814 163.825 193.252L176.244 180.27C174.994 175.411 173.938 169.479 182.255 160.783C189.296 153.408 196.689 150.873 201.389 153.98L205.139 150.063L208.659 151.427L203.871 156.435C206.987 161.188 205.051 166.944 196.549 175.798L192.069 173.158C196.223 168.81 197.693 165.668 197.121 163.476L185.679 175.464C187.245 180.498 188.61 186.219 179.351 195.91C172.204 203.426 164.696 205.838 159.688 202.687ZM166.368 195.707C168.78 196.878 171.755 195.558 174.783 192.398C177.617 189.432 177.96 187.117 177.423 184.177L166.368 195.707ZM184.667 171.574L194.876 160.898C192.896 160.017 190.203 160.968 187.369 163.934C184.535 166.9 184.103 169.013 184.667 171.574Z"
        fill="#D0E7F4"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M133.354 178.985C131.233 188.719 100.12 190.198 63.8667 182.286C27.6135 174.373 -0.0582509 160.044 2.0717 150.301L3.83199 142.186C13.7072 136.817 42.0919 140.567 71.5504 147.001C101.009 153.434 128.302 161.875 135.115 170.861L133.354 178.985Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M135.138 170.855C137.265 161.115 109.6 146.8 73.3465 138.882C37.0929 130.965 5.97899 132.442 3.85174 142.182C1.72448 151.922 29.3894 166.237 65.643 174.155C101.897 182.072 133.011 180.595 135.138 170.855Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M124.465 168.061C126.243 159.916 103.116 147.948 72.8081 141.329C42.5002 134.709 16.4888 135.946 14.71 144.09C12.9312 152.235 36.0587 164.203 66.3666 170.822C96.6745 177.442 122.686 176.205 124.465 168.061Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M124.465 168.071C122.705 176.212 96.6787 177.444 66.3753 170.826C36.0719 164.207 12.9328 152.237 14.7107 144.096C15.6701 139.695 42.717 137.935 73.0292 144.58C103.341 151.225 125.424 163.697 124.465 168.071Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M37.788 153.656L30.2188 152.001L30.782 149.422L32.1815 148.894L38.4393 150.259C40.6397 144.978 51.7647 141.158 63.233 143.217L62.6697 145.735L60.1877 151.016C53.4194 149.537 49.626 150.197 48.0593 152.301L65.6006 156.129C69.1212 152.529 73.61 148.534 85.3687 151.104C95.3408 153.286 101.352 158.259 101.15 163.892L106.431 165.054L107.645 166.145L107.082 168.724L100.314 167.245C97.8844 172.394 91.961 173.741 79.9382 171.109V165.89L80.4663 163.338C85.1839 164.139 89.3998 166.629 90.7904 165.195L74.5781 161.674C71.0575 165.626 66.9296 169.78 53.8331 166.955C43.6762 164.675 37.7088 159.527 37.788 153.656ZM47.232 155.716C47.4872 158.356 50.1629 160.239 54.4404 161.172C58.445 162.053 60.6014 161.128 62.8194 159.122L47.232 155.716ZM77.3594 158.761L91.7938 161.912C91.5209 159.755 89.2942 157.96 85.2895 157.089C81.2848 156.217 79.2517 156.904 77.3594 158.717V158.761Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M38.351 151.015L30.7817 149.36L32.7445 146.253L39.0023 147.617C41.3523 141.976 50.497 140.391 63.2327 143.217L60.7507 148.497C53.9824 147.019 50.189 147.679 48.6223 149.783L66.1724 153.611C69.693 150.011 74.1729 146.015 85.9317 148.586C95.9037 150.768 101.915 155.741 101.713 161.374L106.994 162.536L107.645 166.206L100.877 164.727C98.4474 169.876 92.524 171.223 80.5012 168.591V163.372C86.3806 164.657 89.8219 164.252 91.3886 162.65L75.1763 159.13C71.6557 163.09 67.5278 167.245 54.4313 164.411C44.2392 162.096 38.2718 156.947 38.351 151.015ZM47.795 153.074C48.0502 155.715 50.7258 157.598 55.0034 158.531C59.008 159.411 61.1644 158.487 63.3823 156.48L47.795 153.074ZM77.9224 156.12L92.3568 159.27C92.0839 157.114 89.8571 155.319 85.8525 154.447C81.8478 153.576 79.8147 154.324 77.9224 156.137V156.12Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M280.594 115.263C279.177 125.138 248.257 128.879 211.52 123.616C174.783 118.352 146.151 106.127 147.568 96.2254L148.739 87.996C158.209 81.923 186.788 83.5953 216.634 87.8728C246.479 92.1503 274.327 98.5754 281.764 107.034L280.594 115.263Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M281.788 107.057C283.201 97.1879 254.568 84.9229 217.835 79.6623C181.102 74.4016 150.178 78.1375 148.764 88.0065C147.351 97.8755 175.983 110.141 212.717 115.401C249.45 120.662 280.374 116.926 281.788 107.057Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M270.938 105.036C272.12 96.7838 248.183 86.5288 217.474 82.131C186.765 77.7331 160.913 80.8577 159.731 89.1099C158.549 97.3622 182.485 107.617 213.194 112.015C243.903 116.413 269.756 113.288 270.938 105.036Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M270.93 105.026C269.75 113.282 243.892 116.406 213.183 112.015C182.475 107.623 158.535 97.3249 159.715 89.1131C160.348 84.6596 187.193 80.9542 217.901 85.3549C248.609 89.7556 271.564 100.599 270.93 105.026Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M193.363 105.907L182.097 104.296L182.431 101.928L192.113 83.0405L201.02 84.3167C200.677 86.7283 203.66 88.515 213.21 89.8793L215.665 90.2313L216.466 86.5259L227.838 88.1542L227.75 91.93L232.837 92.6869C245.335 94.4472 253.08 96.7708 254.964 101.251L254.647 105.828C253.82 111.61 247.175 114.189 234.157 112.323L233.084 106.162L233.427 103.794C238.197 104.208 242.721 106.004 242.994 104.146C243.346 101.691 239.632 99.8865 231.957 98.7863L227.011 98.0822V100.837L226.676 103.205L213.474 101.312L213.808 98.9448L214.539 96.2427L212.594 96.0051C205.5 94.9929 202.199 93.7959 200.043 91.6044H199.849L193.363 105.907Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M193.741 103.538L182.466 101.927L192.509 80.6719L201.416 81.9481C201.064 84.3597 204.056 86.1464 213.606 87.5106L216.061 87.8627L216.862 84.1573L228.225 85.7855L228.146 89.5613L233.224 90.3183C248.865 92.5626 255.95 97.0954 255.043 103.459C254.216 109.241 247.571 111.82 234.553 109.954L233.48 103.793C239.597 104.673 243.082 103.899 243.381 101.778C243.733 99.3222 240.028 97.5179 232.353 96.4177L227.407 95.7136L227.037 100.836L213.835 98.9437L214.864 93.8917L212.919 93.654C205.834 92.6419 202.534 91.4449 200.368 89.2533H200.175L193.741 103.538Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M149.047 15.9207C157.276 21.5536 146.943 50.9417 125.978 81.5532C105.013 112.165 81.3462 132.426 73.1697 126.793L66.3045 122.101C65.0459 110.924 79.1987 86.0155 96.2295 61.1514C113.26 36.2873 131.347 14.1516 142.226 11.2207L149.047 15.9207Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M118.971 75.9859C139.752 45.1861 149.889 15.692 141.615 10.1089C133.34 4.52588 109.786 24.9681 89.005 55.7679C68.2244 86.5677 58.0865 116.062 66.3614 121.645C74.6363 127.228 98.1904 106.786 118.971 75.9859Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M116.151 74.0806C133.523 48.3321 141.997 23.6742 135.078 19.0058C128.159 14.3374 108.466 31.4262 91.0939 57.1748C73.7214 82.9233 65.2474 107.581 72.1666 112.25C79.0859 116.918 98.7783 99.8292 116.151 74.0806Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M135.589 20.058C142.472 24.7668 133.829 49.3316 116.306 74.9351C98.7818 100.539 78.9961 117.464 72.1134 112.781C68.3992 110.238 76.9542 84.5287 94.4867 58.9252C112.019 33.3218 131.902 17.5232 135.589 20.058Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M102.065 90.0036L95.6309 99.3948L93.6594 98.0482L81.0117 80.9909L86.099 73.5625C88.1057 74.9355 91.0454 73.0256 96.4759 65.0955L97.8841 63.0448L94.9092 60.686L101.396 51.2156L104.749 52.9759L107.68 48.7512C114.809 38.3391 120.31 32.3717 125.151 32.6885L129.112 35.0033C133.935 38.3039 133.301 45.3978 125.873 56.25L119.888 54.4897L117.916 53.1343C120.398 49.0416 124.007 45.7675 122.458 44.7113C120.416 43.3119 117.177 45.8379 112.776 52.2366L109.951 56.3557L112.415 57.6055L114.387 58.9521L106.835 69.9891L104.846 68.6337L102.743 66.7854L101.669 68.4313C97.6201 74.337 95.0941 76.7662 92.1456 77.7256L92.0311 77.884L102.065 90.0036Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
      <path
        d="M100.094 88.6569L93.6598 98.048L79.0405 79.6442L84.119 72.2158C86.1345 73.5976 89.0742 71.6877 94.5047 63.7488L95.9129 61.698L92.938 59.348L99.4247 49.8689L102.778 51.6291L105.727 47.3956C114.651 34.3519 121.851 30.0128 127.158 33.639C131.981 36.9395 131.348 44.0423 123.919 54.8945L117.934 53.1342C121.455 48.0381 122.273 44.5704 120.504 43.3558C118.462 41.9563 115.223 44.4824 110.823 50.881L107.997 55.0089L112.398 57.6053L104.846 68.6336L100.771 65.4386L99.6976 67.0757C95.6489 72.9903 93.1229 75.4195 90.1744 76.37L90.06 76.5373L100.094 88.6569Z"
        fill="#FFE8C3"
        stroke={primaryDarkGreenColor}
        strokeWidth="1.16842"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default CardAndCoins;
