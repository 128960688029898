import React from 'react';
import { useHistory } from 'react-router-dom';

import { encryptString } from 'utility/string';
import { Loaders } from 'components/cards/Loader';

const ActivateCard = ({ creditCards, loading }) => {
  const history = useHistory();

  if (!loading) {
    // TODO: Handle card / functionality access based on team functionality
    const cardToBeActivated = creditCards && creditCards[0];
    const searchString = cardToBeActivated
      ? `?selectedCardId=${encryptString(cardToBeActivated.id)}&activate=true`
      : '';

    history.push(`/dashboard/cards/list${searchString}`);
  }

  return <Loaders.FullScreen />;
};

export default ActivateCard;
