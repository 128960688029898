// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React from 'react';

import { formatMoneyV2 } from 'utility/currency';
import CurrencyFlag from 'components/svg/CurrencyFlag';

const Balance = ({ balance }) => {
  return (
    <div className="tw-flex tw-items-center">
      <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-3">
        <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
          <CurrencyFlag currency={balance.currency} size={28} />
        </div>
      </div>
      <small>
        <strong>{formatMoneyV2(balance)}</strong> {balance.currency}
      </small>
    </div>
  );
};

export default Balance;
