import React from 'react';
import { IoFlashOutline } from 'react-icons/io5';
import qs from 'query-string';

import DashboardLayout from 'components/dashboard/DashboardLayout';
import UpgradeSubscription from './components/UpgradeSubscription';
import LeftColumn from './components/LeftColumn';
import PricingPlanCard from './components/PricingPlanCard';
import ActiveSubscription from './components/ActiveSubscription';
import { SubscriptionType } from './Subscriptions.types';
import Helpbar from './components/Helpbar';
import { useGetSubscription } from 'hooks/';
import { CURRENT_PLANS, PRICING_PLANS } from './constants';
import { AccountDetail } from './components/AccountDetail';
import EnterpriseHelpbar from './components/EnterpriseHelpbar';
import useScrollViewToPosition from 'hooks/useScrollViewToPosition';

const Subscriptions = ({ type }: { type: SubscriptionType }) => {
  const { isPaidSubscription, name, endDate, nextStartDate, price } = useGetSubscription();
  useScrollViewToPosition();
  const queryParams = qs.parse(window?.location?.search);
  const selectedPlan = String(queryParams?.selected ? queryParams.selected : '');

  const showSubscription = type === SubscriptionType.active && isPaidSubscription;
  const showUpgrade = type === SubscriptionType.upgrade || type === SubscriptionType.plans || !isPaidSubscription;
  const showUpgradeNotActive = type === SubscriptionType.active && !isPaidSubscription;

  const freePlan = !isPaidSubscription;
  const plusPlan = isPaidSubscription && name === CURRENT_PLANS.plus;
  const powerPlan = isPaidSubscription && name === CURRENT_PLANS.power;

  const isActive = (plan: string) => {
    switch (plan) {
      case CURRENT_PLANS.plus:
        return plusPlan;
      case CURRENT_PLANS.power:
        return powerPlan;
      case CURRENT_PLANS.free:
        return freePlan;
      default:
        break;
    }
  };

  return (
    <div>
      <DashboardLayout>
        {showUpgrade && (
          <div className="tw-mb-8 tw-flex tw-flex-col">
            <div className="tw-flex tw-items-center tw-py-1">
              <div className="tw-text-sm tw-text-primary-dark-green tw-font-semibold">Upgrade</div>
              <IoFlashOutline className="tw-text-primary-dark-yellow" />
            </div>
            <div className="tw-text-2xl tw-font-semibold tw-mb-4">Upgrade to Lock In The Best Rates</div>
            <div className="tw-text-base tw-text-neutral-grey-2">
              Unlock more features and lower rates with simple and transparent pricing. Unlike bank fees, our plans make
              it easy to understand what you’re paying.
            </div>
          </div>
        )}
        {showSubscription && (
          <>
            <div className="tw-mb-8 tw-flex tw-flex-col">
              <div className="tw-text-2xl tw-font-semibold tw-mb-4">Manage Subscriptions</div>
            </div>
            <ActiveSubscription
              name={name}
              description="You’re currently benefitting from lower fees and greater rewards."
              price={price}
              endDate={endDate}
              billingDate={nextStartDate}
            />
          </>
        )}
        {(type === SubscriptionType.upgrade || showUpgradeNotActive) && (
          <>
            <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-6">
              <div className="tw-w-full tw-flex tw-flex-wrap md:tw-flex-nowrap tw-items-stretch tw-gap-6 tw-flex-grow">
                <div className="tw-w-full md:tw-w-2/5">
                  <LeftColumn />
                </div>
                <div className="tw-w-full md:tw-w-3/5 tw-p-8">
                  <UpgradeSubscription selectedPlan={selectedPlan} />
                </div>
              </div>
            </div>
            <div className="tw-flex tw-bg-neutral-grey-4 tw-rounded-md">
              <div className="tw-flex tw-flex-col tw-justify-start tw-p-6 tw-space-y-1">
                <div className="tw-font-semibold">Features</div>
                <div className="tw-text-base">All of our plans include </div>
                <div className="tw-flex tw-p-6">
                  <ul className="tw-grid tw-grid-cols-2 tw-gap-4">
                    <AccountDetail>Free USD, EUR, GBP Account Details</AccountDetail>
                    <AccountDetail>Unlimited Team Members</AccountDetail>
                    <AccountDetail>Custom Notifications</AccountDetail>
                    <AccountDetail>U.S. Billing Address</AccountDetail>
                    <AccountDetail>Loop Rewards</AccountDetail>
                    <AccountDetail>No FX Fees on Card Spend</AccountDetail>
                    <AccountDetail>Multi-Factor Authentication</AccountDetail>
                    <AccountDetail>Automated Receipt Matching</AccountDetail>
                    <AccountDetail>Custom Accounts Payable Approval Rules</AccountDetail>
                    <AccountDetail>Free Global Payments</AccountDetail>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
        {type === SubscriptionType.plans && (
          <div className="tw-w-full tw-flex tw-items-stretch tw-flex-grow tw-gap-6 tw-flex-wrap md:tw-flex-nowrap">
            {PRICING_PLANS.map((plan) => (
              <PricingPlanCard
                key={plan.name}
                name={plan.name}
                description={plan.description}
                price={plan.price}
                active={isActive(plan.name)}
              />
            ))}
          </div>
        )}
        {type === SubscriptionType.upgrade ? <EnterpriseHelpbar /> : <Helpbar />}
      </DashboardLayout>
    </div>
  );
};

export default Subscriptions;
