import React from 'react';
import cx from 'classnames';

import Button from 'components/Button';
import { SubmitButton } from 'components/FormFields/v2';

const FormFooter = ({
  onPreviousStep,
  onSubmit,
  submitLabel,
  submitButtonClassName,
  loading,
}: {
  onPreviousStep?: () => void;
  onSubmit?: () => void;
  submitLabel: string;
  submitButtonClassName?: string;
  loading?: boolean;
}) => (
  <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex tw-justify-between">
    {!!onPreviousStep && (
      <Button type="button" isDisabled={loading} secondary onClick={onPreviousStep} className="tw-px-8">
        Back
      </Button>
    )}
    <SubmitButton
      isDisabled={loading}
      className={cx('tw-py-2 tw-px-8 tw-ml-auto', submitButtonClassName)}
      onClick={onSubmit}
    >
      {loading ? 'Submitting...' : submitLabel}
    </SubmitButton>
  </div>
);

export default FormFooter;
