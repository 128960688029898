import React, { useContext } from 'react';

import { Loaders } from 'components/cards/Loader';
import Table from './components/Table';
import { AssetAccountMappingRequestContext } from './contexts/AssetAccountMappingRequestContext';

const AssetAccountSettings = ({ accountName }: { accountName: string }) => {
  const { loading, walletAssetAccountMappings } = useContext(AssetAccountMappingRequestContext);

  return (
    <div className="tw-mt-8">
      {loading ? (
        <Loaders.Light />
      ) : (
        <Table walletAssetAccountDetailsMappings={walletAssetAccountMappings} accountName={accountName} />
      )}
    </div>
  );
};

export default AssetAccountSettings;
