import { format, add, addDays, endOfMonth, startOfMonth, subMonths, isToday } from 'date-fns';
import { startCase } from 'lodash';

const getDayWithSuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

export const formatDateWithTime = (dateTime?: Date) => {
  if (!dateTime) return '';

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
  };
  const time = dateTime.toLocaleTimeString('en-US', timeOptions);
  const date = dateTime.toLocaleDateString('en-US', dateOptions);
  if (isToday(dateTime)) {
    return `Today at ${time}`;
  } else {
    const day = dateTime.getDate();
    const dayWithSuffix = getDayWithSuffix(day);
    return date.replace(day.toString(), dayWithSuffix) + ' at ' + time;
  }
};

// YYYY-MM-DD format
export const formatLocalDate = (date: Date) =>
  date?.getFullYear() +
  '-' +
  String(date?.getMonth() + 1).padStart(2, '0') +
  '-' +
  String(date?.getDate()).padStart(2, '0');

export const formatDate = (date?: Date, dateFormat = 'MMMM d, yyyy') => {
  if (!date) return '';

  try {
    return format(new Date(`${date} 00:00:00`), dateFormat);
  } catch (err) {
    // TODO: Send error to sentry in here
    // This is currently a specific Mobile Safari issue
    return date.toLocaleString();
  }
};

export const formatDOB = (dateTime?: Date, dateFormat = 'MMMM d, yyyy') => {
  if (!dateTime) return '';

  try {
    return format(new Date(dateTime), dateFormat);
  } catch (err) {
    return dateTime.toLocaleString();
  }
};

export const formatDateTime = (dateTime?: Date, dateFormat = 'MMMM d, yyyy') => {
  if (!dateTime) return '';

  try {
    return format(new Date(dateTime), dateFormat);
  } catch (err) {
    return dateTime.toLocaleString();
  }
};

export const formatDueDate = (dueDate?: Date) => {
  if (!dueDate) return 'Payment not required at this time';
  return `Payment due on ${formatDate(dueDate)}`;
};

const generateDateEntry = (date: Date) => {
  return { name: formatDateTime(date), value: formatDateTime(date, 'yyyy-MM-dd') };
};

export const generateDates = (startDate: Date, endDate: Date) => {
  if (startDate > endDate) {
    return [generateDateEntry(startDate)];
  }

  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(generateDateEntry(currentDate));
    currentDate = add(currentDate, { days: 1 });
  }

  return dateArray;
};

export const isWithinPastSixtyDays = (date?: Date) => (date ? addDays(date, 60) > new Date() : false);

export const dateToDateTime = (dateString: string) => {
  const [years, months, days] = dateString.split('-') as unknown as number[];
  const newDate = new Date();

  newDate.setMonth(months - 1);
  newDate.setDate(days);
  newDate.setFullYear(years);

  return newDate;
};

const formatLocaleDate = (date: Date, locale: string) =>
  startCase(date.toLocaleString(locale, { month: 'long', year: 'numeric' }));

// Generate all the periods from now, each month along the past year.
// returns a list of strings 'month - year'
export const getPeriodOptions = () => {
  const now = new Date();
  const options = [];

  for (let i = 0; i < 13; i++) {
    const lastMonthI = subMonths(now, i);
    const key = formatLocaleDate(lastMonthI, 'default');
    const value = formatLocaleDate(lastMonthI, 'en-US');
    options.push({ key, value });
  }

  return options;
};

// given a string 'month-year' return an object {from, to} with first and last date
// of month.
export const getDatesFromOption = (value: string) => {
  const date = new Date(value);
  const from = startOfMonth(date);
  const to = endOfMonth(date);
  return { from, to };
};

export const humanizeSeconds = (timeInSeconds: number) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;

  let result = '';
  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''} `;
  }
  if (minutes > 0) {
    result += `${minutes} minute${minutes > 1 ? 's' : ''} `;
  }
  if (seconds > 0 || (hours === 0 && minutes === 0)) {
    result += `${seconds} second${seconds !== 1 ? 's' : ''}`;
  }

  return result.trim();
};
