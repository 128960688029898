import React from 'react';

const CardOutline = (props) => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.7344 4.4375H4.26562C2.9971 4.4375 1.96875 5.46585 1.96875 6.73438V15.2656C1.96875 16.5342 2.9971 17.5625 4.26562 17.5625H16.7344C18.0029 17.5625 19.0312 16.5342 19.0312 15.2656V6.73438C19.0312 5.46585 18.0029 4.4375 16.7344 4.4375Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.96875 8.375H19.0312" stroke="currentColor" strokeLinejoin="round" />
      <path d="M7.21875 12.8047H5.25V13.625H7.21875V12.8047Z" stroke="currentColor" strokeLinejoin="round" />
    </svg>
  );
};

export default CardOutline;
