import { useContext } from 'react';
import { get } from 'lodash';

import { User } from 'types/user';
import { AuthContext } from 'context/Auth';
import { CREDIT_CARD_LIMITS } from 'constants/index';

const useGetSubscription = () => {
  // TODO update after https://getloop.atlassian.net/browse/LBP-2615
  const { me, meLoading } = useContext(AuthContext) as unknown as { me: User; meLoading: boolean };

  const isPaidSubscription = get(me, 'account.subscription.paid', false);
  const nextStartDate = get(me, 'account.subscription.nextStartDate', '');
  const name = get(me, 'account.subscription.name', '');
  const endDate = get(me, 'account.subscription.endDate', '');
  const price = get(me, 'account.subscription.price', 0);
  const maxPhysicalCards = get(me, 'account.maxPhysicalCards', CREDIT_CARD_LIMITS.PHYSICAL);
  const maxVirtualCards = get(me, 'account.maxVirtualCards', CREDIT_CARD_LIMITS.VIRTUAL);

  return {
    isPaidSubscription,
    nextStartDate,
    name,
    endDate,
    price,
    maxPhysicalCards,
    maxVirtualCards,
    loading: meLoading,
  };
};

export default useGetSubscription;
