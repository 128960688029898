import { gql } from '@apollo/client';

export const START_CARD_MIGRATIONS = gql`
  mutation CreateCreditCardMigration($cardMigrations: [CreditCardMigrationInput!]!) {
    createCreditCardMigration(cardMigrations: $cardMigrations)
  }
`;

// we don't have the endpoint for this yet
// export const GET_CARD_MIGRATION_SUMMARY = gql`
// `;
