import React from 'react';
import cx from 'classnames';

import { usePeriodSelector } from './hooks';
import { CollapsedOptions } from './components';
import { PeriodSelectorProps } from './PeriodSelector.types';
import { CURRENT_CYCLE } from '../../constants';

const PeriodSelector = ({
  selectedStatementId,
  onSelect,
  statementsList,
  currentCycleStartDate,
}: PeriodSelectorProps) => {
  const { collapsedOptions, statementOptions, visibleOptions } = usePeriodSelector({
    selectedStatementId,
    statementsList,
    currentCycleStartDate,
  });

  return (
    <div className="tw-flex tw-mx-auto xl:tw-mx-0 tw-my-0">
      <ul className="tw-flex">
        {visibleOptions.map(({ id, label }, index) => {
          const isSelected = id === selectedStatementId;
          const isFirstItem = index === 0;
          const isLastItem = index === statementOptions.length - 1;
          const isCurrentCycle = id === CURRENT_CYCLE;

          const selectedClassName = isCurrentCycle
            ? 'tw-bg-secondary-pastel-green-100'
            : 'tw-bg-secondary-pastel-yellow-50';

          const className = cx(
            'tw-flex tw-items-center tw-text-sm md:tw-text-base tw-font-semibold tw-whitespace-nowrap tw-justify-center tw-h-10 tw-transition-all tw-transition-300 tw-py-1 tw-px-2 2xl:tw-px-4 tw-border tw-border-neutral-grey-4',
            isSelected ? selectedClassName : 'hover:tw-bg-neutral-grey-5',
            isFirstItem && 'tw-rounded-l',
            isLastItem && 'tw-rounded-r',
            isFirstItem && !isLastItem && 'tw-border-r-0'
          );

          return (
            <li key={id}>
              <button onClick={() => onSelect(id)} className={className}>
                {label}
              </button>
            </li>
          );
        })}
        {!!collapsedOptions.length && (
          <li className="tw-relative">
            <CollapsedOptions options={collapsedOptions} onSelect={onSelect} selectedOptionId={selectedStatementId} />
          </li>
        )}
      </ul>
    </div>
  );
};

export default PeriodSelector;
