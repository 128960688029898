// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React, { useContext } from 'react';
import { get } from 'lodash';
import { BiBarChart } from 'react-icons/bi';
import { useHistory, Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

import { formatMoneyV2 } from 'utility/currency';
import { formatDate } from 'utility/date';
import { CREDIT_ASSESSMENT_STATUS } from 'constants/index';
import { DEFAULT_AMOUNT } from 'constants/defaultValues';
import { AuthContext } from 'context/Auth';
import InfoTooltip from 'components/InfoTooltip';
import Button, { IconAnimation } from 'components/Button';
import useGetProductState from 'hooks/useGetProductState';
import { Currencies } from 'constants/currencies';

const CreditSummary = (props) => {
  const { isLoading: meLoading, isCreditCard } = useGetProductState();
  const loading = meLoading || props.groupedCardsInfoLoading;

  if (loading) return <Skeleton />;

  return isCreditCard ? <CreditCardSummary {...props} /> : <PreFundedCardSummary {...props} />;
};

const Skeleton = () => (
  <div data-testid="credit-summary-loader" className="tw-w-max">
    <div className="tw-flex tw-flex-col lg:tw-items-end tw-mb-4 tw-space-y-4">
      {Array(2)
        .fill()
        .map((_, i) => (
          <div key={i} className="tw-flex tw-items-center tw-justify-between lg:tw-justify-start tw-space-x-4">
            <div className="tw-bg-neutral-grey-4 tw-h-4 tw-w-24 tw-animate-pulse" />
            <div className="tw-bg-neutral-grey-4 tw-flex tw-h-4 tw-w-44 tw-animate-pulse" />
          </div>
        ))}
    </div>
  </div>
);

const CreditCardSummary = ({
  groupAvailableBalance,
  groupOverlimit,
  groupCreditLimit,
  billingCycle,
  noCreditCards,
}) => {
  const limit = groupCreditLimit ? `${formatMoneyV2(groupCreditLimit)}` : `${formatMoneyV2(DEFAULT_AMOUNT)}`;
  const limitCurrency = groupAvailableBalance ? `${groupAvailableBalance.currency}` : Currencies.CAD;
  const groupOverlimitAmount = formatMoneyV2(groupOverlimit);
  const getAvailableAmount = (groupOverlimit, groupAvailableBalance) => {
    if (groupOverlimit?.amount === 0) {
      return formatMoneyV2(groupAvailableBalance);
    } else {
      const overlimitAmount = groupOverlimit?.amount || 0;
      const newAmount = {
        amount: (groupAvailableBalance?.amount || 0) + overlimitAmount,
        currency: groupAvailableBalance?.currency || Currencies.CAD,
      };
      return formatMoneyV2(newAmount);
    }
  };
  const groupOverlimitCurrency = groupOverlimit && groupOverlimit?.currency;
  const amount = getAvailableAmount(groupOverlimit, groupAvailableBalance);

  const currency = groupAvailableBalance && groupAvailableBalance.currency;
  const startDate = billingCycle && billingCycle.startDate;
  const endDate = billingCycle && billingCycle.endDate;

  return (
    <div className="tw-w-max">
      <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-end tw-mb-4">
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-2 lg:tw-mb-0 lg:tw-justify-start lg:tw-w-44">
          <small className="tw-mr-1">Available to Spend</small>
          <InfoTooltip message="Sum of spending limits on all cards" />
        </div>
        <div className="tw-flex tw-items-end">
          <span className="tw-text-2xl tw-mr-2 tw-font-semibold">
            {`${amount} `}
            <small className="tw-text-neutral-grey-2">{currency}</small>
          </span>
        </div>
      </div>
      {groupOverlimit?.amount !== 0 && (
        <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-end tw-mb-4">
          <div className="tw-flex tw-items-center tw-justify-between tw-mb-2 lg:tw-mb-0 lg:tw-justify-start lg:tw-w-44">
            <small className="tw-mr-1">Instant Deposit</small>
            <InfoTooltip message="Based on your account credit health and pending credit transactions, your spending limit was increased by this amount while transactions clear." />
          </div>
          <div className="tw-flex tw-items-end">
            <span className="tw-text-l tw-mr-2 tw-text-neutral-grey-2">
              {groupOverlimitAmount}
              <small className="tw-text-neutral-grey-2 tw-pl-1">{groupOverlimitCurrency}</small>
            </span>
          </div>
        </div>
      )}
      <div className="tw-flex tw-items-end tw-mb-4">
        <div className="tw-flex tw-items-center tw-justify-start tw-w-44">
          <small className="tw-mr-1">Credit Limit</small>
          <InfoTooltip message="The total amount of credit that your business is authorized to access through Loop." />
        </div>
        <div className="tw-flex tw-items-center">
          <strong>{limit}</strong>
          <small className="tw-text-neutral-grey-2 tw-ml-2">{limitCurrency}</small>
        </div>
      </div>
      <div className="tw-flex tw-mb-4">
        <div className="tw-items-center tw-justify-start lg:tw-w-44">
          <small>Current Cycle</small>
        </div>
        <div>
          <small>
            {formatDate(startDate, 'MMMM d')} - {formatDate(endDate)}
          </small>
        </div>
      </div>
      <Link to="/dashboard/payments/pay-balance">
        <Button
          primary
          className="tw-font-bold tw-w-full"
          iconAnimation={IconAnimation.rollOut}
          isDisabled={noCreditCards}
        >
          Pay Card Balance <BsArrowRight className="tw-ml-2" />
        </Button>
      </Link>
    </div>
  );
};

const PreFundedCardSummary = () => {
  return (
    <div className="tw-w-max">
      <div className="tw-flex tw-items-center">
        <div className="tw-flex tw-items-center tw-justify-start tw-w-44">
          <small className="tw-mr-1">Credit Limit</small>
          <InfoTooltip message="The total amount of credit that your business is authorized to access through Loop." />
        </div>
        <div className="tw-flex tw-items-center tw-space-x-2">
          <small className="tw-text-neutral-grey-2">{`${formatMoneyV2(DEFAULT_AMOUNT)} ${
            DEFAULT_AMOUNT.currency
          }`}</small>
          <CreditApplication />
        </div>
      </div>
    </div>
  );
};

const CreditApplication = () => {
  const { me } = useContext(AuthContext);
  const history = useHistory();
  const externalAccountsConnected = get(me, 'account.externalAccountsConnected', false);
  const creditAssessment = get(me, 'account.creditAssessment', {});
  const waitingForCreditReview =
    externalAccountsConnected && creditAssessment.status !== CREDIT_ASSESSMENT_STATUS.APPROVED;
  const onApply = () => history.push('/dashboard/settings/integrations?referrer=cards');

  if (waitingForCreditReview)
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-p-2 tw-rounded-md tw-bg-secondary-light-yellow">
        <span className="tw-text-xs tw-text-primary-dark-yellow">Credit Limit Calculation in Progress</span>
      </div>
    );

  return (
    <Button onClick={onApply} tertiary className="tw-space-x-1">
      <BiBarChart />
      <small className="tw-font-semibold">Apply For Credit Limit</small>
    </Button>
  );
};

export default CreditSummary;
