import React from 'react';

import { Loaders } from 'components/cards/Loader';
import { FormFooter } from '..';
import { useReview } from './hooks';
import { ReviewProps } from './Review.types';

const Review = ({ onPrevStep, onFinish }: ReviewProps) => {
  const { payorInfo, handleSubmit, loading } = useReview({ onFinish, onPrevStep });

  return (
    <>
      {loading ? (
        <div className="tw-text-center tw-m-12">
          <Loaders.Spinner />
        </div>
      ) : (
        <div className="tw-px-8 tw-pt-8 tw-mb-8">
          <strong>Please confirm payor details</strong>
          <div className="tw-flex tw-flex-col tw-pt-8 tw-pb-4">
            <small>Payor Legal Name</small>
            <p className="tw-mt-1">{payorInfo.name}</p>
            <small className="tw-mt-4">Email Address</small>
            <p className="tw-mt-1">{payorInfo.email}</p>
            {payorInfo.referenceId && (
              <>
                <small className="tw-mt-4">Payor Nickname ID</small>
                <p className="tw-mt-1">{payorInfo.referenceId}</p>
              </>
            )}
          </div>
        </div>
      )}
      <FormFooter onCancel={onPrevStep} onSubmit={handleSubmit} submitLabel="Add Payor" loading={loading} />
    </>
  );
};

export default Review;
