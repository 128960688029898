import { useContext, useState, useEffect, useCallback } from 'react';

import { AddPayeeContext } from '../AddPayeeContext';
import { stepsDictionary } from '../utils';
import { SELECT_PAYEE_TYPE } from '../constants';

const useAddPayee = () => {
  const { payeeType, setPayeeType, errorSteps } = useContext(AddPayeeContext);

  const [step, setStep] = useState(0);
  const [steps, setSteps] = useState(payeeType ? stepsDictionary[payeeType] : SELECT_PAYEE_TYPE);

  const handlePrevStep = useCallback(() => {
    const prevStep = step - 1;
    setStep(prevStep);

    if (prevStep === 0) {
      setPayeeType(null);
    }
  }, [step, setPayeeType]);

  const handleNextStep = useCallback(() => {
    setStep((prevStep) => prevStep + 1);
  }, []);

  useEffect(() => {
    setSteps(payeeType ? stepsDictionary[payeeType] : SELECT_PAYEE_TYPE);
  }, [payeeType]);

  return { steps, errorSteps, payeeType, step, setStep, handleNextStep, handlePrevStep };
};

export default useAddPayee;
