import { useContext } from 'react';

import useIsAdmin from 'hooks/useIsAdmin';
import useIsMember from 'hooks/useIsMember';
import { ApprovalRequestsContext, FilterRequestsContext } from 'components/Approvals/ApprovalsContent/context';
import { APPROVAL_MODAL, APPROVAL_REQUEST_STATUS } from 'components/Approvals/constants';

const useMultiselectOptions = () => {
  const { isAdmin } = useIsAdmin();
  const { isMember } = useIsMember();

  const { selectedRequests, setActiveModal, openModal } = useContext(ApprovalRequestsContext);
  const { handleUpdateCardExpense } = useContext(FilterRequestsContext);

  const requestIds = selectedRequests.map((request) => request.transactionId);

  const onApprove = () => {
    handleUpdateCardExpense(requestIds, APPROVAL_REQUEST_STATUS.approved);
  };
  const onDecline = () => {
    setActiveModal(APPROVAL_MODAL.declineCardExpense);
    openModal();
  };

  return {
    isAdmin,
    isMember,
    onApprove,
    onDecline,
    hasSelectedRequests: selectedRequests.length > 0,
  };
};
export default useMultiselectOptions;
