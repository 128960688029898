import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_CREDIT_CARDS_FOR_CARD_CREATE } from 'graphql/cards';

const useCreateCardModal = () => {
  const { data: creditCardsData, loading } = useQuery(GET_CREDIT_CARDS_FOR_CARD_CREATE);
  const creditCards = get(creditCardsData, 'me.account.creditCards', []);

  return {
    creditCards,
    loading,
  };
};

export default useCreateCardModal;
