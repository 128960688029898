import React, { useContext } from 'react';
import { Transition } from '@headlessui/react';
import { BiArrowBack } from 'react-icons/bi';

import { CreditCardContext } from 'context/CreditCard';
import CreateCardFlow from 'components/creditCards/createCardFlow';
import { CreateCardContextProvider } from 'components/creditCards/createCardFlow/CreateCardContext';
import CreditCardDetailContainer from 'containers/creditCards/CreditCardDetail';

export const CreditCardDetailOverlay = ({ refetchCreditCards }) => {
  const { creditCard, setCreditCard } = useContext(CreditCardContext);

  const onClose = () => {
    setCreditCard(null);
    refetchCreditCards();
  };

  return (
    <Transition
      show={!!creditCard}
      as="div"
      className="tw-h-full tw-w-full tw-fixed tw-top-0 tw-right-0 tw-px-4 tw-z-10 tw-bg-neutral-light tw-transform tw-overflow-y-auto tw-scrollbar-hide"
      enter="tw-ease-in-out tw-duration-300"
      enterFrom="tw-translate-x-96"
      enterTo="tw-translate-x-0"
      leave="tw-ease-in-out tw-duration-300"
      leaveFrom="tw-translate-x-0"
      leaveTo="tw-translate-x-96"
    >
      <CreditCardDetailContainer
        header={
          <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
            <BiArrowBack size={24} className="tw-cursor-pointer" onClick={onClose} />
          </div>
        }
      />
    </Transition>
  );
};

export const CreateCardOverlay = ({ show, setShow, creditCards, refetchCreditCards }) => {
  const handleFinish = () => setShow(false);

  return (
    <Transition
      show={show}
      as="div"
      className="tw-h-full tw-w-full tw-fixed tw-top-0 tw-right-0 tw-p-4 tw-z-10 tw-bg-neutral-light tw-transform"
      enter="tw-ease-in-out tw-duration-300"
      enterFrom="tw-translate-x-96"
      enterTo="tw-translate-x-0"
      leave="tw-ease-in-out tw-duration-300"
      leaveFrom="tw-translate-x-0"
      leaveTo="tw-translate-x-96"
    >
      <CreateCardContextProvider>
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
          <BiArrowBack size={24} className="tw-cursor-pointer" onClick={handleFinish} />
        </div>
        <CreateCardFlow onFinish={handleFinish} creditCards={creditCards} refetch={refetchCreditCards} />
      </CreateCardContextProvider>
    </Transition>
  );
};
