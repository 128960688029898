import { ApprovalRequestsContext, FilterRequestsContext } from 'components/Approvals/ApprovalsContent/context';
import { APPROVAL_MODAL, APPROVAL_REQUEST_STATUS } from 'components/Approvals/constants';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';

const useCardExpenseDeclineModal = () => {
  const { handleUpdateCardExpense, isUpdatingCardExpense } = useContext(FilterRequestsContext);
  const { selectedRequests, setSelectedRequests, activeModal, isOpenModal, closeModal } =
    useContext(ApprovalRequestsContext);

  const form = useForm();
  const { handleSubmit, register, watch } = form;
  const show = activeModal === APPROVAL_MODAL.declineCardExpense && isOpenModal;
  const declineReasonValue = watch('declineReason');

  const requestIds = selectedRequests.map((request) => request.transactionId);

  const onDecline = () => {
    handleUpdateCardExpense(requestIds, APPROVAL_REQUEST_STATUS.rejected, declineReasonValue);
  };

  const handleClose = () => {
    setSelectedRequests([]);
  };

  return {
    isUpdatingCardExpense,
    onDecline,
    handleClose,
    handleSubmit,
    form,
    register,
    show,
    closeModal,
  };
};

export default useCardExpenseDeclineModal;
