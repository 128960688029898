import React from 'react';

import Layout from '../Layout';
import { AgreementInfo, Form, Header, PromoText } from './components';
import { useSignUp } from './hooks';

const SignUpWithCode = () => {
  useSignUp();

  return (
    <Layout leftSectionText={<PromoText />}>
      <div className="tw-mb-12">
        <Header />
        <Form />
        <AgreementInfo />
      </div>
    </Layout>
  );
};

export default SignUpWithCode;
