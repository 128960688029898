import React from 'react';
import cx from 'classnames';

import { Currencies } from 'constants/currencies';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { BalanceCardProps } from './BalanceCard.types';

const BalanceCard = ({
  currency,
  formattedBalance,
  className = 'tw-bg-secondary-pastel-green-100',
  wrapperClassName = 'tw-w-full',
  children,
  isAddFunds,
  handleAddFunds,
}: BalanceCardProps) => {
  const hasAddFundsFunctionality = isAddFunds && !!handleAddFunds;

  return (
    <div className={cx('card-balance-item tw-flex tw-flex-col tw-items-end', wrapperClassName)}>
      <div
        className={cx(
          'tw-w-full tw-border tw-border-neutral-grey-4 tw-rounded tw-overflow-hidden',
          hasAddFundsFunctionality && 'tw-rounded-br-none'
        )}
      >
        <div
          className={cx(
            'tw-flex tw-items-center tw-gap-1 lg:tw-gap-3 tw-w-full tw-h-12 tw-px-1 md:tw-px-2 lg:tw-px-4',
            className
          )}
        >
          <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-1">
            <div className="tw-rounded-full tw-bg-neutral-light tw-p-1 tw-w-5">
              <MobileCurrencyFlag currency={Currencies[currency]} size={18} />
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-1 tw-w-full tw-text-base md:tw-text-lg xl:tw-text-xl tw-font-bold">
            {formattedBalance}
            <div className="tw-text-sm md:tw-text-base tw-font-normal">{currency}</div>
          </div>
        </div>
        {!!children && (
          <div className="tw-flex tw-flex-col tw-gap-2 tw-text-xs tw-px-1 md:tw-px-2 lg:tw-px-4 tw-py-2 tw-bg-neutral-grey-5">
            {children}
          </div>
        )}
      </div>
      {hasAddFundsFunctionality && (
        <button
          className="tw-flex tw-items-center tw-gap-2 tw-text-primary-dark-green tw-bg-secondary-pastel-yellow-50 tw-rounded tw-rounded-t-none tw-border tw-border-neutral-grey-4 tw-px-3 tw-text-sm tw-font-semibold tw--mt-px"
          onClick={handleAddFunds}
          aria-label="Add funds to balance"
        >
          Add
          <MobileCurrencyFlag currency={Currencies[currency]} size={14} />
        </button>
      )}
    </div>
  );
};

export default BalanceCard;
