import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const AddPerson = ({ width = '150', height = '150' }) => (
  <svg width={width} height={height} viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56.2608 29.5731C56.3446 29.5165 56.445 29.6169 56.3885 29.7008L53.6168 33.8085C53.5959 33.8396 53.5959 33.8803 53.6168 33.9114L56.3885 38.0192C56.445 38.103 56.3446 38.2035 56.2608 38.1469L52.153 35.3753C52.1219 35.3543 52.0812 35.3543 52.0501 35.3753L47.9423 38.1469C47.8585 38.2035 47.7581 38.103 47.8146 38.0192L50.5863 33.9114C50.6073 33.8803 50.6073 33.8396 50.5863 33.8085L47.8146 29.7008C47.7581 29.6169 47.8585 29.5165 47.9423 29.5731L52.0501 32.3447C52.0812 32.3657 52.1219 32.3657 52.153 32.3447L56.2608 29.5731Z"
      fill="#EEA530"
    />
    <path
      d="M29.9669 61.3065C30.0507 61.2499 30.1511 61.3504 30.0946 61.4342L24.2985 70.0244C24.2775 70.0555 24.2775 70.0962 24.2985 70.1273L30.0946 78.7175C30.1511 78.8014 30.0507 78.9018 29.9669 78.8452L21.3766 73.0492C21.3456 73.0282 21.3048 73.0282 21.2737 73.0492L12.6835 78.8452C12.5997 78.9018 12.4993 78.8014 12.5558 78.7175L18.3519 70.1273C18.3729 70.0962 18.3729 70.0555 18.3519 70.0244L12.5558 61.4342C12.4993 61.3504 12.5997 61.2499 12.6835 61.3065L21.2737 67.1026C21.3048 67.1236 21.3456 67.1236 21.3766 67.1026L29.9669 61.3065Z"
      fill={primaryDarkGreenColor}
    />
    <path
      d="M83.3331 15.8885C83.4169 15.832 83.5173 15.9324 83.4608 16.0162L77.6647 24.6065C77.6437 24.6375 77.6437 24.6783 77.6647 24.7093L83.4608 33.2996C83.5173 33.3834 83.4169 33.4838 83.3331 33.4273L74.7429 27.6312C74.7118 27.6102 74.671 27.6102 74.64 27.6312L66.0497 33.4273C65.9659 33.4838 65.8655 33.3834 65.922 33.2996L71.7181 24.7093C71.7391 24.6783 71.7391 24.6375 71.7181 24.6065L65.922 16.0162C65.8655 15.9324 65.9659 15.832 66.0497 15.8885L74.64 21.6846C74.671 21.7056 74.7118 21.7056 74.7429 21.6846L83.3331 15.8885Z"
      fill={primaryDarkGreenColor}
    />
    <circle opacity="0.5" cx="32.7783" cy="44.0591" r="11.3545" fill={primaryDarkGreenColor} />
    <path
      opacity="0.5"
      d="M70.975 51.9714C70.636 56.5441 67.1695 60.2744 63.364 60.2744C59.5584 60.2744 56.0859 56.545 55.7529 51.9714C55.407 47.2145 58.78 43.6685 63.364 43.6685C67.9479 43.6685 71.3209 47.301 70.975 51.9714Z"
      fill="#EEA530"
    />
    <path
      opacity="0.5"
      d="M63.3637 65.8096C55.8391 65.8096 48.2022 69.961 46.7889 77.797C46.6159 78.7414 47.153 79.6478 48.1425 79.6478H78.5858C79.5752 79.6478 80.1097 78.7414 79.9393 77.797C78.5252 69.961 70.8882 65.8096 63.3637 65.8096Z"
      fill="#EEA530"
    />
    <path
      d="M66.433 54.2424C66.094 58.8151 62.6275 62.5454 58.822 62.5454C55.0164 62.5454 51.5439 58.816 51.2109 54.2424C50.865 49.4855 54.238 45.9395 58.822 45.9395C63.4059 45.9395 66.779 49.572 66.433 54.2424Z"
      stroke="#4F5154"
      strokeWidth="0.574331"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.8217 68.0811C51.2971 68.0811 43.6602 72.2325 42.2469 80.0684C42.074 81.0129 42.611 81.9193 43.6005 81.9193H74.0438C75.0332 81.9193 75.5677 81.0129 75.3973 80.0684C73.9832 72.2325 66.3463 68.0811 58.8217 68.0811Z"
      stroke="#4F5154"
      strokeWidth="0.574331"
      strokeMiterlimit="10"
    />
    <path
      d="M39.5904 46.3299C39.5904 40.6881 35.0131 36.1108 29.3714 36.1108C23.7296 36.1108 19.1523 40.6881 19.1523 46.3299C19.1523 51.9716 23.7296 56.5489 29.3714 56.5489C35.0131 56.5489 39.5904 51.9716 39.5904 46.3299Z"
      stroke="#4F5154"
      strokeWidth="0.574331"
      strokeMiterlimit="10"
    />
    <path
      d="M29.3711 42.0718V50.5876"
      stroke="#4F5154"
      strokeWidth="0.574331"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.6291 46.3301H25.1133"
      stroke="#4F5154"
      strokeWidth="0.574331"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.5904 46.3299C39.5904 40.6881 35.0131 36.1108 29.3714 36.1108C23.7296 36.1108 19.1523 40.6881 19.1523 46.3299C19.1523 51.9716 23.7296 56.5489 29.3714 56.5489C35.0131 56.5489 39.5904 51.9716 39.5904 46.3299Z"
      stroke="#4F5154"
      strokeWidth="0.574331"
      strokeMiterlimit="10"
    />
    <path
      d="M29.3711 42.0718V50.5876"
      stroke="#4F5154"
      strokeWidth="0.574331"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.6291 46.3301H25.1133"
      stroke="#4F5154"
      strokeWidth="0.574331"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AddPerson;
