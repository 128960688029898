import React from 'react';

const RepeatPendingOutline = ({ size = 21, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.125 4.92188L15.0938 6.89062L13.125 8.85938"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4375 6.89062H5.90625C5.03681 6.89322 4.20372 7.23976 3.58892 7.85455C2.97413 8.46934 2.6276 9.30243 2.625 10.1719V10.8281"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.875 16.0781L5.90625 14.1094L7.875 12.1406"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5625 14.1094H15.0938C15.9632 14.1068 16.7963 13.7602 17.4111 13.1455C18.0259 12.5307 18.3724 11.6976 18.375 10.8281V10.1719"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 10.5C12.5156 10.5 10.5 12.5156 10.5 15C10.5 17.4844 12.5156 19.5 15 19.5C17.4844 19.5 19.5 17.4844 19.5 15C19.5 12.5156 17.4844 10.5 15 10.5Z"
        fill="currentColor"
      />
      <path d="M15 13V15H17" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default RepeatPendingOutline;
