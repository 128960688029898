import React from 'react';

const CardAndCoins = ({ width = '193', height = '212' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 193 212" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M95.7059 1.21568C99.7621 1.21567 103.049 13.5259 103.049 28.7101C103.049 43.8944 99.7621 56.2046 95.706 56.2046L92.3228 56.2046C89.3335 52.7255 88.3633 41.0491 88.3633 28.7101C88.3633 16.3711 89.3442 4.72361 92.3228 1.21568L95.7059 1.21568Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M99.6639 28.7106C99.6639 13.5259 96.3764 1.21617 92.3212 1.21617C88.2659 1.21617 84.9785 13.5259 84.9785 28.7106C84.9785 43.8954 88.2659 56.2051 92.3212 56.2051C96.3764 56.2051 99.6639 43.8954 99.6639 28.7106Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M98.2766 28.71C98.2766 16.0156 95.5278 5.72475 92.1369 5.72475C88.7459 5.72475 85.9971 16.0156 85.9971 28.71C85.9971 41.4045 88.7459 51.6953 92.1369 51.6953C95.5278 51.6953 98.2766 41.4045 98.2766 28.71Z"
        fill="#8F5CCF"
      />
      <path
        d="M92.1563 5.72603C95.5466 5.72603 98.2961 16.0157 98.2961 28.7113C98.2961 41.4069 95.5466 51.7002 92.1563 51.7002C90.3269 51.7002 87.291 41.0395 87.291 28.3439C87.291 15.6484 90.3197 5.72603 92.1563 5.72603Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M96.9654 37.0951L96.9654 41.752L95.9916 41.752L87.8291 38.9175L87.8291 35.2367C88.8172 35.2367 89.3649 33.9113 89.3649 29.9748L89.3649 28.9591L87.8291 28.8511L87.8291 24.151L89.3542 23.9674L89.3542 21.864C89.3542 16.6958 89.8303 13.4147 91.5273 12.4027L93.3889 12.2658C95.766 12.2658 97.1837 14.8085 97.1837 20.1893L94.7744 20.978L93.8006 20.978C93.6896 19.0224 94.1586 17.0847 93.3889 17.0847C92.3793 17.0847 91.8674 18.691 91.8674 21.864L91.8674 23.9097L92.9808 23.7585L93.9545 23.7585L93.9545 29.2293L92.9808 29.2293L91.8495 29.0924L91.8674 29.8956C91.8674 32.8272 91.5774 34.2174 90.7934 35.2367L90.7934 35.3159L96.9654 37.0951Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M95.9888 37.0951L95.9888 41.752L86.8525 38.9175L86.8525 35.2367C87.8442 35.2367 88.392 33.9113 88.392 29.9748L88.392 28.9591L86.8525 28.8511L86.8525 24.151L88.3776 23.9674L88.3776 21.864C88.3776 15.3812 89.8096 12.2658 92.4088 12.2658C94.7859 12.2658 96.2036 14.8085 96.2036 20.1893L93.7942 20.978C93.7942 18.4569 93.2823 17.0847 92.4088 17.0847C91.4028 17.0847 90.8872 18.691 90.8872 21.864L90.8872 23.9097L92.9744 23.7585L92.9744 29.2293L90.8693 29.0924L90.8872 29.8956C90.8872 32.8272 90.5973 34.2174 89.8132 35.2367L89.8132 35.3159L95.9888 37.0951Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M46.9782 13.7456C51.7019 13.7456 55.5292 28.0815 55.5292 45.7644C55.5292 63.4473 51.7019 77.7832 46.9782 77.7832L43.0342 77.7832C39.553 73.7316 38.4189 60.1338 38.4189 45.7644C38.4189 31.3949 39.5655 17.8307 43.0342 13.7456L46.9782 13.7456Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M51.5892 45.7634C51.5892 28.0799 47.7608 13.7446 43.0383 13.7446C38.3157 13.7446 34.4873 28.0799 34.4873 45.7634C34.4873 63.4469 38.3157 77.7822 43.0383 77.7822C47.7608 77.7822 51.5892 63.4469 51.5892 45.7634Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M49.974 45.7641C49.974 30.9807 46.7728 18.9965 42.8239 18.9965C38.875 18.9965 35.6738 30.9807 35.6738 45.7641C35.6738 60.5475 38.875 72.5317 42.8239 72.5317C46.7728 72.5317 49.974 60.5475 49.974 45.7641Z"
        fill="#8F5CCF"
      />
      <path
        d="M42.8253 18.9967C46.7735 18.9967 49.9754 30.9796 49.9754 45.7643C49.9754 60.549 46.786 72.5361 42.8253 72.5361C40.6949 72.5361 37.1553 60.1212 37.1553 45.3365C37.1553 30.5518 40.7074 18.9967 42.8253 18.9967Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M44.8909 60.8213L44.8909 64.5122L43.6402 64.5122L43.2566 63.9124L43.2566 60.8632C40.5925 60.3767 37.6991 55.5869 37.499 50.0337L38.7498 50.0337L41.4389 50.6544C41.4389 53.9553 42.1226 55.6498 43.2566 56.1699L43.2566 47.6094C41.2346 46.3511 38.9332 44.6735 38.9332 38.9232C38.9332 34.0536 40.6301 30.7528 43.2566 30.2746L43.2566 27.691L43.6402 27.0157L44.8909 27.0157L44.8909 30.3166C47.5175 30.9247 48.7391 33.5461 48.7391 39.4097L46.3251 39.9633L45.0744 39.9633C44.9702 37.6817 45.6998 35.4671 44.8909 34.968L44.8909 42.8741C47.0756 44.0905 49.4187 45.613 49.4187 52.0008C49.4187 56.9542 47.6384 60.2551 44.8909 60.8213ZM44.8909 56.2076C46.1042 55.8134 46.692 54.3789 46.692 52.2944C46.692 50.3399 46.0458 49.4255 44.8909 48.6035L44.8909 56.2076ZM43.2566 41.8675L43.2566 34.8296C42.2852 35.1777 41.6807 36.394 41.6807 38.3485C41.6807 40.3031 42.2268 41.1797 43.2566 41.8759L43.2566 41.8675Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M43.6402 60.8213L43.6402 64.5122L42.0059 63.9124L42.0059 60.8632C39.1583 60.3431 37.499 56.2496 37.499 50.0337L40.1881 50.6544C40.1881 53.9553 40.8719 55.6498 42.0059 56.1699L42.0059 47.6094C39.9838 46.3511 37.6825 44.6735 37.6825 38.9232C37.6825 34.0536 39.3793 30.7528 42.0059 30.2746L42.0059 27.691L43.6402 27.0157L43.6402 30.3166C46.2668 30.9247 47.4883 33.5461 47.4883 39.4097L45.0744 39.9633C45.0744 37.0945 44.5491 35.53 43.6402 34.968L43.6402 42.8741C45.8248 44.0905 48.1679 45.613 48.1679 52.0008C48.1679 56.9542 46.3877 60.2551 43.6402 60.8213ZM43.6402 56.2076C44.8534 55.8134 45.4413 54.3789 45.4413 52.2944C45.4413 50.3399 44.795 49.4255 43.6402 48.6035L43.6402 56.2076ZM42.0059 41.8675L42.0059 34.8296C41.0345 35.1777 40.4299 36.394 40.4299 38.3485C40.4299 40.3031 40.9761 41.1797 42.0059 41.8759L42.0059 41.8675Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M18.5288 62.5144C23.978 62.7774 27.6018 79.5444 26.6213 99.9617C25.6407 120.379 20.4161 136.721 14.9613 136.454L10.4194 136.239C6.62343 131.365 6.07048 115.602 6.86962 99.0111C7.66876 82.4201 9.74815 66.822 13.9821 62.3002L18.5288 62.5144Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M13.9751 62.289C8.52227 62.0232 3.30466 78.3598 2.32125 98.7778C1.33784 119.196 4.96103 135.963 10.4139 136.229C15.8667 136.495 21.0843 120.158 22.0677 99.7403C23.0511 79.3223 19.4279 62.5548 13.9751 62.289Z"
        fill="#D0E7DA"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M13.4353 68.3419C8.87573 68.1196 4.51303 81.777 3.6909 98.8464C2.86878 115.916 5.89855 129.933 10.4581 130.156C15.0176 130.378 19.3803 116.721 20.2025 99.6512C21.0246 82.5818 17.9948 68.5641 13.4353 68.3419Z"
        fill="#8F5CCF"
      />
      <path
        d="M13.4409 68.3418C17.9971 68.5543 21.0259 82.5802 20.2037 99.6472C19.3815 116.714 15.019 130.378 10.4612 130.156C7.99769 130.037 4.59099 115.504 5.42667 98.4302C6.26235 81.3561 10.9917 68.2203 13.4409 68.3418Z"
        fill="#D0E7DA"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M13.5274 116.745L13.3212 121.008L11.8775 120.938L11.4653 120.226L11.6337 116.702C8.58218 115.989 5.50921 110.294 5.57991 103.876L6.99036 103.952L10.0674 104.818C9.88359 108.63 10.5845 110.63 11.8627 111.289L12.3372 101.41C10.0736 99.8266 7.50826 97.7531 7.82825 91.1305C8.10138 85.5138 10.2408 81.7938 13.301 81.3891L13.4488 78.4142L13.9278 77.6547L15.3715 77.7251L15.1877 81.537C18.1887 82.3863 19.4537 85.4811 19.1245 92.2529L16.3061 92.7297L14.8798 92.676C14.8839 90.04 15.8461 87.5222 14.9451 86.8978L14.5162 96.0267C16.9699 97.5782 19.5881 99.4412 19.2518 106.814C18.9432 112.54 16.7047 116.252 13.5274 116.745ZM13.7819 111.427C15.1846 111.047 15.9587 109.421 16.074 107.012C16.1857 104.756 15.4908 103.669 14.2058 102.648L13.7819 111.427ZM12.6905 94.782L13.0812 86.6527C11.9415 86.9979 11.1742 88.3716 11.0673 90.6267C10.9605 92.8818 11.5158 93.9236 12.6667 94.786L12.6905 94.782Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M12.0502 116.679L11.844 120.943L9.98808 120.16L10.1565 116.636C6.89655 115.875 5.21062 111.052 5.57972 103.875L8.65678 104.741C8.47295 108.553 9.1739 110.553 10.4521 111.212L10.9258 101.328C8.66221 99.7446 6.09762 97.676 6.41762 91.0533C6.69074 85.4366 8.83016 81.7166 11.8903 81.312L12.0381 78.337L13.9609 77.6479L13.7771 81.4598C16.778 82.3091 18.0431 85.404 17.7138 92.1757L14.8954 92.6525C15.0554 89.3412 14.5243 87.5087 13.517 86.8039L13.0881 95.9328C15.5466 97.4835 18.1648 99.3465 17.8237 106.72C17.4992 112.468 15.2608 116.181 12.0502 116.679ZM12.3047 111.361C13.7074 110.981 14.4815 109.355 14.5969 106.946C14.7085 104.69 14.0136 103.603 12.7286 102.582L12.3047 111.361ZM11.2133 94.716L11.604 86.5867C10.4644 86.932 9.69701 88.3057 9.59016 90.5608C9.4833 92.8159 10.0719 93.8521 11.2228 94.7144L11.2133 94.716Z"
        fill="#D0E7DA"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M107.626 8.82364L137.259 77.5409C138.267 79.8818 138.355 82.5206 137.503 84.9239L93.4095 209.511C93.2605 209.947 92.9791 210.326 92.6051 210.592C92.2311 210.859 91.7832 211.002 91.3246 210.999C90.4962 210.999 88.1667 210.999 88.1667 210.999L59.512 140.626C58.501 138.279 58.4155 135.634 59.273 133.226L102.477 7.48688C102.477 7.48688 104.634 7.52044 105.463 7.48688C105.917 7.45623 106.369 7.56847 106.756 7.80801C107.144 8.04755 107.448 8.40251 107.626 8.82364Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M104.292 8.82439L133.925 77.5417C134.933 79.8826 135.021 82.5213 134.169 84.9246L90.0755 209.512C89.926 209.935 89.6533 210.303 89.2928 210.568C88.9324 210.834 88.5009 210.984 88.0546 210.999C87.6083 211.014 87.1678 210.893 86.7905 210.653C86.4132 210.413 86.1165 210.064 85.9391 209.652L56.178 140.627C55.167 138.28 55.0815 135.634 55.939 133.227L100.161 8.95863C100.312 8.53728 100.585 8.17126 100.945 7.90793C101.305 7.6446 101.736 7.49609 102.181 7.48163C102.626 7.46717 103.065 7.58742 103.441 7.82682C103.818 8.06622 104.114 8.41373 104.292 8.82439Z"
        fill="#EED9F7"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M122.603 116.093C115.931 113.654 109.546 121.614 83.8827 107.648C69.3199 101.098 77.5436 87.6539 88.3373 93.6865C124.197 108.486 120.009 133.199 95.4408 130.526C86.3897 129.541 60.9248 120.402 60.9248 120.402"
        stroke="#FFE8C3"
        strokeWidth="10"
        strokeMiterlimit="10"
      />
      <path
        d="M115.544 58.7766L117.74 58.8717L118.902 60.7398L119.669 58.6759L121.799 58.139L120.081 56.763L120.231 54.5649L118.402 55.7842L116.367 54.9565L116.956 57.0874L115.544 58.7766Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M113.253 65.1189L115.443 65.2139L116.605 67.082L117.373 65.0126L119.502 64.4757L117.784 63.1053L117.934 60.9072L116.105 62.1265L114.07 61.2987L114.66 63.4241L113.253 65.1189Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M110.958 71.4618L113.149 71.5569L114.316 73.425L115.083 71.3556L117.207 70.8186L115.489 69.4483L115.639 67.2446L113.816 68.4639L111.781 67.6417L112.365 69.7671L110.958 71.4618Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M108.666 77.8041L110.857 77.8992L112.019 79.7673L112.786 77.6979L114.915 77.1609L113.197 75.7906L113.347 73.5869L111.518 74.8062L109.483 73.984L110.073 76.1094L108.666 77.8041Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M104.075 90.4836L106.266 90.5787L107.433 92.4524L108.2 90.3829L110.33 89.846L108.606 88.4757L108.762 86.272L106.933 87.4913L104.898 86.6691L105.482 88.7945L104.075 90.4836Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M101.784 96.8269L103.975 96.9219L105.137 98.79L105.904 96.7262L108.033 96.1892L106.315 94.8133L106.465 92.6152L104.636 93.8345L102.601 93.0123L103.191 95.1377L101.784 96.8269Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M99.4873 103.17L101.678 103.265L102.845 105.133L103.613 103.069L105.736 102.532L104.018 101.156L104.169 98.958L102.339 100.177L100.305 99.3495L100.894 101.475L99.4873 103.17Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M97.1924 109.512L99.3884 109.607L100.55 111.475L101.318 109.411L103.447 108.869L101.729 107.498L101.879 105.3L100.05 106.52L98.0152 105.692L98.6045 107.817L97.1924 109.512Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M92.6055 122.197L94.796 122.292L95.9635 124.16L96.7308 122.091L98.8546 121.554L97.1366 120.184L97.2867 117.98L95.4631 119.199L93.4283 118.377L94.0121 120.502L92.6055 122.197Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M90.3145 128.534L92.505 128.629L93.667 130.503L94.4342 128.434L96.5636 127.897L94.8456 126.526L94.9957 124.323L93.1666 125.542L91.1317 124.72L91.7211 126.845L90.3145 128.534Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M88.0186 134.877L90.2091 134.972L91.3766 136.845L92.1383 134.776L94.2676 134.239L92.5497 132.869L92.6998 130.665L90.8707 131.884L88.8358 131.062L89.4252 133.188L88.0186 134.877Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M85.7227 141.22L87.9132 141.315L89.0807 143.183L89.8479 141.119L91.9717 140.582L90.2538 139.206L90.4039 137.008L88.5803 138.228L86.5455 137.405L87.1293 139.531L85.7227 141.22Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M81.1367 153.904L83.3272 153.999L84.4948 155.867L85.262 153.804L87.3858 153.267L85.6679 151.891L85.818 149.687L83.9888 150.906L81.954 150.084L82.5433 152.21L81.1367 153.904Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M78.8379 160.247L81.0284 160.343L82.1959 162.211L82.9632 160.141L85.0925 159.604L83.3746 158.234L83.5247 156.03L81.6956 157.25L79.6607 156.427L80.2501 158.553L78.8379 160.247Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M76.5488 166.59L78.7394 166.679L79.9013 168.553L80.6686 166.484L82.7979 165.947L81.08 164.576L81.2301 162.373L79.4009 163.592L77.3661 162.77L77.9554 164.895L76.5488 166.59Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M74.2539 172.927L76.4444 173.022L77.612 174.896L78.3792 172.826L80.503 172.289L78.785 170.919L78.9352 168.715L77.1116 169.935L75.0767 169.112L75.6605 171.238L74.2539 172.927Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M102.402 23.9366L109.941 41.4208C110.204 42.037 110.351 42.697 110.374 43.3672C110.396 44.0429 110.291 44.7166 110.063 45.3528L103.241 64.5428C103.185 64.702 103.082 64.8405 102.946 64.9402C102.81 65.0399 102.648 65.0963 102.48 65.1021C102.311 65.1074 102.145 65.0618 102.002 64.971C101.859 64.8803 101.747 64.7487 101.679 64.5931L94.1402 47.081C93.8637 46.4402 93.7054 45.7541 93.6732 45.0563C93.6434 44.4058 93.7416 43.7557 93.9623 43.1435L100.84 23.987C100.896 23.8277 100.999 23.6893 101.135 23.5896C101.271 23.4898 101.433 23.4334 101.601 23.4277C101.77 23.4224 101.936 23.468 102.079 23.5587C102.222 23.6494 102.334 23.7811 102.402 23.9366Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M91.6382 166.378L92.7168 169.107C92.9551 169.706 93.0887 170.342 93.1115 170.986C93.1378 171.687 93.0302 172.385 92.7946 173.045L85.9784 192.218C85.9238 192.376 85.8229 192.514 85.689 192.614C85.5551 192.714 85.3944 192.771 85.2279 192.777C85.0585 192.782 84.8917 192.735 84.7497 192.642C84.6077 192.549 84.4972 192.415 84.4328 192.257L83.3209 189.511C83.0741 188.888 82.9332 188.228 82.9039 187.559C82.8697 186.878 82.9718 186.197 83.2042 185.557L90.0704 166.411C90.1251 166.253 90.2259 166.115 90.3598 166.015C90.4937 165.915 90.6544 165.858 90.8209 165.852C90.9948 165.842 91.1673 165.887 91.3141 165.982C91.4608 166.076 91.5742 166.215 91.6382 166.378Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M147.128 14.4408C151.749 14.4408 155.497 28.4652 155.497 45.7639C155.497 63.0625 151.749 77.0869 147.128 77.0869L143.278 77.0869C139.872 73.1233 138.763 59.8211 138.763 45.7639C138.763 31.7067 139.884 18.4372 143.278 14.4408L147.128 14.4408Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M151.64 45.7639C151.64 28.4646 147.895 14.4408 143.275 14.4408C138.655 14.4408 134.91 28.4646 134.91 45.7639C134.91 63.0631 138.655 77.0869 143.275 77.0869C147.895 77.0869 151.64 63.0631 151.64 45.7639Z"
        fill="#EED9F7"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M150.059 45.7633C150.059 31.3011 146.927 19.5773 143.064 19.5773C139.201 19.5773 136.069 31.3011 136.069 45.7633C136.069 60.2254 139.201 71.9492 143.064 71.9492C146.927 71.9492 150.059 60.2254 150.059 45.7633Z"
        fill="#8F5CCF"
      />
      <path
        d="M143.063 19.5781C146.93 19.5781 150.058 31.3006 150.058 45.764C150.058 60.2274 146.93 71.9541 143.063 71.9541C140.979 71.9541 137.521 59.8089 137.521 45.3455C137.521 30.8821 140.991 19.5781 143.063 19.5781Z"
        fill="#EED9F7"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M138.546 41.365L137.322 41.365C137.604 37.0116 141.87 31.5873 141.87 31.5873L143.407 31.5873C146.32 31.5873 148.135 35.3088 148.595 42.1569L149.627 41.9107L149.546 44.7829L148.73 44.8936L148.73 46.9083L149.652 47.0601L149.427 49.5219L148.551 49.4276C148.094 56.4808 146.34 60.5757 143.35 60.5757L142.127 60.5757C140.344 59.7181 137.714 54.856 137.322 51.118L138.546 51.118L141.246 51.4258C141.438 54.0518 142.164 55.693 143.35 55.693C144.709 55.693 145.647 53.0219 145.953 49.046L141.817 48.3402L140.752 46.4692L141.976 46.4692L146.014 46.7318L146.014 44.9265L142.106 45.0906L140.883 45.0906L141.988 43.0965L145.895 42.4031C145.59 38.6282 144.709 36.4741 143.407 36.4741C142.221 36.4741 141.438 38.1564 141.246 40.8644L138.546 41.365Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M137.322 41.365C137.551 35.6206 139.39 31.5873 142.184 31.5873C145.096 31.5873 146.911 35.3088 147.372 42.1569L148.404 41.9107L148.322 44.7829L147.506 44.8936L147.506 46.9083L148.428 47.0601L148.204 49.5219L147.327 49.4276C146.87 56.4808 145.116 60.5757 142.127 60.5757C139.272 60.5757 137.494 56.7803 137.322 51.118L140.022 51.4258C140.214 54.0518 140.94 55.693 142.127 55.693C143.485 55.693 144.423 53.0219 144.729 49.046L140.593 48.3402L140.752 46.4692L144.79 46.7318L144.79 44.9265L140.883 45.0906L140.765 43.0965L144.672 42.4031C144.366 38.6282 143.485 36.4741 142.184 36.4741C140.997 36.4741 140.214 38.1564 140.022 40.8644L137.322 41.365Z"
        fill="#EED9F7"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M179.649 61.078C184.886 61.078 189.129 76.9721 189.129 96.5771C189.129 116.182 184.886 132.076 179.649 132.076L175.276 132.076C171.416 127.584 170.159 112.508 170.159 96.5771C170.159 80.6457 171.43 65.6072 175.276 61.078L179.649 61.078Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M184.764 96.5766C184.764 76.971 180.52 61.0775 175.284 61.0775C170.048 61.0775 165.804 76.971 165.804 96.5766C165.804 116.182 170.048 132.076 175.284 132.076C180.52 132.076 184.764 116.182 184.764 96.5766Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M182.975 96.5763C182.975 80.1861 179.426 66.8991 175.047 66.8991C170.669 66.8991 167.12 80.1861 167.12 96.5763C167.12 112.967 170.669 126.253 175.047 126.253C179.426 126.253 182.975 112.967 182.975 96.5763Z"
        fill="#8F5CCF"
      />
      <path
        d="M175.047 66.8999C179.424 66.8999 182.974 80.1853 182.974 96.577C182.974 112.969 179.438 126.259 175.047 126.259C172.685 126.259 168.761 112.494 168.761 96.1027C168.761 79.711 172.699 66.8999 175.047 66.8999Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M177.339 113.271L177.339 117.363L175.952 117.363L175.527 116.698L175.527 113.318C172.573 112.778 169.365 107.468 169.144 101.311L170.53 101.311L173.512 101.999C173.512 105.659 174.27 107.538 175.527 108.114L175.527 98.6233C173.285 97.2282 170.734 95.3682 170.734 88.9928C170.734 83.594 172.615 79.9344 175.527 79.4043L175.527 76.5398L175.952 75.7911L177.339 75.7911L177.339 79.4508C180.251 80.125 181.605 83.0314 181.605 89.5323L178.929 90.1461L177.542 90.1461C177.427 87.6164 178.236 85.1611 177.339 84.6078L177.339 93.3733C179.761 94.7218 182.359 96.4098 182.359 103.492C182.359 108.984 180.385 112.643 177.339 113.271ZM177.339 108.156C178.684 107.719 179.336 106.129 179.336 103.817C179.336 101.65 178.619 100.637 177.339 99.7253L177.339 108.156ZM175.527 92.2572L175.527 84.4543C174.45 84.8403 173.78 86.1888 173.78 88.3558C173.78 90.5227 174.385 91.4946 175.527 92.2665L175.527 92.2572Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M175.952 113.271L175.952 117.363L174.14 116.698L174.14 113.318C170.983 112.741 169.144 108.203 169.144 101.311L172.125 101.999C172.125 105.659 172.883 107.538 174.14 108.114L174.14 98.6233C171.898 97.2282 169.347 95.3682 169.347 88.9928C169.347 83.594 171.228 79.9344 174.14 79.4043L174.14 76.5398L175.952 75.7911L175.952 79.4508C178.864 80.125 180.219 83.0314 180.219 89.5323L177.542 90.1461C177.542 86.9654 176.96 85.2309 175.952 84.6078L175.952 93.3733C178.374 94.7218 180.972 96.4098 180.972 103.492C180.972 108.984 178.998 112.643 175.952 113.271ZM175.952 108.156C177.297 107.719 177.949 106.129 177.949 103.817C177.949 101.65 177.233 100.637 175.952 99.7253L175.952 108.156ZM174.14 92.2572L174.14 84.4543C173.063 84.8403 172.393 86.1888 172.393 88.3558C172.393 90.5227 172.999 91.4946 174.14 92.2665L174.14 92.2572Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M138.277 100.057C144.644 100.057 149.802 119.379 149.802 143.212C149.802 167.046 144.644 186.368 138.277 186.368L132.967 186.368C128.275 180.907 126.752 162.58 126.752 143.212C126.752 123.845 128.292 105.563 132.967 100.057L138.277 100.057Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M144.488 143.213C144.488 119.379 139.328 100.058 132.963 100.058C126.597 100.058 121.437 119.379 121.437 143.213C121.437 167.048 126.597 186.369 132.963 186.369C139.328 186.369 144.488 167.048 144.488 143.213Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M142.311 143.212C142.311 123.287 137.997 107.134 132.674 107.134C127.352 107.134 123.037 123.287 123.037 143.212C123.037 163.138 127.352 179.291 132.674 179.291C137.997 179.291 142.311 163.138 142.311 143.212Z"
        fill="#8F5CCF"
      />
      <path
        d="M132.704 107.136C138.025 107.136 142.341 123.287 142.341 143.215C142.341 163.142 138.025 179.298 132.704 179.298C129.833 179.298 125.067 162.565 125.067 142.638C125.067 122.711 129.821 107.136 132.704 107.136Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M140.253 156.374L140.253 163.683L138.724 163.683L125.912 159.234L125.912 153.457C127.463 153.457 128.323 151.376 128.323 145.197L128.323 143.603L125.912 143.434L125.912 136.056L128.306 135.768L128.306 132.467C128.306 124.354 129.053 119.205 131.717 117.616L134.639 117.401C138.37 117.401 140.595 121.392 140.595 129.838L136.814 131.076L135.285 131.076C135.111 128.006 135.847 124.965 134.639 124.965C133.054 124.965 132.251 127.486 132.251 132.467L132.251 135.678L133.998 135.44L135.527 135.44L135.527 144.027L133.998 144.027L132.223 143.812L132.251 145.073C132.251 149.675 131.796 151.857 130.565 153.457L130.565 153.581L140.253 156.374Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M138.723 156.374L138.723 163.683L124.383 159.234L124.383 153.457C125.939 153.457 126.799 151.376 126.799 145.197L126.799 143.603L124.383 143.434L124.383 136.056L126.777 135.768L126.777 132.467C126.777 122.291 129.024 117.401 133.104 117.401C136.835 117.401 139.06 121.392 139.06 129.838L135.279 131.076C135.279 127.119 134.475 124.965 133.104 124.965C131.525 124.965 130.716 127.486 130.716 132.467L130.716 135.678L133.992 135.44L133.992 144.027L130.688 143.812L130.716 145.073C130.716 149.675 130.261 151.857 129.03 153.457L129.03 153.581L138.723 156.374Z"
        fill="#FFE8C3"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M61.2087 100.931C67.0019 100.875 71.8674 118.4 72.0759 140.08C72.2845 161.761 67.784 179.376 61.9756 179.433L57.1489 179.485C52.8302 174.553 51.2777 157.902 51.1092 140.289C50.9407 122.675 52.1806 106.025 56.382 100.982L61.2087 100.931Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M56.392 100.967C50.6026 101.024 46.0794 118.643 46.2891 140.321C46.4988 161.999 51.362 179.527 57.1513 179.47C62.9406 179.414 67.4638 161.794 67.2541 140.116C67.0445 118.438 62.1813 100.911 56.392 100.967Z"
        fill="#D0E7F4"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M56.185 107.411C51.3441 107.458 47.5618 122.188 47.7371 140.311C47.9124 158.433 51.9788 173.086 56.8197 173.039C61.6606 172.992 65.4429 158.262 65.2676 140.139C65.0923 122.016 61.0259 107.363 56.185 107.411Z"
        fill="#8F5CCF"
      />
      <path
        d="M56.1819 107.418C61.0246 107.368 65.0915 122.025 65.2701 140.147C65.4488 158.269 61.6423 173.004 56.8249 173.049C54.2127 173.077 49.7257 157.903 49.5521 139.78C49.3785 121.658 53.5849 107.443 56.1819 107.418Z"
        fill="#D0E7F4"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M63.5084 152.132L63.5742 158.781L62.1858 158.797L50.4925 154.877L50.442 149.62C51.8565 149.605 52.6198 147.724 52.5633 142.089L52.5495 140.639L50.3521 140.505L50.2871 133.794L52.4622 133.511L52.4495 130.506C52.3612 123.133 53.014 118.455 55.42 116.971L58.0751 116.749C61.4671 116.715 63.5316 120.325 63.605 128.007L60.1628 129.172L58.7736 129.183C58.5916 126.392 59.2246 123.62 58.1346 123.627C56.694 123.641 55.9849 125.945 56.0281 130.475L56.0588 133.394L57.6401 133.15L59.0286 133.134L59.103 140.925L57.7145 140.941L56.0993 140.758L56.1339 141.903C56.1765 146.089 55.7796 148.101 54.7112 149.54L54.7282 149.652L63.5084 152.132Z"
        fill="#8F5CCF"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
      <path
        d="M62.114 152.123L62.1807 158.776L49.0966 154.857L49.0461 149.6C50.4614 149.59 51.2239 147.704 51.1675 142.069L51.1537 140.62L48.9563 140.485L48.892 133.779L51.0663 133.491L51.0544 130.492C50.9677 121.261 52.9467 116.769 56.6792 116.729C60.0712 116.696 62.1358 120.305 62.2091 127.987L58.7669 129.152C58.7344 125.542 57.9834 123.598 56.7395 123.613C55.2989 123.626 54.589 125.926 54.6322 130.455L54.6629 133.374L57.6358 133.134L57.7102 140.926L54.7197 140.778L54.7543 141.922C54.7962 146.103 54.3992 148.115 53.3316 149.559L53.3486 149.671L62.114 152.123Z"
        fill="#D0E7F4"
        stroke="#8F5CCF"
        strokeWidth="1.29067"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default CardAndCoins;
