import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_CREDIT_CARDS_LIST } from 'graphql/cards';
import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { getIdFromApolloGlobalId } from 'utility/apollo';
import { Option } from 'components/UI/SearchDropdown/v2/SearchDropdown.types';
import { CardProcessor, CreditCard, CreditCardStatus } from 'types/creditCard';
import { getCreditCardLabel } from 'utility/creditCards';
import {
  getDetailedCreditCardLabel,
  customCreditCardOptionLabel,
  getCreditCardLabelForSorting,
} from '../CardsSearch.utils';

const useCardsSearch = () => {
  const history = useHistory();

  const { creditCardDetails } = useContext(CardDetailsContext);
  const { id } = creditCardDetails || {};

  const [creditCards, setCreditCards] = useState<CreditCard[]>([]);
  const [cardOptions, setCardOptions] = useState<Option[]>([]);
  const [selectedCard, setSelectedCard] = useState<Option[]>([]);

  const { loading: isCardsListLoading, error: cardsListError } = useQuery<{ creditCards: CreditCard[] }>(
    GET_CREDIT_CARDS_LIST,
    {
      onCompleted: (data) => {
        const creditCards = data?.creditCards;

        if (!creditCards) return;

        setCreditCards(creditCards);
      },
    }
  );

  const sortedCardOptions = useMemo(
    () =>
      cardOptions.sort((a, b) => {
        const cardA = creditCards.find(({ id }) => id === a.value);
        const cardB = creditCards.find(({ id }) => id === b.value);

        if (!cardA || !cardB) return 0;

        const { labelA, labelB } = getCreditCardLabelForSorting({ cardA, cardB });

        if (!labelA.trim() || !labelB.trim()) return -1;

        return labelA.localeCompare(labelB);
      }),
    [cardOptions, creditCards]
  );

  const onSelectedCardsChange = useCallback((options: Option[]) => {
    setSelectedCard(options);

    const selectedCard = options?.[0]; // only one card can be selected

    if (!selectedCard) return;

    const creditCardId = getIdFromApolloGlobalId({ gid: selectedCard.value });
    history.push(`/dashboard/cards/list/${creditCardId}`);
  }, []);

  const handleCustomOptionLabel = useCallback(
    (label: string) => {
      const currentCreditCardId = cardOptions.find((option) => option.label === label)?.value;
      const currentCreditCard = creditCards.find(({ id }) => id === currentCreditCardId);

      if (!currentCreditCardId || !currentCreditCard) return label;

      const { displayName, lastFourDigits, virtual: isVirtual, cardProcessor, status, contacts } = currentCreditCard;

      const isActive = status === CreditCardStatus.active;
      const isSelected = currentCreditCardId === id;
      const isVisa = cardProcessor === CardProcessor.lithic;

      const cardLabel = getDetailedCreditCardLabel({
        displayName,
        lastFourDigits,
        isVirtual,
        cardHolder: contacts?.[0],
      });

      return customCreditCardOptionLabel({ label: cardLabel, isVirtual, isActive, isVisa, isSelected });
    },
    [creditCards, cardOptions, id]
  );

  const handleCustomSelectedOptionLabel = useCallback(
    (value: string) => {
      const currentCreditCard = creditCards.find(({ id }) => id === value);

      if (!currentCreditCard) return '';

      const { displayName, lastFourDigits } = currentCreditCard;

      return getCreditCardLabel({ displayName, lastFourDigits });
    },
    [creditCards, cardOptions, id]
  );

  // set card options
  useEffect(() => {
    if (!creditCards.length) return;

    const options = creditCards.map((card) => {
      const { id, displayName, lastFourDigits, virtual: isVirtual, contacts } = card;
      const label = getDetailedCreditCardLabel({ displayName, lastFourDigits, isVirtual, cardHolder: contacts?.[0] });

      return {
        label,
        value: id,
      };
    });

    setCardOptions(options);
  }, [creditCards]);

  // set initial selected card
  useEffect(() => {
    if (!id || !cardOptions.length) return;

    const currentSelectedOption = cardOptions.find((option) => option.value === id);
    if (currentSelectedOption) setSelectedCard([currentSelectedOption]);
  }, [cardOptions, id]);

  return {
    optionsList: sortedCardOptions,
    selectedCard,
    onSelectedCardsChange,
    isLoading: isCardsListLoading,
    isError: !!cardsListError,
    handleCustomOptionLabel,
    handleCustomSelectedOptionLabel,
  };
};

export default useCardsSearch;
