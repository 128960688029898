import React from 'react';

import BrokenClock from 'components/svg/BrokenClock';

const Error = () => (
  <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 tw-max-w-md tw-m-auto tw-py-16">
    <BrokenClock />
    <div className="tw-font-bold tw-text-lg">You’re almost there!</div>
    <div className="tw-text-center">
      Finish opening an account later so you can send and receive payments like a local. Use account details to get paid
      from your customer, payment processors or marketplaces like Amazon, Shopify, Stripe and Paypal.
    </div>
  </div>
);

export default Error;
