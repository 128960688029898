import React, { useState, useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import config from 'config';
import Modal from 'components/Modal/v2/index.js';
import BankAccountConnection from 'components/BankAccountConnection';
import Close from 'components/svg/Close';
import Remove from 'components/svg/Remove';
import Button from 'components/Button';
import { Currencies } from 'constants/currencies';
import { defaultBankImage, bankImages } from 'constants/index';
import { toast } from 'react-toastify';

import { encrypt } from 'utility/encryption';
import SecurityQuestionForm from './SecurityQuestionForm';
import { GET_BANK_ACCOUNTS, VERIFY_BANK_ACCOUNT_DEPOSIT } from 'graphql/integrations';
import { GET_WALLETS } from 'graphql/wallets';
import { MoneyInputField, SubmitButton } from 'components/FormFields/v2';
import { formatMoneyV2, centsFromMoneyString } from 'utility/currency';
import { useQuery, useMutation } from '@apollo/client';

const DisconnectModal = (props) => {
  const { show, reload, onClose, onDisconnect } = props;

  const onConfirmDisconnect = () => {
    onDisconnect();
    reload();
    onClose();
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-flex-col tw-justify-center">
        <div className="tw-flex tw-justify-between tw-p-6">
          <div>Disconnect</div>
          <Button onClick={() => onClose()}>
            <Close />
          </Button>
        </div>
        <hr className="tw-bg-neutral-grey-4" />
        <div className="tw-flex tw-flex-col tw-py-8 tw-px-12 tw-items-center">
          <Remove width="240" heigth="236" />
          <span className="tw-text-2xl tw-my-4">Are You Sure You Want To Disconnect This Bank Account?</span>
        </div>
        <hr className="tw-bg-neutral-grey-4" />
        <div>
          <div className="tw-flex tw-flex-row tw-justify-between tw-mx-20">
            <div className="tw-py-4">
              <Button
                onClick={onClose}
                className="tw-text-primary-dark-green tw-rounded-md tw-py-3 tw-px-4 tw-flex tw-flex-row tw-items-center"
              >
                <span className="tw-pr-2">Keep Connected</span>
              </Button>
            </div>

            <div className="tw-py-4">
              <Button
                onClick={onConfirmDisconnect}
                className="tw-bg-semantic-error tw-text-neutral-light tw-rounded-md tw-py-3 tw-px-4 tw-flex tw-flex-row tw-items-center"
              >
                <span className="tw-pr-2">Disconnect</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Form = ({ currency, setRetries, onClose, setDisabledState, id }) => {
  const form = useForm();
  const { register, handleSubmit } = form;
  const [formattedAmountOne, setFormattedAmountOne] = useState(formatMoneyV2({ amount: 0, currency }));
  const [formattedAmountTwo, setFormattedAmountTwo] = useState(formatMoneyV2({ amount: 0, currency }));

  const [verifyBankAccountDeposit, { loading: verifyingBankAccountDeposit, error }] = useMutation(
    VERIFY_BANK_ACCOUNT_DEPOSIT,
    {
      refetchQueries: [{ query: GET_BANK_ACCOUNTS }],
      awaitRefetchQueries: true,
    }
  );
  const onSubmitVerified = () => {
    toast.success('Bank account was successfully verified.');
    onClose();
  };
  const onSubmitDisabled = () => {
    toast.error('Maximum retries reached.');
    setDisabledState(true);
  };
  const onSubmitUnverified = (retries) => {
    toast.warning(`Unable to validate amounts. ${retries} attempts remaining.`);
    setRetries(retries);
  };

  const onSubmitError = () => {
    toast.error('Could not verify bank account, please try and submit again.');
  };

  const onSubmit = async () => {
    try {
      const result = await verifyBankAccountDeposit({
        variables: {
          bankAccountId: id,
          amount1: centsFromMoneyString(formattedAmountOne),
          amount2: centsFromMoneyString(formattedAmountTwo),
        },
      });

      if (result?.data?.verifyBankAccountDeposit) {
        const { verifyBankAccountDeposit } = result.data;
        if (verifyBankAccountDeposit.verified) {
          onSubmitVerified();
        } else if (verifyBankAccountDeposit.remainingAttempts === 0) {
          onSubmitDisabled();
        } else {
          onSubmitUnverified(verifyBankAccountDeposit.remainingAttempts);
        }
      }
    } catch (err) {
      console.error(err);
      onSubmitError();
    }
  };

  return (
    <FormProvider {...form} graphqlErrors={error && error.graphqlErrors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-container tw-px-0">
          <div className="tw-flex tw-flex-col tw-space-y-5">
            <MoneyInputField
              ref={register({ required: true })}
              name="amount1"
              label="Deposit One"
              currency={currency}
              value={formattedAmountOne}
              setValue={setFormattedAmountOne}
              moneyFormatter={formatMoneyV2}
              rootClass="tw-flex-grow tw-mr-4"
            />
          </div>
          <div className="tw-flex tw-flex-col tw-space-y-5">
            <MoneyInputField
              ref={register({ required: true })}
              name="amount2"
              label="Deposit Two"
              currency={currency}
              value={formattedAmountTwo}
              setValue={setFormattedAmountTwo}
              moneyFormatter={formatMoneyV2}
              rootClass="tw-flex-grow tw-mr-4"
            />
          </div>
        </div>
        <SubmitButton className="tw-w-full tw-mt-8" disabled={verifyingBankAccountDeposit}>
          {verifyingBankAccountDeposit ? 'Submitting...' : 'Submit'}
        </SubmitButton>
      </form>
    </FormProvider>
  );
};

const DepositVerificationModal = (props) => {
  const { show, onClose, currency, id, depositVerificationStatus } = props;
  const [retryAttempts, setRetries] = useState(null);
  const [disabledState, setDisabledState] = useState(false);

  useEffect(() => {
    if (depositVerificationStatus === 'disabled') {
      setDisabledState(true);
    }
  }, [depositVerificationStatus]);
  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-flex-col tw-justify-center">
        <div className="tw-flex tw-justify-between tw-p-6">
          <div>Verify Your Bank Account</div>
          <Button onClick={() => onClose()}>
            <Close />
          </Button>
        </div>
        <div className="tw-flex tw-flex-col tw-px-12 tw-items-center" role="add-modal-verification">
          <div>
            Please enter the amounts of the two bank deposits you received from Loop Card. It does not matter in which
            order you enter the deposits:
          </div>
          <br />
          {disabledState && (
            <div>
              <p className="tw-text-semantic-error">
                Maxmium number of attempts has been exceeded. Please contact{' '}
                <a
                  className="tw-font-semibold tw-text-semantic-error"
                  target="_blank"
                  href={`mailto:${config.supportEmail}`}
                  rel="noreferrer"
                >
                  {config.supportEmail}
                </a>{' '}
                for assistance
              </p>
            </div>
          )}
          {!disabledState && (
            <Form
              id={id}
              currency={currency}
              setRetries={setRetries}
              onClose={onClose}
              setDisabledState={setDisabledState}
            />
          )}
          {!disabledState && retryAttempts && (
            <div>
              <p className="tw-text-semantic-error">Deposit amounts incorrect {retryAttempts} attempts remaining.</p>
            </div>
          )}
        </div>
      </div>
      <div className="tw-my-6 tw-px-12">
        <span>
          If you need any assistance please reach out to{' '}
          <a
            className="tw-font-semibold tw-text-primary-dark-green"
            target="_blank"
            href={`mailto:${config.supportEmail}`}
            rel="noreferrer"
          >
            {config.supportEmail}
          </a>{' '}
        </span>
      </div>
    </Modal>
  );
};

const AddConnectionModal = ({ show, onClose, allowBankAccountCreation }) => {
  const { data: walletsData } = useQuery(GET_WALLETS);
  const wallets = get(walletsData, 'wallets', []);

  const hasUSDAccount = useMemo(
    () => !!wallets?.find((w) => w.currency === Currencies.USD)?.externalAccountActive,
    [wallets]
  );

  return (
    <Modal show={show} onClose={onClose} fitContent>
      <div className="tw-flex tw-flex-col tw-justify-center">
        <div className="tw-flex tw-justify-between tw-p-6">
          <div>Add Bank Account</div>
          <Button onClick={() => onClose()}>
            <Close />
          </Button>
        </div>
        <hr className="tw-bg-neutral-grey-4" />
        <div className="tw-flex tw-flex-col tw-py-2 tw-px-12 tw-items-center" role="add-modal-integration">
          <BankAccountConnection
            isConnected={false}
            isManualForm={false}
            isManualFormInfo={false}
            flinksRedirectRoute={null}
            inIntegrations={true}
            allowBankAccountCreation={allowBankAccountCreation}
            onlyCA={!hasUSDAccount}
          />
        </div>
      </div>
    </Modal>
  );
};

const AnswerMFAModal = (props) => {
  const { show, onClose, mfaQuestions, onAnswerMFA, isAnswering, error, institutionName } = props;

  const BankLogoComponent = () => {
    const bankImage = bankImages.find((b) => b.name === institutionName);
    const imageUrl = bankImage ? bankImage.image : defaultBankImage;
    return (
      <div
        className="tw-w-full tw-h-16 tw-rounded-md tw-self-center tw-bg-center tw-bg-cover"
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
    );
  };

  const onAnswer = async (answersFormData) => {
    const answers = mfaQuestions.map((mfaQuestion, index) => ({
      question: mfaQuestion,
      answer: encrypt(answersFormData.formData.securityAnswer[index]),
    }));
    await onAnswerMFA(answers);
    onClose();
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-flex-col tw-justify-center">
        <div className="tw-flex tw-justify-between tw-p-6">
          <div>Answer Security Questions</div>
          <Button onClick={() => onClose()}>
            <Close />
          </Button>
        </div>
        <hr className="tw-bg-neutral-grey-4" />
        <div className="tw-flex tw-flex-col tw-px-8 tw-py-4">
          <div className="tw-w-1/3">
            <BankLogoComponent />
          </div>
          <span className="tw-py-6 tw-font-bold tw-text-xl">
            Answer The Questions Below To Reconnect Your Bank Account to Loop
          </span>
          <span>
            We need you to answer the following questions provided by your bank in order to fix your bank account’s
            connection to Loop. Answering will reduce the likelihood of future connection issues.
          </span>
        </div>
        <SecurityQuestionForm
          mfaQuestions={mfaQuestions}
          onSubmit={onAnswer}
          isSubmitting={isAnswering}
          error={error}
        />
      </div>
    </Modal>
  );
};

const Modals = {
  Disconnect: DisconnectModal,
  AddConnection: AddConnectionModal,
  AnswerMFA: AnswerMFAModal,
  DepositVerification: DepositVerificationModal,
};

export default Modals;
