import React from 'react';
import ReactTooltip from 'react-tooltip';

import { theme } from 'styles/tailwind/themes';
import { TooltipProps } from './Tooltip.types';

const Tooltip = ({
  target,
  id,
  wrapperClassName,
  place,
  effect,
  type,
  border = true,
  borderColor = theme.colors.neutral.grey[4],
  borderClass = 'tw-rounded-md',
  children,
  ...rest
}: TooltipProps) => (
  <>
    <div data-tip data-for={id} className={wrapperClassName}>
      {target}
    </div>
    <ReactTooltip
      id={id}
      place={place || 'bottom'}
      effect={effect || 'solid'}
      type={type || 'light'}
      border={border}
      borderColor={borderColor}
      borderClass={borderClass}
      {...rest}
    >
      {children}
    </ReactTooltip>
  </>
);

export default Tooltip;
