import React from 'react';

import { Modal } from 'components/UI';
import { CardMigration } from './CardMigration';
import { CardMigrationV2ModalProps } from './CardMigrationV2.types';
import useGetCards from './hooks/useGetCards';
import styles from './CardMigrationModal.module.scss';
import LoadingScreen from 'components/UI/LoadingScreen/LoadingScreen';
import { CardMigrationContextProvider } from './context/CardMigrationContext';

const CardMigrationV2Modal = ({ show, onClose }: CardMigrationV2ModalProps) => {
  const { loading, cards } = useGetCards();

  return (
    <Modal show={show} onClose={onClose} isFullScreen>
      <CardMigrationContextProvider allCards={cards} onClose={onClose} migrationCompleted={false}>
        <div className={styles.content}>{loading ? <LoadingScreen onClose={onClose} /> : <CardMigration />}</div>
      </CardMigrationContextProvider>
    </Modal>
  );
};

export default CardMigrationV2Modal;
