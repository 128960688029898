import React from 'react';

import { PAYEE_PAYMENT_STATUS } from 'constants/index';

const PaymentStatusBadge = ({ status }) => {
  switch (status) {
    case PAYEE_PAYMENT_STATUS.posted:
      return <Badge className="tw-bg-secondary-light-green tw-text-semantic-success" title="Posted" />;
    case PAYEE_PAYMENT_STATUS.failed:
      return <Badge className="tw-bg-semantic-error-background tw-text-semantic-error" title="Failed" />;
    case PAYEE_PAYMENT_STATUS.denied:
      return <Badge className="tw-bg-semantic-error-background tw-text-semantic-error" title="Denied" />;
    case PAYEE_PAYMENT_STATUS.pending:
    case PAYEE_PAYMENT_STATUS.held:
      return <Badge className="tw-bg-secondary-pastel-yellow-50 tw-text-primary-yellow" title="Pending" />;
    case PAYEE_PAYMENT_STATUS.processing:
    case PAYEE_PAYMENT_STATUS.flagged_for_review:
      return <Badge className="tw-bg-secondary-pastel-yellow-50 tw-text-primary-yellow" title="Processing" />;
    default:
      return null;
  }
};

const Badge = ({ className, title }) => (
  <div className={`tw-py-1 tw-px-2 tw-w-max tw-rounded-md tw-flex tw-items-center tw-justify-center ${className}`}>
    <small>{title}</small>
  </div>
);

export default PaymentStatusBadge;
