import React from 'react';

const ChevronUp = ({ size = 16 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 10.75L8 6.25L3.5 10.75" stroke="#4F5154" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ChevronUp;
