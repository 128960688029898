import React from 'react';
import cx from 'classnames';
import { Combobox, Transition } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';

import { SearchDropdownProps, Option } from './SearchDropdown.types';

const SearchDropdown = ({
  optionsList,
  onSelectOption,
  onSearch,
  selectedOption,
  displayLabel,
  isSearching,
  widthClass = 'tw-w-fit',
}: SearchDropdownProps) => {
  return (
    <div className={widthClass}>
      <Combobox value={selectedOption} onChange={onSelectOption}>
        {({ open }: { open: Boolean }) => {
          return (
            <div className="tw-relative tw-mt-1">
              <div className="tw-relative tw-flex tw-flex-col tw-w-full tw-overflow-hidden tw-rounded-lg tw-bg-neutral-light">
                <Combobox.Input
                  className="tw-truncate tw-px-3 tw-py-2 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 focus:tw-border-primary-dark-green focus:tw-outline-none tw-text-sm"
                  displayValue={(option: Option | null) => option?.label || displayLabel}
                  onChange={(evt) => onSearch(evt.target.value)}
                  placeholder="Type to search.."
                />
                <Combobox.Button className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
                  <FaChevronDown className={cx('tw-ml-2', open && 'tw-rotate-180 tw-transform')} />
                </Combobox.Button>
              </div>
              <Transition
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => onSearch('')}
              >
                <Combobox.Options className="tw-absolute tw-flex tw-flex-col tw-z-40 tw-max-h-80 tw-overflow-y-scroll tw-scrollbar-hide tw-bg-neutral-light tw-rounded-md tw-w-full tw-border-2 tw-border-neutral-grey-3 focus:tw-outline-none">
                  {isSearching && (
                    <div className="tw-relative tw-select-none tw-italic tw-px-3 tw-py-2">Loading...</div>
                  )}
                  {!isSearching && !optionsList.length && (
                    <div className="tw-relative tw-select-none tw-px-3 tw-py-2 ">Nothing found.</div>
                  )}
                  {optionsList.map((option) => (
                    <Combobox.Option
                      title={option.label}
                      key={option.value}
                      className={cx(
                        'hover:tw-text-neutral-light hover:tw-bg-primary-dark-green tw-cursor-pointer tw-px-3 tw-py-2 tw-flex',
                        option.value === selectedOption?.value && 'tw-text-primary-dark-green tw-bg-primary-light-green'
                      )}
                      value={option}
                    >
                      <div
                        className={cx(
                          'tw-flex tw-flex-grow tw-truncate',
                          option.value === selectedOption?.value && 'tw-font-medium'
                        )}
                      >
                        {option.label}
                      </div>
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </Transition>
            </div>
          );
        }}
      </Combobox>
    </div>
  );
};

export default SearchDropdown;
