import React from 'react';

import { FormFooter } from 'components/UI';
import { useWhatsChanged } from '../hooks';
import ChangesRadioComponent from './ChangesRadioComponent';

const PreFundedUserView = () => {
  const { onPrevStep, onNextStep } = useWhatsChanged();

  return (
    <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-flex-grow tw-items-center">
      <div className="tw-flex tw-flex-col tw-gap-2 tw-py-4 tw-w-2/3 tw-mx-auto tw-px-4 lg:tw-px-0">
        <div>
          Below is a list of all of the changes and enhancements that you will see when you switch to the new card
          program:
        </div>
        <div className="tw-my-4 tw-flex tw-flex-col tw-items-center tw-justify-center">
          <div className="tw-w-full tw-flex tw-flex-col tw-items-center ">
            <ChangesRadioComponent />
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-border-b tw-border-neutral-grey-4 tw-bg-neutral-grey-4.5 tw-bottom-0 tw-rounded-b-md tw-flex tw-justify-center">
        <FormFooter
          onCancel={onPrevStep}
          cancelButtonLabel="Go Back"
          onSubmit={onNextStep}
          submitButtonLabel="Continue"
          rootClass="tw-w-2/3 tw-py-6 tw-flex tw-justify-between"
        />
      </div>
    </div>
  );
};
export default PreFundedUserView;
