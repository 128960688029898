import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

type CupCardAndStarsProps = {
  width?: number;
  height?: number;
};

const CupCardAndStars = ({ width = 122, height = 119 }: CupCardAndStarsProps) => (
  <svg width={width} height={height} viewBox="0 0 122 119" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M95.9167 9.90024C95.9953 9.84036 96.0895 9.94669 96.0365 10.0354L91.1016 18.2932C91.082 18.3261 91.082 18.3692 91.1016 18.4022L96.0365 26.6599C96.0895 26.7487 95.9953 26.855 95.9167 26.7951L88.6029 21.2234C88.5738 21.2011 88.5356 21.2011 88.5064 21.2234L81.1926 26.7951C81.114 26.855 81.0199 26.7487 81.0729 26.6599L86.0078 18.4022C86.0274 18.3692 86.0274 18.3261 86.0078 18.2932L81.0729 10.0354C81.0199 9.94669 81.114 9.84036 81.1926 9.90024L88.5064 15.472C88.5356 15.4942 88.5738 15.4942 88.6029 15.472L95.9167 9.90024Z"
      fill={primaryDarkGreenColor}
    />
    <path
      d="M13.7802 38.9976C13.578 38.4761 13.8861 37.8688 14.4297 37.7176L62.5178 24.3349C63.053 24.186 63.5702 24.5484 63.6181 25.106L70.2285 102.105C70.2682 102.569 69.9653 103.008 69.5205 103.132L42.4103 110.676C41.9733 110.798 41.5311 110.578 41.3685 110.159L13.7802 38.9976Z"
      fill="#F4CF95"
    />
    <line
      x1="0.68988"
      y1="-0.68988"
      x2="52.2229"
      y2="-0.68988"
      transform="matrix(0.963389 -0.268106 0.213282 0.976991 11.998 35.6957)"
      stroke="#F4CF95"
      strokeWidth="1.37976"
      strokeLinecap="round"
    />
    <rect
      width="43.5753"
      height="8.66331"
      rx="0.45992"
      transform="matrix(0.963389 -0.268106 0.213282 0.976991 14.2822 24.2868)"
      fill="#F4CF95"
    />
    <rect
      width="49.8003"
      height="31.1879"
      transform="matrix(0.963389 -0.268106 0.213282 0.976991 20.1738 58.56)"
      fill="#FAFAFA"
    />
    <rect
      x="0.186424"
      y="0.275225"
      width="56.9067"
      height="39.8609"
      rx="1.60972"
      transform="matrix(0.977517 0.210856 -0.166836 0.985985 65.972 37.4692)"
      stroke={primaryDarkGreenColor}
      strokeWidth="0.45992"
    />
    <line
      y1="-1.37976"
      x2="57.3667"
      y2="-1.37976"
      transform="matrix(0.977517 0.210856 -0.166836 0.985985 61.1895 65.4811)"
      stroke={primaryDarkGreenColor}
      strokeWidth="2.75952"
    />
    <path
      d="M46.1805 67.3121C46.2591 67.2522 46.3532 67.3585 46.3002 67.4473L36.2291 84.2999C36.2094 84.3329 36.2094 84.376 36.2291 84.4089L46.3002 101.262C46.3532 101.35 46.2591 101.457 46.1805 101.397L31.2543 90.0257C31.2251 90.0035 31.187 90.0035 31.1578 90.0257L16.2316 101.397C16.153 101.457 16.0589 101.35 16.1119 101.262L26.1831 84.4089C26.2027 84.376 26.2027 84.3329 26.1831 84.2999L16.1119 67.4473C16.0589 67.3585 16.153 67.2522 16.2316 67.3121L31.1578 78.6831C31.187 78.7053 31.2251 78.7053 31.2543 78.6831L46.1805 67.3121Z"
      fill={primaryDarkGreenColor}
    />
  </svg>
);

export default CupCardAndStars;
