import React, { useContext } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

import { User } from 'types/user';
import { AuthContext } from 'context/Auth';
import SignOut from 'components/svg/SignOut';
import PrimaryInitialsCircles from 'components/PrimaryInitialsCircle';
import ThemeToggle from '../ThemeToggle';

import styles from './MobileHeader.module.scss';
import LoopLogo from 'components/svg/LoopLogo';

const MobileHeader = () => {
  // TODO update after https://getloop.atlassian.net/browse/LBP-2615
  const { me } = useContext(AuthContext) as unknown as { me: User };

  if (!me) return null;

  const { firstName, lastName } = me;

  return (
    <Menu
      as="div"
      className="layout-header__menu tw-relative tw-flex tw-justify-between lg:tw-hidden tw-w-full tw-p-4 tw-bg-white"
    >
      <Link to="/dashboard/home" title="Go to homepage">
        <LoopLogo />
      </Link>

      <Menu.Button>
        <PrimaryInitialsCircles.Regular firstName={firstName} lastName={lastName} />
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="tw-transition tw-ease-out tw-duration-100"
        enterFrom="tw-transform tw-opacity-0 tw-scale-95"
        enterTo="tw-transform tw-opacity-100 tw-scale-100"
        leave="tw-transition tw-ease-in tw-duration-75"
        leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
        leaveTo="tw-transform tw-opacity-0 tw-scale-95"
      >
        <Menu.Items className="tw-absolute tw-right-3 tw-mt-7 tw-origin-top-left">
          <div className="tw-flex tw-px-2 tw-justify-end">
            <div className={styles.smallArrowUp} />
          </div>
          <Menu.Item key="theme" as="div" className="tw-cursor-pointer tw-bg-neutral-light tw-p-4 tw-shadow-lg">
            <ThemeToggle />
          </Menu.Item>
          <Menu.Item
            as="div"
            className="tw-cursor-pointer tw-bg-neutral-light tw-p-4 tw-rounded-b-md tw-bg-transparent tw-shadow-lg"
          >
            <Link to="/signout" className="tw-flex tw-items-center">
              <SignOut className="tw-mr-2 tw-text-semantic-error" />
              <p className="tw-text-semantic-warning">Sign Out</p>
            </Link>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MobileHeader;
