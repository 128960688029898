import React from 'react';

import { CompletedTableProps } from './CompletedTable.types';
import { useCompletedTable } from './hooks';
import { CompletedRequestDataType } from '../ApprovalRequest.types';
import { CompletedRow } from './components';
import ApprovalRequestDetailOverlay from '../ApprovalRequestDetailOverlay';

const CompletedTable = ({ completedApprovalsList }: CompletedTableProps) => {
  const { noApprovalsAvailable } = useCompletedTable({
    completedApprovalsList,
  });
  if (noApprovalsAvailable) {
    return (
      <div className="tw-border-t tw-border-neutral-grey-3 tw-pt-4">
        You have no completed requests available at this moment.
      </div>
    );
  }

  return (
    <>
      <table className="tw-table tw-table-auto tw-w-full">
        <thead>
          <tr>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Approval Type</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">
              Cardholder/Requester
            </th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">From</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">To/Merchant</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Amount</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Currency</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Date Approved</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Receipt</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          {completedApprovalsList.map((expense: CompletedRequestDataType) => (
            <CompletedRow key={expense.id} completedExpense={expense} />
          ))}
        </tbody>
      </table>
      <ApprovalRequestDetailOverlay requestList={completedApprovalsList} />
    </>
  );
};
export default CompletedTable;
