import React from 'react';

import { formatDateTime } from 'utility/date';
import Button from 'components/Button';
import { IoCallOutline, IoDownloadOutline, IoMailOutline } from 'react-icons/io5';

const FileDetails = ({ fileUrl }) => {
  const extension = fileUrl.split('.').pop();

  switch (extension) {
    case 'pdf':
      return (
        <div>
          <object data={fileUrl} type="application/pdf" width="100%" />
          <a href={fileUrl} target="_blank" rel="noreferrer noopener">
            <IoDownloadOutline size={18} className="tw-text-primary-dark-green tw-float-right" />
          </a>
        </div>
      );
    default:
      return (
        <a href={fileUrl} target="_blank" rel="noreferrer noopener">
          <img src={fileUrl} alt="" loading="lazy" />
        </a>
      );
  }
};

const SourceIcon = ({ sourceType }) => {
  if (sourceType === 'email') {
    return <IoMailOutline size={18} />;
  } else {
    return <IoCallOutline size={18} />;
  }
};

const ReceiptsList = ({ unmatchedReceipts, setSelectedReceipt }) => {
  return (
    <>
      <div className="tw-flex tw-flex-wrap tw-gap-4">
        {unmatchedReceipts.map((unmatchedReceipt) => {
          return (
            <div key={unmatchedReceipt.id}>
              <div className="tw-h-max tw-w-40 tw-rounded-md tw-bg-neutral-light tw-py-2 tw-px-4 tw-transition-shadow tw-duration-500 tw-ease-in-out hover:tw-shadow-notification">
                <div className="tw-flex tw-justify-between tw-items-center">
                  <SourceIcon sourceType={unmatchedReceipt.sourceType} />
                  <small className="tw-truncate tw-ml-1" title={unmatchedReceipt.sourceAddress}>
                    {unmatchedReceipt.sourceAddress}
                  </small>
                </div>
                <div className="tw-flex tw-justify-between tw-items-center tw-cursor-pointer">
                  <FileDetails fileUrl={unmatchedReceipt.receipt} />
                </div>
                <div className="tw-flex tw-justify-between tw-items-center tw-mt-2">
                  <small className="tw-bottom-10 tw-text-black">
                    Uploaded by {unmatchedReceipt.contact.firstName} {unmatchedReceipt.contact.lastName}
                  </small>
                </div>
                <small className="tw-font-semibold">
                  On {formatDateTime(unmatchedReceipt.createdAt, 'p, MMMM d, yyyy')}
                </small>
                <div className="tw-flex tw-justify-between tw-items-center tw-mt-2">
                  <Button
                    type="button"
                    onClick={() => setSelectedReceipt(unmatchedReceipt)}
                    className="tw-bg-primary-dark-green tw-text-neutral-light tw-text-xs tw-rounded-md tw-p-1"
                  >
                    Select
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ReceiptsList;
