import { useContext } from 'react';
import { useForm as useFormHook } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import history from 'history.js';
import { ampTrackEvent } from 'amplitude';
import { SIGN_UP } from 'graphql/user';
import { AuthContext } from 'context/Auth';
import { UTM_KEY } from 'utility/utm';

const useForm = () => {
  const [addUser, { loading: isSigningUp, error }] = useMutation(SIGN_UP);
  const { signIn, isSigningIn } = useContext(AuthContext);

  const form = useFormHook();
  const isSubmitting = isSigningIn || isSigningUp;
  const { register, handleSubmit, watch } = form;
  const currentPassword = watch('password');

  const onSubmit = async (data) => {
    try {
      // TODO: Remove hardcoded country once product is able to decide where to ask the country value
      const utm = window.localStorage.getItem(UTM_KEY);
      const details = { variables: { ...data, country: 'CA', subscribed: false, utm: utm } };
      const response = await addUser(details);

      if (response?.data?.signUp) {
        ampTrackEvent('onboarding: signup: success');
        await signIn(data.email, data.password);
        history.push('/onboarding/eligibility/start');
      }
    } catch (err) {
      console.error(err);
      ampTrackEvent('onboarding: signup: error');
    }
  };

  return {
    form,
    error,
    isSubmitting,
    currentPassword,
    register,
    handleSubmit,
    onSubmit,
  };
};

export default useForm;
