export const tableHeaders = [
  'Vendor',
  'Bank Account',
  'Due Date/Pull Date',
  'Amount',
  'Frequency',
  'Status',
  'Action',
  'Details',
];
