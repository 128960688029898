import React from 'react';

const HowToAmazon = () => {
  return (
    <ol className="pl-4 mb-5">
      <li className="mb-3">
        Login into Seller Central and go to{' '}
        <a href="https://sellercentral.amazon.ca/apps/manage">
          <span className="font-weight-bold">Manage your apps</span>
        </a>{' '}
        page.
      </li>
      <li className="mb-3">
        Click <span className="font-weight-bold">Authorize new developer</span> and enter these details: <br />
        <p className="pl-4 mb-0">
          Developer’s Name: <span className="font-weight-bold">Lending Loop</span>
        </p>
        <p className="pl-4 mb-0">
          Developer’s ID: <span className="font-weight-bold">374024750460</span>
        </p>
      </li>
      <li className="mb-3">Click Next</li>
      <li className="mb-3">
        Agree to <span className="font-weight-bold">provide Lending Loop access</span> by checking the box
      </li>
      <li className="mb-3">Click Next</li>
      <li className="mb-3">
        Find your <span className="font-weight-bold">Seller ID</span> and{' '}
        <span className="font-weight-bold">MWS Auth Token</span> and enter it here:
      </li>
    </ol>
  );
};

export default HowToAmazon;
