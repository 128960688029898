import React, { useMemo } from 'react';

import config from 'config';
import cardsImage from 'images/virtual-and-physical-cards.png';

import { utmParams } from './constants';

const buttonColor = '#a4d1ea';

const Completed = () => {
  const redirectUrl = useMemo(() => {
    const query = new URLSearchParams(utmParams).toString();
    return `${config.landingPageUrl}?${query}`;
  }, []);

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-pt-12 2xl:tw-pt-16 tw-pb-8">
      <div className="tw-flex tw-flex-col tw-gap-6 lg:tw-w-3/4 xl:tw-w-2/3">
        <img
          className="tw-object-contain tw-w-full xl:tw-w-4/5 tw-mx-auto 2xl:tw-mb-8"
          src={cardsImage}
          alt="Virtual and physical credit cards"
        />

        <h2 className="tw-text-neutral-light tw-text-xl 2xl:tw-text-2xl">
          Loop Invoicing is the easier way to get paid. And it won’t cost you 3%
        </h2>
        <div className="tw-font-bold tw-text-lg tw-text-neutral-light">
          Collect payments using Loop’s global billing solution and eliminate costly credit card processing fees, wire
          fees and long payment timelines.
        </div>

        <div className="tw-flex tw-justify-center">
          <a
            href={redirectUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="tw-inline-block tw-text-neutral-dark tw-font-bold tw-rounded-3xl tw-px-7 tw-py-4"
            style={{ backgroundColor: buttonColor }}
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
};

export default Completed;
