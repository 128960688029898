// The following operations should be synced with BE app
// https://github.com/lendingloop/loop-banking/blob/main/app/controllers/graphql_controller.rb
export const SKIP_AUTH_OPERATIONS = [
  'AcceptTeamInvitation',
  'AcceptUserInvite',
  'AddRequestedSupplierBankAccount',
  'ConsentToCreditCheck',
  'ForgotPassword',
  'AuthEmailVerification',
  'GuarantorContact',
  'PayorSignUp',
  'PasswordResetEmail',
  'PayorPaymentRequest',
  'RequestedSupplier',
  'SignerContact',
  'SignUp',
  'SubmitSigning',
  'TeamInvitation',
  'UpdatePassword',
  'UserInvitation',
];

export const RESPONSE_STATUS_CODES = {
  // 2XX — Success
  200: {
    code: 200,
    message: 'OK',
    description: 'The request succeeded.',
  },
  201: {
    code: 201,
    message: 'Created',
    description: 'The request succeeded, and a new resource was created as a result.',
  },

  // 3XX — Redirection
  301: {
    code: 301,
    message: 'Moved Permanently',
    description: 'The URL of the requested resource has been changed permanently.',
  },
  304: {
    code: 304,
    message: 'Not Modified',
    description: 'The response was cached.',
  },

  // 4XX — Client Error
  400: {
    code: 400,
    message: 'Bad Request',
    description: 'The server cannot process the request.',
  },
  401: {
    code: 401,
    message: 'Unauthorized',
    description: 'The client must authenticate itself to get the requested response.',
  },
  403: {
    code: 403,
    message: 'Forbidden',
    description: 'The client does not have access rights to the content.',
  },
  404: {
    code: 404,
    message: 'Not Found',
    description: 'The server cannot find the requested resource.',
  },
  429: {
    code: 429,
    message: 'Too Many Requests',
    description: 'The user has sent too many requests.',
  },

  // 5XX — Server Error
  500: {
    code: 500,
    message: 'Internal Server Error',
    description: 'The server has encountered a situation it does not know how to handle.',
  },
  503: {
    code: 503,
    message: 'Service Unavailable',
    description: 'The server is not ready to handle the request.',
  },
  504: {
    code: 504,
    message: 'Gateway Timeout',
    description:
      'This error response is given when the server is acting as a gateway and cannot get a response in time.',
  },
};
