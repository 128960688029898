import React from 'react';

import { Modal } from 'components/UI';
import { PreviewContent } from 'components/OnboardingDashboard/components/PreviewScreen/components';
import { PayorsListProps } from './PayorsList.types';
import { AddPaymentRequestContextProvider } from '../PayorsList/contexts/AddPaymentRequestContext';
import { NoFilteredData, PayorItem, EditPayorModal, DeletePayorModal, AddPaymentRequestModal } from './components';
import { usePayorsList } from './hooks';

const PayorsList = ({ payors }: PayorsListProps) => {
  const {
    dataByGroups,
    showEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    showDeleteModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    showAddPaymentRequestModal,
    handleOpenAddPaymentRequestModal,
    handleCloseAddPaymentRequestModal,
    showOnboardingPreviewModal,
    onCloseOnboardingPreviewModal,
  } = usePayorsList({ payors });

  if (!payors.length) return <NoFilteredData />;

  return (
    <div className="tw-my-12">
      {Object.keys(dataByGroups).map((key) => (
        <div key={key}>
          <div className="tw-bg-neutral-light lg:tw-bg-neutral-grey-4 tw-px-4 tw-py-2 tw-rounded tw-mt-4">{key}</div>
          {dataByGroups[key]
            .filter((p) => p)
            .map((payor) => (
              <PayorItem
                key={payor.id}
                payorId={payor.id}
                openEditModal={handleOpenEditModal}
                openDeleteModal={handleOpenDeleteModal}
                openAddPaymentRequestModal={handleOpenAddPaymentRequestModal}
              />
            ))}
        </div>
      ))}
      <EditPayorModal show={showEditModal} onClose={handleCloseEditModal} />
      <DeletePayorModal show={showDeleteModal} onClose={handleCloseDeleteModal} />
      <AddPaymentRequestContextProvider>
        <AddPaymentRequestModal show={showAddPaymentRequestModal} onClose={handleCloseAddPaymentRequestModal} />
      </AddPaymentRequestContextProvider>
      <Modal show={showOnboardingPreviewModal} onClose={onCloseOnboardingPreviewModal}>
        <PreviewContent />
      </Modal>
    </div>
  );
};

export default PayorsList;
