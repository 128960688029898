import React from 'react';
import { FormProvider } from 'react-hook-form';
import cx from 'classnames';

import { formatMoneyV2 } from 'utility/currency';
import { Currencies } from 'constants/currencies';
import { EXTERNAL_TRANSACTION_CLEARING_MESSAGE } from 'constants/index';
import { Loaders } from 'components/cards/Loader';
import { FormFooter } from 'components/UI';
import Banner, { BANNER_TYPES } from 'components/Banner';
import { Select, MoneyInputField } from 'components/FormFields/v2';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { useDetails } from './hooks';
import { DetailsProps } from './Details.types';

const Details = ({ onNextStep }: DetailsProps) => {
  const {
    form,
    register,
    handleSubmit,
    handleNextStep,
    isLoading,
    isProcessing,
    error,
    formattedAmount,
    setFormattedAmount,
    currency,
    fromAccountOptions,
    validateFromAccountBalance,
    validateAmountPositive,
    isFromBankAccount,
    formattedWalletBalance,
  } = useDetails({
    onNextStep,
  });

  if (isLoading)
    return (
      <div className="tw-my-8">
        <Loaders.Light />
      </div>
    );

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleNextStep)} className="tw-flex tw-flex-col tw-gap-8">
          <div>
            <Select
              name="fromAccountId"
              label="From Account"
              ref={register({ required: true, validate: validateFromAccountBalance })}
              options={fromAccountOptions}
              required
              disabled={isProcessing}
            />

            {isFromBankAccount ? (
              <Banner
                isShowIcon
                type={BANNER_TYPES.danger}
                message={EXTERNAL_TRANSACTION_CLEARING_MESSAGE}
                className="tw-mt-4"
              />
            ) : (
              <div className="tw-text-xs tw-text-neutral-grey-1 tw-mt-4">
                Available amount: {formattedWalletBalance}
              </div>
            )}
          </div>

          <div className="tw-flex tw-items-start">
            <MoneyInputField
              name="amount"
              label="Amount"
              required
              currency={currency}
              value={formattedAmount}
              moneyFormatter={formatMoneyV2}
              setValue={setFormattedAmount}
              rootClass="tw-flex-grow"
              ref={register({ required: true, validate: validateAmountPositive })}
              disabled={isProcessing}
            />
            <div
              className={cx(
                'tw-flex tw-items-center tw-h-11 tw-bg-neutral-grey-4 tw-rounded-md tw-p-4 tw-mt-7 tw-flex-grow-0 tw-ml-4',
                isProcessing && 'tw-bg-neutral-grey-3 tw-cursor-not-allowed'
              )}
            >
              <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5 tw-mr-2">
                <MobileCurrencyFlag currency={Currencies[currency!]} size={16} />
              </div>
              <p className="tw-text-neutral-grey-2">{currency}</p>
            </div>
          </div>

          {error?.message && <div className="tw-text-semantic-error tw-text-xs">{error.message}</div>}

          <FormFooter
            submitButtonLabel="Submit Payment"
            isDisabled={!!error?.message}
            loading={isProcessing}
            rootClass="tw-mt-4"
          />
        </form>
      </FormProvider>
    </>
  );
};

export default Details;
