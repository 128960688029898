import React from 'react';

import CardAndCoins from 'components/svg/CardAndCoins';

const MemberNoCards = ({ title }) => {
  return (
    <div className="tw-bg-neutral-light tw-m-auto tw-py-5 tw-px-6 tw-flex tw-flex-col tw-items-center tw-rounded-md">
      <div>
        <CardAndCoins height="300" width="100%" />
      </div>
      <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-my-5 tw-w-4/5 lg:tw-w-96">
        <span className="tw-text-2xl tw-mt-4">{title}</span>
        <p className="tw-mt-8" data-testid="connect-bank-or-contact-banner-description">
          Please contact your team manager to have access on Cards.
        </p>
      </div>
    </div>
  );
};

export default MemberNoCards;
