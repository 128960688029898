import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { GET_AGREEMENTS } from 'graphql/agreements';
import Agreements from 'components/settings/Agreements';

const AgreementsContainer = () => {
  const { loading, data } = useQuery(GET_AGREEMENTS);

  const agreements = _.get(data, 'me.agreements') || [];

  return <Agreements agreements={agreements} loading={loading} />;
};

export default AgreementsContainer;
