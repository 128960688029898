import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useGetBusinessAddress, useGetUserInfo } from 'hooks';
import { SIGN_PRE_DEPOSIT_AUTHORIZATION } from 'graphql/bankAccounts';
import { formatDateTime } from 'utility/date';
import { composeFullAddress } from 'utility/address';
import { PADAgreementUSProps } from '../PADAgreementUS.types';

const usePADAgreementUS = ({
  externalBankAccount,
  onNextStep,
}: Pick<PADAgreementUSProps, 'externalBankAccount' | 'onNextStep'>) => {
  const { isLoading: isUserDataLoading, fullName, firstName, lastName, legalBusinessName, email } = useGetUserInfo();
  const { street, unitNumber, city, country, countrySubdivision, postalCode, loadingBusinessAddress } =
    useGetBusinessAddress();

  const formattedBusinessAddress = composeFullAddress({
    country,
    countrySubdivision,
    postalCode,
    city,
    street,
    unitNumber,
  });

  const formattedDate = formatDateTime(new Date());

  const [signPreDepositAuthorization, { loading: loadingSignPDA }] = useMutation(SIGN_PRE_DEPOSIT_AUTHORIZATION);

  const form = useForm();
  const { register, handleSubmit, watch } = form;
  const termsAccepted = watch('acceptedTerms');

  const handleAuthorizePDA = async () => {
    try {
      if (!externalBankAccount) throw new Error('External bank account is missing');

      const response = await signPreDepositAuthorization({
        variables: { firstName, lastName, bankAccountId: externalBankAccount.id },
      });

      if (!response?.data?.signPreDepositAuthorization) throw new Error('Failed to sign pre-deposit authorization');

      await onNextStep();
    } catch (error) {
      console.error(error);
      toast.error(error);
    }
  };

  return {
    userName: fullName,
    userEmail: email,
    businessName: legalBusinessName,
    formattedBusinessAddress,
    formattedDate,
    isLoading: isUserDataLoading || loadingBusinessAddress,
    isSubmitting: loadingSignPDA,
    form,
    handleAuthorizePDA,
    handleSubmit,
    register,
    isValid: termsAccepted,
  };
};

export default usePADAgreementUS;
