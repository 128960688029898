import React, { useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { IoAddCircleOutline } from 'react-icons/io5';

import { GET_TRANSACTION_CATEGORIES } from 'graphql/transactionCategories';
import {
  GET_CATEGORIES_QUICKBOOKS_MAPPING,
  GET_QBO_TAX_CODES,
  GET_QUICKBOOK_EXPENSE_ACCOUNTS,
  UPDATE_CATEGORIES_QUICKBOOKS_MAPPING,
} from 'graphql/accountingServices';

import { MapButton } from './MapButton';
import { QuickBooksAccount } from './Account';
import { LoopCategory } from './LoopCategory';
import { Loaders } from 'components/cards/Loader';
import { NewAccountModalContext } from 'context/AccountingServices';

export const QuickBooksMapping = ({ accountName }) => {
  const {
    loading: loadingQuickbooksCategories,
    error: errorQuickbooksCategories,
    data: quickBookData,
  } = useQuery(GET_QUICKBOOK_EXPENSE_ACCOUNTS);

  const {
    loading: loadingLoopCategories,
    error: errorLoopCategories,
    data: dataLoopCategories,
  } = useQuery(GET_TRANSACTION_CATEGORIES);

  const {
    loading: loadingMapping,
    error: errorMapping,
    data: dataMapping,
  } = useQuery(GET_CATEGORIES_QUICKBOOKS_MAPPING);

  const { data: dataTaxRates } = useQuery(GET_QBO_TAX_CODES);
  const { accountingIntegrationTaxCodes } = dataTaxRates || { accountingIntegrationTaxCodes: [] };

  const [updateMapping, { error: errorMutation }] = useMutation(UPDATE_CATEGORIES_QUICKBOOKS_MAPPING, {
    refetchQueries: [GET_CATEGORIES_QUICKBOOKS_MAPPING, GET_QUICKBOOK_EXPENSE_ACCOUNTS],
  });

  const { transactionCategories } = dataLoopCategories || [];
  const { accountingIntegrationExpenseAccounts: unsortedQuickBooksExpenseAccounts } = quickBookData || {
    accountingIntegrationExpenseAccounts: [],
  };
  const accountingIntegrationExpenseAccounts = [...unsortedQuickBooksExpenseAccounts].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  // TODO: these line and the next function are in the context. Consider both ooptions, using a global context fashion for both pages, or redo queries.
  const { transactionCategoryAccountingIntegrationMapping } = dataMapping || [];

  // If we find a way of wrapping settings and transactions/qboPush under the same provider without passing it to all the
  // other routes / components, we could get from context all the transactionCategories, quickBookExpenses, etc.
  // at the moment we have one provider wrapping transactions/qboPush
  // const { getFromMapping } = useContext(QuickBooksTransactionsContext);
  // TODO: refactor into a more efficeint way. Ticket for Next Sprint November.

  const getFromMapping = (categoryId) => {
    const map = transactionCategoryAccountingIntegrationMapping?.find(
      (mapping) => mapping.transactionCategoryId === categoryId
    );
    if (!map) return {};
    return accountingIntegrationExpenseAccounts?.find((account) => account.id === map?.quickBooksAccountId);
  };

  const onChange = (id, newCategory) => {
    try {
      updateMapping({ variables: { id, quickBooksAccountId: newCategory.id } });
    } catch (e) {
      console.error(e);
      console.error(errorMutation);
    }
  };

  const { showNewExpense, setShowNewExpense, setTransactionCategoryId } = useContext(NewAccountModalContext);
  const handleNewExpenseAccount = (transactionCategoryId) => {
    setTransactionCategoryId(transactionCategoryId);
    setShowNewExpense(true);
  };

  if (loadingQuickbooksCategories || loadingLoopCategories || loadingMapping) return <Loaders.Spinner />;

  if (errorQuickbooksCategories || errorLoopCategories || errorMapping) {
    console.error(errorQuickbooksCategories);
    console.error(errorLoopCategories);
    console.error(errorMapping);
    return 'Error...';
  }

  if (
    transactionCategoryAccountingIntegrationMapping &&
    transactionCategories &&
    accountingIntegrationExpenseAccounts &&
    !showNewExpense
  )
    return (
      <>
        <div className="tw-flex tw-items-center">
          <div className="tw-w-1/2 tw-text-neutral-grey-2 tw-flex tw-justify-between tw-my-5">
            <div className="tw-w-1/2 tw-pl-2">Loop Expense Category</div>
            <div className="tw-w-1/2 tw-pl-2">{accountName} Expense Account</div>
          </div>
          <div className="tw-w-1/2 tw-text-neutral-grey-2 tw-text-center">
            <div>Tax Code</div>
          </div>
        </div>
        <div>
          {transactionCategories.map(({ transactionCategoryId, description, loopId }) => {
            const qboExpenseFromMapping = getFromMapping(transactionCategoryId);
            if (!qboExpenseFromMapping) return null;
            const qboExpenseAccount =
              'id' in qboExpenseFromMapping ? qboExpenseFromMapping : { id: '-1', name: 'Select' };

            return (
              <div className="tw-flex tw-items-center" key={transactionCategoryId}>
                <MapButton
                  className="tw-w-1/2"
                  key={transactionCategoryId}
                  leftSide={<LoopCategory id={transactionCategoryId} description={description} loopId={loopId} />}
                  rightSide={
                    <QuickBooksAccount
                      qbCategories={accountingIntegrationExpenseAccounts}
                      qbCategory={qboExpenseAccount}
                      onChange={(newCategory) => onChange(transactionCategoryId, newCategory)}
                      listOptionStyles={'tw-h-80'}
                      className="tw-relative"
                      newAccount={() => (
                        <div className="tw-p-1 tw-h-8 tw-flex tw-box-border tw-items-center tw-cursor-pointer tw-text-neutral-light tw-bg-primary-dark-green hover:tw-opacity-90">
                          <IoAddCircleOutline className="tw-ml-1" />
                          <span className="tw-mx-1" onClick={() => handleNewExpenseAccount(transactionCategoryId)}>
                            Create Expense Account
                          </span>
                        </div>
                      )}
                    />
                  }
                />
                {/* <div>{qboTaxCodes?.find((elem) => elem.id === qboExpenseAccount.taxCodeId)?.name}</div> */}
                <div className="tw-w-1/2 tw-text-center">
                  {accountingIntegrationTaxCodes?.find((elem) => elem.id === qboExpenseAccount.taxCodeRefId)?.name ||
                    '-'}
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  else return '';
};
