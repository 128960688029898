import React from 'react';

import config from 'config';
import BrokenClock from 'components/svg/BrokenClock';

const CardsListError = () => (
  <div className="tw-flex tw-bg-neutral-light tw-rounded-md tw-p-4 tw-flex-col tw-items-center tw-justify-center tw-space-y-8">
    <BrokenClock />
    <p>
      Unfortunately, we weren't able to display your Loop Cards. Please try refreshing the page. If you continue to
      encounter this issue, please reach out to our team at{' '}
      <a
        className="tw-font-semibold tw-text-primary-dark-green"
        target="_blank"
        href={`mailto:${config.supportEmail}`}
        rel="noreferrer"
      >
        {config.supportEmail}
      </a>
    </p>
  </div>
);

export default CardsListError;
