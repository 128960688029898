import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import { useMutation } from '@apollo/client';

import { SCOPE } from 'constants/index';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import BoxWithCoinsStarsAndCards from 'components/svg/BoxWithCoinsStarsAndCards';
import Button from 'components/Button';
import PermissionChecker from 'components/PermissionChecker';
import { SET_WELCOME_MODAL_SEEN } from 'graphql/user';

const ConnectBankAccountModal = ({ welcomeModalSeen }) => {
  const [show, setShow] = useState(true);
  const history = useHistory();
  if (welcomeModalSeen) return null;
  const [setSeen] = useMutation(SET_WELCOME_MODAL_SEEN);

  const handleClose = () => {
    setSeen();
    setShow(false);
  };
  const onNavigateToBankAccounts = () => {
    setSeen();
    history.push('/dashboard/settings/bank-accounts-settings');
  };

  return (
    <PermissionChecker scope={SCOPE.manageBankAccounts}>
      <Modal show={show} onClose={handleClose}>
        <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
          <strong>Get Started</strong>
          <Close data-testid="close-icon" className="tw-cursor-pointer" onClick={handleClose} />
        </div>
        <div className="tw-flex tw-flex-col tw-justify-center tw-p-8 tw-border-t tw-border-neutral-grey-4 tw-space-y-8">
          <div className="tw-flex tw-items-center tw-justify-center">
            <BoxWithCoinsStarsAndCards />
          </div>
          <div className="tw-space-y-4">
            <span className="tw-font-semibold tw-text-2xl">Welcome to Loop!</span>
            <p>
              With your new Loop account, you can create and manage corporate cards, pay payees around the world, manage
              your different currencies and get paid in US dollars with US banking details. Click the link below to get
              started and make the most of your Loop account.
            </p>
          </div>
          <strong>Before taking full advantage of Loop, you’ll need to:</strong>
          <Button primary onClick={onNavigateToBankAccounts} className="tw-space-x-2 tw-w-max">
            <p className="tw-text-neutral-light">Connect Your External Bank Account</p>
            <BsArrowRight />
          </Button>
          <strong>Why do I need to connect my bank account to Loop?</strong>
          <ul className="tw-space-y-2 tw-list-disc tw-px-4">
            <li>Connect a bank account to load funds or pay balances to your Loop Cards and Accounts</li>
            <li>
              Send payments to your Payees all over the world from your connected account with Loop’s best-in-class FX
              Rates
            </li>
            <li>Have the option to receive your USD payouts into your existing Canadian account with no fees</li>
          </ul>
        </div>
      </Modal>
    </PermissionChecker>
  );
};

export default ConnectBankAccountModal;
