import React from 'react';
import { useHistory } from 'react-router-dom';

import Summary from '../Summary';
import RepeatOutline from 'components/svg/RepeatOutline';
import { Banner } from 'components/UI';
import CardAndCoins from 'components/svg/CardAndCoins';

const PaymentsSummary = () => {
  const history = useHistory();
  const navigateToPayees = () => history.push('/onboarding/dashboard/payments/payees');

  return (
    <Summary
      title="Payments"
      icon={<RepeatOutline />}
      iconClassName="tw-text-primary-dark-yellow tw-bg-secondary-light-yellow"
    >
      <Banner
        title="Send Money Around the World"
        message="Make fast and secure payments around the world. Get market leading FX rates with Loop Payments - included
        free with your Loop account."
        submitButtonLabel="Add Payees"
        onSubmit={navigateToPayees}
        icon={<CardAndCoins width="100" height="100" />}
        iconAlignment="center"
        classNames="tw-mb-0"
      />
    </Summary>
  );
};

export default PaymentsSummary;
