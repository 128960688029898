import React from 'react';
import { useHistory } from 'react-router-dom';

import config from 'config';
import { useGetUserInfo } from 'hooks';
import SettingsLayout from 'containers/settings/SettingsLayout';
import { SettingsLayout as OnboardingSettingsLayout } from 'components/OnboardingDashboard/components/Settings/components';
import ChevronLeft from 'components/svg/ChevronLeft';
import Calendar from 'components/svg/Calendar';
import Button from 'components/Button';

const AddBankAccount = () => {
  const history = useHistory();
  const { isKYCApproved } = useGetUserInfo();

  const bankAccountsSettingsUrl = isKYCApproved
    ? '/dashboard/settings/bank-accounts-settings'
    : '/onboarding/dashboard/settings/bank-accounts-settings';

  const Layout = isKYCApproved ? SettingsLayout : OnboardingSettingsLayout;

  const goBack = () => {
    history.push(bankAccountsSettingsUrl);
  };

  return (
    <Layout>
      <div className="tw-flex tw-flex-col tw-justify-start tw-gap-6">
        <div className="tw-flex tw-flex-row tw-items-center">
          <div className="tw-w-3/12 tw-justify-center tw-mr-4">
            <Button className="tw-flex tw-flex-row tw-items-center tw-text-lg" onClick={goBack}>
              <ChevronLeft size={18} />
              <div className="tw-ml-4">Back</div>
            </Button>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-w-1/2 tw-my-4 tw-bg-neutral-grey-5 tw-p-2 tw-px-4">
            <Calendar width={120} height={120} />
            <small className="tw-ml-4">
              <div>
                We are unable to manually link your bank account for you at this time. Please contact{' '}
                <a
                  className="tw-font-semibold tw-text-primary-dark-green"
                  target="_blank"
                  href={`mailto:${config.supportEmail}`}
                  rel="noreferrer"
                >
                  {config.supportEmail}
                </a>{' '}
                for assistance.
              </div>
            </small>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddBankAccount;
