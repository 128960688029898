import React from 'react';
import cx from 'classnames';
import { AiOutlineReload } from 'react-icons/ai';

import { EXTERNAL_TRANSACTION_CLEARING_MESSAGE } from 'constants/index';
import { formatDateTime } from 'utility/date';
import Banner, { BANNER_TYPES } from 'components/Banner';
import Button, { ButtonSize } from 'components/Button';
import { Loaders } from 'components/cards/Loader';
import { useTransactionResponseDetails } from './hooks';
import { TransactionDetails } from '../../../Review/components';
import { TransactionResponseDetailsProps } from './TransactionResponseDetails.types';

const TransactionResponseDetails = ({
  transaction,
  alignStatuses,
  retryPayBalance,
  hideTransactionLabels,
}: TransactionResponseDetailsProps) => {
  const { isSuccessful, transactionGroupId, initiatedAt, isFromBankAccount, handleRetryPayBalance, isLoading } =
    useTransactionResponseDetails({
      transaction,
      retryPayBalance,
    });

  const statusType = isSuccessful ? BANNER_TYPES.success : BANNER_TYPES.error;
  const statusMessage = isSuccessful
    ? (isFromBankAccount && EXTERNAL_TRANSACTION_CLEARING_MESSAGE) || 'Your transfer has been completed.'
    : 'Your transfer request could not be completed. Please select retry or contact support.';

  if (isLoading) return <Loaders.Light />;

  return (
    <div className="tw-flex tw-flex-col tw-items-start tw-gap-6">
      {!hideTransactionLabels && (
        <div className="tw-font-bold tw-text-neutral-grey-1">{transaction.originalAmount.currency} Card Payment</div>
      )}

      <div className={cx('tw-flex tw-items-center', alignStatuses && 'tw-h-32')}>
        <Banner isShowIcon type={statusType} message={statusMessage} className="tw-gap-1 tw-p-2" />
      </div>

      <TransactionDetails transaction={transaction} />

      {transactionGroupId && (
        <div>
          <div className="tw-text-sm tw-text-neutral-grey-1">Reference ID</div>
          <p className="tw-mt-1">{transactionGroupId}</p>
        </div>
      )}

      {initiatedAt && (
        <div>
          <div className="tw-text-sm tw-text-neutral-grey-1">Initiated at</div>
          <p className="tw-mt-1">{formatDateTime(initiatedAt)}</p>
        </div>
      )}

      {!transactionGroupId && (
        <Button primary size={ButtonSize.sm} onClick={handleRetryPayBalance} className="tw-mt-2">
          Retry <AiOutlineReload className="tw-ml-1" />
        </Button>
      )}
    </div>
  );
};

export default TransactionResponseDetails;
