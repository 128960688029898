import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { BsChevronDown } from 'react-icons/bs';

export const GenericDropDown = ({ label, optionsList, selectedValue, onChange, className = '' }) => {
  const isSelected = (selectedValue, option) => selectedValue && selectedValue === option.toLowerCase();

  return (
    <Listbox value={selectedValue} onChange={onChange}>
      <div className={`tw-relative tw-p-2 ${className}`}>
        <Listbox.Button className="tw-flex tw-items-center tw-cursor-pointer tw-p-2">
          <small className="tw-mr-2">{`${label}  ${
            selectedValue && `(${selectedValue.charAt(0).toUpperCase()}${selectedValue.slice(1)})`
          }`}</small>
          <BsChevronDown size={14} className="tw-text-neutral-grey-2" />
        </Listbox.Button>
        <Transition
          unmount={false}
          as={React.Fragment}
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Listbox.Options className="tw-absolute tw-z-40 tw-w-36 tw-py-1 tw-overflow-auto tw-bg-neutral-light tw-rounded-md tw-shadow-notification">
            {optionsList.map((option) => {
              return (
                <Listbox.Option
                  key={option.toLowerCase()}
                  value={option.toLowerCase()}
                  selected={isSelected(selectedValue, option)}
                  className={`tw-px-2 tw-py-1 tw-cursor-pointer tw-text-neutral-grey-2 hover:tw-text-neutral-light hover:tw-bg-primary-dark-green ${
                    isSelected(selectedValue, option) && 'tw-text-primary-dark-green tw-bg-primary-light-green'
                  }`}
                >
                  <small>{option}</small>
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
