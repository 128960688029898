import React from 'react';

import { BiChevronRight } from 'react-icons/bi';

import { tableHeaders } from './constants';
import { FormFooter } from 'components/UI';
import CardRows from './components/CardRows';
import useReviewSelection from './hooks/useReviewSelection';
import Button, { ButtonSize } from 'components/Button';

const ReviewSelection = () => {
  const { onPrevStep, onNextStep, cards, toggleUpdateAddressForCardId } = useReviewSelection();

  return (
    <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-flex-grow tw-items-center">
      <div className="tw-flex tw-flex-col tw-gap-4 tw-py-4 tw-w-full lg:tw-w-4/5 xl:tw-w-2/3 tw-max-w-screen-lg tw-mx-auto tw-px-4 lg:tw-px-0">
        As you have chosen to re-create your physical cards, we will produce and ship new versions of all of your active
        physical cards to you. Please confirm the delivery address where you would like each card shipped to.
        <div className="tw-flex tw-flex-col lg:tw-w-full tw-gap-1 lg:tw-gap-4 tw-pt-4 lg:tw-pt-0 tw-px-2 lg:tw-px-0 tw-mx-4 lg:tw-mx-0 lg:tw-mb-6 tw-bg-neutral-light">
          <div className="tw-flex tw-gap-4 tw-bg-neutral-grey-5 tw-rounded-md tw-px-2 tw-py-3 tw-w-full">
            {tableHeaders.map(({ text, className }, index) => (
              <div key={index} className={`tw-text-sm tw-text-neutral-grey-1 ${className}`}>
                {text}
              </div>
            ))}
          </div>
          <CardRows cards={cards} toggleUpdateAddressForCardId={toggleUpdateAddressForCardId} />
        </div>
        <div className="tw-flex tw-justify-end tw-align-middle tw-text-base tw-text-primary-dark-green tw-p-0 tw-underline">
          <Button
            data-testid="update-credit-card-address-btn"
            onClick={() => toggleUpdateAddressForCardId(null)}
            className="tw-flex tw-items-center tw-mx-4"
            size={ButtonSize.sm}
          >
            Update Address for All Cards
            <BiChevronRight size={28} />
          </Button>
        </div>
      </div>
      <div className="tw-w-full tw-border-b tw-border-neutral-grey-4 tw-bg-neutral-grey-4.5 tw-bottom-0 tw-rounded-b-md tw-flex tw-justify-center">
        <FormFooter
          onCancel={onPrevStep}
          cancelButtonLabel="Go Back"
          onSubmit={onNextStep}
          submitButtonLabel="Continue"
          rootClass="tw-w-2/3 tw-py-6 tw-flex tw-justify-between"
        />
      </div>
    </div>
  );
};

export default ReviewSelection;
