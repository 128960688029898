import { useContext, useEffect, useCallback } from 'react';
import { useForm as useFormHook } from 'react-hook-form';
import { useMutation, useLazyQuery, ApolloError } from '@apollo/client';
import qs from 'query-string';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import history from 'history.js';
import { AuthContext } from 'context/Auth';
import { PAYOR_SIGN_UP } from 'graphql/user';
import { GET_PAYOR_PAYMENT_REQUEST } from 'graphql/invoicing';
import { PayorSignUpPayload } from '../Form.types';
import { PayorPaymentRequest, PaymentRequestStatus } from 'types/invoicing';
import { PAYOR_ONBOARDING_STEP_URLS } from 'components/onboarding/PayorOnboarding/constants';

const useForm = () => {
  const { signIn, isSigningIn } = useContext(AuthContext) as unknown as {
    signIn: (email: string, password: string) => void;
    isSigningIn: boolean;
  };

  const [payorSignUp, { loading: isSigningUp, error }] = useMutation<{
    payorSignUp: (data: PayorSignUpPayload) => { data: { payorSignUp: { id: string } } };
  }>(PAYOR_SIGN_UP);

  const [
    getPaymentRequest,
    { data: paymentRequestData, loading: isPaymentRequestLoading, error: paymentRequestError },
  ] = useLazyQuery<{
    payorPaymentRequest: PayorPaymentRequest;
  }>(GET_PAYOR_PAYMENT_REQUEST);

  const { sgid } = qs.parse(location.search) as {
    sgid?: string;
  };

  const form = useFormHook();
  const isSubmitting = isSigningIn || isSigningUp || isPaymentRequestLoading;
  const { register, handleSubmit, watch, setValue } = form;
  const currentPassword = watch('password');

  const isInvalidToken = !sgid;

  const payorEmail = get(paymentRequestData, 'payorPaymentRequest.payor.email', '');
  const status = get(paymentRequestData, 'payorPaymentRequest.status', '');

  const isAvailableForProcessing =
    status && ![PaymentRequestStatus.pending, PaymentRequestStatus.overdue].includes(status);

  const isUnauthorized = isInvalidToken || isAvailableForProcessing;

  const onSubmit = useCallback(
    async (data: Omit<PayorSignUpPayload, 'signedId'>) => {
      if (isUnauthorized) return;

      const { password } = data;

      try {
        const payload = { variables: { ...data, email: payorEmail, signedId: sgid } };
        const response = await payorSignUp(payload);

        if (response?.data?.payorSignUp) {
          await signIn(payorEmail, password);
          history.push(`${PAYOR_ONBOARDING_STEP_URLS.connect_bank}?sgid=${sgid}`);
        }
      } catch (err) {
        console.error(err);
        toast.error(`Error: ${(err as ApolloError)?.message || 'Please try again'}`);
      }
    },
    [sgid, isUnauthorized, payorEmail, payorSignUp, signIn]
  );

  useEffect(() => {
    if (!sgid) {
      toast.error('Invalid invitation. This invitation is expired or no longer valid.', { autoClose: false });
      return;
    }

    getPaymentRequest({ variables: { signedId: sgid } });
  }, [paymentRequestData, sgid]);

  useEffect(() => {
    if (isAvailableForProcessing) {
      toast.error('This Payment Request was already processed, expired or rejected.', {
        autoClose: false,
      });
    }
  }, [isAvailableForProcessing]);

  useEffect(() => {
    setValue('email', payorEmail);
  }, [setValue, payorEmail]);

  useEffect(() => {
    if (paymentRequestError?.message) {
      toast.error(paymentRequestError.message, { autoClose: false });
      return;
    }
  }, [paymentRequestError?.message]);

  return {
    form,
    isSubmitting,
    isUnauthorized,
    currentPassword,
    register,
    handleSubmit,
    onSubmit,
    error: error || paymentRequestError,
  };
};

export default useForm;
