import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';

import { GET_AUTH_URL } from '../../../graphql/integrations';

const Stripe = ({ isOnboarding, isPreApproval, className = 'w-50' }) => {
  const [getAuthUrl, { loading, data }] = useLazyQuery(GET_AUTH_URL, {
    variables: { integration: 'stripe', onboarding: isOnboarding, preApproval: isPreApproval },
  });

  const handleClick = () => {
    if (!loading) getAuthUrl();
  };

  useEffect(() => {
    if (data) {
      const { authUrl } = _.get(data, 'integrationsAuth') || {};

      if (authUrl) window.location.href = authUrl;
    }
  }, [data]);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center" onClick={handleClick}>
      {loading ? (
        <div style={{ minWidth: '30px' }} className="spinner-border" role="status" />
      ) : (
        <img className={className} src="https://loop-public.s3.ca-central-1.amazonaws.com/stripe-logo.png" />
      )}
    </div>
  );
};

export default Stripe;
