import { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { useToggle } from 'hooks';
import { CardAutopayContext } from 'components/payments/CardAutopay/context/CardAutopay';

const usePADAgreement = () => {
  const form = useForm();
  const { register } = form;

  const { isOpen: isShowPADModal, open: openPADModal, close: closePADModal } = useToggle();

  const { fromAccountId } = useContext(CardAutopayContext);

  return { register, isShowPADModal, openPADModal, closePADModal, fromAccountId };
};

export default usePADAgreement;
