import { notificationTooltips } from 'constants/index';

export const NotificationTooltip = (group, settingsKey) => {
  if (
    Object.prototype.hasOwnProperty.call(notificationTooltips, group) &&
    Object.prototype.hasOwnProperty.call(notificationTooltips[group], settingsKey)
  ) {
    return notificationTooltips[group][settingsKey];
  }
  return '';
};
