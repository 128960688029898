import React from 'react';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import { WalletTransactionsProvider } from 'context/AccountingServicesWalletTransactions';
import { WalletTransactionsFilterContextProvider } from 'components/accountingServiceTransactions/context/WalletTransactionsFilterContext';
import { AccountingIntegrationCompanyPreferenceContextProvider } from '../../context/AccountingIntegrationCompanyPreferenceContext';
import WalletTransactionsAccountingServicePush from 'components/Transactions/WalletTransactionsAccountingServicePush';

const WalletTransactionsPushLanding = () => {
  return (
    <DashboardLayout>
      <WalletTransactionsFilterContextProvider>
        <WalletTransactionsProvider>
          <AccountingIntegrationCompanyPreferenceContextProvider>
            <WalletTransactionsAccountingServicePush />
          </AccountingIntegrationCompanyPreferenceContextProvider>
        </WalletTransactionsProvider>
      </WalletTransactionsFilterContextProvider>
    </DashboardLayout>
  );
};

export default WalletTransactionsPushLanding;
