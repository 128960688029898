import React from 'react';
import cx from 'classnames';

import { Select } from 'components/FormFields/v2';
import { FromAccountProps } from './FromAccount.types';
import { useFromAccount } from './hook';

const FromAccount = ({
  availableFromAccountIds,
  transactionOriginalCurrency,
  selectedAmountInThisCurrency,
  isDisabled,
  isFxAmount,
  isUnselected,
  isMinDuePayment,
}: FromAccountProps) => {
  const { fromAccountsOptions, register } = useFromAccount({
    availableFromAccountIds,
    transactionOriginalCurrency,
    selectedAmountInThisCurrency,
    isDisabled,
  });

  return (
    <div className="tw-flex tw-flex-col">
      <Select
        name={`fromAccounts.${transactionOriginalCurrency}`}
        options={fromAccountsOptions}
        placeholder={isUnselected ? 'Select/deselect account' : 'Select account'}
        ref={register()}
        disabled={isDisabled}
        title={isDisabled ? 'No outstanding balance' : undefined}
        unselected={isUnselected}
        rootClass={cx('FromAccount_select', isFxAmount ? 'tw-mb-9' : 'tw-mb-2')}
      />
      {isDisabled && isMinDuePayment && (
        <span className="tw-text-xs">
          Minimum Due payments are not
          <br /> available for Overdue statements
        </span>
      )}
    </div>
  );
};

export default FromAccount;
