import { gql } from '@apollo/client';

export const CREATE_CARD_RECURRING_PAYMENT = gql`
  mutation CreateCardRecurringPayment($fromAccount: String!, $paymentType: String!) {
    createCardRecurringPayment(fromAccount: $fromAccount, paymentType: $paymentType) {
      id
    }
  }
`;

export const UPDATE_CARD_RECURRING_PAYMENT = gql`
  mutation UpdateCardRecurringPayment($recurringPaymentId: ID!, $enabled: Boolean!, $fromAccount: String!) {
    updateCardRecurringPayment(recurringPaymentId: $recurringPaymentId, enabled: $enabled, fromAccount: $fromAccount)
  }
`;

export const GET_CARD_RECURRING_PAYMENT = gql`
  query CardRecurringPayment {
    cardRecurringPayment {
      id
      fromAccount
      fromAccountLabel
      dueDay
      processingPaymentDate
      paymentType
      enabled
      currency
    }
  }
`;

export const GET_CARD_RECURRING_PAYMENT_INFO = gql`
  query CardRecurringPaymentInfo {
    cardRecurringPaymentInfo {
      chargeNow
    }
  }
`;
