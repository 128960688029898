import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const CheckmarksAndStars = ({ width = '178', height = '146', vbWidth = '178', vbHeight = '146', ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      {...other}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M163.072 89.9883C163.284 89.9297 163.422 90.2062 163.247 90.3398L143.183 105.702C143.118 105.751 143.09 105.835 143.112 105.914L149.847 130.27C149.905 130.481 149.629 130.619 149.495 130.444L134.133 110.38C134.084 110.316 134 110.287 133.921 110.309L109.565 117.044C109.353 117.102 109.216 116.826 109.39 116.692L129.455 101.33C129.519 101.281 129.547 101.197 129.526 101.118L122.791 76.7625C122.732 76.5506 123.009 76.413 123.143 76.5876L138.505 96.6517C138.554 96.7164 138.638 96.7446 138.717 96.7229L163.072 89.9883Z"
        fill="#EEA530"
      />
      <path
        d="M30.6201 11.9588C30.8023 11.8359 31.0207 12.0542 30.8977 12.2365L24.5 21.7183C24.4544 21.7859 24.4544 21.8744 24.5 21.942L30.8977 31.4239C31.0207 31.6061 30.8023 31.8245 30.6201 31.7015L21.1382 25.3038C21.0706 25.2582 20.9821 25.2582 20.9145 25.3038L11.4327 31.7015C11.2504 31.8245 11.032 31.6061 11.155 31.4239L17.5527 21.942C17.5983 21.8744 17.5983 21.7859 17.5527 21.7183L11.155 12.2365C11.032 12.0542 11.2504 11.8359 11.4327 11.9588L20.9145 18.3565C20.9821 18.4021 21.0706 18.4021 21.1382 18.3565L30.6201 11.9588Z"
        fill="#EEA530"
      />
      <circle cx="86.9277" cy="60.249" r="42.6728" fill="#D0E7DA" />
      <path
        d="M118.505 55.1282C118.505 33.9251 101.303 16.7227 80.0998 16.7227C58.8968 16.7227 41.6943 33.9251 41.6943 55.1282C41.6943 76.3312 58.8968 93.5337 80.0998 93.5337C101.303 93.5337 118.505 76.3312 118.505 55.1282Z"
        stroke={primaryDarkGreenColor}
        strokeMiterlimit="10"
      />
      <path
        d="M99.303 39.126L72.4191 71.1306L60.8975 58.3287"
        stroke={primaryDarkGreenColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5613 104.112C38.5613 95.631 31.6803 88.75 23.1991 88.75C14.7179 88.75 7.83691 95.631 7.83691 104.112C7.83691 112.593 14.7179 119.474 23.1991 119.474C31.6803 119.474 38.5613 112.593 38.5613 104.112Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeMiterlimit="10"
      />
      <path
        d="M30.8798 97.7109L20.1262 110.513L15.5176 105.392"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckmarksAndStars;
