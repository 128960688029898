import React from 'react';

const DocumentTextOutline = ({ size = 20, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.0625 9.07471V17.0625C17.0625 17.5846 16.8551 18.0854 16.4859 18.4546C16.1167 18.8238 15.6159 19.0312 15.0938 19.0312H5.90625C5.38411 19.0312 4.88335 18.8238 4.51413 18.4546C4.14492 18.0854 3.9375 17.5846 3.9375 17.0625V3.9375C3.9375 3.41536 4.14492 2.9146 4.51413 2.54538C4.88335 2.17617 5.38411 1.96875 5.90625 1.96875H9.95654C10.3045 1.9688 10.6382 2.10704 10.8843 2.35307L16.6782 8.14693C16.9242 8.39302 17.0624 8.72673 17.0625 9.07471Z"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 2.29688V7.21875C10.5 7.56685 10.6383 7.90069 10.8844 8.14683C11.1306 8.39297 11.4644 8.53125 11.8125 8.53125H16.7344"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.21875 11.8125H13.7812" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.21875 15.0938H13.7812" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DocumentTextOutline;
