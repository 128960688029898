import React from 'react';
import { FormProvider } from 'react-hook-form';

import CreditAgreementV2 from 'components/creditCards/agreements/CreditAgreementV2';
import { TermContainer } from './components';
import { FormFooter, ProgressBar } from 'components/UI';
import useKeyTerms from './hooks/useKeyTerms';
import { Checkbox } from 'components/FormFields/v2';

const KeyTerms = () => {
  const {
    handleOnPreviousStep,
    handleOnNextStep,
    currentStep,
    terms,
    steps,
    form,
    register,
    handleSubmit,
    firstName,
    lastName,
    isSubmitting,
  } = useKeyTerms();

  return (
    <FormProvider {...form}>
      <form
        className="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-flex-grow"
        onSubmit={handleSubmit(handleOnNextStep)}
      >
        <div className="tw-flex tw-flex-col tw-gap-4 tw-py-8 tw-w-full lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0">
          <ProgressBar className="tw-mb-4" currentStepIndex={currentStep} stepLabelsAndIndexes={steps} />
          <div className="tw-flex tw-flex-col tw-gap-4 md:tw-px-10">
            {terms.map((term, index) => (
              <TermContainer key={index} graphic={term.graphic} title={term.title} content={term.content} />
            ))}
          </div>
          <div className="tw-py-4 tw-border-b tw-border-neutral-grey-4">
            <div className="tw-font-semibold">Credit Agreement</div>
          </div>
          <div className="tw-my-2" id="agreements" style={{ overflowY: 'scroll', height: '300px' }}>
            <div className="tw-mt-6" id="credit-agreement">
              <CreditAgreementV2 />
            </div>
          </div>
          <div className="tw-py-4 tw-border-t tw-border-neutral-grey-4">
            <Checkbox
              name="acceptedCreditAgreement"
              label={<p>{`I, ${firstName} ${lastName}, have read and agree to the credit agreement`}</p>}
              defaultChecked={false}
              ref={register({ required: true })}
              required
            />
          </div>
        </div>
        <div className="tw-bg-neutral-grey-4.5 tw-w-full tw-pt-4 tw-pb-8 tw-rounded-b-md tw-overflow-hidden tw-mt-auto">
          <FormFooter
            cancelButtonLabel="Go Back"
            onCancel={handleOnPreviousStep}
            loading={isSubmitting}
            submitButtonLabel="Create Card"
            rootClass="lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0"
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default KeyTerms;
