import React from 'react';

import InfoTooltip from 'components/InfoTooltip';
import { usePasswordStrengthMeter } from './hooks';
import { PASSWORD_WARNING, SPEC_SYMBOLS } from './constants';

import styles from './PasswordStrengthMeter.module.scss';

const PasswordStrengthMeter = ({ password = '', showScore = true }) => {
  const {
    score,
    color,
    status,
    isPasswordEightCharsLong,
    doesPasswordContainLetter,
    doesPasswordContainNum,
    doesPasswordContainSpecSymbols,
  } = usePasswordStrengthMeter({ password });

  return (
    <div>
      {showScore && (
        <div className="tw-flex tw-items-center">
          <div className="tw-w-full tw-h-2 tw-grid tw-grid-rows-1 tw-grid-flow-col tw-gap-4">
            {Array.from(Array(4).keys()).map((index) => {
              const isColored = index + 1 <= score;

              return (
                <div
                  key={index}
                  className={`tw-h-1 tw-border-b${isColored ? '-2' : ''} ${
                    isColored ? `tw-border-semantic-${color}` : 'tw-border-neutral-grey-3'
                  }`}
                />
              );
            })}
          </div>
          <small className={`tw-text-semantic-${color} tw-ml-2`}>{status}</small>
        </div>
      )}
      <ul className={styles.checkList}>
        <li className={`${styles.check} ${isPasswordEightCharsLong ? styles.success : ''}`}>
          {PASSWORD_WARNING.LENGTH}
        </li>
        <li className={`${styles.check} ${doesPasswordContainLetter ? styles.success : ''}`}>
          {PASSWORD_WARNING.LETTER}
        </li>
        <li className={`${styles.check} ${doesPasswordContainSpecSymbols ? styles.success : ''}`}>
          {PASSWORD_WARNING.SPEC}&nbsp;
          <InfoTooltip message={SPEC_SYMBOLS} />
        </li>
        <li className={`${styles.check} ${doesPasswordContainNum ? styles.success : ''}`}>{PASSWORD_WARNING.DIGIT}</li>
      </ul>
    </div>
  );
};

export default PasswordStrengthMeter;
