import React from 'react';

import { UPDATE_TRANSACTION_CACHE } from 'graphql/transactions';
import { TRANSACTION_TYPES, PAYMENT_AND_DEPOSIT_TYPES, TRANSACTION_STATUS } from 'constants/index';
import { formatDateTime } from 'utility/date';
import ReactTooltip from 'react-tooltip';

import CoinStackSendFunds from 'components/svg/CoinStackSendFunds';
import CoinStackPending from 'components/svg/CoinStackPending';
import CoinStackFailed from 'components/svg/CoinStackFailed';
import CoinStackRefunded from 'components/svg/CoinStackRefunded';
import CoinStackFX from 'components/svg/CoinStackFX';
// import CoinStackReceiveFunds from 'components/svg/CoinStackReceiveFunds';
import CardFailed from 'components/svg/CardFailed';
import CardFX from 'components/svg/CardFX';
import CardReceiveFunds from 'components/svg/CardReceiveFunds';
import CardPending from 'components/svg/CardPending';
import CardSendFunds from 'components/svg/CardSendFunds';
import CardRefunded from 'components/svg/CardRefunded';
import BankFailed from 'components/svg/BankFailed';
import BankFX from 'components/svg/BankFX';
import BankReceiveFunds from 'components/svg/BankReceiveFunds';
import BankPending from 'components/svg/BankPending';
import BankSendFunds from 'components/svg/BankSendFunds';
import BankRefunded from 'components/svg/BankRefunded';
import RepeatOutline from 'components/svg/RepeatOutline';

export const cacheUpdateOptions = ({ key, getTransactionFromKey }) => ({
  update(cache, { data }) {
    cache.modify({
      fields: {
        transactions(existingTransactions = []) {
          const transaction = getTransactionFromKey(data[key]);
          if (!transaction) return existingTransactions;

          const ref = cache.writeFragment({
            data: transaction,
            fragment: UPDATE_TRANSACTION_CACHE,
          });

          return [...existingTransactions, ref];
        },
      },
    });
  },
});

export const formatTransactionName = (name) => {
  if (!name) return '';
  if (name.includes('Loop Wallet - ')) return name.replace('Loop Wallet - ', '').replace('Balance', 'Wallet');
  if (name.includes('Loop Card - ')) return name.replace('Loop Card - ', '').replace('Balance', 'Card');
  if (name.includes('Line Of Credit - '))
    return name.replace('Line Of Credit - ', '').replace('Balance', 'Line Of Credit');

  return name;
};

export const mapTransactionsByDate = (transactions, dateAttribute = 'createdAt') => {
  const mappedTransactions =
    transactions?.reduce((map, transaction) => {
      const date = formatDateTime(transaction[dateAttribute]);
      const transactionsForDate = map[date] || [];

      return { ...map, [date]: [...transactionsForDate, transaction] };
    }, {}) || {};

  return mappedTransactions;
};

export const TooltipIcon = ({ IconComponent, tooltipText, size }) => (
  <div data-tip={tooltipText}>
    <IconComponent size={size} />
    <ReactTooltip />
  </div>
);

export const getTransactionTypeIcon = (transaction, size = 25) => {
  const { from, to, status, type, fxExchangeRate, cardTransaction } = transaction;
  const isFxTransaction = !!fxExchangeRate;
  const possibleWallets = ['Loop CAD Account', 'Loop USD Account', 'Loop EUR Account', 'Loop GBP Account'];
  const isFromWallet = possibleWallets.includes(from);
  const isToWallet = possibleWallets.includes(to);
  const isFromLineOfCredit = from.includes('Line Of Credit');
  const isToLineOfCredit = to.includes('Line Of Credit');
  const isFromCard = type === TRANSACTION_TYPES.WITHDRAWAL && cardTransaction;
  const isToCard = to.includes('Loop Card');
  const isBetweenLoopProducts =
    (isFromWallet && (isToWallet || isToLineOfCredit || isToCard)) ||
    (isFromLineOfCredit && (isToWallet || isToCard)) ||
    (isFromCard && (isToWallet || isToLineOfCredit));

  if (status === TRANSACTION_STATUS.FLAGGED_FOR_REVIEW) {
    return <TooltipIcon IconComponent={CoinStackPending} tooltipText="Pending" size={size} />;
  }
  if (status === TRANSACTION_STATUS.HELD) {
    return <TooltipIcon IconComponent={CoinStackPending} tooltipText="Pending" size={size} />;
  }

  if (isFromLineOfCredit) {
    switch (status) {
      case TRANSACTION_STATUS.POSTED:
        return <TooltipIcon IconComponent={CoinStackSendFunds} tooltipText="Credit" size={size} />;
      case TRANSACTION_STATUS.PENDING:
      case TRANSACTION_STATUS.PROCESSING:
        return <TooltipIcon IconComponent={CoinStackPending} tooltipText="Pending" size={size} />;
      case TRANSACTION_STATUS.DENIED:
        return <TooltipIcon IconComponent={CoinStackFailed} tooltipText="Failed" size={size} />;
      default:
        null;
    }
    // NOTE: this won't show correctly until backend is fixed to display conversion transactions properly
    if (isFxTransaction && status === TRANSACTION_STATUS.POSTED && isBetweenLoopProducts)
      return <TooltipIcon IconComponent={CoinStackFX} tooltipText="Conversion" size={size} />;
  }

  if (isToLineOfCredit) {
    // TODO: uncomment when backend is fixed to display deposits from external accounts properly
    // if (type === TRANSACTION_TYPES.DEPOSIT && status === TRANSACTION_STATUS.POSTED)
    //   return (
    //     <TooltipIcon IconComponent={CoinStackReceiveFunds} tooltipText="Received" size={size} />
    //   );
    if (type === TRANSACTION_TYPES.CREDIT)
      return <TooltipIcon IconComponent={CoinStackRefunded} tooltipText="Credit" size={size} />;
  }

  if (isFromWallet) {
    if (isFxTransaction && status === TRANSACTION_STATUS.POSTED && isBetweenLoopProducts)
      return <TooltipIcon IconComponent={BankFX} tooltipText="Conversion" size={size} />;

    switch (status) {
      case TRANSACTION_STATUS.POSTED:
        switch (type) {
          case TRANSACTION_TYPES.WITHDRAWAL:
          case TRANSACTION_TYPES.MONTHLY_PAYMENT:
          case TRANSACTION_TYPES.BALANCE_REPAYMENT:
            return <TooltipIcon IconComponent={BankSendFunds} tooltipText="Sent" size={size} />;
        }
        break;
      case TRANSACTION_STATUS.DENIED:
        return <TooltipIcon IconComponent={BankFailed} tooltipText="Failed" size={size} />;
      default:
        null;
    }
  }

  if (isToWallet) {
    switch (status) {
      case TRANSACTION_STATUS.POSTED:
        switch (type) {
          case TRANSACTION_TYPES.DEPOSIT:
          case TRANSACTION_TYPES.BALANCE_REPAYMENT:
            return <TooltipIcon IconComponent={BankReceiveFunds} tooltipText="Received" size={size} />;
          case TRANSACTION_TYPES.CREDIT:
            return <TooltipIcon IconComponent={BankRefunded} tooltipText="Credit" size={size} />;
        }
        break;
      default:
        null;
    }
  }

  if (isFromWallet || isToWallet) {
    if (status === TRANSACTION_STATUS.PENDING || status === TRANSACTION_STATUS.PROCESSING)
      return <TooltipIcon IconComponent={BankPending} tooltipText="Pending" size={size} />;
  }

  if (cardTransaction) {
    if (isFxTransaction && status === TRANSACTION_STATUS.POSTED && isBetweenLoopProducts)
      return <TooltipIcon IconComponent={CardFX} tooltipText="Conversion" size={size} />;

    switch (status) {
      case TRANSACTION_STATUS.POSTED:
        switch (type) {
          case TRANSACTION_TYPES.DEPOSIT:
          case TRANSACTION_TYPES.BALANCE_REPAYMENT:
            return <TooltipIcon IconComponent={CardReceiveFunds} tooltipText="Received" size={size} />;
          case TRANSACTION_TYPES.WITHDRAWAL:
            return <TooltipIcon IconComponent={CardSendFunds} tooltipText="Sent" size={size} />;
          case TRANSACTION_TYPES.CREDIT:
          case TRANSACTION_TYPES.CANCELLATION:
            return <TooltipIcon IconComponent={CardRefunded} tooltipText="Credit" size={size} />;
          default:
            null;
        }
        break;
      case TRANSACTION_STATUS.PENDING:
      case TRANSACTION_STATUS.PROCESSING:
        return <TooltipIcon IconComponent={CardPending} tooltipText="Pending" size={size} />;
      case TRANSACTION_STATUS.DENIED:
        return <TooltipIcon IconComponent={CardFailed} tooltipText="Failed" size={size} />;
      default:
        null;
    }
  }

  // Leaving this as a default for now so we can clearly identify when a transaction type is missing/incorrect
  return <RepeatOutline size={21} />;
};

// Recent transactions
export const mapRecentTransactionsByDate = (transactions, includePayments = true) => {
  const transactionsToUse = includePayments
    ? transactions
    : transactions.filter((transaction) => !PAYMENT_AND_DEPOSIT_TYPES.includes(transaction.type));
  if (!transactionsToUse || transactionsToUse.length === 0) {
    return [];
  }

  const twoLatestDays = twoLatestTransactionDates(transactionsToUse);
  const initialTransactionsMap =
    twoLatestDays.length > 1 ? { [twoLatestDays[0]]: [], [twoLatestDays[1]]: [] } : { [twoLatestDays[0]]: [] };

  const mappedTransactions = transactionsToUse.reduce((map, transaction) => {
    const date = formatDateTime(transaction.createdAt);

    return twoLatestDays.includes(date) ? { ...map, [date]: [...map[date], transaction] } : map;
  }, initialTransactionsMap);

  return mappedTransactions;
};

const twoLatestTransactionDates = (transactions) => {
  const sortedDays = transactions
    .reduce((dates, transaction) => {
      const date = formatDateTime(transaction.createdAt);
      return !dates.includes(date) ? [...dates, date] : dates;
    }, [])
    .sort((a, b) => new Date(b) - new Date(a));

  return sortedDays.slice(0, 2);
};

export const pendingAccountDepositTransactions = ({ transactions }) => {
  const processingStatus = ['PENDING', 'PROCESSING'];
  return transactions.filter((transaction) => {
    const isProcessing = processingStatus.includes(transaction.status);
    const isPaymentOrDeposit = PAYMENT_AND_DEPOSIT_TYPES.includes(transaction.type);

    return isProcessing && isPaymentOrDeposit && transaction.expectedCompletionSpeed;
  });
};
