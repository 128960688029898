import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { subYears } from 'date-fns';
import { useMutation } from '@apollo/client';
import { Transition } from '@headlessui/react';
import { ampTrackEvent } from 'amplitude';

import config from 'config';
import { CONSENT_TO_CREDIT_CHECK } from 'graphql/user';
import { Checkbox, TextField, PhoneField, DatePicker, SubmitButton, ErrorLabel } from 'components/FormFields/v2';
import ConsentCreditReportModal from './ConsentCreditReportModal';
import AddressFields from 'components/onboarding/AddressFields';
import { GUARANTOR_BUTTON_LABELS } from './constants';

const getInitialValuesFromContact = ({ contact }) => {
  const defaultValues = { birthdate: '', livedInTheSameAddressRecently: true, otherAddress: { country: 'CA' } };
  if (!contact) return defaultValues;
  const {
    firstName,
    lastName,
    email,
    address,
    otherAdress,
    occupation,
    birthdate,
    livedInTheSameAddressRecently,
    account,
  } = contact;

  return {
    ...defaultValues,
    firstName,
    lastName,
    email,
    address,
    occupation,
    otherAdress,
    birthdate,
    livedInTheSameAddressRecently,
    phoneNumber: account && account.phone,
  };
};

const GuaranteeForm = ({ onSuccess, contact, granteeName, companyName, sgid }) => {
  const form = useForm({ defaultValues: getInitialValuesFromContact({ contact }) });
  const { register, handleSubmit, watch } = form;
  const livedInTheSameAddressRecently = watch('livedInTheSameAddressRecently');
  const creditCheckAuthorizationProvided = watch('creditCheckAuthorizationProvided');
  const [showModal, setShowModal] = useState(false);

  const handleToggleModal = () => setShowModal(!showModal);

  const [giveConsent, { loading, error }] = useMutation(CONSENT_TO_CREDIT_CHECK);
  const onSubmit = async (data) => {
    try {
      const response = await giveConsent({ variables: { signedId: sgid, ...data } });

      if (response.data && response.data.consentToCreditCheck) {
        ampTrackEvent('guarantorConsentForm: submit: success');
        onSuccess();
      }
    } catch (e) {
      console.error(e);
      ampTrackEvent('guarantorConsentForm: submit: error');
    }
  };

  return (
    <div>
      <div>
        <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="tw-space-y-4">
              <div className="tw-flex tw-flex-col tw-space-y-4 lg:tw-space-y-0 lg:tw-flex-row lg:tw-space-x-4 lg:tw-justify-between">
                <TextField
                  rootClass="tw-flex-grow"
                  name="firstName"
                  label="First name"
                  placeholder="First name"
                  required
                  ref={register({ required: true })}
                />
                <TextField
                  rootClass="tw-flex-grow"
                  name="lastName"
                  label="Last name"
                  placeholder="Last name"
                  required
                  ref={register({ required: true })}
                />
              </div>
              <div className="tw-flex tw-flex-col tw-space-y-4 lg:tw-space-y-0 lg:tw-flex-row lg:tw-space-x-4 lg:tw-justify-between">
                <TextField
                  rootClass="tw-flex-grow"
                  name="occupation"
                  label="Occupation"
                  required
                  ref={register({ required: true })}
                />
                <DatePicker
                  rootClass="tw-flex-grow"
                  name="birthdate"
                  label="Birthdate"
                  required
                  ref={register({ required: true })}
                  maxDate={subYears(new Date(), 18)}
                  placeholder="01/01/1990"
                />
              </div>
              <div className="tw-flex tw-flex-col tw-space-y-4 lg:tw-space-y-0 lg:tw-flex-row lg:tw-space-x-4 lg:tw-justify-between">
                <TextField
                  rootClass="tw-flex-grow"
                  name="email"
                  label="Email"
                  required
                  ref={register({ required: true })}
                />
                <TextField
                  rootClass="tw-flex-grow"
                  name="socialInsuranceNumber"
                  label="Social Insurance Number"
                  ref={register({
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Invalid SIN number',
                    },
                    minLength: {
                      value: 9,
                      message: 'SIN number must be nine-digits long',
                    },
                    maxLength: {
                      value: 9,
                      message: 'SIN number must be nine-digits long',
                    },
                  })}
                  data-private
                />
              </div>
              <PhoneField
                rootClass="lg:tw-w-1/2"
                name="phoneNumber"
                placeholder="647-490-4901"
                label="Phone"
                autoComplete="tel-national"
                required
                rules={{ required: true }}
              />
              <div className="tw-space-y-4">
                <h6 className="tw-mt-8">Personal address</h6>
                <AddressFields name="address" selectedCountry={watch('address.country')} />
              </div>
              <Checkbox
                name="livedInTheSameAddressRecently"
                label="I have lived at my current address for longer than two years"
                ref={register()}
              />
              <Transition
                show={!livedInTheSameAddressRecently}
                enter="tw-ease-in-out tw-duration-200"
                enterFrom="tw-opacity-0"
                enterTo="tw-opacity-100"
                leave="tw-ease-in-out tw-duration-200"
                leaveFrom="tw-opacity-100"
                leaveTo="tw-opacity-0"
              >
                <div className="tw-space-y-4">
                  <h6 className="tw-mt-8">Previous address</h6>
                  <AddressFields name="otherAddress" selectedCountry={watch('otherAddress.country')} />
                </div>
              </Transition>
              <Checkbox
                name="creditCheckAuthorizationProvided"
                label={`I consent to ${companyName} opening an account with Loop`}
                ref={register({ required: true })}
              />
              <div className="tw-flex tw-flex-col tw-pt-4 tw-space-y-6">
                {error && <ErrorLabel error={error} />}
                <p>
                  By selecting <strong>{GUARANTOR_BUTTON_LABELS.continue}</strong>, I hereby agree to the
                  <a
                    href={config.baseLegalUrl}
                    target="_blank"
                    className="text-dark tw-cursor-pointer tw-mx-2 font-weight-bold"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  and
                  <a
                    className="text-dark tw-cursor-pointer tw-ml-2 font-weight-bold"
                    onClick={handleToggleModal}
                    data-testid="consent-report-link"
                  >
                    Credit Report Terms
                  </a>
                </p>
                <SubmitButton
                  className="tw-w-full lg:tw-w-44 tw-self-end"
                  disabled={!creditCheckAuthorizationProvided || loading}
                >
                  {loading ? 'Submitting...' : GUARANTOR_BUTTON_LABELS.continue}
                </SubmitButton>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      <ConsentCreditReportModal
        show={showModal}
        onHide={handleToggleModal}
        companyName={companyName}
        granteeName={granteeName}
      />
    </div>
  );
};

export default GuaranteeForm;
