import React from 'react';
import { FormProvider } from 'react-hook-form';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import useCardChargeFee from './hooks/useCardChargeFee';
import { FormFooter, ProgressBar } from 'components/UI';
import CardImage from 'components/creditCards/components/CardImage';

const CardChargeFee = () => {
  const {
    form,
    handleSubmit,
    isSubmitting,
    handleOnNextStep,
    handleOnPreviousStep,
    currentStep,
    loadingMembers,
    chargePhysicalCardFee,
    steps,
  } = useCardChargeFee();

  return (
    <FormProvider {...form}>
      <form
        className="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-flex-grow"
        onSubmit={handleSubmit(handleOnNextStep)}
      >
        <div className="tw-flex tw-flex-col tw-gap-4 tw-py-8 tw-w-full lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0">
          <ProgressBar className="tw-mb-4" currentStepIndex={currentStep} stepLabelsAndIndexes={steps} />
          <div className="tw-h-48 tw-mx-auto">
            <CardImage isVirtual={false} isVisa isActive />
          </div>
          <div className="tw-my-4 tw-mx-10 tw-py-1 tw-px-2 tw-flex tw-items-center tw-space-x-4 tw-bg-neutral-grey-4 tw-rounded-md">
            <AiOutlineInfoCircle size={26} />
            <span className="tw-text-sm">
              Your card will be shipped to the address provided. Please allow up to 7 business days for your Loop card
              to arrive.
            </span>
          </div>
          <div className="tw-flex tw-flex-col tw-mt-2">
            <div className="tw-flex tw-justify-between ">
              Physical Card Fee
              <span className="tw-font-bold lg:tw-ml-6">{chargePhysicalCardFee ? '$5.00' : 'Free'}</span>
            </div>
            <div className="tw-flex tw-pt-4 tw-justify-between tw-pb-4 tw-border-b tw-border-neutral-grey-2">
              Shipping
              <span className="tw-font-bold lg:tw-ml-6">Free</span>
            </div>
            <div className="tw-flex tw-justify-between tw-py-4">
              Total
              <strong className="tw-text-primary-dark-green">{chargePhysicalCardFee ? '$5.00' : 'Free'}</strong>
            </div>
            <span className="tw-py-1"> The Physical card fee will be charged to your Loop card.</span>
          </div>
        </div>

        <div className="tw-bg-neutral-grey-4.5 tw-w-full tw-pt-4 tw-pb-8 tw-rounded-b-md tw-overflow-hidden tw-mt-auto">
          <FormFooter
            cancelButtonLabel="Go Back"
            onCancel={handleOnPreviousStep}
            submitButtonLabel="Order Card"
            rootClass="lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0"
            loading={isSubmitting}
            isDisabled={loadingMembers}
          />
        </div>
      </form>
    </FormProvider>
  );
};
export default CardChargeFee;
