import React from 'react';

import { InfoCard } from './InfoCard';
import { INFO_CARDS } from '../constants';

const LeftColumn = () => {
  return (
    <div className="tw-p-4 tw-space-y-4">
      {INFO_CARDS.map((card) => (
        <InfoCard key={card.title} title={card.title} icon={<card.icon />} description={card.description} />
      ))}
    </div>
  );
};

export default LeftColumn;
