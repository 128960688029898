import { PayBalanceCurrencyOptions } from 'types/payments';

export const enableAutopaySteps: { [key: string]: [number] } = {
  Details: [0],
  Complete: [1],
};

export const PAYMENT_CURRENCY_INFO = {
  [PayBalanceCurrencyOptions.ALL_IN_CAD]: {
    name: 'Pay All Balances in CAD',
  },
  [PayBalanceCurrencyOptions.ALL_IN_USD]: {
    name: 'Pay All Balances in USD',
  },
  [PayBalanceCurrencyOptions.INDIVIDUAL]: {
    name: 'In Individual Currencies',
  },
};
