import React, { useContext, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { subYears } from 'date-fns';
import { Transition } from '@headlessui/react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { USER_ROLE_TYPES } from 'constants/index';
import { COMPLETE_TEAM_INVITATION } from 'graphql/team';
import { AuthContext } from 'context/Auth';
import Layout from 'components/onboarding/Layout';
import { Loaders } from 'components/cards/Loader';
import { TextField, DatePicker, RadioField, SubmitButton } from 'components/FormFields/v2';
import AddressFields from 'components/onboarding/AddressFields';
import ArrowRight from 'components/svg/ArrowRight';

const InvitationPersonalInfo = () => {
  const { meLoading } = useContext(AuthContext);

  return (
    <Layout>
      <div className="lg:tw-pt-24 tw-flex tw-flex-col tw-items-center tw-flex-grow tw-space-y-8 tw-mb-8">
        {meLoading ? <Loaders.Light /> : <Content />}
      </div>
    </Layout>
  );
};

const Content = () => {
  const { me, meRefetch, meLoading } = useContext(AuthContext);
  const [isRefetching, setIsRefetching] = useState(false);
  const history = useHistory();
  const { firstName, lastName, email, internalContact } = me || {};
  const { role } = internalContact || {};
  const [acceptTeamInvitation, { loading: isSubmitting, error }] = useMutation(COMPLETE_TEAM_INVITATION);
  const form = useForm({
    defaultValues: {
      firstName,
      lastName,
      email,
      address: { country: 'CA' },
      otherAddress: { country: 'CA' },
      livedInTheSameAddressRecently: 'true',
    },
  });
  const { register, handleSubmit, setValue, watch } = form;
  const livedInTheSameAddressRecently = watch('livedInTheSameAddressRecently') === 'true';

  useEffect(() => {
    firstName && setValue('firstName', firstName);
    lastName && setValue('lastName', lastName);
    email && setValue('email', email);
  }, [setValue, firstName, lastName, email]);

  const onSubmit = async (data) => {
    try {
      const { birthdate, address, otherAddress, livedInTheSameAddressRecently } = data;
      const variables = {
        birthdate,
        address,
        livedInTheSameAddressRecently: livedInTheSameAddressRecently === 'true',
      };
      if (otherAddress) variables.otherAddress = otherAddress;

      const response = await acceptTeamInvitation({ variables });
      if (response && response.data.completeTeamInvitation) {
        const nextRoute =
          role === USER_ROLE_TYPES.readOnly || role === USER_ROLE_TYPES.bookkeeper
            ? '/dashboard/home'
            : '/team/waiting-room';
        setIsRefetching(true);
        await meRefetch();
        history.push(nextRoute);
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (isRefetching) return <Loaders.Light />;

  return (
    <div className="lg:tw-w-1/2">
      <h1>Finish Setting Up Your Loop Account</h1>
      <h3 className="tw-mt-4 tw-mb-8">
        To complete your account set up, we will need you to provide some additional information about yourself.
      </h3>
      <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
        <form onSubmit={handleSubmit(onSubmit)} className="tw-space-y-8">
          <strong>Your Details</strong>
          <div className="tw-flex tw-items-center tw-space-x-8">
            <TextField
              rootClass="tw-flex-grow"
              name="firstName"
              label="Legal First Name"
              ref={register({ required: true })}
              disabled
            />
            <TextField
              rootClass="tw-flex-grow"
              name="lastName"
              label="Legal Last Name"
              ref={register({ required: true })}
              disabled
            />
          </div>
          <TextField name="email" label="Email Address" ref={register({ required: true })} disabled />
          <DatePicker
            name="birthdate"
            label="Date of Birth"
            placeholder="DD/MM/YYYY"
            maxDate={subYears(new Date(), 18)}
            ref={register({ required: true })}
            required
          />
          <AddressFields
            streetAddressLabel="Personal Street Address"
            name="address"
            teamMemberSignUp
            selectedCountry={watch('address.country')}
            autocompleteCountryRestrictions={['CA', 'US']}
          />
          <div className="tw-mb-24">
            <label className="tw-text-neutral-grey-1 tw-mb-4" htmlFor="isAccountOwner">
              Have you lived at this address for two years or more?
            </label>
            <RadioField
              className="tw-overflow-hidden"
              label="Have you lived at this address for two years or more?"
              name="livedInTheSameAddressRecently"
              options={[
                { label: 'Yes', value: 'true', defaultChecked: true },
                { label: 'No', value: 'false' },
              ]}
              ref={register({ required: true })}
            />
          </div>
          <Transition
            show={!livedInTheSameAddressRecently}
            enter="tw-ease-in-out tw-duration-200"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in-out tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <AddressFields
              name="otherAddress"
              streetAddressLabel="Previous Personal Street Address"
              teamMemberSignUp
              selectedCountry={watch('otherAddress.country')}
              autocompleteCountryRestrictions={['CA', 'US']}
            />
          </Transition>
          <div className="tw-flex tw-justify-end">
            <SubmitButton className="tw-w-full lg:tw-w-40" disabled={meLoading || isSubmitting}>
              <div className="tw-flex tw-justify-center tw-items-center tw-space-x-2">
                <p className="tw-text-neutral-light">{isSubmitting ? 'Submitting...' : 'Finish'}</p>
                <ArrowRight />
              </div>
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default InvitationPersonalInfo;
