import { useQuery } from '@apollo/client';

import qs from 'query-string';
import history from 'history.js';

import { GUARANTOR_CONTACT } from 'graphql/user';
import { GUARANTOR_STEP_URLS } from '../constants';

const useGuarantorVerification = () => {
  const { sgid } = qs.parse(location.search);
  const { data, error, loading } = useQuery(GUARANTOR_CONTACT, { variables: { sgid } });

  const contact = data && data.guarantorContact;

  const environmentId = contact?.personaEnvironmentId;
  const templateId = contact?.personaTemplateId;
  const referenceId = contact?.contactId;
  const verificationCompleted = contact?.thirdPartyVerificationComplete;
  const fields = {
    nameFirst: contact?.firstName,
    nameLast: contact?.lastName,
    addressStreet1: contact?.address?.street,
    addressCity: contact?.address?.city,
    addressSubdivision: contact?.address?.countrySubdivision,
    addressPostalCode: contact?.address?.postalCode,
    addressCountryCode: contact?.address?.country,
  };

  const onComplete = () => history.push(`${GUARANTOR_STEP_URLS.complete}?sgid=${sgid}`);

  return {
    environmentId,
    templateId,
    referenceId,
    verificationCompleted,
    fields,
    error,
    loading,
    onComplete,
  };
};

export default useGuarantorVerification;
