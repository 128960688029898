import React from 'react';

import { useGetUserInfo } from 'hooks';
import { Loaders } from 'components/cards/Loader';

import topOnboardingDashboard from 'images/screenshot-top-ondoarding-dashboard.png';

const PreviewContent = () => {
  const { isLoading, firstName } = useGetUserInfo();

  if (isLoading) return <Loaders.FullScreen />;

  const title = `Hi ${firstName}, welcome to Loop`;

  return (
    <div>
      <h2 className="tw-border-b tw-border-neutral-grey-3 tw-py-2 tw-px-8">{title}</h2>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-pt-4 tw-pb-8 tw-px-8 tw-text-sm md:tw-text-base">
        <h4 className="tw-font-bold">We're Verifying Your Account</h4>
        <div className="">
          Your account is currently being verified, but you can still set-up and explore your Loop dashboard.
        </div>
        <div>
          Your verification status is shown at the top of your dashboard. If any additional information is required you
          can follow the link in the banner to submit documents.
        </div>
        <img src={topOnboardingDashboard} alt="Top dashboard with a banner" />
        <h4 className="tw-font-bold">Continue Your Account Set-up</h4>
        <div>
          While your account is being verified, you can continue setting up your Loop account from the Settings page:
        </div>
        <ul className="tw-list-disc tw-mx-4">
          <li>Connect your bank account to Loop</li>
          <li>Link additional sales channels</li>
          <li>Connect to QuickBooks or Xero</li>
        </ul>
      </div>
    </div>
  );
};
export default PreviewContent;
