import { useEffect, useContext } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';

import { AuthContext } from 'context/Auth';
import { KYC_STATUS, onboardingStatus } from 'constants/index';

//! Do NOT use this hook in Dashboard related staff
const useOnboardingRedirect = () => {
  const history = useHistory();
  const { me, meLoading: loading } = useContext(AuthContext);
  const status = get(me, 'account.onboardingStatus');
  const kycAssessmentStatus = get(me, 'account.kycAssessment.status');

  useEffect(() => {
    if (status !== onboardingStatus.COMPLETED || loading) return;

    if (kycAssessmentStatus === KYC_STATUS.APPROVED) {
      history.replace('/dashboard/home');
    } else {
      history.replace('/onboarding/dashboard/home');
    }
  }, [kycAssessmentStatus, status, loading]);

  return { loading };
};

export default useOnboardingRedirect;
