import React from 'react';

import Loader from 'components/cards/Loader';
import Layout from 'components/onboarding/Layout';
import { EligibilityModal, Header, OptionsList, SelectSalesModal } from './components';
import { useSelectAccountType } from './hooks';

const SelectAccountType = ({ loadingExternalAccounts, reload, externalAccounts }) => {
  const {
    loading,
    progress,
    hasStatus,
    isApproved,
    isRejected,
    salesChannels,
    connectedAccounts,
    isSelectSalesModalOpen,
    openSelectSalesModal,
    closeSelectSalesModal,
    isEligibilityModalOpen,
    closeEligibilityModal,
    onContinue,
    onAddMoreChannels,
    businessName,
  } = useSelectAccountType({ loadingExternalAccounts, reload, externalAccounts });

  if (loading) {
    return (
      <Layout>
        <div className="tw-flex tw-flex-col tw-justify-center tw-flex-grow" data-testid="loader">
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="tw-w-full lg:tw-w-2/3 tw-flex tw-flex-col tw-justify-center tw-flex-grow lg:tw-mt-10">
        <Header />

        <OptionsList handleContinue={onContinue} openSelectSalesModal={openSelectSalesModal} />

        <EligibilityModal
          show={isEligibilityModalOpen}
          onClose={closeEligibilityModal}
          onAddMoreChannels={onAddMoreChannels}
          onSubmit={onContinue}
          progress={progress}
          hasStatus={hasStatus}
          isApproved={isApproved}
        />

        <SelectSalesModal
          show={isSelectSalesModalOpen}
          onClose={closeSelectSalesModal}
          onSubmit={onContinue}
          salesChannels={salesChannels}
          connectedAccounts={connectedAccounts}
          hasStatus={hasStatus}
          isApproved={isApproved}
          isRejected={isRejected}
          businessName={businessName}
        />
      </div>
    </Layout>
  );
};

export default SelectAccountType;
