import React, { useContext, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { IoRadioButtonOn, IoRadioButtonOff, IoFilterOutline } from 'react-icons/io5';
import { Transition } from '@headlessui/react';

import { FilterContext } from 'components/PointsTransactions/context/FilterContext';
import Button from 'components/Button';
import { getPeriodOptions } from 'components/UI/PeriodDropdown';
import { PeriodOptionsProps } from 'components/UI/PeriodDropdown/PeriodDropdown.types';

const MobileFilter = () => {
  const { period, setPeriod } = useContext(FilterContext);

  const handleSubmit = () => {
    onClose();
  };

  const [showFilters, setShowFilters] = useState(false);
  const onClose = () => setShowFilters(false);
  const onShowFilters = () => setShowFilters(true);

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-items-center tw-justify-between">
        <IoFilterOutline data-testid="filters-button" size={24} onClick={onShowFilters} />
      </div>
      <Transition
        show={showFilters}
        as="div"
        unmount={false}
        className="tw-fixed tw-h-screen tw-w-full tw-top-0 tw-right-0 tw-shadow-notification tw-z-10 tw-bg-neutral-grey-3.5 tw-transform tw-overflow-y-scroll"
        enter="tw-ease-in-out tw-duration-300"
        enterFrom="tw-translate-x-96"
        enterTo="tw-translate-x-0"
        leave="tw-ease-in-out tw-duration-300"
        leaveFrom="tw-translate-x-0"
        leaveTo="tw-translate-x-96"
      >
        <div className="tw-bg-neutral-grey-3.5 tw-p-4">
          <div className="tw-flex tw-items-center tw-mb-8">
            <BiArrowBack size={24} className="tw-cursor-pointer" onClick={onClose} />
          </div>
          <h2>Transactions Filters</h2>

          <div className="tw-mt-4">
            <PeriodOptions selectedPeriod={period} setSelectedPeriod={setPeriod} />
          </div>
          <Button primary onClick={handleSubmit} className="tw-w-full tw-mt-8">
            Apply Filters
          </Button>
        </div>
      </Transition>
    </div>
  );
};

const PeriodOptions = ({ selectedPeriod, setSelectedPeriod }: PeriodOptionsProps) => {
  const periodOptions = getPeriodOptions();

  return (
    <>
      <div className="tw-text-sm tw-text-neutral-light-3">Period</div>
      <div className="tw-p-4 tw-mt-4 tw-bg-neutral-light tw-rounded-md">
        {periodOptions.map((option) => {
          const isSelected = selectedPeriod && selectedPeriod.key === option.key;
          const handleSelectOption = () => {
            const isSelected = selectedPeriod && selectedPeriod.key === option.key;

            isSelected ? setSelectedPeriod(null) : setSelectedPeriod(option.value);
          };

          return (
            <div
              key={option.key}
              className="tw-flex tw-items-center tw-p-2 tw-text-neutral-grey-2"
              onClick={handleSelectOption}
            >
              {isSelected ? (
                <IoRadioButtonOn size={18} className="tw-text-primary-dark-green" />
              ) : (
                <IoRadioButtonOff size={18} className="tw-text-neutral-grey-2" />
              )}
              <div className="tw-text-sm tw-ml-3 tw-text-neutral-dark">{option.key}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MobileFilter;
