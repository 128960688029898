import React from 'react';
import { TableSkeleton, PaginationSkeleton } from './components';

const ApprovalsTableSkeleton = () => {
  return (
    <>
      <TableSkeleton />
      <PaginationSkeleton />
    </>
  );
};

export default ApprovalsTableSkeleton;
