import { useMemo, useContext, useCallback } from 'react';
import { groupBy } from 'lodash';

import { Payor } from 'types/invoicing';
import { useToggle, useGetUserInfo } from 'hooks';
import { PayorsContext } from 'components/Invoices/contexts/PayorsContext';
import { PayorsListProps } from '../PayorsList.types';

const usePayorsList = ({ payors }: PayorsListProps) => {
  const { setSelectedPayorId } = useContext(PayorsContext);

  const { isKYCApproved } = useGetUserInfo();

  const { isOpen: showEditModal, open: openEditModal, close: closeEditModal } = useToggle();
  const { isOpen: showDeleteModal, open: openDeleteModal, close: closeDeleteModal } = useToggle();
  const {
    isOpen: showAddPaymentRequestModal,
    open: openAddPaymentRequestModal,
    close: closeAddPaymentRequestModal,
  } = useToggle();

  const {
    isOpen: showOnboardingPreviewModal,
    open: openOnboardingPreviewModal,
    close: onCloseOnboardingPreviewModal,
  } = useToggle();

  const dataByGroups = useMemo(() => {
    const groups = groupBy(payors, ({ name }) => name[0].toUpperCase());

    const sortedGroups: { [key: string]: Payor[] } = Object.keys(groups)
      .sort((a, b) => a.localeCompare(b))
      .reduce((acc, group) => ({ ...acc, [group]: groups[group] }), {});

    return sortedGroups;
  }, [payors]);

  const handleOpenEditModal = useCallback(
    (payorId: string) => {
      setSelectedPayorId(payorId);
      openEditModal();
    },
    [setSelectedPayorId, openEditModal]
  );

  const handleCloseEditModal = useCallback(() => {
    setSelectedPayorId('');
    closeEditModal();
  }, [setSelectedPayorId, closeEditModal]);

  const handleOpenDeleteModal = useCallback(
    (payorId: string) => {
      setSelectedPayorId(payorId);
      openDeleteModal();
    },
    [setSelectedPayorId, openDeleteModal]
  );

  const handleCloseDeleteModal = useCallback(() => {
    setSelectedPayorId('');
    closeDeleteModal();
  }, [setSelectedPayorId, closeDeleteModal]);

  const handleOpenAddPaymentRequestModal = useCallback(
    (payorId: string) => {
      setSelectedPayorId(payorId);
      isKYCApproved ? openAddPaymentRequestModal() : openOnboardingPreviewModal();
    },
    [setSelectedPayorId, openAddPaymentRequestModal, isKYCApproved, openOnboardingPreviewModal]
  );

  const handleCloseAddPaymentRequestModal = useCallback(() => {
    setSelectedPayorId('');
    isKYCApproved ? closeAddPaymentRequestModal() : onCloseOnboardingPreviewModal();
  }, [setSelectedPayorId, closeAddPaymentRequestModal, isKYCApproved, onCloseOnboardingPreviewModal]);

  return {
    dataByGroups,
    showEditModal,
    handleOpenEditModal,
    handleCloseEditModal,
    showDeleteModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    showAddPaymentRequestModal,
    handleOpenAddPaymentRequestModal,
    handleCloseAddPaymentRequestModal,
    showOnboardingPreviewModal,
    onCloseOnboardingPreviewModal,
  };
};

export default usePayorsList;
