const usePermissionToggleSection = ({
  permissions,
  isTitleChecked,
  setIsTitleChecked,
  setSelectedPermissions,
  isPermissionChecked,
  selectedPermissions,
}: {
  permissions: { label: string; value: string }[];
  setSelectedPermissions: React.Dispatch<React.SetStateAction<string[]>>;
  isPermissionChecked: (permission: string) => boolean;
  selectedPermissions: string[];
  isTitleChecked: boolean;
  setIsTitleChecked: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isSectionChecked = () => {
    const permissionsWithoutTitle = permissions.slice(1);
    return (
      permissionsWithoutTitle.filter((permission) => isPermissionChecked(permission.value)).length ===
      permissionsWithoutTitle.length
    );
  };

  const selectAllTitle = permissions[0].label;

  const onSectionToggle = () => {
    if (isTitleChecked) {
      // all permissions are selected, so remove all permissions
      setIsTitleChecked(false);
      const updatedPermissions = selectedPermissions.filter(
        (p) => !permissions.map((permission) => permission.value).includes(p)
      );
      setSelectedPermissions(updatedPermissions);
    } else {
      // add all permissions for the section
      setIsTitleChecked(true);
      setSelectedPermissions([...selectedPermissions, ...permissions.map((permission) => permission.value)]);
    }
  };

  const togglePermission = (permission: string) => {
    if (isPermissionChecked(permission)) {
      const updatedPermissions = selectedPermissions.filter((selectedPermission) => selectedPermission !== permission);
      setSelectedPermissions(updatedPermissions);
    } else {
      const updatedPermissions = selectedPermissions.concat(permission);
      setSelectedPermissions(updatedPermissions);
    }
  };

  return {
    isSectionChecked,
    onSectionToggle,
    togglePermission,
    selectAllTitle,
  };
};

export default usePermissionToggleSection;
