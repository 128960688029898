import React from 'react';

const GridOutline = (props) => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.36719 2.46875H2.78906C2.33602 2.46875 1.96875 2.83602 1.96875 3.28906V8.86719C1.96875 9.32023 2.33602 9.6875 2.78906 9.6875H8.36719C8.82023 9.6875 9.1875 9.32023 9.1875 8.86719V3.28906C9.1875 2.83602 8.82023 2.46875 8.36719 2.46875Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2109 2.46875H12.6328C12.1798 2.46875 11.8125 2.83602 11.8125 3.28906V8.86719C11.8125 9.32023 12.1798 9.6875 12.6328 9.6875H18.2109C18.664 9.6875 19.0312 9.32023 19.0312 8.86719V3.28906C19.0312 2.83602 18.664 2.46875 18.2109 2.46875Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.36719 12.3125H2.78906C2.33602 12.3125 1.96875 12.6798 1.96875 13.1328V18.7109C1.96875 19.164 2.33602 19.5312 2.78906 19.5312H8.36719C8.82023 19.5312 9.1875 19.164 9.1875 18.7109V13.1328C9.1875 12.6798 8.82023 12.3125 8.36719 12.3125Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2109 12.3125H12.6328C12.1798 12.3125 11.8125 12.6798 11.8125 13.1328V18.7109C11.8125 19.164 12.1798 19.5312 12.6328 19.5312H18.2109C18.664 19.5312 19.0312 19.164 19.0312 18.7109V13.1328C19.0312 12.6798 18.664 12.3125 18.2109 12.3125Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GridOutline;
