import { useContext, useCallback } from 'react';
import { ampTrackEvent } from 'amplitude';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { GET_PAY_CARD_BALANCE } from 'graphql/cards';
import { PAY_BALANCE } from 'graphql/payments';
import { PayBalanceCurrencyOptions, PayBalanceType, Transaction, PayBalanceTransactionResponse } from 'types/payments';
import { CardRepaymentContext } from 'context/CardRepayment';
import { useGetSubscription } from 'hooks';

const useComplete = () => {
  const { payBalanceInfo, payBalanceTransactions } = useContext(CardRepaymentContext);
  const { paymentCurrencyOption, paymentType } = payBalanceInfo || {};

  const [payBalance, { loading: isPayingBalance }] = useMutation(PAY_BALANCE, {
    refetchQueries: [{ query: GET_PAY_CARD_BALANCE }],
    awaitRefetchQueries: true,
  });

  const { isPaidSubscription } = useGetSubscription();
  const history = useHistory();

  const isFromBankAccount = payBalanceTransactions.some((txn) => txn.isFromBankAccount);

  const isSubscriptionBanner = !isPaidSubscription && isFromBankAccount;

  const navigateToSubscriptions = () => {
    ampTrackEvent('Subscriptions: Make A Payment');
    history.push('/dashboard/subscriptions');
  };

  const retryPayBalance = useCallback(
    async (txn: Transaction) => {
      const { selectedFromAccountId, originalAmount, fxAmount } = txn;
      const { currency: originalCurrency } = originalAmount;
      const amount = fxAmount || originalAmount;

      const payload = {
        from: selectedFromAccountId,
        to: originalCurrency,
        originalAmount: amount,
        chargedAmount: amount,
        payAll:
          paymentCurrencyOption === PayBalanceCurrencyOptions.ALL_IN_CAD ||
          paymentCurrencyOption === PayBalanceCurrencyOptions.ALL_IN_USD,
        payMin: paymentType === PayBalanceType.MINIMUM_DUE,
      };

      return (await payBalance({
        variables: payload,
      })) as Promise<{ data: { moveFunds: PayBalanceTransactionResponse | null } }>;
    },
    [payBalance, paymentCurrencyOption, paymentType]
  );

  return {
    transactions: payBalanceTransactions,
    isSubscriptionBanner,
    subscriptionBannerAction: navigateToSubscriptions,
    isFromBankAccount,
    retryPayBalance,
    isLoading: isPayingBalance,
    hideTransactionLabels:
      paymentType === PayBalanceType.MINIMUM_DUE ||
      paymentCurrencyOption === PayBalanceCurrencyOptions.ALL_IN_CAD ||
      paymentCurrencyOption === PayBalanceCurrencyOptions.ALL_IN_USD,
  };
};

export default useComplete;
