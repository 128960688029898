import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import config from 'config';
import { ACCEPT_WALLET_AGREEMENT } from 'graphql/wallets';
import { AuthContext } from 'context/Auth';
import TransactionalModal from 'components/Modal/v2/TransactionalModal';
import { SubmitButton, Checkbox } from 'components/FormFields/v2';

const WalletAgreementModal = ({ show, setShow }) => {
  const form = useForm();
  const { meRefetch } = useContext(AuthContext);
  const [acceptWalletAgreement, { loading }] = useMutation(ACCEPT_WALLET_AGREEMENT);

  const { register, handleSubmit, watch } = form;
  const acceptedTerms = watch('acceptedTerms');

  const onSubmit = async () => {
    try {
      const response = await acceptWalletAgreement();

      if (response && response.data.acceptWalletAgreement) {
        setShow(false);
        await meRefetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <TransactionalModal show={show}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-p-8 tw-space-y-8">
            <h5>We've updated our terms!</h5>
            <p>
              We have added some new features at Loop and have made some changes to our legal agreements in order to
              support these changes. Please review and confirm that you have reviewed our new terms found{' '}
              <a
                target="_blank"
                href={config.multiCurrencyAccountAgreement}
                className="tw-text-primary-dark-green tw-font-semibold"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
            <Checkbox
              label={
                <div className="tw-flex tw-space-x-1">
                  <small>I agree to the</small>
                  <small className="tw-text-primary-dark-green tw-font-semibold">
                    <a target="_blank" href={config.multiCurrencyAccountAgreement} rel="noopener noreferrer">
                      Loop Multi-Currency Account Agreement
                    </a>
                  </small>
                </div>
              }
              name="acceptedTerms"
              ref={register({ required: true })}
            />
          </div>
          <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex lg:tw-justify-end">
            <SubmitButton disabled={loading || !acceptedTerms} className="tw-w-full lg:tw-w-32">
              {loading ? 'Accepting...' : 'Accept'}
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </TransactionalModal>
  );
};

export default WalletAgreementModal;
