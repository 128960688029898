import React, { useContext, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
import { IoCloudUploadOutline } from 'react-icons/io5';
import cx from 'classnames';

import { AccountingServiceTransactionsContext } from 'context/AccountingServices';

import { QuickBooksAccount } from 'components/settings/AccountingServices/Account';
import { SearchVendorDropdown } from 'components/settings/AccountingServices/components/MerchantVendorMapping';

import { QboTaxCode } from '../AccountingServiceTaxCode';
import { FaChevronDown } from 'react-icons/fa';
import { formatMoneyV2 } from 'utility/currency';

import { Checkbox } from '../Checkbox';
import { ClearChange } from '../ClearChange';
import { QUICKBOOK_ALLOWED_PUSH_TYPE_TX } from 'constants/accountingService';
import { DECIMAL_VALUE_REGEX } from 'constants/regex';

const QboTx = ({
  to,
  // from,
  amount,
  transactionId,
  qboStatus,
  qboTaxId,
  qboExpenseAccountId,
  qboResponse,
  // qboExpenseAccount,
  transactionCategory,
  qboTransactionDate,
  type,
  accountName,
  receiptDetails,
  merchantVendor,
  accountingIntegrationVendor,
}) => {
  const {
    accountingIntegrationExpenseAccounts,
    selectedExpenseAccounts,
    handleChangeExpenseAccount,
    selectedDates,
    handleChangeDate,
    selectedTaxCodes,
    handleChangeTaxCode,
    selectedTransactionsToPost,
    resetDate,
    resetExpense,
    resetTaxCode,
    getSelectedExpense,
    getSelectedTaxCode,
    handleSelectToPost,
    accountingIntegrationTaxCodes,
    selectedVendorAccounts,
    handleChangeVendorAccount,
    resetVendorAccount,
  } = useContext(AccountingServiceTransactionsContext);

  const formattedAmount = formatMoneyV2({
    ...amount,
    amount: amount.amount > 0 ? -Math.abs(amount.amount) : Math.abs(amount.amount),
  });

  const isNegative = formattedAmount.includes('-');

  const selectedDate =
    transactionId in selectedDates ? selectedDates[transactionId] : new Date(`${qboTransactionDate}T00:00:00`);

  const selectedExpense = getSelectedExpense(transactionId, qboExpenseAccountId, transactionCategory);

  const selectedTaxCode = getSelectedTaxCode({
    transactionId,
    qboTaxId: qboTaxId || selectedExpense?.taxCodeRefId,
    receiptDetails,
    amount: formattedAmount.match(DECIMAL_VALUE_REGEX)[0],
  });

  const selectedToPost = selectedTransactionsToPost.includes(transactionId);

  const [openDateField, setOpenDateField] = useState(false);
  const [rowStatus, setRowStatus] = useState(qboStatus);

  useEffect(() => {
    setRowStatus(qboStatus);
  }, [qboStatus]);

  const handleOpenDateField = () => {
    setOpenDateField(!openDateField);
  };

  const handleClick = () => {
    setRowStatus('pending');
  };

  const isReadOnly = rowStatus !== 'pending';
  const selectToPostDisabled = selectedExpense?.id === '-1';
  const pushDisabled = !QUICKBOOK_ALLOWED_PUSH_TYPE_TX.includes(type?.toLowerCase()); // for now we are disabled posting credit transactions

  return (
    <>
      <div className="tw-pr-2">{merchantVendor?.merchant?.name || 'Loop'}</div>
      {isReadOnly && <div className="tw-text-right">{accountingIntegrationVendor?.name || ''}</div>}
      {!isReadOnly && (
        <div className="qboTax">
          <SearchVendorDropdown
            id={transactionId}
            selectedLabel={selectedVendorAccounts[transactionId]?.label || merchantVendor?.vendor?.name}
            selectedVal={selectedVendorAccounts[transactionId]?.id || merchantVendor?.vendor?.id}
            handleChange={(e) => e?.id && handleChangeVendorAccount(e, transactionId)}
          />
          {merchantVendor?.merchant && transactionId in selectedVendorAccounts && (
            <ClearChange onClick={() => resetVendorAccount(transactionId)} />
          )}
        </div>
      )}
      <div className="tw-pr-2">{to}</div>
      <div className={cx('tw-pr-2', !isNegative ? 'tw-text-semantic-success ' : '')}>{formattedAmount}</div>

      {isReadOnly && <div className="tw-text-right">{selectedExpense.name}</div>}
      {!isReadOnly && accountingIntegrationExpenseAccounts?.length > 0 && (
        <div className="qboTax">
          <QuickBooksAccount
            className="tw-relative"
            buttonStyle
            qbCategories={accountingIntegrationExpenseAccounts}
            qbCategory={selectedExpense}
            onChange={(value) => handleChangeExpenseAccount(value, transactionId)}
          />
          {transactionId in selectedExpenseAccounts && <ClearChange onClick={() => resetExpense(transactionId)} />}
        </div>
      )}

      {isReadOnly && <div className="tw-text-right">{selectedTaxCode?.id ? selectedTaxCode.name : ''}</div>}
      {!isReadOnly && (
        <div className="qboTax">
          <QboTaxCode
            className="tw-relative"
            accountingIntegrationTaxCodes={accountingIntegrationTaxCodes}
            selectedTaxCode={selectedTaxCode}
            onChange={(taxCode) => handleChangeTaxCode(taxCode, transactionId)}
          />
          {transactionId in selectedTaxCodes && <ClearChange onClick={() => resetTaxCode(transactionId)} />}
        </div>
      )}

      {isReadOnly && <div className="qboDate">{selectedDate?.toDateString()}</div>}
      {!isReadOnly && (
        <div className="qboDate">
          <div
            onClick={handleOpenDateField}
            className="tw-pr-2 tw-font-small tw-rounded-md tw-flex tw-items-center tw-justify-end focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green  tw-border-2 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2tw-border-none tw-p-0 tw-bg-neutral-light focus:tw-outline-none focus:tw-ring-0 "
          >
            <DatePicker
              className={'datePicker tw-bg-neutral-light'}
              selected={selectedDate}
              onChange={(date) => handleChangeDate(date, transactionId)}
              onClickOutside={handleOpenDateField}
              open={openDateField}
            />
            <strong>
              <FaChevronDown />
            </strong>
          </div>

          {transactionId in selectedDates && (
            <ClearChange
              onClick={(evt) => {
                evt.stopPropagation();
                resetDate(transactionId);
              }}
            />
          )}
        </div>
      )}

      <div
        className={cx(
          'tw-text-right',
          rowStatus === 'posted' ? 'tw-text-semantic-success' : rowStatus === 'error' && 'tw-text-semantic-error'
        )}
      >
        {qboResponse && rowStatus === 'error' && <ReactTooltip id={`error-tip-${transactionId}`} html={true} />}
        <div data-tip={`<div class='tw-w-96'>${qboResponse}</div>`} data-for={`error-tip-${transactionId}`}>
          {rowStatus}
        </div>
      </div>

      <div className="tw-justify-self-center ">
        {rowStatus === 'pending' && (
          <div>
            <ReactTooltip
              id={`select-tip-${transactionId}`}
              getContent={() =>
                (pushDisabled && `The type of this transaction is disabled to be pushed to ${accountName}`) ||
                (selectToPostDisabled && 'Please select a QBO Expense Account for this transaction before pushing')
              }
            />
            <div data-tip="" data-for={`select-tip-${transactionId}`}>
              <Checkbox
                disabled={selectToPostDisabled || pushDisabled}
                checked={!selectToPostDisabled && !pushDisabled && selectedToPost}
                onChange={(evt) => handleSelectToPost(evt, transactionId)}
              />
            </div>
          </div>
        )}
        {rowStatus === 'error' && (
          <button onClick={handleClick} className="tw-cursor-pointer" data-tip="Retry push">
            <IoCloudUploadOutline size={20} />
          </button>
        )}
      </div>
    </>
  );
};

export default QboTx;
