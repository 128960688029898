import React from 'react';

import CardsWaitingRoom from '../svg/CardsWaitingRoom';

const NoLineOfCreditBanner = () => {
  return (
    <div className="tw-w-full tw-bg-neutral-light tw-py-10 tw-px-6 tw-flex tw-flex-col tw-items-center tw-justify-center">
      <div className="lg:tw-block">
        <CardsWaitingRoom height="200" width="100%" />
      </div>
      <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-my-5 tw-px-12">
        <span className="tw-text-2xl tw-my-4">Line of Credit</span>
        <p>There is no line of credit associated with your account.</p>
      </div>
    </div>
  );
};

export default NoLineOfCreditBanner;
