import React from 'react';

import { RegistrationType } from './SignUp.types';
import {
  UserRegistrationForm,
  UserRegistrationPromoText,
  PayorRegistrationForm,
  PayorRegistrationPromoText,
} from './components';

export const getRegistrationPromoText = (type: RegistrationType) =>
  ({
    [RegistrationType.USER]: <UserRegistrationPromoText />,
    [RegistrationType.PAYOR]: <PayorRegistrationPromoText />,
  }[type]);

export const getRegistrationForm = (type: RegistrationType) =>
  ({
    [RegistrationType.USER]: <UserRegistrationForm />,
    [RegistrationType.PAYOR]: <PayorRegistrationForm />,
  }[type]);
