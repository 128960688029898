import CashOutline from 'components/svg/CashOutline';
import SettingsOutline from 'components/svg/SettingsOutline';
import { TopDropdownNavigation, SidebarItem, TabNavItem } from 'components/UI/Layout/Layout.types';

export const payorTopDropdownNavigation: TopDropdownNavigation = [
  {
    name: 'Account Settings',
    id: '/payor/portal/settings/personal',
    link: '/payor/portal/settings/personal',
    Icon: SettingsOutline,
  },
];

export const payorSideNavItems: SidebarItem[] = [
  {
    route: '/payor/portal',
    Icon: CashOutline,
    label: 'Payments',
  },
  {
    route: '/payor/portal/settings/personal',
    Icon: SettingsOutline,
    label: 'Settings',
  },
];

export const payorTabNabItems: TabNavItem[] = [
  {
    route: '/payor/portal',
    Icon: CashOutline,
    label: 'Payments',
  },
];
