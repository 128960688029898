import React from 'react';

import { Currencies } from 'constants/currencies';
import { Transaction, Money } from 'types/payments';
import InfoTooltip from 'components/InfoTooltip';
import { AmountCardWithFx } from 'components/UI';
import { INTO_TOOLTIP } from 'components/payments/CardRepayment/components/Steps/components/Details/constants';
import { FromAccount } from '../';
import { useAccounts } from './hooks';
import { TransactionProps } from '../../Transactions.types';

const Accounts = ({
  transactions,
  singleTransaction,
  isMinDuePayment,
}: TransactionProps & { transactions: Transaction[] }) => {
  const { txnAmountsByCurrency } = useAccounts({ transactions });

  return (
    <div className="tw-flex tw-flex-col xl:tw-flex-row tw-gap-2 xl:tw-gap-4 tw-items-start">
      <div className="tw-flex tw-items-center tw-gap-1 xl:tw-w-1/6 tw-h-12">
        Account to Pay From <InfoTooltip message={INTO_TOOLTIP.fromAccounts} />
      </div>
      {singleTransaction ? (
        <div className="tw-flex tw-flex-col xl:tw-flex-row tw-gap-2 xl:tw-gap-4 tw-items-start">
          <FromAccount
            transactionOriginalCurrency={singleTransaction.originalAmount.currency}
            selectedAmountInThisCurrency={singleTransaction.originalAmount.amount}
            availableFromAccountIds={singleTransaction.availableFromAccountIds}
            isDisabled={singleTransaction.isDisabled}
            isMinDuePayment={isMinDuePayment}
          />
          <div className="xl:tw-ml-8">
            <div className="tw-flex tw-flex-col xl:tw-flex-row tw-gap-2 xl:tw-gap-4 tw-items-start">
              <div className="tw-flex tw-flex-col tw-items-start tw-gap-1 tw-h-12">
                <div>Total Min Payment</div>
                <div className="tw-text-xs tw-text-neutral-grey-2">post currency conversions above</div>
              </div>
              <AmountCardWithFx
                originalAmount={singleTransaction.originalAmount as unknown as Money<Currencies>}
                isDisabled={singleTransaction.isDisabled}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="tw-flex tw-flex-col xl:tw-flex-row xl:tw-flex-wrap tw-items-start tw-gap-2 xl:tw-w-5/6 tw-mb-2">
          {transactions.map(({ originalAmount: { currency }, fxAmount, availableFromAccountIds, isDisabled }) => {
            const transactionSelectedCurrency = fxAmount?.currency || currency;

            return (
              <FromAccount
                key={currency}
                transactionOriginalCurrency={currency}
                selectedAmountInThisCurrency={txnAmountsByCurrency[transactionSelectedCurrency]}
                availableFromAccountIds={availableFromAccountIds}
                isDisabled={isDisabled}
                isFxAmount={!!fxAmount}
                isUnselected
                isMinDuePayment={isMinDuePayment}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Accounts;
