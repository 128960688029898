import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { BsChevronDown } from 'react-icons/bs';

const CurrencyDropdown = ({ currency, setCurrency }) => {
  const currencies = ['CAD', 'USD', 'EUR', 'GBP'];

  return (
    <Listbox value={currency} onChange={setCurrency}>
      <div className="tw-relative tw-p-2">
        <Listbox.Button
          data-testid="currency-dropdown"
          className="tw-relative tw-flex tw-items-center tw-cursor-pointe tw-p-2"
        >
          <small className="tw-mr-2">Currency {currency && `(${currency})`}</small>
          <BsChevronDown size={14} className="tw-text-neutral-grey-2" />
        </Listbox.Button>
        <Transition
          as={React.Fragment}
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Listbox.Options className="tw-absolute tw-z-10 tw-w-full tw-py-1 tw-overflow-auto tw-bg-neutral-light tw-rounded-md tw-shadow-lg">
            {currencies.map((option) => (
              <Listbox.Option
                key={option}
                value={option}
                selected={currency === option}
                className={`tw-px-2 tw-py-1 tw-cursor-pointer tw-text-neutral-grey-2 hover:tw-text-neutral-light hover:tw-bg-primary-dark-green ${
                  currency === option && 'tw-text-primary-dark-green tw-bg-primary-light-green'
                }`}
              >
                <small>{option}</small>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default CurrencyDropdown;
