import React, { useContext, useMemo } from 'react';
import { Transition } from '@headlessui/react';
import { BiArrowBack } from 'react-icons/bi';

import { TransactionsContext } from 'context/Transactions';
import { TransactionDetail } from '../';

export const TransactionDetailOverlay = ({ accountNames, productType }) => {
  const { selectedTransactionId, setSelectedTransactionId, transactions } = useContext(TransactionsContext);

  const transaction = useMemo(
    () => transactions?.find((transaction) => transaction.transactionId === selectedTransactionId),
    [transactions, selectedTransactionId]
  );

  const onClose = () => setSelectedTransactionId(null);

  if (!transaction) return null;

  return (
    <Transition
      show={!!transaction}
      as="div"
      className="tw-h-screen tw-w-full tw-fixed tw-top-0 tw-right-0 tw-shadow-notification tw-z-10 tw-bg-neutral-grey-3.5 tw-transform"
      enter="tw-ease-in-out tw-duration-300"
      enterFrom="tw-translate-x-96"
      enterTo="tw-translate-x-0"
      leave="tw-ease-in-out tw-duration-300"
      leaveFrom="tw-translate-x-0"
      leaveTo="tw-translate-x-96"
    >
      <TransactionDetail
        transaction={transaction}
        accountNames={accountNames}
        productType={productType}
        header={
          <div className="tw-flex tw-items-center tw-mb-8">
            <BiArrowBack size={24} className="tw-cursor-pointer" onClick={onClose} />
          </div>
        }
      />
    </Transition>
  );
};

export default TransactionDetailOverlay;
