import { useState, useEffect, useContext } from 'react';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import { useDeepEffect } from 'hooks';
import { Payor } from 'types/invoicing';
import { GET_PAYORS } from 'graphql/invoicing';
import { PayorsContext } from 'components/Invoices/contexts/PayorsContext';

const usePayors = () => {
  const { payors, setPayors, loading, setLoading } = useContext(PayorsContext);

  const [search, setSearch] = useState('');
  const [filteredPayors, setFilteredPayors] = useState(payors || []);

  const { data, loading: loadingPayors } = useQuery<{ payors: Payor[] }>(GET_PAYORS);
  const payorsData = get(data, 'payors', []);

  const isEmpty = !payors.length && !loading;

  const handleChangeSearch = (e: React.FormEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);

  useEffect(() => {
    if (!search?.length) {
      setFilteredPayors(payors);
      return;
    }

    const filtered = payors.filter(({ name }) => name.toLowerCase().indexOf(search.toLowerCase()) !== -1);

    setFilteredPayors(filtered);
  }, [search, payors]);

  useDeepEffect(() => {
    setPayors(payorsData);
    setLoading(loadingPayors);
  }, [payorsData, loadingPayors, setPayors, setLoading]);

  return {
    isEmpty,
    loading,
    filteredPayors,
    search,
    handleChangeSearch,
  };
};

export default usePayors;
