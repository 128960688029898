import React from 'react';

import { Progress } from 'components/UI';
import { useAddPaymentRequest } from './hooks';
import { Steps } from './components';
import { addPaymentRequestSteps } from './constants';

const AddPaymentRequest = ({ onFinish }: { onFinish: () => void }) => {
  const { currentStep, onPrevStep, onNextStep, errorSteps, onResetSteps, isCompleted, onComplete } =
    useAddPaymentRequest();

  return (
    <>
      <Progress
        currentStepIndex={currentStep}
        stepLabelsAndIndexes={addPaymentRequestSteps}
        errorSteps={errorSteps}
        isCompleted={isCompleted}
      />
      <Steps
        currentStep={currentStep}
        onPrevStep={onPrevStep}
        onNextStep={onNextStep}
        onFinish={onFinish}
        onResetSteps={onResetSteps}
        onComplete={onComplete}
      />
    </>
  );
};

export default AddPaymentRequest;
