import React from 'react';

import { Badge } from 'components/UI';
import { BadgeStatus } from 'components/UI/Badge';
import { CardTypeBadgeProps } from './CardTypeBadge.types';

export const CardTypeBadge = ({ isVirtual }: CardTypeBadgeProps) => {
  const title = isVirtual ? 'Virtual' : 'Physical';

  return <Badge title={title} status={BadgeStatus.info} classNames="tw-font-bold" />;
};

export default CardTypeBadge;
