import React, { forwardRef } from 'react';

import CurrencyFlag from 'components/svg/CurrencyFlag';
import { CheckboxGroup } from '../';
import { CurrencyCheckboxGroupProps } from './CurrencyCheckboxGroup.types';
import { DEFAULT_CURRENCIES } from './constants';

const CurrencyCheckboxGroup = (props: CurrencyCheckboxGroupProps, ref: React.Ref<HTMLInputElement>) => {
  const { options = DEFAULT_CURRENCIES, ...other } = props;

  return (
    <CheckboxGroup
      options={options.map((currency) => ({
        label: (
          <div className="tw-flex tw-items-center tw-gap-2 tw-rounded-full">
            <CurrencyFlag currency={currency} size={20} />
            {currency}
          </div>
        ),
        value: currency,
      }))}
      ref={ref}
      {...other}
    />
  );
};

export default forwardRef(CurrencyCheckboxGroup);
