import React, { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import Layout from 'components/onboarding/Layout';
import { Loaders } from 'components/cards/Loader';
import CheckmarkAndPerson from 'components/svg/CheckmarkAndPerson';

const WaitingRoom = () => {
  const { meLoading } = useContext(AuthContext);

  return <Layout>{meLoading ? <Loaders.Light /> : <Content />}</Layout>;
};

const Content = () => (
  <div className="lg:tw-pt-24 tw-space-y-8 tw-mb-8 tw-flex tw-flex-col tw-flex-grow tw-justify-center tw-items-center">
    <CheckmarkAndPerson />
    <h1>You're almost there</h1>
    <div className="lg:tw-w-2/3 tw-text-center tw-space-y-8">
      <p>
        It can take a few minutes for us to automatically open your profile. When your personal details are verified you
        will receive an email from us.
      </p>
      <p>
        If we are unable to automatically verify your details, our team will reach out to confirm any outstanding
        information.
      </p>
    </div>
  </div>
);

export default WaitingRoom;
