import React from 'react';
import { useHistory } from 'react-router-dom';

import PermissionChecker from 'components/PermissionChecker';
import { SCOPE } from 'constants/index';
import AccountingServiceConnectButton from 'components/settings/AccountingServices/ConnectButton';
import { ExternalAccounts, EmptyState } from 'components/settings/Integrations/components';
import { validAccountingIntegrations } from 'components/settings/Integrations/constants';
import InfoBlock from 'components/settings/InfoBlock';
import SettingsHeader from 'components/settings/components/SettingsHeader';

const AccountingSection = ({ externalAccounts, reload, companyInfo }) => {
  const history = useHistory();

  const handleShowCategoryMapping = () => {
    history.push('/dashboard/settings/accountingIntegration');
  };

  return (
    <div className="tw-flex tw-flex-row tw-px-4 tw-py-8">
      <div className="tw-flex tw-flex-col tw-w-full">
        <SettingsHeader title={`Accounting Integrations (${externalAccounts?.length})`} />
        {externalAccounts?.length === 0 && (
          <div>
            <InfoBlock
              content={'Connect Loop to your accounting software in order to easily sync your Loop transaction data.'}
            />
            <div className="tw-mt-4 tw-mb-8 tw-py-4 tw-rounded-md tw-w-96 tw-flex tw-flex-col">
              <PermissionChecker scope={SCOPE.manageAccountingIntegrations}>
                {validAccountingIntegrations().map((externalAccountName) => (
                  <AccountingServiceConnectButton key={externalAccountName} integration={externalAccountName}>
                    Connect {externalAccountName}
                  </AccountingServiceConnectButton>
                ))}
              </PermissionChecker>
            </div>
          </div>
        )}
        <div className="tw-flex">
          {externalAccounts?.length > 0 ? (
            <div className="tw-flex tw-items-start">
              <ExternalAccounts
                onShowConfigure={handleShowCategoryMapping}
                accounts={externalAccounts.map((acc) => {
                  return { ...acc, companyInfo };
                })}
                reload={reload}
                accounting
              />
            </div>
          ) : (
            <EmptyState type="accounting" />
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountingSection;
