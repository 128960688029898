import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Disclosure, Transition } from '@headlessui/react';
import { IoCaretDownCircleOutline } from 'react-icons/io5';
import cx from 'classnames';

import InfoTooltip from 'components/InfoTooltip';
import { SubmitButton } from 'components/FormFields/v2';
import { DetailsFormProps } from './DetailsForm.types';
import { BankDetails, BasicInfo, BusinessAddress, Completed } from './components';
import { useDetailsForm } from './hooks';

const DetailsForm = ({
  borrowerName,
  payeeName,
  payeeEmail,
  payeeType,
  isCompleted,
  submitBankDetails,
  isSubmitting,
  submitErrors,
  sumbittedBankDetails,
  addressLabel,
}: DetailsFormProps) => {
  const { handleSubmit, form, isFormInvalid, businessAddress, bankDetails } = useDetailsForm({ sumbittedBankDetails });

  const sections = [
    {
      id: 'banking-information',
      label: (
        <div className="tw-flex tw-gap-1">
          Step 1. Banking Information
          <InfoTooltip message="Bank details are securely stored and provided to your Payor so they can initiate future payments" />
        </div>
      ),
      component: <BankDetails />,
      defaultOpen: true,
    },
    {
      id: 'business-address',
      label: `Step 2. ${addressLabel}`,
      component: <BusinessAddress />,
    },
  ];

  return (
    <>
      <h1 className="tw-mb-6">Payment Details Form</h1>
      <BasicInfo borrowerName={borrowerName} payeeName={payeeName} payeeEmail={payeeEmail} payeeType={payeeType} />

      {isCompleted && borrowerName ? (
        <Completed
          borrowerName={borrowerName}
          businessAddress={businessAddress}
          bankDetails={bankDetails}
          addressLabel={addressLabel}
        />
      ) : (
        <FormProvider {...form} {...{ graphQLErrors: submitErrors?.graphQLErrors }}>
          <form onSubmit={handleSubmit(submitBankDetails)}>
            <div className="tw-flex tw-flex-col tw-gap-4">
              {sections.map(({ id, label, component, defaultOpen }) => (
                <div className="tw-rounded-md tw-border tw-border-neutral-grey-4" key={id}>
                  <Disclosure defaultOpen={defaultOpen}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="tw-flex tw-justify-between tw-items-center tw-w-full tw-bg-secondary-pastel-green-80 tw-text-sm tw-px-4 tw-py-2 tw-text-neutral-grey-1">
                          {label}
                          <IoCaretDownCircleOutline
                            size={24}
                            className={cx(
                              'tw-text-primary-dark-green tw-transition tw-duration-100 tw-ease-out tw-transform',
                              open && 'tw-rotate-180'
                            )}
                          />
                        </Disclosure.Button>
                        <Transition
                          show={open}
                          enter="tw-transition tw-duration-100 tw-ease-out"
                          enterFrom="tw-transform tw-scale-95 tw-opacity-0"
                          enterTo="tw-transform tw-scale-100 tw-opacity-100"
                          leave="tw-transition tw-duration-75 tw-ease-out"
                          leaveFrom="tw-transform tw-scale-100 tw-opacity-100"
                          leaveTo="tw-transform tw-scale-95 tw-opacity-0"
                          unmount={false}
                        >
                          <Disclosure.Panel unmount={false}>{component}</Disclosure.Panel>
                        </Transition>
                      </>
                    )}
                  </Disclosure>
                </div>
              ))}

              {isFormInvalid && (
                <span className="tw-text-semantic-error tw-text-sm">Please fill all required fields</span>
              )}

              <SubmitButton disabled={isSubmitting} className="tw-font-bold tw-w-56 tw-py-2 tw-px-8 tw-ml-auto">
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </SubmitButton>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default DetailsForm;
