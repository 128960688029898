import React from 'react';

import AddUSBankAccount from 'containers/accounts/AddUSBankAccount';
import AddCCBankAccountContainer from 'containers/accounts/AddCCBankAccount';
import USDBankAccountDetailsContainer from 'containers/accounts/USDBankAccountDetails';
import CCBankAccountDetailsContainer from 'containers/accounts/CurrencyCloudBankAccountDetails';
import MoveMoneyFlow from 'containers/MoveMoneyFlowContainer';
import USDBankAccountVerificationStatusContainer from 'containers/accounts/USDBankAccountVerificationStatus';
import AddMoneyFlow from 'containers/AddMoney';
import { Modal } from 'components/UI';
import { useToggle } from 'hooks';

const AddMoneyModal = ({ show, setShow, initialWalletId }) => {
  const onClose = () => setShow(false);

  return (
    <Modal show={show} onClose={onClose} title="Add money">
      <div className="tw-flex tw-flex-col tw-py-4">
        <AddMoneyFlow
          onFinish={onClose}
          finishButtonLabel="Back to Wallets"
          initialToAccountId={initialWalletId}
          svgAndTooltip="bottom"
          footerBorder
          footerButton="modal"
        />
      </div>
    </Modal>
  );
};

const MoveMoneyModal = ({ show, setShow, finishButtonLabel, source }) => {
  const onClose = () => setShow(false);

  return (
    <Modal show={show} onClose={onClose} title="Move money">
      <div className="tw-flex tw-flex-col tw-py-4">
        <MoveMoneyFlow
          finishButtonLabel={finishButtonLabel || 'Back to Wallets'}
          source={source}
          footerBorder
          footerButton="modal"
          isModal
        />
      </div>
    </Modal>
  );
};

const OpenUSAccountModal = ({ show, onClose, onAddAccount }) => {
  const { isOpen: isFullScreen, open: enableFullScreen, close: disableFullScreen } = useToggle();

  const handleFinish = () => {
    onAddAccount();
    onClose();
  };

  return (
    <Modal show={show} onClose={handleFinish} title="US Dollar Account Opening" isFullScreen={isFullScreen}>
      <div className="tw-flex tw-flex-col tw-py-4">
        <AddUSBankAccount
          onFinish={handleFinish}
          enableFullScreen={enableFullScreen}
          disableFullScreen={disableFullScreen}
        />
      </div>
    </Modal>
  );
};

const OpenEURGBPAccountModal = ({ show, onClose, currency, setCurrency, onAddAccount }) => {
  const handleOnClose = () => {
    onClose();
    setCurrency('');
  };

  return (
    <Modal show={show} onClose={handleOnClose} title={`Get your ${currency} Account Details`}>
      <div className="tw-flex tw-flex-col tw-py-4">
        <AddCCBankAccountContainer onFinish={onClose} currency={currency} onAddAccount={onAddAccount} />
      </div>
    </Modal>
  );
};

const USDBankAccountDetailsModal = (props) => {
  const { show, setShow, accountInternalId } = props;
  const onClose = () => setShow(false);

  return (
    <Modal show={show} onClose={onClose} title="Account Details">
      <div className="tw-flex tw-flex-col tw-py-4">
        <USDBankAccountDetailsContainer accountInternalId={accountInternalId} />
      </div>
    </Modal>
  );
};

const USDBankAccountVerificationStatusModal = (props) => {
  const { show, setShow, accountInternalId } = props;
  const onClose = () => setShow(false);

  return (
    <Modal show={show} onClose={onClose} title="Pending Verification">
      <div className="tw-flex tw-flex-col tw-py-4">
        <USDBankAccountVerificationStatusContainer accountInternalId={accountInternalId} />
      </div>
    </Modal>
  );
};

const CurrencyCloudExternalAccountModal = ({ show, setShow, initialWalletId, onFinish, onAddAccount }) => {
  const onClose = () => {
    setShow(false);
    onFinish?.();
    onAddAccount?.();
  };

  return (
    <Modal show={show} onClose={onClose} title={`${initialWalletId?.currency} Account Details`}>
      <div className="tw-flex tw-flex-col tw-py-4">
        {initialWalletId && <CCBankAccountDetailsContainer currency={initialWalletId.currency} />}
      </div>
    </Modal>
  );
};

export const Modals = {
  AddMoney: AddMoneyModal,
  MoveMoney: MoveMoneyModal,
  OpenUSAccount: OpenUSAccountModal,
  USBankAccountDetails: USDBankAccountDetailsModal,
  USDBankAccountVerificationStatus: USDBankAccountVerificationStatusModal,
  CurrencyCloudExternalAccount: CurrencyCloudExternalAccountModal,
  OpenEURGBPAccount: OpenEURGBPAccountModal,
};
