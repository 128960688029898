import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { ADD_PAYOR_BANK_ACCOUNT, GET_PAYOR_BANK_ACCOUNTS } from 'graphql/invoicing';
import { BankAccountCurrency, BankAccountCountry } from 'types/bankAccount';
import { ManualFormProps } from '../ManualForm.types';

const useManualForm = ({
  country,
  currencies,
  onSuccess,
  onError,
}: Pick<ManualFormProps, 'country' | 'currencies' | 'onSuccess' | 'onError'>) => {
  const [addPayorBankAccount, { loading: isAddingPayorBankAccount, error }] = useMutation(ADD_PAYOR_BANK_ACCOUNT, {
    refetchQueries: [{ query: GET_PAYOR_BANK_ACCOUNTS }],
    awaitRefetchQueries: true,
  });

  const isUSBank = country === BankAccountCountry.US;
  const isCanadianBank = country === BankAccountCountry.CA;

  const currencyOptions = useMemo(() => {
    let options = [{ name: 'US Dollar (USD)', value: BankAccountCurrency.USD }];

    if (isCanadianBank) {
      options.unshift({ name: 'Canadian Dollar (CAD)', value: BankAccountCurrency.CAD });
    }

    if (currencies) {
      options = options.filter((option) => currencies.includes(option.value));
    }

    return options;
  }, [isCanadianBank, currencies]);

  const form = useForm({
    defaultValues: {
      currency: isCanadianBank ? BankAccountCurrency.CAD : BankAccountCurrency.USD,
      accountType: 'chequing',
    },
  });
  const { register, handleSubmit } = form;

  const onSubmit = useCallback(
    async (data) => {
      try {
        await addPayorBankAccount({ variables: { ...data, country } });
        toast.success('Bank account has been connected.');
        onSuccess?.();
      } catch (err) {
        console.error(err);
        toast.error('Bank account could not be connected. Please try again.');
        onError?.();
      }
    },
    [onSuccess, onError, addPayorBankAccount]
  );

  return {
    isUSBank,
    isCanadianBank,
    form,
    register,
    handleSubmit,
    onSubmit,
    currencyOptions,
    error,
    isLoading: isAddingPayorBankAccount,
  };
};

export default useManualForm;
