import React from 'react';
import { useErrorHandler } from 'react-error-boundary';
import cx from 'classnames';

import { Loaders } from 'components/cards/Loader';

import styles from './Button.module.scss';

const emptyFn = () => {};

export enum IconAnimation {
  rotateAndRollOut = 'rotate-and-roll-out',
  rolling = 'rolling',
  rollOut = 'roll-out',
  pulse = 'pulse',
  wobble = 'wobble',
  bounce = 'bounce',
}

export enum ButtonSize {
  sm = 'sm',
  regular = 'regular',
  lg = 'lg',
  text = 'text',
}

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  warning?: boolean;
  black?: boolean;
  isDisabled?: boolean;
  isShowSpinner?: boolean;
  isFetching?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  iconAnimation?: IconAnimation;
  size?: ButtonSize;
};

const Button = ({
  children,
  primary,
  secondary,
  tertiary,
  warning,
  black,
  isDisabled,
  isShowSpinner,
  isFetching,
  onClick = emptyFn,
  className = '',
  iconAnimation,
  size = ButtonSize.regular,
  ...rest
}: ButtonProps) => {
  const errorHandler = useErrorHandler();

  const _onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      onClick(e);
    } catch (error) {
      // triggers ErrorBoundary component
      errorHandler(error);
    }
  };

  const hasBaseStyles = primary || secondary || tertiary || warning || black;
  const disabled = isFetching || isDisabled;

  const buttonClassnames = cx(
    {
      [className]: className,
      'tw-bg-neutral-grey-3 tw-text-neutral-light tw-cursor-not-allowed': disabled,
      'tw-rounded-md tw-text-center tw-inline-flex tw-items-center tw-justify-center': hasBaseStyles,
      'tw-bg-primary-dark-green tw-text-neutral-light': primary && !disabled,
      'tw-bg-neutral-light tw-border tw-border-neutral-grey-3': secondary && !disabled,
      'tw-text-primary-dark-green tw-font-bold': tertiary && !disabled,
      'tw-bg-semantic-warning tw-text-neutral-light': warning && !disabled,
      'tw-bg-neutral-black tw-text-neutral-light': black && !disabled,
      'tw-py-1 tw-px-3 tw-text-sm': size === ButtonSize.sm,
      'tw-py-2 tw-px-4': size === ButtonSize.regular,
      'tw-py-3 tw-px-5 tw-text-lg': size === ButtonSize.lg,
      'tw-p-0': size === ButtonSize.text,
    },
    iconAnimation && !disabled && styles['btn--animation'],
    iconAnimation && !disabled && styles[iconAnimation]
  );

  return (
    <button className={buttonClassnames} disabled={disabled} onClick={_onClick} {...rest}>
      {children}

      {(isFetching || isShowSpinner) && <Loaders.Spinner />}
    </button>
  );
};

export default Button;
