import { Currencies } from 'constants/currencies';

export const possibleCurrencies = [
  {
    currency: Currencies.CAD,
    currencyLabel: 'Canadian Dollar',
  },
  {
    currency: Currencies.USD,
    currencyLabel: 'U.S. Dollar',
  },
  {
    currency: Currencies.EUR,
    currencyLabel: 'Euro',
  },
  {
    currency: Currencies.GBP,
    currencyLabel: 'British Pound',
  },
];
