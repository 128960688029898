import React from 'react';

import config from 'config';
import WarningAndStars from 'components/svg/WarningAndStars';

import styles from '../../PersonaVerification.module.scss';

const DEFAULT_ERROR_MESSAGE = "Can't start verification flow";

const ErrorState = ({ message = DEFAULT_ERROR_MESSAGE }: { message?: string | null }) => (
  <div className={styles.error}>
    <WarningAndStars height="100" width="100%" />

    <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-my-5">
      <div className="tw-font-bold tw-my-4">{message || DEFAULT_ERROR_MESSAGE}</div>
      <p>
        Please try to refresh the page. If the problem persists, please contact&nbsp;
        <a
          className="tw-font-semibold tw-text-primary-dark-green"
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:${config.supportEmail}`}
        >
          support
        </a>
        .
      </p>
    </div>
  </div>
);

export default ErrorState;
