import React from 'react';

import { Modal } from 'components/UI';
import { RequestInfoModalProps } from './RequestInfoModal.types';
import { useRequestInfoModal } from './hooks';

const RequestInfoModal = ({
  show,
  onClose,
  selectedPaymentRequestId,
  selectedBankAccountId,
}: RequestInfoModalProps) => {
  const {
    formattedAmount,
    currency,
    formattedDueDate,
    vendorName,
    bankAccountName,
    statusDescription,
    isOngoing,
    frequencyLabel,
    isStoppedByEndDate,
    formattedEndDate,
    isStoppedByNumberOfOccurrences,
    numberOfOccurrences,
    isStoppedByCancel,
  } = useRequestInfoModal({
    selectedPaymentRequestId,
    selectedBankAccountId,
  });

  return (
    <Modal show={show} onClose={onClose} title="Payment Request Details">
      <div className="tw-flex tw-flex-col tw-px-8 tw-pt-6 tw-pb-8 tw-space-y-4">
        <div className="tw-text-sm">{statusDescription}</div>
        <div className="tw-flex tw-pt-4">
          <span className="tw-text-sm tw-w-1/2 tw-text-neutral-grey-2">Vendor</span>
          <span className="tw-text-sm tw-w-1/2">{vendorName}</span>
        </div>
        <div className="tw-flex">
          <span className="tw-text-sm tw-w-1/2 tw-text-neutral-grey-2">Bank Account</span>
          <span className="tw-text-sm tw-w-1/2">{bankAccountName}</span>
        </div>
        <div className="tw-flex">
          <span className="tw-text-sm tw-w-1/2 tw-text-neutral-grey-2">Amount</span>
          <span className="tw-text-sm tw-w-1/2">{`${formattedAmount} ${currency}`}</span>
        </div>
        <div className="tw-flex">
          <span className="tw-text-sm tw-w-1/2 tw-text-neutral-grey-2">Currency</span>
          <span className="tw-text-sm tw-w-1/2">{currency}</span>
        </div>
        {!isOngoing ? (
          <div className="tw-flex">
            <span className="tw-text-sm tw-w-1/2 tw-text-neutral-grey-2">Due Date</span>
            <span className="tw-text-sm tw-w-1/2">{formattedDueDate}</span>
          </div>
        ) : (
          <>
            <div className="tw-flex">
              <span className="tw-text-sm tw-w-1/2 tw-text-neutral-grey-2">Frequency</span>
              <span className="tw-text-sm tw-w-1/2">{frequencyLabel}</span>
            </div>
            <div className="tw-flex">
              <span className="tw-text-sm tw-w-1/2 tw-text-neutral-grey-2">Start Date</span>
              <span className="tw-text-sm tw-w-1/2">{formattedDueDate}</span>
            </div>
            {isStoppedByEndDate && formattedEndDate && (
              <div className="tw-flex">
                <span className="tw-text-sm tw-w-1/2 tw-text-neutral-grey-2">End Date</span>
                <span className="tw-text-sm tw-w-1/2">{formattedEndDate}</span>
              </div>
            )}
            {isStoppedByNumberOfOccurrences && numberOfOccurrences && (
              <div className="tw-flex">
                <div className="tw-text-sm tw-w-1/2 tw-text-neutral-grey-2">Number of Payments</div>
                <span className="tw-text-sm tw-w-1/2">{numberOfOccurrences}</span>
              </div>
            )}
            {isStoppedByCancel && (
              <div className="tw-text-sm">These payments will stop upon cancellation by vendor</div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default RequestInfoModal;
