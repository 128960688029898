import React from 'react';

import { Modal } from 'components/UI';
import { SubmitButton, TextArea } from 'components/FormFields/v2';
import { FormProvider } from 'react-hook-form';
import useCardExpenseDeclineModal from './hooks/useCardExpenseDeclineModal';
import { Loaders } from 'components/cards/Loader';

const CardExpenseDeclineModal = () => {
  const { closeModal, show, form, register, handleSubmit, onDecline, isUpdatingCardExpense } =
    useCardExpenseDeclineModal();
  return (
    <Modal show={show} onClose={closeModal} title="Decline Card Expense">
      <div className="tw-mx-8 tw-my-8">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onDecline)}>
            <TextArea
              name="declineReason"
              data-testid="declineReason"
              label="Decline Reason"
              placeholder="Please enter a reason for declining this expense. This member will receive an email notifying them this expense has been declined."
              ref={register({ required: true })}
              rows={3}
              maxLength={250}
              required
            />
            <div className="tw-flex tw-flex-row tw-py-4 tw-justify-end">
              <SubmitButton
                disabled={isUpdatingCardExpense}
                className={`${
                  isUpdatingCardExpense ? 'tw-bg-neutral-grey-3' : 'tw-bg-semantic-error'
                } tw-rounded-md tw-px-6 tw-py-2 tw-font-semibold tw-text-neutral-light`}
              >
                {isUpdatingCardExpense ? (
                  <div className={'tw-flex tw-flex-row tw-gap-2 tw-items-center'}>
                    <Loaders.Spinner />
                    'Declining'
                  </div>
                ) : (
                  'Decline'
                )}
              </SubmitButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default CardExpenseDeclineModal;
