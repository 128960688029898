import React from 'react';

type CardSendFundsProps = {
  width?: number;
  height?: number;
};

const CardSendFunds = ({ width = 25, height = 25 }: CardSendFundsProps) => (
  <svg width={width} height={height} viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.125 0.5H3.875C2.42525 0.5 1.25 1.67525 1.25 3.125V12.875C1.25 14.3247 2.42525 15.5 3.875 15.5H18.125C19.5747 15.5 20.75 14.3247 20.75 12.875V3.125C20.75 1.67525 19.5747 0.5 18.125 0.5Z"
      fill="white"
      stroke="#26262C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.25 5H20.75" stroke="#26262C" strokeLinejoin="round" />
    <path d="M7.25 10.0625H5V11H7.25V10.0625Z" stroke="#26262C" strokeLinejoin="round" />
    <rect x="13.75" y="8.75" width="10.5" height="10.5" rx="5.25" fill="#E8E8E8" />
    <path
      d="M21 12.875L21.75 13.625L21 14.375"
      stroke="#383B3E"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5 13.625H18.25C17.9188 13.626 17.6014 13.758 17.3672 13.9922C17.133 14.2264 17.001 14.5438 17 14.875V15.125"
      stroke="#383B3E"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="13.75" y="8.75" width="10.5" height="10.5" rx="5.25" stroke="black" strokeWidth="0.5" />
  </svg>
);

export default CardSendFunds;
