import { gql } from '@apollo/client';

export const TRANSACTION_FIELDS = gql`
  fragment TransactionFields on Transaction {
    groupId
    transactionId
    amount {
      amount
      currency
    }
    createdAt
    completedAt
    status
    rejectReason
    type
    note
    transferMethod
    transactionCategory {
      loopId
      transactionCategoryId
      description
    }
    transactionReceipt
    transactionNote
  }
`;

export const GET_TRANSACTIONS = gql`
  ${TRANSACTION_FIELDS}
  query Transactions(
    $products: [String!]
    $startDate: String
    $endDate: String
    $categories: [String!]
    $currencies: [String!]
    $loopProducts: [String!]
  ) {
    transactions(
      products: $products
      startDate: $startDate
      endDate: $endDate
      categories: $categories
      currencies: $currencies
      loopProducts: $loopProducts
    ) {
      ...TransactionFields
      from
      to
      fxExchangeRate
      fxChargedAmount {
        amount
        currency
      }
      fxConvertedAmount {
        amount
        currency
      }
      fxBillingAmount {
        amount
        currency
      }
      description
      cardTransaction
      transferMethod
      points
      cashLikeTransaction
      processingDate
    }
  }
`;

export const GET_TRANSACTIONS_PAGE = gql`
  query TransactionsPage(
    $products: [String!]
    $startDate: String
    $endDate: String
    $currencies: [String!]
    $categories: [String!]
    $search: String
    $loopProducts: [String!]
    $page: String
    $numPerPage: String
  ) {
    transactionsPage(
      products: $products
      startDate: $startDate
      endDate: $endDate
      currencies: $currencies
      categories: $categories
      search: $search
      loopProducts: $loopProducts
      page: $page
      numPerPage: $numPerPage
    ) {
      items {
        transactionId
        amount {
          amount
          currency
        }
        from
        to
        createdAt
        completedAt
        expectedCompletionSpeed
        status
        type
        note
        points
        rejectReason
        loopCardErrorCode
        processingDate
        transferMethod
        merchantAddress
        fxExchangeRate
        fxBillingAmount {
          amount
          currency
        }
        fxChargedAmount {
          amount
          currency
        }
        fxConvertedAmount {
          amount
          currency
        }
        description
        groupId
        cardTransaction
        cashLikeTransaction
        preAuthorization
        transactionCategory {
          description
          transactionCategoryId
        }
        transactionNote
        transactionReceipt
      }
      pageData {
        totalPages
        currentPage
        nextPage
        prevPage
        totalCount
      }
    }
  }
`;

export const GET_TRANSACTIONS_PAGE_EXPORT = gql`
  query TransactionsPageExport(
    $products: [String!]
    $startDate: String
    $endDate: String
    $currencies: [String!]
    $categories: [String!]
    $search: String
    $loopProducts: [String!]
    $page: String
    $numPerPage: String
  ) {
    transactionsPage(
      products: $products
      startDate: $startDate
      endDate: $endDate
      currencies: $currencies
      categories: $categories
      search: $search
      loopProducts: $loopProducts
      page: $page
      numPerPage: $numPerPage
    ) {
      items {
        transactionId
        amount {
          amount
          currency
        }
        from
        to
        createdAt
        completedAt
        expectedCompletionSpeed
        status
        type
        note
        points
        rejectReason
        processingDate
        transferMethod
        merchantAddress
        fxExchangeRate
        fxBillingAmount {
          amount
          currency
        }
        fxChargedAmount {
          amount
          currency
        }
        fxConvertedAmount {
          amount
          currency
        }
        description
        groupId
        cardTransaction
        cashLikeTransaction
        transactionCategory {
          description
        }
        transactionNote
        transactionReceipt
        fromForExport
        toForExport
        creditCardDescription
        lineOfCreditDescription
        accountDescription
        creditCardTransactionType
        lineOfCreditTransactionType
        accountTransactionType
        skipTransactionForExport
      }
      pageData {
        totalPages
        currentPage
        nextPage
        prevPage
        totalCount
      }
    }
  }
`;

export const UPDATE_TRANSACTION_CACHE = gql`
  fragment NewTransaction on Transaction {
    groupId
    transactionId
    amount {
      amount
      currency
    }
    createdAt
    completedAt
    status
    type
    note
    transactionCategory {
      loopId
      transactionCategoryId
      description
    }
  }
`;

export const ADD_TRANSACTION_NOTE_RECEIPT = gql`
  mutation AddTransactionNoteReceipt(
    $financialTransactionId: String!
    $note: String
    $blobSignedId: String
    $unmatchedReceiptId: ID
  ) {
    addTransactionNoteReceipt(
      financialTransactionId: $financialTransactionId
      note: $note
      blobSignedId: $blobSignedId
      unmatchedReceiptId: $unmatchedReceiptId
    )
  }
`;

export const UNLINK_RECEIPT = gql`
  mutation UnlinkReceipt($financialTransactionId: String!) {
    unlinkReceipt(financialTransactionId: $financialTransactionId)
  }
`;
