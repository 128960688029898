import { useState } from 'react';

const useEnableAutopay = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const onNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  return { currentStep, onNextStep };
};

export default useEnableAutopay;
