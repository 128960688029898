import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { TabNavProps } from './TabNav.types';

const defaultIconSize = 27;

const TabNav = ({ tabs }: TabNavProps) => {
  const location = useLocation();

  const selectedTab = useMemo(
    () =>
      tabs.find(
        (tab) =>
          new RegExp(location.pathname, 'g').test(tab.route) ||
          tab?.subRoutes?.some((subRoute) => new RegExp(location.pathname, 'g').test(subRoute))
      ),
    [tabs, location.pathname]
  );

  return (
    <div
      className={cx(
        'tw-sticky tw-bottom-0 tw-flex lg:tw-hidden tw-items-center tw-p-4 tw-bg-neutral-grey-3.5',
        tabs.length > 1 ? 'tw-justify-between' : 'tw-justify-center'
      )}
    >
      {tabs.map(({ label, route, Icon }) => {
        const isActive = selectedTab?.label === label;

        return (
          <Link key={route} to={route}>
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
              <Icon size={defaultIconSize} className={cx(isActive && 'tw-text-primary-dark-green')} />
              <span className={cx('tw-text-xs tw-mt-2', isActive && 'tw-text-primary-dark-green')}>{label}</span>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default TabNav;
