import { useState, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_CREDIT_CARD_BALANCES, GET_PRE_FUNDED_CARD_BALANCES } from 'graphql/cards';
import {
  CreditCardBalancesQueryResponse,
  PreFundedCardBalancesQueryResponse,
  CardBalance,
  CardBalances as CardBalancesType,
} from 'types/creditCard';
import { sortBalancesByCurrency } from 'utility/creditCards';
import useIsAdmin from 'hooks/useIsAdmin';
import { useToggle } from 'hooks';
import { transformCardBalances } from '../CurrentBalances.utils';
import { DEFAULT_CARD_BALANCES } from '../constants';
import { CurrentBalancesProps } from '../CurrentBalances.types';

const useCurrentBalances = ({ isPreFunded }: Pick<CurrentBalancesProps, 'isPreFunded'>) => {
  const [cardBalances, setCardBalances] = useState<CardBalancesType>(DEFAULT_CARD_BALANCES);

  const [getCreditCardBalances, { loading: isCreditCardBalancesLoading, error: cardsCurrentBalancesError }] =
    useLazyQuery<{ primaryCreditCard: CreditCardBalancesQueryResponse }>(GET_CREDIT_CARD_BALANCES, {
      onCompleted: (data) => {
        const balancesData = get(data, 'primaryCreditCard');
        const balances = transformCardBalances({ balances: balancesData });
        setCardBalances(balances);
      },
    });

  const [getPreFundedCardBalances, { loading: isPreFundedCardBalancesLoading, error: preFundedCurrentBalancesError }] =
    useLazyQuery<{ primaryCreditCard: PreFundedCardBalancesQueryResponse }>(GET_PRE_FUNDED_CARD_BALANCES, {
      onCompleted: (data) => {
        const balancesData = get(data, 'primaryCreditCard');
        const balances = transformCardBalances({ balances: balancesData, isPreFunded: true });
        setCardBalances(balances);
      },
    });

  const pageTitle = isPreFunded ? 'Balances' : 'Current Balances';

  const { isAdmin } = useIsAdmin();

  const {
    isOpen: isOpenAddFundsToBalanceModal,
    open: openAddFundsToBalanceModal,
    close: closeAddFundsToBalanceModal,
  } = useToggle();

  const sortedGroupBalances = useMemo(
    () => sortBalancesByCurrency({ items: cardBalances.groupBalances }) as CardBalance[],
    [cardBalances.groupBalances]
  );

  const balances = useMemo(() => {
    const { groupPendingBalances, groupPostedBalances, groupOngoingPayments } = cardBalances;

    return sortedGroupBalances.map((currentBalance) => {
      const currentCurrency = currentBalance.currency;

      const pending = groupPendingBalances?.find(({ currency }) => currency === currentCurrency);
      const posted = groupPostedBalances?.find(({ currency }) => currency === currentCurrency);
      const ongoingPayments = groupOngoingPayments?.find(({ currency }) => currency === currentCurrency);

      return {
        current: currentBalance,
        pending,
        posted,
        ongoingPayments,
      };
    });
  }, [sortedGroupBalances, cardBalances.groupPendingBalances, cardBalances.groupPostedBalances]);

  useEffect(() => {
    if (isPreFunded) {
      getPreFundedCardBalances();
      return;
    }

    getCreditCardBalances();
  }, [isPreFunded, getCreditCardBalances, getPreFundedCardBalances]);

  return {
    pageTitle,
    billingCycle: cardBalances.billingCycle,
    balances,
    isOpenAddFundsToBalanceModal,
    openAddFundsToBalanceModal,
    closeAddFundsToBalanceModal,
    isLoading: isPreFunded ? isPreFundedCardBalancesLoading : isCreditCardBalancesLoading,
    isError: isPreFunded ? !!preFundedCurrentBalancesError : !!cardsCurrentBalancesError,
    isAdmin,
  };
};

export default useCurrentBalances;
