import React, { useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { Collapse } from 'react-collapse';

import { formatMoneyV2 } from 'utility/currency';
import { formatDate } from 'utility/date';
import { Loaders } from 'components/cards/Loader';
import CoinStack from 'components/svg/CoinStack';
import MobileCurrencyFlag from '../MobileCurrencyFlag';
import useIsMobile from 'hooks/useIsMobile';

const LineOfCreditSummary = ({ hasLineOfCredit, lineOfCredit, loadingCreditInfo }) => {
  const [expanded, setIsExpanded] = useState(false);
  const onToggleExpand = () => {
    if (hasLineOfCredit) setIsExpanded(!expanded);
  };

  const isMobile = useIsMobile();

  const currentBalance = lineOfCredit && lineOfCredit.currentBalance;
  const amountDue = lineOfCredit && lineOfCredit.amountDue;

  return (
    <div className="tw-bg-neutral-light tw-border-neutral-grey-4 tw-shadow-md tw-border tw-rounded-md tw-mt-4 tw-w-full">
      <Header
        onExpand={onToggleExpand}
        hasLineOfCredit={hasLineOfCredit}
        lineOfCredit={lineOfCredit}
        isMobile={isMobile}
        amountDue={amountDue}
        isExpanded={expanded}
      />
      {loadingCreditInfo && (
        <div className="tw-flex tw-items-center tw-justify-center tw-p-8 tw-w-full">
          <Loaders.Spinner />
        </div>
      )}
      {!loadingCreditInfo &&
        (hasLineOfCredit ? (
          <CollapsedBalance currentBalance={currentBalance} isExpanded={expanded} isMobile={isMobile} />
        ) : (
          <EmptyState />
        ))}
      {hasLineOfCredit && (
        <Collapse isOpened={expanded}>
          <div className="tw-w-full tw-px-4 tw-bg-neutral-light tw-rounded-b-md">
            <div className="tw-border-t tw-border-neutral-grey-4 tw-py-8">
              <ExpandedBalances lineOfCredit={lineOfCredit} currentBalance={currentBalance} isMobile={isMobile} />
            </div>
          </div>
        </Collapse>
      )}
    </div>
  );
};

const Header = ({ hasLineOfCredit, lineOfCredit, onExpand, isExpanded, isMobile, amountDue }) => {
  const availableBalance = lineOfCredit && lineOfCredit.availableCredit;
  const currency = availableBalance && availableBalance.currency;

  return (
    <div
      className={`tw-flex tw-items-center tw-justify-between tw-p-4 ${
        !isMobile && 'tw-border-b'
      } tw-border-neutral-grey-4`}
    >
      <div className="tw-flex">
        {isMobile ? (
          <div className="tw-flex tw-flex-col">
            <small>Min. payment</small>
            <div className="tw-mt-2 tw-flex tw-items-center">
              <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
                <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                  <MobileCurrencyFlag currency={currency} size={14} />
                </div>
              </div>
              <span className="tw-text-lg tw-font-semibold">{formatMoneyV2(amountDue)}</span>
            </div>
          </div>
        ) : (
          <>
            <div className="tw-flex tw-justify-center tw-bg-secondary-light-yellow tw-text-primary-dark-yellow tw-rounded tw-p-1 tw-mr-2">
              <CoinStack width={18} height={15} />
            </div>
            <strong>Capital</strong>
          </>
        )}
      </div>
      <div className="tw-flex tw-items-center">
        {hasLineOfCredit && (
          <>
            <div>
              {!isMobile && (
                <small className="tw-mr-1">
                  Available{' '}
                  <span className="tw-text-lg tw-mr-2 tw-font-semibold">
                    {`${formatMoneyV2(availableBalance)} `}
                    <small data-testid="header-currency" className="tw-text-neutral-grey-2">
                      {currency}
                    </small>
                  </span>
                </small>
              )}
            </div>
            <div
              onClick={onExpand}
              data-testid="loc-summary-expand-button"
              className={`${
                isExpanded ? 'tw-bg-neutral-grey-4' : 'tw-bg-neutral-light'
              } tw-rounded-md tw-flex tw-justify-center tw-items-center tw-bg-neutral-light tw-border tw-border-neutral-grey-4 tw-p-2 tw-cursor-pointer hover:tw-bg-primary-light-green`}
            >
              <BsChevronDown
                className={`tw-transform tw-rotate-${
                  isExpanded ? '180' : '0'
                } tw-ease-in-out tw-duration-200 tw-transition-all`}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CollapsedBalance = ({ currentBalance, isExpanded, isMobile }) => {
  return (
    <>
      {!isMobile && (
        <div className={`tw-p-4 ${isExpanded && 'tw-pb-0'}`}>
          <small>Line of Credit Balance</small>
          <div className="tw-mt-4 tw-flex tw-items-center">
            <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
              <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                <MobileCurrencyFlag currency={currentBalance.currency} size={14} />
              </div>
            </div>
            <small>{`${formatMoneyV2(currentBalance, true)} ${currentBalance.currency}`}</small>
          </div>
          {isExpanded && <div className="tw-bg-primary-dark-green tw-w-1/5 tw-h-1 tw-rounded-t-md tw-mt-2" />}
        </div>
      )}
    </>
  );
};

const ExpandedBalances = ({ lineOfCredit, currentBalance, amountDue, isMobile }) => {
  const dueDate = lineOfCredit && lineOfCredit.paymentDate;
  const pendingBalance = lineOfCredit && lineOfCredit.pendingBalance;
  const postedBalance = lineOfCredit && lineOfCredit.postedBalance;
  const creditLimit = lineOfCredit && lineOfCredit.creditLimit;

  return (
    <div className={`tw-flex ${isMobile && 'tw-flex-col'}`}>
      <div className={`tw-flex tw-flex-col ${!isMobile && 'tw-w-1/3 tw-mr-16'}`}>
        <div className="tw-flex tw-justify-between tw-items-center">
          <small className="tw-text-neutral-grey-2">Pending transactions</small>
          <small>{formatMoneyV2(pendingBalance)}</small>
        </div>
        <div className="tw-flex tw-justify-between tw-items-center tw-my-1">
          <small className="tw-text-neutral-grey-2">Posted transactions</small>
          <small>{formatMoneyV2(postedBalance)}</small>
        </div>
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
          <small className="tw-text-neutral-grey-2">Current balance</small>
          <small>{formatMoneyV2(currentBalance, true)}</small>
        </div>
      </div>
      <div className={`tw-flex tw-flex-col ${!isMobile && 'tw-w-1/3 tw-mr-16'}`}>
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
          <small className="tw-text-neutral-grey-2">Min. payment</small>
          <small>{formatMoneyV2(amountDue)}</small>
        </div>
        {dueDate && (
          <div className="tw-flex tw-justify-between tw-items-center tw-mb-1">
            <small className="tw-text-neutral-grey-2">Due date</small>
            <small>{formatDate(dueDate)}</small>
          </div>
        )}
        <div className="tw-flex tw-justify-between tw-items-center">
          <small className="tw-text-neutral-grey-2">Credit limit</small>
          <small>{formatMoneyV2(creditLimit)}</small>
        </div>
      </div>
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className="tw-p-4">
      <small>You do not have a Line of Credit at this time.</small>
    </div>
  );
};

export default LineOfCreditSummary;
