import React, { useState } from 'react';

import { PersonaVerification } from 'components/IdentityVerification/components';
import useGuarantorVerification from './hooks/useGuarantorVerification';
import useScrollViewToPosition from 'hooks/useScrollViewToPosition';

const GuarantorPersonaVerification = () => {
  useScrollViewToPosition();

  const [isSuccessful, setIsSuccessful] = useState(false);
  const onSubmitSuccess = () => setIsSuccessful(true);

  const {
    environmentId,
    templateId,
    referenceId,
    verificationCompleted,
    fields,
    error: contactError,
    loading: isContactLoading,
    onComplete,
  } = useGuarantorVerification();

  if (isSuccessful || verificationCompleted) onComplete();

  return (
    <div>
      <p>
        We are required to verify your identity in order to complete this process. Please have your government-issued ID
        ready to complete verification below.
      </p>
      <PersonaVerification
        inputDataError={contactError}
        isInputDataLoading={isContactLoading}
        environmentId={environmentId}
        referenceId={referenceId}
        templateId={templateId}
        fields={fields}
        onCompleted={onSubmitSuccess}
      />
    </div>
  );
};

export default GuarantorPersonaVerification;
