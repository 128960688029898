import React from 'react';
import { PiGaugeLight } from 'react-icons/pi';

import { useSpendingLimits } from './hooks';
import { DetailsCard, ProgressChart } from 'components/UI';
import { SectionSkeleton } from '..';
import { EditSpendingLimitsModal } from './components';
import { CreditCardCurrency } from 'types/creditCard';

const SpendingLimits = () => {
  const {
    isError,
    isLoading,
    isOpenModal,
    openModal,
    closeModal,
    handleUpdateSettings,
    isUpdatingProcessorSettings,
    limitType,
    limitAmount,
    currency,
    limitTypeLabel,
    formattedResetDate,
    formattedLimitAmount,
    spentAmountCents,
    formattedSpentAmount,
    formattedAvailableAmount,
  } = useSpendingLimits();

  if (isLoading) return <SectionSkeleton />;

  return (
    <>
      <DetailsCard
        label="Spending Limits"
        LabelIcon={PiGaugeLight}
        action={isError ? undefined : openModal}
        actionLabel="Change Limits"
      >
        {!isError && limitType ? (
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-flex tw-items-start tw-justify-between tw-w-full tw-gap-2">
              <div className="tw-flex tw-flex-col tw-gap-1">
                <div>{limitTypeLabel}</div>
                {formattedResetDate && (
                  <div className="tw-text-sm tw-text-neutral-grey-2">Resets on {formattedResetDate}</div>
                )}
              </div>
              <div className="tw-font-bold">{formattedLimitAmount}</div>
            </div>

            <div className="tw-flex tw-flex-col tw-gap-1 tw-mt-2">
              <ProgressChart
                id="spent-limit-progress"
                totalAmount={limitAmount}
                currentAmount={spentAmountCents}
                showTooltip
              />

              <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-gap-2">
                <div className="tw-font-bold">Spent {formattedSpentAmount}</div>
                <div className="tw-text-sm tw-text-neutral-grey-2">{formattedAvailableAmount} Available</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="tw-text-neutral-grey-2">
            {isError ? "Can't load credit card spending limits" : 'Limit is not set'}
          </div>
        )}
      </DetailsCard>

      {isOpenModal && (
        <EditSpendingLimitsModal
          isOpenModal={isOpenModal}
          closeModal={closeModal}
          handleUpdateSettings={handleUpdateSettings}
          isLoading={isUpdatingProcessorSettings}
          limitType={limitType}
          limitAmount={limitAmount}
          currency={currency as CreditCardCurrency}
        />
      )}
    </>
  );
};

export default SpendingLimits;
