import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';

import { UPDATE_PASSWORD } from '../../graphql/user';
import history from '../../history';
import Loader from '../cards/Loader';
import { PasswordField, SubmitButton } from '../FormFields/v2';
import { ampTrackEvent } from '../../amplitude';
import Layout from './Layout';

const Error = () => {
  return (
    <>
      <h1 className="tw-mb-3">Invalid password reset link</h1>
      <p>This link might be invalid or expired. Please try generating a new password reset link and try again.</p>
    </>
  );
};

const Loading = () => {
  return <Loader />;
};

const Form = ({ token, email }) => {
  const [mutation, { loading, error }] = useMutation(UPDATE_PASSWORD);

  const onSubmit = async (data) => {
    try {
      const result = await mutation({ variables: { ...data, token } });

      if (result.data && result.data.updatePassword) {
        ampTrackEvent('resetPassword: submit: success');
        history.push(`/signin?email=${email}`);
      }
    } catch (e) {
      console.log(e);
      ampTrackEvent('resetPassword: submit: error');
    }
  };
  const form = useForm({ defaultValues: { email } });
  const { register, handleSubmit } = form;

  return (
    <>
      <h1 className="tw-mb-3">Reset your password</h1>
      <p>Please enter a new password in the fields below</p>
      <FormProvider {...form}>
        <form className="tw-mt-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-mb-2">
            <PasswordField
              name="password"
              label="Create new password"
              placeholder="∗∗∗∗∗∗∗∗"
              ref={register({ required: true })}
              tabIndex={1}
            />
          </div>
          <PasswordField
            name="passwordConfirmation"
            label="Confirm new password"
            placeholder="∗∗∗∗∗∗∗∗"
            ref={register({ required: true })}
            tabIndex={2}
          />
          {error && <span className="tw-text-semantic-error tw-text-xs">{error}</span>}
          <SubmitButton
            type="submit"
            className="tw-w-full tw-mt-8 tw-font-bold"
            isFetching={loading}
            tabIndex={3}
            disabled={loading}
          >
            {loading ? 'Submitting' : 'Reset and Sign in'}
          </SubmitButton>
        </form>
      </FormProvider>
    </>
  );
};

const Content = ({ loading, fetchError, token, email }) => {
  if (loading) return <Loading />;

  if (fetchError) return <Error />;

  return <Form token={token} email={email} />;
};

const ResetPassword = ({ isLoading, fetchError, email, token }) => {
  return (
    <Layout>
      <Content email={email} loading={isLoading} fetchError={fetchError} token={token} />
    </Layout>
  );
};

export default ResetPassword;
