import { gql } from '@apollo/client';

export const GET_USER_EVENTS = gql`
  query UserEvents {
    me {
      ... on User {
        events {
          id
          code
          createdAt
        }
      }
    }
  }
`;

export const GET_COMPLETED_ACCOUNT_GAMIFICATION_EVENTS = gql`
  query CompletedAccountGamificationEvents {
    me {
      ... on User {
        account {
          completedGamificationEvents {
            id
            code
            createdAt
          }
        }
      }
    }
  }
`;
