import React from 'react';
import { IoAttachOutline } from 'react-icons/io5';

import Button from 'components/Button';

const UnmatchedReceiptSelector = ({ setShowReceiptModal }) => (
  <Button type="button" onClick={() => setShowReceiptModal(true)} className="tw-flex tw-pt-2">
    <IoAttachOutline size={24} className="tw-mr-2 tw-text-primary-dark-green" />
    <div className="tw-text-sm tw-text-primary-dark-green">Select from receipts uploaded via SMS</div>
  </Button>
);

export default UnmatchedReceiptSelector;
