import React from 'react';

import { AddCreditCardDocument, PayeeType } from '..';
import { AddCRAPayee } from './components';

const CRASteps = ({ step, handlePrevStep, handleNextStep, onFinish }) => {
  switch (step) {
    case 2:
      return <AddCreditCardDocument onFinish={onFinish} onPreviousStep={handlePrevStep} />;
    case 1:
      return <AddCRAPayee onFinish={onFinish} onPreviousStep={handlePrevStep} />;
    default:
      return <PayeeType onNextStep={handleNextStep} />;
  }
};

export default CRASteps;
