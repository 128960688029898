import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import { GET_FX_RATES_BY_CURRENCIES } from 'graphql/fxRates';
import { FXRateSource, FXRate, FXRatesByCurrenciesResponse } from 'types/fxRates';
import { PayBalanceCurrency, Money } from 'types/payments';
import { GlobalLimitInfo } from 'types/creditCard';
import { useDeepEffect } from 'hooks';
import { GlobalLimitChartType } from '../components/GlobalLimitChart/GlobalLimitChart.types';
import { ConvertedGlobalLimitInfo, UseGetConvertedBalancesProps } from '../GlobalLimit.types';
import { getConvertedBalance } from '../GlobalLimit.utils';
import { Currencies } from 'constants/currencies';

const useGetConvertedGlobalLimitInfo = ({ globalLimitInfo, chartType }: UseGetConvertedBalancesProps) => {
  const [fxRates, setFxRate] = useState<FXRate[] | null>(null);
  const [convertedGlobalLimitInfo, setConvertedGlobalLimitInfo] = useState<ConvertedGlobalLimitInfo | null>(null);

  const [getFXRates, { loading: isFXRatesLoading, error: fxRatesError }] = useLazyQuery<FXRatesByCurrenciesResponse>(
    GET_FX_RATES_BY_CURRENCIES,
    {
      variables: {
        buyCurrencies: [GlobalLimitChartType.USD, GlobalLimitChartType.EUR, GlobalLimitChartType.GBP],
        sellCurrency: PayBalanceCurrency.CAD,
        entity: FXRateSource.CurrencyCloud,
      },
      onCompleted(data) {
        setFxRate(data?.fxRates);
      },
    }
  );

  useDeepEffect(() => {
    const currentFXCurrency = chartType as unknown as Currencies;

    const fxRate = fxRates?.find((rate) => rate.buyCurrency === currentFXCurrency);

    if (!fxRate) {
      setConvertedGlobalLimitInfo(null);
      return;
    }

    const convertedBalances = Object.entries(globalLimitInfo).reduce((acc, balance) => {
      const [key, amount] = balance as [keyof GlobalLimitInfo, Money];

      const convertedBalance = getConvertedBalance({
        amount,
        fxRate,
      });

      acc[key] = convertedBalance;

      return acc;
    }, {} as ConvertedGlobalLimitInfo);

    setConvertedGlobalLimitInfo(convertedBalances);
  }, [fxRates, globalLimitInfo, chartType]);

  useEffect(() => {
    if (![GlobalLimitChartType.USD, GlobalLimitChartType.EUR, GlobalLimitChartType.GBP].includes(chartType)) return;

    getFXRates();
  }, [getFXRates, chartType]);

  useEffect(() => {
    if (fxRatesError) {
      toast.error('Failed to load conversion rates for limit info.');
    }
  }, [fxRatesError]);

  return {
    convertedGlobalLimitInfo,
    isFXRatesLoading,
  };
};

export default useGetConvertedGlobalLimitInfo;
