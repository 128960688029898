import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
// import { toast } from 'react-toastify';

import { GET_CRA_BUSINESS_NUMBER } from 'graphql/user';
import { CADBankAccountOnboardingContext } from 'components/wallets/OpenCADAccountModal/contexts/CADBankAccountOnboardingContext';
import { DetailsProps, FormData } from '../Details.types';

const useDetails = ({ onNextStep }: DetailsProps) => {
  const { craBusinessNumber: initialCraBusinessNumber, setCraBusinessNumber } = useContext(
    CADBankAccountOnboardingContext
  );

  const { data, loading } = useQuery(GET_CRA_BUSINESS_NUMBER);

  const form = useForm({
    defaultValues: {
      craBusinessNumber: initialCraBusinessNumber,
    },
  });

  const { register, handleSubmit, watch, setValue } = form;

  const watchElectronicAgreement = watch('electronicAgreement');

  const onSubmit = ({ craBusinessNumber }: FormData) => {
    setCraBusinessNumber(craBusinessNumber);
    onNextStep();
  };

  useEffect(() => {
    if (loading || initialCraBusinessNumber) return;

    const craBusinessNumber = data?.me?.account?.craBusinessNumber;

    if (!craBusinessNumber) {
      // toast.warn('Can not pre-fill CRA Business Number');
      return;
    }

    setValue('craBusinessNumber', craBusinessNumber);
  }, [data, initialCraBusinessNumber]);

  return {
    form,
    register,
    handleSubmit,
    acceptedElectronicAgreement: watchElectronicAgreement,
    onSubmit,
    isLoading: loading && !initialCraBusinessNumber,
  };
};

export default useDetails;
