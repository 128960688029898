import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_LINE_OF_CREDIT_INFO } from 'graphql/lineOfCredit';

const useGetLineOfCreditInfo = () => {
  const { loading, data } = useQuery(GET_LINE_OF_CREDIT_INFO);

  const { me, accountInfo } = data || {};
  const lineOfCredit = get(me, 'account.lineOfCredit');
  const lineOfCreditLedgerAccount = get(me, 'account.lineOfCredit.ledgerAccount');
  const wallets = get(me, 'account.wallets') || [];
  const creditCards = get(me, 'account.creditCards') || [];
  const bankAccounts = get(me, 'account.bankAccounts') || [];
  const transactions = get(me, 'account.lineOfCredit.transactions') || [];
  const lineOfCreditAccNum = get(accountInfo, 'number');
  const hasLineOfCredit = get(accountInfo, 'hasLineOfCredit');
  const fundInfo = get(accountInfo, 'fundInfo');

  return {
    loading,
    lineOfCredit,
    lineOfCreditLedgerAccount,
    wallets,
    creditCards,
    bankAccounts,
    transactions,
    lineOfCreditAccNum,
    hasLineOfCredit,
    fundInfo,
  };
};

export default useGetLineOfCreditInfo;
