import { useContext, useMemo } from 'react';

import { CardRepaymentContext } from 'context/CardRepayment';
import { Transaction } from 'types/payments';
import { formatMoneyV2 } from 'utility/currency';

const useTransactionDetails = ({ transaction }: { transaction: Transaction }) => {
  const { bankAccounts, wallets } = useContext(CardRepaymentContext);

  const { selectedFromAccountId, originalAmount, fxAmount, isFromBankAccount } = transaction;

  const { currency: originalCurrency } = originalAmount;

  const { amount, currency } = fxAmount || originalAmount;

  const formattedAmount = `${currency} ${formatMoneyV2({ amount, currency })}`;
  const formattedFeesAmount = `${currency} ${formatMoneyV2({ amount: 0, currency })}`;

  const fromAccount = useMemo(
    () => [...bankAccounts, ...wallets].find((account) => account.id === selectedFromAccountId),
    [bankAccounts, wallets, selectedFromAccountId]
  );

  const methodName = isFromBankAccount ? 'External Transfer' : 'Internal Transfer';
  const fromAccountName = fromAccount?.displayName;

  const toAccountName = `Loop Card - ${originalCurrency} Balance`;

  return {
    methodName,
    fromAccountName,
    toAccountName,
    formattedAmount,
    formattedFeesAmount,
  };
};

export default useTransactionDetails;
