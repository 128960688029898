import { useContext, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, ApolloError } from '@apollo/client';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import { userRoles } from 'constants/index';
import { GET_CONTACTS_AND_CORPORATE_SHAREHOLDER } from 'graphql/onboarding';
import { AuthContext } from 'context/Auth';
import { CADBankAccountOnboardingContext } from 'components/wallets/OpenCADAccountModal/contexts/CADBankAccountOnboardingContext';
import { useToggle } from 'hooks';
import { User } from 'types/user';
import { MembersProps, FormData } from '../Members.types';
import { MINIMAL_RELEVANT_OWNERSHIP_PERCENTAGE } from '../constants';

const useDetails = ({ onNextStep }: Pick<MembersProps, 'onNextStep'>) => {
  const { memberDetails, setMemberDetails, setError, setExternalAccount, onCreateAccount } = useContext(
    CADBankAccountOnboardingContext
  );

  const { me, meLoading } = useContext(AuthContext) as unknown as { me: User; meLoading: boolean };
  const { firstName, lastName } = me || {};

  const { isOpen: isLoading, open: showLoading, close: hideLoading } = useToggle();

  const { loading, data } = useQuery(GET_CONTACTS_AND_CORPORATE_SHAREHOLDER);
  const contacts = get(data, 'me.account.contacts') || [];
  const corporateShareholders = get(data, 'me.account.corporateShareholders') || [];

  const ownersWithOverMinimalOwnership = useMemo(
    () =>
      [...contacts, ...corporateShareholders].filter(
        (owner) => owner.ownership >= MINIMAL_RELEVANT_OWNERSHIP_PERCENTAGE
      ),
    [contacts, corporateShareholders]
  );

  const accountOwners = useMemo(
    () => [...contacts].filter((owner) => owner.roles.includes(userRoles.ACCOUNT_OWNER)),
    [contacts]
  );

  const relevantOwners = useMemo(
    () => (ownersWithOverMinimalOwnership.length ? ownersWithOverMinimalOwnership : accountOwners),
    [ownersWithOverMinimalOwnership, accountOwners]
  );

  const form = useForm({
    defaultValues: {
      members: memberDetails,
    },
  });

  const { register, handleSubmit, watch } = form;

  const isAcceptedTerms = watch('acceptedTerms');

  const onSubmit = useCallback(
    async ({ members }: FormData) => {
      const processingToast = toast.loading('Submitting CAD bank account details...');

      try {
        showLoading();

        const accountId = await onCreateAccount({ members });

        if (!accountId) throw new Error('Could not submit CAD bank account details.');

        setExternalAccount((prevState) => ({
          ...prevState,
          id: accountId,
        }));

        setMemberDetails(members);

        toast.update(processingToast, {
          render: 'CAD bank account details submitted successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });

        onNextStep();
      } catch (error) {
        setError(error as ApolloError);
        toast.update(processingToast, {
          render: `Something went wrong. ${(error as ApolloError)?.message || error}`,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      } finally {
        hideLoading();
      }
    },
    [onCreateAccount, setError, onNextStep]
  );

  return {
    firstName,
    lastName,
    relevantOwners,
    form,
    register,
    handleSubmit,
    isAcceptedTerms,
    onSubmit,
    memberDetailsLoading: meLoading || loading,
    isLoading,
  };
};

export default useDetails;
