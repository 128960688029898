import React, { useContext, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useLazyQuery } from '@apollo/client';

import { WalletTransactionsFilterContext } from '../context/WalletTransactionsFilterContext';
import { GET_WALLET_TRANSACTIONS } from 'graphql/accountingServices';
import Button from 'components/Button';
import CurrencyDropdown from 'components/CurrencyDropdown';
import { GenericDropDown } from './GenericDropDown';
import { MonthYearDropDown } from './MonthYearDropDown';
import { getDatesFromOption, getPeriodOptions } from 'utility/date';
import { QBO_TRANSACTION_STATUSES /* QBO_TRANSACTION_TYPES */ } from 'constants/index';

export const WalletTransactionsFilter = ({ setTransactions, className = '' }) => {
  const {
    startDate,
    endDate,
    currency,
    setStartDate,
    setEndDate,
    postedStatus,
    setPostedStatus,
    setCurrency,
    transactionType,
    // setTransactionType,
    resetFilter,
    setIsModified,
    isModified,
  } = useContext(WalletTransactionsFilterContext);

  const [extraLoading, setExtraLoading] = useState(false);

  const [loadQboTransactions, { loading }] = useLazyQuery(GET_WALLET_TRANSACTIONS, {
    onCompleted: (data) => {
      setTransactions(data.accountingIntegrationWalletTransactions);
      setExtraLoading(false);
    },
  });

  const reset = () => {
    resetFilter();
    setIsModified(false);
  };

  const fetchTransactions = () => {
    setExtraLoading(true);
    loadQboTransactions({
      variables: { startDate, endDate, currency, postedStatus, transactionType },
    });
  };

  return (
    <div className={`${className} tw-flex tw-flex-row tw-items-center`}>
      <MonthYearDropDown
        label="Period"
        optionsList={getPeriodOptions()}
        selectedValue={`${startDate.toLocaleString('default', { month: 'long', year: 'numeric' }).toLocaleLowerCase()}`}
        onChange={(value) => {
          const { from, to } = getDatesFromOption(value);
          setStartDate(from);
          setEndDate(to);
          setIsModified(true);
        }}
      />
      <GenericDropDown
        label="Status"
        optionsList={QBO_TRANSACTION_STATUSES}
        selectedValue={postedStatus}
        onChange={(value) => {
          setPostedStatus(value);
          setIsModified(true);
        }}
      />
      {/*
      <GenericDropDown
        label="Transaction Type"
        optionsList={QBO_TRANSACTION_TYPES}
        selectedValue={transactionType}
        onChange={(value) => {
          setTransactionType(value);
          setIsModified(true);
        }}
      />
      */}
      <CurrencyDropdown
        currency={currency}
        setCurrency={(value) => {
          setCurrency(value);
          setIsModified(true);
        }}
      />

      <div className="tw-flex tw-flex-row tw-ml-6">
        {isModified && (
          <div
            onClick={reset}
            data-testid="filter-clear-button"
            className="tw-flex tw-items-center tw-cursor-pointer tw-text-neutral-grey-2 tw-mr-2"
          >
            <IoCloseCircleOutline size={18} className="tw-mr-2" />
            <small>Clear all</small>
          </div>
        )}
        <Button primary onClick={fetchTransactions} isFetching={extraLoading || loading}>
          Update
        </Button>
      </div>
    </div>
  );
};
