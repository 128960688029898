import React, { memo } from 'react';
import cx from 'classnames';

const Content = ({
  className,
  heightClassName,
  primaryBgClassName,
  secondaryBgClassName,
  width,
}: {
  className?: string;
  heightClassName: string;
  primaryBgClassName: string;
  secondaryBgClassName: string;
  width: number;
}) => (
  <div
    className={cx('tw-overflow-hidden tw-rounded-md', className, heightClassName, secondaryBgClassName)}
    role="progressbar"
    aria-valuemin={0}
    aria-valuemax={100}
    aria-valuenow={width}
    aria-label="Progress bar"
  >
    <div className={cx(primaryBgClassName, 'tw-rounded-md tw-h-full tw-z-10')} style={{ width: `${width}%` }} />
    <div className={cx(secondaryBgClassName, 'tw-rounded-md tw-h-full')} />
  </div>
);

export default memo(Content);
