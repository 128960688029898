import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
  <div className="tw-flex tw-flex-col-reverse tw-justify-between tw-mb-6">
    <h1>Get started</h1>
    <Link to="/signin" className="tw-flex tw-self-end">
      <strong className="tw-flex tw-items-center tw-ml-1 tw-text-primary-dark-green">
        Log in&nbsp;<span className="tw-text-xs">&gt;</span>
      </strong>
    </Link>
  </div>
);

export default Header;
