import { CREDIT_CARD_EMPTY_STATES } from 'constants/index';

const creditCardEmptyState = ({
  hasLineOfCredit,
  creditCards,
  hasAtLeastOneBankAccountVerified,
  connectedBankAccount,
  globalCreditLimit,
}) => {
  const hasNoCreditCards = creditCards.length === 0;
  const isExistingLocUser = hasLineOfCredit && hasNoCreditCards;

  if (isExistingLocUser) return CREDIT_CARD_EMPTY_STATES.isExistingLocUser;

  if (!globalCreditLimit) return CREDIT_CARD_EMPTY_STATES.reviewInProgress;

  if (!connectedBankAccount) return CREDIT_CARD_EMPTY_STATES.connectBankAccount;

  if (!hasAtLeastOneBankAccountVerified) return CREDIT_CARD_EMPTY_STATES.waitingBankAccountVerification;

  if (hasAtLeastOneBankAccountVerified) return CREDIT_CARD_EMPTY_STATES.createCreditCard;
};

export default creditCardEmptyState;
