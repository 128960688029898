import { useContext } from 'react';

import { useToggle } from 'hooks';
import { AuthContext } from 'context/Auth';
import { PayorUser } from 'types/invoicing';
import { formatDateTime } from 'utility/date';
import { PADAgreementProps } from '../PADAgreement.types';

const usePADAgreement = ({ payorBankRoutingNumber }: Pick<PADAgreementProps, 'payorBankRoutingNumber'>) => {
  const { me } = useContext(AuthContext) as unknown as {
    me: PayorUser;
  };

  const { email: payorEmail, legalBusinessName: payorBusinessName, firstName, lastName } = me || {};

  const { isOpen: isShowPADModal, open: openPADModal, close: closePADModal } = useToggle();

  const payorRepresentativeName = `${firstName} ${lastName}`;

  const signInDate = formatDateTime(new Date());

  const isUSBankAccount = !!payorBankRoutingNumber;

  return {
    payorEmail,
    payorBusinessName,
    payorRepresentativeName,
    signInDate,
    isShowPADModal,
    openPADModal,
    closePADModal,
    isUSBankAccount,
  };
};

export default usePADAgreement;
