import React from 'react';
import { TbHandMove } from 'react-icons/tb';

import { PaymentRequestCurrency } from 'types/invoicing';
import { TableProps } from './Table.types';
import { useTable } from './hooks';
import { TableRow, RequestInfoModal, ConfirmPaymentModal } from './components';
import { tableHeaders } from './constants';

import styles from './Table.module.scss';

const Table = ({ openBankConnectionModal }: TableProps) => {
  const {
    paymentRequests,
    selectedPaymentRequestId,
    selectedBankAccountId,
    isOpenInfoModal,
    handleOpenInfoModal,
    handleCloseInfoModal,
    isOpenConfirmPaymentModal,
    handleOpenConfirmPaymentModal,
    handleCloseConfirmPaymentModal,
    isTabletOrMobile,
    CADBankAccount,
    USDBankAccount,
  } = useTable();

  return (
    <>
      {isTabletOrMobile && (
        <div className={styles.swipe}>
          <TbHandMove className={styles.icon} />
        </div>
      )}

      <div className={styles.tableWrapper}>
        <table className="tw-table tw-table-auto tw-w-full tw-bg-neutral-light tw-rounded">
          <thead>
            <tr>
              {tableHeaders.map((tableHeader) => (
                <th key={tableHeader} className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">
                  {tableHeader}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paymentRequests.map((paymentRequest) => {
              const { id, amount } = paymentRequest;
              const { currency } = amount;

              const bankAccount = currency === PaymentRequestCurrency.CAD ? CADBankAccount : USDBankAccount;

              return (
                <TableRow
                  key={id}
                  paymentRequest={paymentRequest}
                  openInfoModal={() => handleOpenInfoModal({ id, currency })}
                  openConfirmPaymentModal={() => handleOpenConfirmPaymentModal({ id, currency })}
                  openBankConnectionModal={openBankConnectionModal}
                  bankAccount={bankAccount}
                />
              );
            })}
          </tbody>
        </table>
      </div>

      {selectedPaymentRequestId && (
        <RequestInfoModal
          show={isOpenInfoModal}
          onClose={handleCloseInfoModal}
          selectedPaymentRequestId={selectedPaymentRequestId}
          selectedBankAccountId={selectedBankAccountId}
        />
      )}

      {selectedPaymentRequestId && selectedBankAccountId && (
        <ConfirmPaymentModal
          show={isOpenConfirmPaymentModal}
          onClose={handleCloseConfirmPaymentModal}
          selectedPaymentRequestId={selectedPaymentRequestId}
          selectedBankAccountId={selectedBankAccountId}
        />
      )}
    </>
  );
};

export default Table;
