export type OwnershipDisclosureProps = {
  onPreviousStep?: () => void;
  onNextStep: () => void;
  enableFullScreen?: () => void;
  disableFullScreen?: () => void;
};

export enum OwnershipDisclosureState {
  completed = 'signing_complete',
}
