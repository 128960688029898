import React, { useState, useRef, useEffect } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { BiTransferAlt } from 'react-icons/bi';

import { ExchangeRateInfoProps } from './ExchangeRateInfo.types';
import InfoTooltip from 'components/InfoTooltip';
import { formatMoneyV2 } from 'utility/currency';
import Warning from 'components/svg/Warning';
import { ConvertFundsActionTypes } from 'components/Accounts/AccountsContent/components/AccountsModal/types/funds';
import { Loaders } from 'components/cards/Loader';

const ExchangeRateInfo = ({
  chargedAmount,
  originalAmount,
  rate,
  onRefreshRate,
  rateExpiresAt,
  expired,
  setExpired,
  buyOrSell,
  loadingRate,
  exchangeRateConfirmed,
  chargedAmountSellLabel = 'Amount to Withdraw',
  chargedAmountBuyLabel = 'Amount to Convert',
  originalAmountSellLabel = 'Amount to Receive',
  originalAmountBuyLabel = 'Amount to Buy',
}: ExchangeRateInfoProps) => {
  const [expiresInSeconds, setExpiresInSeconds] = useState<string>('');
  const timerRef = useRef<ReturnType<typeof setInterval> | undefined>();
  const startTimer = () => {
    if (!setExpired) return;
    clearTimer();
    setExpired(false);
    timerRef.current = setInterval(() => {
      if (!rateExpiresAt) return;
      const pastExpiresAt = new Date(rateExpiresAt) <= new Date();

      if (pastExpiresAt) {
        setExpiresInSeconds('');
        setExpired(true);
        clearInterval(timerRef.current);
      } else {
        setExpiresInSeconds(formatDistanceToNowStrict(new Date(rateExpiresAt)));
      }
    }, 500);
  };

  const clearTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current);
  };

  useEffect(() => {
    startTimer();

    return clearTimer;
  }, [rateExpiresAt]);

  if (loadingRate)
    return (
      <div className="tw-text-center">
        <Loaders.Small />
      </div>
    );

  return (
    <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4 tw-mt-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <div className="tw-flex tw-items-center">
          <small className="tw-font-semibold tw-mr-2">
            {buyOrSell === ConvertFundsActionTypes.sell ? chargedAmountSellLabel : chargedAmountBuyLabel}
          </small>
          <InfoTooltip message="The amount you are selling" />
        </div>
        <div className="tw-flex tw-items-center">
          <small className="tw-mr-2">{formatMoneyV2(chargedAmount)}</small>
          <span className="tw-text-xs tw-text-neutral-grey-2 tw-w-8 tw-text-right">{chargedAmount?.currency}</span>
        </div>
      </div>
      <div className="tw-flex tw-justify-between tw-items-center">
        <small className="tw-font-semibold">Your exchange rate</small>
        {!expired && rate && (
          <div className="tw-flex tw-items-center tw-justify-between">
            <small className="tw-mr-2">{rate.toFixed(5)}</small>
            <BiTransferAlt className="tw-text-neutral-grey-2 tw-transform tw-rotate-90 tw-w-8" />
          </div>
        )}
      </div>

      {!exchangeRateConfirmed && (
        <>
          {expired ? (
            <div className="tw-rounded-md tw-bg-secondary-pastel-yellow-80 tw-p-4 tw-my-4 tw-flex tw-justify-between tw-items-center">
              <Warning className="tw-mr-2" />
              <span className="tw-text-primary-dark-yellow tw-text-xs tw-w-3/5 tw-mr-2">
                The exchange rate expired. Refresh to see your final exchange rate.
              </span>
              <span
                onClick={onRefreshRate}
                className="tw-cursor-pointer tw-font-semibold tw-text-xs tw-text-primary-dark-green"
              >
                Refresh Rate
              </span>
            </div>
          ) : (
            <div className="tw-flex tw-justify-between tw-items-center tw-my-4">
              <span className="tw-text-neutral-grey-2 tw-text-xs tw-w-2/3">
                {`Rates expires in ${expiresInSeconds} seconds. Refresh to see your final exchange rate.`}
              </span>
              <span
                onClick={onRefreshRate}
                className="tw-cursor-pointer tw-font-semibold tw-text-neutral-grey-2 tw-text-xs"
              >
                Refresh Rate
              </span>
            </div>
          )}
          <div className="tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex tw-items-center">
              <small className="tw-font-semibold tw-mr-2">
                {buyOrSell === ConvertFundsActionTypes.sell ? originalAmountSellLabel : originalAmountBuyLabel}
              </small>
              <InfoTooltip message="The amount you are buying" />
            </div>
            <div className="tw-flex tw-items-center">
              <small className="tw-mr-2">{formatMoneyV2(originalAmount)}</small>
              <span className="tw-text-xs tw-text-neutral-grey-2 tw-w-8 tw-text-right">{originalAmount?.currency}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExchangeRateInfo;
