import React from 'react';

const QboTxTableHeader = ({ accountName }) => (
  <>
    <div className="tw-text-xs tw-text-neutral-grey-2 tw-font-normal tw-text-left">Merchant</div>
    <div className="tw-text-xs tw-text-neutral-grey-2 tw-font-normal tw-text-left">{accountName} Vendor</div>
    <div className="tw-text-xs tw-text-neutral-grey-2 tw-font-normal tw-text-left">Loop Description</div>
    <div className="tw-text-xs tw-text-neutral-grey-2 tw-font-normal tw-text-left">Amount</div>

    <div className="tw-text-xs tw-text-neutral-grey-2 tw-font-normal tw-text-right">
      <span
        data-tip={`Select the ${accountName} Expense Account that you want this transaction entry to be posted to.`}
      >
        {accountName} Expense Account
      </span>
    </div>
    <div className="tw-text-xs tw-text-neutral-grey-2 tw-font-normal tw-text-right">
      <span
        data-tip={`Select the correct sales tax rate to be included on the ${accountName} entry. <br/> By default, Loop uses the default tax rate for the selected expense account, however, you can modify the tax rate for each of these transactions.`}
      >
        {accountName} Sales Tax
      </span>
    </div>
    <div className="tw-text-xs tw-text-neutral-grey-2 tw-font-normal tw-text-right">
      <span
        data-tip={`The date that will be included as the transaction date on the ${accountName} entry. <br/> You can modify the date below if you want to change the date being pushed to ${accountName}.`}
      >
        {accountName} Payment Date
      </span>
    </div>
    <div className="tw-text-xs tw-text-neutral-grey-2 tw-font-normal tw-text-right">
      <span
        data-tip={`'Pending': This transaction has not yet been selected to be posted to ${accountName}. <br/>
'Processing': A request to post the transaction has been submitted and will be completed shortly.  <br/>
'Posted': The transaction has been posted to ${accountName}.`}
      >
        Status
      </span>
    </div>
    <div className="tw-text-xs tw-text-neutral-grey-2 tw-font-normal tw-text-right">
      <span data-tip={`Select the transactions that you want to post to ${accountName}.`}>Select</span>
    </div>
  </>
);

export default QboTxTableHeader;
