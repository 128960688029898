import React from 'react';

import { Modal } from 'components/UI';
import { CreateCardModalProps } from './CreateCardModal.types';
import { CreateCardContextProvider } from './context/CreateCardContext';
import useCreateCardModal from './hooks/useCreateCardModal';
import { CreateCard, LoadingScreen } from './components';
import styles from './CreateCardModal.module.scss';

const CreateCardModal = ({ show, onClose }: CreateCardModalProps) => {
  const { loading, creditCards } = useCreateCardModal();

  return (
    <Modal show={show} onClose={onClose} isFullScreen>
      <CreateCardContextProvider creditCards={creditCards} onClose={onClose}>
        <div className={styles.content}>{loading ? <LoadingScreen /> : <CreateCard />}</div>
      </CreateCardContextProvider>
    </Modal>
  );
};

export default CreateCardModal;
