import React, { useState, useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiTransferAlt } from 'react-icons/bi';

import { SOURCE_LOC } from 'constants/sources';
import { centsFromMoneyString, formatMoneyV2 } from 'utility/currency';
import { PaymentContext } from 'context/Payment';
import { Select, SubmitButton, MoneyInputField } from 'components/FormFields/v2';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { Loaders } from 'components/cards/Loader';
import InfoTooltip, { useRebuildTooltipForModal } from 'components/InfoTooltip';
import EmptyState from 'components/payments/EmptyState';

const Details = ({
  bankAccounts,
  wallets,
  creditCardAccounts,
  hasLineOfCredit,
  lineOfCredit,
  lineOfCreditLedgerAccount,
  footerBorder,
  footerButton,
  getExchangeRate,
  rate,
  source,
  loadingRate,
  onNextStep,
  loading,
}) => {
  if (loading)
    return (
      <div className="tw-flex tw-justify-center tw-py-10">
        <Loaders.Spinner />
      </div>
    );
  if (!bankAccounts || bankAccounts.length === 0)
    return (
      <EmptyState
        description="You have no bank accounts connected."
        footerBorder={footerBorder}
        footerButton={footerButton}
      />
    );
  if (!wallets || wallets.length === 0)
    return <EmptyState description="You have no wallets." footerBorder={footerBorder} footerButton={footerButton} />;

  const { setMoveMoneyInfo, moveMoneyInfo } = useContext(PaymentContext);
  const form = useForm();
  const { register, handleSubmit, watch, setValue, setError } = form;

  const [fromCurrency, setFromCurrency] = useState('CAD');
  const [formattedFromAmount, setFormattedFromAmount] = useState(formatMoneyV2({ amount: 0, currency: fromCurrency }));
  const [toCurrency, setToCurrency] = useState('CAD');
  const [formattedToAmount, setFormattedToAmount] = useState(formatMoneyV2({ amount: 0, currency: toCurrency }));
  const [selectFromAccount, setSelectFromAccount] = useState('');
  const [selectToAccount, setSelectToAccount] = useState('');

  const walletsAndBankAccounts = wallets.concat(bankAccounts);
  const walletsCardsAndLineOfCredit = hasLineOfCredit
    ? wallets.concat(creditCardAccounts, lineOfCredit)
    : wallets.concat(creditCardAccounts);

  const toValue = watch('toAccount');
  const fromValue = watch('fromAccount');

  const currentToAccount = walletsAndBankAccounts.find((option) => option.id === toValue);
  const currentFromAccount = walletsCardsAndLineOfCredit.find((option) => option && option.id === fromValue);

  const needsConversion =
    currentFromAccount && currentToAccount && currentToAccount.currency !== currentFromAccount.currency;

  const { toOptions, fromOptions } = getDropdownOptions({
    wallets,
    creditCardAccounts,
    lineOfCredit,
    lineOfCreditLedgerAccount,
    walletsAndBankAccounts,
    walletsCardsAndLineOfCredit,
    currentToAccount,
    currentFromAccount,
    source,
  });

  useEffect(() => {
    const fillFormWithPreviousValues = () => {
      const { fromAccount, toAccount, originalAmount, chargedAmount } = moveMoneyInfo;

      setValue('toAccount', toAccount.id);
      setValue('fromAccount', fromAccount.id);
      setFormattedFromAmount(formatMoneyV2(chargedAmount));
      setFormattedToAmount(formatMoneyV2(originalAmount));
    };

    if (moveMoneyInfo) {
      fillFormWithPreviousValues();
    }
  }, [moveMoneyInfo]);

  useRebuildTooltipForModal();

  useSyncCurrencyWithAccount({ setCurrency: setFromCurrency, account: currentFromAccount });
  useSyncCurrencyWithAccount({ setCurrency: setToCurrency, account: currentToAccount });

  useEffect(() => {
    const syncConvertedAmountWithRate = () => {
      setFormattedToAmount(
        formatMoneyV2({ amount: centsFromMoneyString(formattedFromAmount) / rate, currency: toCurrency })
      );
    };

    if (needsConversion && rate) {
      syncConvertedAmountWithRate();
    }
  }, [rate]);

  useEffect(() => {
    if (needsConversion && !loadingRate) {
      getExchangeRate({
        variables: {
          buy: { currency: currentToAccount.currency, amount: 1_000_00 },
          sell: { currency: currentFromAccount.currency, amount: 1_000_00 },
        },
      });
    }
  }, [toValue, fromValue]);

  const syncOriginalAmountWithConverted = (newConvertedAmount) => {
    if (needsConversion && !loadingRate) {
      setFormattedFromAmount(
        formatMoneyV2({ amount: centsFromMoneyString(newConvertedAmount) * rate, currency: fromCurrency })
      );
    }
  };

  const syncConvertedAmountWithOriginal = (newOriginalAmount) => {
    if (needsConversion && !loadingRate) {
      setFormattedToAmount(
        formatMoneyV2({ amount: centsFromMoneyString(newOriginalAmount) / rate, currency: toCurrency })
      );
    }
  };

  const onSubmit = (data) => {
    const { fromAmount, toAmount } = data;
    const chargedAmount = { amount: centsFromMoneyString(fromAmount), currency: fromCurrency };
    const originalAmount = { amount: centsFromMoneyString(toAmount), currency: toCurrency };
    const fromIsWallet = wallets.some((w) => w.id === currentFromAccount.id);
    const fromIsLoc = lineOfCreditLedgerAccount && lineOfCreditLedgerAccount.id === currentFromAccount.id;
    const sufficientFunds =
      fromIsWallet || fromIsLoc ? currentFromAccount.availableBalance.amount >= chargedAmount.amount : true;

    if (sufficientFunds) {
      setMoveMoneyInfo({
        fromAccount: currentFromAccount,
        toAccount: currentToAccount,
        originalAmount: needsConversion ? originalAmount : chargedAmount,
        chargedAmount,
      });
      onNextStep();
    } else {
      setError('fromAccount', { type: 'manual', message: 'Insufficient funds' });
    }
  };

  // TODO: Fix issue where data is not persisting

  const handleSelectFromAccount = (e) => {
    setSelectFromAccount(e.target.value);
  };

  useEffect(() => {
    setValue('fromAccount', selectFromAccount);
  }, [toValue]);

  const handleSelectToAccount = (e) => {
    setSelectToAccount(e.target.value);
  };

  useEffect(() => {
    setValue('toAccount', selectToAccount);
  }, [fromValue]);

  return (
    <div className="tw-mt-8">
      <div className="tw-px-8">
        <small className="tw-text-neutral-grey-2">
          Move money between accounts. If the accounts don't have matching currencies you will be prompted to perform a
          currency conversion.
        </small>
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-p-8">
            <Select
              options={toOptions}
              name="toAccount"
              label="Recipient Account"
              placeholder="Choose"
              ref={register({ required: true })}
              rootClass="tw-mb-4"
              data-testid="to-account-select"
              onChange={handleSelectToAccount}
            />
            <Select
              options={fromOptions}
              name="fromAccount"
              label="From Account"
              placeholder="Choose"
              disabled={!selectToAccount}
              ref={register({ required: true })}
              rootClass="tw-mb-4"
              data-testid="from-account-select"
              onChange={handleSelectFromAccount}
            />
            <div className="tw-flex tw-items-end tw-mb-4">
              <MoneyInputField
                ref={register({ required: true })}
                name="fromAmount"
                label="You Send"
                currency={fromCurrency}
                value={formattedFromAmount}
                moneyFormatter={formatMoneyV2}
                setValue={setFormattedFromAmount}
                onChange={syncConvertedAmountWithOriginal}
                rootClass="tw-flex-grow tw-mr-4"
                data-testid="amount-input"
              />
              <div className="tw-flex tw-items-center tw-h-11 tw-bg-neutral-grey-4 tw-rounded-md tw-p-4 tw-flex-grow-0">
                <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5 tw-mr-2">
                  <MobileCurrencyFlag currency={fromCurrency} size={16} />
                </div>
                <p className="tw-text-neutral-grey-2">{fromCurrency}</p>
              </div>
            </div>
            {loadingRate && (
              <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4 tw-mb-4 tw-flex tw-items-center tw-justify-center">
                <Loaders.Small />
              </div>
            )}
            {needsConversion && !loadingRate && (
              <ExchangeRateInfo
                register={register}
                rate={rate}
                originalAmountFormatted={formattedFromAmount}
                originalCurrency={fromCurrency}
                convertedAmountFormatted={formattedToAmount}
                setConvertedAmountFormatted={setFormattedToAmount}
                convertedCurrency={toCurrency}
                onChangeConvertedAmount={syncOriginalAmountWithConverted}
              />
            )}
          </div>
          <div
            className={`${
              footerBorder ? 'tw-border-t tw-border-neutral-grey-3 tw-pt-4' : 'tw-pb-4'
            } tw-px-8 tw-flex tw-justify-end`}
          >
            <SubmitButton className="tw-w-full lg:tw-w-max">Review</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const getDropdownOptions = ({
  wallets,
  creditCardAccounts,
  walletsAndBankAccounts,
  lineOfCredit,
  lineOfCreditLedgerAccount,
  walletsCardsAndLineOfCredit,
  currentToAccount,
  currentFromAccount,
  source,
}) => {
  const toIsWallet = currentToAccount && wallets.some((w) => w.id === currentToAccount.id);
  const isLocWithdrawal =
    lineOfCreditLedgerAccount &&
    (source == SOURCE_LOC || (currentFromAccount && lineOfCreditLedgerAccount.id === currentFromAccount.id));

  const toOptions = walletsAndBankAccounts.reduce((options, option) => {
    if (isLocWithdrawal && lineOfCreditLedgerAccount && option.currency !== lineOfCreditLedgerAccount.currency)
      return options;

    return [...options, { name: option.displayName, value: option.id }];
  }, []);

  const fromOptions = walletsCardsAndLineOfCredit.reduce((options, option) => {
    if (!option) return options;
    const optionIsCard = creditCardAccounts.some((cca) => cca.id === option.id);
    const optionIsLoc = lineOfCredit && lineOfCredit.id === option.id;
    const currencyIsDifferentFromToAccount = currentToAccount && currentToAccount.currency !== option.currency;
    const name = optionIsCard
      ? `${option.displayName} (${option.balance.currency} ${formatMoneyV2(option.balance)})`
      : (optionIsLoc &&
          `${option.ledgerAccount.displayName} (${option.availableCredit.currency} ${formatMoneyV2(
            option.availableCredit
          )})`) ||
        `${option.displayName} (${option.availableBalance.currency} ${formatMoneyV2(option.availableBalance)})`;

    const isSameAccount = currentToAccount && currentToAccount.id === option.id;
    if (isSameAccount) return options;

    const cardToWallet = toIsWallet && optionIsCard; // This will be removed separately when we allow withdrawals from card to wallet
    if (cardToWallet) return options;

    if (!toIsWallet && currencyIsDifferentFromToAccount) return options;

    const locTransaction = optionIsLoc && currencyIsDifferentFromToAccount;
    if (locTransaction) return options;

    if (isLocWithdrawal && !optionIsLoc) return options;

    const optionIsUSBank = option?.country === 'US'; // TODO: Remove filter after adding support for US-based accounts for Payments
    if (optionIsUSBank && !toIsWallet) return options;

    const currentToIsUSBank = currentToAccount?.country === 'US';
    if (currentToIsUSBank && (optionIsCard || optionIsLoc)) return options;

    return [...options, { name, value: option.id }];
  }, []);

  return { toOptions, fromOptions };
};

const useSyncCurrencyWithAccount = ({ setCurrency, account }) => {
  useEffect(() => {
    if (account) {
      const currency = account.currency ? account.currency : account.availableCredit.currency;
      setCurrency(currency);
    }
  }, [account]);
};

const ExchangeRateInfo = ({
  rate,
  originalAmountFormatted,
  originalCurrency,
  convertedAmountFormatted,
  setConvertedAmountFormatted,
  convertedCurrency,
  onChangeConvertedAmount,
  register,
}) => {
  if (!rate) {
    return (
      <div className="tw-rounded-md tw-bg-secondary-pastel-yellow-80 tw-p-4 tw-mb-4 tw-flex tw-justify-between tw-items-center">
        <span className="tw-text-primary-dark-yellow tw-text-xs">
          An error occurred while retrieving the exchange rate. Please try again later.
        </span>
      </div>
    );
  }

  return (
    <>
      <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4 tw-mb-4">
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <div className="tw-flex tw-items-center">
            <small className="tw-font-semibold tw-mr-2">Amount to withdraw</small>
            <InfoTooltip message="The amount you are selling" />
          </div>
          <div className="tw-flex tw-items-center">
            <small className="tw-mr-2">{originalAmountFormatted}</small>
            <span className="tw-text-xs tw-text-neutral-grey-2 tw-w-8">{originalCurrency}</span>
          </div>
        </div>
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <small className="tw-font-semibold">Your exchange rate</small>
          <div className="tw-flex tw-items-center">
            <small className="tw-mr-2">{rate && rate.toFixed(5)}</small>
            <BiTransferAlt className="tw-text-neutral-grey-2 tw-transform tw-rotate-90 tw-w-8" />
          </div>
        </div>
        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-flex tw-items-center">
            <small className="tw-font-semibold tw-mr-2">Payment amount</small>
            <InfoTooltip message="The amount you are buying" />
          </div>
          <div className="tw-flex tw-items-center">
            <small className="tw-mr-2">{convertedAmountFormatted}</small>
            <span className="tw-text-xs tw-text-neutral-grey-2 tw-w-8">{convertedCurrency}</span>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-items-end tw-mb-4">
        <MoneyInputField
          ref={register()}
          name="toAmount"
          label="Recipient Gets"
          currency={convertedCurrency}
          value={convertedAmountFormatted}
          moneyFormatter={formatMoneyV2}
          setValue={setConvertedAmountFormatted}
          onChange={onChangeConvertedAmount}
          rootClass="tw-flex-grow tw-mr-4"
        />
        <div className="tw-flex tw-items-center tw-h-11 tw-bg-neutral-grey-4 tw-rounded-md tw-p-4 tw-flex-grow-0">
          <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5 tw-mr-2">
            <MobileCurrencyFlag currency={convertedCurrency} size={16} />
          </div>
          <p className="tw-text-neutral-grey-2">{convertedCurrency}</p>
        </div>
      </div>
    </>
  );
};

export default Details;
