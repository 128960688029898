import useIsAdmin from 'hooks/useIsAdmin';
import useIsMember from 'hooks/useIsMember';
import { formatMoneyWithoutCurrencyLabel } from 'utility/currency';
import { ReimbursementApprovalRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';
import { formatDateTime } from 'utility/date';

const useApprovalsRow = ({ request }: { request: ReimbursementApprovalRequestDataType }) => {
  const { isAdmin } = useIsAdmin();
  const { isMember } = useIsMember();

  const { requester, originalAmount, createdAt, merchantName = '-', status = '-' } = request || {};
  const formattedCreatedAt = createdAt ? formatDateTime(createdAt, 'MMM d, yyyy') : '-';
  const formattedOriginalAmount = formatMoneyWithoutCurrencyLabel(originalAmount);

  const getInitials = (name: string) =>
    name
      .split(' ')
      .map((word) => word[0])
      .join('');

  const requesterInitials = getInitials(requester.name);

  const handleStopClickRow = (e: React.MouseEvent<HTMLTableCellElement>) => {
    e.stopPropagation();
  };

  return {
    isAdmin,
    isMember,
    requester,
    merchantName,
    status,
    currency: originalAmount.currency,
    formattedOriginalAmount,
    formattedCreatedAt,
    handleStopClickRow,
    requesterInitials,
  };
};
export default useApprovalsRow;
