import { gql } from '@apollo/client';

export const GET_POINTS_TRANSACTIONS_PAGE = gql`
  query PointsTransactionsPage($startDate: String, $endDate: String, $page: String, $numPerPage: String) {
    pointsTransactionsPage(startDate: $startDate, endDate: $endDate, page: $page, numPerPage: $numPerPage) {
      items {
        createdAt
        label
        points
        balance
      }
      pageData {
        totalPages
        currentPage
        nextPage
        prevPage
        totalCount
      }
    }
  }
`;
