import { useState, useCallback, useMemo } from 'react';

import { useToggle } from 'hooks';
import { TableProps } from '../Table.types';

const useTable = ({ paymentRequests }: TableProps) => {
  const [selectedPaymentRequestId, setSelectedPaymentRequestId] = useState('');

  const { isOpen: isOpenInfoModal, open: openInfoModal, close: closeInfoModal } = useToggle();
  const { isOpen: isOpenDeleteModal, open: openDeleteModal, close: closeDeleteModal } = useToggle();

  const selectedPaymentRequest = useMemo(
    () => paymentRequests.find(({ id }) => id === selectedPaymentRequestId),
    [paymentRequests, selectedPaymentRequestId]
  );

  const handleOpenInfoModal = useCallback(
    (id: string) => {
      setSelectedPaymentRequestId(id);
      openInfoModal();
    },
    [openInfoModal]
  );

  const handleCloseInfoModal = useCallback(() => {
    setSelectedPaymentRequestId('');
    closeInfoModal();
  }, [closeInfoModal]);

  const handleOpenDeleteModal = useCallback(
    (id: string) => {
      setSelectedPaymentRequestId(id);
      openDeleteModal();
    },
    [openDeleteModal]
  );

  const handleCloseDeleteModal = useCallback(() => {
    setSelectedPaymentRequestId('');
    closeDeleteModal();
  }, [closeDeleteModal]);

  return {
    selectedPaymentRequest,
    isOpenInfoModal,
    handleOpenInfoModal,
    handleCloseInfoModal,
    isOpenDeleteModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
  };
};
export default useTable;
