export enum DocuSignEvent {
  'signing_complete' = 'signing_complete',
  'access_code_failed' = 'access_code_failed',
  'cancel' = 'cancel',
  'decline' = 'decline',
  'exception' = 'exception',
  'fax_pending' = 'fax_pending',
  'id_check_failed' = 'id_check_failed',
  'session_timeout' = 'session_timeout',
  'ttl_expired' = 'ttl_expired',
  'viewing_complete' = 'viewing_complete',
}
