import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import qs from 'query-string';
import { get } from 'lodash';

import { GET_PAYOR_PAYMENT_REQUEST } from 'graphql/invoicing';
import { PayorPaymentRequest } from 'types/invoicing';
import { formatDateTime } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { getPaymentRequestSchedule } from 'components/Invoices/Invoices.utils';

const usePromoText = () => {
  const [getPaymentRequest, { data: paymentRequestData, loading: isPaymentRequestLoading }] = useLazyQuery<{
    payorPaymentRequest: PayorPaymentRequest;
  }>(GET_PAYOR_PAYMENT_REQUEST);

  const { sgid: signedId } = qs.parse(location.search) as {
    sgid?: string;
  };

  const vendorName = get(paymentRequestData, 'payorPaymentRequest.vendor.name', '');

  const { amount, dueDate, invoiceNumber, schedule } = paymentRequestData?.payorPaymentRequest || {};

  const formattedAmount = formatMoneyV2(amount);
  const formattedDueDate = formatDateTime(dueDate);

  const currency = amount?.currency;

  const {
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  } = getPaymentRequestSchedule(schedule);

  useEffect(() => {
    if (!signedId) return;

    getPaymentRequest({ variables: { signedId } });
  }, [paymentRequestData, signedId]);

  return {
    vendorName,
    formattedAmount,
    currency,
    formattedDueDate,
    invoiceNumber,
    isPaymentRequestLoading,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  };
};

export default usePromoText;
