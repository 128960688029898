import React from 'react';

import { EnableAutopayModalProps } from './EnableAutopayModal.type';
import { Modal } from 'components/UI';
import { EnableAutopay } from './components';

const EnableAutopayModal = ({ show, onClose }: EnableAutopayModalProps) => {
  return (
    <Modal show={show} onClose={onClose} title="Enable Automatic Payments">
      <div className="tw-py-6">
        <EnableAutopay onFinish={onClose} />
      </div>
    </Modal>
  );
};

export default EnableAutopayModal;
