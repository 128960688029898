import React, { useContext } from 'react';

import { classNames } from 'utility/string';
import useScrollViewToPosition from 'hooks/useScrollViewToPosition';
import { Progress } from 'components/UI';
import { PAY_LOC_STEPS, STEP_COMPONENT_MAP } from './constants';
import { PayLocBalanceContext, PayLocBalanceContextProvider } from './PayLocBalanceContext';

const PayLocBalance = ({ header, hasLineOfCredit, ...props }) => (
  <PayLocBalanceContextProvider {...props}>
    <Wrapper>
      {header}
      {hasLineOfCredit ? <Content /> : <EmptyState />}
    </Wrapper>
  </PayLocBalanceContextProvider>
);

const Content = () => {
  const { step, isModal } = useContext(PayLocBalanceContext);

  useScrollViewToPosition([step]);

  const classes = classNames({
    'lg:tw-max-w-2xl tw-pl-8 tw-pr-8 tw-w-full': true,
    'lg:tw-pl-44 tw-pb-8': !isModal,
  });

  return (
    <div className={classes}>
      <Progress className="tw-pt-8 tw-pb-8 tw-pl-0" currentStepIndex={step} stepLabelsAndIndexes={PAY_LOC_STEPS} />

      <div className="tw-flex tw-flex-col tw-gap-8">{STEP_COMPONENT_MAP[step]}</div>
    </div>
  );
};

const Wrapper = ({ children }) => (
  <div className="tw-flex tw-px-4 lg:tw-px-0">
    <div className="tw-bg-neutral-light tw-rounded-md tw-min-w-full">{children}</div>
  </div>
);

const EmptyState = () => {
  return (
    <div className="tw-p-8 lg:tw-pl-44">
      <p>You do not have a Line of Credit at this time.</p>
    </div>
  );
};

export default PayLocBalance;
