// Check if there is a Facebook Session
const onFacebookStatusResponse = async (results) => {
  console.log(results);
};

const checkFBStatus = () => {
  window.FB && window.FB.getLoginStatus(onFacebookStatusResponse);
};

export { checkFBStatus };
