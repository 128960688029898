import React from 'react';

import { RadioField, Select } from 'components/FormFields/v2';
import Banner, { BANNER_TYPES } from 'components/Banner';
import { OUTSIDE_ZONE_INFO_MESSAGE, DEFAULT_ZONE_INFO_MESSAGE } from '../../constants';
import { useCountrySelect } from './hooks';

const CountrySelect = () => {
  const {
    basedOnCurrencyCountryOptions,
    countryOptions,
    showSpecificCountryFields,
    showAdditionalEUCountiesSelector,
    isSpecificCountrySelected,
    register,
  } = useCountrySelect();

  return (
    <>
      {showSpecificCountryFields ? (
        <>
          <div>
            <label htmlFor="basedOnCurrencyCountry" className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
              Bank Location
              <span className="tw-text-semantic-error">{' *'}</span>
            </label>
            <RadioField
              name="basedOnCurrencyCountry"
              data-testid="basedOnCurrencyCountry"
              options={basedOnCurrencyCountryOptions}
              ref={register()}
            />
          </div>
          {!isSpecificCountrySelected && (
            <Banner type={BANNER_TYPES.danger} message={OUTSIDE_ZONE_INFO_MESSAGE} isShowIcon />
          )}
        </>
      ) : (
        <Banner type={BANNER_TYPES.danger} message={DEFAULT_ZONE_INFO_MESSAGE} isShowIcon />
      )}

      {(!isSpecificCountrySelected || showAdditionalEUCountiesSelector) && (
        <Select
          name="bankAccount.country"
          data-testid="bankAccount.country"
          label="Bank Country"
          placeholder="Select Option"
          ref={register({ required: true })}
          options={countryOptions}
          required
          rootClass="tw-mt-4"
        />
      )}
    </>
  );
};

export default CountrySelect;
