// @ts-nocheck
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { ampTrackEvent } from 'amplitude';
import config from 'config';
import { unauthenticatedFileDownload } from 'utility/fileDownload';
import { ADD_CORPORATE_OWNERSHIP, GET_CORPORATE_OWNERSHIP_INFO } from 'graphql/onboarding';

type useAddCorporateOwnershipModalProps = {
  onClose: () => void;
  hasCorporateOwnershipDocument: boolean;
  corporateOwnership: number;
};

const useAddCorporateOwnershipModal = ({
  onClose,
  hasCorporateOwnershipDocument,
  corporateOwnership,
}: useAddCorporateOwnershipModalProps) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleFileDownload = async () => {
    const downloadUrl = config.corporateContactFormUrl;
    try {
      setIsDownloading(true);
      await unauthenticatedFileDownload({
        url: downloadUrl,
        fileName: 'corporate_contact_form.pdf',
      });
    } catch (error) {
      toast.error('Error downloading document - please try again');
    } finally {
      setIsDownloading(false);
    }
  };

  const form = useForm({ defaultValues: { corporateOwnership } });
  const { setValue, register, handleSubmit, reset } = form;

  const handleOnFileUpload = (file: { blob: { signed_id: string } }) => {
    setValue('blobSignedId', file?.blob?.signed_id);
  };

  const [addCorporateOwnership, { loading: isSubmitting, error }] = useMutation(ADD_CORPORATE_OWNERSHIP, {
    refetchQueries: [{ query: GET_CORPORATE_OWNERSHIP_INFO }],
    awaitRefetchQueries: true,
  });

  const onSubmit = async (data: { corporateOwnership: string; blobSignedId: string }) => {
    const { corporateOwnership, blobSignedId } = data;

    try {
      const response = await addCorporateOwnership({
        variables: { corporateOwnership: parseInt(corporateOwnership), blobSignedId: blobSignedId },
      });

      if (!response?.data?.addCorporateOwnership) {
        throw new Error('Error adding corporate ownership - please try again');
      }

      reset({ corporateOwnership });

      ampTrackEvent('onboarding: add_corporate_ownership: success');
      onClose();
      toast.success(`Corporate ownership successfully ${hasCorporateOwnershipDocument ? 'updated' : 'added'}`);
    } catch (error) {
      toast.error(error);
      ampTrackEvent('onboarding: add_corporate_ownership: error');
    }
  };

  const formattedSubmitButtonLabel = hasCorporateOwnershipDocument
    ? 'Update Corporate Ownership'
    : 'Add Corporate Ownership';

  return {
    form,
    register,
    handleSubmit,
    handleOnFileUpload,
    isDownloading,
    handleFileDownload,
    isSubmitting,
    onSubmit,
    formattedSubmitButtonLabel,
    error,
  };
};

export default useAddCorporateOwnershipModal;
