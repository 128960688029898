import React from 'react';

const CashAndStars = ({ width = '59', height = '56', vbWidth = '59', vbHeight = '56', ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      {...other}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5537 8.67388C14.6633 8.61126 14.7791 8.74569 14.7008 8.8448L11.4423 12.9726C11.4133 13.0093 11.4095 13.06 11.4328 13.1007L14.0407 17.6674C14.1033 17.7771 13.9689 17.8928 13.8698 17.8145L9.74203 14.5561C9.70527 14.527 9.65456 14.5232 9.61389 14.5465L5.0472 17.1544C4.93755 17.2171 4.82184 17.0826 4.90008 16.9835L8.15854 12.8557C8.18756 12.819 8.19136 12.7683 8.16813 12.7276L5.56017 8.16091C5.49755 8.05126 5.63197 7.93555 5.73108 8.01379L9.85886 11.2723C9.89563 11.3013 9.94633 11.3051 9.987 11.2818L14.5537 8.67388Z"
        fill="#E8F3ED"
      />
      <path
        d="M53.6012 40.1747C53.7108 40.1121 53.8265 40.2465 53.7483 40.3456L50.2049 44.8344C50.1759 44.8711 50.1721 44.9218 50.1953 44.9625L53.0313 49.9285C53.094 50.0382 52.9595 50.1539 52.8604 50.0756L48.3717 46.5322C48.3349 46.5032 48.2842 46.4994 48.2436 46.5227L43.2776 49.3587C43.1679 49.4213 43.0522 49.2869 43.1304 49.1877L46.6738 44.699C46.7028 44.6623 46.7066 44.6116 46.6834 44.5709L43.8474 39.6049C43.7848 39.4952 43.9192 39.3795 44.0183 39.4578L48.507 43.0011C48.5438 43.0302 48.5945 43.034 48.6352 43.0107L53.6012 40.1747Z"
        fill="#F7ECFB"
      />
      <path
        d="M54.455 5.43629C54.5805 5.45022 54.5949 5.62701 54.4733 5.66106L50.5835 6.75061C50.5384 6.76325 50.5055 6.802 50.5004 6.84855L50.0547 10.8634C50.0407 10.9889 49.8639 11.0033 49.8299 10.8817L48.7403 6.9919C48.7277 6.9468 48.689 6.91389 48.6424 6.90872L44.6276 6.46302C44.5021 6.44909 44.4877 6.2723 44.6092 6.23825L48.499 5.1487C48.5441 5.13606 48.5771 5.09731 48.5822 5.05076L49.0279 1.03592C49.0419 0.910417 49.2186 0.896018 49.2527 1.01761L50.3423 4.9074C50.3549 4.9525 50.3936 4.98542 50.4402 4.99059L54.455 5.43629Z"
        fill="#F7ECFB"
      />
      <path
        d="M11.577 31.2116L13.5311 33.4818L15.8012 31.5277"
        stroke="#EEA530"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5708 32.9536L14.2967 23.2511C14.4418 21.3119 16.1864 19.8747 18.1227 20.0195L18.7827 20.0689"
        stroke="#EEA530"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.8769 33.1248L39.9228 30.8547L37.6526 32.8088"
        stroke="#E8F3ED"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.8831 31.3828L39.1571 41.0854C39.012 43.0246 37.2674 44.4618 35.3312 44.3169L34.6712 44.2675"
        stroke="#E8F3ED"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_3919_171331)">
        <path
          d="M23.3701 24.6827L44.5353 26.2662C44.9849 26.2999 45.3766 25.9627 45.4103 25.5131L46.2629 14.1165C46.2966 13.6669 45.9594 13.2751 45.5098 13.2415L24.3446 11.658C23.895 11.6243 23.5033 11.9615 23.4696 12.4111L22.617 23.8078C22.5833 24.2573 22.9205 24.6491 23.3701 24.6827Z"
          fill="#FFE8C3"
        />
        <path
          d="M21.1003 26.6368L42.2655 28.2203C42.7151 28.254 43.1069 27.9168 43.1405 27.4672L43.9932 16.0706C44.0268 15.621 43.6896 15.2292 43.24 15.1956L22.0748 13.6121C21.6252 13.5784 21.2335 13.9156 21.1999 14.3652L20.3472 25.7619C20.3136 26.2114 20.6507 26.6032 21.1003 26.6368Z"
          stroke="#EEA530"
          strokeWidth="0.574331"
          strokeLinejoin="round"
        />
        <path
          d="M31.8657 24.9865C34.1136 25.1547 36.0723 23.4687 36.2405 21.2208C36.4087 18.9729 34.7227 17.0142 32.4748 16.846C30.2268 16.6778 28.2682 18.3638 28.1 20.6117C27.9318 22.8597 29.6178 24.8183 31.8657 24.9865Z"
          stroke="#EEA530"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.7496 19.3269C42.6701 19.2461 41.6669 18.7399 40.9607 17.9194C40.2545 17.099 39.9031 16.0316 39.9839 14.9521"
          stroke="#EEA530"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9562 17.6215C21.4907 17.6615 22.0279 17.5958 22.537 17.4282C23.0461 17.2606 23.5173 16.9944 23.9235 16.6447C24.3297 16.295 24.6631 15.8687 24.9046 15.3902C25.1461 14.9117 25.291 14.3903 25.331 13.8558"
          stroke="#EEA530"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.3842 24.2108C42.3047 24.13 41.2373 24.4814 40.4169 25.1876C39.5965 25.8938 39.0902 26.897 39.0094 27.9765"
          stroke="#EEA530"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5908 22.5059C21.1253 22.5459 21.6467 22.6907 22.1253 22.9322C22.6038 23.1737 23.03 23.5071 23.3797 23.9133C23.7294 24.3196 23.9956 24.7907 24.1632 25.2998C24.3308 25.809 24.3965 26.3461 24.3565 26.8806"
          stroke="#EEA530"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip1_3919_171331)">
        <path
          d="M11.7664 47.628L32.9316 49.2116C33.3811 49.2452 33.7729 48.908 33.8065 48.4584L34.6592 37.0618C34.6928 36.6122 34.3556 36.2205 33.906 36.1868L12.7408 34.6033C12.2913 34.5696 11.8995 34.9068 11.8659 35.3564L11.0132 46.7531C10.9796 47.2027 11.3168 47.5944 11.7664 47.628Z"
          fill="#D0E7DA"
        />
        <path
          d="M10.2095 49.1847L31.3747 50.7682C31.8243 50.8018 32.216 50.4646 32.2496 50.0151L33.1023 38.6184C33.1359 38.1688 32.7987 37.7771 32.3492 37.7435L11.184 36.1599C10.7344 36.1263 10.3426 36.4635 10.309 36.9131L9.45633 48.3097C9.42269 48.7593 9.75988 49.151 10.2095 49.1847Z"
          stroke="#E8F3ED"
          strokeWidth="0.574331"
          strokeLinejoin="round"
        />
        <path
          d="M21.7317 46.883C23.9796 47.0512 25.9383 45.3652 26.1064 43.1173C26.2746 40.8693 24.5887 38.9107 22.3407 38.7425C20.0928 38.5743 18.1342 40.2603 17.966 42.5082C17.7978 44.7561 19.4838 46.7148 21.7317 46.883Z"
          fill="#EED9F7"
        />
        <path
          d="M20.9746 47.5343C23.2225 47.7025 25.1812 46.0166 25.3494 43.7686C25.5176 41.5207 23.8316 39.5621 21.5837 39.3939C19.3357 39.2257 17.3771 40.9117 17.2089 43.1596C17.0407 45.4075 18.7267 47.3662 20.9746 47.5343Z"
          stroke="#E8F3ED"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.8587 41.8748C31.7792 41.794 30.776 41.2877 30.0698 40.4673C29.3636 39.6469 29.0123 38.5795 29.093 37.5"
          stroke="#E8F3ED"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0653 40.1694C10.5999 40.2093 11.137 40.1437 11.6461 39.9761C12.1553 39.8085 12.6264 39.5422 13.0326 39.1925C13.4389 38.8429 13.7722 38.4166 14.0137 37.9381C14.2552 37.4596 14.4001 36.9382 14.4401 36.4036"
          stroke="#E8F3ED"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.4933 46.7591C31.4138 46.6784 30.3465 47.0297 29.526 47.7359C28.7056 48.4421 28.1993 49.4453 28.1186 50.5248"
          stroke="#E8F3ED"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.69995 45.0537C10.2345 45.0937 10.7559 45.2386 11.2344 45.4801C11.7129 45.7216 12.1392 46.055 12.4888 46.4612C12.8385 46.8674 13.1048 47.3386 13.2724 47.8477C13.44 48.3568 13.5056 48.894 13.4657 49.4285"
          stroke="#E8F3ED"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3919_171331">
          <rect width="26.8283" height="16.2382" fill="white" transform="translate(20.5356 10.8198) rotate(4.27878)" />
        </clipPath>
        <clipPath id="clip1_3919_171331">
          <rect width="27.1527" height="19.0134" fill="white" transform="translate(8.96655 32.3198) rotate(4.27878)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CashAndStars;
