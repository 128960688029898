import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import cx from 'classnames';

import { RadioField, Select } from 'components/FormFields/v2';
import { useIsMobile } from 'hooks';
import InfoTooltip from 'components/InfoTooltip';
import { INTO_TOOLTIP } from 'components/payments/CardRepayment/components/Steps/components/Details/constants';

const PaymentTypes = ({
  paymentTypeOptions,
  labelClassName = 'xl:tw-w-1/6',
  contentClassName = 'tw-w-5/6',
}: {
  paymentTypeOptions: { label: string; value: string }[];
  labelClassName?: string;
  contentClassName?: string;
}) => {
  const { register } = useFormContext();

  const isMobile = useIsMobile();

  return (
    <div className="tw-flex xl:tw-gap-4 tw-items-start">
      <div className={cx('tw-flex tw-items-center tw-gap-1 tw-w-40 tw-h-12', labelClassName)}>
        Payment Type <InfoTooltip message={INTO_TOOLTIP.paymentType} />
      </div>

      {isMobile ? (
        <Select
          name="paymentType"
          options={paymentTypeOptions}
          placeholder="Select type"
          ref={register({ required: true })}
        />
      ) : (
        <RadioField
          name="paymentType"
          options={paymentTypeOptions}
          ref={register({ required: true })}
          rootClass={(cx('tw-flex-wrap'), contentClassName)}
          size="sm"
        />
      )}
    </div>
  );
};

export default memo(PaymentTypes);
