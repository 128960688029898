import config from 'config';
import { SidebarItem } from 'components/UI/Layout/Layout.types';

export const getSideNavItemDetails = ({ item, selectedItem }: { item: SidebarItem; selectedItem?: SidebarItem }) => {
  const isSelected = selectedItem?.label === item.label;
  const isExpandable = !!item.childItems?.length;
  const isFeatured = config.featuredSideNavEntries.split(',').includes(item.label);
  const isBeta = config.betaSideNavEntries.split(',').includes(item.label);
  const isCallToAction = !!item?.callToActionLabel;

  return {
    isBeta,
    isExpandable,
    isFeatured,
    isSelected,
    isCallToAction,
  };
};
