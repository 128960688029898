import React from 'react';

const SettingsHeader = ({ title, children }: { title: string; children?: React.ReactNode }) => (
  <div className="tw-flex tw-flex-row tw-justify-between tw-text-lg">
    <h5>{title}</h5>

    {children}
  </div>
);
export default SettingsHeader;
