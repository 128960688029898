import React from 'react';

import useSetPageTitle from 'hooks/useSetPageTitle';
import CardAndKey from 'components/svg/CardAndKey';
import Layout from '../Layout';
import Loading from '../Loading';
import Progress from '../Progress';
import { ConfirmationModal, Form } from './components';
import { useOwnersProfile } from './hooks';
import { ONBOARDING_STEPS } from 'components/onboarding/constants';

const OwnersProfile = ({ loading, ...contentProps }) => {
  useSetPageTitle(ONBOARDING_STEPS.ownersProfile.label);

  return <Layout>{loading ? <Loading /> : <Content {...contentProps} />}</Layout>;
};

const Content = ({ accountManager, accountOwner, isSoleProprietor, businessAddress }) => {
  const {
    formInitialValues,
    error,
    isSubmitting,
    handleSubmit,
    isBusinessAndPersonalAddressSame,
    profileData,
    showConfirmationModal,
    setShowConfirmationModal,
    submitOwnerProfile,
  } = useOwnersProfile({ accountManager, accountOwner, isSoleProprietor, businessAddress });

  return (
    <div className="tw-w-full lg:tw-w-4/5 tw-flex tw-flex-col tw-items-center tw-flex-grow">
      <Progress currentStep={4} />
      <div className="tw-w-full lg:tw-w-2/5 tw-mt-16 tw-flex tw-flex-col">
        <h1 data-testid="owners-profile-title" className="tw-mb-4">
          {ONBOARDING_STEPS.ownersProfile.title}
        </h1>
        <p className="tw-mb-8">
          We need some information about the primary owner of the business for regulatory purposes.
        </p>
        <div className="tw-flex tw-flex-row tw-items-center tw-rounded-md tw-bg-neutral-grey-4 tw-p-4 tw-mb-12">
          <CardAndKey className="tw-w-40 tw-mr-4" />
          <small className="tw-text-neutral-grey-1">
            Opening an account with Loop does not affect your personal credit score.
          </small>
        </div>

        <Form
          initialValues={formInitialValues}
          error={error}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          accountManager={accountManager}
          isBusinessAndPersonalAddressSame={isBusinessAndPersonalAddressSame}
        />

        <ConfirmationModal
          profileData={profileData}
          show={showConfirmationModal}
          setShow={setShowConfirmationModal}
          onComplete={submitOwnerProfile}
          loading={isSubmitting}
        />
      </div>
    </div>
  );
};

export default OwnersProfile;
