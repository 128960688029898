import { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { concat, sortBy } from 'lodash';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { CreateCardContext } from 'components/creditCards/components/CreateCardModal/context/CreateCardContext';
import { centsFromMoneyString } from 'utility/currency';
import { Option } from 'components/UI/SearchDropdown/v2/SearchDropdown.types';
import { getMemberOptions, mapMerchantCatagoriesToOptions } from '../UpdateCardSettings.utils';
import { FormDataType } from '../UpdateCardSettings.types';
import { CREDIT_CARD_CURRENCIES } from 'components/creditCards/components/cardsSettings/SpendManagement/constants';
import {
  GET_CREDIT_CARD_PROCESSOR_SETTINGS,
  UPDATE_CREDIT_CARD_PROCESSOR_SETTINGS,
  ADD_CONTACT_TO_PRODUCT_PERMISSION,
} from 'graphql/cards';
import { CreditCardCurrency, CreditCardProcessorSettings } from 'types/creditCard';

const useUpdateCardSettings = () => {
  const { currentStep, steps, members, me, createdCardData, onClose } = useContext(CreateCardContext);
  const { id: creditCardId, processorSettings } = createdCardData || {};
  const { allowedMccsCategories, blockedMccsCategories, allowedCurrencies } = processorSettings || {};

  const [selectedMembers, setSelectedMembers] = useState<Option[]>([]);
  const [selectedMerchantCategories, setSelectedMerchantCategories] = useState<Option[]>([]);
  const [isMerchantCategoriesError, setIsMerchantCategoriesError] = useState(false);

  const [addContactToProductPermission, { loading: isAddContactLoading }] = useMutation(
    ADD_CONTACT_TO_PRODUCT_PERMISSION
  );

  const [updateCreditCardProcessorSettings, { loading: isUpdatingProcessorSettings }] = useMutation<{
    setCardProcessorSettings: CreditCardProcessorSettings;
  }>(UPDATE_CREDIT_CARD_PROCESSOR_SETTINGS, {
    update: (cache, { data }) => {
      const updatedData = data?.setCardProcessorSettings;

      if (!updatedData) return;

      cache.writeQuery({
        query: GET_CREDIT_CARD_PROCESSOR_SETTINGS,
        variables: { creditCardId },
        data: {
          creditCard: {
            id: creditCardId,
            processorSettings: updatedData,
            __typename: 'CreditCard',
          },
        },
      });
    },
  });

  const form = useForm<FormDataType>({
    defaultValues: {
      allowedCurrencies: allowedCurrencies,
      lockOnMerchant: 'false',
    },
  });
  const { handleSubmit, setError } = form;

  const memberOptions = useMemo(() => getMemberOptions(members, me.internalContact), [me.internalContact, members]);
  const onSelectedMembersChange = useCallback((options: Option[]) => {
    setSelectedMembers(options);
  }, []);

  const merchantCategoryList = useMemo(
    () => sortBy(concat(allowedMccsCategories || [], blockedMccsCategories || [])),
    [allowedMccsCategories, blockedMccsCategories]
  );
  const merchantCategoriesOptions = useMemo(
    () => mapMerchantCatagoriesToOptions(merchantCategoryList),
    [merchantCategoryList]
  );

  useEffect(() => {
    setSelectedMerchantCategories(merchantCategoriesOptions);
  }, []);

  const onSubmit = useCallback(
    async (data: FormDataType) => {
      const { limitAmount, limitType, allowedCurrencies, lockOnMerchant, allowedMerchant } = data;

      setIsMerchantCategoriesError(false);

      const limitAmountInCents = centsFromMoneyString(limitAmount);

      if (!limitAmountInCents && limitType) {
        setError('limitAmount', { type: 'manual', message: 'Amount must be greater than $0.00' });
        return;
      }

      if (!limitType && limitAmountInCents) {
        setError('limitType', { type: 'manual', message: 'Select limit type' });
        return;
      }

      const selectedContacts = selectedMembers.map(({ value }) => ({ id: value }));

      let blockedMerchantCategories: string[] = [];
      let allowedMerchants: string[] = [];

      if (lockOnMerchant === 'true') {
        if (!allowedMerchant) {
          setError('allowedMerchant', { type: 'manual', message: 'Select a merchant' });
          return;
        }

        allowedMerchants = [allowedMerchant];
      } else {
        if (!selectedMerchantCategories.length) {
          setIsMerchantCategoriesError(true);
          return;
        }

        const selectedMerchantCategoryValues = selectedMerchantCategories.map(({ value }) => value);
        blockedMerchantCategories = merchantCategoryList.filter(
          (category) => !selectedMerchantCategoryValues.includes(category)
        );
      }

      const blockedCurrencies = CREDIT_CARD_CURRENCIES.filter((currency) => !allowedCurrencies.includes(currency));

      if (blockedCurrencies.length === CREDIT_CARD_CURRENCIES.length) {
        setError('allowedCurrencies', { type: 'manual', message: 'Select at least one currency' });
        return;
      }

      try {
        await Promise.all(
          selectedContacts.map(async ({ id }) => {
            const addPermission = await addContactToProductPermission({
              variables: {
                creditCardId: creditCardId,
                contactId: id,
              },
            });

            if (!addPermission?.data?.addContactToProductPermission || addPermission.errors) {
              throw new Error('Failed to add members to card');
            }
          })
        );

        const processorSettings = await updateCreditCardProcessorSettings({
          variables: {
            creditCardId,
            processorSettings: {
              allowedMerchants: allowedMerchants.join(','),
              blockedMccsCategories: blockedMerchantCategories.join(','),
              blockedCurrencies: blockedCurrencies.join(','),
              limitSettings: {
                cadence: limitType,
                currency: CreditCardCurrency.CAD,
                amountCents: limitAmountInCents,
              },
            },
          },
        });

        if (!processorSettings?.data?.setCardProcessorSettings || processorSettings.errors) {
          throw new Error('Failed to update spend settings');
        }

        toast.success('Credit card settings added successfully');
        onClose();
      } catch (err) {
        console.error(err);
        toast.error((err as Error).message);
      }
    },
    [selectedMembers, selectedMerchantCategories]
  );

  return {
    currentStep,
    steps,
    form,
    handleSubmit,
    onSubmit,
    memberOptions,
    selectedMembers,
    onSelectedMembersChange,
    merchantCategoriesOptions,
    selectedMerchantCategories,
    setSelectedMerchantCategories,
    isMerchantCategoriesError,
    currenciesOptions: CREDIT_CARD_CURRENCIES,
    allowedMerchantsOptions: processorSettings?.merchantsList || [],
    isSubmitting: isUpdatingProcessorSettings || isAddContactLoading,
  };
};

export default useUpdateCardSettings;
