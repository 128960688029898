import React from 'react';

import { OwnershipDisclosure } from 'components/wallets/AddUSBankAccount/components';
import Complete from 'components/wallets/AddUSBankAccount/Complete';
import { StepsProps } from './Steps.types';

const Steps = ({ currentStep, onNextStep, onFinish }: StepsProps) => {
  switch (currentStep) {
    case 0:
      return <OwnershipDisclosure onNextStep={onNextStep} />;
    case 1:
      return <Complete onFinish={onFinish} />;
    default:
      return null;
  }
};

export default Steps;
