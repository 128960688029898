import React, { useState } from 'react';

export const AddPayeeContext = React.createContext({});

export const AddPayeeContextProvider = (props) => {
  const [payeeType, setPayeeType] = useState(null);
  const [errorSteps, setErrorSteps] = useState([]);
  const [supplierInfo, setSupplierInfo] = useState({});
  const [creditCard, setCreditCard] = useState({});
  const [payeeDetailsSource, setPayeeDetailsSource] = useState(null);

  return (
    <AddPayeeContext.Provider
      value={{
        payeeType,
        setCreditCard,
        creditCard,
        setPayeeType,
        supplierInfo,
        setSupplierInfo,
        payeeDetailsSource,
        setPayeeDetailsSource,
        errorSteps,
        setErrorSteps,
      }}
    >
      {props.children}
    </AddPayeeContext.Provider>
  );
};
