import React from 'react';
import { Popover, Transition } from '@headlessui/react';
import { BsChevronDown } from 'react-icons/bs';
import { IoRadioButtonOn, IoRadioButtonOff } from 'react-icons/io5';
import 'react-datepicker/dist/react-datepicker.css';
import cx from 'classnames';

import { PeriodDropdownProps } from './PeriodDropdown.types';
import { DatePicker } from './components';
import usePeriodDropdown from './hooks/usePeriodDropdown';

export const PeriodDropdown = ({
  label,
  selectedValue,
  setSelectedValue,
  className,
  testId,
  portalId,
  maxDate,
  minDate,
}: PeriodDropdownProps) => {
  const { toggleOpen, isOpen, periodOptions, handleChangeFrom, handleChangeTo, customPeriod } = usePeriodDropdown({
    setSelectedValue,
  });

  return (
    <Popover id={portalId}>
      <div className={cx('tw-relative tw-p-2', className)}>
        <Popover.Button
          onClick={toggleOpen}
          data-testid={testId}
          className="tw-flex tw-items-center tw-cursor-pointer tw-p-2 tw-rounded-md"
        >
          <div className="tw-text-sm tw-mr-2">
            {label} {selectedValue?.key ? `(${selectedValue.key})` : ''}
          </div>
          <BsChevronDown size={14} className="tw-text-neutral-grey-2" />
        </Popover.Button>
        <Transition
          unmount={false}
          show={isOpen}
          as={React.Fragment}
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Popover.Panel
            static
            className="periodModal tw-absolute tw-z-40 tw-w-max tw-py-1 tw-overflow-auto tw-bg-neutral-light tw-rounded-md tw-shadow-notification"
          >
            {periodOptions.map((option, index) => {
              const isSelected = selectedValue?.key === option.key;
              const handleSelectOption = () => {
                const isSelected = selectedValue?.key === option.key;

                isSelected ? setSelectedValue(null) : setSelectedValue(option.value);
              };

              return (
                <div
                  key={option.key}
                  className={`tw-flex tw-items-center tw-p-2 tw-cursor-pointer tw-text-neutral-grey-2 ${
                    index === 0 ? '' : 'tw-border-t tw-border-neutral-grey-5'
                  }`}
                  onClick={handleSelectOption}
                >
                  {isSelected ? (
                    <IoRadioButtonOn size={18} className="tw-text-primary-dark-green" />
                  ) : (
                    <IoRadioButtonOff size={18} className="tw-text-neutral-grey-2" />
                  )}
                  <div className="tw-text-sm tw-ml-3 tw-text-neutral-dark">{option.key}</div>
                </div>
              );
            })}
            <div className="tw-mt-2">
              <div className="tw-flex tw-items-center tw-justify-between tw-p-2">
                <DatePicker
                  name="to"
                  portalId={portalId}
                  label="From"
                  onChange={handleChangeFrom}
                  minDate={minDate}
                  maxDate={customPeriod.to}
                  value={customPeriod.from}
                  placeholder="Select start date"
                />
              </div>
              <div className="tw-flex tw-items-center tw-justify-between tw-p-2">
                <DatePicker
                  name="from"
                  portalId={portalId}
                  label="To"
                  onChange={handleChangeTo}
                  minDate={customPeriod.from}
                  maxDate={maxDate}
                  value={customPeriod.to}
                  placeholder="Select end date"
                />
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
};

export default PeriodDropdown;
