import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
// import { Transition } from '@headlessui/react';
// import Close from 'components/svg/Close';

import { GET_UNMATCHED_RECEIPTS_PAGE } from 'graphql/receipts';

import { PaginationSkeleton, ReceiptSkeleton } from './Skeletons';

import ReceiptsList from './ReceiptsList';
import { PaginationControl } from 'components/UI';
// import ReceiptDetail from './ReceiptDetail';

const NoReceiptsBanner = () => (
  <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-bg-neutral-light tw-rounded-md tw-pt-6 tw-pb-4 lg:tw-pt-0 lg:tw-pb-0">
    <div className="tw-text-center tw-mt-6 tw-w-4/5 lg:tw-w-1/3">
      <p className="tw-my-8" data-testid="description">
        Start sending the photos of your receipts to the Loop number and all your unmatched receipts will appear here!
      </p>
    </div>
  </div>
);

export const ReceiptsContainer = ({ setSelectedReceipt }) => {
  const [page, setPage] = useState(0);
  const [numPerPage, setNumPerPage] = useState(4);
  const numPerPageOptionsList = [4, 8, 12, 16];

  const { loading, data: unmatchedReceiptsData } = useQuery(GET_UNMATCHED_RECEIPTS_PAGE, {
    variables: {
      page: `${page}`,
      numPerPage: `${numPerPage}`,
    },
    fetchPolicy: 'network-only',
  });

  const { unmatchedReceiptsPage } = unmatchedReceiptsData || {
    unmatchedReceiptsPage: {
      items: [],
      pageData: { totalCount: 0, totalPages: 1, currentPage: 1, nextPage: 0, prevPage: 0 },
    },
  };

  const {
    items: unmatchedReceipts,
    pageData: { totalCount },
  } = unmatchedReceiptsPage;

  if (loading)
    return (
      <div className="tw-flex tw-flex-col tw-w-full tw-space-y-4 tw-pb-16">
        <ReceiptSkeleton />
        <PaginationSkeleton />
      </div>
    );

  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-space-y-4 tw-pb-16">
      {unmatchedReceipts.length > 0 ? (
        <>
          <ReceiptsList unmatchedReceipts={unmatchedReceipts} setSelectedReceipt={setSelectedReceipt} />
          <div className="tw-mt-12 tw-px-2">
            <PaginationControl
              page={page}
              onChangePage={(val) => setPage(val)}
              onChangeNumberPerPage={(perPage) => setNumPerPage(perPage)}
              numPerPage={numPerPage}
              numberOfItems={totalCount}
              numPerPageOptionsList={numPerPageOptionsList}
            />
          </div>
        </>
      ) : (
        <NoReceiptsBanner />
      )}
    </div>
  );
};

// const ReceiptDetailOverlay = ({ selectedUnmatchedReceipt, setSelectedUnmatchedReceipt }) => {
//   const onClose = () => setSelectedUnmatchedReceipt(null);

//   return (
//     <Transition
//       show={!!selectedUnmatchedReceipt}
//       as="div"
//       className="tw-h-screen tw-w-1/4 tw-fixed tw-top-0 tw-right-0 tw-shadow-notification tw-z-10 tw-bg-neutral-light tw-transform"
//       enter="tw-ease-in-out tw-duration-300"
//       enterFrom="tw-translate-x-96"
//       enterTo="tw-translate-x-0"
//       leave="tw-ease-in-out tw-duration-300"
//       leaveFrom="tw-translate-x-0"
//       leaveTo="tw-translate-x-96"
//     >
//       <ReceiptDetail
//         unmatchedReceipt={selectedUnmatchedReceipt}
//         header={
//           <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
//             <small className="tw-text-neutral-grey-2">Unmatched Receipt details</small>
//             <Close className="tw-cursor-pointer" onClick={onClose} />
//           </div>
//         }
//       />
//     </Transition>
//   );
// };
