import React, { useContext } from 'react';

import InitialsCircle from 'components/InitialsCircle.js';
import { ApprovalRowProps } from './ApprovalRow.types';
import {
  ApprovalRequestStatusBadge,
  ApprovalTypeBadge,
  CurrencyBadge,
} from 'components/Approvals/ApprovalsContent/components/UI';
import { useApprovalsRow } from '../hooks';
import { APPROVAL_MODAL, APPROVAL_TYPE } from 'components/Approvals/constants';
import { ApprovalRequestsContext } from 'components/Approvals/ApprovalsContent/context';

const ApprovalsRow = ({ request, openModal }: ApprovalRowProps) => {
  const {
    requester,
    merchantName,
    isAdmin,
    isMember,
    currency,
    status,
    formattedOriginalAmount,
    formattedCreatedAt,
    handleStopClickRow,
    requesterInitials,
  } = useApprovalsRow({ request });
  const { setSelectedRequestId } = useContext(ApprovalRequestsContext);

  return (
    <tr className="tw-cursor-pointer" onClick={() => setSelectedRequestId(request.id)} key={request.id}>
      <td className="tw-w-24 tw-p-3 tw-text-sm tw-font-normal">
        <ApprovalTypeBadge approvalType={APPROVAL_TYPE.reimbursement} />
      </td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-items-center">
        <div className="tw-flex tw-flex-row tw-place-items-start tw-w-fit tw-justify-start tw-items-center tw-gap-1.5">
          <InitialsCircle.Medium initials={requesterInitials} />
          <div className="tw-flex tw-flex-col tw-justify-start">
            <strong>{requester?.name}</strong>
            <small className="tw-text-neutral-grey-2">{requester?.email}</small>
          </div>
        </div>
      </td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{formattedOriginalAmount}</td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">
        <CurrencyBadge currency={currency} />
      </td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{merchantName}</td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{formattedCreatedAt}</td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center ">
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
          <ApprovalRequestStatusBadge status={status} />
        </div>
      </td>
      {status === 'pending' && (
        <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center" onClick={handleStopClickRow}>
          <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-1">
            {isAdmin && (
              <>
                <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-1">
                  <button
                    className="tw-bg-secondary-light-green tw-py-0.5 tw-px-2 tw-w-max tw-rounded-md tw-flex tw-items-center tw-justify-center"
                    onClick={() => openModal(request.id, APPROVAL_MODAL.approveAndPayReimbursement)}
                  >
                    Approve & Pay
                  </button>
                  <button
                    className="tw-bg-secondary-light-yellow tw-py-0.5 tw-px-2 tw-w-max tw-rounded-md tw-flex tw-items-center tw-justify-center"
                    onClick={() => openModal(request.id, APPROVAL_MODAL.approveReimbursement)}
                  >
                    Approve
                  </button>
                  <button
                    className="tw-bg-secondary-pastel-red-50 tw-py-0.5 tw-px-2 tw-w-max tw-rounded-md tw-flex tw-items-center tw-justify-center"
                    onClick={() => openModal(request.id, APPROVAL_MODAL.declineReimbursement)}
                  >
                    Decline
                  </button>
                </div>
              </>
            )}
            {isMember && (
              <button
                className="tw-bg-neutral-grey-4 tw-py-0.5 tw-px-2 tw-w-max tw-rounded-md tw-flex tw-items-center tw-justify-center"
                onClick={() => openModal(request.id, APPROVAL_MODAL.cancelReimbursement)}
              >
                Cancel
              </button>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};
export default ApprovalsRow;
