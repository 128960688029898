import { TEAM_MEMBER_STATUS } from 'constants/index';
import { InternalContact } from 'types/user';
import { shortenedBusinessName } from 'utility/string';

export const getBusinessNameOptions = (
  displayName: string,
  suggestedCreditCardDisplayNames: string[],
  accountName?: string
) => {
  const businessNameOptions = [];
  if (accountName) businessNameOptions.push({ name: accountName, value: accountName });
  if (displayName) {
    const nameOption = shortenedBusinessName(displayName);
    businessNameOptions.push({ name: nameOption, value: nameOption });
  }
  if (suggestedCreditCardDisplayNames.length > 0) {
    suggestedCreditCardDisplayNames.forEach((name) => {
      const nameOption = shortenedBusinessName(name);
      businessNameOptions.push({ name: `${nameOption}`, value: nameOption });
    });
  }
  return businessNameOptions;
};

export const getCardholderOptions = (members: InternalContact[], me: InternalContact | null) => {
  const adminMember = members.find((member) => member.id === me?.id) as InternalContact;

  const filteredMembers = members.filter(
    (member) =>
      member.status === TEAM_MEMBER_STATUS.active &&
      member.role !== 'read_only' &&
      member.role !== 'bookkeeper' &&
      member.id !== me?.id
  );

  const newAdminObject = { ...adminMember, name: adminMember?.name + ' (you)' };
  adminMember && filteredMembers.unshift(newAdminObject);

  return filteredMembers.map((member) => ({ name: `${member.name}`, value: member.id }));
};
