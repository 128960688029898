import React, { useContext } from 'react';
import cx from 'classnames';

import { formatTransactionName, mapTransactionsByDate } from 'utility/transactions';
import { formatDateTime } from 'utility/date';
import { formatTransactionTableAmount } from 'utility/currency';
import { TransactionsContext } from 'context/Transactions';
import { TRANSACTION_STATUS } from 'constants/index';
import TransactionType from 'components/TransactionType';

const TransactionsByDate = ({ accountNames }) => {
  const { transactions } = useContext(TransactionsContext);

  const transactionsByDate = mapTransactionsByDate(transactions);
  const today = formatDateTime(new Date());

  return (
    <div className="tw-p-4 tw-rounded-md tw-bg-neutral-light">
      {Object.keys(transactionsByDate).map((date) => (
        <TransactionsForDate
          key={date}
          transactions={transactionsByDate[date]}
          date={date}
          isToday={today === date}
          accountNames={accountNames}
        />
      ))}
    </div>
  );
};

const TransactionsForDate = ({ date, transactions, isToday, accountNames }) => (
  <div key={date} className="tw-w-full tw-flex-grow tw-mb-2">
    <div className="tw-text-sm tw-text-neutral-grey-2 tw-mb-4">{isToday ? 'Today' : date}</div>
    {transactions.map((transaction) => (
      <TransactionEntry key={transaction.transactionId} transaction={transaction} accountNames={accountNames} />
    ))}
  </div>
);

const TransactionEntry = ({ transaction, accountNames }) => {
  const { setSelectedTransactionId, productType } = useContext(TransactionsContext);

  const onTouch = () => setSelectedTransactionId(transaction?.transactionId);

  const accountName = accountNames.find((name) => name === transaction.to);
  const formattedAmount = formatTransactionTableAmount({ transaction, accountName, productType });
  const isNegative = formattedAmount.includes('-');
  const deniedTransaction = transaction.status === TRANSACTION_STATUS.DENIED;

  return (
    <div onClick={onTouch} className="tw-flex tw-items-start tw-space-between tw-py-2 tw-flex-grow">
      <div className="tw-flex tw-items-start">
        <div className="tw-mr-2">
          <TransactionType transaction={transaction} />
        </div>
        <div>
          <div className="tw-flex">
            <div className="tw-text-sm tw-text-neutral-grey-2 tw-mr-1">To</div>
            <div className="tw-text-sm">{formatTransactionName(transaction.to)}</div>
          </div>
          <div className="tw-flex">
            <div className="tw-text-sm tw-text-neutral-grey-2 tw-mr-1">from</div>
            <div className="tw-text-sm">{formatTransactionName(transaction.from)}</div>
          </div>
        </div>
      </div>
      <div
        className={cx(
          'tw-flex-grow tw-flex tw-justify-end',
          deniedTransaction ? 'tw-line-through tw-text-semantic-error' : !isNegative && 'tw-text-semantic-success'
        )}
      >
        <div className="tw-text-sm tw-font-semibold">{formattedAmount}</div>
      </div>
    </div>
  );
};

export default TransactionsByDate;
