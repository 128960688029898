import React from 'react';

import { ApprovalRequestStatusBadgeProps } from './ApprovalRequestStatusBadge.types';
import { APPROVAL_REQUEST_STATUS } from 'components/Approvals/constants';
import { Badge } from 'components/UI';

const ApprovalRequestStatusBadge = ({ status }: ApprovalRequestStatusBadgeProps) => {
  switch (status) {
    case APPROVAL_REQUEST_STATUS.approved:
      return <Badge classNames="tw-bg-secondary-light-green tw-text-semantic-success" title="Approved" />;
    case APPROVAL_REQUEST_STATUS.rejected:
      return <Badge classNames="tw-bg-semantic-error-background tw-text-semantic-error" title="Rejected" />;
    case APPROVAL_REQUEST_STATUS.paid:
      return <Badge classNames="tw-bg-secondary-pastel-yellow-50 tw-text-primary-yellow" title="Paid" />;
    case APPROVAL_REQUEST_STATUS.pending:
      return <Badge classNames="tw-bg-secondary-pastel-yellow-50 tw-text-primary-yellow" title="Pending" />;
    case APPROVAL_REQUEST_STATUS.cancelled:
      return <Badge classNames="tw-bg-neutral-grey-4 tw-text-neutral-dark" title="Cancelled" />;
    default:
      return null;
  }
};

export default ApprovalRequestStatusBadge;
