import React from 'react';

import { PADAgreementProps } from '../../Steps.types';
import PADAgreementCA from 'components/payments/PADAgreementCA';
import PADAgreementUS from 'components/payments/PADAgreementUS';
import { usePADAgreement } from './hooks';
import { Loaders } from 'components/cards/Loader';

const PADAgreement = ({ onNextStep }: PADAgreementProps) => {
  const { handleSubmit, loadingMoveFunds, selectedBankAccount, selectedWallet, isUSBankAccount, padAgreementVersion } =
    usePADAgreement({
      onNextStep,
    });

  if (loadingMoveFunds)
    return (
      <div className="tw-text-center tw-p-20">
        <Loaders.Spinner />
      </div>
    );

  return (
    <div>
      {isUSBankAccount ? (
        <PADAgreementUS
          onNextStep={handleSubmit}
          nextStepLabel="Submit Transfer"
          loading={loadingMoveFunds}
          externalBankAccount={selectedBankAccount}
          internalBankAccount={selectedWallet}
        />
      ) : (
        <PADAgreementCA
          onNextStep={handleSubmit}
          loading={loadingMoveFunds}
          footerBorder
          bankAccountWithoutPDA={selectedBankAccount}
          version={padAgreementVersion}
        />
      )}
    </div>
  );
};

export default PADAgreement;
