import { USER_ROLE_TYPES } from 'constants/index';

export const availableRoleOptions = [
  { value: USER_ROLE_TYPES.readOnly, name: 'Read Only' },
  { value: USER_ROLE_TYPES.bookkeeper, name: 'Bookkeeper' },
  { value: USER_ROLE_TYPES.member, name: 'Member' },
  { value: USER_ROLE_TYPES.admin, name: 'Admin' },
];

export const roleOptions = [
  { value: USER_ROLE_TYPES.readOnly, name: 'Read Only' },
  { value: USER_ROLE_TYPES.bookkeeper, name: 'Bookkeeper' },
  { value: USER_ROLE_TYPES.accountOwner, name: 'Account Owner' },
  { value: USER_ROLE_TYPES.admin, name: 'Admin' },
  { value: USER_ROLE_TYPES.member, name: 'Member' },
];
