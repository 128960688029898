import React from 'react';
import { FormProvider } from 'react-hook-form';
import ReactToolTip from 'react-tooltip';

import { TextField, SubmitButton } from 'components/FormFields/v2';
import 'react-toastify/dist/ReactToastify.css';

import FileUpload from 'components/uploader/FileUpload';
import { useNoteReceiptForm } from './hooks';
import { AttachReceiptModal, UnmatchedReceiptSelector } from './components';

const NoteReceiptForm = ({ transaction, formattedAmount, overwriteReceipt }) => {
  const {
    form,
    submitError,
    register,
    handleSubmit,
    onSubmit,
    isSubmitting,
    showUploadBlock,
    showSubmitButtonBlock,
    selectedReceipt,
    setShowReceiptModal,
    setReceipt,
    showReceiptModal,
    handleOnFileUpload,
    receipt,
  } = useNoteReceiptForm({ transaction, overwriteReceipt });

  return (
    <FormProvider {...form} graphQLErrors={submitError?.graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)} className="small">
        {!overwriteReceipt && (
          <div>
            <TextField
              rootClass="tw-col-span-8"
              type="text"
              name="notes"
              hidden={false}
              placeholder="Notes"
              ref={register({ required: false })}
              data-tip="New notes will overwrite existing notes"
            />
            <ReactToolTip />
          </div>
        )}
        {(!receipt || overwriteReceipt) && (
          <div className="tw-flex tw-flex-col">
            {!selectedReceipt && (
              <div>
                <div className="tw-flex tw-justify-between tw-items-center tw-cursor-pointer mt-2">
                  <UnmatchedReceiptSelector setShowReceiptModal={setShowReceiptModal} />
                </div>
                <span className="tw-ml-8">or Upload your transaction receipt here</span>
              </div>
            )}
            {selectedReceipt && (
              <div>
                <div className="tw-flex tw-justify-between tw-items-center tw-cursor-pointer mt-2">
                  <UnmatchedReceiptSelector setShowReceiptModal={setShowReceiptModal} />
                </div>
                <span className="tw-ml-8 tw-underline tw-underline-offset-1 tw-text-semantic-warning">
                  Click on submit to attach the selected receipt to the transaction.
                </span>
                <a href={selectedReceipt.receipt} target="_blank" rel="noreferrer noopener">
                  <img src={selectedReceipt.receipt} alt="" loading="lazy" />
                </a>
              </div>
            )}
            <AttachReceiptModal
              show={showReceiptModal}
              setShow={setShowReceiptModal}
              transaction={transaction}
              formattedAmount={formattedAmount}
              setSelectedReceipt={setReceipt}
            />
          </div>
        )}
        {showUploadBlock && (
          <div className="tw-flex tw-flex-col tw-mt-2">
            <div>
              <FileUpload onUpload={handleOnFileUpload} />
              <TextField type="text" name="blobSignedId" hidden={true} ref={register({ required: false })} />
            </div>
          </div>
        )}
        {showSubmitButtonBlock && (
          <div className="tw-flex tw-flex-row tw-justify-between tw-my-2 tw-overflow-hidden">
            <SubmitButton className="tw-w-44" onClick={handleSubmit} isFetching={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </SubmitButton>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default NoteReceiptForm;
