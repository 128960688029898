import { useContext, useMemo } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { get } from 'lodash';

import { ampTrackEvent } from 'amplitude';
import { User } from 'types/user';
import { AuthContext } from 'context/Auth';
import { SCOPE } from 'constants/index';
import useHasSignedWalletAgreement from '../useHasSignedWalletAgreement';
import {
  getSidebarItems,
  getBottomSidebarItems,
  getMemberSidebarItems,
  getTabNavItems,
  getTopDropdownItems,
} from '../DashboardLayout.utils';
import useHasPermission from 'hooks/useHasPermission';
import useHasCreditCard from 'hooks/useHasCreditCards';
import useHasLineOfCredit from 'hooks/useHasLineOfCredit';
import useIsMember from 'hooks/useIsMember';
import useIsAdmin from 'hooks/useIsAdmin';
import useGetSubscription from 'hooks/useGetSubscription';
import useIsContactPermission from 'hooks/useIsContactPermission';
import useGetProductState from 'hooks/useGetProductState';
import useFeatureToggle from 'hooks/useFeatureToggle';
import { useToggle, useDeepEffect } from 'hooks';
import { SET_ACCOUNT_SESSION } from 'graphql/user';
import { GET_SOLID_REQUIREMENTS } from 'graphql/wallets';

const useDashboardLayout = () => {
  const { showWalletAgreementModal, setShowWalletAgreementModal } = useHasSignedWalletAgreement();

  const { isPaidSubscription, name: subscriptionPlanName } = useGetSubscription();
  const { me } = useContext(AuthContext) as unknown as {
    me: User;
  };
  const { smsMfaEnabled, accounts = [], activeAccountId } = me || {};
  const { hasCreditCard } = useHasCreditCard();
  const { isAdmin } = useIsAdmin();
  const { isMember } = useIsMember();
  const {
    createPayeePayment,
    viewPayeePayments,
    createPayee,
    viewPayors,
    managePayors,
    viewAccountBalances,
    manageRewards,
  } = useIsContactPermission();
  const { hasLineOfCredit } = useHasLineOfCredit();
  const { hasPermission: canManagePayments } = useHasPermission({ scope: SCOPE.managePayments });

  const [setAccountSession] = useMutation(SET_ACCOUNT_SESSION);
  const selectAccount = async (id: string) => {
    await setAccountSession({ variables: { accountId: id } });
    window.location.reload();
  };

  const {
    isOpen: showUSBankAccountVerificationsModal,
    open: openUSBankAccountVerificationsModal,
    close: closeUSBankAccountVerificationsModal,
  } = useToggle();

  const [getSolidRequirements] = useLazyQuery(GET_SOLID_REQUIREMENTS);

  const { isPreFunded } = useGetProductState();

  const { isExperimentEnabled: isApprovalsEnabled } = useFeatureToggle(false, 'approvals');

  const sideNavItems = useMemo(
    () =>
      isMember
        ? getMemberSidebarItems({
            hasCreditCard,
            createPayeePayment,
            viewPayeePayments,
            isApprovalsEnabled,
            viewPayors,
            managePayors,
            viewAccountBalances,
            createPayee,
            manageRewards,
          })
        : getSidebarItems({
            hasLineOfCredit,
            canManagePayments,
            createPayeePayment,
            hasCreditCard,
            isPreFunded,
            isApprovalsEnabled,
            viewPayors,
            managePayors,
            createPayee,
            viewPayeePayments,
            manageRewards,
          }),
    [
      isMember,
      isAdmin,
      hasLineOfCredit,
      canManagePayments,
      hasCreditCard,
      subscriptionPlanName,
      smsMfaEnabled,
      isPaidSubscription,
      createPayeePayment,
      viewPayeePayments,
      createPayee,
      isApprovalsEnabled,
    ]
  );

  const bottomSideNavItems = useMemo(
    () => getBottomSidebarItems({ planName: subscriptionPlanName, smsMfaEnabled, isPaidSubscription }),
    [subscriptionPlanName, smsMfaEnabled, isPaidSubscription]
  );

  const tabNavItems = useMemo(
    () => getTabNavItems({ canManagePayments, createPayeePayment, createPayee }),
    [canManagePayments, createPayeePayment, createPayee]
  );

  const topDropdownNavigation = useMemo(
    () => getTopDropdownItems({ accounts, activeAccountId, selectAccount }),
    [accounts, activeAccountId, selectAccount]
  );

  useDeepEffect(() => {
    if (!isAdmin) return;

    getSolidRequirements({
      onCompleted: (data) => {
        const { naicsProjectionsProvided, solidAccountExists } = get(data, 'me.account.solidRequirements');

        if (solidAccountExists && !naicsProjectionsProvided) {
          ampTrackEvent('Solid: account validation: open modal');
          openUSBankAccountVerificationsModal();
        }
      },
    });
  }, [isAdmin, openUSBankAccountVerificationsModal]);

  return {
    topDropdownNavigation,
    sideNavItems,
    bottomSideNavItems,
    tabNavItems,
    showWalletAgreementModal,
    setShowWalletAgreementModal,
    showUSBankAccountVerificationsModal,
    closeUSBankAccountVerificationsModal,
  };
};

export default useDashboardLayout;
