import React from 'react';

import useHasPermission from 'hooks/useHasPermission';
import { Loaders } from 'components/cards/Loader';

const PermissionChecker = ({ scope, memberScope = false, children, placeholder = null }) => {
  if (memberScope === true) {
    return <>{memberScope ? children : placeholder}</>;
  }
  const { isLoading, hasPermission } = useHasPermission({ scope });

  if (isLoading) return <Loaders.Spinner />;

  return <>{hasPermission ? children : placeholder}</>;
};

export default PermissionChecker;
