import { gql } from '@apollo/client';

export const SUBMIT_CRA_FORM = gql`
  mutation SubmitCRAForm($craBusinessNumber: String!) {
    submitCraForm(craBusinessNumber: $craBusinessNumber)
  }
`;

export const SUBMIT_CREDIT_APPLICATION = gql`
  mutation SubmitCreditApplication(
    $inventoryStorage: [InventoryStorageEnum!]!
    $averageProductMargin: Float!
    $employeeType: EmployeeTypeEnum!
    $employeesMonthlyPayroll: MoneyInput!
    $otherInventoryStorage: String
    $ownersPaymentMethod: OwnersPaymentMethodEnum!
    $ownersMonthlyPayroll: MoneyInput!
    $residentialStatus: ResidentialStatusEnum!
  ) {
    submitCreditApplication(
      inventoryStorage: $inventoryStorage
      averageProductMargin: $averageProductMargin
      employeeType: $employeeType
      employeesMonthlyPayroll: $employeesMonthlyPayroll
      otherInventoryStorage: $otherInventoryStorage
      ownersPaymentMethod: $ownersPaymentMethod
      ownersMonthlyPayroll: $ownersMonthlyPayroll
      residentialStatus: $residentialStatus
    )
  }
`;

export const GET_CREDIT_APPLICATION = gql`
  query CreditApplication {
    me {
      ... on User {
        account {
          contacts {
            firstName
            lastName
            roles
          }
          creditApplication {
            averageProductMargin
            employeeType
            employeesMonthlyPayroll {
              amount
            }
            inventoryStorage
            otherInventoryStorage
            ownersMonthlyPayroll {
              amount
            }
            ownersPaymentMethod
            residentialStatus
          }
        }
      }
    }
  }
`;

export const COMPLETE_CREDIT_APPLICATION = gql`
  mutation CompleteCreditApplication($creditApplicationId: ID!) {
    completeCreditApplication(creditApplicationId: $creditApplicationId)
  }
`;
