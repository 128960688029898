import React from 'react';

import { ToggleSwitch } from 'components/UI';
import { Currencies } from 'constants/currencies';
import { AccountingBankFeedType } from 'types/accountingBankFeed';
import { formatDateTime } from 'utility/date';
import useBankFeedSwitch from './hooks/useBankFeedSwitch';
import { Loaders } from 'components/cards/Loader';

const BankFeedSwitch = ({
  feedConnection,
  currency,
}: {
  feedConnection?: AccountingBankFeedType;
  currency: Currencies;
}) => {
  const { enabledFeed, handleToggleFeed, isToggleLoading, lastSyncedAt } = useBankFeedSwitch({
    feedConnection,
    currency,
  });

  return (
    <div className="tw-flex tw-flex-row tw-items-center">
      Loop {currency} Account
      <ToggleSwitch enabled={enabledFeed} setEnabled={handleToggleFeed} disabled={isToggleLoading} />
      {isToggleLoading && <Loaders.Spinner />}
      <span className="tw-ml-4 tw-text-neutral-grey-2">
        {lastSyncedAt && `Last Synced on ${formatDateTime(lastSyncedAt, 'h:mm b, EEEE, MMMM d, yyyy')}`}
      </span>
    </div>
  );
};

export default BankFeedSwitch;
