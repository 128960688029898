import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { get } from 'lodash';

import { supplierTypes, CURRENCY_CODES } from 'constants/index';
import { EMAIL_REGEX } from 'constants/regex';
import { TextField, Select } from 'components/FormFields/v2';
import FormFooter from 'components/payments/AddPayee/components/FormFooter/FormFooter';
import { AddPayeeContext } from 'components/payments/AddPayee/AddPayeeContext';
import { PAYEE_DETAILS_SOURCE } from 'components/payments/AddPayee/constants';
import { ConfirmationDetails, PayeeDetailsSourceOptions } from './components';

const { CAD } = CURRENCY_CODES;

const SupplierDetails = ({
  onNextStep,
  onPreviousStep,
  error,
  onSubmitSupplierDetails,
  onFinish,
  loading,
  isOpenCompleteSection,
}) => {
  const { supplierInfo, setSupplierInfo, payeeDetailsSource, setPayeeDetailsSource } = useContext(AddPayeeContext);
  const currency = get(supplierInfo, 'bankAccount.currency', CAD);
  const supplierType = get(supplierInfo, 'type', supplierTypes[0].value);
  const { displayName, legalName, firstName, lastName, email, bankAccount } = supplierInfo;

  const form = useForm({
    defaultValues: {
      ...supplierInfo,
      bankAccount: {
        ...bankAccount,
        currency,
      },
      type: supplierType,
      payeeDetailsSource,
    },
  });

  const { handleSubmit, register, watch } = form;
  const selectedSupplierType = watch('type');
  const isCompanySelected = selectedSupplierType === 'company';

  const watchEmail = watch('email');
  const watchPayeeDetailsSource = watch('payeeDetailsSource');
  const isRequestedPayeeDetails = watchPayeeDetailsSource === PAYEE_DETAILS_SOURCE.BY_REQUEST;

  const onSubmit = (data) => {
    const { payeeDetailsSource: payeeDetailsSourceFormValue, ...supplierFormValues } = data;
    setSupplierInfo({
      ...supplierInfo,
      ...supplierFormValues,
      bankAccount: { ...supplierInfo?.bankAccount, ...supplierFormValues?.bankAccount },
    });

    setPayeeDetailsSource(payeeDetailsSourceFormValue);

    if (isRequestedPayeeDetails) {
      onSubmitSupplierDetails(data);
      return;
    }

    onNextStep();
  };

  if (isOpenCompleteSection && !!supplierInfo.email)
    return <ConfirmationDetails supplierEmail={supplierInfo.email} onClose={onFinish} />;

  return (
    <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-px-8 tw-py-8 tw-space-y-6">
          <strong>Please enter the new payee’s details</strong>
          <Select
            options={supplierTypes}
            name="type"
            data-testid="type"
            label="Payee Type"
            placeholder="Choose"
            ref={register({ required: true })}
            required
            defaultValue={supplierType}
          />
          <TextField
            ref={register()}
            name="displayName"
            data-testid="displayName"
            label="Payee Nickname"
            placeholder="Bankonloop (Loop for E-commerce)"
            defaultValue={displayName}
          />

          {isCompanySelected ? (
            <TextField
              name="legalName"
              data-testid="legalName"
              label="Payee Legal Name"
              placeholder="Loop Financial Inc."
              ref={register({ required: true })}
              required
              defaultValue={legalName}
            />
          ) : (
            <>
              <TextField
                name="firstName"
                label="First Name"
                placeholder="John"
                ref={register({ required: true })}
                required
                defaultValue={firstName}
              />
              <TextField
                name="lastName"
                label="Last Name"
                placeholder="Doe"
                ref={register({ required: true })}
                required
                defaultValue={lastName}
              />
            </>
          )}

          <TextField
            name="email"
            data-testid="email"
            label="E-mail address"
            placeholder="supplier@goods.com"
            ref={register({ required: true, pattern: { value: EMAIL_REGEX, message: 'Invalid Email' } })}
            required
            defaultValue={email}
          />

          <PayeeDetailsSourceOptions isDisabled={!watchEmail} selectedValue={watchPayeeDetailsSource} />
        </div>
        <FormFooter
          onPreviousStep={onPreviousStep}
          submitLabel={isRequestedPayeeDetails ? 'Submit' : 'Next'}
          loading={loading}
        />
      </form>
    </FormProvider>
  );
};

export default SupplierDetails;
