import { gql } from '@apollo/client';

export const GET_CURRENT_USER_CONTACT_FOR_PERSONA = gql`
  query CurrentUserContactForPersona {
    me {
      ... on User {
        id
        account {
          onboardingStatus
        }
        internalContact {
          id
          thirdPartyVerificationComplete
          thirdPartyVerificationRequested
        }
      }
    }
  }
`;

export const GET_PERSONA_EMBEDDED_DATA = gql`
  query PersonaEmbeddedData($contactId: ID!) {
    personaEmbedded(contactId: $contactId) {
      environmentId
      inquiryId
      inquiryStatus
      referenceId
      sessionToken
      templateId
    }
  }
`;

export const GET_PERSONA_EMBEDDED_DATA_WITH_CONTACT = gql`
  query PersonaEmbeddedDataWithContact($contactId: ID!) {
    personaEmbedded(contactId: $contactId) {
      contact {
        address {
          street
          postalCode
          city
          country
          countrySubdivision
          unitNumber
        }
        firstName
        lastName
      }
      environmentId
      inquiryId
      inquiryStatus
      referenceId
      sessionToken
      templateId
    }
  }
`;
