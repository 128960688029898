import React, { useEffect, useState, memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { formatMoneyV2 } from 'utility/currency';
import { Select, MoneyInputField } from 'components/FormFields/v2';
import { SpendingLimitsProps } from './SpendingLimits.types';
import { LIMIT_TYPE_OPTIONS } from './constants';
import { CreditCardCurrency } from 'types/creditCard';

// The component should be used inside a FormProvider
const SpendingLimits = ({ limitType, limitAmount, currency = CreditCardCurrency.CAD }: SpendingLimitsProps) => {
  const [formattedAmount, setFormattedAmount] = useState(formatMoneyV2({ amount: limitAmount, currency: currency }));
  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue('limitType', limitType);
  }, [limitType]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <div>
        <label htmlFor="limitType" className="tw-text-sm tw-text-primary-dark-green">
          Limit Type
        </label>
        <Select name="limitType" options={LIMIT_TYPE_OPTIONS} ref={register()} placeholder="No Limit" unselected />
      </div>
      <div>
        <label htmlFor="limitAmount" className="tw-text-sm tw-text-primary-dark-green">
          Spend Limit
        </label>
        <MoneyInputField
          name="limitAmount"
          currency={currency}
          value={formattedAmount}
          setValue={setFormattedAmount}
          moneyFormatter={formatMoneyV2}
          ref={register()}
        />
      </div>
    </div>
  );
};

export default memo(SpendingLimits);
