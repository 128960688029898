import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

type ZapProps = {
  width?: number;
  height?: number;
};

const Zap = ({ width = 56, height = 57 }: ZapProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4.5" width="48" height="48" rx="24" fill="#D0E7DA" />
      <path
        d="M28.9999 18.5L20.0933 29.1879C19.7445 29.6064 19.5701 29.8157 19.5674 29.9925C19.5651 30.1461 19.6336 30.2923 19.7531 30.3889C19.8906 30.5 20.163 30.5 20.7079 30.5H27.9999L26.9999 38.5L35.9064 27.8121C36.2552 27.3936 36.4296 27.1843 36.4323 27.0075C36.4346 26.8539 36.3661 26.7077 36.2466 26.6111C36.1091 26.5 35.8367 26.5 35.2918 26.5H27.9999L28.9999 18.5Z"
        stroke={primaryDarkGreenColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="4" y="4.5" width="48" height="48" rx="24" stroke="#E8F3ED" strokeWidth="8" />
    </svg>
  );
};

export default Zap;
