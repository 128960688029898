import React from 'react';

import { PaymentRequestStatus } from 'types/invoicing';
import { Badge } from 'components/UI';
import { BadgeStatus } from 'components/UI/Badge';

import styles from './PaymentRequestStatusBadge.module.scss';

const PaymentRequestStatusBadge = ({ status }: { status: PaymentRequestStatus }) => {
  const badgeStatus = {
    [PaymentRequestStatus.scheduled]: BadgeStatus.success,
    [PaymentRequestStatus.completed]: BadgeStatus.success,
    [PaymentRequestStatus.processing]: BadgeStatus.success,
    [PaymentRequestStatus.flagged_for_review]: BadgeStatus.success,
    [PaymentRequestStatus.settled]: BadgeStatus.success,
    [PaymentRequestStatus.rejected]: BadgeStatus.error,
    [PaymentRequestStatus.failed]: BadgeStatus.error,
    [PaymentRequestStatus.cancelled]: BadgeStatus.error,
    [PaymentRequestStatus.expired]: BadgeStatus.error,
    [PaymentRequestStatus.overdue]: BadgeStatus.warning,
    [PaymentRequestStatus.pending]: BadgeStatus.pending,
    [PaymentRequestStatus.held]: BadgeStatus.success,
  }[status];

  const badgeTitle =
    status === PaymentRequestStatus.flagged_for_review || status === PaymentRequestStatus.held
      ? PaymentRequestStatus.processing
      : status;

  return <Badge status={badgeStatus} title={badgeTitle} classNames={styles.badge} />;
};

export default PaymentRequestStatusBadge;
