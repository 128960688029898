import { Amplify } from 'aws-amplify';
import config from './config';

Amplify.configure({
  Auth: {
    region: config.awsRegion,
    userPoolId: config.awsUserPoolId,
    userPoolWebClientId: config.awsUserPoolWebClientId,
    authenticationFlowType: 'USER_SRP_AUTH',
    cookieStorage: {
      domain: config.domain,
      path: '/',
      expires: 0.042, // 1 hour
      sameSite: 'strict',
    },
  },
});
