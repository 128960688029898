import { useFormContext } from 'react-hook-form';

import { CURRENCIES } from 'constants/index';

const useBusinessAddress = () => {
  const { register } = useFormContext();

  const availableCurrencies = CURRENCIES;

  return {
    register,
    availableCurrencies,
  };
};

export default useBusinessAddress;
