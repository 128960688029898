import React from 'react';

const CashAndStarsMultiple = ({ width = '94', height = '96', vbWidth = '94', vbHeight = '96', ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      {...other}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0651 19.516C23.1697 19.4454 23.2951 19.5708 23.2245 19.6755L18.7506 26.3061C18.7244 26.345 18.7244 26.3958 18.7506 26.4346L23.2245 33.0653C23.2951 33.17 23.1697 33.2954 23.0651 33.2248L16.4344 28.7509C16.3955 28.7247 16.3447 28.7247 16.3059 28.7509L9.67518 33.2248C9.57051 33.2954 9.44509 33.17 9.51572 33.0653L13.9896 26.4346C14.0158 26.3958 14.0158 26.345 13.9896 26.3061L9.51571 19.6754C9.44509 19.5708 9.57051 19.4454 9.67518 19.516L16.3059 23.9899C16.3447 24.0161 16.3955 24.0161 16.4344 23.9899L23.0651 19.516Z"
        fill="#EEA530"
      />
      <path
        d="M83.059 39.6988C83.0822 39.8229 82.9172 39.8881 82.8493 39.7816L79.855 35.0904C79.8298 35.0509 79.7831 35.0307 79.7371 35.0392L74.266 36.0598C74.1419 36.0829 74.0767 35.918 74.1832 35.85L78.8745 32.8558C78.9139 32.8306 78.9342 32.7839 78.9256 32.7379L77.905 27.2668C77.8819 27.1427 78.0468 27.0775 78.1148 27.1839L81.1091 31.8752C81.1343 31.9147 81.1809 31.9349 81.227 31.9263L86.698 30.9058C86.8221 30.8826 86.8873 31.0476 86.7809 31.1155L82.0896 34.1098C82.0501 34.135 82.0299 34.1817 82.0385 34.2277L83.059 39.6988Z"
        fill="#8F5CCF"
      />
      <path
        d="M69.9078 12.353C70.0258 12.3981 69.9952 12.5728 69.8689 12.5751L61.1991 12.7348C61.1523 12.7356 61.1107 12.7648 61.0939 12.8086L57.9967 20.9078C57.9516 21.0258 57.7769 20.9952 57.7745 20.8689L57.6149 12.1991C57.614 12.1523 57.5848 12.1107 57.5411 12.0939L49.4418 8.99669C49.3239 8.95159 49.3545 8.77688 49.4807 8.77455L58.1505 8.6149C58.1973 8.61404 58.239 8.58483 58.2557 8.54108L61.353 0.441822C61.3981 0.323881 61.5728 0.354488 61.5751 0.480737L61.7347 9.15054C61.7356 9.19737 61.7648 9.23898 61.8086 9.25571L69.9078 12.353Z"
        fill="#8F5CCF"
      />
      <g clipPath="url(#clip0_7771_79551)">
        <path
          d="M4.61231 57.797L4.6123 87.5561C4.6123 88.1882 5.12475 88.7007 5.75689 88.7007L21.781 88.7007C22.4132 88.7007 22.9256 88.1882 22.9256 87.5561L22.9256 57.797C22.9256 57.1648 22.4132 56.6524 21.781 56.6524L5.75689 56.6524C5.12475 56.6524 4.61231 57.1648 4.61231 57.797Z"
          fill="#FFE8C3"
        />
        <path
          d="M1.64258 54.8277L1.64258 84.5869C1.64258 85.219 2.15503 85.7314 2.78716 85.7314L18.8113 85.7314C19.4434 85.7314 19.9559 85.219 19.9559 84.5869L19.9559 54.8277C19.9559 54.1956 19.4434 53.6831 18.8113 53.6831L2.78716 53.6831C2.15503 53.6831 1.64258 54.1956 1.64258 54.8277Z"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinejoin="round"
        />
        <path
          d="M5.07664 69.7075C5.07664 72.8682 7.63887 75.4304 10.7995 75.4304C13.9602 75.4304 16.5225 72.8682 16.5225 69.7075C16.5225 66.5469 13.9602 63.9846 10.7995 63.9846C7.63887 63.9846 5.07664 66.5469 5.07664 69.7075Z"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2331 85.7317C14.2331 84.2139 14.8361 82.7582 15.9093 81.685C16.9826 80.6117 18.4382 80.0088 19.9561 80.0088"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2331 53.6831C14.2331 54.4346 14.3812 55.1788 14.6688 55.8732C14.9564 56.5675 15.3779 57.1984 15.9093 57.7298C16.4408 58.2612 17.0717 58.6828 17.766 58.9704C18.4603 59.258 19.2045 59.406 19.9561 59.406"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.36523 85.7317C7.36523 84.2139 6.76229 82.7582 5.68903 81.685C4.61578 80.6117 3.16013 80.0088 1.64232 80.0088"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.36523 53.6831C7.36523 54.4346 7.21721 55.1788 6.9296 55.8732C6.642 56.5675 6.22045 57.1984 5.68903 57.7298C5.15761 58.2612 4.52672 58.6828 3.83239 58.9704C3.13805 59.258 2.39386 59.406 1.64232 59.406"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip1_7771_79551)">
        <path
          d="M25.3857 41.3941L25.3857 71.0446C25.3857 71.6744 25.7332 72.185 26.1619 72.185L37.0274 74.1401C37.456 74.1401 37.8035 73.6296 37.8035 72.9997L37.8035 39.4383C37.8035 38.8084 37.456 38.2979 37.0274 38.2979L26.1619 40.2536C25.7332 40.2536 25.3857 40.7642 25.3857 41.3941Z"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinejoin="round"
        />
        <path
          d="M28.3854 57.2057C28.3854 60.3548 30.1228 62.9077 32.2659 62.9077C34.4091 62.9077 36.1465 60.3548 36.1465 57.2057C36.1465 54.0565 34.4091 51.5037 32.2659 51.5037C30.1228 51.5037 28.3854 54.0565 28.3854 57.2057Z"
          fill="#FFE8C3"
        />
        <path
          d="M27.7145 56.2191C27.7145 59.3683 29.4519 61.9211 31.595 61.9211C33.7382 61.9211 35.4756 59.3683 35.4756 56.2191C35.4756 53.07 33.7382 50.5171 31.595 50.5171C29.4519 50.5171 27.7145 53.07 27.7145 56.2191Z"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.0131 73.4792C34.0131 71.967 34.332 69.2226 35.0598 68.1532C35.7875 67.0839 36.7745 66.4832 37.8037 66.4832"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.0131 38.959C34.0131 39.7078 34.0235 41.7441 34.2186 42.4359C34.4136 43.1277 34.6994 43.7563 35.0598 44.2858C35.4201 44.8152 35.8479 45.2353 36.3187 45.5218C36.7895 45.8084 37.2941 45.9558 37.8037 45.9558"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2666 72.7166C29.2666 71.2044 28.8578 69.754 28.13 68.6847C27.4023 67.6154 26.4152 67.0146 25.3861 67.0146"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2188 39.9177C29.2187 40.6665 29.1661 41.7439 28.9711 42.4357C28.7761 43.1275 28.4903 43.7561 28.1299 44.2856C27.7696 44.8151 27.3418 45.2351 26.871 45.5216C26.4002 45.8082 25.8956 45.9557 25.386 45.9557"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M42.4237 57.178L40.2461 59.3556L42.4237 61.5332"
        stroke="#D3D7DC"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.791 59.3557H50.7944"
        stroke="#D3D7DC"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.6173 54.8208L50.7949 52.6432L48.6173 50.4656"
        stroke="#D3D7DC"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.25 52.6431L40.2466 52.6431"
        stroke="#D3D7DC"
        strokeWidth="0.550725"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip2_7771_79551)">
        <path
          d="M87.3877 57.797L87.3877 87.5561C87.3877 88.1882 86.8752 88.7007 86.2431 88.7007L70.219 88.7007C69.5868 88.7007 69.0744 88.1882 69.0744 87.5561L69.0744 57.797C69.0744 57.1648 69.5868 56.6524 70.219 56.6524L86.2431 56.6524C86.8752 56.6524 87.3877 57.1648 87.3877 57.797Z"
          fill="#D0E7DA"
        />
        <path
          d="M90.3574 54.8277L90.3574 84.5869C90.3574 85.219 89.845 85.7314 89.2128 85.7314L73.1887 85.7314C72.5566 85.7314 72.0441 85.219 72.0441 84.5869L72.0441 54.8277C72.0441 54.1956 72.5566 53.6831 73.1887 53.6831L89.2128 53.6831C89.845 53.6831 90.3574 54.1956 90.3574 54.8277Z"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinejoin="round"
        />
        <path
          d="M86.9234 69.7075C86.9234 72.8682 84.3611 75.4304 81.2005 75.4304C78.0398 75.4304 75.4775 72.8682 75.4775 69.7075C75.4775 66.5469 78.0398 63.9846 81.2005 63.9846C84.3611 63.9846 86.9234 66.5469 86.9234 69.7075Z"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.7669 85.7317C77.7669 84.2139 77.1639 82.7582 76.0907 81.685C75.0174 80.6117 73.5618 80.0088 72.0439 80.0088"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.7669 53.6831C77.7669 54.4346 77.6188 55.1788 77.3312 55.8732C77.0436 56.5675 76.6221 57.1984 76.0907 57.7298C75.5592 58.2612 74.9283 58.6828 74.234 58.9704C73.5397 59.258 72.7955 59.406 72.0439 59.406"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.6348 85.7317C84.6348 84.2139 85.2377 82.7582 86.311 81.685C87.3842 80.6117 88.8399 80.0088 90.3577 80.0088"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.6348 53.6831C84.6348 54.4346 84.7828 55.1788 85.0704 55.8732C85.358 56.5675 85.7795 57.1984 86.311 57.7298C86.8424 58.2612 87.4733 58.6828 88.1676 58.9704C88.862 59.258 89.6061 59.406 90.3577 59.406"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g clipPath="url(#clip3_7771_79551)">
        <path
          d="M66.6143 41.3941L66.6143 71.0446C66.6143 71.6744 66.2668 72.185 65.8381 72.185L54.9726 74.1401C54.544 74.1401 54.1965 73.6296 54.1965 72.9997L54.1965 39.4383C54.1965 38.8084 54.544 38.2979 54.9726 38.2979L65.8381 40.2536C66.2668 40.2536 66.6143 40.7642 66.6143 41.3941Z"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinejoin="round"
        />
        <path
          d="M63.6146 57.2057C63.6146 60.3548 61.8772 62.9077 59.7341 62.9077C57.5909 62.9077 55.8535 60.3548 55.8535 57.2057C55.8535 54.0565 57.5909 51.5037 59.7341 51.5037C61.8772 51.5037 63.6146 54.0565 63.6146 57.2057Z"
          fill="#D0E7DA"
        />
        <path
          d="M64.2855 56.2191C64.2855 59.3683 62.5481 61.9211 60.405 61.9211C58.2618 61.9211 56.5244 59.3683 56.5244 56.2191C56.5244 53.07 58.2618 50.5171 60.405 50.5171C62.5481 50.5171 64.2855 53.07 64.2855 56.2191Z"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.9869 73.4792C57.9869 71.967 57.668 69.2226 56.9402 68.1532C56.2125 67.0839 55.2255 66.4832 54.1963 66.4832"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.9869 38.959C57.9869 39.7078 57.9765 41.7441 57.7814 42.4359C57.5864 43.1277 57.3006 43.7563 56.9402 44.2858C56.5799 44.8152 56.1521 45.2353 55.6813 45.5218C55.2105 45.8084 54.7059 45.9558 54.1963 45.9558"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.7334 72.7166C62.7334 71.2044 63.1422 69.754 63.87 68.6847C64.5977 67.6154 65.5848 67.0146 66.6139 67.0146"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.7812 39.9177C62.7813 40.6665 62.8339 41.7439 63.0289 42.4357C63.2239 43.1275 63.5097 43.7561 63.8701 44.2856C64.2304 44.8151 64.6582 45.2351 65.129 45.5216C65.5998 45.8082 66.1044 45.9557 66.614 45.9557"
          stroke="#D3D7DC"
          strokeWidth="0.550725"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7771_79551">
          <rect width="37.6165" height="22.7679" fill="white" transform="translate(23.6992 52.3835) rotate(90)" />
        </clipPath>
        <clipPath id="clip1_7771_79551">
          <rect width="36.4381" height="13.4246" fill="white" transform="translate(38.3281 38) rotate(90)" />
        </clipPath>
        <clipPath id="clip2_7771_79551">
          <rect
            width="37.6165"
            height="22.7679"
            fill="white"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 68.3008 52.3835)"
          />
        </clipPath>
        <clipPath id="clip3_7771_79551">
          <rect
            width="36.4381"
            height="13.4246"
            fill="white"
            transform="matrix(4.37114e-08 1 1 -4.37114e-08 53.6719 38)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CashAndStarsMultiple;
