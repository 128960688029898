import React, { useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';

export const QuickBooksAccount = ({
  className = '',
  qbCategories,
  qbCategory,
  onChange,
  newAccount,
  listOptionStyles = '',
  buttonStyle = false,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(qbCategory || { name: 'Select or Create', id: '-1' });

  useEffect(() => {
    if (qbCategory) setSelectedCategory(qbCategory);
  }, [qbCategory]);

  const handleSelectCategory = (value) => {
    onChange(value);
    setSelectedCategory(value);
  };

  return (
    <Listbox className={className} value={selectedCategory} onChange={handleSelectCategory}>
      {({ open }) => (
        <div>
          <Listbox.Button
            className={`qboExpenseAccount tw-flex tw-items-center tw-justify-between tw-cursor-pointer hover:tw-text-primary-dark-green
          ${
            buttonStyle &&
            'tw-px-3 tw-py-2 tw-rounded-md tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input'
          }`}
            name="category"
          >
            <div className="tw-truncate">{selectedCategory.name}</div>
            <FaChevronDown className={`tw-ml-2 ${open && 'tw-rotate-180 tw-transform'}`} />
          </Listbox.Button>
          <Transition
            as={React.Fragment}
            leave="tw-transition tw-ease-in tw-duration-100"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Listbox.Options
              className={`tw-max-h-96 tw-h-auto tw-absolute tw-z-10 tw-overflow-y-scroll tw-bg-neutral-light tw-rounded-md tw-shadow-2xl tw-shadow-inner tw-border-solid tw-border-2 tw-border-primary-light-green tw-scrollbar-hide focus-visible: tw-outline-none focus:tw-outline-none
            ${listOptionStyles}
            `}
            >
              {qbCategories &&
                qbCategories.map((categoryOption) => (
                  <Listbox.Option
                    key={categoryOption.id}
                    value={categoryOption}
                    className={`tw-px-2 tw-py-1 tw-cursor-pointer tw-text-neutral-grey-2 hover:tw-text-neutral-light hover:tw-bg-primary-dark-green ${
                      selectedCategory.id === categoryOption.id &&
                      'tw-text-primary-dark-green tw-bg-primary-light-green'
                    }`}
                    selected={selectedCategory.id === categoryOption.id}
                  >
                    {({ active }) => (
                      <span
                        className={`tw-inline-flex tw-items-center ${active ? 'tw-font-medium' : 'tw-font-normal'}`}
                      >
                        <span className="tw-ml-2 tw-mr-1">{categoryOption.name}</span>
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              {newAccount && <div onClick={(evt) => evt.stopPropagation()}>{newAccount()}</div>}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
