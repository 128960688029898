import React from 'react';

import InitialsCircle from 'components/InitialsCircle';

const InitialsCirclesGroup = ({ initialsList, isLoading = false, countThreshold = 2 }) => {
  if (isLoading) return <Skeleton />;
  if (!initialsList || initialsList.length === 0) return null;
  const visibleMembers = initialsList.slice(0, 2).sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
  const hiddenMembersCount = initialsList.length - countThreshold;

  return (
    <div className="tw-flex">
      {visibleMembers.map((initials) => (
        <div key={initials} className="tw--ml-2 tw-rounded-full tw-border tw-border-secondary-pastel-green-100 tw-flex">
          <InitialsCircle.Small initials={initials} />
        </div>
      ))}
      {hiddenMembersCount > 0 && (
        <div className="tw-rounded-full tw-border tw-border-secondary-pastel-green-100 tw-flex tw-justify-center tw-items-center tw-w-max tw-py-0.5 tw-px-1 tw-bg-secondary-pastel-green-100 tw--ml-2">
          <span className="tw-text-xs tw-text-primary-dark-green">{`+${hiddenMembersCount}`}</span>
        </div>
      )}
    </div>
  );
};

const Skeleton = () => (
  <div className="tw-flex">
    <div className="tw--ml-2 tw-rounded-full tw-border tw-border-neutral-grey-3 tw-bg-neutral-grey-3 tw-h-6 tw-w-6 tw-animate-pulse" />
    <div className="tw--ml-2 tw-rounded-full tw-border tw-border-neutral-grey-4 tw-bg-neutral-grey-4 tw-h-6 tw-w-6 tw-animate-pulse" />
  </div>
);

export default InitialsCirclesGroup;
