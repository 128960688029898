export const getNavItems = () => {
  const items = [
    {
      route: '/onboarding/dashboard/settings/personal',
      label: 'Personal',
    },
    {
      route: '/onboarding/dashboard/settings/notifications',
      label: 'Notifications',
    },
    {
      route: '/onboarding/dashboard/settings/integrations',
      label: 'Sales Integrations',
    },
    {
      route: '/onboarding/dashboard/settings/accounting-integrations',
      label: 'Accounting Integrations',
    },
    {
      route: '/onboarding/dashboard/settings/bank-accounts-settings',
      label: 'Bank Accounts',
      subRoutes: ['/onboarding/dashboard/settings/bank-accounts-settings/add'],
    },
    {
      route: '/onboarding/dashboard/settings/documents',
      label: 'Documents',
    },
    {
      route: '/onboarding/dashboard/settings/team',
      label: 'Team',
    },
    {
      route: '/onboarding/dashboard/settings/agreements',
      label: 'Agreements',
    },
    {
      route: '/onboarding/dashboard/settings/payments',
      label: 'Payments',
    },
  ];

  return items;
};
