import React from 'react';

import { ContentProps } from './Content.types';
import { ErrorState, Loader } from '..';

const Content = ({ isLoading, error, isValid, children }: ContentProps) => {
  if (isLoading) return <Loader />;

  if (error || !isValid) return <ErrorState message={error} />;

  return <>{children}</>;
};

export default Content;
