import React, { useEffect, useState, createContext } from 'react';
import { ApolloError, useQuery, useMutation } from '@apollo/client';
import { useDeepEffect } from 'hooks';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import { ExternalAccountingAccount } from 'types/externalAccountingAccount';
import {
  AddWalletTransactionsCategoryMappingRequestContextType,
  WalletTransactionsCategoryMappingRequestPayloadType,
  CategoryListProps,
  AllAccountsListProps,
  WalletTransactionsCategoryDetailsMappingType,
  WalletTransactionsCategoryMappingType,
} from '../WalletCategorySettings.types';
import {
  GET_ALL_ACCOUNTS,
  GET_WALLET_TRANSACTION_CATEGORIES,
  GET_WALLET_TRANSACTION_CATEGORY_ACCOUNTING_INTEGRATION_MAPPING,
  UPDATE_WALLET_TRANSACTION_CATEGORY_ACCOUNTING_INTEGRATION_MAPPING,
} from 'graphql/accountingServices';
import { Category } from 'types/category';

export const WalletCategoryMappingRequestContext =
  createContext<AddWalletTransactionsCategoryMappingRequestContextType>(
    {} as AddWalletTransactionsCategoryMappingRequestContextType
  );

export const AddWalletCategoryMappingRequestContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [externalAccountingAccounts, setExternalAccountingAccounts] = useState<ExternalAccountingAccount[]>([]);
  const [availableExternalAccountingAccounts, setAvailableExternalAccountingAccounts] = useState<
    ExternalAccountingAccount[]
  >([]);
  const [walletCategoryMappings, setWalletCategoryMappings] = useState<WalletTransactionsCategoryDetailsMappingType[]>(
    []
  );

  const [walletCategoryMappingRequestInfo, setWalletCategoryMappingRequestInfo] =
    useState<WalletTransactionsCategoryMappingRequestPayloadType | null>(null);
  const [error, setError] = useState<ApolloError | null>(null);
  const [loading, setLoading] = useState(true);

  const { data: categoryData, loading: loadingCategories } = useQuery<CategoryListProps>(
    GET_WALLET_TRANSACTION_CATEGORIES
  );
  const categoryList = get(categoryData, 'walletTransactionCategories', []) as Category[];

  const { data: allAccountsData, loading: loadingAllAccounts } = useQuery<AllAccountsListProps>(GET_ALL_ACCOUNTS);
  const allAccountsList = get(allAccountsData, 'accountingIntegrationAllAccounts', []) as ExternalAccountingAccount[];

  const { data: mappingsData, loading: loadingMappings } = useQuery<WalletTransactionsCategoryMappingType>(
    GET_WALLET_TRANSACTION_CATEGORY_ACCOUNTING_INTEGRATION_MAPPING
  );
  const mappingsList = get(
    mappingsData,
    'walletTransactionCategoryAccountingIntegrationMapping',
    []
  ) as WalletTransactionsCategoryMappingType[];

  const [updateMapping] = useMutation(UPDATE_WALLET_TRANSACTION_CATEGORY_ACCOUNTING_INTEGRATION_MAPPING, {
    refetchQueries: [{ query: GET_WALLET_TRANSACTION_CATEGORY_ACCOUNTING_INTEGRATION_MAPPING }],
    awaitRefetchQueries: true,
  });

  const findExternalAccountingAccount = (externalAccountingAccountId: string) => {
    return externalAccountingAccounts.find((account) => account.id === externalAccountingAccountId);
  };

  const findCategory = (categoryId: string) => {
    return categories.find((category) => category.transactionCategoryId === categoryId);
  };

  useDeepEffect(() => {
    setCategories(categoryList);
  }, [categoryList]);

  useDeepEffect(() => {
    const allAccounts = allAccountsList
      .map((account) => ({
        ...account,
        name: `(${account.accountType}) ${account.name}`,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    setExternalAccountingAccounts(allAccounts);
    setAvailableExternalAccountingAccounts(allAccounts);
  }, [allAccountsList]);

  useDeepEffect(() => {
    // Step 1: Map wallet category mappings
    const mappedWalletCategoryMappings = mappingsList.map((mapping) => {
      const externalAccountingAccount = findExternalAccountingAccount(mapping.quickBooksAccountId);
      const category = findCategory(mapping.transactionCategoryId);
      return {
        id: mapping.id,
        transactionCategory: category,
        externalAccount: externalAccountingAccount,
      } as WalletTransactionsCategoryDetailsMappingType;
    });

    // Step 2: Create a key value pair of category ID and mapping
    const existingMappings = {} as { [key: string]: WalletTransactionsCategoryDetailsMappingType };
    mappedWalletCategoryMappings.forEach((mapping) => {
      existingMappings[mapping?.transactionCategory?.transactionCategoryId] = mapping;
    });

    // Step 3: create the final mapping array
    const allMappedWalletCategoryMappings = categories.map((category) => {
      return {
        id: existingMappings[category.transactionCategoryId]?.id,
        transactionCategory: category,
        externalAccount: existingMappings[category.transactionCategoryId]?.externalAccount,
      } as WalletTransactionsCategoryDetailsMappingType;
    });
    setWalletCategoryMappings(allMappedWalletCategoryMappings);

    /*// Step 4: Update available external accounting accounts
    const mappedExternalAccountingAccounts = mappingsList.map((mapping) => mapping.quickBooksAccountId);
    setAvailableExternalAccountingAccounts(
      externalAccountingAccounts.filter((account) => !mappedExternalAccountingAccounts.includes(account.id))
    );*/
  }, [mappingsList, externalAccountingAccounts]);

  useEffect(() => {
    setLoading(loadingCategories || loadingAllAccounts || loadingMappings);
  }, [loadingCategories, loadingAllAccounts, loadingMappings]);

  useDeepEffect(async () => {
    if (walletCategoryMappingRequestInfo) {
      try {
        await updateMapping({
          variables: walletCategoryMappingRequestInfo,
        });
      } catch (err) {
        console.error(err);
        toast.error('Error saving transaction category - accounting integration mapping - please try again');
        setError(err as ApolloError);
      }
    }
  }, [walletCategoryMappingRequestInfo]);

  return (
    <WalletCategoryMappingRequestContext.Provider
      value={{
        loading,
        categories,
        externalAccountingAccounts,
        availableExternalAccountingAccounts,
        walletCategoryMappings,
        walletCategoryMappingRequestInfo,
        setWalletCategoryMappingRequestInfo,
        error,
        setError,
      }}
    >
      {children}
    </WalletCategoryMappingRequestContext.Provider>
  );
};
