import React, { useState, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { get, compact } from 'lodash';

import TeamMember from 'domain/teamMember';
import { GET_MEMBERS_AND_INVITATIONS } from 'graphql/settings';
import { GET_EXTERNAL_ACCOUNTS, GET_BANK_ACCOUNTS } from 'graphql/integrations';
import { SettingsContext } from 'context/Settings';
import { useDeepEffect, useGetUserInfo } from 'hooks';
import { TEAM_INVITATION_STATUS } from 'constants/index';
import SettingsLayout from 'components/settings/SettingsLayout';
import { SettingsLayout as NonKYCApprovedSettingsLayout } from 'components/OnboardingDashboard/components/Settings/components';

const MAX_RETRY_TIME = 1000 * 60; // 1 Min

const SettingsLayoutContainer = (props) => {
  const { isKYCApproved } = useGetUserInfo();

  const [getMembersAndInvitations, { loading: loadingMembers, data: membersData, refetch: refetchMembers }] =
    useLazyQuery(GET_MEMBERS_AND_INVITATIONS, { nextFetchPolicy: 'network-only' });

  const {
    loading: loadingIntegrations,
    data: integrationsData,
    refetch: refetchIntegrations,
  } = useQuery(GET_EXTERNAL_ACCOUNTS);
  const {
    loading: loadingBankAccounts,
    data: bankAccountsData,
    refetch: refetchBankAccounts,
  } = useQuery(GET_BANK_ACCOUNTS);

  const members = get(membersData, 'me.account.internalContacts') || [];
  const invitations = get(membersData, 'me.account.teamInvitations') || [];
  const pendingInvitations = invitations.filter((i) => i.status !== TEAM_INVITATION_STATUS.accepted);
  const invitationsContactIds = compact(pendingInvitations.map((i) => i.internalContactId));
  const availableMembers = members.filter((m) => !invitationsContactIds.includes(m.id));
  const { externalAccounts } = integrationsData || {};
  const { bankAccounts, complete, allowBankAccountCreation, bankConnectionError } =
    get(bankAccountsData, 'bankInfo') || {};
  const waitingForBankDetails = !complete;
  const [bankAccountError, setBankAccountError] = useState(false);

  useDeepEffect(() => {
    const time = new Date();
    const intervalId = setInterval(() => {
      if (new Date() - time > MAX_RETRY_TIME && waitingForBankDetails) {
        setBankAccountError(true);
        return;
      }
      setBankAccountError(false);

      if (waitingForBankDetails) {
        refetchBankAccounts();
      }
    }, 2000);
    return () => clearInterval(intervalId);
  }, [waitingForBankDetails, refetchBankAccounts]);

  useDeepEffect(() => {
    if (isKYCApproved) getMembersAndInvitations();
  }, [isKYCApproved, getMembersAndInvitations]);

  return (
    <Content
      loadingMembers={loadingMembers}
      loadingIntegrations={loadingIntegrations}
      loadingBankAccounts={loadingBankAccounts}
      waitingForBankDetails={waitingForBankDetails}
      members={[...availableMembers, ...pendingInvitations].map((c) => new TeamMember(c))}
      refetchMembers={refetchMembers}
      externalAccounts={externalAccounts}
      bankAccounts={bankAccounts}
      allowBankAccountCreation={allowBankAccountCreation}
      bankConnectionError={bankConnectionError}
      refetchIntegrations={refetchIntegrations}
      refetchBankAccounts={refetchBankAccounts}
      bankAccountError={bankAccountError}
      isKYCApproved={isKYCApproved}
      {...props}
    />
  );
};

const Content = (props) => {
  const {
    setMembers,
    setExternalAccounts,
    setBankAccounts,
    setBankAccountError,
    setAllowBankAccountCreation,
    setBankConnectionError,
    refetchIntegrationsRef,
    refetchBankAccountsRef,
    refetchMembersRef,
    setLoadingMembers,
    setLoadingIntegrations,
    setLoadingBankAccounts,
    setWaitingForBankDetails,
  } = useContext(SettingsContext);
  const {
    loadingMembers,
    loadingIntegrations,
    loadingBankAccounts,
    waitingForBankDetails,
    members,
    externalAccounts,
    bankAccounts,
    allowBankAccountCreation,
    bankConnectionError,
    bankAccountError,
    refetchIntegrations,
    refetchBankAccounts,
    refetchMembers,
    isKYCApproved,
    ...otherProps
  } = props;

  useDeepEffect(() => {
    setLoadingMembers(loadingMembers);
    setLoadingIntegrations(loadingIntegrations);
    setLoadingBankAccounts(loadingBankAccounts);
    setWaitingForBankDetails(waitingForBankDetails);
    setAllowBankAccountCreation(allowBankAccountCreation);
    setBankConnectionError(bankConnectionError);
    const handleReload = (reloadFn) => async () => {
      setLoadingMembers(true);
      setLoadingIntegrations(true);
      setLoadingBankAccounts(true);
      setWaitingForBankDetails(true);
      await reloadFn();
      setLoadingMembers(false);
      setLoadingIntegrations(false);
      setLoadingBankAccounts(false);
      setWaitingForBankDetails(false);
    };
    refetchIntegrationsRef.current = handleReload(refetchIntegrations);
    refetchBankAccountsRef.current = handleReload(refetchBankAccounts);
    refetchMembersRef.current = handleReload(refetchMembers);

    if (members) setMembers(members);
    if (externalAccounts) setExternalAccounts(externalAccounts);
    if (bankAccounts) setBankAccounts(bankAccounts);
    if (bankAccountError) setBankAccountError(bankAccountError);
  }, [
    loadingMembers,
    loadingIntegrations,
    loadingBankAccounts,
    waitingForBankDetails,
    members,
    externalAccounts,
    bankAccounts,
    allowBankAccountCreation,
    bankConnectionError,
    bankAccountError,
  ]);

  const Layout = isKYCApproved ? SettingsLayout : NonKYCApprovedSettingsLayout;

  return <Layout {...otherProps} />;
};

export default SettingsLayoutContainer;
