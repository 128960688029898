import React from 'react';
import cx from 'classnames';
import { get } from 'lodash';

import referAndEarn from 'images/referAndEarn.png';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import { GET_BORROWER_REFERRAL_CODE_AND_POINTS } from 'graphql/rewards';
import { useQuery } from '@apollo/client';
import { Loaders } from 'components/cards/Loader';

const ReferAndEarnBanner = ({ horizontal }: { horizontal?: boolean }) => {
  const history = useHistory();
  const { data, loading } = useQuery(GET_BORROWER_REFERRAL_CODE_AND_POINTS);
  const numberOfPoints = get(data, 'me.account.registrationReferralPoints', 0).toLocaleString();

  const handleOnClick = () => {
    history.push('/dashboard/rewards?tab=refer-and-earn');
  };

  if (loading)
    return (
      <div className="tw-text-center tw-p-20">
        <Loaders.Spinner />
      </div>
    );

  if (!numberOfPoints) return null;

  return (
    <div
      className={cx(
        'tw-flex tw-gap-2 tw-p-4 tw-border tw-border-neutral-grey-4 tw-rounded-md tw-shadow-md tw-items-center',
        horizontal ? 'tw-flex-row' : 'tw-flex-col tw-w-2/5 '
      )}
    >
      <img
        className={cx(horizontal ? 'tw-w-1/5' : 'tw-w-3/5', 'tw-object-contain')}
        src={referAndEarn}
        alt="Refer and Earn"
      />
      <div className={cx(horizontal && 'tw-flex tw-flex-col tw-items-start tw-flex-grow')}>
        <div className="tw-text-lg tw-font-bold tw-text-primary-dark-green">Refer and Earn</div>
        <span>You and a friend both earn {numberOfPoints}pts after their first qualifying transaction</span>
        <Button primary className="tw-my-2 tw-mr-2" onClick={handleOnClick}>
          View Referral Code
        </Button>
      </div>
    </div>
  );
};
export default ReferAndEarnBanner;
