import React from 'react';

import { Transaction } from 'types/payments';
import { Currencies } from 'constants/currencies';
import { AmountCardWithFx } from 'components/UI';
import InfoTooltip from 'components/InfoTooltip';
import { INTO_TOOLTIP } from 'components/payments/CardRepayment/components/Steps/components/Details/constants';

const Amounts = ({ transactions }: { transactions: Transaction[] }) => {
  return (
    <div className="tw-flex tw-flex-col xl:tw-flex-row tw-gap-2 xl:tw-gap-4 tw-items-start">
      <div className="tw-flex tw-items-center tw-gap-1 xl:tw-w-1/6 tw-h-12">
        Payment Amount <InfoTooltip message={INTO_TOOLTIP.paymentAmount} />
      </div>

      <div className="tw-flex tw-flex-col xl:tw-flex-row xl:tw-flex-wrap tw-items-start tw-gap-2 xl:tw-w-5/6 tw-mb-2">
        {transactions.map((transaction) => {
          const { originalAmount, fxAmount, isDisabled } = transaction;
          return (
            <div
              key={originalAmount.currency}
              title={isDisabled ? 'No outstanding balance' : undefined}
              className="tw-mb-2"
            >
              <AmountCardWithFx
                originalAmount={
                  originalAmount as unknown as {
                    amount: number;
                    currency: Currencies;
                  }
                }
                fxAmount={
                  fxAmount as unknown as {
                    amount: number;
                    currency: Currencies;
                  }
                }
                isDisabled={isDisabled}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Amounts;
