import React from 'react';

import { formatMoney } from 'utility/currency';
import WaitingRoomIcon from 'components/svg/WaitingRoom';
import CashAndStarsMultiple2 from 'components/svg/CashAndStarsMultiple2';
import CardAndCalendar from 'components/svg/CardAndCalendar';
import ChecklistAndMagnifier from 'components/svg/ChecklistAndMagnifier';
import config from 'config';
import { TermContent, GlobalCreditLimitType } from './KeyTerms.types';

export const getTerms = ({
  globalCreditLimit,
  isGlobalCreditLimitLoading,
}: {
  globalCreditLimit?: GlobalCreditLimitType;
  isGlobalCreditLimitLoading: boolean;
}) => {
  const Terms: TermContent[] = [
    {
      graphic: (
        <WaitingRoomIcon
          barColor="var(--colors-secondary-pastel-orange-100)"
          bigStarColor="var(--colors-primary-dark-green)"
          width="80"
          height="80"
          className="tw-min-w-max"
        />
      ),
      title: 'Credit Limit',
      content: `${formatMoney(globalCreditLimit)} Equivalent`,
    },
    {
      graphic: <CashAndStarsMultiple2 size={80} />,
      title: 'Card Currencies',
      content: `Spend and repay in CAD, USD, EUR, and GBP`,
    },
    {
      graphic: <CardAndCalendar width="80" height="60" className="tw-min-w-max" />,
      title: 'Payment Due Date',
      content: `25 days after your monthly statement period`,
    },
    {
      graphic: <ChecklistAndMagnifier width="80" height="70" className="tw-min-w-max" />,
      title: 'Annual Interest Rate',
      content: (
        <>
          <small className="tw-block">0% Interest on Purchases when balance paid in full by Payment Due Date</small>
          <div className="tw-text-xs">Cash Advance and Purchase rate equal to {config.defaultCardAPR}</div>
        </>
      ),
    },
  ];

  if (isGlobalCreditLimitLoading) {
    const filteredTerms = Terms.filter((term) => term.title !== 'Credit Limit');
    return filteredTerms;
  }
  return Terms;
};
