import qs from 'query-string';

export const UTM_KEY = 'utmParameters';

export const registerUtmParameters = () => {
  const queryParams = qs.parse(window.location && window.location.search);
  const utm = window.localStorage.getItem(UTM_KEY);
  if (Object.keys(utm || {}).length === 0 || Object.keys(queryParams || {}).length > 0) {
    window.localStorage.setItem(UTM_KEY, JSON.stringify(queryParams));
  }
};
