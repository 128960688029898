import React from 'react';
import { APPROVAL_TYPE } from 'components/Approvals/constants';

const ApprovalTypeBadge = ({ approvalType }: { approvalType: APPROVAL_TYPE }) => {
  switch (approvalType) {
    case APPROVAL_TYPE.reimbursement:
      return (
        <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-1">
          <div className="tw-py-1 tw-px-2 tw-w-max tw-rounded-md tw-align-center tw-bg-secondary-light-yellow">
            <small>Reimbursement</small>
          </div>
        </div>
      );
    case APPROVAL_TYPE.cardExpense:
      return (
        <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-1">
          <div className="tw-py-1 tw-px-2 tw-w-max tw-rounded-md tw-align-center tw-bg-secondary-light-blue">
            <small>Card Transaction</small>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default ApprovalTypeBadge;
