import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Transition } from '@headlessui/react';

import InfoTipEmail from 'components/svg/InfoTipEmail';
import ArrowRight from 'components/svg/ArrowRight';
import { RadioField, SubmitButton } from 'components/FormFields/v2';
import Button from 'components/Button';
import InfoTip from 'components/onboarding/InfoTip';

import { AccountManagerContactFields, CreditReportConsentModal, PrimaryOwnerContactFields } from './components';
import { useForm } from './hooks';

const Form = (props) => {
  const { accountManager, initialValues, onSubmit, error, isSubmitting, isBusinessAndPersonalAddressSame } = props;
  const {
    form,
    handleSubmit,
    handleSubmitForm,
    register,
    isAccountOwner,
    isAccountOwnerValid,
    handleTogglePersonalAddressSameAsBusiness,
    personalAddressSameAsBusiness,
    onGoToPreviousStep,
    show,
    onOpen,
    onClose,
  } = useForm({ accountManager, initialValues, onSubmit, isBusinessAndPersonalAddressSame });

  return (
    <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        {!initialValues.isAccountOwner && (
          <div className="tw-mb-12">
            <label className="tw-text-neutral-grey-1 tw-mb-4" htmlFor="isAccountOwner">
              Are you the primary owner of the business?
              <span className="tw-text-semantic-error">{' *'}</span>
            </label>
            <div className="tw-relative">
              <RadioField
                className="tw-overflow-hidden"
                label="Are you the primary owner of the business?"
                name="isAccountOwner"
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
                ref={register({ required: true })}
              />
              <InfoTip
                icon={<InfoTipEmail />}
                text="If you’re not the primary owner, that’s fine, we will ask the owner to fill in the rest of the information and complete the account opening process"
              />
            </div>
          </div>
        )}
        {isAccountOwnerValid && (
          <>
            <Transition
              show={isAccountOwner !== 'true'}
              enter="tw-ease-in-out tw-duration-200"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-ease-in-out tw-duration-200"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <PrimaryOwnerContactFields />
              <div className="tw-mb-4">
                <h3>Your Details</h3>
              </div>
            </Transition>

            <AccountManagerContactFields
              onTogglePersonalAddressSameAsBusiness={handleTogglePersonalAddressSameAsBusiness}
              personalAddressSameAsBusiness={personalAddressSameAsBusiness}
            />

            <p className="tw-mt-16">
              By clicking Confirm, I consent to the&nbsp;
              <a onClick={onOpen}>
                <b className="tw-cursor-pointer tw-text-primary-dark-green">Credit Report Consent Terms</b>
              </a>
              . This will not affect your credit score.
            </p>
          </>
        )}

        <div className="tw-flex tw-justify-between tw-mt-8">
          <Button
            type="button"
            secondary
            className="tw-w-max tw-bg-neutral-grey-5 tw-text-neutral-dark"
            onClick={onGoToPreviousStep}
          >
            Previous step
          </Button>
          <SubmitButton className="tw-w-max" disabled={isSubmitting || !isAccountOwnerValid}>
            <div className="tw-flex tw-justify-center tw-items-center">
              <p className="tw-text-neutral-light tw-mr-2">{isSubmitting ? 'Submitting...' : 'Continue'}</p>
              <ArrowRight />
            </div>
          </SubmitButton>
        </div>
      </form>
      <CreditReportConsentModal show={show} onClose={onClose} />
    </FormProvider>
  );
};

export default Form;
