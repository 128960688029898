import React from 'react';

const Speedometer = () => (
  <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47.7687 33.9684L40.8107 45.028C40.526 45.4225 40.1798 45.7687 39.7853 46.0534C38.8329 46.7178 37.6568 46.9798 36.5124 46.7826C35.368 46.5853 34.3476 45.9446 33.6726 44.9997C32.9977 44.0547 32.7226 42.8816 32.9071 41.7351C33.0917 40.5886 33.7209 39.5611 34.6583 38.8757L45.7179 31.9176C45.9693 31.7424 46.2684 31.6484 46.5748 31.6484C46.8813 31.6484 47.1804 31.7424 47.4318 31.9176C47.7475 32.1456 47.9602 32.4892 48.0233 32.8735C48.0864 33.2578 47.9949 33.6514 47.7687 33.9684V33.9684Z"
      fill="#26262C"
    />
    <path
      d="M37.5 9.375C19.3799 9.375 4.68753 24.0527 4.68753 42.1729C4.67642 50.1897 7.61141 57.9313 12.9346 63.9258C13.0957 64.1016 13.2422 64.2773 13.4034 64.4385C13.75 64.8138 14.1708 65.113 14.6391 65.3172C15.1074 65.5213 15.613 65.6261 16.1239 65.6247C16.6347 65.6233 17.1398 65.5159 17.6069 65.3092C18.0741 65.1024 18.4933 64.801 18.8379 64.4238C21.2132 61.8426 24.0981 59.7821 27.3103 58.3727C30.5225 56.9633 33.9922 56.2356 37.5 56.2356C41.0079 56.2356 44.4775 56.9633 47.6898 58.3727C50.902 59.7821 53.7869 61.8426 56.1621 64.4238C56.5068 64.801 56.9259 65.1024 57.3931 65.3092C57.8603 65.5159 58.3653 65.6233 58.8762 65.6247C59.3871 65.6261 59.8927 65.5213 60.361 65.3172C60.8293 65.113 61.2501 64.8138 61.5967 64.4385L62.0655 63.9258C67.3887 57.9313 70.3236 50.1897 70.3125 42.1729C70.3125 24.0527 55.6201 9.375 37.5 9.375Z"
      stroke="#26262C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M37.5 18.75V23.4375" stroke="#26262C" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M60.9375 42.1875H56.25" stroke="#26262C" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M18.75 42.1875H14.0625" stroke="#26262C" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M24.2412 28.9302L20.9263 25.6152" stroke="#26262C" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M50.7583 28.9302L54.0732 25.6152" stroke="#26262C" strokeMiterlimit="10" strokeLinecap="round" />
  </svg>
);

export default Speedometer;
