import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import _ from 'lodash';

import { ampTrackEvent } from '../../amplitude';
import Close from '../svg/Close';
import { TextField as TextFieldV1 } from '../FormFields';
import { TextField as TextFieldV2, SubmitButton } from '../FormFields/v2';
import { SUBMIT_WOO_COMMERCE } from '../../graphql/integrations';
import Button from 'components/Button';

const V1 = (props) => {
  const { register, storeUrl, isSubmitting, onCancel } = props;

  return (
    <div className="bg-white rounded p-5 shadow-sm overflow-hidden">
      <h6 className="mb-4">Connect your WooCommerce store</h6>
      <p className="mb-4 fs-08">Please enter the URL for your WooCommerce store (e.g. https://example.com)</p>
      <TextFieldV1
        type="text"
        name="storeUrl"
        placeholder="https://store.example.com"
        className="form-control mt-2"
        label="Your WooCommerce Store URL"
        readOnly={storeUrl}
        ref={register({ required: true })}
      />

      <div className="mt-4 overflow-hidden">
        <Button type="submit" className="btn btn-success float-right" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
        <Button type="button" className="btn btn-secondary mr-4 float-right" data-dismiss="modal" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const V2 = (props) => {
  const { register, isSubmitting, onCancel } = props;

  return (
    <div className="tw-rounded-md tw-bg-neutral-light">
      <div className="tw-flex tw-justify-between tw-px-8 tw-py-4 tw-border-b tw-border-neutral-grey-4">
        <p>Connect sales channel</p>
        <Close className="tw-cursor-pointer" onClick={onCancel} />
      </div>
      <div className="tw-px-8 tw-py-8">
        <img className="tw-w-40" src="https://loop-public.s3.ca-central-1.amazonaws.com/woocommerce-logo.png" />
        <h3 className="tw-mb-8">Connect your WooCommerce store</h3>
        <TextFieldV2
          type="text"
          name="storeUrl"
          placeholder="https://store.example.com"
          rootClass="tw-mt-2"
          label="Your WooCommerce Store URL"
          ref={register({ required: true })}
        />

        <SubmitButton className="tw-mt-12" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </SubmitButton>
      </div>
    </div>
  );
};

const ConnectWooCommerce = (props) => {
  const { onCancel, onboarding, preApproval, storeUrl, layout } = props;
  const form = useForm({
    defaultValues: { storeUrl: storeUrl },
  });
  const { register, handleSubmit } = form;
  const [submitWooCommerce, { loading: isSubmitting, error }] = useMutation(SUBMIT_WOO_COMMERCE);

  const onSubmit = async (data) => {
    ampTrackEvent('Clicked: Connect WooCommerce - Integrations');

    try {
      const response = await submitWooCommerce({ variables: { ...data, onboarding, preApproval } });
      window.location.href = _.get(response, 'data.submitWooCommerce.authUrl');
    } catch (err) {
      console.error(err);
    }
  };

  const Layout = preApproval || layout === 'V2' ? V2 : V1;

  return (
    <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout register={register} onCancel={onCancel} isSubmitting={isSubmitting} storeUrl={storeUrl} />
      </form>
    </FormProvider>
  );
};

export default ConnectWooCommerce;
