import { useState, useEffect, useMemo, useRef, useCallback } from 'react';

import { MFACodeInputProps } from '../MFACodeInput.types';
import { useToggle, useDeepEffect } from 'hooks';
import { keyCodesDictionary } from '../constants';

const { Backspace, ArrowLeft, ArrowDown, Enter, Escape } = keyCodesDictionary;

const useMFACodeInput = ({
  code,
  totalLength,
  onUpdate,
  onSubmit,
  isLoading,
  isError,
  errorMessage,
}: Omit<MFACodeInputProps, 'totalLength' | 'errorMessage'> & { totalLength: number; errorMessage: string }) => {
  const { isOpen: focused, open: onFocus, close: onBlur } = useToggle();

  const [error, setError] = useState(isError ? errorMessage : '');

  const inputRef = useRef<HTMLInputElement>(null);

  const currentCodeLength = code.length;

  const displayDigits = useMemo(() => new Array(totalLength).fill(0), [totalLength]);

  const isValid = useMemo(() => currentCodeLength === totalLength, [code, totalLength]);

  const selectedIndex = currentCodeLength < totalLength ? currentCodeLength : totalLength - 0.8;

  const handleFocus = () => {
    inputRef.current?.focus();
    onFocus();
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Backspace || e.key === ArrowLeft || e.key === ArrowDown) {
      const updatedCode = code.slice(0, currentCodeLength - 1);
      onUpdate(updatedCode);
      setError('');
    }
    if (e.key === Escape) {
      onUpdate('');
      setError('');
    }
    if (e.key === Enter) {
      handleSubmit();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const normalizedValue = value.replace(/\D/g, '');
    const updatedCode = currentCodeLength >= totalLength ? code : (code + normalizedValue).slice(0, totalLength);

    setError('');
    onUpdate(updatedCode);
  };

  const handleSubmit = useCallback(
    async (e?: React.FormEvent) => {
      e?.preventDefault();
      e?.stopPropagation();

      if (isLoading) return;

      if (!isValid) {
        setError(`Code must be ${totalLength} digits`);
        return;
      }

      setError('');

      await onSubmit(code);
    },
    [isLoading, isValid, code, onSubmit]
  );

  useDeepEffect(() => {
    if (isLoading || !!error || !isValid) return;

    handleSubmit();
  }, [isValid, isLoading, error, handleSubmit]);

  useEffect(() => {
    handleFocus();
  }, [error]);

  useEffect(() => {
    if (isError && isValid) setError(errorMessage);
  }, [isError, errorMessage, isValid]);

  return {
    inputRef,
    displayDigits,
    error,
    focused,
    selectedIndex,
    isValid,
    currentCodeLength,
    handleChange,
    handleClick: handleFocus,
    handleKeyUp,
    handleSubmit,
    handleFocus,
    handleBlur: onBlur,
  };
};

export default useMFACodeInput;
