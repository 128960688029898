import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';

import { uploadFile } from 'utility/activeStorage';
import { FileWithProgress, Blob } from '../ReceiptUpload.types';

const useReceiptUpload = ({ fieldName }: { fieldName: string }) => {
  const [file, setFile] = useState<FileWithProgress | null>(null);
  const [hasDroppedEmptyFile, setHasDroppedEmptyFile] = useState(false);
  const [hasDroppedMultipleFiles, setHasDroppedMultipleFiles] = useState(false);
  const { formState, setValue } = useFormContext();

  const isReceiptUploadInvalid = formState.errors[fieldName];

  const startFileUpload = async (file: FileWithProgress) => {
    try {
      const blob: Blob = await uploadFile(file);
      setValue('blobSignedId', blob.signed_id);
    } catch (err) {
      console.error(err);
    }
  };

  const onDrop = async (acceptedFiles: File[], fileRejections: { file: File }[]) => {
    if (fileRejections.length > 0) {
      setHasDroppedMultipleFiles(true);
    } else {
      setHasDroppedMultipleFiles(false);
      if (acceptedFiles[0].size > 0) {
        setFile(acceptedFiles[0]);
        setHasDroppedEmptyFile(false);
        await startFileUpload(acceptedFiles[0]);
      } else {
        setHasDroppedEmptyFile(true);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: 1 });

  const onChangeFile = () => {
    setFile(null);
  };

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    onChangeFile,
    onDrop,
    startFileUpload,
    isReceiptUploadInvalid,
    file,
    hasDroppedEmptyFile,
    hasDroppedMultipleFiles,
  };
};
export default useReceiptUpload;
