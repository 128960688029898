import React, { useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { useHistory } from 'react-router';

import Lighting from 'components/svg/Lighting';
import ArrowRight from 'components/svg/ArrowRight';
import Warning from 'components/svg/Warning';
import Button from 'components/Button';
import { userRoles, onboardingStatus } from 'constants/index';
import { AddIndividualOwnerModal, ConfirmationModal } from 'components/onboarding/BusinessContacts/modals';
import AddedOwners from 'components/onboarding/BusinessContacts/AddedOwners';
import AddCorporateOwnershipModal from './AddCorporateOwnershipModal';
import { onboardingList } from 'components/accountSummary/OnboardingSummary';
import { useToggle } from 'hooks';

const MINIMAL_OWNERSHIP_PERCENTAGE = 75;

const AddOwnerButton = ({ onClick, label }) => (
  <div onClick={onClick} className="tw-flex tw-mb-8 tw-cursor-pointer tw-items-center">
    <BsPlusCircle className="tw-text-primary-dark-green tw-mr-4" />
    <strong className="tw-text-primary-dark-green">{label}</strong>
  </div>
);

const InsufficientOwnership = () => (
  <div className="tw-rounded-md tw-bg-secondary-pastel-yellow-80 tw-p-4 tw-mt-4 tw-mb-8 tw-flex">
    <Warning className="tw-mr-2" />
    <div>
      <small className="tw-text-primary-dark-yellow">Total ownership should be over 75% to continue</small>
    </div>
  </div>
);

const DirectorWarning = () => (
  <div className="tw-rounded-md tw-bg-secondary-pastel-yellow-80 tw-p-4 tw-mt-4 tw-mb-8 tw-flex">
    <Warning className="tw-mr-2" />
    <div>
      <small className="tw-text-primary-dark-yellow">One of the contacts should be a director</small>
    </div>
  </div>
);

const ContactsAndShareholders = ({
  contacts,
  refetchContacts,
  isCorporation,
  isPartnership,
  hasCorporateOwnershipDocument,
  corporateOwnership,
}) => {
  const history = useHistory();
  const {
    isOpen: showCorporateOwnershipModal,
    open: onOpenCorporateOwnershipModal,
    close: onCloseCorporateOwnershipModal,
  } = useToggle();
  const [showIndividualOwnerModal, setShowIndividualOwnerModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onAddIndividualOwner = () => setShowIndividualOwnerModal(true);
  const onContinue = () => setShowConfirmationModal(true);
  const onConfirm = () => history.push(onboardingList.businessVerification.link);
  const onGoToPreviousStep = () => history.push(onboardingList[onboardingStatus.OWNER_PROFILE].link);
  const totalOwnership = [...contacts].reduce((acc, owner) => acc + owner.ownership, 0) + corporateOwnership;
  const hasMinimalOwnership = totalOwnership > MINIMAL_OWNERSHIP_PERCENTAGE;
  const isDirectorAvailable = contacts.some((contact) => contact.roles.includes(userRoles.DIRECTOR));

  return (
    <>
      <div className="tw-flex tw-flex-row tw-items-center tw-rounded-md tw-bg-neutral-grey-4 tw-py-8 tw-px-6 tw-mb-10 tw-mt-2">
        <Lighting className="tw-w-80 tw-mr-4" />
        <small className="tw-text-neutral-grey-1">
          In order to complete the account opening, we need to know the information about any other Directors of the
          business or individuals who own 25% or more of the company.&nbsp;
          <small className="tw-font-semibold">Obtaining this information is a regulatory requirement.</small>
        </small>
      </div>
      {isCorporation && (
        <AddCorporateOwnershipModal
          show={showCorporateOwnershipModal}
          onClose={onCloseCorporateOwnershipModal}
          hasCorporateOwnershipDocument={hasCorporateOwnershipDocument}
          corporateOwnership={corporateOwnership}
        />
      )}
      <AddIndividualOwnerModal
        onSuccess={refetchContacts}
        show={showIndividualOwnerModal}
        setShow={setShowIndividualOwnerModal}
        isPartnership={isPartnership}
      />
      <ConfirmationModal onSuccess={onConfirm} show={showConfirmationModal} setShow={setShowConfirmationModal} />
      <AddOwnerButton
        onClick={onAddIndividualOwner}
        label={isCorporation ? 'Add Individual Owner/Director' : 'Add New Partner'}
      />
      {isCorporation && !hasCorporateOwnershipDocument && (
        <AddOwnerButton onClick={onOpenCorporateOwnershipModal} label="Add Corporate Ownership" />
      )}
      <AddedOwners
        contacts={contacts}
        refetchContacts={refetchContacts}
        isPartnership={isPartnership}
        hasCorporateOwnershipDocument={hasCorporateOwnershipDocument}
        corporateOwnership={corporateOwnership}
        onOpenCorporateOwnershipModal={onOpenCorporateOwnershipModal}
      />
      <div className="tw-mt-16">
        {!isDirectorAvailable && isCorporation && <DirectorWarning />}
        {!hasMinimalOwnership && <InsufficientOwnership />}
        <div className="tw-flex tw-justify-between tw-mt-8">
          <Button
            type="button"
            secondary
            className="tw-w-max tw-bg-neutral-grey-5 tw-text-neutral-dark"
            onClick={onGoToPreviousStep}
          >
            Previous step
          </Button>
          <Button
            type="submit"
            isDisabled={!hasMinimalOwnership || (isCorporation && !isDirectorAvailable)}
            data-testid="continue-btn"
            className="tw-text-neutral-dark tw-py-2 tw-px-4 tw-rounded-md tw-text-center tw-w-max"
            primary={true}
            onClick={onContinue}
          >
            <div className="tw-flex tw-justify-center tw-items-center">
              <p className="tw-text-neutral-light tw-mr-2">Continue</p>
              <ArrowRight />
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default ContactsAndShareholders;
