import React, { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import cx from 'classnames';
import { IoCheckmark } from 'react-icons/io5';

import { FieldLabel, ErrorLabel } from '../components';
import { CheckboxGroupProps } from './CheckboxGroup.types';
import { DEFAULT_OPTIONS } from './constants';

import styles from './CheckboxGroup.module.scss';

const CheckboxGroup = (props: CheckboxGroupProps, ref: React.Ref<HTMLInputElement>) => {
  const {
    name,
    label,
    options = DEFAULT_OPTIONS,
    rootClass,
    labelClass,
    hideIcons = false,
    disabled,
    required,
    multiple,
    ...other
  } = props;

  const { errors } = useFormContext() || {};
  const error = get(errors, name);

  const type = multiple ? 'checkbox' : 'radio';

  return (
    <div className={cx(rootClass)}>
      {label && <FieldLabel label={label} required={required} className={labelClass} />}
      <ol className="tw-flex tw-gap-2">
        {options.map(({ value, label }, i) => {
          const id = `select-group-${name}-${i}`;

          return (
            <li className={styles.checkbox} key={id}>
              <input
                id={id}
                type={type}
                name={name}
                value={value}
                ref={ref}
                disabled={disabled}
                {...other}
                className="tw-sr-only"
              />
              <label
                className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-px-2 tw-p-1 tw-border tw-border-neutral-grey-3 tw-rounded-md tw-bg-neutral-light tw-cursor-pointer tw-transition-all tw-duration-200 tw-ease-in-out"
                htmlFor={id}
              >
                <span>{label}</span>
                {!hideIcons && <IoCheckmark size={18} className={styles.checkIcon} />}
              </label>
            </li>
          );
        })}
      </ol>
      {error && <ErrorLabel type={error.type} message={error.message} />}
    </div>
  );
};

export default forwardRef(CheckboxGroup);
