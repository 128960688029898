import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router';
import { get } from 'lodash';

import { GET_CURRENT_USER_CONTACT_FOR_PERSONA } from 'graphql/personaVerification';

const useIdentityVerification = () => {
  const {
    data,
    error,
    loading,
    refetch: refetchVerificationData,
  } = useQuery(GET_CURRENT_USER_CONTACT_FOR_PERSONA, {
    fetchPolicy: 'network-only',
  });

  const contactId = get(data, 'me.internalContact.id');
  const isVerificationCompleted = get(data, 'me.internalContact.thirdPartyVerificationComplete');

  const history = useHistory();

  const handleComplete = async () => {
    await refetchVerificationData();
    history.push('/onboarding/waiting-room');
  };

  const handleSkip = async () => history.push('/onboarding/dashboard/home');

  useEffect(() => {
    if (isVerificationCompleted) {
      handleComplete();
    }
  }, [isVerificationCompleted]);

  return {
    contactId,
    isVerificationCompleted,
    error,
    loading,
    handleComplete,
    handleSkip,
  };
};

export default useIdentityVerification;
