import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { RadioField, SubmitButton } from 'components/FormFields/v2';
import Modal from 'components/Modal/v2';
import Button from 'components/Button';
import ArrowRight from 'components/svg/ArrowRight';
import { InfoTooltipForModals } from 'components/InfoTooltip';
import { PolicyAgreement } from './components';
import { TOOLTIP_HIO, TOOLTIP_PEP, TOOLTIP_THIRD_PARTY_FOUNDS } from './LastStepModal.constants';

const LastStepModal = ({ show, setShow, onSuccess, loading }) => {
  const form = useForm();
  const { register, handleSubmit, watch } = form;

  const handleClose = () => setShow(false);

  const onConfirm = async ({ exposedInPolitics, fundsInThirdParty }) => {
    await onSuccess({
      exposedInPolitics: exposedInPolitics === 'true',
      fundsInThirdParty: fundsInThirdParty === 'true',
    });
    handleClose();
  };

  const isSubmitDisabled = !watch('exposedInPolitics') || !watch('fundsInThirdParty');

  return (
    <Modal show={show} onClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onConfirm)}>
          <div className="tw-px-8 tw-pt-8">
            <h3>Ready To Submit</h3>
          </div>
          <div className="tw-flex tw-flex-col tw-px-8 tw-pt-8 tw-space-y-8">
            <p>
              <b>Before you submit, please review these final questions:</b>
            </p>
            <div className="tw-space-y-2">
              <div className="tw-mb-8 tw-space-y-2">
                <label htmlFor="exposedInPolitics">
                  Are any people associated with the business a&nbsp;
                  <div className="tw-inline">
                    <InfoTooltipForModals message={TOOLTIP_PEP}>
                      <b>PEP</b>&nbsp;
                    </InfoTooltipForModals>
                  </div>
                  or&nbsp;
                  <div className="tw-inline">
                    <InfoTooltipForModals html={true} message={TOOLTIP_HIO}>
                      <b>HIO</b>
                    </InfoTooltipForModals>
                  </div>
                  ?
                </label>
                <RadioField
                  name="exposedInPolitics"
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  ref={register({ required: true })}
                />
              </div>
              <div className="tw-mb-8 tw-space-y-4">
                <label htmlFor="fundsInThirdParty">
                  Will this account be used on behalf of a person other than the business opening the account?
                  <InfoTooltipForModals message={TOOLTIP_THIRD_PARTY_FOUNDS} onlyIcon />
                </label>
                <RadioField
                  name="fundsInThirdParty"
                  options={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  ref={register({ required: true })}
                />
              </div>
            </div>
            <PolicyAgreement />
          </div>
          <div className="tw-w-full tw-flex tw-justify-between tw-border-t tw-border-neutral-grey-4 tw-mt-12 tw-px-8 tw-py-4">
            <Button
              type="button"
              secondary
              className="tw-w-max tw-bg-neutral-grey-5 tw-text-neutral-dark"
              onClick={handleClose}
            >
              Previous step
            </Button>
            <SubmitButton disabled={loading || isSubmitDisabled} className="tw-w-max lg:tw-w-32">
              {loading ? (
                'Submitting...'
              ) : (
                <div className="tw-flex tw-justify-center tw-items-center">
                  <p className="tw-text-neutral-light tw-mr-2">Submit</p>
                  <ArrowRight />
                </div>
              )}
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default LastStepModal;
