import React from 'react';

const InfoTipLine = ({ size = '39', color = '#92979C', ...other }) => {
  const width = size;
  const height = (7 * width) / 39;

  return (
    <svg width={width} height={height} viewBox="0 0 39 7" fill="none" {...other}>
      <line x1="3.5" y1="3.5" x2="38.5" y2="3.5" stroke={color} strokeLinecap="round" />
      <circle cx="3" cy="3.5" r="3" fill={color} />
    </svg>
  );
};

export default InfoTipLine;
