import React from 'react';
export const Checkbox = (props) => {
  const { label, name, className, checked, ...inputProps } = props;

  return (
    <div className={`${className} tw-flex tw-justify-start tw-items-center tw-outline-none`}>
      <input id={name} type="checkbox" name={name} checked={checked} {...inputProps} />
      {label && (
        <label className="tw-mx-2 tw-mb-0" htmlFor={name}>
          {label}
        </label>
      )}
    </div>
  );
};
