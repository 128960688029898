import React, { useMemo, useRef } from 'react';
import cx from 'classnames';

import ReactDatePicker from 'react-datepicker';
import { DatePickerProps } from './DatePicker.types';
import { BsCalendar4 } from 'react-icons/bs';
import { subYears } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = (props: DatePickerProps) => {
  const {
    label,
    name,
    rootClass = '',
    className,
    minDate,
    maxDate,
    inputFormat = 'dd/MM/yyyy',
    placeholder,
    value,
    onChange,
    portalId,
  } = props;
  const calendarRef = useRef<ReactDatePicker>(null);

  const handleDateChange = (date: Date) => {
    onChange(date);
  };

  const onShowCalendar = () => {
    if (calendarRef.current) {
      calendarRef.current.setOpen(true);
    }
  };

  const selectedDate = value;
  const maxDateLimit = useMemo(() => maxDate || new Date(), [maxDate]);
  const minDateLimit = useMemo(() => minDate || subYears(new Date(), 100), [minDate]);

  return (
    <div className={rootClass}>
      <label htmlFor={name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
        {label}
      </label>
      <div className="tw-relative">
        <ReactDatePicker
          ref={calendarRef}
          selected={selectedDate}
          portalId={portalId}
          dateFormat={inputFormat}
          showMonthDropdown
          showYearDropdown
          className={cx(
            'tw-block tw-px-3 tw-py-2 tw-rounded-md tw-w-max tw-bg-neutral-light tw-z-30 tw-border-2 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input',
            className
          )}
          dropdownMode="select"
          onChange={handleDateChange}
          showPopperArrow={false}
          popperClassName="tw-z-50"
          maxDate={maxDateLimit}
          minDate={minDateLimit}
          placeholderText={placeholder}
        />
        <div
          onClick={onShowCalendar}
          className="tw-cursor-pointer tw-absolute tw-inset-y-0 tw-right-0 tw-pb-1 tw-pr-3 tw-flex tw-items-center tw-text-sm"
        >
          <BsCalendar4 />
        </div>
      </div>
    </div>
  );
};
export default DatePicker;
