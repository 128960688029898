import { PayBalanceCurrency } from 'types/payments';

export enum GlobalLimitChartType {
  CAD = PayBalanceCurrency.CAD,
  USD = PayBalanceCurrency.USD,
  EUR = PayBalanceCurrency.EUR,
  GBP = PayBalanceCurrency.GBP,
}

export type ChartDatasetItem = {
  id: string;
  label: string;
  value: number;
  additionalValue?: number;
  currency: PayBalanceCurrency;
  labelColor?: string;
  bgColor: string;
};

export type ChartDataset = ChartDatasetItem[];

export type GlobalLimitChartProps = {
  dataset: ChartDataset;
  type: GlobalLimitChartType;
  isLoading: boolean;
};
