import React from 'react';
import { BiCheck } from 'react-icons/bi';

import { ONBOARDING_STEPS } from 'components/onboarding/constants';
import styles from './Progress.module.scss';
import SandClock from '../svg/SandClock';

const Progress = (props) => {
  const { currentStep = 1, hasOwnership } = props;

  const totalTime = 7;
  let timeLeft = totalTime - currentStep + 1;
  timeLeft = hasOwnership ? 1 : timeLeft;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div
          className={styles.item}
          data-ispaststep={currentStep > 1}
          data-iscurrentstep={currentStep === 1}
          data-isfuturestep={currentStep < 1}
        >
          <div className={styles.circle}>{currentStep > 1 ? <BiCheck /> : null}</div>
          <div className={styles.line}></div>
          <span className={styles.title + ' tw-hidden lg:tw-block'}>{ONBOARDING_STEPS.accountType.label}</span>
        </div>
        <div
          className={styles.item}
          data-ispaststep={currentStep > 2}
          data-iscurrentstep={currentStep === 2}
          data-isfuturestep={currentStep < 2}
        >
          <div className={styles.circle}>{currentStep > 2 ? <BiCheck /> : null}</div>
          <div className={styles.line}></div>
          <span className={styles.title + ' tw-hidden lg:tw-block'}>{ONBOARDING_STEPS.businessDetails.label}</span>
        </div>
        <div
          className={styles.item}
          data-ispaststep={currentStep > 3}
          data-iscurrentstep={currentStep === 3}
          data-isfuturestep={currentStep < 3}
        >
          <div className={styles.circle}>{currentStep > 3 ? <BiCheck /> : null}</div>
          <div className={styles.line}></div>
          <span className={styles.title + ' tw-hidden lg:tw-block'}>{ONBOARDING_STEPS.businessAddress.label}</span>
        </div>
        <div
          className={styles.item}
          data-ispaststep={currentStep > 4}
          data-iscurrentstep={currentStep === 4}
          data-isfuturestep={currentStep < 4}
        >
          <div className={styles.circle}>{currentStep > 4 ? <BiCheck /> : null}</div>
          <div className={styles.line}></div>
          <span className={styles.title + ' tw-hidden lg:tw-block'}>{ONBOARDING_STEPS.ownersProfile.label}</span>
        </div>
        <div
          className={styles.item}
          data-ispaststep={currentStep > 5}
          data-iscurrentstep={currentStep === 5}
          data-isfuturestep={currentStep < 5}
        >
          <div className={styles.circle}>{currentStep > 5 ? <BiCheck /> : null}</div>
          <div className={styles.line}></div>
          <span className={styles.title + ' tw-hidden lg:tw-block'}>{ONBOARDING_STEPS.businessContacts.label}</span>
        </div>
        <div
          className={styles.item}
          data-ispaststep={currentStep > 6}
          data-iscurrentstep={currentStep === 6}
          data-isfuturestep={currentStep < 6}
        >
          <div className={styles.circle}>{currentStep > 6 ? <BiCheck /> : null}</div>
          <div className={styles.line}></div>
          <span className={styles.title + ' tw-hidden lg:tw-block'}>{ONBOARDING_STEPS.businessVerification.label}</span>
        </div>
        <div
          className={styles.item}
          data-ispaststep={currentStep > 7}
          data-iscurrentstep={currentStep === 7}
          data-isfuturestep={currentStep < 7}
        >
          <div className={styles.circle}>{currentStep > 7 ? <BiCheck /> : null}</div>
          <span className={styles.title + ' tw-hidden lg:tw-block'}>{ONBOARDING_STEPS.idVerification.label}</span>
        </div>
      </div>
      <div className={styles.timeLeft + ' tw-text-primary-dark-green tw-text-sm tw-py-1 tw-px-4 tw-ml-8 lg:tw-ml-16'}>
        <SandClock size="18px" className="tw-text-primary-dark-green tw-mr-1" /> up to {timeLeft} min
        {timeLeft === 1 ? '' : 's'} left
      </div>
    </div>
  );
};

export default Progress;
