import { useContext } from 'react';

import { User } from 'types/user';
import { AuthContext } from 'context/Auth';

const useHeader = () => {
  const { me, meLoading } = useContext(AuthContext) as unknown as { me: User; meLoading: boolean };

  const { firstName } = me || {};

  return { meLoading, firstName };
};

export default useHeader;
