import React, { useState, useContext } from 'react';
import { get } from 'lodash';
import { BsChevronDown, BsPlusCircle } from 'react-icons/bs';
import { Collapse } from 'react-collapse';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import config from 'config';
import { AuthContext } from 'context/Auth';
import { balancesOrder, SCOPE, CREDIT_CARD_EMPTY_STATES, CREDIT_ASSESSMENT_STATUS } from 'constants/index';
import { formatMoney, formatMoneyV2 } from 'utility/currency';
import { formatDueDate } from 'utility/date';
import { encryptString } from 'utility/string';
import creditCardEmptyState from 'utility/creditCardEmptyState';
import { getAvailableAmount } from 'utility/creditCards';
import { DEFAULT_AMOUNT } from 'constants/defaultValues';
import CardOutline from 'components/svg/CardOutline';
import CurrencyFlag from 'components/svg/CurrencyFlag';
import CreditCardsList from 'components/CreditCardsList';
import { CreateCardContextProvider } from 'components/creditCards/createCardFlow/CreateCardContext';
import CreateCardModal from 'components/creditCards/createCardFlow/CreateCardModal';
import { CurrencyContextProvider, CurrencyContext } from 'components/creditCards//CurrencyContext';
import Button from 'components/Button';
import { Loaders } from 'components/cards/Loader';
import PermissionChecker from 'components/PermissionChecker';
import useGetProductState from 'hooks/useGetProductState';

const CreditCardsSummary = ({
  creditCards,
  creditCardIds,
  groupedCardsInfo,
  globalCreditLimit,
  hasAtLeastOneBankAccountVerified,
  connectedBankAccount,
  bankAccountsLoading,
  creditCardLoading,
  bankAccountVerified,
  hasLineOfCredit,
  allowCardCreation,
}) => {
  const { me, meLoading } = useContext(AuthContext);
  const externalAccountsConnected = get(me, 'account.externalAccountsConnected', false);
  const creditAssessment = get(me, 'account.creditAssessment', {});

  const [expanded, setIsExpanded] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('CAD');

  const hasCreditCards = creditCardIds.length > 0;
  const cardsDataAvailable =
    !creditCardLoading && !meLoading && hasCreditCards && groupedCardsInfo && creditCards.length > 0;
  const waitingForCreditReview =
    externalAccountsConnected && creditAssessment.status !== CREDIT_ASSESSMENT_STATUS.APPROVED;

  const [showCreateCardModal, setShowCreateCardModal] = useState(false);

  const closeCreateCardModal = () => setShowCreateCardModal(false);
  const openCreateCardModal = () => setShowCreateCardModal(true);

  const onToggleExpand = () => {
    if (hasCreditCards) setIsExpanded(!expanded);
  };

  return (
    <>
      <div
        className={cx(
          'tw-bg-neutral-light tw-border-neutral-grey-4 tw-shadow-md tw-w-full',
          expanded ? 'tw-rounded-t-md tw-border-t tw-border-l tw-border-r' : 'tw-border tw-rounded-md'
        )}
      >
        <CurrencyContextProvider>
          <Header
            onExpand={onToggleExpand}
            creditCardsCount={creditCardIds.length}
            cardsDataAvailable={cardsDataAvailable}
            isExpanded={expanded}
            groupedCardsInfo={groupedCardsInfo}
          />
        </CurrencyContextProvider>

        {creditCardLoading || bankAccountsLoading || meLoading ? (
          <div
            className="tw-bg-neutral-light tw-rounded-b-md tw-p-8 tw-flex tw-justify-center tw-flex-column"
            data-testid="load"
          >
            <Loaders.Spinner />
          </div>
        ) : (
          <>
            {hasCreditCards &&
              (cardsDataAvailable ? (
                <CardBalances
                  groupedCardsInfo={groupedCardsInfo}
                  onSelectCurrency={setSelectedCurrency}
                  selectedCurrency={selectedCurrency}
                  isExpanded={expanded}
                  waitingForCreditReview={waitingForCreditReview}
                />
              ) : (
                <ErrorState />
              ))}

            {!hasCreditCards && (
              <EmptyState
                onCreateCard={openCreateCardModal}
                creditCards={creditCards}
                globalCreditLimit={globalCreditLimit}
                hasAtLeastOneBankAccountVerified={hasAtLeastOneBankAccountVerified}
                connectedBankAccount={connectedBankAccount}
                bankAccountVerified={bankAccountVerified}
                hasLineOfCredit={hasLineOfCredit}
                allowCardCreation={allowCardCreation}
              />
            )}
          </>
        )}
      </div>
      {cardsDataAvailable && (
        <Collapse isOpened={expanded}>
          <div className="tw-w-full tw-px-4 tw-bg-neutral-light tw-border-l tw-border-r tw-border-neutral-grey-4 tw-rounded-b-md tw-shadow-md">
            <div className="tw-border-t tw-border-neutral-grey-4 tw-py-8">
              <CollapsedContent
                creditCards={creditCards}
                globalCreditLimit={globalCreditLimit}
                selectedCurrency={selectedCurrency}
                groupedCardsInfo={groupedCardsInfo}
                onCreateCard={openCreateCardModal}
                hasLineOfCredit={hasLineOfCredit}
                allowCardCreation={allowCardCreation}
                waitingForCreditReview={waitingForCreditReview}
              />
            </div>
          </div>
        </Collapse>
      )}
      <CreateCardContextProvider>
        <CreateCardModal
          show={showCreateCardModal}
          onClose={closeCreateCardModal}
          creditCards={creditCards}
          globalCreditLimit={globalCreditLimit}
        />
      </CreateCardContextProvider>
    </>
  );
};

const Header = ({ cardsDataAvailable, creditCardsCount, onExpand, isExpanded, groupedCardsInfo }) => {
  const { currency } = useContext(CurrencyContext);
  const { groupAvailableBalance: availableBalance, groupOverlimit } = groupedCardsInfo || {};
  const { isPreFunded } = useGetProductState();

  const amount = getAvailableAmount(groupOverlimit, availableBalance);

  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-border-b tw-border-neutral-grey-4">
      <div className="tw-flex">
        <div className="tw-flex tw-justify-center tw-items-center tw-bg-secondary-light-blue tw-rounded tw-p-0.5 tw-mr-2">
          <CardOutline className="tw-text-primary-blue tw-h-4" />
        </div>
        <strong>{`Cards (${creditCardsCount})`}</strong>
      </div>
      <div className="tw-flex tw-items-center">
        {cardsDataAvailable > 0 && (
          <>
            {!isPreFunded && (
              <div>
                <small className="tw-mr-1">
                  Available{' '}
                  <span className="tw-text-lg tw-mr-2 tw-font-semibold">
                    {amount}
                    <small className="tw-text-neutral-grey-2"> {currency}</small>
                  </span>
                </small>
              </div>
            )}
            <div
              onClick={onExpand}
              data-testid="cards-summary-expand-button"
              className={cx(
                'tw-rounded-md tw-flex tw-justify-center tw-items-center tw-bg-neutral-light tw-border tw-border-neutral-grey-4 tw-p-2 tw-cursor-pointer hover:tw-bg-primary-light-green',
                isExpanded ? 'tw-bg-neutral-grey-4' : 'tw-bg-neutral-light'
              )}
            >
              <BsChevronDown
                className={cx(
                  'tw-ease-in-out tw-duration-200 tw-transition-all',
                  `tw-transform tw-rotate-${isExpanded ? '180' : '0'}`
                )}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CollapsedContent = ({
  creditCards,
  selectedCurrency,
  groupedCardsInfo,
  onCreateCard,
  allowCardCreation,
  waitingForCreditReview,
}) => {
  const history = useHistory();

  const groupCreditLimit = groupedCardsInfo.groupCreditLimit;

  const navigateToCardDetail = (creditCard) =>
    history.push(`/dashboard/cards/list?selectedCardId=${encryptString(creditCard.id)}`);

  return (
    <>
      <CardBalanceSummary
        selectedBalance={
          groupedCardsInfo && groupedCardsInfo.groupBalances.find((b) => b.currency === selectedCurrency)
        }
        pendingTransactionsAmount={groupedCardsInfo.groupPendingBalances.find((b) => b.currency === selectedCurrency)}
        postedTransactionsAmount={groupedCardsInfo.groupPostedBalances.find((b) => b.currency === selectedCurrency)}
        waitingForCreditReview={waitingForCreditReview}
      />
      <div className="tw-my-8 tw-flex tw-items-center tw-justify-between">
        <small className="tw-text-neutral-grey-1">Your Cards</small>
        {allowCardCreation && (
          <PermissionChecker scope={SCOPE.manageCards}>
            <Button onClick={onCreateCard}>
              <div className="tw-flex tw-items-center">
                <BsPlusCircle size={18} className="tw-text-primary-dark-green tw-mr-4" />
                <small className="tw-text-primary-dark-green tw-font-semibold">Create Card</small>
              </div>
            </Button>
          </PermissionChecker>
        )}
      </div>
      <div className="tw-flex tw-items-center tw-my-8">
        <small className="tw-text-neutral-grey-1 tw-mr-4">Cards Limit</small>
        <small className="tw-text-neutral-grey-1">
          {formatMoneyV2(groupCreditLimit)} {groupCreditLimit.currency}
        </small>
      </div>
      <CreditCardsList
        creditCards={creditCards}
        selectedCurrency={selectedCurrency}
        onSelectCard={navigateToCardDetail}
      />
    </>
  );
};

const AmountDueAndPayment = ({ paymentDate }) => (
  <div className="tw-pb-0 tw-items-center">
    {/* TODO: Add back once backend is returning the correct amount for this field
    <small className="tw-mr-4 tw-text-neutral-grey-1">Amount Due</small>
    <small className="tw-mr-8 tw-text-neutral-grey-1">{formatMoney(amountDue)}</small> */}
    <small className="tw-text-neutral-grey-2">{formatDueDate(paymentDate)}</small>
  </div>
);

const CardBalances = ({ groupedCardsInfo, onSelectCurrency, selectedCurrency, isExpanded, waitingForCreditReview }) => {
  const totalAmountDue = groupedCardsInfo.amountDue || DEFAULT_AMOUNT;

  return (
    <div className={cx('tw-p-4', isExpanded && 'tw-pb-0')}>
      {isExpanded ? (
        <AmountDueAndPayment amountDue={totalAmountDue} paymentDate={groupedCardsInfo.paymentDate} />
      ) : (
        <>
          <small>Card Balances</small>
        </>
      )}
      <div className="tw-flex tw-mt-4">
        {balancesOrder.map((currency) => {
          const balance = groupedCardsInfo.groupBalances.find((cardBalance) => cardBalance.currency === currency) || {
            amount: 0,
            currency,
          };
          const onClick = () => onSelectCurrency(balance.currency);
          const isSelected = balance.currency === selectedCurrency;
          const isNotExpanded = !isExpanded && 'tw-text-neutral-grey-dark';
          const isExpandedAndSelected =
            isExpanded && isSelected ? 'tw-text-neutral-grey-dark' : 'tw-text-neutral-grey-2';

          return (
            <div key={balance.currency} className="tw-flex-grow">
              <div onClick={onClick} className={cx('tw-flex tw-items-center', isExpanded && 'tw-cursor-pointer')}>
                <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
                  <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                    <CurrencyFlag currency={balance.currency} size={14} />
                  </div>
                </div>
                <small className={isNotExpanded || isExpandedAndSelected}>{`${
                  waitingForCreditReview ? formatMoneyV2(balance, true) : formatMoneyV2(balance)
                } ${balance.currency}`}</small>
              </div>
              {isExpanded && isSelected && (
                <div className="tw-bg-primary-dark-green tw-w-5/6 tw-h-1 tw-rounded-t-md tw-mt-2" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CardBalanceSummary = ({
  selectedBalance,
  pendingTransactionsAmount,
  postedTransactionsAmount,
  waitingForCreditReview,
}) => {
  const { isPreFunded } = useGetProductState();

  return (
    <div className="tw-flex">
      <div className="tw-flex tw-flex-col tw-w-1/3 tw-mr-16">
        <div className="tw-flex tw-justify-between tw-items-center">
          <small className="tw-text-neutral-grey-2">Pending transactions</small>
          <small>{formatMoney(pendingTransactionsAmount)}</small>
        </div>
        <div className="tw-flex tw-justify-between tw-items-center tw-my-1">
          <small className="tw-text-neutral-grey-2">Posted transactions</small>
          <small>{`${
            isPreFunded || waitingForCreditReview
              ? formatMoney(postedTransactionsAmount, true)
              : formatMoney(postedTransactionsAmount)
          }`}</small>
        </div>
        <div className="tw-flex tw-justify-between tw-items-center">
          <small className="tw-text-neutral-grey-2">Current balance</small>
          <small>{`${
            isPreFunded || waitingForCreditReview ? formatMoney(selectedBalance, true) : formatMoney(selectedBalance)
          }`}</small>
        </div>
      </div>
    </div>
  );
};

const EmptyState = ({ onCreateCard, ...otherProps }) => {
  const creditCardState = creditCardEmptyState(otherProps);

  const history = useHistory();
  const navigateToConnectBankAccount = () => history.push('/dashboard/settings/bank-accounts-settings');

  return (
    <div data-testid="card-summary-message-container" className="tw-p-4 tw-flex tw-items-center">
      {creditCardState === CREDIT_CARD_EMPTY_STATES.isExistingLocUser && (
        <small className="tw-mr-1">
          Please email{' '}
          <a
            className="tw-font-semibold tw-text-primary-dark-green"
            target="_blank"
            href={`mailto:${config.contactEmail}`}
            rel="noreferrer"
            data-testid="contact-email"
          >
            {config.contactEmail}
          </a>{' '}
          and allocate some of your credit limit spend on cards.
        </small>
      )}

      {creditCardState === CREDIT_CARD_EMPTY_STATES.createCreditCard && (
        <PermissionChecker
          scope={SCOPE.manageCards}
          placeholder={<p>Contact the account administrator to create your first card</p>}
        >
          <Button onClick={onCreateCard}>
            <small className="tw-text-primary-dark-green tw-font-semibold">Create your first card</small>
          </Button>
        </PermissionChecker>
      )}

      {creditCardState === CREDIT_CARD_EMPTY_STATES.waitingBankAccountVerification && (
        <small>
          Your bank account is being verified. Please check your email for a message from Loop with further
          instructions. As soon as it’s verified, you'll be able to create your cards.
        </small>
      )}

      {creditCardState === CREDIT_CARD_EMPTY_STATES.connectBankAccount && (
        <>
          <small>Connect your bank account to create cards.</small>
          <PermissionChecker scope={SCOPE.manageBankAccounts}>
            <button onClick={navigateToConnectBankAccount}>
              <small className="tw-ml-1 tw-text-primary-dark-green tw-font-semibold">Connect Bank Account</small>
            </button>
          </PermissionChecker>
        </>
      )}

      {creditCardState === CREDIT_CARD_EMPTY_STATES.reviewInProgress && (
        <small>We are currently reviewing your account.</small>
      )}
    </div>
  );
};

const ErrorState = () => (
  <div className="tw-p-4 tw-flex">
    <small>
      Unfortunately, we weren't able to display your Loop Cards. Please try refreshing the page. If you continue to
      encounter this issue, please reach out to our team at{' '}
      <a
        className="tw-font-semibold tw-text-primary-dark-green"
        target="_blank"
        href={`mailto:${config.supportEmail}`}
        rel="noreferrer"
      >
        {config.supportEmail}
      </a>
    </small>
  </div>
);

export default CreditCardsSummary;
