import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const Payments2 = ({ size = '146', ...other }) => {
  const width = size;
  const height = (106 * width) / 146;

  return (
    <svg width={width} height={height} viewBox="0 0 114 106" fill="none" {...other}>
      <path
        d="M28.835 16.3497C28.9447 16.2871 29.0604 16.4215 28.9822 16.5206L22.3597 24.9098C22.3307 24.9466 22.3269 24.9973 22.3502 25.0379L27.6505 34.3192C27.7131 34.4288 27.5787 34.5445 27.4796 34.4663L19.0904 27.8439C19.0536 27.8148 19.0029 27.811 18.9623 27.8343L9.68104 33.1346C9.57138 33.1972 9.45567 33.0628 9.53391 32.9637L16.1563 24.5745C16.1854 24.5378 16.1891 24.4871 16.1659 24.4464L10.8656 15.1652C10.8029 15.0555 10.9374 14.9398 11.0365 15.018L19.4257 21.6404C19.4624 21.6695 19.5131 21.6733 19.5538 21.65L28.835 16.3497Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M103.645 76.7008C103.755 76.6382 103.871 76.7726 103.792 76.8717L96.6241 85.9524C96.5951 85.9892 96.5913 86.0399 96.6145 86.0806L102.352 96.1268C102.414 96.2365 102.28 96.3522 102.181 96.2739L93.1002 89.1056C93.0634 89.0766 93.0127 89.0728 92.972 89.0961L82.9258 94.8333C82.8161 94.8959 82.7004 94.7615 82.7787 94.6624L89.9469 85.5817C89.976 85.5449 89.9798 85.4942 89.9565 85.4536L84.2193 75.4073C84.1567 75.2976 84.2911 75.1819 84.3902 75.2602L93.4709 82.4285C93.5077 82.4575 93.5584 82.4613 93.599 82.4381L103.645 76.7008Z"
        fill="#8F5CCF"
      />
      <path
        d="M105.349 10.475C105.474 10.489 105.489 10.6657 105.367 10.6998L97.3248 12.9525C97.2797 12.9651 97.2468 13.0039 97.2417 13.0504L96.3202 21.3513C96.3062 21.4768 96.1294 21.4912 96.0954 21.3696L93.8427 13.3273C93.8301 13.2822 93.7913 13.2493 93.7448 13.2441L85.4439 12.3226C85.3184 12.3086 85.304 12.1319 85.4256 12.0978L93.4679 9.84512C93.513 9.83248 93.5459 9.79373 93.5511 9.74718L94.4726 1.44633C94.4865 1.32083 94.6633 1.30643 94.6974 1.42802L96.9501 9.47033C96.9627 9.51543 97.0014 9.54834 97.048 9.55351L105.349 10.475Z"
        fill="#8F5CCF"
      />
      <path
        d="M22.6619 59.7982L26.4057 64.1475L30.755 60.4037"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4812 63.1357L27.872 44.5468C28.15 40.8316 31.4924 38.0781 35.202 38.3556L36.4665 38.4502"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.7124 63.4626L76.9686 59.1133L72.6193 62.8571"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.8931 60.125L75.5023 78.7139C75.2243 82.4291 71.8818 85.1827 68.1723 84.9051L66.9077 84.8105"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_3845_121612)">
        <path
          d="M45.2562 47.2889L85.8061 50.3228C86.6675 50.3872 87.418 49.7412 87.4824 48.8798L89.116 27.0453C89.1805 26.1839 88.5345 25.4334 87.6731 25.369L47.1232 22.3351C46.2618 22.2707 45.5113 22.9167 45.4469 23.778L43.8133 45.6126C43.7488 46.474 44.3949 47.2245 45.2562 47.2889Z"
          fill="#FFE8C3"
        />
        <path
          d="M40.9076 51.033L81.4575 54.0669C82.3188 54.1314 83.0693 53.4853 83.1338 52.624L84.7674 30.7894C84.8318 29.9281 84.1858 29.1776 83.3245 29.1131L42.7746 26.0793C41.9132 26.0148 41.1627 26.6608 41.0983 27.5222L39.4646 49.3567C39.4002 50.2181 40.0462 50.9686 40.9076 51.033Z"
          stroke={primaryDarkGreenColor}
          strokeWidth="0.574331"
          strokeLinejoin="round"
        />
        <path
          d="M61.5328 47.871C65.8395 48.1932 69.5921 44.9631 69.9143 40.6563C70.2365 36.3496 67.0064 32.5971 62.6997 32.2748C58.3929 31.9526 54.6404 35.1827 54.3182 39.4895C53.996 43.7962 57.2261 47.5487 61.5328 47.871Z"
          stroke={primaryDarkGreenColor}
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M84.3008 37.028C82.2326 36.8732 80.3106 35.9033 78.9576 34.3314C77.6046 32.7596 76.9314 30.7147 77.0862 28.6465"
          stroke={primaryDarkGreenColor}
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.6317 33.76C41.6557 33.8366 42.6848 33.7108 43.6603 33.3897C44.6357 33.0686 45.5383 32.5585 46.3166 31.8885C47.0949 31.2186 47.7336 30.4019 48.1963 29.4851C48.659 28.5683 48.9365 27.5694 49.0131 26.5453"
          stroke={primaryDarkGreenColor}
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.6008 46.3852C81.5326 46.2305 79.4877 46.9036 77.9158 48.2566C76.344 49.6096 75.374 51.5316 75.2193 53.5998"
          stroke={primaryDarkGreenColor}
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.9314 43.1182C40.9555 43.1948 41.9544 43.4724 42.8712 43.935C43.788 44.3977 44.6046 45.0364 45.2746 45.8147C45.9445 46.593 46.4546 47.4956 46.7757 48.4711C47.0968 49.4465 47.2226 50.4756 47.146 51.4997"
          stroke={primaryDarkGreenColor}
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M23.0243 91.2498L63.5742 94.2837C64.4355 94.3481 65.186 93.7021 65.2505 92.8408L66.8841 71.0062C66.9485 70.1449 66.3025 69.3944 65.4412 69.3299L24.8913 66.296C24.0299 66.2316 23.2794 66.8776 23.215 67.739L21.5813 89.5735C21.5169 90.4349 22.1629 91.1854 23.0243 91.2498Z"
        fill="#D0E7DA"
      />
      <path
        d="M20.0416 94.2323L60.5915 97.2661C61.4529 97.3306 62.2034 96.6846 62.2678 95.8232L63.9014 73.9886C63.9659 73.1273 63.3199 72.3768 62.4585 72.3123L21.9086 69.2785C21.0472 69.214 20.2967 69.86 20.2323 70.7214L18.5987 92.556C18.5342 93.4173 19.1803 94.1678 20.0416 94.2323Z"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.574331"
        strokeLinejoin="round"
      />
      <path
        d="M42.1165 89.8221C46.4233 90.1444 50.1758 86.9143 50.498 82.6075C50.8203 78.3008 47.5902 74.5482 43.2834 74.226C38.9767 73.9038 35.2241 77.1339 34.9019 81.4406C34.5797 85.7474 37.8098 89.4999 42.1165 89.8221Z"
        fill="#EED9F7"
      />
      <path
        d="M40.6661 91.0702C44.9728 91.3924 48.7254 88.1623 49.0476 83.8556C49.3698 79.5488 46.1397 75.7963 41.833 75.4741C37.5262 75.1518 33.7737 78.3819 33.4515 82.6887C33.1293 86.9954 36.3594 90.748 40.6661 91.0702Z"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.4348 80.2272C61.3667 80.0725 59.4447 79.1025 58.0917 77.5306C56.7387 75.9588 56.0655 73.9139 56.2202 71.8457"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7657 76.9602C20.7897 77.0368 21.8189 76.911 22.7943 76.5898C23.7697 76.2687 24.6723 75.7587 25.4506 75.0887C26.2289 74.4188 26.8676 73.6021 27.3303 72.6853C27.793 71.7685 28.0706 70.7696 28.1472 69.7455"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.7348 89.5844C60.6667 89.4297 58.6217 90.1029 57.0499 91.4559C55.478 92.8089 54.5081 94.7309 54.3533 96.799"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0654 86.3174C20.0895 86.394 21.0884 86.6716 22.0052 87.1342C22.922 87.5969 23.7387 88.2356 24.4086 89.0139C25.0785 89.7922 25.5886 90.6949 25.9097 91.6703C26.2308 92.6457 26.3567 93.6748 26.2801 94.6989"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip0_3845_121612">
          <rect width="51.3997" height="31.1103" fill="white" transform="translate(39.8257 20.7295) rotate(4.27878)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Payments2;
