import { PaymentRequestStatus } from 'types/invoicing';

export const getRequestStatusDescription = (status: PaymentRequestStatus) => {
  switch (status) {
    case PaymentRequestStatus.pending:
      return 'Request for payment has been sent to your customer. We are waiting for them to accept the request.';
    case PaymentRequestStatus.scheduled:
      return 'Payment will be drawn from your customer’s account on the scheduled date. You should receive the funds in your Loop account 4-5 business days after.';
    case PaymentRequestStatus.rejected:
      return 'Payment request has been rejected by your customer. Please contact them and then initiate a new payment request with Loop.';
    case PaymentRequestStatus.completed:
      return 'Payment request has been completed successfully and the funds have been deposited into your Loop account.';
    case PaymentRequestStatus.settled:
      return 'Payment request has been completed successfully and the funds have been deposited into your Loop account.';
    case PaymentRequestStatus.failed:
      return 'Attempt to retrieve funds from your customer’s account on the scheduled date has failed. Please have your customer confirm funds availability and initiate a new payment request with Loop.';
    case PaymentRequestStatus.cancelled:
      return 'Payment request was cancelled. To process this payment, please initiate a new payment request with Loop.';
    case PaymentRequestStatus.expired:
      return 'Payment request has expired because your customer has not taken an action before the due date. Please contact them and then initiate a new payment request with Loop.';
    case PaymentRequestStatus.held:
    case PaymentRequestStatus.flagged_for_review:
    case PaymentRequestStatus.processing:
      return 'Payment withdrawal request has been initiated with your customer’s bank. If processed successfully, you can expect to have the payment in your account in the next 4-5 business days.';
    case PaymentRequestStatus.overdue:
      return 'Payment request has been marked as overdue because your customer has not taken an action before the due date. Please contact them to pay this request or it will expire in 60 days.';
    default:
      return '';
  }
};
