import React from 'react';
import cx from 'classnames';

import { ErrorState } from 'components/creditCards/components/CardBalances/components';
import { ChartTypeSwitcher, ChartSkeleton, GlobalLimitChart, GlobalLimitLegend, TotalCreditLimit } from './components';
import { useGlobalLimit } from './hooks';

const GlobalLimit = () => {
  const {
    chartType,
    changeChartType,
    dataset,
    isLoading,
    isError,
    needsConversion,
    isFXRatesLoading,
    totalCreditLimit,
    isOverlimitAvailable,
  } = useGlobalLimit();

  const pageTitle = 'Card Limit';

  if (isLoading) {
    return (
      <section className="tw-bg-neutral-light tw-shadow-default tw-rounded tw-py-6 tw-pb-4 tw-px-4">
        <div className="tw-flex tw-flex-nowrap tw-justify-between tw-gap-4 tw-mb-4">
          <h2>{pageTitle}</h2>
        </div>
        <ChartSkeleton />
      </section>
    );
  }

  if (isError) return <ErrorState title={pageTitle} showShadow />;

  return (
    <section className="tw-bg-neutral-light md:tw-shadow-default tw-rounded tw-py-6 tw-pb-4 tw-px-4">
      <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between tw-gap-4 tw-mb-4">
        <h2>{pageTitle}</h2>
        <ChartTypeSwitcher type={chartType} onChange={changeChartType} />
      </div>

      <div className="tw-flex tw-flex-row-reverse md:tw-flex-col tw-gap-4 md:tw-gap-0">
        <GlobalLimitChart dataset={dataset} type={chartType} isLoading={isFXRatesLoading} />
        <GlobalLimitLegend dataset={dataset} />
      </div>

      <div className={cx('tw-mt-2', isOverlimitAvailable ? 'md:tw-mt-4' : 'xl:tw--mt-5')}>
        <TotalCreditLimit limit={totalCreditLimit} isLoading={isFXRatesLoading} />
      </div>

      <div
        className={cx(
          'tw-text-xs tw-transition-opacity md:tw-text-right tw-mt-3',
          needsConversion ? 'tw-opacity-100' : 'tw-opacity-0'
        )}
      >
        *Non-CAD limits displayed are approximate conversions, and may change at time of purchase
      </div>
    </section>
  );
};

export default GlobalLimit;
