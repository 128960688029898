import { CurrentBillingCycleInfo, GroupOngoingAmount } from 'types/creditCard';
import { Statement, StatementStatus } from 'types/statement';
import { DEFAULT_BALANCES, minDuePaidStatuses, fullPaidStatuses, STATEMENT_BG_COLOR } from './constants';

export const parseCurrentBillingCycleInfo2Statement = (
  data: CurrentBillingCycleInfo
): Statement & { nextStatementGenerationDate?: Date } => {
  const {
    groupBalances,
    latestStatement,
    groupCurrentCycleSpentByCurrency,
    groupCurrentCyclePaymentsByCurrency,
    nextStatementGenerationDate,
  } = data;
  const { endingBalanceByCurrency, dueDate, startDate, endDate } = latestStatement || {};

  const balances =
    groupBalances?.reduce((acc, { currency, amount }) => {
      acc[currency] = {
        cents: amount,
        currency_iso: currency,
      };
      return acc;
    }, {} as GroupOngoingAmount) || DEFAULT_BALANCES;

  const statement = {
    id: 'current_cycle',
    title: 'Current Cycle',
    startDate: new Date(),
    endDate: new Date(),
    // we don't use value from fields above, keep them for compatibility
    payment: null,
    dueDate,
    previousStartDate: startDate,
    previousEndDate: endDate,
    nextStatementGenerationDate,
    previousBalanceByCurrency: endingBalanceByCurrency,
    endingBalanceByCurrency: balances,
    debits: groupCurrentCycleSpentByCurrency,
    credits: groupCurrentCyclePaymentsByCurrency,
  };

  return statement;
};

export const parseStatementStatus = ({
  status,
  isDueDatePassed,
}: {
  status?: StatementStatus;
  isDueDatePassed: boolean;
}) => {
  const isMinDuePaid = !!status && minDuePaidStatuses.includes(status);
  const isFullyPaid = !!status && fullPaidStatuses.includes(status);

  const result = { isFullyPaid, isMinDuePaid };

  if (isFullyPaid) return { ...result, label: 'Paid', className: STATEMENT_BG_COLOR.paid };

  if (isMinDuePaid) return { ...result, label: 'Min Due Paid', className: STATEMENT_BG_COLOR.min_due_paid };

  if (isDueDatePassed) return { ...result, label: 'Overdue', className: STATEMENT_BG_COLOR.overdue };

  return { ...result, label: 'Requires Payment', className: STATEMENT_BG_COLOR.requires_payment };
};
