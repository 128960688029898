import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import useIsMobile from 'hooks/useIsMobile';
import { AccountingServiceWalletTxTable } from 'components/accountingServiceTransactions/table/AccountingServiceWalletTxTable';
import { AccountingIntegrationCompanyPreferenceContext } from './context/AccountingIntegrationCompanyPreferenceContext';
import { MultiCurrencyDisabled } from 'components/settings/AccountingServices/MultiCurrencyDisabled';
import { Loaders } from 'components/cards/Loader';
import { validAccountingIntegrations } from 'components/settings/Integrations/constants';
import { GET_EXTERNAL_ACCOUNTS } from 'graphql/integrations';

const WalletTransactionsAccountingServicePush = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const [connectedAccountingService, setConnectedAccountingService] = useState(null);

  const { data: integrationsData } = useQuery(GET_EXTERNAL_ACCOUNTS);

  useEffect(() => {
    if (integrationsData) {
      const connectedAccountingService = integrationsData.externalAccounts.filter((ea) =>
        validAccountingIntegrations().includes(ea.name)
      )[0];
      setConnectedAccountingService(connectedAccountingService);
    }
  }, [integrationsData]);

  const {
    companyPreference: { homeCurrency, multiCurrencyEnabled },
    loading,
  } = useContext(AccountingIntegrationCompanyPreferenceContext);

  if (isMobile) history.push('/dashboard/home');
  if (loading) return <Loaders.Light />;

  return (
    <>
      {multiCurrencyEnabled && connectedAccountingService && (
        <AccountingServiceWalletTxTable accountName={connectedAccountingService?.name} />
      )}
      {!multiCurrencyEnabled && connectedAccountingService && (
        <MultiCurrencyDisabled accountName={connectedAccountingService?.name} homeCurrency={homeCurrency} />
      )}
    </>
  );
};

export default WalletTransactionsAccountingServicePush;
