import React, { useState, useContext, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useLazyQuery } from '@apollo/client';

import { GET_CREDIT_CARD_BY_ID } from 'graphql/cards';
import { CreditCardContext } from 'context/CreditCard';
import CreditCard from 'domain/creditCard';
import CreditCardDetail from 'components/creditCards//CreditCardDetail';
import { useDeepEffect } from 'hooks';

const CreditCardDetailContainer = ({ header }) => {
  const [creditCardDetails, setCreditCardDetails] = useState(null);

  const { creditCard } = useContext(CreditCardContext);

  const [getCreditCardById, { loading, data, error }] = useLazyQuery(GET_CREDIT_CARD_BY_ID, {
    fetchPolicy: 'network-only',
  });

  const fetchCardDetails = useCallback(() => {
    if (!creditCard?.id) return;

    getCreditCardById({ variables: { creditCardId: creditCard.id } });
  }, [creditCard?.id]);

  useEffect(() => {
    fetchCardDetails();
  }, [fetchCardDetails]);

  useDeepEffect(() => {
    const creditCardData = get(data, 'creditCard');
    if (!creditCardData) return;

    const creditCard = new CreditCard(creditCardData);
    setCreditCardDetails(creditCard);
  }, [data]);

  return (
    <CreditCardDetail
      creditCardDetails={creditCardDetails}
      loading={loading}
      error={error}
      refetchCreditCardDetails={fetchCardDetails}
      header={header}
    />
  );
};
export default CreditCardDetailContainer;
