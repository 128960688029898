import { useContext } from 'react';

import { formatDateTime } from 'utility/date';
import { CardAutopayContext } from 'components/payments/CardAutopay/context/CardAutopay';
import { BankAccount } from 'types/bankAccount';
import { Wallet } from 'types/wallet';

const usePADAgreementContent = () => {
  const today = new Date();
  const formattedTodaysDate = formatDateTime(today);

  const { fromAccountId, bankAccounts, wallets } = useContext(CardAutopayContext);
  const selectedFromAccount = [...wallets, ...bankAccounts].find(({ id }) => id === fromAccountId);

  const isBankAccount = selectedFromAccount?.__typename === 'BankAccount';
  const bankName = (selectedFromAccount as BankAccount)?.institutionName;
  const transitNumber = (selectedFromAccount as BankAccount)?.transitNumber;
  const institutionNumber = (selectedFromAccount as BankAccount)?.institutionNumber;
  const accountNumber = (selectedFromAccount as BankAccount)?.accountNumber;

  const displayName = (selectedFromAccount as Wallet)?.displayName;

  return { formattedTodaysDate, bankName, transitNumber, institutionNumber, accountNumber, isBankAccount, displayName };
};

export default usePADAgreementContent;
