import React, { useEffect } from 'react';

import { Progress } from 'components/UI';
import Layout from 'components/onboarding/Layout';
import GuaranteeForm from './GuaranteeForm';
import { Error, Loading } from './cards';
import { GUARANTOR_STEPS, GUARANTOR_STEP_URLS } from './constants';

import history from 'history.js';

const GuarantorConsent = (props) => {
  const { contact } = props;

  const onSubmitSuccess = () => history.push(`${GUARANTOR_STEP_URLS.idVerification}?sgid=${props.sgid}`);

  const granteeName = contact ? `${contact.firstName} ${contact.lastName}` : '';
  const companyName = contact && contact.account ? contact.account.name : '';

  const consentProvided = contact?.creditCheckAuthorizationProvided;

  useEffect(() => {
    if (consentProvided) {
      onSubmitSuccess();
    }
  }, [consentProvided]);

  return (
    <Layout>
      <div className="tw-max-w-screen-sm bg-white">
        <Progress currentStepIndex={0} stepLabelsAndIndexes={GUARANTOR_STEPS} classNames="tw-w-full tw-mb-16" />
        <h1 data-testid="consent-title" className="tw-mt-12 tw-mb-10">
          Consent for {companyName} to Open An Account
        </h1>

        <Content onSuccess={onSubmitSuccess} companyName={companyName} granteeName={granteeName} {...props} />
      </div>
    </Layout>
  );
};

const Content = (props) => {
  if (props.loading) return <Loading />;

  if (props.error) {
    return <Error message="Invalid link" />;
  }

  return (
    <GuaranteeForm
      onSuccess={props.onSuccess}
      sgid={props.sgid}
      contact={props.contact}
      granteeName={props.granteeName}
      companyName={props.companyName}
      error={props.submissionError}
    />
  );
};

export default GuarantorConsent;
