import { gql } from '@apollo/client';

export const GET_BORROWER_FLINKS_URL = gql`
  query FlinksConnectionUrl(
    $redirectUrl: String
    $country: String!
    $currency: String
    $connectionId: String
    $integrations: Boolean
  ) {
    flinksConnectionUrl(
      redirectUrl: $redirectUrl
      country: $country
      currency: $currency
      connectionId: $connectionId
      integrations: $integrations
    ) {
      url
    }
  }
`;

export const GET_PAYOR_FLINKS_URL = gql`
  query PayorFlinksUrl($redirectUrl: String!, $country: String!, $currency: String) {
    payorFlinksUrl(redirectUrl: $redirectUrl, country: $country, currency: $currency) {
      url
    }
  }
`;

export const CREATE_PAYOR_FLINKS_CONNECTION = gql`
  mutation CreatePayorFlinksConnection($accountId: String!, $loginId: String!, $country: String!) {
    createPayorFlinksConnection(accountId: $accountId, loginId: $loginId, country: $country)
  }
`;
