import React, { useState } from 'react';

export const NotificationAlertContext = React.createContext({ notification: null });

export const NotificationAlertContextProvider = (props) => {
  const [notification, setNotification] = useState(null);

  return (
    <NotificationAlertContext.Provider value={{ notification, setNotification }}>
      {props.children}
    </NotificationAlertContext.Provider>
  );
};
