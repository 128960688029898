import React, { useState } from 'react';

import { SCOPE } from 'constants/index';
import Button from 'components/Button';
import { CreditCardDetailOverlay, CreateCardOverlay } from './CreditCardOverlays';
import CardsList from './CardsList';
import PermissionChecker from 'components/PermissionChecker';
import { NOT_ALLOWED_CARD_CREATION_INFO } from 'constants/messages';
import { CreditCard } from 'types/creditCard';

type CardsListMobileContentProps = {
  creditCards: CreditCard[];
  refetchCreditCards: () => void;
  isMemberAndCanCreateCard: boolean;
};

const CardsListMobileContent = ({
  creditCards,
  refetchCreditCards,
  isMemberAndCanCreateCard,
}: CardsListMobileContentProps) => {
  const [showCreateCard, setShowCreateCard] = useState(false);
  const onCreateCard = () => setShowCreateCard(true);

  return (
    <div className="tw-px-4 tw-pb-8 tw-min-h-screen tw-bg-neutral-grey-3.5">
      <div className="tw-mt-4 tw-space-y-6">
        <div>
          <CardsList creditCards={creditCards} />
        </div>
        <PermissionChecker
          scope={SCOPE.manageCards}
          memberScope={isMemberAndCanCreateCard}
          // TODO: remove any after PermissionChecker will be written in TS
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          placeholder={NOT_ALLOWED_CARD_CREATION_INFO as any}
        >
          <Button primary onClick={onCreateCard} className="tw-w-full">
            Add new Card
          </Button>
        </PermissionChecker>
      </div>
      <CreditCardDetailOverlay refetchCreditCards={refetchCreditCards} />
      <CreateCardOverlay
        show={showCreateCard}
        setShow={setShowCreateCard}
        creditCards={creditCards}
        refetchCreditCards={refetchCreditCards}
      />
    </div>
  );
};

export default CardsListMobileContent;
