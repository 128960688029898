import React from 'react';

const ReimbursementRequestDetail = ({
  label,
  value,
  children,
}: {
  label: string;
  value?: string;
  children?: React.ReactNode;
}) => (
  <div className="tw-flex tw-flex-col md:tw-flex-row tw-text-sm md:tw-gap-2">
    <div className="tw-font-medium">{label}:</div>
    <span>{value}</span>
    {children}
  </div>
);

export default ReimbursementRequestDetail;
