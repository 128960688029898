import React from 'react';
import { AiOutlineMinusCircle, AiFillControl } from 'react-icons/ai';

import { TeamMember } from 'types/user';
import PermissionChecker from 'components/PermissionChecker';
import { SCOPE } from 'constants/index';
import Button from 'components/Button';
import { canBeDeactivated } from '../utils';
import { formatMoneyV2 } from 'utility/currency';
import InfoTooltip from 'components/InfoTooltip';

const UserDetails = ({
  member,
  isInActive,
  onShowPermissions,
  canPerformDeactivateOrRoleChange,
  onDeactivateMember,
  me,
  canViewRole,
}: {
  member: TeamMember;
  isInActive: boolean;
  onShowPermissions: () => void;
  canPerformDeactivateOrRoleChange: boolean;
  onDeactivateMember: () => void;
  me: TeamMember;
  canViewRole: boolean;
}) => {
  const canShowModifyButton = () => {
    if (member.roleValue === 'member') {
      return !isInActive;
    } else if (member.roleValue === 'bookkeeper') {
      return !isInActive && member?.id.includes('/Contact/');
    } else {
      return false;
    }
  };

  return (
    <div className="tw-flex tw-flex-col">
      {canViewRole && (
        <div className="tw-flex tw-flex-row tw-justify-between tw-py-2">
          <strong>User Role</strong>
          <div>{member.role}</div>
        </div>
      )}
      {Object.keys(member.rules).length !== 0 && ['member', 'bookkeeper'].includes(member.roleValue) && (
        <div>
          <div className="tw-flex tw-flex-row tw-justify-between tw-py-2">
            <div className="tw-flex">
              <strong>Approval Limit (per payment)</strong>
              <InfoTooltip message="This member can independently initiate payments up to the specified amount without requiring approvals. With the Approve Payments permission, they can also approve payments by others up to this amount." />
            </div>
            <div>{`${formatMoneyV2(member.rules.paymentApprovalAmount)} ${
              member.rules.paymentApprovalAmount.currency
            }`}</div>
          </div>
        </div>
      )}
      <div className="tw-my-6 tw-flex tw-justify-between tw-items-center">
        {canShowModifyButton() && (
          <PermissionChecker scope={SCOPE.manageMembers}>
            <Button primary type="button" onClick={onShowPermissions} className="tw-w-2/5 tw-bg-neutral-black">
              <div className="tw-flex tw-flex-row tw-items-center tw-content-center">
                <AiFillControl size={20} className="tw-mr-3" />
                <strong>Modify User</strong>
              </div>
            </Button>
          </PermissionChecker>
        )}
        {canBeDeactivated(me, member) && canPerformDeactivateOrRoleChange && (
          <PermissionChecker scope={SCOPE.manageMembers}>
            <Button secondary type="button" onClick={onDeactivateMember} className="tw-w-2/5">
              <div className="tw-flex tw-flex-row tw-items-center tw-content-center tw-text-semantic-warning">
                <AiOutlineMinusCircle size={20} className="tw-mr-3" />
                <strong>Deactivate User</strong>
              </div>
            </Button>
          </PermissionChecker>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
