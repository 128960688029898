import React from 'react';

import PaymentBoxes from '../PaymentBoxes';
import './payment.scss';

const Payments = () => (
  <div>
    <h2 className="tw-text-2xl tw-mb-4">Transfer Between Accounts</h2>
    <p className="tw-text-sm tw-text-neutral-grey-2">
      Transfer funds between Loop accounts and external bank accounts.
    </p>

    <div className="payment-cards-container tw-flex tw-overflow-x-scroll tw-gap-8 tw-mt-12 tw-mb-12">
      <PaymentBoxes />
    </div>
  </div>
);

export default Payments;
