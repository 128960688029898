import React from 'react';
import { IoCopyOutline } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';

import { ADDRESS_CATEGORY } from 'constants/index';
import { useToggle } from 'hooks';
import Pencil from 'components/svg/Pencil';
import CurrencyFlag from 'components/svg/CurrencyFlag';
import UpdateAddressModal from './UpdateAddressModal';
import Button from 'components/Button';

const { OA, DA, CA, DEFAULT } = ADDRESS_CATEGORY;

const SupportedAddress = ({ address, onCopy, creditCard }) => {
  const { isOpen: showUpdateAddressModal, open: openUpdateAddressModal, close: closeUpdateAddressModal } = useToggle();

  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-my-4" data-private>
      <div>{getAddressIcon(address.category)}</div>
      {address.street ? (
        <>
          <div className="tw-w-2/4 tw-flex tw-flex-col tw-text-neutral-grey-1">
            <small>{`${address.street}`}</small>
            {address.city && address.countrySubdivision && (
              <small>{`${address.city}, ${address.countrySubdivision}`}</small>
            )}
            <small>{`${address.postalCode} ${address.country}`}</small>
          </div>
          <div className="tw-flex tw-items-center tw-w-1/4">
            {address.category !== DA && address.category !== DEFAULT && (
              <Pencil size="14" className="tw-mx-2 tw-cursor-pointer" onClick={openUpdateAddressModal} />
            )}
            <IoCopyOutline className="tw-mx-2 tw-cursor-pointer" onClick={onCopy} data-tip="Copied!" />
          </div>
        </>
      ) : (
        <div className="tw-my-2">
          <Button className="tw-underline tw-text-neutral-grey-2" onClick={openUpdateAddressModal}>
            Add {address.category === OA || address.category === CA ? 'CA' : 'US'} Address
          </Button>
        </div>
      )}
      <ReactTooltip
        event="mouseup"
        eventOff="mouseout"
        textColor="var(--colors-natural-light)"
        backgroundColor="var(--colors-natural-grey-1)"
        delayHide={500}
      />
      <UpdateAddressModal
        show={showUpdateAddressModal}
        onClose={closeUpdateAddressModal}
        address={address}
        creditCard={creditCard}
      />
    </div>
  );
};

const getAddressIcon = (addressCategory) => {
  if (addressCategory === DA || addressCategory === DEFAULT) {
    return <MainAddressIcon />;
  } else {
    return <FlagIcon addressCategory={addressCategory} />;
  }
};

const MainAddressIcon = () => (
  <div className="tw-w-1/4 tw-border-2 tw-border-neutral-grey-3 tw-rounded-full tw-w-max tw-p-0.5 tw-mr-10">
    <div className="tw-w-4 tw-h-4 tw-flex tw-justify-center tw-items-center">
      <small className="tw-font-semibold">1</small>
    </div>
  </div>
);

const FlagIcon = ({ addressCategory }) => (
  <div className="tw-rounded-full tw-bg-neutral-grey-3 tw-p-0.5 tw-mr-2">
    <div className="tw-w-5 tw-h-5 tw-rounded-full tw-bg-neutral-light tw-p-0.5">
      <CurrencyFlag currency={addressCategory === OA || addressCategory === CA ? 'CAD' : 'USD'} size={16} />
    </div>
  </div>
);

export default SupportedAddress;
