import React, { useState, useContext } from 'react';

import { Loaders } from 'components/cards/Loader';
import { formatDateTime } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { AuthContext } from 'context/Auth';
import PaymentConfirmationModal from 'components/payments/PaymentConfirmationModal';
import PaymentStatusBadge from 'components/payments/PaymentStatusBadge';
import { useGetCurrencyLabel } from 'components/payments/PayPayee/components/hooks';

const ViewPaymentsContent = ({ loading, payeePayments }) => {
  const noPayeePayments = payeePayments.length === 0;
  // filter payee payments with approval status of null
  const postedPayments = payeePayments.filter(
    (payeePayment) => payeePayment?.approvalStatus === null || payeePayment?.approvalStatus === 'APPROVED'
  );
  const sortedPayeePayments = postedPayments
    ?.slice()
    .sort((a, b) => new Date(b.transactionTime) - new Date(a.transactionTime));

  if (loading) return <Loaders.Light />;

  if (noPayeePayments) return <p className="tw-mt-8">You do not have any payments at this time.</p>;

  return (
    <div className="tw-my-8">
      <PaymentsHistoryTable sortedPayeePayments={sortedPayeePayments} />
    </div>
  );
};

const PaymentsHistoryTable = ({ sortedPayeePayments }) => {
  const tableHeaders = ['To', 'From', 'Date Initiated', 'Amount', 'Method', 'Status', 'Confirmation'];

  return (
    <table className="tw-table tw-table-auto tw-w-full">
      <thead>
        <tr>
          {tableHeaders.map((tableHeader) => (
            <th key={tableHeader} className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">
              {tableHeader}
            </th>
          ))}
        </tr>
      </thead>
      {sortedPayeePayments.map((payeePayment) => (
        <TableRow key={payeePayment.payeePaymentId} payeePayment={payeePayment} />
      ))}
    </table>
  );
};

const TableRow = ({ payeePayment }) => {
  const { me } = useContext(AuthContext);
  if (!me) return null;

  const { account } = me;
  const { displayName, name } = account;
  const businessName = displayName || name;

  const payeeName = payeePayment?.payee?.record?.displayName;
  const fromAccount = payeePayment?.transaction ? payeePayment?.transaction?.from : businessName;
  const dateInitiated = formatDateTime(payeePayment?.transactionTime);
  const payment = payeePayment?.payment;
  const amount = formatMoneyV2({ amount: payment?.amount, currency: payment?.currency });
  const currency = payment?.currency;
  const { transferMethod } = payeePayment?.transaction || '';
  const paymentStatus = payeePayment?.status;

  const [showPaymentConfirmation, setShowPaymentConfirmation] = useState(false);
  const onOpen = () => setShowPaymentConfirmation(true);

  const currencyLabel = useGetCurrencyLabel(currency);

  return (
    <>
      <tr>
        <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{payeeName}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{fromAccount}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{dateInitiated}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{`${amount} ${currencyLabel}`}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{transferMethod}</td>
        <td className="tw-px-3 tw-py-4 tw-border-b tw-border-neutral-grey-5">
          <PaymentStatusBadge status={paymentStatus} />
        </td>
        <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">
          <button onClick={onOpen} className="tw-text-primary-blue tw-underline">
            View
          </button>
        </td>
      </tr>
      <PaymentConfirmationModal
        show={showPaymentConfirmation}
        setShow={setShowPaymentConfirmation}
        payeePayment={payeePayment}
        payment={`${amount} ${currencyLabel}`}
        transferMethod={transferMethod}
        payeeName={payeeName}
        paymentStatus={paymentStatus}
        dateInitiated={dateInitiated}
      />
    </>
  );
};

export default ViewPaymentsContent;
