import React from 'react';

import CardAndCoins from 'components/svg/CardAndCoins';

const ReviewInProgressBanner = ({ title }) => {
  return (
    <div className="tw-m-auto tw-bg-neutral-light tw-py-5 tw-px-6 tw-flex tw-flex-col tw-items-center tw-rounded-md">
      <div>
        <CardAndCoins height="300" width="100%" />
      </div>
      <div
        className="tw-flex tw-flex-col tw-items-center tw-text-center tw-my-5 tw-w-96"
        data-testid="reviewing-account-description"
      >
        <span className="tw-text-2xl tw-mt-4">{title}</span>
        <p className="tw-my-8">We are currently reviewing your account.</p>
      </div>
    </div>
  );
};

export default ReviewInProgressBanner;
