import React from 'react';
import { BiArrowBack } from 'react-icons/bi';

import Modal from 'components/Modal/v2';
import { LineOfCreditSummary } from 'components/lineOfCredit/DesktopLayout';
import MoveMoneyPromotionPrompt from 'components/lineOfCredit/MoveMoneyPromotionPrompt';
import RecentTransactionsMobile from 'components/RecentTransactionsMobile';
import DropdownSummary from '../desktop/LineOfCreditSummary';
import { SCOPE } from 'constants/index';
import PermissionChecker from 'components/PermissionChecker';

const LineOfCreditModal = ({ show, onClose, hasLineOfCredit, lineOfCredit, transactions, loadingTransactions }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-p-4 tw-pb-8 tw-min-h-screen tw-bg-neutral-grey-3.5">
        <BiArrowBack size={24} className="tw-cursor-pointer" onClick={onClose} />
        <h2 className="tw-mt-4 tw-mb-8">Line of Credit</h2>
        <LineOfCreditSummary lineOfCredit={lineOfCredit} />
        <DropdownSummary hasLineOfCredit={hasLineOfCredit} lineOfCredit={lineOfCredit} />
        <PermissionChecker scope={SCOPE.managePayments}>
          <MoveMoneyPromotionPrompt />
        </PermissionChecker>
        <RecentTransactionsMobile transactions={transactions} loadingTransactions={loadingTransactions} />
      </div>
    </Modal>
  );
};

export default LineOfCreditModal;
