export enum BadgeStatus {
  info = 'info',
  success = 'success',
  error = 'error',
  warning = 'warning',
  pending = 'pending',
  primary = 'primary',
}

export type BadgeProps = {
  title: string;
  status?: BadgeStatus;
  classNames?: string;
  icon?: React.ReactNode;
};
