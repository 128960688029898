import React from 'react';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

import { SCOPE } from 'constants/index';
import { formatMoneyV2 } from 'utility/currency';
import CardAndCalendar from 'components/svg/CardAndCalendar';
import PermissionChecker from 'components/PermissionChecker';

const DelinquentPaymentBanner = ({ primaryCard }) => {
  if (!primaryCard) return null;

  if (primaryCard.isDelinquent()) return <DelinquentCardBanner primaryCard={primaryCard} />;
  if (primaryCard.isPaymentDue() && primaryCard.isUnpaid()) {
    return <StatementPastDueBanner primaryCard={primaryCard} />;
  }

  return null;
};

const StatementPastDueBanner = ({ primaryCard }) => {
  const nonEmptyBalances = Object.values(primaryCard.groupOngoingAmountDueByCurrency).filter((b) => b.cents > 0);
  if (nonEmptyBalances.length === 0) return null;

  const formattedBalances = nonEmptyBalances
    .map((b) => `${b.currency_iso} ${formatMoneyV2({ amount: b.cents, currency: b.currency_iso }, true)}`)
    .join(', ');

  return (
    <BaseBanner
      title="Statement Balance Past Due"
      description={`The following balances are past due: ${formattedBalances}. You must pay your outstanding balances immediately to minimize interest and fees.`}
    />
  );
};

const DelinquentCardBanner = ({ primaryCard }) => {
  const nonEmptyBalances = Object.values(primaryCard.groupOngoingAmountDueByCurrency).filter((b) => b.cents > 0);
  if (nonEmptyBalances.length === 0) return null;

  const formattedBalances = nonEmptyBalances
    .map((b) => `${b.currency_iso} ${formatMoneyV2({ amount: b.cents, currency: b.currency_iso }, true)}`)
    .join(', ');

  return (
    <BaseBanner
      title="Card Account Delinquent"
      description={`The following balances are past due: ${formattedBalances}. Payment of past due amounts are required immediately to avoid interruption of your Loop services`}
    />
  );
};

const BaseBanner = ({ title, description }) => (
  <div className="tw-bg-secondary-light-yellow tw-flex tw-items-center tw-rounded-md tw-p-4 tw-space-x-4 tw-mb-4">
    <CardAndCalendar className="tw-flex tw-w-44 lg:tw-w-32" />
    <div className="tw-space-y-2">
      <small className="tw-font-semibold tw-block">{title}</small>
      <small className="tw-block">{description}</small>
      <PermissionChecker scope={SCOPE.managePayments}>
        <Link to="/dashboard/payments/pay-balance" className="tw-flex tw-items-center tw-space-x-2">
          <small className="tw-text-primary-dark-green tw-font-bold">Make a payment</small>
          <BsArrowRight className="tw-text-primary-dark-green" />
        </Link>
      </PermissionChecker>
    </div>
  </div>
);

export default DelinquentPaymentBanner;
