import React, { useState, createContext, useEffect } from 'react';
import { useQuery, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import qs from 'query-string';

import history from 'history.js';
import { GET_PAYOR_BANK_ACCOUNTS, GET_PAYOR_PAYMENT_REQUESTS } from 'graphql/invoicing';
import { PaymentRequestCurrency, PayorBankAccount, PayorPaymentRequest } from 'types/invoicing';
import { PaymentRequestsContextType } from '../PaymentRequests.types';

export const PaymentRequestsContext = createContext<PaymentRequestsContextType>({} as PaymentRequestsContextType);

export const PaymentRequestsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    data: bankAccountsData,
    loading: bankAccountsLoading,
    error: bankAccountsError,
  } = useQuery<{ payorBankAccounts: { connectionsReady: boolean; accounts: PayorBankAccount[] } }>(
    GET_PAYOR_BANK_ACCOUNTS
  );

  const {
    data: paymentRequestsData,
    loading: paymentRequestsLoading,
    error: paymentRequestsError,
  } = useQuery<{
    payorPaymentRequests: PayorPaymentRequest[];
  }>(GET_PAYOR_PAYMENT_REQUESTS);

  const bankAccountsConnectionsReady = get(bankAccountsData, 'payorBankAccounts.connectionsReady', false);
  const bankAccounts = get(bankAccountsData, 'payorBankAccounts.accounts', []);

  const paymentRequests = get(paymentRequestsData, 'payorPaymentRequests', []);

  const [error, setError] = useState<ApolloError | undefined>(undefined);

  const {
    failed: failedFlinksConnection,
    success: successFlinksConnection,
    warning: warningFlinksConnection,
  } = qs.parse(location.search);

  const isLoading = bankAccountsLoading || paymentRequestsLoading;
  const noBankAccounts = !bankAccounts.length;
  const isBankAccountsConnectionError = !bankAccountsConnectionsReady;
  const isCADBankAccountConnected = bankAccounts.some(({ currency }) => currency === PaymentRequestCurrency.CAD);
  const isUSDBankAccountConnected = bankAccounts.some(({ currency }) => currency === PaymentRequestCurrency.USD);

  const hasCADPaymentRequests = paymentRequests.some(({ amount }) => amount.currency === PaymentRequestCurrency.CAD);
  const hasUSDPaymentRequests = paymentRequests.some(({ amount }) => amount.currency === PaymentRequestCurrency.USD);

  // clear up url params after Flinks connection
  useEffect(() => {
    if (
      successFlinksConnection === 'Flinks' ||
      failedFlinksConnection === 'Flinks' ||
      warningFlinksConnection === 'Flinks'
    )
      history.replace(location.pathname);
  }, [successFlinksConnection, failedFlinksConnection, warningFlinksConnection]);

  useEffect(() => {
    if (!error?.message) return;

    toast.error(`Error: ${error?.message}`);
    console.error(error);
  }, [error]);

  useEffect(() => {
    if (bankAccountsError) setError(bankAccountsError);
    if (paymentRequestsError) setError(paymentRequestsError);
  }, [bankAccountsError, paymentRequestsError, setError]);

  return (
    <PaymentRequestsContext.Provider
      value={{
        bankAccounts,
        paymentRequests,
        error,
        setError,
        isLoading,
        noBankAccounts,
        isBankAccountsConnectionError,
        requiredCADBankAccount: hasCADPaymentRequests && !isCADBankAccountConnected,
        requiredUSDBankAccount: hasUSDPaymentRequests && !isUSDBankAccountConnected,
      }}
    >
      {children}
    </PaymentRequestsContext.Provider>
  );
};
