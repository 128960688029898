import React from 'react';

const ChartSkeleton = () => {
  const legendLength = 3;
  return (
    <div className="tw-flex tw-flex-col tw-w-full">
      <div className="tw-hidden md:tw-flex tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-w-full tw-h-10 tw-rounded tw-mb-4" />
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 md:tw-gap-8 tw-px-4">
        {Array(legendLength)
          .fill('')
          .map((_, i) => (
            <div key={i} className="tw-flex tw-items-center tw-gap-4 tw-h-8 md:tw-h-4 tw-animate-pulse">
              <div className="tw-bg-neutral-grey-4 tw-h-full tw-w-32 md:tw-w-16 tw-rounded" />
              <div className="tw-bg-neutral-grey-4 tw-h-full tw-w-full md:tw-w-32 tw-rounded" />
            </div>
          ))}
      </div>
      <div className="tw-flex tw-items-center tw-justify-start md:tw-justify-end tw-gap-4 tw-mt-6 xl:tw--mt-4 tw-mb-4">
        <div className="tw-flex tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-w-56 tw-h-5 tw-rounded" />
      </div>
    </div>
  );
};

export default ChartSkeleton;
