import React from 'react';
import cx from 'classnames';
import { ProgressBarProps } from './ProgressBar.types';
import { Step } from './components';

const ProgressBar = ({ currentStepIndex, stepLabelsAndIndexes, className, errorSteps }: ProgressBarProps) => {
  const stepsEntries = Object.entries(stepLabelsAndIndexes);

  return (
    <ol className={cx('tw-flex tw-gap-2 tw-items-start', className)}>
      {stepsEntries.map(([label, stepIndexes], index) => {
        const hasError = !!errorSteps?.includes(stepIndexes);

        return (
          <Step
            key={label}
            isCurrent={currentStepIndex === index}
            hasError={hasError}
            isPast={currentStepIndex > index}
          />
        );
      })}
    </ol>
  );
};

export default ProgressBar;
