import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { ampTrackEvent } from 'amplitude';
import { SingleNavItemProps } from './SingleNavItem.types';
import { FeaturedLabel, BetaLabel } from '..';

const SingleNavItem = ({ item, isSelected = false, isFeatured = false, isBeta }: SingleNavItemProps) => {
  // TODO: temporary code for getting feature statistics
  const handleSendStatistics = () => {
    if (item.label === 'Request Payments') ampTrackEvent('invoice: click on sidenav item');
  };

  return (
    <Link
      className={cx(
        'tw-flex tw-pl-12 tw-items-center hover:tw-text-primary-dark-green tw-py-3',
        isSelected && 'tw-text-primary-dark-green'
      )}
      to={item.route}
      onClick={handleSendStatistics}
    >
      <item.Icon className="tw-flex-shrink-0 tw-w-21-px" />
      <small className="tw-mx-4 tw-font-semibold">{item.label}</small>
      {isFeatured && <FeaturedLabel />}
      {isBeta && <BetaLabel />}
    </Link>
  );
};

export default SingleNavItem;
