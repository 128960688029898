import React from 'react';
import { BiCheck } from 'react-icons/bi';
import { addDays, format } from 'date-fns';

import FormFooter from 'components/payments/AddPayee/components/FormFooter/FormFooter';
import { ConfirmationDetailsProps } from './ConfirmationDetails.types';
import { REQUEST_EXPIRY_DAYS } from './constants';

const ConfirmationDetails = ({ supplierEmail, onClose }: ConfirmationDetailsProps) => {
  const expirationDate = addDays(new Date(), REQUEST_EXPIRY_DAYS);
  const formattedExpirationDate = format(expirationDate, 'MMMM do, yyyy');

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <div className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-secondary-light-green tw-mb-4">
          <div className="tw-mr-2 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
            <BiCheck className="tw-text-secondary-light-green" />
          </div>
          <div className="tw-text-sm tw-text-semantic-success">Payee payment information requested</div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-y-6 tw-py-2">
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-1">Email Sent To</div>
            <div className="tw-mt-1">{supplierEmail}</div>
          </div>
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-1">Email Link Valid Until</div>
            <div className="tw-mt-1">{formattedExpirationDate}</div>
          </div>
          <div>We have sent a request to your payee via email to obtain their bank details.</div>
          <div>
            You will receive a notification once your Payee submits their payment details. If the Payee has not
            submitted their details before the link expires, you will need to re-send the link or enter their
            information in manually.
          </div>
        </div>
      </div>
      <FormFooter submitLabel="Done" onSubmit={onClose} />
    </>
  );
};

export default ConfirmationDetails;
