import React from 'react';
import { useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { BsChevronLeft } from 'react-icons/bs';

import AddMoneyFlow from 'containers/AddMoney';
import DashboardLayout from 'components/dashboard/DashboardLayout';

const AddMoney = (props) => {
  const history = useHistory();
  const navigateBack = () => history.goBack();

  return (
    <DashboardLayout title="Payments">
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-bg-neutral-light tw-rounded-md lg:tw-rounded-none tw-mx-4 lg:tw-mx-0">
        <div className="tw-hidden lg:tw-flex">
          <div
            className="tw-flex tw-items-center tw-self-start tw-ml-6 tw-mr-10 tw-cursor-pointer"
            onClick={navigateBack}
          >
            <BsChevronLeft size={14} className="tw-mr-4" />
            <h5 className="tw-text-lg tw-font-semibold">Back</h5>
          </div>
        </div>
        <div className="tw-flex tw-flex-col lg:tw-hidden">
          <div className="tw-pt-4 tw-pl-8">
            <BiArrowBack size={24} onClick={navigateBack} className="tw-cursor-pointer tw-my-2" />
            <span className="tw-text-lg">Add Money</span>
          </div>
        </div>
        <div className="tw-py-4 lg:tw-py-0 lg:tw-w-1/2">
          <AddMoneyFlow {...props} finishButtonLabel="Back to Payments" onFinish={navigateBack} />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddMoney;
