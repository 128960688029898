import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ampTrackEvent } from 'amplitude';

import CheckmarksAndStars from 'components/svg/CheckmarksAndStars';
import CheckmarkAndPerson2 from 'components/svg/CheckmarkAndPerson2';
import NewCardDetailsStep from 'components/creditCards/createCardFlow/NewCardDetailsStep';
import { CreateCardContext } from 'components/creditCards/createCardFlow/CreateCardContext';
import { TeamContext } from 'components/settings/Team/TeamContext';
import Button from 'components/Button';
import { Loaders } from 'components/cards/Loader';
import AddTeamMemberStep from './AddTeamMemberStep';
import { CreditCardContext } from 'context/CreditCard';
import useIsAdmin from 'hooks/useIsAdmin';
import useIsMobile from 'hooks/useIsMobile';
import { useGetSubscription } from 'hooks';
import { Banner } from 'components/UI';
import { BannerSize } from 'components/UI/Banner';
import CreditCardPlus from 'components/svg/CreditCardPlus';

const CreateCardSuccess = ({ onClose, refetch, creditCards }) => {
  const [navigateBack, setNavigateBack] = useState(false);
  const [navigateToTeamStep, setNavigateToTeamStep] = useState(false);
  const { cardFormData, isFetchingCardData } = useContext(CreateCardContext);
  const { address } = cardFormData;
  const { members, loadingMembers, getMembers } = useContext(TeamContext);
  const hasTeamMembers = members?.length > 1;
  const { isAdmin } = useIsAdmin();
  // TODO: Allow user to redirect to Team Settings when mobile Team Settings component is implement
  const isMobile = useIsMobile();

  useEffect(() => {
    getMembers();
  }, []);

  const { isPaidSubscription } = useGetSubscription();

  const { creditCard } = useContext(CreditCardContext);

  const onNavigateBack = () => setNavigateBack(true);

  const history = useHistory();
  const navigateToTeamSettings = () => history.push('/dashboard/settings/team');
  const onNavigateToTeamStep = () => setNavigateToTeamStep(true);
  const navigateToSubscriptions = () => {
    ampTrackEvent('Subscriptions: Create Card');
    history.push('/dashboard/subscriptions');
  };

  const handleClick = () => {
    if (!hasTeamMembers && !address && !isMobile) return navigateToTeamSettings();
    if (hasTeamMembers && !address) return onNavigateToTeamStep();
    if (address || isMobile) return onClose();
  };

  const buttonText = () => {
    if (isAdmin && !hasTeamMembers && !address && !isMobile) return 'Add New User';
    if (isAdmin && hasTeamMembers && !address) return 'Share';
    if (address || !isAdmin || (isMobile && !hasTeamMembers)) return 'Done';
  };

  if (navigateBack) return <NewCardDetailsStep onClose={onClose} refetch={refetch} creditCards={creditCards} />;

  if (navigateToTeamStep)
    return (
      <AddTeamMemberStep loadingMembers={loadingMembers} members={members} creditCard={creditCard} onClose={onClose} />
    );

  if (loadingMembers)
    return (
      <div className="tw-flex tw-justify-center">
        <Loaders.Light />
      </div>
    );

  return (
    <>
      <div className="tw-px-8 lg:tw-px-10 tw-pb-2 tw-flex tw-flex-col tw-items-center">
        {address ? (
          <PhysicalCardSuccess
            address={address}
            isPaidSubscription={isPaidSubscription}
            isAdmin={isAdmin}
            navigateToSubscriptions={navigateToSubscriptions}
            creditCards={creditCards}
          />
        ) : (
          <VirtualCardSuccess
            hasTeamMembers={hasTeamMembers}
            isAdmin={isAdmin}
            isMobile={isMobile}
            isPaidSubscription={isPaidSubscription}
            navigateToSubscriptions={navigateToSubscriptions}
            creditCards={creditCards}
          />
        )}
      </div>
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-gap-y-4 lg:tw-gap-y-0 tw-border-t tw-border-neutral-grey-4 tw-px-8 lg:tw-px-8 tw-py-6">
        <Button isDisabled={isFetchingCardData} tertiary className="tw-pl-0" onClick={onNavigateBack}>
          Create another card
        </Button>
        <Button
          isDisabled={isFetchingCardData}
          className={`${
            address ? 'tw-full lg:tw-w-32' : 'tw-full lg:tw-w-max'
          } tw-bg-primary-dark-green tw-py-2 tw-px-4 tw-rounded-md tw-text-center tw-text-neutral-light`}
          onClick={handleClick}
        >
          {buttonText()}
        </Button>
      </div>
    </>
  );
};

const PhysicalCardSuccess = ({ address, isPaidSubscription, isAdmin, creditCards, navigateToSubscriptions }) => (
  <div className="tw-py-6">
    {!isPaidSubscription && isAdmin && creditCards.length > 15 && (
      <Banner
        icon={<CreditCardPlus width={40} />}
        iconAlignment="center"
        title="Create even more cards"
        message="Get more free cards for you to share with your team when you upgrade to Loop Plus."
        submitButtonLabel="Upgrade Now"
        size={BannerSize.sm}
        onSubmit={navigateToSubscriptions}
      />
    )}
    <div className="tw-flex tw-justify-center tw-mb-6">
      <CheckmarksAndStars />
    </div>
    <div className="tw-text-center">
      <span className="tw-text-xl tw-text-center tw-font-bold">Your Loop Card is on the way!</span>
    </div>
    <p className="tw-mt-6">Your card will be mailed to:</p>
    <p>{`${address.street}${address.unitNumber ? `, ${address.unitNumber}` : ''}`}</p>
    <p>{`${address.city}, ${address.countrySubdivision}`}</p>
    <p>{address.postalCode}</p>
    <p>Canada</p>
    <p className="tw-mt-8">
      Please allow <strong>up to 7 business days</strong> for your card to arrive.
    </p>
  </div>
);

export const VirtualCardSuccess = ({
  isAdmin,
  hasTeamMembers,
  isPaidSubscription,
  navigateToSubscriptions,
  creditCards,
}) => {
  const successText = () => {
    if (isAdmin && hasTeamMembers)
      return 'The card is ready to use. Share your new card details with other members of your team.';
    if (isAdmin && !hasTeamMembers)
      return 'The card is ready to use, but you don’t have any Team Members added to your Account. Add them now to start sharing your Loop Card with them.';
    if (!isAdmin) return 'The card is ready to use. Start spending today!';
  };

  return (
    <div className="tw-pt-6 tw-text-center">
      {!isPaidSubscription && isAdmin && creditCards.length > 15 && (
        <Banner
          icon={<CreditCardPlus width={40} />}
          iconAlignment="center"
          title="Create even more cards"
          message="Get more free cards for you to share with your team when you upgrade to Loop Plus."
          submitButtonLabel="Upgrade Now"
          size={BannerSize.sm}
          onSubmit={navigateToSubscriptions}
        />
      )}
      <div className="tw-flex tw-justify-center tw-mb-6">
        {isAdmin ? <CheckmarkAndPerson2 /> : <CheckmarksAndStars />}
      </div>
      <span className="tw-text-xl tw-font-bold">Your Loop Card Was Created Successfully!</span>
      <p className="tw-my-6">{successText()}</p>
    </div>
  );
};

export default CreateCardSuccess;
