import React, { useContext } from 'react';
import { CompletedRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';
import { useCompletedRow } from '../hooks';
import { ApprovalRequestStatusBadge, ApprovalTypeBadge, CurrencyBadge } from '../../UI';
import { ApprovalRequestsContext } from 'components/Approvals/ApprovalsContent/context';
import { APPROVAL_TYPE } from 'components/Approvals/constants';
import { BsXCircleFill } from 'react-icons/bs';
import { RiCheckboxCircleFill } from 'react-icons/ri';

const CompletedRow = ({ completedExpense }: { completedExpense: CompletedRequestDataType }) => {
  const {
    status,
    formattedRequesterName,
    receipt,
    merchantName,
    formattedAmount,
    currency,
    type,
    formattedDateApproved,
    formattedFromLabel,
  } = useCompletedRow({
    completedExpense,
  });
  const { setSelectedRequestId } = useContext(ApprovalRequestsContext);

  return (
    <tr
      className="tw-cursor-pointer"
      onClick={() => {
        setSelectedRequestId(completedExpense.id);
      }}
      key={completedExpense.id}
    >
      <td className="tw-w-24 tw-p-3 tw-font-normal tw-text-center">
        <ApprovalTypeBadge approvalType={type as APPROVAL_TYPE} />
      </td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{formattedRequesterName}</td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center"> {formattedFromLabel}</td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{merchantName}</td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{formattedAmount}</td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center ">
        <CurrencyBadge currency={currency} />
      </td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{formattedDateApproved}</td>
      <td className="tw-p-3">
        <div className="tw-flex tw-flex-row tw-justify-center">
          {receipt ? (
            <RiCheckboxCircleFill className="tw-text-semantic-success" size={16} />
          ) : (
            <BsXCircleFill className="tw-text-semantic-warning" size={16} />
          )}
        </div>
      </td>
      <td className="tw-p-3 tw-font-normal tw-text-center tw-flex tw-flex-row tw-justify-center tw-items-center">
        <ApprovalRequestStatusBadge status={status} />
      </td>
    </tr>
  );
};

export default CompletedRow;
