export const TOOLTIP_PEP =
  'A PEP is a family member, close associate or individual person who currently holds, or has held a specific office or position in or on behalf of a Canadian or foreign government. For foreign state positions, examples of a PEP would include heads of states, ambassadors, heads of agencies, judges and similar positions.';

export const TOOLTIP_HIO = `
  <div>
    An HIO is a family member, close associate or individual person who currently holds, or
    has held, the specific office or position of head of an international organization within
    the last 5 years. Also, the international organization that they head or were head of is
    either:
    <ul>
      <li>an international organization established by the governments of states; or</li>
      <li>an institution established by an international organization</li>
    </ul>
    The HIO is the primary person who leads the organization. For example, the HIO could be a
    president or CEO.
  </div>`;

export const TOOLTIP_THIRD_PARTY_FOUNDS =
  'Please answer yes if you are opening this account, receiving or sending money on behalf of a third-party';
