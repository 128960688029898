import { useContext, useState } from 'react';

import useIsAdmin from 'hooks/useIsAdmin';
import useIsMember from 'hooks/useIsMember';
import { CardExpenseTableProps } from '../CardExpenseTable.types';
import { ApprovalRequestsContext } from 'components/Approvals/ApprovalsContent/context/ApprovalRequests';
import { CardExpenseRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';

const useCardExpenseTable = ({ cardExpenseRequestList }: CardExpenseTableProps) => {
  const { selectedTab, payees, wallets, lineOfCredit, bankAccounts, setSelectedRequests, selectedRequests } =
    useContext(ApprovalRequestsContext);

  const { isAdmin } = useIsAdmin();
  const { isMember } = useIsMember();

  const noApprovalsAvailable = cardExpenseRequestList.length <= 0;
  const viewOnly = !isAdmin && !isMember;

  const toggleSelectedExpense = (expense: CardExpenseRequestDataType) => {
    setSelectedRequests((prevExpenses) =>
      prevExpenses.find((exp) => exp.transactionId === expense.transactionId)
        ? prevExpenses.filter((exp) => exp.transactionId !== expense.transactionId)
        : [...prevExpenses, expense]
    );
  };
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleToggleSelectAll = () => {
    if (!isAllSelected) {
      setSelectedRequests(cardExpenseRequestList);
    } else {
      setSelectedRequests([]);
    }
    setIsAllSelected(!isAllSelected);
  };

  return {
    isMember,
    isAdmin,
    noApprovalsAvailable,
    selectedRequests,
    setSelectedRequests,
    toggleSelectedExpense,
    selectedTab,
    payees,
    wallets,
    lineOfCredit,
    bankAccounts,
    viewOnly,
    isMultiSelectActive: selectedRequests.length > 0,
    handleToggleSelectAll,
    isAllSelected,
  };
};
export default useCardExpenseTable;
