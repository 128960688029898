// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React, { useState } from 'react';
import { IoDownloadOutline } from 'react-icons/io5';

import StatementsContainer from 'containers/creditCards/statements/Statements';
import useSetCardFromQueryString from './useSetCardFromQueryString';
import CreateCardModal from './createCardFlow/CreateCardModal';
import CardsList from './CardsList';
import { CurrencyContextProvider } from './CurrencyContext';
import { CreateCardContextProvider } from './createCardFlow/CreateCardContext';
import CardMaintenance from './CardMaintenance';
import CreditSummary from './CreditSummary';
import CreditCardDetailOverlay from './CreditCardDetailOverlay';
import RightHandSidebar from './sidebar/RightHandSidebar';
import useIsMember from 'hooks/useIsMember';

const CardsPage = ({
  creditCards,
  visibleCards,
  groupedCardsInfo,
  groupedCardsInfoLoading,
  globalCreditLimit,
  creditCardLoading,
  accountState,
}) => {
  const [showStatementsModal, setShowStatementsModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  useSetCardFromQueryString({ creditCards });
  const { isMember } = useIsMember();

  const onShowStatementsModal = () => setShowStatementsModal(true);
  const onCloseCreateModal = () => setShowCreateModal(false);
  const onShowCreateModal = () => setShowCreateModal(true);

  return (
    <CurrencyContextProvider>
      <CreateCardContextProvider>
        <div className="tw-hidden lg:tw-flex">
          <div className="tw-w-3/4 tw-pr-24 tw-pb-16">
            <CardMaintenance />
            {!isMember && (
              <div className="tw-flex tw-justify-between tw-mb-2" data-testid="download-statement-container">
                <h2>Cards</h2>
                <div onClick={onShowStatementsModal} className="tw-flex tw-items-center tw-cursor-pointer">
                  <IoDownloadOutline size={18} className="tw-text-primary-dark-green tw-mr-1" />
                  <small className="tw-text-primary-dark-green tw-font-semibold">Download Card Statements</small>
                </div>
              </div>
            )}
            {!isMember && (
              <CreditSummary
                groupedCardsInfoLoading={groupedCardsInfoLoading}
                groupCreditLimit={groupedCardsInfo && groupedCardsInfo.groupCreditLimit}
                groupAvailableBalance={groupedCardsInfo && groupedCardsInfo.groupAvailableBalance}
                billingCycle={groupedCardsInfo && groupedCardsInfo.billingCycle}
                groupOverlimit={groupedCardsInfo?.groupOverlimit}
              />
            )}
            <StatementsContainer show={showStatementsModal} setShow={setShowStatementsModal} type="Card" />
            <CreateCardModal
              show={showCreateModal}
              onClose={onCloseCreateModal}
              creditCards={creditCards}
              globalCreditLimit={globalCreditLimit}
            />
            <div className="tw-mt-8">
              <CardsList
                creditCardLoading={creditCardLoading}
                creditCards={visibleCards}
                errorFetchingCards={accountState === 'errorFetchingCards'}
                onCreateCard={onShowCreateModal}
              />
            </div>
          </div>
          <div className="tw-w-1/4">
            <RightHandSidebar groupedCardsInfo={groupedCardsInfo} loading={groupedCardsInfoLoading} />
          </div>

          <CreditCardDetailOverlay />
        </div>
      </CreateCardContextProvider>
    </CurrencyContextProvider>
  );
};

export default CardsPage;
