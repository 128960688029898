import React, { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import Modal from 'components/Modal/v2/index';
import Close from 'components/svg/Close';
import Button from 'components/Button';

const CreditReportConsentModal = ({ show, onClose }) => {
  const { me } = useContext(AuthContext);
  if (!me) return null;

  const {
    firstName,
    lastName,
    account: { name },
  } = me;
  const fullName = `${firstName} ${lastName}`;
  const accountName = `${name}`;

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-py-6">
        <div className="tw-px-8 tw-flex tw-justify-between tw-pb-4 tw-border-b tw-border-neutral-grey-4">
          <h3>Credit Report Consent Terms</h3>
          <Close className="tw-cursor-pointer" onClick={onClose} />
        </div>
        <div className="tw-px-8 tw-py-8">
          <p>
            {`I, ${fullName}, on my behalf and on behalf of ${accountName} (the “Business”) consent to Loop and/or
          its third-party agents, business partners or service providers ("Third Parties”) obtaining a credit report
          from a credit or consumer reporting agency (a “Credit Agency”) about me or the Business, for the purpose of
          determining my and the Business’ creditworthiness.`}
          </p>
          <p>
            {`I acknowledge that this consent is effective immediately, and I understand that personal, financial or other
          information relating to me or the Business (the “Information”) will be disclosed to Loop and/or its Third
          Parties for the purposes of a) determining the eligibility of ${accountName} for financial products and if approved,
          to provide and administer such financial products; b) reviewing and determining my ongoing creditworthiness
          in light of my guarantee of the Business’ obligations;, c) Loop’s general risk management and assessment
          processes, and d) Loop’s compliance with laws.`}
          </p>
          <p>I authorize any Credit Agency to provide the Information to Loop and its Third Parties.</p>
        </div>
        <div className="tw-pb-8 tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-4">
          <Button primary className="float-right tw-w-1/3" onClick={onClose}>
            Back
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreditReportConsentModal;
