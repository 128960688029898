import React, { useContext } from 'react';
import { CardExpenseRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';
import { useCardExpenseRow } from '../hooks';
import { ApprovalRequestStatusBadge, ApprovalTypeBadge, CurrencyBadge } from '../../UI';
import { APPROVAL_TYPE } from 'components/Approvals/constants';
import { ApprovalRequestsContext } from 'components/Approvals/ApprovalsContent/context';
import { Currencies } from 'constants/currencies';

const CardExpenseRow = ({
  cardExpense,
  selectedExpenses,
  toggleSelectedExpense,
}: {
  cardExpense: CardExpenseRequestDataType;
  selectedExpenses: CardExpenseRequestDataType[];
  toggleSelectedExpense: (cardExpense: CardExpenseRequestDataType) => void;
}) => {
  const {
    formattedRequesterName,
    merchantName,
    formattedAmount,
    formattedTransactionDate,
    receipt,
    currency,
    handleStopClickRow,
    isAdmin,
    transactionId,
    status,
    fromLabel,
    handleApprove,
    handleDecline,
    isUpdatingCardExpense,
  } = useCardExpenseRow({
    cardExpense,
  });
  const { setSelectedRequestId } = useContext(ApprovalRequestsContext);

  return (
    <tr className="tw-cursor-pointer" onClick={() => setSelectedRequestId(transactionId)} key={transactionId}>
      <td className="tw-w-24 tw-p-3 tw-font-normal tw-text-center">
        <ApprovalTypeBadge approvalType={APPROVAL_TYPE.cardExpense} />
      </td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{formattedRequesterName}</td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{fromLabel}</td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{merchantName}</td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{formattedAmount}</td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center ">
        <CurrencyBadge currency={currency as Currencies} />
      </td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center" onClick={handleStopClickRow}>
        {receipt ? (
          <a href={receipt} target="_blank" rel="noreferrer">
            <span className="tw-font-semibold tw-text-primary-dark-green hover:tw-underline">View</span>
          </a>
        ) : (
          '-'
        )}
      </td>
      <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center">{formattedTransactionDate}</td>
      {!isAdmin && (
        <td className="tw-p-3 tw-font-normal tw-text-center tw-flex tw-flex-row tw-justify-center tw-items-center">
          <ApprovalRequestStatusBadge status={status} />
        </td>
      )}
      {isAdmin && (
        <>
          <td className="tw-p-3 tw-text-sm tw-font-normal tw-text-center" onClick={handleStopClickRow}>
            {selectedExpenses.length <= 0 ? (
              <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-1">
                <button
                  className="tw-bg-secondary-light-green tw-py-0.5 tw-px-2 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-w-16"
                  onClick={handleApprove}
                  disabled={isUpdatingCardExpense}
                >
                  Approve
                </button>
                <button
                  className="tw-bg-secondary-pastel-red-50 tw-py-0.5 tw-px-2 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-w-16"
                  onClick={handleDecline}
                  disabled={isUpdatingCardExpense}
                >
                  Decline
                </button>
              </div>
            ) : (
              <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-1">
                <div className="tw-bg-neutral-grey-4 tw-py-0.5 tw-px-2 tw-rounded-md tw-h-6 tw-w-16" />
                <div className="tw-bg-neutral-grey-4 tw-py-0.5 tw-px-2 tw-rounded-md tw-h-6 tw-w-16" />
              </div>
            )}
          </td>
          <td onClick={handleStopClickRow}>
            <input
              type="checkbox"
              checked={selectedExpenses.find((exp) => exp.transactionId === cardExpense.transactionId) !== undefined}
              onChange={() => toggleSelectedExpense(cardExpense)}
            />
          </td>
        </>
      )}
    </tr>
  );
};

export default CardExpenseRow;
