import React from 'react';

type CardPendingProps = {
  width?: number;
  height?: number;
};

const CardPending = ({ width = 25, height = 20 }: CardPendingProps) => (
  <svg width={width} height={height} viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.125 0.5H3.875C2.42525 0.5 1.25 1.67525 1.25 3.125V12.875C1.25 14.3247 2.42525 15.5 3.875 15.5H18.125C19.5747 15.5 20.75 14.3247 20.75 12.875V3.125C20.75 1.67525 19.5747 0.5 18.125 0.5Z"
      fill="white"
      stroke="#26262C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.25 5H20.75" stroke="#26262C" strokeLinejoin="round" />
    <path d="M7.25 10.0625H5V11H7.25V10.0625Z" stroke="#26262C" strokeLinejoin="round" />
    <path
      d="M19.25 9.25C16.4896 9.25 14.25 11.4896 14.25 14.25C14.25 17.0104 16.4896 19.25 19.25 19.25C22.0104 19.25 24.25 17.0104 24.25 14.25C24.25 11.4896 22.0104 9.25 19.25 9.25Z"
      fill="#59B0CB"
    />
    <path
      d="M19.25 9.25C16.4896 9.25 14.25 11.4896 14.25 14.25C14.25 17.0104 16.4896 19.25 19.25 19.25C22.0104 19.25 24.25 17.0104 24.25 14.25C24.25 11.4896 22.0104 9.25 19.25 9.25Z"
      stroke="white"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path
      d="M19.25 9.25C16.4896 9.25 14.25 11.4896 14.25 14.25C14.25 17.0104 16.4896 19.25 19.25 19.25C22.0104 19.25 24.25 17.0104 24.25 14.25C24.25 11.4896 22.0104 9.25 19.25 9.25Z"
      stroke="black"
      strokeWidth="0.5"
      strokeMiterlimit="10"
    />
    <path d="M19.25 12.0276V15.0276H22.25" fill="#59B0CB" />
    <path
      d="M19.25 12.0276V15.0276H22.25"
      stroke="white"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.25 12.0276V15.0276H22.25"
      stroke="black"
      strokeWidth="0.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CardPending;
