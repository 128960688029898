import { useContext } from 'react';
import { useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

import { GET_PAYORS, ADD_PAYOR } from 'graphql/invoicing';
import { AddPayorContext } from 'components/Invoices/contexts/AddPayorContext';
import { ReviewProps } from '../Review.types';

const useReview = ({ onFinish, onPrevStep }: ReviewProps) => {
  const { payorInfo, setError } = useContext(AddPayorContext);

  const [addPayor, { loading }] = useMutation(ADD_PAYOR, {
    refetchQueries: [{ query: GET_PAYORS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async () => {
    try {
      await addPayor({ variables: { ...payorInfo } });
      onFinish();
      toast.success('Payor was added successfully');
    } catch (err) {
      console.error(err);
      toast.error('Error adding Payor - please try again');
      setError(err as ApolloError);
      onPrevStep();
    }
  };

  return { payorInfo, handleSubmit, loading };
};

export default useReview;
