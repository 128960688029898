import React from 'react';
import { IoSyncCircleOutline } from 'react-icons/io5';

import { formatDate } from 'utility/date';
import { BalanceCycleProps } from './BalanceCycle.types';

const BalanceCycle = ({ billingCycle }: BalanceCycleProps) => {
  if (!billingCycle) return null;

  const { startDate, endDate } = billingCycle;

  const startDateYear = formatDate(startDate, 'yyyy');
  const endDateYear = formatDate(endDate, 'yyyy');
  const startDataFormat = startDateYear === endDateYear ? 'MMMM d' : 'MMMM d, yyyy';

  const formattedStartDate = formatDate(startDate, startDataFormat);
  const formattedEndDate = formatDate(endDate, 'MMMM d, yyyy');

  return (
    <div className="tw-hidden lg:tw-flex tw-items-center tw-justify-between tw-gap-6 tw-px-2 tw-bg-secondary-pastel-green-100 tw-border tw-border-neutral-grey-4 tw-rounded-md tw-cursor-default tw-text-sm tw-h-6">
      <div className="tw-flex tw-gap-1 tw-items-center">
        <IoSyncCircleOutline className="tw-text-neutral-grey-2" size={18} />
        Current Cycle
      </div>
      <div className="tw-text-primary-dark-green">
        {formattedStartDate} - {formattedEndDate}
      </div>
    </div>
  );
};

export default BalanceCycle;
