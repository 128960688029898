import React from 'react';

import { EXTERNAL_TRANSACTION_CLEARING_MESSAGE } from 'constants/index';
import Banner, { BANNER_TYPES } from 'components/Banner';
import { FormFooter } from 'components/UI';
import { useComplete } from './hooks';
import { CompleteProps } from './Complete.types';

const Complete = ({ onResetSteps }: CompleteProps) => {
  const {
    formattedAmount,
    isFromBankAccount,
    methodName,
    fromAccountName,
    toAccountName,
    formattedInitiatedAt,
    transactionGroupId,
    isSuccessful,
  } = useComplete();

  const statusType = isSuccessful ? BANNER_TYPES.success : BANNER_TYPES.error;
  const statusMessage = isSuccessful
    ? (isFromBankAccount && EXTERNAL_TRANSACTION_CLEARING_MESSAGE) || 'Your transfer has been completed.'
    : 'Your transfer request could not be completed. Please try again or contact support.';

  return (
    <>
      <Banner isShowIcon type={statusType} message={statusMessage} className="tw-mb-12" />

      <div className="tw-flex tw-gap-8">
        <div className="tw-flex tw-flex-col tw-space-y-8">
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-1">Method</div>
            <p className="tw-mt-1">{methodName}</p>
          </div>
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-1">From Account</div>
            <p className="tw-mt-1">{fromAccountName}</p>
          </div>
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-1">To Account</div>
            <p className="tw-mt-1">{toAccountName}</p>
          </div>
          <div>
            <div className="tw-text-sm tw-text-neutral-grey-1">Amount</div>
            <p className="tw-mt-1">{formattedAmount}</p>
          </div>
          {transactionGroupId && (
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">Reference ID</div>
              <p className="tw-mt-1">{transactionGroupId}</p>
            </div>
          )}

          {formattedInitiatedAt && (
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">Initiated at</div>
              <p className="tw-mt-1">{formattedInitiatedAt}</p>
            </div>
          )}
        </div>
      </div>

      <FormFooter submitButtonLabel="Close" onSubmit={onResetSteps} hideSubmitButtonIcon />
    </>
  );
};

export default Complete;
