export const bsElementStyle = {
  base: {
    color: '#ffffff',
    fontWeight: 700,
    fontFamily: '"Inter"',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    '::placeholder': {
      color: '#ffffff',
    },
    backgroundColor: 'ffffff',
  },
};

export const bsCardNumberElementStyle = {
  ...bsElementStyle,
  base: {
    ...bsElementStyle.base,
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
};

export const bsCopyIconStyles = {
  color: '#ffffff',
  size: '1.25rem',
};
