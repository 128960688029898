import React from 'react';
import QboTxTableHeader from './AccountingServiceTxTableHeader';
import WalletTransactionHeader from './WalletTransactionHeader';

const QboTxSkeleton = () =>
  Array(8)
    .fill()
    .map((_, i) => (
      <div key={i * 10} className="tw-p-3 tw-w-full">
        <div className="tw-flex tw-w-full tw-animate-pulse tw-bg-neutral-grey-4 tw-h-12 tw-rounded" />
      </div>
    ));

export const QboTxTableSkeleton = () => (
  <div className="gridTable">
    <QboTxTableHeader />
    {Array(10)
      .fill()
      .map((_, i) => (
        <QboTxSkeleton key={i} />
      ))}
  </div>
);

const WalletTransactionsSkeleton = () =>
  Array(8)
    .fill()
    .map((_, i) => (
      <div key={i * 10} className="tw-p-3 tw-w-full">
        <div className="tw-flex tw-w-full tw-animate-pulse tw-bg-neutral-grey-4 tw-h-12 tw-rounded" />
      </div>
    ));

export const WalletTransactionsTableSkeleton = () => (
  <div className="gridTable">
    <WalletTransactionHeader />
    {Array(10)
      .fill()
      .map((_, i) => (
        <WalletTransactionsSkeleton key={i} />
      ))}
  </div>
);
