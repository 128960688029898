import React from 'react';
import cx from 'classnames';

import { WalletBoxProps } from './WalletBox.types';
import { WalletTypeLabel } from './components';
import CurrencyFlag from 'components/svg/CurrencyFlag';
import { getCurrencyLabel } from './WalletBox.utils';
import { formatMoneyV2 } from 'utility/currency';
import { PendingDepositsInfo } from 'components/Accounts/components';

const WalletBox = ({
  currency,
  availableBalance,
  ongoingPaymentsBalance,
  activeExternalAccount,
  onClick,
  isClicked,
}: WalletBoxProps) => {
  return (
    <a
      href="#wallet-actions-bar"
      className={cx(
        'wallet-box-item tw-w-full md:tw-w-1/4 tw-border tw-border-primary-dark-green tw-rounded tw-px-4 tw-pb-1 tw-relative tw-cursor-pointer hover:tw-shadow-notification hover:tw-text-neutral-dark',
        isClicked ? 'tw-bg-primary-light-green' : 'tw-bg-neutral-grey-5'
      )}
      onClick={onClick}
    >
      <WalletTypeLabel activeExternalAccount={activeExternalAccount} isClicked={isClicked} />
      <div className="tw-flex tw-flex-col tw-justify-between">
        <div className="tw-py-10 tw-flex tw-items-start">
          <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-4 tw-mt-2">
            <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
              <CurrencyFlag currency={currency} size={26} />
            </div>
          </div>
          <div className="tw-flex tw-flex-col">
            <div className="tw-text-xl tw-font-semibold">{formatMoneyV2(availableBalance)}</div>
            <div className="tw-text-sm">{getCurrencyLabel(availableBalance.currency)}</div>
            {!!ongoingPaymentsBalance.amount && (
              <div className="tw-flex tw-gap-1 tw-text-sm tw-mt-2 tw-break-words">
                <span>
                  Pending Deposits: <b>{formatMoneyV2(ongoingPaymentsBalance)}</b>
                </span>
                <PendingDepositsInfo />
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

export default WalletBox;
