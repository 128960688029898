import { Currencies } from 'constants/currencies';

type useBankDetailsProps = {
  selectedWalletCurrency: string;
};

const useBankDetails = ({ selectedWalletCurrency }: useBankDetailsProps) => {
  const isCurrencyCloudAccount = selectedWalletCurrency === Currencies.GBP || selectedWalletCurrency === Currencies.EUR;
  const isSolidAccount = selectedWalletCurrency === Currencies.USD;

  return { isCurrencyCloudAccount, isSolidAccount };
};

export default useBankDetails;
