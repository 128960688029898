import React from 'react';
import { useHistory } from 'react-router-dom';
import { IoCloudUploadOutline } from 'react-icons/io5';

import Button from 'components/Button';

const PushQboLink = ({ accountName }) => {
  const history = useHistory();
  return (
    <Button
      tertiary
      onClick={(evt) => {
        evt.preventDefault();
        history.push('/dashboard/transactions/cardTransactionsPush');
      }}
    >
      <IoCloudUploadOutline size={24} className="tw-mr-2 tw-text-primary-dark-green" />
      <div className="tw-text-sm tw-text-primary-dark-green">Push transactions to {accountName}</div>
    </Button>
  );
};

export default PushQboLink;
