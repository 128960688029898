import React from 'react';
import { useHistory } from 'react-router-dom';

import { possibleCurrencies } from './constants';
import CurrencyBox from 'components/home/Header/components/QuickActions/components/CurrencyBox';

const ConvertFundsCurrencySelectorContent = () => {
  const history = useHistory();
  const navigateToConvertFundsModal = (currency: string) =>
    history.push(`/dashboard/accounts?convert-funds=true&currency=${currency}`);

  return (
    <>
      <span className="tw-font-semibold">Which Currency would you like to Convert From?</span>
      <div className="tw-flex tw-justify-center tw-mt-8 tw-mb-4 tw-gap-x-2">
        {possibleCurrencies.map(({ currency, currencyLabel }) => {
          return (
            <CurrencyBox
              key={currency}
              currency={currency}
              currencyLabel={currencyLabel}
              onClick={() => navigateToConvertFundsModal(currency)}
            />
          );
        })}
      </div>
    </>
  );
};

export default ConvertFundsCurrencySelectorContent;
