import React from 'react';
import { FaRegSnowflake } from 'react-icons/fa';
import { BiCheckCircle } from 'react-icons/bi';
import { IoCloseCircleOutline } from 'react-icons/io5';
import cx from 'classnames';

import { CREDIT_CARD_STATUS } from 'constants/index';
import SandClock from 'components/svg/SandClock';
import InitialsCirclesGroup from 'components/InitialsCirclesGroup';
import PhysicalCardClean from 'components/svg/PhysicalCardClean';

import styles from './CreditCardsList.module.scss';

import creditCardVirtualSrc from 'images/creditCardVirtual.png';

const CreditCardsList = ({ creditCards, onSelectCard }) => (
  <div className="tw-flex tw-flex-wrap tw-gap-2 credit-card-list">
    {creditCards.map((creditCard) => {
      const isVirtual = creditCard.virtual;
      const isActive = creditCard.status === CREDIT_CARD_STATUS.active;
      const handleSelectCard = () => onSelectCard(creditCard);

      return (
        <div
          key={creditCard.id}
          onClick={handleSelectCard}
          className={cx(styles.creditCardWrapper, 'credit-card-list__item')}
        >
          <div className="tw-h-max tw-w-56 tw-rounded-md tw-bg-neutral-light tw-py-2 tw-px-4 tw-transition-shadow tw-duration-500 tw-ease-in-out tw-cursor-pointer hover:tw-shadow-notification">
            <div className="tw-flex tw-justify-between tw-items-center">
              <CardStatus status={creditCard.status} />
              <CardContacts contacts={creditCard.contacts} />
            </div>
            <div className={cx(styles.creditCard, styles[`creditCard-${isActive ? 'active' : 'inactive'}`])}>
              {isVirtual ? <img src={creditCardVirtualSrc} alt="Credit card" /> : <PhysicalCardClean width="100%" />}

              <div className="tw-absolute tw-z-10 tw-bottom-12 tw-left-4 tw-text-neutral-light tw-text-sm tw-font-semibold">
                ∗∗∗∗ ∗∗∗∗ ∗∗∗∗ {creditCard.lastFourDigits}
              </div>
              {!isVirtual && (
                <div className="tw-absolute tw-z-10 tw-bottom-3 tw-left-4 tw-text-neutral-light tw-text-xs tw-font-semibold tw-uppercase">
                  {creditCard.nameOnCard}
                </div>
              )}
            </div>
            <small className="tw-font-semibold">{`${creditCard.displayName} - ${creditCard.lastFourDigits}`}</small>
            <CardFooter creditCard={creditCard} />
          </div>
        </div>
      );
    })}
  </div>
);

export const CardStatus = ({ status }) => {
  switch (status) {
    case CREDIT_CARD_STATUS.active:
      return (
        <div className="tw-bg-secondary-light-green tw-w-max tw-rounded-md tw-px-2">
          <span className="tw-text-xs tw-text-semantic-success">Active</span>
        </div>
      );
    case CREDIT_CARD_STATUS.suspended:
      return (
        <div className="tw-flex tw-items-center tw-bg-secondary-light-blue tw-w-max tw-rounded-md tw-p-1">
          <FaRegSnowflake size={12} className="tw-text-primary-blue tw-mr-2" />
          <span className="tw-text-xs tw-text-primary-blue">Frozen</span>
        </div>
      );
    case CREDIT_CARD_STATUS.admin_suspended:
      return (
        <div className="tw-flex tw-items-center tw-bg-secondary-light-blue tw-w-max tw-rounded-md tw-p-1">
          <FaRegSnowflake size={12} className="tw-text-primary-blue tw-mr-2" />
          <span className="tw-text-xs tw-text-black">Suspended by Admin</span>
        </div>
      );
    case CREDIT_CARD_STATUS.lost:
    case CREDIT_CARD_STATUS.stolen:
    case CREDIT_CARD_STATUS.fraudulent:
    case CREDIT_CARD_STATUS.damaged:
    case CREDIT_CARD_STATUS.block:
      return (
        <div className="tw-flex tw-items-center tw-bg-neutral-grey-5 tw-w-max tw-rounded-md tw-p-1">
          <IoCloseCircleOutline size={12} className="tw-text-neutral-grey-2 tw-mr-2" />
          <span className="tw-text-xs tw-text-neutral-grey-2">Closed</span>
        </div>
      );
    default:
      return (
        <div className="tw-flex tw-items-center tw-bg-neutral-grey-5 tw-w-max tw-rounded-md tw-p-1">
          <SandClock className="tw-text-neutral-grey-2 tw-mr-2" />
          <span className="tw-text-xs tw-text-neutral-grey-2">Inactive</span>
        </div>
      );
  }
};

export const CardContacts = ({ contacts = [] }) => {
  if (contacts.length === 0) return null;
  const initialsList = contacts.map((c) => `${c.firstName[0]}${c.lastName[0]}`.toUpperCase());

  return <InitialsCirclesGroup initialsList={initialsList} />;
};

const CardFooter = ({ creditCard }) => {
  switch (creditCard.status) {
    case CREDIT_CARD_STATUS.active:
      return (
        // {/* TODO: Uncomment below once backend logic is implemented */}
        // <div className="tw-flex tw-items-start">
        //   <div className="tw-pt-1">
        //     <CircularProgress
        //       value={(creditCard.amountSpent.amount / creditCard.creditLimitCents) * 100}
        //       strokeWidth={16}
        //       size={16}
        //       pathColor="var(--colors-primary-yellow)"
        //       trailColor="var(--colors-natural-grey-3)"
        //       clockWise
        //     />
        //   </div>
        //   <div className="tw-ml-2">
        //     <div className="tw-flex tw-items-end">
        //       <small className="tw-mr-1">{`${formatMoneyV2(availableBalance)}`}</small>
        //       <span className="tw-text-neutral-grey-2 tw-text-xs">{`${
        //         availableBalance ? availableBalance.currency : 'CAD'
        //       }`}</span>
        //     </div>
        //     <span className="tw-text-neutral-grey-2 tw-text-xs">Left to Spend</span>
        //   </div>
        // </div>
        <></>
      );
    case CREDIT_CARD_STATUS.suspended:
      return (
        <div className="tw-mt-2 tw-flex tw-items-center tw-bg-secondary-light-blue tw-w-max tw-rounded-md tw-p-2">
          <FaRegSnowflake className="tw-text-primary-blue tw-mr-2" />
          <small className="tw-font-semibold tw-text-primary-blue">Unfreeze</small>
        </div>
      );
    case CREDIT_CARD_STATUS.admin_suspended:
    case CREDIT_CARD_STATUS.lost:
    case CREDIT_CARD_STATUS.stolen:
    case CREDIT_CARD_STATUS.fraudulent:
    case CREDIT_CARD_STATUS.damaged:
    case CREDIT_CARD_STATUS.block:
      return null;
    default:
      return (
        <div className="tw-mt-2 tw-flex tw-items-center tw-bg-secondary-light-green tw-w-max tw-rounded-md tw-p-2">
          <BiCheckCircle className="tw-text-primary-dark-green tw-mr-2" />
          <small className="tw-font-semibold tw-text-primary-dark-green">Activate</small>
        </div>
      );
  }
};

export default CreditCardsList;
