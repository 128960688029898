import { useState, useEffect } from 'react';

import { PAYEE_RECORD_TYPES } from 'constants/index';
import { CRA_ACCOUNT_PAYEE_DISPLAY_NAME } from './constants';

const usePayeesFilters = (payees) => {
  const [search, setSearch] = useState('');
  const [visiblePayees, setVisiblePayees] = useState(payees || []);

  useEffect(() => {
    if (!search?.length) {
      setVisiblePayees(payees);
      return;
    }

    const filtered = payees.filter((payee) => {
      const { name, displayName, __typename: type } = payee.record;

      const searchString =
        type === PAYEE_RECORD_TYPES.CRAAccount ? CRA_ACCOUNT_PAYEE_DISPLAY_NAME : `${name} ${displayName}`;

      return searchString.toLowerCase().includes(search.toLowerCase());
    });

    setVisiblePayees(filtered);
  }, [search, payees]);

  const onChangeSearch = (event) => setSearch(event.target.value);

  return {
    onChangeSearch,
    visiblePayees,
  };
};

export default usePayeesFilters;
