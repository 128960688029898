/**
 * Based on http://www.brainjar.com/js/validation/
 */

const isUSRoutingNumberValid = (routingNumber: string): { isValid: boolean; message: string } => {
  const routingNumberRegex = /^[0-9]{9}$/;
  if (!routingNumberRegex.test(routingNumber))
    return { isValid: false, message: 'Routing number should be 9 digits long' };

  // Run through each digit and calculate the total.

  let n = 0;
  for (let i = 0; i < routingNumber.length; i += 3) {
    n +=
      parseInt(routingNumber.charAt(i), 10) * 3 +
      parseInt(routingNumber.charAt(i + 1), 10) * 7 +
      parseInt(routingNumber.charAt(i + 2), 10);
  }

  // If the resulting sum is an even multiple of ten (but not zero),
  // the aba routing number is good.

  const isValid = n != 0 && n % 10 == 0;

  return { isValid, message: `Routing number is ${isValid ? 'valid' : 'invalid'}` };
};

const validateUSRoutingNumber = (value: string) => {
  const { isValid, message } = isUSRoutingNumberValid(value);

  return isValid || message;
};

export default validateUSRoutingNumber;
