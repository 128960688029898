import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import BusinessAddress from '../components/onboarding/BusinessAddress';
import { GET_BUSINESS_ADDRESS } from '../graphql/onboarding';
import { entityTypes } from '../constants';
import useOnboardingRedirect from '../hooks/useOnboardingRedirect';

const BusinessAddressContainer = () => {
  const { loading, data } = useQuery(GET_BUSINESS_ADDRESS, { fetchPolicy: 'network-only' });
  const { street, unitNumber, city, countrySubdivision, postalCode, country } = _.get(data, 'me.account.address') || {};
  const accountType = _.get(data, 'me.account.entityType');
  const { loading: isLoadingDetails } = useOnboardingRedirect();
  const isSoleProprietor = accountType === entityTypes.SOLE_PROPRIETORSHIP;

  return (
    <BusinessAddress
      loading={isLoadingDetails || loading}
      street={street}
      unitNumber={unitNumber}
      city={city}
      countrySubdivision={countrySubdivision}
      postalCode={postalCode}
      country={country}
      isSoleProprietor={isSoleProprietor}
    />
  );
};

export default BusinessAddressContainer;
