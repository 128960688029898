import React from 'react';
import { useQuery } from '@apollo/client';
import qs from 'query-string';
import { get } from 'lodash';

import TeamRegistration from 'components/auth/TeamRegistration';
import { TEAM_INVITATION } from 'graphql/team';

const TeamRegistrationContainer = () => {
  const { sgid } = qs.parse(location.search);
  const { loading, data, error } = useQuery(TEAM_INVITATION, { variables: { sgid } });
  const invitation = get(data, 'teamInvitation');

  return <TeamRegistration loading={loading} error={error} invitation={invitation} sgid={sgid} />;
};

export default TeamRegistrationContainer;
