import { memo } from 'react';

import { getTransactionTypeIcon } from 'utility/transactions';

const TransactionTypeCard = ({ transaction }) => {
  const Icon = getTransactionTypeIcon(transaction, 27);

  return Icon;
};

export default memo(TransactionTypeCard);
