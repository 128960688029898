import React from 'react';

type BankFailedProps = {
  size?: number;
};

const BankFailed = ({ size = 25 }: BankFailedProps) => (
  <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.375"
      y="19.2122"
      width="20.1802"
      height="1.34302"
      rx="0.671512"
      fill="white"
      stroke="black"
      strokeWidth="0.75"
    />
    <rect x="2.46826" y="17.1191" width="15.9942" height="1.34302" fill="white" stroke="black" strokeWidth="0.75" />
    <rect x="2.46826" y="6.375" width="15.9942" height="1.34302" fill="white" stroke="black" strokeWidth="0.75" />
    <rect x="3.58447" y="7.96973" width="1.34302" height="8.9" fill="white" stroke="black" strokeWidth="0.75" />
    <rect x="9.60498" y="7.96973" width="1.34302" height="8.9" fill="white" stroke="black" strokeWidth="0.75" />
    <rect x="15.8633" y="7.96973" width="1.34302" height="8.9" fill="white" stroke="black" strokeWidth="0.75" />
    <path
      d="M10.0359 0.609171C10.4019 0.474321 10.8043 0.475632 11.1694 0.612863L20.0676 3.95716C20.4029 4.08319 20.625 4.40388 20.625 4.76211C20.625 5.23703 20.24 5.62203 19.7651 5.62203H1.23141C0.758427 5.62203 0.375 5.2386 0.375 4.76562C0.375 4.40682 0.598664 4.08606 0.935343 3.96202L10.0359 0.609171Z"
      fill="white"
      stroke="black"
      strokeWidth="0.75"
    />
    <rect x="13.75" y="13.75" width="10.5" height="10.5" rx="5.25" fill="#FEF3F2" />
    <path d="M20.75 20.75L17.25 17.25" stroke="#F14E1B" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.75 17.25L17.25 20.75" stroke="#F14E1B" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="13.75" y="13.75" width="10.5" height="10.5" rx="5.25" stroke="black" strokeWidth="0.5" />
  </svg>
);

export default BankFailed;
