import React from 'react';

import config from 'config';
import { Checkbox } from 'components/FormFields/v2';
import Button from 'components/Button';
import { useAgreements } from './hooks';
import { AgreementsProps } from './Agreements.types';

const Agreements = ({ onNextStep }: AgreementsProps) => {
  const {
    isAcceptedCADAgreement,
    isAcceptedPrivacyPolicy,
    handleAcceptedCADAgreement,
    handleAcceptedPrivacyPolicy,
    handleSubmit,
    isLoading,
  } = useAgreements({
    onNextStep,
  });

  return (
    <div>
      <div className="tw-flex tw-flex-col tw-gap-8 tw-p-8">
        <div>
          <div className="tw-mb-2">To continue please open and read the documentation below:</div>
          <ul className="tw-list-disc tw-px-4">
            <li className="tw-mb-1">
              <a href={config.multiCurrencyAccountAgreementCA} target="_blank" rel="noopener noreferrer">
                <span className="tw-font-bold tw-underline">Loop Multi-Currency Account Agreement</span>
              </a>
            </li>
            <li className="tw-mb-1">
              <a href={config.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                <span className="tw-font-bold tw-underline">Privacy Policy</span>
              </a>
            </li>
          </ul>
        </div>

        <div>
          <Checkbox
            label={
              <div className="tw-cursor-pointer tw-text-sm">
                I agree to the{' '}
                <a target="_blank" href={config.multiCurrencyAccountAgreementCA} rel="noopener noreferrer">
                  <span className="tw-font-bold tw-text-primary-dark-green tw-underline">
                    Loop Multi-Currency Account Agreement
                  </span>
                </a>
                , including the authorization for Direct Deposits
              </div>
            }
            value={isAcceptedCADAgreement}
            name="acceptCADAgreement"
            onChange={handleAcceptedCADAgreement}
            rootClass="tw-mb-2"
          />
          <Checkbox
            label={
              <div className="tw-cursor-pointer tw-text-sm">
                I confirm that I provide consent to the collection, use and disclosure of my personal information which
                will be used in connection with assessing my eligibility to open the account and access other products
                offered by Loop in accordance with Loop’s{' '}
                <a target="_blank" href={config.privacyPolicyUrl} rel="noopener noreferrer">
                  <span className="tw-font-bold tw-text-primary-dark-green tw-underline">Privacy Policy</span>
                </a>
              </div>
            }
            value={isAcceptedPrivacyPolicy}
            name="acceptPrivacyPolicy"
            onChange={handleAcceptedPrivacyPolicy}
          />
        </div>
      </div>
      <div className="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-pt-4 tw-flex tw-justify-end">
        <Button
          onClick={handleSubmit}
          primary
          isDisabled={!isAcceptedCADAgreement || !isAcceptedPrivacyPolicy || isLoading}
          className="tw-font-bold"
        >
          Open Account
        </Button>
      </div>
    </div>
  );
};

export default Agreements;
