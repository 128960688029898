import {
  ADMIN_PERMISSIONS,
  BOOKKEEPER_PERMISSIONS,
  READ_ONLY_PERMISSIONS,
  TEAM_MEMBER_STATUS,
  USER_ROLE_TYPES,
} from 'constants/index';

export const deHumanizePermission = (permission) => {
  return permission
    ?.split(' ')
    .map((s) => s.toLowerCase())
    .join('_');
};

export const humanizePermission = (permission) => {
  return permission
    ?.split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');
};

export const isPermissionChecked = (member, permission) => {
  return member.permissions.includes(deHumanizePermission(permission));
};

export const getPermissionSet = (role) => {
  switch (role) {
    case 'admin':
      return ADMIN_PERMISSIONS.map((permission) => permission.value);
    case 'read_only':
      return READ_ONLY_PERMISSIONS.map((permission) => permission.value);
    case 'bookkeeper':
      return BOOKKEEPER_PERMISSIONS.map((permission) => permission.value);
    default:
      return [];
  }
};

export const canBeDeactivated = (self, selectedMember) => {
  const isAccountOwner = selectedMember.roleValue === USER_ROLE_TYPES.accountOwner;
  return selectedMember.status === TEAM_MEMBER_STATUS.active && !isAccountOwner && self.id !== selectedMember.id;
};
