import { formatDistanceToNow } from 'date-fns';

export const ROLE_NAME_MAP = {
  read_only: 'Read-only',
  bookkeeper: 'Bookkeeper',
  account_owner: 'Owner',
  admin: 'Admin',
  member: 'Member',
};

class TeamMember {
  constructor(attributes) {
    const { id, role, firstName, lastName, status, positions, email, user, permissions, rules, manager } =
      attributes || {};
    const { lastActiveAt } = user || {};

    this.id = id;
    this.role = ROLE_NAME_MAP[role] || '-';
    this.roleValue = role;
    this.firstName = firstName;
    this.lastName = lastName;
    this.fullName = `${firstName} ${lastName}`;
    this.initials = `${firstName[0]}${lastName[0]}`.toUpperCase();
    this.lastActiveAt = lastActiveAt ? formatDistanceToNow(new Date(lastActiveAt), { addSuffix: true }) : '-';
    this.status = status;
    this.email = email;
    this.positions = positions || [];
    this.permissions = permissions || [];
    this.rules = rules || {};
    this.manager = manager || null;
  }

  isContact() {
    return this.id.includes('/Contact/');
  }
}

export default TeamMember;
