import React from 'react';
import { Transition } from '@headlessui/react';

import Close from 'components/svg/Close';
import { ReimbursementDetail, CardExpenseDetail } from './components';
import './ApprovalRequestDetailOverlay.scss';
import { ApprovalRequestDetailOverlayProps } from './ApprovalRequestDetailOverlay.types';
import {
  CardExpenseRequestDataType,
  ReimbursementApprovalRequestDataType,
  isCardExpenseRequestDataType,
} from '../ApprovalRequest.types';
import useApprovalRequestDetailOverlay from './hooks/useApprovalRequestDetailOverlay';

const ApprovalRequestDetailOverlay = ({ requestList }: ApprovalRequestDetailOverlayProps) => {
  const { request, onClose, isOpenModal } = useApprovalRequestDetailOverlay({ requestList });

  if (!request || isOpenModal) return null;

  return (
    <Transition
      show={!!request}
      as="div"
      className="tw-h-screen tw-w-1/4 tw-fixed tw-top-0 tw-right-0 tw-shadow-notification transaction-detail-index tw-bg-neutral-light tw-transform"
      enter="tw-ease-in-out tw-duration-300"
      enterFrom="tw-translate-x-96"
      enterTo="tw-translate-x-0"
      leave="tw-ease-in-out tw-duration-300"
      leaveFrom="tw-translate-x-0"
      leaveTo="tw-translate-x-96"
    >
      {isCardExpenseRequestDataType(request) ? (
        <CardExpenseDetail
          header={
            <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
              <div className="tw-text-sm tw-text-neutral-grey-2">Transaction Details</div>
              <Close className="tw-cursor-pointer" onClick={onClose} />
            </div>
          }
          cardExpense={request as CardExpenseRequestDataType}
        />
      ) : (
        <ReimbursementDetail
          header={
            <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
              <div className="tw-text-sm tw-text-neutral-grey-2">Request Details</div>
              <Close className="tw-cursor-pointer" onClick={onClose} />
            </div>
          }
          request={request as ReimbursementApprovalRequestDataType}
        />
      )}
    </Transition>
  );
};

export default ApprovalRequestDetailOverlay;
