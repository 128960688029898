import React from 'react';
import { FormProvider } from 'react-hook-form';
import cx from 'classnames';

import { useNotEnabled } from './hooks';
import { PAYMENT_CURRENCY_OPTIONS, PAYMENT_TYPE_OPTIONS, INFO_TOOLTIP_MESSAGE } from './constants';
import {
  CurrencyOptions,
  PaymentTypes,
} from 'components/payments/CardRepayment/components/Steps/components/Details/components';
import { Select } from 'components/FormFields/v2';
import InfoTooltip from 'components/InfoTooltip';
import { Loaders } from 'components/cards/Loader';
import { FormFooter } from 'components/UI';
import AutopayBanner from '../AutopayBanner';
import PADAgreement from '../PADAgreement';

import styles from './NotEnabled.module.scss';

const NotEnabled = ({ openEnableAutopayModal }: { openEnableAutopayModal: () => void }) => {
  const {
    form,
    register,
    loading,
    fromAccountOptionsOfSameCurrency,
    selectedPaymentCurrencyOption,
    onNavigateBack,
    handleSubmit,
    handleSubmitFunction,
    loadingRecurringPaymentData,
    formattedNextPaymentDate,
  } = useNotEnabled({ openEnableAutopayModal });

  if (loading || loadingRecurringPaymentData) return <Loaders.Light />;

  return (
    <div className="tw-px-4 tw-mb-16 lg:tw-px-0">
      <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-x-16 tw-gap-y-4 lg:tw-gap-y-0 tw-mt-2 tw-mb-6 lg:tw-my-8">
        <h3>Configure Automatic Payments</h3>
        <div className="tw-border tw-border-neutral-grey-2 tw-rounded-md tw-bg-neutral-grey-4 tw-py-2 tw-px-4 tw-text-neutral-grey-2 tw-text-center">
          Currently Not Enabled
        </div>
      </div>
      <div className="tw-text-neutral-grey-2 tw-text-sm lg:tw-w-2/3">
        Schedule automatic statement payments All in CAD or All in USD. If it is within 7 days of your statement due
        date, your first automatic payment will be charged immediately.
      </div>
      <div className="tw-flex tw-flex-col-reverse xl:tw-flex-row tw-justify-start tw-gap-8 tw-my-8 tw-w-full">
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(handleSubmitFunction)}
            className={cx('tw-flex tw-flex-col tw-gap-y-6', styles.form)}
          >
            <CurrencyOptions
              options={PAYMENT_CURRENCY_OPTIONS}
              infoTooltipMessage={INFO_TOOLTIP_MESSAGE.paymentCurrency}
              labelClassName="xl:tw-w-1/3"
              contentClassName="tw-w-2/3"
            />
            <PaymentTypes
              paymentTypeOptions={PAYMENT_TYPE_OPTIONS}
              labelClassName="xl:tw-w-1/3"
              contentClassName="tw-w-2/3"
            />
            <div className="tw-flex xl:tw-gap-3 tw-items-start">
              <div className="tw-flex tw-items-center tw-gap-1 tw-w-40 tw-h-12 xl:tw-w-1/3">
                Account to Pay From <InfoTooltip message={INFO_TOOLTIP_MESSAGE.fromAccounts} />
              </div>

              <Select
                name="fromAccount"
                options={fromAccountOptionsOfSameCurrency}
                placeholder="Select account"
                ref={register({ required: true })}
                rootClass="tw-w-40"
                className="tw-text-sm"
                unselected
              />
            </div>
            {formattedNextPaymentDate && (
              <div className="tw-flex xl:tw-gap-3">
                <div className="xl:tw-w-1/3">Next Automatic Payment</div>
                <div className="xl:tw-w-2/3">{formattedNextPaymentDate}</div>
              </div>
            )}
            <PADAgreement />
            <FormFooter
              cancelButtonLabel="Back"
              onCancel={onNavigateBack}
              submitButtonLabel="Enable Automatic Payment"
            />
          </form>
        </FormProvider>
        <div className="tw-min-w-min">
          <AutopayBanner currency={selectedPaymentCurrencyOption} />
        </div>
      </div>
    </div>
  );
};

export default NotEnabled;
