import React from 'react';

import { mapTransactionsByDate, pendingAccountDepositTransactions } from 'utility/transactions';
import { formatTransactionTableAmount } from 'utility/currency';
import InfoTooltip from 'components/InfoTooltip';

const PendingAccountDepositsTable = ({ transactions, accountNames, loadingTransactions }) => {
  if (loadingTransactions) return <TableSkeleton />;
  if (!transactions) return null;

  const pendingTransactions = pendingAccountDepositTransactions({ transactions, accountNames });
  if (pendingTransactions.length === 0) return null;

  return (
    <Layout>
      <TransactionsTable transactionsByDate={mapTransactionsByDate(pendingTransactions)} accountNames={accountNames} />
    </Layout>
  );
};

const Layout = ({ children }) => (
  <div className="tw-space-y-4">
    <small className="tw-text-neutral-grey-1 tw-bg-secondary-pastel-green-80 tw-p-2">Pending Deposits</small>
    <div className="tw-mt-4 tw-bg-neutral-light">{children}</div>
  </div>
);

const TransactionsTable = ({ transactionsByDate, accountNames }) => {
  return (
    <table className="tw-table tw-table-auto tw-w-full">
      <TableHeaders />
      <tbody>
        {Object.keys(transactionsByDate).map((date) => (
          <TableSection key={date} transactions={transactionsByDate[date]} accountNames={accountNames} />
        ))}
      </tbody>
    </table>
  );
};

const TableHeaders = () => (
  <thead>
    <tr>
      <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">From</th>
      <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">To</th>
      <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Amount</th>
      <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-right">
        Estimated completion speed
      </th>
    </tr>
  </thead>
);

const TableSection = ({ transactions, accountNames }) => (
  <>
    {transactions.map((transaction) => {
      const accountName = accountNames.find((name) => name === transaction.to);

      return <TransactionRow key={transaction.transactionId} transaction={transaction} accountName={accountName} />;
    })}
  </>
);

const TransactionRow = ({ transaction, accountName }) => {
  const formattedAmount = formatTransactionTableAmount({
    transaction,
    accountName,
  });

  return (
    <tr>
      <td className="tw-p-3 tw-text-sm tw-border-b tw-border-neutral-grey-5">{transaction.from}</td>
      <td className="tw-p-3 tw-text-sm tw-border-b tw-border-neutral-grey-5">{transaction.to}</td>
      <td className="tw-p-3 tw-text-sm tw-border-b tw-border-neutral-grey-5 tw-text-semantic-success">
        {formattedAmount}
      </td>
      <td className="tw-p-3 tw-text-sm tw-border-b tw-border-neutral-grey-5 tw-text-right">
        {transaction.expectedCompletionSpeed.includes('ETA') && (
          <div className="tw-flex tw-justify-end">
            <span className="tw-mr-1">{transaction.expectedCompletionSpeed}</span>
            <InfoTooltip
              testId={`deposit-${transaction.id}`}
              message="Your deposit is estimated to complete on the specified date."
              mobileOrientation="right"
            />
          </div>
        )}
        {transaction.expectedCompletionSpeed === 'Fast' && (
          <div className="tw-flex tw-justify-end">
            <span className="tw-mr-1">1 Business Day</span>
            <InfoTooltip
              testId={`deposit-${transaction.id}`}
              message="Your deposit will be completed within 1 business day"
              mobileOrientation="right"
            />
          </div>
        )}
      </td>
    </tr>
  );
};

const TableSkeleton = () => {
  return (
    <Layout>
      <table data-testid="table-skeleton" className="tw-table tw-table-auto tw-w-full">
        <TableHeaders />
        <tbody>
          {Array(3)
            .fill()
            .map((_, i) => (
              <tr key={i}>
                <th className="tw-p-3 tw-w-full" colSpan={5}>
                  <div className="tw-flex tw-w-full tw-animate-pulse tw-bg-neutral-grey-4 tw-h-8 tw-rounded" />
                </th>
              </tr>
            ))}
        </tbody>
      </table>
    </Layout>
  );
};

export default PendingAccountDepositsTable;
