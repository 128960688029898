import React, { useContext } from 'react';

import { PaymentRequestsContext } from 'components/PayorPortal/PaymentRequests/contexts/PaymentRequestsContext';
import { PaymentRequestCurrency } from 'types/invoicing';
import { Banner } from 'components/UI';
import { BannerType } from 'components/UI/Banner';
import CardAndStar from 'components/svg/CardAndStar';
import CardAndKey from 'components/svg/CardAndKey';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { Currencies } from 'constants/currencies';
import { BannersSectionProps } from './BannersSection.types';

const BannersSection = ({ onClick }: BannersSectionProps) => {
  const { noBankAccounts, requiredCADBankAccount, requiredUSDBankAccount, isBankAccountsConnectionError } =
    useContext(PaymentRequestsContext);

  const connectionCurrency = noBankAccounts
    ? undefined
    : (requiredCADBankAccount && PaymentRequestCurrency.CAD) || (requiredUSDBankAccount && PaymentRequestCurrency.USD);

  const handleBankConnection = () => {
    onClick(connectionCurrency || undefined);
  };

  if (isBankAccountsConnectionError) {
    return (
      <Banner
        title="There is insufficient data to process your bank account."
        message={
          <div>
            Thank you for connecting your bank account. Unfortunately, we are having trouble retrieving banking details
            to process the payment.
            <div className="tw-mt-2">Please provide your bank details below.</div>
          </div>
        }
        submitButtonLabel="Connect Bank Account Manually"
        onSubmit={handleBankConnection}
        icon={<CardAndKey />}
        type={BannerType.warning}
      />
    );
  }

  if (noBankAccounts) {
    return (
      <Banner
        title="You don't have a bank account connected."
        message="To pay your vendor, please connect your bank account."
        submitButtonLabel="Connect Bank Account"
        onSubmit={handleBankConnection}
        icon={<CardAndStar />}
      />
    );
  }

  if (requiredCADBankAccount || requiredUSDBankAccount) {
    return (
      <Banner
        title={`You don't have a ${connectionCurrency} bank account connected.`}
        message={`Some of the requested payments are in ${connectionCurrency} currency. To pay your vendor, please connect your ${connectionCurrency} bank account.`}
        submitButtonLabel={`Connect ${connectionCurrency} Bank Account`}
        onSubmit={handleBankConnection}
        icon={
          <div className="tw-rounded-full tw-bg-neutral-light tw-p-1">
            <MobileCurrencyFlag currency={Currencies[connectionCurrency || Currencies.CAD]} size={40} />
          </div>
        }
      />
    );
  }

  return null;
};

export default BannersSection;
