import React from 'react';
import cx from 'classnames';

import { BANNER_TYPES, BANNER_ICON_MAP } from './constants';

import styles from './Banner.module.scss';

const Banner = ({ isShowIcon, type = BANNER_TYPES.success, message, className }) => (
  <div
    className={cx(styles.banner, {
      [className]: className,
      'tw-bg-primary-light-green tw-text-semantic-success': type === BANNER_TYPES.success,
      'tw-bg-secondary-pastel-yellow-80 tw-text-semantic-warning': type === BANNER_TYPES.danger,
      'tw-bg-secondary-pastel-red-50 tw-text-semantic-error': type === BANNER_TYPES.error,
      'tw-bg-neutral-grey-5': type === BANNER_TYPES.info,
    })}
  >
    {isShowIcon && <div className={styles.banner__icon}>{BANNER_ICON_MAP[type]}</div>}

    {message}
  </div>
);

export default Banner;
