import React, { useState, useCallback, createContext, useEffect } from 'react';
import qs from 'query-string';
import { isString } from 'lodash';

import { decryptString } from 'utility/string';

// Filter Context
export const FilterContext = createContext(null);

export const FilterProvider = ({ children }) => {
  const [search, setSearch] = useState('');
  const [showOnlyPending, setShowOnlyPending] = useState(false);
  const [period, setPeriod] = useState('');
  const [currencies, setCurrencies] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentBillingCycle, setCurrentBillingCycle] = useState('');
  const [previousBillingCycle, setPreviousBillingCycle] = useState('');
  const [page, setPage] = useState(0);
  const [numPerPage, setNumPerPage] = useState(5);

  const { productId } = qs.parse(location?.search);

  const selectedProductId = isString(productId) ? decryptString(productId) : null;

  const handleClearFilters = useCallback(() => {
    setSearch('');
    setShowOnlyPending(false);
    setPeriod('');
    setProducts([]);
    setCurrencies([]);
    setCategories([]);
  }, []);

  const handleClearPage = useCallback(() => {
    setPage(0);
    setNumPerPage(5);
  }, []);

  const resetFilters = useCallback(() => {
    handleClearFilters();
    handleClearPage();
  }, [handleClearFilters, handleClearPage]);

  useEffect(() => {
    resetFilters();
  }, [resetFilters]);

  useEffect(() => {
    if (!selectedProductId) return;

    setProducts((prevState) => [...prevState, { value: selectedProductId }]);
  }, [selectedProductId]);

  const hasDropdownFilters =
    !!period || currencies.length > 0 || products.length > 0 || categories.length > 0 || search.length > 0;

  return (
    <FilterContext.Provider
      value={{
        page,
        setPage,
        numPerPage,
        setNumPerPage,
        search,
        setSearch,
        showOnlyPending,
        setShowOnlyPending,
        period,
        setPeriod,
        currencies,
        setCurrencies,
        products,
        setProducts,
        loopProducts: products.map((prod) => prod.value),
        categories,
        currentBillingCycle,
        setCurrentBillingCycle,
        previousBillingCycle,
        setPreviousBillingCycle,
        setCategories,
        handleClearFilters,
        hasDropdownFilters,
        handleClearPage,
        resetFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
