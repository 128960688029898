import { gql } from '@apollo/client';

export const GET_MEMBER_LIST = gql`
  query Members {
    me {
      ... on User {
        account {
          internalContacts {
            id
            firstName
            lastName
            role
            status
            email
            positions
            name
            manager {
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_CARD_EXPENSES = gql`
  query cardExpenses(
    $page: String
    $numPerPage: String
    $startDate: String
    $endDate: String
    $products: [ID!]
    $currencies: [String!]
    $members: [ID!]
  ) {
    cardExpenses(
      page: $page
      numPerPage: $numPerPage
      startDate: $startDate
      endDate: $endDate
      products: $products
      currencies: $currencies
      members: $members
    ) {
      items {
        transactionId
        requester {
          name
        }
        fromLabel
        merchantName
        amountRequested {
          amount
          currency
        }
        receipt
        description
        transactionDate
        status
      }
      pageData {
        totalPages
        currentPage
        nextPage
        prevPage
        totalCount
      }
    }
  }
`;

export const UPDATE_CARD_EXPENSE = gql`
  mutation CreateCardExpense($transactionId: Int!, $status: String!, $declineReason: String) {
    createCardExpense(transactionId: $transactionId, status: $status, declineReason: $declineReason)
  }
`;
