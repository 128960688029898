import React from 'react';

import { TextField, Select } from 'components/FormFields/v2';
import { useBusinessAddress } from './hooks';

const BusinessAddress = () => {
  const { register, countryOptions, countrySubdivisions, isCanada } = useBusinessAddress();

  return (
    <div className="tw-px-6 tw-py-4 tw-space-y-6">
      <Select
        name="address.country"
        label="Country"
        placeholder="Select Option"
        ref={register({ required: true })}
        options={countryOptions}
        required
      />
      <TextField
        name="address.street"
        label="Business Street Address"
        placeholder="410 Adelaide St. W."
        ref={register({ required: true })}
        required
      />
      <div className="tw-flex tw-gap-8">
        <Select
          name="address.countrySubdivision"
          label={isCanada ? 'Province' : 'State'}
          placeholder="Select State"
          ref={register({ required: true })}
          options={countrySubdivisions}
          defaultValue={countrySubdivisions[0].value}
          required
        />
        <TextField name="address.city" label="City" placeholder="Toronto" ref={register({ required: true })} required />
        <TextField
          name="address.postalCode"
          label="Postal Code"
          placeholder="M5V1S8"
          ref={register({ required: true })}
          required
        />
      </div>
    </div>
  );
};

export default BusinessAddress;
