import React from 'react';

import LoaderCard from './cards/Loader';
import styles from './FlinksConnection.module.scss';

const FlinksConnection = ({ loading, flinksUrl }) => {
  if (loading) return <LoaderCard />;

  return (
    <iframe key={flinksUrl} src={flinksUrl} className={styles.embeddedResponsive}>
      <p>An iFrame should be here</p>
    </iframe>
  );
};

export default FlinksConnection;
