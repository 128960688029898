import { EXTERNAL_TRANSACTION_CLEARING_MESSAGE } from 'constants/index';

export const ERROR = {
  NO_FROM_ACCOUNT_SELECTED: 'Please select at least one account to pay from.',
  NO_TRANSACTIONS: 'Please select at least one transaction.',
  DEFAULT: 'Cannot proceed due to error.',
};

export const INTO_TOOLTIP = {
  paymentCurrency: 'Pay your card balance in CAD or USD equivalent or each balance individually',
  paymentType: 'Select which outstanding balance you would like to pay',
  paymentAmount: 'Amount due based on the Payment Type selected',
  fromAccounts: EXTERNAL_TRANSACTION_CLEARING_MESSAGE,
};
