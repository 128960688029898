import React from 'react';

import { AGREEMENT_SECTIONS } from './constants';

const Agreement = () => {
  return (
    <ol className="tw-flex tw-flex-col tw-gap-4 tw-text-sm">
      {AGREEMENT_SECTIONS.map(({ title, content }, index) => (
        <li key={index}>
          <>
            <div>
              {index + 1}. {title}
            </div>
            <div>{content}</div>
          </>
        </li>
      ))}
    </ol>
  );
};

export default Agreement;
