import React from 'react';

const CardAndStar2 = ({ width = '77', height = '52', vbHeight = '77', vbWidth = '52', ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      {...other}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3919_151083)">
        <path
          d="M13.2569 21.2384C16.5076 19.9127 18.068 16.2028 16.7423 12.9522C15.4166 9.70155 11.7068 8.14108 8.45612 9.46679C5.20548 10.7925 3.64501 14.5024 4.97071 17.753C6.29642 21.0036 10.0063 22.5641 13.2569 21.2384Z"
          fill="#EED9F7"
        />
        <path
          d="M15.3188 13.8921C15.3188 10.2678 12.3784 7.32739 8.75411 7.32739C5.12987 7.32739 2.18945 10.2678 2.18945 13.8921C2.18945 17.5163 5.12987 20.4567 8.75411 20.4567C12.3784 20.4567 15.3188 17.5163 15.3188 13.8921Z"
          stroke="#8F5CCF"
          strokeWidth="0.574331"
          strokeMiterlimit="10"
        />
        <path
          d="M8.75391 11.1567V16.6273"
          stroke="#8F5CCF"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.4881 13.892H6.01758"
          stroke="#8F5CCF"
          strokeWidth="0.574331"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M55.2917 19.2352L34.7078 11.1292C32.6137 10.3045 30.2475 11.3336 29.4228 13.4278L23.8766 27.5115C23.0519 29.6057 24.081 31.9718 26.1752 32.7965L46.7591 40.9025C48.8532 41.7272 51.2194 40.6981 52.0441 38.604L57.5903 24.5202C58.415 22.4261 57.3859 20.0599 55.2917 19.2352Z"
        fill="#FFF4E1"
      />
      <path
        d="M51.4187 21.0753L30.8347 12.9693C28.7406 12.1446 26.3744 13.1737 25.5498 15.2679L20.0035 29.3516C19.1789 31.4457 20.208 33.8119 22.3021 34.6366L42.886 42.7426C44.9802 43.5673 47.3464 42.5382 48.171 40.444L53.7173 26.3603C54.5419 24.2661 53.5128 21.9 51.4187 21.0753Z"
        stroke="#EEA530"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24.4844 17.9763L52.6519 29.0688" stroke="#EEA530" strokeLinejoin="round" />
      <path
        d="M30.2716 28.702L27.0215 27.4221L26.4882 28.7763L29.7383 30.0562L30.2716 28.702Z"
        stroke="#EEA530"
        strokeLinejoin="round"
      />
      <path
        d="M12.9648 32.2632C13.0358 32.0551 13.3417 32.0972 13.3538 32.3167L13.7336 39.204C13.7381 39.2855 13.7915 39.356 13.8687 39.3823L20.3975 41.6083C20.6056 41.6792 20.5635 41.9852 20.3439 41.9973L13.4566 42.377C13.3752 42.3815 13.3046 42.435 13.2783 42.5122L11.0524 49.0409C10.9814 49.249 10.6755 49.2069 10.6634 48.9874L10.2836 42.1001C10.2791 42.0187 10.2257 41.9481 10.1485 41.9218L3.61972 39.6958C3.41163 39.6249 3.45373 39.3189 3.67325 39.3068L10.5606 38.9271C10.642 38.9226 10.7126 38.8691 10.7389 38.7919L12.9648 32.2632Z"
        fill="#E8F3ED"
      />
      <path
        d="M75.3614 11.9488C75.5764 11.9946 75.5708 12.3034 75.3543 12.3414L66.6362 13.8722C66.5558 13.8863 66.4921 13.9478 66.4751 14.0275L64.6312 22.6849C64.5854 22.8999 64.2767 22.8943 64.2386 22.6778L62.7078 13.9597C62.6937 13.8793 62.6323 13.8156 62.5525 13.7986L53.8952 11.9547C53.6802 11.9089 53.6857 11.6002 53.9023 11.5621L62.6204 10.0313C62.7007 10.0172 62.7644 9.95578 62.7814 9.87602L64.6253 1.2187C64.6711 1.00367 64.9799 1.00923 65.0179 1.22577L66.5487 9.94391C66.5628 10.0242 66.6243 10.0879 66.704 10.1049L75.3614 11.9488Z"
        fill="#E8F3ED"
      />
      <defs>
        <clipPath id="clip0_3919_151083">
          <rect width="17.5058" height="17.5058" fill="white" transform="translate(0 5.13916)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CardAndStar2;
