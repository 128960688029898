import React from 'react';

import { Currencies } from 'constants/currencies';

import AEFlag from './assets/AED.png';
import AUFlag from './assets/AUD.png';
import BGFlag from './assets/BGN.png';
import CAFlag from './assets/CAD.png';
import CHFlag from './assets/CHF.png';
import CNFlag from './assets/CNY.png';
import CZFlag from './assets/CZK.png';
import DKFlag from './assets/DKK.png';
import EUFlag from './assets/EUR.png';
import ILFlag from './assets/ILS.png';
import INFlag from './assets/INR.png';
import JPFlag from './assets/JPY.png';
import KEFlag from './assets/KES.png';
import MXFlag from './assets/MXN.png';
import NZFlag from './assets/NZD.png';
import PHFlag from './assets/PHP.png';
import QAFlag from './assets/QAR.png';
import SAFlag from './assets/SAR.png';
import THFlag from './assets/THB.png';
import TRFlag from './assets/TRY.png';
import UGFlag from './assets/UGX.png';
import UKFlag from './assets/GBP.png';
import USFlag from './assets/USD.png';
import ZAFlag from './assets/ZAR.png';

const getCountryFlagByCurrency = (country: Currencies) =>
  ({
    [Currencies.AED]: AEFlag,
    [Currencies.AUD]: AUFlag,
    [Currencies.BGN]: BGFlag,
    [Currencies.CAD]: CAFlag,
    [Currencies.CHF]: CHFlag,
    [Currencies.CNY]: CNFlag,
    [Currencies.CZK]: CZFlag,
    [Currencies.DKK]: DKFlag,
    [Currencies.EUR]: EUFlag,
    [Currencies.GBP]: UKFlag,
    [Currencies.ILS]: ILFlag,
    [Currencies.INR]: INFlag,
    [Currencies.JPY]: JPFlag,
    [Currencies.KES]: KEFlag,
    [Currencies.MXN]: MXFlag,
    [Currencies.NZD]: NZFlag,
    [Currencies.PHP]: PHFlag,
    [Currencies.QAR]: QAFlag,
    [Currencies.SAR]: SAFlag,
    [Currencies.THB]: THFlag,
    [Currencies.TRY]: TRFlag,
    [Currencies.UGX]: UGFlag,
    [Currencies.USD]: USFlag,
    [Currencies.ZAR]: ZAFlag,
  }[country]);

const MobileCurrencyFlag = ({ currency, size = 22 }: { currency: Currencies; size?: number }) => {
  if (!currency) return null;

  const flagImg = getCountryFlagByCurrency(currency);

  if (!flagImg) return null;

  return <img src={flagImg} height={size} width={size} />;
};

export default MobileCurrencyFlag;
