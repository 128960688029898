import React from 'react';
import { useHistory } from 'react-router-dom';

import { Banner } from 'components/UI';
import { BannerType } from 'components/UI/Banner';
import Warning from 'components/svg/Warning';
import { TimedComponentWrapper } from 'components/UI/TimedComponentWrapper';

const SolidMigrationBanner = () => {
  const history = useHistory();

  return (
    <TimedComponentWrapper startAt="2024-09-30" endAt="2024-10-31">
      <Banner
        type={BannerType.actionRequired}
        title={'Important Update About Your USD Account'}
        message={
          <span>
            Your Loop USD Account Number has been updated. Please use your new Account Number for all transactions by
            clicking your <span className="tw-font-bold">Accounts </span>
            and <span className="tw-font-bold"> Bank Details </span> under your USD Account. For recurring payments,
            please ensure your information is updated with your vendors. We’ll monitor your old Account Number for 30
            days to manage any misdirected payments. Thank you for your understanding and continued partnership.
          </span>
        }
        icon={<Warning />}
        classNames={'tw-max-w-full tw-px-8 tw-py-4 tw-my-0'}
        submitButton={true}
        onSubmit={() => {
          history.push('/dashboard/accounts');
        }}
        submitButtonLabel={'View Accounts'}
      />
    </TimedComponentWrapper>
  );
};

export default SolidMigrationBanner;
