import React from 'react';

import { ApprovalsRow } from './components';
import {
  ApprovalRequestActionModal,
  ApprovalRequestDetailOverlay,
} from 'components/Approvals/ApprovalsContent/components';
import { ReimbursementTableProps } from './ReimbursementTable.types';
import { useReimbursementTable } from './hooks';
import { APPROVAL_ACTION_TYPE, APPROVAL_MODAL, APPROVAL_TAB } from 'components/Approvals/constants';
import { PayPayeeModal } from 'components/payments/desktop/payees/PayeesList';
import { PAY_PAYEE_SOURCE } from 'components/payments/PayPayee/constants';

const ReimbursementTable = ({ reimbursementRequestList, showActions = true }: ReimbursementTableProps) => {
  const {
    selectedRequest,
    isOpenModal,
    handleOpenModal,
    handleCloseModal,
    activeModal,
    isAdmin,
    isMember,
    noApprovalsAvailable,
    selectedTab,
    payees,
    wallets,
    lineOfCredit,
    bankAccounts,
    viewOnly,
  } = useReimbursementTable({
    reimbursementRequestList,
  });

  if (noApprovalsAvailable) {
    return (
      <div className="tw-border-t tw-border-neutral-grey-3 tw-pt-4">
        {selectedTab == APPROVAL_TAB.expenseReimbursements
          ? 'You have no pending approvals at this moment'
          : 'You have no completed approvals at this moment'}
      </div>
    );
  }

  return (
    <>
      <table className="tw-table tw-table-auto tw-w-full">
        <thead>
          <tr>
            <th className="tw-w-24 tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">
              Request Type
            </th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Requester</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Amount</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Currency</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Merchant</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Request Date</th>
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Status</th>
            {!viewOnly && showActions && (
              <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-center">Action</th>
            )}
          </tr>
        </thead>
        <tbody>
          {reimbursementRequestList.map((request) => (
            <ApprovalsRow key={request.id} request={request} openModal={handleOpenModal} />
          ))}
        </tbody>
      </table>
      <ApprovalRequestDetailOverlay requestList={reimbursementRequestList} />
      {isAdmin && (
        <>
          <ApprovalRequestActionModal
            show={isOpenModal && activeModal === APPROVAL_MODAL.approveReimbursement}
            onClose={handleCloseModal}
            approvalRequest={selectedRequest}
            actionType={APPROVAL_ACTION_TYPE.approve}
          />
          <ApprovalRequestActionModal
            show={isOpenModal && activeModal === APPROVAL_MODAL.declineReimbursement}
            onClose={handleCloseModal}
            approvalRequest={selectedRequest}
            actionType={APPROVAL_ACTION_TYPE.decline}
          />
          <PayPayeeModal
            wallets={wallets}
            lineOfCredit={lineOfCredit}
            payees={payees}
            show={isOpenModal && activeModal === APPROVAL_MODAL.approveAndPayReimbursement}
            onClose={handleCloseModal}
            initialPayeeId={undefined}
            bankAccounts={bankAccounts}
            source={PAY_PAYEE_SOURCE.reimbursements}
            reimbursement={selectedRequest}
          />
        </>
      )}
      {isMember && (
        <ApprovalRequestActionModal
          show={isOpenModal && activeModal === APPROVAL_MODAL.cancelReimbursement}
          onClose={handleCloseModal}
          approvalRequest={selectedRequest}
          actionType={APPROVAL_ACTION_TYPE.cancel}
        />
      )}
    </>
  );
};
export default ReimbursementTable;
