import React from 'react';
import cx from 'classnames';

import { Theme } from 'styles/tailwind';
import LoopLogo from 'components/svg/LoopLogo';
import config from 'config';
import { LayoutProps } from './Layout.types';

import styles from './Layout.module.scss';
import cardImage from 'images/card-with-currencies-and-balance.png';
import { useLocation } from 'react-router-dom';

const Footer = () => (
  <div className="tw-flex tw-flex-grow-0 tw-self-end tw-items-center tw-justify-end tw-pb-4">
    <small className="tw-text-neutral-grey-2">{config.year} © Loop. ALL Rights Reserved. </small>
    <a className="tw-ml-8" href={config.baseLegalUrl} target="_blank" rel="noreferrer">
      <small className="tw-underline tw-text-neutral-grey-2">Legal </small>
    </a>
  </div>
);

const LeftSection = ({
  text = '',
  children,
}: {
  text?: LayoutProps['leftSectionText'];
  children?: LayoutProps['leftSectionCustomContent'];
}) => {
  const location = useLocation();
  const isSourceRegisterWithCode = location.pathname.includes('apply');
  const cardImageClassName = cx(
    !isSourceRegisterWithCode && 'tw-mt-auto',
    'tw-mb-5 tw-ml-5 tw-mr-10 tw-max-h-full tw-overflow-hidden'
  );
  return (
    <section
      className={cx(
        'left-section tw-hidden lg:tw-flex tw-flex-1 tw-flex-col tw-w-2/5 tw-pt-11 tw-px-3 tw-items-center tw-bg-primary-dark-green',
        styles.leftSection
      )}
    >
      {children || (
        <>
          <div className="tw-max-h-full">
            <LoopLogo color="#ffffff" width="9.5rem" height="3.3rem" />
          </div>
          <div className="tw-flex tw-items-center tw-justify-center tw-mt-24 tw-mb-10">{text}</div>
          <div className={cardImageClassName}>
            <img className={styles.image} src={cardImage} alt="Multi currencies credit card" />
          </div>
        </>
      )}
    </section>
  );
};

const RightSection = ({
  children,
  className,
}: {
  children: LayoutProps['children'];
  className: LayoutProps['contentClassName'];
}) => (
  <section className="tw-bg-neutral-light tw-flex tw-flex-col tw-w-full lg:tw-w-3/5 tw-items-center tw-overflow-y-auto tw-pt-11 tw-px-3">
    <div className={cx('tw-flex tw-flex-grow tw-flex-col tw-w-full tw-items-center', className)}>
      <div className="tw-w-full">{children}</div>
    </div>
    <Footer />
  </section>
);

const Layout = ({
  children,
  leftSectionText,
  leftSectionCustomContent,
  contentClassName = 'tw-justify-center lg:tw-w-1/2',
}: LayoutProps) => (
  <Theme>
    <div className="tw-min-h-screen tw-w-full tw-flex tw-flex-row">
      <LeftSection text={leftSectionText}>{leftSectionCustomContent}</LeftSection>
      <RightSection className={contentClassName}>{children}</RightSection>
    </div>
  </Theme>
);

export default Layout;
