import { useContext } from 'react';

import { CreateCardContext } from 'components/creditCards/components/CreateCardModal/context/CreateCardContext';

const useCardCreationLimit = () => {
  const { onClose } = useContext(CreateCardContext);

  return {
    onClose,
  };
};

export default useCardCreationLimit;
