import React, { useEffect } from 'react';

import DocuSignIframe from 'components/DocuSign/Content';
import { Country } from 'types/coreExternalAccount';
import { CATaxDeclarationProps } from './CATaxDeclaration.types';
import { useTaxDeclaration } from './hooks';
import { Loaders } from 'components/cards/Loader';

const CATaxDeclaration = ({ onNextStep, enableFullScreen, disableFullScreen }: CATaxDeclarationProps) => {
  const { signingUrl, isError, isLoading, isCompleted, agreementName } = useTaxDeclaration({
    enableFullScreen,
    disableFullScreen,
    country: Country.CA,
  });

  useEffect(() => {
    if (isCompleted) {
      onNextStep();
    }
  }, [isCompleted, onNextStep]);

  return (
    <div>
      <div className="tw-flex tw-flex-col tw-gap-8 tw-p-8">
        <div>{isLoading ? <Loaders.Spinner /> : <div className="tw-font-bold tw-mb-2">{agreementName}</div>}</div>
        <div className="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-pt-4 tw-flex tw-justify-center">
          <div className="tw-text-sm">Please complete and sign the document below required for account opening.</div>
        </div>

        <DocuSignIframe iframeUrl={signingUrl} isLoading={isLoading} isError={isError} isCompleted={isCompleted} />
      </div>
    </div>
  );
};

export default CATaxDeclaration;
