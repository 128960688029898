import { useMemo, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

import { GET_WALLETS_AND_BANK_ACCOUNTS } from 'graphql/wallets';
import { useDeepEffect } from 'hooks';
import { useGetRecurringPaymentInfo, useCreateOrUpdateRecurringPayment } from 'components/payments/CardAutopay/hooks';
import { PayBalanceCurrencyOptions, PayBalanceType } from 'types/payments';
import { Currencies } from 'constants/currencies';
import { CardAutopayContext } from 'components/payments/CardAutopay/context/CardAutopay';

const useNotEnabled = ({ openEnableAutopayModal }: { openEnableAutopayModal: () => void }) => {
  const { data, loading: loadingWalletsAndBankAccounts } = useQuery(GET_WALLETS_AND_BANK_ACCOUNTS);
  const { onSubmitRecurringPayment, loadingCreateCardRecurringPayment, loadingUpdateRecurringPayment } =
    useCreateOrUpdateRecurringPayment();

  const { loadingChargeNowData, chargeNow, loadingRecurringPaymentData, formattedNextPaymentDate } =
    useGetRecurringPaymentInfo();

  const wallets = get(data, 'me.account.wallets', []);
  const bankAccounts = get(data, 'me.account.bankAccounts', []);
  const allowedBankAccounts = bankAccounts.filter(
    ({ currency }: { currency: Currencies }) => currency === Currencies.CAD
  );

  const form = useForm();
  const { register, control, handleSubmit } = form;

  const selectedFromAccountId = useWatch<string>({ control, name: 'fromAccount' }) || '';
  const watchPaymentCurrencyOption = useWatch<PayBalanceCurrencyOptions>({ control, name: 'paymentCurrencyOption' });
  const watchPaymentType = useWatch<PayBalanceType>({ control, name: 'paymentType' });

  const { setFromAccountId, setBankAccounts, setWallets, setPaymentCurrency, setPaymentType } =
    useContext(CardAutopayContext);

  useDeepEffect(() => {
    setFromAccountId(selectedFromAccountId);
    setBankAccounts(allowedBankAccounts);
    setWallets(wallets);
  }, [selectedFromAccountId, wallets, allowedBankAccounts]);

  useEffect(() => {
    setPaymentCurrency(watchPaymentCurrencyOption);
    setPaymentType(watchPaymentType);
  }, [watchPaymentCurrencyOption, watchPaymentType]);

  let selectedPaymentCurrencyOption: string;

  switch (watchPaymentCurrencyOption) {
    case PayBalanceCurrencyOptions.ALL_IN_CAD:
      selectedPaymentCurrencyOption = Currencies.CAD;
      break;
    case PayBalanceCurrencyOptions.ALL_IN_USD:
      selectedPaymentCurrencyOption = Currencies.USD;
      break;
    default:
      selectedPaymentCurrencyOption = '';
  }

  const fromAccountOptionsOfSameCurrency = useMemo(() => {
    return [...wallets, ...allowedBankAccounts]
      .filter(({ currency }) => currency === selectedPaymentCurrencyOption)
      .map(({ id, displayName }) => {
        return {
          value: id,
          name: displayName,
        };
      });
  }, [selectedPaymentCurrencyOption]);

  const history = useHistory();
  const onNavigateBack = () => history.goBack();

  const handleSubmitFunction = chargeNow ? openEnableAutopayModal : onSubmitRecurringPayment;

  return {
    form,
    register,
    loading:
      loadingWalletsAndBankAccounts ||
      loadingCreateCardRecurringPayment ||
      loadingUpdateRecurringPayment ||
      loadingChargeNowData,
    fromAccountOptionsOfSameCurrency,
    selectedPaymentCurrencyOption,
    onNavigateBack,
    handleSubmit,
    handleSubmitFunction,
    loadingRecurringPaymentData,
    formattedNextPaymentDate,
  };
};

export default useNotEnabled;
