import React from 'react';

const Attachment = ({ size = 32, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13 4H17.1719C17.7021 4.00008 18.2106 4.21073 18.5856 4.58563L27.4144 13.4144C27.7893 13.7894 27.9999 14.2979 28 14.8281V27C28 27.7956 27.6839 28.5587 27.1213 29.1213C26.5587 29.6839 25.7956 30 25 30H12C11.2044 30 10.4413 29.6839 9.87868 29.1213C9.31607 28.5587 9 27.7956 9 27V19"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 4.5V12C18 12.5304 18.2107 13.0391 18.5858 13.4142C18.9609 13.7893 19.4696 14 20 14H27.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5V14.5C10.0026 14.6977 9.96558 14.8939 9.89112 15.0771C9.81666 15.2603 9.70627 15.4267 9.56646 15.5665C9.42665 15.7063 9.26026 15.8167 9.07709 15.8911C8.89393 15.9656 8.6977 16.0026 8.5 16C7.75 16 7 15.4312 7 14.5V5.5C7 3.58812 8.03562 2 10 2C11.9644 2 13 3.55 13 5.46125V14.1331C13 16.8206 11.2613 19 8.5 19C5.73875 19 4 16.8213 4 14.1331V9"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Attachment;
