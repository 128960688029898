import React from 'react';

const Warning = ({ size = '24', ...other }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M4.01108 20.918H19.9889C20.2484 20.9179 20.5035 20.8506 20.7291 20.7224C20.9548 20.5943 21.1434 20.4099 21.2765 20.1871C21.4095 19.9643 21.4825 19.7108 21.4883 19.4513C21.494 19.1919 21.4324 18.9354 21.3094 18.7069L13.3209 3.87094C12.7542 2.81906 11.2458 2.81906 10.6791 3.87094L2.69062 18.7069C2.56759 18.9354 2.50595 19.1919 2.51172 19.4513C2.51749 19.7108 2.59047 19.9643 2.72353 20.1871C2.85658 20.4099 3.04516 20.5943 3.27084 20.7224C3.49652 20.8506 3.75158 20.9179 4.01108 20.918Z"
        fill="#EEA530"
        stroke="#EEA530"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7308 9.15917L11.9998 14.8779L12.2684 9.16151C12.2701 9.12497 12.2643 9.08847 12.2514 9.05425C12.2384 9.02002 12.2187 8.9888 12.1933 8.96247C12.1679 8.93615 12.1374 8.91528 12.1036 8.90115C12.0699 8.88701 12.0336 8.87991 11.997 8.88026V8.88026C11.9611 8.88061 11.9256 8.88816 11.8926 8.90246C11.8596 8.91676 11.8299 8.93752 11.805 8.96352C11.7802 8.98952 11.7609 9.02023 11.7481 9.05383C11.7353 9.08743 11.7295 9.12325 11.7308 9.15917V9.15917Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18.1211C11.9135 18.1211 11.8289 18.0954 11.7569 18.0474C11.685 17.9993 11.6289 17.931 11.5958 17.851C11.5627 17.7711 11.554 17.6831 11.5709 17.5982C11.5878 17.5134 11.6295 17.4354 11.6906 17.3742C11.7518 17.3131 11.8298 17.2714 11.9147 17.2545C11.9995 17.2376 12.0875 17.2463 12.1674 17.2794C12.2474 17.3125 12.3157 17.3686 12.3638 17.4405C12.4118 17.5125 12.4375 17.5971 12.4375 17.6836C12.4375 17.7996 12.3914 17.9109 12.3094 17.993C12.2273 18.075 12.116 18.1211 12 18.1211Z"
        fill="#26262C"
        stroke="white"
      />
    </svg>
  );
};

export default Warning;
