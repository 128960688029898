import React, { useMemo } from 'react';

import { Tooltip } from '..';
import { Content } from './components';
import { ProgressChartProps } from './ProgressChart.types';

const ProgressChart = ({
  id,
  totalAmount,
  currentAmount,
  className,
  heightClassName = 'tw-h-2',
  primaryBgClassName = 'tw-bg-primary-dark-green',
  secondaryBgClassName = 'tw-bg-neutral-grey-4',
  showTooltip = false,
  tooltipPlace,
}: ProgressChartProps) => {
  const width = useMemo(() => {
    if (totalAmount === 0 || currentAmount === 0 || currentAmount > totalAmount) return 0;

    const ratio = (currentAmount / totalAmount) * 100;
    return parseFloat(ratio.toFixed(1));
  }, [currentAmount, totalAmount]);

  if (!showTooltip) {
    return (
      <Content
        className={className}
        heightClassName={heightClassName}
        primaryBgClassName={primaryBgClassName}
        secondaryBgClassName={secondaryBgClassName}
        width={width}
      />
    );
  }

  return (
    <Tooltip
      id={id}
      place={tooltipPlace}
      target={
        <Content
          className={className}
          heightClassName={heightClassName}
          primaryBgClassName={primaryBgClassName}
          secondaryBgClassName={secondaryBgClassName}
          width={width}
        />
      }
    >
      {width}%
    </Tooltip>
  );
};

export default ProgressChart;
