export const CURRENCY_ACCOUNT = {
  account_type: 'Currency Account',
  description:
    'Store funds, convert your funds to other currencies, bill customers and make payments to suppliers around the world.',
};

export const BUSINESS_OPERATING_ACCOUNT = {
  account_type: 'Operating Account',
  description:
    'Upgrade to receive local account details (including a sort code, IBAN, routing number, and transit number) that you can use to receive payments or pay with pre-authorized debit.',
};
