import { Transaction, PayBalanceCurrency } from 'types/payments';

const useAccounts = ({ transactions }: { transactions: Transaction[] }) => {
  const txnAmountsByCurrency = transactions.reduce((acc, txn) => {
    const { originalAmount, fxAmount, selectedFromAccountId, isFromBankAccount } = txn;

    if (!selectedFromAccountId || isFromBankAccount) return acc;

    const amount = fxAmount?.amount || originalAmount.amount;
    const currency = fxAmount?.currency || originalAmount.currency;

    if (!acc[currency]) {
      acc[currency] = 0;
    }
    acc[currency] += amount;
    return acc;
  }, {} as Record<PayBalanceCurrency, number>);

  return { txnAmountsByCurrency };
};

export default useAccounts;
