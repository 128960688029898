import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';
import { IoAddCircleOutline } from 'react-icons/io5';
import cx from 'classnames';

import { Loaders } from 'components/cards/Loader';
import { useTransactionCategory } from './hooks';

import styles from './TransactionCategory.module.scss';

const { Button, Options, Option } = Listbox;

const TransactionCategory = ({ transaction }) => {
  const {
    transactionCategory,
    handleSelectCategory,
    transactionCategories,
    loading,
    error,
    getIcon,
    isMemberBool,
    handleNewCategory,
    isUpdatingTransactionCategory,
  } = useTransactionCategory({ transaction });

  return (
    <Listbox value={transactionCategory?.description} onChange={handleSelectCategory}>
      <div className={cx('tw-relative tw-pb-1 tw-w-full', styles.transactionCategory)}>
        <Button
          className="tw-flex tw-justify-end tw-items-center tw-cursor-pointer hover:tw-text-primary-dark-green tw-ml-auto"
          name="category"
        >
          {isUpdatingTransactionCategory ? (
            <Loaders.Spinner />
          ) : (
            <>
              {getIcon(transactionCategory?.loopId)}

              <div className="tw-text-sm tw-mx-1">{transactionCategory?.description}</div>

              {!isMemberBool && (
                <span className={styles.chevron} title="Change the category">
                  <FaChevronDown />
                </span>
              )}
            </>
          )}
        </Button>

        {loading && <div>Loading...</div>}
        {error && <div>Error loading...</div>}

        {!isMemberBool && !loading && !error && (
          <Transition
            as={Fragment}
            leave="tw-transition tw-ease-in tw-duration-100"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <Options
              role="option"
              className="tw-absolute tw-h-80 tw-w-full tw-right-0 tw-z-10 tw-overflow-y-scroll tw-bg-neutral-light tw-rounded-md tw-shadow-2xl tw-shadow-inner tw-border-solid tw-border-2 tw-border-primary-light-green tw-scrollbar-hide focus-visible: tw-ouline-none focus:tw-outline-none"
            >
              {transactionCategories.map((category) => {
                const isSelected = transactionCategory?.transactionCategoryId === category?.transactionCategoryId;

                return (
                  <div key={category.transactionCategoryId}>
                    <Option
                      value={category}
                      className={cx(
                        'tw-px-2 tw-py-1 tw-cursor-pointer tw-text-neutral-grey-2 hover:tw-text-neutral-light hover:tw-bg-primary-dark-green',
                        isSelected && 'tw-text-primary-dark-green tw-bg-primary-light-green'
                      )}
                      selected={isSelected}
                    >
                      <span
                        className={cx('tw-inline-flex tw-items-center', isSelected ? 'tw-font-bold' : 'tw-font-normal')}
                      >
                        {getIcon(category?.loopId)}
                        <span className={`tw-ml-2 tw-mr-1`}>{category.description}</span>
                      </span>
                    </Option>
                  </div>
                );
              })}
              <div className="tw-p-1 tw-h-8 tw-flex tw-box-border tw-items-center tw-cursor-pointer tw-text-neutral-light tw-bg-primary-dark-green hover:tw-opacity-90">
                <IoAddCircleOutline className="tw-ml-1" />
                <span className="tw-ml-2" onClick={handleNewCategory}>
                  New Category
                </span>
              </div>
            </Options>
          </Transition>
        )}
      </div>
    </Listbox>
  );
};

export default TransactionCategory;
