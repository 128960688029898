import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

import { FirstPurchaseModalProps } from './FirstPurchaseModal.types';
import { useFirstPurchaseModal } from './hooks';
import { Loaders } from 'components/cards/Loader';
import { Modal } from 'components/UI';
import CupCardAndStars from 'components/svg/CupCardAndStars';
import Button, { IconAnimation } from 'components/Button';
import { CreateCardContextProvider } from 'components/creditCards/createCardFlow/CreateCardContext';
import NewCardDetailsStep from 'components/creditCards/createCardFlow/NewCardDetailsStep';

const FirstPurchaseModal = ({ show, onClose }: FirstPurchaseModalProps) => {
  const { showNextSection, hasCreditCard, handleClick, isLoadingCardsData, creditCards, globalCreditLimit } =
    useFirstPurchaseModal({ show });

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={showNextSection && !hasCreditCard ? 'Create Card' : 'Make Your First Purchase'}
    >
      {isLoadingCardsData && (
        <div className="tw-flex tw-justify-center">
          <Loaders.Light />
        </div>
      )}
      {!isLoadingCardsData && !showNextSection && (
        <div className="tw-p-8">
          <div className="tw-flex tw-justify-center">
            <CupCardAndStars />
          </div>
          <h3 className="tw-my-6">Your First Loop Card Purchase</h3>
          <p>
            With your new Loop card you can add funds or use your pre-approved credit to: spend in four currencies, pay
            suppliers, run ads, and you will earn points for every dollar spent! Click the link below to create your
            first card or view your existing card details.
          </p>
          <p className="tw-my-6">Set-up or view your Loop card:</p>
          <Button primary onClick={handleClick} iconAnimation={IconAnimation.rollOut}>
            Make Your First Purchase <BsArrowRight className="tw-ml-2" />
          </Button>
          <div className="tw-text-xs tw-mt-6">*Points may take up to 24 hours to appear on your account</div>
        </div>
      )}
      {!isLoadingCardsData && showNextSection && !hasCreditCard && (
        <CreateCardContextProvider>
          <NewCardDetailsStep
            onClose={onClose}
            creditCards={creditCards}
            globalCreditLimit={globalCreditLimit}
            // TODO: remove this refetch from the whole create card flow
            refetch
          />
        </CreateCardContextProvider>
      )}
    </Modal>
  );
};

export default FirstPurchaseModal;
