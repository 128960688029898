import React from 'react';

import LastStepModal from 'components/onboarding/LastStepModal';

const ConfirmationModal = ({ show, setShow, profileData, onComplete, loading }) => {
  const onConfirm = ({ exposedInPolitics, fundsInThirdParty }) =>
    onComplete({ ...profileData, exposedInPolitics, fundsInThirdParty });

  return <LastStepModal show={show} setShow={setShow} onSuccess={onConfirm} loading={loading} />;
};

export default ConfirmationModal;
