import React from 'react';

import DashboardLayout from 'components/dashboard/DashboardLayout';
import { ApprovalsContent } from './ApprovalsContent';
import { ApprovalRequestsContextProvider, FilterRequestsContextProvider } from './ApprovalsContent/context';

const Approvals = () => {
  return (
    <DashboardLayout title="Approvals">
      <ApprovalRequestsContextProvider>
        <FilterRequestsContextProvider>
          <ApprovalsContent />
        </FilterRequestsContextProvider>
      </ApprovalRequestsContextProvider>
    </DashboardLayout>
  );
};

export default Approvals;
