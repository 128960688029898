import { gql } from '@apollo/client';

// Users and Contacts are different
// A user will always have a contact
// But a contact will not always have a user
export const GET_USERS = gql`
  query Users {
    me {
      ... on User {
        account {
          contacts {
            id
            email
            firstName
            lastName
            invitedToLoop
            user {
              id
              email
              roles
              firstName
              lastName
              enabled
              superAdmin
              permissions {
                manageBankAccounts
                manageExternalAccounts
                manageLinesOfCredit
                managePayments
              }
            }
          }
        }
      }
    }
  }
`;

export const CHANGE_EMAIL = gql`
  mutation ChangeEmail($currentPassword: String!, $newEmail: String!) {
    changeEmail(currentPassword: $currentPassword, newEmail: $newEmail)
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
    changePassword(currentPassword: $currentPassword, password: $password, passwordConfirmation: $passwordConfirmation)
  }
`;
export const CHANGE_PHONE = gql`
  mutation ChangePhoneNumber($currentPassword: String!, $newPhoneNumber: String!) {
    changePhoneNumber(currentPassword: $currentPassword, newPhoneNumber: $newPhoneNumber)
  }
`;
export const UPDATE_MFA = gql`
  mutation SetMFAPreferences($smsEnabled: Boolean!) {
    setMfaPreferences(smsEnabled: $smsEnabled)
  }
`;
export const VERIFY_ATTRIBUTE = gql`
  mutation VerifyAttribute($attributeName: VerifiableAttributeEnum!, $verificationCode: String!) {
    verifyAttribute(attributeName: $attributeName, verificationCode: $verificationCode)
  }
`;

export const SEND_VERIFICATION_CODE = gql`
  mutation SendAttributeVerificationCode($attributeName: VerifiableAttributeEnum!) {
    sendAttributeVerificationCode(attributeName: $attributeName)
  }
`;

export const GET_MEMBERS_AND_INVITATIONS = gql`
  query MembersAndInvitations {
    me {
      ... on User {
        account {
          internalContacts {
            id
            firstName
            lastName
            role
            status
            email
            positions
            user {
              lastActiveAt
            }
            permissions
            rules {
              paymentApprovalAmount {
                amount
                currency
              }
            }
            manager {
              id
              firstName
              lastName
            }
          }
          teamInvitations {
            id
            firstName
            lastName
            email
            role
            status
            positions
            internalContactId
            permissions
          }
        }
      }
    }
  }
`;

export const GET_ADMIN_NOTIFICATION_SETTINGS = gql`
  query AdminNotificationSettings {
    adminNotificationSettings {
      card {
        approvedCardTransaction {
          emailContacts {
            id
            email
            name
            role
            status
          }
          smsContacts {
            id
            email
            name
            role
            status
          }
        }
        refundedCardTransaction {
          emailContacts {
            id
            email
            name
            role
            status
          }
        }
        cancelledCardTransaction {
          emailContacts {
            id
            email
            name
            role
            status
          }
        }
        memberAddedToCard {
          emailContacts {
            id
            email
            name
            role
            status
          }
        }
        newCardCreated {
          emailContacts {
            id
            email
            name
            role
            status
          }
        }
        paymentDue {
          emailContacts {
            id
            email
            name
            role
            status
          }
        }
      }
      capital {
        paymentDue {
          emailContacts {
            id
            email
            name
            role
            status
          }
        }
      }
      account {
        depositReceived {
          emailContacts {
            id
            email
            name
            role
            status
          }
        }
        withdrawalCreated {
          emailContacts {
            id
            email
            name
            role
            status
          }
        }
      }
      payment {
        payeePaymentCreated {
          emailContacts {
            id
            email
            name
            role
            status
          }
        }
        payeePaymentSent {
          emailContacts {
            id
            email
            name
            role
            status
          }
        }
      }
    }
  }
`;

export const GET_NOTIFICATION_SETTINGS = gql`
  query NotificationSettings($internalContactId: ID!) {
    notificationSettings(internalContactId: $internalContactId) {
      id
      notificationSettings {
        card {
          approvedCardTransaction {
            email
            sms
            period
          }
          refundedCardTransaction {
            email
            sms
            period
          }
          cancelledCardTransaction {
            email
            sms
            period
          }
          memberAddedToCard {
            email
            sms
            period
          }
          newCardCreated {
            email
            sms
            period
          }
          paymentDue {
            email
            sms
            period
          }
        }
        capital {
          paymentDue {
            email
            sms
            period
          }
        }
        account {
          depositReceived {
            email
            sms
            period
          }
          withdrawalCreated {
            email
            sms
            period
          }
        }
        payment {
          payeePaymentCreated {
            email
            sms
            period
          }
          payeePaymentSent {
            email
            sms
            period
          }
        }
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation UpdateNotificationSettings($notificationSettings: [NotificationSettingsInput!]!) {
    updateNotificationSettings(notificationSettings: $notificationSettings)
  }
`;

export const UPDATE_CUSTOMER_FILES_UPLOAD_EMAIL = gql`
  mutation UpdateLoopEmailAlias($loopEmailLocalPart: String!) {
    updateLoopEmailAlias(loopEmailLocalPart: $loopEmailLocalPart)
  }
`;
