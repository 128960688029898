import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import ExternalWalletAccount from 'domain/externalWalletAccount';
import { GET_EXTERNAL_WALLET_ACCOUNT_DETAILS } from 'graphql/wallets';
import USDBankAccountDetails from 'components/wallets/USDBankAccountDetails';

const USDBankAccountDetailsContainer = ({ accountInternalId }) => {
  const { loading, data } = useQuery(GET_EXTERNAL_WALLET_ACCOUNT_DETAILS, { variables: { accountInternalId } });
  const walletExternalAccount = new ExternalWalletAccount(_.get(data, 'walletExternalAccount'));

  return <USDBankAccountDetails loading={loading} walletExternalAccount={walletExternalAccount} />;
};

export default USDBankAccountDetailsContainer;
