const clearDomainCookies = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
};

const DEFAULT_COOKIE_DURATION = 24 * 60 * 60 * 1000; // 1 day

const setCookie = <T>(name: string, value: T, duration = DEFAULT_COOKIE_DURATION) => {
  const date = new Date();
  date.setTime(date.getTime() + duration);
  const expires = date.toUTCString();

  const stringValue = JSON.stringify(value);

  document.cookie = `${name}=${stringValue || ''};expires=${expires};path=/`;
};

const getCookie = <T>(name: string): T | null => {
  const nameEQ = name + '=';
  const cookies = document.cookie.split(';').map((cookie) => cookie.trim());

  for (let cookie of cookies) {
    if (cookie.indexOf(nameEQ) === 0) {
      try {
        return JSON.parse(cookie.substring(nameEQ.length, cookie.length));
      } catch (e) {
        console.error(`Error parsing cookie ${name}`, e);
        return null;
      }
    }
  }
  return null;
};

export { clearDomainCookies, setCookie, getCookie };
