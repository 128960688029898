import React from 'react';

import { Modal } from 'components/UI';
import CreateReimbursementRequest from './CreateReimbursementRequest';
import { ReimbursementRequestModalProps } from './ReimbursementRequestModal.types';
import { CreateReimbursementRequestContextProvider } from './context/CreateReimbursementRequest';

const ReimbursementRequestModal = ({ show, onClose }: ReimbursementRequestModalProps) => {
  return (
    <Modal show={show} onClose={onClose} title="Reimbursement Request">
      <div className="tw-flex tw-flex-col tw-pt-4 tw-border-t tw-border-neutral-grey-4">
        <CreateReimbursementRequestContextProvider>
          <CreateReimbursementRequest onFinish={onClose} />
        </CreateReimbursementRequestContextProvider>
      </div>
    </Modal>
  );
};

export default ReimbursementRequestModal;
