import React from 'react';

import InitialsCircle from 'components/InitialsCircle';
import { CreditCardContact } from 'types/creditCard';
import { InternalContact, InternalContactRole, InternalContactStatus } from 'types/user';

export const getCustomOptionBarItemLabel = (name: string) => {
  const initials = name
    .split(' ')
    .filter((_, i) => i < 2)
    .map((word) => word[0])
    .join('');

  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-gap-1">
      <div className="tw-hidden lg:tw-block">
        <InitialsCircle.Small initials={initials} />
      </div>
      <span>{name}</span>
    </div>
  );
};

export const getCustomOptionLabel = (name: string, roleLabel?: string) => {
  if (!roleLabel) return name;

  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-gap-1">
      {name}
      <span className="tw-text-sm tw-text-neutral-grey-2">({roleLabel})</span>
    </div>
  );
};

export const canCardMembersBeDeleted = (member: CreditCardContact) => {
  return ![InternalContactRole.account_owner, InternalContactRole.admin].includes(member.role);
};

export const getMemberRoleLabel = (role: InternalContactRole) =>
  ({
    [InternalContactRole.account_owner]: 'Account Owner',
    [InternalContactRole.admin]: 'Admin',
    [InternalContactRole.member]: 'Member',
    [InternalContactRole.bookkeeper]: 'Bookkeeper',
    [InternalContactRole.read_only]: 'Read Only',
  }[role] || '');

export const mapMembersToOptions = (members: Pick<CreditCardContact, 'id' | 'firstName' | 'lastName' | 'role'>[]) =>
  members.map(({ id, firstName, lastName, role }) => {
    const isAlwaysSelected = [InternalContactRole.account_owner, InternalContactRole.admin].includes(role);
    return { value: id, label: `${firstName} ${lastName}`, isAlwaysSelected, roleLabel: getMemberRoleLabel(role) };
  });

export const sortCardMembers = (members: (CreditCardContact | InternalContact)[]) =>
  [...members].sort((a, b) => {
    if ([InternalContactRole.account_owner, InternalContactRole.admin].includes(a.role)) return -1;
    if ([InternalContactRole.account_owner, InternalContactRole.admin].includes(b.role)) return 1;
    return 0;
  });

export const getAllCreditCardMembers = ({
  members,
  internalContacts,
}: {
  members: CreditCardContact[];
  internalContacts: InternalContact[];
}): Pick<InternalContact, 'id' | 'firstName' | 'lastName' | 'role' | 'status'>[] => {
  const adminContacts = internalContacts.filter(
    ({ status, role }) =>
      status === InternalContactStatus.active &&
      [InternalContactRole.account_owner, InternalContactRole.admin].includes(role)
  );

  const existingCardMembersOnly = members.filter(({ role }) => role === InternalContactRole.member);

  return sortCardMembers([...adminContacts, ...existingCardMembersOnly]);
};
