import React from 'react';

const Details = ({ requesterName, addressLabel }: { requesterName?: string; addressLabel: string }) => {
  if (!requesterName) return null;

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-14 tw-text-neutral-light tw-pt-16 tw-pb-8">
      <div className="tw-flex tw-justify-center">
        <div className="lg:tw-w-3/4 xl:tw-w-2/3">
          <h4 className="tw-font-bold tw-text-neutral-light tw-mb-4">{requesterName} wants to pay you!</h4>
          <div>
            {requesterName} has requested your payment details. Once you submit the information requested they will be
            able to send payments directly to your account.
          </div>
        </div>
      </div>

      <div className="tw-flex tw-justify-center tw-border-t-2 tw-border-neutral-light tw-pt-10 tw-w-full">
        <div className="lg:tw-w-3/4 xl:tw-w-2/3">
          <h4 className="tw-font-bold tw-text-neutral-light tw-mb-4">
            Enter your direct payment details so {requesterName} can send payments to you!
          </h4>
          <div>
            Just <span className="tw-font-bold">two</span> easy steps and then you can start receiving payments:
            <ul className="tw-font-bold tw-p-0 tw-m-0 tw-my-4">
              <li className="tw-flex tw-gap-6 tw-mb-4">
                <div className="tw-w-16">Step 1.</div>
                <div>Banking Information</div>
              </li>
              <li className="tw-flex tw-gap-6 tw-mb-4">
                <div className="tw-w-16">Step 2.</div>
                <div>{addressLabel}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
