import React from 'react';

import { Modal } from 'components/UI';
import { UpdateCardLimitSettingsModalProps } from '../CreditCardLimitSettingsContent.types';
import LimitSettingsForm from './LimitSettingsForm';

const LimitSettingsModal = ({
  creditCard,
  creditCardLimitSettings,
  setCreditCardLimitSettings,
  show,
  toggleModal,
}: UpdateCardLimitSettingsModalProps) => {
  return (
    <Modal show={show} onClose={toggleModal} title="Set Auto-Freeze Rule">
      <div className="tw-border-b tw-border-neutral-grey-4">
        <div className="tw-flex tw-flex-col tw-px-8 tw-py-8">
          <p>
            Please enter a monthly or lifetime spending limit for this card. Once this card exceeds the limit, it will
            be frozen and be unable to make additional purchases until it is unfrozen or the limit reset on the 1st of
            each month.
          </p>
        </div>
        <LimitSettingsForm
          creditCard={creditCard}
          onClose={toggleModal}
          creditCardLimitSettings={creditCardLimitSettings}
          setCreditCardLimitSettings={setCreditCardLimitSettings}
        ></LimitSettingsForm>
      </div>
    </Modal>
  );
};

export default LimitSettingsModal;
