import React from 'react';

import Button from 'components/Button';

const EmptyState = ({ description, onGoBack }: { description: string; onGoBack: () => void }) => (
  <div className="tw-my-8 tw-px-8">
    <div className="tw-text-sm tw-text-neutral-grey-2">{description}</div>
    <Button primary className="tw-px-8 tw-my-8" onClick={onGoBack}>
      Close
    </Button>
  </div>
);

export default EmptyState;
