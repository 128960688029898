import { useContext } from 'react';
import { get } from 'lodash';

import { User, UserTypes } from 'types/user';
import { AuthContext } from 'context/Auth';
import { KYC_STATUS, onboardingStatus } from 'constants/index';
import { DOCUMENT_CATEGORY_VALUES } from 'components/onboarding/additionalDetails/constants';

const { OTHERS } = DOCUMENT_CATEGORY_VALUES;

const useGetUserInfo = () => {
  const { me, meLoading, isLoading } = useContext(AuthContext) as unknown as {
    me: User;
    meLoading: boolean;
    isLoading: boolean;
  };

  const { firstName, lastName, __typename, email, account } = me || {};
  const userOnboardingStatus = get(me, 'account.onboardingStatus');
  const kycAssessmentStatus = get(me, 'account.kycAssessment.status');
  let kycFailedReasons = get(me, 'account.kycAssessment.reasons', [] as string[]);

  const isRegularUser = __typename === UserTypes.REGULAR_USER;
  const isPayorUser = __typename === UserTypes.PAYOR_USER;
  const isOnboardingCompleted = userOnboardingStatus === onboardingStatus.COMPLETED;
  const isKYCApproved = kycAssessmentStatus === KYC_STATUS.APPROVED;
  const hasKYCFailedReasons = !!kycFailedReasons?.length;
  const fullName = `${firstName} ${lastName}`;
  const { name } = account || {};

  if (
    (kycAssessmentStatus === KYC_STATUS.PENDING_DATA || kycAssessmentStatus === KYC_STATUS.DRAFT) &&
    !hasKYCFailedReasons
  ) {
    kycFailedReasons = [OTHERS];
  }

  return {
    isLoading: meLoading || isLoading,
    firstName,
    lastName,
    fullName,
    email,
    isRegularUser,
    isPayorUser,
    userOnboardingStatus,
    isOnboardingCompleted,
    isKYCApproved,
    kycFailedReasons,
    legalBusinessName: name,
  };
};

export default useGetUserInfo;
