import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { GET_CREDIT_CARDS_FOR_ACTIVATION } from 'graphql/cards';
import ActivateCard from 'components/creditCards/cardActions/ActivateCard';

const ActivateCardContainer = () => {
  const { loading, data } = useQuery(GET_CREDIT_CARDS_FOR_ACTIVATION, { fetchPolicy: 'no-cache' });
  const { me } = data || {};
  const creditCards = _.get(me, 'account.creditCards') || [];
  const inactivePhysicalCards = creditCards.filter((card) => !card.virtual && !card.settings.attributes.active);

  return <ActivateCard creditCards={inactivePhysicalCards} loading={loading} />;
};

export default ActivateCardContainer;
