import React from 'react';
import { BiSolidBadgeCheck } from 'react-icons/bi';

import {
  INITIAL_CARD_PERMISSIONS,
  INITIAL_ACCOUNT_PERMISSIONS,
  INITIAL_PAYMENT_PERMISSIONS,
  INITIAL_TEAMS_AND_SETTINGS_PERMISSIONS,
  BILLING_PERMISSIONS,
} from 'constants/index';
import ViewPermissionSection from './Components/ViewPermissionSection';

const UserPermissions = ({ permissions }: { permissions: string[] }) => {
  const permissionSections = [
    { title: 'Cards', sectionPermissions: INITIAL_CARD_PERMISSIONS },
    { title: 'Accounts', sectionPermissions: INITIAL_ACCOUNT_PERMISSIONS },
    { title: 'Payments', sectionPermissions: INITIAL_PAYMENT_PERMISSIONS },
    { title: 'Teams and Settings', sectionPermissions: INITIAL_TEAMS_AND_SETTINGS_PERMISSIONS },
    { title: 'Billing', sectionPermissions: BILLING_PERMISSIONS },
  ];
  return (
    <div className="tw-bg-neutral-grey-4 tw-rounded-md tw-my-6">
      <div className="tw-flex tw-flex-row tw-items-center tw-ml-3">
        <BiSolidBadgeCheck size={24} style={{ color: '#045b3f' }} className="tw-bg-primary-light-green tw-rounded-md" />
        <strong className="tw-flex tw-flex-col tw-my-4 tw-ml-3 tw-text-lg">User Permissions</strong>
      </div>
      {permissionSections.map((section) => (
        <ViewPermissionSection
          key={section.title}
          title={section.title}
          memberPermissions={permissions}
          sectionPermissions={section.sectionPermissions}
        />
      ))}
    </div>
  );
};

export default UserPermissions;
