import { useContext } from 'react';
import { get } from 'lodash';

import { AuthContext } from 'context/Auth';
import config from 'config';
import { User } from 'types/user';
import { ADMIN_DOMAINS_REGEX } from 'constants/featureToggles';

const useUnderDevelopmentRoutes = ({ route }: { route?: string } = {}) => {
  const { me, meLoading } = useContext(AuthContext) as unknown as { me: User; meLoading: boolean };
  const email = get(me, 'email');

  const isUnderDevelopmentRoute = route ? config.underDevelopmentRoutes.split(',').includes(route) : true;

  const isInternalUser = ADMIN_DOMAINS_REGEX.test(email);

  const isRouteAvailable = isInternalUser || !isUnderDevelopmentRoute;

  return { isRouteAvailable, loading: meLoading };
};

export default useUnderDevelopmentRoutes;
