import { useContext } from 'react';

import { PaymentRequestCurrency } from 'types/invoicing';
import { PaymentRequestsContext } from 'components/PayorPortal/PaymentRequests/contexts/PaymentRequestsContext';
import { usePaymentRequestFieldValues } from '../../../hooks';
import { TableRowProps } from '../TableRow.types';

const useTableRow = ({ paymentRequest, bankAccount }: Pick<TableRowProps, 'bankAccount' | 'paymentRequest'>) => {
  const {
    formattedAmount,
    currency,
    formattedDueDate,
    status,
    vendorName,
    bankAccountName,
    isAvailableForProcessing,
    frequencyType,
    isOngoing,
  } = usePaymentRequestFieldValues({
    paymentRequest,
    bankAccount,
  });

  const { noBankAccounts, requiredCADBankAccount, requiredUSDBankAccount } = useContext(PaymentRequestsContext);

  const isBankAccountConnected = !(
    noBankAccounts ||
    (currency === PaymentRequestCurrency.CAD && requiredCADBankAccount) ||
    (currency === PaymentRequestCurrency.USD && requiredUSDBankAccount)
  );

  return {
    vendorName,
    bankAccountName,
    formattedDueDate,
    formattedAmount,
    status,
    isOngoing,
    frequencyType,
    isBankAccountConnected,
    isAvailableForProcessing,
    currency,
  };
};

export default useTableRow;
