import React from 'react';

import { PersonaVerificationModalProps } from './PersonaVerificationModal.types';
import { Modal } from 'components/UI';
import { usePersonaEmbeddedData } from 'hooks';
import { PersonaVerification } from 'components/IdentityVerification/components';
import { MESSAGES } from 'components/IdentityVerification/constants';

const PersonaVerificationModal = ({
  show,
  onClose,
  internalContactId,
  onCompleted,
  message = MESSAGES.modal.openAccount,
}: PersonaVerificationModalProps) => {
  const { environmentId, inquiryId, referenceId, sessionToken, templateId, fields, isLoading, error } =
    usePersonaEmbeddedData({
      contactId: internalContactId,
      withContactFields: true,
    });

  return (
    <Modal show={show} onClose={onClose} title="Identity Verification">
      <div className="tw-p-8 tw-flex tw-flex-col tw-gap-y-2">
        <div className="tw-text-sm">{message}</div>
        <div className="tw-text-sm">Please have your government-issued ID ready to complete verification below.</div>
        <PersonaVerification
          inputDataError={error}
          environmentId={environmentId}
          isInputDataLoading={isLoading}
          inquiryId={inquiryId}
          referenceId={referenceId}
          sessionToken={sessionToken}
          templateId={templateId}
          fields={fields}
          onCompleted={onCompleted}
        />
      </div>
    </Modal>
  );
};

export default PersonaVerificationModal;
