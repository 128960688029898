import React from 'react';
import { Collapse } from 'react-collapse';
import cx from 'classnames';
import { BsChevronDown } from 'react-icons/bs';

import Button, { IconAnimation } from 'components/Button';
import InitialsCircle from 'components/InitialsCircle';
import { Actions, Details } from './components';
import { usePayorItem } from './hooks';
import { PayorItemProps } from './PayorItem.types';
import DocumentTextOutline from 'components/svg/DocumentTextOutline';

import styles from './PayorItem.module.scss';

const PayorItem = ({ payorId, openEditModal, openDeleteModal, openAddPaymentRequestModal }: PayorItemProps) => {
  const {
    payor,
    isMobile,
    showDetails,
    toggleShowDetails,
    handleOpenEditModal,
    handleOpenDeleteModal,
    handleOpenAddPaymentRequestModal,
    isAllowedToManagePayors,
    isAllowedToRequestPayments,
    initials,
  } = usePayorItem({
    payorId,
    openEditModal,
    openDeleteModal,
    openAddPaymentRequestModal,
  });

  if (!payor) return null;

  const { id, name, email, referenceId } = payor;

  return (
    <div
      className={cx(styles.payorItem, isMobile ? 'tw-border-neutral-light' : 'tw-border-neutral-grey-4')}
      onClick={toggleShowDetails}
      aria-label="Toggle payor details"
      aria-expanded={showDetails}
      aria-controls={`payor-details-${id}`}
    >
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-w-full tw-flex tw-flex-col tw-place-items-start tw-gap-y-2 lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
          <div className="tw-flex tw-items-center">
            <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
              <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                <InitialsCircle.Small initials={initials} />
              </div>
            </div>
            <span>{name}</span>
          </div>
          {!showDetails && isAllowedToRequestPayments && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleOpenAddPaymentRequestModal();
              }}
              className="tw-flex tw-items-center tw-text-primary-dark-green tw-bg-primary-light-green tw-px-4 tw-py-1 tw-rounded-md"
              iconAnimation={IconAnimation.pulse}
            >
              <DocumentTextOutline className="tw-mr-2" />
              Request Payment
            </Button>
          )}
        </div>
        <div
          data-testid="toggle-open-close"
          className={cx(
            'tw-rounded-md tw-flex tw-justify-center tw-items-center tw-bg-neutral-light tw-border tw-border-neutral-grey-4 tw-p-2 tw-ml-4 tw-max-h-9',
            showDetails ? 'tw-bg-neutral-grey-4' : 'tw-bg-neutral-light'
          )}
        >
          <BsChevronDown
            className={cx(
              'tw-transform tw-ease-in-out tw-duration-200 tw-transition-all',
              `tw-rotate-${showDetails ? '180' : '0'}`
            )}
          />
        </div>
      </div>

      <Collapse isOpened={showDetails} aria-labelledby={`payor-details-${id}`}>
        <Details name={name} email={email} referenceId={referenceId} />
        <Actions
          openEditModal={handleOpenEditModal}
          openDeleteModal={handleOpenDeleteModal}
          openAddPaymentRequestModal={handleOpenAddPaymentRequestModal}
          isAllowedToManagePayors={isAllowedToManagePayors}
          isAllowedToRequestPayments={isAllowedToRequestPayments}
        />
      </Collapse>
    </div>
  );
};

export default PayorItem;
