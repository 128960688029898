import { useEffect, useContext } from 'react';

import { CADBankAccountOnboardingContext } from 'components/wallets/OpenCADAccountModal/contexts/CADBankAccountOnboardingContext';
import {
  useExternalAccountAgreements,
  useExternalAccountAgreementState,
} from 'components/wallets/OpenCADAccountModal/hooks';
import { UseTaxDeclarationProps } from '../CATaxDeclaration.types';
import { CADAgreementDocumentType, USDAgreementDocumentType, Country } from 'types/coreExternalAccount';

const useTaxDeclaration = ({ enableFullScreen, disableFullScreen, country }: UseTaxDeclarationProps) => {
  const { externalAccount } = useContext(CADBankAccountOnboardingContext);
  const externalWalletAccountId = externalAccount?.id;

  const {
    agreements,
    loading: agreementsLoading,
    error: agreementsError,
  } = useExternalAccountAgreements(externalWalletAccountId);

  const selectedAgreement = agreements?.find(({ documentType }) => {
    if (country === Country.CA) {
      return Object.values(CADAgreementDocumentType).includes(documentType as CADAgreementDocumentType);
    } else if (country === Country.US) {
      return Object.values(USDAgreementDocumentType).includes(documentType as USDAgreementDocumentType);
    }
  });

  const documentType = selectedAgreement?.documentType;
  const agreementName = selectedAgreement?.name;

  const {
    signingUrl,
    isAgreementSigned,
    isSigningFailed,
    error: agreementStateError,
    loading: agreementStateLoading,
  } = useExternalAccountAgreementState({
    externalWalletAccountId,
    agreements,
    selectedDocumentType: documentType,
  });

  useEffect(() => {
    enableFullScreen?.();
    return () => disableFullScreen?.();
  }, [enableFullScreen, disableFullScreen]);

  return {
    signingUrl,
    isCompleted: isAgreementSigned,
    isError: !signingUrl || !!agreementsError || !!agreementStateError || isSigningFailed,
    isLoading: agreementsLoading || agreementStateLoading,
    agreementName,
  };
};

export default useTaxDeclaration;
