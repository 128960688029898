import React from 'react';

import Banner, { BANNER_TYPES } from 'components/Banner';

const ReferrerBanner = ({ referrer }) => {
  if (referrer !== 'cards') return null;

  return (
    <Banner
      type={BANNER_TYPES.info}
      message={
        <div className="tw-flex tw-flex-col tw-items-start">
          <small>Connect a sales channel in order to apply for a credit limit.</small>
          <small>
            Your credit limit is based off the revenue earned through the sales channels below - so the more you
            connect, the higher your limit!
          </small>
        </div>
      }
    />
  );
};

export default ReferrerBanner;
