import React from 'react';

import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { ToggleDropdown } from 'components/UI';

const CurrenciesDropdown = ({ label, selectedValues, setSelectedValues, className, testId }) => {
  const currencies = ['CAD', 'USD', 'EUR', 'GBP'];

  return (
    <ToggleDropdown
      label={label}
      options={currencies}
      selectedOptions={selectedValues}
      setSelectedOptions={setSelectedValues}
      className={className}
      testId={testId}
      optionElement={(option) => (
        <>
          <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
            <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
              <MobileCurrencyFlag size={14} currency={option} />
            </div>
          </div>
          <div className="tw-text-sm">{option}</div>
        </>
      )}
    />
  );
};

export default CurrenciesDropdown;
