import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_BUSINESS_ADDRESS } from 'graphql/user';

const useGetBusinessAddress = () => {
  const { data: businessAddressData, loading: loadingBusinessAddress } = useQuery(GET_BUSINESS_ADDRESS);

  const account = get(businessAddressData, 'me.account', {});
  const { address } = account || {};
  const { street, unitNumber, city, countrySubdivision, country, postalCode } = address || {};

  return { loadingBusinessAddress, street, unitNumber, city, countrySubdivision, country, postalCode };
};

export default useGetBusinessAddress;
