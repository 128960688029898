import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];
const secondaryPastelGreenColor = theme.colors.secondary['pastel-green'][100];

const TeamIcon = (props) => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="4" y="4" width="48" height="48" rx="24" fill={secondaryPastelGreenColor} />
      <g clipPath="url(#clip0_10098_130532)">
        <path
          d="M34.8439 23.875C34.7065 25.7814 33.2923 27.25 31.7501 27.25C30.2079 27.25 28.7914 25.7819 28.6564 23.875C28.5157 21.8917 29.8925 20.5 31.7501 20.5C33.6078 20.5 34.9845 21.9278 34.8439 23.875Z"
          stroke={primaryDarkGreenColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.7498 30.25C28.695 30.25 25.7573 31.7673 25.0214 34.7223C24.9239 35.1133 25.169 35.5 25.5708 35.5H37.9293C38.3311 35.5 38.5748 35.1133 38.4787 34.7223C37.7428 31.72 34.8051 30.25 31.7498 30.25Z"
          stroke={primaryDarkGreenColor}
          strokeMiterlimit="10"
        />
        <path
          d="M25.375 24.7159C25.2653 26.2384 24.1225 27.4375 22.8906 27.4375C21.6587 27.4375 20.514 26.2389 20.4062 24.7159C20.2942 23.132 21.4065 22 22.8906 22C24.3746 22 25.487 23.1611 25.375 24.7159Z"
          stroke={primaryDarkGreenColor}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.6567 30.3438C24.8106 29.9562 23.8787 29.8071 22.8911 29.8071C20.4536 29.8071 18.1051 31.0188 17.5169 33.379C17.4395 33.6912 17.6355 34.0001 17.9561 34.0001H23.2192"
          stroke={primaryDarkGreenColor}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <rect x="4" y="4" width="48" height="48" rx="24" stroke="#E8F3ED" strokeWidth="8" />
      <defs>
        <clipPath id="clip0_10098_130532">
          <rect width="24" height="24" fill="white" transform="translate(13 16)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TeamIcon;
