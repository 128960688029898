import { EXTERNAL_TRANSACTION_CLEARING_MESSAGE } from 'constants/index';
import { PayBalanceCurrencyOptions } from 'types/payments';

export const PAYMENT_CURRENCY_OPTIONS = [PayBalanceCurrencyOptions.ALL_IN_CAD, PayBalanceCurrencyOptions.ALL_IN_USD];

export const PAYMENT_TYPE_OPTIONS = [
  {
    label: 'Statement Balance',
    value: 'statement_balance',
    name: 'Statement Balance',
  },
];

export const INFO_TOOLTIP_MESSAGE = {
  paymentCurrency: 'Pay your card balance in CAD or USD equivalent',
  fromAccounts: EXTERNAL_TRANSACTION_CLEARING_MESSAGE,
};
