import React, { useMemo } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Listbox, Transition } from '@headlessui/react';
import cx from 'classnames';

import { CollapsedOptionsProps } from './CollapsedOptions.types';

const CollapsedOptions = ({ options, selectedOptionId, onSelect }: CollapsedOptionsProps) => {
  const isSelectedIdAmongOptions = useMemo(
    () => options.some(({ id }) => id === selectedOptionId),
    [options, selectedOptionId]
  );

  return (
    <Listbox value={selectedOptionId} onChange={onSelect}>
      <Listbox.Button
        className={cx(
          'tw-flex tw-items-center tw-gap-2 tw-text-sm md:tw-text-base tw-font-semibold tw-justify-center tw-h-10 tw-transition-all tw-transition-300 tw-py-1 tw-px-4  tw-border tw-border-neutral-grey-4 tw-rounded-r tw-border-l-0',
          isSelectedIdAmongOptions ? 'tw-bg-secondary-pastel-yellow-50' : 'hover:tw-bg-neutral-grey-5'
        )}
      >
        <AiOutlinePlus size={22} />
        Select
      </Listbox.Button>
      <Transition
        unmount={false}
        as={React.Fragment}
        leave="tw-transition tw-ease-in tw-duration-100"
        leaveFrom="tw-opacity-100"
        leaveTo="tw-opacity-0"
      >
        <Listbox.Options
          unmount={false}
          className="tw-z-10 tw-absolute tw-right-0 tw-bg-neutral-light tw-mt-1 tw-max-h-56 tw-w-36 tw-flex tw-flex-col tw-overflow-y-auto tw-rounded-md tw-border tw-border-neutral-grey-4 tw-bg-white tw-text-base tw-shadow-lg sm:tw-text-sm"
        >
          {options.map(({ id, label }) => (
            <Listbox.Option
              key={id}
              value={id}
              className={cx(
                'tw-cursor-pointer tw-transition tw-ease-in tw-duration-300 tw-py-2 tw-px-4 tw-flex tw-flex-grow tw-text-sm md:tw-text-base',
                id === selectedOptionId
                  ? 'tw-bg-secondary-pastel-yellow-50 tw-font-semibold'
                  : 'hover:tw-bg-neutral-grey-5'
              )}
            >
              {label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
};

export default CollapsedOptions;
