import React, { useEffect } from 'react';
import { IoDownloadOutline } from 'react-icons/io5';
import { useLazyQuery } from '@apollo/client';
import { formatDateTime } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { PAYEE_PAYMENT_STATUS, PAYEE_RECORD_TYPES } from 'constants/index';
import {
  GET_PAYEE_PAYMENT_PDF,
  GET_PAYEE_PAYMENT_MT103,
  GET_PAYEE_PAYMENT_MT103_DOWNLOAD_URL,
} from 'graphql/payeePayments';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import InfoTooltip from 'components/InfoTooltip';
import fileDownload from 'utility/fileDownload';
import { Loaders } from 'components/cards/Loader.js';
import { PayeePaymentApprovals } from './components';

const PaymentConfirmationModal = ({
  show,
  setShow,
  payeePayment,
  payment,
  transferMethod,
  payeeName,
  paymentStatus,
  dateInitiated,
}) => {
  const {
    transaction,
    chargedPayment: chargedAmount,
    exchangeRate,
    payee,
    reason,
    payeePaymentApprovals,
  } = payeePayment || {};

  const amountWithdrawn = formatMoneyV2({ amount: chargedAmount?.amount, currency: chargedAmount?.currency });
  const chargedCurrency = chargedAmount?.currency;
  const { transactionId = '', completedAt } = transaction || {};

  const paymentDateLabel = completedAt ? 'Payment Sent Date' : 'Initiated At';
  const paymentPosted = paymentStatus === PAYEE_PAYMENT_STATUS.posted;

  const onGetPDFDownloadUrl = (data) => {
    const { downloadUrl } = data?.payeePaymentDownload || {};
    try {
      fileDownload(downloadUrl, `payee_payment_confirmation_${transactionId}.pdf`);
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong, please try again later.');
    }
  };

  const onGetMt103DownloadUrl = (data) => {
    const { downloadUrl, filename } = data?.payeePaymentMt103Download || {};

    try {
      fileDownload(downloadUrl, filename);
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong, please try again later.');
    }
  };

  const [getMt103Instruction, { loading: mt103Loading, data: mt103data }] = useLazyQuery(GET_PAYEE_PAYMENT_MT103, {
    variables: { payeePaymentId: payeePayment.id },
  });

  const [getPdfUrl, { loading: pdfDocumentLoading }] = useLazyQuery(GET_PAYEE_PAYMENT_PDF, {
    onCompleted: onGetPDFDownloadUrl,
    variables: { payeePaymentId: payeePayment.id },
  });

  const [getMt103Url, { loading: mt103DocumentLoading }] = useLazyQuery(GET_PAYEE_PAYMENT_MT103_DOWNLOAD_URL, {
    onCompleted: onGetMt103DownloadUrl,
    variables: { payeePaymentId: payeePayment.id },
  });

  useEffect(() => {
    if (show) getMt103Instruction();
  }, [show]);

  const onClose = () => setShow(false);

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <strong>Payment Confirmation</strong>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-px-8 tw-pt-6 tw-pb-8 tw-space-y-4">
        <span className="tw-text-xl tw-font-semibold">Payment Details</span>
        <small>
          {(paymentStatus === PAYEE_PAYMENT_STATUS.processing ||
            paymentStatus === PAYEE_PAYMENT_STATUS.flagged_for_review) &&
            `The instruction to pay ${payeeName} has been sent. It usually takes no more than 2 business days for the transfer of funds to be completed. The details of the payment request are outlined below for reference:`}
          {(paymentStatus === PAYEE_PAYMENT_STATUS.pending || PAYEE_PAYMENT_STATUS.held) &&
            `Your request to pay ${payeeName} has been received. This payment requires approval before it can be processed. The details of the request are outlined below for reference:`}
          {paymentStatus === PAYEE_PAYMENT_STATUS.posted &&
            `Your payment to ${payeeName} was succesfully executed. Please find the transaction details below for your
          records:`}
        </small>

        {paymentPosted && <DownloadConfirmationPDF documentLoading={pdfDocumentLoading} getUrl={getPdfUrl} />}

        {paymentPosted && !mt103Loading && mt103data?.payeePayment?.mt103 && (
          <DownloadMt103File documentLoading={mt103DocumentLoading} getUrl={getMt103Url} />
        )}

        <div className="tw-flex tw-pt-4">
          <small className="tw-w-2/3">Transaction ID</small>
          <small className="tw-w-1/3">{transactionId}</small>
        </div>
        <div className="tw-flex">
          <small className="tw-w-2/3">Reason</small>
          <small className="tw-w-1/3">{reason}</small>
        </div>
        <div className="tw-flex">
          <small className="tw-w-2/3">Amount withdrawn</small>
          <small className="tw-w-1/3">{`${amountWithdrawn} ${chargedCurrency}`}</small>
        </div>
        <div className="tw-flex">
          <small className="tw-w-2/3">Amount paid</small>
          <small className="tw-w-1/3">{payment}</small>
        </div>
        <div className="tw-flex">
          <small className="tw-w-2/3">Exchange rate</small>
          <small className="tw-w-1/3">{exchangeRate}</small>
        </div>
        <div className="tw-flex">
          <small className="tw-w-2/3">{paymentDateLabel}</small>
          <small className="tw-w-1/3">{formatDateTime(completedAt || dateInitiated)}</small>
        </div>
        <div className="tw-flex">
          <small className="tw-w-2/3">Payment Method</small>
          <small className="tw-w-1/3">{transferMethod}</small>
        </div>
        <div className="tw-flex">
          <strong>Payee's Details</strong>
        </div>
        <PayeeDetails payee={payee} />

        <PayeePaymentApprovals payeePaymentApprovals={payeePaymentApprovals} />
      </div>
    </Modal>
  );
};

const DownloadConfirmationPDF = ({ documentLoading, getUrl }) => {
  if (documentLoading) return <Loaders.Spinner />;

  return (
    <button onClick={getUrl} className="tw-flex tw-items-center tw-cursor-pointer">
      <IoDownloadOutline size={18} className="tw-text-primary-dark-green tw-mr-1" />
      <div className="tw-text-sm tw-text-primary-dark-green tw-font-semibold">Download Payment Confirmation</div>
    </button>
  );
};

const DownloadMt103File = ({ documentLoading, getUrl }) => {
  if (documentLoading) return <Loaders.Spinner />;

  const tooltipContent =
    'This document confirms the payment processed, providing comprehensive details of the international transaction to the recipient.';

  return (
    <button onClick={getUrl} className="tw-flex tw-items-center tw-cursor-pointer">
      <IoDownloadOutline size={18} className="tw-text-primary-dark-green tw-mr-1" />
      <div className="tw-text-sm tw-text-primary-dark-green tw-font-semibold tw-mr-1">Download MT103</div>
      <InfoTooltip message={tooltipContent} />
    </button>
  );
};

const PayeeDetails = ({ payee }) => {
  switch (payee.record.__typename) {
    case PAYEE_RECORD_TYPES.Supplier:
      return <SupplierDetails payee={payee} />;
    case PAYEE_RECORD_TYPES.CreditCardPayee:
      return <CreditCardPayeeDetails payee={payee} />;
    case PAYEE_RECORD_TYPES.CRAAccount:
      return <CRAAccountDetails payee={payee} />;
    default:
      return null;
  }
};

// TODO: refactor Supplier, CreditCardPayee and CRAAccount to have their own domain class

const SupplierDetails = (props) => {
  const { payee } = props;
  const { record: supplier } = payee || {};
  const { displayName, name, address, bankName, bankAccount } = supplier || {};
  const { street, unitNumber, city, countrySubdivision, postalCode } = address;
  const payeeAddress = `${street}, ${unitNumber || ''} ${
    unitNumber ? ',' : ''
  } ${city}, ${countrySubdivision} ${postalCode}`;
  const { maskedNumber } = bankAccount || {};

  return (
    <>
      <div className="tw-flex">
        <small className="tw-w-2/3">Payee Name</small>
        <small className="tw-w-1/3">{displayName || name}</small>
      </div>
      <div className="tw-flex">
        <small className="tw-w-2/3">Payee address</small>
        <div className="tw-w-1/3">
          <small>{payeeAddress}</small>
        </div>
      </div>
      <div className="tw-flex">
        <small className="tw-w-2/3">Bank name</small>
        <small className="tw-w-1/3">{bankName}</small>
      </div>
      <div className="tw-flex tw-pb-4">
        <small className="tw-w-2/3">Account number</small>
        <small className="tw-w-1/3">{maskedNumber}</small>
      </div>
    </>
  );
};

const CreditCardPayeeDetails = (props) => {
  const { payee } = props;
  const { record: creditCardPayee } = payee || {};
  const { displayName, cardNumber, cardType, currency } = creditCardPayee || {};

  return (
    <>
      <div className="tw-flex">
        <small className="tw-w-2/3">Payee Name</small>
        <small className="tw-w-1/3">{displayName}</small>
      </div>
      <div className="tw-flex">
        <small className="tw-w-2/3">Card number</small>
        <div className="tw-w-1/3">
          <small>{cardNumber}</small>
        </div>
      </div>
      <div className="tw-flex">
        <small className="tw-w-2/3">Card type</small>
        <small className="tw-w-1/3">{cardType.charAt(0).toUpperCase() + cardType.slice(1)}</small>
      </div>
      <div className="tw-flex tw-pb-4">
        <small className="tw-w-2/3">Currency</small>
        <small className="tw-w-1/3">{currency}</small>
      </div>
    </>
  );
};

const CRAAccountDetails = (props) => {
  const { payee } = props;
  const { record: craAccount } = payee || {};
  const { displayName, businessNumber, currency } = craAccount;

  return (
    <>
      <div className="tw-flex">
        <small className="tw-w-2/3">Payee Name</small>
        <small className="tw-w-1/3">{displayName}</small>
      </div>
      <div className="tw-flex">
        <small className="tw-w-2/3">Business number</small>
        <div className="tw-w-1/3">
          <small>{businessNumber}</small>
        </div>
      </div>
      <div className="tw-flex tw-pb-4">
        <small className="tw-w-2/3">Currency</small>
        <small className="tw-w-1/3">{currency}</small>
      </div>
    </>
  );
};

export default PaymentConfirmationModal;
