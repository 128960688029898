import React from 'react';

import { usePromoText } from './hooks';

import styles from './PromoText.module.scss';

const PromoText = () => {
  const {
    vendorName,
    formattedAmount,
    currency,
    formattedDueDate,
    invoiceNumber,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  } = usePromoText();

  if (!vendorName || !formattedAmount || !formattedDueDate || !currency) return null;

  return (
    <div className={styles.promo}>
      <h3 className={styles.promo__header}>You’ve received a payment request from {vendorName}.</h3>
      <div className={styles.promo__details}>
        <b>Payment request details</b>
        <div>
          Amount:{' '}
          <b>
            {currency} {formattedAmount}
          </b>
        </div>
        {!isOngoing ? (
          <div>
            Due date: <b>{formattedDueDate}</b>
          </div>
        ) : (
          <>
            <div>
              Frequency: <b>{frequencyLabel}</b>
            </div>
            <div>
              Start Date: <b>{formattedStartDate}</b>
            </div>
            {isStoppedByEndDate && formattedEndDate && (
              <div>
                End Date: <b>{formattedEndDate}</b>
              </div>
            )}
            {isStoppedByNumberOfOccurrences && numberOfOccurrences && (
              <div>
                Number of Payments: <b>{numberOfOccurrences}</b>
              </div>
            )}
            {isStoppedByCancel && <div>These payments will stop upon cancellation by vendor</div>}
          </>
        )}
        {invoiceNumber && (
          <div>
            Invoice Number: <b>{invoiceNumber}</b>
          </div>
        )}
      </div>
      <p className={styles.promo__text}>Please register with Loop to review and approve this payment request.</p>
    </div>
  );
};

export default PromoText;
