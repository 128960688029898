import React, { useContext } from 'react';

import { CardMigrationContext } from 'components/CardMigrationV2/context/CardMigrationContext';
import Acknowledgement from './components/Acknowledgement';
import Changes from './components/Changes';
import CardMigrationPreferences from './components/CardMigrationPreferences';
import ReviewSelection from './components/ReviewSelection';
import UpgradeConfirmation from './components/UpgradeConfirmation';
import CardUpgradeSummary from './components/CardUpgradeSummary';
import { ConfirmSelection } from './components/ConfirmSelection';
import UpdateCardDeliveryAddress from './components/UpdateCardDeliveryAddress';

const Steps = () => {
  const { currentStep } = useContext(CardMigrationContext);

  switch (currentStep) {
    case 0:
      return <Acknowledgement />;

    case 1:
      return <Changes />;

    case 2:
      return <CardMigrationPreferences />;

    case 3:
      return <ReviewSelection />;

    case 4:
      return <ConfirmSelection />;

    case 5:
      return <UpgradeConfirmation />;

    case 6:
      return <CardUpgradeSummary />;

    case 7:
      return <UpdateCardDeliveryAddress />;

    default:
      return null;
  }
};

export default Steps;
