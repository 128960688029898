import React from 'react';

const SandClock = ({ size = '14', ...other }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" {...other}>
      <path
        d="M3.98163 12.6875H10.0186C10.56 12.6875 10.9907 12.2421 10.9324 11.7288C10.5564 8.42188 8.31261 8.47656 8.31261 7C8.31261 5.52344 10.5851 5.60547 10.9321 2.27117C10.9868 1.75766 10.56 1.3125 10.0186 1.3125H3.98163C3.44023 1.3125 3.01448 1.75766 3.06808 2.27117C3.41507 5.60547 5.68761 5.49609 5.68761 7C5.68761 8.50391 3.44378 8.42188 3.06808 11.7288C3.00956 12.2421 3.44023 12.6875 3.98163 12.6875Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.38716 11.8125H4.62471C4.19814 11.8125 4.07783 11.3203 4.37697 11.0152C5.10103 10.2812 6.56255 9.7557 6.56255 8.91406V6.125C6.56255 5.58223 5.52349 5.16797 4.88064 4.2875C4.77455 4.1423 4.78521 3.9375 5.05482 3.9375H8.95759C9.18755 3.9375 9.23732 4.14066 9.13259 4.28613C8.49904 5.16797 7.43755 5.57949 7.43755 6.125V8.91406C7.43755 9.74887 8.96087 10.1992 9.63599 11.016C9.90806 11.3452 9.8129 11.8125 9.38716 11.8125Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SandClock;
