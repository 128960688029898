import React from 'react';
import cx from 'classnames';

import { Modal, FormFooter } from 'components/UI';
import { Members } from 'components/creditCards/components/cardsSettings';
import { EditCardMembersModalProps } from './EditCardMembersModal.types';
import { useEditCardMembersModal } from './hooks';

import styles from './EditCardMembersModal.module.scss';

const EditCardMembersModal = ({
  isOpenModal,
  closeModal,
  handleUpdateMembers,
  members,
  internalContacts,
  isLoading,
}: EditCardMembersModalProps) => {
  const { handleCloseModal, onSubmit, memberOptions, selectedMembers, onSelectedMembersChange } =
    useEditCardMembersModal({
      closeModal,
      handleUpdateMembers,
      members,
      internalContacts,
    });

  return (
    <Modal show={isOpenModal} title="Manage Team Access" onClose={handleCloseModal}>
      <div className={cx('tw-pt-4 tw-pb-4 tw-px-8', styles.modal)}>
        <Members
          memberOptions={memberOptions}
          selectedMembers={selectedMembers}
          onSelectOptions={onSelectedMembersChange}
          isLoading={isLoading}
        />
      </div>

      <FormFooter
        submitButtonLabel="Update"
        onSubmit={onSubmit}
        cancelButtonLabel="Cancel"
        onCancel={handleCloseModal}
        isDisabled={isLoading}
        loading={isLoading}
        rootClass="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-8"
      />
    </Modal>
  );
};

export default EditCardMembersModal;
