import React from 'react';

import Pencil from 'components/svg/Pencil';
import Button, { ButtonSize } from 'components/Button';

import { composeFullAddress } from 'utility/address';
import { CardMigrationSelectedCard } from 'components/CardMigrationV2/CardMigrationV2.types';

const CardRow = ({ card, updateAddress }: { card: CardMigrationSelectedCard; updateAddress: () => void }) => (
  <div className="tw-flex tw-gap-4 tw-rounded-md tw-py-3 tw-px-2 tw--mr-2 tw-w-full">
    <div className="tw-w-2/6">
      <strong>{card.nickName}</strong>
    </div>
    <div className="tw-w-3/6">{card.address && composeFullAddress(card.address)}</div>
    <div className="tw-flex tw-w-1/6 tw-justify-center">
      <Button
        data-testid="update-credit-card-address-btn"
        onClick={updateAddress}
        className="tw-flex tw-items-center tw-mx-4"
        size={ButtonSize.sm}
      >
        <Pencil size="12" />
      </Button>
    </div>
  </div>
);

export default CardRow;
