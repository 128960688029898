import React, { useRef, useContext, useEffect, useState } from 'react';
import { BiTransferAlt } from 'react-icons/bi';
import { formatDistanceToNowStrict } from 'date-fns';

import { formatMoneyV2 } from 'utility/currency';
import { formatDateTime } from 'utility/date';
import { PaymentContext } from 'context/Payment';
import { Loaders } from 'components/cards/Loader';
import Warning from 'components/svg/Warning';
import InfoTooltip, { useRebuildTooltipForModal } from 'components/InfoTooltip';
import Button from 'components/Button';

const Review = ({
  onPreviousStep,
  onNextStep,
  loading,
  footerBorder,
  getExchangeRate,
  rate,
  loadingRate,
  rateExpiresAt,
  wallets,
}) => {
  const { moveMoneyInfo, setMoveMoneyInfo } = useContext(PaymentContext);
  const { fromAccount, toAccount, originalAmount, chargedAmount } = moveMoneyInfo;
  const [currentChargedAmount, setCurrentChargedAmount] = useState(chargedAmount);
  const [expired, setExpired] = useState(false);
  const needsConversion = fromAccount.currency !== toAccount.currency;
  const submitDisabled = loading || loadingRate || expired;
  const isInternalTransfer = wallets.some((w) => w.id === toAccount.id);

  const onRefreshRate = () => {
    getExchangeRate({
      variables: {
        buy: { currency: originalAmount.currency, amount: originalAmount.amount },
        sell: { currency: chargedAmount.currency },
      },
    });
  };

  const onSubmit = () => {
    setMoveMoneyInfo({ ...moveMoneyInfo, chargedAmount: currentChargedAmount });
    const preDepositAuthorization = isInternalTransfer || toAccount.preDepositAuthorization;

    onNextStep(fromAccount, preDepositAuthorization);
  };

  useEffect(() => {
    const isExchangeRateExpired = new Date(rateExpiresAt) <= new Date();
    if (!isExchangeRateExpired) return;

    if (needsConversion) {
      getExchangeRate({
        variables: {
          buy: { currency: originalAmount.currency, amount: originalAmount.amount },
          sell: { currency: chargedAmount.currency },
        },
      });
    }
  }, [needsConversion, rateExpiresAt, originalAmount, chargedAmount]);

  useEffect(() => {
    const isExchangeRateExpired = new Date(rateExpiresAt) <= new Date();
    if (!isExchangeRateExpired) return;

    if (needsConversion && rate) {
      setCurrentChargedAmount({ amount: originalAmount.amount * rate, currency: chargedAmount.currency });
    }
  }, [rate, needsConversion, rateExpiresAt, originalAmount, chargedAmount]);

  useRebuildTooltipForModal();

  const fromAccountDisplayName = fromAccount.displayName
    ? fromAccount.displayName
    : fromAccount.ledgerAccount.displayName;
  const isLocWithdrawal = fromAccountDisplayName.includes('Line Of Credit');
  const today = formatDateTime(new Date());

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <small className="tw-text-neutral-grey-2">Please confirm your transfer details.</small>
        <div className="tw-flex tw-flex-col tw-pt-8 tw-pb-4">
          <small>Recipient Account</small>
          <p className="tw-mt-1">{toAccount.displayName}</p>
          <small className="tw-mt-4">From Account</small>
          <p className="tw-mt-1">{fromAccountDisplayName}</p>
          <small className="tw-mt-4">Amount</small>
          <p className="tw-mt-1">{`${originalAmount.currency} ${formatMoneyV2(originalAmount)}`}</p>
          <small className="tw-mt-4">Date</small>
          <p className="tw-mt-1">{today}</p>
          {loadingRate && (
            <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4 tw-mt-4 tw-flex tw-items-center tw-justify-center">
              <Loaders.Small />
            </div>
          )}
          {needsConversion && rate && !loadingRate && (
            <ExchangeRateInfo
              currentChargedAmount={currentChargedAmount}
              originalAmount={originalAmount}
              rate={rate}
              rateExpiresAt={rateExpiresAt}
              onRefreshRate={onRefreshRate}
              expired={expired}
              setExpired={setExpired}
            />
          )}
          <small className="tw-mt-4">Method</small>
          <p className="tw-mt-1">{isInternalTransfer ? 'Internal Transfer' : 'Domestic Transfer'}</p>
          {isLocWithdrawal && (
            <>
              <small className="tw-mt-4">Fees</small>
              <p className="tw-mt-1">
                You are making a withdrawal from your Line of Credit. Interest and fees will be charged in accordance
                with your credit agreement.
              </p>
            </>
          )}
        </div>
      </div>
      <div
        className={`${
          footerBorder ? 'tw-border-t tw-border-neutral-grey-3 tw-pt-4' : 'tw-pb-4'
        } tw-px-8 tw-flex tw-flex-col tw-space-y-4 lg:tw-space-y-0 lg:tw-flex-row tw-justify-between`}
      >
        <Button onClick={onPreviousStep} secondary className="tw-w-full lg:tw-w-max">
          Edit Transfer Details
        </Button>
        <Button isDisabled={submitDisabled} onClick={onSubmit} primary className="tw-w-full lg:tw-w-max">
          {loading ? 'Submitting...' : 'Submit Transfer'}
        </Button>
      </div>
    </>
  );
};

export const ExchangeRateInfo = ({
  currentChargedAmount,
  originalAmount,
  rate,
  onRefreshRate,
  rateExpiresAt,
  expired,
  setExpired,
}) => {
  const [expiresInSeconds, setExpiresInSeconds] = useState();
  const timerRef = useRef();

  const startTimer = () => {
    clearTimer();
    setExpired(false);
    timerRef.current = setInterval(() => {
      const pastExpiresAt = new Date(rateExpiresAt) <= new Date();

      if (pastExpiresAt) {
        setExpiresInSeconds();
        setExpired(true);
        clearInterval(timerRef.current);
      } else {
        setExpiresInSeconds(formatDistanceToNowStrict(new Date(rateExpiresAt)));
      }
    }, 500);
  };

  const clearTimer = () => {
    if (timerRef.current) clearInterval(timerRef.current);
  };

  useEffect(() => {
    startTimer();

    return clearTimer;
  }, [rateExpiresAt]);

  return (
    <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-p-4 tw-mt-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <div className="tw-flex tw-items-center">
          <small className="tw-font-semibold tw-mr-2">Amount to withdraw</small>
          <InfoTooltip message="The amount you are selling" />
        </div>
        <div className="tw-flex tw-items-center">
          <small className="tw-mr-2">{formatMoneyV2(currentChargedAmount)}</small>
          <span className="tw-text-xs tw-text-neutral-grey-2 tw-w-8 tw-text-right">
            {currentChargedAmount.currency}
          </span>
        </div>
      </div>
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <small className="tw-font-semibold">Your exchange rate</small>
        {!expired && rate && (
          <div className="tw-flex tw-items-center tw-justify-between">
            <small className="tw-mr-2">{rate.toFixed(5)}</small>
            <BiTransferAlt className="tw-text-neutral-grey-2 tw-transform tw-rotate-90 tw-w-8" />
          </div>
        )}
      </div>
      {expired ? (
        <div className="tw-rounded-md tw-bg-secondary-pastel-yellow-80 tw-p-4 tw-mb-4 tw-flex tw-justify-between tw-items-center">
          <Warning className="tw-mr-2" />
          <span className="tw-text-primary-dark-yellow tw-text-xs tw-w-3/5 tw-mr-2">
            The exchange rate expired. Refresh to see your final exchange rate.
          </span>
          <span
            onClick={onRefreshRate}
            className="tw-cursor-pointer tw-font-semibold tw-text-xs tw-text-primary-dark-green"
          >
            Refresh Rate
          </span>
        </div>
      ) : (
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <span className="tw-text-neutral-grey-2 tw-text-xs tw-w-2/3">
            {`Rates expires in ${expiresInSeconds} seconds. Refresh to see your final exchange rate.`}
          </span>
          <span
            onClick={onRefreshRate}
            className="tw-cursor-pointer tw-font-semibold tw-text-neutral-grey-2 tw-text-xs"
          >
            Refresh Rate
          </span>
        </div>
      )}
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center">
          <small className="tw-font-semibold tw-mr-2">Payment amount</small>
          <InfoTooltip message="The amount you are buying" />
        </div>
        <div className="tw-flex tw-items-center">
          <small className="tw-mr-2">{formatMoneyV2(originalAmount)}</small>
          <span className="tw-text-xs tw-text-neutral-grey-2 tw-w-8 tw-text-right">{originalAmount.currency}</span>
        </div>
      </div>
    </div>
  );
};

export default Review;
