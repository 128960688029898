import { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import { TransactionsContext } from 'context/Transactions';
import { useTransactionCategories } from 'hooks/useTransactionCategories';

const useTransactionCategory = ({ transaction }) => {
  const { me } = useContext(AuthContext);
  const isMemberBool = me?.internalContact?.role === 'member';

  const { openCategoryModal, setTransactionToCategorize } = useContext(TransactionsContext);
  const { transactionCategories, loading, error, getIcon, updateTransactionCategory, isUpdatingTransactionCategory } =
    useTransactionCategories();

  const { transactionCategory } = transaction;

  const handleSelectCategory = async (newCategory) => {
    try {
      const payload = {
        transactionId: `${transaction.transactionId}`,
        oldCategoryId: transactionCategory.transactionCategoryId,
        newCategoryId: newCategory.transactionCategoryId,
        description: null,
      };

      await updateTransactionCategory({
        variables: payload,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleNewCategory = () => {
    setTransactionToCategorize(transaction);
    openCategoryModal();
  };

  return {
    transactionCategory,
    handleSelectCategory,
    transactionCategories,
    loading,
    error,
    getIcon,
    isMemberBool,
    handleNewCategory,
    isUpdatingTransactionCategory,
  };
};

export default useTransactionCategory;
