import { gql } from '@apollo/client';

export const REQUESTED_SUPPLIER = gql`
  query RequestedSupplier($signedId: String!) {
    requestedSupplier(signedId: $signedId) {
      id
      borrowerName
      email
      name
      firstName
      lastName
      status
      type
    }
  }
`;

export const ADD_REQUESTED_SUPPLIER_BANK_ACCOUNT = gql`
  mutation AddRequestedSupplierBankAccount(
    $address: SupplierAddressInput!
    $bankAccount: SupplierBankAccountInput!
    $signedId: String!
  ) {
    addRequestedSupplierBankAccount(address: $address, bankAccount: $bankAccount, signedId: $signedId) {
      id
      address {
        city
        country
        countrySubdivision
        postalCode
        street
      }
      bankAccount {
        maskedNumber
      }
    }
  }
`;
