import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';

import { CHANGE_EMAIL } from 'graphql/settings';
import { TextField, PasswordField } from 'components/FormFields/v2';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import Button from 'components/Button';

const UpdateEmail = ({ email, closeModal, meRefetch, show }) => {
  const form = useForm();

  const { register, handleSubmit } = form;

  const [showPassword, setShowPassword] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  const [changeEmail, { loading: changingEmail, error, reset }] = useMutation(CHANGE_EMAIL);

  const onSubmit = async ({ currentPassword }) => {
    try {
      await changeEmail({ variables: { newEmail: emailAddress, currentPassword } });
      await meRefetch();
      handleClose();
    } catch (err) {
      console.error(err);
    }
  };

  const handleTogglePassword = ({ newEmail }) => {
    setShowPassword(!showPassword);
    setEmailAddress(newEmail);
  };

  const handleClose = () => {
    reset();
    setShowPassword(false);
    setEmailAddress('');
    closeModal('email');
  };

  return (
    <Modal show={show} onClose={handleClose}>
      <div className="tw-flex tw-justify-between tw-p-6">
        <h5 className="tw-text-left">Edit details</h5>
        <Button onClick={handleClose}>
          <Close />
        </Button>
      </div>
      <hr className="tw-mb-6" />
      <h3 className="tw-px-10 tw-text-2xl tw-font-semibold">
        {!showPassword ? 'Enter your updated email address.' : 'Enter password to save changes.'}
      </h3>
      <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
        <form onSubmit={handleSubmit(showPassword ? onSubmit : handleTogglePassword)}>
          <div className="tw-flex tw-w-full tw-flex-col tw-justify-center tw-p-10">
            {!showPassword && (
              <TextField
                type="text"
                name="newEmail"
                rootClass="tw-mb-6"
                placeholder="example@lendingloop.ca"
                label="Email Address"
                required
                defaultValue={email}
                ref={register({ required: true })}
              />
            )}

            {showPassword && (
              <PasswordField
                data-testid="signin-password"
                label="Password"
                placeholder="∗∗∗∗∗∗∗∗"
                autoComplete="current-password"
                tabIndex={2}
                type="password"
                name="currentPassword"
                required
                ref={register({ required: true })}
              />
            )}
          </div>

          <hr />
          <div className="tw-p-6 tw-flex tw-justify-end">
            <Button
              type="submit"
              className="tw-text-right tw-bg-primary-dark-green tw-text-neutral-light tw-rounded-md tw-py-2 tw-px-6 tw-flex tw-flex-row tw-items-center"
              onClick={handleSubmit}
              disabled={changingEmail}
            >
              {showPassword ? 'Confirm' : 'Save'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default UpdateEmail;
