import { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { AccountsContext } from 'context/Accounts';
import { GET_WALLET_TRANSACTIONS_ON_ACCOUNTS_PAGE } from 'graphql/wallets';
import { Currencies } from 'constants/currencies';

const useAccountsModal = () => {
  const { wallets, selectedWalletId, bankAccounts } = useContext(AccountsContext);

  const selectedWallet = useMemo(() => wallets?.find(({ id }) => id === selectedWalletId), [wallets, selectedWalletId]);

  const solidAccountInternalId =
    selectedWallet?.currency === Currencies.USD ? selectedWallet?.externalAccounts[0]?.internalId : '';

  const coreExternalWalletAccountId =
    selectedWallet?.currency === Currencies.CAD ? selectedWallet?.externalAccounts?.[0]?.id : '';

  const coreExternalWalletAccountStatus =
    selectedWallet?.currency === Currencies.CAD ? selectedWallet?.externalAccounts?.[0]?.status : '';

  const {
    data: walletTransactionsData,
    loading: loadingWalletTransactionsData,
    refetch: refetchWalletTransactions,
  } = useQuery(GET_WALLET_TRANSACTIONS_ON_ACCOUNTS_PAGE);
  const walletsForTransactions = get(walletTransactionsData, 'me.account.wallets', []);
  const selectedWalletForTransactions = useMemo(
    () => walletsForTransactions.find((wallet: { id: string }) => wallet.id === selectedWalletId),
    [walletsForTransactions, selectedWalletId]
  );

  return {
    walletId: selectedWallet?.id,
    displayName: selectedWallet?.displayName,
    availableBalance: selectedWallet?.availableBalance,
    currency: selectedWallet?.currency,
    bankAccounts,
    wallets,
    activeExternalAccount: selectedWallet?.activeExternalAccount,
    transactions: selectedWalletForTransactions?.recentTransactions,
    solidInternalId: solidAccountInternalId,
    coreExternalWalletAccountId,
    coreExternalWalletAccountStatus,
    loadingWalletTransactionsData,
    refetchWalletTransactions,
  };
};

export default useAccountsModal;
