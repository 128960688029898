import React from 'react';
import { StatBannerProps } from './Banner.types';
import CurrencyFlag from 'components/svg/CurrencyFlag';

const StatBanner = ({ content, currencies, title }: StatBannerProps) => {
  const Icons = (
    <div className="tw-relative tw-flex tw-items-center">
      {currencies.map((currency: string, index: number) => {
        return (
          <div
            key={currency}
            className={`tw-absolute tw-p-px tw-bg-neutral-grey-5 tw-rounded-full tw-left-${index * 3}`}
          >
            <CurrencyFlag currency={currency} size={20} />
          </div>
        );
      })}
    </div>
  );
  return (
    <div className="tw-bg-secondary-pastel-yellow-50 tw-p-4 tw-rounded-md tw-text-sm tw-flex tw-border-solid tw-border tw-border-neutral-grey-4">
      <div className="tw-py-4 tw-px-2 tw-w-20 tw-flex tw-flex-none">{Icons}</div>
      <div className="tw-py-4">
        <div className="tw-font-bold title tw-mb-1">{title}</div>
        <div className="message">{content} </div>
      </div>
    </div>
  );
};

export { StatBanner };
