import React from 'react';

const MenuSkeleton = () => (
  <div className="tw-flex tw-gap-4">
    <div className="tw-flex tw-animate-pulse tw-bg-neutral-grey-4 tw-h-8 tw-rounded-full tw-w-8" />
    <div className="tw-flex tw-animate-pulse tw-bg-neutral-grey-4 tw-h-8 tw-rounded tw-w-24" />
  </div>
);

export default MenuSkeleton;
