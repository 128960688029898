import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryYellowColor = theme.colors.primary['yellow'];
const primaryDarkGreenColor = theme.colors.primary['dark-green'];
const neutralDarkColor = theme.colors.neutral['dark'];

type CheckmarkAndPerson3Props = {
  width?: number;
  height?: number;
};

const CheckmarkAndPerson3 = ({ width = 155, height = 154 }: CheckmarkAndPerson3Props) => (
  <svg width={width} height={height} viewBox="0 0 95 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M74.9628 26.2394C75.0293 26.1945 75.109 26.2742 75.0642 26.3407L71.9593 30.9424C71.9426 30.9671 71.9426 30.9994 71.9593 31.0241L75.0642 35.6258C75.109 35.6923 75.0293 35.772 74.9628 35.7271L70.3611 32.6222C70.3365 32.6056 70.3042 32.6056 70.2795 32.6222L65.6778 35.7271C65.6113 35.772 65.5316 35.6923 65.5765 35.6258L68.6814 31.0241C68.698 30.9994 68.698 30.9671 68.6814 30.9424L65.5765 26.3407C65.5316 26.2742 65.6113 26.1945 65.6778 26.2394L70.2795 29.3443C70.3042 29.361 70.3365 29.361 70.3611 29.3443L74.9628 26.2394Z"
      fill={primaryYellowColor}
    />
    <path
      d="M34.3658 64.5999C34.4323 64.555 34.512 64.6347 34.4671 64.7012L28.0705 74.1814C28.0539 74.2061 28.0539 74.2384 28.0705 74.2631L34.4671 83.7433C34.512 83.8098 34.4323 83.8895 34.3658 83.8447L24.8856 77.448C24.8609 77.4314 24.8286 77.4314 24.8039 77.448L15.3237 83.8447C15.2572 83.8895 15.1775 83.8098 15.2223 83.7433L21.619 74.2631C21.6356 74.2384 21.6356 74.2061 21.619 74.1814L15.2223 64.7012C15.1775 64.6347 15.2572 64.555 15.3237 64.5999L24.8039 70.9965C24.8286 71.0131 24.8609 71.0131 24.8855 70.9965L34.3658 64.5999Z"
      fill={primaryYellowColor}
    />
    <path
      d="M57.341 8.34513C57.4075 8.30025 57.4872 8.37995 57.4423 8.44646L52.3743 15.9576C52.3577 15.9822 52.3577 16.0145 52.3743 16.0392L57.4423 23.5503C57.4872 23.6168 57.4075 23.6965 57.341 23.6517L49.8299 18.5837C49.8052 18.567 49.7729 18.567 49.7482 18.5837L42.2371 23.6517C42.1706 23.6965 42.0909 23.6168 42.1358 23.5503L47.2038 16.0392C47.2204 16.0145 47.2204 15.9822 47.2038 15.9576L42.1358 8.44646C42.0909 8.37994 42.1706 8.30025 42.2371 8.34513L49.7482 13.4131C49.7729 13.4298 49.8052 13.4298 49.8299 13.4131L57.341 8.34513Z"
      fill={primaryDarkGreenColor}
    />
    <path
      d="M52.1329 40.1258C52.1994 40.0809 52.2791 40.1606 52.2342 40.2271L49.5852 44.1531C49.5685 44.1778 49.5685 44.2101 49.5852 44.2348L52.2342 48.1608C52.2791 48.2273 52.1994 48.307 52.1329 48.2622L48.2068 45.6131C48.1822 45.5965 48.1499 45.5965 48.1252 45.6131L44.1992 48.2622C44.1326 48.307 44.0529 48.2273 44.0978 48.1608L46.7468 44.2348C46.7635 44.2101 46.7635 44.1778 46.7468 44.1531L44.0978 40.2271C44.0529 40.1606 44.1326 40.0809 44.1992 40.1258L48.1252 42.7748C48.1499 42.7914 48.1822 42.7914 48.2068 42.7748L52.1329 40.1258Z"
      fill={primaryDarkGreenColor}
    />
    <path
      opacity="0.5"
      d="M81.6562 53.8252C81.2872 58.8019 77.5144 62.8618 73.3726 62.8618C69.2308 62.8618 65.4515 58.8029 65.0891 53.8252C64.7125 48.648 68.3837 44.7886 73.3726 44.7886C78.3616 44.7886 82.0327 48.7421 81.6562 53.8252Z"
      fill={primaryYellowColor}
    />
    <path
      opacity="0.5"
      d="M73.3721 68.8867C65.1827 68.8867 56.8709 73.405 55.3328 81.9334C55.1445 82.9613 55.7291 83.9478 56.8059 83.9478H89.9393C91.0162 83.9478 91.5979 82.9613 91.4125 81.9334C89.8734 73.405 81.5616 68.8867 73.3721 68.8867Z"
      fill={primaryYellowColor}
    />
    <path
      d="M73.9404 56.9902C73.5714 61.967 69.7986 66.0269 65.6568 66.0269C61.515 66.0269 57.7356 61.9679 57.3732 56.9902C56.9967 51.813 60.6678 47.9536 65.6568 47.9536C70.6458 47.9536 74.3169 51.9071 73.9404 56.9902Z"
      stroke={neutralDarkColor}
      strokeWidth="0.45572"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.6563 72.0513C57.4669 72.0513 49.1551 76.5696 47.617 85.0979C47.4287 86.1258 48.0132 87.1123 49.0901 87.1123H82.2235C83.3004 87.1123 83.8821 86.1258 83.6966 85.0979C82.1576 76.5696 73.8458 72.0513 65.6563 72.0513Z"
      stroke={neutralDarkColor}
      strokeWidth="0.45572"
      strokeMiterlimit="10"
    />
    <path
      d="M36.1652 40.1452C36.1652 32.249 29.7703 25.8428 21.8883 25.8428C14.0062 25.8428 7.61133 32.249 7.61133 40.1452C7.61133 48.0413 14.0062 54.4475 21.8883 54.4475C29.7703 54.4475 36.1652 48.0413 36.1652 40.1452Z"
      stroke={neutralDarkColor}
      strokeWidth="0.45572"
      strokeMiterlimit="10"
    />
    <path
      d="M29.0269 34.1855L19.0331 46.1042L14.75 41.3367"
      stroke={neutralDarkColor}
      strokeWidth="0.45572"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckmarkAndPerson3;
