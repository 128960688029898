import React from 'react';

import { Loaders } from 'components/cards/Loader';
import Button from 'components/Button';
import { useCustomEmailSection } from './hooks';
import { ConfigureInstructions, ConfigureModal } from './components';

const ConfigureEmailSection = () => {
  const { meLoading, showConfigureModal, onShowConfigureModal, onCloseConfigureModal, existingLoopDomainEmail } =
    useCustomEmailSection();

  if (meLoading) return <Loaders.Spinner />;

  return (
    <div className="">
      <div className="tw-font-light tw-w-5/6 tw-mb-6">
        Create a custom email for attaching receipts to your Loop transactions. When you get your receipts and bills,
        take a picture of it and send it to your custom Loop Receipts email address.
      </div>
      <h5 className="tw-py-2 tw-font-semibold">Configure Email</h5>
      <div>
        <div className="tw-flex tw-flex-col">
          <div className={`tw-mr-2 tw-flex tw-flex-row tw-gap-3 tw-items-center`}>
            Custom Loop Receipts Email
            <div className="tw-rounded-md tw-bg-neutral-grey-4 tw-font-semibold tw-py-2 tw-px-10">
              {existingLoopDomainEmail}
            </div>
            <Button onClick={onShowConfigureModal} primary>
              Configure
            </Button>
          </div>
        </div>
      </div>
      <ConfigureInstructions />
      <ConfigureModal
        show={showConfigureModal}
        onClose={onCloseConfigureModal}
        existingLoopDomainEmail={existingLoopDomainEmail}
      />
    </div>
  );
};

export default ConfigureEmailSection;
