import React from 'react';

import { PendingIdentityVerificationBannerProps } from './PendingIdentityVerificationBanner.types';
import { Banner } from 'components/UI';
import { BannerType } from 'components/UI/Banner/Banner';
import Identification from 'components/svg/Identification';
import { MESSAGES } from 'components/IdentityVerification/constants';

const PendingIdentityVerificationBanner = ({
  title = 'Verify Your Identity',
  message = MESSAGES.banner.openAccount,
  actionLabel = 'Identity Verification',
  onSubmit,
}: PendingIdentityVerificationBannerProps) => (
  <Banner
    type={BannerType.warning}
    title={title}
    message={message}
    submitButtonLabel={actionLabel}
    onSubmit={onSubmit}
    icon={<Identification />}
    iconAlignment="center"
    classNames="tw-mt-8 tw-p-3"
  />
);

export default PendingIdentityVerificationBanner;
