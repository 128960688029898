import { useFormContext } from 'react-hook-form';

import { SUPPLIER_SPECIFIC_COUNTRIES } from 'constants/index';
import { getCountries, getCountrySubdivisions } from 'utility/countries';

const useBusinessAddress = () => {
  const { register, watch } = useFormContext();
  const selectedCountry = watch('address.country');

  const countryOptions = getCountries();
  const countrySubdivisions = getCountrySubdivisions(selectedCountry);

  return {
    register,
    countryOptions,
    countrySubdivisions,
    isCanada: selectedCountry === SUPPLIER_SPECIFIC_COUNTRIES.CA.code,
  };
};

export default useBusinessAddress;
