import React, { createContext, useState } from 'react';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { WalletTransactionsFilterContextType } from '../AccountingServiceTransactions.types';
import { Currencies } from 'constants/currencies';

export const WalletTransactionsFilterContext = createContext<WalletTransactionsFilterContextType>(
  {} as WalletTransactionsFilterContextType
);

export const WalletTransactionsFilterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const now = new Date();
  const firstDayThisMonth = startOfMonth(now);
  const lastDayThisMonth = endOfMonth(now);
  const [startDate, setStartDate] = useState<Date>(firstDayThisMonth);
  const [endDate, setEndDate] = useState<Date>(lastDayThisMonth);

  const [postedStatus, setPostedStatus] = useState<string>('all');

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const currencyParam = query.get('currency') as Currencies;

  const [currency, setCurrency] = useState<Currencies>(currencyParam || Currencies.CAD);
  const [isModified, setIsModified] = useState<boolean>(false);

  const resetFilter = () => {
    setStartDate(firstDayThisMonth);
    setEndDate(lastDayThisMonth);
    setPostedStatus('pending');
    setCurrency(Currencies.CAD);
    setIsModified(false);
  };

  return (
    <WalletTransactionsFilterContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        postedStatus,
        setPostedStatus,
        currency,
        setCurrency,
        isModified,
        setIsModified,
        resetFilter,
      }}
    >
      {children}
    </WalletTransactionsFilterContext.Provider>
  );
};
