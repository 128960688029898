import React from 'react';
import cx from 'classnames';

import { ProgressBarSkeletonProps } from './ProgressBarSkeleton.types';

const ProgressBarSkeleton = ({ className, stepClassName, stepCount }: ProgressBarSkeletonProps) => {
  return (
    <ol className={cx('tw-flex tw-gap-2 tw-items-start', className)}>
      {Array.from({ length: stepCount }).map((_, index) => (
        <li
          key={index}
          className={cx('tw-bg-neutral-grey-4 tw-animate-pulse tw-h-2 tw-w-full tw-rounded-full', stepClassName)}
        />
      ))}
    </ol>
  );
};

export default ProgressBarSkeleton;
