import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const BrokenClock = ({ width = '137', height = '183', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 137 183"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="broken-clock-image"
      {...rest}
    >
      <path
        d="M120.022 86.6863V30.6676C120.022 29.1943 118.927 28 117.577 28H107.796C106.445 28 105.351 29.1943 105.351 30.6676V86.6863"
        fill="#D0E7F4"
      />
      <path
        d="M100.46 86.6867V47.0067C100.46 45.9632 99.3649 45.1172 98.0145 45.1172H88.2334C86.8829 45.1172 85.7881 45.9632 85.7881 47.0067V86.6867"
        fill="#D0E7F4"
      />
      <path
        d="M80.8972 86.686V61.0107C80.8972 60.3355 79.8024 59.7881 78.452 59.7881H68.6709C67.3204 59.7881 66.2256 60.3355 66.2256 61.0107V86.686"
        fill="#D0E7F4"
      />
      <path
        d="M112.686 86.6864V35.3359C112.686 33.9854 111.592 32.8906 110.241 32.8906H100.46C99.1095 32.8906 98.0147 33.9854 98.0147 35.3359V86.6864"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.1238 86.6868V52.4531C93.1238 51.1026 92.029 50.0078 90.6785 50.0078H80.8975C79.547 50.0078 78.4522 51.1026 78.4522 52.4531V86.6868"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.5613 86.6861V69.5693C73.5613 68.2188 72.4665 67.124 71.116 67.124H61.335C59.9845 67.124 58.8897 68.2188 58.8897 69.5693V86.6861"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.9991 86.6865H117.576"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.022 120.155C133.022 145.958 113.248 166.873 88.8571 166.873H60.3924C50.2102 158.316 44.6959 134.98 44.6959 120.155C44.6959 106.367 57.5218 85.4988 60.3924 73.4365H88.8463C113.248 73.4221 133.022 94.3376 133.022 120.155Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M72.9193 156.472C86.1833 156.472 96.9358 140.206 96.9358 120.141C96.9358 100.076 86.1833 83.8096 72.9193 83.8096C59.6553 83.8096 48.9027 100.076 48.9027 120.141C48.9027 140.206 59.6553 156.472 72.9193 156.472Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M48.9027 120.155C48.9027 103.458 58.9048 89.2342 72.9192 83.8243C71.4331 83.2517 69.9096 82.7811 68.3594 82.416C52.0074 86.2591 39.7758 101.696 39.7758 120.155C39.7758 138.614 52.0218 154.051 68.3594 157.895C69.9096 157.529 71.4331 157.059 72.9192 156.486C58.9048 151.062 48.9027 136.839 48.9027 120.155Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M60.1762 65.7369C62.132 68.928 50.167 77.5182 35.1548 86.7207C20.1426 95.9232 7.53287 101.873 5.81122 99.0676C1.39129 91.8583 0.0162569 83.1885 1.98861 74.9655C3.96095 66.7424 9.11912 59.6396 16.3284 55.2197C23.5376 50.7998 32.2074 49.4247 40.4305 51.3971C48.6536 53.3694 55.7563 58.5276 60.1762 65.7369Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M60.1762 65.7369C60.1762 65.7369 58.9227 62.4341 31.3152 79.013C3.84455 95.5091 5.81112 99.0676 5.81112 99.0676C-3.63271 83.9618 1.32332 64.4222 16.3319 55.2197C23.5407 50.8005 32.2098 49.4258 40.4321 51.3982C48.6544 53.3705 55.7566 58.5283 60.1762 65.7369Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M60.1762 65.7371C60.1762 65.7371 58.5482 64.4729 49.5618 68.9031C45.2397 71.0353 43.0426 65.8452 36.1092 60.3777C27.0544 53.239 15.5216 55.7531 16.3319 55.22C30.7246 45.7149 50.9737 50.725 60.1762 65.7371Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M76.7294 97.5131L57.4276 102.602C56.9485 102.728 55.886 101.99 55.886 101.5L56.4839 62.5472C56.4872 62.3003 56.5709 62.0612 56.7223 61.8662C56.8738 61.6712 57.0847 61.5309 57.3231 61.4667L76.7726 56.1396C77.5218 56.6583 77.565 56.7988 77.565 57.1049V96.4254C77.5654 96.6735 77.4836 96.9148 77.3324 97.1115C77.1813 97.3083 76.9693 97.4495 76.7294 97.5131Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M82.5679 99.4874L63.2624 104.577C62.787 104.703 61.7245 103.964 61.7245 103.475L62.3224 64.5215C62.3232 64.274 62.406 64.0337 62.5578 63.8382C62.7097 63.6427 62.922 63.503 63.1616 63.441L82.6111 58.1104C83.3603 58.6326 83.4035 58.7695 83.4035 59.0756V98.4069C83.4022 98.6538 83.3197 98.8934 83.1687 99.0887C83.0177 99.284 82.8065 99.4241 82.5679 99.4874Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M76.128 97.0639L56.8262 102.153C56.7154 102.182 56.5996 102.186 56.4873 102.163C56.3751 102.141 56.2695 102.093 56.1785 102.023C56.0875 101.954 56.0135 101.865 55.9623 101.762C55.911 101.66 55.8837 101.547 55.8825 101.433V62.0979C55.8858 61.851 55.9695 61.6119 56.1209 61.4169C56.2724 61.2219 56.4833 61.0817 56.7217 61.0174L76.0308 55.9065C76.1413 55.8781 76.2568 55.8752 76.3685 55.898C76.4803 55.9208 76.5854 55.9687 76.676 56.0381C76.7665 56.1075 76.8401 56.1965 76.8912 56.2985C76.9423 56.4005 76.9695 56.5128 76.9708 56.6268V95.9581C76.9754 96.2104 76.8947 96.4569 76.7417 96.6576C76.5888 96.8583 76.3725 97.0014 76.128 97.0639Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M66.4247 85.9736C70.2559 85.9736 73.3617 82.8678 73.3617 79.0366C73.3617 75.2054 70.2559 72.0996 66.4247 72.0996C62.5935 72.0996 59.4877 75.2054 59.4877 79.0366C59.4877 82.8678 62.5935 85.9736 66.4247 85.9736Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M60.3881 69.1895L57.6868 69.9099C57.6628 69.9165 57.6377 69.9176 57.6132 69.9129C57.5888 69.9083 57.5658 69.898 57.5459 69.8831C57.5261 69.8681 57.51 69.8488 57.4988 69.8266C57.4876 69.8044 57.4817 69.7799 57.4815 69.755V63.0557C57.4813 63.0013 57.4993 62.9485 57.5326 62.9054C57.5659 62.8624 57.6125 62.8316 57.6652 62.818L60.3665 62.0977C60.3904 62.091 60.4156 62.09 60.44 62.0947C60.4645 62.0993 60.4875 62.1095 60.5073 62.1245C60.5272 62.1395 60.5433 62.1588 60.5545 62.181C60.5657 62.2032 60.5716 62.2277 60.5718 62.2525V68.9518C60.5719 69.0062 60.5539 69.0591 60.5207 69.1022C60.4874 69.1452 60.4408 69.1759 60.3881 69.1895Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M75.4724 95.8857L72.771 96.606C72.7468 96.6128 72.7213 96.6137 72.6966 96.6089C72.6719 96.604 72.6487 96.5935 72.6288 96.5781C72.6089 96.5628 72.5928 96.5429 72.5818 96.5203C72.5709 96.4976 72.5654 96.4727 72.5658 96.4476V89.7627C72.5656 89.7083 72.5836 89.6554 72.6169 89.6124C72.6501 89.5693 72.6968 89.5386 72.7494 89.525L75.4508 88.8046C75.475 88.7987 75.5002 88.7982 75.5246 88.8034C75.549 88.8085 75.5719 88.8191 75.5916 88.8343C75.6114 88.8496 75.6274 88.8691 75.6386 88.8914C75.6497 88.9136 75.6557 88.9382 75.6561 88.9631V95.6624C75.6531 95.7143 75.6337 95.7639 75.6007 95.804C75.5677 95.8442 75.5227 95.8728 75.4724 95.8857Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M60.3881 99.7966L57.6868 100.517C57.6625 100.524 57.6371 100.525 57.6124 100.52C57.5877 100.515 57.5644 100.504 57.5445 100.489C57.5246 100.474 57.5085 100.454 57.4976 100.431C57.4866 100.409 57.4811 100.384 57.4815 100.358V93.6592C57.4814 93.6048 57.4993 93.5519 57.5326 93.5088C57.5659 93.4658 57.6125 93.4351 57.6652 93.4215L60.3665 92.7011C60.3907 92.6951 60.4159 92.6947 60.4403 92.6999C60.4647 92.705 60.4877 92.7156 60.5074 92.7308C60.5271 92.746 60.5432 92.7655 60.5543 92.7878C60.5655 92.8101 60.5714 92.8347 60.5718 92.8596V99.5589C60.5719 99.6133 60.5539 99.6661 60.5207 99.7092C60.4874 99.7522 60.4408 99.783 60.3881 99.7966Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M75.4724 65.2705L72.771 65.9909C72.7471 65.9975 72.7219 65.9985 72.6975 65.9939C72.6731 65.9892 72.65 65.979 72.6302 65.9641C72.6104 65.9491 72.5942 65.9298 72.583 65.9076C72.5719 65.8853 72.5659 65.8609 72.5657 65.836V59.1475C72.5656 59.0931 72.5836 59.0402 72.6169 58.9972C72.6501 58.9542 72.6968 58.9234 72.7494 58.9098L75.4507 58.1895C75.4747 58.1828 75.4999 58.1818 75.5243 58.1865C75.5487 58.1911 75.5717 58.2013 75.5916 58.2163C75.6114 58.2313 75.6276 58.2506 75.6387 58.2728C75.6499 58.295 75.6558 58.3195 75.6561 58.3443V65.0436C75.6538 65.0961 75.6348 65.1466 75.6017 65.1874C75.5686 65.2283 75.5233 65.2574 75.4724 65.2705Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M93.4492 144.893L77.1291 156.383C76.7249 156.666 75.4734 156.347 75.3025 155.884L62.2957 119.164C62.2043 118.93 62.1932 118.673 62.2642 118.433C62.3352 118.192 62.4843 117.983 62.6878 117.837L79.0678 106.064C79.9515 106.294 80.0402 106.408 80.1484 106.694L93.8466 143.561C93.9381 143.795 93.9486 144.054 93.8766 144.295C93.8046 144.536 93.6542 144.747 93.4492 144.893Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M95.9154 143.795L79.5952 155.285C79.1935 155.571 77.9396 155.249 77.7687 154.787L64.7619 118.066C64.674 117.833 64.6663 117.578 64.74 117.341C64.8136 117.103 64.9644 116.897 65.1685 116.755L81.5459 104.985C82.4298 105.209 82.5185 105.323 82.624 105.612L96.33 142.477C96.4157 142.712 96.4215 142.968 96.3465 143.206C96.2715 143.445 96.12 143.652 95.9154 143.795Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M92.7307 144.678L76.4106 156.168C76.3168 156.234 76.209 156.278 76.0955 156.296C75.982 156.314 75.866 156.306 75.7562 156.272C75.6465 156.238 75.5461 156.179 75.4627 156.1C75.3793 156.021 75.3153 155.924 75.2754 155.816L61.5743 118.956C61.4838 118.722 61.4735 118.465 61.5449 118.224C61.6164 117.984 61.7655 117.774 61.9691 117.627L78.2892 106.137C78.383 106.07 78.4908 106.027 78.6043 106.009C78.7177 105.991 78.8338 105.999 78.9435 106.033C79.0533 106.067 79.1537 106.126 79.2371 106.205C79.3204 106.284 79.3845 106.381 79.4243 106.489L93.1226 143.356C93.2134 143.589 93.2241 143.845 93.1532 144.084C93.0822 144.324 92.9336 144.532 92.7307 144.678Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M82.0095 136.301C84.8492 133.729 85.0665 129.342 82.4948 126.503C79.923 123.663 75.5361 123.446 72.6964 126.017C69.8566 128.589 69.6393 132.976 72.2111 135.816C74.7828 138.655 79.1697 138.873 82.0095 136.301Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M68.2639 124.031L65.9833 125.644C65.963 125.659 65.9394 125.669 65.9145 125.674C65.8896 125.678 65.8641 125.676 65.8399 125.669C65.8157 125.661 65.7936 125.648 65.7754 125.631C65.7571 125.614 65.7432 125.592 65.7347 125.568L63.4013 119.289C63.3832 119.238 63.3822 119.183 63.3986 119.131C63.4149 119.079 63.4477 119.035 63.492 119.003L65.7725 117.39C65.7929 117.375 65.8164 117.365 65.8413 117.361C65.8662 117.356 65.8918 117.358 65.916 117.365C65.9401 117.373 65.9622 117.386 65.9805 117.403C65.9988 117.421 66.0127 117.442 66.0212 117.466L68.3545 123.745C68.3733 123.796 68.3746 123.852 68.3582 123.903C68.3418 123.955 68.3086 124 68.2639 124.031Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M91.7003 143.803L89.4171 145.419C89.3967 145.434 89.3731 145.443 89.3484 145.447C89.3237 145.451 89.2985 145.449 89.2746 145.441C89.2508 145.433 89.2291 145.42 89.2111 145.403C89.1931 145.386 89.1795 145.364 89.1711 145.341L86.8378 139.062C86.8187 139.012 86.817 138.956 86.8329 138.905C86.8489 138.854 86.8815 138.809 86.9258 138.778L89.209 137.162C89.2293 137.147 89.2529 137.137 89.2778 137.133C89.3027 137.129 89.3282 137.131 89.3524 137.138C89.3766 137.145 89.3987 137.158 89.4169 137.176C89.4352 137.193 89.4491 137.215 89.4576 137.238L91.791 143.517C91.8091 143.568 91.8101 143.624 91.7937 143.676C91.7774 143.727 91.7446 143.772 91.7003 143.803Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M78.9244 152.72L76.6439 154.334C76.6235 154.348 76.6 154.358 76.5751 154.363C76.5501 154.367 76.5246 154.365 76.5004 154.358C76.4763 154.351 76.4542 154.338 76.4359 154.32C76.4176 154.303 76.4037 154.281 76.3952 154.257L74.0619 147.979C74.044 147.928 74.0429 147.873 74.0588 147.822C74.0746 147.771 74.1066 147.726 74.1499 147.695L76.4331 146.079C76.4536 146.065 76.4772 146.055 76.5019 146.052C76.5266 146.048 76.5519 146.05 76.5758 146.057C76.5998 146.064 76.6217 146.077 76.6401 146.094C76.6584 146.111 76.6726 146.132 76.6817 146.155L79.0151 152.434C79.0339 152.485 79.0351 152.541 79.0187 152.593C79.0023 152.644 78.9691 152.689 78.9244 152.72Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M81.0424 115.112L78.7592 116.728C78.7385 116.742 78.7151 116.75 78.6905 116.754C78.666 116.757 78.641 116.755 78.6174 116.748C78.5938 116.74 78.5722 116.727 78.5541 116.71C78.5361 116.693 78.5221 116.672 78.5132 116.649L76.1798 110.371C76.1607 110.32 76.159 110.265 76.175 110.214C76.1909 110.162 76.2236 110.117 76.2678 110.087L78.551 108.471C78.5712 108.456 78.5944 108.447 78.6188 108.443C78.6433 108.438 78.6684 108.44 78.6922 108.447C78.716 108.454 78.7379 108.467 78.7561 108.483C78.7743 108.5 78.7884 108.521 78.7973 108.544L81.1306 114.823C81.1491 114.874 81.1504 114.93 81.1345 114.982C81.1186 115.034 81.0863 115.08 81.0424 115.112Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M87.3853 65.5916L67.6081 62.865C67.1218 62.7966 66.4231 61.7089 66.614 61.2515L82.1484 25.5292C82.2443 25.3 82.4131 25.1088 82.6286 24.9851C82.844 24.8614 83.0943 24.8121 83.3406 24.8448L103.338 27.4093C103.827 28.1801 103.817 28.3241 103.698 28.6051L88.5703 64.9108C88.4751 65.1388 88.3074 65.329 88.0932 65.452C87.879 65.5751 87.6302 65.6241 87.3853 65.5916Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M88.6602 70.2566L68.8829 67.5264C68.3967 67.4616 67.698 66.3703 67.8853 65.9164L83.4233 30.1905C83.5185 29.9626 83.6862 29.7724 83.9004 29.6493C84.1146 29.5263 84.3634 29.4772 84.6083 29.5098L89.8452 69.5722C89.7496 69.8001 89.582 69.9904 89.368 70.1139C89.154 70.2375 88.9054 70.2876 88.6602 70.2566Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M87.0035 64.9471L67.2262 62.2062C67.1125 62.1909 67.0038 62.1496 66.9087 62.0855C66.8136 62.0213 66.7345 61.9361 66.6777 61.8364C66.6209 61.7368 66.5879 61.6253 66.5812 61.5108C66.5745 61.3962 66.5943 61.2817 66.6391 61.1761L81.7665 24.8703C81.8627 24.6407 82.0322 24.4495 82.2485 24.3263C82.4648 24.2032 82.7158 24.1551 82.9623 24.1896L102.736 26.9161C102.852 26.9294 102.964 26.9698 103.062 27.0342C103.16 27.0985 103.242 27.185 103.3 27.2866C103.359 27.3882 103.393 27.5021 103.399 27.6192C103.406 27.7362 103.384 27.8532 103.337 27.9606L88.2101 64.2628C88.114 64.495 87.9431 64.6885 87.7245 64.8125C87.5059 64.9365 87.2521 64.9839 87.0035 64.9471Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M84.9829 51.5088C88.8141 51.5088 91.9199 48.403 91.9199 44.5718C91.9199 40.7406 88.8141 37.6348 84.9829 37.6348C81.1517 37.6348 78.0459 40.7406 78.0459 44.5718C78.0459 48.403 81.1517 51.5088 84.9829 51.5088Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M83.2 33.1613L80.4267 32.8012C80.4018 32.7983 80.378 32.7896 80.3572 32.7758C80.3363 32.762 80.3191 32.7434 80.3068 32.7216C80.2945 32.6998 80.2875 32.6755 80.2864 32.6505C80.2854 32.6255 80.2902 32.6006 80.3006 32.5778L82.8759 26.3972C82.8967 26.3472 82.9333 26.3053 82.9801 26.278C83.0269 26.2507 83.0814 26.2394 83.1352 26.2459L85.905 26.6061C85.9298 26.6092 85.9536 26.618 85.9744 26.6317C85.9953 26.6455 86.0127 26.664 86.0252 26.6856C86.0378 26.7072 86.0452 26.7314 86.0468 26.7564C86.0484 26.7814 86.0443 26.8063 86.0346 26.8294L83.4594 33.0317C83.4351 33.0777 83.3971 33.115 83.3506 33.1382C83.304 33.1615 83.2514 33.1696 83.2 33.1613Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M86.8523 63.6039L84.079 63.2437C84.0545 63.2402 84.0311 63.231 84.0107 63.217C83.9902 63.203 83.9733 63.1846 83.9611 63.163C83.949 63.1414 83.9419 63.1174 83.9405 63.0927C83.939 63.0679 83.9433 63.0432 83.9529 63.0204L86.5282 56.8362C86.5496 56.7868 86.5864 56.7457 86.6332 56.7191C86.68 56.6925 86.7341 56.6817 86.7875 56.6885L89.5608 57.0487C89.5858 57.0521 89.6096 57.0612 89.6304 57.0755C89.6513 57.0897 89.6685 57.1085 89.6807 57.1306C89.6929 57.1526 89.6999 57.1772 89.7009 57.2024C89.702 57.2275 89.6972 57.2526 89.6869 57.2756L87.1297 63.4562C87.1087 63.5099 87.0692 63.5543 87.0183 63.5814C86.9674 63.6085 86.9086 63.6165 86.8523 63.6039Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M71.4258 61.4135L68.6524 61.0353C68.6279 61.0317 68.6046 61.0226 68.5841 61.0086C68.5637 60.9946 68.5468 60.9761 68.5346 60.9546C68.5224 60.933 68.5153 60.909 68.5139 60.8842C68.5125 60.8595 68.5167 60.8348 68.5264 60.812L71.1016 54.6422C71.1225 54.5924 71.1593 54.551 71.2062 54.5243C71.2531 54.4976 71.3075 54.4871 71.3609 54.4945L74.1307 54.8547C74.1555 54.8577 74.1793 54.8665 74.2001 54.8803C74.221 54.8941 74.2384 54.9125 74.251 54.9341C74.2635 54.9558 74.2709 54.98 74.2725 55.005C74.2742 55.0299 74.27 55.0549 74.2604 55.078L71.6851 61.2622C71.6643 61.3123 71.6277 61.3541 71.5809 61.3814C71.534 61.4087 71.4796 61.42 71.4258 61.4135Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M98.6263 35.3543L95.8566 34.9941C95.8317 34.9911 95.808 34.9823 95.7871 34.9685C95.7662 34.9547 95.7489 34.9363 95.7363 34.9147C95.7237 34.8931 95.7164 34.8688 95.7147 34.8439C95.7131 34.8189 95.7173 34.7939 95.7269 34.7708L98.295 28.5686C98.3164 28.5192 98.3532 28.4781 98.4 28.4515C98.4467 28.4249 98.5009 28.4141 98.5543 28.4209L101.328 28.7811C101.352 28.7852 101.376 28.7947 101.396 28.8091C101.416 28.8235 101.433 28.8423 101.445 28.8641C101.458 28.8859 101.465 28.9102 101.466 28.9351C101.467 28.96 101.463 28.985 101.454 29.008L98.8857 35.1922C98.8667 35.2443 98.8309 35.2885 98.7839 35.3179C98.7369 35.3473 98.6814 35.3601 98.6263 35.3543Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M63.8964 139.983L45.7797 131.593C45.3331 131.387 44.9855 130.141 45.3001 129.761L70.5971 100.138C70.7559 99.9488 70.9729 99.8169 71.2143 99.7631C71.4558 99.7094 71.7082 99.7368 71.9325 99.8412L90.3117 108.14C90.5609 109.019 90.5069 109.152 90.3094 109.391L65.2295 139.689C65.0705 139.877 64.8537 140.008 64.6128 140.062C64.3718 140.115 64.1201 140.087 63.8964 139.983Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M69.0071 144.214L50.8933 135.827C50.4466 135.621 50.099 134.374 50.4136 133.994L75.7078 104.369C75.8674 104.181 76.0843 104.05 76.3255 103.996C76.5667 103.942 76.8188 103.969 77.0433 104.072L95.4225 112.371C95.6688 113.248 95.6149 113.381 95.4173 113.62L70.3375 143.917C70.1793 144.106 69.9632 144.238 69.7226 144.291C69.4821 144.345 69.2306 144.318 69.0071 144.214Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M63.7198 139.253L45.6059 130.866C45.5021 130.818 45.4107 130.746 45.3386 130.657C45.2664 130.568 45.2156 130.464 45.1898 130.353C45.164 130.241 45.1639 130.125 45.1897 130.014C45.2154 129.902 45.2663 129.798 45.3383 129.709L70.4205 99.4086C70.58 99.22 70.797 99.0889 71.0382 99.0352C71.2793 98.9815 71.5314 99.0083 71.7559 99.1114L89.8924 107.494C89.9962 107.543 90.0876 107.614 90.1597 107.703C90.2318 107.792 90.2827 107.896 90.3085 108.007C90.3343 108.119 90.3343 108.235 90.3086 108.346C90.2829 108.458 90.232 108.562 90.1599 108.651L65.0801 138.949C64.9208 139.145 64.6998 139.282 64.4529 139.337C64.206 139.393 63.9477 139.363 63.7198 139.253Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M63.3171 124.523C66.2683 126.966 70.6412 126.554 73.0842 123.603C75.5272 120.651 75.1152 116.279 72.1639 113.836C69.2127 111.393 64.8398 111.805 62.3968 114.756C59.9538 117.707 60.3658 122.08 63.3171 124.523Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M69.3694 107.745L66.8291 106.577C66.8062 106.567 66.7859 106.551 66.77 106.532C66.7541 106.512 66.7429 106.49 66.7373 106.465C66.7318 106.44 66.7321 106.415 66.7381 106.39C66.7441 106.366 66.7557 106.343 66.7721 106.324L71.0439 101.164C71.0784 101.122 71.1253 101.093 71.1777 101.081C71.23 101.069 71.2849 101.074 71.3342 101.095L73.8772 102.265C73.8996 102.276 73.9194 102.292 73.9349 102.311C73.9504 102.331 73.9613 102.354 73.9668 102.378C73.9723 102.402 73.9722 102.428 73.9666 102.452C73.9609 102.476 73.9499 102.499 73.9343 102.518L69.6625 107.679C69.6279 107.721 69.5803 107.75 69.5272 107.762C69.4741 107.774 69.4186 107.768 69.3694 107.745Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M49.8547 131.319L47.3144 130.152C47.2915 130.141 47.2712 130.126 47.2553 130.106C47.2394 130.087 47.2282 130.064 47.2226 130.039C47.2171 130.015 47.2174 129.989 47.2234 129.965C47.2294 129.94 47.241 129.918 47.2574 129.899L51.5292 124.738C51.5635 124.697 51.6102 124.669 51.6622 124.657C51.7142 124.645 51.7686 124.651 51.8172 124.672L54.3602 125.842C54.3827 125.853 54.4024 125.869 54.4179 125.889C54.4334 125.908 54.4443 125.931 54.4498 125.955C54.4553 125.979 54.4552 126.005 54.4496 126.029C54.4439 126.053 54.4329 126.076 54.4173 126.095L50.1455 131.256C50.1114 131.298 50.064 131.327 50.0112 131.339C49.9583 131.35 49.9031 131.343 49.8547 131.319Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M83.4858 114.347L80.9428 113.177C80.9204 113.167 80.9008 113.151 80.8854 113.131C80.87 113.112 80.8593 113.089 80.8541 113.065C80.8488 113.041 80.8492 113.016 80.8552 112.992C80.8611 112.968 80.8725 112.945 80.8885 112.926L85.1511 107.777C85.1852 107.735 85.2322 107.706 85.2847 107.694C85.3372 107.681 85.3923 107.687 85.4414 107.709L87.9844 108.879C88.0068 108.889 88.0264 108.905 88.0418 108.924C88.0572 108.944 88.0679 108.966 88.0731 108.991C88.0784 109.015 88.078 109.04 88.072 109.064C88.0661 109.088 88.0547 109.11 88.0387 109.129L83.7669 114.29C83.732 114.328 83.6861 114.354 83.6356 114.364C83.5851 114.374 83.5327 114.368 83.4858 114.347Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M63.9639 137.931L61.4209 136.761C61.3985 136.75 61.3789 136.734 61.3635 136.715C61.3481 136.696 61.3374 136.673 61.3322 136.649C61.3269 136.624 61.3273 136.599 61.3333 136.575C61.3392 136.551 61.3506 136.529 61.3666 136.51L65.6384 131.349C65.6724 131.308 65.7191 131.279 65.7712 131.267C65.8233 131.256 65.8779 131.261 65.9264 131.284L68.4694 132.454C68.4918 132.464 68.5114 132.48 68.5268 132.499C68.5421 132.519 68.5529 132.542 68.5581 132.566C68.5634 132.59 68.563 132.615 68.557 132.639C68.5511 132.663 68.5397 132.686 68.5237 132.704L64.2519 137.865C64.2183 137.907 64.1716 137.936 64.1193 137.948C64.067 137.96 64.0122 137.954 63.9639 137.931Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M95.1881 180.982C95.1881 181.386 94.8169 181.831 94.0298 182.189C93.2647 182.536 92.1906 182.758 90.9912 182.758C89.7918 182.758 88.7177 182.536 87.9525 182.189C87.1655 181.831 86.7943 181.386 86.7943 180.982V164.275H95.1881V180.982Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
      />
      <path
        d="M53.2191 175.333C53.2191 175.737 52.8479 176.181 52.0609 176.539C51.2957 176.887 50.2216 177.108 49.0222 177.108C47.8228 177.108 46.7487 176.887 45.9835 176.539C45.1965 176.181 44.8253 175.737 44.8253 175.333V158.626H53.2191V175.333Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
      />
      <path
        d="M31.5205 133.453C35.3517 133.453 38.4575 130.347 38.4575 126.516C38.4575 122.685 35.3517 119.579 31.5205 119.579C27.6893 119.579 24.5835 122.685 24.5835 126.516C24.5835 130.347 27.6893 133.453 31.5205 133.453Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M32.6228 115.019L30.0295 113.968C30.0064 113.958 29.9857 113.944 29.9691 113.925C29.9525 113.906 29.9404 113.884 29.9338 113.86C29.9272 113.836 29.9262 113.811 29.9309 113.786C29.9357 113.762 29.946 113.739 29.9611 113.719L33.9915 108.367C34.0236 108.323 34.0695 108.292 34.1218 108.277C34.1741 108.263 34.2297 108.266 34.2796 108.288L36.8693 109.35C36.8924 109.359 36.9131 109.374 36.9297 109.393C36.9463 109.411 36.9584 109.433 36.965 109.457C36.9716 109.481 36.9726 109.507 36.9679 109.531C36.9631 109.556 36.9528 109.579 36.9377 109.599L32.9109 114.94C32.8776 114.982 32.8317 115.013 32.7799 115.027C32.728 115.041 32.673 115.038 32.6228 115.019Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M28.6139 145.418L26.0242 144.366C26.0011 144.357 25.9804 144.342 25.9638 144.324C25.9472 144.305 25.9351 144.283 25.9285 144.259C25.9219 144.235 25.9209 144.209 25.9256 144.185C25.9303 144.16 25.9407 144.137 25.9558 144.117L29.9826 138.765C30.0147 138.722 30.0606 138.69 30.1129 138.676C30.1652 138.661 30.2207 138.665 30.2707 138.686L32.864 139.738C32.8871 139.747 32.9078 139.762 32.9244 139.78C32.941 139.799 32.9531 139.821 32.9597 139.845C32.9663 139.869 32.9673 139.894 32.9626 139.919C32.9578 139.943 32.9475 139.966 32.9324 139.986L28.902 145.338C28.8691 145.381 28.8232 145.412 28.7712 145.426C28.7192 145.44 28.664 145.438 28.6139 145.418Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M45.8772 146.737L43.5613 148.304C43.5408 148.318 43.5174 148.327 43.4927 148.331C43.4681 148.334 43.443 148.332 43.4193 148.324C43.3956 148.317 43.374 148.304 43.3561 148.286C43.3383 148.269 43.3247 148.248 43.3164 148.224L41.1157 141.896C41.0976 141.845 41.097 141.789 41.114 141.738C41.1309 141.686 41.1645 141.642 41.2094 141.611L43.5253 140.045C43.5462 140.031 43.5698 140.023 43.5945 140.02C43.6191 140.017 43.6441 140.019 43.6676 140.027C43.6911 140.035 43.7126 140.048 43.7303 140.066C43.7481 140.083 43.7617 140.104 43.7702 140.128L45.9853 146.449C46.0029 146.502 46.0018 146.559 45.9822 146.612C45.9625 146.664 45.9255 146.708 45.8772 146.737Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M80.0052 40.1962L63.4371 51.3616C63.0301 51.639 61.7839 51.2896 61.6362 50.825L49.3902 13.8384C49.3085 13.6039 49.3066 13.3489 49.3848 13.1131C49.463 12.8773 49.6168 12.674 49.8224 12.5346L66.4554 1.0918C67.3342 1.33672 67.4206 1.45197 67.5359 1.74372L80.4554 38.8923C80.5353 39.1288 80.5345 39.3852 80.453 39.6211C80.3715 39.8571 80.214 40.0593 80.0052 40.1962Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.2"
        d="M85.2782 43.6244L69.6031 54.2532C69.1925 54.527 74.1862 44.0891 74.0422 43.6244L54.6632 17.2667C54.5802 17.0323 54.5777 16.7769 54.656 16.5408C54.7342 16.3047 54.8888 16.1014 55.0954 15.9629C55.0954 15.9629 76.5763 36.7415 76.6771 37.0332L80.6463 27.629L85.7248 42.3386C85.8006 42.5723 85.798 42.8244 85.7174 43.0565C85.6368 43.2887 85.4826 43.4881 85.2782 43.6244Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M79.2884 39.9693L62.7203 51.1347C62.6261 51.1993 62.5185 51.2416 62.4055 51.2582C62.2925 51.2748 62.1772 51.2654 62.0685 51.2306C61.9597 51.1959 61.8603 51.1367 61.7779 51.0577C61.6955 50.9786 61.6322 50.8818 61.593 50.7745L48.6806 13.5827C48.5993 13.3478 48.598 13.0925 48.6768 12.8567C48.7556 12.6209 48.9102 12.4177 49.1164 12.2789L65.6846 1.13143C65.7787 1.06682 65.8864 1.0246 65.9994 1.00798C66.1124 0.991357 66.2276 1.00078 66.3364 1.03554C66.4452 1.07029 66.5446 1.12945 66.627 1.20849C66.7094 1.28754 66.7727 1.38437 66.8119 1.4916L79.7314 38.6402C79.819 38.8792 79.8229 39.1408 79.7424 39.3822C79.6619 39.6237 79.5019 39.8306 79.2884 39.9693Z"
        fill="#D0E7DA"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M64.2044 33.0654C68.0356 33.0654 71.1414 29.9596 71.1414 26.1284C71.1414 22.2972 68.0356 19.1914 64.2044 19.1914C60.3732 19.1914 57.2674 22.2972 57.2674 26.1284C57.2674 29.9596 60.3732 33.0654 64.2044 33.0654Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M55.2684 18.8116L52.9524 20.3784C52.9312 20.3918 52.9073 20.4003 52.8824 20.4033C52.8576 20.4063 52.8323 20.4038 52.8085 20.3958C52.7848 20.3879 52.763 20.3748 52.745 20.3575C52.7269 20.3401 52.7128 20.319 52.7039 20.2956L50.5068 13.9673C50.4877 13.9168 50.4865 13.8613 50.5036 13.8101C50.5207 13.7589 50.5549 13.7152 50.6005 13.6863L52.9128 12.1196C52.9333 12.1054 52.9568 12.0962 52.9814 12.0925C53.006 12.0889 53.0312 12.091 53.0548 12.0987C53.0785 12.1064 53.1001 12.1194 53.118 12.1367C53.1358 12.1541 53.1494 12.1753 53.1577 12.1988L55.3584 18.5271C55.3756 18.5779 55.3762 18.6329 55.36 18.6841C55.3438 18.7353 55.3117 18.78 55.2684 18.8116Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M78.2835 39.0724L75.964 40.6355C75.9432 40.6497 75.9195 40.6589 75.8946 40.6625C75.8698 40.6661 75.8444 40.6641 75.8204 40.6564C75.7965 40.6488 75.7746 40.6358 75.7564 40.6185C75.7382 40.6011 75.7242 40.5799 75.7155 40.5563L73.5184 34.228C73.4992 34.1776 73.4981 34.122 73.5152 34.0708C73.5322 34.0197 73.5664 33.9759 73.612 33.9471L75.928 32.3803C75.9485 32.3661 75.9719 32.3569 75.9966 32.3533C76.0212 32.3497 76.0463 32.3518 76.07 32.3594C76.0937 32.3671 76.1153 32.3801 76.1331 32.3975C76.151 32.4148 76.1646 32.4361 76.1729 32.4595L78.3772 38.795C78.3924 38.8453 78.3915 38.899 78.3747 38.9487C78.3579 38.9984 78.3261 39.0416 78.2835 39.0724Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M65.3207 47.7203L62.9976 49.2871C62.9767 49.3005 62.953 49.309 62.9284 49.312C62.9037 49.315 62.8788 49.3124 62.8552 49.3045C62.8317 49.2965 62.8103 49.2834 62.7925 49.2661C62.7748 49.2487 62.7612 49.2276 62.7527 49.2043L60.552 42.876C60.5348 42.8253 60.5346 42.7705 60.5515 42.7197C60.5684 42.669 60.6015 42.6252 60.6456 42.595L62.9616 41.0283C62.9821 41.0134 63.0058 41.0037 63.0308 40.9998C63.0559 40.996 63.0815 40.9983 63.1054 41.0064C63.1294 41.0145 63.1511 41.0283 63.1687 41.0465C63.1863 41.0647 63.1992 41.0869 63.2065 41.1111L65.4144 47.4394C65.4316 47.49 65.4317 47.5449 65.4148 47.5956C65.3979 47.6464 65.3649 47.6902 65.3207 47.7203Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M68.2275 10.1606L65.9115 11.7273C65.891 11.7415 65.8676 11.7507 65.8429 11.7543C65.8183 11.758 65.7932 11.7559 65.7695 11.7482C65.7458 11.7405 65.7242 11.7275 65.7064 11.7102C65.6885 11.6928 65.6749 11.6716 65.6666 11.6481L63.4659 5.31979C63.4491 5.26858 63.4491 5.21332 63.4659 5.1621C63.4828 5.11089 63.5156 5.06643 63.5596 5.03525L65.8791 3.46848C65.9 3.45511 65.9237 3.44661 65.9483 3.4436C65.9729 3.4406 65.9979 3.44316 66.0215 3.45112C66.045 3.45907 66.0664 3.4722 66.0841 3.48955C66.1019 3.50689 66.1155 3.528 66.124 3.55132L68.3247 9.87962C68.3422 9.93059 68.3421 9.98593 68.3245 10.0368C68.3069 10.0878 68.2727 10.1313 68.2275 10.1606Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M129.777 105.82C127.508 108.795 114.037 101.884 100.044 91.1935C86.0512 80.5035 77.1044 69.7306 79.1214 67.1121C81.6265 63.6874 84.79 60.7972 88.4265 58.6109C92.063 56.4246 96.0993 54.9862 100.299 54.3799C104.498 53.7736 108.777 54.0117 112.883 55.0802C116.99 56.1487 120.841 58.0261 124.213 60.6024C127.584 63.1787 130.408 66.402 132.517 70.0835C134.627 73.765 135.98 77.8306 136.499 82.0419C137.017 86.2533 136.689 90.5257 135.535 94.6088C134.381 98.6919 132.423 102.504 129.777 105.82Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M129.391 106.252C129.391 106.252 127.155 108.373 114.656 101.101C110.489 98.6773 121.068 90.0222 123.819 81.6517C127.421 70.6916 123.416 60.8984 124.18 61.4818C138.072 71.7109 140.326 92.4462 129.391 106.252Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M39.7758 120.155C39.7758 101.696 52.0218 86.2587 68.3594 82.4156C65.749 81.7951 63.0754 81.4796 60.3923 81.4756C40.2044 81.4756 23.8416 98.7857 23.8416 120.141C23.8416 141.495 40.2008 158.805 60.3923 158.805C63.076 158.806 65.7508 158.496 68.363 157.88C52.0074 154.037 39.7758 138.6 39.7758 120.155Z"
        fill={primaryDarkGreenColor}
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
      <path
        d="M60.3923 73.4229C35.9976 73.4229 16.2239 94.3383 16.2239 120.156C16.2239 145.973 35.9976 166.86 60.3923 166.86C84.7871 166.86 104.554 145.944 104.554 120.156C104.554 94.3671 84.7799 73.4229 60.3923 73.4229ZM60.3923 158.806C40.2045 158.806 23.8416 141.496 23.8416 120.141C23.8416 98.7865 40.2009 81.4764 60.3923 81.4764C80.5838 81.4764 96.9358 98.7865 96.9358 120.156C96.9358 141.525 80.5622 158.806 60.3923 158.806Z"
        fill="#EED9F7"
        stroke={primaryDarkGreenColor}
        strokeWidth="0.484257"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default BrokenClock;
