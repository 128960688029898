import { useContext } from 'react';
import { get } from 'lodash';

import { AuthContext } from 'context/Auth';

const useGetProductState = () => {
  const { me, meLoading } = useContext(AuthContext);
  const productState = get(me, 'account.productState', {});

  const isPreFunded = !!productState.preFunded;
  const isCreditCard = !!productState.creditCard;
  const isCapitalProducts = !!productState.capitalProducts;
  const isAccount = !!productState.account;

  return { isLoading: meLoading, isPreFunded, isCreditCard, isCapitalProducts, isAccount };
};

export default useGetProductState;
