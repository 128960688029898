import React from 'react';

export const ReceiptSkeleton = () => {
  const columns = 4;
  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-space-y-4 tw-pb-16">
      <div className="tw-flex tw-flex-wrap tw-gap-4">
        {Array(columns)
          .fill()
          .map((_, i) => (
            <div key={i} className="tw-w-56 tw-rounded-md tw-py-2 tw-px-4 tw-bg-neutral-grey-4 tw-h-96 tw-rounded" />
          ))}
      </div>
    </div>
  );
};

export const PaginationSkeleton = () => (
  <div className="tw-mt-12 tw-px-2 tw-flex tw-justify-between">
    <div className="tw-flex tw-space-x-2">
      {Array(2)
        .fill()
        .map((_, i) => (
          <div key={i} className="tw-flex tw-animate-pulse tw-bg-neutral-grey-4 tw-h-4 tw-w-12 tw-rounded" />
        ))}
    </div>
    <div className="tw-flex tw-space-x-2">
      {Array(2)
        .fill()
        .map((_, i) => (
          <div key={i} className="tw-flex tw-animate-pulse tw-bg-neutral-grey-4 tw-h-4 tw-w-12 tw-rounded" />
        ))}
    </div>
  </div>
);
