import { useState, useCallback, useContext, useMemo } from 'react';
import { compareDesc } from 'date-fns';

import { useToggle, useIsMobile } from 'hooks';
import { PaymentRequestCurrency } from 'types/invoicing';
import { PaymentRequestsContext } from 'components/PayorPortal/PaymentRequests/contexts/PaymentRequestsContext';

const useTable = () => {
  const { paymentRequests, bankAccounts } = useContext(PaymentRequestsContext);

  const [selectedPaymentRequestId, setSelectedPaymentRequestId] = useState('');
  const [selectedBankAccountId, setSelectedBankAccountId] = useState('');

  const isTabletOrMobile = useIsMobile();

  const { isOpen: isOpenInfoModal, open: openInfoModal, close: closeInfoModal } = useToggle();
  const {
    isOpen: isOpenConfirmPaymentModal,
    open: openConfirmPaymentModal,
    close: closeConfirmPaymentModal,
  } = useToggle();

  const sortedPaymentRequests = useMemo(
    () => [...paymentRequests].sort((a, b) => compareDesc(new Date(a.createdAt), new Date(b.createdAt))),
    [paymentRequests]
  );

  const CADBankAccount = useMemo(
    () => bankAccounts.find((bankAccount) => bankAccount.currency === PaymentRequestCurrency.CAD),
    [bankAccounts]
  );

  const USDBankAccount = useMemo(
    () => bankAccounts.find((bankAccount) => bankAccount.currency === PaymentRequestCurrency.USD),
    [bankAccounts]
  );

  const handleOpenInfoModal = useCallback(
    ({ id, currency }: { id: string; currency: PaymentRequestCurrency }) => {
      const bankAccount = currency === PaymentRequestCurrency.CAD ? CADBankAccount : USDBankAccount;

      setSelectedPaymentRequestId(id);
      setSelectedBankAccountId(bankAccount?.id || '');
      openInfoModal();
    },
    [openInfoModal, CADBankAccount, USDBankAccount]
  );

  const handleCloseInfoModal = useCallback(() => {
    setSelectedPaymentRequestId('');
    setSelectedBankAccountId('');
    closeInfoModal();
  }, [closeInfoModal]);

  const handleOpenConfirmPaymentModal = useCallback(
    ({ id, currency }: { id: string; currency: PaymentRequestCurrency }) => {
      const bankAccount = currency === PaymentRequestCurrency.CAD ? CADBankAccount : USDBankAccount;
      if (!bankAccount) return;

      setSelectedPaymentRequestId(id);
      setSelectedBankAccountId(bankAccount.id);
      openConfirmPaymentModal();
    },
    [openConfirmPaymentModal, CADBankAccount, USDBankAccount]
  );

  const handleCloseConfirmPaymentModal = useCallback(() => {
    setSelectedPaymentRequestId('');
    setSelectedBankAccountId('');
    closeConfirmPaymentModal();
  }, [closeConfirmPaymentModal]);

  return {
    paymentRequests: sortedPaymentRequests,
    selectedPaymentRequestId,
    selectedBankAccountId,
    isOpenInfoModal,
    handleOpenInfoModal,
    handleCloseInfoModal,
    isOpenConfirmPaymentModal,
    handleOpenConfirmPaymentModal,
    handleCloseConfirmPaymentModal,
    isTabletOrMobile,
    CADBankAccount,
    USDBankAccount,
  };
};
export default useTable;
