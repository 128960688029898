import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const WaitingListSuccess = ({ width = 160, height = 144, ...other }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 160 144" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M94.6658 57.0781C75.9803 57.0781 60.8204 72.1427 60.8204 90.7106C60.8204 109.279 75.9803 124.343 94.6658 124.343C113.351 124.343 128.511 109.279 128.511 90.7106C128.511 72.1427 113.351 57.0781 94.6658 57.0781Z"
        fill="#FFE8C3"
      />
      <path
        d="M87.8543 64.7417C69.1688 64.7417 54.0089 79.8063 54.0089 98.3742C54.0089 116.942 69.1688 132.007 87.8543 132.007C106.54 132.007 121.7 116.942 121.7 98.3742C121.7 79.8063 106.54 64.7417 87.8543 64.7417Z"
        stroke="#606265"
        strokeMiterlimit="10"
      />
      <path d="M88.0674 75.8101V100.928H104.671" stroke="#606265" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M41.0537 12.7589L38.1982 11.3209L38.0883 37.7594H28.8101C27.444 37.7594 26.3367 38.8668 26.3367 40.2328C26.3367 45.5261 30.6242 49.8172 35.9175 49.8172H53.684C60.9327 49.9278 63.0839 49.7064 65.1062 47.9366C66.9856 46.2919 67.5224 43.9542 67.5224 38.3125C67.5224 27.1397 67.6323 11.0996 67.6323 11.0996L64.4472 12.7589L61.5917 11.0996L58.5165 12.7589L55.7708 11.0996L52.9152 12.7589L49.9499 11.0996L46.9845 12.7589L44.0191 11.0996L41.0537 12.7589Z"
        fill="#D0E7F4"
      />
      <path
        d="M34.4258 40.9005V14.5054L37.3633 15.9718L40.3008 14.5054L43.2328 15.9718L46.2043 14.5054L49.1134 15.9718L52.0316 14.5054L54.9627 15.9718L57.926 14.5054L60.8644 15.9718L63.801 14.5054V35.0349"
        stroke="#606265"
        strokeLinejoin="round"
      />
      <path
        d="M63.8006 34.9399V45.3703C63.8006 47.3462 63.0237 49.2412 61.6408 50.6384C60.2578 52.0356 58.3822 52.8205 56.4264 52.8205V52.8205C54.4706 52.8205 52.595 52.0356 51.2121 50.6384C49.8291 49.2412 49.0522 47.3462 49.0522 45.3703V40.9001H23.9799C23.7857 40.8984 23.5932 40.9357 23.4135 41.01C23.2338 41.0843 23.0705 41.194 22.9332 41.3327C22.7959 41.4714 22.6873 41.6363 22.6138 41.8179C22.5403 41.9995 22.5033 42.194 22.5051 42.3902C22.5051 48.3504 23.1263 52.8205 29.8793 52.8205H56.4264"
        stroke="#606265"
        strokeLinejoin="round"
      />
      <path d="M40.3859 23.0195H58.2664" stroke="#606265" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M46.3457 30.6831H58.2661" stroke="#606265" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M35.9602 81.3675C36.1721 81.3089 36.3097 81.5854 36.1351 81.719L25.9783 89.4955C25.9135 89.5451 25.8854 89.629 25.9071 89.7076L29.3163 102.037C29.3749 102.249 29.0984 102.386 28.9647 102.212L21.1883 92.055C21.1387 91.9902 21.0548 91.9621 20.9762 91.9838L8.64685 95.393C8.43495 95.4516 8.2974 95.1751 8.47197 95.0414L18.6288 87.2649C18.6935 87.2154 18.7217 87.1314 18.7 87.0528L15.2908 74.7235C15.2322 74.5116 15.5087 74.3741 15.6423 74.5487L23.4188 84.7055C23.4684 84.7702 23.5523 84.7984 23.6309 84.7766L35.9602 81.3675Z"
        fill="#8F5CCF"
      />
      <path
        d="M131.611 13.0411C131.824 13.0959 131.805 13.4042 131.587 13.4331L123.355 14.5231C123.274 14.5338 123.208 14.5925 123.188 14.6715L121.117 22.7135C121.062 22.9264 120.754 22.9078 120.725 22.6899L119.635 14.4573C119.624 14.3765 119.565 14.3103 119.486 14.2899L111.444 12.2191C111.231 12.1643 111.25 11.856 111.468 11.8271L119.7 10.7371C119.781 10.7264 119.848 10.6677 119.868 10.5887L121.939 2.5467C121.994 2.33379 122.302 2.35237 122.331 2.57033L123.421 10.8028C123.431 10.8837 123.49 10.9499 123.569 10.9703L131.611 13.0411Z"
        fill="#EEA530"
      />
      <path
        d="M148.009 132.668C148.118 132.859 147.883 133.06 147.711 132.924L138.816 125.894C138.752 125.843 138.663 125.836 138.593 125.877L128.744 131.494C128.553 131.603 128.351 131.369 128.488 131.196L135.518 122.301C135.569 122.237 135.575 122.149 135.535 122.078L129.918 112.229C129.809 112.038 130.043 111.837 130.216 111.973L139.111 119.003C139.175 119.054 139.263 119.06 139.334 119.02L149.183 113.403C149.374 113.294 149.575 113.528 149.439 113.701L142.408 122.596C142.358 122.66 142.351 122.748 142.392 122.819L148.009 132.668Z"
        fill={primaryDarkGreenColor}
      />
    </svg>
  );
};

export default WaitingListSuccess;
