import React from 'react';
import { FormProvider } from 'react-hook-form';

import { TextField, Select, SubmitButton } from 'components/FormFields/v2';
import Button from 'components/Button';
import { validateUSRoutingNumber, validateAccountNumber } from 'utility/validation/bankAccount';
import { useManualForm } from './hooks';
import { ManualFormProps } from './ManualForm.types';

const ManualForm = ({ country, currencies, onSuccess, onError, goBack, goBackLabel = 'Back' }: ManualFormProps) => {
  const { isUSBank, isCanadianBank, form, register, handleSubmit, onSubmit, currencyOptions, error, isLoading } =
    useManualForm({
      country,
      currencies,
      onSuccess,
      onError,
    });

  return (
    <FormProvider {...form} {...{ graphQLErrors: error?.graphQLErrors }}>
      <form onSubmit={handleSubmit(onSubmit)} className="tw-w-full">
        <div className="tw-flex tw-flex-col tw-space-y-5">
          <div className="tw-text-sm tw-text-neutral-grey-2">Enter details of your bank account.</div>
          <TextField
            type="text"
            name="bankName"
            placeholder={isCanadianBank ? 'Royal Bank of Canada' : 'Bank of America'}
            label="Bank Name"
            ref={register({ required: true })}
            required
          />

          {isCanadianBank && (
            <>
              <TextField
                type="text"
                name="transitNumber"
                placeholder="12345"
                label="Transit Number"
                ref={register({ required: true, minLength: 5, maxLength: 5 })}
                required
              />
              <TextField
                type="text"
                name="institutionNumber"
                placeholder="123"
                label="Institution Number"
                ref={register({ required: true, minLength: 3, maxLength: 3 })}
                required
              />
            </>
          )}

          {isUSBank && (
            <TextField
              type="text"
              name="routingNumber"
              placeholder="123456789"
              label="Routing Number"
              ref={register({ required: true, validate: validateUSRoutingNumber })}
              required
            />
          )}

          <TextField
            type="text"
            name="accountNumber"
            placeholder="1234567"
            label="Account Number"
            ref={register({
              required: true,
              minLength: isCanadianBank ? 5 : 3,
              maxLength: isCanadianBank ? 12 : 17,
              validate: validateAccountNumber,
            })}
            required
          />
          <Select
            type="text"
            name="accountType"
            label="Account Type"
            ref={register({ required: true })}
            options={[
              { name: 'Chequing', value: 'chequing' },
              { name: 'Savings', value: 'savings' },
            ]}
            required
          />
          <Select
            type="text"
            name="currency"
            label="Currency"
            ref={register({ required: true })}
            options={currencyOptions}
            required
          />
        </div>

        <div className="tw-flex tw-flex-row tw-justify-between tw-mt-12 tw-overflow-hidden">
          <Button onClick={goBack} type="button" secondary disabled={isLoading}>
            {goBackLabel}
          </Button>
          <SubmitButton onClick={handleSubmit} disabled={isLoading} isFetching={isLoading}>
            {isLoading ? 'Connecting...' : 'Connect'}
          </SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default ManualForm;
