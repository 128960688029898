import React from 'react';
import { FormProvider } from 'react-hook-form';
import { BiCheck } from 'react-icons/bi';

import WarningOutline from 'components/svg/WarningOutline';
import { RadioField } from 'components/FormFields/v2';
import { FlinksIframe, ManualForm } from './components';
import { useBankAccountConnection } from './hooks';
import { BankAccountConnectionProps } from './BankAccountConnection.types';

const BankAccountConnection = ({
  isConnected,
  currency,
  onCancel,
  onSubmit,
  onCancelLabel = 'Cancel',
  isManualForm,
  isManualFormInfo,
  flinksRedirectRoute,
  isPayor,
  inIntegrations = false,
  allowBankAccountCreation = false,
  onlyCA = false,
}: BankAccountConnectionProps) => {
  const { form, register, country, countryOptions, allowedCurrencies, showCountrySwitcher } = useBankAccountConnection({
    currency,
    onlyCA,
  });

  if (isConnected) {
    return (
      <div className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-secondary-light-green tw-mt-8 tw-mb-8">
        <div className="tw-mr-4 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
          <BiCheck className="tw-text-secondary-light-green" />
        </div>
        <div className="tw-text-sm tw-text-semantic-success">
          Your Bank Account details were successfully pulled with Flinks.
        </div>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-pt-4 tw-pb-8" role="integration">
      {showCountrySwitcher && (
        <FormProvider {...form}>
          <form className="tw-my-6">
            <label htmlFor="country" className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer tw-mb-4">
              Select the location of your bank
              <span className="tw-text-semantic-error">{' *'}</span>
            </label>
            <RadioField
              name="country"
              label="Country"
              options={countryOptions}
              ref={register({ required: true })}
              required
            />
          </form>
        </FormProvider>
      )}

      {isManualForm ? (
        <>
          {isManualFormInfo && (
            <div className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-secondary-pastel-yellow-50 tw-mb-8">
              <div className="tw-mr-4 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full">
                <WarningOutline className="tw-text-primary-dark-yellow" />
              </div>
              <div className="tw-text-sm tw-text-primary-dark-yellow">
                Thank you for connecting your bank account. Unfortunately, we are having trouble retrieving banking
                details to process the payment.
                <div className="tw-mt-2">Please provide your bank details below.</div>
              </div>
            </div>
          )}

          <ManualForm
            country={country}
            currencies={allowedCurrencies}
            goBack={onCancel}
            goBackLabel={onCancelLabel}
            onSuccess={onSubmit}
          />
        </>
      ) : (
        <FlinksIframe
          country={country}
          currencies={allowedCurrencies}
          isPayor={isPayor}
          redirectRoute={flinksRedirectRoute}
          inIntegrations={inIntegrations}
          allowBankAccountCreation={allowBankAccountCreation}
        />
      )}
    </div>
  );
};

export default BankAccountConnection;
