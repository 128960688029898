import { useState, useContext } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { CreditCardContext } from 'context/CreditCard';
import { decryptString } from 'utility/string';

const useSetCardFromQueryString = ({ creditCards }) => {
  const { setCreditCard, setActivateCard } = useContext(CreditCardContext);
  const [hasSetInitialCard, setHasSetInitialCard] = useState(false);

  const location = useLocation();

  const { selectedCardId, activate } = qs.parse(location?.search);

  if (selectedCardId && !hasSetInitialCard) {
    const decryptedId = decryptString(selectedCardId);
    const selectedCard = creditCards.find((card) => card.id === decryptedId);

    setActivateCard(!!activate);
    if (selectedCard) {
      setCreditCard(selectedCard);
      setHasSetInitialCard(true);
    }
  }
};

export default useSetCardFromQueryString;
