import React from 'react';

import { BsExclamationCircleFill } from 'react-icons/bs';

import { Banner, FormFooter, Href } from 'components/UI';
import { BannerSize, BannerType } from 'components/UI/Banner';
import { Checkbox } from 'components/FormFields/v2';
import useConfirmSelection from './hooks/useConfirmSelection';
import ConfirmCardRow from './components/ConfirmCardRow';

const ConfirmSelection = () => {
  const {
    cards,
    buttonEnabled,
    agreementAccepted,
    handleAgreementAcceptance,
    onPrevStep,
    startMigration,
    isCreditUser,
  } = useConfirmSelection();

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-items-center">
      <div className="tw-flex tw-w-2/3 tw-flex-col tw-gap-4 tw-pl-8 tw-pr-4">
        <Banner
          submitButton={false}
          icon={<BsExclamationCircleFill color="var(--colors-primary-warning)" size={18} />}
          iconAlignment="center"
          submitButtonLabel="no"
          type={BannerType.warning}
          size={BannerSize.sm}
          message="Once you click Start Migration, you will be switched to the new card platform within [timeframe]. Your old Loop Cards will stop working and be permanently de-activated on [insert date]."
          arrowIcon={false}
          centerAlignContent={true}
          classNames="tw-mb-0"
          hasBorder={true}
        />
        <p>You have chosen to re-create the following cards:</p>
      </div>

      <div className="tw-flex tw-flex-col tw-w-2/3 tw-py-2 tw-pl-8 tw-pr-4">
        <div className="tw-flex tw-gap-0.5 tw-bg-neutral-grey-5 tw-px-2 tw-py-3 tw-w-full">
          <div className="tw-text-sm tw-text-neutral-grey-1 tw-w-4/6 tw-pl-4">Card Name</div>
          <div className="tw-text-sm tw-text-neutral-grey-1 tw-w-1/6">Type</div>
          <div className="tw-text-sm tw-text-neutral-grey-1 tw-w-1/6 tw-text-center">Migrate?</div>
        </div>
        <div
          className="tw-flex tw-flex-col tw-overflow-y-auto tw-bg-neutral-grey-5 tw-w-full"
          style={{ maxHeight: '20rem' }}
        >
          {cards.map((card) => (
            <ConfirmCardRow key={card.cardId} card={card} />
          ))}
        </div>
      </div>
      <div className="tw-w-2/3 tw-py-6 tw-pl-8 tw-pr-4">
        <div className="tw-flex tw-justify-end">
          <p>
            I agree to the updated <Href href="#">Loop Global VISA Cardholder Agreement</Href>
          </p>
          <Checkbox
            checked={agreementAccepted.globalVisaAgreement}
            onChange={() => handleAgreementAcceptance('globalVisaAgreement')}
            className="tw-ml-4"
          />
        </div>
        {isCreditUser && (
          <div className="tw-flex tw-justify-end tw-mt-6">
            <p>
              I agree to the updated <Href href="#">Loop Card-based Credit Facility Terms and Conditions</Href>
            </p>
            <Checkbox
              checked={agreementAccepted.creditFacilityAgreement}
              onChange={() => handleAgreementAcceptance('creditFacilityAgreement')}
              className="tw-ml-4"
            />
          </div>
        )}
      </div>
      <div className="tw-w-full tw-border-b tw-border-neutral-grey-4 tw-bg-neutral-grey-4.5 tw-bottom-0 tw-rounded-b-md tw-flex tw-justify-center">
        <FormFooter
          onCancel={onPrevStep}
          cancelButtonLabel="Back"
          onSubmit={startMigration}
          submitButtonLabel={'Start Migration'}
          isDisabled={!buttonEnabled}
          rootClass="tw-w-2/3 tw-py-6 tw-px-8"
        />
      </div>
    </div>
  );
};

export default ConfirmSelection;
