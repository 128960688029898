import { useCallback, useState, useMemo } from 'react';

import { InternalContactStatus, InternalContactRole } from 'types/user';
import { Option } from 'components/UI/SearchDropdown/v2/SearchDropdown.types';
import {
  mapMembersToOptions,
  sortCardMembers,
} from 'components/creditCards/components/cardsSettings/Members/Members.utils';
import { UseEditCardMembersModalProps } from '../EditCardMembersModal.types';

const useEditCardMembersModal = ({
  members,
  internalContacts,
  handleUpdateMembers,
  closeModal,
}: UseEditCardMembersModalProps) => {
  const [selectedMembers, setSelectedMembers] = useState<Option[]>(mapMembersToOptions(members));

  const memberOptions = useMemo(() => {
    const filteredInternalContacts = internalContacts.filter(
      ({ status, role }) =>
        status === InternalContactStatus.active &&
        [InternalContactRole.account_owner, InternalContactRole.admin, InternalContactRole.member].includes(role)
    );

    const sortedInternalContacts = sortCardMembers(filteredInternalContacts);

    return mapMembersToOptions(sortedInternalContacts);
  }, [internalContacts]);

  const onSelectedMembersChange = useCallback((options: Option[]) => {
    setSelectedMembers(options);
  }, []);

  const handleCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onSubmit = useCallback(async () => {
    const selectedMembersIds = selectedMembers.map(({ value }) => value);

    await handleUpdateMembers({
      contacts: selectedMembersIds,
    });
  }, [handleUpdateMembers, selectedMembers]);

  return {
    handleCloseModal,
    onSubmit,
    memberOptions,
    selectedMembers,
    onSelectedMembersChange,
  };
};

export default useEditCardMembersModal;
