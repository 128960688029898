import React, { useContext } from 'react';

import { CardMigrationContext } from 'components/CardMigrationV2/context/CardMigrationContext';
import { FormFooter } from 'components/UI';

const Acknowledgement = () => {
  const { onNextStep } = useContext(CardMigrationContext);

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-items-center">
      <div className="tw-flex tw-w-2/3 tw-flex-col tw-gap-4 tw-py-6 tw-pl-8 tw-pr-4 tw-my-16">
        <p>
          Loop is excited to share the launch of the Loop Global VISA Card. This new offering will provide a more
          reliable experience when spending around the world and also comes with several new enhancements.
        </p>
        <p>
          Click continue to find out more information about our upgraded card experience and the next steps on how to
          switch.
        </p>
      </div>

      <div className="tw-w-full tw-border-b tw-border-neutral-grey-4 tw-bg-neutral-grey-4.5 tw-bottom-0 tw-rounded-b-md tw-flex tw-justify-center">
        <FormFooter onSubmit={onNextStep} submitButtonLabel={'Continue'} rootClass="tw-w-2/3 tw-py-6 tw-px-8" />
      </div>
    </div>
  );
};

export default Acknowledgement;
