import { PAYEE_RECORD_TYPES } from 'constants/index';
import { formatMoneyV2 } from 'utility/currency';

const getToDropdownOptions = ({ verifiedPayees }) => {
  return verifiedPayees.map((option) => ({
    name: `${option.record.displayName} (${option.record.currency})`,
    value: option.id,
  }));
};

const getFromDropdownOptions = ({ lineOfCredit, wallets, hasCurrencyCloud, currentToAccount, bankAccounts }) => {
  const fromAccounts = wallets?.length ? wallets : [];

  const options = fromAccounts.reduce((options, account) => {
    const balance = account.availableBalance;

    const skipOption = currentToAccount
      ? !hasCurrencyCloud && balance.currency !== currentToAccount.record?.currency
      : false;
    if (skipOption) return options;

    const name = `${account.displayName} (${balance.currency} ${formatMoneyV2(balance)})`;

    return [...options, { name, value: account.id }];
  }, []);

  if (lineOfCredit) {
    options.push({
      name: `${lineOfCredit.displayName} (${lineOfCredit.formattedAvailableCredit()})`,
      value: lineOfCredit.id,
    });
  }

  if (bankAccounts?.length) {
    bankAccounts.forEach((bankAccount) => {
      if (bankAccount.verified === false) return;
      if (bankAccount.country === 'US') return; // TODO: Remove filter after adding support & PAD agreement for US-based accounts for Payments
      options.push({
        name: bankAccount.displayName,
        value: bankAccount.id,
      });
    });
  }

  return options;
};

export const getDropdownOptions = ({ wallets, bankAccounts, payees, lineOfCredit, currentToAccount }) => {
  const verifiedPayees = payees.filter((p) => {
    return p.record.__typename === PAYEE_RECORD_TYPES.Supplier
      ? p.record.bankAccount && p.record.bankAccount.verified
      : p.record.verified;
  });

  const hasCurrencyCloud = currentToAccount && currentToAccount.record.hasCurrencyCloud;

  const toOptions = getToDropdownOptions({ verifiedPayees });

  const fromOptions = getFromDropdownOptions({
    lineOfCredit,
    wallets,
    hasCurrencyCloud,
    currentToAccount,
    bankAccounts,
  });

  return { toOptions, fromOptions };
};
