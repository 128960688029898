import React from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import AddTeamMember from './AddTeamMember';
import useIsMobile from 'hooks/useIsMobile';
import AddPerson from 'components/svg/AddPerson';
import Button from 'components/Button';

const AddTeamMemberModal = ({ show, setShow, shareString, loadingMembers, members, creditCard }) => {
  const onClose = () => setShow(false);

  const hasTeamMembers = members?.length > 1;

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4">
        {shareString}
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-border-t tw-border-neutral-grey-4">
        {hasTeamMembers ? (
          <AddTeamMember loadingMembers={loadingMembers} members={members} creditCard={creditCard} onClose={onClose} />
        ) : (
          <AddTeamMemberEmptyState onClose={onClose} />
        )}
      </div>
    </Modal>
  );
};

const AddTeamMemberEmptyState = ({ onClose }) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const navigateToTeamSettings = () => history.push('/dashboard/settings/team');

  return (
    <div className="tw-w-full">
      <div className="tw-flex tw-flex-col tw-items-center tw-mt-2">
        <AddPerson />
        <p className="tw-px-8 lg:tw-px-10 tw-my-6 tw-text-center">
          You don’t have any Team Members added to your Account. Add them now to start sharing your Loop Card with them.
        </p>
      </div>
      <div className="tw-flex tw-justify-end tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-6">
        <Button primary onClick={isMobile ? onClose : navigateToTeamSettings}>
          {isMobile ? 'Back' : 'Add New User'}
        </Button>
      </div>
    </div>
  );
};

export default AddTeamMemberModal;
