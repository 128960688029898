import { useState, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_PRE_APPROVAL_STATUS } from 'graphql/preApproval';
import { PreApprovalContext } from 'context/PreApproval';

import { PreApprovalStatus } from '../constants';

const useCheckPreApproval = () => {
  const { getPreApproved, setPreApproved } = useContext(PreApprovalContext);
  const isAlreadyPreApproved = getPreApproved();
  const [preApprovalStatus, setPreApprovalStatus] = useState(
    isAlreadyPreApproved ? PreApprovalStatus.APPROVED : PreApprovalStatus.IDLE
  );

  const handleError = (error) => {
    console.error(error);

    // LBP-1168 - In case  timeout, approve user so he can move on
    setPreApprovalStatus(PreApprovalStatus.APPROVED);
  };

  const handleCompleted = (data) => {
    setPreApproved({ status: data.preApprovalStatus });
    setPreApprovalStatus(data.preApprovalStatus ? PreApprovalStatus.APPROVED : PreApprovalStatus.REJECTED);
  };

  const [getPreApprovalStatus] = useLazyQuery(GET_PRE_APPROVAL_STATUS, {
    onError: handleError,
    onCompleted: handleCompleted,
  });

  const checkStatus = () => {
    if (isAlreadyPreApproved) return;
    setPreApprovalStatus(PreApprovalStatus.PENDING);

    getPreApprovalStatus();
  };

  return { checkStatus, preApprovalStatus };
};

export default useCheckPreApproval;
