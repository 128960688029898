import { PayBalanceType, PayBalanceCurrencyOptions } from 'types/payments';

export const cardRepaymentSteps = {
  Details: [0],
  Review: [1],
  Complete: [2],
};

export const PAYMENT_TYPE_INFO = {
  [PayBalanceType.STATEMENT_BALANCE]: {
    name: 'Statement Balance',
    description: 'Total amount due (accounts for any payments made since the statement end date)',
  },
  [PayBalanceType.CURRENT_BALANCE]: {
    name: 'Current Balance',
    description: 'Total amount including any amount not yet due from the current statement period',
  },
  [PayBalanceType.MINIMUM_DUE]: {
    name: 'Minimum Amount Due',
    description: 'Minimum payment required to avoid additional charges',
  },
};

export const PAYMENT_CURRENCY_INFO = {
  [PayBalanceCurrencyOptions.ALL_IN_CAD]: {
    name: 'Pay all in CAD',
  },
  [PayBalanceCurrencyOptions.ALL_IN_USD]: {
    name: 'Pay all in USD',
  },
  [PayBalanceCurrencyOptions.INDIVIDUAL]: {
    name: 'In Individual Currencies',
  },
};
