import SettingsOutline from 'components/svg/SettingsOutline';
import GridOutline from 'components/svg/GridOutline';

import { TopDropdownNavigation, TabNavItem } from 'components/UI/Layout/Layout.types';

export const onboardingDashboardTopDropdownNavigation: TopDropdownNavigation = [
  {
    name: 'Account Settings',
    id: '/onboarding/dashboard/settings/personal',
    link: '/onboarding/dashboard/settings/personal',
    Icon: SettingsOutline,
  },
];

export const onboardingDashboardTabNavItems: TabNavItem[] = [
  {
    route: '/onboarding/dashboard/home',
    Icon: GridOutline,
    label: 'Dashboard',
  },
];
