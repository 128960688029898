import React from 'react';

const ArrowRight = ({ size = 19, color = 'none', ...other }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M9.92188 4.4375L14.9844 9.5L9.92188 14.5625"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.2812 9.5H4.01562" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowRight;
