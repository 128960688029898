import { useContext } from 'react';

import { CardAutopayContext } from 'components/payments/CardAutopay/context/CardAutopay';

import { formatDateTime } from 'utility/date';

const useGetRecurringPaymentInfo = () => {
  const { recurringPayment, chargeNow, loadingRecurringPaymentData, loadingChargeNowData } =
    useContext(CardAutopayContext);

  const { fromAccount, fromAccountLabel, paymentType, enabled, id, processingPaymentDate, currency } =
    recurringPayment || {};
  const recurringPaymentId = id;
  const isAutopayEnabled = !!enabled;
  const formattedNextPaymentDate = formatDateTime(processingPaymentDate);

  return {
    loadingRecurringPaymentData,
    recurringPaymentId,
    hasRecurringPayment: !!recurringPayment,
    isAutopayEnabled,
    fromAccount,
    fromAccountLabel,
    paymentType,
    currency,
    formattedNextPaymentDate,
    loadingChargeNowData,
    chargeNow,
  };
};

export default useGetRecurringPaymentInfo;
