import React, { useState, useCallback, useRef, useEffect, createContext } from 'react';

import { LOCAL_STORAGE_KEYS } from 'constants/index';
import { loadFromLocalStorage, saveToLocalStorage } from '../utility/localStorage';

export enum ThemeMode {
  DARK = 'dark',
  LIGHT = 'light',
}

type ThemeContextType = {
  themeMode: ThemeMode;
  setLightTheme: () => void;
  setDarkTheme: () => void;
  toggleThemeMode: () => void;
  isDarkTheme: boolean;
};

const themeModeKey = LOCAL_STORAGE_KEYS.themeMode;

export const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

export const ThemeContextProvider = ({ children }: { children: React.ReactNode }) => {
  const initialThemeMode = (loadFromLocalStorage<ThemeMode>(themeModeKey) as ThemeMode) || ThemeMode.LIGHT;

  const [themeMode, setThemeMode] = useState(initialThemeMode);

  const documentBody = useRef(document.body);

  useEffect(() => {
    documentBody.current.dataset.themeMode = themeMode;
    saveToLocalStorage(themeModeKey, themeMode);
  }, [themeMode]);

  const setLightTheme = useCallback(() => {
    setThemeMode(ThemeMode.LIGHT);
  }, []);

  const setDarkTheme = useCallback(() => {
    setThemeMode(ThemeMode.DARK);
  }, []);

  const toggleThemeMode = useCallback(() => {
    setThemeMode((prev) => (prev === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT));
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        themeMode,
        setLightTheme,
        setDarkTheme,
        toggleThemeMode,
        isDarkTheme: themeMode === ThemeMode.DARK,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
