import React, { useState, createContext } from 'react';
import { ApolloError } from '@apollo/client';

import { AddPaymentRequestContextType, AddPaymentRequestDataType } from '../PayorsList.types';

export const AddPaymentRequestContext = createContext<AddPaymentRequestContextType>({} as AddPaymentRequestContextType);

export const AddPaymentRequestContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [paymentRequestInfo, setPaymentRequestInfo] = useState<AddPaymentRequestDataType | null>(null);
  const [error, setError] = useState<ApolloError | null>(null);

  return (
    <AddPaymentRequestContext.Provider
      value={{
        paymentRequestInfo,
        setPaymentRequestInfo,
        error,
        setError,
      }}
    >
      {children}
    </AddPaymentRequestContext.Provider>
  );
};
