import React, { useState, createContext, Dispatch, SetStateAction, useCallback, useEffect, useContext } from 'react';
import { get } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  APPROVAL_MODAL,
  APPROVAL_REQUEST_STATUS,
  INITIAL_NUM_PER_PAGE,
  INITIAL_PAGE,
} from 'components/Approvals/constants';
import { DatePeriod } from '../components/ApprovalRequest.types';
import { GET_CREDIT_CARDS_FOR_FILTER } from 'graphql/transactionsFilter';
import { CreditCard, InternalContactFilterType } from '../components/FilterBar/FilterBar.types';
import { GET_CARD_EXPENSES, GET_MEMBER_LIST, UPDATE_CARD_EXPENSE } from 'graphql/cardApprovals';
import { Currencies } from 'constants/currencies';
import { ExpenseTypes } from 'constants/expenseTypes';
import useIsMember from 'hooks/useIsMember';
import { toast } from 'react-toastify';
import { ApprovalRequestsContext } from './ApprovalRequests';
import { AuthContext } from 'context/Auth';
import { User } from 'types/user';

type FilterRequestsContextType = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  numPerPage: number;
  setNumPerPage: Dispatch<SetStateAction<number>>;
  handleClearFilters: () => void;
  period: DatePeriod | null;
  setPeriod: React.Dispatch<React.SetStateAction<DatePeriod | null>>;
  handleClearPagination: () => void;
  cardProducts: CreditCard[];
  selectedProducts: CreditCard[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<CreditCard[]>>;
  loadingCardProducts: boolean;
  memberList: InternalContactFilterType[];
  selectedMembers: InternalContactFilterType[];
  setSelectedMembers: React.Dispatch<React.SetStateAction<InternalContactFilterType[]>>;
  loadingMemberList: boolean;
  currencyList: Currencies[];
  selectedCurrencies: Currencies[];
  setSelectedCurrencies: React.Dispatch<React.SetStateAction<Currencies[]>>;
  expenseTypeList: ExpenseTypes[];
  selectedExpenseTypes: ExpenseTypes[];
  setSelectedExpenseTypes: React.Dispatch<React.SetStateAction<ExpenseTypes[]>>;
  hasDropdownFilters?: boolean;
  handleUpdateCardExpense: (
    transactionId: string[],
    status: APPROVAL_REQUEST_STATUS,
    declineReason?: string
  ) => Promise<void>;
  isUpdatingCardExpense: boolean;
};

export const FilterRequestsContext = createContext<FilterRequestsContextType>({
  page: 0,
  setPage: () => {},
  numPerPage: 0,
  setNumPerPage: () => {},
  handleClearPagination: () => {},
  handleClearFilters: () => {},
  period: null,
  setPeriod: () => {},
  cardProducts: [],
  selectedProducts: [],
  setSelectedProducts: () => {},
  loadingCardProducts: true,
  memberList: [],
  selectedMembers: [],
  setSelectedMembers: () => {},
  loadingMemberList: true,
  currencyList: [],
  selectedCurrencies: [],
  setSelectedCurrencies: () => {},
  expenseTypeList: [],
  selectedExpenseTypes: [],
  setSelectedExpenseTypes: () => {},
  hasDropdownFilters: false,
  handleUpdateCardExpense: () => Promise.resolve(),
  isUpdatingCardExpense: false,
});

export const FilterRequestsContextProvider: React.FC = ({ children }) => {
  const { isMember } = useIsMember();
  const { setSelectedRequests, activeModal, closeModal } = useContext(ApprovalRequestsContext);
  const { me } = useContext(AuthContext) as unknown as { me: User };
  const [page, setPage] = useState(INITIAL_PAGE);
  const [numPerPage, setNumPerPage] = useState(INITIAL_NUM_PER_PAGE);
  const [period, setPeriod] = useState<DatePeriod | null>(null);
  const [selectedProducts, setSelectedProducts] = useState<CreditCard[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<InternalContactFilterType[]>([]);
  const [selectedCurrencies, setSelectedCurrencies] = useState<Currencies[]>([]);
  const [selectedExpenseTypes, setSelectedExpenseTypes] = useState<ExpenseTypes[]>([]);

  const currencyList = [Currencies.CAD, Currencies.USD, Currencies.GBP, Currencies.EUR];
  const expenseTypeList = [ExpenseTypes.CardExpense, ExpenseTypes.ExpenseReimbursement];

  const handleClearPagination = useCallback(() => {
    setPage(INITIAL_PAGE);
    setNumPerPage(INITIAL_NUM_PER_PAGE);
  }, []);

  const userInternalContactId = get(me, 'internalContact.id');

  const handleClearFilters = useCallback(() => {
    setPeriod(null);
    setSelectedCurrencies([]);
    setSelectedProducts([]);
    setSelectedMembers([]);
    setSelectedExpenseTypes([]);
  }, []);

  const [getMembers, { data: memberListData, loading: loadingMemberList }] = useLazyQuery(GET_MEMBER_LIST);
  const memberListArr = get(memberListData, 'me.account.internalContacts') || [];

  const memberList = memberListArr.filter(
    (member: InternalContactFilterType) => member.manager?.id === userInternalContactId || !member.manager
  );

  const [getCards, { data: creditCardData, loading: loadingCardProducts }] = useLazyQuery(GET_CREDIT_CARDS_FOR_FILTER);
  const creditCards = get(creditCardData, 'me.account.creditCards') || [];

  const memberIds = memberList
    .filter((member: InternalContactFilterType) => member.role === 'member') // filter out admins
    .map((member: InternalContactFilterType) => member.id);

  const memberCards = creditCards.filter((product: CreditCard) =>
    product.contacts.some((contact) => memberIds.includes(contact.id))
  );

  const cardProducts = memberCards.map((cc: CreditCard) => ({ displayName: cc.displayName, id: cc.id }));

  const hasDropdownFilters =
    !!period || selectedCurrencies.length > 0 || selectedProducts.length > 0 || selectedMembers.length > 0;

  const [updateCardExpense, { loading: isUpdatingCardExpense }] = useMutation(UPDATE_CARD_EXPENSE, {
    refetchQueries: [GET_CARD_EXPENSES],
    awaitRefetchQueries: true,
    fetchPolicy: 'network-only',
  });

  const handleUpdateCardExpense = useCallback(
    async (transactionId: string[], status: APPROVAL_REQUEST_STATUS, declineReason?: string) => {
      try {
        await Promise.all(
          transactionId.map((id) =>
            updateCardExpense({
              variables: { transactionId: Number(id), status, declineReason: declineReason },
            })
          )
        );
        if (status === APPROVAL_REQUEST_STATUS.rejected && activeModal === APPROVAL_MODAL.declineCardExpense) {
          closeModal();
        }
        toast.success(`Card Expense successfully ${status}`);
      } catch (err) {
        console.error(err);
        toast.error('Error cancelling card Expense  - please try again');
      }
      setSelectedRequests([]);
    },
    [activeModal, closeModal, setSelectedRequests, updateCardExpense]
  );

  useEffect(() => {
    getCards();
    !isMember && getMembers();
  }, []);

  return (
    <FilterRequestsContext.Provider
      value={{
        page,
        setPage,
        numPerPage,
        setNumPerPage,
        handleClearPagination,
        period,
        setPeriod,
        handleClearFilters,
        cardProducts,
        selectedProducts,
        setSelectedProducts,
        loadingCardProducts,
        memberList,
        selectedMembers,
        setSelectedMembers,
        loadingMemberList,
        currencyList,
        selectedCurrencies,
        setSelectedCurrencies,
        expenseTypeList,
        selectedExpenseTypes,
        setSelectedExpenseTypes,
        hasDropdownFilters,
        handleUpdateCardExpense,
        isUpdatingCardExpense,
      }}
    >
      {children}
    </FilterRequestsContext.Provider>
  );
};
