import React from 'react';

import { PayeeType } from '..';
import { SupplierAddress, SupplierBankingDetails, SupplierDetails } from './components';
import { useAddSupplierStep } from './hooks';

const AddSupplierSteps = ({ step, setStep, handleNextStep, handlePrevStep, onFinish }) => {
  const { handleFinish, loading, mutationError, isOpenCompleteSection } = useAddSupplierStep({
    setStep,
    onFinish,
  });

  switch (step) {
    case 0:
      return <PayeeType onNextStep={handleNextStep} />;
    case 1:
      return (
        <SupplierDetails
          onNextStep={handleNextStep}
          onPreviousStep={handlePrevStep}
          error={mutationError}
          onSubmitSupplierDetails={handleFinish}
          loading={loading}
          isOpenCompleteSection={isOpenCompleteSection}
          onFinish={onFinish}
        />
      );
    case 2:
      return (
        <SupplierBankingDetails onNextStep={handleNextStep} onPreviousStep={handlePrevStep} error={mutationError} />
      );
    case 3:
      return (
        <SupplierAddress
          onPreviousStep={handlePrevStep}
          onFinish={handleFinish}
          loading={loading}
          error={mutationError}
        />
      );

    default:
      null;
  }
};

export default AddSupplierSteps;
