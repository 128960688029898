import React from 'react';
import { FormProvider } from 'react-hook-form';
import qs from 'query-string';

import { TextField, PasswordField, SubmitButton, Select } from 'components/FormFields/v2';
import ArrowRight from 'components/svg/ArrowRight';
import PasswordStrengthMeter from 'components/auth/PasswordStrengthMeter';
import { PASSWORD_REGEX, EMAIL_REGEX } from 'constants/regex';
import { useForm } from './hooks';
import { isNotURL } from 'utility/validators';
import { monthlyOnlineRevenueOptions } from 'constants/index';

const Form = () => {
  const { form, error, isSubmitting, currentPassword, register, handleSubmit, onSubmit } = useForm();
  const queryParams = qs.parse(window.location && window.location.search);

  return (
    <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-flex tw-gap-x-4 tw-mb-8">
          <TextField
            rootClass="tw-flex-1"
            name="firstName"
            label="Legal First Name"
            defaultValue={queryParams.name}
            placeholder="John"
            ref={register({
              required: true,
              validate: (value) => isNotURL(value) || 'First name cannot be a URL',
            })}
            tabIndex={1}
          />
          <TextField
            rootClass="tw-flex-1"
            name="lastName"
            label="Legal Last Name"
            placeholder="Doe"
            ref={register({
              required: true,
              validate: (value) => isNotURL(value) || 'Last name cannot be a URL',
            })}
            tabIndex={2}
          />
        </div>
        <TextField
          rootClass="tw-mb-8"
          name="legalBusinessName"
          label="Legal Business Name"
          placeholder="12345678 Canada Inc."
          ref={register({ required: true })}
          tabIndex={3}
        />
        <TextField
          rootClass="tw-mb-8"
          type="email"
          name="email"
          placeholder="example@example.com"
          defaultValue={queryParams.email}
          label="Work Email Address"
          ref={register({
            required: true,
            pattern: { value: EMAIL_REGEX, message: 'Please enter a valid email address' },
          })}
          tabIndex={4}
        />
        <Select
          rootClass="tw-mb-8"
          name="monthlyOnlineRevenue"
          label="What is your Average Monthly Business Revenue?"
          placeholder="Select Option"
          required
          ref={register({ required: true })}
          options={monthlyOnlineRevenueOptions}
          tabIndex={5}
        />
        <PasswordField
          rootClass="tw-mb-4"
          type="password"
          name="password"
          placeholder="∗∗∗∗∗∗∗∗"
          label="Create Password"
          ref={register({
            required: true,
            pattern: { value: PASSWORD_REGEX, message: 'Password does not the requirements' },
          })}
          tabIndex={6}
        />
        <PasswordStrengthMeter password={currentPassword} showScore={false} />
        <SubmitButton type="submit" className="tw-w-full tw-mt-8" tabIndex={7} disabled={isSubmitting}>
          {isSubmitting ? (
            'Submitting...'
          ) : (
            <div className="tw-flex tw-justify-center tw-items-center">
              <p className="tw-text-neutral-light tw-mr-2">Create Account</p>
              <ArrowRight />
            </div>
          )}
        </SubmitButton>
      </form>
    </FormProvider>
  );
};

export default Form;
