import React from 'react';
import { GiftsList } from './GiftsList';
import { RedeemdGiftsList } from './RedeemedGiftsList';

import './Rewards.css';

export const Rewards = () => {
  return (
    <div className="tw-flex tw-flex-col">
      <h4 className="tw-my-4">Redeem Points for Gift Cards</h4>
      <GiftsList />

      <h4 className="tw-mt-16">Redemption Activity</h4>
      <span className="tw-mt-4 tw-text-sm">
        In the table below, you can see all of the rewards you have redeemed your Loop Points for.
      </span>
      <RedeemdGiftsList />
    </div>
  );
};
