import React from 'react';

import styles from './PromoText.module.scss';

const PromoText = () => (
  <div className={styles.promo}>
    <h3 className={styles.promo__header}>
      Use your promo or invitation code to redeem a special sign-up offer. Get started in just a few clicks.
    </h3>
    <p className={styles.promo__text}>
      As an invited company, you’ll get access to priority onboarding, and join thousands of businesses that use Loop to
      manage all their banking needs. From corporate cards to international payments, FX, capital and so much more, all
      in multiple currencies and in a unified platform.
    </p>
  </div>
);

export default PromoText;
