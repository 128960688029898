import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const DocumentAttachOutline = ({ size = 20 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.53125 2.625H11.269C11.617 2.62505 11.9507 2.76329 12.1968 3.00932L17.9907 8.80318C18.2367 9.04927 18.3749 9.38298 18.375 9.73096V17.7188C18.375 18.2409 18.1676 18.7417 17.7984 19.1109C17.4292 19.4801 16.9284 19.6875 16.4062 19.6875H7.875C7.35286 19.6875 6.8521 19.4801 6.48288 19.1109C6.11367 18.7417 5.90625 18.2409 5.90625 17.7188V12.4688"
        stroke={primaryDarkGreenColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8125 2.95312V7.875C11.8125 8.2231 11.9508 8.55694 12.1969 8.80308C12.4431 9.04922 12.7769 9.1875 13.125 9.1875H18.0469"
        stroke={primaryDarkGreenColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5625 3.28125V9.51562C6.56421 9.64537 6.53991 9.77414 6.49105 9.89434C6.44219 10.0145 6.36974 10.1237 6.27799 10.2155C6.18624 10.3072 6.07704 10.3797 5.95684 10.4286C5.83664 10.4774 5.70787 10.5017 5.57812 10.5C5.08594 10.5 4.59375 10.1268 4.59375 9.51562V3.60938C4.59375 2.35471 5.27338 1.3125 6.5625 1.3125C7.85162 1.3125 8.53125 2.32969 8.53125 3.58395V9.27486C8.53125 11.0385 7.3902 12.4688 5.57812 12.4688C3.76605 12.4688 2.625 11.0389 2.625 9.27486V5.90625"
        stroke={primaryDarkGreenColor}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DocumentAttachOutline;
