import { setContext } from 'apollo-link-context';
import { GraphQLError } from 'graphql';

import { getToken } from 'utility/auth';
import { SKIP_AUTH_OPERATIONS, RESPONSE_STATUS_CODES } from 'constants/api';

// Handle auth token in graphQL requests
const authMiddleware = setContext(async (currentContext, previousContext) => {
  const token = await getToken();

  if (!token && currentContext.operationName && !SKIP_AUTH_OPERATIONS.includes(currentContext.operationName)) {
    throw new GraphQLError(RESPONSE_STATUS_CODES[401].description, {
      // @ts-ignore
      extensions: {
        code: RESPONSE_STATUS_CODES[401].message,
        http: {
          status: RESPONSE_STATUS_CODES[401].code,
        },
      },
    });
  }

  return {
    headers: {
      ...previousContext.headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export default authMiddleware;
