/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { SubmitButton, Select } from 'components/FormFields/v2';
import SettingsLayout from 'containers/settings/SettingsLayout';
import { User } from 'types/user';
import { UPDATE_ACCOUNT_SETTINGS } from 'graphql/accounts';
import { AuthContext } from 'context/Auth';
import Pencil from 'components/svg/Pencil';
import Button from 'components/Button';
import InfoBlock from './InfoBlock';
import SettingsHeader from './components/SettingsHeader';

const Payments = () => {
  return (
    <SettingsLayout>
      <PaymentsDetails />
    </SettingsLayout>
  );
};

const PaymentsDetails = () => {
  const { me } = useContext(AuthContext) as unknown as { me: User };
  const [numApprovers, setNumApprovers] = useState(me.account?.settings?.numApprovers || 1);
  const [editPayment, setEditPayment] = useState(false);
  const form = useForm({ defaultValues: { numberOfApprovers: numApprovers } });
  const { handleSubmit, register } = form;
  const [updateAccountSettings, { loading }] = useMutation(UPDATE_ACCOUNT_SETTINGS);

  const onSelectNumberApprovers = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setNumApprovers(Number(event.target.value));
  };

  const onSubmit = async () => {
    const submitToast = toast.loading('Saving...');
    try {
      const accountId = me.activeAccountId?.split('/').at(-1);
      const response = await updateAccountSettings({
        variables: {
          accountId,
          settings: { numApprovers },
        },
      });
      if (response.data.updateAccountSettings) {
        toast.update(submitToast, {
          render: 'Succesfully Saved!',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        setEditPayment(false);
      } else {
        throw new Error('Failed to save settings');
      }
    } catch {
      toast.update(submitToast, {
        render: 'Error Saving Settings',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const options = [
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
  ];

  const handleEdit = () => {
    setEditPayment(!editPayment);
  };

  return (
    <div className="tw-px-4 tw-py-8">
      <SettingsHeader title="Payment Approvals" />
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="tw-space-y-8">
          <div className="tw-pb-8 tw-space-y-8">
            <InfoBlock
              content={
                'This is a global setting for your account where you can define the number of approvers needed before an outbound payment is sent using "Send Payments." Account administrators and owners have the authority to approve payments of any amount. If the team member requesting the payment is also an assigned approver with a corresponding approval limit, their approval will count as one approval.'
              }
            />
            {!editPayment && (
              <div className="tw-flex tw-flex-col tw-my-12">
                <strong className="tw-mb-2">
                  Required Number of Approvals for Payments: <span className="tw-mb-2">{numApprovers}</span>
                </strong>
                <Button onClick={() => handleEdit()} className="tw-flex tw-items-center tw-pl-0">
                  <Pencil size="12" />
                  <small className="tw-ml-2">Edit</small>
                </Button>
              </div>
            )}
            {editPayment && (
              <div className="tw-flex tw-flex-col">
                <h5>Select number of approvals</h5>
                <Select
                  options={options}
                  name="numberOfApprovers"
                  label="Number of Approvers"
                  placeholder="Select a number"
                  rootClass="tw-mt-3 tw-mb-4 tw-w-1/4"
                  infoText="Ensure you have enough team administrators available to approve the payments"
                  onChange={onSelectNumberApprovers}
                  defaultValue={numApprovers}
                  ref={register({ required: true })}
                />
                <div>
                  <SubmitButton disabled={loading}>Save</SubmitButton>
                </div>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Payments;
