import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Checkbox, SubmitButton } from 'components/FormFields/v2';
import { Loaders } from 'components/cards/Loader';
import { Agreement, Details } from './components';
import { usePADAgreementUS } from './hooks';
import { PADAgreementUSProps } from './PADAgreementUS.types';

const PADAgreementUS = ({
  loading,
  nextStepLabel = 'Submit Transfer',
  onNextStep,
  externalBankAccount,
  internalBankAccount,
}: PADAgreementUSProps) => {
  const {
    userName,
    userEmail,
    businessName,
    formattedBusinessAddress,
    formattedDate,
    isLoading,
    form,
    handleAuthorizePDA,
    handleSubmit,
    register,
    isValid,
  } = usePADAgreementUS({
    externalBankAccount,
    onNextStep,
  });

  if (loading || isLoading)
    return (
      <div className="tw-text-center tw-p-20">
        <Loaders.Spinner />
      </div>
    );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleAuthorizePDA)}>
        <div className="tw-px-8 tw-py-8">
          <div className="tw-text-neutral-grey-2 tw-pb-2 tw-text-sm">Pre-authorized Debit Agreement</div>
          <div>
            By clicking “{nextStepLabel}”, I, <strong>{userName}</strong> acknowledge on behalf of {businessName} that I
            have read, understood, and agreed to the terms of this Electronic Authorization Agreement for ACH
            Transactions.
          </div>

          <div className="tw-overflow-y-scroll tw-h-96">
            <Details
              formattedDate={formattedDate}
              userName={userName}
              userEmail={userEmail}
              businessName={businessName}
              formattedBusinessAddress={formattedBusinessAddress}
              externalBankAccount={externalBankAccount}
              internalBankAccount={internalBankAccount}
            />
            <Agreement />
          </div>

          <Checkbox
            label="I accept the Pre-authorized Debit Agreement"
            name="acceptedTerms"
            ref={register({ required: true })}
            required
            rootClass="tw-mt-8"
          />
        </div>
        <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex tw-justify-end">
          <SubmitButton isFetching={loading} isDisabled={!isValid}>
            {loading ? 'Submitting...' : nextStepLabel}
          </SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default PADAgreementUS;
