import { useQuery, useMutation } from '@apollo/client';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { GET_MONTHLY_ONLINE_REVENUE } from 'graphql/onboarding';
import { ADD_DOCUMENT } from 'graphql/document';
import { monthlyOnlineRevenueOptions, documentCategories } from 'constants/index';
import { ONBOARDING_STEPS } from 'components/onboarding/constants';
import { onboardingList } from 'components/accountSummary/OnboardingSummary';

const useBusinessVerification = () => {
  const { data: monthlyOnlineRevenueData, loading } = useQuery(GET_MONTHLY_ONLINE_REVENUE);

  const monthlyOnlineRevenue = get(monthlyOnlineRevenueData, 'me.account.monthlyOnlineRevenue', '');
  const isPreRevenue = monthlyOnlineRevenue === monthlyOnlineRevenueOptions[0].name;
  const businessVerificationLabel = isPreRevenue
    ? ONBOARDING_STEPS.businessVerification.preRevenue.label
    : ONBOARDING_STEPS.businessVerification.revenue.label;
  const businessVerificationTitle = isPreRevenue
    ? ONBOARDING_STEPS.businessVerification.preRevenue.title
    : ONBOARDING_STEPS.businessVerification.revenue.title;

  const form = useForm();
  const { setValue, register, handleSubmit } = form;

  const handleOnFileUpload = (file: { blob: { signed_id: string } }) => {
    setValue('blobSignedId', file?.blob?.signed_id);
  };

  const history = useHistory();
  const navigateToIdVerification = () => history.push(onboardingList.identityVerification.link);
  const addBankAccountLink = '/onboarding/dashboard/settings/bank-accounts-settings';

  const category = documentCategories.find((category) => category.value === 'OTHERS')?.value;

  const [addDocument, { loading: isSubmitting }] = useMutation(ADD_DOCUMENT);
  const onSubmit = async (data: { blobSignedId: string; category: string }) => {
    try {
      const response = await addDocument({
        variables: { blobSignedId: data.blobSignedId, category: category },
      });

      if (!response?.data?.addDocument) {
        throw new Error('Error uploading documents - please try again');
      }

      navigateToIdVerification();
    } catch (error) {
      console.error(error);
      toast.error('Error submitting document - please try again');
    }
  };

  return {
    loading,
    businessVerificationLabel,
    businessVerificationTitle,
    isPreRevenue,
    form,
    register,
    handleSubmit,
    onSubmit,
    isSubmitting,
    handleOnFileUpload,
    navigateToIdVerification,
    addBankAccountLink,
  };
};

export default useBusinessVerification;
