import { Currencies } from 'constants/currencies';

export const possibleCurrencies = [
  {
    currency: Currencies.CAD,
    currencyLabel: 'Canadian Dollar',
  },
  {
    currency: Currencies.USD,
    currencyLabel: 'U.S. Dollar',
  },
];
