import CryptoJS from 'crypto-js';

import config from 'config';

// This encryption module mirrors the methods implemented in the banking application at Dashboard::EncryptionService

export const decrypt = (input) => {
  if (!input) return null;
  const { key, iv } = encryptionConfig();

  const decrypted = CryptoJS.AES.decrypt(input, key, { iv });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const encrypt = (input) => {
  if (!input) return null;
  const { key, iv } = encryptionConfig();

  const encrypted = CryptoJS.AES.encrypt(input, key, { iv });
  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
};

const encryptionConfig = () => {
  const encryptionKey = config.bankingEncryptionKey;
  const initializationVector = encryptionKey.slice(0, encryptionKey.length / 2);

  return {
    key: CryptoJS.enc.Hex.parse(encryptionKey),
    iv: CryptoJS.enc.Hex.parse(initializationVector),
  };
};
