import React from 'react';

import { useTransactionCategories } from 'hooks/useTransactionCategories';

export const LoopCategory = ({ id, description, loopId }) => {
  const { getIcon } = useTransactionCategories();
  return (
    <div key={`${id}`}>
      <div className="tw-flex tw-items-center">
        <span className="tw-mr-2">{getIcon(loopId)}</span>
        <span>{description}</span>
      </div>
    </div>
  );
};
