import React from 'react';

import { Progress } from 'components/UI';
import { Loaders } from 'components/cards/Loader';
import { EmptyState, Steps, AutomaticPayments } from '../';
import { cardRepaymentSteps } from '../../constants';
import { ContentProps } from './Content.types';
import { useContent } from './hooks';

const Content = ({ onNavigateBack, onFinish }: ContentProps) => {
  const {
    currentStep,
    onPrevStep,
    onNextStep,
    onResetSteps,
    isCompleted,
    onComplete,
    errorSteps,
    isLoading,
    isEmptyState,
    noWallets,
    noBankAccounts,
  } = useContent();

  if (isLoading)
    return (
      <div className="tw-my-8">
        <Loaders.Light />
      </div>
    );

  if (isEmptyState)
    return <EmptyState description="You have no credit cards to pay balances for." onGoBack={onNavigateBack} />;

  if (noWallets && noBankAccounts)
    return <EmptyState description="You have no wallets or bank accounts connected." onGoBack={onNavigateBack} />;

  return (
    <>
      <Progress
        currentStepIndex={currentStep}
        stepLabelsAndIndexes={cardRepaymentSteps}
        errorSteps={errorSteps}
        isCompleted={isCompleted}
        classNames="xs:tw-px-4 tw-mt-2"
      />
      <AutomaticPayments />
      <div className="tw-mt-8 tw-px-4 lg:tw-px-0">
        <h3 className="tw-hidden lg:tw-block tw-mb-6">Pay Card Balance</h3>

        <Steps
          currentStep={currentStep}
          onPrevStep={onPrevStep}
          onNextStep={onNextStep}
          onFinish={onFinish}
          onResetSteps={onResetSteps}
          onComplete={onComplete}
        />
      </div>
    </>
  );
};

export default Content;
