import React from 'react';
import cx from 'classnames';
import { IoHourglassOutline, IoCalendarOutline } from 'react-icons/io5';

import { useIsMobile } from 'hooks';
import { formatDate } from 'utility/date';
import { DueDateProps } from './DueDate.types';

const DueDate = ({ dueDate, isCurrentCycle, isDueDatePassed }: DueDateProps) => {
  const isMobile = useIsMobile();

  if (!dueDate) return null;

  const format = isMobile ? 'MMM d, yyyy' : 'MMMM d, yyyy';
  const formattedDueDate = formatDate(dueDate, format);

  const Icon = isCurrentCycle ? IoCalendarOutline : IoHourglassOutline;

  const dueDateClassName = isDueDatePassed ? 'tw-bg-semantic-warning' : 'tw-bg-secondary-pastel-yellow-50';
  const dueDateLabel = isDueDatePassed ? 'Payment Was Due' : 'Payment Due By';

  return (
    <div
      className={cx(
        'tw-flex tw-items-center tw-justify-between tw-gap-2 sm:tw-gap-6 tw-px-2 tw-border tw-border-neutral-grey-4 tw-rounded-md tw-cursor-default tw-text-sm tw-h-6',
        isCurrentCycle ? 'tw-bg-secondary-pastel-green-100' : dueDateClassName
      )}
    >
      <div className="tw-flex tw-gap-2 tw-items-center">
        <Icon className="tw-text-neutral-grey-2" size={18} />
        {isCurrentCycle ? (
          <span className="tw-tracking-tight sm:tw-tracking-normal">Next Statement Generation Date</span>
        ) : (
          dueDateLabel
        )}
      </div>
      <div className="tw-text-primary-dark-green">{formattedDueDate}</div>
    </div>
  );
};

export default DueDate;
