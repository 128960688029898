import React from 'react';

const RepeatOutline = ({ size = 21, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.125 5.42188L15.0938 7.39062L13.125 9.35938"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4375 7.39062H5.90625C5.03681 7.39322 4.20372 7.73976 3.58892 8.35455C2.97413 8.96934 2.6276 9.80243 2.625 10.6719V11.3281"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.875 16.5781L5.90625 14.6094L7.875 12.6406"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5625 14.6094H15.0938C15.9632 14.6068 16.7963 14.2602 17.4111 13.6455C18.0259 13.0307 18.3724 12.1976 18.375 11.3281V10.6719"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RepeatOutline;
