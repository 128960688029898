import React from 'react';

import Stripe from 'components/onboarding/integrations/Stripe';
import Shopify from 'components/onboarding/integrations/Shopify';
import WooCommerce from 'components/onboarding/integrations/WooCommerce';
import Amazon from 'components/onboarding/integrations/Amazon';
import Square from 'components/onboarding/integrations/Square';

const getSalesChannels = ({ reload }) => {
  return [
    {
      name: 'Stripe',
      component: <Stripe isPreApproval className="tw-w-full" />,
      testId: 'stripe-button',
    },
    {
      name: 'Shopify',
      component: <Shopify isPreApproval />,
      testId: 'shopify-button',
    },
    {
      name: 'WooCommerce',
      component: <WooCommerce isPreApproval />,
      testId: 'wooCommerce-button',
    },
    {
      name: 'Amazon',
      component: <Amazon isPreApproval onSuccess={reload} useOauth={true} className="tw-w-1/2" />,
      testId: 'amazon-button',
    },
    {
      name: 'Square',
      component: <Square isPreApproval />,
      testId: 'square-button',
    },
  ];
};

export default getSalesChannels;
