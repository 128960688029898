import { useContext, useState } from 'react';
import { get } from 'lodash';
import { useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

import { ReviewProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/ConvertFunds/components/Steps/Steps.types';
import { FundsContext } from 'components/Accounts/AccountsContent/contexts/FundsContext';
import { formatMoneyV2 } from 'utility/currency';
import { MOVE_FUNDS } from 'graphql/payments';
import { GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_STATEMENTS } from 'graphql/wallets';
import useGetExchangeRate from 'hooks/useGetExchangeRate';
import { ConvertFundsActionTypes } from 'components/Accounts/AccountsContent/components/AccountsModal/types/funds';
import { formatDateTime } from 'utility/date';
import { useDeepEffect } from 'hooks';
import { Currencies } from 'constants/currencies';
import { MIN_EXCHANGE_RATE_AMOUNT } from 'constants/index';

const useReview = ({ onNextStep, onPrevStep }: ReviewProps) => {
  const { convertFundsInfo, setConvertFundsInfo, setConvertFundsTransaction, setError } = useContext(FundsContext);

  const { originalAmount, chargedAmount, buyOrSell, fromAccount, toAccount } = convertFundsInfo || {};

  const fromAccountDisplayName = get(fromAccount, 'displayName', '');
  const toAccountDisplayName = get(toAccount, 'displayName', '');

  const formattedOriginalAmount = `${formatMoneyV2(originalAmount)} ${originalAmount?.currency}`;

  const [expired, setExpired] = useState(false);

  const { rate, rateExpiresAt, buy, sell, getExchangeRate, loadingRate, exchangeRateReference } = useGetExchangeRate();

  useDeepEffect(() => {
    if (!fromAccount || !toAccount) return;
    if (loadingRate || !buyOrSell || !rate || !sell || !buy || !rateExpiresAt || !exchangeRateReference) return;

    const rateAmounts = [buy, sell];
    const currentChargedAmount = rateAmounts.find(({ currency }) => currency === fromAccount.currency);
    const currentOriginalAmount = rateAmounts.find(({ currency }) => currency === toAccount.currency);

    if (!currentChargedAmount || !currentOriginalAmount) return;
    const currentRate = 1 / rate;

    setConvertFundsInfo((prevState) => ({
      ...prevState!,
      originalAmount: currentOriginalAmount,
      chargedAmount: currentChargedAmount,
      rate: Number(currentRate.toFixed(5)),
      rateExpiresAt,
      exchangeRateReference,
    }));
  }, [loadingRate, rate, buy, sell, buyOrSell, exchangeRateReference]);

  const onRefreshRate = () => {
    if (!toAccount?.currency || !fromAccount?.currency || toAccount.currency === fromAccount.currency || !buyOrSell)
      return;

    const payload = {} as {
      buy: { currency: Currencies; amount?: number };
      sell: { currency: Currencies; amount?: number };
    };

    const amount = Number(buyOrSell === ConvertFundsActionTypes.sell ? chargedAmount?.amount : originalAmount?.amount);

    const payloadAmount = amount > MIN_EXCHANGE_RATE_AMOUNT ? amount : MIN_EXCHANGE_RATE_AMOUNT;

    if (buyOrSell === ConvertFundsActionTypes.sell) {
      payload.buy = { currency: toAccount.currency };
      payload.sell = {
        currency: fromAccount.currency,
        amount: payloadAmount,
      };
    } else {
      payload.buy = { currency: toAccount.currency, amount: payloadAmount };
      payload.sell = { currency: fromAccount.currency };
    }

    getExchangeRate({
      variables: payload,
    });

    setExpired(false);
  };

  const [moveFunds, { loading }] = useMutation(MOVE_FUNDS, {
    refetchQueries: [{ query: GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_STATEMENTS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async () => {
    if (!fromAccount || !toAccount || !originalAmount || !chargedAmount || !buyOrSell) return;

    try {
      const response = await moveFunds({
        variables: {
          from: fromAccount.id,
          to: toAccount.id,
          originalAmount: { amount: originalAmount.amount, currency: originalAmount.currency },
          chargedAmount: { amount: chargedAmount.amount, currency: chargedAmount.currency },
          exchangeRateReference: convertFundsInfo?.exchangeRateReference,
        },
      });

      if (!response?.data?.moveFunds) {
        throw new Error('Error converting funds - please try again');
      }
      // Making sure it always shows the charged amount in the transaction
      const moveFundsResponse = { ...response.data.moveFunds, amount: chargedAmount };

      setConvertFundsTransaction(moveFundsResponse);
      onNextStep();
    } catch (err) {
      console.error(err);
      toast.error('Error converting funds - please try again');
      setError(err as ApolloError);
      onPrevStep();
    }
  };

  const today = formatDateTime(new Date());

  return {
    fromAccountDisplayName,
    toAccountDisplayName,
    formattedOriginalAmount,
    rate: convertFundsInfo?.rate,
    rateExpiresAt: convertFundsInfo?.rateExpiresAt,
    chargedAmount,
    setExpired,
    originalAmount,
    expired,
    onRefreshRate,
    loadingRate,
    loading,
    handleSubmit,
    today,
    buyOrSell,
  };
};

export default useReview;
