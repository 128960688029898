import { PaymentRequestScheduleFrequency, PaymentRequestSchedule } from 'types/invoicing';
import { ONGOING_FREQUENCY_OPTIONS, REQUESTS_RECURRING_TYPE } from './constants';
import { formatDateTime } from 'utility/date';

export const getFrequencyLabelByValue = (frequencyValue?: PaymentRequestScheduleFrequency) => {
  return ONGOING_FREQUENCY_OPTIONS.find(({ value }) => value === frequencyValue)?.name || '';
};

export const getPaymentRequestSchedule = (schedule?: PaymentRequestSchedule | null) => {
  const { frequency, startDate, endDate, numberOfOccurrences } = schedule || {};

  const isOngoing = frequency && frequency !== REQUESTS_RECURRING_TYPE.once.value;

  const frequencyType = isOngoing ? REQUESTS_RECURRING_TYPE.ongoing.name : REQUESTS_RECURRING_TYPE.once.name;

  const frequencyLabel = getFrequencyLabelByValue(frequency);

  const formattedStartDate = formatDateTime(startDate);
  const formattedEndDate = endDate && formatDateTime(endDate);
  const isStoppedByEndDate = !!endDate;
  const isStoppedByNumberOfOccurrences = !!numberOfOccurrences;
  const isStoppedByCancel = !isStoppedByEndDate && !isStoppedByNumberOfOccurrences;

  return {
    frequencyType,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  };
};

export const getDueDateNote = (dateLabel: string) =>
  `Note: If your Payor does not accept your payment request by the ${dateLabel}, the request will be marked as Overdue. If your Payor then accepts the request, the payment will be immediately initiated.`;
