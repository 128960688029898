import React, { useState } from 'react';

import AmazonOauth from './AmazonOauth';
import Modal from 'components/Modal';
import ModalV2 from 'components/Modal/v2';
import ConnectAmazonCard from 'components/integrations/ConnectAmazon';

const AmazonMws = (props) => {
  const { onSuccess, isPreApproval, className = 'w-50' } = props;
  const [showConnectAmazon, setShowConnectAmazon] = useState(false);
  const hideConnectAmazon = () => setShowConnectAmazon(false);
  const handleOnSuccess = () => {
    hideConnectAmazon();
    onSuccess && onSuccess();
  };

  const ModalComponent = isPreApproval ? ModalV2 : Modal;

  return (
    <>
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        onClick={() => setShowConnectAmazon(true)}
      >
        <img className={className} src="https://loop-public.s3.ca-central-1.amazonaws.com/amazon_logo.png" />
      </div>

      <ModalComponent show={showConnectAmazon} onClose={hideConnectAmazon}>
        <ConnectAmazonCard isPreApproval={isPreApproval} onCancel={hideConnectAmazon} onSuccess={handleOnSuccess} />
      </ModalComponent>
    </>
  );
};

const Amazon = (props) => {
  return props.useOauth ? <AmazonOauth {...props} /> : <AmazonMws {...props} />;
};

export default Amazon;
