import { getAccountStateWithQueries } from 'utility/accountState';

import useIsMember from 'hooks/useIsMember';
import useIsContactPermission from 'hooks/useIsContactPermission';

const useCardsAccountState = () => {
  const account = getAccountStateWithQueries();
  const accountState = account.accountState;
  const { isMember } = useIsMember();
  const { createVirtualCard, createPhysicalCard, creditAgreementSigned } = useIsContactPermission();
  const canCreateCard: boolean = createVirtualCard || createPhysicalCard;

  return {
    accountState,
    isMember,
    canCreateCard,
    creditAgreementSigned,
  };
};

export default useCardsAccountState;
