import React, { Dispatch, SetStateAction } from 'react';
import cx from 'classnames';
import { Switch } from '@headlessui/react';

const ToggleSwitch = ({
  enabled,
  setEnabled,
  disabled,
  showLabel = false,
  leftLabel = 'on',
  rightLabel = 'off',
}: {
  enabled: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  showLabel?: boolean;
  disabled?: boolean;
  leftLabel?: string;
  rightLabel?: string;
}) => {
  return (
    <Switch.Group>
      <div className="tw-flex tw-flex-row tw-justify-items-center tw-text-neutral-grey-1">
        {showLabel && <Switch.Label>{leftLabel}</Switch.Label>}
        <Switch
          checked={enabled}
          onChange={setEnabled}
          disabled={disabled}
          className={cx(
            enabled ? 'tw-bg-primary-dark-green' : 'tw-bg-neutral-grey-2',
            'tw-relative tw-inline-flex tw-items-center tw-rounded-full tw-transition-colors tw-duration-200 tw-ease-in-out',
            'tw-h-5 tw-w-9 tw-mx-2'
          )}
        >
          <span
            className={cx(
              enabled ? 'tw-translate-x-5' : 'tw-translate-x-1',
              'tw-inline-block tw-transform tw-bg-neutral-light tw-rounded-full tw-duration-200 tw-ease-in-out',
              'tw-h-3 tw-w-3'
            )}
          />
        </Switch>
        {showLabel && <Switch.Label>{rightLabel}</Switch.Label>}
      </div>
    </Switch.Group>
  );
};

export default ToggleSwitch;
