import { useHistory } from 'react-router-dom';

const useCardAutopay = () => {
  const history = useHistory();
  const onNavigateBack = () => history.goBack();

  return { onNavigateBack };
};

export default useCardAutopay;
