import { useState, useCallback, RefObject } from 'react';
import { useBasisTheory } from '@basis-theory/basis-theory-react';
import { useLazyQuery, useMutation } from '@apollo/client';
import type {
  TextElement as TextElementType,
  CardVerificationCodeElement as CardVerificationCodeElementType,
} from '@basis-theory/basis-theory-react/types';

import config from 'config';
import { getIdFromApolloGlobalId } from 'utility/apollo';
import { AUTHORIZE_BASIS_THEORY_SESSION } from 'graphql/basisTheory';
import { GET_CREDIT_CARDS_LIST, GET_CREDIT_CARD_DETAILS_BY_ID } from 'graphql/cards';

const useBasisTheoryOperations = () => {
  const { bt } = useBasisTheory(config.basisTheoryApiKey, { elements: true });
  const [authorizeBasisTheorySession] = useMutation(AUTHORIZE_BASIS_THEORY_SESSION);
  const [loading, setLoading] = useState(false);
  const [getCreditCardsForList] = useLazyQuery(GET_CREDIT_CARDS_LIST);
  const [getCreditCardByID] = useLazyQuery(GET_CREDIT_CARD_DETAILS_BY_ID);

  const createSession = useCallback(async () => {
    if (!bt) {
      throw new Error('Basis Theory not initialized');
    }

    const session = await bt.sessions.create();
    if (!session?.nonce) {
      throw new Error('Failed to create Basis Theory session');
    }

    return session;
  }, [bt]);

  const authorizeSession = useCallback(
    async (creditCardId: string, nonce: string) => {
      const response = await authorizeBasisTheorySession({ variables: { creditCardId, nonce } });

      if (!response.data?.authorizeBasisTheorySession) {
        throw new Error('Unable to authorize Basis Theory session');
      }

      return response;
    },
    [authorizeBasisTheorySession]
  );

  async function initializeSessionAndAuthorize(creditCardId: string) {
    setLoading(true);

    const session = await createSession();
    const { nonce, sessionKey } = session;

    await authorizeSession(creditCardId, nonce);

    return sessionKey;
  }

  const setPinProxyPatch = useCallback(
    async (
      path: string,
      pinRef: RefObject<TextElementType>,
      confirmPinRef: RefObject<TextElementType>,
      sessionKey: string
    ) => {
      if (!bt) {
        throw new Error('Basis Theory not initialized');
      }

      const res = await bt.proxy.patch({
        path,
        headers: {
          'BT-PROXY-KEY': config.basisTheorySetPinProxyKey,
        },
        body: {
          pin: pinRef.current,
          confirmPin: confirmPinRef.current,
        },
        apiKey: sessionKey,
      });

      if (res.error) {
        throw new Error(res.error);
      }

      return res;
    },
    [bt]
  );

  const activateCardProxyPost = useCallback(
    async (
      creditCardId: string,
      cardProcessorId: string,
      securityCodeRef: RefObject<CardVerificationCodeElementType>,
      sessionKey: string
    ) => {
      if (!bt) {
        throw new Error('Basis Theory not initialized');
      }

      const res = await bt.proxy.post({
        headers: {
          'BT-PROXY-KEY': config.basisTheoryActivateCardProxyKey,
        },
        body: {
          securityCode: securityCodeRef.current,
          tokenSecurityCode: `{{ token: ${cardProcessorId} | json: "$.data.cvc" }}`,
          cardId: getIdFromApolloGlobalId({ gid: creditCardId }),
        },
        apiKey: sessionKey,
      });

      if (res.error) {
        throw new Error(res.error);
      }

      return res;
    },
    [bt]
  );

  const setPin = useCallback(
    async (
      creditCardId: string,
      cardProcessorId: string,
      pinRef: RefObject<TextElementType>,
      confirmPinRef: RefObject<TextElementType>
    ) => {
      const sessionKey = await initializeSessionAndAuthorize(creditCardId);

      await setPinProxyPatch(cardProcessorId, pinRef, confirmPinRef, sessionKey);
    },
    [createSession, authorizeSession, setPinProxyPatch]
  );

  const activateCard = useCallback(
    async (
      creditCardId: string,
      cardProcessorId: string,
      securityCodeRef: RefObject<CardVerificationCodeElementType>
    ) => {
      const sessionKey = await initializeSessionAndAuthorize(creditCardId);
      const res = await activateCardProxyPost(creditCardId, cardProcessorId, securityCodeRef, sessionKey);
      if (!res.error) {
        await getCreditCardsForList({ fetchPolicy: 'network-only' });
        await getCreditCardByID({ variables: { creditCardId: creditCardId }, fetchPolicy: 'network-only' });
      }
    },
    [createSession, authorizeSession, setPinProxyPatch]
  );

  return {
    bt,
    loading,
    setLoading,
    createSession,
    authorizeSession,
    setPinProxyPatch,
    setPin,
    activateCard,
  };
};

export default useBasisTheoryOperations;
