import React, { useContext, useEffect } from 'react';
import qs from 'query-string';

import { AuthContext } from '../context/Auth';
import Loader from '../components/cards/Loader';

const Impersonate = () => {
  const { impersonateUser } = useContext(AuthContext);
  const data = qs.parse(location.search);

  useEffect(() => {
    impersonateUser(data);
  }, []);

  return <Loader />;
};

export default Impersonate;
