import React, { useContext, useState } from 'react';
import { Popover } from '@headlessui/react';
import { IoQrCodeOutline } from 'react-icons/io5';
import { useMutation } from '@apollo/client';

import { AuthContext } from 'context/Auth';
import { Loaders } from 'components/cards/Loader';
import SettingsLayout from 'containers/settings/SettingsLayout';
import Pencil from 'components/svg/Pencil';
import UpdateEmail from 'components/settings/UpdateEmail';
import UpdateMobilePhoneNumber from 'components/settings/UpdateMobilePhoneNumber';
import UpdatePassword from 'components/settings/UpdatePassword';
import InfoTooltip from 'components/InfoTooltip';
import App2StepAuthorization from './App2StepAuthorization';
import Modal from 'components/Modal/v2';
import styles from 'components/InfoTooltip.module.scss';
import usePhoneNumberFormat from 'hooks/usePhoneNumberFormat';
import SuccessCheckmark from 'components/svg/SuccessCheckmark';
import { UPDATE_MFA } from 'graphql/settings';
import { NotificationAlertContext } from 'context/NotificationAlert';
import Button, { ButtonSize } from 'components/Button';
import Notification from 'components/Notification';
import SettingsHeader from './components/SettingsHeader';

const PersonalInfoEntry = ({ value, label, editable, isVerified, rootClass, handleEditModal }) => {
  return (
    <>
      <div className={`tw-flex tw-flex-col ${rootClass}`}>
        <strong className="tw-mb-2">{label}</strong>
        <p className="tw-break-words tw-mb-2">
          {value}
          {isVerified && (
            <span className="tw-m-2 tw-bg-secondary-light-green tw-rounded tw-text-semantic-success tw-p-1 ">
              Verified
            </span>
          )}
        </p>

        {editable && (
          <Button
            data-testid="edit-button"
            onClick={() => handleEditModal(label)}
            className="tw-flex tw-items-center"
            size={ButtonSize.text}
          >
            <Pencil size="12" />
            <small className="tw-ml-2">Edit</small>
          </Button>
        )}
      </div>
    </>
  );
};

const PersonalSection = ({ email, mobilePhoneNumber, handleEdit, handleToggleModal, firstName, lastName }) => {
  return (
    <div className="tw-pr-8">
      <SettingsHeader title="Personal Information" />
      <PersonalInfoEntry rootClass="tw-my-12" value={`${firstName} ${lastName}`} label="Name" hasSeparator />
      <PersonalInfoEntry
        handleEdit={handleEdit}
        value={email}
        label="Email"
        handleEditModal={handleToggleModal}
        // editable
        hasSeparator
        rootClass="tw-my-12"
      />
      <PersonalInfoEntry
        handleEdit={handleEdit}
        value={mobilePhoneNumber}
        label="Phone"
        handleEditModal={handleToggleModal}
        editable
        rootClass="tw-my-12"
      />
    </div>
  );
};

const SecuritySection = ({ smsMfaEnabled, mobilePhoneNumberVerified, handleToggleModal, totpMfaEnabled }) => {
  const mfaEnabled = (mobilePhoneNumberVerified && smsMfaEnabled) || totpMfaEnabled;
  return (
    <div data-testid="security-section">
      <SettingsHeader title="Security" />
      <>
        <div className="tw-flex tw-flex-col tw-my-12">
          <span className="tw-font-semibold tw-mb-4">Password</span>

          <div className="tw-mb-2">
            <Button
              onClick={() => handleToggleModal('password')}
              className="tw-flex tw-flex-row tw-items-center tw-text-sm tw-text-primary-dark-green hover:tw-text-neutral-grey-1"
              size={ButtonSize.text}
            >
              <span>Change Password</span>
            </Button>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-my-12">
          <br />
          <span className="tw-font-semibold tw-mb-4">Two Step Verification</span>
          <span className="tw-container tw-text-sm">
            Protect your accounts by enabling two step verification via Authenticator App.
          </span>
          <br />
          {totpMfaEnabled && (
            <>
              <div className="tw-flex tw-items-center">
                <SuccessCheckmark size="20" />
                <span className="tw-ml-2">2FA - enabled</span>
              </div>
            </>
          )}
          {mobilePhoneNumberVerified && smsMfaEnabled && (
            <>
              <div className="tw-flex tw-items-center">
                <SuccessCheckmark size="20" />
                <span className="tw-ml-2">SMS - enabled</span>
              </div>
            </>
          )}
          <br />
          <div className="tw-mb-2">
            <AuthenticationPopover handleToggleModal={handleToggleModal} mfaEnabled={mfaEnabled} />
          </div>
        </div>
      </>
    </div>
  );
};

const AuthenticationPopover = ({ handleToggleModal, mfaEnabled }) => {
  return (
    <Popover>
      <Popover.Button className="tw-flex tw-justify-center tw-w-max tw-p-3 tw-rounded-md tw-text-neutral-light tw-bg-primary-dark-green hover:tw-text-neutral-light">
        {mfaEnabled && <span className="tw-text-center">Update Two-Step Verification</span>}
        {!mfaEnabled && <span className="tw-text-center">Enable Two-Step Verification</span>}
      </Popover.Button>

      <Popover.Panel className="tw-shadow-notification tw-top-2 tw-rounded  tw-w-56 tw-relative">
        <div className={styles.arrowUp} />
        <div
          onClick={() => handleToggleModal('2fa_APP')}
          className="tw-cursor-pointer tw-p-4 tw-rounded hover:tw-bg-neutral-grey-4 tw-flex tw-items-center"
        >
          <IoQrCodeOutline size={30} />
          <span className="tw-mx-3 tw-text-lg tw-cursor-pointer ">2FA App</span>
          <InfoTooltip size={20} name={'2fa'} mobileOrientation="right">
            <>
              <strong>Why do I need this?</strong>
              <p className="text-white">
                Passwords can get stolen - especially if you use the same password for multiple sites. Adding Two-Step
                Verification means that, even if your password gets stolen, your Loop account will remain secure.{' '}
              </p>
              <strong className="tw-my-10">How does it work? </strong>
              <p className="text-white">
                After you turn on Two-Step Verification for your account, signing in will be a little different:{' '}
              </p>
              <p className="text-white">1. You'll enter your password, as usual.</p>
              <p className="text-white">2. You can enter the code from your authenticator app and sign-in. </p>
            </>
          </InfoTooltip>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

const Content = ({ me, handleToggleModal, handleDisableClick }) => {
  const {
    email,
    handleEdit,
    mobilePhoneNumber,
    firstName,
    mobilePhoneNumberVerified,
    lastName,
    smsMfaEnabled,
    totpMfaEnabled,
  } = me || {};
  const { formatToPhoneNumber } = usePhoneNumberFormat();
  const phoneNumber = formatToPhoneNumber(mobilePhoneNumber);

  return (
    <div className="tw-w-90">
      <div className="tw-container-md tw-flex tw-flex-row tw-justify-start tw-mb-5 tw-px-4 tw-py-8">
        <div className="tw-container tw-w-1/3">
          <PersonalSection
            email={email}
            handleEdit={handleEdit}
            mobilePhoneNumber={phoneNumber}
            firstName={firstName}
            lastName={lastName}
            handleToggleModal={handleToggleModal}
          />
        </div>
        <div className="tw-container tw-w-1/3">
          <SecuritySection
            mobilePhoneNumber={phoneNumber}
            handleToggleModal={handleToggleModal}
            smsMfaEnabled={smsMfaEnabled}
            mobilePhoneNumberVerified={mobilePhoneNumberVerified}
            handleClickConfirm={handleDisableClick}
            totpMfaEnabled={totpMfaEnabled}
          />
        </div>
      </div>
    </div>
  );
};

const ConfirmDialog = ({ show, onClose, onConfirm }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-flex-col tw-items-center tw-p-4">
        <p>Are you sure you want to disable Two-Step Verification?</p>
        <div className="tw-flex tw-justify-between tw-mt-8 tw-w-full">
          <Button secondary onClick={onClose} className="tw-w-44">
            Cancel
          </Button>
          <Button primary onClick={onConfirm} className="tw-w-44">
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const PersonalSettings = () => {
  const { me, meLoading, meRefetch } = useContext(AuthContext);
  const { setNotification } = useContext(NotificationAlertContext);
  const [updateMFA] = useMutation(UPDATE_MFA);

  const { email, mobilePhoneNumber } = me || {};

  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);
  const [showUpdatePhoneModal, setShowUpdatePhoneModal] = useState(false);
  const [showPassWordModal, setShowPasswordModal] = useState(false);
  const [showSMS2Step, setSMS2Step] = useState(false);
  const [showApp2Step, setApp2Step] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const hideConfirm = () => {
    setShowConfirm(false);
  };

  const handleDisableClick = () => {
    setShowConfirm(true);
  };

  const updateMFACall = async () => {
    try {
      await updateMFA({ variables: { smsEnabled: false } });
      setNotification(
        <Notification Icon={SuccessCheckmark} message="SMS - Two-factor Authentication has been disabled" />
      );
      hideConfirm();
    } catch (err) {
      console.error(err);
    }
    meRefetch && (await meRefetch());
  };

  const handleToggleModal = (type) => {
    if (type.toLowerCase() === 'email') setShowUpdateEmailModal(!showUpdateEmailModal);
    if (type.toLowerCase() === 'phone') setShowUpdatePhoneModal(!showUpdatePhoneModal);
    if (type === 'password') setShowPasswordModal(!showPassWordModal);
    if (type === '2fa') setSMS2Step(!showSMS2Step);
    if (type === '2fa_APP') setApp2Step(!showApp2Step);
  };

  return (
    <div className="tw-container-me tw-flex tw-flex-col tw-justify-around tw-w-full" data-testid="update-modal">
      <SettingsLayout>
        {meLoading ? (
          <Loaders.Light />
        ) : (
          <Content handleDisableClick={handleDisableClick} handleToggleModal={handleToggleModal} me={me} />
        )}
      </SettingsLayout>

      <UpdateMobilePhoneNumber
        show={showUpdatePhoneModal}
        closeModal={handleToggleModal}
        mobilePhoneNumber={mobilePhoneNumber}
        meRefetch={meRefetch}
      />
      <UpdateEmail show={showUpdateEmailModal} meRefetch={meRefetch} closeModal={handleToggleModal} email={email} />
      <UpdatePassword show={showPassWordModal} closeModal={handleToggleModal} />
      <ConfirmDialog show={showConfirm} onClose={hideConfirm} onConfirm={updateMFACall} />
      <App2StepAuthorization show={showApp2Step} phone={mobilePhoneNumber} closeModal={handleToggleModal} />
    </div>
  );
};

export default PersonalSettings;
