import { Currencies } from 'constants/currencies';

export enum PayBalanceCurrency {
  CAD = Currencies.CAD,
  USD = Currencies.USD,
  EUR = Currencies.EUR,
  GBP = Currencies.GBP,
}

export enum PayBalanceType {
  CURRENT_BALANCE = 'CURRENT_BALANCE',
  MINIMUM_DUE = 'MINIMUM_DUE',
  STATEMENT_BALANCE = 'STATEMENT_BALANCE',
}

export enum PayBalanceCurrencyOptions {
  ALL_IN_CAD = 'ALL_IN_CAD',
  ALL_IN_USD = 'ALL_IN_USD',
  INDIVIDUAL = 'INDIVIDUAL',
}

export type Transaction = {
  id: string;
  originalAmount: Money<PayBalanceCurrency>;
  fxAmount?: Money<PayBalanceCurrency>;
  availableFromAccountIds: string[];
  selectedFromAccountId: string | undefined;
  isDisabled: boolean;
  isFromBankAccount: boolean;
};

export type PayBalanceTransactionResponse = {
  transactionGroupId: string;
  initiatedAt: Date;
  amount: Money<PayBalanceCurrency>;
  fromAccount: {
    displayName: string;
    currency: PayBalanceCurrency;
  };
  toAccount: {
    displayName: string;
    currency: PayBalanceCurrency;
  };
  transferMethod: string;
};

export type PayBalanceTransaction = Transaction & {
  transactionGroupId: string | undefined;
  initiatedAt: Date | undefined;
};

export type PayBalance = {
  paymentType?: PayBalanceType;
  paymentCurrencyOption?: PayBalanceCurrencyOptions;
  fromAccounts?: { [id: string]: string };
  transactions: Transaction[];
};

export type Money<T = PayBalanceCurrency.CAD> = {
  amount: number;
  currency: T;
};

export type MoneyInCents<T = PayBalanceCurrency.CAD> = { cents: number; currency_iso: T };
