import { useMutation } from '@apollo/client';

import { UPDATE_SUBSCRIPTION } from 'graphql/subscriptions';

const useUpdateSubscription = () => {
  const [updateSubscription, { loading }] = useMutation(UPDATE_SUBSCRIPTION);

  return { updateSubscription, loading };
};

export default useUpdateSubscription;
