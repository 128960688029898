import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useState } from 'react';
import {
  CREATE_XERO_BANK_FEED_CONNECTION,
  DELETE_XERO_BANK_FEED_CONNECTION,
  GET_XERO_BANK_FEED_CONNECTIONS,
} from 'graphql/xeroBankFeeds';
import { Currencies } from 'constants/currencies';
import { AccountingBankFeedType } from 'types/accountingBankFeed';

const useBankFeedSwitch = ({
  feedConnection,
  currency,
}: {
  feedConnection?: AccountingBankFeedType;
  currency: Currencies;
}) => {
  const [createXeroBankFeedConnection, { loading: isCreateLoading }] = useMutation(CREATE_XERO_BANK_FEED_CONNECTION, {
    refetchQueries: [{ query: GET_XERO_BANK_FEED_CONNECTIONS }],
  });
  const [deleteXeroBankFeedConnection, { loading: isDeleteLoading }] = useMutation(DELETE_XERO_BANK_FEED_CONNECTION, {
    refetchQueries: [{ query: GET_XERO_BANK_FEED_CONNECTIONS }],
  });

  const [enabledFeed, setEnabledFeed] = useState(() => !!feedConnection);

  const handleToggleFeed = () => {
    if (enabledFeed) {
      deleteXeroBankFeedConnection({
        variables: { feedConnectionId: feedConnection?.feedConnectionId },
        onCompleted: () => {
          setEnabledFeed(false);
        },
        onError(error) {
          toast.error(error.message);
        },
      });
    } else {
      createXeroBankFeedConnection({
        variables: { currency },
        onCompleted: () => {
          setEnabledFeed(true);
        },
        onError(error) {
          toast.error(error.message);
        },
      });
    }
  };

  const isToggleLoading = isCreateLoading || isDeleteLoading;

  const lastSyncedAt = feedConnection?.lastSyncedAt;

  return {
    enabledFeed,
    handleToggleFeed,
    isToggleLoading,
    lastSyncedAt,
  };
};

export default useBankFeedSwitch;
