import React, { useState } from 'react';

export const PaymentContext = React.createContext({});

export const PaymentContextProvider = ({ children }) => {
  const [addMoneyInfo, setAddMoneyInfo] = useState(null);
  const [addMoneyTransaction, setAddMoneyTransaction] = useState(null);
  const [moveMoneyInfo, setMoveMoneyInfo] = useState(null);
  const [moveMoneyTransaction, setMoveMoneyTransaction] = useState(null);
  const [payBalanceInfo, setPayBalanceInfo] = useState(null);
  const [payBalanceTransaction, setPayBalanceTransaction] = useState(null);
  const [payAll, setPayAll] = useState(false);
  const [payAllUsd, setPayAllUsd] = useState(false);

  return (
    <PaymentContext.Provider
      value={{
        addMoneyInfo,
        setAddMoneyInfo,
        addMoneyTransaction,
        setAddMoneyTransaction,
        moveMoneyInfo,
        setMoveMoneyInfo,
        moveMoneyTransaction,
        setMoveMoneyTransaction,
        payBalanceInfo,
        setPayBalanceInfo,
        payBalanceTransaction,
        setPayBalanceTransaction,
        payAll,
        setPayAll,
        payAllUsd,
        setPayAllUsd,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
