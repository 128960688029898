import React from 'react';

import InfoTooltip from '../InfoTooltip';
import { formatMoneyV2 } from '../../utility/currency';

const LineOfCreditLimit = ({ lineOfCredit }) => {
  const creditLimit = lineOfCredit && lineOfCredit.creditLimit;
  const currency = lineOfCredit && lineOfCredit.creditLimit.currency;

  return (
    <div className="tw-flex tw-flex-col lg:tw-flex-row">
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-1 lg:tw-mb-0 lg:tw-justify-start lg:tw-w-44">
        <small className="tw-mr-1">Line of Credit Limit</small>
        <InfoTooltip
          testId="loc-credit-limit"
          message="The total amount of credit that your business is authorized to access through your line of credit."
          mobileOrientation="right"
        />
      </div>
      <div className="tw-flex tw-items-center tw-items-end">
        <strong>{formatMoneyV2(creditLimit)}</strong>
        <small className="tw-text-neutral-grey-2 tw-ml-2">{currency}</small>
      </div>
    </div>
  );
};

export default LineOfCreditLimit;
