import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { TextField, Select } from 'components/FormFields/v2';
import { GET_CRA_BUSINESS_NUMBER } from 'graphql/user';
import { ADD_CRA_ACCOUNT, GET_PAYEES } from 'graphql/payees';
import { craProgramAccountTypes } from 'constants/index';
import Button from 'components/Button';

const AddCRAPayee = ({ onFinish, onPreviousStep }) => {
  const form = useForm();
  const { register, handleSubmit, watch } = form;

  const { data } = useQuery(GET_CRA_BUSINESS_NUMBER);

  const [addPayee, { loading: isAdding, error }] = useMutation(ADD_CRA_ACCOUNT, {
    refetchQueries: [{ query: GET_PAYEES }],
    awaitRefetchQueries: true,
  });
  const { me } = data || {};
  const { account } = me || {};
  const { craBusinessNumber } = account || {};

  const defaultBusinessNumber = craBusinessNumber || '817129778';
  const defaultProgramAccount = 'rt';
  const defaultProgramAccountNumber = '1001';

  const businessNumber = watch('businessNumber', defaultBusinessNumber);
  const programAccount = watch('programAccount', defaultProgramAccount);
  const programAccountNumber = watch('programAccountNumber', defaultProgramAccountNumber);

  const generatedFullProgramAccountNumber = `${businessNumber}${programAccount.toUpperCase()}${programAccountNumber}`;

  const onSubmit = async (data) => {
    try {
      await addPayee({ variables: data });
      toast.success('Payee was added successfully');
      onFinish();
    } catch (err) {
      toast.error('Error adding Payee - please try again');
      console.error(err);
    }
  };

  return (
    <>
      <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-p-8 tw-space-y-8">
            <strong className="tw-font-semibold">Add A New Payee</strong>
            <TextField
              name="businessNumber"
              label="CRA Business Number"
              placeholder={defaultBusinessNumber}
              defaultValue={craBusinessNumber}
              ref={register({ required: true })}
              data-private
              required
            />
            <TextField
              name="displayCurrency"
              label="Currency to Pay In"
              placeholder="CAD - Canadian Dollars"
              disabled
              required
            />
            <Select
              name="programAccount"
              label="Program Account"
              placeholder="Select Option"
              ref={register({ required: true })}
              options={craProgramAccountTypes}
              required
            />
            <TextField
              name="programAccountNumber"
              label="Program Account Number"
              placeholder={defaultProgramAccountNumber}
              ref={register({ required: true })}
              required
            />
            <TextField
              name="fullProgramAccountNumber"
              label="Full Program Account Number"
              placeholder="817129778RT1001"
              value={generatedFullProgramAccountNumber}
              disabled
              required
            />
          </div>

          <div className="tw-border-t tw-border-neutral-grey-3 " />

          <div className="tw-flex tw-my-5 tw-px-5 tw-justify-between">
            <Button type="button" secondary className="tw-px-6" onClick={onPreviousStep} data-testid="prev-step-btn">
              Back
            </Button>
            <Button
              data-testid="add-payee-btn"
              className={`${
                isAdding ? 'tw-bg-neutral-grey-3' : 'tw-bg-primary-dark-green'
              } tw-text-neutral-light tw-rounded-md tw-py-2 tw-px-6 tw-flex tw-flex-row tw-items-center`}
            >
              {!isAdding ? 'Add Payee' : 'Adding Payee'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default AddCRAPayee;
