import { useCallback, useState } from 'react';
import { useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import { UpdateCardLimitSettingsFormProps } from '../CreditCardLimitSettingsContent.types';
import { formatMoneyV2, centsFromMoneyString } from 'utility/currency';
import { UPDATE_CARD_LIMIT_SETTINGS } from 'graphql/cards';
import { CreditCardLimitSettings } from 'types/creditCard';
import { Currencies } from 'constants/currencies';

const useUpdateCardLimitSettings = ({
  creditCard,
  creditCardLimitSettings,
  setCreditCardLimitSettings,
  onClose,
}: UpdateCardLimitSettingsFormProps) => {
  const [updateCardLimitSettings, { error: updateCardLimitSettingsError }] = useMutation(UPDATE_CARD_LIMIT_SETTINGS);

  const [formattedAmount, setFormattedAmount] = useState(
    formatMoneyV2({ amount: creditCardLimitSettings.cardLimitCents, currency: Currencies.CAD })
  );

  const form = useForm<{ limit: string; expiresMonthly: boolean }>({
    defaultValues: { limit: formattedAmount },
  });

  const { register, handleSubmit, getValues } = form;

  const onSave = useCallback(async function () {
    const processingToast = toast.loading('Updating card limit settings...');

    try {
      const limitCents = centsFromMoneyString(getValues('limit'));
      const expiresMonthly = getValues('cardLimitExpireType') === 'monthly';
      const expiresAnnually = getValues('cardLimitExpireType') === 'annually';
      const response = await updateCardLimitSettings({
        variables: {
          creditCardId: creditCard.id,
          limit: { amount: limitCents, currency: Currencies.CAD },
          expiresMonthly,
          expiresAnnually,
        },
      });

      if (!response?.data?.updateCardLimitSettings) {
        throw new Error('Could not update card limit settings.');
      }

      toast.update(processingToast, {
        render: 'Card limit settings has been updated.',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      const updatedSettings: CreditCardLimitSettings = {
        cardLimitCents: limitCents,
        expiresMonthly,
        expiresAnnually,
        currentExpenseCents: creditCardLimitSettings.currentExpenseCents,
      };
      setCreditCardLimitSettings(updatedSettings);

      onClose();
    } catch (err) {
      toast.update(processingToast, {
        render: `Something went wrong. ${(err as ApolloError)?.message || err}. Please try again.`,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      console.error(err);
    }
  }, []);

  return {
    onSave,
    form,
    register,
    handleSubmit,
    formattedAmount,
    setFormattedAmount,
    updateCardLimitSettingsError,
  };
};

export default useUpdateCardLimitSettings;
