import { useState, useContext, useMemo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

import { GET_PAYORS, UPDATE_PAYOR } from 'graphql/invoicing';
import { UpdatePayorPayloadType } from 'components/Invoices/components/Payors/Payors.types';
import { PayorsContext } from 'components/Invoices/contexts/PayorsContext';
import { EditPayorModalProps } from '../EditPayorModal.types';

const useEditPayorModal = ({ onClose }: Pick<EditPayorModalProps, 'onClose'>) => {
  const { payors, selectedPayorId } = useContext(PayorsContext);

  const [error, setError] = useState<ApolloError | null>();

  const [updatePayor, { loading }] = useMutation(UPDATE_PAYOR, {
    refetchQueries: [{ query: GET_PAYORS }],
    awaitRefetchQueries: true,
  });

  const payor = useMemo(() => payors.find(({ id }) => id === selectedPayorId), [payors, selectedPayorId]);

  const { id: payorId, referenceId } = payor || {};

  const form = useForm({
    defaultValues: {
      referenceId,
    },
  });

  const { handleSubmit, register, setValue } = form;

  const onSubmit = useCallback(
    async (data: { payorInfo: UpdatePayorPayloadType }) => {
      setError(null);

      if (!payorId) throw new Error(`${payorId} couldn't be empty`);

      try {
        await updatePayor({ variables: { payorId, ...data } });
        toast.success('Payor was updated successfully');
        onClose();
      } catch (err) {
        console.error(err);
        toast.error('Error updating Payor - please try again');
        setError(err as ApolloError);
      }
    },
    [payorId, updatePayor, onClose, setError]
  );

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  useEffect(() => {
    // use setTimeout to trigger rerender
    setTimeout(() => setValue('referenceId', referenceId));
  }, [referenceId, setValue]);

  useEffect(
    () => () => {
      setError(null);
    },
    [setError]
  );

  return {
    form,
    register,
    onSubmit,
    handleSubmit,
    error,
    loading,
    handleClose,
  };
};

export default useEditPayorModal;
