import React, { useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_WALLETS_AND_BANK_ACCOUNTS } from 'graphql/wallets';
import { GET_PRIMARY_CARD_LEDGER_ACCOUNTS } from 'graphql/cards';
import AddMoneyFlow from 'components/payments/AddMoney/AddMoneyFlow/AddMoney';

const AddMoneyContainer = (props) => {
  const [getAccounts, { loading: loadingAccounts, data: accountsData }] = useLazyQuery(GET_WALLETS_AND_BANK_ACCOUNTS, {
    onCompleted: () => {
      setIsRefetchingAccounts(false);
    },
  });
  const { loading: loadingCard, data: cardData } = useQuery(GET_PRIMARY_CARD_LEDGER_ACCOUNTS);
  const [isRefetchingAccounts, setIsRefetchingAccounts] = useState(true);

  const { me } = accountsData || {};
  const wallets = get(me, 'account.wallets', []);
  const bankAccounts = get(me, 'account.bankAccounts', []);
  const cardLedgerAccounts = get(cardData, 'primaryCreditCard.accounts', []);
  const loading = loadingAccounts || loadingCard || isRefetchingAccounts;

  useEffect(() => {
    getAccounts();
  }, []);

  const handleRefetch = () => {
    setIsRefetchingAccounts(true);

    getAccounts();
  };

  return (
    <AddMoneyFlow
      wallets={wallets}
      bankAccounts={bankAccounts}
      cardLedgerAccounts={cardLedgerAccounts}
      loading={loading}
      refetch={handleRefetch}
      {...props}
    />
  );
};

export default AddMoneyContainer;
