import React, { useState, useContext, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { Transition } from '@headlessui/react';

import { CREDIT_CARD_LIMITS, TEAM_MEMBER_STATUS } from 'constants/index';
import { AuthContext } from 'context/Auth';
import { TeamContext } from 'components/settings/Team/TeamContext';
import { CreateCardContext } from './CreateCardContext';
import InfoTooltip from 'components/InfoTooltip';
import ArrowRight from 'components/svg/ArrowRight';
import AgreementsStep from './AgreementsStep';
import ConfirmDeliveryAddressStep from './ConfirmDeliveryAddressStep';
import CreateCardSuccess from './CreateCardSuccess';
import useCreateCreditCards from 'hooks/useCreateCreditCards';
import { RadioField, TextField, Checkbox, SubmitButton, Select, PinField } from 'components/FormFields/v2';
import { validateInteger } from 'utility/validators';
import { shortenedContactName, shortenedBusinessName } from 'utility/string';
import { Loaders } from 'components/cards/Loader';
import useIsContactPermission from 'hooks/useIsContactPermission';
import useIsMember from 'hooks/useIsMember';
import useGetSubscription from 'hooks/useGetSubscription';
import config from 'config';

const NewCardDetails = ({ onClose, creditCards, globalCreditLimit, refetch }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { me } = useContext(AuthContext);
  if (!me) return null;

  const { isMember } = useIsMember();
  const { members, loadingMembers, getMembers } = useContext(TeamContext);

  const filteredMembers = members.filter(
    (member) =>
      member.status === TEAM_MEMBER_STATUS.active &&
      member.role !== 'read_only' &&
      member.role !== 'bookkeeper' &&
      member.id !== me?.internalContact?.id
  );
  const adminMember = members.find((member) => member.id === me?.internalContact?.id);
  const newAdminObject = { ...adminMember, name: adminMember?.name + ' (you)' };
  const { maxVirtualCards } = useGetSubscription();

  filteredMembers.unshift(newAdminObject);

  const { cardFormData, setCardFormData, initialTypeOfCard } = useContext(CreateCardContext);

  const { isSubmitting, createCreditCard, setHasSubmitted, hasSubmitted, error } = useCreateCreditCards();

  const form = useForm({ defaultValues: { typeOfCard: initialTypeOfCard } });
  const { register, handleSubmit, watch, setValue, clearErrors, setError, errors } = form;
  const physicalCardSelected = watch('typeOfCard') === 'physical';

  const handleSubmitForm = async (data) => {
    const { displayName, typeOfCard, businessNameOnCard, pin, pinConfirmation, cardholderName } = data;
    const virtual = typeOfCard !== 'physical';
    const businessName = businessNameOnCard || me.account.name;
    const nameOnCard = isMember ? defaultContactName : members.find((member) => member.id === cardholderName)?.name;
    const contactId = isMember ? me?.internalContact?.id : cardholderName;
    setCardFormData({
      displayName,
      virtual,
      businessNameOnCard: businessName,
      pin,
      pinConfirmation,
      cardholderName: nameOnCard,
      contactId: contactId,
    });

    if (hasAtLeastOneCard && virtual) {
      await createCreditCard({
        virtual,
        nameOnCard: isMember ? defaultContactName : businessName.slice(0, 18),
        displayName,
        refetch,
        contactId: contactId,
      });
    } else {
      setHasSubmitted(true);
    }
  };

  useEffect(() => {
    if (cardFormData) {
      setValue('typeOfCard', cardFormData.virtual ? 'virtual' : 'physical');
      setValue('nameOnCard', cardFormData.nameOnCard);
      setValue('displayName', cardFormData.displayName);
      setValue('businessNameOnCard', cardFormData.businessNameOnCard);
      setValue('pin', cardFormData.pin);
      setValue('pinConfirmation', cardFormData.pinConfirmation);
    }

    if (!hasAtLeastOneCard) setIsExpanded(true);
    if (hasAtLeastOneCard) setValue('typeOfCard', null);

    ReactTooltip.rebuild();
  }, [cardFormData]);

  useEffect(() => {
    getMembers();
  }, []);

  const hasNoCards = (creditCards || []).length === 0;
  const accountName = me.account.name.slice(0, 18);
  const displayName = me.account.displayName && me.account.displayName;
  const suggestedCreditCardDisplayNames = me.suggestedCreditCardDisplayNames || [];
  const hasAtLeastOneCard = (creditCards || []).length > 0 || isMember;
  const virtualCardCount = creditCards.filter((creditCard) => creditCard.virtual).length;
  const dropdownOptions = [{ name: accountName, value: accountName }];
  const defaultContactName = shortenedContactName(me.embossedName);

  const { createVirtualCard, createPhysicalCard } = useIsContactPermission();

  if (displayName) {
    const nameOption = shortenedBusinessName(displayName);
    dropdownOptions.push({ name: nameOption, value: nameOption });
  }
  if (suggestedCreditCardDisplayNames.length > 0) {
    suggestedCreditCardDisplayNames.forEach((name) => {
      const nameOption = shortenedBusinessName(name);
      dropdownOptions.push({ name: `${nameOption}`, value: nameOption });
    });
  }
  const radioFieldOptions = [];
  if (virtualCardCount < (maxVirtualCards || CREDIT_CARD_LIMITS.VIRTUAL) && createVirtualCard) {
    radioFieldOptions.push({ label: 'Virtual', value: 'virtual', defaultChecked: !hasAtLeastOneCard });
  }
  if (hasAtLeastOneCard && createPhysicalCard) {
    radioFieldOptions.push({ label: 'Physical', value: 'physical', defaultChecked: false });
  }

  const handleSwitchTypeOfCard = () => {
    setIsExpanded(true);
    setValue('nameOnCard', defaultContactName);
  };

  const validatePinConfirmation = (data) => {
    const { pin, pinConfirmation } = data;
    if (pin.length !== 4) setError('pin', { type: 'manual', message: `PIN has to be a 4-digits code` });
    else if (pinConfirmation.length !== 4)
      setError('pinConfirmation', { type: 'manual', message: `PIN has to be a 4-digits code` });
    else if (pin === pinConfirmation) {
      clearErrors(['pin', 'pinConfirmation']);
      validateDisplayName(data);
    } else {
      ['pin', 'pinConfirmation'].forEach((field) =>
        setError(field, { type: 'manual', message: `PIN and PIN Confirmation don't match` })
      );
    }
  };

  const validateDisplayName = (data) => {
    const displayNameMatch = creditCards.find((card) => {
      return card.nickname.toLowerCase() === data['displayName'].toLowerCase();
    });
    if (displayNameMatch) {
      setError('displayName', { type: 'manual', message: `Card Nickname has already been used` });
    } else {
      handleSubmitForm(data);
    }
  };

  const submitForm = (data) => {
    if (physicalCardSelected) validatePinConfirmation(data);
    else validateDisplayName(data);
  };

  return (
    <>
      {hasSubmitted ? (
        <NextStep
          isPhysical={!cardFormData.virtual}
          hasAtLeastOneCard={hasAtLeastOneCard}
          onClose={onClose}
          refetch={refetch}
          creditCards={creditCards}
          globalCreditLimit={globalCreditLimit}
        />
      ) : (
        <>
          <div className="tw-pt-6 tw-px-8 lg:tw-px-10">
            <strong>{hasNoCards && !isMember ? 'Card Details' : 'New Card Details'}</strong>
          </div>
          <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="tw-px-8 lg:tw-px-10 tw-flex tw-flex-col tw-my-6">
                <label htmlFor="typeOfCard" className="tw-text-sm">
                  What type of card do you want to create?<span className="tw-text-semantic-error">*</span>
                </label>
                <RadioField
                  rootClass="tw-mt-2 tw-mb-4"
                  label="What type of card do you want to create?"
                  name="typeOfCard"
                  options={radioFieldOptions}
                  ref={register({ required: true })}
                  onClick={handleSwitchTypeOfCard}
                />
                <small className="tw-text-neutral-grey-2">
                  {hasNoCards &&
                    !isMember &&
                    `The first Loop Card you create needs to be virtual. After your first virtual card is created, you'll be
              able to order a physical card.`}
                </small>
              </div>
              <Transition
                show={isExpanded}
                enter="tw-ease-in-out tw-duration-200"
                enterFrom="tw-opacity-0"
                enterTo="tw-opacity-100"
                leave="tw-ease-in-out tw-duration-200"
                leaveFrom="tw-opacity-100"
                leaveTo="tw-opacity-0"
              >
                <div className={`tw-px-8 lg:tw-px-10 ${isExpanded && 'tw-mt-8'}`}>
                  <label htmlFor="cardholderName" className="tw-text-sm">
                    Cardholder Name<span className="tw-text-semantic-error">*</span>
                  </label>
                  <div className="tw-flex tw-mb-4">
                    {loadingMembers && (
                      <div className="tw-mr-4">
                        <Loaders.Spinner />
                      </div>
                    )}
                    {!loadingMembers &&
                      (isMember ? (
                        <TextField
                          rootClass="lg:tw-w-9/12 tw-mr-4"
                          name="cardholderName"
                          value={defaultContactName}
                          disabled
                        />
                      ) : (
                        <Select
                          rootClass="lg:tw-w-9/12 tw-mr-4"
                          name="cardholderName"
                          options={filteredMembers.map((p) => ({ name: `${p.name}`, value: p.id }))}
                          ref={register({ required: true })}
                        />
                      ))}
                    <InfoTooltip message="Select the person’s name that you want assigned to this card. You will sometimes need to use an individual cardholder name for transactions that require the name of an individual instead of a business name." />
                  </div>
                  <label htmlFor="displayName" className="tw-text-sm">
                    Card Nickname<span className="tw-text-semantic-error">*</span>
                  </label>
                  <div className="tw-flex tw-items-center tw-flex-wrap tw-mb-4">
                    <TextField
                      rootClass="lg:tw-w-9/12 tw-mr-4"
                      name="displayName"
                      placeholder="Facebook Ads"
                      ref={register({ required: true })}
                    />
                    <div className="tw-flex tw-pt-2">
                      <p className="tw-mr-4"> Card</p>
                      <InfoTooltip message="Card nicknames make it easy to know what the card is used for. Examples of nicknames are Ads, USD or Software." />
                    </div>
                  </div>
                  {hasAtLeastOneCard && physicalCardSelected ? (
                    <>
                      <label htmlFor="businessNameOnCard" className="tw-text-sm">
                        Business Name Displayed on Card<span className="tw-text-semantic-error">*</span>
                      </label>
                      <div className="tw-flex tw-mb-4">
                        <Select
                          rootClass="tw-w-9/12 tw-mr-4"
                          name="businessNameOnCard"
                          placeholder="Select"
                          ref={register({
                            required: true,
                            maxLength: {
                              value: 18,
                              message: 'Maximum of 18 characters',
                            },
                          })}
                          options={dropdownOptions}
                        />
                        <InfoTooltip message="Physical cards have room for 18 characters. If your business name is longer than 18 characters, your business name will be truncated on the card." />
                      </div>
                      <label htmlFor="pin" className="tw-text-sm">
                        Please Enter a 4-digit PIN<span className="tw-text-semantic-error">*</span>
                      </label>
                      <div className="tw-flex tw-mb-4">
                        <PinField
                          rootClass="tw-w-36 tw-mr-4"
                          name="pin"
                          placeholder="_ _ _ _ "
                          maxLength={4}
                          allowNegative={false}
                          isNumericString={true}
                          isAllowed={validateInteger}
                          allowLeadingZeros={true}
                          rules={register({ required: 'required' })}
                        />
                        <InfoTooltip message="Your physical card requires a 4-digit PIN for chip transactions. Please use a memorable PIN number as you will not be able to edit this after your card has been ordered." />
                      </div>
                      <label htmlFor="pinConfirmation" className="tw-text-sm">
                        Confirm your PIN<span className="tw-text-semantic-error">*</span>
                      </label>
                      <div className="tw-flex">
                        <PinField
                          rootClass="tw-w-36"
                          name="pinConfirmation"
                          placeholder="_ _ _ _ "
                          maxLength={4}
                          allowNegative={false}
                          isNumericString={true}
                          isAllowed={validateInteger}
                          allowLeadingZeros={true}
                          rules={register({ required: 'required' })}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="tw-px-8 lg:tw-px-10 tw-mt-16 tw-my-4">
                  <Checkbox
                    name="acceptedCardholderAgreement"
                    label={
                      <p>
                        I agree to the{' '}
                        <a
                          href={config.cardHolderAgreementUrl}
                          className="tw-text-primary-dark-green hover:tw-text-primary-dark-green tw-font-bold tw-border-b-2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Loop Access Card Agreement
                        </a>
                      </p>
                    }
                    defaultChecked={false}
                    ref={register({ required: true })}
                  />
                  {errors.acceptedCardholderAgreement && (
                    <span className="tw-text-sm tw-mt-10 tw-text-semantic-error">
                      You have not agreed to the Loop Access Card Agreement
                    </span>
                  )}
                </div>
                <div className="tw-border-t tw-border-neutral-grey-4 tw-px-8 lg:tw-px-8 tw-pt-6">
                  <SubmitButton
                    className={`${
                      isSubmitting ? 'tw-bg-neutral-grey-3' : 'tw-bg-primary-dark-green'
                    } tw-w-full lg:tw-w-max tw-float-right tw-mb-6`}
                    disabled={isSubmitting}
                  >
                    <div className="tw-flex tw-justify-center tw-items-center">
                      <p className={`tw-text-neutral-light tw-mr-2`}>
                        {isSubmitting
                          ? 'Submitting...'
                          : (hasAtLeastOneCard && !physicalCardSelected && 'Create Card') || 'Continue'}
                      </p>
                      <ArrowRight />
                    </div>
                  </SubmitButton>
                </div>
              </Transition>
            </form>
          </FormProvider>
        </>
      )}
    </>
  );
};

const NextStep = (props) => {
  const { isPhysical, hasAtLeastOneCard, ...otherProps } = props;

  if (isPhysical) return <ConfirmDeliveryAddressStep {...otherProps} />;
  if (hasAtLeastOneCard) return <CreateCardSuccess {...otherProps} />;

  return <AgreementsStep {...otherProps} />;
};

export default NewCardDetails;
