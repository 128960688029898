import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';

export const validAccountingIntegrations = () => {
  const channels = ['QuickBooks', 'Xero'];
  return channels;
};

export const salesChannelIconMap = {
  Amazon: (
    <img
      className="tw-pt-2 tw-ml-3"
      style={{ width: '100px' }}
      src="https://loop-public.s3.ca-central-1.amazonaws.com/amazon_logo.png"
    />
  ),
  Google: <FcGoogle size="30" className="tw-ml-8" />,
  Facebook: <FaFacebook size="30" className="tw-text-primary-blue tw-ml-8" />,
  Stripe: <img style={{ width: '100px' }} src="https://loop-public.s3.ca-central-1.amazonaws.com/stripe-logo.png" />,
  Shopify: (
    <img
      className="tw-pb-2 tw-ml-3"
      style={{ width: '100px' }}
      src="https://loop-public.s3.ca-central-1.amazonaws.com/shopify_logo.png"
    />
  ),
  WooCommerce: (
    <img style={{ width: '120px' }} src="https://loop-public.s3.ca-central-1.amazonaws.com/woocommerce-logo.png" />
  ),
  Square: (
    <img
      style={{ width: '100px', paddingLeft: '10px' }}
      src="https://loop-public.s3.ca-central-1.amazonaws.com/square-logo.png"
    />
  ),
  Paypal: (
    <img
      style={{ width: '100px', paddingLeft: '10px' }}
      src="https://loop-public.s3.ca-central-1.amazonaws.com/paypal-logo.png"
    />
  ),
  QuickBooks: (
    <img style={{ height: '40px' }} src="https://andrewstaxaccounting.com/wp-content/uploads/2018/12/qb.png" />
  ),
  Xero: <img style={{ height: '40px' }} src="https://loop-public.s3.ca-central-1.amazonaws.com/xero-logo.svg" />,
};

export const accountingServicesConnectButtonStyleMap = {
  quickbooks: {
    width: '222px',
    height: '36px',
    background: 'url(https://loop-public.s3.ca-central-1.amazonaws.com/C2QB_green_btn_med_default.svg)',
  },
  xero: {
    width: '190px',
    height: '44px',
    background: 'url(https://loop-public.s3.ca-central-1.amazonaws.com/xero-connect-blue.svg)',
  },
};

export const accountingServicesAccountCreationURLs = {
  quickbooks: 'https://app.qbo.intuit.com/app/chartofaccounts',
  xero: 'https://go.xero.com/Bank/BankAccounts.aspx',
};
