import React, { createContext, useState } from 'react';
import { ApolloError } from '@apollo/client';

import { AccountIndustry, AccountProjections } from '../USBankAccountVerificationModal.types';

type USBankAccountVerificationContextType = {
  industryData?: AccountIndustry;
  setIndustryData: React.Dispatch<React.SetStateAction<AccountIndustry | undefined>>;
  projections?: AccountProjections;
  setProjections: React.Dispatch<React.SetStateAction<AccountProjections | undefined>>;
  error?: ApolloError;
  setError: React.Dispatch<React.SetStateAction<ApolloError | undefined>>;
};

export const USBankAccountVerificationContext = createContext<USBankAccountVerificationContextType>(
  {} as USBankAccountVerificationContextType
);

export const USBankAccountVerificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [industryData, setIndustryData] = useState<AccountIndustry>();
  const [projections, setProjections] = useState<AccountProjections>();
  const [error, setError] = useState<ApolloError>();

  return (
    <USBankAccountVerificationContext.Provider
      value={{
        industryData,
        setIndustryData,
        projections,
        setProjections,
        error,
        setError,
      }}
    >
      {children}
    </USBankAccountVerificationContext.Provider>
  );
};
