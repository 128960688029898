import React from 'react';
import { Transition } from '@headlessui/react';
import { MdPin } from 'react-icons/md';

import { useToggle } from 'hooks';
import CardActionButton from '../CardActionButton';
import CopyableField from '../CopyableField';

const TogglePin = ({ creditCard, onCopyValueToClipboard, isImpersonating }) => {
  const { isOpen: showPin, toggle: onTogglePin } = useToggle();
  const { isOpen: pinVisible, toggle: handleTogglePin } = useToggle();

  const onCopyPin = () => {
    if (!isImpersonating) onCopyValueToClipboard(creditCard.pin());
  };

  return (
    <>
      <CardActionButton
        label={`${showPin ? 'Hide' : 'View'} PIN`}
        Icon={MdPin}
        onClick={onTogglePin}
        className="tw-text-primary-lilac tw-bg-secondary-light-lilac"
      />
      <Transition
        as="div"
        className="tw-mt-4 tw-mb-2"
        show={showPin}
        enter="tw-ease-in-out tw-duration-200 tw-transition-all"
        enterFrom="tw-transform tw-opacity-0"
        enterTo="tw-transform tw-opacity-100"
        leave="tw-ease-in-out tw-duration-200 tw-transition-all"
        leaveFrom="tw-transform tw-opacity-100"
        leaveTo="tw-transform tw-opacity-0"
      >
        <CopyableField
          handleToggle={handleTogglePin}
          show={pinVisible}
          label="PIN Code"
          value={pinVisible ? creditCard.pin() : '∗∗∗∗'}
          onCopy={onCopyPin}
        />
      </Transition>
    </>
  );
};

export default TogglePin;
