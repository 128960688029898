import React from 'react';

type CardRefundedProps = {
  width?: number;
  height?: number;
};

const CardRefunded = ({ width = 25, height = 20 }: CardRefundedProps) => (
  <svg width={width} height={height} viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.125 0.5H3.875C2.42525 0.5 1.25 1.67525 1.25 3.125V12.875C1.25 14.3247 2.42525 15.5 3.875 15.5H18.125C19.5747 15.5 20.75 14.3247 20.75 12.875V3.125C20.75 1.67525 19.5747 0.5 18.125 0.5Z"
      fill="white"
      stroke="#26262C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.25 5H20.75" stroke="#26262C" strokeLinejoin="round" />
    <path d="M7.25 10.0625H5V11H7.25V10.0625Z" stroke="#26262C" strokeLinejoin="round" />
    <g>
      <rect x="13.75" y="8.75" width="10.5" height="10.5" rx="5.25" fill="white" />
      <rect x="13.75" y="8.75" width="10.5" height="10.5" rx="5.25" stroke="black" strokeWidth="0.5" />
    </g>
    <path
      d="M15.5567 16.6576C15.6387 16.6713 15.7178 16.6151 15.7314 16.533L15.891 15.5802C16.5064 16.7062 17.7009 17.4189 19.0199 17.4189C20.9787 17.4189 22.5713 15.8507 22.5713 13.9238C22.5713 11.9969 20.9772 10.4286 19.0184 10.4286C17.6902 10.4286 16.4821 11.1489 15.8667 12.3069C15.8272 12.3814 15.856 12.4726 15.9305 12.5121C16.005 12.5516 16.0961 12.5227 16.1356 12.4482C16.6979 11.389 17.8027 10.7311 19.0184 10.7311C20.81 10.7326 22.2674 12.1641 22.2674 13.9238C22.2674 15.6835 20.81 17.115 19.0199 17.115C17.7814 17.115 16.6645 16.4297 16.1144 15.3523L17.2237 15.565C17.3058 15.5802 17.3848 15.5255 17.4 15.4419C17.4152 15.3614 17.362 15.2824 17.2814 15.2656L15.8393 14.9891C15.8302 14.9875 15.8211 14.986 15.8104 14.986C15.736 14.986 15.6737 15.0392 15.66 15.1122L15.4305 16.4798C15.4169 16.5634 15.4716 16.6424 15.5567 16.6576Z"
      fill="black"
      stroke="black"
      strokeWidth="0.3"
    />
  </svg>
);

export default CardRefunded;
