import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];
const bgColor = theme.colors.neutral.light;

const WarningAndStars = (props) => {
  return (
    <svg width="268" height="162" viewBox="0 0 268 162" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="268" height="162" fill={bgColor} />
      <path
        d="M87.7262 28.7691C87.9085 28.6461 88.1269 28.8645 88.0039 29.0467L81.7777 38.2744C81.7321 38.342 81.7321 38.4305 81.7777 38.4981L88.0039 47.7258C88.1269 47.908 87.9085 48.1264 87.7262 48.0034L78.4986 41.7773C78.431 41.7316 78.3425 41.7316 78.2749 41.7773L69.0472 48.0034C68.8649 48.1264 68.6466 47.908 68.7695 47.7258L74.9957 38.4981C75.0413 38.4305 75.0413 38.342 74.9957 38.2744L68.7695 29.0467C68.6466 28.8645 68.865 28.6461 69.0472 28.7691L78.2749 34.9953C78.3425 35.0409 78.431 35.0409 78.4986 34.9953L87.7262 28.7691Z"
        fill={primaryDarkGreenColor}
      />
      <circle cx="142.684" cy="77.158" r="41.6345" fill="#FFE8C3" />
      <path
        d="M173.494 72.162C173.494 51.4748 156.71 34.6909 136.023 34.6909C115.336 34.6909 98.5518 51.4748 98.5518 72.162C98.5518 92.8492 115.336 109.633 136.023 109.633C156.71 109.633 173.494 92.8492 173.494 72.162Z"
        stroke={primaryDarkGreenColor}
        strokeMiterlimit="10"
      />
      <path
        d="M148.513 84.6526L123.532 59.6719"
        stroke={primaryDarkGreenColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M123.532 84.6526L148.513 59.6719" stroke="#26262C" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M196.555 117.893L207.309 89.5585L199.575 87.0534L191.674 116.311L196.555 117.893ZM194.33 128.505L196.444 121.978L189.369 119.686L187.254 126.213L194.33 128.505Z"
        fill="#EEA530"
      />
      <path
        d="M95.4935 118.667C95.4935 110.392 88.7799 103.678 80.505 103.678C72.2302 103.678 65.5166 110.392 65.5166 118.667C65.5166 126.942 72.2302 133.655 80.505 133.655C88.7799 133.655 95.4935 126.942 95.4935 118.667Z"
        stroke="#E17D5E"
        strokeMiterlimit="10"
      />
      <path d="M85.5011 123.663L75.5088 113.67" stroke="#E17D5E" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M75.5088 123.663L85.5011 113.67" stroke="#E17D5E" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default WarningAndStars;
