import { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { getBankAccountConnections } from 'apis/flinks';
import { GET_BANK_ACCOUNTS } from 'graphql/integrations';
import SettingsLayout from 'containers/settings/SettingsLayout';
import { SettingsLayout as NonKYCApprovedSettingsLayout } from 'components/OnboardingDashboard/components/Settings/components';
import { useGetUserInfo } from 'hooks';

const useFlinksConnection = () => {
  const [progress, setProgress] = useState(0);
  const progressRef = useRef(progress);
  progressRef.current = progress;
  const progressTimeout = useRef<NodeJS.Timeout | null>(null);

  const history = useHistory();
  const { search: searchParams } = useLocation();

  const [getBankAccounts] = useLazyQuery(GET_BANK_ACCOUNTS, { fetchPolicy: 'network-only' });

  const { isKYCApproved } = useGetUserInfo();

  const bankAccountsSettingsUrl = isKYCApproved
    ? '/dashboard/settings/bank-accounts-settings'
    : '/onboarding/dashboard/settings/bank-accounts-settings';

  const Layout = isKYCApproved ? SettingsLayout : NonKYCApprovedSettingsLayout;

  const handleBankAccountConnection = useCallback(async () => {
    let redirectUrl = bankAccountsSettingsUrl;

    try {
      await getBankAccountConnections(searchParams);
      redirectUrl += '?success=Flinks';
    } catch (err) {
      redirectUrl += '?failed=Flinks';
    } finally {
      setProgress(100);

      await getBankAccounts();

      progressTimeout.current = setTimeout(() => {
        history.push(redirectUrl);
      }, 1000);
    }
  }, [bankAccountsSettingsUrl, searchParams, progressTimeout]);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      if (progressRef.current < 100) {
        setProgress(progressRef.current + 1);
      }
    }, 300);

    return () => clearInterval(progressInterval);
  }, []);

  useEffect(() => {
    handleBankAccountConnection();

    return () => clearInterval(progressTimeout.current!);
  }, [handleBankAccountConnection]);

  return {
    Layout,
    progress,
  };
};

export default useFlinksConnection;
