import React from 'react';

type CheckReceiptOutlineProps = {
  size?: number;
};

const CheckReceiptOutline = ({ size = 21 }: CheckReceiptOutlineProps) => (
  <svg width={size} height={size} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(3.38235 1.25)">
      <path
        d="M-0.932353 0.75H9.33294C10.4294 0.75 11.3183 1.65404 11.3183 2.76923V15.2308C11.3183 16.346 10.4294 17.25 9.33294 17.25H0.0682353C-0.0282096 17.25 -0.917059 16.346 -0.917059 15.2308V2.76923C-0.917059 1.65404 -0.0282096 0.75 -0.932353 0.75Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path d="M1.10941 3.11523H7.72703" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.10941 6.48047H7.72703" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.10941 9.8457H4.41824" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M12.8001 7.82617L6.62365 15.0057L4.97659 12.3134"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default CheckReceiptOutline;
