import { useHistory } from 'react-router-dom';
import { Currencies } from 'constants/currencies';

type useEmptyStateProps = {
  selectedWalletCurrency: string;
};

const useEmptyState = ({ selectedWalletCurrency }: useEmptyStateProps) => {
  const isEuroOrGbp = selectedWalletCurrency === Currencies.EUR || selectedWalletCurrency === Currencies.GBP;

  const history = useHistory();
  const navigateToOpenAccountModal = () =>
    history.push(`/dashboard/accounts?open-account=true&currency=${selectedWalletCurrency}`);

  return { isEuroOrGbp, navigateToOpenAccountModal };
};

export default useEmptyState;
