import { useEffect } from 'react';

const DEFAULT_OPTS = { top: 0, left: 0, behavior: 'smooth' };

const useScrollViewToPosition = (deps = [], opts = DEFAULT_OPTS) => {
  useEffect(() => {
    window.scrollTo(opts);
  }, [deps].flat());
};

export default useScrollViewToPosition;
