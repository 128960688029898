import React, { useContext, useState, useEffect } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import { IoInformationCircleOutline } from 'react-icons/io5';

import { CreditCardContext } from 'context/CreditCard';
import Button from 'components/Button';
import ActivateCardModal from './ActivateCardModal';

const InactiveCardActions = ({ creditCard }) => {
  const { activateCard, setActivateCard } = useContext(CreditCardContext);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const showActivateCardModal = () => setShowActivateModal(true);

  useEffect(() => {
    if (activateCard) {
      setShowActivateModal(true);
      setActivateCard(false);
    }
  }, [activateCard]);

  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-py-4">
      <div>
        <Button primary onClick={showActivateCardModal}>
          <BiCheckCircle className="tw-mr-2" />
          Activate Card
        </Button>
      </div>
      <div className="tw-flex tw-items-start tw-rounded-md tw-bg-secondary-light-yellow tw-text-semantic-warning tw-p-2 tw-mt-4">
        <IoInformationCircleOutline size={24} className="tw-mr-2" />
        <span className="tw-text-xs">
          {`This card is waiting for you to activate. To start using this card you’ll have to enter the CVC2.`}
        </span>
      </div>
      <div className="tw-flex tw-items-start tw-rounded-md tw-bg-secondary-light-yellow tw-p-2 tw-mt-4">
        <span className="tw-text-xs">
          <span className="tw-font-bold">Card Delivery Tracking: </span>
          {creditCard.trackerUrl ? (
            <a href={creditCard.trackerUrl} target="_blank" rel="noreferrer">
              <span className="tw-font-bold tw-text-primary-dark-green tw-underline">Track</span>
            </a>
          ) : (
            'Not yet available. Once your card has been shipped a tracking number will be generated.'
          )}
        </span>
      </div>
      <ActivateCardModal show={showActivateModal} setShow={setShowActivateModal} creditCard={creditCard} />
    </div>
  );
};

export default InactiveCardActions;
