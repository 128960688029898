import React from 'react';

import { useGetLineOfCreditInfo } from 'hooks';
import PayLocBalanceFlow from 'components/payments/PayLocBalance/PayLocBalanceFlow';

const PayLocBalanceFlowContainer = (props) => {
  const { loading, lineOfCredit, wallets, bankAccounts, lineOfCreditAccNum, hasLineOfCredit, fundInfo } =
    useGetLineOfCreditInfo();

  return (
    <PayLocBalanceFlow
      {...props}
      wallets={wallets}
      bankAccounts={bankAccounts}
      lineOfCredit={lineOfCredit}
      lineOfCreditAccNum={lineOfCreditAccNum}
      hasLineOfCredit={hasLineOfCredit}
      fundInfo={fundInfo}
      loading={loading}
    />
  );
};

export default PayLocBalanceFlowContainer;
