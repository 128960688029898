import React from 'react';

import { KYC_STATUS } from 'constants/index';

const KYCStatusContainer = (status) => {
  const kycStatus = status.status;
  return (
    <div>
      {kycStatus === KYC_STATUS.DRAFT ? (
        <p>
          We are currently in the process of reviewing the data you have provided us. We will reach out with an email if
          there are any issues with verifying your account.
        </p>
      ) : (
        <p>
          Please ensure all directors have given consent and any additional verification documents have been uploaded.
          You will receive a confirmation email once your account has been verified!
        </p>
      )}
    </div>
  );
};

export default KYCStatusContainer;
