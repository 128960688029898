import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Loaders } from 'components/cards/Loader';
import DashboardLayout from 'components/dashboard/DashboardLayout';

const Transaction = () => {
  const { id } = useParams();
  const history = useHistory();

  history.push(`/dashboard/transactions?selectedTransactionId=${id}`);

  return (
    <DashboardLayout>
      <Loaders.Light />
    </DashboardLayout>
  );
};

export default Transaction;
