import { PaymentRequestStatus } from 'types/invoicing';
import { usePaymentRequestFieldValues } from 'components/Invoices/components/PaymentRequests/components/Table/hooks';
import { TableRowProps } from '../TableRow.types';

const useTableRow = ({ paymentRequest }: Pick<TableRowProps, 'paymentRequest'>) => {
  const {
    payorName,
    accountName,
    formattedAmount,
    currency,
    formattedInitDate,
    formattedDueDate,
    status,
    frequencyType,
    isOngoing,
  } = usePaymentRequestFieldValues({ paymentRequest });

  const isDeletable = [
    PaymentRequestStatus.pending,
    PaymentRequestStatus.scheduled,
    PaymentRequestStatus.overdue,
  ].includes(status);

  return {
    payorName,
    accountName,
    formattedAmount,
    currency,
    formattedInitDate,
    formattedDueDate,
    status,
    frequencyType,
    isOngoing,
    isDeletable,
  };
};

export default useTableRow;
