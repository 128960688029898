import { useEffect, useState, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { SOLID_OWNERSHIP_DISCLOSURE_STATE } from 'graphql/wallets';
import { DISCLOSURE_STATE_REFETCHING_INTERVAL } from '../constants';

const useDisclosureState = () => {
  const [isDisclosurePreSigned, setIsDisclosurePreSigned] = useState(false);
  const [isDisclosureSigningConfirmed, setIsDisclosureSigningConfirmed] = useState(false);
  const [isDisclosureSigningFailed, setIsDisclosureSigningFailed] = useState(false);

  const disclosureStatePullingIntervalId = useRef<NodeJS.Timeout | null>(null);

  const [getSolidOwnershipDisclosureState, { error, loading }] = useLazyQuery(SOLID_OWNERSHIP_DISCLOSURE_STATE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const solidOwnershipDisclosurePreSigned = get(data, 'solidRequirements.ownershipDisclosureSigned');
      if (!solidOwnershipDisclosurePreSigned) return;

      if (disclosureStatePullingIntervalId.current) {
        clearTimeout(disclosureStatePullingIntervalId.current);
      }

      setIsDisclosureSigningConfirmed(true);
    },
  });

  useEffect(() => {
    if (!isDisclosurePreSigned) return;

    disclosureStatePullingIntervalId.current = setInterval(async () => {
      await getSolidOwnershipDisclosureState();
    }, DISCLOSURE_STATE_REFETCHING_INTERVAL);

    return () => {
      if (disclosureStatePullingIntervalId.current) {
        clearTimeout(disclosureStatePullingIntervalId.current);
      }
    };
  }, [isDisclosurePreSigned]);

  return {
    isDisclosurePreSigned,
    setIsDisclosurePreSigned,
    isDisclosureSigningFailed,
    setIsDisclosureSigningFailed,
    isDisclosureSigningConfirmed,
    disclosureStateError: error,
    disclosureStateLoading: loading,
  };
};

export default useDisclosureState;
