import React from 'react';
import { useQuery } from '@apollo/client';

import Documents from '../../components/settings/Documents';
import { GET_DOCUMENTS } from '../../graphql/document';

const DocumentsContainer = () => {
  const { loading, data, refetch, networkStatus } = useQuery(GET_DOCUMENTS);
  const { documents } = data || {};

  return <Documents documents={documents} loading={loading} reload={refetch} networkStatus={networkStatus} />;
};

export default DocumentsContainer;
