import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { ampTrackEvent } from 'amplitude';

import { GET_SUBSCRIPTION_SAVINGS } from 'graphql/payees';
import { PayPayeeContext } from 'components/payments/PayPayee/PayPayeeContext';
import { getTransferMethod } from 'utility/payments';
import { useGetSubscription, useIsMobile } from 'hooks';
import { formatDateTime } from 'utility/date';

const useComplete = () => {
  const isMobile = useIsMobile();
  const { payeePayment, paymentInfo } = useContext(PayPayeeContext);
  const {
    payment,
    chargedPayment,
    conversionRate: rate,
    payee,
    transactionTime,
    transaction,
    status,
  } = payeePayment || {};
  const { fromAccount, toAccount, createMemberPayment, notes, reason, needsConversion } = paymentInfo || {};

  const toAccountName = payee.name;
  const fromAccountName = fromAccount.displayName;

  const { isPaidSubscription } = useGetSubscription();
  const originalAmountCurrency = payment.currency;
  const chargedAmountCurrency = chargedPayment.currency;
  const toAccountBankSwiftCode = get(toAccount, 'record.bankAccount.code');
  const showSavingsComponent = !isPaidSubscription && needsConversion;

  const transferMethod = getTransferMethod({
    fromCurrency: originalAmountCurrency,
    toCurrency: chargedAmountCurrency,
    isSwiftCode: !!toAccountBankSwiftCode,
  });

  const [savingsAmount, setSavingsAmount] = useState();
  const history = useHistory();
  const navigateToSubscriptions = () => {
    ampTrackEvent('Subscriptions: Payee Payment');
    history.push('/dashboard/subscriptions');
  };

  const [getSubscriptionSavings, { loading }] = useLazyQuery(GET_SUBSCRIPTION_SAVINGS, {
    variables: {
      buy: { amount: payment.amount, currency: payment.currency },
      sell: { amount: chargedPayment.amount, currency: chargedPayment.currency },
    },
    onCompleted: (data) => {
      const amount = get(data, 'subscriptionSavings.savingsAmount');
      setSavingsAmount(amount);
    },
  });

  useEffect(() => {
    if (showSavingsComponent) getSubscriptionSavings();
  }, [showSavingsComponent]);

  return {
    createMemberPayment,
    showSavingsComponent,
    savingsAmount,
    navigateToSubscriptions,
    loading,
    transactionId: transaction?.transactionId,
    transferMethod,
    formattedCreatedAt: formatDateTime(transactionTime),
    fromAccountName,
    toAccountName,
    originalAmountCurrency,
    chargedAmount: chargedPayment,
    originalAmount: payment,
    reason,
    notes,
    isConversion: needsConversion,
    rate,
    isMobile,
    status,
  };
};

export default useComplete;
