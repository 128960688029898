// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React from 'react';

import PreFundedCardSidebar from './PreFundedCardSidebar';
import CreditCardSidebar from './CreditCardSidebar';
import useIsMember from 'hooks/useIsMember';
import useGetProductState from 'hooks/useGetProductState';

const RightHandSidebar = ({ groupedCardsInfo, loading }) => {
  const { isLoading: meLoading, isCreditCard } = useGetProductState();
  const { isMember } = useIsMember();

  if (loading || meLoading) return <LoadingState />;
  if (isMember) return null;

  return isCreditCard ? (
    <CreditCardSidebar groupedCardsInfo={groupedCardsInfo} />
  ) : (
    <PreFundedCardSidebar groupedCardsInfo={groupedCardsInfo} />
  );
};

const LoadingState = () => (
  <div data-testid="sidebar-loader" className="tw-px-2 tw-space-y-8">
    <div className="tw-space-y-4">
      <div className="tw-flex tw-items-center tw-bg-neutral-grey-4 tw-h-4 tw-w-44 tw-animate-pulse" />
      <div className="tw-space-y-4">
        {Array(4)
          .fill()
          .map((_, i) => (
            <div key={i} className="tw-flex tw-items-center tw-space-x-4">
              <div className="tw-flex tw-items-center tw-bg-neutral-grey-4 tw-rounded-full tw-h-8 tw-w-8 tw-animate-pulse" />
              <div className="tw-flex tw-items-center tw-bg-neutral-grey-4 tw-h-6 tw-w-44 tw-animate-pulse" />
            </div>
          ))}
      </div>
    </div>
  </div>
);

export default RightHandSidebar;
