import React from 'react';

import { DOCUMENT_CATEGORY_VALUES } from './constants';

export const getAdditionalDocumentsTitle = (categoryValue: string, additionalValue: string, categoryName: string) => {
  switch (categoryValue) {
    case DOCUMENT_CATEGORY_VALUES.PHOTO_IDS:
      return `Proof of ID for ${additionalValue}`;
    case DOCUMENT_CATEGORY_VALUES.OTHER_INVOICES:
      return `Proof of Address for ${additionalValue}`;
    case DOCUMENT_CATEGORY_VALUES.OTHERS:
      return `Business Formation Documents for ${additionalValue}`;
    default:
      return categoryName;
  }
};

export const getAdditionalDocumentsWarningText = (categoryValue: string) => {
  switch (categoryValue) {
    case DOCUMENT_CATEGORY_VALUES.PHOTO_IDS:
      return (
        <>
          <p className="tw-mb-4">
            Please upload <b>one piece of Type A ID and one piece of Type B ID</b>:
          </p>
          <p>
            <b>Type A ID:</b> Passport, Drivers License, Birth Certificate, PR or Identity Card
          </p>
          <p>
            <b>Type B ID:</b> anything listed in Type A or a Personal Bank Statement, Insurance Document, Phone or
            Utility Bill
          </p>
        </>
      );
    case DOCUMENT_CATEGORY_VALUES.OTHER_INVOICES:
      return (
        <>
          <p className="tw-mb-4">
            The address you signed-up with is not an exact match with your business registration. Please upload a
            document that contains your <b>business name and current business address</b>. Valid documents include:
          </p>
          <p>Bank Statement, Lease Agreement, Phone or Utility Bill</p>
        </>
      );
    case DOCUMENT_CATEGORY_VALUES.OTHERS:
      return (
        <>
          <p className="tw-mb-4">
            We need to verify that you are operating a Canadian business. Please upload one of the following documents:
          </p>
          <ul className="tw-list-disc tw-ml-6">
            <li>Articles of Incorporation</li>
            <li>Partnership Registration</li>
            <li>Sole Proprietorship Documentation</li>
          </ul>
          <p className="tw-my-4">If you operate a non-registered sole proprietorship, you may instead provide:</p>
          <ul className="tw-list-disc tw-ml-6">
            <li>Business name registration</li>
            <li>GST/HST number registration</li>
            <li>Business license or permit</li>
            <li>Trade mark registration</li>
            <li>Sales channel invoice</li>
            <li>Legal agreement with a landlord or manufacturer</li>
            <li>T1 Income Tax Package</li>
          </ul>
        </>
      );
    default:
      return null;
  }
};
