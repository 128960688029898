import { useContext } from 'react';

import { transferMethod } from 'utility/string';
import { CardAutopayContext } from 'components/payments/CardAutopay/context/CardAutopay';
// import { PAYMENT_CURRENCY_INFO } from 'components/payments/CardRepayment/constants';
import { PAYMENT_TYPE_OPTIONS } from 'components/payments/CardAutopay/components/Content/components/NotEnabled/constants';
import { PAYMENT_CURRENCY_INFO } from 'components/payments/CardAutopay/components/Content/components/EnableAutopayModal/components/EnableAutopay/constants';
import { Currencies } from 'constants/currencies';

const useDetails = () => {
  const { paymentCurrency, fromAccountId, bankAccounts, wallets, paymentType } = useContext(CardAutopayContext);

  if (!paymentCurrency) return { formattedPaymentCurrency: Currencies.CAD };

  const formattedPaymentCurrency = PAYMENT_CURRENCY_INFO[paymentCurrency].name;

  const selectedFromAccount = [...wallets, ...bankAccounts].find(({ id }) => id === fromAccountId);
  const isBankAccount = selectedFromAccount?.__typename === 'BankAccount';

  const formattedPaymentType = PAYMENT_TYPE_OPTIONS.find((option) => option.value === paymentType)?.name;

  return {
    formattedPaymentCurrency,
    formattedPaymentType,
    fromAccount: selectedFromAccount?.displayName,
    method: transferMethod(!isBankAccount),
    isBankAccount,
  };
};

export default useDetails;
