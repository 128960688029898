import { gql } from '@apollo/client';

export const GET_DASHBOARD_INFO = gql`
  query DashboardInfo {
    me {
      ... on User {
        internalContact {
          status
        }
      }
    }
  }
`;
