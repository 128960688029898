import { ampTrackEvent } from 'amplitude';
import React, { useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import config from 'config';
import ArrowRight from 'components/svg/ArrowRight';
import { IoPencilOutline } from 'react-icons/io5';
import Button from 'components/Button';
import { GET_KYC_AND_CREDIT_ASSESSMENT_STATUS, DELETE_INDIVIDUAL_OWNER } from 'graphql/onboarding';
import Layout from 'components/onboarding/Layout';
import { Loaders } from 'components/cards/Loader';
import useIsMobile from 'hooks/useIsMobile';
import {
  ConfirmOwnerDeletionModal,
  EditIndividualRegistryOwnerModal,
} from 'components/onboarding/BusinessContacts/modals';

const getOwner = ({
  firstName,
  lastName,
  creditCheckAuthorizationProvided,
  creditCheckAuthorizationSentAt,
  creditCheckAuthorizationExpired,
}) => {
  return {
    name: `${firstName[0]}. ${lastName}`,
    creditCheckAuthorizationProvided: creditCheckAuthorizationProvided,
    creditCheckAuthorizationSentAt,
    creditCheckAuthorizationExpired,
  };
};

const RegistryOwnerRow = ({ owner, onEdit, loading, onDelete, isMobile }) => (
  <tr>
    <td>
      <strong className="tw-text-neutral-grey-1">{owner.name}</strong>
    </td>
    <td>{owner.creditCheckAuthorizationProvided ? 'Yes' : 'No'}</td>
    <td className="tw-py-4">
      {isMobile && <IoPencilOutline onClick={!loading ? onEdit : null} size={24} />}
      {!isMobile && (
        <Button primary onClick={!loading ? onEdit : null}>
          <div className="tw-flex tw-justify-center tw-items-center">
            <p className="tw-text-neutral-light tw-mr-2">Add Email</p>
          </div>
        </Button>
      )}
    </td>
    <td className="tw-py-4">
      <Button warning onClick={!loading ? onDelete : null}>
        <div className="tw-flex tw-justify-center tw-items-center">
          <p className="tw-text-neutral-light tw-text-center">Delete</p>
        </div>
      </Button>
    </td>
  </tr>
);

const ContactDetailsContainer = () => {
  const { data, refetch: refetchContacts, loading } = useQuery(GET_KYC_AND_CREDIT_ASSESSMENT_STATUS);
  const [selectedContactId, setSelectedContactId] = useState();
  const [showIndividualOwnerModal, setShowIndividualOwnerModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteIndividualOwner, { loading: isDeletingIndividual }] = useMutation(DELETE_INDIVIDUAL_OWNER);
  let contacts = _.get(data, 'me.account.contactsWithoutEmail', []);

  // TODO: Remove the following filtering after https://getloop.atlassian.net/browse/LBP-2314 will be completed
  if (config.env === 'testing' && contacts.length > 1) {
    contacts = contacts.filter(({ firstName, lastName }) => !(firstName === 'John' && lastName === 'Doe'));
  }

  const allAddedContacts = _.get(data, 'me.account.contacts', []).filter((contact) => contact.email !== null);
  const allCorporateShareholderContacts = _.get(data, 'me.account.corporateShareholders', []).filter(
    (contact) => contact.signingOfficerContact.email !== null
  );
  const businessName = _.get(data, 'me.account.name');
  const onDeleteMethodRef = useRef();
  const isMobile = useIsMobile();

  if (loading) return <Loaders.FullScreen />;

  const onCloseModal = () => {
    setSelectedContactId(null);
    onDeleteMethodRef.current = null;
  };
  const selectedContact = contacts.find((contact) => contact.id === selectedContactId);
  const history = useHistory();
  const handleSubmit = () => {
    history.push({ pathname: '/onboarding/additional-details', state: { bypassContactDetailsStep: true } });
  };

  const onDeleteOwner = () => {
    refetchContacts();
    setSelectedContactId(null);
    setShowDeleteModal(false);
  };

  return (
    <Layout>
      <div className="tw-w-full lg:tw-w-2/5 tw-mt-16 tw-flex tw-flex-col tw-flex-grow">
        <div className="tw-mb-8">
          <h1>Action required: Additional Business Contacts found</h1>
          <br></br>
          <p>
            While attempting to verify your business, we found other names associated with this organization. If these
            individuals are directors of the company or if they own <strong>{'>'} 25%</strong> of the business, we need
            you to provide their email address so that we can collect their consent for
            <strong> {businessName}</strong> to open a Loop Account.
          </p>
          <br></br>
          <h3>Existing Contacts</h3>
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-p-1 lg:tw-p-4 tw-border-2 tw-border-dashed tw-border-neutral-grey-2 tw-rounded-md tw-bg-neutral-grey-5 tw-cursor-pointer">
            <table className="lg:tw-table tw-table-auto tw-w-full">
              <thead>
                <tr>
                  <th className="tw-text-sm tw-font-normal tw-text-neutral-grey-2">Name</th>
                  <th className="tw-text-sm tw-font-normal tw-text-neutral-grey-2">Email</th>
                </tr>
              </thead>
              <tbody>
                {allAddedContacts.map((contact) => (
                  <tr key={contact}>
                    <td>
                      <strong className="tw-text-neutral-grey-1">
                        {contact.firstName} {contact.lastName}
                      </strong>
                    </td>
                    <td>{contact.email}</td>
                  </tr>
                ))}
                {allCorporateShareholderContacts.map((corporateShareholderContact) => (
                  <tr key={corporateShareholderContact.id}>
                    <td>
                      <strong className="tw-text-neutral-grey-1">
                        {corporateShareholderContact.signingOfficerContact.firstName}{' '}
                        {corporateShareholderContact.signingOfficerContact.lastName}
                        {' ('}
                        {corporateShareholderContact.name}
                        {')'}
                      </strong>
                    </td>
                    <td>{corporateShareholderContact.signingOfficerContact.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br></br>
          <br></br>
          <h3>Additional Contacts</h3>
          <br></br>
          <p>
            For each name below, please add an email address or delete contacts that are either not associated with the
            business or are duplicated above.
          </p>
          <br></br>
          {contacts.length > 0 ? (
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-p-1 lg:tw-p-4 tw-border-2 tw-border-dashed tw-border-neutral-grey-2 tw-rounded-md tw-bg-neutral-grey-5 tw-cursor-pointer">
              <table className="lg:tw-table tw-table-auto tw-w-full">
                <thead>
                  <tr>
                    <th className="tw-text-sm tw-font-normal tw-text-neutral-grey-2">Name</th>
                    <th className="tw-text-sm tw-font-normal tw-text-neutral-grey-2 tw-w-2/5 lg:tw-w-1/3">
                      Email Provided
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.map(({ id, firstName, lastName, creditCheckAuthorizationProvided }) => {
                    const owner = getOwner({ firstName, lastName, creditCheckAuthorizationProvided });
                    const onEdit = () => {
                      setSelectedContactId(id);
                      setShowIndividualOwnerModal(true);
                    };

                    const handleDelete = async (contactId) => {
                      try {
                        await deleteIndividualOwner({ variables: { contactId: contactId } });

                        ampTrackEvent('onboarding: delete_individual_owner: success');
                        onDeleteOwner();
                      } catch (err) {
                        setSelectedContactId(null);
                        console.error(err);
                      }
                    };

                    const onDelete = () => {
                      setSelectedContactId(id);
                      onDeleteMethodRef.current = () => handleDelete(id);
                      setShowDeleteModal(true);
                    };
                    return (
                      <RegistryOwnerRow
                        key={id}
                        onEdit={onEdit}
                        loading={selectedContactId === id}
                        owner={owner}
                        onDelete={onDelete}
                        isMobile={isMobile}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <strong>No additional action needed, please press continue to finish onboarding</strong>
          )}
          <br></br>
          <br></br>
          <p>
            Please email our support team at{' '}
            <a
              className="tw-font-semibold tw-text-primary-dark-green"
              target="_blank"
              href={`mailto:${config.verificationEmail}`}
              rel="noreferrer"
              data-testid="contact-email"
            >
              {config.verificationEmail}
            </a>{' '}
            if you have any questions.
          </p>
          <br></br>
          <div className="tw-flex tw-justify-end">
            <Button primary onClick={handleSubmit}>
              <div className="tw-flex tw-justify-center tw-items-center">
                <p className="tw-text-neutral-light tw-mr-2">Continue</p>
                <ArrowRight />
              </div>
            </Button>
          </div>
        </div>
        <EditIndividualRegistryOwnerModal
          onSuccess={() => {
            refetchContacts();
            setSelectedContactId(null);
          }}
          contact={selectedContact}
          show={showIndividualOwnerModal}
          setShow={setShowIndividualOwnerModal}
          onClose={onCloseModal}
        />
        <ConfirmOwnerDeletionModal
          show={showDeleteModal}
          contact={selectedContact}
          setShow={setShowDeleteModal}
          onClose={onCloseModal}
          onConfirm={onDeleteMethodRef.current}
          loading={isDeletingIndividual}
        />
      </div>
    </Layout>
  );
};

export default ContactDetailsContainer;
