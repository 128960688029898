import React from 'react';
import { BiCheck } from 'react-icons/bi';

import Button from 'components/Button';
import { ExchangeRateInfo } from 'components/Accounts/AccountsContent/components/AccountsModal/components/ConvertFunds/components/Steps/components';
import { useGetCurrencyLabel } from '../hooks';
import { Banner, ReferAndEarnBanner } from 'components/UI';
import { BannerSize } from 'components/UI/Banner';
import CoinSwap from 'components/svg/CoinSwap';
import { Loaders } from 'components/cards/Loader';
import { formatMoneyV2 } from 'utility/currency';
import { useComplete } from './hooks';
import cx from 'classnames';
import { PAY_PAYEE_SOURCE } from 'components/payments/PayPayee/constants';
import { PAYEE_PAYMENT_STATUS } from 'constants/index';

const Complete = ({ onNewPayment, onFinish, source }) => {
  const {
    showSavingsComponent,
    savingsAmount,
    navigateToSubscriptions,
    loading,
    transactionId,
    transferMethod,
    formattedCreatedAt,
    fromAccountName,
    toAccountName,
    originalAmountCurrency,
    chargedAmount,
    originalAmount,
    reason,
    notes,
    isConversion,
    rate,
    isMobile,
    status,
  } = useComplete();

  if (loading) return <Loaders.Light />;

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <div className="tw-p-2 tw-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-secondary-light-green tw-mb-8">
          <div className="tw-mr-4 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
            <BiCheck className="tw-text-secondary-light-green" />
          </div>
          {status === PAYEE_PAYMENT_STATUS.draft ? (
            <small className="tw-text-semantic-success">Your transfer has been initiated. An admin will approve.</small>
          ) : (
            <small className="tw-text-semantic-success">Your transfer has been completed</small>
          )}
        </div>
        {showSavingsComponent && savingsAmount && (
          <Banner
            icon={<CoinSwap width={40} />}
            iconAlignment="center"
            title={`You could have saved ${formatMoneyV2(savingsAmount)} on this payment`}
            message="Access even lower FX Fees when you make payments with Loop Power."
            submitButtonLabel="Upgrade Now"
            size={BannerSize.sm}
            onSubmit={navigateToSubscriptions}
          />
        )}
        <TransactionSummary
          transactionId={transactionId}
          transferMethod={transferMethod}
          formattedCreatedAt={formattedCreatedAt}
          fromAccountName={fromAccountName}
          toAccountName={toAccountName}
          originalAmountCurrency={originalAmountCurrency}
          chargedAmount={chargedAmount}
          originalAmount={originalAmount}
          reason={reason}
          notes={notes}
          isConversion={isConversion}
          rate={rate}
        />
        {!isMobile && <ReferAndEarnBanner horizontal />}
      </div>
      <div
        className={cx(
          'tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex tw-flex-col lg:tw-flex-row',
          source === PAY_PAYEE_SOURCE.reimbursements ? 'tw-justify-end' : 'tw-justify-between'
        )}
      >
        {source === PAY_PAYEE_SOURCE.reimbursements ? (
          <Button secondary onClick={onFinish} className="tw-w-full lg:tw-w-max">
            Back to Reimbursements
          </Button>
        ) : (
          <>
            <Button secondary onClick={onFinish} className="tw-w-full lg:tw-w-max">
              Back to Payees
            </Button>
            <Button primary onClick={onNewPayment} className="tw-mt-4 lg:tw-mt-0 tw-w-full lg:tw-w-max">
              Make Another Payment
            </Button>
          </>
        )}
      </div>
    </>
  );
};

const TransactionSummary = ({
  transactionId,
  transferMethod,
  formattedCreatedAt,
  fromAccountName,
  toAccountName,
  originalAmount,
  originalAmountCurrency,
  chargedAmount,
  reason,
  notes,
  isConversion,
  rate,
}) => {
  const currencyLabel = useGetCurrencyLabel(originalAmountCurrency);

  return (
    <div className="tw-flex tw-flex-col tw-pb-4">
      {transactionId && (
        <>
          <small className="tw-text-neutral-grey-2 tw-mt-4">Reference ID</small>
          <p className="tw-mt-1">{transactionId}</p>
        </>
      )}
      <small className="tw-text-neutral-grey-2 tw-mt-4">Initiated at</small>
      <p className="tw-mt-1">{formattedCreatedAt}</p>
      <small className="tw-text-neutral-grey-2 tw-mt-4">Recipient Account</small>
      <p className="tw-mt-1">{toAccountName}</p>
      <small className="tw-text-neutral-grey-2 tw-mt-4">From Account</small>
      <p className="tw-mt-1">{fromAccountName}</p>
      <small className="tw-text-neutral-grey-2 tw-mt-4">Payment Ref #</small>
      <p className="tw-mt-1">{reason}</p>

      <small className="tw-text-neutral-grey-2 tw-mt-4">Amount Your Payee Will Receive</small>
      <p className="tw-mt-1">{`${currencyLabel} ${formatMoneyV2(originalAmount)}`}</p>

      {isConversion && (
        <ExchangeRateInfo
          chargedAmount={chargedAmount}
          rate={rate}
          exchangeRateConfirmed
          chargedAmountSellLabel="Amount to Convert"
          chargedAmountBuyLabel="Amount to Convert"
          originalAmountSellLabel="Amount Your Payee Will Receive"
          originalAmountBuyLabel="Amount Your Payee Will Receive"
        />
      )}

      {notes && (
        <>
          <small className="tw-text-neutral-grey-2 tw-mt-4">Notes</small>
          <p className="tw-mt-1">{notes}</p>
        </>
      )}
      <small className="tw-text-neutral-grey-2 tw-mt-4">Method</small>
      <p className="tw-mt-1">{transferMethod}</p>
    </div>
  );
};

export default Complete;
