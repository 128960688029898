import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { CONFIRM_BANK_ACCOUNT_EMAIL } from 'graphql/bankAccounts';
import { ToasterContext } from 'context/Toaster';
import { Loaders } from 'components/cards/Loader';

const Loader = () => <Loaders.FullScreen />;

const Content = ({ hasDischargedLinesOfCredit, hasLineOfCredit }) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const email = query.get('email');
  const bankAccountId = query.get('bank_account_id');
  const { showToaster } = useContext(ToasterContext);
  const [confirmBankAccount] = useMutation(CONFIRM_BANK_ACCOUNT_EMAIL);

  const verifyBankAccount = async (data) => {
    try {
      await confirmBankAccount({ variables: { ...data } });
      showToaster({ type: 'success', text: 'Bank account successfully verified' });
    } catch (err) {
      showToaster({ type: 'error', text: 'Failed to verify bank account' });
      console.log(err);
    } finally {
      if (hasDischargedLinesOfCredit || hasLineOfCredit) {
        history.push('/dashboard/settings/bank-accounts');
      } else {
        history.push('/dashboard/settings/bank-accounts-settings');
      }
    }
  };

  useEffect(() => {
    verifyBankAccount({ email, bankAccountId });
  }, []);

  return <Loader />;
};

const VerifyBankAccountEmail = ({ loading, hasDischargedLinesOfCredit, hasLineOfCredit }) => {
  return loading ? (
    <Loader />
  ) : (
    <Content hasDischargedLinesOfCredit={hasDischargedLinesOfCredit} hasLineOfCredit={hasLineOfCredit} />
  );
};

export default VerifyBankAccountEmail;
