import React from 'react';

import { PADAgreementModalProps } from './PADAgreementModal.types';
import { Modal } from 'components/UI';
import Close from 'components/svg/Close';
import PADAgreementContent from '../PADAgreementContent';

const PADAgreementModal = ({ show, onClose }: PADAgreementModalProps) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div
        className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4 tw-font-bold outline-hidden print-hidden"
        tabIndex={0}
      >
        Pre-Authorized Debit Agreement
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-px-8 tw-my-6">
        <PADAgreementContent />
      </div>
    </Modal>
  );
};

export default PADAgreementModal;
