import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Clamp from 'react-simple-clamp';
import { GiInfo } from 'react-icons/gi';

import Button from 'components/Button';
import styles from './PaymentBox.module.scss';

const PaymentBox = (props) => {
  const { Icon, text, btnText, btnLink, showReadMore, infoText } = props;
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);
  return (
    <div className={styles.box}>
      <div className={styles.topContainer}>
        <Icon />

        {showReadMore ? <Clamp expanded={expanded} content={text} maxLines={2} maxHeight={92} /> : <p>{text}</p>}
        {showReadMore ? (
          <Button className={styles.btnReadMore} onClick={toggleExpanded}>
            {expanded ? 'read less' : 'read more'}
          </Button>
        ) : null}
      </div>

      <Link className={styles.btn} to={btnLink}>
        {btnText}
      </Link>

      {infoText && (
        <div className={styles.infoText + ' tw-text-sm'}>
          <GiInfo size={24} />
          {infoText}
        </div>
      )}
    </div>
  );
};

export default PaymentBox;
