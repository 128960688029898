import { useCallback, useContext, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { isEmpty } from 'lodash';

import { PayBalanceType, PayBalanceCurrencyOptions, PayBalance, PayBalanceCurrency, Transaction } from 'types/payments';
import { CardRepaymentContext } from 'context/CardRepayment';
import { Currencies } from 'constants/currencies';
import { PAYMENT_TYPE_INFO } from 'components/payments/CardRepayment/constants';
import { DetailsProps } from '../Details.types';
import { populateTransactions } from '../Details.utils';
import { useDeepEffect } from 'hooks';
import { ERROR } from '../constants';

const useDetails = ({ onNextStep }: DetailsProps) => {
  const {
    payBalanceInfo,
    setPayBalanceInfo,
    wallets,
    bankAccounts,
    statementBalanceInCad,
    statementBalanceInUsd,
    eurStatementBalanceInCad,
    gbpStatementBalanceInCad,
    usdStatementBalanceInCad,
    groupedCardsInfo,
    isLoading,
    error,
    setError,
  } = useContext(CardRepaymentContext);

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [singleTransaction, setSingleTransaction] = useState<Transaction | undefined>();

  const { paymentCurrencyOption, paymentType, fromAccounts } = payBalanceInfo || {};
  const {
    groupBalances,
    groupOngoingAmountDueByCurrency,
    groupOngoingMinimumAmountDueByCurrency,
    groupConvertedMinimumAmountDueByCurrency,
  } = groupedCardsInfo || {};

  const form = useForm({
    defaultValues: {
      paymentCurrencyOption,
      paymentType,
      fromAccounts,
    },
  });

  const { handleSubmit, setValue, clearErrors, control } = form;

  const watchPaymentCurrencyOption = useWatch<PayBalanceCurrencyOptions>({ control, name: 'paymentCurrencyOption' });
  const watchPaymentType = useWatch<PayBalanceType>({ control, name: 'paymentType' });
  const watchFromAccounts = useWatch<Record<PayBalanceCurrency, string>>({ control, name: 'fromAccounts' });

  const allFromAccounts = useMemo(() => [...wallets, ...bankAccounts], [wallets, bankAccounts]);

  const paymentTypeOptions = useMemo(
    () =>
      [PayBalanceType.STATEMENT_BALANCE, PayBalanceType.CURRENT_BALANCE, PayBalanceType.MINIMUM_DUE]
        .filter((type) =>
          watchPaymentCurrencyOption === PayBalanceCurrencyOptions.INDIVIDUAL
            ? true
            : type === PayBalanceType.STATEMENT_BALANCE
        )
        .map((type) => ({
          label: PAYMENT_TYPE_INFO[type].name,
          name: PAYMENT_TYPE_INFO[type].name,
          value: type,
        })),
    [watchPaymentCurrencyOption]
  );

  const isNothingToPay = useMemo(() => {
    if (!transactions) return true;

    const disabledTransactions = transactions.filter((txn) => txn.isDisabled);

    return disabledTransactions.length === transactions.length;
  }, [transactions]);

  const handleNextStep = useCallback(
    (data: PayBalance) => {
      if (error) {
        toast.error(ERROR.DEFAULT);
        return;
      }

      const { fromAccounts } = data || {};

      const atLeastOneFromAccount = Object.values(fromAccounts || {}).some(Boolean);

      if (!atLeastOneFromAccount) {
        setError({
          name: 'fromAccounts',
          message: ERROR.NO_FROM_ACCOUNT_SELECTED,
        } as ApolloError);
        return;
      }

      const currentTransactions = singleTransaction ? [singleTransaction] : transactions;

      if (!currentTransactions?.length) {
        toast.error(ERROR.NO_TRANSACTIONS);
        return;
      }

      setPayBalanceInfo((prevState) => ({
        ...prevState,
        ...data,
        transactions: currentTransactions,
      }));
      onNextStep();
    },
    [onNextStep, setPayBalanceInfo, transactions, singleTransaction, error, setError]
  );

  useDeepEffect(() => {
    const isMinDuePayment = watchPaymentType === PayBalanceType.MINIMUM_DUE;

    const minDueFromAccounts = {} as Record<PayBalanceCurrency, string>;

    if (isMinDuePayment) {
      const cadWalletId = wallets.find((wallet) => wallet.currency === Currencies[PayBalanceCurrency.CAD])?.id || '';
      const selectedCadFromAccountId = watchFromAccounts?.[PayBalanceCurrency.CAD] || cadWalletId;

      minDueFromAccounts[PayBalanceCurrency.CAD] = selectedCadFromAccountId;
      minDueFromAccounts[PayBalanceCurrency.USD] = selectedCadFromAccountId;
      minDueFromAccounts[PayBalanceCurrency.EUR] = selectedCadFromAccountId;
      minDueFromAccounts[PayBalanceCurrency.GBP] = selectedCadFromAccountId;
    }

    const selectedFromAccounts = !isEmpty(minDueFromAccounts) ? minDueFromAccounts : watchFromAccounts;

    const { transactions: currentTransactions, singleTransaction: currentSingleTransaction } = populateTransactions({
      watchPaymentCurrencyOption,
      watchPaymentType,
      groupBalances,
      groupOngoingAmountDueByCurrency,
      groupOngoingMinimumAmountDueByCurrency,
      groupConvertedMinimumAmountDueByCurrency,
      statementBalanceInCad,
      statementBalanceInUsd,
      eurStatementBalanceInCad,
      gbpStatementBalanceInCad,
      usdStatementBalanceInCad,
      allFromAccounts,
      selectedFromAccounts,
      wallets,
    });

    setTransactions(currentTransactions);
    setSingleTransaction(currentSingleTransaction);
  }, [
    watchPaymentCurrencyOption,
    watchPaymentType,
    groupBalances,
    groupOngoingAmountDueByCurrency,
    groupOngoingMinimumAmountDueByCurrency,
    statementBalanceInCad,
    statementBalanceInUsd,
    eurStatementBalanceInCad,
    gbpStatementBalanceInCad,
    usdStatementBalanceInCad,
    allFromAccounts,
    wallets,
    watchFromAccounts,
  ]);

  useDeepEffect(() => {
    clearErrors();
    if (fromAccounts) return;

    [PayBalanceCurrency.CAD, PayBalanceCurrency.USD, PayBalanceCurrency.EUR, PayBalanceCurrency.GBP].forEach(
      (currency) => setValue(`fromAccounts.${currency}`, '')
    );
  }, [watchPaymentType, watchPaymentCurrencyOption, setValue, fromAccounts, clearErrors]);

  useDeepEffect(() => {
    const atLeastOneFromAccount = Object.values(watchFromAccounts || {}).some(Boolean);

    if (atLeastOneFromAccount && error?.name === 'fromAccounts') {
      setError(null);
    }
  }, [watchFromAccounts, error, setError]);

  return {
    form,
    handleSubmit,
    handleNextStep,
    isLoading,
    transactions,
    singleTransaction,
    paymentTypeOptions,
    isNothingToPay,
    error,
    isShowPaymentTypes: !!watchPaymentCurrencyOption,
    isShowTransactions: !!watchPaymentType,
    isMinDuePayment: watchPaymentType === PayBalanceType.MINIMUM_DUE,
  };
};

export default useDetails;
