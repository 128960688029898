import React from 'react';

import Close from 'components/svg/Close';
import { SelectedOptionsBarProps } from './SelectedOptionsBar.types';

const SelectedOptionsBar = ({ selectedOptions, onRemoveOption, customOptionBarItemLabel }: SelectedOptionsBarProps) => (
  <div className="tw-flex tw-flex-wrap tw-gap-2 tw-mt-4">
    {selectedOptions.map(({ value, label, isAlwaysSelected }) => (
      <div
        key={value}
        className="tw-flex tw-items-center tw-gap-1 tw-rounded-r-full tw-rounded-l-full tw-cursor-default tw-bg-neutral-grey-3.5 tw-border tw-border-neutral-grey-3.5 hover:tw-border-primary-dark-green hover:tw-text-primary-dark-green hover:tw-bg-primary-light-green tw-transition-colors tw-p-2 tw-text-sm lg:tw-text-base"
      >
        <div>{customOptionBarItemLabel?.(label) || label}</div>
        {!isAlwaysSelected && (
          <button
            type="button"
            onClick={() => onRemoveOption(value)}
            className="tw-text-neutral-grey-2 hover:tw-text-semantic-error"
            title="Remove item"
          >
            <Close />
          </button>
        )}
      </div>
    ))}
  </div>
);

export default SelectedOptionsBar;
