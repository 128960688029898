import React from 'react';

import { TableProps } from './Table.types';
import { TableRow } from './components';
import { tableHeaders } from './constants';

const Table = ({ walletAssetAccountDetailsMappings, accountName }: TableProps) => {
  return (
    <>
      <table className="tw-table tw-table-auto tw-w-full">
        <thead>
          <tr>
            {tableHeaders.map((tableHeader, index) => (
              <th
                key={tableHeader}
                className={`tw-text-neutral-grey-2 tw-font-normal ${index == 2 && 'tw-text-center'}`}
              >
                {index > 0 && accountName} {tableHeader}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {walletAssetAccountDetailsMappings.map((walletAssetAccountDetailsMapping) => (
            <TableRow
              key={walletAssetAccountDetailsMapping.wallet.id}
              accountName={accountName}
              walletAssetAccountDetailsMapping={walletAssetAccountDetailsMapping}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Table;
