import { useContext, useState } from 'react';

import { CardMigrationContext } from 'components/CardMigrationV2/context/CardMigrationContext';

const useConfirmSelection = () => {
  const { cards, onPrevStep, startMigration, isCreditUser } = useContext(CardMigrationContext);
  const [agreementAccepted, setAgreementAccepted] = useState({
    globalVisaAgreement: false,
    creditFacilityAgreement: false,
  });

  const handleAgreementAcceptance = (agreementType: 'globalVisaAgreement' | 'creditFacilityAgreement') => {
    setAgreementAccepted((prevState) => ({
      ...prevState,
      [agreementType]: !prevState[agreementType],
    }));
  };

  const isMigrationButtonDisabled = isCreditUser
    ? agreementAccepted.globalVisaAgreement && agreementAccepted.creditFacilityAgreement
    : agreementAccepted.globalVisaAgreement;

  return {
    cards,
    isCreditUser,
    onPrevStep,
    startMigration,

    agreementAccepted,
    handleAgreementAcceptance,
    buttonEnabled: isMigrationButtonDisabled,
  };
};

export default useConfirmSelection;
