import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { CREDIT_CARD_STATUS } from 'constants/index';
import ActiveOrFrozenCardActions from './ActiveOrFrozenCardActions';
import ClosedCardActions from './ClosedCardActions';
import InactiveCardActions from './InactiveCardActions';
import AdminSuspendedCardActions from './AdminSuspendedCardActions';

const CardActions = ({ creditCard }) => {
  switch (creditCard.status) {
    case CREDIT_CARD_STATUS.admin_suspended:
      return <AdminSuspendedCardActions />;
    case CREDIT_CARD_STATUS.active:
    case CREDIT_CARD_STATUS.suspended:
      return <ActiveOrFrozenCardActions creditCard={creditCard} />;
    case CREDIT_CARD_STATUS.lost:
    case CREDIT_CARD_STATUS.stolen:
    case CREDIT_CARD_STATUS.fraudulent:
    case CREDIT_CARD_STATUS.damaged:
    case CREDIT_CARD_STATUS.block:
      return <ClosedCardActions />;
    default:
      return <InactiveCardActions creditCard={creditCard} />;
  }
};

export default CardActions;
