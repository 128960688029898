import { PayBalanceCurrencyOptions } from 'types/payments';
import { Currencies } from 'constants/currencies';

export const getCurrencyOptions = (currencyOption: PayBalanceCurrencyOptions) => {
  switch (currencyOption) {
    case PayBalanceCurrencyOptions.ALL_IN_CAD:
      return Currencies.CAD;
    case PayBalanceCurrencyOptions.ALL_IN_USD:
      return Currencies.USD;
    default:
      return null;
  }
};
