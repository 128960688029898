import React from 'react';

import { Modal } from 'components/UI';
import { ShowSupplierBankDetailsProps } from './ShowSupplierBankDetails.types';
import { useShowSupplierBankDetails } from './hooks';

const ShowSupplierBankDetails = ({ show, onClose, bankName, bankAccount }: ShowSupplierBankDetailsProps) => {
  // Eventually we will want to show bank details for Suppliers of all currencies - this is just for CAD right now
  const { bankDetails } = useShowSupplierBankDetails({ bankName, bankAccount });

  return (
    <Modal show={show} onClose={onClose} title="Bank Details">
      <div className="tw-grid tw-grid-cols-2 tw-gap-6 tw-p-8">
        {bankDetails.map((detail, index) => (
          <BankDetail key={index} label={detail.label} value={detail.value} />
        ))}
      </div>
    </Modal>
  );
};

const BankDetail = ({ label, value }: { label: string; value: string }) => (
  <div>
    <div className="tw-text-sm tw-text-neutral-grey-1">{label}</div>
    <div>{value}</div>
  </div>
);

export default ShowSupplierBankDetails;
