import {
  getOnboardingDashboardSideNavItems,
  getOnboardingBottomSidebarItems,
} from 'components/OnboardingDashboard/components/OnboardingDashboardLayout/OnboardingDashboardLayout.utils';

const useOnboardingDashboardLayout = () => {
  const bottomSideNavItems = getOnboardingBottomSidebarItems();
  const sideNavItems = getOnboardingDashboardSideNavItems();

  return { bottomSideNavItems, sideNavItems };
};

export default useOnboardingDashboardLayout;
