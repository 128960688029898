import { ampTrackEvent } from 'amplitude';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

import { GET_CREDIT_CARD_DETAILS_BY_ID, UPDATE_CARD_STATUS } from 'graphql/cards';
import { ConfirmActionModalType } from '../ConfirmActionModal.types';

const useConfirmActionModal = ({
  creditCardId,
  status,
  onClose,
  updateCardStatusFlag,
}: Pick<ConfirmActionModalType, 'creditCardId' | 'status' | 'onClose' | 'updateCardStatusFlag'>) => {
  const [updateCardStatus, { loading }] = useMutation(UPDATE_CARD_STATUS, {
    refetchQueries: [
      { query: GET_CREDIT_CARD_DETAILS_BY_ID, fetchPolicy: 'network-only', variables: { creditCardId } },
    ],
    awaitRefetchQueries: true,
  });
  const onConfirm = async () => {
    try {
      if (updateCardStatusFlag === false) {
        onClose();
        return;
      }
      const result = await updateCardStatus({
        variables: { creditCardId, status },
      });

      if (result.data && result.data.updateCardStatus) {
        toast.success('Card status has been updated successfully');
        ampTrackEvent(`cardUpdateStatus: ${status}: success`);
      }
    } catch (e) {
      console.error(e);
      toast.error('Unable to update the card status - please try again');
      ampTrackEvent(`cardUpdateStatus: ${status}: error`);
    } finally {
      onClose();
    }
  };
  return { onConfirm, loading };
};

export default useConfirmActionModal;
