import React, { useContext } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import cx from 'classnames';

import { FilterContext } from 'components/PointsTransactions/context/FilterContext';
import { PeriodDropdown } from 'components/UI';

const Filter = ({ className = '' }) => {
  const { period, setPeriod, hasDropdownFilters, handleClearFilters } = useContext(FilterContext);

  return (
    <>
      <div className={cx('tw-flex tw-justify-between tw-items-center', className)}>
        <div className="tw-flex tw-items-center">
          <div className="tw-text-sm tw-text-neutral-grey-2">Filter</div>
          <PeriodDropdown
            testId="filter-period-dropdown"
            label="Period"
            selectedValue={period}
            setSelectedValue={setPeriod}
            className="tw-ml-2"
            maxDate={new Date()}
            portalId="desktop-root"
          />
          {hasDropdownFilters && (
            <div
              onClick={handleClearFilters}
              data-testid="filter-clear-button"
              className="tw-flex tw-items-center tw-cursor-pointer tw-text-neutral-grey-2 tw-ml-2"
            >
              <IoCloseCircleOutline size={18} className="tw-mr-2" />
              <div className="tw-text-sm">Clear all</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Filter;
