import React from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ampTrackEvent } from 'amplitude';
import { IoReloadOutline } from 'react-icons/io5';

import { RESET_CARD_PIN_ATTEMPTS } from 'graphql/cards';
import { Loaders } from 'components/cards/Loader';
import CardActionButton from '../CardActionButton';

const ResetPinAttempts = ({ creditCard }) => {
  const [resetCardPinAttempts, { loading: loadingResetPinAttempts }] = useMutation(RESET_CARD_PIN_ATTEMPTS);

  const onResetPinAttempts = async () => {
    try {
      await resetCardPinAttempts({
        variables: {
          creditCardId: creditCard.id,
        },
      });
      toast.success('Card PIN attempts reset successfully');
      ampTrackEvent('resetCardPinAttempts: success');
    } catch (error) {
      console.error(error);
      toast.error('Error resetting Card PIN attempts - please try again');
      ampTrackEvent('resetCardPinAttempts: error');
    }
  };

  return (
    <>
      {loadingResetPinAttempts ? (
        <div className="tw-text-center">
          <Loaders.Spinner />
        </div>
      ) : (
        !creditCard.virtual &&
        creditCard.pin && (
          <CardActionButton
            label="Reset PIN Attempts"
            Icon={IoReloadOutline}
            onClick={onResetPinAttempts}
            className="tw-bg-secondary-light-green tw-text-primary-dark-green"
          />
        )
      )}
    </>
  );
};

export default ResetPinAttempts;
