import { endOfMonth, startOfMonth } from 'date-fns';

export const START_OF_MONTH = startOfMonth(new Date());
export const END_OF_MONTH = endOfMonth(new Date());

export const DEFAULT_CUSTOM_PERIOD_OPTION = {
  key: 'Other',
  from: START_OF_MONTH,
  to: END_OF_MONTH,
};
