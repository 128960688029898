import { useContext, useMemo } from 'react';

import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { getVisibleCardActions } from '../CardActions.utils';
import { useToggle } from 'hooks';

const useCardActions = ({ showCardDetails }: { showCardDetails: () => void }) => {
  const { creditCardDetails } = useContext(CardDetailsContext);
  const { status, id: cardId, virtual: isVirtual } = creditCardDetails || {};
  const { isOpen: showActivateCardModal, toggle: toggleActivateCardModal } = useToggle();
  const { isOpen: showFreezeCardModal, toggle: toggleFreezeCardModal } = useToggle();
  const { isOpen: showUnfreezeCardModal, toggle: toggleUnfreezeCardModal } = useToggle();
  const { isOpen: showReportLostCardModal, toggle: toggleReportLostCardModal } = useToggle();
  const { isOpen: showSetPinModal, toggle: toggleSetPinModal } = useToggle();

  const handleSetPin = () => {
    toggleSetPinModal();
  };

  const visibleActions = useMemo(
    () =>
      getVisibleCardActions({
        status,
        onActivateCard: toggleActivateCardModal,
        onViewDetails: showCardDetails,
        onFreezeCard: toggleFreezeCardModal,
        onUnfreezeCard: toggleUnfreezeCardModal,
        onReportLost: toggleReportLostCardModal,
        isVirtual,
      }),
    [status, showCardDetails]
  );

  const hiddenActions = useMemo(
    () => [
      { id: 'set-pin', label: 'Set PIN', action: handleSetPin },
      { id: 'freeze-card-rules', label: 'Freeze Card Rules', action: () => {} },
      { id: 'other', label: 'Other', action: () => {} },
    ],
    [handleSetPin]
  );

  return {
    cardId,
    visibleActions,
    hiddenActions,
    showCardDetails,
    showActivateCardModal,
    toggleActivateCardModal,
    showFreezeCardModal,
    toggleFreezeCardModal,
    showUnfreezeCardModal,
    toggleUnfreezeCardModal,
    showReportLostCardModal,
    toggleReportLostCardModal,
    showSetPinModal,
    toggleSetPinModal,
    isVirtual,
  };
};

export default useCardActions;
