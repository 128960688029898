import React from 'react';

const CardsListSkeleton = () => {
  const COLUMNS = 6;
  return (
    <div>
      <div className="tw-flex tw-w-full tw-animate-pulse tw-bg-neutral-grey-4.5 tw-h-32 tw-rounded tw-mb-6" />
      {Array(COLUMNS)
        .fill(0)
        .map((_, i) => (
          <div className="tw-my-2 tw-w-full" key={i}>
            <div className="tw-flex tw-w-full tw-animate-pulse tw-bg-neutral-grey-4.5 tw-h-24 tw-rounded" />
          </div>
        ))}
    </div>
  );
};

export default CardsListSkeleton;
