import React from 'react';
import { AiOutlineBank, AiOutlineGift } from 'react-icons/ai';
import { RiBillLine } from 'react-icons/ri';
import {
  MdBusiness,
  MdTravelExplore,
  MdOutlineFastfood,
  MdOutlineInventory,
  MdOutlineLocalShipping,
  MdMiscellaneousServices,
} from 'react-icons/md';
import { BiCameraMovie, BiCategory } from 'react-icons/bi';
import {} from 'react-icons/fi';
import { FaFileContract } from 'react-icons/fa';
import { BsCash } from 'react-icons/bs';
import { HiOutlineReceiptTax, HiOutlineOfficeBuilding, HiOutlineSpeakerphone } from 'react-icons/hi';

// This is a Categories Mock for tests. UI uses transactionCategories fetch in graphql/queries.
export const CATEGORIES = [
  { transactionCategoryId: '1', description: 'Other Expenses', loopId: '0' },
  { transactionCategoryId: '2', description: 'Advertising', loopId: '1000' },
  { transactionCategoryId: '3', description: 'Cash', loopId: '2000' },
  { transactionCategoryId: '4', description: 'Contractors', loopId: '3000' },
  { transactionCategoryId: '5', description: 'Donations', loopId: '4000' },
  { transactionCategoryId: '6', description: 'Entertainment', loopId: '5000' },
  { transactionCategoryId: '7', description: 'Fees', loopId: '6000' },
  { transactionCategoryId: '8', description: 'Food', loopId: '7000' },
  { transactionCategoryId: '9', description: 'Insurance', loopId: '8000' },
  { transactionCategoryId: '10', description: 'Inventory', loopId: '9000' },
  { transactionCategoryId: '11', description: 'Investments', loopId: '10000' },
  { transactionCategoryId: '12', description: 'Loans', loopId: '11000' },
  { transactionCategoryId: '13', description: 'Payroll', loopId: '12000' },
  { transactionCategoryId: '14', description: 'Rent', loopId: '13000' },
  { transactionCategoryId: '15', description: 'Revenue', loopId: '14000' },
  { transactionCategoryId: '16', description: 'Shipping', loopId: '15000' },
  { transactionCategoryId: '17', description: 'Software', loopId: '16000' },
  { transactionCategoryId: '18', description: 'Taxes', loopId: '19000' },
  { transactionCategoryId: '19', description: 'Travel', loopId: '21000' },
  { transactionCategoryId: '20', description: 'Utilities', loopId: '22000' },
];

export const CATEGORIES_ICONS = {
  0: <BiCategory />,
  1000: <HiOutlineSpeakerphone />,
  2000: <BsCash />,
  3000: <FaFileContract />,
  4000: <AiOutlineGift />,
  5000: <BiCameraMovie />,
  6000: <AiOutlineBank />,
  7000: <MdOutlineFastfood />,
  8000: <AiOutlineBank />,
  9000: <MdOutlineInventory />,
  10000: <BsCash />,
  11000: <BsCash />,
  12000: <BsCash />,
  13000: <HiOutlineOfficeBuilding />,
  14000: <BsCash />,
  15000: <MdOutlineLocalShipping />,
  16000: <MdBusiness />,
  19000: <HiOutlineReceiptTax />,
  21000: <MdTravelExplore />,
  22000: <RiBillLine />,
  23000: <MdMiscellaneousServices />,
};
