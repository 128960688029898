import { PAYEE_DETAILS_SOURCE } from 'components/payments/AddPayee/constants';

export const payeeDetailsSourceOptions = [
  {
    label: 'Enter Myself',
    value: PAYEE_DETAILS_SOURCE.MANUAL,
  },
  {
    label: 'Request from Payee',
    value: PAYEE_DETAILS_SOURCE.BY_REQUEST,
  },
];
