import React from 'react';

import { ADDRESS_CATEGORY } from 'constants/index';
import SupportedAddress from './SupportedAddress';

const { OA, RP, CA, US } = ADDRESS_CATEGORY;

const AdditonalAddresses = ({ additionalAddresses, creditCard }) => {
  const CAAddress = additionalAddresses.find((address) => address.category === OA || address.category === CA);
  const USAddress = additionalAddresses.find((address) => address.category === RP || address.category === US);

  return (
    <>
      <div className="tw-text-center">
        <small className="tw-font-semibold">Addresses Linked To Card</small>
      </div>
      {CAAddress ? (
        <SupportedAddress address={CAAddress} creditCard={creditCard} />
      ) : (
        <EmptyState addressCategory={CA} creditCard={creditCard} />
      )}
      {USAddress ? (
        <SupportedAddress address={USAddress} creditCard={creditCard} />
      ) : (
        <EmptyState addressCategory={US} creditCard={creditCard} />
      )}
    </>
  );
};

const EmptyState = ({ addressCategory, creditCard }) => {
  const address = {
    city: '',
    country: '',
    countrySubdivision: '',
    postalCode: '',
    street: '',
    category: addressCategory === OA || addressCategory === CA ? OA : RP,
  };
  return <SupportedAddress address={address} creditCard={creditCard} />;
};

export default AdditonalAddresses;
