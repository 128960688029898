import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_BANK_ACCOUNTS } from 'graphql/integrations';
import { BankAccount } from 'types/bankAccount';

const useGetBankAccountsState = () => {
  const { data: bankAccountsData, loading, error } = useQuery(GET_BANK_ACCOUNTS);

  const bankAccounts: BankAccount[] = get(bankAccountsData, 'bankInfo.bankAccounts', []);

  const hasBankAccounts = bankAccounts.length > 0;
  const hasAtLeastOneBankAccountVerified = bankAccounts.some((ba) => ba.verified);

  return {
    isLoading: loading,
    isError: !!error,
    hasBankAccounts,
    hasAtLeastOneBankAccountVerified,
  };
};

export default useGetBankAccountsState;
