import React from 'react';

import { PreviewScreenProps } from './PreviewScreen.types';
import { PreviewContent } from './components';

import styles from './PreviewScreen.module.scss';

const PreviewScreen = ({ bgImage }: PreviewScreenProps) => {
  return (
    <div className={styles.preview}>
      <img className={styles.image} src={bgImage} alt="page background" />
      <div className="tw-z-10 tw-w-full tw-w-1/3 md:tw-w-2/3 lg:tw-w-1/2 tw-flex-grow md:tw-flex-grow-0 tw-rounded-md tw-bg-neutral-light tw-border-2 tw-border-primary-dark-green">
        <PreviewContent />
      </div>
    </div>
  );
};

export default PreviewScreen;
