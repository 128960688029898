import React from 'react';

import { Loaders } from 'components/cards/Loader';
import { PADAgreementProps } from './PADAgreement.types';
import PADAgreementCA from 'components/payments/PADAgreementCA';
import { usePADAgreement } from './hooks';

const PADAgreement = ({ onNextStep }: PADAgreementProps) => {
  const { selectedBankAccount, handleSubmit, padAgreementVersion, isLoading, isError } = usePADAgreement({
    onNextStep,
  });

  if (isLoading) return <Loaders.Light />;

  if (isError) return 'Something went wrong';

  return (
    <div>
      <PADAgreementCA
        onNextStep={handleSubmit}
        footerBorder
        bankAccountWithoutPDA={selectedBankAccount}
        submitButtonLabel="Next"
        version={padAgreementVersion}
      />
    </div>
  );
};

export default PADAgreement;
