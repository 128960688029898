import React from 'react';
import { useMutation } from '@apollo/client';
import { ampTrackEvent } from 'amplitude';
import { toast } from 'react-toastify';

import { UPDATE_CARD_STATUS, GET_CREDIT_CARDS_FOR_LIST, GET_CREDIT_CARD_BY_ID } from 'graphql/cards';
import Button from 'components/Button';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';

const ConfirmActionModal = ({
  show,
  onClose,
  creditCardId,
  status,
  title,
  description,
  confirmLabel,
  updateCardStatusFlag = true,
}) => {
  const [updateCardStatus, { loading }] = useMutation(UPDATE_CARD_STATUS, {
    refetchQueries: [
      { query: GET_CREDIT_CARDS_FOR_LIST, fetchPolicy: 'network-only' },
      { query: GET_CREDIT_CARD_BY_ID, fetchPolicy: 'network-only', variables: { creditCardId } },
    ],
    awaitRefetchQueries: true,
  });
  const onConfirm = async () => {
    try {
      if (updateCardStatusFlag === false) {
        onClose();
        return;
      }
      const result = await updateCardStatus({
        variables: { creditCardId, status },
      });

      if (result.data && result.data.updateCardStatus) {
        toast.success('Card status has been updated successfully');
        ampTrackEvent(`cardUpdateStatus: ${status}: success`);
      }
    } catch (e) {
      console.error(e);
      ampTrackEvent(`cardUpdateStatus: ${status}: error`);
      toast.error('Unable to update the card status - please try again');
    } finally {
      onClose();
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4">
        {title}
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-px-8 tw-py-8 tw-border-t tw-border-b tw-border-neutral-grey-4">
        <p>{description}</p>
      </div>
      <div className="tw-flex tw-justify-end tw-px-8 tw-py-6">
        {!updateCardStatusFlag ? (
          <Button secondary onClick={onConfirm}>
            Done
          </Button>
        ) : (
          <Button
            className={`${
              loading ? 'tw-bg-neutral-grey-3' : 'tw-bg-primary-dark-green'
            } tw-px-4 tw-py-2 tw-rounded-md tw-ml-8 tw-text-neutral-light tw-flex tw-justify-center tw-items-center`}
            onClick={onConfirm}
            isFetching={loading}
          >
            <div className="tw-flex tw-justify-center tw-items-center">{loading ? 'Submitting...' : confirmLabel}</div>
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmActionModal;
