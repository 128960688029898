import React from 'react';

import CashAndStars from 'components/svg/CashAndStars';
import { Banner } from 'components/UI';

const OpenUSDAccountBanner = ({ openUSDAccount }: { openUSDAccount: () => void }) => (
  <Banner
    title="Get a Free US Dollar Account in the USA"
    message="Get a free US account number and a routing number so you can send and receive payments from anyone in the US
          including your payments from Amazon, Shopify, Stripe and PayPal."
    submitButtonLabel="Go to Accounts"
    icon={<CashAndStars />}
    onSubmit={openUSDAccount}
  />
);

export default OpenUSDAccountBanner;
