import { useContext, useRef, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import type { TextElement as TextElementType } from '@basis-theory/basis-theory-react/types';

import { ConfirmPinError } from 'components/creditCards/creditCards.types';
import { CreateCardContext } from 'components/creditCards/components/CreateCardModal/context/CreateCardContext';
import { useBasisTheoryOperations } from 'components/creditCards/hooks';

const useCardPin = () => {
  const { currentStep, steps, onNextStep, onPrevStep, createdCardData } = useContext(CreateCardContext);
  const { id: cardId, cardProcessorId } = createdCardData || {};
  const { bt, setPin } = useBasisTheoryOperations();

  const [loading, setLoading] = useState(false);

  const pinRef = useRef<TextElementType>(null);
  const confirmPinRef = useRef<TextElementType>(null);

  const form = useForm({
    defaultValues: {
      pin: '',
      confirmPin: '',
    },
  });

  const { handleSubmit } = form;

  const onSubmit = useCallback(async () => {
    setLoading(true);
    const processingToast = toast.loading('Setting PIN...');

    if (!cardId || !cardProcessorId) {
      toast.update(processingToast, {
        render: 'Unable to set PIN - please try again',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    try {
      await setPin(cardId, cardProcessorId, pinRef, confirmPinRef);

      toast.update(processingToast, {
        render: 'PIN set successfully',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      setLoading(false);
      onNextStep();
    } catch (error) {
      console.log(error);

      const err = error as ConfirmPinError;
      const errorMessage = err.data?.confirm_pin_error
        ? err.data.confirm_pin_error
        : 'Unable to set PIN - please try again';

      toast.update(processingToast, {
        render: errorMessage,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      setLoading(false);
    }
  }, [cardId, cardProcessorId, setPin, onNextStep, setLoading]);

  const handleOnNextStep = () => {
    onSubmit();
  };

  const handleOnPreviousStep = () => {
    form.reset();
    onPrevStep();
  };

  return {
    currentStep,
    steps,
    form,
    handleSubmit,
    pinRef,
    confirmPinRef,
    loading,
    handleOnNextStep,
    handleOnPreviousStep,
    bt,
  };
};

export default useCardPin;
