import React from 'react';
import { PiDotsThreeVertical, PiSealCheckLight, PiSnowflakeLight, PiWarningLight } from 'react-icons/pi';
import { Menu, Transition } from '@headlessui/react';
import cx from 'classnames';

import { ActionItem, ConfirmActionModal, SetPinModal } from './components';
import { useCardActions } from './hooks';
import { CREDIT_CARD_STATUS } from 'constants/index';

import styles from '../../CardDetails.module.scss';
import ActivateCardModal from './components/ActivateCardModal';

const CardActions = ({ showCardDetails }: { showCardDetails: () => void }) => {
  const {
    cardId,
    visibleActions,
    hiddenActions,
    showActivateCardModal,
    toggleActivateCardModal,
    showFreezeCardModal,
    toggleFreezeCardModal,
    showUnfreezeCardModal,
    toggleUnfreezeCardModal,
    showReportLostCardModal,
    toggleReportLostCardModal,
    showSetPinModal,
    toggleSetPinModal,
  } = useCardActions({ showCardDetails });

  return (
    <>
      <div className={cx(styles.cardActions, 'tw-flex tw-justify-between tw-gap-8 tw-mb-4')}>
        {visibleActions.map(({ id, Icon, label, action, className, actionDisabled }) => (
          <ActionItem
            key={id}
            title={label}
            Icon={Icon}
            onClick={action}
            className={className}
            actionDisabled={actionDisabled}
          />
        ))}
        <Menu as="div" className="tw-relative tw-inline-block">
          <Menu.Button>
            <ActionItem title="More" Icon={PiDotsThreeVertical} onClick={() => {}} />
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
            enterTo="tw-transform tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
          >
            <Menu.Items className="tw-absolute tw-right-0 tw-origin-top-right tw-w-72 tw-mt-2 tw-z-50 tw-rounded-md tw-border tw-border-neutral-grey-4 tw-overflow-hidden">
              {hiddenActions.map(({ id, label, action }) => (
                <Menu.Item
                  key={id}
                  as="div"
                  className="tw-cursor-pointer tw-bg-neutral-light hover:tw-bg-neutral-grey-5 tw-px-4 tw-py-2 tw-shadow-lg"
                  onClick={action}
                >
                  {label}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      {showActivateCardModal && <ActivateCardModal show={showActivateCardModal} onClose={toggleActivateCardModal} />}

      {showFreezeCardModal && (
        <ConfirmActionModal
          show={showFreezeCardModal}
          onClose={toggleFreezeCardModal}
          creditCardId={cardId}
          status={CREDIT_CARD_STATUS.suspended.toUpperCase()}
          title={
            <div className="tw-flex tw-items-center">
              <PiSnowflakeLight className="tw-text-primary-blue" size={16} />
              <strong className="tw-ml-2">Freeze card?</strong>
            </div>
          }
          description="The card will be frozen temporarily. You will be able to unfreeze it at any time."
          confirmLabel="Freeze card"
        />
      )}

      {showUnfreezeCardModal && (
        <ConfirmActionModal
          show={showUnfreezeCardModal}
          onClose={toggleUnfreezeCardModal}
          creditCardId={cardId}
          status={CREDIT_CARD_STATUS.active.toUpperCase()}
          title={
            <div className="tw-flex tw-items-center">
              <PiSealCheckLight className="tw-text-primary-blue" size={16} />
              <strong className="tw-ml-2">Unfreeze card?</strong>
            </div>
          }
          description="The card will be reactivated. You will be able to freeze it again at any time."
          confirmLabel="Unfreeze card"
        />
      )}

      {showReportLostCardModal && (
        <ConfirmActionModal
          show={showReportLostCardModal}
          onClose={toggleReportLostCardModal}
          creditCardId={cardId}
          status={CREDIT_CARD_STATUS.lost.toUpperCase()}
          title={
            <div className="tw-flex tw-items-center">
              <PiWarningLight className="tw-text-primary-dark-yellow" size={16} />
              <strong className="tw-ml-2">Report Lost/Stolen</strong>
            </div>
          }
          description="The card will be reported as lost and deactivated."
          confirmLabel="Report and Deactivate"
        />
      )}

      {showSetPinModal && <SetPinModal show={showSetPinModal} onClose={toggleSetPinModal} />}
    </>
  );
};

export default CardActions;
