import { gql } from '@apollo/client';

export const GET_REIMBURSEMENT_REQUESTS = gql`
  query ExpenseReimbursements(
    $status: String
    $page: String
    $numPerPage: String
    $startDate: String
    $endDate: String
    $members: [ID!]
    $currencies: [String!]
  ) {
    expenseReimbursements(
      status: $status
      page: $page
      numPerPage: $numPerPage
      startDate: $startDate
      endDate: $endDate
      members: $members
      currencies: $currencies
    ) {
      items {
        id
        requester {
          name
          email
        }
        amountRequested {
          amount
          currency
        }
        originalAmount {
          amount
          currency
        }
        taxAmount {
          amount
          currency
        }
        receipt
        merchantName
        status
        description
        transactionDate
        approver {
          name
        }
        dateApproved
        createdAt
      }
      pageData {
        totalPages
        currentPage
        nextPage
        prevPage
        totalCount
      }
    }
  }
`;
export const CREATE_REIMBURSEMENT_REQUEST = gql`
  mutation CreateReimbursementRequest(
    $requestType: String!
    $amountRequested: MoneyInput!
    $originalAmount: MoneyInput
    $taxAmount: MoneyInput
    $merchantName: String!
    $description: String!
    $transactionDate: String
    $blobSignedId: String
  ) {
    createReimbursementRequest(
      requestType: $requestType
      amountRequested: $amountRequested
      originalAmount: $originalAmount
      taxAmount: $taxAmount
      merchantName: $merchantName
      description: $description
      transactionDate: $transactionDate
      blobSignedId: $blobSignedId
    ) {
      id
      status
      amountRequested {
        amount
        currency
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_REIMBURSEMENT_REQUEST_STATUS = gql`
  mutation UpdateReimbursementRequest($expenseReimbursementId: ID!, $status: String!, $declineReason: String) {
    updateReimbursementRequest(
      expenseReimbursementId: $expenseReimbursementId
      status: $status
      declineReason: $declineReason
    ) {
      id
      status
      amountRequested {
        amount
        currency
      }
      createdAt
      updatedAt
    }
  }
`;
