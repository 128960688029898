import { CreditCardStatus } from 'types/creditCard';

export const getCardStatusDetails = (status?: CreditCardStatus) => {
  switch (status) {
    case CreditCardStatus.active:
      return { text: 'Active', className: 'tw-text-semantic-success' };
    case CreditCardStatus.inactive:
      return { text: 'Inactive', className: 'tw-text-neutral-grey-2' };
    case CreditCardStatus.suspended:
      return { text: 'Frozen', className: 'tw-text-primary-blue' };
    case CreditCardStatus.admin_suspended:
      return { text: 'Suspended by Admin', className: '' };
    case CreditCardStatus.lost:
    case CreditCardStatus.stolen:
    case CreditCardStatus.fraudulent:
    case CreditCardStatus.damaged:
    case CreditCardStatus.block:
      return { text: 'Closed', className: 'tw-text-neutral-grey-2' };
    default:
      return { text: 'Inactive', className: 'tw-text-neutral-grey-2' };
  }
};
