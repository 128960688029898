import React from 'react';

const Notification = ({ Icon, message, error }) => {
  return (
    <div
      className={`tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-center tw-py-3 tw-rounded-md ${
        error
          ? 'tw-bg-semantic-error-background tw-text-semantic-error'
          : 'tw-bg-secondary-light-green tw-text-semantic-success'
      }`}
    >
      <Icon />
      <span className="tw-ml-4">{message}</span>
    </div>
  );
};

export default Notification;
