import React from 'react';
import { BiCheck } from 'react-icons/bi';
import { IoCopyOutline } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';

import Button from 'components/Button';
import { useComplete } from './hooks';
import { CompleteProps } from './Complete.types';

const Complete = ({ onFinish }: CompleteProps) => {
  const { onCopy, accountNumber, institutionNumber, transitNumber } = useComplete();

  return (
    <div>
      <div className="tw-flex tw-flex-col tw-gap-8 tw-p-8">
        <div className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-secondary-light-green">
          <div className="tw-mr-2 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
            <BiCheck className="tw-text-secondary-light-green" />
          </div>
          <div className="tw-text-sm tw-text-semantic-success">Account successfully created</div>
        </div>
        <div className="tw-text-sm">
          Thank you for submitting your account information. You can begin receiving payments to your account using the
          account details below.
        </div>
        <div className="tw-flex tw-flex-col tw-gap-y-4 tw-py-2">
          <div>
            <small>Account Number</small>
            <div className="tw-flex tw-items-center tw-gap-2 tw-mt-1">
              {accountNumber}{' '}
              <IoCopyOutline
                className="tw-text-primary-dark-green tw-cursor-pointer"
                onClick={() => onCopy(accountNumber)}
                data-tip="Copied!"
                data-for="tooltip-account-number"
              />
              <ReactTooltip
                id="tooltip-account-number"
                event="mouseup"
                eventOff="mouseout"
                delayHide={500}
                textColor="var(--colors-natural-light)"
                backgroundColor="var(--colors-natural-grey-1)"
              />
            </div>
          </div>
          <div>
            <small>Institution Number</small>
            <div className="tw-flex tw-items-center tw-gap-2 tw-mt-1">
              {institutionNumber}{' '}
              <IoCopyOutline
                className="tw-text-primary-dark-green tw-cursor-pointer"
                onClick={() => onCopy(institutionNumber)}
                data-tip="Copied!"
                data-for="tooltip-institution-number"
              />
              <ReactTooltip
                id="tooltip-institution-number"
                event="mouseup"
                eventOff="mouseout"
                delayHide={500}
                textColor="var(--colors-natural-light)"
                backgroundColor="var(--colors-natural-grey-1)"
              />
            </div>
          </div>
          <div>
            <small>Transit Number</small>
            <div className="tw-flex tw-items-center tw-gap-2 tw-mt-1">
              {transitNumber}{' '}
              <IoCopyOutline
                className="tw-text-primary-dark-green tw-cursor-pointer"
                onClick={() => onCopy(transitNumber)}
                data-tip="Copied!"
                data-for="tooltip-transit-number"
              />
              <ReactTooltip
                data-for="tooltip-transit-number"
                event="mouseup"
                eventOff="mouseout"
                delayHide={500}
                textColor="var(--colors-natural-light)"
                backgroundColor="var(--colors-natural-grey-1)"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex lg:tw-justify-end">
        <Button primary onClick={onFinish} className="tw-w-full lg:tw-w-max">
          Go To Accounts
        </Button>
      </div>
    </div>
  );
};

export default Complete;
