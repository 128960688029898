import React, { memo } from 'react';

import { BalanceCard } from 'components/creditCards/components/CardBalances/components';
import { CurrentBalanceCardProps } from './CurrentBalanceCard.types';
import { useCurrentBalanceCard } from './hooks';
import { PendingDepositsInfo } from 'components/Accounts/components';

const CurrentBalanceCard = ({
  current,
  pending,
  posted,
  ongoingPayments,
  isPreFunded,
  isAdmin,
  openAddFundsToBalanceModal,
}: CurrentBalanceCardProps) => {
  const {
    currency,
    formattedBalance,
    formattedPending,
    formattedPosted,
    formattedOngoingPayments,
    handleOpenAddFundsToBalanceModal,
  } = useCurrentBalanceCard({
    current,
    pending,
    posted,
    ongoingPayments,
    isAdmin,
    openAddFundsToBalanceModal,
  });

  return (
    <BalanceCard
      currency={currency}
      formattedBalance={formattedBalance}
      isAddFunds={isPreFunded && isAdmin}
      handleAddFunds={handleOpenAddFundsToBalanceModal}
    >
      {isPreFunded ? (
        <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-gap-1">
          <div className="tw-flex tw-gap-2">
            Pending Deposits <PendingDepositsInfo />
          </div>
          {formattedOngoingPayments}
        </div>
      ) : (
        <>
          <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-gap-1">
            <div>Pending</div>
            {formattedPending}
          </div>
          <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-gap-1">
            <div>Posted</div>
            {formattedPosted}
          </div>
        </>
      )}
    </BalanceCard>
  );
};

export default memo(CurrentBalanceCard);
