import React from 'react';
import { useFormContext } from 'react-hook-form';

import { SUPPLIER_SPECIFIC_COUNTRIES, CURRENCY_CODES } from 'constants/index';
import {
  DIGITS_REGEX,
  BRITISH_SHORT_CODE_REGEX,
  BIC_SWIFT_REGEX,
  IBAN_REGEX,
  BSB_CODE_REGEX,
  DKK_CODE_REGEX,
} from 'constants/regex';
import { sanitizeAccountNumber } from 'utility/string';
import { TextField } from 'components/FormFields/v2';
import { validateAccountNumber, validateINRBankCode } from 'utility/validation/bankAccount';

const { CA, US, EU, GB, AU, CN, MX, PH, DK, IN } = SUPPLIER_SPECIFIC_COUNTRIES;
const { CAD, USD, EUR, GBP, AUD, CNY, MXN, PHP, DKK, INR } = CURRENCY_CODES;

const BankingFields = () => {
  const { watch } = useFormContext();

  const country = watch('basedOnCurrencyCountry');
  const currency = watch('bankAccount.currency');

  if (country === CA.code && currency === CAD) return <CABankFields />;
  if (country === US.code && currency === USD) return <USBankFields />;
  if (country === CN.code && currency === CNY) return <CNBankFields />;
  if (country === GB.code && currency === GBP) return <GBBankFields />;
  if (country === MX.code && currency === MXN) return <MXBankFields />;
  if (country === EU.code && currency === EUR) return <EUBankFields />;
  if (country === PH.code && currency === PHP) return <PHBankFields />;
  if (country === AU.code && currency === AUD) return <AUBankFields />;
  if (country === DK.code && currency === DKK) return <DKBankFields />;
  if (country === IN.code && currency === INR) return <InBankFields />;

  return <DefaultBankFields />;
};

const DefaultBankFields = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        name="bankAccount.name"
        label="Bank Name"
        placeholder="Royal Bank of Canada"
        ref={register({ required: true })}
        required
      />
      <AccountNumberField required />
      <BankCodeField placeholder="ROYCCAT2XXX" required />
      <TextField
        name="bankAccount.iban"
        label="Bank IBAN (If Available)"
        placeholder="GB33BUKB20201555555555"
        ref={register({ pattern: { value: IBAN_REGEX, message: 'Invalid Value' } })}
      />
    </>
  );
};

const CABankFields = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        name="bankAccount.name"
        label="Bank Name"
        placeholder="Royal Bank of Canada"
        ref={register({ required: true })}
        required
      />
      <AccountNumberField required />
      <TextField
        name="bankAccount.institutionNumber"
        label="Institution Number"
        placeholder="260"
        ref={register({
          pattern: {
            value: DIGITS_REGEX,
            message: 'Invalid Institution Number',
          },
          minLength: {
            value: 3,
            message: 'Institution Number must be three-digits long',
          },
          maxLength: {
            value: 3,
            message: 'Institution Number must be three-digits long',
          },
        })}
        required
      />
      <TextField
        name="bankAccount.transitNumber"
        label="Transit Number"
        placeholder="11242"
        ref={register({
          pattern: {
            value: DIGITS_REGEX,
            message: 'Invalid Transit Number',
          },
          minLength: {
            value: 5,
            message: 'Transit Number must be five-digits long',
          },
          maxLength: {
            value: 5,
            message: 'Transit Number must be five-digits long',
          },
        })}
        required
      />
    </>
  );
};

const CNBankFields = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        name="bankAccount.name"
        label="Bank Name"
        placeholder="Bank of China"
        ref={register({ required: true })}
        required
      />
      <AccountNumberField required />
      <TextField
        name="bankAccount.cnaps"
        label="CNAPS"
        placeholder="501222000000"
        ref={register({ required: true })}
        required
      />
      <BankCodeField placeholder="BKCHCNBJ110" required />
    </>
  );
};

const GBBankFields = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        name="bankAccount.name"
        label="Bank Name"
        placeholder="Barclays"
        ref={register({ required: true })}
        required
      />
      <AccountNumberField required />
      <TextField
        name="bankAccount.sortCode"
        label="Sort Code Number"
        placeholder="202678"
        ref={register({ required: true, pattern: { value: BRITISH_SHORT_CODE_REGEX, message: 'Invalid Value' } })}
        required
      />
    </>
  );
};

const MXBankFields = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        name="bankAccount.name"
        label="Bank Name"
        placeholder="IXE Banco"
        ref={register({ required: true })}
        required
      />
      <AccountNumberField required />
      <TextField
        name="bankAccount.clabe"
        label="CLABE"
        placeholder="032180000118359719"
        ref={register({ required: true })}
        required
      />
      <BankCodeField placeholder="IXEBMXMMXXX" />
    </>
  );
};

const USBankFields = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        name="bankAccount.name"
        label="Bank Name"
        placeholder="Bank of America"
        ref={register({ required: true })}
        required
      />
      <AccountNumberField required />
      <TextField
        name="bankAccount.aba"
        label="ABA Number (Routing Number)"
        placeholder="123456789"
        ref={register({ required: true })}
        required
      />
    </>
  );
};

const EUBankFields = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        name="bankAccount.name"
        label="Bank Name"
        placeholder="Deutsche Bank"
        ref={register({ required: true })}
        required
      />
      <BankCodeField placeholder="DEUTDEMM760" required />
      <TextField
        name="bankAccount.iban"
        label="IBAN"
        placeholder="DE89370400440532013000"
        ref={register({ required: true, pattern: { value: IBAN_REGEX, message: 'Invalid Value' } })}
      />
    </>
  );
};

const PHBankFields = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        name="bankAccount.name"
        label="Bank Name"
        placeholder="Bank of the Philippine Islands"
        ref={register({ required: true })}
        required
      />
      <AccountNumberField required />
      <BankCodeField placeholder="BOPIPHMMXXX" required />
    </>
  );
};

const AUBankFields = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        name="bankAccount.name"
        label="Bank Name"
        placeholder="National Australia Bank"
        ref={register({ required: true })}
        required
      />
      <AccountNumberField required />
      <TextField
        name="bankAccount.bsbCode"
        label="BSB Code"
        placeholder="123456"
        ref={register({ required: true, pattern: { value: BSB_CODE_REGEX, message: 'Must be 6 digits' } })}
        required
      />
    </>
  );
};

const DKBankFields = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        name="bankAccount.name"
        label="Bank Name"
        placeholder="Danske Bank"
        ref={register({ required: true })}
        required
      />
      <AccountNumberField required />
      <TextField
        name="bankAccount.bankCode"
        label="Code"
        placeholder="0040"
        ref={register({ required: true, pattern: { value: DKK_CODE_REGEX, message: 'Must be 4 digits' } })}
        required
      />
    </>
  );
};

const InBankFields = () => {
  const { register } = useFormContext();

  return (
    <>
      <TextField
        name="bankAccount.name"
        label="Bank Name"
        placeholder="State Bank of India"
        ref={register({ required: true })}
        required
      />
      <AccountNumberField required />
      <span className="tw-text-neutral-grey-1 tw-text-sm">
        Note: Account number length depends on payment amount (Up to ₹200,000: 10-20 digits; Over ₹200,000: 1-20 digits)
      </span>
      <TextField
        name="bankAccount.ifsc"
        label="Indian Financial System Code (IFSC)"
        placeholder="SBIN0070837"
        ref={register({ required: true, validate: validateINRBankCode })}
        required
      />
    </>
  );
};

const BankCodeField = ({ placeholder, required = false }) => {
  const { register } = useFormContext();

  return (
    <TextField
      name="bankAccount.code"
      label="Bank Code (SWIFT/BIC)"
      placeholder={placeholder}
      ref={register({ required, pattern: { value: BIC_SWIFT_REGEX, message: 'Invalid Value' } })}
      required={required}
    />
  );
};

const AccountNumberField = ({ required = false }) => {
  const { register, setValue } = useFormContext();

  const handleChange = (e) => {
    setValue('bankAccount.number', sanitizeAccountNumber(e.target.value));
  };

  return (
    <TextField
      name="bankAccount.number"
      label="Account Number"
      placeholder="00000123456789"
      ref={register({
        required: true,
        validate: validateAccountNumber,
      })}
      onChange={handleChange}
      required={required}
    />
  );
};

export default BankingFields;
