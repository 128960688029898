import React from 'react';

import CardsWaitingRoom from 'components/svg/CardsWaitingRoom';

const PendingBankAccountVerificationBanner = ({ title }) => {
  return (
    <div className="tw-m-auto tw-bg-neutral-light tw-py-10 tw-px-6 tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-md">
      <div className="lg:tw-block">
        <CardsWaitingRoom height="200" width="100%" />
      </div>
      <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-my-5 tw-w-96 tw-px-12">
        <span className="tw-text-2xl tw-my-4">{title}</span>
        <p>
          Your bank account is being verified. Please check your email for a message from Loop with further
          instructions. As soon as it’s verified, you'll be able to create your cards.
        </p>
      </div>
    </div>
  );
};

export default PendingBankAccountVerificationBanner;
