import React from 'react';

import Button from 'components/Button';
import { SubmitButton } from 'components/FormFields/v2';
import { FormFooterProps } from './FormFooter.types';

const FormFooter = ({ onCancel, onSubmit, submitLabel, loading }: FormFooterProps) => (
  <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex tw-justify-between">
    <Button isDisabled={loading} secondary onClick={onCancel}>
      Back
    </Button>
    <SubmitButton isDisabled={loading} onClick={onSubmit}>
      {loading ? 'Submitting...' : submitLabel}
    </SubmitButton>
  </div>
);

export default FormFooter;
