import React from 'react';
import CurrencyFlag from 'components/svg/CurrencyFlag';

export const Currency = ({ currency }) => (
  <div className="tw-flex tw-items-center">
    <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
      <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
        <CurrencyFlag currency={currency} />
      </div>
    </div>
    <span>{currency}</span>
  </div>
);
