import React from 'react';
import { BsSend, BsCurrencyExchange } from 'react-icons/bs';
import { ampTrackEvent } from 'amplitude';

import { User } from 'types/user';
import { TopDropdownNavigation, SidebarItem, TabNavItem } from 'components/UI/Layout/Layout.types';
import { TRANSACTION_PRODUCT_TYPES } from 'constants/index';
import SettingsOutline from 'components/svg/SettingsOutline';
import GridOutline from 'components/svg/GridOutline';
import CardOutline from 'components/svg/CardOutline';
import Bank from 'components/svg/Bank';
import CashOutline from 'components/svg/CashOutline';
import RepeatOutline from 'components/svg/RepeatOutline';
import GiftOutline from 'components/svg/GiftOutline';
import SidebarLightningOutline from 'components/svg/SidebarLightningOutline';
import DocumentTextOutline from 'components/svg/DocumentTextOutline';
import CoinStack from 'components/svg/CoinStack';
import LockClosedOutline from 'components/svg/LockClosedOutline';
import PersonOutline from 'components/svg/PersonOutline';
import CheckReceiptOutline from 'components/svg/CheckReceiptOutline';

export const getMemberSidebarItems = ({
  hasCreditCard,
  createPayeePayment,
  viewPayeePayments,
  isApprovalsEnabled,
  viewPayors,
  managePayors,
  viewAccountBalances,
  createPayee,
  manageRewards,
}: {
  hasCreditCard: boolean;
  createPayeePayment: boolean;
  viewPayeePayments: boolean;
  isApprovalsEnabled: boolean;
  viewPayors: boolean;
  managePayors: boolean;
  viewAccountBalances: boolean;
  createPayee: boolean;
  manageRewards: boolean;
}): SidebarItem[] => {
  const items = [
    {
      route: '/dashboard/cards/list',
      Icon: CardOutline,
      label: 'Cards',
    },
    viewAccountBalances && {
      route: '/dashboard/accounts',
      Icon: Bank,
      label: 'Accounts',
    },
    (createPayeePayment || viewPayeePayments || createPayee) && {
      route: '/dashboard/payments/payees',
      Icon: CashOutline,
      label: 'Send Payments',
    },
    hasCreditCard && {
      route: '/dashboard/transactions',
      subRoutes: ['/dashboard/transactions\\d*'],
      Icon: RepeatOutline,
      label: 'Transactions',
      childItems: [
        hasCreditCard && {
          label: 'Cards',
          route: `/dashboard/transactions?productType=${TRANSACTION_PRODUCT_TYPES.creditCard}`,
        },
      ].filter(Boolean) as { label: string; route: string }[],
    },
    manageRewards && {
      route: '/dashboard/rewards',
      Icon: GiftOutline,
      label: 'Rewards & Offers',
    },
    isApprovalsEnabled && {
      route: '/dashboard/approvals',
      Icon: CheckReceiptOutline,
      label: 'Approvals',
    },
    (viewPayors || managePayors) && {
      route: '/dashboard/invoices',
      Icon: DocumentTextOutline,
      label: 'Request Payments',
    },
  ];

  return items.filter(Boolean) as SidebarItem[];
};

export const getBottomSidebarItems = ({
  planName = 'Upgrade',
  smsMfaEnabled,
  isPaidSubscription,
}: {
  planName?: string;
  smsMfaEnabled: boolean;
  isPaidSubscription: boolean;
}): SidebarItem[] => {
  const items = [
    !isPaidSubscription && {
      route: '/fx-compare',
      Icon: BsCurrencyExchange,
      label: 'Compare FX Rates',
    },
    !smsMfaEnabled && {
      route: '/dashboard/settings/mfa',
      Icon: LockClosedOutline,
      label: '2-Step Authentication',
    },
    {
      route: isPaidSubscription ? '/dashboard/subscriptions/active' : '/dashboard/subscriptions',
      subRoutes: ['/dashboard/subscriptions\\d*'],
      Icon: SidebarLightningOutline,
      label: planName ? planName : 'Upgrade',
    },
    {
      route: '/dashboard/settings/personal',
      subRoutes: ['/dashboard/settings\\d*', '/bank-accounts/verify'],
      Icon: SettingsOutline,
      label: 'Settings',
    },
    {
      callToActionLabel: 'Refer and Earn $50',
      route: '/dashboard/rewards?tab=refer-and-earn',
      label: 'Refer and Earn $50',
      Icon: GridOutline, //this icon is not used, just making sure we satisfy the Icon type
      onClick: () => {
        ampTrackEvent('Refer and Earn $50: Clicked');
      },
    },
  ];

  return items.filter(Boolean) as SidebarItem[];
};

export const getSidebarItems = ({
  hasLineOfCredit,
  canManagePayments,
  createPayeePayment,
  hasCreditCard = true,
  isPreFunded = false,
  isApprovalsEnabled,
  viewPayors,
  managePayors,
  createPayee,
  viewPayeePayments,
  manageRewards,
}: {
  hasLineOfCredit: boolean;
  canManagePayments: boolean;
  createPayeePayment: boolean;
  hasCreditCard?: boolean;
  isPreFunded?: boolean;
  isApprovalsEnabled: boolean;
  viewPayors: boolean;
  managePayors: boolean;
  createPayee: boolean;
  viewPayeePayments: boolean;
  manageRewards: boolean;
}): SidebarItem[] => {
  const items: SidebarItem[] = [
    {
      route: '/dashboard/home',
      Icon: GridOutline,
      label: 'Home',
    },
    {
      route: '/dashboard/cards',
      redirectRoute: '/dashboard/cards/balances',
      subRoutes: ['/dashboard/cards\\.*'],
      Icon: CardOutline,
      label: 'Cards',
      childItems: [
        {
          label: isPreFunded ? 'Balances' : 'Balances and Limit',
          route: '/dashboard/cards/balances',
        },
        {
          label: 'View Cards',
          route: '/dashboard/cards/list',
        },
      ],
    },
    hasLineOfCredit && {
      route: '/dashboard/line-of-credit',
      Icon: CoinStack,
      label: 'Capital',
    },
    {
      route: '/dashboard/accounts',
      Icon: Bank,
      label: 'Accounts',
    },
    (canManagePayments || createPayeePayment || createPayee || viewPayeePayments) && {
      route: '/dashboard/payments/payees',
      Icon: BsSend,
      label: 'Send Payments',
    },
    (viewPayors || managePayors) && {
      route: '/dashboard/invoices',
      Icon: DocumentTextOutline,
      label: 'Request Payments',
    },
    {
      route: '/dashboard/transactions',
      subRoutes: ['/dashboard/transactions\\d*'],
      Icon: RepeatOutline,
      label: 'Transactions',
      childItems: [
        {
          label: 'Cards',
          route: `/dashboard/transactions?productType=${TRANSACTION_PRODUCT_TYPES.creditCard}`,
        },
        {
          label: 'Accounts',
          route: `/dashboard/transactions?productType=${TRANSACTION_PRODUCT_TYPES.wallet}`,
        },
        hasLineOfCredit && {
          label: 'Capital',
          route: `/dashboard/transactions?productType=${TRANSACTION_PRODUCT_TYPES.lineOfCredit}`,
        },
      ].filter(Boolean) as { label: string; route: string }[],
    },
    manageRewards && {
      route: '/dashboard/rewards',
      Icon: GiftOutline,
      label: 'Rewards & Offers',
    },
    isApprovalsEnabled && {
      route: '/dashboard/approvals',
      Icon: CheckReceiptOutline,
      label: 'Approvals',
    },
  ].filter(Boolean) as SidebarItem[];

  if (!hasCreditCard) {
    const labels = ['Rewards & Offers', 'Transactions', 'Payments', 'Accounts'];
    const filteredItems = items.filter((item) => !labels.includes(item.label));
    return filteredItems;
  }
  return items;
};

export const getTabNavItems = ({
  canManagePayments,
  createPayeePayment,
  createPayee,
}: {
  canManagePayments: boolean;
  createPayeePayment: boolean;
  createPayee: boolean;
}): TabNavItem[] => {
  const tabs: TabNavItem[] = [
    {
      route: '/dashboard/home',
      Icon: GridOutline,
      label: 'Dashboard',
    },
    {
      route: '/dashboard/transactions/splash',
      subRoutes: ['/dashboard/transactions', '/dashboard/transactions\\d*'],
      Icon: RepeatOutline,
      label: 'Transactions',
    },
  ];

  if (createPayeePayment || canManagePayments || createPayee) {
    tabs.push({
      route: '/dashboard/payments/payees',
      Icon: CashOutline,
      label: 'Payments',
    });
  }

  return tabs;
};

export const getTopDropdownItems = ({
  accounts,
  activeAccountId,
  selectAccount,
}: { selectAccount: (id: string) => void } & Pick<User, 'accounts' | 'activeAccountId'>) => {
  const items: TopDropdownNavigation = [
    {
      name: 'Account Settings',
      id: '/dashboard/settings/personal',
      link: '/dashboard/settings/personal',
      Icon: SettingsOutline,
    },
  ];

  if (accounts?.length) {
    const accountItems = accounts.map(({ id, name }) => {
      const itemName = (
        <>
          <small className="tw-font-small tw-font-semibold">{name}</small>
          {activeAccountId === id && <small className="tw-font-small tw-text-neutral-grey-2">(Active)</small>}
        </>
      );

      const handleSelectAccount = () => selectAccount(id);
      return {
        name: itemName,
        id,
        action: handleSelectAccount,
        Icon: PersonOutline,
      };
    });

    items.push(...accountItems);
  }

  return items;
};
