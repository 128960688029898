import React from 'react';

import InfoTooltip from '../InfoTooltip';
import { formatMoneyV2 } from '../../utility/currency';

const AvailableToSpend = ({ lineOfCredit }) => {
  const amount = lineOfCredit && lineOfCredit.availableCredit;

  return (
    <div className="tw-flex tw-flex-col lg:tw-flex-row">
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-1 lg:tw-mb-0 lg:tw-justify-start lg:tw-w-44">
        <small className="tw-mr-1">Available to Spend</small>
        <InfoTooltip
          testId="loc-available-to-spend"
          message="The total amount that you currently have available to spend on your line of credit."
          mobileOrientation="right"
        />
      </div>
      <div className="tw-flex tw-items-end">
        <span className="tw-text-2xl tw-mr-2 tw-font-semibold">{formatMoneyV2(amount)}</span>
      </div>
    </div>
  );
};

export default AvailableToSpend;
