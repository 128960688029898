import React from 'react';
import cx from 'classnames';

import { ActionItemProps } from './ActionItem.types';

import styles from './ActionItem.module.scss';

const ActionItem = ({
  title,
  Icon,
  onClick,
  className = 'tw-bg-neutral-grey-5 tw-text-neutral-dark',
  actionDisabled = false,
}: ActionItemProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(
        'tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1',
        actionDisabled ? 'tw-pointer-events-none ' : styles.button
      )}
      disabled={actionDisabled}
    >
      <div
        className={cx(
          'tw-flex tw-items-center tw-justify-center tw-rounded-full tw-w-16 tw-h-16',
          actionDisabled ? 'tw-bg-neutral-grey-4 tw-text-neutral-grey-2' : styles.icon,
          className
        )}
      >
        <Icon size={36} />
      </div>
      <div className="tw-font-bold tw-text-sm">{title}</div>
    </button>
  );
};

export default ActionItem;
