import React, { useState, useCallback, createContext, useEffect, MouseEventHandler } from 'react';

import { DatePeriod } from 'components/UI/PeriodDropdown/PeriodDropdown.types';

type FilterContextType = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  numPerPage: number;
  setNumPerPage: React.Dispatch<React.SetStateAction<number>>;
  period: DatePeriod | null;
  setPeriod: React.Dispatch<React.SetStateAction<DatePeriod | null>>;
  handleClearFilters: MouseEventHandler<HTMLDivElement>;
  hasDropdownFilters: boolean;
  handleClearPage: MouseEventHandler<HTMLDivElement>;
  resetFilters: (e: number) => void;
};

// Filter Context
export const FilterContext = createContext<FilterContextType>({} as FilterContextType);
const INITIAL_PAGE = 0;
const INITIAL_NUM_PER_PAGE = 5;

export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  const [period, setPeriod] = useState<DatePeriod | null>(null);
  const [page, setPage] = useState(INITIAL_PAGE);
  const [numPerPage, setNumPerPage] = useState(INITIAL_NUM_PER_PAGE);

  const handleClearFilters = useCallback(() => {
    setPeriod(null);
  }, []);

  const handleClearPage = useCallback(() => {
    setPage(INITIAL_PAGE);
    setNumPerPage(INITIAL_NUM_PER_PAGE);
  }, []);

  const resetFilters = useCallback(() => {
    handleClearFilters();
    handleClearPage();
  }, [handleClearFilters, handleClearPage]);

  useEffect(() => {
    resetFilters();
  }, [resetFilters]);

  const hasDropdownFilters = !!period;

  return (
    <FilterContext.Provider
      value={{
        page,
        setPage,
        numPerPage,
        setNumPerPage,
        period,
        setPeriod,
        handleClearFilters,
        hasDropdownFilters,
        handleClearPage,
        resetFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
