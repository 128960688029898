import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useIsMember from 'hooks/useIsMember';
import useGetProductState from 'hooks/useGetProductState';
import { useGetCreditCardsState, useGetBankAccountsState } from 'hooks';

const useCardBalances = () => {
  const {
    isLoading: isLoadingCardsState,
    isError: isErrorFetchingCardsState,
    noCreditCards,
  } = useGetCreditCardsState();

  const {
    isLoading: isLoadingBankAccountsState,
    isError: isErrorFetchingBankAccountsState,
    hasBankAccounts,
    hasAtLeastOneBankAccountVerified,
  } = useGetBankAccountsState();

  const { isLoading: meLoading, isPreFunded } = useGetProductState();

  const { isMember } = useIsMember();
  const history = useHistory();

  const goBack = () => history.push('/dashboard/cards/splash');

  const pageTitle = isPreFunded ? 'Balances' : 'Balances and Limit';

  useEffect(() => {
    if (isMember) history.replace('/dashboard/cards/list');
  }, [isMember]);

  return {
    pageTitle,
    isError: isErrorFetchingCardsState || isErrorFetchingBankAccountsState,
    isLoading: isLoadingCardsState || isLoadingBankAccountsState || meLoading,
    noCreditCards,
    hasBankAccounts,
    hasAtLeastOneBankAccountVerified,
    isPreFunded,
    goBack,
  };
};

export default useCardBalances;
