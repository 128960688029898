import { useContext, useCallback } from 'react';
import { ampTrackEvent } from 'amplitude';

import { formatMoneyV2 } from 'utility/currency';
import { AddFundsToBalanceContext } from 'components/creditCards/context/AddFundsToBalance';
import { DEFAULT_ADD_FUNDS_AMOUNT } from '../constants';
import { CurrentBalanceCardProps } from '../CurrentBalanceCard.types';

const useCurrentBalanceCard = ({
  current,
  pending,
  posted,
  ongoingPayments,
  openAddFundsToBalanceModal,
  isAdmin,
}: Omit<CurrentBalanceCardProps, 'isPreFunded'>) => {
  const { setTransaction } = useContext(AddFundsToBalanceContext);

  const { currency } = current;

  const formattedBalance = formatMoneyV2(current);
  const formattedPending = pending ? formatMoneyV2(pending) : '';
  const formattedPosted = posted ? formatMoneyV2(posted) : '';
  const formattedOngoingPayments = ongoingPayments ? formatMoneyV2(ongoingPayments) : '';

  const handleOpenAddFundsToBalanceModal = useCallback(() => {
    if (!isAdmin) return;

    setTransaction({
      originalAmount: {
        amount: DEFAULT_ADD_FUNDS_AMOUNT,
        currency,
      },
    });
    openAddFundsToBalanceModal();
    ampTrackEvent('payBalance: customAmount: open modal');
  }, [isAdmin, currency, openAddFundsToBalanceModal]);

  return {
    currency,
    formattedBalance,
    formattedPending,
    formattedPosted,
    formattedOngoingPayments,
    handleOpenAddFundsToBalanceModal,
  };
};

export default useCurrentBalanceCard;
