import React from 'react';

import config from 'config';

const LOCAL_STORAGE_KEY = 'pre-approval';

const Square = ({ isPreApproval, className = 'w-50' }) => {
  const clientId = config.squareClientId;
  const baseUrl = config.squareBaseSite;
  const scopes =
    'ORDERS_READ+PAYOUTS_READ+PAYMENTS_READ+INVENTORY_READ+INVOICES_READ+MERCHANT_PROFILE_READ+ITEMS_READ+ONLINE_STORE_SITE_READ';
  const authUrl = `https://connect.${baseUrl}.com/oauth2/authorize?client_id=${clientId}&scope=${scopes}&session=false`;

  const handleClick = () => {
    if (isPreApproval) {
      window.localStorage.setItem(LOCAL_STORAGE_KEY, true);
    } else {
      window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    }

    window.location.href = authUrl;
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center" onClick={handleClick}>
      <img className={className} src="https://loop-public.s3.ca-central-1.amazonaws.com/square-logo.png" />
    </div>
  );
};

export default Square;
