import React from 'react';

const TableSkeleton = () => {
  const COLUMNS = 5;
  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-mb-8">
      <table className="tw-table tw-table-auto tw-w-full">
        <tbody>
          {Array(COLUMNS)
            .fill(0)
            .map((_, i) => (
              <tr key={i}>
                <th className="tw-p-3 tw-w-full" colSpan={COLUMNS}>
                  <div className="tw-flex tw-w-full tw-animate-pulse tw-bg-neutral-grey-4 tw-h-12 tw-rounded" />
                </th>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
