import React, { memo } from 'react';
import { IoLogoLinkedin } from 'react-icons/io5';

import config from 'config';
import SocialShareButton from '../../SocialShareButton';
import { PostContent, SocialShareType } from '../../SocialShareButton.types';

const LinkedinShareButton = ({
  url,
  text,
  title,
  source = config.landingPageUrl,
}: Pick<PostContent, 'url' | 'text' | 'title' | 'source'>) => {
  const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}${
    title ? `&title=${encodeURIComponent(title)}` : ''
  }${text ? `summary=${encodeURIComponent(text)}` : ''}${source ? `&source=${encodeURIComponent(source)}` : ''}`;

  return (
    <SocialShareButton postUrl={linkedInUrl} type={SocialShareType.Linkedin}>
      <IoLogoLinkedin />
    </SocialShareButton>
  );
};

export default memo(LinkedinShareButton);
