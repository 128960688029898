import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from 'context/Auth';
import { AiOutlineBank } from 'react-icons/ai';
import { BsCurrencyExchange } from 'react-icons/bs';

import { SCOPE } from 'constants/index';
import useIsMobile from 'hooks/useIsMobile';
import LockClosedOutline from 'components/svg/LockClosedOutline';
import CardOutline from 'components/svg/CardOutline';
import { CreateCardContextProvider } from 'components/creditCards/createCardFlow/CreateCardContext';
import CreateCardModal from 'components/creditCards/createCardFlow/CreateCardModal';
import Button, { ButtonSize } from 'components/Button';
import CashOutline from 'components/svg/CashOutline';
import PermissionChecker from 'components/PermissionChecker';
import { useGetSubscription } from 'hooks/';

const QuickActions = (props) => {
  const { me, meLoading } = useContext(AuthContext);
  const mfaEnabled = me?.totpMfaEnabled || me?.smsMfaEnabled;
  return (
    <div data-testid="quick-action-container" className="tw-flex tw-flex-col tw-px-4 lg:tw-px-0">
      <p className="tw-mb-4 tw-text-neutral-grey-1">Quick Actions</p>
      {meLoading ? <Skeleton /> : <Actions mfaEnabled={mfaEnabled} {...props} />}
    </div>
  );
};

const Actions = ({
  creditCards,
  creditCardLoading,
  globalCreditLimit,
  allowCardCreation,
  mfaEnabled,
  groupedCardsInfo,
  connectedBankAccount,
}) => {
  const [show, setShow] = useState(false);
  const isMobile = useIsMobile();
  const { isPaidSubscription } = useGetSubscription();

  const paymentDate = groupedCardsInfo && groupedCardsInfo.paymentDate;
  const openCreateCardModal = () => setShow(true);
  const closeCreateCardModal = () => setShow(false);

  return (
    <>
      {!mfaEnabled && (
        <Link
          to="/dashboard/settings/personal"
          className="tw-flex tw-items-center tw-bg-neutral-grey-5 tw-mb-3 tw-rounded tw-py-3 tw-px-2"
        >
          <LockClosedOutline className="tw-ml-1 tw-mr-3" />
          <small className="tw-mr-1">Enable Two-Step Authentication</small>
        </Link>
      )}
      {!connectedBankAccount && !isMobile && (
        <PermissionChecker scope={SCOPE.manageBankAccounts}>
          <Link
            to="/dashboard/settings/bank-accounts-settings"
            className="tw-flex tw-items-center tw-bg-neutral-grey-5 tw-mb-3 tw-rounded tw-py-3 tw-px-2"
          >
            <AiOutlineBank className="tw-text-primary-dark-green tw-ml-1 tw-mr-3" size={20} />
            <small className="tw-mr-1">Connect Bank Account</small>
          </Link>
        </PermissionChecker>
      )}
      {allowCardCreation && (
        <PermissionChecker scope={SCOPE.manageCards}>
          <Button
            disabled={creditCardLoading}
            className="tw-flex tw-items-center tw-bg-neutral-grey-5 tw-mb-3 tw-rounded tw-py-3 tw-px-2"
            onClick={openCreateCardModal}
            size={ButtonSize.text}
          >
            <CardOutline className="tw-text-primary-blue tw-ml-1 tw-mr-3" />
            <small className="tw-mr-1">Create New Card</small>
          </Button>
        </PermissionChecker>
      )}
      {paymentDate && (
        <PermissionChecker scope={SCOPE.managePayments}>
          <Link
            to="/dashboard/payments/pay-balance"
            className="tw-flex tw-items-center tw-bg-neutral-grey-5 tw-mb-3 tw-rounded tw-py-3 tw-px-2 hover:tw-text-neutral-dark"
          >
            <CashOutline className="tw-ml-1 tw-mr-3" />
            <small className="tw-mr-1">Pay Card Balance</small>
          </Link>
        </PermissionChecker>
      )}
      <CreateCardContextProvider>
        <CreateCardModal
          show={show}
          onClose={closeCreateCardModal}
          creditCards={creditCards}
          globalCreditLimit={globalCreditLimit}
        />
      </CreateCardContextProvider>
      {!isPaidSubscription && (
        <Link
          to="/fx-compare"
          className="tw-flex tw-items-center tw-bg-neutral-grey-5 tw-mb-3 tw-rounded tw-py-3 tw-px-2"
        >
          <BsCurrencyExchange className="tw-text-primary-dark-green tw-ml-1 tw-mr-3" size={20} />
          <small className="tw-mr-1">Compare FX Rates</small>
        </Link>
      )}
    </>
  );
};

const Skeleton = () => (
  <>
    <div className="tw-flex tw-items-center tw-bg-neutral-grey-4 tw-h-8 tw-w-full tw-animate-pulse tw-mb-3 tw-rounded tw-py-3 tw-px-2" />
    <div className="tw-flex tw-items-center tw-bg-neutral-grey-4 tw-h-8 tw-w-full tw-animate-pulse tw-mb-3 tw-rounded tw-py-3 tw-px-2" />
    <div className="tw-flex tw-items-center tw-bg-neutral-grey-4 tw-h-8 tw-w-full tw-animate-pulse tw-mb-3 tw-rounded tw-py-3 tw-px-2" />
  </>
);

export default QuickActions;
