import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ADD_TRANSACTION_NOTE_RECEIPT, GET_TRANSACTIONS_PAGE } from 'graphql/transactions';
import { GET_CARD_EXPENSES } from 'graphql/cardApprovals';
import { GET_COMPLETED_EXPENSES } from 'graphql/completedExpenses';
import {
  isCardExpenseRequestDataType,
  isCompletedExpenseRequestDataType,
} from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';

const useNoteReceiptForm = ({ transaction, overwriteReceipt }) => {
  const form = useForm();
  const { register, handleSubmit, setValue, watch } = form;

  const isCardExpenseType = isCardExpenseRequestDataType(transaction);
  const receipt = isCardExpenseType ? transaction.receipt : transaction.transactionReceipt;
  const transactionNote = isCardExpenseType ? null : transaction.transactionNote;

  const refetchQueries = [GET_TRANSACTIONS_PAGE];
  if (isCardExpenseRequestDataType(transaction)) {
    refetchQueries.push(GET_CARD_EXPENSES);
  }
  if (isCompletedExpenseRequestDataType(transaction)) {
    refetchQueries.push(GET_COMPLETED_EXPENSES);
  }

  const [addNoteReceipt, { loading: isSubmitting, error: submitError }] = useMutation(ADD_TRANSACTION_NOTE_RECEIPT, {
    refetchQueries,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState();

  const setReceipt = (receipt) => {
    setSelectedReceipt(receipt);
    setValue('blobSignedId', receipt.blobId);
    setShowReceiptModal(false);
  };

  const onSubmit = async (params) => {
    try {
      params.financialTransactionId = transaction.transactionId.toString();
      params.note = params.notes;
      if (selectedReceipt) {
        params.blobSignedId = selectedReceipt.blobId;
        params.unmatchedReceiptId = selectedReceipt.id;
      } else {
        params.blobSignedId = params.blobSignedId || undefined;
      }

      const {
        data: { addTransactionNoteReceipt },
      } = await addNoteReceipt({ variables: { ...params } });

      if (params.note || params.blobSignedId) setIsSubmitted(true);

      if (addTransactionNoteReceipt) {
        toast.success(
          'Successfully posted ' +
            (params.note ? 'Note' : '') +
            (params.note && !!params.blobSignedId ? ' and ' : '') +
            (params.blobSignedId ? 'Receipt' : ''),
          {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
    } catch (err) {
      console.error(err);
    }
    toast.error('Could not post', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleOnFileUpload = (file) => {
    setValue('blobSignedId', file && file.blob.signed_id);
  };

  const showSubmitButtonBlock =
    !isSubmitted && (watch('blobSignedId', false) || watch('notes', false) || selectedReceipt);
  const showUploadBlock = (!receipt || overwriteReceipt) && !selectedReceipt;

  return {
    form,
    submitError,
    register,
    handleSubmit,
    onSubmit,
    isSubmitting,
    showUploadBlock,
    showSubmitButtonBlock,
    selectedReceipt,
    setShowReceiptModal,
    setReceipt,
    showReceiptModal,
    handleOnFileUpload,
    isCardExpenseType,
    receipt,
    transactionNote,
  };
};

export default useNoteReceiptForm;
