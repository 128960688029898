import React, { memo, useCallback } from 'react';

import { SearchDropdownV2 } from 'components/UI';
import { MembersProps } from './Members.types';
import { getCustomOptionBarItemLabel, getCustomOptionLabel } from './Members.utils';
import { Option } from 'components/UI/SearchDropdown/v2/SearchDropdown.types';

const Members = ({ memberOptions, selectedMembers, onSelectOptions, isLoading }: MembersProps) => {
  const handleCustomOptionLabel = useCallback(
    (label: string) => {
      const currentContactRole = memberOptions.find((option) => option.label === label)?.roleLabel;

      return getCustomOptionLabel(label, currentContactRole);
    },
    [memberOptions]
  );

  return (
    <div>
      <label className="tw-text-sm tw-text-primary-dark-green">Add Team Members</label>
      <SearchDropdownV2
        optionsList={memberOptions}
        selectedOptions={selectedMembers}
        onSelectOptions={onSelectOptions as (options: Option | Option[] | null) => void}
        customOptionLabel={handleCustomOptionLabel}
        multiple
        showSelectedOptionsBar
        customOptionBarItemLabel={getCustomOptionBarItemLabel}
        isLoading={isLoading}
      />
    </div>
  );
};

export default memo(Members);
