import React from 'react';

import Button from 'components/Button';
import { SubmitButton } from 'components/FormFields/v2';
import { FormFooterProps } from './FormFooter.types';

const FormFooter = ({
  onCancel,
  onSubmit,
  cancelButtonLabel = 'Back',
  submitButtonLabel = 'Submit',
  loading,
  isDisabled,
}: FormFooterProps) => (
  <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex tw-justify-between">
    <Button isDisabled={loading} secondary onClick={onCancel}>
      {cancelButtonLabel}
    </Button>
    <SubmitButton isDisabled={loading || isDisabled} onClick={onSubmit}>
      {loading ? 'Submitting...' : submitButtonLabel}
    </SubmitButton>
  </div>
);

export default FormFooter;
