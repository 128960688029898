import React, { createContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_CARD_RECURRING_PAYMENT, GET_CARD_RECURRING_PAYMENT_INFO } from 'graphql/recurringPayment';
import { BankAccount } from 'types/bankAccount';
import { Wallet } from 'types/wallet';
import { PayBalanceCurrencyOptions, PayBalanceType } from 'types/payments';
import { CardRecurringPayment } from 'types/cardRecurringPayment';

type CardAutopayContextType = {
  fromAccountId: string | null;
  setFromAccountId: (fromAccountId: string | null) => void;
  bankAccounts: BankAccount[];
  setBankAccounts: (bankAccounts: BankAccount[]) => void;
  wallets: Wallet[];
  setWallets: (wallets: Wallet[]) => void;
  paymentCurrency: PayBalanceCurrencyOptions | undefined;
  setPaymentCurrency: (paymentCurrency?: PayBalanceCurrencyOptions) => void;
  paymentType: PayBalanceType | undefined;
  setPaymentType: (paymentType?: PayBalanceType) => void;
  recurringPayment: CardRecurringPayment | null;
  loadingRecurringPaymentData?: boolean;
  chargeNow: boolean;
  loadingChargeNowData?: boolean;
};

export const CardAutopayContext = createContext<CardAutopayContextType>({} as CardAutopayContextType);

export const CardAutopayContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [fromAccountId, setFromAccountId] = useState<string | null>(null);
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [paymentCurrency, setPaymentCurrency] = useState<PayBalanceCurrencyOptions>();
  const [paymentType, setPaymentType] = useState<PayBalanceType>();
  const [recurringPayment, setRecurringPayment] = useState<CardRecurringPayment | null>(null);
  const [chargeNow, setChargeNow] = useState(false);

  const { loading: loadingRecurringPaymentData } = useQuery(GET_CARD_RECURRING_PAYMENT, {
    onCompleted: (data) => {
      setRecurringPayment(get(data, 'cardRecurringPayment', null));
    },
  });
  const { loading: loadingChargeNowData } = useQuery(GET_CARD_RECURRING_PAYMENT_INFO, {
    onCompleted: (data) => {
      setChargeNow(data.cardRecurringPaymentInfo.chargeNow);
    },
  });

  return (
    <CardAutopayContext.Provider
      value={{
        fromAccountId,
        setFromAccountId,
        wallets,
        setWallets,
        bankAccounts,
        setBankAccounts,
        paymentCurrency,
        setPaymentCurrency,
        paymentType,
        setPaymentType,
        recurringPayment,
        loadingRecurringPaymentData,
        chargeNow,
        loadingChargeNowData,
      }}
    >
      {children}
    </CardAutopayContext.Provider>
  );
};
