import { ALPHANUMERIC_REGEX } from 'constants/regex';

export const errorMessages = {
  ALPHANUMERIC: 'Account number only allows alphanumeric characters',
};

const validateAlphanumericAccountNumber = (value: string) => {
  const isValid = ALPHANUMERIC_REGEX.test(value);

  return isValid || errorMessages.ALPHANUMERIC;
};

export default validateAlphanumericAccountNumber;
