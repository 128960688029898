export enum APPROVAL_ACTION_TYPE {
  cancel = 'cancel',
  approve = 'approve',
  decline = 'decline',
  approveAndPay = 'approve and pay',
}

export enum APPROVAL_REQUEST_STATUS {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  paid = 'paid',
  cancelled = 'cancelled',
}

export enum APPROVAL_TAB {
  expenseReimbursements = 'Expense Reimbursements',
  cardExpenses = 'Card Expenses',
  completed = 'Completed',
}

export enum APPROVAL_TYPE {
  reimbursement = 'ExpenseReimbursement',
  cardExpense = 'CardExpense',
  expense = 'Expense',
}
export enum APPROVAL_MODAL {
  cancelReimbursement = 'cancelReimbursement',
  approveReimbursement = 'approveReimbursement',
  declineReimbursement = 'declineReimbursement',
  approveAndPayReimbursement = 'approveAndPayReimbursement',
  declineCardExpense = 'declineCardExpense',
}

export const HEADERS = [
  'type',
  'requester',
  'approver',
  'requested_amount',
  'requested_amount_currency',
  'original_amount',
  'original_amount_currency',
  'tax_amount',
  'from',
  'merchant_name',
  'request_description',
  'date_requested',
  'status',
  'date_approved',
];

export const INITIAL_PAGE = 0;
export const INITIAL_NUM_PER_PAGE = 5;
