import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const InfoTip2 = ({ size = '86', ...other }) => {
  const width = size;
  const height = (79 * width) / 86;

  return (
    <svg width={width} height={height} viewBox="0 0 86 79" fill="none" {...other}>
      <path
        d="M20.0269 8.53607C20.2092 8.4131 20.4275 8.63147 20.3046 8.81372L16.2598 14.8084C16.2142 14.876 16.2142 14.9645 16.2598 15.0321L20.3046 21.0267C20.4275 21.209 20.2092 21.4273 20.0269 21.3044L14.0323 17.2596C13.9647 17.214 13.8761 17.214 13.8085 17.2596L7.81392 21.3044C7.63167 21.4273 7.41329 21.209 7.53626 21.0267L11.581 15.0321C11.6266 14.9645 11.6266 14.876 11.581 14.8083L7.53626 8.81372C7.41329 8.63147 7.63167 8.4131 7.81392 8.53607L13.8085 12.5808C13.8761 12.6264 13.9647 12.6264 14.0323 12.5808L20.0269 8.53607Z"
        fill="#D0E7F4"
      />
      <path
        d="M55.4342 9.62611C55.6353 9.71481 55.5669 10.016 55.3471 10.009L50.4752 9.85484C50.3937 9.85226 50.3188 9.89943 50.2859 9.97405L48.3193 14.4341C48.2306 14.6352 47.9294 14.5668 47.9364 14.347L48.0905 9.47513C48.0931 9.39362 48.046 9.31871 47.9713 9.28581L43.5113 7.31918C43.3102 7.23048 43.3786 6.92933 43.5983 6.93628L48.4703 7.09044C48.5518 7.09302 48.6267 7.04585 48.6596 6.97124L50.6262 2.51123C50.7149 2.31006 51.0161 2.3785 51.0091 2.59824L50.8549 7.47016C50.8524 7.55167 50.8995 7.62658 50.9742 7.65948L55.4342 9.62611Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M77.6464 57.076C77.8287 56.953 78.047 57.1714 77.9241 57.3536L73.515 63.8882C73.4694 63.9558 73.4694 64.0443 73.515 64.1119L77.9241 70.6465C78.047 70.8287 77.8287 71.0471 77.6464 70.9241L71.1119 66.5151C71.0443 66.4694 70.9557 66.4694 70.8881 66.5151L64.3536 70.9241C64.1713 71.0471 63.953 70.8287 64.0759 70.6465L68.485 64.1119C68.5306 64.0443 68.5306 63.9558 68.485 63.8882L64.0759 57.3536C63.953 57.1714 64.1713 56.953 64.3536 57.076L70.8881 61.485C70.9557 61.5307 71.0443 61.5307 71.1119 61.485L77.6464 57.076Z"
        fill="#EED9F7"
      />
      <path d="M17.5 48.75L20.5 51.75L23.5 48.75" stroke="#4F5154" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M20.5 51L20.5 37.2188C20.5 34.4644 22.8123 32.25 25.5625 32.25L26.5 32.25"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M60.5 48.25L57.5 45.25L54.5 48.25" stroke="#4F5154" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M57.5 46L57.5 59.7812C57.5 62.5356 55.1877 64.75 52.4375 64.75L51.5 64.75"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.4688 38.2813L63.5312 38.2812C64.1698 38.2812 64.6875 37.7636 64.6875 37.125V20.9375C64.6875 20.2989 64.1698 19.7812 63.5312 19.7812L33.4688 19.7813C32.8302 19.7813 32.3125 20.2989 32.3125 20.9375V37.125C32.3125 37.7636 32.8302 38.2813 33.4688 38.2813Z"
        fill="#FFE8C3"
      />
      <path
        d="M30.4688 41.2813L60.5312 41.2812C61.1698 41.2812 61.6875 40.7636 61.6875 40.125V23.9375C61.6875 23.2989 61.1698 22.7812 60.5312 22.7812L30.4687 22.7813C29.8302 22.7813 29.3125 23.2989 29.3125 23.9375V40.125C29.3125 40.7636 29.8302 41.2813 30.4688 41.2813Z"
        stroke="#4F5154"
        strokeLinejoin="round"
      />
      <path
        d="M45.5 37.8125C48.6929 37.8125 51.2812 35.2241 51.2812 32.0312C51.2812 28.8384 48.6929 26.25 45.5 26.25C42.3071 26.25 39.7188 28.8384 39.7188 32.0312C39.7188 35.2241 42.3071 37.8125 45.5 37.8125Z"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.6875 28.5625C60.1542 28.5625 58.6837 27.9534 57.5995 26.8692C56.5153 25.785 55.9062 24.3145 55.9062 22.7812"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.3125 28.5625C30.0717 28.5625 30.8235 28.413 31.5249 28.1224C32.2263 27.8319 32.8636 27.406 33.4005 26.8692C33.9373 26.3324 34.3631 25.6951 34.6537 24.9936C34.9442 24.2922 35.0938 23.5405 35.0938 22.7812"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.6875 35.5C60.1542 35.5 58.6837 36.1091 57.5995 37.1933C56.5153 38.2775 55.9062 39.748 55.9062 41.2812"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.3125 35.5C30.0717 35.5 30.8235 35.6495 31.5249 35.9401C32.2263 36.2306 32.8636 36.6565 33.4005 37.1933C33.9373 37.7301 34.3631 38.3674 34.6537 39.0689C34.9442 39.7703 35.0938 40.522 35.0938 41.2812"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4688 74.2812H47.5312C48.1698 74.2812 48.6875 73.7636 48.6875 73.125V56.9375C48.6875 56.2989 48.1698 55.7812 47.5312 55.7812H17.4687C16.8302 55.7812 16.3125 56.2989 16.3125 56.9375V73.125C16.3125 73.7636 16.8302 74.2812 17.4688 74.2812Z"
        stroke="#4F5154"
        strokeLinejoin="round"
      />
      <path
        d="M33.5 69.8125C36.6929 69.8125 39.2812 67.2241 39.2812 64.0312C39.2812 60.8384 36.6929 58.25 33.5 58.25C30.3071 58.25 27.7188 60.8384 27.7188 64.0312C27.7188 67.2241 30.3071 69.8125 33.5 69.8125Z"
        fill="#D0E7DA"
      />
      <path
        d="M32.5 70.8125C35.6929 70.8125 38.2812 68.2241 38.2812 65.0312C38.2812 61.8384 35.6929 59.25 32.5 59.25C29.3071 59.25 26.7188 61.8384 26.7188 65.0312C26.7188 68.2241 29.3071 70.8125 32.5 70.8125Z"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.6875 61.5625C47.1542 61.5625 45.6837 60.9534 44.5995 59.8692C43.5153 58.785 42.9062 57.3145 42.9062 55.7812"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3125 61.5625C17.0717 61.5625 17.8235 61.413 18.5249 61.1224C19.2263 60.8319 19.8636 60.406 20.4005 59.8692C20.9373 59.3324 21.3631 58.6951 21.6537 57.9936C21.9442 57.2922 22.0938 56.5405 22.0938 55.7812"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.6875 68.5C47.1542 68.5 45.6837 69.1091 44.5995 70.1933C43.5153 71.2775 42.9062 72.748 42.9062 74.2812"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3125 68.5C17.0717 68.5 17.8235 68.6495 18.5249 68.9401C19.2263 69.2306 19.8636 69.6565 20.4005 70.1933C20.9373 70.7301 21.3631 71.3674 21.6537 72.0689C21.9442 72.7703 22.0938 73.522 22.0938 74.2812"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InfoTip2;
