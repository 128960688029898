import React from 'react';

const PaginationSkeleton = () => (
  <div className="tw-mt-12 tw-px-2 tw-flex tw-justify-between">
    <div className="tw-flex tw-space-x-2">
      {Array(2)
        .fill(0)
        .map((_, i) => (
          <div key={'a' + i} className="tw-flex tw-animate-pulse tw-bg-neutral-grey-4 tw-h-4 tw-w-12 tw-rounded" />
        ))}
    </div>
    <div className="tw-flex tw-space-x-2">
      {Array(2)
        .fill(0)
        .map((_, i) => (
          <div key={'b' + i} className="tw-flex tw-animate-pulse tw-bg-neutral-grey-4 tw-h-4 tw-w-12 tw-rounded" />
        ))}
    </div>
  </div>
);

export default PaginationSkeleton;
