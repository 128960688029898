import React from 'react';
import { BiArrowBack } from 'react-icons/bi';

import DashboardLayout from 'components/dashboard/DashboardLayout';
import { CardBalancesContent } from 'components/creditCards/components/CardBalances/components';
import { useCardBalances } from './hooks';

const CardBalances = () => {
  const {
    pageTitle,
    isError,
    isLoading,
    noCreditCards,
    hasBankAccounts,
    hasAtLeastOneBankAccountVerified,
    isPreFunded,
    goBack,
  } = useCardBalances();

  const showPageTitle =
    isLoading || isPreFunded
      ? false
      : isError || noCreditCards || !hasBankAccounts || !hasAtLeastOneBankAccountVerified;

  return (
    <DashboardLayout title={pageTitle}>
      <div>
        <div className="lg:tw-hidden tw-mb-4">
          <BiArrowBack size={24} className="tw-cursor-pointer" onClick={goBack} />
        </div>
        {showPageTitle && <h2 className="tw-mb-4">{pageTitle}</h2>}
      </div>
      <CardBalancesContent
        isError={isError}
        isLoading={isLoading}
        noCreditCards={noCreditCards}
        hasBankAccounts={hasBankAccounts}
        hasAtLeastOneBankAccountVerified={hasAtLeastOneBankAccountVerified}
        isPreFunded={isPreFunded}
      />
    </DashboardLayout>
  );
};

export default CardBalances;
