import { DetailsProps } from '../Details.types';

const useDetails = ({
  externalBankAccount,
  internalBankAccount,
}: Pick<DetailsProps, 'externalBankAccount' | 'internalBankAccount'>) => {
  const externalBankName = externalBankAccount?.institutionName;
  const externalBankAccountNumber = externalBankAccount?.accountNumber;
  const externalBankRoutingNumber = externalBankAccount?.routingNumber;

  const internalBankDetails = internalBankAccount?.externalAccounts?.[0];
  const internalBankName = 'Lewis & Clark Bank';
  const internalBankAccountNumber = internalBankDetails?.accountNumber;
  const internalBankRoutingNumber = internalBankDetails?.routingNumber;

  return {
    externalBankName,
    externalBankAccountNumber,
    externalBankRoutingNumber,
    internalBankName,
    internalBankAccountNumber,
    internalBankRoutingNumber,
  };
};

export default useDetails;
