import React from 'react';

type HrefType = {
  href: string;
  children: React.ReactNode;
  target?: string;
  rel?: string;
  className?: string;
};

const Href = ({ href, children, target = '_self', rel = '', className = '' }: HrefType) => (
  <a
    href={href}
    target={target}
    rel={rel}
    className={`tw-text-base tw-text-primary-dark-green tw-p-0 ${className}`}
    style={{ textDecoration: 'underline' }} // text-decoration for `a` is disabled by default
  >
    {children}
  </a>
);

export default Href;
