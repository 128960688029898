import React from 'react';

import { useDesktopHome } from '../hooks';
import { StatutoryHolidayBanner } from 'components/home/Header/components/StatutoryHolidayBanner/StatutoryHolidayBanner';
import { SolidMigrationBanner } from '../SolidMigrationBanner';
import { TimedComponentWrapper } from 'components/UI/TimedComponentWrapper';
import { SolidMigrationModal } from '../SolidMigrationModal';
import ConnectBankAccountModal from 'components/home/ConnectBankAccountModal';
import { NotifyCardMigration } from 'components/CardMigrationV2/components/NotifyCardMigration';

import styles from './BannerAndModalContainer.module.scss';

const BannerAndModalContainer = () => {
  const { welcomeModalSeen, newSolidAccountNumberSeen, connectedBankAccount, walletLoading, meLoading } =
    useDesktopHome();

  return (
    <div className={styles.BannerModalContainer}>
      {/* BANNERS */}
      <div className={styles.BannerContainer}>
        <StatutoryHolidayBanner />
        {!newSolidAccountNumberSeen && <SolidMigrationBanner />}
        <NotifyCardMigration />
      </div>

      {/* MODALS */}
      {!meLoading && !newSolidAccountNumberSeen && (
        <TimedComponentWrapper startAt="2024-09-30" endAt="2024-10-04">
          <SolidMigrationModal shouldShowSolidModal={!newSolidAccountNumberSeen} />
        </TimedComponentWrapper>
      )}

      {!walletLoading && !meLoading && !connectedBankAccount && (
        <ConnectBankAccountModal welcomeModalSeen={welcomeModalSeen} />
      )}
    </div>
  );
};

export default BannerAndModalContainer;
