import React, { useContext } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { BsPlusCircle, BsChevronDown } from 'react-icons/bs';
import { IoSearchOutline, IoCloseCircleOutline } from 'react-icons/io5';

import { CREDIT_CARD_STATES, CREDIT_CARD_TYPES, SCOPE } from 'constants/index';
import { CreditCardContext } from 'context/CreditCard';
import CreditCardsList from 'components/CreditCardsList';
import Button from 'components/Button';
import { Loaders } from 'components/cards/Loader';
import { CurrencyContext } from './CurrencyContext';
import useCardsListFilters from './useCardsListFilters';
import ErrorFetchingCreditCards from './ErrorFetchingCreditCards';
import SMSNotificationPromotion from './SMSNotificationPromotion';
import config from 'config';
import PermissionChecker from 'components/PermissionChecker';
import useIsContactPermission from 'hooks/useIsContactPermission';

const CardsList = ({ creditCards, onCreateCard, errorFetchingCards, creditCardLoading }) => {
  const { currency } = useContext(CurrencyContext);
  const {
    onChangeSearch,
    visibleCards,
    selectedStates,
    onChangeSelectedStates,
    selectedTypes,
    onChangeSelectedTypes,
    onClearFilters,
  } = useCardsListFilters(creditCards);
  const { setCreditCard } = useContext(CreditCardContext);

  const handleSelectCard = (card) => {
    setCreditCard(card);
  };

  const handleRetry = () => {
    window.location.reload();
  };

  const { createVirtualCard, createPhysicalCard, creditAgreementSigned } = useIsContactPermission();
  const canCreateCard = (createVirtualCard || createPhysicalCard) && creditAgreementSigned;

  return (
    <>
      <SMSNotificationPromotion />
      <CreditCardFilters
        creditCards={creditCards}
        creditCardsCount={creditCards.length}
        onChangeSearch={onChangeSearch}
        selectedStates={selectedStates}
        onChangeSelectedStates={onChangeSelectedStates}
        selectedTypes={selectedTypes}
        onChangeSelectedTypes={onChangeSelectedTypes}
        onClearFilters={onClearFilters}
        onCreateCard={onCreateCard}
        creditCardLoading={creditCardLoading}
        canCreateCard={canCreateCard}
      />

      {creditCardLoading && <Loaders.Spinner />}

      {!creditCardLoading &&
        (errorFetchingCards ? (
          <ErrorFetchingCreditCards handleRetry={handleRetry} />
        ) : (
          <CreditCardsList creditCards={visibleCards} selectedCurrency={currency} onSelectCard={handleSelectCard} />
        ))}
    </>
  );
};

const CreditCardFilters = (props) => {
  const {
    creditCardsCount,
    onChangeSearch,
    onChangeSelectedStates,
    onChangeSelectedTypes,
    onClearFilters,
    selectedStates,
    selectedTypes,
    onCreateCard,
    creditCardLoading,
    canCreateCard,
  } = props;

  const hasDropdownFilters = selectedStates.length + selectedTypes.length > 0;
  const disabledCreateCardButton = creditCardLoading || config.cardApiDown;

  return (
    <>
      <div className="tw-flex tw-items-center tw-mb-4">
        <strong className="tw-mr-8">{`Cards (${creditCardsCount})`}</strong>
        <div className="tw-relative tw-flex-grow">
          <div className="tw-absolute tw-py-2.5 tw-pl-3 tw-flex tw-items-center">
            <IoSearchOutline size={24} className="tw-mr-2" />
          </div>
          <input
            data-testid="filter-search-text-input"
            placeholder="Search by card nickname or last four digits"
            className="tw-block tw-px-3 tw-py-2 tw-pl-12 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input"
            onChange={onChangeSearch}
          />
        </div>
        <PermissionChecker scope={SCOPE.manageCards} memberScope={canCreateCard}>
          <Button primary isDisabled={disabledCreateCardButton} className="tw-ml-8" onClick={onCreateCard}>
            <BsPlusCircle className="tw-mr-2" />
            Create New Card
          </Button>
        </PermissionChecker>
      </div>
      <div className="tw-flex tw-items-center tw-mb-8">
        <small className="tw-text-neutral-grey-2">Filter</small>
        <Dropdown
          testId="filter-status-dropdown"
          label="Status"
          options={Object.values(CREDIT_CARD_STATES)}
          selectedValues={selectedStates}
          setSelectedValues={onChangeSelectedStates}
          className="tw-ml-2"
          optionsClassName="tw-w-28"
        />
        <Dropdown
          testId="filter-type-dropdown"
          label="Card Type"
          options={Object.values(CREDIT_CARD_TYPES)}
          selectedValues={selectedTypes}
          setSelectedValues={onChangeSelectedTypes}
          className="tw-ml-2"
        />
        {hasDropdownFilters && (
          <div
            onClick={onClearFilters}
            data-testid="filter-clear-button"
            className="tw-flex tw-items-center tw-cursor-pointer tw-text-neutral-grey-2 tw-ml-2"
          >
            <IoCloseCircleOutline size={18} className="tw-mr-2" />
            <small>Clear all</small>
          </div>
        )}
      </div>
    </>
  );
};

const Dropdown = ({ label, options, selectedValues, setSelectedValues, className, testId, optionsClassName }) => {
  const handleSelect = (option) => {
    const optionIndex = selectedValues.indexOf(option);
    if (optionIndex === -1) {
      setSelectedValues([...selectedValues, option]);
    } else {
      setSelectedValues([...selectedValues.slice(0, optionIndex), ...selectedValues.slice(optionIndex + 1)]);
    }
  };

  return (
    <Listbox value={selectedValues} onChange={handleSelect}>
      <div className={`tw-relative tw-p-2 ${className}`}>
        <Listbox.Button data-testid={testId} className="tw-flex tw-items-center tw-cursor-pointer">
          <small className="tw-mr-2">{`${label}${
            selectedValues && selectedValues.length > 0 ? ` (${selectedValues.length})` : ''
          }`}</small>
          <BsChevronDown size={14} className="tw-text-neutral-grey-2" />
        </Listbox.Button>
        <Transition
          unmount={false}
          as={React.Fragment}
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Listbox.Options
            className={`tw-absolute tw-z-50 tw-w-24 tw-py-1 tw-overflow-auto tw-bg-neutral-light tw-rounded-md tw-shadow-lg ${optionsClassName}`}
          >
            {options.map((option) => {
              const isSelected = selectedValues.includes(option);
              const onCheck = () => handleSelect(option);

              return (
                <Listbox.Option
                  key={option}
                  value={option}
                  className="tw-flex tw-items-center tw-px-2 tw-py-1 tw-cursor-pointer tw-text-neutral-grey-2 hover:tw-text-neutral-light hover:tw-bg-primary-dark-green"
                >
                  <input className="tw-mr-2" checked={isSelected} onChange={onCheck} type="checkbox" />
                  <small className="tw-capitalize">{option}</small>
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default CardsList;
