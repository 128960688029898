import React from 'react';

const Ellipses = ({ width = '3', height = '15' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="1.5" cy="1.5" r="1.5" fill="#4F5154" />
      <circle cx="1.5" cy="7.5" r="1.5" fill="#4F5154" />
      <circle cx="1.5" cy="13.5" r="1.5" fill="#4F5154" />
    </svg>
  );
};

export default Ellipses;
