import React, { useState } from 'react';

const MFAContext = React.createContext({});

const MFAContextProvider = (props) => {
  const [smsToken, setSMSTokenFn] = useState();
  const [smsTokenExpiresAt, setSMSTokenExpiresAt] = useState();

  const setSMSToken = ({ token, expiresAt }) => {
    setSMSTokenFn(token);
    setSMSTokenExpiresAt(expiresAt);
  };
  const getSMSToken = () => {
    if (!smsTokenExpiresAt || !smsToken) return null;
    if (new Date() >= new Date(smsTokenExpiresAt)) return null;
    return smsToken;
  };

  return <MFAContext.Provider value={{ getSMSToken, setSMSToken }}>{props.children}</MFAContext.Provider>;
};

export { MFAContext, MFAContextProvider };
