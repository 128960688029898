import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ACTIVATE_CREDIT_CARD, GET_CREDIT_CARDS_FOR_LIST, GET_CREDIT_CARD_BY_ID } from 'graphql/cards';
import { ampTrackEvent } from 'amplitude';
import { validateInteger } from 'utility/validators';
import Close from 'components/svg/Close';
import Success from 'components/svg/Success';
import ExampleCardBack from 'components/svg/ExampleCardBack';
import Modal from 'components/Modal/v2';
import { SubmitButton, MaskedTextField } from 'components/FormFields/v2';
import Button from 'components/Button';

const ActivateCardModal = ({ show, setShow, creditCard }) => {
  const [activateCard, { loading, error }] = useMutation(ACTIVATE_CREDIT_CARD, {
    refetchQueries: [
      { query: GET_CREDIT_CARDS_FOR_LIST, fetchPolicy: 'network-only' },
      { query: GET_CREDIT_CARD_BY_ID, fetchPolicy: 'network-only', variables: { creditCardId: creditCard?.id } },
    ],
    awaitRefetchQueries: true,
  });
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const onClose = () => setShow(false);

  const form = useForm();
  const { register, handleSubmit } = form;

  const handleSubmitForm = async ({ cvc2 }) => {
    try {
      await activateCard({
        variables: { creditCardGid: creditCard.id, lastFourDigits: creditCard.lastFourDigits, cvc2 },
      });

      ampTrackEvent('activateCard: success');
      setHasSubmitted(true);
      toast.success('Card has been activated successfully');
    } catch (error) {
      ampTrackEvent('activateCard: error');
      toast.error('Unable to activate the card - please try again');
      console.error(error);
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <strong className="tw-ml-2">{hasSubmitted ? 'Successful Card Activation' : 'Activate Physical Card'}</strong>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      {hasSubmitted ? (
        <>
          <div className="tw-flex tw-flex-col tw-px-8 tw-pt-8 tw-p-4 tw-border-t tw-border-b tw-border-neutral-grey-4">
            <div className="tw-px-8 tw-flex tw-flex-col tw-justify-center">
              <Success className="tw-self-center" />
              <h3 className="tw-my-8 tw-font-semibold">{`Great! Your ${creditCard.displayName} - ${creditCard.lastFourDigits} Card is active and ready to use!`}</h3>
            </div>
          </div>
          <div className="tw-flex tw-justify-end tw-px-8 tw-pt-8 tw-p-4 tw-border-t tw-border-t tw-border-neutral-grey-4">
            <Button primary className="tw-ml-8" onClick={onClose}>
              Got it!
            </Button>
          </div>
        </>
      ) : (
        <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="tw-flex tw-flex-col tw-px-12 tw-pt-8 tw-p-4 tw-border-t tw-border-b tw-border-neutral-grey-4">
              <div className="tw-flex tw-mb-4 tw-justify-center">
                <ExampleCardBack />
              </div>
              <strong className="tw-mt-4 tw-mb-6">
                To activate your physical card, enter the 3-digit security code found on the back of your card
              </strong>
              <div>
                <div className="tw-flex tw-items-center tw-justify-between">
                  <p className="tw-mr-4">Last 4 digits</p>
                  <div className="tw-flex tw-items-center">
                    <p>∗∗∗∗ - ∗∗∗∗ - ∗∗∗∗ - {creditCard.lastFourDigits}</p>
                  </div>
                </div>
                <div className="tw-flex tw-items-center tw-justify-between tw-mt-2">
                  <p className="tw-mr-4">CVC2</p>
                  <MaskedTextField
                    rootClass="tw-w-20"
                    name="cvc2"
                    rules={register({
                      required: 'required',
                      maxLength: { value: 3, message: 'Must have 3 digits' },
                      minLength: { value: 3, message: 'Must have 3 digits' },
                    })}
                    mask="_ "
                    format="###"
                    placeholder="_ _ _ "
                    allowNegative={false}
                    isNumericString={true}
                    isAllowed={validateInteger}
                  />
                </div>
              </div>
            </div>
            <div className="tw-flex tw-justify-end tw-px-8 tw-pt-8 tw-p-4 tw-border-t tw-border-t tw-border-neutral-grey-4">
              <SubmitButton disabled={loading}>{loading ? 'Submitting...' : 'Activate'}</SubmitButton>
            </div>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};

export default ActivateCardModal;
