import React from 'react';

import { DetailsProps } from './Details.types';

const Details = ({ name, email, referenceId }: DetailsProps) => {
  return (
    <div className="tw-my-6 tw-overflow-hidden">
      <div className="tw-w-1/2">
        <div className="tw-text-sm tw-text-neutral-grey-1">Payor Legal Name</div>
        <div>{name}</div>

        <div className="tw-text-sm tw-text-neutral-grey-1 tw-mt-6">Email</div>
        <div>{email}</div>

        {referenceId && (
          <>
            <div className="tw-text-sm tw-text-neutral-grey-1 tw-mt-6">Payor Nickname ID</div>
            <div>{referenceId}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default Details;
