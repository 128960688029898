import React from 'react';

import DashboardLayout from 'components/dashboard/DashboardLayout';
import useIsMobile from 'hooks/useIsMobile';
import { ReceiptsContainer } from './ReceiptsContainer';

export const UnmatchedReceipts = () => {
  const isMobile = useIsMobile();
  return (
    <DashboardLayout title="Unmatched Receipts" className="tw-position-relative">
      <div className={`tw-flex ${isMobile && 'tw-flex-col tw-px-4'}`}>
        <ReceiptsContainer />
      </div>
    </DashboardLayout>
  );
};
