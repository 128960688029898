import React from 'react';

const LockClosedOutline = ({ width = '21', height = '17', ...other }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M9.8125 6.8125V3.47266C9.8125 2.72673 9.51618 2.01136 8.98874 1.48392C8.46129 0.956472 7.74592 0.660156 7 0.660156C6.25408 0.660156 5.53871 0.956472 5.01126 1.48392C4.48382 2.01136 4.1875 2.72673 4.1875 3.47266V6.8125"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9375 6.8125H3.0625C2.13052 6.8125 1.375 7.56802 1.375 8.5V14.6875C1.375 15.6195 2.13052 16.375 3.0625 16.375H10.9375C11.8695 16.375 12.625 15.6195 12.625 14.6875V8.5C12.625 7.56802 11.8695 6.8125 10.9375 6.8125Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockClosedOutline;
