import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import { TextField } from 'components/FormFields/v2';
import Button from 'components/Button';
import { useTransactionCategories } from 'hooks/useTransactionCategories';
import { CREATE_LOOP_CATEGORY } from 'graphql/accountingServices';
import { GET_TRANSACTION_CATEGORIES } from 'graphql/transactionCategories';

const NewCategoryModal = ({ show, onClose }) => {
  const form = useForm({ mode: 'onChange' });
  const { transactionCategories } = useTransactionCategories();
  const [createCategory, { loading }] = useMutation(CREATE_LOOP_CATEGORY);

  const {
    register,
    reset,
    clearErrors,
    formState: { isDirty, isValid },
    handleSubmit,
  } = form;

  useEffect(() => {
    clearErrors();
    reset();
  }, []);

  const handleClose = () => {
    clearErrors();
    reset();
    onClose();
  };

  const onSubmit = async (data, evt) => {
    evt.preventDefault();

    try {
      const { category_name: description } = data;
      await createCategory({
        variables: {
          description,
        },
        refetchQueries: [{ query: GET_TRANSACTION_CATEGORIES }],
      });
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const noDuplicates = (name) =>
    !transactionCategories ||
    (transactionCategories && !transactionCategories.some((category) => category.description === name));

  return (
    <Modal show={show} onClose={handleClose}>
      <div className="tw-flex tw-flex-row tw-justify-between tw-px-8 tw-py-4 tw-border-b tw-border-neutral-grey-4">
        <div>Add New Category</div>
        <Close role="button" className="tw-cursor-pointer" onClick={handleClose} />
      </div>
      <div className="tw-p-8">
        <FormProvider {...form}>
          <form
            onSubmit={(e) => {
              handleSubmit(onSubmit)(e).catch((e) => {
                console.log(e);
              });
            }}
          >
            <TextField
              rootClass="tw-mb-4"
              name="category_name"
              label="Category Name"
              placeholder="e.g: Google Ads"
              autoComplete="off"
              ref={register({
                required: true,
                validate: {
                  duplicates: (v) => noDuplicates(v) || 'A category with this name already exists',
                },
              })}
              tabIndex={1}
            />

            <div className="tw-flex tw-justify-end">
              <Button primary type="submit" isDisabled={!isDirty || !isValid} isFetching={loading}>
                Add Category
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default NewCategoryModal;
