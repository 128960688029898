import { useState } from 'react';

import { Event } from 'types/event';
import { GAMIFICATION_TITLES } from '../constants';
import { getCards } from '../ProductCommunicationCards.utils';

const useProductCommunicationCards = ({ accountCompletedEvents }: { accountCompletedEvents: [] }) => {
  const accountCompletedEventsCodes = accountCompletedEvents.map((event: Event) => event.code);

  const [activeModalTitle, setActiveModalTitle] = useState('');
  const closeActiveModal = () => setActiveModalTitle('');

  const showFirstPurchaseModal = activeModalTitle === GAMIFICATION_TITLES.FIRST_CARD_PURCHASE;
  const showFirstDepositModal = activeModalTitle === GAMIFICATION_TITLES.FIRST_ACCOUNT_DEPOSIT;
  const showFirstConversionModal = activeModalTitle === GAMIFICATION_TITLES.FIRST_ACCOUNT_CONVERSION;

  const productContent = getCards({ accountCompletedEventsCodes });

  return {
    setActiveModalTitle,
    closeActiveModal,
    showFirstPurchaseModal,
    showFirstDepositModal,
    showFirstConversionModal,
    productContent,
  };
};

export default useProductCommunicationCards;
