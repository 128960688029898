import React from 'react';

import { Details, Review, Complete } from './components';
import { StepsProps } from './Steps.types';

const Steps = ({ currentStep, onNextStep, onPrevStep, onFinish, onResetSteps }: StepsProps) => {
  switch (currentStep) {
    case 0:
      return <Details onNextStep={onNextStep} />;
    case 1:
      return <Review onNextStep={onNextStep} onPrevStep={onPrevStep} />;
    default:
      return <Complete onFinish={onFinish} onResetSteps={onResetSteps} />;
  }
};

export default Steps;
