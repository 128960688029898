import React from 'react';

import { useAccountsModal } from '../../hooks';
import { Currencies } from 'constants/currencies';
import CCBankAccountDetailsContainer from 'containers/accounts/CurrencyCloudBankAccountDetails.js';
import USDBankAccountDetailsContainer from 'containers/accounts/USDBankAccountDetails';

const ViewBankDetails = () => {
  const { currency, solidInternalId } = useAccountsModal();

  const currencyCloudAccount = currency === Currencies.GBP || currency === Currencies.EUR;
  const cadAccount = currency === Currencies.CAD;
  const solidAccount = currency === Currencies.USD;

  return (
    <div className="tw-py-4">
      {(currencyCloudAccount || cadAccount) && <CCBankAccountDetailsContainer currency={currency} />}
      {solidAccount && <USDBankAccountDetailsContainer accountInternalId={solidInternalId} />}
    </div>
  );
};

export default ViewBankDetails;
