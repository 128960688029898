import React from 'react';

import config from 'config';
import { DetailsSection } from './components';
import { useCorePADAgreement } from './hooks';
import { CorePADAgreementProps } from './CorePADAgreement.types';

const CorePADAgreement = ({ bankAccount }: CorePADAgreementProps) => {
  const {
    formattedTodaysDate,
    bankName,
    transitNumber,
    institutionNumber,
    accountNumber,
    payorDisplayName,
    payorLegalName,
    payorEmailAddress,
  } = useCorePADAgreement({ bankAccount });

  return (
    <div className="tw-text-sm tw-flex tw-flex-col tw-gap-4">
      <div>
        I, {payorDisplayName}, of the Payor, hereby agree to enter into this Pre-Authorized Debit (PAD) Agreement on
        behalf of the Payor and authorize Loop Payments Inc. to debit the Payor’s deposit account in accordance with the
        Pre-Authorized Debit Agreement. I hereby agree to waive the right to receive pre- notification of the amount of
        the PAD.
      </div>
      <div className="tw-text-base tw-text-center tw-underline tw-font-bold">Pre-Authorized Debit (PAD) Agreement</div>
      <div>This Pre-Authorized Debit (PAD) Agreement is for Cash Management Purposes</div>
      <div>Date: {formattedTodaysDate}</div>
      <div>Authorized Signer: {payorDisplayName}</div>
      <div>
        In accordance with the Multi-Currency Account Agreement (the “Terms”), I hereby authorize Loop Payments Inc. to
        initiate electronic funds transfer debits from my bank deposit account listed below in order to transfer funds
        from my deposit account at another financial institution to the account(s) I hold at Loop.
      </div>
      <div>
        I understand that this is a PAD drawn on my account for the purpose of transferring, consolidating or
        repositioning funds between my account at one Member to their account at another Member, for the same Business
        or closely-affiliated Businesses (e.g. transfers between a parent company and its subsidiary) and therefore such
        PAD shall not be returned pursuant to the Rules.
      </div>
      <div>
        I understand that this PAD Agreement is for Variable amounts and may be debited on a Sporadic frequency. Loop is
        required to obtain an authorization from me/us for each and every PAD prior to the PAD being exchanged and
        cleared in accordance with Rule H1 of the CPA Rules. In order to initiate a PAD, a payment transfer
        authorization will be digitally authorized by a company administrator through the online Loop Platform. At the
        time that each authorization is provided, the authorization will include both the amount to be withdrawn from my
        account as well as the frequency of any debits under such PAD. I agree that any individual designated as an
        administrator who logs into the Loop Platform by entering a password or security code or equivalent and agreeing
        to the specific amount to be transferred will constitute valid authorization for the Processing Institution to
        debit the Account.
      </div>
      <div>
        I agree that EFT transactions I authorize comply with all applicable law and I confirm that I have the authority
        to authorize this PAD Agreement.
      </div>

      <DetailsSection
        title="Loop’s Contact Information:"
        columns={[
          { label: 'Full Legal Name:', value: 'Loop Financial Inc.' },
          { label: 'Street Address:', value: '410 Adelaide St W, Toronto ON M5V1S8' },
          { label: 'Email Address:', value: config.padAgreementEmailAddress },
        ]}
      />

      <DetailsSection
        title="Payor’s Information:"
        columns={[
          { label: 'Full Legal Name:', value: payorLegalName },
          { label: 'Email Address:', value: payorEmailAddress },
        ]}
      />

      <DetailsSection
        title="Payor’s Deposit Account Information:"
        columns={[
          { label: 'Name of Bank (the “Processing Institution”):', value: bankName },
          { label: 'Branch Transit Number:', value: transitNumber },
          { label: 'Processing Institution Number:', value: institutionNumber },
          { label: 'Deposit Account Number:', value: accountNumber },
        ]}
      />

      <div>
        <div className="tw-underline tw-font-bold tw-mb-1">Change of Account:</div>
        <div>
          In the event of a change in the information of the Payor’s deposit account, the Payor undertakes to notify
          Loop of such change in writing at least fifteen (15) business days before any scheduled payment and provide it
          with the information of its new deposit account.
        </div>
      </div>
      <div>
        <div className="tw-underline tw-font-bold tw-mb-1">Waiver to Pre-Notification:</div>
        <div>
          YOU HEREBY WAIVE ALL OF THE RIGHTS YOU MAY HAVE TO BE NOTIFIED PRIOR TO A DEBIT FROM YOUR DEPOSIT ACCOUNT FOR
          ANY REGULAR, VARIABLE OR SPORADIC AMOUNT, INCLUDING ANY SCHEDULED PAYMENT AND ANY NSF FEE OR LATE PAYMENT FEE.
        </div>
      </div>
      <div>
        <div className="tw-underline tw-font-bold tw-mb-1">Recourse/Reimbursement Rights:</div>
        <div>
          I acknowledge and agree that this agreement is for cash management PADs and the Payee will not provide
          recourse through the clearing system, and no recourse will be provided through the clearing system (that is, I
          will not receive automatic reimbursement in the event of a dispute) and I must seek reimbursement or recourse
          from the Payee in the event a PAD is erroneously charged to the Account. To obtain more information on the
          Payor’s recourse rights, the Payor may contact its Processing Institution or visit{' '}
          <a href="payments.ca" target="_blank" rel="noopener noreferrer">
            <span className="tw-text-primary-dark-green tw-underline">payments.ca</span>
          </a>
          .
        </div>
      </div>
      <div>
        <div className="tw-underline tw-font-bold tw-mb-1">Cancellation Rights:</div>
        <div className="tw-mb-2">
          This authority is to remain in effect until Loop Payments Inc. has received written notification from me/us of
          its change or termination. This PAD Agreement may be canceled at any time provided that notice in writing is
          received by Loop Payments Inc. no later than thirty (30) days before the next scheduled debit. To obtain a
          sample cancelation form, or for more information on the Payor’s right to cancel this PAD Agreement, contact
          the Payor’s Processing Institution or visit{' '}
          <a href="payments.ca" target="_blank" rel="noopener noreferrer">
            <span className="tw-text-primary-dark-green tw-underline">payments.ca</span>
          </a>
          .
        </div>
        <div>
          Should the Payor elect to cancel this PAD Agreement, the Payor must make other arrangements to the
          satisfaction of Loop such that transfers may continue to be made as required under the Terms.
        </div>
      </div>
      <div>
        <div className="tw-underline tw-font-bold tw-mb-1">Jurisdiction:</div>
        <div>
          Any legal action or proceeding arising out of or relating to this Agreement shall be instituted in the courts
          of the Province of Ontario, and the parties hereby submit to the exclusive jurisdiction of such courts.
        </div>
      </div>
    </div>
  );
};

export default CorePADAgreement;
