import React, { useContext, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import history from 'history.js';

import { AccountingServiceTransactionsContext } from 'context/AccountingServices';
import QboTableHeader from './AccountingServiceTxTableHeader';
import { QboTxTableSkeleton } from './AccountingServiceTxTableSkeleton';
import QboTx from './AccountingServiceTx';
import Button from 'components/Button';
import { Banner } from 'components/UI';
import { BannerSize } from 'components/UI/Banner';
import InfoTip1 from 'components/svg/InfoTip1';

import { GET_QBO_TRANSACTIONS, PUSH_QBO_TRANSACTIONS } from 'graphql/accountingServices';
import { Checkbox } from './Checkbox';
import { Filter } from '../filter/Filter';
import './AccountingServiceTxTable.css';
import { FilterContext } from '../context/FilterContext';
import { QuickBooksLiabilityAccounts } from 'components/settings/AccountingServices/LiabilityAccounts';
import { QuickBooksNewLiabilityModal } from 'components/settings/AccountingServices/NewLiabilityModal';

export const QboTxTable = ({ accountName }) => {
  const [qboTransactions, setQboTransactions] = useState([]);
  const [loadingTx, setLoading] = useState(true);

  const [loadQboTransactions] = useLazyQuery(GET_QBO_TRANSACTIONS, {
    onCompleted: (data) => {
      setQboTransactions(data.accountingIntegrationTransactions);
      loadingTx && setLoading(false);
    },
  });

  const { startDate, endDate, postedStatus, currency } = useContext(FilterContext);
  useEffect(() => {
    loadingTx && loadQboTransactions({ variables: { startDate, endDate, postedStatus, currency } });
  }, [loadingTx]);

  const {
    selectedTransactionsToPost,
    setSelectedTransactionsToPost,
    parseTransactionToPost,
    selectAllToPost,
    setSelectAllToPost,
    resetContext,
    isTransactionSelectableToPost,
    getLiabilityAccountFromCurrency,
  } = useContext(AccountingServiceTransactionsContext);

  const hasLiabilityAccountForCurrency = getLiabilityAccountFromCurrency(currency);

  const handleSelectAllToPost = (evt) => {
    const { checked } = evt.currentTarget;

    if (checked) {
      const allTxToPost = qboTransactions
        .filter((transaction) => isTransactionSelectableToPost(transaction))
        .map(({ transactionId }) => transactionId);
      setSelectedTransactionsToPost(allTxToPost);
      if (allTxToPost.length > 0) setSelectAllToPost(checked);
    } else {
      setSelectedTransactionsToPost([]);
      setSelectAllToPost(checked);
    }
  };

  const [pushTransactions, { loading }] = useMutation(PUSH_QBO_TRANSACTIONS, {
    variables: {
      transactions: qboTransactions
        .filter(({ transactionId }) => selectedTransactionsToPost.includes(transactionId))
        .map((transaction) => parseTransactionToPost(transaction)),
    },
    refetchQueries: [{ query: GET_QBO_TRANSACTIONS, variables: { startDate, endDate, postedStatus, currency } }],
  });

  const handlePush = async (evt) => {
    evt.preventDefault();
    try {
      if (selectedTransactionsToPost) {
        const { data: pushAccountingIntegrationTransactions } = await pushTransactions();
        if (pushAccountingIntegrationTransactions) {
          // Todo: this is taken from Multiple Members Cards.
          // reusable config to come here and in the multiple members components.
          toast.success(`Transactions Pushed to ${accountName} Successfully`);
          resetContext();
        } else {
          throw new Error('Error return false while pushing');
        }
      } else {
        console.error('no transaction selected to push, somethig is wrong');
      }
    } catch (e) {
      // toast.error
      console.error(e);
    }
  };

  const navigateToVendorMapping = () => {
    history.push('/dashboard/settings/accountingIntegration');
  };

  if (loadingTx) return <QboTxTableSkeleton />;

  return (
    <div className="tw-flex tw-flex-col">
      <h3 className="tw-font-semibold">Push Loop Card Transactions to {accountName}</h3>
      <Banner
        icon={<InfoTip1 width={40} />}
        iconAlignment="center"
        title={`Sync ${accountName} Vendors`}
        message={`Please sync your ${accountName} vendors with Loop Card Merchants before pushing transactions.`}
        size={BannerSize.regular}
        onSubmit={navigateToVendorMapping}
      />
      <small className="tw-text-neutral-grey-2">
        Select the transactions that you want to push and click the “Push to {accountName}" button when ready.{' '}
      </small>
      <small className="tw-pb-2 tw-text-neutral-grey-2">
        You can also change the {accountName} expense account, sales tax and date if you need to do so before posting.
      </small>
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-start tw-py-2">
        <div className="tw-text-sm tw-text-neutral-grey-2">Filter Transactions by: </div>
        <Filter className="tw-p-2" setTransactions={setQboTransactions} />
        <div className="tw-flex-grow" />
        <Link to="/dashboard/settings/accountingIntegration">
          <div className="tw-flex tw-items-center">
            <small className="tw-font-semibold tw-text-primary-dark-green tw-mr-2">Set Merchant-Vendor rules</small>
          </div>
        </Link>
      </div>

      {!hasLiabilityAccountForCurrency && (
        <div>
          <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-1">
            For each Loop Card currency, select the {accountName} liability account that you want to push transactions
            to. If you don’t have an existing account for a given currency,
            {accountName === 'Xero'
              ? ' click the Create Xero Account button, and reload the app once you are done creating it on Xero.'
              : ' Loop can create one for you below.'}
          </div>
          <div className="tw-text-sm tw-font-medium tw-text-neutral-grey-2 tw-text-left tw-mt-2 tw-mb-5">
            Changing the mapping below will only affect transactions pushed to {accountName} in future, it will not
            override any data that has already been synced to {accountName}.
          </div>
          <div className="tw-text-semantic-warning tw-text-sm">
            Also, please make sure you complete both the Card and Account related integrations before pushing your
            transactions.
          </div>
          <QuickBooksLiabilityAccounts
            className="tw-w-3/4"
            accountName={accountName}
            currency={currency}
            showAction={false}
          />
          <QuickBooksNewLiabilityModal accountName={accountName} />
        </div>
      )}

      {hasLiabilityAccountForCurrency && qboTransactions.length === 0 && (
        <div className="tw-my-20 tw-text-neutral-grey-2 tw-text-center">No Transactions with this filter</div>
      )}
      {hasLiabilityAccountForCurrency && qboTransactions.length > 0 && (
        <>
          <div className="tw-flex tw-justify-between">
            <small className="tw-flex tw-flex-row">
              You are pushing to &nbsp;<u>{hasLiabilityAccountForCurrency.name}</u>
            </small>
            <small>
              <Checkbox
                className="tw-flex tw-flex-row"
                label="Select all pending"
                checked={selectAllToPost}
                onChange={handleSelectAllToPost}
              />
            </small>
          </div>
          <div className="gridTable ">
            <QboTableHeader accountName={accountName} className="tw-contents" />

            <ReactTooltip multiline />

            {qboTransactions.map((transaction) => {
              return <QboTx accountName={accountName} key={transaction?.transactionId} {...transaction} />;
            })}
          </div>
          <div className="tw-sticky tw-bottom-0 tw-flex tw-flex-row tw-justify-end tw-mt-2 ">
            <Button
              primary
              isDisabled={selectedTransactionsToPost.length === 0}
              onClick={handlePush}
              isFetching={loading}
            >
              Push to {accountName}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
