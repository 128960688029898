import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';

import { GET_WALLETS } from 'graphql/creditOffer';
import { AccountWallet } from 'types/wallet';
import { Currencies } from 'constants/currencies';

const useDepositInformation = () => {
  const { data: walletData, loading: walletLoading } = useQuery(GET_WALLETS);

  const wallets = get(walletData, 'me.account.wallets', []);
  const possibleWallets = wallets.filter((wallet: AccountWallet) =>
    [Currencies.USD, Currencies.EUR, Currencies.GBP].includes(wallet.currency)
  );
  const walletOptions = useMemo(() => {
    return possibleWallets.map((wallet: AccountWallet) => ({
      value: wallet.id,
      name: wallet.displayName,
    }));
  }, []);

  const form = useForm();
  const { watch, register } = form;
  const watchWallet = watch('wallet');

  const selectedWallet = useMemo(
    () => wallets.find((wallet: AccountWallet) => wallet.id === watchWallet),
    [watchWallet]
  );
  const selectedWalletCurrency = selectedWallet?.currency;
  const hasActiveExternalAccount = selectedWallet?.activeExternalAccount;
  const showEmptyState = !hasActiveExternalAccount;

  const solidAccountInternalId =
    selectedWalletCurrency === Currencies.USD && hasActiveExternalAccount
      ? selectedWallet?.externalAccounts[0]?.internalId
      : '';

  return {
    walletLoading,
    walletOptions,
    selectedWallet,
    selectedWalletCurrency,
    showEmptyState,
    form,
    register,
    solidInternalId: solidAccountInternalId,
  };
};

export default useDepositInformation;
