import React, { useEffect, useState, createContext } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useDeepEffect } from 'hooks';

import {
  AccountingIntegrationCompanyPreferenceType,
  AccountingIntegrationCompanyPreferenceContextType,
} from '../Transactions.types';
import { GET_QBO_PREFERENCES_CURRENCY } from 'graphql/accountingServices';

export const AccountingIntegrationCompanyPreferenceContext =
  createContext<AccountingIntegrationCompanyPreferenceContextType>(
    {} as AccountingIntegrationCompanyPreferenceContextType
  );

export const AccountingIntegrationCompanyPreferenceContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [companyPreference, setCompanyPreference] = useState<AccountingIntegrationCompanyPreferenceType>({
    homeCurrency: null,
    multiCurrencyEnabled: false,
  });
  const [error, setError] = useState<ApolloError | null>(null);
  const [loading, setLoading] = useState(true);

  const { data: preferenceData, loading: loadingCompanyPreference } = useQuery<{
    accountingIntegrationPreferencesCurrency: AccountingIntegrationCompanyPreferenceType;
  }>(GET_QBO_PREFERENCES_CURRENCY);
  const preference = get(preferenceData, 'accountingIntegrationPreferencesCurrency', {
    homeCurrency: null,
    multiCurrencyEnabled: false,
  }) as AccountingIntegrationCompanyPreferenceType;

  useDeepEffect(() => {
    setCompanyPreference(preference);
  }, [preference]);

  useEffect(() => {
    setLoading(loadingCompanyPreference);
  }, [loadingCompanyPreference]);

  return (
    <AccountingIntegrationCompanyPreferenceContext.Provider
      value={{
        loading,
        companyPreference,
        error,
        setError,
      }}
    >
      {children}
    </AccountingIntegrationCompanyPreferenceContext.Provider>
  );
};
