import React from 'react';

import { Badge } from 'components/UI';
import { mapCardStatusToBadgeProps } from './CardStatusBadge.utils';
import { CardStatusBadgeProps } from './CardStatusBadge.types';

export const CardStatusBadge = ({ status }: CardStatusBadgeProps) => {
  if (!status) return null;

  const { title, badgeStatus, icon } = mapCardStatusToBadgeProps(status);

  return <Badge title={title} status={badgeStatus} icon={icon} classNames="tw-font-bold" />;
};

export default CardStatusBadge;
