import React from 'react';

import { Layout } from 'components/UI';
import { onboardingDashboardTabNavItems, onboardingDashboardTopDropdownNavigation } from './constants';
import { useOnboardingDashboardLayout } from './hooks';

const OnboardingDashboardLayout = ({ children, title }: { children: React.ReactNode; title?: string }) => {
  const { sideNavItems, bottomSideNavItems } = useOnboardingDashboardLayout();

  return (
    <Layout
      topDropdownNavigation={onboardingDashboardTopDropdownNavigation}
      sideNavItems={sideNavItems}
      bottomSideNavItems={bottomSideNavItems}
      tabNavItems={onboardingDashboardTabNavItems}
      title={title}
    >
      {children}
    </Layout>
  );
};

export default OnboardingDashboardLayout;
