import { StepType } from '../CreateCardModal.types';

export const getCreateCardSteps = ({
  includeKeyTerms,
  isPhysicalCard,
}: {
  includeKeyTerms: boolean;
  isPhysicalCard: boolean;
}): StepType => {
  const steps: StepType = {
    'Card Setup': 0,
    'Card Information': 1,
  };

  if (isPhysicalCard) {
    steps['Card Delivery Address'] = 2;
    steps['Card Charge Fee'] = 3;
    steps['Set Pin'] = 4;
    steps['Card Settings'] = 5;
  } else {
    if (includeKeyTerms) {
      steps['Key Terms'] = 2;
      steps['Card Settings'] = 3;
    } else {
      steps['Card Settings'] = 2;
    }
  }

  return steps;
};
