import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import SettingsLayout from 'containers/settings/SettingsLayout';
import useIsMobile from 'hooks/useIsMobile';
import { Loaders } from 'components/cards/Loader';
import { AuthContext } from 'context/Auth';
import { SettingsContext } from 'context/Settings';
import { IntegrationsSettings } from './components';

const Integrations = () => {
  const history = useHistory();
  const isMobile = useIsMobile();
  if (isMobile) history.push('/dashboard/home');

  const { externalAccounts, loadingIntegrations, refetchIntegrationsRef } = useContext(SettingsContext);
  const { me, meLoading } = useContext(AuthContext);
  const { permissions, account } = me || {};
  const { craBusinessNumber } = account || {};
  const { manageExternalAccounts } = permissions || {};

  return (
    <div className="tw-container-md tw-flex tw-flex-col tw-justify-around tw-w-full">
      <SettingsLayout>
        {loadingIntegrations || meLoading ? (
          <Loaders.Light />
        ) : (
          <IntegrationsSettings
            manageExternalAccounts={manageExternalAccounts}
            externalAccounts={externalAccounts}
            craBusinessNumber={craBusinessNumber}
            reload={refetchIntegrationsRef.current}
          />
        )}
      </SettingsLayout>
    </div>
  );
};

export default Integrations;
