import React from 'react';

const Regular = ({ firstName = '', lastName = '' }) => {
  const initials = `${firstName[0]}${lastName[0]}`;

  return (
    <div className="tw-bg-primary-dark-green tw-rounded-full tw-flex tw-justify-center tw-items-center tw-h-8 tw-w-8">
      <small className="tw-text-neutral-light">{initials}</small>
    </div>
  );
};

const Small = ({ firstName = '', lastName = '' }) => {
  const initials = `${firstName[0]}${lastName[0]}`;

  return (
    <div className="tw-bg-primary-dark-green tw-rounded-full tw-flex tw-justify-center tw-items-center tw-h-6 tw-w-6">
      <span className="tw-text-xs tw-text-neutral-light">{initials}</span>
    </div>
  );
};

const InitialsCircles = {
  Regular,
  Small,
};

export default InitialsCircles;
