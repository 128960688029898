import React from 'react';

import { useNavigateToConfigureAutopay } from '../hooks';
import Button from 'components/Button';

const ManageButton = ({ formattedNextPaymentDate }: { formattedNextPaymentDate?: string }) => {
  const { navigateToConfigureAutopay } = useNavigateToConfigureAutopay();

  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-x-6 tw-gap-y-3">
      <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-x-6 tw-gap-y-3">
        <Button primary onClick={navigateToConfigureAutopay} className="tw-w-40">
          Manage
        </Button>
        <div className="tw-rounded-md tw-bg-primary-light-green tw-py-2 tw-px-4 tw-text-neutral-grey-1 tw-text-center tw-text-sm">
          Currently Enabled
        </div>
      </div>
      {formattedNextPaymentDate && <>Next Payment Date: {formattedNextPaymentDate}</>}
    </div>
  );
};

export default ManageButton;
