import React, { useContext } from 'react';

import { Loaders } from 'components/cards/Loader';
import Table from './components/Table';
import { WalletCategoryMappingRequestContext } from './contexts/WalletCategoryMappingRequestContext';

const WalletCategorySettings = ({ accountName }: { accountName: string }) => {
  const { loading, walletCategoryMappings } = useContext(WalletCategoryMappingRequestContext);

  if (loading) return <Loaders.Light />;

  return (
    <div className="tw-mt-8">
      <Table walletTransactionsCategoryDetailsMappings={walletCategoryMappings} accountName={accountName} />
    </div>
  );
};

export default WalletCategorySettings;
