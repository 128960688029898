import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_TRANSACTIONS_PAGE } from 'graphql/transactions';
import { GET_WALLET_NAMES } from 'graphql/wallets';
import { useDeepEffect } from 'hooks';
import { TransactionsContext } from 'context/Transactions';
import { FilterContext } from 'components/Transactions/context/FilterContext';
import { getAccountStateWithQueries } from 'utility/accountState';
import useIsMobile from 'hooks/useIsMobile';
import { formatLocalDate } from 'utility/date';

const useTransactionsContent = () => {
  const isMobile = useIsMobile();

  const { productType, setTransactions } = useContext(TransactionsContext);
  const { period, currencies, categories, loopProducts, search, page, numPerPage, setPage, setNumPerPage } =
    useContext(FilterContext);

  const { loading, data: transactionsData } = useQuery(GET_TRANSACTIONS_PAGE, {
    variables: {
      startDate: formatLocalDate(period?.from),
      endDate: formatLocalDate(period?.to),
      currencies,
      categories: categories?.map((cat) => cat.transactionCategoryId),
      loopProducts,
      search,
      products: [productType],
      page: `${page}`,
      numPerPage: `${numPerPage}`,
    },
  });

  const transactions = get(transactionsData, 'transactionsPage.items', []);
  const totalCount = get(transactionsData, 'transactionsPage.pageData.totalCount', 0);

  const { data: dataWallets } = useQuery(GET_WALLET_NAMES);
  const wallets = get(dataWallets, 'me.account.wallets', []);
  const accountNames = wallets.map((wallet) => wallet.displayName);

  const { accountState } = getAccountStateWithQueries({
    loadingTransactions: loading,
    transactions: transactions.length > 0 ? [transactions[0]] : [], // an array of one element only
  });

  const isLoading = accountState === 'loading';
  const hasTransactions = accountState === 'hasTransactions';

  useDeepEffect(() => {
    setTransactions(transactions);
  }, [transactions, setTransactions]);

  return {
    productType,
    accountState,
    accountNames,
    totalCount,
    page,
    numPerPage,
    setPage,
    setNumPerPage,
    isMobile,
    isLoading,
    hasTransactions,
  };
};

export default useTransactionsContent;
