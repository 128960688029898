import { useContext, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import type { TextElement as TextElementType } from '@basis-theory/basis-theory-react/types';

import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { UseSetPinModalProps } from '../SetPinModal.types';
import { ConfirmPinError } from 'components/creditCards/creditCards.types';
import { useBasisTheoryOperations } from 'components/creditCards/hooks';

const useSetPinModal = ({ onClose }: UseSetPinModalProps) => {
  const { creditCardDetails } = useContext(CardDetailsContext);
  const { id: cardId, cardProcessorId } = creditCardDetails || {};

  const { bt, setPin, loading, setLoading } = useBasisTheoryOperations();

  const pinRef = useRef<TextElementType>(null);
  const confirmPinRef = useRef<TextElementType>(null);

  const form = useForm({
    defaultValues: {
      pin: '',
      confirmPin: '',
    },
  });

  const { handleSubmit } = form;

  const onSubmit = useCallback(async () => {
    const processingToast = toast.loading('Setting PIN...');

    if (!cardId || !cardProcessorId) {
      toast.update(processingToast, {
        render: 'Unable to set PIN - please try again',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }

    try {
      await setPin(cardId, cardProcessorId, pinRef, confirmPinRef);

      toast.update(processingToast, {
        render: 'PIN set successfully',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      onClose();
    } catch (error) {
      console.log(error);

      const err = error as ConfirmPinError;
      const errorMessage = err.data?.confirm_pin_error
        ? err.data.confirm_pin_error
        : 'Unable to set PIN - please try again';

      toast.update(processingToast, {
        render: errorMessage,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, [cardId, cardProcessorId, setPin, onClose, pinRef]);

  return {
    form,
    handleSubmit,
    onSubmit,
    bt,
    pinRef,
    confirmPinRef,
    loading,
  };
};

export default useSetPinModal;
