import React, { useEffect, useState, createContext } from 'react';
import { ApolloError, useQuery, useMutation } from '@apollo/client';
import { useDeepEffect } from 'hooks';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import { Wallet } from 'types/wallet';
import { ExternalAccountingAccount } from 'types/externalAccountingAccount';
import {
  AddAssetAccountMappingRequestContextType,
  WalletAssetAccountMappingRequestPayloadType,
  WalletsListProps,
  AssetAccountsListProps,
  WalletAssetAccountMappingListProps,
  WalletAssetAccountMappingType,
  WalletAssetAccountDetailsMappingType,
} from '../AssetAccountSettings.types';
import {
  GET_ASSET_ACCOUNTS,
  GET_WALLET_ASSET_ACCOUNT_MAPPING,
  UPDATE_WALLET_ASSET_ACCOUNT_MAPPING,
} from 'graphql/accountingServices';
import { GET_WALLETS } from 'graphql/wallets';

export const AssetAccountMappingRequestContext = createContext<AddAssetAccountMappingRequestContextType>(
  {} as AddAssetAccountMappingRequestContextType
);

export const AddPaymentRequestContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [externalAccountingAccounts, setExternalAccountingAccounts] = useState<ExternalAccountingAccount[]>([]);
  const [availableExternalAccountingAccounts, setAvailableExternalAccountingAccounts] = useState<
    ExternalAccountingAccount[]
  >([]);
  const [walletAssetAccountMappings, setWalletAssetAccountMappings] = useState<WalletAssetAccountDetailsMappingType[]>(
    []
  );

  const [assetAccountMappingRequestInfo, setAssetAccountMappingRequestInfo] =
    useState<WalletAssetAccountMappingRequestPayloadType | null>(null);
  const [error, setError] = useState<ApolloError | null>(null);
  const [loading, setLoading] = useState(true);

  const { data: walletData, loading: loadingWallets } = useQuery<WalletsListProps>(GET_WALLETS);
  const walletList = get(walletData, 'wallets', []) as Wallet[];

  const { data: assetAccountsData, loading: loadingAssetAccounts } =
    useQuery<AssetAccountsListProps>(GET_ASSET_ACCOUNTS);
  const assetAccountList = get(
    assetAccountsData,
    'accountingIntegrationAssetRevenueAccounts',
    []
  ) as ExternalAccountingAccount[];

  const { data: mappingsData, loading: loadingMappings } = useQuery<WalletAssetAccountMappingListProps>(
    GET_WALLET_ASSET_ACCOUNT_MAPPING
  );
  const mappingsList = get(
    mappingsData,
    'walletAccountingIntegrationAssetAccountMapping',
    []
  ) as WalletAssetAccountMappingType[];

  const [updateMapping] = useMutation(UPDATE_WALLET_ASSET_ACCOUNT_MAPPING, {
    refetchQueries: [{ query: GET_WALLET_ASSET_ACCOUNT_MAPPING }],
    awaitRefetchQueries: true,
  });

  const findExternalAccountingAccount = (externalAccountingAccountId: string) => {
    return externalAccountingAccounts.find((account) => account.id === externalAccountingAccountId);
  };

  useDeepEffect(() => {
    setWallets(walletList);
  }, [walletList]);

  useDeepEffect(() => {
    setExternalAccountingAccounts(assetAccountList);
  }, [assetAccountList]);

  useDeepEffect(() => {
    const mappedWalletAssetAccountMappings = mappingsList.map((mapping) => {
      const externalAccountingAccount = findExternalAccountingAccount(mapping.externalAccountId);
      return {
        id: mapping.id,
        wallet: mapping.wallet,
        externalAccount: externalAccountingAccount,
      };
    });
    setWalletAssetAccountMappings(mappedWalletAssetAccountMappings);

    const mappedExternalAccountingAccounts = mappingsList.map((mapping) => mapping.externalAccountId);
    setAvailableExternalAccountingAccounts(
      externalAccountingAccounts.filter((account) => !mappedExternalAccountingAccounts.includes(account.id))
    );
  }, [mappingsList, externalAccountingAccounts]);

  useEffect(() => {
    setLoading(loadingWallets || loadingAssetAccounts || loadingMappings);
  }, [loadingWallets, loadingAssetAccounts, loadingMappings]);

  useDeepEffect(async () => {
    if (assetAccountMappingRequestInfo) {
      try {
        await updateMapping({
          variables: assetAccountMappingRequestInfo,
        });
      } catch (err) {
        console.error(err);
        toast.error('Error saving asset account mapping - please try again');
        setError(err as ApolloError);
      }
    }
  }, [assetAccountMappingRequestInfo]);

  return (
    <AssetAccountMappingRequestContext.Provider
      value={{
        loading,
        wallets,
        externalAccountingAccounts,
        availableExternalAccountingAccounts,
        walletAssetAccountMappings,
        assetAccountMappingRequestInfo,
        setAssetAccountMappingRequestInfo,
        error,
        setError,
      }}
    >
      {children}
    </AssetAccountMappingRequestContext.Provider>
  );
};
