import React from 'react';

import Layout from 'components/auth/Layout';
import { AgreementInfo, Header } from './components';
import { useSignUp } from './hooks';
import { SignUpProps } from './SignUp.types';

const SignUp = ({ type }: SignUpProps) => {
  const { promo, form } = useSignUp({ type });

  return (
    <Layout leftSectionText={promo}>
      <div className="tw-mb-12">
        <Header />
        {form}
        <AgreementInfo />
      </div>
    </Layout>
  );
};

export default SignUp;
