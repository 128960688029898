import React, { useState } from 'react';

import Modal from '../../Modal';
import ModalV2 from '../../Modal/v2';
import ConnectWooCommerceCard from '../../cards/ConnectWooCommerce';

const wooCommerceLogo = 'https://loop-public.s3.ca-central-1.amazonaws.com/woocommerce-logo.png';

const WooCommerce = ({ isOnboarding, isPreApproval, layout }) => {
  const [showConnectWooCommerce, setShowConnectWooCommerce] = useState(false);
  const hideConnectWooCommerce = () => setShowConnectWooCommerce(false);

  const ModalComponent = isPreApproval ? ModalV2 : Modal;

  return (
    <>
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        onClick={() => setShowConnectWooCommerce(true)}
      >
        <img className="d-block w-100" src={wooCommerceLogo} />
      </div>

      <ModalComponent show={showConnectWooCommerce} onClose={hideConnectWooCommerce}>
        <ConnectWooCommerceCard
          onCancel={hideConnectWooCommerce}
          onSuccess={hideConnectWooCommerce}
          onboarding={isOnboarding}
          preApproval={isPreApproval}
          layout={layout}
        />
      </ModalComponent>
    </>
  );
};

export default WooCommerce;
