import React from 'react';

const Close = ({ size = '24', ...other }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path d="M17.25 17.25L6.75 6.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.25 6.75L6.75 17.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Close;
