import React, { createContext, useState, useRef, useContext, useEffect } from 'react';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { CreditCard, CreditCardStatus } from 'types/creditCard';
import { User } from 'types/user';
import { Address } from 'types/shared';
import {
  CardMigrationContextType,
  CardMigrationFormType,
  CardMigrationSelectedCard,
  SummaryReport,
} from '../CardMigrationV2.types';

import { AuthContext } from 'context/Auth';
import useGetProductState from 'hooks/useGetProductState';
import { CardMigrationOption } from '../constants';
import { START_CARD_MIGRATIONS } from 'graphql/cardMigrationServices';
import { getSelectedCards } from '../CardMigration.utils';

export const CardMigrationContext = createContext<CardMigrationContextType>({} as CardMigrationContextType);

export const CardMigrationContextProvider = ({
  children,
  onClose,
  allCards,
  migrationCompleted,
}: {
  children: React.ReactNode;
  allCards: CreditCard[];
  migrationCompleted: boolean;
  onClose: () => void;
}) => {
  const { me } = useContext(AuthContext) as unknown as { me: User };
  const { isPreFunded: isPreFundedUser, isCreditCard: isCreditUser } = useGetProductState();

  const [summary, toggle] = useState<SummaryReport[]>([]);
  const summaryLoading = summary.length === 0;
  const cardMigrationFormDataRef = useRef<CardMigrationFormType>({
    selectedMigration: null,
    selectedCards: [],
  });
  const [selectedCard, setSelectedCard] = useState<CardMigrationSelectedCard | null>(null);

  const [startCardMigration] = useMutation(START_CARD_MIGRATIONS);

  const prepareForMigration = () => {
    return cardMigrationFormDataRef.current.selectedCards
      ?.filter((card) => card.migrate)
      .map((card) => {
        const data: { creditCardId: string; address?: Address } = {
          creditCardId: card.cardId,
        };
        if (card.address) {
          const address = JSON.parse(JSON.stringify(card.address));
          // dunno where this is coming from but the BE isn't expecting it
          delete address.__typename;
          data['address'] = address;
        }
        return data;
      });
  };

  useEffect(() => {
    setTimeout(() => {
      toggle([
        { url: 'https://www.bankonloop.com/', type: 'merchant-report' },
        { url: 'https://www.bankonloop.com/', type: 'migration-summary' },
      ]);
    }, 2500);
  }, [migrationCompleted]);

  const startMigration = async () => {
    if (cardMigrationFormDataRef.current.selectedMigration === CardMigrationOption.none) {
      onNextStep();
      return;
    }
    try {
      const data = prepareForMigration();
      if (!data || data.length === 0) {
        toast.error('Please select at least one card to migrate');
        return;
      }
      const { data: migrationStatus } = await startCardMigration({
        variables: {
          cardMigrations: data,
        },
      });
      if (migrationStatus?.createCreditCardMigration) {
        onNextStep();
        return;
      }
    } catch (err) {
      console.error('Failed to start migration', err);
      toast.error('Failed to start migration.');
    }
  };

  // migrationCompleted will define whether the user sees the Upgrade Complete! modal or not
  const [currentStep, setCurrentStep] = useState(migrationCompleted ? 6 : 0);

  const displayName = get(me, 'account.displayName', '');
  const accountName = get(me, 'account.name', '');
  const userName = displayName || accountName;

  const virtualCards = allCards.filter((card) => card.virtual && card.status === CreditCardStatus.active);
  const activeCards = allCards.filter((card) => card.status === CreditCardStatus.active);

  const steps: Record<number, string> = {
    0: 'Information About Upgrading to Loop’s New Card Program',
    1: "What's Changing?",
    2: 'Card Migration Preferences',
    3: 'Physical Card Delivery Details',
    4: 'Confirm Your Selection',
    5: 'Migration Has Started',
    6: 'Upgrade Complete!',
    7: 'Physical Card Delivery Details',
  };
  const stepTitle = steps[currentStep];
  const goToConfirmSelectionStep = () => setCurrentStep(3);
  const goToUpdateAddressStep = () => setCurrentStep(7);
  const onPrevStep = () => {
    const prevStep = currentStep - 1;
    if (currentStep === 4) {
      // move to step 2 if user did not select CardMigrationOption.allActive
      if (cardMigrationFormDataRef.current?.selectedMigration !== CardMigrationOption.allActive) {
        setCurrentStep(2);
        return;
      }
    }
    if (prevStep === 3 && virtualCards.length === activeCards.length) {
      // no physical cards available
      setCurrentStep(2);
      return;
    }
    setCurrentStep(prevStep);
  };
  const onNextStep = () => {
    const nextStep = currentStep + 1;
    if (currentStep === 5 || nextStep === Object.keys(steps).length) {
      onClose();
      return;
    }
    if (currentStep === 2) {
      // move to step 4 if user did not select CardMigrationOption.allActive
      if (virtualCards.length === activeCards.length) {
        setCurrentStep(4);
        return;
      }
      if (cardMigrationFormDataRef.current?.selectedMigration !== CardMigrationOption.allActive) {
        setCurrentStep(4);
        return;
      }
    }
    setCurrentStep(nextStep);
  };

  const handleAddressUpdate = ({ cardId, address }: { cardId: string | null; address: Address }) => {
    const refSelectedCards = cardMigrationFormDataRef.current.selectedCards;
    if (cardId === null) {
      refSelectedCards?.forEach((card) => {
        // do not update address for virtual cards
        if (card.migrate && !card.isVirtual) {
          card.address = address;
        }
      });
    } else {
      refSelectedCards?.forEach((card) => {
        if (card.migrate && card.cardId === cardId) {
          card.address = address;
        }
      });
    }
    cardMigrationFormDataRef.current.selectedCards = refSelectedCards;
  };

  const handleCardMigrationPreferenceChange = (selectedMigrationOption: CardMigrationOption) => {
    const selectedCards = getSelectedCards(allCards, selectedMigrationOption);
    cardMigrationFormDataRef.current = {
      selectedMigration: selectedMigrationOption,
      selectedCards,
    };
  };

  return (
    <CardMigrationContext.Provider
      value={{
        cardMigrationFormDataRef,
        handleCardMigrationPreferenceChange,

        displayName,
        accountName,
        userName,
        cards: cardMigrationFormDataRef.current.selectedCards || [],

        isPreFundedUser,
        isCreditUser,
        virtualCards,
        activeCards,

        selectedCard,
        setSelectedCard,

        stepTitle,
        currentStep,
        steps,

        onPrevStep,
        onNextStep,
        goToUpdateAddressStep,
        goToConfirmSelectionStep,
        onClose,

        handleAddressUpdate,
        summaryLoading,
        summary,

        startMigration,
      }}
    >
      {children}
    </CardMigrationContext.Provider>
  );
};
