import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import { ampTrackEvent } from 'amplitude';
import { MOVE_FUNDS } from 'graphql/payments';
import { PaymentContext, PaymentContextProvider } from 'context/Payment';
import { Progress } from 'components/UI';
import PADAgreementCA from 'components/payments/PADAgreementCA';
import { Loaders } from 'components/cards/Loader';
import Details from './Details';
import Review from './Review';
import Complete from './Complete';

const MOVE_MONEY_STEPS = {
  Details: [0],
  Review: [1, 2],
  Complete: [3],
};

const MoveMoney = (props) => {
  const [step, setStep] = useState(0);

  return (
    <PaymentContextProvider>
      <Progress currentStepIndex={step} stepLabelsAndIndexes={MOVE_MONEY_STEPS} />
      <Content {...props} step={step} setStep={setStep} />
    </PaymentContextProvider>
  );
};

const Content = ({ loading, ...props }) => {
  if (loading)
    return (
      <div className="tw-w-full">
        <Loaders.Light />
      </div>
    );

  return <Form {...props} />;
};

const Form = (props) => {
  const { step, setStep, refetch, ...otherProps } = props;
  const { setMoveMoneyInfo, moveMoneyInfo, setMoveMoneyTransaction } = useContext(PaymentContext);
  const [moveFunds, { loading }] = useMutation(MOVE_FUNDS);

  const onMoveFunds = async ({ nextStep }) => {
    const { fromAccount, toAccount, originalAmount, chargedAmount } = moveMoneyInfo;

    try {
      const response = await moveFunds({
        variables: {
          from: fromAccount.ledgerAccount ? fromAccount.ledgerAccount.id : fromAccount.id,
          to: toAccount.id,
          originalAmount,
          chargedAmount,
        },
      });

      if (response.data && response.data.moveFunds) {
        setMoveMoneyTransaction(response.data.moveFunds);
        ampTrackEvent('moveMoney: transferFunds: success');
        setStep(nextStep);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitReviewStep = (fromAccount, preDepositAuthorization) => {
    const isNotFromExternalBankAccount = fromAccount.__typename !== 'BankAccount';

    if (isNotFromExternalBankAccount || preDepositAuthorization) {
      onMoveFunds({ nextStep: 3 });
    } else {
      setStep(2);
    }
  };

  const onNewTransfer = () => {
    refetch && refetch();
    setMoveMoneyInfo(null);
    setStep(0);
  };

  switch (step) {
    case 0:
      return <Details {...otherProps} onNextStep={() => setStep(1)} />;
    case 1:
      return (
        <Review {...otherProps} loading={loading} onNextStep={onSubmitReviewStep} onPreviousStep={() => setStep(0)} />
      );
    case 2:
      return <PADAgreementCA {...otherProps} loading={loading} onNextStep={() => onMoveFunds({ nextStep: 3 })} />;
    default:
      return <Complete {...otherProps} onNewTransfer={onNewTransfer} />;
  }
};

export default MoveMoney;
