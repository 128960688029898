import React from 'react';

import { Loaders } from 'components/cards/Loader';
import Button from 'components/Button';
import { ExchangeRateInfo } from 'components/Accounts/AccountsContent/components/AccountsModal/components/ConvertFunds/components/Steps/components';
import { useReview } from './hooks';
import { ReviewProps } from './Review.types';

const Review = ({ onPreviousStep, onNextStep, loading }: ReviewProps) => {
  const {
    fromAccountDisplayName,
    toAccountDisplayName,
    toAccountName,
    toAccountEmail,
    formattedOriginalAmount,
    rate,
    rateExpiresAt,
    reason,
    invoiceNumber,
    invoiceDate,
    notes,
    sendConfirmationEmail,
    needsConversion,
    chargedAmount,
    setExpired,
    originalAmount,
    expired,
    onRefreshRate,
    loadingRate,
    buyOrSell,
    isRateLoading,
    error,
    transferMethod,
  } = useReview();

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <small className="tw-text-neutral-grey-2">Please confirm your transfer details.</small>
        <div className="tw-flex tw-flex-col tw-pt-8 tw-pb-4">
          <small className="tw-text-neutral-grey-2">Payee</small>
          <p className="tw-mt-1">{toAccountDisplayName}</p>
          <small className="tw-text-neutral-grey-2 tw-mt-4">From Account</small>
          <p className="tw-mt-1">{fromAccountDisplayName}</p>
          <small className="tw-text-neutral-grey-2 tw-mt-4">Payment Reason</small>
          <p className="tw-mt-1">{reason}</p>
          {invoiceNumber && invoiceDate && (
            <>
              <small className="tw-text-neutral-grey-2 tw-mt-4">Invoice Number</small>
              <p className="tw-mt-1">{invoiceNumber}</p>
              <small className="tw-text-neutral-grey-2 tw-mt-4">Invoice Date</small>
              <p className="tw-mt-1">{invoiceDate}</p>
            </>
          )}
          <small className="tw-text-neutral-grey-2 tw-mt-4">Amount Your Payee Will Receive</small>
          <p className="tw-mt-1">{loadingRate ? <Loaders.Spinner /> : formattedOriginalAmount}</p>
          {needsConversion && (
            <ExchangeRateInfo
              chargedAmount={chargedAmount}
              originalAmount={originalAmount}
              rate={rate}
              rateExpiresAt={rateExpiresAt}
              onRefreshRate={onRefreshRate}
              expired={expired}
              setExpired={setExpired}
              buyOrSell={buyOrSell}
              loadingRate={loadingRate}
              exchangeRateConfirmed={loading}
              chargedAmountSellLabel="Amount to Convert"
              chargedAmountBuyLabel="Amount to Convert"
              originalAmountSellLabel="Amount Your Payee Will Receive"
              originalAmountBuyLabel="Amount Your Payee Will Receive"
            />
          )}
          <small className="tw-text-neutral-grey-2 tw-mt-4">Method</small>
          <p className="tw-mt-1">{transferMethod}</p>
          {sendConfirmationEmail === 'true' && (
            <div className="tw-mt-6">
              <div className="tw-text-sm">Payment confirmation will be sent to:</div>
              <p className="tw-mt-1">
                {toAccountName} ({toAccountEmail})
              </p>
            </div>
          )}
          {notes && (
            <>
              <small className="tw-text-neutral-grey-2 tw-mt-4">Notes</small>
              <p className="tw-mt-1">{notes}</p>
            </>
          )}
        </div>
      </div>
      {error && <div className="tw-px-8 tw-mb-8 tw-my-4 tw-text-semantic-error">{error}</div>}
      <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex tw-flex-col lg:tw-flex-row tw-justify-between">
        <Button secondary onClick={onPreviousStep} className="tw-w-full lg:tw-w-max">
          Edit Payment Details
        </Button>
        <Button
          primary
          isDisabled={expired || loading || isRateLoading}
          onClick={onNextStep}
          className="tw-py-2 tw-px-4 tw-rounded-md tw-text-center tw-mt-4 lg:tw-mt-0 tw-w-full lg:tw-w-max"
          isFetching={loading || isRateLoading}
        >
          Pay Payee
        </Button>
      </div>
    </>
  );
};

export default Review;
