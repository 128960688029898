import React from 'react';

type CoinStackProps = {
  width?: number;
  height?: number;
};

const CoinStack = ({ width = 20, height = 20 }: CoinStackProps) => (
  <svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.775 5.66667C9.96444 5.66667 12.55 4.622 12.55 3.33333C12.55 2.04467 9.96444 1 6.775 1C3.58556 1 1 2.04467 1 3.33333C1 4.622 3.58556 5.66667 6.775 5.66667Z"
      fill="none"
    />
    <path
      d="M16.225 12.6667C19.4144 12.6667 22 11.622 22 10.3333C22 9.04467 19.4144 8 16.225 8C13.0356 8 10.45 9.04467 10.45 10.3333C10.45 11.622 13.0356 12.6667 16.225 12.6667Z"
      fill="none"
    />
    <path
      d="M10.45 10.3333C10.45 9.04467 13.0356 8 16.225 8C19.4144 8 22 9.04467 22 10.3333V19.6667C22 20.9553 19.4144 22 16.225 22C13.0356 22 10.45 20.9553 10.45 19.6667V10.3333Z"
      fill="none"
    />
    <path
      d="M6.775 1C3.58556 1 1 2.04467 1 3.33333V17.3333C1 18.622 3.58556 19.6667 6.775 19.6667C8.17111 19.6667 9.45151 19.4665 10.45 19.1333V10.3333C10.45 9.60875 11.2674 8.96131 12.55 8.53334V3.33333C12.55 2.04467 9.96444 1 6.775 1Z"
      fill="none"
    />
    <path
      d="M12.55 3.33333C12.55 4.622 9.96444 5.66667 6.775 5.66667C3.58556 5.66667 1 4.622 1 3.33333M12.55 3.33333C12.55 2.04467 9.96444 1 6.775 1C3.58556 1 1 2.04467 1 3.33333M12.55 3.33333V8.53334C11.2674 8.96131 10.45 9.60875 10.45 10.3333M1 3.33333V17.3333C1 18.622 3.58556 19.6667 6.775 19.6667C8.17111 19.6667 9.45151 19.4665 10.45 19.1333V10.3333M1 8C1 9.28866 3.58556 10.3333 6.775 10.3333C8.17111 10.3333 9.45151 10.1332 10.45 9.79999M1 12.6667C1 13.9553 3.58556 15 6.775 15C8.17111 15 9.45151 14.7998 10.45 14.4667M22 10.3333C22 11.622 19.4144 12.6667 16.225 12.6667C13.0356 12.6667 10.45 11.622 10.45 10.3333M22 10.3333C22 9.04467 19.4144 8 16.225 8C13.0356 8 10.45 9.04467 10.45 10.3333M22 10.3333V19.6667C22 20.9553 19.4144 22 16.225 22C13.0356 22 10.45 20.9553 10.45 19.6667V10.3333M22 15C22 16.2887 19.4144 17.3333 16.225 17.3333C13.0356 17.3333 10.45 16.2887 10.45 15"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CoinStack;
