import React, { memo } from 'react';

import { DetailsCardProps } from './DetailsCard.types';

const DetailsCard = ({ children, label, LabelIcon, subLabel, action, actionLabel = 'Edit' }: DetailsCardProps) => {
  return (
    <div className="tw-rounded-md tw-border tw-bg-neutral-light tw-border-neutral-grey-3.5 tw-px-3 lg:tw-px-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-py-4 tw-border-b tw-border-neutral-grey-3.5">
        <div className="tw-flex tw-items-center">
          {LabelIcon && <LabelIcon size={24} className="tw-mr-2" />}
          <div>
            <span className="tw-font-bold">{label}</span>
            {subLabel && <span className="tw-text-sm tw-text-neutral-grey-1 tw-ml-2">{subLabel}</span>}
          </div>
        </div>
        {action && (
          <button
            onClick={action}
            className="tw-text-neutral-grey-1 tw-border-b tw-border-neutral-grey-1 tw-ml-2 hover:tw-text-primary-dark-green hover:tw-border-primary-dark-green tw-transition tw-ease-in-out tw-duration-250"
          >
            {actionLabel}
          </button>
        )}
      </div>

      <div className="tw-py-4 lg:tw-pb-6">{children}</div>
    </div>
  );
};

export default memo(DetailsCard);
