import React from 'react';
import { BiCheck } from 'react-icons/bi';

import Clock from 'components/svg/Clock';
import { Loaders } from 'components/cards/Loader';

const USDBankAccountVerificationStatus = ({ loading, accountVerificationStatus }) => {
  if (loading) return <Loading />;
  const hasMembersData = accountVerificationStatus.members.length > 0;

  return (
    <div className="tw-p-8 tw-space-y-8">
      <div className="tw-space-y-4 tw-flex tw-flex-col">
        <small>
          Your Loop USD account verification is now in progress. Verification can take up to two business days. You will
          receive an email when your USD account has been verified and you can begin to make transactions to and from
          the account.
        </small>
        <small>
          Please make sure the correct identification documents were uploaded. If you want to upload new documents you
          can do so{' '}
          <span className="tw-text-primary-dark-green tw-underline tw-font-semibold">
            <a href="/dashboard/settings/documents">here</a>
          </span>
          .
        </small>
      </div>
      <div>
        {hasMembersData ? (
          accountVerificationStatus.members.map((member) => {
            return <MemberVerificationStatus key={member.fullName()} member={member} />;
          })
        ) : (
          <MembersDataError />
        )}
      </div>
    </div>
  );
};

const Loading = () => (
  <div className="tw-flex tw-items-center tw-justify-center tw-p-16">
    <Loaders.Small />
  </div>
);

const MemberVerificationStatus = ({ member }) => (
  <div className="tw-flex tw-items-end tw-justify-between">
    <div>
      <small className="tw-font-semibold">{member.fullName()}</small>
      <p>{member.formattedId()}</p>
    </div>
    <div className="tw-flex tw-items-center tw-space-x-2">
      {member.isAccepted() ? (
        <div className="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-dark-green tw-w-4 tw-h-4">
          <BiCheck className="tw-text-neutral-light" />
        </div>
      ) : (
        <Clock className="tw-w-4 tw-h-4 tw-text-neutral-grey-2" />
      )}
      <p>{member.isAccepted() ? 'Complete' : `Pending`}</p>
    </div>
  </div>
);

const MembersDataError = () => (
  <div>
    <small>
      We were unable to retrieve the statuses for the individual members verification at. Please try again later.
    </small>
  </div>
);

export default USDBankAccountVerificationStatus;
