import { gql } from '@apollo/client';
import { TRANSACTION_FIELDS } from './transactions';

export const GET_PAYEE_PAYMENT = gql`
  ${TRANSACTION_FIELDS}
  query PayeePayment($payeePaymentId: ID!) {
    payeePayment(payeePaymentId: $payeePaymentId) {
      id
      payment {
        amount
        currency
      }
      chargedPayment {
        amount
        currency
      }
      conversionRate
      payee {
        record {
          ... on Supplier {
            name
            displayName
            bankAccount {
              maskedNumber
            }
            address {
              street
              postalCode
              city
              country
              countrySubdivision
            }
            bankName
          }
          ... on CreditCardPayee {
            id
            cardType
            cardNumber
            displayName
            verified
            currency
          }
          ... on CRAAccount {
            id
            displayName
          }
        }
      }
      transactionTime
      transaction {
        ...TransactionFields
      }
    }
  }
`;

export const GET_PAYEE_PAYMENT_PDF = gql`
  query PayeePaymentPDF($payeePaymentId: ID!) {
    payeePaymentDownload(payeePaymentId: $payeePaymentId)
  }
`;

export const GET_PAYEE_PAYMENT_MT103_DOWNLOAD_URL = gql`
  query PayeePaymentMt103($payeePaymentId: ID!) {
    payeePaymentMt103Download(payeePaymentId: $payeePaymentId)
  }
`;

export const GET_PAYEE_PAYMENT_MT103 = gql`
  query PayeePaymentMT103($payeePaymentId: ID!) {
    payeePayment(payeePaymentId: $payeePaymentId) {
      id
      payeePaymentId
      mt103
    }
  }
`;
