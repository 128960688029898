import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { BsChevronLeft } from 'react-icons/bs';

import { useCardAutopay } from './hooks';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import { Content } from '../CardAutopay/components';
import { CardAutopayContextProvider } from './context/CardAutopay';

const CardAutopay = () => {
  const { onNavigateBack } = useCardAutopay();

  return (
    <DashboardLayout title="Configure Automatic Payments">
      <div className="tw-flex tw-flex-col tw-w-full tw-bg-neutral-light tw-rounded-md tw-py-8 lg:tw-py-0">
        <button
          className="tw-hidden lg:tw-flex tw-items-center tw-self-start tw-cursor-pointer tw-text-lg tw-font-semibold"
          onClick={onNavigateBack}
        >
          <BsChevronLeft size={14} className="tw-mr-2" />
          Back
        </button>
        <button className="tw-px-4 tw-cursor-pointer lg:tw-hidden">
          <BiArrowBack size={24} onClick={onNavigateBack} />
        </button>
        <CardAutopayContextProvider>
          <Content />
        </CardAutopayContextProvider>
      </div>
    </DashboardLayout>
  );
};

export default CardAutopay;
