import { useMemo } from 'react';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';
import { compareDesc } from 'date-fns';

import { PaymentRequest } from 'types/invoicing';
import { GET_PAYMENT_REQUESTS } from 'graphql/invoicing';

const usePaymentRequests = () => {
  const { data, loading } = useQuery<{ paymentRequests: PaymentRequest[] }>(GET_PAYMENT_REQUESTS);
  const paymentRequests = get(data, 'paymentRequests', []);

  const sortedPaymentRequests = useMemo(
    () => [...paymentRequests].sort((a, b) => compareDesc(new Date(a.createdAt), new Date(b.createdAt))),
    [paymentRequests]
  );

  const isEmpty = !sortedPaymentRequests.length && !loading;

  return { isEmpty, loading, paymentRequests: sortedPaymentRequests };
};

export default usePaymentRequests;
