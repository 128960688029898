import React from 'react';

const ExampleCardBack = (props) => {
  const { width = 228, height = 143, ...other } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 228 143" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M219.517 0H7.71258C3.6472 0 0.351562 3.29458 0.351562 7.35866V135.317C0.351562 139.381 3.6472 142.675 7.71258 142.675H219.517C223.582 142.675 226.878 139.381 226.878 135.317V7.35866C226.878 3.29458 223.582 0 219.517 0Z"
        fill="url(#paint0_linear_3849_126731)"
      />
      <path
        d="M140.224 71.1396L141.762 64.9714H141.048C140.746 65.7219 140.252 66.0056 139.281 66.0056L139.126 66.6462H140.508L139.391 71.1396H140.224ZM144.731 71.2678C146.717 71.2678 147.724 68.5406 147.724 66.8658C147.724 65.5205 147.111 64.8433 145.949 64.8433C144.42 64.8433 143.569 66.2069 143.569 67.1953C143.569 68.2935 144.192 68.989 145.207 68.989C145.766 68.989 146.287 68.7694 146.69 68.3667H146.708C146.296 69.8584 145.537 70.5356 144.759 70.5356C144.164 70.5356 143.871 70.2428 143.871 69.6662C143.871 69.6388 143.88 69.6022 143.88 69.5564H143.029C143.02 69.6479 143.02 69.7303 143.02 69.7852C143.02 70.6912 143.624 71.2678 144.731 71.2678ZM145.418 68.3026C144.796 68.3026 144.448 67.8542 144.448 67.1404C144.448 66.5181 144.933 65.5755 145.921 65.5755C146.58 65.5755 146.928 66.0056 146.928 66.7011C146.928 67.3692 146.415 68.3026 145.418 68.3026ZM149.87 71.2678C151.856 71.2678 152.863 68.5406 152.863 66.8658C152.863 65.5205 152.25 64.8433 151.087 64.8433C149.559 64.8433 148.708 66.2069 148.708 67.1953C148.708 68.2935 149.33 68.989 150.346 68.989C150.904 68.989 151.426 68.7694 151.829 68.3667H151.847C151.435 69.8584 150.676 70.5356 149.898 70.5356C149.303 70.5356 149.01 70.2428 149.01 69.6662C149.01 69.6388 149.019 69.6022 149.019 69.5564H148.168C148.159 69.6479 148.159 69.7303 148.159 69.7852C148.159 70.6912 148.763 71.2678 149.87 71.2678ZM150.557 68.3026C149.934 68.3026 149.587 67.8542 149.587 67.1404C149.587 66.5181 150.072 65.5755 151.06 65.5755C151.719 65.5755 152.067 66.0056 152.067 66.7011C152.067 67.3692 151.554 68.3026 150.557 68.3026Z"
        fill="white"
      />
      <rect
        x="19.9609"
        y="96.7461"
        width="35.9529"
        height="27.4549"
        rx="4.05287"
        fill="url(#paint1_linear_3849_126731)"
      />
      <g opacity="0.5">
        <rect x="19.9609" y="58.832" width="112.434" height="0.653688" fill="#C4C4C4" />
        <rect x="19.9609" y="63.4082" width="112.434" height="0.653688" fill="#C4C4C4" />
        <rect x="19.9609" y="67.9834" width="112.434" height="0.653688" fill="#C4C4C4" />
        <rect x="19.9609" y="72.5596" width="112.434" height="0.653688" fill="#C4C4C4" />
        <rect x="19.9609" y="77.1357" width="112.434" height="0.653688" fill="#C4C4C4" />
      </g>
      <rect x="0.351562" y="16.9961" width="226.503" height="30.7234" fill="black" />
      <path
        d="M172.5 68.5C172.5 72.5245 169.654 76.2646 164.843 79.0265C160.045 81.7806 153.385 83.5 146 83.5C138.615 83.5 131.955 81.7806 127.157 79.0265C122.346 76.2646 119.5 72.5245 119.5 68.5C119.5 64.4755 122.346 60.7354 127.157 57.9735C131.955 55.2194 138.615 53.5 146 53.5C153.385 53.5 160.045 55.2194 164.843 57.9735C169.654 60.7354 172.5 64.4755 172.5 68.5Z"
        stroke="#59B0CB"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3849_126731"
          x1="224.448"
          y1="43.3425"
          x2="36.3618"
          y2="160.986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B3B3C" />
          <stop offset="0.41" stopColor="#343334" />
          <stop offset="1" stopColor="#231F20" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3849_126731"
          x1="16.5369"
          y1="96.7461"
          x2="58.881"
          y2="124.113"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDD9D6" />
          <stop offset="0.212761" stopColor="#8A868C" />
          <stop offset="0.548384" stopColor="#F2F3F3" stopOpacity="0.8" />
          <stop offset="0.914316" stopColor="#807C80" />
          <stop offset="1" stopColor="#A6A8AB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ExampleCardBack;
