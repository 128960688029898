import React, { useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { Transition } from '@headlessui/react';

import config from 'config';
import Button from 'components/Button';
import CurrencyFlag from 'components/svg/CurrencyFlag';
import { Select, MoneyInputField } from 'components/FormFields/v2';
import { PayLocBalanceContext } from '../PayLocBalanceContext';
import { formatMoneyV2, centsFromMoneyString } from 'utility/currency';
import { classNames } from 'utility/string';
import { ReadOnly } from './shared';

const Details = () => {
  const {
    step,
    setStep,
    payload,
    setPayload,
    form,
    setError,
    clearErrors,
    formState,
    selectedPaymentDetail,
    formattedPaymentAmount,
    formattedFromAccountOptions,
    lineOfCredit = {},
    wallets,
    showAmountDue,
    formattedPaymentDateOptions,
    modifiedPayment,
    formattedNextPaymentDueDate,
  } = useContext(PayLocBalanceContext);
  const minRequiredPaymentAmountMoney = lineOfCredit.amountDue || {};
  const defaultMinRequiredPaymentAmountMoney = showAmountDue
    ? minRequiredPaymentAmountMoney
    : { amount: 100_00, currency: 'CAD' };
  const currentDate = new Date();
  const dueDate = new Date(formattedNextPaymentDueDate);

  const onNamedChange =
    (name) =>
    (e = {}) => {
      const value = e.target.value;
      setPayload((state = {}) => ({ ...state, [name]: value }));
      clearErrors(name);
    };

  const onSetPaymentAmount = (amt) => {
    const paymentAmount = centsFromMoneyString(amt);
    setPayload((state = {}) => ({ ...state, paymentAmount }));
    clearErrors('paymentAmount');
  };

  const isAmountFieldVisible = ['oneTimePayment', 'modifyPayment', 'internalTransfer'].includes(selectedPaymentDetail);
  const isPaymentDateFieldVisible = ['modifyPayment'].includes(selectedPaymentDetail);

  const onValidateForm = () => {
    const fromWallet = wallets.find((w) => w.id === payload.fromAccount);
    const paymentDetails = fromWallet ? 'internalTransfer' : payload.paymentDetails;
    setPayload({ ...payload, paymentDetails });

    if (!payload.fromAccount) {
      setError('fromAccount', { type: 'custom', message: 'This field is required' });
    }

    if (payload.paymentAmount < defaultMinRequiredPaymentAmountMoney.amount) {
      setError('paymentAmount', {
        type: 'custom',
        message: `Amount must be greater than or equal to ${formatMoneyV2(defaultMinRequiredPaymentAmountMoney)}`,
      });
    }

    if (fromWallet && payload.paymentAmount > fromWallet.availableBalance.amount) {
      setError('fromAccount', { type: 'manual', message: 'Insufficient funds' });
    }

    if (Object.keys(formState.errors).length) {
      return console.warn(formState.errors);
    }

    setStep(step + 1);
  };

  if (modifiedPayment && currentDate < dueDate) return <AlreadyModifiedOnceView />;

  return (
    <>
      <small className="tw-text-neutral-grey-2">Make a payment to your Line of Credit using the form below.</small>

      <FormProvider {...form}>
        <Select
          onChange={onNamedChange('fromAccount')}
          name="fromAccount"
          value={payload.fromAccount}
          label="From Account"
          options={formattedFromAccountOptions}
          placeholder="Please select from dropdown"
          style={{ color: '#000000' }}
        />

        <div>
          <div className="tw-text-neutral-grey-1 tw-mb-3">Payment Details</div>

          <SelectTiles />
        </div>

        <Transition
          show={isAmountFieldVisible}
          className="tw-flex tw-justify-between tw-gap-x-2"
          enter="tw-ease-in-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in-out tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <MoneyInputField
            type="tel"
            name="paymentAmount"
            value={formattedPaymentAmount}
            label="Payment Amount"
            currency={lineOfCredit.currency}
            setValue={onSetPaymentAmount}
            moneyFormatter={formatMoneyV2}
            rootClass="tw-w-3/4"
          />
          <div>
            <label className="tw-text-neutral-grey-1">Currency</label>

            <div className="tw-flex tw-items-center tw-gap-2 tw-bg-neutral-grey-4 tw-rounded-md tw-max-w-max tw-py-2 tw-px-3">
              <CurrencyFlag currency={lineOfCredit.currency} size={16} />
              <span>{lineOfCredit.currency}</span>
            </div>
          </div>

          {showAmountDue && (
            <small className="tw-text-neutral-grey-2 tw-text-xs tw-mt-2 tw-col-span-3">
              Must be at least {formatMoneyV2(minRequiredPaymentAmountMoney)}
            </small>
          )}
        </Transition>

        <Transition
          show={isPaymentDateFieldVisible}
          enter="tw-ease-in-out tw-duration-300"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-ease-in-out tw-duration-200"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Select
            onChange={onNamedChange('paymentDate')}
            name="paymentDate"
            value={payload.paymentDate}
            label="Payment Date"
            options={formattedPaymentDateOptions}
            placeholder="Please select from dropdown"
            rootClass="tw-mb-2"
            style={{ color: '#000000' }}
          />
          <small className="tw-text-neutral-grey-2 tw-text-xs">
            Your scheduled payment due date is {formattedNextPaymentDueDate}
          </small>
        </Transition>
      </FormProvider>

      <div className="tw-flex tw-justify-end">
        <Button primary className="tw-w-full lg:tw-w-max" onClick={onValidateForm}>
          Review
        </Button>
      </div>
    </>
  );
};

const SelectTile = ({ label, sublabel, value }) => {
  const { selectedPaymentDetail, setSelectedPaymentDetail, clearErrors } = useContext(PayLocBalanceContext);
  const isSelected = selectedPaymentDetail === value;

  const BASE_CLASSES =
    'tw-border tw-rounded-md tw-py-4 tw-px-2 tw-h-24 tw-flex tw-flex-col tw-justify-center tw-items-center lg:tw-h-20';
  const SELECTED_OPTION_CLASSES =
    'tw-border-primary-dark-green tw-ring tw-ring-secondary-pastel-green-100 tw-bg-secondary-pastel-green-80 tw-font-semibold';
  const UNSELECTED_OPTION_CLASSES = 'tw-border-neutral-grey-3';

  const buttonClassnames = classNames({
    [BASE_CLASSES]: true,
    [SELECTED_OPTION_CLASSES]: isSelected,
    [UNSELECTED_OPTION_CLASSES]: !isSelected,
  });

  const onSelectTile = () => {
    setSelectedPaymentDetail(value);
    clearErrors('paymentAmount');
  };

  return (
    <Button onClick={onSelectTile} className={buttonClassnames}>
      <div>{label}</div>
      <div>{sublabel}</div>
    </Button>
  );
};

const SelectTiles = () => {
  const { lineOfCredit, showAmountDue, formattedNextPaymentDueDate } = useContext(PayLocBalanceContext);

  return (
    <div className="tw-grid tw-grid-cols-2 tw-gap-4">
      {showAmountDue ? (
        <SelectTile label={`Modify ${formattedNextPaymentDueDate} Payment`} value="modifyPayment" />
      ) : (
        <>
          {lineOfCredit.outstandingBalance.amount >= 100000 && (
            <SelectTile
              label="Outstanding Balance"
              sublabel={<AmountSublabel isAbsolute amount={lineOfCredit.outstandingBalance} />}
              value="outstandingBalancePayment"
            />
          )}
          <SelectTile label="One-time Payment" value="oneTimePayment" />
        </>
      )}
    </div>
  );
};

const AmountSublabel = ({ isAbsolute, amount = {} }) => (
  <small className="tw-text-neutral-grey-1">{`(${amount.currency} ${formatMoneyV2(amount, isAbsolute)})`}</small>
);

const AlreadyModifiedOnceView = () => {
  const { lineOfCredit = {}, formattedNextPaymentDueDate } = useContext(PayLocBalanceContext);

  const minRequiredPaymentAmountMoney = lineOfCredit.amountDue;

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <small className="tw-text-neutral-grey-2">This payment has already been modified.</small>

      <ReadOnly label="Payment Amount" value={formatMoneyV2(minRequiredPaymentAmountMoney)} />

      <ReadOnly label="Payment Date" value={formattedNextPaymentDueDate} />

      <small>
        If you want to make a change to this payment again, please email us at{' '}
        <a className="tw-font-semibold" href={`mailto:${config.paymentsEmailAddress}`}>
          {config.paymentsEmailAddress}
        </a>
      </small>
    </div>
  );
};

export default Details;
