import { PayBalanceCurrency } from 'types/payments';
import { StatementStatus } from 'types/statement';

export const CURRENT_CYCLE = 'current_cycle';

export const DEFAULT_BALANCES = {
  [PayBalanceCurrency.CAD]: {
    cents: 0,
    currency_iso: PayBalanceCurrency.CAD,
  },
  [PayBalanceCurrency.USD]: {
    cents: 0,
    currency_iso: PayBalanceCurrency.USD,
  },
  [PayBalanceCurrency.EUR]: {
    cents: 0,
    currency_iso: PayBalanceCurrency.EUR,
  },
  [PayBalanceCurrency.GBP]: {
    cents: 0,
    currency_iso: PayBalanceCurrency.GBP,
  },
};

export const minDuePaidStatuses = [StatementStatus.MINIMUM_PAID];
export const fullPaidStatuses = [StatementStatus.PAID];

export const enum STATEMENT_BG_COLOR {
  current_cycle = 'tw-bg-secondary-pastel-green-100',
  requires_payment = 'tw-bg-secondary-pastel-yellow-50',
  paid = 'tw-bg-neutral-grey-4',
  min_due_paid = 'tw-bg-secondary-pastel-yellow-80',
  overdue = 'tw-bg-semantic-warning',
}
