import React from 'react';
import CardAndCalendar from 'components/svg/CardAndCoins';

import { Banner } from 'components/UI/';
import { BannerType } from 'components/UI/Banner';

const NoCardsBanner = ({ onSubmit }: { onSubmit: () => void }) => (
  <Banner
    type={BannerType.warning}
    icon={<CardAndCalendar width="90" height="100" />}
    title="Create your first Loop Card"
    message="You haven’t created a Loop Card yet. Create your first virtual card and instantly start spending."
    onSubmit={onSubmit}
    submitButtonLabel="Go to Cards"
    classNames="tw-mt-8"
  />
);

export default NoCardsBanner;
