import { CreditCardProcessorSettingsLimitsCadence } from 'types/creditCard';

export const getLimitTypeLabel = (limitType?: CreditCardProcessorSettingsLimitsCadence | null) => {
  switch (limitType) {
    case CreditCardProcessorSettingsLimitsCadence.monthly:
      return 'Monthly Limit';
    case CreditCardProcessorSettingsLimitsCadence.annually:
      return 'Annually Limit';
    case CreditCardProcessorSettingsLimitsCadence.lifetime:
      return 'Lifetime Limit';
    default:
      return '';
  }
};
