import { useState, useEffect } from 'react';
import { useDeepEffect } from 'hooks';

import { Option, SearchDropdownProps } from './SearchDropdown.types';

const useCommonSearchDropdown = ({
  unsortedItemsList,
  selectedVal,
  handleChange,
}: {
  unsortedItemsList?: Option[];
  selectedVal?: string;
  handleChange: (item: { id: string; name: string }) => void;
}): SearchDropdownProps => {
  const [queryString, setQueryString] = useState<string | null>(null);
  const [optionsList, setOptionsList] = useState<Option[]>([]);
  const [sortedItems, setSortedItems] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const selectOption = (option: Option) => {
    setQueryString(null);
    setSelectedOption(option);
    handleChange({ id: option.value, name: option.label });
  };

  const searchItems = (query: string) => {
    if (query) {
      setOptionsList(sortedItems?.filter((item) => item.label?.toLowerCase().includes(query.toLowerCase())) || []);
    }
  };

  useDeepEffect(() => {
    if (sortedItems) {
      const toSetOption = sortedItems.find((item) => `${item.value}` === selectedVal);
      if (toSetOption) setSelectedOption(toSetOption);
    }
  }, [selectedVal, sortedItems]);

  useDeepEffect(() => {
    if (unsortedItemsList) {
      const localItems = [...unsortedItemsList].sort((a, b) => {
        return a.label?.localeCompare(b.label);
      });

      setSortedItems(localItems);
      setOptionsList(localItems);
    }
  }, [unsortedItemsList]);

  useEffect(() => {
    if (queryString) {
      searchItems(queryString);
    } else if (sortedItems.length) {
      setOptionsList(sortedItems);
    }
  }, [queryString]);

  return {
    optionsList,
    onSelectOption: selectOption,
    onSearch: (value) => setQueryString(value),
    selectedOption,
    displayLabel: queryString || '',
  };
};

export default useCommonSearchDropdown;
