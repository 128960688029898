import React, { useContext, useState } from 'react';
import { IoCopyOutline, IoDownloadOutline } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';
import { useLazyQuery } from '@apollo/client';
import { AuthContext } from 'context/Auth';

import { useRebuildTooltipForModal } from 'components/InfoTooltip';
import { Loaders } from 'components/cards/Loader';
import { RadioField } from 'components/FormFields/v2';
import fileDownload from 'utility/fileDownload';
import { GET_BANK_DETAILS_PDF } from 'graphql/usBankDetails';
import { radioFieldOptionsEUR, radioFieldOptionsUK, accountDetailTypes } from './AddCCBankAccount/constants';

const CCBankAccountDetails = ({ loading, walletExternalAccount, currency }) => {
  const isReady = !!walletExternalAccount?.externalAccountsInfo?.length;
  if (loading) return <Loading />;

  return (
    <div className="tw-pb-4 tw-px-8" data-private>
      <h2 className="tw-py-4">{currency} Bank Account</h2>
      {!isReady && <AccountVerificationInProgress currency={currency} />}
      {isReady && (
        <AccountReady currency={currency} walletExternalAccounts={walletExternalAccount?.externalAccountsInfo} />
      )}
    </div>
  );
};

const Loading = () => (
  <div className="tw-flex tw-items-center tw-justify-center tw-p-16">
    <Loaders.Small />
  </div>
);

const AccountVerificationInProgress = ({ currency }) => {
  return (
    <div className="tw-space-y-8 tw-flex tw-flex-col">
      <small>
        Your Loop {currency} account verification is now in progress. You will receive an email when your {currency}{' '}
        account has been verified and you can begin to make transactions to and from the account.
      </small>
      <small>
        Please make sure the correct identification documents were uploaded. If you want to upload new documents you can
        do so{' '}
        <span className="tw-text-primary-dark-green tw-underline tw-font-semibold">
          <a href="/dashboard/settings/documents">here</a>
        </span>
        .
      </small>
    </div>
  );
};

const AccountReady = ({ currency, walletExternalAccounts }) => {
  useRebuildTooltipForModal();

  const { me } = useContext(AuthContext);
  const { account } = me;

  const onGetDownloadUrl = (data) => {
    const { downloadUrl } = (data && data.proofOfAccountDetailsDownload) || {};
    try {
      fileDownload(downloadUrl, `proof_of_account_details_${account && account.name}.pdf`);
    } catch (error) {
      console.error(error);
    }
  };

  const regularAccount = walletExternalAccounts.find((account) => account.paymentType === 'regular');
  const priorityAccount = walletExternalAccounts.find((account) => account.paymentType === 'priority');

  const [selectedAccount, setSelectedAccount] = useState(regularAccount);

  const defaultSelected = currency === 'EUR' ? 'sepa_payment' : 'uk_faster_payments';
  const [selectedTypeOfAccountDetails, setSelectedTypeOfAccountDetails] = useState(defaultSelected);

  const ukFasterPayments = selectedTypeOfAccountDetails === accountDetailTypes.UK_FASTER_PAYMENTS;
  const eurswiftPayment = selectedTypeOfAccountDetails === accountDetailTypes.SWIFT_PAYMENT && currency === 'EUR';
  const ukswiftPayment = selectedTypeOfAccountDetails === accountDetailTypes.SWIFT_PAYMENT && currency === 'GBP';
  const sepaPayment = selectedTypeOfAccountDetails === accountDetailTypes.SEPA_PAYMENT && currency === 'EUR';
  const iban = eurswiftPayment || sepaPayment || ukswiftPayment;

  const [getUrl, { loading }] = useLazyQuery(GET_BANK_DETAILS_PDF, {
    onCompleted: onGetDownloadUrl,
    variables: { currency: currency },
  });

  const radioFieldOptions = currency === 'EUR' ? radioFieldOptionsEUR : radioFieldOptionsUK;

  const handleSwitchType = (value) => {
    setSelectedTypeOfAccountDetails(value);
    setSelectedAccount(value === 'swift_payment' ? priorityAccount : regularAccount);
  };

  if (loading) return <Loading />;

  return (
    <div className="tw-space-y-8" data-private>
      <div className="tw-flex tw-flex-col tw-space-y-4">
        <div onClick={getUrl} className="tw-flex tw-items-center tw-cursor-pointer">
          <IoDownloadOutline size={18} className="tw-text-primary-dark-green tw-mr-1" />
          <div className="tw-text-sm tw-text-primary-dark-green tw-font-semibold">
            Download {currency} Proof of Account Details
          </div>
        </div>
        <small className="tw-text-neutral-grey-2">
          Use these account details to get funds paid by your customers or payment processors directly into your Loop{' '}
          {currency} Bank Account.
        </small>

        <RadioField
          rootClass="tw-mt-2 tw-mb-4"
          label="type of account details"
          name="typeOfaccountDetails"
          options={radioFieldOptions}
          onClick={(e) => handleSwitchType(e.target.value)}
        />

        {ukFasterPayments && (
          <small className="tw-text-neutral-grey-2">
            Accepts payments up to £1,000,000 originating in the UK. Funds typically arrive within a few minutes of
            being sent, 24/7.
          </small>
        )}

        {ukswiftPayment && (
          <small className="tw-text-neutral-grey-2">
            For payments above £1,000,000 or those originating outside the UK.
          </small>
        )}

        {eurswiftPayment && (
          <small className="tw-text-neutral-grey-2">
            Used for internation wire payments or high value transactions.
          </small>
        )}

        {sepaPayment && (
          <small className="tw-text-neutral-grey-2">
            For euro payments to anywhere in the European Union, as well as a number of non-EU countries, similar to a
            domestic payment.
          </small>
        )}

        <div className="tw-space-y-4">
          <CopyableField title="Account Holder Name" value={selectedAccount?.accountHolderName} />
          {!iban && <CopyableField title="Account Number" value={selectedAccount?.accountNumber} />}
          {iban && (
            <>
              <CopyableField title="IBAN" value={selectedAccount?.accountNumber} />
              <CopyableField title="Swift Code / BIC" value={selectedAccount?.routingCode} />
            </>
          )}
          {!iban && <CopyableField title="Sort Code" value={parseSortCode(selectedAccount?.routingCode)} />}
          {eurswiftPayment && <CopyableField title="Bank Address" value={selectedAccount?.bankAddress} />}
          {(ukswiftPayment || eurswiftPayment) && <CopyableField title="Payment Network" value={'SWIFT'} />}
          {ukFasterPayments && <CopyableField title="Payment Network" value={'UK Faster Payments'} />}
          {sepaPayment && <CopyableField title="Payment Network" value={'SEPA'} />}
          <span className="tw-text-xs">
            Note: Incoming payments may take 1 business day to be added to your account
          </span>
        </div>
      </div>
    </div>
  );
};

const parseSortCode = (sortCode) => {
  const first = sortCode?.slice(0, 2);
  const second = sortCode?.slice(2, 4);
  const third = sortCode?.slice(4, 6);
  return `${first}-${second}-${third}`;
};

const CopyableField = ({ value, title }) => {
  const onCopy = () => navigator.clipboard.writeText(value);

  return (
    <div>
      <small className="tw-text-neutral-grey-1">{title}</small>
      <div className="tw-flex tw-items-center tw-space-x-2">
        <small>{value}</small>
        <IoCopyOutline
          className="tw-text-primary-dark-green tw-cursor-pointer"
          onClick={onCopy}
          data-tip="Copied!"
          data-for={`tooltip-${title}`}
        />
        <ReactTooltip
          id={`tooltip-${title}`}
          event="mouseup"
          eventOff="mouseout"
          delayHide={500}
          textColor="var(--colors-natural-light)"
          backgroundColor="var(--colors-natural-grey-1)"
        />
      </div>
    </div>
  );
};
export default CCBankAccountDetails;
