import React, { useContext, useMemo } from 'react';
import { Transition } from '@headlessui/react';

import { TransactionsContext } from 'context/Transactions';
import Close from 'components/svg/Close';

import { TransactionDetail } from '../';
import './TransactionDetailOverlay.css';

const TransactionDetailOverlay = ({ accountNames, productType }) => {
  const { selectedTransactionId, setSelectedTransactionId, transactions } = useContext(TransactionsContext);

  const transaction = useMemo(
    () => transactions?.find((transaction) => transaction.transactionId === selectedTransactionId),
    [transactions, selectedTransactionId]
  );
  const onCloseOverlay = () => setSelectedTransactionId(null);

  return (
    <Transition
      show={!!transaction}
      as="div"
      className="tw-h-screen tw-w-1/4 tw-fixed tw-top-0 tw-right-0 tw-shadow-notification transaction-detail-index tw-bg-neutral-light tw-transform"
      enter="tw-ease-in-out tw-duration-300"
      enterFrom="tw-translate-x-96"
      enterTo="tw-translate-x-0"
      leave="tw-ease-in-out tw-duration-300"
      leaveFrom="tw-translate-x-0"
      leaveTo="tw-translate-x-96"
    >
      <TransactionDetail
        transaction={transaction}
        accountNames={accountNames}
        productType={productType}
        header={
          <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
            <div className="tw-text-sm tw-text-neutral-grey-2">Transaction details</div>
            <Close className="tw-cursor-pointer" onClick={onCloseOverlay} />
          </div>
        }
      />
    </Transition>
  );
};

export default TransactionDetailOverlay;
