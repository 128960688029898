import React from 'react';

const DetailsFormSkeleton = () => {
  return (
    <>
      <h1 className="tw-mb-6">Payment Details Form</h1>
      <div className="tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-h-32 tw-w-100 tw-mb-8" />
      <div className="tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-h-10 tw-w-100 tw-mb-4" />
      <div className="tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-h-10 tw-w-100 tw-mb-4" />
      <div className="tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-h-10 tw-w-56 tw-ml-auto" />
    </>
  );
};

export default DetailsFormSkeleton;
