import React from 'react';

import { OnboardingDashboardLayout, PreviewScreen } from '../';
import cardScreenshotBlur from 'images/screenshot-cards-page-blurred.png';

const Cards = () => {
  return (
    <OnboardingDashboardLayout>
      <PreviewScreen bgImage={cardScreenshotBlur} />
    </OnboardingDashboardLayout>
  );
};

export default Cards;
