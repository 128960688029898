import { APPROVAL_TYPE } from 'components/Approvals/constants';
import { Currencies } from 'constants/currencies';
import { Money } from 'types/payments';

export type DatePeriod = {
  from: Date;
  to: Date;
  key: string;
};

export type ReimbursementApprovalRequestDataType = {
  id: string;
  requester: { name: string; email: string };
  amountRequested: Money<Currencies>;
  originalAmount: Money<Currencies>;
  merchantName: string;
  transactionDate: Date;
  status: string;
  description?: string;
  category?: string;
  taxAmount?: Money<Currencies>;
  dateApproved?: Date;
  createdAt: Date;
  approver?: { name: string; email: string };
  receipt?: string;
  declineReason?: string;
  defaultApprover?: { name: string; email: string };
  __typename: string;
};

export type CardExpenseRequestDataType = {
  transactionId: string;
  requester: {
    name: string;
  };
  fromLabel: string;
  merchantName: string;
  amountRequested: Money<Currencies>;
  receipt?: string;
  description?: string;
  transactionDate: Date;
  dateApproved?: Date;
  status: string;
  declineReason?: string;
  __typename: string;
};

export type CompletedRequestDataType = {
  id: string;
  requester: { name: string };
  amountRequested: Money<Currencies>;
  originalAmount: Money<Currencies>;
  taxAmount?: Money<Currencies>;
  fromLabel?: string;
  approver: {
    name: string;
  };
  merchantName: string;
  status: string;
  description: string;
  dateApproved: Date;
  transactionDate: Date;
  updatedAt: Date;
  type: string;
  receipt?: string;
  approvedAt: Date;
  createdAt: Date;
  transactionId?: string;
  __typename: string;
};

export function isReimbursementRequestList(
  data: (ReimbursementApprovalRequestDataType | CardExpenseRequestDataType | CompletedRequestDataType)[]
): data is ReimbursementApprovalRequestDataType[] {
  return Array.isArray(data) && data.length > 0 && 'id' in data[0] && data[0].__typename === 'ExpenseReimbursement';
}
export function isCardExpenseRequestList(
  data: (ReimbursementApprovalRequestDataType | CardExpenseRequestDataType | CompletedRequestDataType)[]
): data is CardExpenseRequestDataType[] {
  return Array.isArray(data) && data.length > 0 && 'transactionId' in data[0] && data[0].__typename === 'CardExpense';
}

export function isCardExpenseRequestDataType(cardExpense: object): cardExpense is CardExpenseRequestDataType {
  return (
    ('transactionId' in cardExpense &&
      (cardExpense as CardExpenseRequestDataType).__typename === APPROVAL_TYPE.cardExpense) ||
    ('type' in cardExpense && (cardExpense as CompletedRequestDataType).type === 'CardExpense')
  );
}

export function isCompletedExpenseRequestDataType(
  completedExpense: object
): completedExpense is CompletedRequestDataType {
  return (
    '__typename' in completedExpense &&
    (completedExpense as CompletedRequestDataType).__typename === APPROVAL_TYPE.expense
  );
}
