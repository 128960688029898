import React, { useContext, useState } from 'react';
import { IoCopyOutline, IoDownloadOutline } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';
import { useLazyQuery } from '@apollo/client';
import { AuthContext } from 'context/Auth';
import { toast } from 'react-toastify';

import { useRebuildTooltipForModal } from 'components/InfoTooltip';
import { RadioField } from 'components/FormFields/v2';
import fileDownload from 'utility/fileDownload';
import { Loaders } from 'components/cards/Loader';
import { GET_BANK_DETAILS_PDF } from 'graphql/usBankDetails';
import { radioFieldOptionsCAD } from './AddCCBankAccount/constants';

const CADBankAccountDetails = ({ loading, walletExternalAccount, currency }) => {
  const isReady = !!walletExternalAccount?.externalAccountsInfo?.length;

  const isCAD = currency === 'CAD' && isReady;

  if (loading) return <Loading />;

  return (
    <div className="tw-pb-4 tw-px-8" data-private>
      <h2 className="tw-py-4">Business Operating Account</h2>
      {!isReady && <AccountVerificationInProgress currency={currency} />}
      {isCAD && (
        <CADBankAccountReady currency={currency} walletExternalAccounts={walletExternalAccount?.externalAccountsInfo} />
      )}
    </div>
  );
};

const CADBankAccountReady = ({ currency, walletExternalAccounts }) => {
  useRebuildTooltipForModal();

  const { me } = useContext(AuthContext);
  const { account } = me;

  const onGetDownloadUrl = (data) => {
    try {
      const { downloadUrl } = data?.proofOfAccountDetailsDownload || {};
      if (!downloadUrl) throw new Error('No download URL found');

      fileDownload(downloadUrl, `proof_of_account_details_${account?.name?.toLowerCase()?.replaceAll(' ', '_')}.pdf`);
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong, please try again later.');
    }
  };

  const regularAccount = walletExternalAccounts.find((account) => account.paymentType === 'regular');
  const priorityAccount = walletExternalAccounts.find((account) => account.paymentType === 'priority');

  const [selectedAccount, setSelectedAccount] = useState(regularAccount);

  const [getUrl, { loading: documentLoading }] = useLazyQuery(GET_BANK_DETAILS_PDF, {
    onCompleted: onGetDownloadUrl,
    variables: { currency },
  });

  const radioFieldOptions = radioFieldOptionsCAD;

  const handleSwitchType = (value) => {
    setSelectedAccount(value === 'swift_payment' ? priorityAccount : regularAccount);
  };

  if (documentLoading) return <Loading />;

  return (
    <div className="tw-space-y-8" data-private>
      {selectedAccount?.accountNumber && (
        <div className="tw-flex tw-flex-col tw-space-y-4">
          <div onClick={getUrl} className="tw-flex tw-items-center tw-cursor-pointer">
            <IoDownloadOutline size={18} className="tw-text-primary-dark-green tw-mr-1" />
            <div className="tw-text-sm tw-text-primary-dark-green tw-font-semibold">
              Download CAD Proof of Account Details
            </div>
          </div>
          <small className="tw-text-neutral-grey-2">
            Use these account details to get funds paid by your customers or payment processors directly into your Loop
            Business Operating Account.
          </small>

          <RadioField
            rootClass="tw-mt-2 tw-mb-4"
            label="type of account details"
            name="typeOfAccountDetails"
            options={radioFieldOptions}
            onClick={(e) => handleSwitchType(e.target.value)}
          />

          <small className="tw-text-neutral-grey-2">
            Receive local payments or pay with pre-authorized debit anywhere in Canada.
          </small>

          <div className="tw-text-sm tw-font-bold">
            Note: Domestic and International Wires into and out of the account is not supported.
          </div>

          <div className="tw-space-y-4">
            <CopyableField title="Account Holder Name" value={selectedAccount?.accountHolderName} />
            <CopyableField title="Account Number" value={selectedAccount?.accountNumber} />
            <CopyableField title="Institution Name" value={selectedAccount?.bankName} />
            <CopyableField title="Institution Number" value={selectedAccount?.routingCode.split(' ')[0]} />
            <CopyableField title="Transit Number" value={selectedAccount?.routingCode.split(' ')[1]} />
            <CopyableField title="Payment Network" value="CAD EFT" />
          </div>
        </div>
      )}
      {!selectedAccount?.accountNumber && (
        <div className="tw-flex tw-flex-col tw-space-y-4">
          <div className="tw-text-sm">
            We are unable to retrieve your CAD Business Operating Account details. A Loop team member will contact you
            to finalize the process of opening your CAD Business Operating Account and provide you with your account
            details.
          </div>
        </div>
      )}
    </div>
  );
};

const Loading = () => (
  <div className="tw-flex tw-items-center tw-justify-center tw-p-16">
    <Loaders.Small />
  </div>
);

const AccountVerificationInProgress = ({ currency }) => {
  return (
    <div className="tw-space-y-8 tw-flex tw-flex-col">
      <small>
        Your Loop {currency} account verification is now in progress. You will receive an email when your {currency}{' '}
        account has been verified and you can begin to make transactions to and from the account.
      </small>
      <small>
        Please make sure the correct identification documents were uploaded. If you want to upload new documents you can
        do so{' '}
        <span className="tw-text-primary-dark-green tw-underline tw-font-semibold">
          <a href="/dashboard/settings/documents">here</a>
        </span>
        .
      </small>
    </div>
  );
};

const CopyableField = ({ value, title }) => {
  const onCopy = () => navigator.clipboard.writeText(value);

  return (
    <div>
      <small className="tw-text-neutral-grey-1">{title}</small>
      <div className="tw-flex tw-items-center tw-space-x-2">
        <small>{value}</small>
        <IoCopyOutline
          className="tw-text-primary-dark-green tw-cursor-pointer"
          onClick={onCopy}
          data-tip="Copied!"
          data-for={`tooltip-${title}`}
        />
        <ReactTooltip
          id={`tooltip-${title}`}
          event="mouseup"
          eventOff="mouseout"
          delayHide={500}
          textColor="var(--colors-natural-light)"
          backgroundColor="var(--colors-natural-grey-1)"
        />
      </div>
    </div>
  );
};

export default CADBankAccountDetails;
