import React from 'react';
import { BiDollar } from 'react-icons/bi';
import { BsSend } from 'react-icons/bs';
import { IoRepeatOutline } from 'react-icons/io5';

import { useQuickActions } from './hooks';
import { SCOPE } from 'constants/index';
import { Loaders } from 'components/cards/Loader';
import PermissionChecker from 'components/PermissionChecker';
import Button from 'components/Button';
import CardOutline from 'components/svg/CardOutline';
import { CreateCardContextProvider } from 'components/creditCards/createCardFlow/CreateCardContext';
import CreateCardModal from 'components/creditCards/createCardFlow/CreateCardModal';
import { AddFundsCurrencySelectorModal, ConvertFundsCurrencySelectorModal } from './components';

const QuickActions = () => {
  const {
    loading,
    allowCardCreation,
    creditCards,
    globalCreditLimit,
    show,
    closeCreateCardModal,
    handleCreateCardClick,
    showAddFundsCurrencySelectorModal,
    openAddFundsCurrencySelectorModal,
    closeAddFundsCurrencySelectorModal,
    showConvertFundsCurrencySelectorModal,
    openConvertFundsCurrencySelectorModal,
    closeConvertFundsCurrencySelectorModal,
    navigateToSendPayments,
    isLoadingCardsData,
  } = useQuickActions();

  if (loading) return <Loaders.Spinner />;

  return (
    <>
      <ul className="tw-flex tw-flex-wrap tw-justify-end tw-gap-2">
        {allowCardCreation && (
          <li>
            <PermissionChecker scope={SCOPE.manageCards}>
              <Button primary onClick={handleCreateCardClick} className="tw-w-max">
                <CardOutline className="tw-mr-2" /> Create Card
              </Button>
            </PermissionChecker>
          </li>
        )}
        <li>
          <PermissionChecker scope={SCOPE.managePayments}>
            <Button primary onClick={openConvertFundsCurrencySelectorModal} className="tw-w-max">
              <IoRepeatOutline className="tw-mr-2" size={20} /> Convert Funds
            </Button>
          </PermissionChecker>
        </li>
        <li>
          <PermissionChecker scope={SCOPE.managePayments}>
            <Button primary onClick={openAddFundsCurrencySelectorModal} className="tw-w-max">
              <BiDollar className="tw-mr-1" size={20} /> Add Funds
            </Button>
          </PermissionChecker>
        </li>
        <li>
          <PermissionChecker scope={SCOPE.managePayments}>
            <Button primary onClick={navigateToSendPayments} className="tw-w-max">
              <BsSend className="tw-mr-2" /> Send Payments
            </Button>
          </PermissionChecker>
        </li>
      </ul>

      {allowCardCreation && (
        <CreateCardContextProvider>
          <CreateCardModal
            show={show}
            onClose={closeCreateCardModal}
            creditCards={creditCards}
            globalCreditLimit={globalCreditLimit}
            isLoadingCardsData={isLoadingCardsData}
          />
        </CreateCardContextProvider>
      )}

      <AddFundsCurrencySelectorModal
        show={showAddFundsCurrencySelectorModal}
        onClose={closeAddFundsCurrencySelectorModal}
      />
      <ConvertFundsCurrencySelectorModal
        show={showConvertFundsCurrencySelectorModal}
        onClose={closeConvertFundsCurrencySelectorModal}
      />
    </>
  );
};

export default QuickActions;
