import React, { useState, useContext } from 'react';
import { IoTicketOutline, IoGiftOutline } from 'react-icons/io5';
import { BsArrowRight } from 'react-icons/bs';
import { useMutation } from '@apollo/client';

import Button from 'components/Button';
import Banner, { BANNER_TYPES } from 'components/Banner';
import { OfferDetailsModal } from './OfferDetailsModal';
import { RewardsContext } from '../contexts/RewardsContext';
import { CREATE_PROMOTION_ENROLLMENT, GET_POINTS_AND_REWARDS_INFO } from 'graphql/creditOffer';

export const Offers = () => {
  const [showOfferDetailsModal, setShowOfferDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState();

  const [createPromotionEnrollment] = useMutation(CREATE_PROMOTION_ENROLLMENT, {
    refetchQueries: [GET_POINTS_AND_REWARDS_INFO],
  });

  const onOpen = (offer) => {
    setSelectedOffer(offer);
    setShowOfferDetailsModal(true);
  };

  const onSelectCurrentOffer = async (offer) => {
    setLoading(true);
    setSelectedOffer(offer);
    await createPromotionEnrollment({ variables: { promotionId: offer.id } });
    // await refetch();
    setLoading(false);
  };

  const { rewardsAccount } = useContext(RewardsContext);
  const { offers } = rewardsAccount || { offers: [] };
  if (!offers || offers.length === 0) return null;

  return (
    <div className="tw-space-y-4 tw-mt-8 tw-w-full">
      <p>Your Offers</p>
      {offers.map((offer) => {
        return (
          <div
            className="tw-flex tw-flex-col tw-rounded-md tw-border-neutral-grey-4 tw-border-2 tw-p-4 tw-space-y-6"
            key={offer.id}
          >
            <div className="tw-flex tw-space-x-4">
              <div className="tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-rounded-md tw-py-1 tw-px-4 tw-border-2 tw-border-neutral-grey-4 tw-h-20 tw-w-44">
                <img className="tw-object-contain tw-h-16" src={offer.imageUrl} />
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-space-y-2">
              <div className="tw-flex tw-items-center tw-space-x-2">
                <IoTicketOutline />
                <small>{offer.subtext}</small>
              </div>
              <div className="tw-flex tw-items-center tw-space-x-2">
                <IoGiftOutline className="tw-text-semantic-success" />
                <small className="tw-text-semantic-success">{offer.mainDetails}</small>
              </div>
            </div>
            <div>
              <small className="tw-cursor-pointer tw-underline" onClick={() => onOpen(offer)}>
                Learn more about this offer
              </small>
              <OfferDetailsModal
                show={showOfferDetailsModal}
                setShow={setShowOfferDetailsModal}
                offer={selectedOffer}
              />
            </div>
            {offer.activated ? (
              <Banner isShowIcon type={BANNER_TYPES.success} message="This offer is active on your Loop Card." />
            ) : (
              <Button
                primary
                className="tw-w-max"
                onClick={() => onSelectCurrentOffer(offer)}
                disabled={selectedOffer === offer && loading}
              >
                <div className="tw-flex tw-items-center tw-space-x-2">
                  <p className="tw-text-neutral-light">
                    {selectedOffer === offer && loading ? 'Submitting...' : 'Activate Offer'}
                  </p>
                  <BsArrowRight />
                </div>{' '}
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
};
