import React, { useContext } from 'react';

import { Header, Steps, CardCreationLimit } from './components';
import { CreateCardContext } from '../../context/CreateCardContext';

const CreateCard = () => {
  const { stepTitle, onClose, showLimit, enableCloseBtn } = useContext(CreateCardContext);

  return (
    <div className="tw-flex tw-h-full tw-flex-grow tw-flex-col">
      {showLimit ? (
        <CardCreationLimit />
      ) : (
        <div className="tw-flex tw-flex-grow tw-flex-col tw-items-center">
          <Header onClose={onClose} title={stepTitle} enableCloseBtn={enableCloseBtn} />
          <Steps />
        </div>
      )}
    </div>
  );
};

export default CreateCard;
