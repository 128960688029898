import React, { useState } from 'react';

import { useToggle } from 'hooks';
import { salesChannelIconMap } from 'components/settings/Integrations/constants';
import SuccessCheckmark from 'components/svg/SuccessCheckmark';
import Banner, { BANNER_TYPES } from 'components/Banner';
import Button from 'components/Button';
import Refresh from 'components/svg/Refresh';
import { getId } from 'utility/string';
import PermissionChecker from 'components/PermissionChecker';
import { SCOPE } from 'constants/index';
import ReconnectButton from 'components/settings/Integrations/components/ReconnectButton';
import { DisconnectModal } from 'components/settings/Integrations/components/Modals';

const ExternalAccounts = ({ accounts, reload, onShowConfigure, accounting = false }) => {
  const [externalAccount, setExternalAccount] = useState();

  const { isOpen: showDisconnectModal, open: setShowDisconnectModal, close: onHideDisconnectModal } = useToggle();

  const iconForSalesChannel = (channel) => salesChannelIconMap[channel];

  return (
    <>
      {accounts.map((account) => {
        const logo = iconForSalesChannel(account.name);
        const ownerName = account.ownerName || '';

        const onShowDisconnectModal = () => {
          setExternalAccount(account);
          setShowDisconnectModal(true);
        };

        return (
          <div key={account.id} className="channel-integrations tw-flex tw-flex-col tw-w-64 tw-mr-3">
            <div className="tw-border tw-border-neutral-grey-3 tw-bg-neutral-grey-4 tw-rounded-md tw-min-h-full tw-flex tw-flex-col tw-justify-evenly tw-p-2">
              <div
                className="tw-flex tw-flex-col tw-items-center tw-w-full
              "
              >
                {logo}
                {account.connected ? (
                  <div className="text-center">
                    <div className="tw-flex tw-flex-row">
                      <small className="tw-mr-2 tw-font-semibold tw-mb-2 tw-text-semantic-success">Connected</small>
                      <SuccessCheckmark />
                    </div>
                    {account?.companyInfo?.isActionRequired && (
                      <Banner className="tw-mb-2" isShowIcon type={BANNER_TYPES.error} message=" Requires Action" />
                    )}
                    {onShowConfigure && (
                      <Button onClick={onShowConfigure} primary className="tw-text-sm">
                        Configure
                      </Button>
                    )}
                  </div>
                ) : (
                  <div className="tw-flex tw-flex-row">
                    <small className="tw-mr-2 tw-font-semibold tw-mb-4 tw-text-neutral-grey-2">Disconnected</small>
                    <Refresh />
                  </div>
                )}
              </div>
              <div className="tw-flex tw-flex-col tw-text-neutral-dark tw-p-4">
                <span className="tw-text-xs tw-mb-2">{ownerName}</span>
                <span className="tw-text-xs tw-break-all">{account.uid}</span>
                <span className="tw-text-xs">
                  {account?.companyInfo ? (
                    <>
                      <div className="tw-font-bold">{account.companyInfo?.name}</div>
                      {account.companyInfo?.lastPushedAt && (
                        <>
                          <div className="tw-mt-2">Data Last Pushed Date</div>
                          <div className="tw-font-bold">
                            {new Date(account.companyInfo?.lastPushedAt).toLocaleString()}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    `ID ${getId(account.id)}`
                  )}
                </span>
              </div>
            </div>

            <div className="tw-flex tw-flex-row tw-justify-between">
              <PermissionChecker scope={SCOPE.manageExternalAccounts}>
                {account.connected ? (
                  <Button onClick={onShowDisconnectModal}>
                    <span className="tw-text-xs tw-text-neutral-grey-2 hover:tw-text-semantic-error">
                      {accounting ? `Disconnect from ${account.name}` : 'Disconnect'}
                    </span>
                  </Button>
                ) : (
                  <ReconnectButton externalAccount={account} />
                )}
              </PermissionChecker>
            </div>
          </div>
        );
      })}
      <DisconnectModal
        show={showDisconnectModal}
        onClose={onHideDisconnectModal}
        externalAccount={externalAccount}
        onDisconnect={reload}
      />
    </>
  );
};

export default ExternalAccounts;
