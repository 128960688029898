import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const ChecklistAndMagnifier = ({ width = '56', height = '59', vbWidth = '56', vbHeight = '59', ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      {...other}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2109 4.99986C11.306 4.92328 11.4198 5.05928 11.3557 5.17278L9.07369 9.21265C9.04991 9.25475 9.04991 9.30989 9.07369 9.35199L11.3557 13.3919C11.4198 13.5054 11.306 13.6414 11.2109 13.5648L7.82883 10.839C7.79359 10.8105 7.74743 10.8105 7.71218 10.839L4.33009 13.5648C4.23506 13.6414 4.12121 13.5054 4.18532 13.3919L6.46733 9.35199C6.49111 9.30989 6.49111 9.25475 6.46733 9.21265L4.18532 5.17278C4.12121 5.05928 4.23506 4.92328 4.33009 4.99986L7.71218 7.72569C7.74743 7.75409 7.79359 7.75409 7.82883 7.72569L11.2109 4.99986Z"
        fill={primaryDarkGreenColor}
      />
      <rect x="11.7402" y="16.2939" width="22.8536" height="30.3314" rx="1.14866" fill="#F7D298" />
      <rect
        x="9.48834"
        y="20.3731"
        width="22.2792"
        height="29.7571"
        rx="0.861496"
        stroke="#4F5154"
        strokeWidth="0.574331"
      />
      <line
        x1="18.376"
        y1="27.3818"
        x2="27.324"
        y2="27.3818"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
      />
      <path
        d="M16.1839 26.1523L14.0017 28.4272L13.0098 27.3932"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="18.376"
        y1="32.6884"
        x2="27.324"
        y2="32.6884"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
      />
      <path
        d="M16.1839 31.459L14.0017 33.7338L13.0098 32.6998"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="18.376"
        y1="37.998"
        x2="27.324"
        y2="37.998"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
      />
      <path
        d="M16.1839 36.7686L14.0017 39.0434L13.0098 38.0094"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.9066 16.7989C50.0263 16.7133 50.1572 16.8877 50.073 17.0206L45.6659 23.9745C45.6346 24.0238 45.6316 24.0908 45.6581 24.1439L49.4069 31.6284C49.4785 31.7715 49.3326 31.9279 49.2213 31.8272L43.3996 26.563C43.3583 26.5257 43.3022 26.522 43.2578 26.5538L36.9919 31.0316C36.8721 31.1172 36.7412 30.9428 36.8255 30.8099L41.2326 23.856C41.2638 23.8067 41.2669 23.7397 41.2403 23.6866L37.4916 16.202C37.4199 16.059 37.5659 15.9026 37.6772 16.0033L43.4988 21.2675C43.5401 21.3048 43.5962 21.3085 43.6407 21.2767L49.9066 16.7989Z"
        fill="#EEA530"
      />
      <line
        x1="18.376"
        y1="43.3046"
        x2="27.324"
        y2="43.3046"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
      />
      <path
        d="M16.1839 42.0752L14.0017 44.3501L13.0098 43.316"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.0381 46.6253C39.0381 44.9756 38.6285 43.3629 37.8612 41.9912C37.0939 40.6195 36.0033 39.5504 34.7273 38.9191C33.4514 38.2878 32.0473 38.1226 30.6927 38.4445C29.3382 38.7663 28.0939 39.5607 27.1173 40.7272C26.1407 41.8938 25.4756 43.38 25.2062 44.998C24.9368 46.6161 25.075 48.2932 25.6036 49.8173C26.1321 51.3415 27.0271 52.6442 28.1755 53.5607C29.3238 54.4773 30.6739 54.9665 32.0551 54.9665C33.907 54.9663 35.6831 54.0875 36.9927 52.5232C38.3022 50.959 39.038 48.8375 39.0381 46.6253Z"
        fill="white"
        stroke="#606265"
        strokeWidth="0.574331"
        strokeMiterlimit="10"
      />
      <path
        d="M26.9766 52.6914L22.5328 57.9994"
        stroke="#606265"
        strokeWidth="0.574331"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M40.9424 48.1419C40.9424 46.4922 40.5328 44.8795 39.7655 43.5078C38.9982 42.1361 37.9076 41.067 36.6316 40.4357C35.3557 39.8044 33.9516 39.6392 32.597 39.9611C31.2425 40.2829 29.9982 41.0773 29.0216 42.2438C28.045 43.4104 27.3799 44.8966 27.1105 46.5146C26.8411 48.1327 26.9793 49.8098 27.5079 51.3339C28.0364 52.8581 28.9314 54.1608 30.0798 55.0773C31.2281 55.9939 32.5782 56.4831 33.9594 56.4831C35.8113 56.4829 37.5874 55.6041 38.897 54.0398C40.2065 52.4756 40.9423 50.3541 40.9424 48.1419Z"
        fill="#85ACAA"
      />
      <path
        d="M28.2461 46.6249C28.2461 46.6249 29.6312 43.5918 32.055 43.5918C34.4789 43.5918 35.8639 46.6249 35.8639 46.6249C35.8639 46.6249 34.4789 49.6581 32.055 49.6581C29.6312 49.6581 28.2461 46.6249 28.2461 46.6249Z"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0548 48.1415C32.756 48.1415 33.3244 47.4625 33.3244 46.625C33.3244 45.7874 32.756 45.1084 32.0548 45.1084C31.3536 45.1084 30.7852 45.7874 30.7852 46.625C30.7852 47.4625 31.3536 48.1415 32.0548 48.1415Z"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.0381 46.6253C39.0381 44.9756 38.6285 43.3629 37.8612 41.9912C37.0939 40.6195 36.0033 39.5504 34.7273 38.9191C33.4514 38.2878 32.0473 38.1226 30.6927 38.4445C29.3382 38.7663 28.0939 39.5607 27.1173 40.7272C26.1407 41.8938 25.4756 43.38 25.2062 44.998C24.9368 46.6161 25.075 48.2932 25.6036 49.8173C26.1321 51.3415 27.0271 52.6442 28.1755 53.5607C29.3238 54.4773 30.6739 54.9665 32.0551 54.9665C33.907 54.9663 35.6831 54.0875 36.9927 52.5232C38.3022 50.959 39.038 48.8375 39.0381 46.6253V46.6253Z"
        stroke="#606265"
        strokeWidth="0.574331"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default ChecklistAndMagnifier;
