import { useContext, useState, useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';

import { DetailsProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { Currencies } from 'constants/currencies';
import { useAccountsModal } from 'components/Accounts/AccountsContent/components/AccountsModal/hooks';
import { FundsContext } from 'components/Accounts/AccountsContent/contexts/FundsContext';
import { FundsPayloadType } from 'components/Accounts/AccountsContent/components/AccountsModal/types/funds';
import { formatMoneyV2, centsFromMoneyString } from 'utility/currency';

const useDetails = ({ onNextStep }: DetailsProps) => {
  const { walletId, displayName, availableBalance, currency = Currencies.CAD, bankAccounts } = useAccountsModal();
  const { addFundsInfo, setAddFundsInfo, error } = useContext(FundsContext);

  const formattedBalance = `${formatMoneyV2(availableBalance)} ${availableBalance?.currency}`;
  const { amount } = get(addFundsInfo, 'amount', { amount: 0, currency: currency });
  const [formattedAmount, setFormattedAmount] = useState(formatMoneyV2({ amount, currency }));
  const [toAccount, setToAccount] = useState(walletId);
  const [zeroError, setZeroError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isDisabled = zeroError && isSubmitted;

  useEffect(() => {
    if (formattedAmount === '$0.00') {
      setZeroError(true);
    } else {
      setZeroError(false);
    }
  }, [formattedAmount]);

  useEffect(() => {
    setToAccount(walletId);
  }, [walletId]);

  const fromOptions = useMemo(
    () =>
      bankAccounts
        .filter((ba) => ba.currency === currency)
        .map((ba) => {
          return { name: ba.displayName, value: ba.id, currency: ba.currency };
        }),
    [bankAccounts, currency]
  );

  const noBankAccountConnected = !bankAccounts.some((bankAccount) => bankAccount.currency === currency);

  const form = useForm({
    defaultValues: {
      ...addFundsInfo,
      amount: formattedAmount,
    },
  });
  const { handleSubmit, register, setError } = form;

  const handleOnNextStep = (data: FundsPayloadType) => {
    setAddFundsInfo({ ...data, amount: { amount: centsFromMoneyString(data.amount), currency } });
    if (isDisabled) {
      setError('amount', { type: 'manual', message: 'Amount must be greater than $0.00' });
      return;
    }

    onNextStep();
  };

  return {
    displayName,
    formattedBalance,
    currency,
    formattedAmount,
    setFormattedAmount,
    toAccount,
    fromOptions,
    noBankAccountConnected,
    handleOnNextStep,
    form,
    handleSubmit,
    register,
    formatMoneyV2,
    setIsSubmitted,
    errorMessage: error?.message,
  };
};

export default useDetails;
