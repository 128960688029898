import React from 'react';

const ChevronLeft = ({ size = 16 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 -3.93403e-07C13.9706 -1.76132e-07 18 4.02944 18 9V9C18 13.9706 13.9706 18 9 18V18C4.02944 18 -6.10673e-07 13.9706 -3.93403e-07 9V9C-1.76132e-07 4.02944 4.02944 -6.10673e-07 9 -3.93403e-07V-3.93403e-07Z"
        fill="white"
      />
      <path d="M11.25 4.5L6.75 9L11.25 13.5" stroke="#383B3E" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ChevronLeft;
