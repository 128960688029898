import { startOfMonth, endOfMonth, subMonths } from 'date-fns';
import { DatePeriod } from '../PeriodDropdown.types';

type getPeriodOptionProps = {
  customPeriod?: DatePeriod;
  customOptions?: { key?: React.Key; value: DatePeriod }[];
};

export const getPeriodOptions = ({ customPeriod, customOptions }: getPeriodOptionProps = {}) => {
  const lastMonth = subMonths(new Date(), 1);
  const options = customOptions ?? [
    {
      key: 'Current month',
      value: { key: 'Current month', from: startOfMonth(new Date()), to: endOfMonth(new Date()) },
    },
    {
      key: 'Last month',
      value: {
        key: 'Last month',
        from: startOfMonth(lastMonth),
        to: endOfMonth(lastMonth),
      },
    },
  ];
  if (customPeriod) {
    options.push({
      key: 'Other',
      value: customPeriod,
    });
  }

  return options;
};
export default getPeriodOptions;
