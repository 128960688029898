import React, { ErrorInfo, useEffect } from 'react';
import { ErrorBoundary as _ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/browser';

import ErrorFallback from './ErrorFallback';

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const onHandleError = (error: Error, errorInfo: ErrorInfo) => {
    Sentry.setExtra('componentStack', errorInfo);
    Sentry.captureException(error);
  };

  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, []);

  return (
    <_ErrorBoundary FallbackComponent={ErrorFallback} onError={onHandleError}>
      {children}
    </_ErrorBoundary>
  );
};

export default ErrorBoundary;
