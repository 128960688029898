import React from 'react';

import { Layout } from 'components/UI';
import { payorTopDropdownNavigation, payorSideNavItems, payorTabNabItems } from './constants';

const PortalLayout = ({ children, title }: { children: React.ReactNode; title?: string }) => {
  return (
    <Layout
      topDropdownNavigation={payorTopDropdownNavigation}
      sideNavItems={payorSideNavItems}
      tabNavItems={payorTabNabItems}
      title={title}
    >
      {children}
    </Layout>
  );
};

export default PortalLayout;
