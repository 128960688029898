import React from 'react';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import { Content as StatementsContent } from 'components/StatementsModal';
import { useAccountsModal } from '../../hooks';
import { GET_WALLET_STATEMENTS_BY_CURRENCY } from 'graphql/wallets';
import { AccountWallet } from 'types/wallet';
import { Loaders } from 'components/cards/Loader';

const DownloadStatements = () => {
  const { currency, displayName } = useAccountsModal();

  const { data, loading: loadingWallet } = useQuery<{ wallet: AccountWallet }>(GET_WALLET_STATEMENTS_BY_CURRENCY, {
    variables: { currency: currency },
  });
  const walletData = get(data, 'wallet', null);
  const statements = get(walletData, 'statements', []);

  if (loadingWallet)
    return (
      <div className="tw-text-center tw-p-20">
        <Loaders.Spinner />
      </div>
    );

  return (
    <div className="tw-p-8">
      <StatementsContent statements={statements} type={displayName} />
    </div>
  );
};

export default DownloadStatements;
