import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';

import { PageData } from 'types/pagination';
import { BorrowerMerchant, ExternalExpenseAccount } from 'types/accounting';
import { Category } from 'types/category';

export enum MappingStatusType {
  all = 'all',
  unmapped = 'unmapped',
  mapped = 'mapped',
}

export type ExternalExpenseAccountListProps = {
  accountingIntegrationExpenseAccounts: ExternalExpenseAccount[];
};

export type CategoryListProps = {
  transactionCategories: Category[];
};

export type SearchDropdownComponentProps = {
  id: string;
  selectedVal?: string;
  selectedLabel?: string;
  handleChange: (value: { id: string; name: string } | null) => void;
};

export type ResolverArgument = {
  page?: number;
  numPerPage?: number;
  queryString?: string;
};

export type VendorMappingPayload = {
  borrowerMerchantId: string;
  vendorId: string;
  categoryId: string;
  accountingIntegrationAccountId: string;
  accountingIntegrationAccountName?: string;
};

export type MerchantVendorMappingContextType = {
  mappings?: { borrowerMerchants: { items: BorrowerMerchant[]; pageData: PageData } };
  getMappings: LazyQueryExecFunction<
    { borrowerMerchants: { items: BorrowerMerchant[]; pageData: PageData } },
    OperationVariables
  >;
  setMappingRequest: (args: VendorMappingPayload) => void;
  isLoading: boolean;
  isUpdatingMapping: boolean;
  page: number;
  setPage: (val: number) => void;
  numPerPage: number;
  setNumPerPage: (val: number) => void;
  queryString: string;
  setQueryString: (val: string) => void;
  mappingStatus: MappingStatusType;
  setMappingStatus: (val: MappingStatusType) => void;
  syncVendors: () => void;
  isSyncingVendors: boolean;
  vendorsAvailable: boolean;
  noMappingsFound: boolean;
  lastRowSaved: { [key: string]: boolean };
  totalCount: number;
};
