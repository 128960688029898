import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';

import { ampTrackEvent } from 'amplitude';
import { MOVE_FUNDS } from 'graphql/payments';
import { PaymentContext, PaymentContextProvider } from 'context/Payment';
import { Progress } from 'components/UI';
import PADAgreementCA from 'components/payments/PADAgreementCA';
import { Loaders } from 'components/cards/Loader';
import Details from './Details';
import Review from './Review';
import Complete from './Complete';

const ADD_MONEY_STEPS = {
  Details: [0],
  Review: [1, 2],
  Complete: [3],
};

const AddMoney = (props) => {
  const [step, setStep] = useState(0);
  const { loading, loadingCreditInfo } = props;
  const isLoading = loading || loadingCreditInfo;

  return (
    <PaymentContextProvider>
      {isLoading ? (
        <Loaders.Light />
      ) : (
        <>
          <Progress currentStepIndex={step} stepLabelsAndIndexes={ADD_MONEY_STEPS} />
          <Content {...props} step={step} setStep={setStep} />
        </>
      )}
    </PaymentContextProvider>
  );
};

const Content = (props) => {
  const { step, setStep, refetch, ...otherProps } = props;
  const { addMoneyInfo, setAddMoneyInfo, setAddMoneyTransaction } = useContext(PaymentContext);
  const [moveFunds, { loading }] = useMutation(MOVE_FUNDS);

  const onMoveFunds = async ({ nextStep }) => {
    const { fromAccount, toAccount, amount, toAccountIsCard } = addMoneyInfo;

    try {
      const response = await moveFunds({
        variables: {
          from: fromAccount.id,
          to: toAccountIsCard ? toAccount.currency : toAccount.id,
          originalAmount: amount,
          chargedAmount: amount,
        },
      });

      if (response.data && response.data.moveFunds) {
        setAddMoneyTransaction(response.data.moveFunds);
        ampTrackEvent('addMoney: transferFunds: success');
        setStep(nextStep);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitReviewStep = (preDepositAuthorization) => {
    if (preDepositAuthorization) {
      onMoveFunds({ nextStep: 3 });
    } else {
      setStep(2);
    }
  };

  const onNewTransfer = () => {
    refetch && refetch();
    setAddMoneyInfo(null);
    setStep(0);
  };

  switch (step) {
    case 0:
      return <Details {...otherProps} onNextStep={() => setStep(1)} />;
    case 1:
      return (
        <Review {...otherProps} loading={loading} onNextStep={onSubmitReviewStep} onPreviousStep={() => setStep(0)} />
      );
    case 2:
      return <PADAgreementCA {...otherProps} loading={loading} onNextStep={() => onMoveFunds({ nextStep: 3 })} />;
    default:
      return <Complete {...otherProps} onNewTransfer={onNewTransfer} />;
  }
};

export default AddMoney;
