import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { IoGiftOutline, IoTicketOutline } from 'react-icons/io5';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Modal } from 'components/UI';
import Button from 'components/Button';
import GiftOutline from 'components/svg/GiftOutline';
import GiftAndCash from 'components/svg/GiftAndCash';
import { Loaders } from 'components/cards/Loader';
import { formatMoneyV2 } from 'utility/currency';
import { RewardsContext } from '../contexts/RewardsContext';
import { GET_POINTS_AND_REWARDS_INFO } from 'graphql/creditOffer';
import { GET_REDEEMED_GIFTS, GET_GIFT_OFFERS, REDEEM_POINTS } from 'graphql/rewards';

export const RedeemPointsModal = (props) => {
  const { show, onClose, giftOffer } = props;
  const { rewardsAccount, loadingContext } = useContext(RewardsContext);
  const { internalId, description, pointsRequired, giftValue, imageUrl, redemptionDetails } = giftOffer; // selectedGift;

  const [redeemGift, { loading: redeemLoading }] = useMutation(REDEEM_POINTS, {
    refetchQueries: [GET_POINTS_AND_REWARDS_INFO, GET_REDEEMED_GIFTS, GET_GIFT_OFFERS],
  });

  const onConfirmRedeem = async () => {
    try {
      await redeemGift({ variables: { rewardsAccount: rewardsAccount?.internalId, giftOffer: internalId } });
      toast.success('Points redeemed successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error('An error occurred while trying to redeem your points', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    onClose();
  };

  if (loadingContext) return 'Loading...';

  return (
    <Modal show={show} onClose={onClose} title="Redeem Reward">
      <div className="tw-flex tw-flex-col tw-justify-center">
        <hr className="tw-bg-neutral-grey-4" />
        <div className="tw-flex tw-flex-col tw-py-8 tw-p-6 tw-items-center">
          <GiftAndCash />

          <div className="tw-flex tw-flex-row tw-py-8 tw-w-4/5 tw-justify-between">
            <div className="tw-flex tw-items-center tw-justify-between tw-border tw-border-neutral-grey-4 tw-bg-neutral-light">
              <img className="tw-h-40 tw-w-40" src={imageUrl || 'https://placehold.jp/160x50.png'} />
            </div>

            <div className="tw-p-4 tw-flex tw-flex-col tw-justify-center">
              <div>{description}</div>
              <div className="tw-flex tw-items-center tw-space-x-1 tw-text-sm">
                <IoTicketOutline />
                <span>{Intl.NumberFormat().format(pointsRequired)} points</span>
              </div>
              <div className="tw-flex tw-items-center tw-space-x-1 tw-text-semantic-success tw-text-sm">
                <IoGiftOutline />
                <span>{formatMoneyV2(giftValue)} Gift Card</span>
              </div>
            </div>
          </div>

          <div className="tw-flex tw-flex-col tw-py-4 tw-items-center tw-justify-between tw-border tw-border-neutral-grey-4 tw-bg-neutral-light tw-w-4/5">
            <div className="tw-flex tw-flex-row tw-px-4 tw-py-2 tw-justify-between tw-w-full">
              <span>Current Points</span>
              <div className="tw-flex tw-flex-row tw-justify-between">
                <span>{Intl.NumberFormat().format(rewardsAccount.balance)}</span>
                <GiftOutline className="tw-ml-2" />
              </div>
            </div>
            <div className="tw-flex tw-flex-row tw-px-4 tw-py-2 tw-justify-between tw-w-full">
              <span>Cost to Redeem</span>
              <div className="tw-flex tw-flex-row tw-justify-between">
                <span>{Intl.NumberFormat().format(pointsRequired)}</span>
                <GiftOutline className="tw-ml-2" />
              </div>
            </div>
            <div className="tw-flex tw-flex-row tw-px-4 tw-py-2 tw-justify-between tw-w-full">
              <span>Balance left after redeeming</span>
              <div className="tw-flex tw-flex-row tw-justify-between">
                <span>{Intl.NumberFormat().format(rewardsAccount.balance - pointsRequired)}</span>
                <GiftOutline className="tw-ml-2" />
              </div>
            </div>
          </div>

          <div className="tw-flex tw-flex-col tw-my-8 tw-w-4/5">
            <span className="tw-text-xl tw-py-4 tw-font-semi-bold">About The Reward</span>
            <div className="tw-py-2" dangerouslySetInnerHTML={{ __html: redemptionDetails }} />
            <span className="tw-py-2">This reward is a digital gift card.</span>
            <span className="tw-py-2">
              The gift card will be accessible within the Rewards section of your Loop Account.
            </span>
          </div>
        </div>
        <hr className="tw-bg-neutral-grey-4" />
        <div className="tw-p-6 tw-flex tw-justify-between">
          <Button onClick={onClose} tertiary>
            Cancel
          </Button>
          <Button onClick={onConfirmRedeem} isDisabled={redeemLoading} primary>
            {redeemLoading ? <Loaders.Spinner className="tw-w-full" /> : 'Redeem Reward'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
