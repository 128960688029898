import React from 'react';
import { IoRepeatOutline, IoReturnDownBackOutline } from 'react-icons/io5';
import { AiOutlineSend, AiOutlineEye } from 'react-icons/ai';
import { TbFileDownload } from 'react-icons/tb';
import { BiDollar } from 'react-icons/bi';

import { Currencies } from 'constants/currencies';
import useIsReadOnly from 'hooks/useIsReadOnly';
import useIsContactPermission from 'hooks/useIsContactPermission';

export const getButtons = (currency: Currencies, activeExternalAccount: boolean) => {
  const { isReadOnly } = useIsReadOnly();
  const {
    createFxConversions,
    addFundsToAccount,
    withdrawFundsToVerifiedConnectedBankAccounts,
    viewAccountDetails,
    createPayeePayment,
  } = useIsContactPermission();

  const buttons = [];

  const convertButton = {
    buttonIcon: <IoRepeatOutline />,
    iconSize: 20,
    buttonText: 'Convert',
    primary: true,
  };

  const payPayeeButton = {
    buttonIcon: <AiOutlineSend />,
    iconSize: 16,
    buttonText: 'Pay Payee',
    primary: true,
  };

  const addFundsButton = {
    buttonIcon: <BiDollar />,
    iconSize: 20,
    buttonText: 'Add Funds',
    primary: true,
  };

  const withdrawButton = {
    buttonIcon: <IoReturnDownBackOutline />,
    iconSize: 20,
    buttonText: 'Withdraw',
    primary: true,
  };

  const bankDetailsButton = { buttonIcon: <AiOutlineEye />, iconSize: 22, buttonText: 'Bank Details', primary: false };

  const statementsButton = {
    iconSize: 20,
    buttonIcon: <TbFileDownload />,
    buttonText: 'Statements',
    primary: false,
  };

  if (createFxConversions) {
    buttons.push(convertButton);
  }
  if (createPayeePayment) {
    buttons.push(payPayeeButton);
  }
  if (![Currencies.EUR, Currencies.GBP].includes(currency)) {
    if (addFundsToAccount) {
      buttons.splice(0, 0, addFundsButton);
    }
    if (withdrawFundsToVerifiedConnectedBankAccounts) {
      buttons.splice(1, 0, withdrawButton);
    }
  }
  if (activeExternalAccount === true && viewAccountDetails) {
    buttons.push(bankDetailsButton);
  }
  buttons.push(statementsButton);

  if (isReadOnly) {
    return [statementsButton];
  }

  return buttons;
};
