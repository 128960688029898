import React, { useContext } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';

import { ExternalAccountingAccount } from 'types/externalAccountingAccount';
import { TableRowProps } from './TableRow.types';
import { QuickBooksAccount } from 'components/settings/AccountingServices/Account';
import { AssetAccountMappingRequestContext } from '../../../../contexts/AssetAccountMappingRequestContext';
import { accountingServicesAccountCreationURLs } from 'components/settings/Integrations/constants';

const TableRow = ({ walletAssetAccountDetailsMapping, accountName }: TableRowProps) => {
  const { wallet, externalAccount } = walletAssetAccountDetailsMapping;
  const { availableExternalAccountingAccounts, setAssetAccountMappingRequestInfo } = useContext(
    AssetAccountMappingRequestContext
  );

  const handleNewAccount = () => {
    if (accountName === 'Xero') {
      window.open(accountingServicesAccountCreationURLs.xero, '_blank', 'noreferrer');
    } else if (accountName === 'QuickBooks') {
      window.open(accountingServicesAccountCreationURLs.quickbooks, '_blank', 'noreferrer');
    }
  };

  const handleMappingUpdate = (externalAccount: ExternalAccountingAccount) => {
    setAssetAccountMappingRequestInfo({ walletId: wallet.id, externalAccountId: externalAccount.id });
  };

  const filteredAvailableExternalAccounts = availableExternalAccountingAccounts.filter(
    (account) => account.currency === wallet.currency
  );

  return (
    <>
      <tr>
        <td className="tw-px-3 tw-py-4 tw-border-b tw-border-neutral-grey-5">{wallet.name}</td>
        <td className="tw-px-3 tw-py-4 tw-border-b tw-border-neutral-grey-5">
          <QuickBooksAccount
            qbCategories={filteredAvailableExternalAccounts}
            qbCategory={externalAccount}
            onChange={handleMappingUpdate}
            listOptionStyles={'tw-h-80'}
            className="tw-relative"
            newAccount={() => (
              <div className="tw-p-1 tw-h-8 tw-flex tw-box-border tw-items-center tw-cursor-pointer tw-text-neutral-light tw-bg-primary-dark-green hover:tw-opacity-90">
                <IoAddCircleOutline className="tw-ml-1" />
                <span className="tw-mx-1" onClick={handleNewAccount}>
                  Create {accountName === 'Xero' ? 'Bank' : 'Asset'} Account
                </span>
              </div>
            )}
          />
        </td>
        <td className="tw-px-3 tw-py-4 tw-border-b tw-border-neutral-grey-5 tw-text-center">
          {externalAccount?.taxCodeRefId || '-'}
        </td>
      </tr>
    </>
  );
};

export default TableRow;
