import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import cx from 'classnames';

import config from 'config';

const ErrorState = ({ title, showShadow }: { title: string; showShadow?: boolean }) => {
  useEffect(() => {
    toast.error(`Failed to load ${title} information`);
  }, []);

  return (
    <section className={cx('tw-bg-neutral-light tw-rounded tw-py-6 tw-px-4', showShadow && 'tw-shadow-default')}>
      <div className="tw-flex tw-flex-nowrap tw-justify-between tw-gap-4 tw-mb-4">
        <h2>{title}</h2>
      </div>
      <div>
        Unfortunately, we weren't able to display your Loop {title}. Please try refreshing the page. If you continue to
        encounter this issue, please reach out to our team at{' '}
        <a
          className="tw-text-primary-dark-green tw-font-bold"
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:${config.supportEmail}`}
        >
          {config.supportEmail}
        </a>
      </div>
    </section>
  );
};

export default ErrorState;
