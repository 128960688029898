import React from 'react';

import { Label } from './components';
import { ChartItemProps } from './ChartItem.types';

const ChartItem = ({ label, value, bgColor, labelColor, isLoading }: ChartItemProps) => {
  if (isLoading) {
    return (
      <div className="tw-h-8 md:tw-h-12 tw-w-full tw-rounded-lg md:tw-rounded-none tw-bg-neutral-grey-4 tw-animate-pulse" />
    );
  }

  return (
    <div
      className="tw-rounded-lg md:tw-rounded-none tw-min-w-min"
      style={{
        backgroundColor: bgColor,
        width: `${value}%`,
      }}
    >
      <Label label={label} labelColor={labelColor} />
    </div>
  );
};

export default ChartItem;
