import React from 'react';
import { TableRowProps } from '../../TableRow.types';
const RowAction = ({
  isBankAccountConnected,
  isAvailableForProcessing,
  openBankConnectionModal,
  openConfirmPaymentModal,
}: Pick<TableRowProps, 'openConfirmPaymentModal'> & {
  isBankAccountConnected: boolean;
  isAvailableForProcessing: boolean;
  openBankConnectionModal: () => void;
}) => {
  if (isAvailableForProcessing && !isBankAccountConnected)
    return (
      <button onClick={openBankConnectionModal} className="tw-text-primary-blue tw-underline">
        Connect Bank Account
      </button>
    );

  if (isAvailableForProcessing)
    return (
      <button onClick={openConfirmPaymentModal} className="tw-text-primary-blue tw-underline">
        Confirm
      </button>
    );

  return null;
};

export default RowAction;
