import React from 'react';

const backgroundColorClassForInitials = (initials) => {
  const bgColorClasses = ['tw-bg-primary-yellow', 'tw-bg-primary-lilac', 'tw-bg-primary-blue'];
  const charCodes = initials
    .split('')
    .map((char) => char.charCodeAt(0))
    .join('');
  const codeValue = parseInt(charCodes, 10);

  return bgColorClasses[codeValue % bgColorClasses.length];
};

const LargeInitialsCircle = ({ initials }) => {
  const bgColorClass = backgroundColorClassForInitials(initials);

  return (
    <div className={`tw-rounded-full tw-w-max tw-p-4 ${bgColorClass}`}>
      <div className="tw-w-9 tw-h-9 tw-flex tw-justify-center tw-items-center">
        <h1 className="tw-text-neutral-light">{initials}</h1>
      </div>
    </div>
  );
};

const MediumInitialsCircle = ({ initials }) => {
  const bgColorClass = backgroundColorClassForInitials(initials);

  return (
    <div className={`tw-rounded-full tw-w-max tw-p-2 ${bgColorClass}`}>
      <div className="tw-w-5 tw-h-5 tw-flex tw-justify-center tw-items-center">
        <small className="tw-text-neutral-light">{initials}</small>
      </div>
    </div>
  );
};

const SmallInitialsCircle = ({ initials }) => {
  const bgColorClass = backgroundColorClassForInitials(initials);

  return (
    <div className={`tw-rounded-full tw-w-max tw-p-0.5 ${bgColorClass}`}>
      <div className="tw-w-4 tw-h-4 tw-flex tw-justify-center tw-items-center">
        <span className="tw-text-xs tw-text-neutral-light">{initials}</span>
      </div>
    </div>
  );
};

const InitialsCircle = {
  Large: LargeInitialsCircle,
  Medium: MediumInitialsCircle,
  Small: SmallInitialsCircle,
};

export default InitialsCircle;
