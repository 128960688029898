import React from 'react';

const GiftAndCash = ({ size = 121, ...other }) => {
  const width = size;
  const height = (131 * width) / 121;

  return (
    <svg width={width} height={height} viewBox="0 0 121 131" fill="none" {...other}>
      <path
        d="M86.1959 63.5162L110.636 78.23C111.155 78.5425 111.821 78.3608 112.124 77.8241L119.787 64.2194C120.09 63.6827 119.914 62.9942 119.395 62.6817L94.9546 47.9679C94.4355 47.6553 93.7695 47.8371 93.4672 48.3738L85.8033 61.9785C85.501 62.5152 85.6768 63.2037 86.1959 63.5162Z"
        fill="#D0E7F4"
      />
      <path
        d="M82.6334 64.1734L107.074 78.8872C107.593 79.1998 108.259 79.018 108.561 78.4814L116.225 64.8766C116.527 64.3399 116.352 63.6514 115.832 63.3389L91.3921 48.6251C90.873 48.3126 90.207 48.4943 89.9047 49.031L82.2408 62.6357C81.9385 63.1724 82.1143 63.8609 82.6334 64.1734Z"
        stroke="#4F5154"
        strokeLinejoin="round"
      />
      <path
        d="M96.4973 68.6156C99.0931 70.1784 102.423 69.2698 103.934 66.5864C105.446 63.9029 104.567 60.4607 101.972 58.8979C99.3758 57.3352 96.046 58.2437 94.5344 60.9272C93.0227 63.6107 93.9016 67.0529 96.4973 68.6156Z"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.035 68.7646C112.789 68.0141 111.882 66.7825 111.513 65.3407C111.145 63.8988 111.346 62.3648 112.072 61.0762"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.7141 52.9184C88.3313 53.29 89.0133 53.5323 89.7211 53.6314C90.4288 53.7305 91.1486 53.6845 91.8392 53.4961C92.5298 53.3077 93.1777 52.9805 93.746 52.5332C94.3143 52.0859 94.7918 51.5272 95.1512 50.8891"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.751 74.5942C109.504 73.8438 108.02 73.636 106.626 74.0165C105.231 74.3971 104.04 75.3348 103.314 76.6235"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.4316 58.749C85.0489 59.1206 85.5892 59.6142 86.0219 60.2017C86.4546 60.7892 86.7711 61.459 86.9534 62.173C87.1357 62.8869 87.1802 63.631 87.0843 64.3627C86.9884 65.0944 86.754 65.7994 86.3946 66.4374"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9351 54.1621L45.5575 41.7828C46.1018 41.5198 46.3368 40.8505 46.0824 40.2879L39.6345 26.0251C39.3801 25.4624 38.7327 25.2195 38.1884 25.4824L12.566 37.8617C12.0218 38.1247 11.7868 38.794 12.0411 39.3566L18.4891 53.6194C18.7434 54.1821 19.3908 54.425 19.9351 54.1621Z"
        stroke="#4F5154"
        strokeLinejoin="round"
      />
      <path
        d="M32.3143 43.3794C35.0356 42.0646 36.2107 38.7181 34.9389 35.9049C33.6671 33.0916 30.43 31.8768 27.7086 33.1916C24.9873 34.5064 23.8123 37.8529 25.0841 40.6661C26.3559 43.4794 29.593 44.6941 32.3143 43.3794Z"
        fill="#EED9F7"
      />
      <path
        d="M31.3651 44.9165C34.0864 43.6017 35.2615 40.2552 33.9896 37.442C32.7178 34.6287 29.4807 33.4139 26.7594 34.7287C24.0381 36.0435 22.863 39.39 24.1349 42.2032C25.4067 45.0165 28.6438 46.2313 31.3651 44.9165Z"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.4763 30.0999C40.1695 30.7313 38.6736 30.8002 37.3176 30.2914C35.9617 29.7825 34.8568 28.7377 34.2461 27.3867"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8829 43.4327C14.53 43.1201 15.1111 42.6788 15.5932 42.134C16.0753 41.5891 16.4489 40.9515 16.6926 40.2574C16.9363 39.5633 17.0454 38.8264 17.0136 38.0888C16.9819 37.3511 16.8099 36.6272 16.5074 35.9582"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.2418 36.2121C42.935 36.8435 41.9243 37.9857 41.4321 39.3875C40.9399 40.7892 41.0065 42.3357 41.6173 43.6866"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6465 49.5449C17.2936 49.2323 17.9939 49.0545 18.7074 49.0216C19.4209 48.9888 20.1338 49.1016 20.8052 49.3535C21.4765 49.6055 22.0933 49.9916 22.6204 50.49C23.1474 50.9884 23.5743 51.5892 23.8767 52.2581"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.0102 78.8047H40.0833C37.4916 78.8047 35.3906 80.9767 35.3906 83.6559V90.9328C35.3906 93.6121 37.4916 95.784 40.0833 95.784H87.0102C89.6019 95.784 91.7029 93.6121 91.7029 90.9328V83.6559C91.7029 80.9767 89.6019 78.8047 87.0102 78.8047Z"
        fill="#FFE8C3"
      />
      <path
        d="M87.0128 95.7842V117.615C87.0128 119.545 86.2712 121.396 84.9511 122.76C83.6311 124.125 81.8407 124.892 79.9738 124.892H47.125C45.2581 124.892 43.4677 124.125 42.1476 122.76C40.8275 121.396 40.0859 119.545 40.0859 117.615V95.7842"
        fill="#FFE8C3"
      />
      <path
        d="M59.75 75.018V83.5077H67.9622C69.5864 83.5077 71.1741 83.0097 72.5246 82.0769C73.8751 81.144 74.9277 79.8181 75.5493 78.2668C76.1708 76.7156 76.3334 75.0086 76.0166 73.3617C75.6997 71.7149 74.9176 70.2022 73.7691 69.0149C72.6206 67.8276 71.1573 67.019 69.5643 66.6915C67.9713 66.3639 66.3201 66.532 64.8195 67.1746C63.3189 67.8171 62.0364 68.9053 61.134 70.3014C60.2316 71.6975 59.75 73.3389 59.75 75.018V75.018Z"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M59.7506 75.018C59.7506 77.3617 59.7506 83.5077 59.7506 83.5077H51.5384C49.9142 83.5077 48.3264 83.0097 46.9759 82.0769C45.6254 81.144 44.5729 79.8181 43.9513 78.2668C43.3297 76.7156 43.1671 75.0086 43.484 73.3617C43.8008 71.7149 44.583 70.2022 45.7315 69.0149C46.88 67.8276 48.3432 67.019 49.9363 66.6915C51.5293 66.3639 53.1805 66.532 54.6811 67.1746C56.1816 67.8171 57.4642 68.9053 58.3666 70.3014C59.2689 71.6975 59.7506 73.3389 59.7506 75.018V75.018Z"
        stroke="#4F5154"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M83.2133 83.5078H36.2864C33.6947 83.5078 31.5938 85.6798 31.5938 88.3591V95.6359C31.5938 98.3152 33.6947 100.487 36.2864 100.487H83.2133C85.805 100.487 87.906 98.3152 87.906 95.6359V88.3591C87.906 85.6798 85.805 83.5078 83.2133 83.5078Z"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.214 100.487V122.318C83.214 124.248 82.4724 126.099 81.1523 127.463C79.8322 128.828 78.0418 129.595 76.175 129.595H43.3261C41.4593 129.595 39.6689 128.828 38.3488 127.463C37.0287 126.099 36.2871 124.248 36.2871 122.318V100.487"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M59.75 83.5078V129.595" stroke="#4F5154" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M103.769 11.7475C103.84 11.5394 104.146 11.5815 104.158 11.8011L104.882 24.9297C104.887 25.0111 104.94 25.0816 105.017 25.108L117.462 29.3511C117.67 29.422 117.628 29.728 117.409 29.7401L104.28 30.4639C104.199 30.4684 104.128 30.5219 104.102 30.5991L99.8588 43.0442C99.7878 43.2523 99.4819 43.2102 99.4698 42.9907L98.7459 29.862C98.7414 29.7806 98.688 29.7101 98.6108 29.6838L86.1657 25.4406C85.9576 25.3697 85.9997 25.0638 86.2192 25.0517L99.3478 24.3278C99.4292 24.3233 99.4998 24.2698 99.5261 24.1926L103.769 11.7475Z"
        fill="#8F5CCF"
      />
      <path
        d="M59.7647 7.05663C59.9198 6.90082 60.1762 7.07297 60.0907 7.27552L56.7783 15.1218C56.7465 15.1969 56.7636 15.2838 56.8214 15.3413L62.8573 21.35C63.0132 21.5051 62.841 21.7615 62.6385 21.676L54.7922 18.3636C54.717 18.3319 54.6302 18.3489 54.5726 18.4067L48.564 24.4427C48.4089 24.5985 48.1525 24.4263 48.238 24.2238L51.5504 16.3775C51.5821 16.3024 51.5651 16.2155 51.5073 16.158L45.4713 10.1493C45.3155 9.99419 45.4877 9.7378 45.6902 9.82331L53.5365 13.1357C53.6116 13.1674 53.6985 13.1504 53.756 13.0926L59.7647 7.05663Z"
        fill="#EEA530"
      />
      <path
        d="M21.3506 68.0566C21.5057 67.9008 21.7621 68.073 21.6766 68.2755L18.3642 76.1218C18.3325 76.1969 18.3495 76.2838 18.4073 76.3413L24.4433 82.35C24.5991 82.5051 24.4269 82.7615 24.2244 82.676L16.3781 79.3636C16.303 79.3319 16.2161 79.3489 16.1586 79.4067L10.1499 85.4427C9.99481 85.5985 9.73842 85.4263 9.82393 85.2238L13.1363 77.3775C13.1681 77.3024 13.151 77.2155 13.0932 77.158L7.05725 71.1493C6.90143 70.9942 7.07359 70.7378 7.27614 70.8233L15.1224 74.1357C15.1976 74.1674 15.2844 74.1504 15.342 74.0926L21.3506 68.0566Z"
        fill="#EEA530"
      />
    </svg>
  );
};

export default GiftAndCash;
