import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_TRANSACTION_CATEGORIES } from 'graphql/transactionCategories';
import {
  SearchDropdownComponentProps,
  CategoryListProps,
} from 'components/settings/AccountingServices/AccountingServices.types';
import SearchDropdown, { useCommonSearchDropdown } from 'components/UI/SearchDropdown';
import { Category } from 'types/category';

const SearchCategoryDropdown = ({ selectedVal, handleChange }: SearchDropdownComponentProps) => {
  const { data: loopCategories, loading } = useQuery<CategoryListProps>(GET_TRANSACTION_CATEGORIES);

  const getOptionFromItem = (item: Category) => {
    return {
      value: item.transactionCategoryId,
      label: item.description || item.transactionCategoryId,
    };
  };

  const { optionsList, onSelectOption, onSearch, selectedOption, displayLabel } = useCommonSearchDropdown({
    unsortedItemsList: loopCategories?.transactionCategories.map(getOptionFromItem),
    selectedVal: selectedVal,
    handleChange: handleChange,
  });

  return (
    <SearchDropdown
      optionsList={optionsList}
      onSelectOption={onSelectOption}
      onSearch={onSearch}
      selectedOption={selectedOption}
      displayLabel={displayLabel}
      isSearching={loading}
      widthClass="tw-w-56"
    />
  );
};

export default SearchCategoryDropdown;
