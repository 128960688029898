import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { BsChevronDown } from 'react-icons/bs';
import cx from 'classnames';

import { ToggleDropdownProps, WithId } from './ToggleDropdown.types';
import useToggleDropdown from './hooks/useToggleDropdown';
import { Loaders } from 'components/cards/Loader';
import { Currencies } from 'constants/currencies';

const ToggleDropdown = <T extends string | Currencies | WithId>({
  options,
  selectedOptions,
  setSelectedOptions,
  label = 'Select Options',
  optionKey,
  testId,
  optionElement,
  idKey,
  loadingOptions = false,
  isDisabled,
  isControlFieldView,
}: ToggleDropdownProps<T>) => {
  const { handleToggleOption, selectedOptionsCount } = useToggleDropdown({
    selectedOptions,
    setSelectedOptions,
    idKey,
  });

  if (loadingOptions)
    return (
      <div className="tw-px-8 tw-flex tw-items-center">
        <Loaders.Spinner customClass="spinner-border tw-border-2 tw-h-4 tw-w-4" />
      </div>
    );

  if (options.length === 0 && !loadingOptions) return null;

  return (
    <Listbox value={selectedOptions} onChange={() => {}} multiple disabled={isDisabled}>
      <div className="tw-relative">
        <Listbox.Button
          data-testid={testId}
          className={cx(
            'tw-flex tw-items-center tw-gap-2 tw-cursor-pointer tw-p-2',
            isControlFieldView &&
              'tw-flex tw-justify-between tw-truncate tw-px-3 tw-py-2 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 focus:tw-border-primary-dark-green focus:tw-outline-none',
            isControlFieldView && isDisabled && 'tw-text-neutral-grey-2 tw-bg-neutral-grey-3 tw-cursor-not-allowed'
          )}
        >
          <div className={cx('tw-flex tw-gap-1', isControlFieldView ? 'tw-text-base' : 'tw-text-sm')}>
            <span>{label}</span>
            {(!!selectedOptionsCount || isControlFieldView) && <span>({selectedOptionsCount})</span>}
          </div>

          <BsChevronDown className={cx(!isControlFieldView && 'tw-text-neutral-grey-2')} />
        </Listbox.Button>
        <Transition
          unmount={false}
          as={Fragment}
          enter="tw-transition tw-ease-in tw-duration-100"
          enterFrom="tw-opacity-0"
          enterTo="tw-opacity-100"
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Listbox.Options
            as="ol"
            className={cx(
              'tw-absolute tw-z-40 tw-max-h-80 tw-overflow-y-scroll tw-py-1 tw-overflow-auto tw-bg-neutral-light tw-rounded-md tw-shadow-notification',
              isControlFieldView ? 'tw-w-full' : 'tw-w-52 tw-scrollbar-hide'
            )}
          >
            {options.map((option) => {
              const isSelected = selectedOptions.some((value) =>
                typeof value === 'object' ? value[idKey as keyof T] === option[idKey as keyof T] : option === value
              );
              const onCheck = () => handleToggleOption(option);

              return (
                <Listbox.Option
                  as="li"
                  key={typeof option === 'object' ? String(option[idKey as keyof T]) : String(option)}
                  value={option}
                  className="tw-flex tw-items-center tw-justify-start tw-p-2 tw-cursor-pointer"
                  data-testid={`${testId}-option-${option[idKey as keyof T]}`}
                  onClick={onCheck}
                >
                  <input type="checkbox" checked={isSelected} readOnly={true} className="tw-mr-2" />
                  {optionElement ? (
                    optionElement(option)
                  ) : (
                    <div className="tw-text-sm tw-capitalize">
                      {typeof option === 'object' && optionKey !== undefined ? option[optionKey] : option}
                    </div>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default ToggleDropdown;
