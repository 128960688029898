import React, { useEffect, useState, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { IoPencilOutline, IoCheckmarkCircleOutline, IoCloseCircleOutline } from 'react-icons/io5';
import { useMutation } from '@apollo/client';

import { ampTrackEvent } from 'amplitude';
import { TextField } from 'components/FormFields/v2';
import { AuthContext } from 'context/Auth';
import { CREDIT_CARD_STATUS, SCOPE, TRANSACTION_PRODUCT_TYPES } from 'constants/index';
import { formatDate, formatDateTime } from 'utility/date';
import { formatTransactionName } from 'utility/transactions';
import { formatMoneyV2 } from 'utility/currency';
import { encryptString } from 'utility/string';
import { CardStatus } from 'components/CreditCardsList';
import TransactionType from 'components/TransactionType';
import PermissionChecker from 'components/PermissionChecker';
import { Loaders } from 'components/cards/Loader';
import CardActions from 'components/creditCards/cardActions';
import ErrorFetchingCreditCards from './ErrorFetchingCreditCards';
import { UPDATE_CARD_DISPLAY_NAME } from 'graphql/cards';
import PhysicalCardClean from 'components/svg/PhysicalCardClean';

import creditCardVirtualSrc from 'images/creditCardVirtual.png';

const CreditCardDetail = (props) => {
  const { creditCardDetails, refetchCreditCardDetails, loading, error, ...otherProps } = props;

  const element = document.getElementById('card-detail-overlay');
  element?.scrollIntoView({ block: 'start', behavior: 'smooth' });

  const [toggleRetry, setToggleRetry] = useState(false);

  useEffect(() => {
    if (creditCardDetails) setToggleRetry(false);
  }, [creditCardDetails, loading]);

  const handleRetry = () => {
    setToggleRetry(true);
    setTimeout(() => setToggleRetry(false), 10000);
    refetchCreditCardDetails();
  };

  if (error && !toggleRetry && !loading) {
    return <ErrorFetchingCreditCards handleRetry={handleRetry} />;
  }

  // if me is in creditCardContacts

  return (
    <div id="card-detail-overlay" className="tw-flex lg:tw-min-h-full tw-py-4">
      {loading || toggleRetry ? (
        <div className="tw-w-screen tw-h-screen tw-flex tw-justify-center">
          <Loaders.Small customClass="tw-m-auto" />
        </div>
      ) : (
        creditCardDetails && (
          <Content creditCard={creditCardDetails} refetchCreditCardDetails={refetchCreditCardDetails} {...otherProps} />
        )
      )}
    </div>
  );
};

const Content = ({ creditCard, header, refetchCreditCardDetails }) => {
  const [updateCardDisplayName, { loading, error }] = useMutation(UPDATE_CARD_DISPLAY_NAME);
  const [isDisabled, setIsDisabled] = useState(true);
  const [editNickname, setEditNickname] = useState(false);
  const { me } = useContext(AuthContext);
  const isMember = me?.internalContact?.role === 'member';
  const hasPermission = creditCard.contacts
    ? creditCard.contacts.some((contact) => contact.id === me.internalContact.id)
    : false;

  const onEditNickname = () => {
    setIsDisabled(false);
    setEditNickname(true);
  };

  const onCancel = () => {
    setIsDisabled(true);
    setEditNickname(false);
  };

  const defaultValues = { displayName: `${creditCard.displayName}` };
  const form = useForm({ defaultValues });
  const { register, handleSubmit } = form;

  const handleSubmitForm = async (data) => {
    const { displayName } = data;
    try {
      const response = await updateCardDisplayName({
        variables: { creditCardId: creditCard.id, displayName },
      });

      if (response.data && response.data.updateCardDisplayName) {
        ampTrackEvent('updateCardDisplayName: success');
        setEditNickname(false);
        setIsDisabled(true);
        refetchCreditCardDetails();
      }
    } catch (error) {
      ampTrackEvent('updateCardDisplayName: error');
      console.error(error);
    }
  };

  return (
    <div className="tw-w-screen" data-private>
      {header}
      <CardStatus status={creditCard.status} />
      <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="tw-flex tw-justify-between tw-items-center tw-mt-2">
            <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-y-1">
              {editNickname && (
                <TextField
                  name="displayName"
                  ref={register}
                  disabled={isDisabled}
                  data-testid="card-nickname-input-field"
                />
              )}
              {!editNickname && <h3>{`${creditCard.displayName}`}</h3>}
              <h3 className="tw-ml-2">{`- ${creditCard.lastFourDigits}`}</h3>
              {loading ? (
                <Loaders.Spinner />
              ) : (
                <div className={`tw-flex ${!editNickname && 'tw-ml-2'}`}>
                  {!isMember && (
                    <div className="tw-flex tw-cursor-pointer" onClick={onEditNickname} data-testid="edit-nickname-btn">
                      {!editNickname && <IoPencilOutline size={22} />}
                    </div>
                  )}
                  {editNickname && (
                    <div className="tw-flex">
                      <button className="tw-cursor-pointer hover:tw-text-primary-dark-green" type="submit">
                        <IoCheckmarkCircleOutline size={26} />
                      </button>
                      <div className="tw-cursor-pointer hover:tw-text-primary-dark-green" onClick={onCancel}>
                        <IoCloseCircleOutline size={26} />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="tw-bg-neutral-grey-4 tw-w-max tw-border tw-border-neutral-grey-3 tw-rounded-full tw-py-1 tw-px-3 tw-mt-2">
            <p>{creditCard.virtual ? 'Virtual' : 'Physical'}</p>
          </div>
        </form>
      </FormProvider>
      <div className="tw-relative tw-mt-4 credit-card-details">
        <div className={creditCard.status !== CREDIT_CARD_STATUS.active ? 'tw-opacity-60' : ''}>
          {creditCard.virtual ? (
            <img src={creditCardVirtualSrc} alt="Credit card" />
          ) : (
            <PhysicalCardClean width="100%" height="100%" />
          )}
        </div>

        <h5 className="tw-absolute tw-z-20 tw-top-1/3 tw-translate-y-11 tw-left-8 tw-font-semibold tw-text-neutral-light">
          {creditCard.cardHolderName}
        </h5>
        <h5 className="tw-absolute tw-z-20 tw-top-1/2 tw-left-8 tw-font-semibold tw-text-neutral-light">
          {`∗∗∗∗ ∗∗∗∗ ∗∗∗∗ ${creditCard.lastFourDigits}`}
        </h5>
        <div className="tw-absolute tw-flex tw-flex-col tw-z-20 tw-top-3/4 tw-left-8">
          <span className="tw-text-neutral-light tw-text-sm">EXPIRY</span>
          <span className="tw-text-neutral-light tw-text-xs">
            {creditCard.status === CREDIT_CARD_STATUS.block
              ? '∗∗/∗∗'
              : formatDate(creditCard.expirationDate(), 'MM/yy')}
          </span>
        </div>
        <div className="tw-absolute tw-flex tw-flex-col tw-z-20 tw-top-3/4 tw-left-32">
          <span className="tw-text-neutral-light tw-text-sm">CVC2</span>
          <span className="tw-font-semibold tw-text-neutral-light tw-text-xs">∗∗∗</span>
        </div>
      </div>
      <PermissionChecker scope={SCOPE.manageCards} memberScope={hasPermission}>
        <CardActions creditCard={creditCard} />
      </PermissionChecker>
      <CardTransactions transactions={creditCard.recentTransactions} cardId={creditCard.id} />
    </div>
  );
};

const CardTransactions = ({ transactions = [], cardId }) => {
  const latestTransactions = [...transactions]
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(0, 3);

  return (
    <div className="tw-pt-4 tw-mt-2 tw-border-t tw-border-neutral-grey-4">
      <div className="tw-flex tw-justify-between tw-mb-4">
        <small className="tw-text-neutral-grey-2">Transactions</small>
        <Link
          to={`/dashboard/transactions?productType=${TRANSACTION_PRODUCT_TYPES.creditCard}&productId=${encryptString(
            cardId
          )}`}
          type="button"
        >
          <small className="tw-text-primary-dark-green tw-font-semibold">Show all</small>
        </Link>
      </div>
      {latestTransactions.map((transaction) => (
        <TransactionEntry key={transaction.transactionId} transaction={transaction} />
      ))}
    </div>
  );
};

const TransactionEntry = ({ transaction }) => {
  const isPositive = transaction.amount.amount > 0;

  return (
    <div className="tw-flex tw-items-start tw-space-between tw tw-flex-grow tw-mb-4">
      <div className="tw-flex tw-items-start">
        <div className="tw-mr-2">
          <TransactionType transaction={transaction} />
        </div>
        <div>
          <div className="tw-flex">
            <small className="tw-text-neutral-grey-2 tw-mr-1">To</small>
            <small>{formatTransactionName(transaction.to)}</small>
          </div>
          <small className="tw-text-neutral-grey-2 tw-mr-1">
            {formatDateTime(transaction.createdAt, 'p, MMMM d, yyyy')}
          </small>
        </div>
      </div>
      <div className="tw-flex-grow tw-flex tw-justify-end">
        <small>{`${isPositive ? '' : '-'}${formatMoneyV2(transaction.amount, true)} ${
          transaction.amount.currency
        }`}</small>
      </div>
    </div>
  );
};

export default CreditCardDetail;
