import React from 'react';

import Close from 'components/svg/Close';
import CreditReportConsent from 'components/CreditReportConsent';
import Modal from 'components/Modal/v2';

const ConsentCreditReportModal = ({ show, onHide, companyName, granteeName }) => {
  return (
    <Modal show={show} onClose={onHide}>
      <div
        className="tw-bg-neutral-light tw-rounded tw-py-5 tw-shadow-sm tw-overflow-hidden"
        data-testid="credit-consent-modal"
      >
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-10">
          <h4>Credit Report Terms</h4>
          <Close className="tw-cursor-pointer" onClick={onHide} />
        </div>
        <hr className="tw-mb-6 tw-mt-5" />
        <div className="tw-px-10">
          <CreditReportConsent companyName={companyName} granteeName={granteeName} />
        </div>
      </div>
    </Modal>
  );
};

export default ConsentCreditReportModal;
