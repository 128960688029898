import React, { useState } from 'react';
import { IoTicketOutline, IoGiftOutline } from 'react-icons/io5';
import { BsArrowRight } from 'react-icons/bs';
import { useMutation } from '@apollo/client';

import Button from 'components/Button';
import Banner, { BANNER_TYPES } from 'components/Banner';
import { OfferDetailsModal } from 'components/rewardsAndOffers/offers/OfferDetailsModal';
import { CREATE_PROMOTION_ENROLLMENT } from 'graphql/creditOffer';

const Offers = ({ offers = [], refetch }) => {
  const [loading, setLoading] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState();
  const [createPromotionEnrollment] = useMutation(CREATE_PROMOTION_ENROLLMENT);

  const onSelectCurrentOffer = async (offer) => {
    setLoading(true);
    setSelectedOffer(offer);
    await createPromotionEnrollment({ variables: { promotionId: offer.id } });
    await refetch();
    setLoading(false);
  };

  const [showOfferDetailsModal, setShowOfferDetailsModal] = useState(false);

  const onOpen = (offer) => {
    setSelectedOffer(offer);
    setShowOfferDetailsModal(true);
  };

  if (!offers.length) return null;

  return (
    <div className="tw-bg-neutral-light tw-rounded-md tw-mt-4 tw-p-4">
      <p>Your Offers</p>
      {offers.map((offer) => {
        if (!offer) return null;

        return (
          <div
            className="tw-flex tw-flex-col tw-rounded-md tw-border-neutral-grey-4 tw-border-2 tw-space-y-8 tw-p-4 tw-mt-4"
            key={offer.id}
          >
            <div className="tw-flex">
              <div
                className={`tw-flex tw-items-center tw-justify-center tw-rounded-md tw-py-1 tw-px-8 tw-border-2 tw-border-neutral-grey-4 tw-h-20 tw-w-44`}
              >
                <img className="tw-object-contain tw-h-16" src={offer.imageUrl} />
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-space-y-2">
              <div className="tw-flex tw-items-center tw-space-x-4">
                <IoTicketOutline size={32} />
                <small>{offer.subtext}</small>
              </div>
              <div className="tw-flex tw-items-center tw-space-x-4">
                <IoGiftOutline size={24} className="tw-text-semantic-success" />
                <small className="tw-text-semantic-success">{offer.mainDetails}</small>
              </div>
              <div>
                <small className="tw-cursor-pointer tw-underline" onClick={() => onOpen(offer)}>
                  Learn more about this offer
                </small>
                <OfferDetailsModal
                  show={showOfferDetailsModal}
                  setShow={setShowOfferDetailsModal}
                  offer={selectedOffer}
                />
              </div>
            </div>
            {offer.activated ? (
              <Banner isShowIcon type={BANNER_TYPES.success} message="This offer is active on your Loop Card." />
            ) : (
              <Button primary onClick={() => onSelectCurrentOffer(offer)} disabled={selectedOffer === offer && loading}>
                <div className="tw-flex tw-items-center tw-space-x-2">
                  <p className="tw-text-neutral-light">
                    {selectedOffer === offer && loading ? 'Submitting...' : 'Activate Offer'}
                  </p>
                  <BsArrowRight />
                </div>{' '}
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Offers;
