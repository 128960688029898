import React, { useState, useContext } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import { FaRegSnowflake } from 'react-icons/fa';

import { CREDIT_CARD_STATUS } from 'constants/index';
import { CreditCardContext } from 'context/CreditCard';
import PhysicalCardClean from 'components/svg/PhysicalCardClean';
import { CardStatus, CardContacts } from 'components/CreditCardsList';

import creditCardVirtualSrc from 'images/creditCardVirtual.png';

const CardsList = ({ creditCards }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  return creditCards.length > 2 && !expanded ? (
    <CompactCardsList
      visibleCards={creditCards.slice(0, 2)}
      onExpand={toggleExpanded}
      totalCardsCount={creditCards.length}
    />
  ) : (
    <ExpandedCardsList creditCards={creditCards} onCollapse={toggleExpanded} />
  );
};

const CompactCardsList = ({ visibleCards, totalCardsCount, onExpand, onSelectCard }) => (
  <>
    <small className="tw-text-neutral-grey-2">Credit Cards</small>
    <div className="tw-mt-4">
      {visibleCards.map((creditCard) => (
        <CardItem key={creditCard.id} creditCard={creditCard} onClick={onSelectCard} />
      ))}
    </div>
    <div
      onClick={onExpand}
      className="tw-bg-neutral-light tw-my-4 tw-py-2 tw-px-4 tw-border tw-border-neutral-grey-3 tw-rounded-md tw-text-center"
    >
      See all Cards ({totalCardsCount})
    </div>
  </>
);

const ExpandedCardsList = ({ creditCards, onCollapse }) => {
  const activeCards = creditCards.filter((card) => card.status === CREDIT_CARD_STATUS.active);
  const cardsWaitingForAction = creditCards.filter((card) => card.status !== CREDIT_CARD_STATUS.active);

  return (
    <>
      {activeCards.length > 0 && (
        <>
          <small className="tw-text-neutral-grey-2">Active Cards</small>
          <div className="tw-mt-4">
            {activeCards.map((creditCard) => (
              <CardItem key={creditCard.id} creditCard={creditCard} />
            ))}
          </div>
        </>
      )}
      {cardsWaitingForAction.length > 0 && (
        <>
          <small className="tw-text-neutral-grey-2">Waiting for Action</small>
          <div className="tw-mt-4">
            {cardsWaitingForAction.map((creditCard) => (
              <CardItem key={creditCard.id} creditCard={creditCard} />
            ))}
          </div>
        </>
      )}
      {creditCards.length > 2 && (
        <div
          onClick={onCollapse}
          className="tw-bg-neutral-light tw-my-4 tw-py-2 tw-px-4 tw-border tw-border-neutral-grey-3 tw-rounded-md tw-text-center"
        >
          See less
        </div>
      )}
    </>
  );
};

const CardItem = ({ creditCard }) => {
  const { setCreditCard } = useContext(CreditCardContext);
  const isVirtual = creditCard.virtual;
  const isActive = creditCard.status === CREDIT_CARD_STATUS.active;

  const handleClick = () => {
    setCreditCard(creditCard);
  };

  return (
    <div className="tw-bg-neutral-light tw-rounded-md tw-mb-4 tw-p-4" onClick={handleClick}>
      <div className="tw-flex">
        <div className={`tw-mr-4 ${!isActive ? 'tw-opacity-60' : ''}`}>
          {isVirtual ? (
            <img src={creditCardVirtualSrc} alt="Credit card" height={76} width={121} />
          ) : (
            <PhysicalCardClean height={76} width={121} />
          )}
        </div>

        <div className="tw-flex-grow">
          <div className="tw-mb-2 tw-flex tw-justify-between">
            <CardStatus status={creditCard.status} />
            <CardContacts contacts={creditCard.contacts} />
          </div>
          <small className="tw-font-semibold">{`${creditCard.displayName} - ${creditCard.lastFourDigits}`}</small>
        </div>
      </div>
      <CardFooter status={creditCard.status} />
    </div>
  );
};

const CardFooter = ({ status }) => {
  switch (status) {
    case CREDIT_CARD_STATUS.active:
      return (
        <div className="tw-mt-2 tw-flex tw-items-center tw-justify-center tw-w-full tw-rounded-md tw-p-2">
          <small className="tw-font-semibold tw-text-primary-dark-green">See details</small>
        </div>
      );
    case CREDIT_CARD_STATUS.suspended:
      return (
        <div className="tw-mt-2 tw-flex tw-items-center tw-justify-center tw-bg-secondary-light-blue tw-w-full tw-rounded-md tw-p-2">
          <FaRegSnowflake className="tw-text-primary-blue tw-mr-2" />
          <small className="tw-font-semibold tw-text-primary-blue">Unfreeze</small>
        </div>
      );
    case CREDIT_CARD_STATUS.lost:
    case CREDIT_CARD_STATUS.stolen:
    case CREDIT_CARD_STATUS.fraudulent:
    case CREDIT_CARD_STATUS.damaged:
    case CREDIT_CARD_STATUS.block:
      return null;
    default:
      return (
        <div className="tw-mt-2 tw-flex tw-items-center tw-justify-center tw-bg-secondary-light-green tw-w-full tw-rounded-md tw-p-2">
          <BiCheckCircle className="tw-text-primary-dark-green tw-mr-2" />
          <small className="tw-font-semibold tw-text-primary-dark-green">Activate</small>
        </div>
      );
  }
};

export default CardsList;
