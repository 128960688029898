import React, { useContext } from 'react';

import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { CardStatusBadge, CardTypeBadge } from './components';

const Header = () => {
  const { creditCardLabel, creditCardDetails } = useContext(CardDetailsContext);

  const { status, virtual } = creditCardDetails || {};

  return (
    <div className="tw-flex tw-items-center tw-gap-4">
      <h2 className="tw-mr-auto">{creditCardLabel}</h2>
      <CardTypeBadge isVirtual={virtual} />
      <CardStatusBadge status={status} />
    </div>
  );
};

export default Header;
