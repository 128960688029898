import React from 'react';
import cx from 'classnames';

const ErrorLabel = ({ type, message, className }: { type?: string; message?: string; className?: string }) => {
  if (!type && !message) return null;

  return (
    <div role="alert" className={cx('tw-text-sm tw-text-semantic-error tw-my-2', className)}>
      {type === 'required' && !message ? 'This field is required' : message}
    </div>
  );
};

export default ErrorLabel;
