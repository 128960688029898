// https://github.com/manishsaraan/email-validator/blob/master/index.js
export const EMAIL_REGEX =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

// https://stackoverflow.com/questions/11341957/uk-bank-sort-code-javascript-regular-expression
export const BRITISH_SHORT_CODE_REGEX = /^(?!(?:0{6}))(?:\d{6})$/;

// https://stackoverflow.com/questions/3028150/what-is-proper-regex-expression-for-swift-codes#3028168
export const BIC_SWIFT_REGEX = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

// https://stackoverflow.com/questions/44656264/iban-regex-design
export const IBAN_REGEX = /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/;

export const BSB_CODE_REGEX = /^[0-9]{6}$/;

// https://stackoverflow.com/questions/12090077/javascript-regular-expression-password-validation-having-special-characters
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;

export const NON_NUMERIC_REGEX = /\D/g;

export const DIGITS_REGEX = /\d/g;

export const DIGITS_ONLY_REGEX = /^[\d\s]+$/;

export const DECIMAL_VALUE_REGEX = /-?\d+(?:\.\d+)?/g;

export const ALPHANUMERIC_REGEX = /^[A-Za-z0-9]*$/;

export const EMAIL_LOCAL_PART_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+/;

export const DKK_CODE_REGEX = /^[0-9]{4}$/;

export const IFSC_REGEX = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;

export const URL_REGEX =
  /\b((?:http|https|ftp):\/\/|www\.|data:|file:\/\/)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([^\s()<>]*)(?:\([\w\d]+\)|([^[:punct:]\s]|\/?))/i;
