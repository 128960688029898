import { useContext, useMemo, useEffect } from 'react';
import { get } from 'lodash';

import { PayorsContext } from 'components/Invoices/contexts/PayorsContext';
import { AddPaymentRequestContext } from 'components/Invoices/components/Payors/components/PayorsList/contexts/AddPaymentRequestContext';
import history from 'history.js';
import { useGetSubscription, useIsMobile } from 'hooks';
import { CompleteProps } from '../Complete.types';
import { formatDateTime } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { PaymentRequestCurrency } from 'types/invoicing';
import { REQUESTS_RECURRING_TYPE, REQUESTS_RECURRING_STOP_TYPE } from 'components/Invoices/constants';
import { getFrequencyLabelByValue } from 'components/Invoices/Invoices.utils';

const useComplete = ({ onComplete }: Pick<CompleteProps, 'onComplete'>) => {
  const isMobile = useIsMobile();
  const { payors, wallets } = useContext(PayorsContext);
  const { paymentRequestInfo } = useContext(AddPaymentRequestContext);

  const {
    payorId,
    dueDate,
    invoiceNumber,
    fee,
    recurringType,
    frequency,
    recurringStopType,
    endDate,
    numberOfOccurrences,
    accountId,
  } = paymentRequestInfo || {};

  const { amount, currency } = get(
    paymentRequestInfo,
    'amount',
    {} as { amount?: number; currency?: PaymentRequestCurrency }
  );

  const payor = useMemo(() => payors.find(({ id }) => id === payorId), [payors, payorId]);

  const accountName = useMemo(() => wallets.find(({ id }) => id === accountId)?.name, [wallets, accountId]);

  const formattedDueDate = dueDate && formatDateTime(new Date(dueDate));
  const formattedInitDate = formatDateTime(new Date());
  const formattedAmount = amount && currency ? formatMoneyV2({ amount, currency }) : 0;
  const formattedPaymentRequestFee = fee ? formatMoneyV2(fee) : 0;

  const isOngoing = recurringType === REQUESTS_RECURRING_TYPE.ongoing.value;

  const isStoppedByEndDate = recurringStopType === REQUESTS_RECURRING_STOP_TYPE.endDate.value;
  const isStoppedByNumberOfOccurrences = recurringStopType === REQUESTS_RECURRING_STOP_TYPE.numberOfOccurrences.value;
  const isStoppedByCancel = recurringStopType === REQUESTS_RECURRING_STOP_TYPE.byCancel.value;

  const formattedEndDate = endDate && formatDateTime(new Date(endDate));
  const frequencyLabel = getFrequencyLabelByValue(frequency);

  const { isPaidSubscription } = useGetSubscription();

  const handleClickPromo = () => {
    history.push('/dashboard/subscriptions');
  };

  useEffect(() => {
    onComplete();
  }, [onComplete]);

  return {
    payorName: payor?.name,
    payorEmail: payor?.email,
    payorReferenceId: payor?.referenceId,
    accountName,
    formattedInitDate,
    formattedDueDate,
    formattedAmount,
    formattedPaymentRequestFee,
    currency,
    invoiceNumber,
    showPromo: !isPaidSubscription,
    handleClickPromo,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
    frequencyLabel,
    formattedEndDate,
    numberOfOccurrences,
    isMobile,
  };
};

export default useComplete;
