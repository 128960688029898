import React, { useContext } from 'react';

import { TransactionsContext } from 'context/Transactions';

const TableSkeleton = () => {
  const { productType } = useContext(TransactionsContext);
  const columns = productType === 'credit_card' ? 6 : 5;
  return (
    <div className="tw-flex tw-flex-col tw-w-full tw-mb-8">
      <table className="tw-table tw-table-auto tw-w-full">
        <tbody>
          {Array(columns)
            .fill()
            .map((_, i) => (
              <tr key={i * 10}>
                <th className="tw-p-3 tw-w-full" colSpan={columns}>
                  <div className="tw-flex tw-w-full tw-animate-pulse tw-bg-neutral-grey-4 tw-h-12 tw-rounded" />
                </th>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
