import React from 'react';
import Modal from 'components/Modal/v2';
import Button from 'components/Button';
import useConfirmUnlinkDialog from './useConfirmUnlinkDialog';

const ConfirmUnlinkDialog = ({ transaction, openUnlinkDialog, closeUnlinkDialog }) => {
  const { onUnlinkConfirm, isSubmitting } = useConfirmUnlinkDialog({ transaction, closeUnlinkDialog });

  return (
    <Modal show={openUnlinkDialog} onClose={closeUnlinkDialog}>
      <div className="tw-flex tw-flex-col tw-items-center tw-p-4">
        <p>Are you sure you want to unlink the receipt?</p>
        <div className="tw-flex tw-justify-between tw-mt-8 tw-w-full">
          <Button secondary onClick={closeUnlinkDialog} className="tw-w-44">
            Cancel
          </Button>
          <Button primary onClick={onUnlinkConfirm} isShowSpinner={isSubmitting} className="tw-w-44">
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmUnlinkDialog;
