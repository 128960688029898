import { CreditCard, CreditCardStatus } from 'types/creditCard';
import { getCreditCardMainAddress } from 'utility/creditCards';
import { CardMigrationOption } from './constants';
import { CardMigrationSelectedCard } from './CardMigrationV2.types';

const populateSelectedCards = (
  allCards: CreditCard[],
  selectedMigrationOption: CardMigrationOption
): CardMigrationSelectedCard[] => {
  switch (selectedMigrationOption) {
    case CardMigrationOption.allActive:
      return allCards.map((card) => ({
        cardId: card.id,
        nickName: card.nickname,
        address: getCreditCardMainAddress(card) ?? (card.addresses?.[0] || {}),
        migrate: card.status === CreditCardStatus.active,
        isVirtual: card.virtual !== undefined ? card.virtual : false,
      }));
    case CardMigrationOption.allVirtual:
      return allCards.map((card) => ({
        cardId: card.id,
        nickName: card.nickname,
        address: null,
        migrate: !!(card.status === CreditCardStatus.active && card.virtual),
        isVirtual: !!card.virtual,
      }));
    case CardMigrationOption.none:
    default:
      return allCards.map((card) => ({
        cardId: card.id,
        nickName: card.nickname,
        address: null,
        migrate: false,
        isVirtual: !!card.virtual,
      }));
  }
};

const sortCards = (cards: CardMigrationSelectedCard[]): CardMigrationSelectedCard[] => {
  return cards.sort((a, b) => {
    if (a.migrate === b.migrate) {
      return 0;
    }
    return a.migrate ? -1 : 1;
  });
};

export const getSelectedCards = (
  cards: CreditCard[],
  selectedMigrationOption: CardMigrationOption
): CardMigrationSelectedCard[] => {
  return sortCards(populateSelectedCards(cards, selectedMigrationOption));
};
