import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_PERSONA_EMBEDDED_DATA, GET_PERSONA_EMBEDDED_DATA_WITH_CONTACT } from 'graphql/personaVerification';
import { PersonaEmbeddedData } from 'types/personaVerification';

const usePersonaEmbeddedData = ({
  contactId,
  withContactFields = false,
}: {
  contactId?: string;
  withContactFields?: boolean;
}) => {
  const [data, setData] = useState<PersonaEmbeddedData | null>(null);

  const environmentId = get(data, 'environmentId') || undefined;
  const inquiryId = get(data, 'inquiryId') || undefined;
  const referenceId = get(data, 'referenceId') || undefined;
  const sessionToken = get(data, 'sessionToken') || undefined;
  const templateId = get(data, 'templateId') || undefined;
  const fields = withContactFields
    ? {
        nameFirst: get(data, 'contact.firstName') || undefined,
        nameLast: get(data, 'contact.lastName') || undefined,
        addressStreet1: get(data, 'contact.address.street') || undefined,
        addressCity: get(data, 'contact.address.city') || undefined,
        addressSubdivision: get(data, 'contact.address.countrySubdivision') || undefined,
        addressPostalCode: get(data, 'contact.address.postalCode') || undefined,
        addressCountryCode: get(data, 'contact.address.country') || undefined,
      }
    : undefined;

  const onCompleted = (response: { personaEmbedded: PersonaEmbeddedData }) => {
    setData(get(response, 'personaEmbedded', null));
  };

  const [getPersonaEmbeddedData, { loading, error }] = useLazyQuery<{ personaEmbedded: PersonaEmbeddedData }>(
    GET_PERSONA_EMBEDDED_DATA,
    {
      fetchPolicy: 'network-only',
      onCompleted,
    }
  );

  const [getPersonaEmbeddedDataWithContact] = useLazyQuery<{ personaEmbedded: PersonaEmbeddedData }>(
    GET_PERSONA_EMBEDDED_DATA_WITH_CONTACT,
    {
      fetchPolicy: 'network-only',
      onCompleted,
    }
  );

  useEffect(() => {
    if (!contactId) return;

    const request = withContactFields ? getPersonaEmbeddedDataWithContact : getPersonaEmbeddedData;

    request({
      variables: {
        contactId,
      },
    });
  }, [contactId, withContactFields, getPersonaEmbeddedDataWithContact, getPersonaEmbeddedData]);

  return {
    environmentId,
    inquiryId,
    referenceId,
    sessionToken,
    templateId,
    fields,
    isLoading: loading || !data,
    error,
  };
};

export default usePersonaEmbeddedData;
