import React, { useState, createContext } from 'react';
import { useQuery } from '@apollo/client';
import { get, round } from 'lodash';

import {
  GET_CURRENCY_QUICKBOOKS_LIABILITY_ACCOUNT_MAPPING,
  GET_CATEGORIES_QUICKBOOKS_MAPPING,
  GET_QUICKBOOK_EXPENSE_ACCOUNTS,
  GET_QUICKBOOK_LIABILITY_ACCOUNTS,
  GET_QBO_TAX_CODES,
} from 'graphql/accountingServices';

export const NewAccountModalContext = createContext();

export const NewAccountModalContextProvider = ({ children }) => {
  const [showNewLiability, setShowNewLiability] = useState(false);
  const [showNewExpense, setShowNewExpense] = useState(false);
  const [currency, setCurrency] = useState('CAD');
  const [name, setName] = useState('');
  const [acctNum, setAcctNum] = useState('');
  const [transactionCategoryId, setTransactionCategoryId] = useState();

  const { data: dataTaxRates } = useQuery(GET_QBO_TAX_CODES);
  const accountingIntegrationTaxCodes = get(dataTaxRates, 'accountingIntegrationTaxCodes', []) || [];
  const [tax, setTax] = useState((accountingIntegrationTaxCodes && accountingIntegrationTaxCodes[0]) || 'NAN');

  const resetAndClose = () => {
    setName('');
    setCurrency('');
    setAcctNum('');
    setTransactionCategoryId();
    setTax('');
    setShowNewExpense(false);
    setShowNewLiability(false);
  };

  return (
    <NewAccountModalContext.Provider
      value={{
        // modal
        showNewLiability,
        setShowNewLiability,
        showNewExpense,
        setShowNewExpense,
        resetAndClose,
        // fields
        currency,
        setCurrency,
        name,
        setName,
        acctNum,
        setAcctNum,
        tax,
        setTax,
        accountingIntegrationTaxCodes,
        transactionCategoryId,
        setTransactionCategoryId,
      }}
    >
      {children}
    </NewAccountModalContext.Provider>
  );
};

export const AccountingServiceTransactionsContext = React.createContext({});

export const QuickBooksTransactionsProvider = (props) => {
  // ---- Liability Account Context Section ---- //
  const { data: qboLiabilities } = useQuery(GET_QUICKBOOK_LIABILITY_ACCOUNTS);
  const unsortedQuickBooksLiabilityAccounts = get(qboLiabilities, 'accountingIntegrationLiabilityAccounts', []) || [];
  const accountingIntegrationLiabilityAccounts = [...unsortedQuickBooksLiabilityAccounts].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  //

  // ---- Expense Account Context Section ---- //
  const { data: qboExpenses } = useQuery(GET_QUICKBOOK_EXPENSE_ACCOUNTS);
  const unsortedQuickBooksExpenseAccounts = get(qboExpenses, 'accountingIntegrationExpenseAccounts', []) || [];
  const accountingIntegrationExpenseAccounts = [...unsortedQuickBooksExpenseAccounts].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const [selectedExpenseAccounts, setSelectedExpenseAccounts] = useState({});
  const handleChangeExpenseAccount = (expenseAccount, transactionId) => {
    const accounts = { ...selectedExpenseAccounts };
    accounts[transactionId] = expenseAccount;
    setSelectedExpenseAccounts(accounts);
    expenseAccount?.taxCodeRefId !== null &&
      handleChangeTaxCode(getTaxCodeById(expenseAccount?.taxCodeRefId), transactionId);
  };

  const resetExpense = (transactionId) => {
    const expenses = { ...selectedExpenseAccounts };
    delete expenses[transactionId];
    setSelectedExpenseAccounts(expenses);
  };
  const { data: qboCategoryMapping } = useQuery(GET_CATEGORIES_QUICKBOOKS_MAPPING);
  const { transactionCategoryAccountingIntegrationMapping } = qboCategoryMapping || [];

  const getFromMapping = (categoryId) => {
    const map = transactionCategoryAccountingIntegrationMapping?.find(
      (mapping) => mapping.transactionCategoryId === categoryId
    );
    if (!map) return null;
    return accountingIntegrationExpenseAccounts?.find((account) => account.id === map?.quickBooksAccountId);
  };

  const getExpenseAccountById = (qboExpenseAccountId) =>
    accountingIntegrationExpenseAccounts?.find((item) => item.id === qboExpenseAccountId);

  const NULL_EXPENSE = { id: '-1', name: '- Select' };
  const getSelectedExpense = (transactionId, qboExpenseAccountId, transactionCategory) =>
    getExpenseAccountById(selectedExpenseAccounts?.[transactionId]?.id) ||
    getExpenseAccountById(qboExpenseAccountId) ||
    getFromMapping(transactionCategory?.transactionCategoryId) ||
    NULL_EXPENSE;

  // ---- END Expense Account Context Section ---- //

  // ---- Tax Code Context Section ---- //
  const { data: dataTaxRates, loading: loadingTaxCodes, error: errorTaxCodes } = useQuery(GET_QBO_TAX_CODES);
  const unsortedQboTaxCodes = get(dataTaxRates, 'accountingIntegrationTaxCodes', []) || [];
  const accountingIntegrationTaxCodes = [...unsortedQboTaxCodes].sort((a, b) => a.name.localeCompare(b.name));

  const [selectedTaxCodes, setSelectedTaxCodes] = useState({});
  const handleChangeTaxCode = (taxCode, transactionId) => {
    const codes = { ...selectedTaxCodes };
    codes[transactionId] = taxCode;
    setSelectedTaxCodes(codes);
  };
  const resetTaxCode = (transactionId) => {
    const codes = { ...selectedTaxCodes };
    delete codes[transactionId];
    setSelectedTaxCodes(codes);
  };

  const getTaxCodeById = (taxCodeId) => accountingIntegrationTaxCodes?.find((taxCode) => taxCode.id === taxCodeId);
  const getTaxCodeByTypePercent = (type, percent) =>
    accountingIntegrationTaxCodes?.find((taxCode) =>
      taxCode.name.match(new RegExp(`${type}.*${percent}\\.?\\d*%`, 'gi'))
    );
  const getTaxPercent = (receiptDetails, amount) => {
    const gst = parseFloat(receiptDetails?.gst);
    const hst = parseFloat(receiptDetails?.hst);

    if (gst > 0 && gst < amount) {
      const gst_percent = round((gst / (amount - gst)) * 100);
      return getTaxCodeByTypePercent('gst', gst_percent);
    }
    if (hst > 0 && hst < amount) {
      const hst_percent = round((hst / (amount - hst)) * 100);
      return getTaxCodeByTypePercent('hst', hst_percent);
    }
    return null;
  };

  // Get the selected tax code based on transactionId, qboTaxId, receiptDetails, and amount
  const getSelectedTaxCode = ({ transactionId, qboTaxId, receiptDetails, amount }) => {
    const NULL_TAXCODE = { id: null, name: '- Select' };

    if (loadingTaxCodes) return NULL_TAXCODE;

    return (
      selectedTaxCodes?.[transactionId] ||
      getTaxCodeById(qboTaxId) ||
      getTaxPercent(receiptDetails, amount) ||
      NULL_TAXCODE
    );
  };

  // ---- END Tax Code Context Section ---- //

  // ---- Dates Context Section ---- //
  const [selectedDates, setSelectedDates] = useState({});
  const handleChangeDate = (date, transactionId) => {
    const dates = { ...selectedDates };
    dates[transactionId] = date;
    setSelectedDates(dates);
  };

  const resetDate = (transactionId) => {
    const dates = { ...selectedDates };
    delete dates[transactionId];
    setSelectedDates(dates);
  };
  // ---- END Dates Context Section ---- //

  // ---- Vendor Context Section ---- //
  const [selectedVendorAccounts, setSelectedVendorAccounts] = useState({});
  const handleChangeVendorAccount = (vendorAccount, transactionId) => {
    const vendorAccounts = { ...selectedVendorAccounts };
    vendorAccounts[transactionId] = vendorAccount;
    setSelectedVendorAccounts(vendorAccounts);
  };

  const resetVendorAccount = (transactionId) => {
    const vendorAccounts = { ...selectedVendorAccounts };
    delete vendorAccounts[transactionId];
    setSelectedVendorAccounts(vendorAccounts);
  };
  // ---- END Vendor Context Section ---- //

  // ---- Internal Helpers ---- //

  /*
  const getExpenseFromCategory = (categoryId) => {
    const mapped = transactionCategoryQuickBooksMapping.find((map) => map.transactionCategoryId === categoryId);
    if (mapped) return mapped.quickBooksAccountId;
  };
  */

  const { data: qboCurrenciesMapData } = useQuery(GET_CURRENCY_QUICKBOOKS_LIABILITY_ACCOUNT_MAPPING);
  const currencyAccountingIntegrationLiabilityAccountMapping =
    get(qboCurrenciesMapData, 'currencyAccountingIntegrationLiabilityAccountMapping', []) || [];

  // TODO: WE should memoize this function since always cad => same account.
  const getLiabilityAccountFromCurrency = (currency) => {
    const mapping = currencyAccountingIntegrationLiabilityAccountMapping?.find((elem) => elem.currency === currency);
    if (!mapping) return {};
    if (mapping)
      return accountingIntegrationLiabilityAccounts?.find((account) => account.id === mapping?.quickBooksAccountId);
  };

  const resetContext = () => {
    setSelectedDates({});
    setSelectedExpenseAccounts({});
    setSelectedTaxCodes({});
    setSelectedTransactionsToPost([]);
    setSelectAllToPost(false);
  };

  const isTransactionSelectableToPost = (transaction) => {
    const { transactionId, qboExpenseAccountId, transactionCategory, qboStatus } = transaction;
    const selectedExpense = getSelectedExpense(transactionId, qboExpenseAccountId, transactionCategory);

    return qboStatus === 'pending' && selectedExpense.id !== NULL_EXPENSE.id;
  };

  // ---- END Internal Helpers --//

  // ---- Transactions Selected To Post ---- //
  const [selectedTransactionsToPost, setSelectedTransactionsToPost] = useState([]);

  const handleSelectToPost = (evt, transactionId) => {
    setSelectAllToPost(false);
    const { checked } = evt.currentTarget;

    const transactionsToPost = checked
      ? [...selectedTransactionsToPost, transactionId]
      : selectedTransactionsToPost.filter((tid) => tid !== transactionId);

    setSelectedTransactionsToPost(transactionsToPost);
  };

  const [selectAllToPost, setSelectAllToPost] = useState(false);

  // this helper parses the selectedDates, selectedExpenseAccount and selectedTaxCode and
  // injects that data in the transactions data. This is used for push to QuickBooks.
  const parseTransactionToPost = (transaction) => {
    const {
      transactionId,
      amount,
      transactionCategory,
      qboTransactionDate,
      qboExpenseAccountId,
      qboTaxId,
      merchantVendor,
    } = transaction;

    const expenseAccount =
      transactionId in selectedExpenseAccounts
        ? selectedExpenseAccounts[transactionId]
        : getExpenseAccountById(qboExpenseAccountId) || getFromMapping(transactionCategory?.transactionCategoryId);
    const liabilityAccount = getLiabilityAccountFromCurrency(amount?.currency);

    return {
      transactionId: `${transactionId}`,
      qboExpenseAccountId: expenseAccount?.id,
      qboExpenseAccountName: expenseAccount?.name,
      accountingIntegrationVendorId: selectedVendorAccounts[transactionId]?.id || merchantVendor?.vendor?.id,
      qboLiabilityAccountId: liabilityAccount?.id,
      qboLiabilityAccountName: liabilityAccount?.name,
      qboTransactionDate: transactionId in selectedDates ? selectedDates[transactionId] : qboTransactionDate,
      qboTaxId:
        transactionId in selectedTaxCodes
          ? selectedTaxCodes[transactionId]?.id || expenseAccount?.taxCodeRefId
          : qboTaxId || expenseAccount?.taxCodeRefId || accountingIntegrationTaxCodes[0]?.id || '2',
      // this assumes that qboTaxCodes[0] is the default, 'Exempt'.  Also as fallback, if not tax codes retrieves '2' is the id of Exempt.
    };
  };
  // ---- END Transactions Selected to Post --//

  // ---- Filter Qbo Transactions  --//

  // ---- End Filter Qbo Transactions  --//

  // Provider values
  return (
    <AccountingServiceTransactionsContext.Provider
      value={{
        resetContext,

        selectedTransactionsToPost,
        setSelectedTransactionsToPost,
        handleSelectToPost,
        parseTransactionToPost,
        selectAllToPost,
        setSelectAllToPost,
        isTransactionSelectableToPost,

        getFromMapping,
        accountingIntegrationExpenseAccounts,
        resetExpense,
        getSelectedExpense,

        accountingIntegrationTaxCodes,
        loadingTaxCodes,
        errorTaxCodes,
        selectedTaxCodes,
        handleChangeTaxCode,
        resetTaxCode,
        getSelectedTaxCode,

        selectedDates,
        handleChangeDate,
        resetDate,

        selectedExpenseAccounts,
        handleChangeExpenseAccount,

        selectedVendorAccounts,
        handleChangeVendorAccount,
        resetVendorAccount,

        getLiabilityAccountFromCurrency,
      }}
    >
      {props.children}
    </AccountingServiceTransactionsContext.Provider>
  );
};
