import React from 'react';
import { useHistory } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { BiArrowBack } from 'react-icons/bi';

import useIsMobile from 'hooks/useIsMobile';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import MoveMoneyFlow from 'containers/MoveMoneyFlowContainer';

const MoveMoney = () => {
  const isMobile = useIsMobile();

  const history = useHistory();
  const navigateBack = () => history.push('/dashboard/payments');

  return (
    <DashboardLayout title="Payments">
      <div className={`tw-flex tw-px-4 lg:tw-px-0`}>
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-bg-neutral-light lg:tw-bg-transparent tw-rounded-md lg:tw-rounded-none tw-w-full tw-mb-16">
          <>
            <div
              className="tw-hidden lg:tw-flex tw-items-center tw-self-start tw-ml-6 tw-mr-10 tw-cursor-pointer"
              onClick={navigateBack}
            >
              <BsChevronLeft size={14} className="tw-mr-4" />
              <h5 className="tw-text-lg tw-font-semibold">Back</h5>
            </div>
            <div className="tw-flex lg:tw-hidden tw-items-center tw-space-x-4 tw-pt-4 tw-pl-8">
              <BiArrowBack size={24} onClick={navigateBack} className="tw-cursor-pointer tw-my-2" />
              <span className="tw-text-lg">Move Money</span>
            </div>
            <div className="tw-py-4 lg:tw-py-0 lg:tw-w-3/5">
              <h5 className="tw-hidden lg:tw-block tw-text-lg tw-font-semibold tw-ml-8 tw-mb-8">Move Money</h5>
              <MoveMoneyFlow finishButtonLabel="Back to Payments" onFinish={navigateBack} isModal={isMobile} />
            </div>
          </>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MoveMoney;
