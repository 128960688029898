import { useContext, useEffect, useState } from 'react';

import { SCOPE } from 'constants/index';
import { AuthContext } from 'context/Auth';
import useHasPermission from 'hooks/useHasPermission';

const useHasSignedWalletAgreement = () => {
  const { me, meLoading } = useContext(AuthContext);
  const [showWalletAgreementModal, setShowWalletAgreementModal] = useState(false);
  const { hasPermission: canManageDocuments } = useHasPermission({ scope: SCOPE.manageDocuments });
  const { agreements } = me || {};

  useEffect(() => {
    const hasSignedAgreement =
      agreements && agreements.some((agreement) => agreement.documentType === 'wallets_agreement');
    const isTest = process.env.NODE_ENV === 'test'; // avoid rendering modal in test cases

    if (!meLoading && !hasSignedAgreement && !isTest && canManageDocuments) {
      setShowWalletAgreementModal(true);
    }
  }, [agreements]);

  return { showWalletAgreementModal, setShowWalletAgreementModal };
};

export default useHasSignedWalletAgreement;
