import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';

import config from 'config';
import { ampTrackEvent } from 'amplitude';
import { userRoles } from 'constants/index';
import { PASSWORD_REGEX } from 'constants/regex';
import { AuthContext } from 'context/Auth';
import { ACCEPT_TEAM_INVITATION } from 'graphql/user';
import { TextField, PasswordField, SubmitButton, ErrorLabel } from 'components/FormFields/v2';
import ArrowRight from 'components/svg/ArrowRight';
import { Loaders } from 'components/cards/Loader';
import Button from 'components/Button';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import Layout from './Layout';

const TeamRegistration = ({ loading, invitation, sgid }) => {
  return <Layout>{loading ? <Loaders.Light /> : <Content invitation={invitation} sgid={sgid} />}</Layout>;
};

const Content = ({ invitation, sgid }) => {
  if (invitation) return <Form invitation={invitation} sgid={sgid} />;

  return <InvitationError />;
};

const Form = ({ invitation, sgid }) => {
  const [acceptTeamInvitation, { loading: isSubmitting, error }] = useMutation(ACCEPT_TEAM_INVITATION);
  const form = useForm({ defaultValues: { ...invitation } });
  const history = useHistory();
  const [customError, setCustomError] = useState();
  const { signIn, isSigningIn } = useContext(AuthContext);
  const isLoading = isSigningIn || isSubmitting;
  const { register, handleSubmit, watch } = form;
  const currentPassword = watch('password');
  const contactRole = invitation?.contactRole?.toUpperCase();
  const isEditingDisabled = [userRoles.ACCOUNT_MANAGER, userRoles.DIRECTOR, userRoles.SHAREHOLDER].includes(
    contactRole
  );

  const onSubmit = async (data) => {
    try {
      const { password } = data;
      const dataSource = isEditingDisabled ? invitation : data;
      const { firstName, lastName } = dataSource;

      setCustomError(null);
      const variables = { firstName, lastName, password, email: invitation.email, signedId: sgid };
      const response = await acceptTeamInvitation({ variables });

      if (response?.data?.acceptTeamInvitation) {
        ampTrackEvent('onboarding: teamRegistration: success');
        await signIn(invitation.email, password);
        history.push('/signin'); // TODO: redirect to profile completion page
      } else {
        setCustomError({ message: 'Something went wrong. Please try again later.' });
      }
    } catch (err) {
      ampTrackEvent('onboarding: teamRegistration: error');
      console.error(err);
    }
  };

  return (
    <div className="tw-space-y-8 tw-mb-8">
      <h1>Set up your profile</h1>
      <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
        <form onSubmit={handleSubmit(onSubmit)} className="tw-space-y-8">
          <TextField
            name="firstName"
            label="Legal First Name"
            placeholder="John"
            ref={register({ required: true })}
            tabIndex={isEditingDisabled ? -1 : 1}
            disabled={isEditingDisabled}
          />
          <TextField
            name="lastName"
            label="Legal Last Name"
            placeholder="Doe"
            ref={register({ required: true })}
            tabIndex={isEditingDisabled ? -1 : 2}
            disabled={isEditingDisabled}
          />
          <TextField
            name="email"
            label="Email Address"
            value={invitation.email}
            ref={register({ required: true })}
            disabled
          />
          <PasswordField
            type="password"
            name="password"
            placeholder="∗∗∗∗∗∗∗∗"
            label="Create Password"
            ref={register({
              required: true,
              pattern: {
                value: PASSWORD_REGEX,
              },
            })}
            tabIndex={isEditingDisabled ? 1 : 3}
          />
          <PasswordStrengthMeter password={currentPassword} showScore={false} />
          {customError && <ErrorLabel error={customError} />}
          <SubmitButton className="tw-w-full" tabIndex={isEditingDisabled ? 1 : 2} disabled={isLoading}>
            {isLoading ? (
              'Submitting...'
            ) : (
              <div className="tw-flex tw-justify-center tw-items-center">
                <p className="tw-text-neutral-light tw-mr-2">Join Loop</p>
                <ArrowRight />
              </div>
            )}
          </SubmitButton>
        </form>
      </FormProvider>
      <div className="tw-flex tw-justify-center">
        <small className="tw-text-neutral-grey-2">
          By signing up I agree to{' '}
          <a className="tw-underline" target="_blank" rel="noopener noreferrer" href={config.privacyPolicyUrl}>
            <span className="tw-underline">Loop’s Privacy Policy</span>
          </a>{' '}
          and accepted{' '}
          <a target="_blank" rel="noopener noreferrer" href={config.platformAgreementUrl}>
            <span className="tw-underline">Loop’s Terms of Service</span>
          </a>
          .
        </small>
      </div>
    </div>
  );
};

const InvitationError = () => (
  <div className="tw-space-y-8 tw-mb-8">
    <h1>Invalid invitation</h1>
    <p>This invitation is expired or no longer valid.</p>
    <div>
      <Link to="/signin">
        <Button primary>Return to Login</Button>
      </Link>
    </div>
  </div>
);

export default TeamRegistration;
