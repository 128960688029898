import React from 'react';

export const CRA_ACCOUNT_PAYEE_DISPLAY_NAME = 'Canada Revenue Agency';

export const CARD_PAYMENT_BOX_TEXT = {
  creditCard: (
    <>
      <b>Pay outstanding balances</b> on your Loop Card for each currency you've spent in. Pay before the due date to
      avoid interest or fees.
    </>
  ),
  preFunded: (
    <>
      <b>Add balances</b> to your Loop Card from a Loop Account or an external bank account.
    </>
  ),
};
