import React from 'react';
import { Transition } from '@headlessui/react';

import { CardType } from 'components/creditCards/components/CreateCardModal/constants';
import { CardImageProps } from './CardImage.types';

import { CardImageButton } from './components';

const CardImage = ({ type, onChange, isAtlas = false }: CardImageProps) => {
  return (
    <div className="tw-relative tw-flex tw-flex-row tw-gap-4 tw-justify-center tw-w-full tw-h-48">
      {type ? (
        <>
          <Transition
            as="div"
            className="tw-absolute tw-w-full tw-h-full"
            show={type === CardType.Physical && !isAtlas}
            enter="tw-transition tw-ease-in tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
          >
            <CardImageButton onChange={onChange} />
          </Transition>
          <Transition
            as="div"
            className="tw-absolute tw-w-full tw-h-full"
            show={type === CardType.Virtual}
            enter="tw-transition tw-ease-in tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
          >
            <CardImageButton onChange={onChange} isVirtual />
          </Transition>

          <Transition
            as="div"
            className="tw-absolute tw-w-full tw-h-full"
            show={type === CardType.Physical && isAtlas}
            enter="tw-transition tw-ease-in tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
          >
            <CardImageButton onChange={onChange} isAtlas />
          </Transition>
        </>
      ) : (
        <>
          <CardImageButton onChange={onChange} />
          <CardImageButton onChange={onChange} isVirtual />
        </>
      )}
    </div>
  );
};

export default CardImage;
