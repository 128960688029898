import React, { useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import _ from 'lodash';

import styles from './CreditApplication.module.scss';
import commonStyles from './common.module.scss';
import { TextField, Select, Checkbox } from '../../FormFields';
import {
  employeeTypeOptions,
  ownersPaymentMethodOptions,
  residentialStatusOptions,
  inventoryStorageOptions,
} from '../../../constants';
import { toUnit, toCents } from '../../../utility/currency';
import { SUBMIT_CREDIT_APPLICATION } from '../../../graphql/creditApplication';
import Loader from '../../cards/Loader';
import Layout from '../Layout';
import { ampTrackEvent } from '../../../amplitude';
import { AuthContext } from '../../../context/Auth';
import Button from 'components/Button';

const title = 'Credit Application';
const subTitle =
  'We need some additional information about the business in order to review how much credit we can offer you.';

const CreditApplication = (props) => {
  const {
    averageProductMargin,
    employeeType,
    employeesMonthlyPayroll,
    inventoryStorage,
    loading,
    otherInventoryStorage,
    ownersMonthlyPayroll,
    ownersPaymentMethod,
    residentialStatus,
    accountOwnerName,
  } = props;

  if (loading) {
    return (
      <Layout title={title} subTitle={subTitle}>
        <Loader />
      </Layout>
    );
  }

  const { me } = useContext(AuthContext);
  const businessName = _.get(me, 'account.name');

  const form = useForm({
    defaultValues: {
      averageProductMargin: averageProductMargin,
      employeeType,
      employeesMonthlyPayroll: employeesMonthlyPayroll && toUnit(employeesMonthlyPayroll.amount),
      ownersMonthlyPayroll: ownersMonthlyPayroll && toUnit(ownersMonthlyPayroll.amount),
      ownersPaymentMethod,
      residentialStatus,
      otherInventoryStorage,
    },
  });
  const { register, handleSubmit, watch } = form;
  const history = useHistory();
  const [submitCreditApplication, { loading: isSubmitting, error }] = useMutation(SUBMIT_CREDIT_APPLICATION);

  const isOtherStorageSelected = watch(
    'inventoryStorage.OTHER',
    inventoryStorage && inventoryStorage.includes('OTHER')
  );

  const onSubmit = async (data) => {
    const inventoryStorage = Object.keys(data.inventoryStorage).filter((key) => !!data.inventoryStorage[key]);
    // TODO: Change the currency here based on the currency of the default currency of the user
    const currency = 'CAD';
    const variables = {
      inventoryStorage,
      averageProductMargin: parseFloat(data.averageProductMargin),
      employeeType: data.employeeType,
      employeesMonthlyPayroll: { amount: toCents(data.employeesMonthlyPayroll), currency: currency },
      ownersPaymentMethod: data.ownersPaymentMethod,
      ownersMonthlyPayroll: { amount: toCents(data.ownersMonthlyPayroll), currency: currency },
      residentialStatus: data.residentialStatus,
      otherInventoryStorage: data.otherInventoryStorage,
    };
    try {
      await submitCreditApplication({ variables });
      ampTrackEvent('onboarding: credit_application: success');
      history.push('/onboarding/credit-application/cra');
    } catch (err) {
      console.error(err);
      ampTrackEvent('onboarding: credit_application: error');
    }
  };

  const onCancel = () => {
    window.location.href = '/';
  };

  return (
    <Layout title={title} subTitle={subTitle}>
      <div className={commonStyles.container}>
        <h1 className="tw-mt-12">Line of Credit Application</h1>
        <p className="tw-mb-12">Fill in the requested information to start the Line of Credit Application.</p>
        <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-12 input-label small fw-500 tk-futura-pt fs-1 mb-2">
                Where do you store your inventory?
              </div>
              <div className="col-12 d-flex flex-wrap justify-content-between">
                {inventoryStorageOptions.map((option, i) => (
                  <Checkbox
                    key={i}
                    rootClass={styles.checkbox + ' fs-08'}
                    name={`inventoryStorage.${option.value}`}
                    ref={register()}
                    label={option.name}
                    defaultChecked={inventoryStorage && inventoryStorage.includes(option.value)}
                  />
                ))}
              </div>
              {error && error.graphQLErrors.filter((error) => error.path.includes('invetoryStorage')) && (
                <div className="col-12 input-label small fw-500 tk-futura-pt fs-1 mb-3">
                  <span className="text-danger fw-500 tk-futura-pt">Please select inventory storage</span>
                </div>
              )}

              {isOtherStorageSelected ? (
                <TextField
                  rootClass="col-12 mb-3"
                  name="otherInventoryStorage"
                  label="Please Input the Location That You Store Your Inventory"
                  ref={register({ required: true })}
                />
              ) : null}

              <TextField
                rootClass="col-12 mb-3"
                name="averageProductMargin"
                label="What is Your Average Product Margin Expressed As %"
                infoText="1 -(average landed product cost divided by average selling price)"
                type="number"
                placeholder="25.50%"
                step=".01"
                ref={register({ required: true })}
              />
              <Select
                rootClass="col-12 mb-3"
                name="employeeType"
                label="Have You Hired Any Employees or Contractors"
                placeholder="Select Option"
                ref={register({ required: true })}
                options={employeeTypeOptions}
                defaultValue={null}
              />
              <TextField
                rootClass="col-12 mb-3"
                name="employeesMonthlyPayroll"
                label={`What Is ${businessName}'s Monthly Payroll Cost (Excluding Owners)`}
                type="number"
                placeholder="$500,000.00"
                step=".01"
                ref={register({ required: true })}
              />

              <Select
                rootClass="col-12 mb-3"
                name="ownersPaymentMethod"
                label="How Do The Owners of the Business Get Paid"
                placeholder="Select Option"
                ref={register({ required: true })}
                options={ownersPaymentMethodOptions}
                defaultValue={null}
              />

              <TextField
                rootClass="col-12 mb-3"
                name="ownersMonthlyPayroll"
                label="What Is Your Monthly Payroll (Owners Only)"
                type="number"
                placeholder="$200,000.00"
                step=".01"
                ref={register({ required: true })}
              />

              <Select
                rootClass="col-12 mb-3"
                name="residentialStatus"
                label={`${accountOwnerName}'s Residential Status`}
                placeholder="Select Option"
                ref={register({ required: true })}
                options={residentialStatusOptions}
                defaultValue={null}
              />
            </div>
            <div className="row">
              <div className="col-12 col-lg-4 order-lg-2 offset-lg-4">
                <Button type="submit" className="btn tw-bg-primary-dark-green tw-text-neutral-light btn-block mt-4">
                  {isSubmitting ? 'Submitting...' : 'Continue'}
                </Button>
              </div>

              <div className="col-12 col-lg-4">
                <Button type="button" className="btn btn-outline-primary btn-block mt-4" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Layout>
  );
};

export default CreditApplication;
