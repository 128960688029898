import React, { useState, createContext } from 'react';

export const CreateCardContext = createContext({});

export const CreateCardContextProvider = ({ children }) => {
  const [cardFormData, setCardFormData] = useState();
  const [initialTypeOfCard, setInitialTypeOfCard] = useState('virtual');
  // controls immediate user feedback when performing async requests
  const [isFetchingCardData, setIsFetchingCardData] = useState(false);
  // controls side effect behavior
  const [isModalFetchDataOnUnmount, setIsModalFetchDataOnUnmount] = useState(false);

  return (
    <CreateCardContext.Provider
      value={{
        cardFormData,
        setCardFormData,
        initialTypeOfCard,
        setInitialTypeOfCard,
        isFetchingCardData,
        setIsFetchingCardData,
        isModalFetchDataOnUnmount,
        setIsModalFetchDataOnUnmount,
      }}
    >
      {children}
    </CreateCardContext.Provider>
  );
};
