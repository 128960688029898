import React from 'react';
import { FormProvider } from 'react-hook-form';

import config from 'config';
import { TextField, Checkbox, SubmitButton } from 'components/FormFields/v2';
import { useDetails } from './hooks';
import { DetailsProps } from './Details.types';

const Details = ({ onNextStep }: DetailsProps) => {
  const { form, handleSubmit, register, acceptedElectronicAgreement, onSubmit, isLoading } = useDetails({ onNextStep });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-flex tw-flex-col tw-gap-8 tw-p-8">
          <div className="tw-text-sm tw-text-neutral-grey-2">
            In order to open an account with Loop, we'll need you to complete this brief onboarding and provide some
            additional information about your business.
          </div>
          <div>
            <TextField
              name="craBusinessNumber"
              label="CRA Business Number"
              placeholder={isLoading ? 'Loading...' : '123456789'}
              disabled={isLoading}
              maxLength="9"
              ref={register({
                required: true,
                pattern: { value: /\d{9}/, message: 'Must contain exactly 9 digits' },
              })}
              required
            />
            <div className="tw-text-xs tw-text-neutral-grey-2 tw-mt-2 tw-leading-none">
              The business number (BN) is a 9-digit account number that identifies your business to federal, provincial,
              and municipal governments. The BN is issued by the Canada Revenue Agency (CRA).
            </div>
          </div>

          <div>
            <div className="tw-text-sm tw-text-neutral-grey-2 tw-mb-4">
              By agreeing to the{' '}
              <a target="_blank" href={config.electronicsCommunicationAgreement} rel="noopener noreferrer">
                <span className="tw-text-neutral-grey-1 tw-underline">Electronic Communications Agreement</span>
              </a>
              , you consent to receive electronic disclosures and/or statements for your Loop Canadian Dollar Business
              Account and consent to be legally bound to this agreement.
            </div>
            <Checkbox
              label={
                <div className="tw-flex tw-cursor-pointer">
                  <small>
                    I have read and accept the{' '}
                    <a target="_blank" href={config.electronicsCommunicationAgreement} rel="noopener noreferrer">
                      <span className="tw-text-primary-dark-green tw-font-bold tw-underline">
                        Electronic Communications agreement.
                      </span>
                    </a>
                  </small>
                </div>
              }
              name="electronicAgreement"
              ref={register({ required: true })}
            />
          </div>
        </div>
        <div className="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-pt-4 tw-flex tw-justify-end">
          <SubmitButton isDisabled={!acceptedElectronicAgreement}>Next</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default Details;
