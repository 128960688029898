import { BankAccount } from 'types/bankAccount';

export type SolidContactType = {
  id: string;
  solidContactId: string;
  details: {};
};
export type PayeeExternalContactType = {
  solidContactType: SolidContactType;
};
export type Payee = {
  id: string;
  record: {}[] | null;
  paymentsAvailable: boolean;
  externalContact: PayeeExternalContactType | null;
};

export enum SupplierType {
  individual = 'individual',
  company = 'company',
}

export enum SupplierStatus {
  bank_details_requested = 'bank_details_requested',
  completed = 'completed',
}

export type SupplierAddress = {
  unitNumber: string;
  street: string;
  city: string;
  countrySubdivision: string;
  country: string;
  postalCode: string;
};

export type Supplier = {
  id: string;
  address: SupplierAddress | null;
  bankAccount: BankAccount | null;
  email: string;
  status: SupplierStatus | null;
  type: SupplierType;
};
