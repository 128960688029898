import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { get, isNull } from 'lodash';
import { useForm as useFormHook } from 'react-hook-form';

const useForm = ({ accountManager, initialValues, onSubmit, isBusinessAndPersonalAddressSame }) => {
  const form = useFormHook({ defaultValues: initialValues });
  const { handleSubmit, register, setValue, watch } = form;

  useEffect(() => {
    if (get(accountManager, 'birthdate')) {
      setValue('birthdate', get(accountManager, 'birthdate'), { shouldTouch: false });
    }
  }, [accountManager]);

  const history = useHistory();
  const isAccountOwner = watch('isAccountOwner');
  const isAccountOwnerValid = !isNull(isAccountOwner);
  const [personalAddressSameAsBusiness, setPersonalAddressSameAsBusiness] = useState(isBusinessAndPersonalAddressSame);

  const handleSubmitForm = (payload) => {
    const { isAccountOwner, accountOwnerContact, ...rest } = payload || {};

    const formData = {
      isAccountOwner,
      ...rest,
      ...(isAccountOwner === 'false' && { accountOwnerContact }),
    };

    onSubmit({ formData, personalAddressSameAsBusiness });
  };

  const handleTogglePersonalAddressSameAsBusiness = (event) => setPersonalAddressSameAsBusiness(event.target.checked);
  const onGoToPreviousStep = () => history.push('/onboarding/business-address');

  const [show, setShow] = useState(false);

  const onClose = () => {
    setShow(false);
  };

  const onOpen = () => {
    setShow(true);
  };

  return {
    form,
    handleSubmit,
    handleSubmitForm,
    register,
    isAccountOwner,
    isAccountOwnerValid,
    handleTogglePersonalAddressSameAsBusiness,
    personalAddressSameAsBusiness,
    onGoToPreviousStep,
    setValue,
    show,
    onOpen,
    onClose,
  };
};

export default useForm;
