import { gql } from '@apollo/client';

export const GET_AGREEMENTS = gql`
  query Agreements {
    me {
      ... on User {
        agreements {
          id
          status
          documentType
          productType
          signedAt
          signedBy
          url
          name
        }
      }
    }
  }
`;
