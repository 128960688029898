import React from 'react';
import cx from 'classnames';

import { BadgeProps, BadgeStatus } from './Badge.types';

import styles from './Badge.module.scss';

const Badge = ({ title, status = BadgeStatus.info, classNames, icon }: BadgeProps) => {
  return (
    <div className={cx(styles.badge, styles[`badge--${status}`], classNames)}>
      {icon}
      <div className={styles.badge__label}>{title}</div>
    </div>
  );
};

export default Badge;
