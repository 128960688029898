import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const CardAndCalendar = ({ width = '62', height = '64', vbWidth = '62', vbHeight = '64', ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      {...other}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9139 17.2999L4.21068 20.9126C2.91829 21.2802 2.16856 22.6258 2.5361 23.9182L5.00794 32.6099C5.37548 33.9023 6.72112 34.652 8.01351 34.2845L20.7168 30.6718C22.0092 30.3043 22.7589 28.9586 22.3913 27.6662L19.9195 18.9745C19.552 17.6821 18.2063 16.9324 16.9139 17.2999Z"
        fill="#EED9F7"
      />
      <path
        d="M15.0438 21.4455L2.34056 25.0581C1.04818 25.4257 0.29844 26.7713 0.665983 28.0637L3.13782 36.7554C3.50536 38.0478 4.851 38.7975 6.14339 38.43L18.8466 34.8173C20.139 34.4498 20.8888 33.1041 20.5212 31.8117L18.0494 23.12C17.6818 21.8276 16.3362 21.0779 15.0438 21.4455Z"
        stroke="#4F5154"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.14062 29.7344L18.524 24.7907" stroke="#4F5154" strokeLinejoin="round" />
      <path
        d="M7.77287 32.7265L5.76709 33.2969L6.00477 34.1326L8.01054 33.5622L7.77287 32.7265Z"
        stroke="#4F5154"
        strokeLinejoin="round"
      />
      <path
        d="M33.6004 2.11191C33.6713 1.90382 33.9773 1.94592 33.9894 2.16544L34.317 8.10766C34.3215 8.18908 34.375 8.25963 34.4522 8.28595L40.085 10.2064C40.2931 10.2774 40.251 10.5833 40.0315 10.5954L34.0893 10.9231C34.0078 10.9276 33.9373 10.981 33.911 11.0582L31.9905 16.6911C31.9195 16.8992 31.6136 16.8571 31.6015 16.6375L31.2739 10.6953C31.2694 10.6139 31.2159 10.5434 31.1387 10.517L25.5059 8.59654C25.2978 8.52559 25.3399 8.21965 25.5594 8.20755L31.5016 7.87991C31.583 7.87542 31.6536 7.82195 31.6799 7.74476L33.6004 2.11191Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M22.6553 46.5886C22.8676 46.5313 23.0034 46.8087 22.828 46.9412L18.903 49.9079C18.8379 49.9571 18.8093 50.0408 18.8305 50.1196L20.1123 54.8697C20.1695 55.082 19.8922 55.2178 19.7596 55.0424L16.793 51.1174C16.7438 51.0523 16.66 51.0236 16.5813 51.0449L11.8312 52.3267C11.6189 52.3839 11.4831 52.1066 11.6585 51.974L15.5835 49.0073C15.6485 48.9582 15.6772 48.8744 15.656 48.7957L14.3742 44.0456C14.3169 43.8333 14.5943 43.6975 14.7269 43.8729L17.6935 47.7979C17.7427 47.8629 17.8264 47.8916 17.9052 47.8704L22.6553 46.5886Z"
        fill="#EEA530"
      />
      <path
        d="M49.3668 16.9143C49.5219 16.7585 49.7783 16.9306 49.6928 17.1332L48.2738 20.4945C48.2421 20.5696 48.2592 20.6565 48.3169 20.714L50.9027 23.2881C51.0585 23.4432 50.8864 23.6996 50.6838 23.614L47.3225 22.195C47.2474 22.1633 47.1605 22.1804 47.103 22.2382L44.5289 24.8239C44.3738 24.9798 44.1174 24.8076 44.2029 24.6051L45.622 21.2438C45.6537 21.1686 45.6366 21.0818 45.5788 21.0242L42.993 18.4502C42.8372 18.2951 43.0094 18.0387 43.2119 18.1242L46.5732 19.5432C46.6484 19.5749 46.7352 19.5578 46.7928 19.5L49.3668 16.9143Z"
        fill="#EEA530"
      />
      <g clipPath="url(#clip0_3825_131450)">
        <path
          d="M56.4706 30.6184L35.9565 26.6457C34.2571 26.3166 32.5883 27.5527 32.2293 29.4066L28.3288 49.5477C27.9698 51.4017 29.0564 53.1714 30.7558 53.5005L51.2699 57.4732C52.9693 57.8023 54.638 56.5662 54.9971 54.7123L58.8976 34.5712C59.2566 32.7173 58.17 30.9476 56.4706 30.6184Z"
          fill="#FFE8C3"
        />
        <path
          d="M54.7625 31.8518L34.2485 27.8791C32.5491 27.55 30.8803 28.7861 30.5213 30.64L26.6208 50.7811C26.2618 52.635 27.3484 54.4048 29.0478 54.7339L49.5619 58.7066C51.2613 59.0357 52.93 57.7996 53.2891 55.9457L57.1896 35.8046C57.5486 33.9507 56.462 32.181 54.7625 31.8518Z"
          stroke="#4F5154"
          strokeLinejoin="round"
        />
        <path
          d="M44.6858 42.6704C45.5356 42.835 46.3699 42.2169 46.5494 41.29C46.7289 40.363 46.1856 39.4781 45.3359 39.3136C44.4862 39.149 43.6518 39.7671 43.4723 40.694C43.2928 41.621 43.8361 42.5059 44.6858 42.6704Z"
          fill="#4F5154"
        />
        <path
          d="M49.8143 43.6636C50.664 43.8281 51.4983 43.2101 51.6778 42.2831C51.8574 41.3562 51.3141 40.4713 50.4643 40.3067C49.6146 40.1422 48.7803 40.7602 48.6007 41.6872C48.4212 42.6142 48.9645 43.499 49.8143 43.6636Z"
          fill="#4F5154"
        />
        <path
          d="M43.6023 48.2651C44.4521 48.4297 45.2864 47.8116 45.4659 46.8847C45.6454 45.9577 45.1021 45.0729 44.2524 44.9083C43.4027 44.7438 42.5683 45.3618 42.3888 46.2888C42.2093 47.2157 42.7526 48.1006 43.6023 48.2651Z"
          fill="#4F5154"
        />
        <path
          d="M48.7312 49.2583C49.581 49.4229 50.4153 48.8048 50.5948 47.8778C50.7744 46.9509 50.2311 46.066 49.3813 45.9015C48.5316 45.7369 47.6972 46.355 47.5177 47.2819C47.3382 48.2089 47.8815 49.0938 48.7312 49.2583Z"
          fill="#4F5154"
        />
        <path
          d="M33.346 46.2798C34.1957 46.4444 35.0301 45.8263 35.2096 44.8993C35.3891 43.9724 34.8458 43.0875 33.9961 42.923C33.1464 42.7584 32.312 43.3765 32.1325 44.3034C31.953 45.2304 32.4963 46.1152 33.346 46.2798Z"
          fill="#4F5154"
        />
        <path
          d="M38.4739 47.272C39.3236 47.4365 40.158 46.8185 40.3375 45.8915C40.517 44.9646 39.9737 44.0797 39.124 43.9151C38.2743 43.7506 37.4399 44.3686 37.2604 45.2956C37.0809 46.2226 37.6242 47.1074 38.4739 47.272Z"
          fill="#4F5154"
        />
        <path
          d="M32.2625 51.8736C33.1122 52.0381 33.9466 51.4201 34.1261 50.4931C34.3056 49.5661 33.7623 48.6813 32.9126 48.5167C32.0629 48.3521 31.2285 48.9702 31.049 49.8972C30.8695 50.8241 31.4128 51.709 32.2625 51.8736Z"
          fill="#4F5154"
        />
        <path
          d="M37.3904 52.8667C38.2401 53.0313 39.0745 52.4132 39.254 51.4862C39.4335 50.5593 38.8902 49.6744 38.0405 49.5099C37.1908 49.3453 36.3564 49.9634 36.1769 50.8903C35.9974 51.8173 36.5407 52.7022 37.3904 52.8667Z"
          fill="#4F5154"
        />
        <path
          d="M42.5188 53.8599C43.3686 54.0244 44.2029 53.4064 44.3824 52.4794C44.562 51.5524 44.0186 50.6676 43.1689 50.503C42.3192 50.3385 41.4848 50.9565 41.3053 51.8835C41.1258 52.8105 41.6691 53.6953 42.5188 53.8599Z"
          fill="#4F5154"
        />
        <path d="M36.7334 26.0391L36.3 28.277" stroke="#4F5154" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M53.145 29.2168L52.7116 31.4547" stroke="#4F5154" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M56.7561 38.0425L30.0879 32.8779" stroke="#4F5154" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3825_131450">
          <rect width="33.4323" height="36.4716" fill="white" transform="translate(29.1777 21.0928) rotate(10.9602)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CardAndCalendar;
