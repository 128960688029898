export const TAX_ENUM = ['NON', 'HST', 'GST'];
export const CURRENCY_REFS = {
  CAD: {
    value: 'CAD',
    name: 'Canadian Dollar',
  },
  USD: {
    value: 'USD',
    name: 'United States Dollar',
  },
  GBP: {
    value: 'GBP',
    name: 'British Pound Sterling',
  },
  EUR: {
    value: 'EUR',
    name: 'European Euro',
  },
};

export const QUICKBOOK_MOCK = [
  { id: '1000', name: 'Cash & Cash Equivalents', taxCodeRefId: 'CA001' },
  { id: '1010', name: 'Business Checking', taxCodeRefId: 'CA002' },
  { id: '1011', name: 'Business Chequing (TD)', taxCodeRefId: 'CA003' },
  { id: '1012', name: 'Business Chequing (BMO)', taxCodeRefId: 'CA004' },
  { id: '1013', name: 'Business Chequing (RBC)', taxCodeRefId: 'CA005' },
  { id: '1020', name: 'Savings Account', taxCodeRefId: 'CA006' },
  { id: '1021', name: 'Business Savings (RBC)', taxCodeRefId: 'CA007' },
];

export const QUICKBOOK_ALLOWED_PUSH_TYPE_TX = [
  'withdrawal',
  'cancellation',
  'credit',
  'withdrawal_fee',
  'monthly_fee',
  'nsf_fee',
  'currency_exchange_markup_fee',
  'late_payment_fee',
  'wire_fee',
  'fee',
  'credit_card_interchange',
  'credit_card_fee',
  'deposit',
  'balance_repayment',
];
