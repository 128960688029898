import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_CARDS_GLOBAL_LIMIT_INFO } from 'graphql/cards';
import { GlobalLimitInfoQueryResponse, GlobalLimitInfo } from 'types/creditCard';
import { Money, PayBalanceCurrency } from 'types/payments';
import { useDeepEffect } from 'hooks';
import { GlobalLimitChartType, ChartDataset } from '../components/GlobalLimitChart/GlobalLimitChart.types';
import { transformCardsGlobalLimitInfo, buildDataset } from '../GlobalLimit.utils';
import { DEFAULT_GLOBAL_LIMIT_INFO, DEFAULT_BALANCE } from '../constants';
import { useGetConvertedGlobalLimitInfo } from './';

const useGlobalLimit = () => {
  const [chartType, setChartType] = useState(GlobalLimitChartType.CAD);
  const [dataset, setDataset] = useState<ChartDataset>([]);
  const [globalLimitInfo, setGlobalLimitInfo] = useState<GlobalLimitInfo>(DEFAULT_GLOBAL_LIMIT_INFO);
  const [totalCreditLimit, setTotalCreditLimit] = useState<Money<PayBalanceCurrency>>(DEFAULT_BALANCE);

  const { loading: isGlobalLimitInfoLoading, error: globalLimitInfoError } = useQuery<{
    primaryCreditCard: GlobalLimitInfoQueryResponse;
  }>(GET_CARDS_GLOBAL_LIMIT_INFO, {
    onCompleted: (data) => {
      const globalLimitInfoData = get(data, 'primaryCreditCard');
      const globalLimitInfo = transformCardsGlobalLimitInfo(globalLimitInfoData);

      // adjust total spent to exclude amount due
      const totalSpentAmount = globalLimitInfo.groupTotalSpent.amount - globalLimitInfo.groupAmountDue.amount;
      const groupTotalSpent = {
        amount: totalSpentAmount > 0 ? totalSpentAmount : 0,
        currency: globalLimitInfo.groupTotalSpent.currency,
      };

      const { groupAvailableBalance, groupOverlimit, groupAmountDue, groupCreditLimit } = globalLimitInfo;
      setGlobalLimitInfo({ groupAvailableBalance, groupOverlimit, groupTotalSpent, groupAmountDue, groupCreditLimit });
    },
  });

  const needsConversion = [GlobalLimitChartType.USD, GlobalLimitChartType.EUR, GlobalLimitChartType.GBP].includes(
    chartType
  );

  const { convertedGlobalLimitInfo, isFXRatesLoading } = useGetConvertedGlobalLimitInfo({
    globalLimitInfo,
    chartType,
  });

  const changeChartType = (type: GlobalLimitChartType) => {
    setChartType(type);
  };

  useDeepEffect(() => {
    const isConversionReady = !isFXRatesLoading && convertedGlobalLimitInfo;

    if (needsConversion && !isConversionReady) return;

    const globalLimitSource = needsConversion && isConversionReady ? convertedGlobalLimitInfo : globalLimitInfo;

    const {
      groupAvailableBalance: availableBalance,
      groupAmountDue: amountDue,
      groupCreditLimit,
      groupOverlimit: availableOverlimit,
      groupTotalSpent: amountSpent,
    } = globalLimitSource;

    const isAvailableBalanceNegative = availableBalance.amount < 0;
    const isOverlimit = availableOverlimit.amount > 0;

    const availableBalanceLabel = isOverlimit ? 'Available from Credit' : 'Available';
    const availableBalanceValue = isOverlimit && isAvailableBalanceNegative ? 0 : availableBalance.amount;

    const availableOverlimitValue = isAvailableBalanceNegative
      ? availableOverlimit.amount - Math.abs(availableBalance.amount)
      : availableOverlimit.amount;

    const newDataset = buildDataset({
      isOverlimit,
      availableOverlimit: { value: availableOverlimitValue > 0 ? availableOverlimitValue : 0 },
      availableBalance: { label: availableBalanceLabel, value: availableBalanceValue },
      amountSpent: { value: amountSpent.amount },
      amountDue: { value: amountDue.amount },
      currency: availableBalance.currency,
    });

    setDataset(newDataset);
    setTotalCreditLimit(groupCreditLimit);
  }, [globalLimitInfo, convertedGlobalLimitInfo, needsConversion, isFXRatesLoading]);

  return {
    chartType,
    changeChartType,
    dataset,
    isLoading: isGlobalLimitInfoLoading,
    isError: !!globalLimitInfoError,
    needsConversion,
    isFXRatesLoading,
    totalCreditLimit,
    isOverlimitAvailable: globalLimitInfo.groupOverlimit.amount > 0,
  };
};

export default useGlobalLimit;
