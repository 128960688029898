import React, { useMemo, useState, useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { EXTERNAL_TRANSACTION_CLEARING_MESSAGE } from 'constants/index';
import { formatMoneyV2, centsFromMoneyString } from 'utility/currency';
import { PaymentContext } from 'context/Payment';
import { Select, MoneyInputField, SubmitButton } from 'components/FormFields/v2';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import CashAndStarsAlt from 'components/svg/CashAndStarsAlt';
import InfoTip from 'components/onboarding/InfoTip';
import EmptyState from 'components/payments/EmptyState';
import useGetProductState from 'hooks/useGetProductState';

const Details = ({
  wallets,
  bankAccounts,
  cardLedgerAccounts,
  onFinish,
  onNextStep,
  initialToAccountId,
  footerBorder,
  footerButton,
  svgAndTooltip = 'right',
}) => {
  const { addMoneyInfo, setAddMoneyInfo } = useContext(PaymentContext);
  const toAccounts = useMemo(() => [...wallets, ...cardLedgerAccounts], []);
  const initialToAccount = wallets.find((w) => w.id === initialToAccountId);
  const initialFromAccount = initialToAccount && bankAccounts.find((ba) => ba.currency === initialToAccount.currency);
  const form = useForm({
    defaultValues: {
      toAccount: initialToAccount && initialToAccount.id,
      fromAccount: initialFromAccount && initialFromAccount.id,
    },
  });

  const { register, handleSubmit, watch, setValue, setError } = form;
  const toAccount = watch('toAccount');
  const selectedToAccount = toAccounts.find((a) => a.id === toAccount);
  const toAccountIsCard = cardLedgerAccounts.some((a) => a.id === toAccount);
  const currency = selectedToAccount ? selectedToAccount.currency : 'CAD';
  const [formattedAmount, setFormattedAmount] = useState(formatMoneyV2({ amount: 0, currency }));

  const { fromOptions, toOptions } = getSelectOptions({
    toAccounts,
    fromAccounts: toAccountIsCard ? [...bankAccounts, ...wallets] : bankAccounts,
    toAccount,
    cardLedgerAccounts,
  });

  const onSubmit = (data) => {
    const { fromAccount, amount } = data;
    const selectedFromAccount = [...bankAccounts, ...wallets].find((ba) => ba.id === fromAccount);
    const cents = centsFromMoneyString(amount);
    const fromIsWallet = wallets.some((w) => w.id === fromAccount);
    const sufficientFunds = fromIsWallet ? selectedFromAccount.availableBalance.amount >= cents : true;

    if (sufficientFunds) {
      setAddMoneyInfo({
        fromAccount: selectedFromAccount,
        toAccount: selectedToAccount,
        amount: { amount: cents, currency: selectedFromAccount.currency },
        toAccountIsCard,
      });
      onNextStep();
    } else {
      setError('fromAccount', { type: 'manual', message: 'Insufficient funds' });
    }
  };

  useEffect(() => {
    if (addMoneyInfo) {
      const { toAccount, fromAccount, amount } = addMoneyInfo;
      setFormattedAmount(formatMoneyV2(amount));
      setValue('toAccount', toAccount.id);
      setValue('fromAccount', fromAccount.id);
    }
  }, [addMoneyInfo]);

  if (!bankAccounts || bankAccounts.length === 0)
    return (
      <EmptyState
        description="You have no bank accounts connected."
        onFinish={onFinish}
        footerBorder={footerBorder}
        footerButton={footerButton}
      />
    );

  if (toAccounts.length === 0)
    return (
      <EmptyState
        description="You have no wallets or cards to add money to."
        onFinish={onFinish}
        footerBorder={footerBorder}
        footerButton={footerButton}
      />
    );

  return (
    <div className="tw-mt-8 tw-relative">
      <div className="tw-px-8">
        <small className="tw-text-neutral-grey-2">
          Select the from account and to account. The from and to accounts must have matching currencies.
        </small>
      </div>
      <FormProvider {...form}>
        <form className="tw-mt-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-px-8 tw-space-y-4">
            <Select
              label="Recipient Account"
              name="toAccount"
              placeholder="Choose"
              ref={register({ required: true })}
              options={toOptions}
              data-testid="to-account-select"
            />
            <Select
              options={fromOptions}
              name="fromAccount"
              label="From Account"
              placeholder="Choose"
              ref={register({ required: true })}
              data-testid="from-account-select"
              disabled={!toAccount}
            />
            <div className="tw-flex tw-items-end">
              <MoneyInputField
                ref={register({ required: true })}
                name="amount"
                label="Amount"
                currency={currency}
                value={formattedAmount}
                setValue={setFormattedAmount}
                rootClass="tw-flex-grow tw-mr-4"
                data-testid="amount-input"
              />
              <div className="tw-flex tw-items-center tw-h-11 tw-bg-neutral-grey-4 tw-rounded-md tw-p-4 tw-flex-grow-0">
                <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5 tw-mr-2">
                  <MobileCurrencyFlag currency={currency} size={16} />
                </div>
                <p className="tw-text-neutral-grey-2">{currency}</p>
              </div>
            </div>
            <div className="tw-flex tw-items-center tw-mb-8">
              <InfoTip
                icon={<CashAndStarsAlt />}
                text={EXTERNAL_TRANSACTION_CLEARING_MESSAGE}
                forceBottom={svgAndTooltip === 'bottom'}
              />
            </div>
          </div>
          <div
            className={`${
              footerBorder ? 'tw-border-t tw-border-neutral-grey-3' : ''
            } tw-px-8 tw-py-4 tw-flex tw-justify-end`}
          >
            <SubmitButton className="tw-w-full lg:tw-w-max">Review</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const getSelectOptions = ({ toAccounts, fromAccounts, toAccount }) => {
  const { isCreditCard } = useGetProductState();
  const currentToAccount = toAccounts.find((ba) => ba.id === toAccount);

  const toOptions = toAccounts.reduce((options, option) => {
    if (isCreditCard && option.displayName.includes('Loop Card')) return options;

    return [...options, { name: option.displayName, value: option.id }];
  }, []);

  const fromOptions = fromAccounts
    .filter((a) => (currentToAccount ? a.currency === currentToAccount.currency : false))
    .filter((a) => (isCreditCard ? a.country !== 'US' : true)) // TODO: Remove filter after adding support for US-based accounts for Payments
    .map((a) => {
      const name = a.availableBalance
        ? `${a.displayName} (${a.availableBalance.currency} ${formatMoneyV2(a.availableBalance)})`
        : a.displayName;
      return { name, value: a.id };
    });

  return { fromOptions, toOptions };
};

export default Details;
