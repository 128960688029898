import React from 'react';
import { useQuery } from '@apollo/client';
import qs from 'query-string';

import ProfileSetup from '../components/auth/ProfileSetup';
import { USER_INVITATION } from '../graphql/user';

const ProfileSetupContainer = () => {
  const { sgid } = qs.parse(location.search);
  const { loading, data, error } = useQuery(USER_INVITATION, { variables: { sgid } });

  if (error) console.error(error);

  return <ProfileSetup loading={loading} error={error} contact={data && data.userInvitation} sgid={sgid} />;
};

export default ProfileSetupContainer;
