import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_CREDIT_CARDS_LIST } from 'graphql/cards';
import { CreditCard } from 'types/creditCard';
import { CardsListContextProviderProps, CardsListContextType } from './CardsList.types';

export const CardsListContext = React.createContext<CardsListContextType>({} as CardsListContextType);

export const CardsListContextProvider = ({ children }: CardsListContextProviderProps) => {
  const { data, loading, error } = useQuery<{ creditCards: CreditCard[] }>(GET_CREDIT_CARDS_LIST);

  const creditCards = data?.creditCards;

  return (
    <CardsListContext.Provider
      value={{
        isLoading: loading,
        error,
        creditCards,
      }}
    >
      {children}
    </CardsListContext.Provider>
  );
};
