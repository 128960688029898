import React from 'react';

import Stripe from 'components/onboarding/integrations/Stripe';
import Square from 'components/onboarding/integrations/Square';
import AmazonOauth from 'components/onboarding/integrations/AmazonOauth';

export const salesChannels = () => {
  const channels = [
    {
      name: 'Stripe',
      component: <Stripe />,
      testId: 'stripe',
    },
    {
      name: 'Shopify',
      component: (
        <img
          style={{ width: '62%', marginTop: '-12px' }}
          src="https://loop-public.s3.ca-central-1.amazonaws.com/shopify_logo.png"
        />
      ),
      testId: 'shopify',
    },
    {
      name: 'WooCommerce',
      component: (
        <img
          className="tw-block tw-w-full"
          src="https://loop-public.s3.ca-central-1.amazonaws.com/woocommerce-logo.png"
        />
      ),
      testId: 'wooCommerce',
    },
    {
      name: 'Square',
      component: <Square />,
      testId: 'square',
    },
    {
      name: 'Amazon',
      component: <AmazonOauth />,
      testId: 'amazon',
    },
  ];
  return channels;
};
