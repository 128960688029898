import React from 'react';
import { Link } from 'react-router-dom';

import DashboardLayout from 'components/dashboard/DashboardLayout';
import config from 'config';
import LightningPromo from 'components/svg/LightningPromo';
import useScrollViewToPosition from 'hooks/useScrollViewToPosition';

import styles from './FXTable.module.scss';

const FXTable = () => {
  useScrollViewToPosition();

  return (
    <div className="tw-mb-12">
      <DashboardLayout>
        <div className="lg:tw-flex tw-flex-wrap">
          <div className="tw-w-full lg:tw-w-1/4 tw-pb-8 lg:tw-order-2">
            <Link
              to="/dashboard/subscriptions/active"
              className="tw-flex tw-items-center tw-rounded tw-bg-primary-dark-green tw-mb-3 tw-rounded tw-py-3"
            >
              <div className="tw-flex tw-p-3 tw-items-start">
                <div className="tw-mr-1 tw-col-2">
                  <LightningPromo />
                </div>
                <div className="tw-ml-1">
                  <small className="tw-flex tw-mr-1 tw-font-semibold tw-text-neutral-grey-5">Upgrade</small>
                  <span className="tw-flex tw-text-xs tw-text-neutral-grey-5 tw-mt-2">
                    Unlock more features and lower rates with simple and transparent pricing. Unlike bank fees, our
                    plans make it easy to understand what you’re paying.
                  </span>
                  <small className="tw-flex tw-font-semibold tw-text-neutral-grey-5 tw-mt-2">See Plans →</small>
                </div>
              </div>
            </Link>
          </div>
          <div className="tw-w-full lg:tw-w-3/4 lg:tw-order-1">
            <iframe src={config.fxCompareUrl} className={styles.iframe} />
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default FXTable;
