import { useContext, useState, useCallback, useEffect } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { get, isUndefined } from 'lodash';
import { toast } from 'react-toastify';
import { ampTrackEvent } from 'amplitude';

import getStepsWithError from 'utility/getStepsWithError';
import { AddPayeeContext } from 'components/payments/AddPayee/AddPayeeContext';
import { ADD_SUPPLIER_BANK_ACCOUNT } from 'graphql/suppliers';
import { BankDetailsInfo } from '../../../AddSupplierBankDetails.types';
import { ATTRIBUTES_BY_STEP } from '../constants';

const AddBankDetails = ({ supplierId, onFinish }: { supplierId: string; onFinish: () => void }) => {
  const { errorSteps, setErrorSteps } = useContext(AddPayeeContext) as unknown as {
    errorSteps: number[];
    setErrorSteps: React.Dispatch<React.SetStateAction<number[]>>;
  };

  const [addSupplierBankAccount, { loading: isSubmitting, error: addSupplierBankAccountError }] = useMutation<{
    addSupplierBankAccount: { id: string };
  }>(ADD_SUPPLIER_BANK_ACCOUNT);

  const [step, setStep] = useState(0);
  const [error, setError] = useState<ApolloError | undefined>();

  const handlePrevStep = useCallback(() => {
    const prevStep = step - 1;
    setStep(prevStep < 0 ? 0 : prevStep);
  }, [step]);

  const handleNextStep = useCallback(() => {
    setStep((prevStep) => prevStep + 1);
  }, []);

  const submitBankDetails = useCallback(
    async (data: BankDetailsInfo) => {
      const processingToast = toast.loading('Submitting bank details...');

      const { address, bankAccount } = data || {};
      const payload = {
        supplierId,
        address,
        bankAccount,
      };

      try {
        const response = await addSupplierBankAccount({ variables: payload });

        const supplier = get(response, 'data.addSupplierBankAccount');

        if (!supplier) throw new Error('Something went wrong. Please try again.');

        toast.update(processingToast, {
          render: 'Bank details were added successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        onFinish();

        ampTrackEvent('Supplier: bank details added by user: success');
      } catch (error) {
        toast.update(processingToast, {
          render: 'Something went wrong. Please try again.',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
        console.error(error);
        ampTrackEvent('Supplier: bank details added by user: failed');
      }
    },
    [supplierId, onFinish]
  );

  useEffect(() => {
    const fallbackSteps = getStepsWithError({
      attributesByStep: ATTRIBUTES_BY_STEP,
      error: addSupplierBankAccountError,
    });
    setErrorSteps(fallbackSteps);

    const errorStep = fallbackSteps[0];

    if (!isUndefined(errorStep)) setStep(errorStep);
  }, [setErrorSteps, addSupplierBankAccountError]);

  useEffect(() => {
    setError(addSupplierBankAccountError);
  }, [setError, addSupplierBankAccountError]);

  return { error, errorSteps, step, setStep, handleNextStep, handlePrevStep, submitBankDetails, isSubmitting };
};

export default AddBankDetails;
