import React from 'react';

import config from 'config';

const AgreementInfo = () => (
  <div className="tw-mt-12 tw-text-center tw-text-neutral-grey-2 tw-text-sm">
    By clicking "Create Account" I confirm I have read and agree to the&nbsp;
    <a className="tw-inline-block" target="_blank" rel="noopener noreferrer" href={config.platformAgreementUrl}>
      <span className="tw-underline">Platform Agreement</span>
    </a>
    &nbsp;and&nbsp;
    <a className="tw-inline-block" target="_blank" rel="noopener noreferrer" href={config.privacyPolicyUrl}>
      <span className="tw-underline">Privacy Policy</span>
    </a>
    .
  </div>
);

export default AgreementInfo;
