import React, { useState, useEffect } from 'react';
import { IoDownloadOutline } from 'react-icons/io5';
import { useLazyQuery } from '@apollo/client';
import { Pagination } from 'react-headless-pagination';
import _ from 'lodash';

import fileDownload from 'utility/fileDownload';
import { GET_STATEMENT } from 'graphql/statements';
import { Loading } from './dashboard/DashboardLayout';
import Modal from './Modal/v2';
import Close from './svg/Close';
import Attachment from './svg/Attachment';

const StatementsModal = ({ statements, loading, show, setShow, type }) => {
  const onClose = () => setShow(false);

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <strong>{`Download ${type} Statement`}</strong>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-px-8 tw-pt-8 tw-p-4 tw-border-t tw-border-neutral-grey-4">
        {loading ? <Loading /> : <Content statements={statements} type={type} />}
      </div>
    </Modal>
  );
};

export const Content = ({ statements, type }) => {
  const [month, setMonth] = useState();
  const [visibleStatements, setVisibleStatements] = useState(statements);
  const [page, setPage] = useState(0);
  const numPerPage = 5;
  const pages = _.chunk(visibleStatements, numPerPage);
  const statementsForPage = pages[page] || [];

  const handleChangePage = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (month) {
      setVisibleStatements(statements.filter((statement) => month === new Date(statement.createdAt).getMonth()));
    } else {
      setVisibleStatements(statements);
    }
  }, [month]);

  return (
    <div>
      <strong>{`Your ${type} Statements`}</strong>
      <div className="tw-mt-8">
        {visibleStatements?.length > 0 ? (
          <Statements statements={statementsForPage} setMonth={setMonth} type={type} />
        ) : (
          <p>No statements found.</p>
        )}
      </div>
      <div className="tw-mt-12 tw-px-2">
        {visibleStatements?.length > 4 && (
          <PaginationControl
            page={page}
            onChangePage={handleChangePage}
            numPerPage={numPerPage}
            numberOfItems={visibleStatements.length}
          />
        )}
      </div>
    </div>
  );
};

const Statements = ({ statements, type }) => (
  <table className="tw-table-auto tw-w-full">
    <tbody>
      {statements.map((statement) => (
        <StatementRow key={statement.id} statement={statement} type={type} />
      ))}
    </tbody>
  </table>
);

const StatementRow = ({ statement, type }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const onGetDownloadUrl = (data) => {
    const { downloadUrl } = (data && data.statementsDownload) || {};
    try {
      fileDownload(downloadUrl, `statement_${statement.title}.pdf`, {
        onComplete: () => {
          setIsDownloading(false);
        },
      });
    } catch (error) {
      setIsDownloading(false);
      console.error(error);
    }
  };

  const [getUrl] = useLazyQuery(GET_STATEMENT, {
    variables: { statementId: statement.id },
    onCompleted: onGetDownloadUrl,
  });

  const getDownloadUrl = () => {
    setIsDownloading(true);
    getUrl();
  };

  return (
    <tr className="tw-flex tw-items-center tw-border-b tw-border-neutral-grey-4">
      <td className="tw-w-10 tw-py-3 tw-pl-2">
        <Attachment size={21} />
      </td>
      <td className="tw-w-10/12 tw-py-3 tw-pl-2 tw-text-sm">{`${type} Statement - ${statement.title}`}</td>
      <td className="">
        {isDownloading ? (
          <div className="spinner-border tw-w-4 tw-h-4" role="status" />
        ) : (
          <IoDownloadOutline
            onClick={getDownloadUrl}
            size={21}
            className="tw-text-primary-dark-green tw-cursor-pointer"
          />
        )}
      </td>
    </tr>
  );
};

const PaginationControl = ({ page, numPerPage, numberOfItems, onChangePage }) => {
  const totalOfPages =
    numberOfItems % numPerPage === 0
      ? Math.floor(numberOfItems / numPerPage)
      : Math.floor(numberOfItems / numPerPage) + 1;

  return (
    <div className="tw-flex tw-items-center tw-float-right">
      <Pagination
        currentPage={page}
        setCurrentPage={onChangePage}
        totalPages={totalOfPages}
        edgePageCount={1}
        middlePagesSiblingCount={1}
        className="tw-flex tw-items-center"
        truncableText="..."
        truncableClassName="tw-text-sm"
      >
        <Pagination.PrevButton
          className={`${page === 0 ? 'tw-text-neutral-grey-3' : ''} tw-mr-4 tw-font-semibold tw-text-sm`}
        >
          Prev
        </Pagination.PrevButton>
        <div className="tw-flex tw-items-center tw-justify-center">
          <Pagination.PageButton
            activeClassName="tw-border tw-border-neutral-grey-3 tw-rounded-md"
            className="tw-text-sm tw-px-3 tw-py-1 tw-mx-2 tw-cursor-pointer"
          />
        </div>
        <Pagination.NextButton
          className={`${page === totalOfPages - 1 ? 'tw-text-neutral-grey-3' : ''} tw-ml-4 tw-font-semibold tw-text-sm`}
        >
          Next
        </Pagination.NextButton>
      </Pagination>
    </div>
  );
};

export default StatementsModal;
