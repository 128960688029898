import { useState, useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ApolloError } from '@apollo/client';

import { USBankAccountVerificationContext } from 'components/Accounts/components/USBankAccountVerificationModal/contexts/USBankAccountVerificationContext';
import { AccountProjections } from 'components/Accounts/components/USBankAccountVerificationModal/USBankAccountVerificationModal.types';
import { TransactionsVolumeProps } from '../TransactionsVolume.types';

const useTransactionsVolume = ({
  onNextStep,
  onSubmitNaicsAndProjections,
}: Pick<TransactionsVolumeProps, 'onNextStep' | 'onSubmitNaicsAndProjections'>) => {
  const { industryData, projections, setProjections, error, setError } = useContext(USBankAccountVerificationContext);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      projections,
    },
  });

  const { register, handleSubmit } = form;

  const onSubmit = useCallback(
    async (data: AccountProjections) => {
      setError(undefined);
      setIsLoading(true);

      try {
        // submit the NAICS code and projections if callback is provided
        await onSubmitNaicsAndProjections?.({
          naicsCode: industryData?.naicsCode || '',
          projections: data,
        });

        setProjections(data);

        onNextStep();
      } catch (err) {
        console.error(err);
        setError(err as ApolloError);
      } finally {
        setIsLoading(false);
      }
    },
    [industryData, onNextStep, onSubmitNaicsAndProjections]
  );

  return {
    form,
    register,
    handleSubmit,
    onSubmit,
    error,
    isLoading,
  };
};

export default useTransactionsVolume;
