import { ID_DOCUMENT_OPTIONS } from 'constants/index';
class ExternalWalletVerificationStatus {
  constructor(attributes) {
    const { isVerified, members } = attributes || {};

    this.isVerified = isVerified || false;
    this.members = members ? members.map((m) => new ExternalWalletMemberVerificationStatus(m)) : [];
  }
}

class ExternalWalletMemberVerificationStatus {
  constructor(attributes) {
    const { firstName, lastName, status, idType, idNumber } = attributes || {};

    this.firstName = firstName;
    this.lastName = lastName;
    this.status = status;
    this.idType = idType;
    this.idNumber = idNumber;
  }

  fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  isAccepted() {
    return this.status === 'accepted';
  }

  formattedId() {
    const idOption = ID_DOCUMENT_OPTIONS.find((o) => o.value === this.idType);
    const idString = idOption ? idOption.name : 'Document';

    return `${idString} - ${this.idNumber}`;
  }
}

export default ExternalWalletVerificationStatus;
