import React from 'react';
import { useQuery } from '@apollo/client';

import { formatMoney } from 'utility/currency';
import { GET_REDEEMED_GIFTS } from 'graphql/rewards';
import GiftStatusBadge from './GiftStatusBadge';
import CurrencyFlag from 'components/svg/CurrencyFlag';
import { Loaders } from 'components/cards/Loader';

import { IconTooltip } from 'components/InfoTooltip';
import RepeatOutline from 'components/svg/RepeatOutline';

export const RedeemdGiftsList = () => {
  const { data: redeemedGiftsData, loading, error } = useQuery(GET_REDEEMED_GIFTS);
  const { redeemedGifts } = redeemedGiftsData || { redeemedGifts: [] };

  if (loading) return <Loaders.Light />;
  if (error) {
    console.error(error);
    return 'Error fetching RedeemGiftsList';
  }
  if (!loading && redeemedGifts?.length === 0)
    return <div className="tw-mt-4 tw-text-sm tw-text-neutral-grey-2">No Redeemed Gifts yet.</div>;

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-mt-16">
      <div className="tw-flex">
        <table className="tw-table tw-table-auto tw-w-3/4">
          <thead>
            <tr>
              <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Date</th>
              <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Provider</th>
              <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Status</th>
              <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Points</th>
              <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Amount</th>
              <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Currency</th>
              <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Link</th>
            </tr>
          </thead>
          <tbody>
            {redeemedGifts.map((redeemedGift) => (
              <tr key={redeemedGift.internalId}>
                <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">
                  {redeemedGift.redeemedAt}
                </td>
                <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5 tw-capitalize">
                  {redeemedGift.provider}
                </td>
                <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5 tw-capitalize">
                  <GiftStatusBadge status={redeemedGift.status} />
                </td>
                <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5 tw-capitalize">
                  {Intl.NumberFormat().format(redeemedGift.pointsRequired)}
                </td>
                <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">
                  {formatMoney(redeemedGift.giftValue)}
                </td>
                <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">
                  <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                    <CurrencyFlag currency={redeemedGift.giftValue.currencyy} size={14} />
                  </div>
                </td>
                <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">
                  {redeemedGift.status === 'redeemed' ? (
                    <a
                      className="tw-text-sm tw-font-bold tw-text-primary-dark-green tw-self-end tw-flex-nowrap"
                      href={redeemedGift.giftLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get Reward
                    </a>
                  ) : (
                    <div>
                      <IconTooltip icon={<RepeatOutline />} message="Please wait, we are generating your reward." />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
