import React, { memo } from 'react';
import cx from 'classnames';
import { Combobox, Transition } from '@headlessui/react';
import { BsChevronDown } from 'react-icons/bs';
import { IoSearchOutline } from 'react-icons/io5';

import { SearchDropdownProps, Option } from './SearchDropdown.types';
import { useSearchDropdown } from './hooks';
import { SelectedOptionsBar, SelectedOptionBadges } from './components';

const SearchDropdown = ({
  optionsList,
  selectedOptions,
  onSelectOptions,
  customOptionLabel,
  isLoading,
  placeholder = 'Type to search...',
  rootClass = '',
  multiple,
  showSelectedOptionsBar,
  customOptionBarItemLabel,
  showSearchIcon,
  showSelectedOptionsBadges,
  customSelectedOptionsBadgeLabel,
}: SearchDropdownProps) => {
  const { filteredOptions, handleSelectOption, handleRemoveOption, handleSearch, resetSearch } = useSearchDropdown({
    optionsList,
    selectedOptions,
    onSelectOptions,
  });
  const showOptionsBar = showSelectedOptionsBar && Array.isArray(selectedOptions) && !!selectedOptions.length;

  return (
    <div className={rootClass}>
      <Combobox value={selectedOptions} onChange={handleSelectOption} multiple={multiple}>
        {({ open }) => {
          return (
            <div className="tw-relative tw-mt-1">
              <div
                className={cx(
                  'tw-relative tw-flex tw-flex-col tw-w-full tw-rounded-md tw-overflow-hidden tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-pr-8',
                  showSearchIcon ? 'tw-pl-12' : 'tw-pl-3'
                )}
              >
                {showSearchIcon && (
                  <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-text-neutral-grey-1 tw-bg-neutral-light tw-pl-3 tw-pr-1 tw-z-10">
                    <IoSearchOutline size={24} />
                  </div>
                )}

                <div className="tw-flex tw-w-full">
                  {/* Due to styles issues there no support if multiple */}
                  {showSelectedOptionsBadges && !multiple && (
                    <SelectedOptionBadges
                      selectedOptions={selectedOptions}
                      customSelectedOptionsBadgeLabel={customSelectedOptionsBadgeLabel}
                    />
                  )}

                  <Combobox.Input
                    className="tw-truncate tw-w-full tw-border-0 tw-py-2 tw-bg-transparent tw-shadow-none focus:tw-outline-none focus:tw-shadow-none tw-z-0"
                    displayValue={(option?: Option) => option?.label || ''}
                    onChange={handleSearch}
                    placeholder={placeholder}
                  />
                </div>

                <Combobox.Button className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-bg-neutral-light tw-pr-3 tw-pl-1 tw-z-10">
                  <BsChevronDown className={cx(open && 'tw-rotate-180 tw-transform')} />
                </Combobox.Button>
              </div>
              <Transition
                as={React.Fragment}
                enter="tw-transition tw-ease-in tw-duration-100"
                enterFrom="tw-opacity-0"
                enterTo="tw-opacity-100"
                leave="tw-transition tw-ease-in tw-duration-100"
                leaveFrom="tw-opacity-100"
                leaveTo="tw-opacity-0"
                afterLeave={resetSearch}
              >
                <Combobox.Options
                  as="ol"
                  className="tw-absolute tw-flex tw-flex-col tw-z-40 tw-max-h-80 tw-overflow-y-scroll tw-bg-neutral-light tw-rounded-md tw-w-full tw-border-2 tw-border-neutral-grey-3 focus:tw-outline-none"
                >
                  {isLoading && <li className="tw-relative tw-select-none tw-italic tw-px-3 tw-py-2">Loading...</li>}
                  {!isLoading && !filteredOptions.length && (
                    <li className="tw-relative tw-select-none tw-px-3 tw-py-2 ">Nothing found.</li>
                  )}
                  {filteredOptions.map((option) => {
                    const { label, value, isAlwaysSelected } = option;
                    const isSelected = selectedOptions?.some((option) => option.value === value);

                    const optionLabel = customOptionLabel?.(label) || label;

                    return (
                      <Combobox.Option
                        as="li"
                        title={label}
                        key={value}
                        className={cx(
                          isAlwaysSelected
                            ? 'tw-cursor-not-allowed tw-text-neutral-grey-2 tw-bg-neutral-grey-4.5'
                            : 'tw-cursor-pointer hover:tw-text-primary-dark-green hover:tw-bg-primary-light-green'
                        )}
                        value={option}
                        disabled={isAlwaysSelected}
                      >
                        <div
                          className={cx(
                            'tw-flex tw-flex-grow tw-px-3 tw-py-2 tw-truncate',
                            !isAlwaysSelected && isSelected && 'tw-text-primary-dark-green tw-bg-primary-light-green',
                            !isAlwaysSelected &&
                              isSelected &&
                              multiple &&
                              'hover:tw-text-semantic-error hover:tw-bg-semantic-error-background'
                          )}
                        >
                          {optionLabel}
                        </div>
                      </Combobox.Option>
                    );
                  })}
                </Combobox.Options>
              </Transition>
            </div>
          );
        }}
      </Combobox>

      {showOptionsBar && (
        <SelectedOptionsBar
          selectedOptions={selectedOptions}
          onRemoveOption={handleRemoveOption}
          customOptionBarItemLabel={customOptionBarItemLabel}
        />
      )}
    </div>
  );
};

export default memo(SearchDropdown);
