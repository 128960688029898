import { useState, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';

import useGetProductState from 'hooks/useGetProductState';
import { GET_CREDIT_CARDS_FOR_LIST } from 'graphql/cards';
import { GET_GLOBAL_CREDIT_LIMIT } from 'graphql/creditOffer';
import { AuthContext } from 'context/Auth';
import { User } from 'types/user';

type useFirstPurchaseModalProps = {
  show: boolean;
};

const useFirstPurchaseModal = ({ show }: useFirstPurchaseModalProps) => {
  const [showNextSection, setShowNextSection] = useState(false);
  const [creditCards, setCreditCards] = useState([]);
  const [globalCreditLimit, setGlobalCreditLimit] = useState(null);

  const [getCreditCards, { data: creditCardData, loading: creditCardLoading }] = useLazyQuery(
    GET_CREDIT_CARDS_FOR_LIST,
    {
      onCompleted: () => {
        const fetchedCreditCards = get(creditCardData, 'me.account.creditCards', []);
        setCreditCards(fetchedCreditCards);
      },
    }
  );
  const [getGlobalCreditLimit, { data: globalCreditLimitData, loading: globalCreditLimitLoading }] = useLazyQuery(
    GET_GLOBAL_CREDIT_LIMIT,
    {
      onCompleted: () => {
        const fetchedGlobalCreditLimit = get(globalCreditLimitData, 'me.account.globalCreditLimit', null);
        setGlobalCreditLimit(fetchedGlobalCreditLimit);
      },
    }
  );

  const { me, meLoading } = useContext(AuthContext) as unknown as {
    me: User;
    meLoading: boolean;
  };

  const hasCreditCard = !!me?.account?.creditCards?.length;
  const { isPreFunded, isCreditCard } = useGetProductState();
  const history = useHistory();

  const handleClick = () => {
    if (!hasCreditCard) {
      setShowNextSection(true);
      getCreditCards();
      getGlobalCreditLimit();
      return;
    }

    if (isPreFunded) history.push('/dashboard/payments/add-money');
    if (isCreditCard) history.push('/dashboard/cards/list');
  };

  const isLoadingCardsData = meLoading || creditCardLoading || globalCreditLimitLoading;

  useEffect(() => {
    if (!show) setShowNextSection(false);
  }, [show]);

  return { showNextSection, hasCreditCard, handleClick, isLoadingCardsData, creditCards, globalCreditLimit };
};

export default useFirstPurchaseModal;
