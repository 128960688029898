import React from 'react';
import { IoCopyOutline, IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';

const CopyableField = ({ onCopy, label, value, show, handleToggle, className = '' }) => (
  <div className={`tw-flex tw-items-center tw-justify-between ${className}`} data-private>
    <small className="tw-text-neutral-grey-2">{label}</small>
    <div className="tw-flex tw-items-center">
      <p>{value}</p>
      <IoCopyOutline
        className="tw-mx-2 tw-text-primary-dark-green tw-cursor-pointer"
        onClick={onCopy}
        data-tip="Copied!"
      />
      {!handleToggle && <div className="tw-w-5" />}
      {handleToggle &&
        (show ? (
          <IoEyeOutline className="tw-text-primary-dark-green tw-cursor-pointer" onClick={handleToggle} size={20} />
        ) : (
          <IoEyeOffOutline className=" tw-text-primary-dark-green tw-cursor-pointer" onClick={handleToggle} size={20} />
        ))}
    </div>
    <ReactTooltip
      event="mouseup"
      eventOff="mouseout"
      textColor="var(--colors-natural-light)"
      backgroundColor="var(--colors-natural-grey-1)"
      delayHide={500}
    />
  </div>
);

export default CopyableField;
