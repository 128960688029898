import { useState, useEffect } from 'react';

type useFirstDepositModalProps = {
  show: boolean;
};

const useFirstDepositModal = ({ show }: useFirstDepositModalProps) => {
  const [showNextSection, setShowNextSection] = useState(false);

  const handleClick = () => setShowNextSection(true);

  useEffect(() => {
    if (!show) {
      setShowNextSection(false);
    }
  }, [show]);

  return { showNextSection, handleClick };
};

export default useFirstDepositModal;
