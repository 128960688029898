import React from 'react';
import { useFormContext } from 'react-hook-form';

import AddressFields from 'components/onboarding/AddressFields';
import { TextField } from 'components/FormFields/v2';

const PrimaryOwnerContactFields = () => {
  const { register, watch } = useFormContext();

  return (
    <div className="tw-rounded-md tw-bg-neutral-grey-4 tw-p-4 tw-mb-8">
      <h3>Primary Owner's Details</h3>
      <div className="tw-flex tw-justify-between tw-w-full tw-mt-4">
        <TextField
          rootClass="tw-mb-8 tw-w-1/2 tw-mr-2"
          name="accountOwnerContact.firstName"
          label="Legal First Name"
          ref={register({ required: true })}
        />
        <TextField
          rootClass="tw-mb-8 tw-w-1/2"
          name="accountOwnerContact.lastName"
          label="Legal Last Name"
          ref={register({ required: true })}
        />
      </div>
      <TextField
        rootClass="tw-mb-8"
        name="accountOwnerContact.email"
        label="E-mail address"
        ref={register({ required: true })}
      />
      <AddressFields
        streetAddressLabel="Owner's Street Address"
        cityLabel="Owner's City"
        name="accountOwnerContact.address"
        selectedCountry={watch('address.country')}
      />
    </div>
  );
};

export default PrimaryOwnerContactFields;
