export const GUARANTOR_STEP_LABELS = {
  consentForm: { title: 'Consent Form', label: 'Consent Form' },
  idVerification: { title: 'Identity Verification', label: 'ID Verification' },
  complete: { title: 'Consent & ID Verification Complete', label: 'Complete' },
};

export const GUARANTOR_STEP_URLS = {
  idVerification: '/guarantee_consent/identity_verification',
  complete: '/guarantee_consent/complete',
};

export const GUARANTOR_STEPS: { [key: string]: [number] } = {
  'Consent Form': [0],
  'Identity Verification': [1],
  Complete: [2],
};

export const GUARANTOR_BUTTON_LABELS = {
  continue: 'Continue',
};
