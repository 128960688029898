export const getNavItems = () => {
  const items = [
    {
      route: '/payor/portal/settings/personal',
      label: 'Personal',
    },
  ];

  return items;
};
