import React from 'react';

import SettingsLayout from 'components/OnboardingDashboard/components/Settings/components/SettingsLayout/SettingsLayout';
import { PreviewScreen } from 'components/OnboardingDashboard/components';
import notificationSettingsScreenshotBlur from 'images/screenshot-settings-notification-blur.png';

const NotificationSettings = () => {
  return (
    <SettingsLayout>
      <div className="tw-my-10">
        <PreviewScreen bgImage={notificationSettingsScreenshotBlur} />
      </div>
    </SettingsLayout>
  );
};

export default NotificationSettings;
