import { onError } from 'apollo-link-error';
import { toast } from 'react-toastify';

import history from 'history.js';
import { RESPONSE_STATUS_CODES } from 'constants/api';

interface ServerError extends Error {
  statusCode?: number;
}

// Intercept graphQL and network errors
const errorMiddleware = onError(({ graphQLErrors, networkError, operation }) => {
  const { operationName } = operation || {};

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const errorMessage = `[GraphQL error]: Operation: ${operationName}, Message: ${message}, Location: ${locations}, Path: ${path}`;

      console.warn(errorMessage);
    });
  }

  if (!networkError) return;

  const errorMessage = `[${networkError.name}]: Operation: ${operationName}, Message: ${networkError.message}`;

  if (networkError.name === 'ServerError') {
    console.error(errorMessage);

    if ((networkError as ServerError)?.statusCode === RESPONSE_STATUS_CODES['401'].code) {
      history.push('/signout');
      toast.error('Your session has expired, please sign in again.');
    }
  } else {
    console.warn(errorMessage);
  }
});

export default errorMiddleware;
