import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { BsChevronLeft } from 'react-icons/bs';

import DashboardLayout from 'components/dashboard/DashboardLayout';
import { CardRepaymentContextProvider } from 'context/CardRepayment';
import { Content } from './components';
import { useCardRepayment } from './hooks';

const CardRepayment = () => {
  const { onNavigateToCards, onNavigateBack } = useCardRepayment();

  return (
    <DashboardLayout title="Pay Card Balance">
      <div className="tw-flex tw-mb-16 lg:tw-w-full tw-gap-8">
        <button
          className="tw-hidden lg:tw-flex tw-items-center tw-self-start tw-cursor-pointer tw-text-lg tw-font-semibold"
          onClick={onNavigateBack}
        >
          <BsChevronLeft size={14} className="tw-mr-2" />
          Back
        </button>
        <div className="tw-flex tw-flex-col tw-w-full tw-bg-neutral-light tw-rounded-md tw-py-8 lg:tw-py-0">
          <button className="tw-px-4 tw-mb-2 tw-cursor-pointer lg:tw-hidden">
            <BiArrowBack size={24} onClick={onNavigateBack} />
          </button>
          <h2 className="tw-text-lg tw-text-left tw-px-4 tw-mb-4 lg:tw-hidden">Pay Card Balance</h2>
          <CardRepaymentContextProvider>
            <Content onNavigateBack={onNavigateBack} onFinish={onNavigateToCards} />
          </CardRepaymentContextProvider>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CardRepayment;
