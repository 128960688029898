import React from 'react';

import OnboardingDashboardLayout from '../OnboardingDashboardLayout';
import PreviewScreen from '../PreviewScreen';
import accountsScreenshotBlur from 'images/screenshot-accounts-page-blurred.png';

const Accounts = () => {
  return (
    <OnboardingDashboardLayout>
      <PreviewScreen bgImage={accountsScreenshotBlur} />
    </OnboardingDashboardLayout>
  );
};

export default Accounts;
