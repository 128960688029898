import React from 'react';

type CardReceiveFundsProps = {
  width?: number;
  height?: number;
};

const CardReceiveFunds = ({ width = 25, height = 20 }: CardReceiveFundsProps) => (
  <svg width={width} height={height} viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.125 0.5H3.875C2.42525 0.5 1.25 1.67525 1.25 3.125V12.875C1.25 14.3247 2.42525 15.5 3.875 15.5H18.125C19.5747 15.5 20.75 14.3247 20.75 12.875V3.125C20.75 1.67525 19.5747 0.5 18.125 0.5Z"
      fill="white"
      stroke="#26262C"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1.25 5H20.75" stroke="#26262C" strokeLinejoin="round" />
    <path d="M7.25 10.0625H5V11H7.25V10.0625Z" stroke="#26262C" strokeLinejoin="round" />
    <rect x="13.75" y="8.75" width="10.5" height="10.5" rx="5.25" fill="#ECFDF3" />
    <path
      d="M18.9998 11.6667V16.3334M16.6665 14.0001H21.3332"
      stroke="#12B76A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="13.75" y="8.75" width="10.5" height="10.5" rx="5.25" stroke="black" strokeWidth="0.5" />
  </svg>
);

export default CardReceiveFunds;
