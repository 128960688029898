import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const Calendar = ({ width = 120, height = 120 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 59 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0554 2.20363C19.1264 1.99553 19.4323 2.03763 19.4444 2.25716L19.8037 8.77237C19.8081 8.85379 19.8616 8.92434 19.9388 8.95066L26.1148 11.0563C26.3229 11.1273 26.2808 11.4332 26.0613 11.4453L19.5461 11.8046C19.4647 11.8091 19.3941 11.8625 19.3678 11.9397L17.2621 18.1157C17.1912 18.3238 16.8852 18.2817 16.8731 18.0622L16.5139 11.547C16.5094 11.4656 16.4559 11.395 16.3787 11.3687L10.2027 9.26302C9.99463 9.19207 10.0367 8.88612 10.2563 8.87402L16.7715 8.51479C16.8529 8.5103 16.9234 8.45682 16.9498 8.37964L19.0554 2.20363Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M16.2418 42.324C16.4541 42.2667 16.5899 42.5441 16.4145 42.6766L12.098 45.9392C12.033 45.9883 12.0043 46.0721 12.0255 46.1508L13.4351 51.3747C13.4924 51.587 13.2151 51.7228 13.0825 51.5474L9.81995 47.231C9.77078 47.1659 9.68703 47.1372 9.6083 47.1585L4.3844 48.5681C4.17213 48.6253 4.03631 48.348 4.2117 48.2154L8.52817 44.9529C8.59323 44.9037 8.62191 44.8199 8.60067 44.7412L7.19107 39.5173C7.13379 39.305 7.41115 39.1692 7.54372 39.3446L10.8063 43.6611C10.8554 43.7261 10.9392 43.7548 11.0179 43.7336L16.2418 42.324Z"
        fill="#EEA530"
      />
      <path
        d="M45.0859 10.2197C45.241 10.0639 45.4974 10.236 45.4119 10.4386L43.8419 14.1575C43.8102 14.2326 43.8273 14.3195 43.8851 14.377L46.7459 17.2249C46.9017 17.38 46.7296 17.6364 46.527 17.5509L42.8081 15.9809C42.733 15.9492 42.6462 15.9663 42.5886 16.0241L39.7407 18.8849C39.5856 19.0408 39.3292 18.8686 39.4147 18.6661L40.9847 14.9472C41.0164 14.872 40.9993 14.7852 40.9415 14.7276L38.0807 11.8797C37.9249 11.7246 38.097 11.4682 38.2996 11.5537L42.0185 13.1237C42.0936 13.1554 42.1805 13.1384 42.238 13.0806L45.0859 10.2197Z"
        fill="#EEA530"
      />
      <g clipPath="url(#clip0_3339_97896)">
        <path
          d="M52.7046 25.0875L30.5362 20.7944C28.6997 20.4387 26.8964 21.7745 26.5084 23.7779L22.2934 45.5433C21.9054 47.5467 23.0796 49.4592 24.9161 49.8148L47.0845 54.1079C48.921 54.4636 50.7243 53.1278 51.1123 51.1243L55.3274 29.359C55.7153 27.3555 54.5411 25.4431 52.7046 25.0875Z"
          fill="#EED9F7"
        />
        <path
          d="M50.8589 26.4205L28.6905 22.1274C26.854 21.7717 25.0507 23.1075 24.6627 25.111L20.4477 46.8763C20.0597 48.8797 21.2339 50.7922 23.0704 51.1478L45.2388 55.4409C47.0753 55.7966 48.8786 54.4608 49.2666 52.4573L53.4817 30.692C53.8696 28.6886 52.6954 26.7761 50.8589 26.4205Z"
          stroke="#4F5154"
          strokeLinejoin="round"
        />
        <path
          d="M39.9699 38.1117C40.8881 38.2895 41.7898 37.6216 41.9838 36.6199C42.1778 35.6181 41.5906 34.6619 40.6724 34.4841C39.7541 34.3063 38.8525 34.9742 38.6585 35.9759C38.4645 36.9776 39.0516 37.9338 39.9699 38.1117Z"
          fill="#4F5154"
        />
        <path
          d="M45.5129 39.1849C46.4311 39.3627 47.3327 38.6948 47.5267 37.6931C47.7207 36.6914 47.1336 35.7352 46.2154 35.5573C45.2971 35.3795 44.3955 36.0474 44.2015 37.0491C44.0075 38.0509 44.5946 39.0071 45.5129 39.1849Z"
          fill="#4F5154"
        />
        <path
          d="M38.8 44.1576C39.7182 44.3354 40.6199 43.6675 40.8138 42.6658C41.0078 41.664 40.4207 40.7078 39.5025 40.53C38.5842 40.3522 37.6826 41.0201 37.4886 42.0218C37.2946 43.0235 37.8817 43.9797 38.8 44.1576Z"
          fill="#4F5154"
        />
        <path
          d="M44.341 45.2308C45.2592 45.4086 46.1609 44.7407 46.3549 43.739C46.5489 42.7373 45.9617 41.7811 45.0435 41.6032C44.1252 41.4254 43.2236 42.0933 43.0296 43.095C42.8356 44.0968 43.4227 45.053 44.341 45.2308Z"
          fill="#4F5154"
        />
        <path
          d="M27.716 42.0111C28.6342 42.1889 29.5359 41.521 29.7299 40.5193C29.9239 39.5175 29.3367 38.5613 28.4185 38.3835C27.5002 38.2057 26.5986 38.8736 26.4046 39.8753C26.2106 40.877 26.7977 41.8332 27.716 42.0111Z"
          fill="#4F5154"
        />
        <path
          d="M33.257 43.0843C34.1752 43.2621 35.0769 42.5942 35.2709 41.5925C35.4649 40.5908 34.8778 39.6346 33.9595 39.4567C33.0413 39.2789 32.1396 39.9468 31.9456 40.9485C31.7516 41.9503 32.3388 42.9065 33.257 43.0843Z"
          fill="#4F5154"
        />
        <path
          d="M26.5441 48.057C27.4624 48.2348 28.364 47.5669 28.558 46.5652C28.752 45.5634 28.1649 44.6072 27.2466 44.4294C26.3284 44.2516 25.4267 44.9195 25.2327 45.9212C25.0387 46.9229 25.6259 47.8791 26.5441 48.057Z"
          fill="#4F5154"
        />
        <path
          d="M32.0871 49.1302C33.0053 49.308 33.907 48.6401 34.101 47.6384C34.295 46.6367 33.7078 45.6805 32.7896 45.5026C31.8713 45.3248 30.9697 45.9927 30.7757 46.9944C30.5817 47.9962 31.1688 48.9524 32.0871 49.1302Z"
          fill="#4F5154"
        />
        <path
          d="M37.6281 50.2034C38.5463 50.3813 39.448 49.7134 39.642 48.7117C39.836 47.7099 39.2488 46.7537 38.3306 46.5759C37.4124 46.3981 36.5107 47.066 36.3167 48.0677C36.1227 49.0694 36.7098 50.0256 37.6281 50.2034Z"
          fill="#4F5154"
        />
        <path d="M31.375 20.1387L30.9067 22.557" stroke="#4F5154" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M49.1113 23.5732L48.643 25.9916" stroke="#4F5154" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M53.0142 33.1103L24.1953 27.5293" stroke="#4F5154" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3339_97896">
          <rect width="36.1284" height="39.4129" fill="white" transform="translate(23.2109 14.7939) rotate(10.9602)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Calendar;
