import React, { useState, createContext, useCallback } from 'react';
import { ApolloError } from '@apollo/client';

import {
  FundsContextType,
  FundsPayloadType,
  FundsTransactionType,
  ConvertFundsPayloadType,
} from 'components/Accounts/AccountsContent/components/AccountsModal/types/funds';

export const FundsContext = createContext<FundsContextType>({} as FundsContextType);

export const FundsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [addFundsInfo, setAddFundsInfo] = useState<FundsPayloadType | null>(null);
  const [addFundsTransaction, setAddFundsTransaction] = useState<FundsTransactionType | null>(null);
  const [withdrawFundsInfo, setWithdrawFundsInfo] = useState<FundsPayloadType | null>(null);
  const [withdrawFundsTransaction, setWithdrawFundsTransaction] = useState<FundsTransactionType | null>(null);
  const [convertFundsInfo, setConvertFundsInfo] = useState<ConvertFundsPayloadType | null>(null);
  const [convertFundsTransaction, setConvertFundsTransaction] = useState<FundsTransactionType | null>(null);
  const [error, setError] = useState<ApolloError | null>(null);

  const resetFundsContext = useCallback(() => {
    setAddFundsInfo(null);
    setAddFundsTransaction(null);
    setWithdrawFundsInfo(null);
    setWithdrawFundsTransaction(null);
    setConvertFundsInfo(null);
    setConvertFundsTransaction(null);
    setError(null);
  }, []);

  return (
    <FundsContext.Provider
      value={{
        addFundsInfo,
        setAddFundsInfo,
        addFundsTransaction,
        setAddFundsTransaction,
        withdrawFundsInfo,
        setWithdrawFundsInfo,
        withdrawFundsTransaction,
        setWithdrawFundsTransaction,
        convertFundsInfo,
        setConvertFundsInfo,
        convertFundsTransaction,
        setConvertFundsTransaction,
        error,
        setError,
        resetFundsContext,
      }}
    >
      {children}
    </FundsContext.Provider>
  );
};
