import React from 'react';
import cx from 'classnames';
import Button from 'components/Button';
import ArrowRight from 'components/svg/ArrowRight';

import styles from './OptionCard.module.scss';

const OptionCard = ({ text, imageSrc, imageAlt, classNames, actionText, handleAction }) => (
  <li className={cx(styles.card, classNames)}>
    <div className={styles.card__image}>
      <img src={imageSrc} alt={imageAlt} />
    </div>
    <p className={styles.card__text}>{text}</p>
    <Button className={styles.card__button} primary onClick={handleAction}>
      {actionText}
      <ArrowRight />
    </Button>
  </li>
);

export default OptionCard;
