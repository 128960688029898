import { Auth } from 'aws-amplify';

export const getToken = async () => {
  try {
    const session = await Auth.currentSession();

    return session.getAccessToken().getJwtToken();
  } catch (error) {
    console.warn('Failed to get user session token', error);
  }
};
