import React, { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getCountries, getCountrySubdivisions } from 'utility/countries';
import { TextField, Select } from 'components/FormFields/v2';
import FormFooter from 'components/payments/AddPayee/components/FormFooter/FormFooter';
import { AddPayeeContext } from 'components/payments/AddPayee/AddPayeeContext';

const SupplierAddress = ({ onFinish, onPreviousStep, loading, error }) => {
  const { supplierInfo, setSupplierInfo } = useContext(AddPayeeContext);
  const form = useForm({
    defaultValues: {
      ...supplierInfo,
      address: {
        ...supplierInfo.address,
        country: (supplierInfo.address && supplierInfo.address.country) || 'CA',
      },
    },
  });
  const { handleSubmit, register, watch } = form;
  const selectedCountry = watch('address.country');

  const countryOptions = getCountries();
  const countrySubdivisions = getCountrySubdivisions(selectedCountry);

  const onSubmit = (data) => {
    const updatedData = { ...supplierInfo, ...data };

    setSupplierInfo(updatedData);
    onFinish(updatedData);
  };

  return (
    <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-px-8 tw-py-8 tw-space-y-6">
          <strong>Please enter the payee’s company address</strong>
          <Select
            name="address.country"
            data-testid="address.country"
            label="Payee Country"
            placeholder="Select Option"
            ref={register({ required: true })}
            options={countryOptions}
            required
          />
          <TextField
            name="address.street"
            data-testid="address.street"
            label="Payee Street Address"
            placeholder="410 Adelaide St. W."
            ref={register({ required: true })}
            required
          />
          <Select
            name="address.countrySubdivision"
            data-testid="address.countrySubdivision"
            label="Payee State"
            placeholder="Select State"
            ref={register({ required: true })}
            options={countrySubdivisions}
            defaultValue={countrySubdivisions[0].value}
            required
          />
          <TextField
            name="address.city"
            data-testid="address.city"
            label="Payee City"
            placeholder="Toronto"
            ref={register({ required: true })}
            required
          />
          <TextField
            name="address.postalCode"
            data-testid="address.postalCode"
            label="Payee Postal Code"
            placeholder="M5V1S8"
            ref={register({ required: true })}
            required
          />
        </div>
        <FormFooter onPreviousStep={onPreviousStep} submitLabel="Submit" loading={loading} />
      </form>
    </FormProvider>
  );
};

export default SupplierAddress;
