import { Currencies } from 'constants/currencies';
import { INR_REASONS } from 'constants/index';

export const TRANSFER_METHOD_LABELS = {
  international: 'International Transfer',
  domestic: 'Domestic Transfer',
};

export const getTransferMethod = ({
  fromCurrency,
  toCurrency,
  isSwiftCode = false,
}: {
  fromCurrency?: Currencies;
  toCurrency?: Currencies;
  isSwiftCode?: boolean;
}) => {
  if (isSwiftCode) return TRANSFER_METHOD_LABELS.international;

  return fromCurrency && toCurrency && fromCurrency !== toCurrency
    ? TRANSFER_METHOD_LABELS.international
    : TRANSFER_METHOD_LABELS.domestic;
};

export const getInrReasonLabel = (reason: string) => {
  return INR_REASONS.find((r) => r?.value === reason)?.name;
};
