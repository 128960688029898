import React, { useContext } from 'react';

import {
  UpdateCardSettings,
  CardSetup,
  CardInformation,
  KeyTerms,
  CardDeliveryAddress,
  CardChargeFee,
  CardPin,
} from './components';
import { CreateCardContext } from 'components/creditCards/components/CreateCardModal/context/CreateCardContext';

const Steps = () => {
  const { stepTitle } = useContext(CreateCardContext);

  switch (stepTitle) {
    case 'Card Setup':
      return <CardSetup />;
    case 'Card Information':
      return <CardInformation />;
    case 'Card Settings':
      return <UpdateCardSettings />;
    case 'Key Terms':
      return <KeyTerms />;
    case 'Card Delivery Address':
      return <CardDeliveryAddress />;
    case 'Card Charge Fee':
      return <CardChargeFee />;
    case 'Set Pin':
      return <CardPin />;
    default:
      return null;
  }
};

export default Steps;
