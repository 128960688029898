import { useState, useMemo, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { industries } from 'constants/index';
import { GET_ACCOUNT_INDUSTRIES, GET_NAICS_INFO } from 'graphql/user';
import { USBankAccountVerificationContext } from 'components/Accounts/components/USBankAccountVerificationModal/contexts/USBankAccountVerificationContext';
import { IndustryCodeProps } from '../IndustryCode.types';

const useIndustryCode = ({ onNextStep }: IndustryCodeProps) => {
  const { industryData, setIndustryData, error, setError } = useContext(USBankAccountVerificationContext);
  const { mainIndustry, industry, naicsCode, naicsDescription } = industryData || {};
  const naicsCodeValue = naicsCode ? `${naicsCode}${naicsDescription ? ` - ${naicsDescription}` : ''}` : '';

  const [selectedMainIndustry, setSelectedMainIndustry] = useState(mainIndustry);
  const industryOptions = useMemo(
    () =>
      selectedMainIndustry ? industries.filter((industry) => industry.groupIds.includes(selectedMainIndustry)) : [],
    [selectedMainIndustry]
  );

  const [getAccountIndustries, { loading: accountIndustriesLoading }] = useLazyQuery(GET_ACCOUNT_INDUSTRIES, {
    onCompleted: (data) => {
      const { mainIndustry, industry } = get(data, 'me.account', {});
      if (mainIndustry && industry) {
        setIndustryData({ ...industryData, mainIndustry, industry });
      }
    },
    // onError: setError,
  });

  const [getNaicsInfo, { loading: naicsInfoLoading }] = useLazyQuery(GET_NAICS_INFO);

  const form = useForm({
    defaultValues: {
      mainIndustry,
      industry,
      naicsCodeValue,
    },
  });
  const { register, watch, handleSubmit, setValue } = form;
  const watchedNaicsCodeValue = watch('naicsCodeValue');
  const watchedMainIndustry = watch('mainIndustry');
  const watchedIndustry = watch('industry');

  const handleOnNextStep = () => {
    onNextStep();
  };

  useEffect(() => {
    getAccountIndustries();
  }, []);

  useEffect(() => {
    setSelectedMainIndustry(mainIndustry);
    setValue('industry', industry);
    setValue('mainIndustry', mainIndustry);
    setValue('naicsCodeValue', naicsCodeValue);
  }, [mainIndustry, industry, naicsCodeValue]);

  useEffect(() => {
    if (watchedMainIndustry && watchedIndustry) {
      setError(undefined);

      getNaicsInfo({
        variables: {
          mainIndustry: watchedMainIndustry,
          industry: watchedIndustry,
        },
        onCompleted: (data) => {
          const { code, description } = get(data, 'naicsInfo', {});

          if (!code) {
            setError(new Error('No NAICS code found for the selected industry') as unknown as ApolloError);
            return;
          }

          setIndustryData({
            mainIndustry: watchedMainIndustry,
            industry: watchedIndustry,
            naicsCode: code,
            naicsDescription: description,
          });
        },
      });
    }
  }, [watchedMainIndustry, watchedIndustry]);

  return {
    form,
    register,
    handleSubmit,
    handleOnNextStep,
    industryOptions,
    setSelectedMainIndustry,
    isDisabled: !watchedNaicsCodeValue,
    isLoading: accountIndustriesLoading || naicsInfoLoading,
    error,
  };
};

export default useIndustryCode;
