import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_CURRENCY_CLOUD_EXTERNAL_ACCOUNTS } from 'graphql/wallets';
import CCBankAccountDetails from 'components/wallets/CCBankAccountDetails';
import CADBankAccountDetails from 'components/wallets/CADBankAccountDetails';
import { Currencies } from 'constants/currencies';

// Refactor: https://getloop.atlassian.net/browse/LBP-4831
const CCBankAccountDetailsContainer = ({ currency }) => {
  const { loading, data } = useQuery(GET_CURRENCY_CLOUD_EXTERNAL_ACCOUNTS, { variables: { currency } });

  const walletExternalAccount = data?.currencyCloudExternalAccount;

  if (currency === Currencies.CAD) {
    return (
      <CADBankAccountDetails loading={loading} walletExternalAccount={walletExternalAccount} currency={currency} />
    );
  }

  return <CCBankAccountDetails loading={loading} walletExternalAccount={walletExternalAccount} currency={currency} />;
};

export default CCBankAccountDetailsContainer;
