import React from 'react';

const CreditReportConsent = ({ companyName, granteeName }) => {
  return (
    <>
      <p>
        I, <b>{granteeName}</b>, on my behalf and on behalf of <b>{companyName} (the "Business")</b> consent to Loop
        Financial Inc. ("Loop") and/or its third-party agents, business partners or service providers ("Third Parties")
        obtaining a credit report from a credit or consumer reporting agency (a "Credit Agency") about me or the
        Business, for the purpose of determining my and the Business' eligibility to open an Account, maintain an
        Account or use the Services in accordance with Loop's Platform Agreeement.
      </p>
      <br />
      <p>
        I acknowledge that this consent is effective immediately, and I understand that personal, financial or other
        information relating to me or the Business (the "Information") will be disclosed to Loop and/or its Third
        Parties for the purposes of a) determining the eligibility of <b>{companyName}</b> for financial products and if
        approved, to provide and administer such financial products; b) reviewing and determining ongoing
        creditworthiness in light of the Business' obligations;, c) Loop's general risk management and assessment
        processes, and d) Loop's compliance with laws.
      </p>
      <br />
      <p>I authorize any Credit Agency to provide the Information to Loop and its Third Parties.</p>
    </>
  );
};

export default CreditReportConsent;
