import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import Warning from 'components/svg/Warning';
import { BsFillExclamationCircleFill } from 'react-icons/bs';

export const BANNER_TYPES = {
  success: 'success',
  danger: 'danger',
  info: 'info',
  error: 'error',
};

export const BANNER_ICON_MAP = {
  [BANNER_TYPES.success]: <FaCheckCircle />,
  [BANNER_TYPES.danger]: <Warning />,
  [BANNER_TYPES.error]: <BsFillExclamationCircleFill />,
};
