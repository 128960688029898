import React from 'react';
import cx from 'classnames';

import { ChartTypeSwitcherProps } from './ChartTypeSwitcher.types';
import { CurrencyOption } from './components';
import { chartOptions } from './constants';

const ChartTypeSwitcher = ({ type, onChange }: ChartTypeSwitcherProps) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-center md:tw-justify-start tw-gap-4">
      <div className="tw-font-bold">Display Limit In</div>
      <ul className="tw-flex">
        {chartOptions.map((option, index) => {
          const isSelected = option === type;
          const isFirstItem = index === 0;
          const isLastItem = index === chartOptions.length - 1;

          const className = cx(
            isSelected ? 'tw-bg-secondary-pastel-green-100' : 'hover:tw-bg-neutral-grey-5',
            isFirstItem && 'tw-rounded-l',
            isLastItem && 'tw-rounded-r',
            isFirstItem && !isLastItem && 'tw-border-r-0'
          );

          return <CurrencyOption key={option} value={option} onSelect={onChange} className={className} />;
        })}
      </ul>
    </div>
  );
};

export default ChartTypeSwitcher;
