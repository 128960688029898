import React from 'react';

import { useFundsFlow } from '../../hooks';
import Steps from './components/Steps';

const ConvertFunds = ({ onFinish }: { onFinish: () => void }) => {
  const { currentStep, onPrevStep, onNextStep, onResetSteps } = useFundsFlow();

  return (
    <Steps
      currentStep={currentStep}
      onPrevStep={onPrevStep}
      onNextStep={onNextStep}
      onFinish={onFinish}
      onResetSteps={onResetSteps}
    />
  );
};

export default ConvertFunds;
