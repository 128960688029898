import React from 'react';

import { formatDateTime } from 'utility/date';
import { PayeePaymentApprovalsProps } from './PayeePaymentApprovals.types';

const PayeePaymentApprovals = ({ payeePaymentApprovals }: PayeePaymentApprovalsProps) => {
  if (!payeePaymentApprovals?.length) return null;

  return (
    <div className="tw-text-sm">
      <div className="tw-font-semibold tw-mb-2">Approved by:</div>
      <ol className="tw-list-disc tw-list-inside">
        {payeePaymentApprovals.map(({ id, name, approvedAt }) => {
          const formatApprovedDate = formatDateTime(approvedAt, 'MMMM d, yyyy, h:mm b');
          return (
            <li key={id}>
              {name} at {formatApprovedDate}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default PayeePaymentApprovals;
