import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { GET_PAYEES, GET_PAYEE_PAYMENTS } from 'graphql/payees';
import { GET_ACCOUNTS_FOR_PAYEE_PAYMENTS } from 'graphql/cards';
import { GET_LINE_OF_CREDIT_INFO_FOR_PAYEE_PAYMENT } from 'graphql/lineOfCredit';
import LineOfCredit from 'domain/lineOfCredit';
import Payees from 'components/payments/Payees';

const PayeesContainer = ({ isOnboardingPreview = false }) => {
  const { loading: loadingPayees, data: payeesData } = useQuery(GET_PAYEES, { fetchPolicy: 'network-only' });
  const {
    loading: loadingPayeePayments,
    data: payeePaymentsData,
    refetch: refetchPayeePayments,
  } = useQuery(GET_PAYEE_PAYMENTS);
  const { loading: loadingProducts, data: productsData } = useQuery(GET_ACCOUNTS_FOR_PAYEE_PAYMENTS);
  const { loading: loadingLOC, data: LOCData } = useQuery(GET_LINE_OF_CREDIT_INFO_FOR_PAYEE_PAYMENT);

  const payees = _.get(payeesData, 'payees') || [];
  const payeePayments = _.get(payeePaymentsData, 'payeePayments') || [];

  const { me } = productsData || {};
  const lineOfCredit = _.get(LOCData, 'me.account.lineOfCredit');
  const wallets = _.get(me, 'account.wallets') || [];
  const bankAccounts = _.get(me, 'account.bankAccounts') || [];

  return (
    <Payees
      loading={loadingPayees || loadingProducts || loadingLOC || loadingPayeePayments}
      wallets={wallets}
      bankAccounts={bankAccounts}
      lineOfCredit={lineOfCredit && new LineOfCredit(lineOfCredit)}
      payees={payees}
      payeePayments={payeePayments}
      refetchPayeePayments={refetchPayeePayments}
      isOnboardingPreview={isOnboardingPreview}
    />
  );
};

export default PayeesContainer;
