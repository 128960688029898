import React from 'react';

import RocketWithSparkles from 'components/svg/RocketWithSparkles';

export type InfoBlockProps = {
  icon?: JSX.Element;
  content: string;
};

const InfoBlock = ({ content, icon = <RocketWithSparkles /> }: InfoBlockProps) => {
  return (
    <div className="tw-px-9 tw-py-6 tw-mb-4 tw-flex tw-flex-row tw-items-center tw-rounded-md tw-bg-neutral-grey-5 w-full">
      {icon && <div className="tw-mr-9 tw-h-14 tw-flex-none">{icon}</div>}
      <span className="tw-text-neutral-grey-1">{content}</span>
    </div>
  );
};

export default InfoBlock;
