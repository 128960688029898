import { useState, useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';

import { useToggle } from 'hooks';
import { CADBankAccountOnboardingContext } from 'components/wallets/OpenCADAccountModal/contexts/CADBankAccountOnboardingContext';
import { AgreementsProps } from '../Agreements.types';

const useAgreements = ({ onNextStep }: Pick<AgreementsProps, 'onNextStep'>) => {
  const { onActivateAccount, setError, externalAccount, setExternalAccount } = useContext(
    CADBankAccountOnboardingContext
  );

  const { isOpen: isLoading, open: showLoading, close: hideLoading } = useToggle();

  const [isAcceptedCADAgreement, setIsAcceptedCADAgreement] = useState(false);
  const [isAcceptedPrivacyPolicy, setIsAcceptedPrivacyPolicy] = useState(false);

  const handleAcceptedCADAgreement = (e: React.ChangeEvent<HTMLInputElement>) =>
    setIsAcceptedCADAgreement(e.target.checked);

  const handleAcceptedPrivacyPolicy = (e: React.ChangeEvent<HTMLInputElement>) =>
    setIsAcceptedPrivacyPolicy(e.target.checked);

  const handleSubmit = useCallback(async () => {
    if (!isAcceptedCADAgreement || !isAcceptedPrivacyPolicy) return;

    const processingToast = toast.loading('Activating CAD bank account...');

    try {
      showLoading();

      const data = await onActivateAccount();

      if (!data) throw new Error('Could not activate CAD bank account.');

      const { accountNumber, institutionNumber, transitNumber } = data;

      setExternalAccount((prevState) => ({
        ...prevState,
        accountNumber,
        institutionNumber,
        transitNumber,
      }));

      toast.update(processingToast, {
        render: 'CAD bank account activated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      onNextStep();
    } catch (error) {
      setError(error as ApolloError);
      toast.update(processingToast, {
        render: `Something went wrong. ${(error as ApolloError)?.message || error}`,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      hideLoading();
    }
  }, [isAcceptedCADAgreement, isAcceptedPrivacyPolicy, onActivateAccount, setError, onNextStep]);

  return {
    isAcceptedCADAgreement,
    isAcceptedPrivacyPolicy,
    handleAcceptedCADAgreement,
    handleAcceptedPrivacyPolicy,
    handleSubmit,
    isLoading,
    externalAccount,
  };
};

export default useAgreements;
