import React from 'react';

import { TableProps } from './Table.types';
import { useTable } from './hooks';
import { TableRow, RequestInfoModal, DeleteRequestModal } from './components';
import { tableHeaders } from './constants';

const Table = ({ paymentRequests }: TableProps) => {
  const {
    selectedPaymentRequest,
    isOpenInfoModal,
    handleOpenInfoModal,
    handleCloseInfoModal,
    isOpenDeleteModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
  } = useTable({
    paymentRequests,
  });

  return (
    <>
      <table className="tw-table tw-table-auto tw-w-full">
        <thead>
          <tr>
            {tableHeaders.map((tableHeader) => (
              <th key={tableHeader} className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">
                {tableHeader}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paymentRequests.map((paymentRequest) => (
            <TableRow
              key={paymentRequest.id}
              paymentRequest={paymentRequest}
              openInfoModal={() => handleOpenInfoModal(paymentRequest.id)}
              openDeleteModal={() => handleOpenDeleteModal(paymentRequest.id)}
            />
          ))}
        </tbody>
      </table>

      {selectedPaymentRequest && (
        <RequestInfoModal
          show={isOpenInfoModal}
          onClose={handleCloseInfoModal}
          paymentRequest={selectedPaymentRequest}
        />
      )}

      <DeleteRequestModal
        show={isOpenDeleteModal}
        onClose={handleCloseDeleteModal}
        paymentRequest={selectedPaymentRequest}
      />
    </>
  );
};

export default Table;
