import React from 'react';
import { useHistory } from 'react-router-dom';

import CardAndCoins from 'components/svg/CardAndCoins';
import Button from 'components/Button';
import config from 'config';
import { SCOPE } from 'constants/index';
import PermissionChecker from 'components/PermissionChecker';
import { ConnectBankOrContactLoopBannerProps } from './ConnectBankOrContactLoopBanner.types';
import useGetProductState from 'hooks/useGetProductState';
import { useIsMobile } from 'hooks';

const ConnectBankOrContactLoopBanner = ({ title, message }: ConnectBankOrContactLoopBannerProps) => {
  const history = useHistory();
  const isMobile = useIsMobile();

  const navigateToConnectBankAccount = () => history.push('/dashboard/settings/bank-accounts-settings');

  const { isCapitalProducts } = useGetProductState();

  return (
    <div className="tw-bg-neutral-light tw-m-auto tw-py-5 tw-px-6 tw-flex tw-flex-col tw-items-center tw-rounded-md">
      <div>
        <CardAndCoins height="300" width="100%" />
      </div>
      <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-my-5 tw-w-4/5 lg:tw-w-96">
        <span className="tw-text-2xl tw-mt-4">{title}</span>
        <p className="tw-mt-8" data-testid="connect-bank-or-contact-banner-description">
          {isCapitalProducts ? (
            <>
              Interested in Loop Card? Email{' '}
              <a
                className="tw-font-semibold tw-text-primary-dark-green"
                target="_blank"
                href={`mailto:${config.contactEmail}`}
                rel="noreferrer"
              >
                {config.contactEmail}
              </a>{' '}
              and allocate some of your credit limit spend on cards.
            </>
          ) : (
            <p>{message}</p>
          )}
        </p>
        {!isCapitalProducts && !isMobile && (
          <PermissionChecker scope={SCOPE.manageBankAccounts}>
            <Button primary className="tw-mt-10 tw-py-3 tw-font-semibold" onClick={navigateToConnectBankAccount}>
              Connect Bank Account
            </Button>
          </PermissionChecker>
        )}
      </div>
    </div>
  );
};

export default ConnectBankOrContactLoopBanner;
