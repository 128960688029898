import { useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { CardMigrationContext } from 'components/CardMigrationV2/context/CardMigrationContext';
import { mapCardMigrationOptions } from '../CardMigrationPreferences.utils';
import { CardMigrationOption } from 'components/CardMigrationV2/constants';

const useCardMigrationPreferences = () => {
  const {
    onPrevStep,
    onNextStep,
    cardMigrationFormDataRef,
    virtualCards,
    activeCards,
    handleCardMigrationPreferenceChange,
  } = useContext(CardMigrationContext);

  const form = useForm({
    defaultValues: {
      cardMigrationType: cardMigrationFormDataRef.current?.selectedMigration || null,
    },
  });

  const { register, watch, handleSubmit, setValue } = form;

  const selectedCardMigration = watch('cardMigrationType');

  const numVirtual = virtualCards.length;
  const numActive = activeCards.length;

  const cardMigrationTypeOptions = useMemo(() => mapCardMigrationOptions({ numVirtual, numActive }), []);

  const handleCardMigrationOptionChange = (value: CardMigrationOption) => {
    setValue('cardMigrationType', value);
  };

  const isNextButtonDisabled = !selectedCardMigration;
  const handleOnNextStep = () => {
    handleCardMigrationPreferenceChange(selectedCardMigration ?? CardMigrationOption.none);
    onNextStep();
  };

  return {
    form,
    register,
    watch,
    handleSubmit,
    setValue,
    handleCardMigrationOptionChange,
    onPrevStep,
    onNextStep: handleOnNextStep,
    cardMigrationTypeOptions,
    isNextButtonDisabled,
    selectedCardMigration,
  };
};

export default useCardMigrationPreferences;
