import React from 'react';
import { Link } from 'react-router-dom';
import { IoCopyOutline, IoWarningOutline } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';

import { useReferAndEarn } from './hooks';
import { Loaders } from 'components/cards/Loader';
import Button from 'components/Button';
import { SocialShareButton } from 'components/UI';
import { SHARE_MESSAGE, SHARE_TITLE } from './constants';

const ReferAndEarn = () => {
  const {
    loading,
    referralCode,
    onCopyReferralCode,
    formattedNumberOfPoints,
    useRebuildTooltipForModal,
    inviteLink,
    rootUrl,
    inAppReferralUrl,
    termsAndConditionsUrl,
  } = useReferAndEarn();

  useRebuildTooltipForModal();

  if (loading) return <Loaders.Light />;

  return (
    <>
      <div className="tw-my-8 tw-flex tw-flex-col tw-gap-y-5 tw-items-center lg:tw-flex-row lg:tw-gap-x-4 tw-flex-grow-0">
        {referralCode ? (
          <>
            <div className="tw-border tw-border-neutral-grey-4 tw-bg-neutral-grey-5 tw-flex tw-justify-between tw-items-center tw-w-80 tw-h-14 tw-min-w-max tw-px-4 tw-py-3">
              <span>Your Referral Code</span>
              <span className="tw-font-bold">{referralCode}</span>
            </div>
            <div className="tw-flex tw-items-center tw-justify-center tw-gap-x-4 lg:tw-justify-start">
              <Button
                primary
                onClick={onCopyReferralCode}
                className="tw-py-2 tw-px-6 tw-w-max tw-self-center"
                data-tip="Copied!"
              >
                <IoCopyOutline className="tw-mr-2" /> Copy Invite Link
              </Button>
              <div className="tw-flex tw-flex-col tw--mt-4 lg:tw--mt-6">
                <div className="tw-text-neutral-grey-2">Share</div>
                <div className="tw-flex tw-gap-2">
                  <SocialShareButton.Linkedin url={inviteLink} text={SHARE_MESSAGE} title={SHARE_TITLE} />
                  <SocialShareButton.Twitter url={inviteLink} text={SHARE_MESSAGE} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="tw-flex tw-items-center tw-text-semantic-warning">
            <IoWarningOutline className="tw-mr-2" /> Unable to load Referral Code. Please try refreshing the page.
          </div>
        )}
      </div>
      <ReactTooltip
        event="mouseup"
        eventOff="mouseout"
        textColor="var(--colors-natural-light)"
        backgroundColor="var(--colors-natural-grey-1)"
        delayHide={500}
      />
      <div className="tw-w-full tw-flex tw-flex-col tw-gap-y-10 tw-bg-neutral-light tw-rounded-md tw-p-4 lg:tw-p-0">
        <div>
          <div className="tw-flex tw-flex-col lg:tw-flex-row">
            <div className="tw-font-bold tw-w-24">You Get</div>
            <div className="lg:tw-w-2/3">
              <strong>{formattedNumberOfPoints} points</strong> when they sign up using your referral code at{' '}
              <Link to={{ pathname: inviteLink }} target="_blank" rel="noreferrer">
                <span className="tw-font-semibold tw-text-primary-dark-green">{rootUrl}/apply</span>
              </Link>{' '}
              and complete their first qualifying transaction.
            </div>
          </div>
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-mt-4">
            <div className="tw-font-bold tw-w-24">They Get</div>
            <div className="lg:tw-w-2/3">
              <strong>{formattedNumberOfPoints} points</strong> for completing their first qualifying transaction after
              signing up with your referral code.
            </div>
          </div>
        </div>

        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-x-10">
          <div>
            <Link to={{ pathname: inAppReferralUrl }} target="_blank" rel="noreferrer">
              <span className="tw-font-semibold tw-text-primary-dark-green tw-underline">
                Frequently Asked Questions
              </span>
            </Link>
          </div>
          <div>
            <Link to={{ pathname: termsAndConditionsUrl }} target="_blank" rel="noreferrer">
              <span className="tw-font-semibold tw-text-primary-dark-green tw-underline">Terms and Conditions</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferAndEarn;
