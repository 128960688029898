import React from 'react';

import { PortalLayout } from '../components';
import { PaymentRequestsContent } from './components';
import { PaymentRequestsContextProvider } from './contexts/PaymentRequestsContext';

const PaymentRequests = () => (
  <PortalLayout title="Payments">
    <div className="tw-mb-8 tw-flex tw-flex-col">
      <h2 className="tw-text-2xl tw-mb-4">Payments</h2>
      <PaymentRequestsContextProvider>
        <PaymentRequestsContent />
      </PaymentRequestsContextProvider>
    </div>
  </PortalLayout>
);

export default PaymentRequests;
