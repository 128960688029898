import Calculator from 'components/svg/Calculator';
import LightingOutline from 'components/svg/LightningOutline';
import TeamIcon from 'components/svg/TeamIcon';

export const CURRENT_PLANS = {
  free: 'Free Plan',
  plus: 'Loop Plus Plan',
  power: 'Loop Power Plan',
};

export const ACTIONS = {
  create: 'create',
  cancel: 'cancel',
};

export const PRICING_PLANS = [
  {
    name: CURRENT_PLANS.free,
    description: 'Basic features to get started',
    price: 0,
  },
  {
    name: CURRENT_PLANS.plus,
    description: 'Lower fees and greater rewards',
    price: 49,
  },
  {
    name: CURRENT_PLANS.power,
    description: 'For Power Users',
    price: 199,
  },
];

export const INFO_CARDS = [
  {
    title: 'Reduce FX Fees',
    description:
      'Want even better than market leading FX fees? Upgrade to Loop’s Plus or Power plans and get access to fees previously reserved for only Fortune 500 companies.',
    icon: Calculator,
  },
  {
    title: 'Earn Points Faster',
    description:
      'For everything you purchase using Loop Card, paid users will earn two reward points multiplied by the amount of spend.',
    icon: LightingOutline,
  },
  {
    title: 'Features for Bigger Teams',
    description:
      'Have more than just a couple of team members to manage? Loop’s Plus and Power plans include unlimited virtual cards and 10 free physical cards to help manage spend',
    icon: TeamIcon,
  },
];
