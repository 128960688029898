import { InternalContactRole } from 'types/user';

export const getContent = (option: InternalContactRole): string => {
  switch (option) {
    case InternalContactRole.read_only:
      return 'Read-only users will be able to view all the information in your account, but will not be able to initiate any payments or transfers. This role is perfect for admin staff.';
    case InternalContactRole.member:
      return 'Members will only be able to view products and transactions for the products which are assigned to them. The member role can be used for team members that you want to only be able to see specific card details.';
    case InternalContactRole.admin:
      return 'Users that are granted Admin status will have full access to all Loop products and features without limits. This \
      role should only be given to team members who need to manage all accounts, cards and payments for your business.';
    case InternalContactRole.bookkeeper:
      return 'Bookkeepers can view all the information in your account, manage accounting integrations and create payee payments. Payments created by a bookkeeper may require administrative approval before they are sent if the bookkeeper does not have the appropriate approval limits.';
    default:
      return '';
  }
};
