import { themeConfig } from 'styles/tailwind/config';
import { GlobalLimitInfo, GlobalLimitInfoQueryResponse } from 'types/creditCard';
import { Money, PayBalanceCurrency } from 'types/payments';
import { FXRate } from 'types/fxRates';
import { ChartDataset } from './components/GlobalLimitChart/GlobalLimitChart.types';
import { FxCurrencies } from './GlobalLimit.types';
import { DEFAULT_GLOBAL_LIMIT_INFO } from './constants';

export const transformCardsGlobalLimitInfo = (globalLimitInfo?: GlobalLimitInfoQueryResponse): GlobalLimitInfo => {
  const result = Object.entries(globalLimitInfo || {}).reduce(
    (acc, [key, value]) => {
      if (value) {
        acc[key as keyof GlobalLimitInfo] = value;
      }
      return acc;
    },
    { ...DEFAULT_GLOBAL_LIMIT_INFO }
  );

  return result;
};

export const getConvertedBalance = ({ amount, fxRate }: { amount: Money<PayBalanceCurrency>; fxRate: FXRate }) => {
  return {
    amount: Math.round(amount.amount * fxRate.rate),
    currency: fxRate.buyCurrency as unknown as FxCurrencies,
  };
};

export const buildDataset = ({
  isOverlimit,
  availableOverlimit,
  availableBalance,
  amountSpent,
  amountDue,
  currency,
}: {
  isOverlimit: boolean;
  availableOverlimit: { value: number };
  availableBalance: { label: string; value: number };
  amountSpent: { value: number };
  amountDue: { value: number };
  currency: PayBalanceCurrency;
}) => {
  const dataset = [
    isOverlimit && {
      id: 'availableOverlimit',
      label: 'Available from Instant Deposit',
      value: 0,
      additionalValue: availableOverlimit.value,
      currency,
      bgColor: themeConfig.colors.primary['light-blue'],
    },
    {
      id: 'availableBalance',
      label: availableBalance.label,
      value: availableBalance.value,
      currency,
      labelColor: themeConfig.colors.neutral.light,
      bgColor: themeConfig.colors.primary['dark-green'],
    },
    {
      id: 'amountSpent',
      label: 'Spent',
      value: amountSpent.value,
      currency,
      bgColor: themeConfig.colors.secondary['pastel-green'][100],
    },
    {
      id: 'amountDue',
      label: 'Due',
      value: amountDue.value,
      currency,
      bgColor: themeConfig.colors.secondary['pastel-yellow'][50],
    },
  ].filter(Boolean) as ChartDataset;

  return dataset;
};
