import React from 'react';

import Layout from 'components/onboarding/Layout';
import { Progress } from 'components/UI';
import { GUARANTOR_STEPS, GUARANTOR_STEP_LABELS } from '../constants';
import CheckmarkAndPerson3 from 'components/svg/CheckmarkAndPerson3';

import styles from './GuarantorComplete.module.scss';

const GuarantorComplete = () => {
  return (
    <Layout>
      <div className={styles.outerContainer}>
        <Progress currentStepIndex={3} stepLabelsAndIndexes={GUARANTOR_STEPS} />
        <div className={styles.innerContainer}>
          <h1>{GUARANTOR_STEP_LABELS.complete.title}</h1>
          <CheckmarkAndPerson3 />
          <p>Thank you for completing the consent form and identity verification.</p>
        </div>
      </div>
    </Layout>
  );
};

export default GuarantorComplete;
