import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Modal, FormFooter } from 'components/UI';
import FileUpload from 'components/uploader/FileUpload';
import { TextField, MaskedTextField } from 'components/FormFields/v2';
import { Loaders } from 'components/cards/Loader';
import { validateInteger } from 'utility/validators';
import { AddCorporateOwnershipModalProps } from './AddCorporateOwnershipModal.types';
import useAddCorporateOwnershipModal from './hooks/useAddCorporateOwnershipModal';

const AddCorporateOwnershipModal = ({
  show,
  onClose,
  hasCorporateOwnershipDocument,
  corporateOwnership,
}: AddCorporateOwnershipModalProps) => {
  const {
    form,
    register,
    handleSubmit,
    handleOnFileUpload,
    isDownloading,
    handleFileDownload,
    isSubmitting,
    onSubmit,
    formattedSubmitButtonLabel,
    error,
  } = useAddCorporateOwnershipModal({
    onClose,
    hasCorporateOwnershipDocument,
    corporateOwnership,
  });

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={`${hasCorporateOwnershipDocument ? 'Edit' : 'Add'} Corporate Ownership`}
    >
      <FormProvider {...form} {...{ graphQLErrors: error?.graphQLErrors }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-px-8 tw-pt-8">
            <strong>Corporate Ownership</strong>
            <div className="tw-text-sm tw-my-4">
              If your business is partially or wholly owned by a corporation, please download the form below and
              re-upload the completed form.
            </div>
            <button type="button" onClick={handleFileDownload} className="tw-underline">
              {isDownloading ? <Loaders.Spinner /> : 'Download Corporate Ownership Form'}
            </button>
            <div className="tw-my-8">
              <label htmlFor="blobSignedId" className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
                Upload Completed Ownership Form
                <span className="tw-text-semantic-error">&nbsp;*</span>
              </label>
              <FileUpload onUpload={handleOnFileUpload} />
              <TextField
                type="text"
                name="blobSignedId"
                hidden={true}
                ref={register({ required: hasCorporateOwnershipDocument ? false : true })}
              />
            </div>
            <MaskedTextField
              required
              name="corporateOwnership"
              label="Total Percent Corporate Ownership"
              placeholder="%"
              suffix="%"
              allowNegative={false}
              isNumericString={true}
              isAllowed={validateInteger}
              rules={{ required: 'This field is required' }}
            />
          </div>
          <FormFooter submitButtonLabel={formattedSubmitButtonLabel} isDisabled={isSubmitting} rootClass="tw-p-8" />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddCorporateOwnershipModal;
