import React from 'react';

import config from 'config';
import Button from 'components/Button';
import { Modal } from 'components/UI';
import { Loaders } from 'components/cards/Loader';
import { DeletePayorModalProps } from './DeletePayorModal.types';
import { useDeletePayorModal } from './hooks';

const DeletePayorModal = ({ show, onClose }: DeletePayorModalProps) => {
  const { payorName, handleDeletePayor, loading } = useDeletePayorModal({
    onClose,
  });

  return (
    <Modal show={show} onClose={onClose} title="Delete Payor">
      {loading ? (
        <Loaders.Light />
      ) : (
        <div className="tw-px-12 tw-py-8 tw-border-t-2 tw-border-neutral-grey-4">
          <div className="tw-text-lg tw-mb-4">
            Are you sure you want to delete <b>{payorName}</b>?
          </div>
          <div className="tw-text-sm tw-text-neutral-grey-1">
            Scheduled payments from this payor will continue to be processed. Contact us at{' '}
            <a
              className="tw-font-semibold tw-text-primary-dark-green"
              target="_blank"
              href={`mailto:${config.supportEmail}`}
              rel="noreferrer"
            >
              {config.supportEmail}
            </a>{' '}
            to cancel scheduled payments.
          </div>
        </div>
      )}

      <div className="tw-py-4 tw-px-8 tw-flex tw-justify-between">
        <Button
          className="tw-bg-neutral-grey-5 tw-border-neutral-grey-4 tw-border tw-rounded-md tw-px-6 tw-py-2"
          onClick={onClose}
          isDisabled={loading}
          secondary
        >
          Cancel
        </Button>
        <Button
          className="tw-rounded-md tw-px-6 tw-py-2"
          onClick={handleDeletePayor}
          isDisabled={loading}
          warning={true}
        >
          {loading ? 'Deleting...' : 'Delete'}
        </Button>
      </div>
    </Modal>
  );
};

export default DeletePayorModal;
