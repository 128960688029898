import React from 'react';

import { Header } from './components';

export type SummaryProps = {
  title: string;
  icon: JSX.Element;
  iconClassName?: string;
  children: React.ReactNode;
};

const Summary = ({ title, icon, iconClassName, children }: SummaryProps) => {
  return (
    <div className="tw-bg-neutral-light tw-border-neutral-grey-4 tw-shadow-md tw-border tw-rounded-md tw-my-10 tw-max-w-screen-lg">
      <Header title={title} icon={icon} iconClassName={iconClassName} />
      <div className="tw-p-4">{children}</div>
    </div>
  );
};

export default Summary;
