import React from 'react';
import cx from 'classnames';

import { ConnectorProps } from './Connector.types';

import styles from './Connector.module.scss';

const Connector = ({ isPast }: ConnectorProps) => (
  <hr className={cx(styles.connector, isPast && 'tw-border-primary-dark-green')} />
);

export default Connector;
