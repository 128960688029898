import React from 'react';

import { Modal } from 'components/UI';
import Button from 'components/Button';
import BrokenClock from 'components/svg/BrokenClock';
import { useSessionExpirationModal } from './hooks';

const SessionExpirationModal = () => {
  const { show, formattedDuration, handleRefreshSessionTokens, handleCloseSession } = useSessionExpirationModal();

  return (
    <Modal show={show} title="Session Expiry Warning">
      <div className="tw-flex tw-items-center tw-justify-between tw-gap-6 tw-p-8">
        <div>
          <BrokenClock width="75" height="90" />
        </div>
        <p>
          Your current session is about to expire due to inactivity. To continue using Loop, please click the{' '}
          <span className="tw-font-semibold">Continue</span> button below. If you do not take any action, you will be
          automatically logged out in <span className="tw-font-semibold">{formattedDuration}</span>.
        </p>
      </div>

      <div className="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-4 tw-flex tw-justify-between">
        <Button onClick={handleCloseSession}>Log Out</Button>
        <Button primary onClick={handleRefreshSessionTokens}>
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default SessionExpirationModal;
