import React, { useContext, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { AuthContext } from 'context/Auth';
import { CreateCardContext } from './CreateCardContext';
import { Checkbox, SubmitButton } from 'components/FormFields/v2';
import CreditAgreementV2 from 'components/creditCards/agreements/CreditAgreementV2';
import NewCardDetailsStep from './NewCardDetailsStep';
import CreateCardSuccess from './CreateCardSuccess';
import { formatMoney } from 'utility/currency';
import useCreateCreditCards from 'hooks/useCreateCreditCards';
import WaitingRoomIcon from 'components/svg/WaitingRoom';
import CashAndStarsMultiple2 from 'components/svg/CashAndStarsMultiple2';
import CardAndCalendar from 'components/svg/CardAndCalendar';
import ChecklistAndMagnifier from 'components/svg/ChecklistAndMagnifier';
import Button from 'components/Button';
import config from 'config';

const KeyTerms = ({ globalCreditLimit }) => {
  return (
    <div id="key-terms" className="tw-px-8 lg:tw-px-10 tw-mb-8">
      <div className="tw-flex tw-items-center tw-bg-primary-light-green tw-my-4 tw-px-4 tw-pt-2 tw-rounded">
        <WaitingRoomIcon
          barColor="var(--colors-secondary-pastel-orange-100)"
          bigStarColor="var(--colors-primary-dark-green)"
          width="80"
          height="80"
          className="tw-min-w-max"
        />
        <div className="tw-ml-4">
          <span className="tw-font-bold tw-block">Credit Limit</span>
          <small>{formatMoney(globalCreditLimit?.amount)} Equivalent</small>
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-bg-primary-light-green tw-mb-4 tw-px-4 tw-py-4 tw-rounded">
        <CashAndStarsMultiple2 size="80" className="tw-min-w-max" />
        <div className="tw-ml-4">
          <span className="tw-font-bold tw-block">Card Currencies</span>
          <small>Spend and repay in CAD, USD, EUR, and GBP</small>
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-bg-primary-light-green tw-mb-4 tw-px-4 tw-py-4 tw-rounded">
        <CardAndCalendar width="80" height="60" className="tw-min-w-max" />
        <div className="tw-ml-4">
          <span className="tw-font-bold tw-block">Payment Due Date</span>
          <small>25 days after your monthly statement period</small>
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-bg-primary-light-green tw-mb-4 tw-px-4 tw-py-4 tw-rounded">
        <ChecklistAndMagnifier width="80" height="70" className="tw-min-w-max" />
        <div className="tw-ml-4">
          <span className="tw-font-bold tw-block">Annual Interest Rate</span>
          <small className="tw-block">0% Interest on Purchases when balance paid in full by Payment Due Date</small>
          <div className="tw-text-xs">Cash Advance and Purchase rate equal to {config.defaultCardAPR}</div>
        </div>
      </div>
    </div>
  );
};

const AgreementsStep = ({ onClose, refetch, creditCards, globalCreditLimit }) => {
  const [navigateBack, setNavigateBack] = useState(false);
  const { cardFormData, setIsModalFetchDataOnUnmount } = useContext(CreateCardContext);
  const { isSubmitting, createCreditCard, hasSubmitted } = useCreateCreditCards();

  const form = useForm();
  const { register, handleSubmit } = form;

  const { me } = useContext(AuthContext);
  if (!me) return null;

  const { firstName, lastName } = me;

  const handleSubmitForm = async () => {
    const { virtual, businessNameOnCard, displayName, contactId } = cardFormData;

    // ensures most recent cc data is fetched when modal is closed
    setIsModalFetchDataOnUnmount(true);

    await createCreditCard({ virtual, nameOnCard: businessNameOnCard.slice(0, 18), displayName, contactId });
  };

  const onNavigateBack = () => {
    setNavigateBack(true);
  };

  if (hasSubmitted) return <CreateCardSuccess onClose={onClose} refetch={refetch} creditCards={creditCards} />;

  if (navigateBack)
    return (
      <NewCardDetailsStep
        onClose={onClose}
        refetch={refetch}
        creditCards={creditCards}
        globalCreditLimit={globalCreditLimit}
      />
    );

  return (
    <div className="tw-py-6">
      <div className="tw-px-8 lg:tw-px-10">
        <strong>Key Terms</strong>
      </div>
      <div>
        <KeyTerms globalCreditLimit={globalCreditLimit} />
      </div>
      <div className="tw-px-8 lg:tw-px-10">
        <strong>Agreements</strong>
      </div>
      <div
        className="tw-px-8 lg:tw-px-10 tw-mt-8 tw-mb-10 tw-border-b tw-border-t tw-border-neutral-grey-4"
        id="agreements"
        style={{ overflowY: 'scroll', height: '300px' }}
      >
        <div className="tw-mt-6" id="credit-agreement">
          <CreditAgreementV2 />
        </div>
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="tw-px-8 lg:tw-px-10 tw-mb-4">
            <Checkbox
              name="acceptedCreditAgreement"
              label={<p>{`I, ${firstName} ${lastName}, have read and agree to the credit agreement`}</p>}
              defaultChecked={true}
              ref={register({ required: true })}
            />
          </div>
          <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-gap-y-4 lg:tw-gap-y-0 tw-border-t tw-border-neutral-grey-4  tw-px-8 lg:tw-px-8 tw-pt-6">
            <Button
              className="tw-w-full lg:tw-w-max tw-bg-neutral-grey-5 tw-border tw-border-neutral-grey-3 tw-text-neutral-dark tw-py-2 tw-px-4 tw-rounded-md tw-text-center"
              onClick={onNavigateBack}
            >
              Back to Card Details
            </Button>
            <SubmitButton
              className={`tw-w-full lg:tw-w-max ${isSubmitting ? 'tw-bg-neutral-grey-3' : 'tw-bg-primary-dark-green'}`}
            >
              <p className="tw-text-neutral-light">{isSubmitting ? 'Submitting...' : 'Create Card'}</p>
            </SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AgreementsStep;
