import React from 'react';
import { capitalize } from 'utility/string';
import { ReimbursementApprovalRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';
import ReimbursementRequestDetail from './ReimbursementRequestDetail';
import { useReimbursementRequestInfo } from './hooks';

const ReimbursementRequestInfo = ({ request }: { request?: ReimbursementApprovalRequestDataType }) => {
  const {
    formattedAmountRequested,
    formattedOriginalAmount,
    requester,
    showAmountInOtherCurrency,
    originalAmountCurrency,
  } = useReimbursementRequestInfo({ request });

  return (
    <div className="tw-pb-4 tw-px-6">
      <div className="tw-flex tw-flex-col tw-bg-secondary-light-green tw-rounded-md tw-p-4">
        <div className="tw-text-m tw-font-medium">Reimbursement Details</div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-flex tw-flex-col tw-w-3/5 tw-mr-4">
            <ReimbursementRequestDetail label="Member">
              <div className="tw-flex tw-flex-col tw-w-3/4 tw-break-words">
                <span>{capitalize(requester?.name)}</span>
                <span>{requester?.email}</span>
              </div>
            </ReimbursementRequestDetail>
          </div>
          <div className="tw-flex tw-flex-col tw-w-2/5">
            <ReimbursementRequestDetail label="Amount in CAD" value={formattedAmountRequested} />
            {showAmountInOtherCurrency && (
              <ReimbursementRequestDetail
                label={`Amount in ${originalAmountCurrency}`}
                value={formattedOriginalAmount}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReimbursementRequestInfo;
