import React from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';

const DeniedTransaction = ({ transaction }) => {
  const preAuthTransaction = transaction.preAuthorization;

  return (
    <div className="tw-flex">
      <span className="tw-mr-1">{transaction.rejectReason}</span>
      <IoInformationCircleOutline
        size={20}
        data-for="reject-reason-id"
        data-tip
        className="tw-text-neutral-grey-2 tw-cursor-pointer focus:tw-outline-none focus:tw-ring-0"
      />
      <ReactTooltip
        id="reject-reason-id"
        className="tw-w-48 tw-text-md tw-text-center tw-rounded-md tw-shadow-lg tw-opacity-100"
        textColor="var(--colors-semantic-error)"
        backgroundColor="var(--colors-semantic-error-background)"
      >
        {preAuthTransaction
          ? 'Rejected because Pre-auth transaction needs 35% more available balance to proceed.'
          : 'Rejected due to transaction volume limit exceeded. Please contact support@bankonloop.com.'}
      </ReactTooltip>
    </div>
  );
};

export default DeniedTransaction;
