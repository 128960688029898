import React, { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import Layout from 'components/onboarding/Layout';
import { Loaders } from 'components/cards/Loader';
import Remove from 'components/svg/Remove';

const InactiveUser = () => {
  const { meLoading } = useContext(AuthContext);

  return (
    <Layout>
      <div className="lg:tw-pt-24 tw-space-y-8 tw-mb-8 tw-flex tw-flex-col tw-justify-center tw-items-center tw-flex-grow">
        {meLoading ? <Loaders.Light /> : <Content />}
      </div>
    </Layout>
  );
};

const Content = () => (
  <>
    <Remove />
    <h1>Account suspended</h1>
    <div className="lg:tw-w-2/3 tw-text-center tw-space-y-8">
      <p>Your account has been suspended. Please contact your account administrator.</p>
    </div>
  </>
);

export default InactiveUser;
