import React from 'react';
import cx from 'classnames';
import { BiCheck, BiX } from 'react-icons/bi';

import { CardMigrationSelectedCard } from 'components/CardMigrationV2/CardMigrationV2.types';
import { composeFullAddress } from 'utility/address';

const ConfirmCardRow = ({ card }: { card: CardMigrationSelectedCard }) => {
  const { nickName, isVirtual, address, migrate } = card;
  const shouldBeDoubleRow = !isVirtual && migrate;

  return (
    <div
      className={cx(
        !migrate && 'tw-bg-neutral-grey-4',
        'tw-flex tw-py-4 tw-px-2 tw-w-full tw-flex-col tw-bg-primary-light-green tw-border-b-2 tw-border-neutral-grey-3'
      )}
    >
      <div
        className={cx(
          shouldBeDoubleRow && 'tw-border-b-2 tw-border-secondary-pastel-green-100',
          'tw-flex tw-w-full tw-pb-2'
        )}
      >
        <div className="tw-w-4/6 tw-font-bold tw-pl-4">{nickName}</div>
        <div className="tw-w-1/6">{isVirtual ? 'Virtual' : 'Physical'}</div>
        <div className="tw-w-1/6 tw-flex tw-justify-end">
          {migrate ? (
            <BiCheck size={24} className="tw-mr-8 tw-text-primary-dark-green" />
          ) : (
            <BiX size={24} className="tw-mr-8 tw-text-semantic-error" />
          )}
        </div>
      </div>
      {shouldBeDoubleRow && address && (
        <div className="tw-w-full tw-text-sm tw-mt-0 tw-pl-4 tw-pt-1">Deliver to: {composeFullAddress(address)}</div>
      )}
    </div>
  );
};

export default ConfirmCardRow;
