import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ampTrackEvent } from 'amplitude';
import { PASSWORD_REGEX } from 'constants/regex';
import { CHANGE_PASSWORD } from 'graphql/settings';
import { PasswordField } from 'components/FormFields/v2';
import PasswordStrengthMeter from 'components/auth/PasswordStrengthMeter';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import Button from 'components/Button';

const UpdatePassword = ({ closeModal, show }) => {
  const form = useForm();
  const { register, handleSubmit, watch } = form;
  const password = watch('password');
  const [changePassword, { loading: changingPassword, error }] = useMutation(CHANGE_PASSWORD);

  const onSubmit = async ({ currentPassword, password, passwordConfirmation }) => {
    const statusToast = toast.loading('Updating password...');

    ampTrackEvent('Update Password');

    try {
      await changePassword({ variables: { currentPassword, password, passwordConfirmation } });

      toast.update(statusToast, {
        render: 'Password has been updated.',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      handleClose();
    } catch (err) {
      console.error(err);
      toast.update(statusToast, {
        render: 'Something went wrong. Please try again.',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleClose = () => {
    closeModal('password');
  };

  return (
    <Modal show={show} onClose={handleClose}>
      <div className="tw-flex tw-justify-between tw-p-6">
        <h5 className="tw-text-left">Change Password</h5>
        <Button onClick={handleClose}>
          <Close />
        </Button>
      </div>
      <hr className="tw-mb-6" />
      <h3 className="tw-px-10 tw-text-2xl tw-font-semibold">Confirm password change</h3>
      <FormProvider {...form} graphQLErrors={error?.graphQLErrors}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-flex tw-w-full tw-flex-col tw-justify-center tw-p-10">
            <PasswordField
              type="password"
              name="currentPassword"
              rootClass="tw-mb-5"
              placeholder="********"
              label="Current password"
              required
              ref={register({ required: true })}
            />
            <PasswordField
              type="password"
              name="password"
              placeholder="********"
              label="New password"
              required
              ref={register({
                required: true,
                pattern: {
                  value: PASSWORD_REGEX,
                },
              })}
            />
            <PasswordStrengthMeter password={password} showScore={false} />
            <PasswordField
              type="password"
              name="passwordConfirmation"
              placeholder="********"
              label="Confirm new password"
              rootClass="tw-mt-8"
              ref={register({
                required: true,
                pattern: {
                  value: PASSWORD_REGEX,
                },
              })}
              required
            />
          </div>

          <hr />
          <div className="tw-p-6 tw-flex tw-justify-end">
            <Button
              primary
              type="submit"
              className="tw-text-right tw-px-12"
              onClick={handleSubmit}
              disabled={changingPassword}
            >
              {changingPassword ? 'Confirming...' : 'Confirm'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default UpdatePassword;
