import React from 'react';

export const MultiCurrencyDisabled = ({ accountName }) =>
  accountName.toLowerCase() == 'quickbooks' ? QuickBooksMultiCurrencyDisabled() : XeroBooksMultiCurrencyDisabled();

const QuickBooksMultiCurrencyDisabled = () => (
  <div className="tw-text-sm tw-bg-secondary-pastel-yellow-50 tw-rounded-md tw-pl-6 tw-pr-2 tw-py-3 tw-m-auto tw-w-max tw-flex-col tw-space-py-2 tw-mt-10">
    <div className="tw-text-md tw-font-bold tw-mb-3">
      To Proceed, Please Enable Multi-Currency In QuickBooks By Following The Steps Below{' '}
    </div>

    <ul className="tw-list-disc">
      <li className="tw-py-1">Click on Settings ⚙ and select Account and settings. </li>
      <li className="tw-py-1">From the Home Currency ▼ dropdown, select your currency.</li>
      <li className="tw-py-1">
        Turn on the Multicurrency switch and confirm that you understand that you can't undo this option.{' '}
      </li>
      <li className="tw-py-1">Select Save, then Done. You can reload this page.</li>
    </ul>
  </div>
);

const XeroBooksMultiCurrencyDisabled = () => (
  <div className="tw-text-sm tw-bg-secondary-pastel-yellow-50 tw-rounded-md tw-pl-6 tw-pr-2 tw-py-3 tw-m-auto tw-w-max tw-flex-col tw-space-py-2 tw-mt-10">
    <div className="tw-text-md tw-font-bold tw-mb-3">
      To Proceed, Please Enable Multi-Currency In Xero By Following The Steps Below{' '}
    </div>

    <ul className="tw-list-disc">
      <li className="tw-py-1">Click on the organisation name, select Settings, then click Currencies.</li>
      <li className="tw-py-1">Click Add Currency. (This will not include your base/home currency: CAD)</li>
      <li className="tw-py-1">Select a currency.</li>
      <li className="tw-py-1">Click Add Currency. You can then reload this page.</li>
      <li className="tw-py-1">
        For more info please refer
        <a href="https://central.xero.com/s/article/Add-a-foreign-currency-in-Xero" target="_blank" rel="noreferrer">
          {' '}
          <small className="tw-underline tw-text-neutral-grey-2">Xero help page</small>
        </a>
      </li>
    </ul>
  </div>
);
