import React, { useState, createContext, Dispatch, SetStateAction } from 'react';
import { get } from 'lodash';

import { APPROVAL_MODAL, APPROVAL_TAB } from 'components/Approvals/constants';
import { useQuery } from '@apollo/client';
import { GET_PAYEES } from 'graphql/payees';
import { AccountWallet } from 'types/wallet';
import { BankAccount } from 'types/bankAccount';
import { GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_FOR_ACCOUNTS_PAGE } from 'graphql/wallets';
import { Payee } from 'types/payees';
import LineOfCredit from 'domain/lineOfCredit';
import { useToggle } from 'hooks';
import { CardExpenseRequestDataType } from '../components/ApprovalRequest.types';

type ApprovalRequestsContextType = {
  selectedRequestId: String | null;
  setSelectedRequestId: Dispatch<SetStateAction<String | null>>;
  selectedTab: APPROVAL_TAB | null;
  setSelectedTab: Dispatch<SetStateAction<APPROVAL_TAB>>;
  payees: Payee[];
  hasPayees: boolean | null;
  wallets: AccountWallet[];
  lineOfCredit: [] | null;
  bankAccounts: BankAccount[];
  isOpenModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  activeModal: String | null;
  setActiveModal: Dispatch<SetStateAction<APPROVAL_MODAL | null>>;
  selectedRequests: CardExpenseRequestDataType[];
  setSelectedRequests: Dispatch<SetStateAction<CardExpenseRequestDataType[]>>;
};

export const ApprovalRequestsContext = createContext<ApprovalRequestsContextType>({
  selectedRequestId: null,
  setSelectedRequestId: () => {},
  selectedTab: APPROVAL_TAB.expenseReimbursements,
  setSelectedTab: () => {},
  payees: [],
  hasPayees: null,
  wallets: [],
  lineOfCredit: null,
  bankAccounts: [],
  isOpenModal: false,
  openModal: () => {},
  closeModal: () => {},
  activeModal: null,
  setActiveModal: () => {},
  selectedRequests: [],
  setSelectedRequests: () => {},
});

export const ApprovalRequestsContextProvider: React.FC = ({ children }) => {
  const [selectedRequestId, setSelectedRequestId] = useState<String | null>(null);
  const [selectedTab, setSelectedTab] = useState<APPROVAL_TAB>(APPROVAL_TAB.expenseReimbursements);

  const [selectedRequests, setSelectedRequests] = useState<CardExpenseRequestDataType[]>([]);

  const { data: payeesData } = useQuery(GET_PAYEES, { fetchPolicy: 'network-only' });
  const payees = get(payeesData, 'payees') || [];
  const hasPayees = payees?.length > 0;

  const { isOpen: isOpenModal, open: openModal, close: closeModal } = useToggle();
  const [activeModal, setActiveModal] = useState<APPROVAL_MODAL | null>(null);

  const { data: walletData } = useQuery<{ wallets: AccountWallet[]; bankAccounts: BankAccount[] }>(
    GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_FOR_ACCOUNTS_PAGE
  );
  const bankAccounts: BankAccount[] = get(walletData, 'me.account.bankAccounts', []);
  const lineOfCredit = get(walletData, 'me.account.lineOfCredit', null);
  const wallets: AccountWallet[] = get(walletData, 'me.account.wallets', []);

  return (
    <ApprovalRequestsContext.Provider
      value={{
        selectedRequestId,
        setSelectedRequestId,
        selectedTab,
        setSelectedTab,
        payees,
        hasPayees,
        wallets,
        lineOfCredit: lineOfCredit && new LineOfCredit(lineOfCredit),
        bankAccounts,
        isOpenModal,
        openModal,
        closeModal,
        activeModal,
        setActiveModal,
        selectedRequests,
        setSelectedRequests,
      }}
    >
      {children}
    </ApprovalRequestsContext.Provider>
  );
};
