import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';

import PaymentBoxes from '../PaymentBoxes';

const Payments = () => {
  const history = useHistory();

  return (
    <div className="tw-p-4 tw-space-y-4">
      <BiArrowBack onClick={() => history.goBack()} size={24} />
      <h2 className="tw-text-2xl">Transfer Between Accounts</h2>
      <div>
        <small className="tw-text-neutral-grey-1">
          Transfer funds between Loop accounts and external bank accounts.
        </small>
      </div>
      <div className="tw-flex tw-flex-col tw-pt-8">
        <PaymentBoxes />
      </div>
    </div>
  );
};

export default Payments;
