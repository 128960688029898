import React from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';

import config from 'config';

const AdminSuspendedCardActions = () => (
  <div className="tw-flex tw-flex-col tw-w-full tw-py-4">
    <div className="tw-flex tw-items-start tw-rounded-md tw-bg-neutral-grey-4 tw-p-2 tw-mt-2">
      <IoInformationCircleOutline size={24} className="tw-mr-2" />
      <span className="tw-text-xs tw-text-neutral-grey-1">
        This card has been been suspended by our Team. Please contact{' '}
        <a
          className="tw-font-semibold tw-text-primary-dark-green"
          target="_blank"
          href={`mailto:${config.supportEmail}`}
          rel="noreferrer"
        >
          support@bankonloop.com
        </a>
        .
      </span>
    </div>
  </div>
);

export default AdminSuspendedCardActions;
