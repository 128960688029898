import React from 'react';
import { useToggle } from 'hooks';

import { Banner } from 'components/UI';
import { BannerType } from 'components/UI/Banner';
import CardMigrationV2Modal from 'components/CardMigrationV2/CardMigrationV2Modal';
import Warning from 'components/svg/Warning';
import useFeatureToggle from 'hooks/useFeatureToggle';

const NotifyCardMigration = () => {
  const { isExperimentEnabled, loading } = useFeatureToggle(true, 'card_2_0_migration');
  const { isOpen, toggle, close } = useToggle();

  if (loading || !isExperimentEnabled) return null;

  return (
    <>
      <Banner
        type={BannerType.actionRequired}
        title={'Action Required by [Date]'}
        message={
          <>
            <span>
              Loop has launched a new card platform offering a number of new features and benefits. As part of this
              change, we will require you to migrate your cards, meaning that you will receive new card details for all
              of your Loop Cards.
            </span>
            <span>
              Complete your migration [time period] and you will receive [MIGRATION Promo] for all spend before [DATE].
            </span>
          </>
        }
        icon={<Warning />}
        classNames={'tw-max-w-full tw-mt-10 tw-px-8 tw-py-4'}
        submitButton={true}
        submitButtonLabel={'Select Migration Preferences'}
        onSubmit={toggle}
      />
      <CardMigrationV2Modal show={isOpen} onClose={close} />
    </>
  );
};

export default NotifyCardMigration;
