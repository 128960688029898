import { useEffect, useState, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { SOLID_OWNERSHIP_DISCLOSURE_READINESS } from 'graphql/wallets';
import { DISCLOSURE_READINESS_REFETCHING_INTERVAL } from '../constants';

const useDisclosureReadiness = () => {
  const [isDisclosureReadyToBeRequested, setIsDisclosureReadyToBeRequested] = useState<boolean>(false);

  const disclosureReadinessPullingIntervalId = useRef<NodeJS.Timeout | null>(null);

  const [getSolidOwnershipDisclosureReadiness, { error, loading }] = useLazyQuery(
    SOLID_OWNERSHIP_DISCLOSURE_READINESS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const isReady = get(data, 'solidRequirements.controlPersonReady');
        if (!isReady) return;

        if (disclosureReadinessPullingIntervalId.current) {
          clearTimeout(disclosureReadinessPullingIntervalId.current);
        }

        setIsDisclosureReadyToBeRequested(true);
      },
    }
  );

  useEffect(() => {
    disclosureReadinessPullingIntervalId.current = setInterval(async () => {
      await getSolidOwnershipDisclosureReadiness();
    }, DISCLOSURE_READINESS_REFETCHING_INTERVAL);

    return () => {
      if (disclosureReadinessPullingIntervalId.current) {
        clearTimeout(disclosureReadinessPullingIntervalId.current);
      }
    };
  }, []);

  return {
    isDisclosureReadyToBeRequested,
    disclosureReadinessError: error,
    disclosureReadinessLoading: loading,
  };
};

export default useDisclosureReadiness;
