import React from 'react';
import cx from 'classnames';

import { StatementStatus as StatementStatusType } from 'types/statement';
import { parseStatementStatus } from 'components/creditCards/components/CardBalances/components/StatementBalances/StatementBalances.utils';

type StatementStatusProps = {
  status: StatementStatusType;
  isDueDatePassed: boolean;
};

const StatementStatus = ({ status, isDueDatePassed }: StatementStatusProps) => {
  const { label, className } = parseStatementStatus({ status, isDueDatePassed });

  return (
    <div
      className={cx(
        'tw-px-2 tw-py-px tw-mr-auto tw-border tw-border-neutral-grey-4 tw-rounded-md tw-text-sm tw-font-semibold tw-h-6 tw-min-w-20 tw-whitespace-nowrap',
        className
      )}
    >
      {label}
    </div>
  );
};

export default StatementStatus;
