import React from 'react';
import cx from 'classnames';

import { ActionButtonProps } from './ActionButton.types';

const ActionButton = ({ primary, buttonIcon, fontSize, buttonText, onClick }: ActionButtonProps) => {
  return (
    <button
      className={cx(
        'tw-py-2 tw-px-4 tw-rounded-md tw-text-center tw-inline-flex tw-items-center tw-justify-center tw-text-neutral-light md:tw-w-40 tw-w-full',
        primary === false ? 'tw-bg-primary-yellow' : 'tw-bg-primary-dark-green'
      )}
      onClick={onClick}
    >
      <span className="tw-mr-2" style={{ fontSize }}>
        {buttonIcon}
      </span>
      <span>{buttonText}</span>
    </button>
  );
};

export default ActionButton;
