import { useQuery } from '@apollo/client';
import { GET_XERO_BANK_FEED_CONNECTIONS } from 'graphql/xeroBankFeeds';
import { Currencies } from 'constants/currencies';

const useBankFeeds = () => {
  const { data, loading: isFeedConnectionLoading } = useQuery(GET_XERO_BANK_FEED_CONNECTIONS);
  const feedConnections = data?.xeroAccountingBankFeeds || [];

  const currencyList = [Currencies.CAD, Currencies.USD, Currencies.GBP, Currencies.EUR];

  return {
    currencyList,
    feedConnections,
    isFeedConnectionLoading,
  };
};

export default useBankFeeds;
