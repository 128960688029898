import React, { useContext } from 'react';
import { Transition } from '@headlessui/react';

import CreditCardDetailContainer from 'containers/creditCards/CreditCardDetail';
import { CreditCardContext } from 'context/CreditCard';
import Close from 'components/svg/Close';

const CreditCardDetailOverlay = () => {
  const { creditCard, setCreditCard } = useContext(CreditCardContext);

  const onCloseOverlay = () => setCreditCard(null);

  return (
    <Transition
      show={!!creditCard}
      as="div"
      className="tw-h-screen tw-w-1/4 tw-fixed tw-top-0 tw-right-0 tw-shadow-notification tw-px-4 tw-z-10 tw-bg-neutral-light tw-transform tw-overflow-y-auto tw-scrollbar-hide"
      enter="tw-ease-in-out tw-duration-300"
      enterFrom="tw-translate-x-96"
      enterTo="tw-translate-x-0"
      leave="tw-ease-in-out tw-duration-300"
      leaveFrom="tw-translate-x-0"
      leaveTo="tw-translate-x-96"
    >
      <CreditCardDetailContainer
        header={
          <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
            <small className="tw-text-neutral-grey-2">Card Details</small>
            <Close className="tw-cursor-pointer" onClick={onCloseOverlay} />
          </div>
        }
      />
    </Transition>
  );
};

export default CreditCardDetailOverlay;
