import React from 'react';

import { Modal, Progress } from 'components/UI';
import { Steps } from './components';
import { USBankAccountVerificationProvider } from './contexts/USBankAccountVerificationContext';
import { useUSBankAccountVerificationModal } from './hooks';
import { bankAccountVerificationSteps } from './constants';
import { USBankAccountVerificationModalProps } from './USBankAccountVerificationModal.types';

const USBankAccountVerificationModal = ({ show, onClose }: USBankAccountVerificationModalProps) => {
  const {
    currentStep,
    onPrevStep,
    onNextStep,
    errorSteps,
    isCompleted,
    onComplete,
    showProgress,
    onSubmitNaicsAndProjections,
  } = useUSBankAccountVerificationModal();

  return (
    <Modal show={show} onClose={onClose} title="Verify Your US Account Information">
      {showProgress && (
        <Progress
          currentStepIndex={currentStep}
          stepLabelsAndIndexes={bankAccountVerificationSteps}
          errorSteps={errorSteps}
          isCompleted={isCompleted}
          classNames="tw-mt-8 tw-px-8"
        />
      )}
      <USBankAccountVerificationProvider>
        <Steps
          currentStep={currentStep}
          onPrevStep={onPrevStep}
          onNextStep={onNextStep}
          onComplete={onComplete}
          onFinish={onClose}
          onSubmitNaicsAndProjections={onSubmitNaicsAndProjections}
        />
      </USBankAccountVerificationProvider>
    </Modal>
  );
};

export default USBankAccountVerificationModal;
