import React from 'react';
import cx from 'classnames';
import { TextElement } from '@basis-theory/basis-theory-react';

import { bsPinElementStyle } from './constants';
import { BasisTheroyPinInputFieldProps } from './BasisTheoryPinInputFields.types';
import styles from './BasisTheoryPinInputFields.module.scss';

const BasisTheoryPinInputFields = ({
  pinRef,
  confirmPinRef,
  bt,
  isColumnLayout = true,
}: BasisTheroyPinInputFieldProps) => (
  <div
    className={`tw-justify-center tw-flex ${
      isColumnLayout ? 'tw-flex-col' : 'tw-flex-row tw-gap-8'
    } tw-font-bold tw-px-8`}
    aria-label="Pin"
  >
    <div>
      <label htmlFor="pin" className="tw-text-sm tw-text-primary-dark-green">
        4-Digit PIN
      </label>
      <div className={cx(styles.inputContainer, 'tw-mb-4')}>
        <TextElement
          id="pin"
          mask={[/\d/u, /\d/u, /\d/u, /\d/u]}
          bt={bt}
          style={bsPinElementStyle}
          placeholder="****"
          ref={pinRef}
        />
      </div>
    </div>
    <div>
      <label htmlFor="confirmPin" className="tw-text-sm tw-text-primary-dark-green">
        Confirm PIN
      </label>
      <div className={styles.inputContainer}>
        <TextElement
          id="confirmPin"
          mask={[/\d/u, /\d/u, /\d/u, /\d/u]}
          bt={bt}
          style={bsPinElementStyle}
          placeholder="****"
          ref={confirmPinRef}
        />
      </div>
    </div>
  </div>
);
export default BasisTheoryPinInputFields;
