import React from 'react';
import { Theme } from 'styles/tailwind';
import { Link } from 'react-router-dom';

import LoopLogo from 'components/svg/LoopLogo';
import config from '../config';
import BrokenClock from 'components/svg/BrokenClock';
import ArrowRight from 'components/svg/ArrowRight';
import Button from 'components/Button';

import './NotFoundFallback.scss';

const NotFoundFallback = () => {
  return (
    <Theme className="NotFoundFallback">
      <Header />
      <Content />
      <Footer />
    </Theme>
  );
};

const Header = () => (
  <div className="NotFoundFallback__Header">
    <a href="/">
      <LoopLogo />
    </a>
  </div>
);

const Content = () => (
  <div className="NotFoundFallback__Content">
    <BrokenClock />

    <div className="tw-text-4xl tw-font-semibold tw-mt-4">Looks like we've gotten our loops crossed</div>

    <div className="tw-text-base tw-text-neutral-grey-1">
      We know time is money but we couldn't find the page you were looking for.
    </div>

    <Link to="/dashboard" className="tw-mt-6">
      <Button primary>
        Home <ArrowRight className="tw-ml-2" />
      </Button>
    </Link>
  </div>
);

const Footer = () => (
  <div className="NotFoundFallback__Footer">
    <div className="tw-flex tw-flex-grow-0 tw-items-center">
      <small className="tw-text-neutral-grey-2">{config.year} © Loop. ALL Rights Reserved. </small>
      <a className="tw-hidden lg:tw-block tw-ml-8" href={config.privacyPolicyUrl} target="_blank" rel="noreferrer">
        <small className="tw-underline tw-text-neutral-grey-2">Legal </small>
      </a>
    </div>
  </div>
);

export default NotFoundFallback;
