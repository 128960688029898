import React from 'react';
import { useToggle } from 'hooks';

import { composeFullAddress } from 'utility/address';
import { AddressCategory } from 'types/creditCard';
import { UpdateCardAddressModal } from '../../components';
import { CardAddressProps } from './CardAddress.types';

const CardAddress = ({ label, address }: CardAddressProps) => {
  const { isOpen: isOpenModal, open: openUpdateCardAddressModal, close: closeUpdateCardAddressModal } = useToggle();
  const { unitNumber, street, city, postalCode, country, countrySubdivision, category } = address || {};

  const formattedAddress = address
    ? composeFullAddress({
        country,
        countrySubdivision,
        postalCode,
        city,
        street,
        unitNumber,
      })
    : '';

  return (
    <div className="tw-grid tw-grid-cols-3 tw-items-center tw-w-full">
      <span className="tw-text-neutral-grey-2">{label} Address</span>
      <span className="tw-font-bold">{formattedAddress}</span>
      {category !== AddressCategory.DEFAULT && (
        <button
          onClick={openUpdateCardAddressModal}
          className="tw-justify-self-end tw-text-neutral-grey-1 tw-border-b tw-ml-2 hover:tw-text-primary-dark-green hover:tw-border-primary-dark-green tw-transition tw-ease-in-out tw-duration-250 tw-col-start-3"
        >
          {formattedAddress ? 'Edit' : 'Add'}
        </button>
      )}
      <UpdateCardAddressModal
        isOpenModal={isOpenModal}
        handleCloseModal={closeUpdateCardAddressModal}
        address={address || null}
        currentCountry={label}
      />
    </div>
  );
};

export default CardAddress;
