import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Modal } from 'components/UI';
import { TextField, SubmitButton } from 'components/FormFields/v2';
import Button from 'components/Button';
import { EditPayorModalProps } from './EditPayorModal.types';
import { useEditPayorModal } from './hooks';

const EditPayorModal = ({ show, onClose }: EditPayorModalProps) => {
  const { form, register, onSubmit, handleSubmit, error, loading } = useEditPayorModal({
    onClose,
  });

  return (
    <Modal show={show} onClose={onClose} title="Edit Payor">
      <div className="tw-flex tw-flex-col tw-border-t tw-border-neutral-grey-4">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="tw-px-8 tw-py-8 tw-space-y-8">
              <TextField name="referenceId" label="Payor Nickname ID" placeholder="Nickname ID" ref={register()} />

              {error?.message && <div className="tw-my-8 tw-text-semantic-error tw-text-xs">{error.message}</div>}
            </div>

            <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex tw-justify-between">
              <Button isDisabled={loading} secondary type="reset" onClick={onClose} className="tw-px-8">
                Back
              </Button>
              <SubmitButton isDisabled={loading} className="tw-py-2 tw-px-8">
                {loading ? 'Submitting...' : 'Submit'}
              </SubmitButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default EditPayorModal;
