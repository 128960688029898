import React from 'react';
import { FormProvider } from 'react-hook-form';
import { PiUsersThreeLight, PiGaugeLight, PiProhibitInsetLight } from 'react-icons/pi';

import { Collapse, ProgressBar, FormFooter } from 'components/UI';
import { Members, SpendingLimits, SpendManagement } from 'components/creditCards/components/cardsSettings';
import { useUpdateCardSettings } from './hooks';

const UpdateCardSettings = () => {
  const {
    currentStep,
    steps,
    form,
    handleSubmit,
    onSubmit,
    memberOptions,
    selectedMembers,
    onSelectedMembersChange,
    merchantCategoriesOptions,
    selectedMerchantCategories,
    setSelectedMerchantCategories,
    isMerchantCategoriesError,
    currenciesOptions,
    allowedMerchantsOptions,
    isSubmitting,
  } = useUpdateCardSettings();

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="tw-flex tw-h-full tw-flex-col tw-justify-between tw-flex-grow tw-w-full"
      >
        <div className="tw-flex tw-flex-col tw-gap-4 tw-py-8 tw-w-full lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0">
          <ProgressBar className="tw-mb-4" currentStepIndex={currentStep} stepLabelsAndIndexes={steps} />

          <Collapse
            label="Manage Team Access"
            LabelIcon={PiUsersThreeLight}
            subLabel="Optional"
            unmount={false}
            defaultOpen
          >
            <Members
              memberOptions={memberOptions}
              selectedMembers={selectedMembers}
              onSelectOptions={onSelectedMembersChange}
            />
          </Collapse>

          <Collapse label="Set Card Limits" LabelIcon={PiGaugeLight} subLabel="Optional" unmount={false}>
            <SpendingLimits />
          </Collapse>

          <Collapse label="Restrict Spending" LabelIcon={PiProhibitInsetLight} subLabel="Optional" unmount={false}>
            <SpendManagement
              merchantCategoriesOptions={merchantCategoriesOptions}
              selectedMerchantCategoriesOptions={selectedMerchantCategories}
              setSelectedMerchantCategoriesOptions={setSelectedMerchantCategories}
              isMerchantCategoriesError={isMerchantCategoriesError}
              currencies={currenciesOptions}
              merchants={allowedMerchantsOptions}
            />
          </Collapse>
        </div>

        <div className="tw-bg-neutral-grey-4.5 tw-w-full tw-pt-4 tw-pb-8 tw-rounded-b-md tw-overflow-hidden tw-mt-auto">
          <FormFooter
            submitButtonLabel="Update Card"
            loading={isSubmitting}
            rootClass="lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0"
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default UpdateCardSettings;
