import { useState, useEffect } from 'react';

import { PreApprovalStatus } from '../constants';

const usePreApprovalProgress = ({ preApprovalStatus, checkStatus, hasConnectedAccounts, loadingExternalAccounts }) => {
  const [progress, setProgress] = useState(0);
  const [hasStatus, setHasStatus] = useState(false);
  const timerIds = [];

  useCheckStatusIfIdle({ preApprovalStatus, checkStatus, loadingExternalAccounts, hasConnectedAccounts });

  useUpdateProgressIfPending({ progress, setProgress, preApprovalStatus, timerIds });

  useCompleteProgressIfDone({ preApprovalStatus, setProgress, timerIds, setHasStatus });

  return { progress, hasStatus };
};

const useCheckStatusIfIdle = ({ preApprovalStatus, checkStatus, loadingExternalAccounts, hasConnectedAccounts }) => {
  useEffect(() => {
    const readyToCheckStatus =
      preApprovalStatus === PreApprovalStatus.IDLE && !loadingExternalAccounts && hasConnectedAccounts;

    if (readyToCheckStatus) checkStatus();
  }, [preApprovalStatus, checkStatus, loadingExternalAccounts, hasConnectedAccounts]);
};

const useUpdateProgressIfPending = ({ progress, setProgress, preApprovalStatus, timerIds }) => {
  useEffect(() => {
    const shouldUpdateStatus = preApprovalStatus === PreApprovalStatus.PENDING && progress < 99;

    if (shouldUpdateStatus) {
      timerIds.push(
        setTimeout(() => {
          setProgress(progress + 1);
        }, 100)
      );
    }
  }, [progress, setProgress, preApprovalStatus, timerIds]);
};

const useCompleteProgressIfDone = ({ preApprovalStatus, setProgress, timerIds, setHasStatus }) => {
  useEffect(() => {
    const isCheckDone =
      preApprovalStatus === PreApprovalStatus.APPROVED || preApprovalStatus === PreApprovalStatus.REJECTED;

    if (isCheckDone) {
      timerIds.forEach((id) => clearTimeout(id));
      setProgress(100);

      setTimeout(() => {
        setHasStatus(true);
      }, 300);
    }
  }, [preApprovalStatus, setProgress, timerIds]);
};

export default usePreApprovalProgress;
