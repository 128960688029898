import React from 'react';

type AutopayBannerProps = {
  currency: String;
};

const AutopayBanner = ({ currency }: AutopayBannerProps) => {
  return (
    <div className="tw-border tw-border-neutral-grey-2 tw-rounded tw-text-sm tw-text-neutral-grey-2 tw-w-52 tw-overflow-hidden">
      <div className="tw-font-semibold tw-text-center tw-py-2 tw-bg-primary-light-green">Automatic Payment</div>
      <div className="tw-flex tw-flex-col tw-gap-y-2 tw-border-t tw-p-4 tw-bg-secondary-pastel-yellow-50">
        <div>
          Schedule your payments to automatically pay <b>All Statement Balances in {currency}</b> each billing cycle.
        </div>
        <div>Payments will be charged 7 days before your payment due date from the account you select.</div>
      </div>
    </div>
  );
};

export default AutopayBanner;
