import React, { useState } from 'react';

import { formatDueDate } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { Modals } from 'components/lineOfCredit/modals';
import Button from 'components/Button';
import { paymentStatus, SCOPE } from 'constants/index';
import PermissionChecker from 'components/PermissionChecker';

const SidebarBalances = ({ lineOfCredit, fundInfo }) => {
  const status = fundInfo && fundInfo.nextPayment && fundInfo.nextPayment.status;
  const dueDate = lineOfCredit && lineOfCredit.paymentDate;
  const currentBalance = lineOfCredit && lineOfCredit.currentBalance;
  const pendingBalance = lineOfCredit && lineOfCredit.pendingBalance;
  const postedBalance = lineOfCredit && lineOfCredit.postedBalance;
  const amountDue = lineOfCredit && lineOfCredit.amountDue;
  const showDue = paymentStatus[status] === 'unpaid';

  const [showPayBalanceModal, setShowPayBalanceModal] = useState(false);

  const onShowPayBalanceModal = () => {
    setShowPayBalanceModal(true);
  };

  return (
    <div className="tw-px-4">
      {showDue && <small>{formatDueDate(dueDate)}</small>}
      <div className="tw-flex tw-justify-between tw-mt-6 tw-mb-2">
        <small className="tw-text-neutral-grey-2">Pending</small>
        <small>{formatMoneyV2(pendingBalance)}</small>
      </div>
      <div className="tw-flex tw-justify-between tw-my-2">
        <small className="tw-text-neutral-grey-2">Posted</small>
        <small>{formatMoneyV2(postedBalance)}</small>
      </div>
      <div className="tw-flex tw-justify-between tw-my-2">
        <small className="tw-text-neutral-grey-2">Current</small>
        <small>{formatMoneyV2(currentBalance)}</small>
      </div>
      {showDue && (
        <div className="tw-flex tw-justify-between">
          <small className="tw-text-neutral-grey-2">Min. payment</small>
          <small>{formatMoneyV2(amountDue)}</small>
        </div>
      )}
      <PermissionChecker scope={SCOPE.managePayments}>
        <div className="tw-flex tw-justify-end tw-mt-1" data-testid="make-loc-payment-btn">
          <Button onClick={onShowPayBalanceModal}>
            <small className="tw-text-primary-dark-green tw-font-semibold">Make payment</small>
          </Button>
        </div>
      </PermissionChecker>
      <Modals.PayLocBalance show={showPayBalanceModal} setShow={setShowPayBalanceModal} />
    </div>
  );
};

export default SidebarBalances;
