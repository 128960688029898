import React from 'react';

import { useNavigateToConfigureAutopay } from '../hooks';
import Button from 'components/Button';

const ActivateButton = () => {
  const { navigateToConfigureAutopay } = useNavigateToConfigureAutopay();

  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-x-6 tw-gap-y-3">
      <Button primary onClick={navigateToConfigureAutopay} className="tw-w-40">
        Activate
      </Button>
      <div className="tw-rounded-md tw-bg-neutral-grey-4 tw-py-2 tw-px-4 tw-text-neutral-grey-2 tw-text-center tw-text-sm">
        Currently Not Enabled
      </div>
    </div>
  );
};

export default ActivateButton;
