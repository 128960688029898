import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

import CashAndStars from 'components/svg/CashAndStars';
import Button from 'components/Button';
import { USAccountOwnershipDisclosureBannerProps } from './USAccountOwnershipDisclosureBanner.types';

const USAccountOwnershipDisclosureBanner = ({ openModal }: USAccountOwnershipDisclosureBannerProps) => {
  return (
    <>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-items-center tw-border tw-rounded-md tw-p-4 tw-bg-primary-dark-green tw-text-neutral-light">
        <div>
          <CashAndStars />
        </div>
        <div>
          <strong>Finish Opening a Local USD Account</strong>
          <div className="tw-text-sm">
            You’re almost there! Finish opening an account so you can send and receive payments like a local. Use
            account details to get paid from your customer, payment processors or marketplaces like Amazon, Shopify,
            Stripe and Paypal.
          </div>
        </div>
        <div className="tw-min-w-max">
          <Button onClick={openModal} tertiary className="tw-text-neutral-light tw-underline">
            Open Account
            <BsArrowRight size={22} className="tw-ml-2" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default USAccountOwnershipDisclosureBanner;
