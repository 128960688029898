import React from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { GET_NOTIFICATION_SETTINGS, UPDATE_NOTIFICATION_SETTINGS } from 'graphql/settings';
import Loader from 'components/cards/Loader';

const GeneralNotifications = ({ internalContactId }) => {
  const [updateNotificationSettings] = useMutation(UPDATE_NOTIFICATION_SETTINGS);
  const { loading, data, refetch } = useQuery(GET_NOTIFICATION_SETTINGS, {
    variables: { internalContactId: internalContactId },
  });

  const updateNotification = async (notificationSettings) => {
    try {
      await updateNotificationSettings({
        variables: {
          notificationSettings: notificationSettings,
        },
      });
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
    const field = e.target.name;
    const value = e.target.checked;
    const updatedNotificationSetting = JSON.parse(JSON.stringify(data.notificationSettings), omitTypename);

    updatedNotificationSetting.notificationSettings.card.approvedCardTransaction[field] = value;
    updateNotification(updatedNotificationSetting);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="tw-w-1/2 tw-p-4">
      <strong>General Notifications</strong>
      <div className="tw-flex tw-justify-end tw-mb-2">
        {/* <span className="tw-text-xs">Email</span> */}
        <span className="tw-text-xs tw-ml-4">SMS</span>
      </div>
      <InvertedCheckbox
        rootClass="tw-pr-1.5 tw-text-sm"
        label="Approved Card Transaction"
        name="approvedCardTransaction"
        onChange={handleChange}
        value={data.notificationSettings.notificationSettings.card.approvedCardTransaction}
      />
    </div>
  );
};

const InvertedCheckbox = ({ label, name, onChange, value, rootClass }) => {
  return (
    <div className={`tw-flex tw-justify-between tw-items-center ${rootClass}`}>
      <div>
        <label className="tw-mb-0" htmlFor={name}>
          {label}
        </label>
        <label className="tw-text-neutral-grey-2">
          You must enable this notification for cards in order to upload receipts through SMS
        </label>
      </div>
      <div>
        {/* <input
          className="tw-cursor-pointer"
          onChange={onChange}
          defaultChecked={value.email}
          id="email"
          type="checkbox"
          name="email"
        /> */}
        <input
          className="tw-cursor-pointer tw-ml-7"
          onChange={onChange}
          defaultChecked={value.sms}
          id="sms"
          type="checkbox"
          name="sms"
        />
      </div>
    </div>
  );
};

export default GeneralNotifications;
