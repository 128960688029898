import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IoCashOutline, IoGiftOutline, IoListOutline, IoRibbonOutline } from 'react-icons/io5';
import { Tab } from '@headlessui/react';
import cx from 'classnames';

import { TabIndexContext } from 'context/TabIndex';
import { Offers } from 'components/rewardsAndOffers/offers';
import { Summary, Rewards, History } from 'components/rewardsAndOffers/rewards';
import { ReferAndEarn } from 'components/rewardsAndOffers/ReferAndEarn';
import useIsMobile from 'hooks/useIsMobile';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import { RewardsContextProvider } from 'components/rewardsAndOffers/contexts/RewardsContext';

export const RewardsAndOffer = () => {
  const history = useHistory();
  const isMobile = useIsMobile();

  if (isMobile) history.push('/dashboard/home');

  const queryParams = new URLSearchParams(useLocation().search);
  const tab = queryParams.get('tab');
  const referAndEarn = tab === 'refer-and-earn';
  const { defaultIndex } = useContext(TabIndexContext);

  const pageTabs = [
    {
      label: 'Rewards',
      icon: <IoGiftOutline />,
    },
    {
      label: 'History',
      icon: <IoListOutline />,
    },
    {
      label: 'Offers',
      icon: <IoCashOutline />,
    },
    {
      label: 'Refer & Earn',
      icon: <IoRibbonOutline />,
    },
  ];

  const pageTabContent = [
    {
      title: 'Rewards',
      component: (
        <>
          <Summary className="tw-mb-4 tw-mt-4" />
          <Rewards />
        </>
      ),
    },
    {
      title: 'History',
      component: <History />,
    },
    {
      title: 'Offers',
      component: <Offers />,
    },
    {
      title: 'Earn 10,000 Points For Each Business You Refer',
      component: <ReferAndEarn />,
    },
  ];

  return (
    <DashboardLayout title="Rewards & Offers" className="tw-overflow-x-hidden">
      <div className="tw-mb-8">
        <RewardsContextProvider>
          <Tab.Group defaultIndex={referAndEarn ? 3 : defaultIndex}>
            <Tab.List className="tw-py-4">
              {pageTabs.map((tab) => (
                <Tab
                  key={tab}
                  route={tab.route}
                  className={({ selected }) =>
                    cx(
                      'tw-bg-neutral-grey-5 tw-py-2 tw-px-6 tw-border-2 tw-rounded-md tw-inline-flex tw-items-center tw-mr-4 tw-gap-x-2',
                      selected
                        ? 'tw-border-primary-light-green tw-bg-primary-light-green tw-text-primary-dark-green hover:tw-text-primary-dark-green'
                        : 'tw-border-neutral-grey-4'
                    )
                  }
                >
                  {tab.icon}
                  {tab.label}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels>
              {pageTabContent.map((tabContent) => (
                <Tab.Panel key={tabContent.title}>
                  <div className="tw-mt-4">
                    <h2 className="tw-font-semibold">{tabContent.title}</h2>
                    {tabContent.component}
                  </div>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </RewardsContextProvider>
      </div>
    </DashboardLayout>
  );
};
