import { Option } from 'components/UI/SearchDropdown/v2/SearchDropdown.types';
import { TEAM_MEMBER_STATUS } from 'constants/index';
import { InternalContact } from 'types/user';

export const getMemberOptions = (members: InternalContact[], me: InternalContact | null): Option[] => {
  const filteredMembers = members.filter(
    (member) =>
      member.status === TEAM_MEMBER_STATUS.active &&
      member.role !== 'read_only' &&
      member.role !== 'bookkeeper' &&
      member.id !== me?.id
  );

  return filteredMembers.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};

export const mapMerchantCatagoriesToOptions = (categories: string[]): Option[] =>
  categories.map((value) => ({
    value,
    label: value,
  }));
