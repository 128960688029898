import React from 'react';
import { BsCalendarWeek } from 'react-icons/bs';
import { HiOutlineArrowDownTray } from 'react-icons/hi2';

import { TableRowProps } from './TableRow.types';
import { PaymentRequestStatusBadge } from '..';
import { useTableRow } from './hooks';

const TableRow = ({ paymentRequest, openInfoModal, openDeleteModal }: TableRowProps) => {
  const {
    payorName,
    accountName,
    formattedAmount,
    currency,
    formattedInitDate,
    formattedDueDate,
    status,
    frequencyType,
    isOngoing,
    isDeletable,
  } = useTableRow({ paymentRequest });

  return (
    <>
      <tr className="tw-border-b tw-border-neutral-grey-5">
        <td className="tw-px-3 tw-py-4 tw-text-sm ">{payorName}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm">{accountName}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm">{formattedInitDate}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm">{formattedDueDate}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm">{`${formattedAmount} ${currency}`}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm tw-flex tw-gap-2 tw-items-center">
          {isOngoing ? <BsCalendarWeek /> : <HiOutlineArrowDownTray />} {frequencyType}
        </td>
        <td className="tw-px-3 tw-py-4">
          <PaymentRequestStatusBadge status={status} />
        </td>
        <td className="tw-px-3 tw-py-4 tw-text-sm">
          {isDeletable && (
            <button onClick={openDeleteModal} className="tw-text-primary-blue tw-underline">
              Cancel
            </button>
          )}
        </td>
        <td className="tw-px-3 tw-py-4 tw-text-sm">
          <button onClick={openInfoModal} className="tw-text-primary-blue tw-underline">
            View
          </button>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
