import React from 'react';

import { AddSupplierSteps, CRASteps, CreditCardSteps, PayeeType } from '..';
import { PAYEE_TYPES } from 'components/payments/AddPayee/constants';

const { SUPPLIER, CRA, CREDIT_CARD } = PAYEE_TYPES;

const Steps = ({ payeeType, step, setStep, handleNextStep, handlePrevStep, onFinish }) => {
  switch (payeeType) {
    case SUPPLIER:
      return (
        <AddSupplierSteps
          step={step}
          setStep={setStep}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          onFinish={onFinish}
        />
      );
    case CRA:
      return (
        <CRASteps step={step} handlePrevStep={handlePrevStep} handleNextStep={handleNextStep} onFinish={onFinish} />
      );
    case CREDIT_CARD:
      return (
        <CreditCardSteps
          step={step}
          handlePrevStep={handlePrevStep}
          handleNextStep={handleNextStep}
          onFinish={onFinish}
        />
      );
    default:
      return <PayeeType onNextStep={handleNextStep} />;
  }
};

export default Steps;
