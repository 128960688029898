import { useContext } from 'react';

import { AuthContext } from 'context/Auth';

const useHasPermission = ({ scope }) => {
  const { me, meLoading } = useContext(AuthContext);

  const { permissions } = (me && me.internalContact) || { permissions: [] };

  const hasPermission = permissions.includes(scope);

  return { isLoading: meLoading, hasPermission };
};

export default useHasPermission;
