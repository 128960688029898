import React from 'react';

import { Loaders } from 'components/cards/Loader';
import { Error, Success } from './components';
import { ContentProps } from './Content.types';

const Content = ({
  iframeUrl,
  iframeMinHeight = '40rem',
  isLoading,
  isError,
  isCompleted,
  errorTitle = 'Something went wrong',
  errorMessage = 'Can not load the agreement',
  successTitle = 'Agreement signed successfully',
  successMessage = 'You have successfully signed the agreement',
}: ContentProps) => {
  if (isLoading) return <Loaders.Light />;

  if (isCompleted) return <Success title={successTitle} message={successMessage} />;

  if (isError) return <Error title={errorTitle} message={errorMessage} />;

  if (iframeUrl) {
    return <iframe src={iframeUrl} className="tw-w-full tw-h-full" style={{ minHeight: iframeMinHeight }} />;
  }

  return null;
};

export default Content;
