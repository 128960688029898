import React from 'react';

import { Progress } from 'components/UI';
import { useEnableAutopay } from './hooks';
import { Steps } from './components';
import { StepsProps } from './components/Steps/Steps';
import { enableAutopaySteps } from './constants';

const EnableAutopay = ({ onFinish }: Pick<StepsProps, 'onFinish'>) => {
  const { currentStep, onNextStep } = useEnableAutopay();

  return (
    <>
      <Progress currentStepIndex={currentStep} stepLabelsAndIndexes={enableAutopaySteps} />
      <Steps currentStep={currentStep} onNextStep={onNextStep} onFinish={onFinish} />
    </>
  );
};

export default EnableAutopay;
