import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import config from '../config';
import Loader from '../components/cards/Loader';

const LOCAL_STORAGE_KEY = 'pre-approval';

const CallbackContainer = () => {
  const { service, from } = useParams();
  const history = useHistory();

  const isPreApproval = from === 'pre-approval' || window.localStorage.getItem(LOCAL_STORAGE_KEY);

  useEffect(async () => {
    try {
      await axios.get(`${config.coreAppUrl}/auth/${service}/callback${window.location.search}`);
    } catch (err) {
      if (err?.response?.status === 409 && err?.response?.statusText === 'Conflict') {
        toast.error(
          `This sales channel has already been used in another account. Please contact ${config.supportEmail} for assistance.`
        );
      }
      console.error(err);
    }
    if (isPreApproval) {
      history.push('/onboarding/eligibility/start');
    } else {
      history.push('/dashboard/settings/integrations');
    }
  });

  return <Loader />;
};

export default CallbackContainer;
