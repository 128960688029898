import React from 'react';

import styles from './PromoText.module.scss';

const PromoText = () => (
  <div className={styles.promo}>
    <h3 className={styles.promo__header}>
      Create your Loop account in less than <br /> 5 minutes.
    </h3>
    <p className={styles.promo__text}>
      Join thousands of businesses that use Loop to manage corporate cards, payments, FX, capital and more - in multiple
      currencies - all from a single platform.
    </p>
  </div>
);

export default PromoText;
