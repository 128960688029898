import React from 'react';

import InfoTooltip from 'components/InfoTooltip';

const PendingDepositsInfo = () => (
  <InfoTooltip
    message="Deposits typically take up to 4 business days to become available in your account"
    testId="pending-deposits-info"
  />
);

export default PendingDepositsInfo;
