import React from 'react';

const CashOutline = ({ size = 21, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.96875 14.2813L19.0312 14.2812C19.3937 14.2812 19.6875 13.9874 19.6875 13.625V4.4375C19.6875 4.07506 19.3937 3.78125 19.0312 3.78125L1.96875 3.78125C1.60631 3.78125 1.3125 4.07506 1.3125 4.4375V13.625C1.3125 13.9874 1.60631 14.2813 1.96875 14.2813Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path d="M2.625 16.25H18.375" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.9375 18.2188H17.0625" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.5 12.3125C12.3122 12.3125 13.7812 10.8434 13.7812 9.03125C13.7812 7.21907 12.3122 5.75 10.5 5.75C8.68782 5.75 7.21875 7.21907 7.21875 9.03125C7.21875 10.8434 8.68782 12.3125 10.5 12.3125Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CashOutline;
