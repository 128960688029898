import React from 'react';

import CardSelectionBar from 'components/CardMigrationV2/components/CardSelectionBar';

import { CardMigrationOption } from 'components/CardMigrationV2/constants';
import { cardMigrationData } from 'components/CardMigrationV2/components/CardSelectionBar/CardSelectionBar.types';
import migrateNoCardsSrc from 'images/migrationNoCards.png';
import migrateActiveCardsSrc from 'images/migrationActiveCards.png';
import migrateVirtualCardsSrc from 'images/migrationVirtualCards.png';

export const getCardBarData = ({
  numVirtual,
  numActive,
}: {
  numVirtual: number | null;
  numActive: number | null;
}): cardMigrationData[] => {
  return [
    {
      value: CardMigrationOption.allActive,
      title: 'Re-Create All Active Cards',
      isRecommended: true,
      content:
        'Any active virtual or physical cards will be automatically re-created for you. You can specify your physical card delivery settings on the next page.',
      numOfCards: numActive,
      imagePath: migrateActiveCardsSrc,
    },
    {
      value: CardMigrationOption.allVirtual,
      title: 'Re-Create Only Virtual Active Cards',
      content: 'Active virtual cards will be recreated under the new cards program',
      numOfCards: numVirtual,
      imagePath: migrateVirtualCardsSrc,
    },
    {
      value: CardMigrationOption.none,
      title: 'Do Not Migrate Any Cards',
      content: 'No cards will be migrated. Start fresh under the new cards program',
      numOfCards: null,
      imagePath: migrateNoCardsSrc,
    },
  ];
};

export const mapCardMigrationOptions = ({
  numVirtual,
  numActive,
}: {
  numVirtual: number | null;
  numActive: number | null;
}): { label: JSX.Element; value: CardMigrationOption }[] => {
  const mappedOptions: { label: JSX.Element; value: CardMigrationOption }[] = [];
  const cardBarData = getCardBarData({ numVirtual, numActive });

  Object.values(CardMigrationOption).map((val) => {
    const option = cardBarData.find((option) => option.value === val);

    if (option) {
      const optionElement = (
        <CardSelectionBar
          title={option.title}
          isRecommended={option.isRecommended}
          numOfCards={option.numOfCards}
          description={option.content}
          imagePath={option.imagePath}
        />
      );

      mappedOptions.push({ label: optionElement, value: val });
    }
  });

  return mappedOptions;
};
