import React from 'react';

import PayLocBalanceFlow from 'containers/PayLocBalanceFlow';
import Close from 'components/svg/Close';
import Modal from 'components/Modal/v2';

const PayLocBalanceModal = ({ show, setShow }) => {
  const onClose = () => setShow(false);

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <strong>Pay Line of Credit Balance</strong>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-py-4 tw-border-t tw-border-neutral-grey-4">
        <PayLocBalanceFlow onFinish={onClose} finishButtonLabel="Close" footerBorder svgAndTooltip="bottom" isModal />
      </div>
    </Modal>
  );
};

export const Modals = {
  PayLocBalance: PayLocBalanceModal,
};
