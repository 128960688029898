import React, { createContext, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import { PaymentInfo, PayeePayment } from './PayPayee.types';
import { ReimbursementApprovalRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';

type PayPayeeContextType = {
  paymentInfo: PaymentInfo | null;
  setPaymentInfo: React.Dispatch<React.SetStateAction<PaymentInfo | null>>;
  payeePayment: PayeePayment | null;
  setPayeePayment: React.Dispatch<React.SetStateAction<PayeePayment | null>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  resetPayPayeeContext: () => void;
  reimbursementInfo: ReimbursementApprovalRequestDataType | null;
  setReimbursementInfo: React.Dispatch<React.SetStateAction<ReimbursementApprovalRequestDataType | null>>;
};

export const PayPayeeContext = createContext<PayPayeeContextType>({} as PayPayeeContextType);

export const PayPayeeContextProvider = ({
  children,
  initialReimbursementInfo,
}: {
  children: React.ReactNode;
  initialReimbursementInfo?: ReimbursementApprovalRequestDataType;
}) => {
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>(null);
  const [payeePayment, setPayeePayment] = useState<PayeePayment | null>(null);
  const [error, setError] = useState<string>('');
  const [reimbursementInfo, setReimbursementInfo] = useState<ReimbursementApprovalRequestDataType | null>(
    initialReimbursementInfo || null
  );

  const resetPayPayeeContext = useCallback(() => {
    setPaymentInfo(null);
    setPayeePayment(null);
    setError('');
    setReimbursementInfo(null);
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <PayPayeeContext.Provider
      value={{
        paymentInfo,
        setPaymentInfo,
        payeePayment,
        setPayeePayment,
        error,
        setError,
        resetPayPayeeContext,
        reimbursementInfo,
        setReimbursementInfo,
      }}
    >
      {children}
    </PayPayeeContext.Provider>
  );
};
