import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];
const primaryLightGreenColor = theme.colors.primary['light-green'];

type BriefcaseProps = {
  width?: number;
  height?: number;
};

const Briefcase = ({ width = 45, height = 40 }: BriefcaseProps) => (
  <svg width={width} height={height} viewBox="0 0 45 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.6 24.4102V27.5102H18.67V24.4502C11.12 23.4502 4.67 19.6902 1 14.4502V37.6702C1 37.9053 1.0463 38.138 1.1363 38.3552C1.2262 38.5724 1.3581 38.7697 1.5243 38.9359C1.6905 39.1021 1.8878 39.234 2.105 39.3239C2.3222 39.4139 2.5549 39.4602 2.79 39.4602H42.19C42.6647 39.4602 43.12 39.2716 43.4557 38.9359C43.7914 38.6002 43.98 38.1449 43.98 37.6702V14.4102C40.33 19.6302 34.02 23.3802 26.6 24.4102Z"
      fill={primaryLightGreenColor}
      stroke={primaryDarkGreenColor}
      strokeMiterlimit="10"
    />
    <path
      d="M42.07 9.94922H2.91C2.4034 9.94922 1.9176 10.1504 1.5594 10.5086C1.2012 10.8668 1 11.3526 1 11.8592V14.4092C4.69 19.6892 11.12 23.4792 18.67 24.4092V21.9892H26.6V24.4092C34.02 23.4092 40.33 19.6292 43.98 14.4092V11.8592C43.98 11.3526 43.7788 10.8668 43.4206 10.5086C43.0624 10.1504 42.5766 9.94922 42.07 9.94922Z"
      fill={primaryLightGreenColor}
      stroke={primaryDarkGreenColor}
      strokeMiterlimit="10"
    />
    <path
      d="M18.6699 21.9883V24.4483V27.5083H26.5999V24.4083V21.9883H18.6699Z"
      fill={primaryLightGreenColor}
      stroke={primaryDarkGreenColor}
      strokeMiterlimit="10"
    />
    <path
      d="M32.19 1H12.79C12.3886 1 12.0035 1.1588 11.7187 1.4416C11.434 1.7245 11.2727 2.1086 11.27 2.51V9.95H14.01V4.54C14.01 4.304 14.1038 4.0776 14.2707 3.9107C14.4376 3.7438 14.664 3.65 14.9 3.65H30.34C30.5761 3.65 30.8024 3.7438 30.9694 3.9107C31.1363 4.0776 31.23 4.304 31.23 4.54V9.95H33.71V2.51C33.7074 2.1086 33.5461 1.7245 33.2613 1.4416C32.9765 1.1588 32.5914 1 32.19 1Z"
      fill={primaryLightGreenColor}
      stroke={primaryDarkGreenColor}
      strokeWidth="1.06"
      strokeMiterlimit="10"
    />
  </svg>
);

export default Briefcase;
