import React from 'react';

import config from 'config';
import WarningAndStars from 'components/svg/WarningAndStars';

const ErrorState = ({ title = 'Oops! something seems to be wrong' }: { title?: string }) => (
  <div className="tw-m-auto tw-bg-neutral-light tw-py-10 tw-px-6 tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-md">
    <div className="lg:tw-block">
      <WarningAndStars height="200" width="100%" />
    </div>
    <div className="tw-flex tw-flex-col tw-items-center tw-text-center tw-my-5 tw-w-96 tw-px-12">
      <div className="tw-text-2xl tw-my-4">{title}</div>
      <p>
        Please try to refresh the page. If the problem persists, please contact &nbsp;
        <a
          className="tw-font-semibold tw-text-primary-dark-green"
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:${config.supportEmail}`}
        >
          support
        </a>
        .
      </p>
    </div>
  </div>
);

export default ErrorState;
