import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { GET_AUTH_URL } from 'graphql/integrations';
import ConnectShopifyCard from 'components/cards/ConnectShopify';
import ConnectWooCommerceCard from 'components/cards/ConnectWooCommerce';
import ConnectAmazonCard from 'components/integrations/ConnectAmazon';
import Modal from 'components/Modal/v2/index';
import Button from 'components/Button';

const useAuthReconnection = (accountName) => {
  const [getAuthUrl, { loading, data }] = useLazyQuery(GET_AUTH_URL, {
    variables: { integration: accountName === 'Amazon' ? 'amazon' : 'stripe' },
  });

  const handleAuthReconnect = () => {
    if (!loading) getAuthUrl();
  };

  useEffect(() => {
    if (data) {
      const { authUrl } = get(data, 'integrationsAuth') || {};

      if (authUrl) window.location.href = authUrl;
    }
  }, [data]);

  return { handleAuthReconnect, loading };
};

const useModalReconnection = () => {
  const [show, setShow] = useState(false);
  const hideModal = () => setShow(false);
  const showModal = () => setShow(true);

  const getModalComponent = (accountName) => {
    switch (accountName) {
      case 'WooCommerce':
        return (
          <Modal show={show} onClose={hideModal}>
            <ConnectWooCommerceCard onCancel={hideModal} onSuccess={hideModal} layout="V2" />
          </Modal>
        );
      case 'Shopify':
        return (
          <Modal show={show} onClose={hideModal}>
            <ConnectShopifyCard onCancel={hideModal} onSuccess={hideModal} layout="V2" />
          </Modal>
        );
      case 'Amazon':
        return (
          <Modal show={show} onClose={hideModal}>
            <ConnectAmazonCard onCancel={hideModal} onSuccess={hideModal} layout="V2" />
          </Modal>
        );
      case 'Square':
        break;
      default:
        return null;
    }
  };

  return { getModalComponent, showModal };
};

const ReconnectButton = ({ externalAccount }) => {
  const { handleAuthReconnect, loading } = useAuthReconnection(externalAccount.name);
  const { showModal, getModalComponent } = useModalReconnection();

  const handleReconnect = () => {
    if (['Stripe', 'Amazon'].includes(externalAccount.name)) {
      handleAuthReconnect();
    }
    if (['WooCommerce', 'Shopify', 'Square', 'Paypal'].includes(externalAccount.name)) {
      showModal();
    }
  };

  return (
    <>
      <Button onClick={handleReconnect}>
        <span className="tw-text-xs tw-text-semantic-success">{loading ? 'Redirecting...' : 'Re-connect'}</span>
      </Button>
      {getModalComponent(externalAccount.name)}
    </>
  );
};

export default ReconnectButton;
