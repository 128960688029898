import React, { ReactNode } from 'react';
import GreenCheckmark from 'components/svg/GreenCheckmark';

export const AccountDetail = ({ children }: { children: ReactNode }) => {
  return (
    <li className="tw-text-sm tw-flex tw-align-items-center">
      <GreenCheckmark className="tw-flex-shrink-0 tw-mr-2" />
      {children}
    </li>
  );
};
