import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useIsMobile from 'hooks/useIsMobile';
import { Navigation } from './components';
import { NotificationAlertContext } from 'context/NotificationAlert';
import { PopupNotification } from 'components/settings/SettingsLayout';
import OnboardingDashboardLayout from 'components/OnboardingDashboard/components/OnboardingDashboardLayout/OnboardingDashboardLayout';

type SettingsLayoutProps = {
  children?: React.ReactNode;
};

const SettingsLayout = ({ children }: SettingsLayoutProps) => {
  const { notification, setNotification } = useContext(NotificationAlertContext) as unknown as {
    notification: null | string;
    setNotification: (notification: null | string) => void;
  };
  const isMobile = useIsMobile();
  const history = useHistory();

  useEffect(() => {
    const notificationTimer = setTimeout(() => {
      setNotification(null);
    }, 10000);

    return () => {
      clearTimeout(notificationTimer);
    };
  }, [setNotification]);

  if (isMobile) history.push('/onboarding/dashboard/home');

  return (
    <OnboardingDashboardLayout>
      <div className="tw-flex tw-flex-col">
        <PopupNotification notification={notification} />

        <h2 className="tw-mb-5">Settings</h2>
        <span className="tw-text-sm tw-font-medium tw-pb-10 tw-text-neutral-grey-2">
          Here you can view and update your details and manage your login options and passwords.
        </span>
        <div className="tw-flex tw-flex-col tw-mb-5">
          <Navigation />
          <hr className="tw-border-neutral-grey-4 tw-mb-3" />
          {children}
        </div>
      </div>
    </OnboardingDashboardLayout>
  );
};

export default SettingsLayout;
