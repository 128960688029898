import React from 'react';

const LoaderSpinner = () => {
  return (
    <div className="tw-bg-natural-light tw-rounded tw-flex tw-items-center tw-flex-col" data-testid="load">
      <div className="spinner-border spinner-border-sm tw-text-neutral-light" role="status"></div>
    </div>
  );
};

export default LoaderSpinner;
