import React from 'react';
import { FormProvider } from 'react-hook-form';

import { DetailsProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { useDetails } from './hooks';
import { TextField } from 'components/FormFields/v2';
import {
  AmountInput,
  Footer,
  SelectAccountOptions,
  EmptyStateConnectBankAccount,
} from 'components/Accounts/AccountsContent/components/AccountsModal/components';

const Details = ({ onNextStep }: DetailsProps) => {
  const {
    displayName,
    formattedBalance,
    form,
    handleSubmit,
    handleOnNextStep,
    fromAccount,
    register,
    currency,
    formattedAmount,
    setFormattedAmount,
    toOptions,
    noBankAccountConnected,
    formatMoneyV2,
    isError,
    setIsSubmitted,
    errorMessage,
  } = useDetails({ onNextStep });

  if (noBankAccountConnected) return <EmptyStateConnectBankAccount add={false} />;

  return (
    <>
      <div className="tw-px-8">
        <div className="tw-text-xl tw-font-semibold tw-my-6">How much would you like to withdraw?</div>
        <p>
          You are withdrawing funds from your {displayName}. Your available balance is{' '}
          <strong className="tw-underline">{formattedBalance}</strong>.
        </p>
      </div>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(handleOnNextStep)}>
          <div className="tw-px-8 tw-pt-6 tw-pb-4">
            <TextField name="fromAccount" value={fromAccount} ref={register({ required: true })} hidden />
            <AmountInput
              currency={currency}
              formattedAmount={formattedAmount}
              formatMoneyV2={formatMoneyV2}
              setFormattedAmount={setFormattedAmount}
              register={register}
            />
            {!!errorMessage && <div className="tw-text-semantic-error">{errorMessage}</div>}
            <SelectAccountOptions options={toOptions} name="toAccount" label="To Account" register={register} />
          </div>
          <Footer
            submitLabel="Review"
            isDisabled={isError}
            onSubmit={() => {
              setIsSubmitted(true);
            }}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default Details;
