import React, { useState, useContext } from 'react';
import { Loaders } from 'components/cards/Loader';
import { formatDateTime } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';

import { AuthContext } from 'context/Auth';
import PaymentConfirmationModal from 'components/payments/PayeePaymentReview';
import PaymentApprovalStatusBadge from 'components/payments/PaymentApprovalStatusBadge';
import { PayPayeeContext, PayPayeeContextProvider } from 'components/payments/PayPayee/PayPayeeContext';
import Button from 'components/Button';

const ViewApprovedPaymentsContent = ({ loading, payeePayments, props, canApprove }) => {
  const sortPayeePayments =
    payeePayments && payeePayments.slice().sort((a, b) => new Date(b.transactionTime) - new Date(a.transactionTime));
  const sortedPayeePayments = sortPayeePayments.filter(
    (payeePayment) => payeePayment?.approvalStatus !== null && payeePayment?.approvalStatus === 'PENDING_APPROVAL'
  );
  const noPayeePayments = sortedPayeePayments.length === 0;

  const creditCardAccounts = props?.creditCard && props?.creditCard?.accounts;

  const accounts = {
    creditCardAccounts: creditCardAccounts,
    wallets: props?.wallets,
    lineofCredit: props?.lineOfCredit,
    bankAccounts: props?.bankAccounts,
  };

  if (loading) return <Loaders.Light />;

  if (noPayeePayments) return <p className="tw-mt-8">You do not have any pending payments at this time.</p>;

  return (
    <div className="tw-my-8">
      <PayPayeeContextProvider>
        <PaymentsHistoryTable
          sortedPayeePayments={sortedPayeePayments}
          accounts={accounts}
          props={props}
          canApprove={canApprove}
        />
      </PayPayeeContextProvider>
    </div>
  );
};

const PaymentsHistoryTable = ({ sortedPayeePayments, accounts, props, canApprove }) => {
  const tableHeaders = ['To', 'From', 'Date Initiated', 'Amount', 'Approval Status', 'Approve/Deny'];
  if (!canApprove) {
    tableHeaders.pop();
  }
  return (
    <table className="tw-table tw-table-auto tw-w-full">
      <thead>
        <tr>
          {tableHeaders.map((tableHeader) => (
            <th key={tableHeader} className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">
              {tableHeader}
            </th>
          ))}
        </tr>
      </thead>
      {sortedPayeePayments.map((payeePayment) => (
        <TableRow
          key={payeePayment.payeePaymentId}
          payeePayment={payeePayment}
          accounts={accounts}
          props={props}
          canApprove={canApprove}
        />
      ))}
    </table>
  );
};

const findFromAccount = (payeePayment, accounts) => {
  const { creditCardAccounts, wallets, lineofCredit, bankAccounts } = accounts;
  const { productId } = payeePayment;
  const creditCardAccount = creditCardAccounts?.find((account) => account?.id.split('/').pop() === productId);
  const wallet = wallets?.find((account) => account?.id.split('/').pop() === productId);
  const bankAccount = bankAccounts?.find((account) => account?.id.split('/').pop() === productId);

  if (creditCardAccount) {
    return creditCardAccount;
  }
  if (wallet) {
    return wallet;
  }
  if (bankAccount) {
    return bankAccount;
  }

  return lineofCredit;
};

const TableRow = ({ payeePayment, accounts, props, canApprove }) => {
  const { me } = useContext(AuthContext);
  const { setPaymentInfo } = useContext(PayPayeeContext);

  if (!me) return null;

  const from = findFromAccount(payeePayment, accounts);

  const payeeName = payeePayment?.payee?.record?.displayName;
  const fromAccount = from?.displayName || accounts?.lineofCredit?.displayName;
  const dateInitiated = formatDateTime(payeePayment?.transactionTime);
  const payment = payeePayment?.payment;
  const amount = formatMoneyV2({ amount: payment?.amount, currency: payment?.currency });
  const currency = payment?.currency;
  const paymentStatus = payeePayment?.approvalStatus;

  const [showPaymentConfirmation, setShowPaymentConfirmation] = useState(false);
  const onOpen = () => {
    const needsConversion = from && payeePayment.payee.record && payeePayment.payee.record.currency !== from.currency;
    setPaymentInfo({
      fromAccount: from,
      toAccount: payeePayment.payee,
      originalAmount: needsConversion ? payeePayment.payment : payeePayment.chargedPayment,
      chargedAmount: payeePayment.chargedPayment,
      reason: payeePayment.reason,
      payeePayment: payeePayment,
      isLoC: from?.id === accounts?.lineofCredit?.id,
      needsConversion: needsConversion,
      creditCard: props?.creditCard,
    });

    setShowPaymentConfirmation(true);
  };

  const { approvalRequiredFromUser, approvalsNeeded, approvalsMade } = payeePayment || {};

  return (
    <>
      <tr>
        <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{payeeName}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{fromAccount}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{dateInitiated}</td>
        <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{`${amount} ${currency}`}</td>
        <td className="tw-px-3 tw-py-4 tw-border-b tw-border-neutral-grey-5">
          <PaymentApprovalStatusBadge
            status={paymentStatus}
            approvalsNeeded={approvalsNeeded}
            approvalsMade={approvalsMade}
          />
        </td>
        {canApprove && (
          <td className="tw-px-3 tw-py-4">
            {approvalRequiredFromUser ? (
              <Button onClick={onOpen} primary>
                Approve/Deny
              </Button>
            ) : (
              '-'
            )}
          </td>
        )}
      </tr>
      <PaymentConfirmationModal
        show={showPaymentConfirmation}
        setShow={setShowPaymentConfirmation}
        payeePayment={payeePayment}
        payment={`${amount} ${currency}`}
        payeeName={payeeName}
        paymentStatus={paymentStatus}
        accounts={accounts}
        refetchPayeePayments={props?.refetchPayeePayments}
      />
    </>
  );
};

export default ViewApprovedPaymentsContent;
