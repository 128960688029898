import React, { useState } from 'react';

export const PreApprovalContext = React.createContext({});

const DEFAULT_STATUS = false;
const LOCAL_STORAGE_KEY = 'preApproved';

export const PreApprovalContextProvider = (props) => {
  const [preApproved, setPreApprovedFn] = useState(() => {
    try {
      const status = window.localStorage.getItem(LOCAL_STORAGE_KEY);

      return status ? JSON.parse(status) : DEFAULT_STATUS;
    } catch (error) {
      console.log(error);

      return DEFAULT_STATUS;
    }
  });

  const setPreApproved = ({ status }) => {
    setPreApprovedFn(status);

    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(status));
  };
  const getPreApproved = () => preApproved;

  const clearPreApproved = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  return (
    <PreApprovalContext.Provider value={{ getPreApproved, setPreApproved, clearPreApproved }}>
      {props.children}
    </PreApprovalContext.Provider>
  );
};
