import { useState, useEffect } from 'react';
import { FlinksEvents } from 'constants/flinks';

function useFlinksIframeEvent() {
  const [flinksIframeData, setFlinksIframeData] = useState<{ step: FlinksEvents } | null>(null);
  useEffect(() => {
    // https://docs.flinks.com/docs/events
    const handleFlinksIframeEvent = (event: MessageEvent) => {
      if (event?.data) setFlinksIframeData(event.data);
    };

    window.addEventListener('message', handleFlinksIframeEvent);
    return () => window.removeEventListener('message', handleFlinksIframeEvent);
  }, []);

  const isSessionExpired =
    flinksIframeData?.step === FlinksEvents.SESSION_NONEXISTENT ||
    flinksIframeData?.step === FlinksEvents.SESSION_EXPIRED;
  const isInstitutionInvalid = flinksIframeData?.step === FlinksEvents.INVALID_INSTITUTION;
  const isInstitutionNotAvailable = flinksIframeData?.step === FlinksEvents.INSTITUTION_NOT_AVAILABLE;
  const isAppOffline = flinksIframeData?.step === FlinksEvents.APP_OFFLINE;
  const isMaximumRetryReached = flinksIframeData?.step == FlinksEvents.MAXIMUM_RETRY_REACHED;
  const isComponentDenyConsent = flinksIframeData?.step == FlinksEvents.COMPONENT_DENY_CONSENT;
  const isComponentDenyTerms = flinksIframeData?.step == FlinksEvents.COMPONENT_DENY_TERMS;
  const isDisabledInstitution = flinksIframeData?.step == FlinksEvents.DISABLED_INSTITUTION;

  return {
    isSessionExpired,
    isInstitutionInvalid,
    isInstitutionNotAvailable,
    isAppOffline,
    isMaximumRetryReached,
    isComponentDenyConsent,
    isComponentDenyTerms,
    isDisabledInstitution,
  };
}

export default useFlinksIframeEvent;
