import React from 'react';
import { RequestDetailsProps } from './RequestDetails.types';

const RequestDetails = ({ label, value }: RequestDetailsProps) => {
  return (
    <div>
      <div className="tw-text-sm tw-text-neutral-grey-1">{label}</div>
      <p className="tw-mt-1">{value ?? '-'}</p>
    </div>
  );
};

export default RequestDetails;
