import React from 'react';
import { Transition, Listbox } from '@headlessui/react';
import { useQuery } from '@apollo/client';
import { BsChevronDown } from 'react-icons/bs';

import { GET_TRANSACTION_CATEGORIES } from 'graphql/transactionCategories';
import { CATEGORIES_ICONS } from 'constants/categories';
import { Loaders } from 'components/cards/Loader';

const CategoriesDropdown = ({ label, selectedValues, setSelectedValues, className, testId }) => {
  const { data, loading, error } = useQuery(GET_TRANSACTION_CATEGORIES);
  const { transactionCategories } = data || { transactionCategories: [] };

  const handleSelect = (option) => {
    const optionIndex = selectedValues.indexOf(option);
    if (optionIndex === -1) {
      setSelectedValues([...selectedValues, option]);
    } else {
      setSelectedValues([...selectedValues.slice(0, optionIndex), ...selectedValues.slice(optionIndex + 1)]);
    }
  };

  if (loading || !transactionCategories) return <Loaders.Spinner />;
  if (error) {
    console.error(error);
    return 'Error...';
  }

  return (
    <Listbox value={selectedValues} onChange={handleSelect}>
      <div className={`tw-relative tw-p-2 ${className}`}>
        <Listbox.Button data-testid={testId} className="tw-flex tw-items-center tw-cursor-pointer tw-p-2">
          <div className="tw-text-sm tw-mr-2">{`${label}${
            selectedValues?.length ? ` (${selectedValues.length})` : ''
          }`}</div>
          <BsChevronDown size={14} className="tw-text-neutral-grey-2" />
        </Listbox.Button>
        <Transition
          unmount={false}
          as={React.Fragment}
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Listbox.Options className="tw-absolute tw-z-40 tw-w-52 tw-h-96 tw-overflow-y-scroll  tw-scrollbar-hide tw-py-1 tw-overflow-auto tw-bg-neutral-light tw-rounded-md tw-shadow-notification">
            {transactionCategories.map((category) => {
              const isSelected = selectedValues.some((value) => value.description === category.description);
              const onCheck = () => handleSelect(category);

              return (
                <Listbox.Option
                  key={category.transactionCategoryId}
                  value={category}
                  // when cato confirms,  hover:tw-text-neutral-light hover:tw-bg-primary-dark-green ${isSelected && 'tw-text-primary-dark-green tw-bg-primary-light-green'
                  className={`tw-flex tw-items-center tw-justify-start tw-p-2 tw-cursor-pointer
                  }`}
                  data-testid={`filter-currency-${category.description.toLowerCase()}-category`}
                >
                  <input className="tw-mr-2" checked={isSelected} onChange={onCheck} type="checkbox" />
                  <div className="tw-mx-1">{CATEGORIES_ICONS[category.transactionCategoryId]}</div>

                  <div className="tw-text-sm">{category.description}</div>
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default CategoriesDropdown;
