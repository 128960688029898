import React from 'react';

import { CurrencyBoxProps } from './CurrencyBox.types';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag/MobileCurrencyFlag';

const CurrencyBox = ({ currency, currencyLabel, onClick }: CurrencyBoxProps) => (
  <div
    onClick={onClick}
    className="tw-border tw-border-primary-dark-green tw-rounded tw-bg-neutral-grey-5 tw-flex tw-flex-col tw-items-center tw-py-3 tw-w-1/4 tw-cursor-pointer hover:tw-shadow-notification"
  >
    <div className="tw-max-w-max tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5">
      <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
        <MobileCurrencyFlag currency={currency} size={40} />
      </div>
    </div>
    <div className="tw-text-xs tw-text-center tw-mt-2">{currencyLabel}</div>
  </div>
);

export default CurrencyBox;
