import React from 'react';
import cx from 'classnames';

import { CardImageProps } from './CardImage.types';
import { CreditCardStatus } from 'types/creditCard';
import PhysicalCardClean from 'components/svg/PhysicalCardClean';
import RequireActivation from 'components/svg/RequireActivation';
import AdminSuspended from 'components/svg/AdminSuspended';
import DeactivatedCard from 'components/svg/DeactivatedCard';

import creditCardVirtualSrc from 'images/creditCardVirtual.png';
import atlasCardSrc from 'images/AtlasCardMock.png';
import Frozen from 'components/svg/Frozen';

import visaCardVirtualSrc from 'images/cardVirtual.png';
import visaCardPhysicalSrc from 'images/cardPhysical.png';

import virtualCardIconSrc from 'images/cardIcons/cardVirtual.png';
import physicalCardIconSrc from 'images/cardIcons/cardPhysical.png';
import inactiveCardIconSrc from 'images/cardIcons/cardInactive.png';
import oldVirtualCardIconSrc from 'images/cardIcons/oldCardVirtual.png';
import oldPhysicalCardIconSrc from 'images/cardIcons/oldCardPhysical.png';
import oldInactiveCardIconSrc from 'images/cardIcons/oldCardInactive.png';

const getCardComponent = ({ isVirtual, isAtlas, isActive, isVisa }: CardImageProps) => {
  const style = cx('tw-w-full tw-h-full tw-object-contain');
  switch (true) {
    case isVisa && !isVirtual && isActive:
      return <img src={visaCardPhysicalSrc} alt="Credit card" className={style} />;
    case isVisa:
      return <img src={visaCardVirtualSrc} alt="Credit card" className={style} />;
    case !isVirtual && isActive:
      return <PhysicalCardClean className={style} width="100%" height="100%" />;
    case isAtlas:
      return <img src={atlasCardSrc} alt="Credit card" className={style} />;
    default:
      return <img src={creditCardVirtualSrc} alt="Credit card" className={style} />;
  }
};

const getCardStatusIcon = ({ status, dim = 25 }: { status?: CreditCardStatus; dim?: number }) => {
  switch (status) {
    case CreditCardStatus.inactive:
      return <RequireActivation height={dim} width={dim} />;
    case CreditCardStatus.suspended:
      return <Frozen height={dim} width={dim} />;
    case CreditCardStatus.admin_suspended:
      return <AdminSuspended height={dim} width={dim} />;
    case CreditCardStatus.lost:
    case CreditCardStatus.stolen:
    case CreditCardStatus.fraudulent:
    case CreditCardStatus.damaged:
    case CreditCardStatus.block:
      return <DeactivatedCard height={dim} width={dim} />;
    default:
      return null;
  }
};

const getCardIconImage = ({ isVirtual, isActive, isVisa }: CardImageProps) => {
  const style = cx('tw-w-full tw-h-full tw-object-contain');
  switch (true) {
    case isVisa && !isActive:
      return <img src={inactiveCardIconSrc} alt="Credit card" className={style} />;
    case isVisa && !isVirtual:
      return <img src={physicalCardIconSrc} alt="Credit card" className={style} />;
    case isVisa:
      return <img src={virtualCardIconSrc} alt="Credit card" className={style} />;
    case !isActive:
      return <img src={oldInactiveCardIconSrc} alt="Credit card" className={style} />;
    case !isVirtual:
      return <img src={oldPhysicalCardIconSrc} alt="Credit card" className={style} />;
    default:
      return <img src={oldVirtualCardIconSrc} alt="Credit card" className={style} />;
  }
};

const CardImage = ({ isActive, isVirtual, isVisa, isAtlas, isIcon, status }: CardImageProps) => {
  return (
    <div className={cx('tw-w-full tw-h-full tw-relative')}>
      {!isActive && !isIcon && (
        <div className={cx('tw-w-full tw-h-full tw-absolute tw-mix-blend-hue tw-bg-neutral-black')} />
      )}
      {isIcon
        ? getCardIconImage({ isVirtual, isActive, isVisa })
        : getCardComponent({ isVirtual, isAtlas, isVisa, isActive })}

      {isIcon && (
        <div className="tw-absolute tw-inset-0 tw-flex tw-justify-center tw-items-center tw-z-10">
          {getCardStatusIcon({ status })}
        </div>
      )}
    </div>
  );
};

export default CardImage;
