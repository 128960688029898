import React from 'react';

const SignOut = (props) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.25 16.25V18.125C14.25 18.6223 14.0525 19.0992 13.7008 19.4508C13.3492 19.8025 12.8723 20 12.375 20H4.875C4.37772 20 3.90081 19.8025 3.54917 19.4508C3.19754 19.0992 3 18.6223 3 18.125V6.875C3 6.37772 3.19754 5.90081 3.54917 5.54917C3.90081 5.19754 4.37772 5 4.875 5H12C13.0355 5 14.25 5.83953 14.25 6.875V8.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.25 16.25L21 12.5L17.25 8.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.25 12.5H20.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SignOut;
