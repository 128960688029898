export const radioFieldOptionsUK = [
  {
    label: 'Local',
    value: 'uk_faster_payments',
    defaultChecked: true,
  },
  {
    label: 'International',
    value: 'swift_payment',
  },
];

export const radioFieldOptionsCAD = [
  {
    label: 'Local',
    value: 'eft_payment',
    defaultChecked: true,
  },
];

export const radioFieldOptionsEUR = [
  {
    label: 'Local',
    value: 'sepa_payment',
    defaultChecked: true,
  },
  {
    label: 'International',
    value: 'swift_payment',
  },
];

export const accountDetailTypes = {
  UK_FASTER_PAYMENTS: 'uk_faster_payments',
  SWIFT_PAYMENT: 'swift_payment',
  SEPA_PAYMENT: 'sepa_payment',
};
