import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { useQuery, useApolloClient } from '@apollo/client';
import amplitude from 'amplitude-js';
import { toast } from 'react-toastify';

import { GET_ME } from '../graphql/user';
import history from '../history';
import { authChallenges } from '../constants';
import { checkFBStatus } from '../facebook';
import { getCookie, setCookie } from 'utility/cookies';

const AuthContext = React.createContext({
  isSigningIn: true,
  signIn: null,
  signOut: null,
});

const IMPERSONATE_USER_KEY = 'impersonateSession';

const AuthContextProvider = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [signInError, setSignInError] = useState(null);
  const [smsCodeRequested, setSMSCodeRequested] = useState(false);
  const [mfaCodeRequested, setMFACodeRequested] = useState(false);
  const [newPasswordRequested, setNewPasswordRequested] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [submittingNewPassword, setSubmittingNewPassword] = useState(false);
  const [smsCodeVerifying, setSMSCodeVerifying] = useState(false);
  const [smsCodeRequestedNumber, setSMSCodeRequestedNumber] = useState(null);
  const [smsCodeError, setSMSCodeError] = useState(false);
  const [user, setUser] = useState(null);
  const [isImpersonating, setIsImpersonating] = useState(!!getCookie(IMPERSONATE_USER_KEY));

  const client = useApolloClient();

  const { loading: meLoading, data, refetch: meRefetch } = useQuery(GET_ME);

  const me = data?.me;
  const { id, contactId } = me || {};

  // Temporary save user creds to resend MFA code
  // https://github.com/aws-amplify/amplify-js/issues/6676
  // Should remove this once amplify provide custom method to send sms code
  // without calling signin
  const [userCreds, setUserCreds] = useState(null);
  const { email: userCredsEmail, password: userCredsPassword } = userCreds || {};

  useEffect(() => {
    (async () => {
      let session;
      try {
        session = await Auth.currentSession();
      } catch (err) {
        console.log(err);
      }

      if (session) setIsSignedIn(true);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!id) return;

    amplitude.getInstance().setUserId(String(id));
  }, [id]);

  useEffect(() => {
    // Create and dispatch custom event for Embedded Messaging scripts in index.html
    const userLoggedInEvent = new CustomEvent('userSessionChanged', { detail: { contactId } });
    window.dispatchEvent(userLoggedInEvent);
  }, [isSignedIn, contactId]);

  const confirmSignIn = useCallback(
    async (code) => {
      setSMSCodeError(false);
      setSMSCodeVerifying(true);
      try {
        await Auth.confirmSignIn(user, code, user.challengeName);

        setIsSignedIn(true);
        setSMSCodeRequested(false);
        setMFACodeRequested(false);
        setSMSCodeError(false);
        setUserCreds(null);
        checkFBStatus();

        await meRefetch();
      } catch (err) {
        toast.error(err?.message);
        setSMSCodeError(true);
      }
      setSMSCodeVerifying(false);
    },
    [user, meRefetch]
  );

  const signIn = useCallback(
    async (email, password) => {
      setIsSigningIn(true);
      setIsSignedIn(false);
      setSignInError(null);
      setSMSCodeError(false);
      setNewPasswordRequested(false);

      try {
        const normalizedEmail = email?.toLowerCase()?.trim();
        const user = await Auth.signIn(normalizedEmail, password);

        setUser(user);
        const { challengeName, challengeParam } = user || {};
        const { SMS_MFA, SOFTWARE_TOKEN_MFA, NEW_PASSWORD_REQUIRED } = authChallenges;

        switch (challengeName) {
          case SMS_MFA: {
            setSMSCodeRequested(true);
            setSMSCodeRequestedNumber(challengeParam.CODE_DELIVERY_DESTINATION);
            setUserCreds({ email, password }); // Temporary save creds to trigger resending sms code
            break;
          }
          case SOFTWARE_TOKEN_MFA: {
            setSMSCodeRequested(true);
            setMFACodeRequested(true);
            break;
          }
          case NEW_PASSWORD_REQUIRED: {
            setNewPasswordRequested(true);
            break;
          }
          default: {
            await meRefetch();
            setIsSignedIn(true);
            checkFBStatus();
          }
        }
      } catch (err) {
        const message = err?.code == 'InvalidParameterException' ? 'Incorrect username or password' : err?.message;
        setSignInError(err && message);
        toast.error(message);
      }

      setIsSigningIn(false);
    },
    [meRefetch]
  );

  const impersonateUser = useCallback(async (data) => {
    const { cognito_id: cognitoId, host, token, protocol } = data || {};
    Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });
    try {
      const user = await Auth.signIn(cognitoId);
      setUser(user);

      await Auth.sendCustomChallengeAnswer(user, token, { serverUrl: protocol + host });
      setIsSignedIn(true);
      setIsImpersonating(true);
      setCookie(IMPERSONATE_USER_KEY, true);
    } catch (err) {
      history.push('/');
      console.log('error impersonate user: ', err);
    }
  }, []);

  const completeNewPassword = useCallback(
    async (password) => {
      setNewPasswordError(null);
      setSubmittingNewPassword(true);

      try {
        await Auth.completeNewPassword(user, password);

        setNewPasswordError(null);
        setIsSignedIn(true);
        setNewPasswordRequested(false);
        checkFBStatus();

        await meRefetch();
      } catch (err) {
        setNewPasswordError(err.message);
      }
      setSubmittingNewPassword(false);
    },
    [user, meRefetch]
  );

  const signOut = useCallback(async () => {
    try {
      await client.clearStore();
      await Auth.signOut();
      setIsSignedIn(false);
      setUser(null);
    } catch (err) {
      console.log('error signing out: ', err);
    }
  }, [client]);

  const resendVerificationCode = useCallback(async () => {
    await signIn(userCredsEmail, userCredsPassword);
  }, [signIn, userCredsEmail, userCredsPassword]);

  const setupTOTP = useCallback(async () => {
    try {
      const signedUser = await Auth.currentAuthenticatedUser();
      const code = await Auth.setupTOTP(signedUser);
      return code;
    } catch (err) {
      console.log('error setup TOTP: ', err);
    }
  }, []);

  const verifyTOTP = useCallback(async (challengeAnswer) => {
    try {
      const signedUser = await Auth.currentAuthenticatedUser();
      const verified = await Auth.verifyTotpToken(signedUser, challengeAnswer);
      if (verified.Status === 'SUCCESS') {
        await Auth.setPreferredMFA(signedUser, 'TOTP');
        return true;
      }
      return false;
    } catch (err) {
      console.log('error verify TOTP: ', err);
      return false;
    }
  }, []);

  const refreshSessionTokens = useCallback(async () => {
    try {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
    } catch (error) {
      console.error('Failed to refresh user session', error);
    }
  }, []);

  const value = useMemo(
    () => ({
      isImpersonating,
      isSigningIn,
      signIn,
      signOut,
      isSignedIn,
      isLoading,
      signInError,
      smsCodeRequested,
      smsCodeRequestedNumber,
      confirmSignIn,
      smsCodeError,
      smsCodeVerifying,
      newPasswordRequested,
      submittingNewPassword,
      newPasswordError,
      completeNewPassword,
      resendVerificationCode,
      impersonateUser,
      me,
      meLoading,
      meRefetch,
      setupTOTP,
      verifyTOTP,
      mfaCodeRequested,
      refreshSessionTokens,
    }),
    [
      isImpersonating,
      isSigningIn,
      signIn,
      signOut,
      isSignedIn,
      isLoading,
      signInError,
      smsCodeRequested,
      smsCodeRequestedNumber,
      confirmSignIn,
      smsCodeError,
      smsCodeVerifying,
      newPasswordRequested,
      submittingNewPassword,
      newPasswordError,
      completeNewPassword,
      resendVerificationCode,
      impersonateUser,
      me,
      meLoading,
      meRefetch,
      setupTOTP,
      verifyTOTP,
      mfaCodeRequested,
      refreshSessionTokens,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
