import React, { useState } from 'react';

import { Loaders } from 'components/cards/Loader';
import Button from 'components/Button';
import { PayPayeeModal } from 'components/payments/desktop/payees/PayeesList';

const TopThreePayees = ({ payeePayments, payees, lineOfCredit, wallets = [], loadingProducts, loading }) => {
  const payeeOccurrence = [];
  for (const payment of payeePayments) {
    payeeOccurrence[payment.payee.record.displayName] = (payeeOccurrence[payment.payee.record.displayName] || 0) + 1;
  }

  const payeeOccurrences = Object.entries(payeeOccurrence)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 3);

  const topPayees = payeeOccurrences.map((payeeOccurrence) => {
    return payeeOccurrence[0];
  });

  const quickSendPayees = payees.filter((payee) => topPayees.find((p) => p === payee.record.displayName));

  const [showPayPayeeModal, setShowPayPayeeModal] = useState(false);
  const [payeeId, setPayeeId] = useState();
  const onShowPayPayeeModal = (id) => {
    setPayeeId(id);
    setShowPayPayeeModal(true);
  };
  const onClosePayPayeeModal = () => setShowPayPayeeModal(false);

  if (loading || loadingProducts) {
    return (
      <div className="tw-pl-4 tw-pt-4">
        <Loaders.Spinner />
      </div>
    );
  }

  return (
    <div className="tw-mt-2">
      {quickSendPayees.length > 0 ? (
        quickSendPayees.map((payee) => {
          return (
            <Button
              key={payee.id}
              className="tw-text-sm tw-text-primary-dark-green tw-block"
              onClick={() => onShowPayPayeeModal(payee.id)}
            >
              {payee.record.displayName}
            </Button>
          );
        })
      ) : (
        <Button className="tw-text-sm tw-font-bold tw-text-primary-dark-green">Make a Payment</Button>
      )}
      <PayPayeeModal
        // TODO: If Bank Account support is ever added in the future, MAKE SURE it's being passed with a country attribute for filters
        wallets={wallets}
        lineOfCredit={lineOfCredit}
        payees={quickSendPayees}
        show={showPayPayeeModal}
        onClose={onClosePayPayeeModal}
        initialPayeeId={payeeId}
      />
    </div>
  );
};

export default TopThreePayees;
