import React from 'react';

import LoopLogo from 'components/svg/LoopLogo';

const Header = () => (
  <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full tw-py-4 md:tw-py-8 tw-px-8 md:tw-px-12 tw-border-b tw-border-neutral-grey-4 tw-bg-neutral-light">
    <LoopLogo />
  </div>
);

export default Header;
