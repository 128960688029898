import React, { useState, useEffect } from 'react';
import { useQuery, NetworkStatus } from '@apollo/client';
import { get } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';

import config from 'config';
import AdditionalDetails from 'components/onboarding/additionalDetails';
import { GET_KYC_AND_CREDIT_ASSESSMENT_STATUS } from 'graphql/onboarding';
import { documentCategories } from 'constants/index';
import { DOCUMENT_CATEGORY_VALUES } from 'components/onboarding/additionalDetails/constants';
import { useGetUserInfo } from 'hooks';
import { useGetCreditAssessmentStatus } from 'containers/onboarding/WaitingRoom';
import useHome from 'components/OnboardingDashboard/components/Home/hooks/';

const { OTHER_INVOICES, PHOTO_IDS, OTHERS } = DOCUMENT_CATEGORY_VALUES;

const AdditionalDetailsContainer = () => {
  const { loading, data, refetch, networkStatus } = useQuery(GET_KYC_AND_CREDIT_ASSESSMENT_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const { isMissingContactDetails, loading: isKYCAssessmentLoading } = useGetCreditAssessmentStatus();
  const [isInitialLoad, setIsInitialLoad] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const bypassContactDetailsStep = location?.state?.bypassContactDetailsStep;

  useEffect(() => {
    if (!isKYCAssessmentLoading) setIsInitialLoad(true);
  }, [isKYCAssessmentLoading]);

  useEffect(() => {
    if (!bypassContactDetailsStep && isMissingContactDetails && isInitialLoad) {
      history.replace('/onboarding/contact-details');
    }
  }, [isMissingContactDetails, isInitialLoad, bypassContactDetailsStep]);

  const isRefetching = networkStatus === NetworkStatus.refetch;
  const status = get(data, 'me.account.kycAssessment.status');

  const { isMissingDocuments } = useHome();
  const { kycFailedReasons } = useGetUserInfo();

  const reasons = kycFailedReasons?.length ? kycFailedReasons : [OTHERS];

  const contacts = get(data, 'me.account.contacts', []);
  const contactsRequireDocument = get(data, 'me.account.contactsRequireDocument');
  let contactsNeedConsent = get(data, 'me.account.contactsNeedConsent', []);
  const corporateShareholdersNeedConsent = get(data, 'me.account.corporateShareholdersNeedConsent', []);
  const legalBusinessName = get(data, 'me.account.name', '');

  // TODO: Remove the following filtering after https://getloop.atlassian.net/browse/LBP-2314 will be completed
  if (config.env === 'testing' && contactsNeedConsent.length > 1) {
    contactsNeedConsent = contactsNeedConsent.filter(
      ({ firstName, lastName }) => !(firstName === 'John' && lastName === 'Doe')
    );
  }

  const businessAddress = get(data, 'me.account.address', {});
  const categories = documentCategories.filter((item) => reasons.includes(item.value));
  const documentsNeeded = categories.reduce((acc, category) => {
    switch (category.value) {
      case OTHER_INVOICES: {
        const { unitNumber, street, city, countrySubdivision } = businessAddress;
        category.additionalValue = `${unitNumber ? unitNumber : ''} ${street} ${city} ${countrySubdivision}`;
        acc.push(category);
        break;
      }

      case PHOTO_IDS: {
        const currentContacts = contactsRequireDocument?.length ? contactsRequireDocument : contacts;

        currentContacts?.forEach((contact) => {
          const additionalValue = [contact.firstName, contact.lastName].join(' ');
          acc.push({
            name: category.name,
            value: category.value,
            additionalValue,
            contactId: contact.id,
            key: `${category.key}+${contact.id}`,
          });
        });

        break;
      }

      case OTHERS: {
        category.additionalValue = legalBusinessName;
        acc.push(category);
        break;
      }

      default:
        acc.push(category);
    }

    return acc;
  }, []);

  return (
    <AdditionalDetails
      loading={loading || !isInitialLoad}
      isRefetching={isRefetching}
      refetch={refetch}
      status={status}
      categories={documentsNeeded}
      contactsNeedConsent={contactsNeedConsent}
      corporateShareholdersNeedConsent={corporateShareholdersNeedConsent}
      isMissingDocuments={isMissingDocuments}
    />
  );
};

export default AdditionalDetailsContainer;
