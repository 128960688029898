import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Transition } from '@headlessui/react';
import { IoIosTimer } from 'react-icons/io';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import CardActionButton from '../CardActionButton';
import { useToggle } from 'hooks';
import { GET_CARD_LIMIT_SETTINGS } from 'graphql/cards';
import { CreditCard, CreditCardLimitSettings } from 'types/creditCard';
import CreditCardLimitSettingsContent from '../../CreditCardLimitSettingsContent';

const ToggleUpdateCardLimitSettings = ({ creditCard }: { creditCard: CreditCard }) => {
  const { isOpen: showDetails, toggle: onToggleDetails } = useToggle();

  const [creditCardLimitSettings, setCreditCardLimitSettings] = useState<CreditCardLimitSettings | null>(null);

  const [getCreditCardLimitSettings, { loading, refetch }] = useLazyQuery(GET_CARD_LIMIT_SETTINGS, {
    fetchPolicy: 'network-only',
    variables: { creditCardId: creditCard.id },
    onCompleted: (data) => {
      const settings = get(data, 'creditCard.limitSettings', null);
      if (!settings) {
        toast.update(toast.loading('Load card limit settings...'), {
          render: 'Error trying to load card limit settings.',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
        return;
      }

      const creditCardLimitSettings: CreditCardLimitSettings = {
        cardLimitCents: settings.cardLimitCents,
        expiresMonthly: settings.expiresMonthly,
        expiresAnnually: settings.expiresAnnually,
        currentExpenseCents: settings.currentExpenseCents,
      };
      setCreditCardLimitSettings(creditCardLimitSettings);
    },
  });

  useEffect(() => {
    if (showDetails) {
      getCreditCardLimitSettings();
    }
  }, [showDetails]);

  useEffect(() => {
    refetch();
  }, [creditCardLimitSettings?.expiresMonthly]);

  return (
    <>
      <CardActionButton
        label="Freeze Card Rules"
        Icon={IoIosTimer}
        onClick={onToggleDetails}
        className="tw-bg-secondary-light-blue tw-text-primary-blue"
      />
      <Transition
        as="div"
        className="tw-mt-4 tw-mb-2"
        show={showDetails}
        enter="tw-ease-in-out tw-duration-200 tw-transition-all"
        enterFrom="tw-transform tw-opacity-0"
        enterTo="tw-transform tw-opacity-100"
        leave="tw-ease-in-out tw-duration-200 tw-transition-all"
        leaveFrom="tw-transform tw-opacity-100"
        leaveTo="tw-transform tw-opacity-0"
      >
        <CreditCardLimitSettingsContent
          creditCard={creditCard}
          creditCardLimitSettings={creditCardLimitSettings}
          setCreditCardLimitSettings={setCreditCardLimitSettings}
          loading={loading}
        />
      </Transition>
    </>
  );
};

export default ToggleUpdateCardLimitSettings;
