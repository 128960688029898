import React, { useContext } from 'react';

import { Modal } from 'components/UI';
import { AddPaymentRequestContext } from 'components/Invoices/components/Payors/components/PayorsList/contexts/AddPaymentRequestContext';
import { AddPaymentRequest } from './components';
import { AddPaymentRequestModalProps } from './AddPaymentRequestModal.types';

const AddPaymentRequestModal = ({ show, onClose }: AddPaymentRequestModalProps) => {
  const { setPaymentRequestInfo, setError } = useContext(AddPaymentRequestContext);

  const handleClose = () => {
    setPaymentRequestInfo(null);
    setError(null);
    onClose();
  };

  return (
    <Modal show={show} onClose={handleClose} title="Request Payment">
      <div className="tw-flex tw-flex-col tw-pt-4 tw-border-t tw-border-neutral-grey-4">
        <AddPaymentRequest onFinish={handleClose} />
      </div>
    </Modal>
  );
};

export default AddPaymentRequestModal;
