import { gql } from '@apollo/client';

export const SIGN_PRE_DEPOSIT_AUTHORIZATION = gql`
  mutation SignPreDepositAuthorization(
    $firstName: String!
    $lastName: String!
    $bankAccountId: String!
    $version: String
  ) {
    signPreDepositAuthorization(
      firstName: $firstName
      lastName: $lastName
      bankAccountId: $bankAccountId
      version: $version
    )
  }
`;

export const CONFIRM_BANK_ACCOUNT_EMAIL = gql`
  mutation ConfirmBankAccountEmail($bankAccountId: String!, $email: String!) {
    confirmBankAccountEmail(bankAccountId: $bankAccountId, email: $email)
  }
`;

export const BANK_ACCOUNT_PAD_FRAGMENT = gql`
  fragment BankAccountPad on BankAccount {
    id
    preDepositAuthorization
  }
`;

export const GET_BANK_ACCOUNTS_DETAILS = gql`
  query BankAccountsDetails {
    me {
      ... on User {
        account {
          bankAccounts {
            id
            accountNumber
            institutionName
            currency
            preDepositAuthorization
            institutionNumber
            transitNumber
            country
          }
        }
      }
    }
  }
`;
