import { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import { USER_ROLE_TYPES } from 'constants/index';

const useIsAdmin = () => {
  const { me, meLoading } = useContext(AuthContext);

  const isAdmin = !!(me && [USER_ROLE_TYPES.accountOwner, USER_ROLE_TYPES.admin].includes(me?.internalContact?.role));

  return { isLoading: meLoading, isAdmin };
};

export default useIsAdmin;
