import React from 'react';

import { themeConfig } from 'styles/tailwind/config';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import ArrowRight from 'components/svg/ArrowRight';
import Success from 'components/svg/Success';
import CircularProgress from 'components/CircularProgress';
import Button from 'components/Button';

const EligibilityModal = ({ show, onClose, onSubmit, onAddMoreChannels, progress, hasStatus, isApproved }) => (
  <Modal show={show} onClose={onClose}>
    <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
      <p>{isApproved ? 'Eligible' : 'Connect sales channel'}</p>
      <Close className="tw-cursor-pointer" onClick={onClose} />
    </div>
    {hasStatus && isApproved ? (
      <div className="tw-pt-8 tw-pb-4">
        <div className="tw-px-8 tw-flex tw-flex-col tw-justify-center">
          <Success className="tw-self-center" />
          <h3 className="tw-mt-8">You're eligible!</h3>
          <p className="tw-my-4">
            Connect more sales channels to increase your credit limit. If you've connected all of them, click continue
            to open your account.
          </p>
        </div>
        <div className="tw-flex tw-justify-between tw-items-center tw-px-8 tw-py-4 tw-border-t tw-border-neutral-grey-4">
          <strong onClick={onAddMoreChannels} className="tw-text-primary-dark-green tw-cursor-pointer">
            Connect more channels
          </strong>
          <Button type="submit" primary onClick={onSubmit}>
            <div className="tw-flex tw-justify-center tw-items-center">
              <p className="tw-text-neutral-light tw-mr-2">Continue</p>
              <ArrowRight />
            </div>
          </Button>
        </div>
      </div>
    ) : (
      <div className="tw-px-8 tw-pb-4">
        <div className="tw-pt-8 tw-pb-4 tw-flex tw-justify-center tw-items-center">
          <CircularProgress size={110} value={progress} pathColor={themeConfig.colors.primary['dark-green']} clockWise>
            <strong className="tw-text-primary-dark-green">{`${progress}%`}</strong>
          </CircularProgress>
        </div>
        <h3 className="tw-mt-8">Confirming Eligibility</h3>
        <p className="tw-mt-4">
          This can take up to 1 minute. We're confirming that you have enough revenue to be eligible for the Loop Card.
          Once eligible, we'll use this information to set your credit limit.
        </p>
      </div>
    )}
  </Modal>
);

export default EligibilityModal;
