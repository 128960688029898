import React from 'react';
import { FaFileAlt } from 'react-icons/fa';
import fileSize from 'filesize';

import { documentCategories } from 'constants/index';

const FileItem = (props) => {
  const { file } = props;

  const category = file.documentType ? documentCategories.find((c) => c.value === file.documentType) : '';
  const documentType = category && category.name;

  return (
    <div className="tw-flex tw-my-4 tw-py-2 tw-items-center tw-space-x-2">
      <FaFileAlt size={52} className="tw-text-primary-dark-green" />
      <div className="tw-flex tw-flex-col tw-w-full tw-space-y-1">
        <strong>{`${file.name} ${documentType || ''}`}</strong>
        <div className="tw-w-full tw-bg-neutral-grey-3 tw-h-2 tw-rounded">
          <div
            className="tw-bg-semantic-success tw-h-2 tw-rounded"
            role="progressbar"
            style={{ width: `${file.progress || 1}%` }}
          />
        </div>
        <div className="tw-flex tw-justify-between">
          <small>
            {file.progress === 100
              ? fileSize(file.total || 0)
              : `${fileSize(file.loaded || 0)} of ${fileSize(file.total || 0)}`}
          </small>
          <small className="tw-text-semantic-success">
            {file.progress === 100 ? 'Uploaded' : 'Uploading...'} {Math.round(file.progress) || 0}%
          </small>
        </div>
      </div>
    </div>
  );
};

export default FileItem;
