import React from 'react';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { Currencies } from 'constants/currencies';

const CurrencyBadge = ({ currency }: { currency: Currencies }) => {
  return (
    <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-1">
      <div className="tw-rounded-full tw-bg-neutral-grey-4">
        <div className="tw-rounded-full tw-justify-center tw-items-center tw-bg-neutral-light tw-m-0.5 tw-p-0.5">
          <MobileCurrencyFlag currency={currency} size={16} />
        </div>
      </div>
      <span>{currency}</span>
    </div>
  );
};

export default CurrencyBadge;
