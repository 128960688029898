import React from 'react';

import { CardSelectionBarProps } from './CardSelectionBar.types';

import creditCardPhysicalSrc from 'images/creditCardPhysical.png';

const CardSelectionBar = ({
  title,
  imagePath,
  numOfCards,
  description,
  isRecommended = false,
}: CardSelectionBarProps) => {
  return (
    <div
      className={
        'card-row tw-flex tw-items-center tw-justify-between tw-w-full tw-py-4 tw-border-neutral-grey-4 tw-cursor-pointer'
      }
    >
      {/* image and text container */}
      <div className="image-and-text tw-flex">
        <div className="tw-flex tw-items-center image-container tw-w-28 tw-h-24 tw-flex-none">
          {imagePath ? (
            <img src={imagePath} alt="Card Image" className="tw-w-full tw-h-full tw-object-contain" />
          ) : (
            <img src={creditCardPhysicalSrc} alt="Credit card" className="tw-w-full tw-object-contain" />
          )}
        </div>

        <div className="text-container tw-flex tw-flex-col tw-ml-8 tw-justify-center">
          <span className="title tw-font-bold">
            {title}
            {isRecommended && <span className=" tw-pl-1 tw-text-semantic-success">(Recommended)</span>}
          </span>
          {numOfCards && <span className="num tw-text-sm">{numOfCards} cards</span>}
          <span className="tw-text-sm">{description}</span>
        </div>
      </div>
    </div>
  );
};

export default CardSelectionBar;
