import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * This custom hook is a memorized version of useEffect.
 * It deeply checks the dependencies array to prevent
 * triggering for non-primitives entities
 *
 * @param {Function} fn
 * @param {Array} deps
 */

const useDeepEffect = (fn, deps) => {
  const isFirst = useRef(true);
  const prevDeps = useRef(deps);

  useEffect(() => {
    const isFirstEffect = isFirst.current;
    const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

    isFirst.current = false;
    prevDeps.current = deps;

    if (isFirstEffect || !isSame) {
      return fn();
    }
  }, deps);
};

export default useDeepEffect;
