import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TiArrowRight } from 'react-icons/ti';
import _ from 'lodash';

import styles from './OnboardingSummary.module.scss';
import { AuthContext } from '../../context/Auth';
import { onboardingStatus, userRoles, KYC_STATUS } from '../../constants';

export const onboardingList = {
  [onboardingStatus.BANK_ACCOUNT]: {
    label: 'Connect Your Bank Account Securely to Loop to Continue Your Credit Application',
    link: '/onboarding/connect-bank-account',
  },
  [onboardingStatus.BUSINESS_ADDRESS]: {
    label: 'Provide Your Business Address to Complete Your Business Profile',
    link: '/onboarding/business-address',
  },
  [onboardingStatus.BUSINESS_DETAILS]: {
    label: 'Provide Some Basic Details About Your Business to Complete Your Profile',
    link: '/onboarding/business-details',
  },
  [onboardingStatus.CRA]: {
    label: 'Allow Loop to Verify Your Business Securely Through Your Canada Revenue Agency Account',
    link: '/onboarding/credit-application/cra',
  },
  [onboardingStatus.CREDIT_APPLICATION]: {
    label: 'Provide Some Information About Your Business to Continue Your Credit Application',
    link: '/onboarding/credit-application',
  },
  [onboardingStatus.DETAILS_COMPLETED]: {
    label: 'Submit Your Application',
    link: '/onboarding/credit-application/file-upload',
  },
  [onboardingStatus.DOCUMENTS]: {
    label: 'Submit Your Application',
    link: '/onboarding/credit-application/file-upload',
  },
  [onboardingStatus.INTEGRATIONS]: {
    label: 'Connect Loop to Your eCommerce Platforms to Finalize Your Credit Application',
    link: '/onboarding/eligibility/start',
  },
  [onboardingStatus.OWNER_PROFILE]: {
    label: 'Provide Some Information About Yourself to Allow Loop to Verify You',
    link: '/onboarding/owners-profile',
  },
  [onboardingStatus.SHAREHOLDERS]: {
    label: 'Provide Details About Any Business Associates to Complete Your Business Profile',
    link: '/onboarding/business-contacts',
  },
  [onboardingStatus.BUSINESS_CONTACTS]: {
    label: 'Provide Your Business Contact Details to Complete Your Business Profile',
    link: '/onboarding/business-contacts',
  },
  [onboardingStatus.COMPLETED]: {
    label: 'Complete Your Credit Application With Loop',
    link: '#',
  },
  [onboardingStatus.COMPLETED_PENDING_KYC]: {
    label: 'We are verifying your account',
    link: '/onboarding/waiting-room',
  },
  businessVerification: {
    label: 'Business Verification',
    link: '/onboarding/business-verification',
  },
  identityVerification: {
    label: 'Identity Verification',
    link: '/onboarding/identity-verification',
  },
};

const OnboardingSummary = (props) => {
  const { teamMembersInvited } = props;
  const { me, meLoading } = useContext(AuthContext);
  const { smsMfaEnabled, roles } = me || {};
  if (meLoading) return null;

  const isOwner = roles && roles.includes(userRoles.ACCOUNT_OWNER);
  const isManager = roles && roles.includes(userRoles.ACCOUNT_MANAGER);
  if (!isOwner && !isManager) return null;

  const status = _.get(me, 'account.onboardingStatus');
  const KYCStatus = _.get(me, 'account.kycAssessment.status');

  const isOnboardingCompleted = status === onboardingStatus.COMPLETED;

  const onboardingItem = onboardingList[status];
  const onboardingLink = onboardingItem && onboardingItem.link;
  const onboardingLabel = onboardingItem && onboardingItem.label;

  return (
    <div className="bg-white rounded-xl mb-4">
      <Link
        data-testid="btn-continue-onboarding"
        to={onboardingLink}
        className={styles.item}
        disabled={isOnboardingCompleted}
      >
        <div>
          <div className="fs-1 fwb mb-2">{onboardingLabel}</div>

          <span className={styles.label + (isOnboardingCompleted ? ' bg-success' : ' bg-danger')}>
            {isOnboardingCompleted ? 'COMPLETE' : 'IMPORTANT'}
          </span>
        </div>

        {!isOnboardingCompleted && <TiArrowRight className={styles.btn} size={32} />}
      </Link>
      {KYCStatus === KYC_STATUS.WAITING_FOR_USER_DATA ? (
        <Link to="/onboarding/additional-details" className={styles.item}>
          <div>
            <div className="fs-1 fwb mb-2">We Need Additional Documents to Verify Your Business</div>
            <span className={styles.label + ' bg-danger'}>IMPORTANT</span>
          </div>

          {!smsMfaEnabled && <TiArrowRight className={styles.btn} size={32} />}
        </Link>
      ) : null}

      <Link to="/dashboard/settings/users" className={styles.item} disabled={teamMembersInvited}>
        <div>
          <div className="fs-1 fwb mb-2">Invite Other Team Members To Your Account</div>
          <span className={styles.label + (teamMembersInvited ? ' bg-success' : ' bg-warning')}>
            {teamMembersInvited ? 'COMPLETE' : 'PENDING'}
          </span>
        </div>

        {!teamMembersInvited && <TiArrowRight className={styles.btn} size={32} />}
      </Link>

      <Link to="/dashboard/settings/mfa" className={styles.item} disabled={smsMfaEnabled}>
        <div>
          <div className="fs-1 fwb mb-2">Enable Multi-Factor Authentication</div>
          <span className={styles.label + (smsMfaEnabled ? ' bg-success' : ' bg-warning')}>
            {smsMfaEnabled ? 'COMPLETE' : 'PENDING'}
          </span>
        </div>

        {!smsMfaEnabled && <TiArrowRight className={styles.btn} size={32} />}
      </Link>
    </div>
  );
};

export default OnboardingSummary;
