import React from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import OwnersProfile from 'components/onboarding/OwnersProfile';
import { GET_OWNERS_PROFILE } from '../graphql/onboarding';
import { userRoles, entityTypes } from '../constants';
import useOnboardingRedirect from '../hooks/useOnboardingRedirect';

const OwnersProfileContainer = () => {
  const { loading, data } = useQuery(GET_OWNERS_PROFILE);
  const { loading: isLoadingDetails } = useOnboardingRedirect();
  const contacts = get(data, 'me.account.contacts') || [];
  const { street, unitNumber, city, countrySubdivision, postalCode, country } = get(data, 'me.account.address') || {};
  const accountType = get(data, 'me.account.entityType');

  const isSoleProprietor = accountType === entityTypes.SOLE_PROPRIETORSHIP;
  const accountManager = contacts.find((c) => c.roles.includes(userRoles.ACCOUNT_MANAGER));
  // Is Account Manager also account owner?
  const isAccountOwner = accountManager?.roles?.includes(userRoles.ACCOUNT_OWNER);
  const accountOwner = !isAccountOwner && contacts.find((c) => c.roles.includes(userRoles.ACCOUNT_OWNER));

  return (
    <OwnersProfile
      loading={isLoadingDetails || loading}
      accountManager={accountManager}
      accountOwner={accountOwner}
      isSoleProprietor={isSoleProprietor}
      businessAddress={{ street, unitNumber, city, countrySubdivision, postalCode, country }}
    />
  );
};

export default OwnersProfileContainer;
