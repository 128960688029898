import { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import { formatDateTime } from 'utility/date';
import { User } from 'types/user';
import { CorePADAgreementProps } from '../CorePADAgreement.types';

const useCorePADAgreement = ({ bankAccount }: Pick<CorePADAgreementProps, 'bankAccount'>) => {
  const { me } = useContext(AuthContext) as unknown as { me: User };
  const { firstName, lastName, email } = me || {};

  const today = new Date();
  const formattedTodaysDate = formatDateTime(today);

  const bankName = bankAccount?.institutionName;
  const transitNumber = bankAccount?.transitNumber;
  const institutionNumber = bankAccount?.institutionNumber;
  const accountNumber = bankAccount?.accountNumber;

  const payorDisplayName = `${firstName} ${lastName}`;

  return {
    formattedTodaysDate,
    bankName,
    transitNumber,
    institutionNumber,
    accountNumber,
    payorDisplayName,
    payorLegalName: payorDisplayName,
    payorEmailAddress: email,
  };
};

export default useCorePADAgreement;
