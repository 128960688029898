import React, { useContext } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { IoGiftOutline } from 'react-icons/io5';
import { Link, useHistory } from 'react-router-dom';

import { formatMoney } from 'utility/currency';
import { getAvailableAmount } from 'utility/creditCards';
import { DEFAULT_CARD_DATA } from 'constants/defaultValues';
import CreditCard from 'domain/creditCard';
import CardOutline from 'components/svg/CardOutline';
import Bank from 'components/svg/Bank';
import CoinStack from 'components/svg/CoinStack';
import DocumentTextOutline from 'components/svg/DocumentTextOutline';
import { Loaders } from 'components/cards/Loader';
import CreditCardsModal from 'components/home/mobile/creditCards/CreditCardsModal';
import LineOfCreditModal from 'components/home/mobile/LineOfCreditModal';
import QuickActions from 'components/home/QuickActions';
import DelinquentPaymentBanner from 'components/home/DelinquentPaymentBanner';
import RewardsModal from 'components/home/mobile/rewardsAndOffers/RewardsModal';
import PendingAccountDepositsList from 'components/home/mobile/PendingAccountDepositsList';
import useIsMember from 'hooks/useIsMember';
import useIsAdmin from 'hooks/useIsAdmin';
import useFeatureToggle from 'hooks/useFeatureToggle';
import useGetProductState from 'hooks/useGetProductState';
import { useUnderDevelopmentRoutes, useToggle } from 'hooks';
import { RewardsContext, RewardsContextProvider } from 'components/rewardsAndOffers/contexts/RewardsContext';
import IdentityVerification from 'components/IdentityVerification';
import { MESSAGES } from 'components/IdentityVerification/constants';

const MobileLayout = (props) => {
  const {
    loadingCreditInfo,
    hasLineOfCredit,
    groupedCardsInfo,
    wallets,
    creditCards,
    creditCardIds,
    creditCardLoading,
  } = props;
  const accountNames = (wallets || []).map((wallet) => wallet.displayName);

  const { isMember } = useIsMember();
  const { isAdmin } = useIsAdmin();

  const { isRouteAvailable } = useUnderDevelopmentRoutes();
  const { isExperimentEnabled } = useFeatureToggle();

  if (isMember) {
    return (
      <div className="tw-mt-8">
        <IdentityVerification
          onlyIfRequested
          bannerMessage={MESSAGES.banner.continueUsingAccount}
          modalMessage={MESSAGES.modal.continueUsingAccount}
        />

        <CreditCardsCard
          creditCards={creditCards}
          creditCardIds={creditCardIds}
          groupedCardsInfo={groupedCardsInfo}
          creditCardLoading={creditCardLoading}
          // TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380
          {...props}
        />
      </div>
    );
  }

  return (
    <>
      {loadingCreditInfo ? (
        <Loaders.FullScreen />
      ) : (
        <>
          <div className="tw-px-4">
            <IdentityVerification
              onlyIfRequested
              bannerMessage={MESSAGES.banner.continueUsingAccount}
              modalMessage={MESSAGES.modal.continueUsingAccount}
            />

            <DelinquentPaymentBanner primaryCard={new CreditCard(groupedCardsInfo)} />

            <CreditCardsCard
              creditCards={creditCards}
              creditCardIds={creditCardIds}
              groupedCardsInfo={groupedCardsInfo}
              creditCardLoading={creditCardLoading}
              // TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380
              {...props}
            />

            {hasLineOfCredit && <LineOfCreditCard {...props} />}
            <div className="tw-mt-4">
              <WalletsCard {...props} />
            </div>

            {isRouteAvailable && isExperimentEnabled && (
              <div className="tw-mt-4">
                <InvoicesWrapper />
              </div>
            )}

            <div className="tw-mt-8">
              <small className="tw-text-neutral-grey-1 tw-mb-2">Benefits</small>
              {isAdmin && (
                <RewardsContextProvider>
                  <RewardsCard {...props} />
                </RewardsContextProvider>
              )}
            </div>
          </div>
          <div className="tw-mt-8">
            <PendingAccountDepositsList
              transactions={props.transactions}
              loadingTransactions={props.loadingTransactions}
              accountNames={accountNames}
            />
          </div>
          <div className="tw-bg-neutral-light tw-mt-8 tw-py-4">
            {/* Hiding this for now until we can state what documents to update
            <MyTasks {...props} /> */}
            <QuickActions {...props} />
          </div>
        </>
      )}
    </>
  );
};

const CreditCardsCard = ({
  creditCards,
  creditCardIds,
  groupedCardsInfo,
  creditCardLoading,
  // TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380
  globalCreditLimit,
  connectedBankAccount,
  hasAtLeastOneBankAccountVerified,
  refetchProducts,
  hasLineOfCredit,
}) => {
  const {
    // TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380
    isOpen: showModal,
    // open: onShowModal,
    close: onCloseModal,
  } = useToggle();

  const { isPreFunded } = useGetProductState();

  const history = useHistory();
  const goToCardsSplashPage = () => history.push('/dashboard/cards/splash');

  const { groupAvailableBalance, groupOverlimit } = groupedCardsInfo || DEFAULT_CARD_DATA;
  const cardsDataAvailable = !creditCardLoading && groupedCardsInfo && creditCardIds.length === creditCards.length;

  const amount = getAvailableAmount(groupOverlimit, groupAvailableBalance);

  const { isAdmin } = useIsAdmin();

  return (
    <>
      <button
        className="tw-flex tw-items-center tw-justify-between tw-rounded-md tw-bg-neutral-light tw-p-4 tw-w-full tw-text-left"
        onClick={goToCardsSplashPage}
      >
        <div className="tw-flex tw-justify-start tw-items-center">
          <div className="tw-flex tw-justify-center tw-items-center tw-bg-secondary-light-blue tw-rounded tw-py-0.5 tw-px-1 tw-mr-4">
            <CardOutline className="tw-text-primary-blue tw-h-6 tw-w-21-px" />
          </div>
          <div className="tw-flex tw-flex-col tw-justify-between">
            <strong>Cards ({creditCardIds.length})</strong>
            {isAdmin && cardsDataAvailable && !isPreFunded && (
              <small className="tw-text-neutral-grey-1">
                Available {amount} {groupAvailableBalance.currency}
              </small>
            )}
          </div>
        </div>

        {creditCardLoading ? (
          <div className="tw-bg-neutral-light tw-rounded-md tw-flex tw-items-center tw-flex-col" data-testid="load">
            <Loaders.Spinner />
          </div>
        ) : (
          <BsChevronRight />
        )}
      </button>
      {/* TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380 */}
      <CreditCardsModal
        cardsDataAvailable={cardsDataAvailable}
        creditCardIds={creditCardIds}
        connectedBankAccount={connectedBankAccount}
        hasAtLeastOneBankAccountVerified={hasAtLeastOneBankAccountVerified}
        globalCreditLimit={globalCreditLimit}
        creditCards={creditCards}
        groupedCardsInfo={groupedCardsInfo}
        show={showModal}
        onClose={onCloseModal}
        refetchCreditCards={refetchProducts}
        hasLineOfCredit={hasLineOfCredit}
      />
    </>
  );
};

const LineOfCreditCard = ({ loadingCreditInfo, hasLineOfCredit, lineOfCredit, transactions, loadingTransactions }) => {
  const { isOpen: showModal, open: onShowModal, close: onCloseModal } = useToggle();

  const locTransactions = transactions.filter(
    (transaction) =>
      (transaction.to && transaction.to.includes('Line Of Credit')) ||
      (transaction.from && transaction.from.includes('Line Of Credit'))
  );

  return (
    <>
      <button
        className="tw-flex tw-items-center tw-justify-between tw-rounded-md tw-bg-neutral-light tw-p-4 tw-mt-4 tw-w-full tw-text-left hover:tw-text-neutral-dark"
        onClick={onShowModal}
      >
        <div className="tw-flex tw-justify-start tw-items-center">
          <div className="tw-flex tw-justify-center tw-items-center tw-bg-secondary-light-yellow tw-text-primary-dark-yellow tw-rounded tw-p-1 tw-mr-4">
            <CoinStack width={18} height={17} />
          </div>
          <div className="tw-flex tw-flex-col tw-justify-between">
            <strong>Capital</strong>
            <small className="tw-text-neutral-grey-1">{`Available ${formatMoney(lineOfCredit.availableCredit)} ${
              lineOfCredit.availableCredit.currency
            }`}</small>
          </div>
        </div>
        {loadingCreditInfo ? (
          <div className="tw-bg-neutral-light tw-rounded-md tw-flex tw-items-center tw-flex-col" data-testid="load">
            <Loaders.Spinner />
          </div>
        ) : (
          <BsChevronRight />
        )}
      </button>

      <LineOfCreditModal
        show={showModal}
        onClose={onCloseModal}
        hasLineOfCredit={hasLineOfCredit}
        lineOfCredit={lineOfCredit}
        transactions={locTransactions}
        loadingTransactions={loadingTransactions}
      />
    </>
  );
};

const WalletsCard = ({ walletLoading }) => {
  return (
    <Link
      to="/dashboard/accounts"
      className="tw-flex tw-items-center tw-justify-between tw-rounded-md tw-bg-neutral-light tw-p-4 tw-mt-4 tw-w-full tw-text-left hover:tw-text-neutral-dark"
    >
      <div className="tw-flex tw-justify-start tw-items-center">
        <div className="tw-flex tw-justify-center tw-items-center tw-bg-secondary-light-lilac tw-text-primary-lilac tw-rounded tw-p-1 tw-mr-4">
          <Bank width={17} className="tw-w-21-px" />
        </div>
        <div className="tw-flex tw-flex-col tw-justify-between">
          <strong>Accounts</strong>
        </div>
      </div>
      {walletLoading ? <Loaders.Spinner /> : <BsChevronRight />}
    </Link>
  );
};

const InvoicesWrapper = () => (
  <Link
    to="/dashboard/invoices"
    className="tw-flex tw-items-center tw-justify-between tw-rounded-md tw-bg-neutral-light tw-p-4 tw-mt-4 tw-w-full tw-text-left hover:tw-text-neutral-dark"
  >
    <div className="tw-flex tw-justify-start tw-items-center">
      <div className="tw-flex tw-justify-center tw-items-center tw-bg-secondary-light-blue tw-rounded tw-py-0.5 tw-px-1 tw-mr-4">
        <DocumentTextOutline className="tw-text-primary-blue tw-h-6 tw-w-21-px" />
      </div>
      <div className="tw-flex tw-flex-col tw-justify-between">
        <strong>Request Payments</strong>
      </div>
    </div>
    <BsChevronRight />
  </Link>
);

const RewardsCard = () => {
  const { isOpen: showModal, open: onShowModal, close: onCloseModal } = useToggle();

  const { rewardsAccount, refetch, loadingContext: loadingRewards } = useContext(RewardsContext);

  return (
    <>
      <button
        className="tw-flex tw-items-center tw-justify-between tw-rounded-md tw-bg-neutral-light tw-p-4 tw-mt-4 tw-w-full tw-text-left hover:tw-text-neutral-dark"
        onClick={onShowModal}
      >
        <div className="tw-flex tw-justify-start tw-items-center">
          <div className="tw-flex tw-justify-center tw-items-center tw-bg-secondary-pastel-green-100 tw-rounded tw-py-0.5 tw-px-1 tw-mr-4">
            <IoGiftOutline size={20} className="tw-text-primary-dark-green tw-w-21-px" />
          </div>
          <div className="tw-flex tw-flex-col tw-justify-between">
            <strong>Points and Rewards</strong>
            <small className="tw-text-neutral-grey-1">{`Your Points ${
              (rewardsAccount && rewardsAccount.balance) || 0
            }`}</small>
          </div>
        </div>
        {loadingRewards ? <Loaders.Spinner /> : <BsChevronRight />}
      </button>
      <RewardsModal rewardsAccount={rewardsAccount} show={showModal} onClose={onCloseModal} refetch={refetch} />
    </>
  );
};

export default MobileLayout;
