class ExternalWalletAccount {
  constructor(attributes) {
    const { routingNumber, accountNumber, isVerified, ready, currency, internalId, type, address } = attributes || {};

    this.internalId = internalId;
    this.routingNumber = routingNumber;
    this.accountNumber = accountNumber;
    this.isVerified = isVerified;
    this.ready = ready;
    this.currency = currency;
    this.type = type;
    this.address = address;
  }

  isReady() {
    return this.ready && this.routingNumber;
  }

  toJson() {
    return {
      routingNumber: this.routingNumber,
      accountNumber: this.accountNumber,
      isVerified: this.isVerified,
      ready: this.ready,
      currency: this.currency,
      internalId: this.internalId,
      type: this.type,
      address: this.address,
    };
  }
}

export default ExternalWalletAccount;
