import { memo } from 'react';

import { getTransactionTypeIcon } from 'utility/transactions';

const TransactionType = ({ transaction }) => {
  const Icon = getTransactionTypeIcon(transaction);

  return Icon;
};

export default memo(TransactionType);
