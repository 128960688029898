// TODO: remove this after https://getloop.atlassian.net/browse/LBP-4380

import React, { useState } from 'react';
import { BsChevronDown, BsPlusCircle } from 'react-icons/bs';
import { Collapse } from 'react-collapse';
import { useHistory } from 'react-router-dom';

import { AddFundsToBalanceContextProvider } from 'components/creditCards/context/AddFundsToBalance';
import { balancesOrder } from 'constants/index';
import { formatMoneyV2 } from 'utility/currency';
import InfoTooltip from 'components/InfoTooltip';
import CardBalances from 'components/creditCards/CardBalances';
import Button from 'components/Button';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import useGetProductState from 'hooks/useGetProductState';
import { AddFundsToBalanceModal } from 'components/creditCards/components';
import { useToggle } from 'hooks';

const Balances = ({ groupedCardsInfo }) => {
  const { isLoading: meLoading, isCreditCard } = useGetProductState();

  if (meLoading) return <Skeleton />;

  return (
    <AddFundsToBalanceContextProvider>
      {isCreditCard ? (
        <CreditCardBalances groupedCardsInfo={groupedCardsInfo} />
      ) : (
        <PreFundedCardBalances groupedCardsInfo={groupedCardsInfo} />
      )}
    </AddFundsToBalanceContextProvider>
  );
};

const Skeleton = () => (
  <div data-testid="sidebar-loader" className="tw-mt-8 tw-space-y-4">
    <div className="tw-space-y-4">
      <div className="tw-flex tw-items-center tw-bg-neutral-light tw-h-4 tw-w-32 tw-animate-pulse" />
      <div className="tw-space-y-2">
        {Array(4)
          .fill()
          .map((_, i) => (
            <div key={i} className="tw-rounded-md tw-bg-neutral-light tw-animate-pulse tw-flex tw-h-12 tw-w-full" />
          ))}
      </div>
    </div>
  </div>
);

const CreditCardBalances = ({ groupedCardsInfo }) => {
  const {
    isOpen: isOpenAddFundsToBalanceModal,
    open: openAddFundsToBalanceModal,
    close: closeAddFundsToBalanceModal,
  } = useToggle();

  return (
    <div className="tw-mt-8">
      <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
        <small className="tw-text-neutral-grey-2">Current Balances</small>
        <InfoTooltip
          testId="cards-current-balances"
          message="The Current Balances section shows the current value of your pending and posted transactions on your Loop Card for each currency. You can review the full breakdown of these balances by expanding each currency element."
        />
      </div>
      {balancesOrder.map((currency) => {
        const [isExpanded, setIsExpanded] = useState(false);
        const onToggleExpand = () => setIsExpanded(!isExpanded);

        const balanceForCurrency = (b) => b.currency === currency;
        const defaultAmount = { amount: 0, currency };
        const balance = groupedCardsInfo.groupBalances.find(balanceForCurrency) || defaultAmount;
        const postedTransactionsAmount = groupedCardsInfo.groupPostedBalances.find(balanceForCurrency) || defaultAmount;
        const pendingTransactionsAmount =
          groupedCardsInfo.groupPendingBalances.find(balanceForCurrency) || defaultAmount;
        const minimumPaymentByCurrency = groupedCardsInfo.groupOngoingMinimumAmountDueByCurrency;
        const minimumPayment = minimumPaymentByCurrency[balance.currency] || defaultAmount;
        const endingBalances = groupedCardsInfo.groupOngoingAmountDueByCurrency;
        const amountDue = endingBalances[balance.currency] || defaultAmount;

        return (
          <div key={balance.currency}>
            <div className={`tw-mt-2 ${isExpanded ? 'tw-rounded-t-md' : 'tw-rounded-md'} tw-bg-neutral-light tw-p-4`}>
              <div className="tw-flex tw-items-center tw-justify-between">
                <Balance balance={balance} />
                <div
                  onClick={onToggleExpand}
                  data-testid={`${balance.currency}-balance-expand-toggle`}
                  className="tw-rounded-md tw-flex tw-justify-center tw-items-center tw-bg-neutral-light tw-border tw-border-neutral-grey-4 tw-p-2 tw-cursor-pointer hover:tw-bg-neutral-grey-4"
                >
                  <BsChevronDown
                    className={`tw-transform tw-rotate-${
                      isExpanded ? '180' : '0'
                    } tw-ease-in-out tw-duration-200 tw-transition-all`}
                  />
                </div>
              </div>
            </div>
            <Collapse isOpened={isExpanded}>
              <div className="tw-bg-neutral-light tw-px-4 tw-pb-4 tw-rounded-b-md">
                <CardBalances
                  balance={balance}
                  postedTransactionsAmount={postedTransactionsAmount}
                  pendingTransactionsAmount={pendingTransactionsAmount}
                  minimumPayment={minimumPayment}
                  amountDue={amountDue}
                  openAddFundsToBalanceModal={openAddFundsToBalanceModal}
                />
              </div>
            </Collapse>
          </div>
        );
      })}
      <AddFundsToBalanceModal show={isOpenAddFundsToBalanceModal} onClose={closeAddFundsToBalanceModal} />
    </div>
  );
};

const PreFundedCardBalances = ({ groupedCardsInfo }) => {
  const history = useHistory();
  const onLoadFunds = () => history.push('/dashboard/payments/add-money');

  return (
    <div className="tw-mt-8 tw-space-y-4">
      <div className="tw-flex tw-items-center tw-justify-between">
        <small className="tw-text-neutral-grey-2">Balance Loaded to Loop Card</small>
        <InfoTooltip message="Pre-funded Balances include amounts you have loaded onto your Loop Card for each currency minus any posted transactions or pending transactions." />
      </div>
      <Button primary onClick={onLoadFunds} className="tw-space-x-4 tw-w-full">
        <BsPlusCircle />
        <p className="tw-text-neutral-light">Load Funds</p>
      </Button>
      <div className="tw-space-y-2">
        {balancesOrder.map((currency) => {
          const balance = groupedCardsInfo.groupBalances.find((b) => b.currency === currency) || {
            amount: 0,
            currency,
          };
          const invertedBalance = { amount: -balance.amount, currency: balance.currency };

          return (
            <div key={balance.currency} className="tw-rounded-md tw-bg-neutral-light tw-p-4">
              <div className="tw-flex tw-items-center tw-justify-between">
                <Balance balance={invertedBalance} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Balance = ({ balance }) => (
  <div className="tw-flex tw-items-center">
    <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
      <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
        <MobileCurrencyFlag currency={balance.currency} size={22} />
      </div>
    </div>
    <small>{`${formatMoneyV2(balance)} ${balance.currency}`}</small>
  </div>
);

export default Balances;
