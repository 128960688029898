import React, { useContext } from 'react';

import { AccountsModalProps } from './AccountsModal.types';
import { Modal } from 'components/UI';
import { getAccountsModalTitle } from './AccountsModal.utils';
import { useAccountsModal } from './hooks';
import ConvertFunds from './components/ConvertFunds/ConvertFunds';
import AddFunds from './components/AddFunds';
import PayPayee from './components/PayPayee';
import ViewBankDetails from './components/ViewBankDetails';
import WithdrawFunds from './components/WithdrawFunds';
import DownloadStatements from './components/DownloadStatements';
import { FundsContext } from '../../contexts/FundsContext';

const AccountsModal = ({ show, onClose, title }: AccountsModalProps) => {
  const { resetFundsContext } = useContext(FundsContext);
  const { refetchWalletTransactions } = useAccountsModal();

  const handleOnClose = () => {
    if (title === 'Pay Payee') refetchWalletTransactions();

    resetFundsContext();
    onClose();
  };

  return (
    <Modal show={show} onClose={handleOnClose} title={getAccountsModalTitle(title)}>
      <div className="tw-flex tw-flex-col">
        {title === 'Add Funds' && <AddFunds onFinish={handleOnClose} />}
        {title === 'Convert' && <ConvertFunds onFinish={handleOnClose} />}
        {title === 'Pay Payee' && <PayPayee onClose={onClose} />}
        {title === 'Withdraw' && <WithdrawFunds onFinish={handleOnClose} />}
        {title === 'Statements' && <DownloadStatements />}
        {title === 'Bank Details' && <ViewBankDetails />}
      </div>
    </Modal>
  );
};

export default AccountsModal;
