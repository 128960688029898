import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import {
  ADD_CONTACT_TO_PRODUCT_PERMISSION,
  REMOVE_CONTACT_FROM_PRODUCT_PERMISSION,
  GET_CREDIT_CARDS_FOR_LIST,
} from 'graphql/cards';
import { TEAM_MEMBER_STATUS } from 'constants/index';
import { Loaders } from 'components/cards/Loader';
import { Select, SubmitButton } from 'components/FormFields/v2';
import { memberFilter } from 'utility/team';
import Button from 'components/Button';

const AddTeamMember = ({ loadingMembers, members, creditCard, onClose }) => {
  const [creditCardContacts, setCreditCardContacts] = useState(creditCard.contacts);
  const [statefilteredMembers, setStatefilteredMembers] = useState([]);

  const filteredMembers = members?.filter(
    (member) => member.role === 'member' && member.status === TEAM_MEMBER_STATUS.active
  );

  const creditCardContactOptions = memberFilter(filteredMembers, creditCardContacts);

  const [addContactToProductPermission, { loading: loadingAddMember, error }] = useMutation(
    ADD_CONTACT_TO_PRODUCT_PERMISSION,
    { refetchQueries: [{ query: GET_CREDIT_CARDS_FOR_LIST }], awaitRefetchQueries: true }
  );
  const [removeContactFromProductPermission, { loading: loadingRemoveMember, error: removeError }] = useMutation(
    REMOVE_CONTACT_FROM_PRODUCT_PERMISSION,
    { refetchQueries: [{ query: GET_CREDIT_CARDS_FOR_LIST }], awaitRefetchQueries: true }
  );

  const form = useForm();
  const { register, handleSubmit } = form;

  const isLoading = loadingMembers && statefilteredMembers.length === 0;
  if (statefilteredMembers?.length === 0 && filteredMembers?.length !== 0) {
    statefilteredMembers?.push(...filteredMembers);
  }

  const AddContactToProductPermission = async (selectedPerson) => {
    try {
      await addContactToProductPermission({
        variables: {
          creditCardId: creditCard.id,
          contactId: selectedPerson.addTeamMember,
        },
      });
      toast.success('Team member added successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const selectedTeamMember = filteredMembers.find((member) => member.id === selectedPerson.addTeamMember);
      setCreditCardContacts([...creditCardContacts, selectedTeamMember]);
      setStatefilteredMembers(statefilteredMembers.filter((member) => member.id !== selectedPerson.addTeamMember));
    } catch (error) {
      console.error(error);
    }
  };

  const removeMember = async (memberId) => {
    try {
      await removeContactFromProductPermission({
        variables: {
          creditCardId: creditCard.id,
          contactId: memberId,
        },
      });
      toast.success('Team member removed successfully', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const updatedMembers = creditCardContacts.filter((member) => member.id !== memberId);
      setCreditCardContacts(updatedMembers);
      setStatefilteredMembers([...statefilteredMembers, creditCardContacts.find((member) => member.id === memberId)]);
    } catch (error) {
      console.error(removeError);
    }
  };

  const handleSelect = (id) => {
    AddContactToProductPermission(id);
  };

  return (
    <div>
      {isLoading || loadingAddMember || loadingRemoveMember ? (
        <div className="tw-py-8 tw-text-center">
          <Loaders.Spinner />
        </div>
      ) : (
        <>
          <div className="tw-pt-8 tw-pb-4 tw-flex tw-justify-center">
            <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
              <form onSubmit={handleSubmit(handleSelect)} className="tw-w-full">
                <div className="tw-flex tw-justify-center tw-items-end tw-gap-4">
                  <Select
                    name="addTeamMember"
                    placeholder="Choose one"
                    options={creditCardContactOptions?.map((p) => ({ name: `${p.name}`, value: p.id }))}
                    ref={register({ required: true })}
                    rootClass="tw-w-1/2"
                  />
                  <SubmitButton className="tw-py-2.5 tw-bg-natural-grey-2" disabled={loadingAddMember}>
                    Share
                  </SubmitButton>
                </div>
              </form>
            </FormProvider>
          </div>
          <div className={`${creditCardContacts?.length > 0 && 'tw-mt-4 tw-mb-8'}`}>
            <table className="tw-table tw-table-auto tw-w-10/12 tw-m-auto">
              {creditCardContacts?.length > 0 && (
                <thead>
                  <tr>
                    <th className="tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-pb-2">Name</th>
                    <th className="tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-pb-2">Status</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {creditCard.contacts &&
                  creditCardContacts.map((member) => (
                    <MemberRow key={member.id} member={member} onRemove={removeMember} />
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      <div className="tw-flex tw-justify-end tw-align-bottom tw-px-8 tw-py-4 tw-border-t tw-border-neutral-grey-4">
        <Button secondary onClick={onClose}>
          Done
        </Button>
      </div>
    </div>
  );
};

const MemberRow = ({ member, onRemove }) => {
  const isDisabled = member.role === 'account_owner' || member.role === 'admin';
  return (
    <tr>
      <td className="tw-py-3">{member.name}</td>
      <td className="tw-py-3">{getMemberTypeByRole(member.role)}</td>
      <td>
        {!isDisabled && (
          <Button primary className="tw-bg-semantic-error" onClick={() => onRemove(member.id)}>
            Remove
          </Button>
        )}
      </td>
    </tr>
  );
};

export const getMemberTypeByRole = (role) =>
  ({
    account_owner: 'Account Owner',
    member: 'Member',
    admin: 'Admin',
    read_only: 'Read Only',
    bookkeeper: 'Bookkeeper',
  }[role] || null);

export default AddTeamMember;
