import React from 'react';

const OwnerInformationSectionSkeleton = () => {
  return (
    <>
      <div className="tw-flex tw-gap-2 tw-animate-pulse">
        <div className="tw-w-1/3 tw-h-10 tw-rounded tw-bg-neutral-grey-4" />
        <div className="tw-w-1/3 tw-h-10 tw-rounded tw-bg-neutral-grey-4" />
        <div className="tw-w-1/3 tw-h-10 tw-rounded tw-bg-neutral-grey-4" />
      </div>
      <div className="tw-flex tw-gap-2 tw-animate-pulse">
        <div className="tw-w-1/3 tw-h-10 tw-rounded tw-bg-neutral-grey-4" />
        <div className="tw-w-1/3 tw-h-10 tw-rounded tw-bg-neutral-grey-4" />
        <div className="tw-w-1/3 tw-h-10 tw-rounded tw-bg-neutral-grey-4" />
      </div>
    </>
  );
};

export default OwnerInformationSectionSkeleton;
