import React, { useContext } from 'react';
import cx from 'classnames';
import {
  CardNumberElement,
  CardExpirationDateElement,
  CardVerificationCodeElement,
} from '@basis-theory/basis-theory-react';

import { Loaders } from 'components/cards/Loader';
import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { bsElementStyle, bsCardNumberElementStyle, bsCopyIconStyles } from './constants';
import { DetailsProps } from './Details.types';

import styles from './Details.module.scss';

const Details = ({ isLoading, cardNumberRef, cardExpiryRef, cardCVCRef }: DetailsProps) => {
  const { creditCardDetails } = useContext(CardDetailsContext);
  const { lastFourDigits, cardHolderName } = creditCardDetails || {};

  const maskedNumber = `**** **** **** ${lastFourDigits}`;
  const maskedExpiry = '**/**';
  const maskedCvc = '***';

  return (
    <section
      className={cx(
        styles.details,
        'tw-flex tw-flex-col tw-gap-4 2xl:tw-gap-6 tw-whitespace-nowrap md:tw-text-2xl lg:tw-text-xl'
      )}
      aria-label="Credit Card Details"
    >
      <div className="tw-text-2xl tw-font-bold tw-w-64 md:tw-w-72 md:tw-mb-4">
        {isLoading && <Loaders.Spinner />}
        <div className={cx(isLoading && 'tw-sr-only')}>
          <CardNumberElement
            id="loopCardNumber"
            readOnly
            ref={cardNumberRef}
            placeholder={maskedNumber}
            style={bsCardNumberElementStyle}
            iconPosition="none"
            copyIconStyles={bsCopyIconStyles}
            enableCopy
          />
        </div>
      </div>
      <div className="tw-flex tw-gap-6 sm:tw-text-lg md:tw-text-2xl lg:tw-text-xl">
        <div className="tw-flex tw-flex-col tw-gap-1">
          <div>EXPIRY</div>
          <div className="tw-font-bold tw-w-28">
            {isLoading && <Loaders.Spinner />}
            <div className={cx(isLoading && 'tw-sr-only')}>
              <CardExpirationDateElement
                id="loopCardExpiration"
                readOnly
                ref={cardExpiryRef}
                placeholder={maskedExpiry}
                style={bsElementStyle}
                enableCopy
                copyIconStyles={bsCopyIconStyles}
              />
            </div>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-1">
          <span>CVC2</span>
          <div className="tw-font-bold tw-w-20">
            {isLoading && <Loaders.Spinner />}
            <div className={cx(isLoading && 'tw-sr-only')}>
              <CardVerificationCodeElement
                id="loopCardCVC"
                readOnly
                ref={cardCVCRef}
                placeholder={maskedCvc}
                style={bsElementStyle}
                enableCopy
                copyIconStyles={bsCopyIconStyles}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tw-font-bold" aria-label="Card Holder Name">
        {cardHolderName}
      </div>
    </section>
  );
};

export default Details;
