import { useContext, useMemo } from 'react';
import {
  CardExpenseRequestDataType,
  CompletedRequestDataType,
  ReimbursementApprovalRequestDataType,
  isCardExpenseRequestList,
  isReimbursementRequestList,
} from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';
import { ApprovalRequestsContext } from 'components/Approvals/ApprovalsContent/context';
import { ApprovalRequestDetailOverlayProps } from '../ApprovalRequestDetailOverlay.types';

const useApprovalRequestDetailOverlay = ({ requestList }: ApprovalRequestDetailOverlayProps) => {
  const { selectedRequestId, setSelectedRequestId, isOpenModal } = useContext(ApprovalRequestsContext);

  const request = useMemo(() => {
    if (isReimbursementRequestList(requestList)) {
      return requestList?.find((item: ReimbursementApprovalRequestDataType) => {
        return item.id === selectedRequestId;
      });
    } else if (isCardExpenseRequestList(requestList)) {
      return requestList?.find((item: CardExpenseRequestDataType) => {
        return item.transactionId === selectedRequestId;
      });
    } else {
      return requestList?.find((item: CompletedRequestDataType) => {
        return item.id === selectedRequestId;
      });
    }
  }, [requestList, selectedRequestId]);

  const onClose = () => setSelectedRequestId(null);

  return { request, onClose, isOpenModal };
};

export default useApprovalRequestDetailOverlay;
