import { useContext, useMemo } from 'react';

import { PaymentRequestsContext } from 'components/PayorPortal/PaymentRequests/contexts/PaymentRequestsContext';
import { usePaymentRequestFieldValues } from '../../../hooks';
import { RequestInfoModalProps } from '../RequestInfoModal.types';
import { getRequestStatusDescription } from '../RequestInfoModal.utils';

const useRequestInfoModal = ({
  selectedPaymentRequestId,
  selectedBankAccountId,
}: Pick<RequestInfoModalProps, 'selectedPaymentRequestId' | 'selectedBankAccountId'>) => {
  const { paymentRequests, bankAccounts } = useContext(PaymentRequestsContext);

  const paymentRequest = useMemo(
    () => paymentRequests.find(({ id }) => id === selectedPaymentRequestId)!,
    [paymentRequests, selectedPaymentRequestId]
  );

  const bankAccount = useMemo(
    () => bankAccounts.find(({ id }) => id === selectedBankAccountId),
    [bankAccounts, selectedBankAccountId]
  );

  const {
    formattedAmount,
    currency,
    formattedDueDate,
    vendorName,
    bankAccountName,
    status,
    isOngoing,
    frequencyLabel,
    isStoppedByEndDate,
    formattedEndDate,
    isStoppedByNumberOfOccurrences,
    numberOfOccurrences,
    isStoppedByCancel,
  } = usePaymentRequestFieldValues({
    paymentRequest,
    bankAccount,
  });

  const statusDescription = getRequestStatusDescription(status);

  return {
    formattedAmount,
    currency,
    formattedDueDate,
    vendorName,
    bankAccountName,
    statusDescription,
    isOngoing,
    frequencyLabel,
    isStoppedByEndDate,
    formattedEndDate,
    isStoppedByNumberOfOccurrences,
    numberOfOccurrences,
    isStoppedByCancel,
  };
};

export default useRequestInfoModal;
