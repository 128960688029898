import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const WaitingRoomIcon = ({
  width = '147',
  height = '121',
  vbWidth = '147',
  vbHeight = '121',
  barColor,
  bigStarColor,
  ...other
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${vbWidth} ${vbHeight}`}
    {...other}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M103.924 92.92V36.9012C103.924 35.428 102.829 34.2336 101.478 34.2336H91.6972C90.3467 34.2336 89.252 35.428 89.252 36.9012V92.92"
      fill={barColor}
    />
    <path
      d="M84.361 92.9201V53.2401C84.361 52.1966 83.2663 51.3506 81.9158 51.3506H72.1347C70.7842 51.3506 69.6895 52.1966 69.6895 53.2401V92.9201"
      fill={barColor}
    />
    <path
      d="M64.7985 92.9201V67.2448C64.7985 66.5696 63.7038 66.0222 62.3533 66.0222H52.5722C51.2217 66.0222 50.127 66.5696 50.127 67.2448V92.9201"
      fill={barColor}
    />
    <path
      d="M96.5876 92.9201V41.5695C96.5876 40.219 95.4928 39.1243 94.1423 39.1243H84.3613C83.0108 39.1243 81.916 40.219 81.916 41.5695V92.9201"
      stroke="#4F5154"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M77.0251 92.9202V58.6865C77.0251 57.336 75.9303 56.2412 74.5798 56.2412H64.7988C63.4483 56.2412 62.3535 57.336 62.3535 58.6865V92.9202"
      stroke="#4F5154"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.4646 92.92V75.8032C57.4646 74.4527 56.3698 73.3579 55.0193 73.3579H45.2382C43.8878 73.3579 42.793 74.4527 42.793 75.8032V92.92"
      stroke="#4F5154"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.9023 92.9202H101.479"
      stroke="#4F5154"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.7941 28.3924C65.7941 31.6281 65.0201 34.8168 63.5366 37.6924C62.0531 40.568 59.9033 43.047 57.2665 44.9224C54.6298 46.7978 51.5827 48.0152 48.3795 48.4731C45.1764 48.9309 41.9103 48.6158 38.8537 47.5542"
      stroke="#4F5154"
      strokeLinecap="round"
    />
    <path
      d="M33.3874 12.1281C36.1135 10.0963 39.3066 8.78235 42.6731 8.30695C46.0396 7.83155 49.4717 8.20997 52.6538 9.40744C55.8359 10.6049 58.6659 12.583 60.8838 15.1599C63.1017 17.7368 64.6364 20.8299 65.3466 24.1548"
      stroke="#4F5154"
      strokeLinecap="round"
    />
    <path
      d="M35.3675 45.9598C32.7912 44.4723 30.5736 42.4364 28.8718 39.9964C27.1701 37.5563 26.0258 34.7718 25.5202 31.8402C25.0146 28.9087 25.16 25.9017 25.9461 23.0326C26.7322 20.1635 28.1397 17.5024 30.0689 15.2379"
      stroke="#4F5154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.8614 12.7669L33.2832 12.186L33.864 7.60782"
      stroke="#4F5154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.3977 41.8415L36.1133 46.4006L31.5542 47.1162"
      stroke="#4F5154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.6231 30.5703L65.7402 27.1672L69.1433 30.2843"
      stroke="#4F5154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M137.443 88.6648C137.655 88.6062 137.793 88.8827 137.618 89.0164L126.246 97.723C126.182 97.7726 126.153 97.8565 126.175 97.9351L129.992 111.739C130.051 111.951 129.774 112.089 129.641 111.914L120.934 100.542C120.884 100.478 120.801 100.449 120.722 100.471L106.918 104.288C106.706 104.347 106.568 104.07 106.743 103.936L118.115 95.2299C118.179 95.1803 118.208 95.0964 118.186 95.0178L114.369 81.2138C114.31 81.0019 114.587 80.8644 114.72 81.0389L123.427 92.4106C123.477 92.4753 123.561 92.5035 123.639 92.4818L137.443 88.6648Z"
      fill={bigStarColor}
    />
    <path
      d="M17.5072 55.6018C17.6895 55.4789 17.9078 55.6972 17.7849 55.8795L14.3066 61.0346C14.2609 61.1022 14.2609 61.1907 14.3066 61.2583L17.7849 66.4135C17.9078 66.5957 17.6895 66.8141 17.5072 66.6911L12.3521 63.2128C12.2845 63.1672 12.196 63.1672 12.1284 63.2128L6.97325 66.6911C6.791 66.8141 6.57263 66.5957 6.6956 66.4135L10.1739 61.2583C10.2195 61.1907 10.2195 61.1022 10.1739 61.0346L6.6956 55.8795C6.57263 55.6972 6.791 55.4789 6.97325 55.6018L12.1284 59.0801C12.196 59.1258 12.2845 59.1258 12.3521 59.0801L17.5072 55.6018Z"
      fill={primaryDarkGreenColor}
    />
  </svg>
);

export default WaitingRoomIcon;
