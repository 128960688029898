import React from 'react';

import { useQuery } from '@apollo/client';
import { GET_GIFT_OFFERS } from 'graphql/rewards';
import { Loaders } from 'components/cards/Loader';
import Carousel from 'components/Carousel';
import config from 'config';

import { GiftOffer } from 'components/rewardsAndOffers/rewards/GiftOffer';

export const GiftsList = () => {
  const { data: giftOffersData, loading: loadingGiftOffers, error: errorGifts } = useQuery(GET_GIFT_OFFERS);
  const { giftOffers } = giftOffersData || { giftOffers: [] };

  if (loadingGiftOffers) return <Loaders.Light />;
  if (errorGifts) {
    console.error(errorGifts);
    return 'Error loading Gifts';
  }

  if (giftOffers.length > 0)
    return (
      <div className="tw-flex flex-column tw-items-center">
        <div className="carousel-container tw-max-w-screen-xl">
          <Carousel slidesPerView={1} spaceBetween={1} alignment="left">
            {giftOffers?.map((gift) => {
              return <GiftOffer key={gift.internalId} gift={gift} />;
            })}
          </Carousel>
        </div>
      </div>
    );
  else {
    return (
      <div>
        You are not currently able to redeem Loop Points. Please contact our team at{' '}
        <a
          className="tw-font-semibold tw-text-primary-dark-green"
          target="_blank"
          href={`mailto:${config.supportEmail}`}
          rel="noreferrer"
        >
          {config.supportEmail}
        </a>{' '}
        for additional information
      </div>
    );
  }
};
