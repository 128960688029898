import React from 'react';

type CoinStackRefundedProps = {
  size?: number;
};

const CoinStackRefunded = ({ size = 23 }: CoinStackRefundedProps) => (
  <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.775 5.66667C9.96444 5.66667 12.55 4.622 12.55 3.33333C12.55 2.04467 9.96444 1 6.775 1C3.58556 1 1 2.04467 1 3.33333C1 4.622 3.58556 5.66667 6.775 5.66667Z"
      fill="white"
    />
    <path
      d="M16.225 12.6667C19.4144 12.6667 22 11.622 22 10.3333C22 9.04467 19.4144 8 16.225 8C13.0356 8 10.45 9.04467 10.45 10.3333C10.45 11.622 13.0356 12.6667 16.225 12.6667Z"
      fill="white"
    />
    <path
      d="M10.45 10.3333C10.45 9.04467 13.0356 8 16.225 8C19.4144 8 22 9.04467 22 10.3333V19.6667C22 20.9553 19.4144 22 16.225 22C13.0356 22 10.45 20.9553 10.45 19.6667V10.3333Z"
      fill="white"
    />
    <path
      d="M6.775 1C3.58556 1 1 2.04467 1 3.33333V17.3333C1 18.622 3.58556 19.6667 6.775 19.6667C8.17111 19.6667 9.45151 19.4665 10.45 19.1333V10.3333C10.45 9.60875 11.2674 8.96131 12.55 8.53334V3.33333C12.55 2.04467 9.96444 1 6.775 1Z"
      fill="white"
    />
    <path
      d="M12.55 3.33333C12.55 4.622 9.96444 5.66667 6.775 5.66667C3.58556 5.66667 1 4.622 1 3.33333M12.55 3.33333C12.55 2.04467 9.96444 1 6.775 1C3.58556 1 1 2.04467 1 3.33333M12.55 3.33333V8.53334C11.2674 8.96131 10.45 9.60875 10.45 10.3333M1 3.33333V17.3333C1 18.622 3.58556 19.6667 6.775 19.6667C8.17111 19.6667 9.45151 19.4665 10.45 19.1333V10.3333M1 8C1 9.28866 3.58556 10.3333 6.775 10.3333C8.17111 10.3333 9.45151 10.1332 10.45 9.79999M1 12.6667C1 13.9553 3.58556 15 6.775 15C8.17111 15 9.45151 14.7998 10.45 14.4667M22 10.3333C22 11.622 19.4144 12.6667 16.225 12.6667C13.0356 12.6667 10.45 11.622 10.45 10.3333M22 10.3333C22 9.04467 19.4144 8 16.225 8C13.0356 8 10.45 9.04467 10.45 10.3333M22 10.3333V19.6667C22 20.9553 19.4144 22 16.225 22C13.0356 22 10.45 20.9553 10.45 19.6667V10.3333M22 15C22 16.2887 19.4144 17.3333 16.225 17.3333C13.0356 17.3333 10.45 16.2887 10.45 15"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g>
      <rect x="14.75" y="14.75" width="10.5" height="10.5" rx="5.25" fill="white" />
      <rect x="14.75" y="14.75" width="10.5" height="10.5" rx="5.25" stroke="black" strokeWidth="0.5" />
    </g>
    <path
      d="M16.5567 22.6576C16.6387 22.6713 16.7178 22.6151 16.7314 22.533L16.891 21.5802C17.5064 22.7062 18.7009 23.4189 20.0199 23.4189C21.9787 23.4189 23.5713 21.8507 23.5713 19.9238C23.5713 17.9969 21.9772 16.4286 20.0184 16.4286C18.6902 16.4286 17.4821 17.1489 16.8667 18.3069C16.8272 18.3814 16.856 18.4726 16.9305 18.5121C17.005 18.5516 17.0961 18.5227 17.1356 18.4482C17.6979 17.389 18.8027 16.7311 20.0184 16.7311C21.81 16.7326 23.2674 18.1641 23.2674 19.9238C23.2674 21.6835 21.81 23.115 20.0199 23.115C18.7814 23.115 17.6645 22.4297 17.1144 21.3523L18.2237 21.565C18.3058 21.5802 18.3848 21.5255 18.4 21.4419C18.4152 21.3614 18.362 21.2824 18.2814 21.2656L16.8393 20.9891C16.8302 20.9875 16.8211 20.986 16.8104 20.986C16.736 20.986 16.6737 21.0392 16.66 21.1122L16.4305 22.4798C16.4169 22.5634 16.4716 22.6424 16.5567 22.6576Z"
      fill="black"
      stroke="black"
      strokeWidth="0.3"
    />
  </svg>
);

export default CoinStackRefunded;
