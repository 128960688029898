import { useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { CreateCardContext } from 'components/creditCards/components/CreateCardModal/context/CreateCardContext';
import useIsMember from 'hooks/useIsMember';
import { shortenedContactName } from 'utility/string';
import { getBusinessNameOptions, getCardholderOptions } from '../CardInformation.utils';
import { CardInformationFormType } from '../CardInformation.types';
import { CreditCard } from 'types/creditCard';
import { useCreateCard } from 'components/creditCards/components/CreateCardModal/components/CreateCard/hooks/useCreateCard';

const useCardInformation = () => {
  const {
    createCardFormDataRef,
    onNextStep,
    onPrevStep,
    currentStep,
    steps,
    me,
    creditCards,
    hasNoCards,
    members,
    loadingMembers,
  } = useContext(CreateCardContext);

  const { isMember } = useIsMember();
  const { handleCreateCard, isSubmitting, error } = useCreateCard();

  const form = useForm<CardInformationFormType>({
    defaultValues: {},
  });

  const { register, handleSubmit, setError } = form;

  const defaultContactName = shortenedContactName(me.embossedName);
  const suggestedCreditCardDisplayNames = me.suggestedCreditCardDisplayNames || [];
  const accountName = me.account?.name.slice(0, 18);
  const displayName = me.account?.displayName && me.account.displayName;
  const businessNameOptions = getBusinessNameOptions(displayName, suggestedCreditCardDisplayNames, accountName);
  const cardHolderNameOptions = getCardholderOptions(members, me.internalContact);
  const isVirtual = createCardFormDataRef.current?.virtual;

  const validateDisplayName = (data: CardInformationFormType) => {
    const displayNameMatch = creditCards.find((card: CreditCard) => {
      return card?.nickname?.toLowerCase() === data.cardNickname.toLowerCase();
    });
    if (displayNameMatch) {
      setError('cardNickname', { type: 'manual', message: `Card Nickname has already been used` });
    } else {
      handleSubmitForm(data);
    }
  };

  const handleSubmitForm = useCallback(
    async (data: CardInformationFormType) => {
      const { cardholderName, cardNickname: displayName, businessNameOnCard } = data;
      const businessName = businessNameOnCard || me?.account?.name;
      const derivedCardholderName = isMember
        ? defaultContactName
        : members.find((member) => member.id === cardholderName)?.name;
      const contactId = isMember ? me?.internalContact?.id : cardholderName;
      const nameOnCard = isMember ? defaultContactName : businessName?.slice(0, 18);

      createCardFormDataRef.current = {
        ...createCardFormDataRef.current,
        cardholderName: derivedCardholderName,
        displayName,
        businessNameOnCard: businessName,
        contactId,
        nameOnCard,
      };

      if (hasNoCards || !isVirtual) {
        onNextStep();
      } else {
        const result = await handleCreateCard();
        if (result?.success) onNextStep();
      }
    },
    [isMember, defaultContactName, members, isVirtual, error, me?.account?.name, onNextStep]
  );

  const handleOnNextStep = (data: CardInformationFormType) => {
    !isVirtual ? handleSubmitForm(data) : validateDisplayName(data);
  };

  const handleOnPreviousStep = () => {
    form.reset();
    onPrevStep();
  };

  return {
    form,
    register,
    loadingMembers,
    cardHolderNameOptions,
    businessNameOptions,
    handleSubmit,
    handleOnNextStep,
    handleOnPreviousStep,
    currentStep,
    steps,
    isVirtual: isVirtual,
    isMember,
    defaultContactName,
    isPhysicalCard: !isVirtual,
    isSubmitting,
    error,
  };
};

export default useCardInformation;
