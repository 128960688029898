import React from 'react';

import Button from 'components/Button';

const EmptyState = ({ onFinish, description, footerBorder, footerButton }) => (
  <>
    <div className="tw-my-8 tw-px-8">
      <small className="tw-text-neutral-grey-2">{description}</small>
    </div>
    <div className={`${footerBorder ? 'tw-border-t tw-border-neutral-grey-3' : ''} tw-flex tw-justify-end`}>
      {footerButton === 'modal' && (
        <Button
          className="tw-bg-primary-dark-green tw-text-neutral-light tw-py-2 tw-px-8 tw-mt-4 tw-mr-4 tw-rounded-md tw-text-center tw-w-max"
          onClick={onFinish}
        >
          Close
        </Button>
      )}
    </div>
  </>
);

export default EmptyState;
