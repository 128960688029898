import React from 'react';

import Close from 'components/svg/Close';
import Modal from 'components/Modal/v2';
import AddPayee from 'components/payments/AddPayee';
import { AddPayeeContextProvider } from 'components/payments/AddPayee/AddPayeeContext';

const AddPayeeModal = ({ show, onClose }) => {
  return (
    <Modal show={show} onClose={() => {}}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <strong>Add Payee</strong>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-pt-4 tw-border-t tw-border-neutral-grey-4">
        <AddPayeeContextProvider>
          <AddPayee onFinish={onClose} />
        </AddPayeeContextProvider>
      </div>
    </Modal>
  );
};

export default AddPayeeModal;
