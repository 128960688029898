import React from 'react';

import { Modal } from 'components/UI';
import Button from 'components/Button';
import { SkipConfirmationModalProps } from './SkipConfirmationModal.types';

const SkipConfirmationModal = ({ show, onSubmit, onClose }: SkipConfirmationModalProps) => (
  <Modal show={show} onClose={onClose} title="Skip the connection?">
    <div className="tw-px-12 tw-py-8 tw-text-lg tw-border tw-border tw-border-neutral-grey-4">
      <p>Are you sure you want to skip the connection of your bank account?</p>
      <br></br>
      <p>Your bank account should be connected to be able to confirm the payment request.</p>
    </div>
    <div className="tw-py-4 tw-px-8 tw-flex tw-justify-between">
      <Button primary onClick={onClose}>
        Return
      </Button>
      <Button secondary onClick={onSubmit}>
        Add account later
      </Button>
    </div>
  </Modal>
);

export default SkipConfirmationModal;
