import React from 'react';
import { FormProvider } from 'react-hook-form';

import useCardInformation from './hooks/useCardInformation';
import { FormFooter, ProgressBar } from 'components/UI';
import CardImage from 'components/creditCards/components/CardImage';
import { Select, TextField } from 'components/FormFields/v2';

const CardInformation = () => {
  const {
    register,
    form,
    handleSubmit,
    handleOnNextStep,
    handleOnPreviousStep,
    currentStep,
    steps,
    cardHolderNameOptions,
    businessNameOptions,
    loadingMembers,
    isMember,
    defaultContactName,
    isPhysicalCard,
    isSubmitting,
    error,
  } = useCardInformation();

  return (
    <FormProvider {...form} {...{ graphQLErrors: error?.graphQLErrors }}>
      <form
        className="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-flex-grow"
        onSubmit={handleSubmit(handleOnNextStep)}
      >
        <div className="tw-flex tw-flex-col tw-gap-4 tw-py-8 tw-w-full lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0">
          <ProgressBar className="tw-mb-4" currentStepIndex={currentStep} stepLabelsAndIndexes={steps} />
          <div className="tw-h-48 tw-mx-auto">
            <CardImage isVirtual={!isPhysicalCard} isVisa isActive />
          </div>
          <div className="tw-flex tw-flex-col tw-mt-2">
            <label htmlFor="cardholderName" className="tw-text-sm tw-text-primary-dark-green">
              Cardholder Name
            </label>
            {loadingMembers && (
              <div className="tw-flex tw-animate-pulse tw-rounded-md tw-bg-neutral-grey-4 tw-w-full tw-h-12" />
            )}
            {!loadingMembers &&
              (isMember ? (
                <TextField name="cardholderName" value={defaultContactName} disabled />
              ) : (
                <Select
                  name="cardholderName"
                  placeholder="Select Cardholder"
                  options={cardHolderNameOptions}
                  ref={register({ required: true })}
                />
              ))}
          </div>
          <div className="tw-flex tw-flex-col">
            <label htmlFor="cardNickname" className="tw-text-sm tw-text-primary-dark-green">
              Card Nickname
            </label>
            <TextField name="cardNickname" placeholder="Subscription Card" ref={register({ required: true })} />
          </div>
          {isPhysicalCard && (
            <>
              <div className="tw-flex tw-flex-col">
                <label htmlFor="businessNameOnCard" className="tw-text-sm tw-text-primary-dark-green">
                  Business Name Displayed on Card
                </label>
                <Select
                  name="businessNameOnCard"
                  placeholder="Select Name on Card"
                  ref={register({
                    required: true,
                    maxLength: {
                      value: 18,
                      message: 'Maximum of 18 characters',
                    },
                  })}
                  options={businessNameOptions}
                />
              </div>
            </>
          )}
        </div>

        <div className="tw-bg-neutral-grey-4.5 tw-w-full tw-pt-4 tw-pb-8 tw-rounded-b-md tw-overflow-hidden tw-mt-auto">
          <FormFooter
            cancelButtonLabel="Go Back"
            loading={isSubmitting}
            onCancel={handleOnPreviousStep}
            submitButtonLabel="Next Step"
            rootClass="lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0"
            isDisabled={loadingMembers}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default CardInformation;
