import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';

import { useToggle } from 'hooks';
import { GET_CURRENT_USER_CONTACT_FOR_PERSONA } from 'graphql/personaVerification';

const useIdentityVerification = () => {
  const [
    getCurrentUserContactForPersona,
    { loading: loadingInternalContactData, data: internalContactData, refetch: refetchInternalContactData },
  ] = useLazyQuery(GET_CURRENT_USER_CONTACT_FOR_PERSONA, {
    fetchPolicy: 'network-only',
  });

  const internalContactId = get(internalContactData, 'me.internalContact.id');
  const thirdPartyVerificationComplete = get(internalContactData, 'me.internalContact.thirdPartyVerificationComplete');
  const thirdPartyVerificationRequested = get(
    internalContactData,
    'me.internalContact.thirdPartyVerificationRequested'
  );

  const {
    isOpen: showPersonaVerificationModal,
    open: openPersonaVerificationModal,
    close: closePersonaVerificationModal,
  } = useToggle();

  const handleClosePersonaVerificationModal = async () => {
    await refetchInternalContactData();
    closePersonaVerificationModal();
  };

  useEffect(() => {
    getCurrentUserContactForPersona();
  }, []);

  return {
    loadingInternalContactData,
    internalContactId,
    thirdPartyVerificationRequested,
    thirdPartyVerificationComplete,
    showPersonaVerificationModal,
    openPersonaVerificationModal,
    closePersonaVerificationModal,
    handleClosePersonaVerificationModal,
  };
};

export default useIdentityVerification;
