import React from 'react';

import { OptionCard } from './components';
import { CARD_WITH_CREDIT, CARD_WITHOUT_CREDIT } from './constants';

import cardWithCurrencies from 'images/cardWithCurrencies.png';
import cardWithSalesChannels from 'images/cardWithSalesChannels.png';

import styles from './OptionsList.module.scss';

const OptionsList = ({ handleContinue, openSelectSalesModal }) => {
  return (
    <ul className={styles.list}>
      <OptionCard
        classNames="tw-flex tw-w-full lg:tw-w-1/2 tw-bg-secondary-pastel-yellow-50"
        imageSrc={cardWithCurrencies}
        imageAlt="Card with currencies"
        text={CARD_WITHOUT_CREDIT}
        actionText="Proceed Without Credit Limit"
        handleAction={handleContinue}
      />
      <OptionCard
        classNames="tw-flex tw-w-full lg:tw-w-1/2 tw-bg-primary-light-green"
        imageSrc={cardWithSalesChannels}
        imageAlt="Card with sales channels"
        text={CARD_WITH_CREDIT}
        actionText="Apply For a Credit Limit"
        handleAction={openSelectSalesModal}
      />
    </ul>
  );
};

export default OptionsList;
