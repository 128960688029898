import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { GET_CONTACTS_AND_CORPORATE_SHAREHOLDER } from 'graphql/onboarding';
import AddUSBankAccount from 'components/wallets/AddUSBankAccount';

const AddUSBankAccountContainer = (props) => {
  const { loading, data } = useQuery(GET_CONTACTS_AND_CORPORATE_SHAREHOLDER);
  const contacts = _.get(data, 'me.account.contacts') || [];
  const corporateShareholders = _.get(data, 'me.account.corporateShareholders') || [];

  return (
    <AddUSBankAccount loading={loading} contacts={contacts} corporateShareholders={corporateShareholders} {...props} />
  );
};

export default AddUSBankAccountContainer;
