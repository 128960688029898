import React from 'react';
import NKCarousel from 'nuka-carousel';
import cx from 'classnames';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';

const SLIDES_PER_VIEW_HINT_NEXT_SLIDE = 1.2;

const Carousel = ({
  children,
  slidesPerView = SLIDES_PER_VIEW_HINT_NEXT_SLIDE,
  spaceBetween = 16,
  alignment = '',
  onChangeSlide = undefined,
  showArrows = true,
  showBottomControls = false,
}) => {
  const handleChangeSlide = (index) => {
    onChangeSlide && onChangeSlide(index);
  };

  return (
    <NKCarousel
      afterSlide={handleChangeSlide}
      slidesToShow={slidesPerView}
      cellSpacing={spaceBetween}
      cellAlign={alignment}
      dragThreshold={0.3}
      className={cx(showBottomControls && 'tw-pb-8')}
      renderBottomCenterControls={showBottomControls ? CarouselControls : null}
      renderCenterLeftControls={showArrows ? PreviousButton : null}
      renderCenterRightControls={showArrows ? NextButton : null}
    >
      {children}
    </NKCarousel>
  );
};

const PreviousButton = ({ previousSlide, currentSlide }) => (
  <BsChevronLeft
    onClick={previousSlide}
    size={24}
    className={`${currentSlide === 0 ? 'tw-hidden' : ''} tw-cursor-pointer`}
  />
);

const NextButton = ({ nextSlide, currentSlide, slideCount }) => (
  <BsChevronRight
    onClick={nextSlide}
    size={24}
    className={`${currentSlide === slideCount - 1 ? 'tw-hidden' : ''} tw-cursor-pointer`}
  />
);

const CarouselControls = ({ pagingDotsIndices, currentSlide, goToSlide }) => (
  <div className="tw-flex tw-gap-2">
    {pagingDotsIndices.map((i) => {
      const isSelected = i === currentSlide;
      return (
        <button
          key={i}
          onClick={() => goToSlide(i)}
          aria-label={`Go to slide ${i}`}
          className={cx(
            'tw-border tw-border-neutral-grey-2 tw-rounded-full tw-h-2 tw-w-2 tw-transition-all tw-transition-300',
            isSelected ? 'tw-bg-neutral-grey-3' : 'hover:tw-bg-neutral-grey-5'
          )}
        ></button>
      );
    })}
  </div>
);

export default Carousel;
