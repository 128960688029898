import React from 'react';

import { Details, Complete } from './components';

export type StepsProps = {
  currentStep: number;
  onNextStep?: () => void;
  onFinish: () => void;
};

const Steps = ({ currentStep, onNextStep, onFinish }: StepsProps) => {
  switch (currentStep) {
    case 1:
      return <Complete onFinish={onFinish} />;
    default:
      return <Details onNextStep={onNextStep} onFinish={onFinish} />;
  }
};

export default Steps;
