import React, { useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { IoAddCircleOutline } from 'react-icons/io5';
import { FaExclamationCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

import {
  GET_QUICKBOOK_LIABILITY_ACCOUNTS,
  GET_CURRENCY_QUICKBOOKS_LIABILITY_ACCOUNT_MAPPING,
  UPDATE_CURRENCY_QUICKBOOK_LIABILITY_MAPPING,
} from 'graphql/accountingServices';

import Button from 'components/Button';
import { QuickBooksAccount } from './Account';
import { Loaders } from 'components/cards/Loader';
import { MapButton } from './MapButton';
import { Currency } from './Currency';
import { NewAccountModalContext } from 'context/AccountingServices';

export const QuickBooksLiabilityAccounts = ({ accountName, currency, showAction = true, className = '' }) => {
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_QUICKBOOK_LIABILITY_ACCOUNTS);
  const { loading: loadingMapping, data: dataMapping } = useQuery(GET_CURRENCY_QUICKBOOKS_LIABILITY_ACCOUNT_MAPPING);
  const [updateMapping, { error: errorMutation }] = useMutation(UPDATE_CURRENCY_QUICKBOOK_LIABILITY_MAPPING, {
    refetchQueries: [GET_CURRENCY_QUICKBOOKS_LIABILITY_ACCOUNT_MAPPING, GET_QUICKBOOK_LIABILITY_ACCOUNTS],
  });

  const unsortedQuickBooksLiabilityAccounts = get(data, 'accountingIntegrationLiabilityAccounts', []) || [];
  const accountingIntegrationLiabilityAccounts = [...unsortedQuickBooksLiabilityAccounts].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  let { currencyAccountingIntegrationLiabilityAccountMapping } = dataMapping || [];
  const { showNewLiability, setShowNewLiability, setCurrency } = useContext(NewAccountModalContext);

  // if we pass currency as prop,  we only show  mapping for this currency
  if (currency)
    currencyAccountingIntegrationLiabilityAccountMapping = currencyAccountingIntegrationLiabilityAccountMapping.filter(
      (map) => map.currency === currency
    );

  if (loading) return <Loaders.Spinner />;
  if (error) {
    console.error(error);
    return <div>error</div>;
  }

  const handleNewAccount = (currency) => {
    if (accountName === 'Xero') {
      window.open('https://go.xero.com/Bank/BankAccounts.aspx', '_blank', 'noreferrer');
    } else {
      setCurrency(currency);
      setShowNewLiability(true);
    }
  };

  const onChangeLiability = (currency, category) => {
    try {
      updateMapping({ variables: { currency, quickBooksAccountId: category.id } });
    } catch (e) {
      console.error(e);
      console.error(errorMutation);
    }
  };

  if (!showNewLiability)
    return (
      <div className={`${className} tw-flex tw-flex-col`}>
        {!loadingMapping &&
          currencyAccountingIntegrationLiabilityAccountMapping &&
          currencyAccountingIntegrationLiabilityAccountMapping.length > 0 &&
          currencyAccountingIntegrationLiabilityAccountMapping.map(({ currency, quickBooksAccountId }) => {
            const qboLiabilityAccount = accountingIntegrationLiabilityAccounts?.find(
              (item) => item.id === quickBooksAccountId
            );
            return (
              <div key={currency} className="tw-flex tw-items-center tw-space-x-4">
                <MapButton
                  className="tw-w-1/2"
                  key={currency}
                  leftSide={<Currency currency={currency} />}
                  rightSide={
                    accountingIntegrationLiabilityAccounts && accountingIntegrationLiabilityAccounts.length > 0 ? (
                      <QuickBooksAccount
                        qbCategories={accountingIntegrationLiabilityAccounts.filter(
                          (liability) => liability.currency === currency
                        )}
                        qbCategory={qboLiabilityAccount}
                        onChange={(category) => onChangeLiability(currency, category)}
                        className="tw-relative"
                        newAccount={() => (
                          <div className="tw-p-1 tw-h-12 tw-flex tw-box-border tw-items-center tw-cursor-pointer tw-text-neutral-light tw-bg-primary-dark-green hover:tw-opacity-90">
                            <IoAddCircleOutline className="tw-ml-1" />
                            <span className="tw-mx-1" onClick={() => handleNewAccount(currency)}>
                              Create {accountName} Account
                            </span>
                          </div>
                        )}
                      />
                    ) : (
                      <div className="tw-p-1 tw-h-12 tw-flex tw-box-border tw-items-center tw-cursor-pointer tw-text-neutral-light tw-bg-primary-dark-green hover:tw-opacity-90">
                        <IoAddCircleOutline className="tw-ml-1" />
                        <span className="tw-mx-1" onClick={() => handleNewAccount(currency)}>
                          Create {accountName} Account
                        </span>
                      </div>
                    )
                  }
                />

                {qboLiabilityAccount && showAction && (
                  <Button
                    primary
                    onClick={() => history.push(`/dashboard/transactions/cardTransactionsPush/?currency=${currency}`)}
                  >
                    <small>{`Push ${currency} transactions to ${accountName}`}</small>
                  </Button>
                )}
                {!qboLiabilityAccount && showAction && (
                  <div className="tw-bg-semantic-warning tw-text-light tw-flex tw-items-center tw-space-x-2 tw-p-2">
                    <FaExclamationCircle /> <small>Select an account for this currency</small>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  else return '';
};
