import React from 'react';
import cx from 'classnames';
import { StepProps } from './Step.types';

const Step = ({ isCurrent, isPast, hasError }: StepProps) => {
  return (
    <li
      className={cx(
        isCurrent && 'tw-bg-primary-dark-green',
        isPast && 'tw-bg-secondary-pastel-green-80',
        hasError && 'tw-bg-semantic-warning',
        !isCurrent && !isPast && 'tw-bg-neutral-grey-4',
        'tw-h-2 tw-w-full tw-rounded-full'
      )}
    ></li>
  );
};

export default Step;
