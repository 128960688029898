import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { GET_TRANSACTIONS_PAGE, UNLINK_RECEIPT } from 'graphql/transactions';
import { GET_CARD_EXPENSES } from 'graphql/cardApprovals';
import { GET_COMPLETED_EXPENSES } from 'graphql/completedExpenses';
import {
  isCardExpenseRequestDataType,
  isCompletedExpenseRequestDataType,
} from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';

const useConfirmUnlinkDialog = ({ transaction, closeUnlinkDialog }) => {
  const refetchQueries = [GET_TRANSACTIONS_PAGE];
  if (isCardExpenseRequestDataType(transaction)) {
    refetchQueries.push(GET_CARD_EXPENSES);
  }
  if (isCompletedExpenseRequestDataType(transaction)) {
    refetchQueries.push(GET_COMPLETED_EXPENSES);
  }

  const [unlinkReceipt, { loading: isSubmitting }] = useMutation(UNLINK_RECEIPT, {
    refetchQueries: refetchQueries,
  });

  const onUnlinkConfirm = async () => {
    try {
      await unlinkReceipt({ variables: { financialTransactionId: transaction.transactionId.toString() } });
      toast.success('Successfully unlinked the receipt');
      closeUnlinkDialog();
    } catch (err) {
      console.error(err);
      toast.error('Could not unlink');
    }
  };

  return {
    onUnlinkConfirm,
    isSubmitting,
  };
};

export default useConfirmUnlinkDialog;
