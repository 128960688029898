export type SocialShareButtonProps = {
  postUrl: string;
  type: SocialShareType;
  children?: React.ReactNode;
};

export type PostContent = {
  url: string;
  text?: string;
  title?: string;
  source?: string;
};

export enum SocialShareType {
  Linkedin = 'linkedin',
  Twitter = 'twitter',
}
