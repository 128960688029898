import React from 'react';
import { BsPrinter } from 'react-icons/bs';

import { Modal } from 'components/UI';
import { SubmitButton } from 'components/FormFields/v2';
import Close from 'components/svg/Close';
import { PADContent } from '../';

import { PADAgreementModalProps } from './PADAgreementModal.types';

const PADAgreementModal = ({
  show,
  onClose,
  payorEmail,
  payorBusinessName,
  payorRepresentativeName,
  signInDate,
  payeeName,
  payeeEmail,
  payeeAddress,
  payorBankName,
  payorBankAccountNumber,
  payorBankRoutingNumber,
  payorBankInstitutionNumber,
  payorBankTransitNumber,
  loading,
  isUSBankAccount,
}: PADAgreementModalProps) => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div
        className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-mb-8 tw-border-b tw-border-neutral-grey-4 tw-font-bold outline-hidden print-hidden"
        tabIndex={0}
      >
        Pre-Authorized Debit Agreement
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-px-8 tw-my-4">
        <PADContent
          signInDate={signInDate}
          payeeName={payeeName}
          payeeAddress={payeeAddress}
          payeeEmail={payeeEmail}
          payorBusinessName={payorBusinessName}
          payorRepresentativeName={payorRepresentativeName}
          payorEmail={payorEmail}
          payorBankName={payorBankName}
          payorBankAccountNumber={payorBankAccountNumber}
          payorBankRoutingNumber={payorBankRoutingNumber}
          payorBankInstitutionNumber={payorBankInstitutionNumber}
          payorBankTransitNumber={payorBankTransitNumber}
          loading={loading}
          isUSBankAccount={isUSBankAccount}
        />
      </div>
      <div className="tw-border-t tw-border-neutral-grey-3 tw-py-4 tw-px-8 tw-mt-8 tw-flex tw-justify-end print-hidden">
        <SubmitButton onClick={handlePrint} isDisabled={loading}>
          <div className="tw-flex tw-justify-center tw-items-center">
            <p className="tw-text-neutral-light tw-mr-2">Print</p>
            <BsPrinter />
          </div>
        </SubmitButton>
      </div>
    </Modal>
  );
};

export default PADAgreementModal;
