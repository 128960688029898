import React from 'react';

import { Theme } from 'styles/tailwind';
import config from 'config';
import Button from 'components/Button';
import { Header } from './components';

import macImage from 'images/mac-img.png';

const Maintenance = () => {
  return (
    <Theme>
      <div>
        <Header />
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-8 md:tw-gap-16 lg:tw-gap-8 tw-mt-8 md:tw-mt-16 tw-mb-8">
          <div className="tw-flex tw-items-center tw-justify-center lg:tw-w-1/2">
            <div className="tw-flex-grow tw-flex tw-flex-col tw-gap-4 tw-justify-center md:tw-justify-end tw-px-4 md:tw-px-8 tw-max-w-3xl">
              <div className="tw-text-primary-dark-green tw-font-bold">Loop is Undergoing Upgrades</div>
              <h2 className="tw-font-bold tw-text-4xl md:tw-text-6xl lg:tw-text-7xl tw-mb-4">Under maintenance</h2>
              <div className="tw-text-lg md:tw-text-xl">
                The page you’re looking for is currently under
                <br />
                maintenance.
              </div>
              <div className="tw-text-lg md:tw-text-xl">Please check back soon.</div>
              <div className="tw-flex tw-justify-center md:tw-justify-start tw-gap-4 tw-my-6">
                <a href={`mailto:${config.contactEmail}`} target="_blank" rel="noreferrer">
                  <Button secondary>Contact Us</Button>
                </a>
                <a href={config.landingPageUrl} target="_blank" rel="noreferrer">
                  <Button primary>Back To Home Page</Button>
                </a>
              </div>
            </div>
          </div>
          <div className="lg:tw-w-1/2">
            <img src={macImage} alt="Macbook images" className="tw-w-full" />
          </div>
        </div>
      </div>
    </Theme>
  );
};

export default Maintenance;
