import React, { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import Payments1 from 'components/svg/Payments1';
import Payments2 from 'components/svg/Payments2';
import Payments3 from 'components/svg/Payments3';
import Payments4 from 'components/svg/Payments4';
import PaymentBox from 'components/payments/PaymentBox';
import useGetProductState from 'hooks/useGetProductState';
import { CARD_PAYMENT_BOX_TEXT } from 'components/payments/constants';

const PaymentBoxes = () => {
  const { me } = useContext(AuthContext);
  const { lineOfCredit } = me.account || false;

  return (
    <>
      <PaymentBox
        btnText="Add Money to Account"
        btnLink="/dashboard/payments/add-money"
        Icon={Payments1}
        text={
          <>
            <b>Add money</b> to your Loop Account from an external USD or CAD bank account linked to Loop.
          </>
        }
      />

      <PaymentBox
        btnText="Move Money"
        btnLink="/dashboard/payments/move-money"
        Icon={Payments2}
        text={
          <>
            <b>Transfer money</b> between your Loop products, convert currencies or transfer to a bank account linked to
            Loop.
          </>
        }
      />

      <CardPaymentBox />

      {!!lineOfCredit && (
        <PaymentBox
          btnText="Pay Line of Credit Balance"
          btnLink="/dashboard/payments/pay-loc-balance"
          Icon={Payments4}
          text={
            <>
              <b>Make a new payment</b> or <b>modify a schedule payment</b> on your Line of Credit.
            </>
          }
        />
      )}
    </>
  );
};

const CardPaymentBox = () => {
  const { isCreditCard } = useGetProductState();

  return (
    <PaymentBox
      btnText={isCreditCard ? 'Pay Card Balance' : 'Add Money to Card'}
      btnLink={`/dashboard/payments/${isCreditCard ? 'pay-balance' : 'add-money'}`}
      Icon={Payments3}
      text={isCreditCard ? CARD_PAYMENT_BOX_TEXT.creditCard : CARD_PAYMENT_BOX_TEXT.preFunded}
    />
  );
};

export default PaymentBoxes;
