import React from 'react';

import { useToggle } from 'hooks';
import { useGetRecurringPaymentInfo } from 'components/payments/CardAutopay/hooks';
import { Enabled, NotEnabled, EnableAutopayModal } from './components';
import { Loaders } from 'components/cards/Loader';

const Content = () => {
  const { loadingRecurringPaymentData, isAutopayEnabled } = useGetRecurringPaymentInfo();

  const {
    open: setShowEnableAutopayModal,
    isOpen: showEnableAutopayModal,
    close: setHideEnableAutopayModal,
  } = useToggle();

  if (loadingRecurringPaymentData) return <Loaders.Light />;

  return (
    <>
      {isAutopayEnabled ? <Enabled /> : <NotEnabled openEnableAutopayModal={setShowEnableAutopayModal} />}

      <EnableAutopayModal show={showEnableAutopayModal} onClose={setHideEnableAutopayModal} />
    </>
  );
};

export default Content;
