import React from 'react';

import { Modal } from 'components/UI';
import { AddFundsToBalanceModalProps } from './AddFundsToBalanceModal.types';
import { useAddFundsToBalanceModal } from './hooks';
import { Steps } from './components';

const AddFundsToBalanceModal = ({ show, onClose }: AddFundsToBalanceModalProps) => {
  const { currentStep, onNextStep, onResetSteps, currency } = useAddFundsToBalanceModal({ onClose });

  if (!currency) return null;

  return (
    <Modal title={`Add Funds to ${currency} Card Balance`} show={show} onClose={onResetSteps}>
      <div className="tw-px-8 tw-my-8">
        <Steps currentStep={currentStep} onNextStep={onNextStep} onResetSteps={onResetSteps} />
      </div>
    </Modal>
  );
};

export default AddFundsToBalanceModal;
