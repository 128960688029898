import React, { useState } from 'react';

export const ToasterContext = React.createContext({});

const DEFAULT_TOASTER_PROPS = {
  type: 'success',
  text: '',
};

export const ToasterContextProvider = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [toasterProps, setToasterProps] = useState(DEFAULT_TOASTER_PROPS);

  const showToaster = ({ type, text }) => {
    setToasterProps({ type, text });
    setIsVisible(true);
  };

  const hideToaster = () => {
    setIsVisible(false);
  };

  return (
    <ToasterContext.Provider value={{ showToaster, hideToaster, toasterProps, isVisible }}>
      {props.children}
    </ToasterContext.Provider>
  );
};
