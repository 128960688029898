import React from 'react';

const StatementDetailsSkeleton = ({ isCurrentCycle }: { isCurrentCycle?: boolean }) => {
  const balancesLength = 4;

  return (
    <div className="tw-w-full">
      {isCurrentCycle && (
        <div className="tw-flex tw-mb-2 tw-rounded-md tw-bg-neutral-grey-4 tw-w-80 tw-h-6 tw-animate-pulse" />
      )}
      <div className="tw-flex tw-mb-4 md:tw-mb-6 tw-rounded-md tw-bg-neutral-grey-4 tw-w-60 tw-h-6 tw-animate-pulse" />
      <div className="tw-flex tw-flex-wrap lg:tw-flex-nowrap tw-rounded-md lg:tw-rounded-none tw-overflow-hidden tw-gap-0 lg:tw-gap-2 xl:tw-gap-6 tw-w-full tw-animate-pulse tw-mb-3">
        {Array(balancesLength)
          .fill('')
          .map((_, i) => (
            <div key={i} className="lg:tw-rounded-md tw-bg-neutral-grey-4 tw-w-full tw-h-14 lg:tw-h-40" />
          ))}
      </div>
    </div>
  );
};

export default StatementDetailsSkeleton;
