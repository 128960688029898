import React from 'react';

import { GlobalLimitLegendProps } from './GlobalLimitLegend.types';

const GlobalLimitLegend = ({ dataset }: GlobalLimitLegendProps) => {
  return (
    <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-start md:tw-items-center tw-gap-3 md:tw-gap-8 md:tw-px-4">
      {dataset.map(({ id, label, bgColor }) => (
        <div key={id} className="tw-flex tw-items-center tw-gap-4 tw-h-8 md:tw-h-4">
          <div
            className="tw-hidden md:tw-block tw-h-full tw-w-16 tw-rounded"
            style={{
              backgroundColor: bgColor,
            }}
          />
          <div className="tw-text-sm xl:tw-text-base tw-font-bold">{label}</div>
        </div>
      ))}
    </div>
  );
};

export default GlobalLimitLegend;
