import React from 'react';

import Loader from '../cards/Loader';

const Loading = () => (
  <div className="tw-flex tw-flex-col tw-justify-center tw-flex-grow" data-testid="loader">
    <Loader />
  </div>
);

export default Loading;
