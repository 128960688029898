import React from 'react';

import RepeatOutline from 'components/svg/RepeatOutline';

const Header = () => {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-border-b tw-border-neutral-grey-4">
      <div className="tw-flex">
        <div className="tw-flex tw-justify-center tw-items-center tw-bg-secondary-light-yellow tw-rounded tw-p-0.5 tw-mr-2">
          <RepeatOutline className="tw-text-primary-dark-yellow tw-h-4" />
        </div>
        <strong>Payments</strong>
      </div>
    </div>
  );
};

export default Header;
