import { useMemo } from 'react';

import { encryptString } from 'utility/string';
import { FlinksIframeProps } from '../FlinksIframe.types';

const DEFAULT_BANK_CONNECTION_COUNTRY = 'CA';
const DEFAULT_BANK_CONNECTION_CURRENCY = 'cad';

const useFlinksIframe = ({
  redirectRoute,
  country,
  currencies,
  isPayor,
}: Pick<FlinksIframeProps, 'redirectRoute' | 'country' | 'currencies' | 'isPayor'>) => {
  const flinksCountry = country || DEFAULT_BANK_CONNECTION_COUNTRY;
  const callbackPath = isPayor ? '/payor/bank-connections/callback' : '/bank-connections/callback';

  const flinksRedirectUrl = isPayor
    ? useMemo(() => {
        const redirectUrlConnector = redirectRoute?.includes('?') ? '&' : '?';
        const initPageUrl = encryptString(
          `${redirectRoute || location.pathname}${redirectUrlConnector}country=${flinksCountry}`
        );

        return `${location.origin}${callbackPath}?redirectUrl=${initPageUrl}`;
      }, [redirectRoute, flinksCountry])
    : null;

  const flinksCurrency = currencies?.length ? currencies.join().toLowerCase() : DEFAULT_BANK_CONNECTION_CURRENCY;

  return {
    flinksRedirectUrl,
    flinksCurrency,
    flinksCountry,
  };
};

export default useFlinksIframe;
