import React from 'react';

import SupportedAddress from './SupportedAddress';

const MainBusinessAddress = ({ mainAddress, onCopyCardAddress }) => {
  return (
    <>
      <div className="tw-text-center">
        <small className="tw-font-semibold">Main Business Address</small>
      </div>
      {
        <SupportedAddress
          address={mainAddress}
          onCopy={() => onCopyCardAddress(mainAddress.postalCode, mainAddress.street)}
        />
      }
    </>
  );
};

export default MainBusinessAddress;
