import React from 'react';

import { AmountInputProps } from './AmountInput.types';
import { MoneyInputField } from 'components/FormFields/v2';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import { validateMoneyInput } from 'utility/currency';

const AmountInput = ({
  name,
  label,
  currency,
  formattedAmount,
  formatMoneyV2,
  setFormattedAmount,
  register,
  dataTestId,
}: AmountInputProps) => {
  return (
    <div className="tw-relative">
      <MoneyInputField
        name={name || 'amount'}
        label={label || 'Amount'}
        required
        currency={currency}
        value={formattedAmount}
        moneyFormatter={formatMoneyV2}
        setValue={setFormattedAmount}
        rootClass="tw-w-3/4"
        ref={register({
          required: true,
          validate: (value) => validateMoneyInput(value, setFormattedAmount),
        })}
        data-testid={dataTestId}
      />
      <div className="tw-h-20 tw-flex tw-items-end tw-absolute tw-right-0 tw-top-0 tw-pb-2">
        <div className="tw-flex tw-items-center tw-h-11 tw-bg-neutral-grey-4 tw-rounded-md tw-p-4 tw-flex-grow-0 tw-ml-4">
          <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5 tw-mr-2">
            <MobileCurrencyFlag currency={currency} size={16} />
          </div>
          <p className="tw-text-neutral-grey-2">{currency}</p>
        </div>
      </div>
    </div>
  );
};

export default AmountInput;
