import React, { useState } from 'react';
import { formatMoneyV2 } from 'utility/currency';
import { IoGiftOutline, IoTicketOutline } from 'react-icons/io5';

import { RedeemPointsModal } from './Modals';

export const GiftOffer = (props) => {
  const { gift } = props;
  const { internalId, imageUrl, description, pointsRequired, giftValue, available } = gift;
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const onShowRedeemModal = () => {
    setShowRedeemModal(available);
  };
  const onHideRedeemModal = () => {
    setShowRedeemModal(false);
  };

  return (
    <div
      key={internalId}
      className="tw-flex tw-flex-col tw-w-60 tw-bg-neutral-light tw-border-neutral-grey-4 tw-shadow-md hover:tw-shadow-lg hover:tw-opacity-80 tw-border tw-rounded-md tw-cursor-pointer"
      onClick={onShowRedeemModal}
    >
      <div className="tw-h-40 tw-flex tw-justify-center">
        <img className="tw-h-40 tw-object-contain" src={imageUrl || 'https://placehold.jp/200x50.png'} />
      </div>

      <div className={'tw-p-4 ' + (available ? '' : 'tw-text-neutral-grey-3')}>
        <div>{description}</div>
        <div className="tw-flex tw-items-center tw-space-x-1 tw-text-sm tw-mt-1">
          <IoTicketOutline />
          <span>{Intl.NumberFormat().format(pointsRequired)} points</span>
        </div>
        <div
          className={'tw-flex tw-items-center tw-space-x-1 tw-text-sm ' + (available ? 'tw-text-semantic-success' : '')}
        >
          <IoGiftOutline />
          <span className="tw-mt-1">{formatMoneyV2(giftValue)} Gift Card</span>
        </div>
      </div>
      <RedeemPointsModal giftOffer={gift} show={showRedeemModal} onClose={onHideRedeemModal} />
    </div>
  );
};
