import React from 'react';
import cx from 'classnames';
import { Pagination } from 'react-headless-pagination';
import { PaginationControlProps } from 'types/pagination';

import useIsMobile from 'hooks/useIsMobile';

const PaginationControl = ({
  page,
  numPerPage,
  numberOfItems,
  onChangePage,
  onChangeNumberPerPage,
  numPerPageOptionsList = [5, 10, 15, 20],
}: PaginationControlProps) => {
  const isMobile = useIsMobile();
  const totalOfPages =
    numberOfItems % numPerPage === 0
      ? Math.floor(numberOfItems / numPerPage)
      : Math.floor(numberOfItems / numPerPage) + 1;

  const handleChangeNumberPerPage = (e: React.ChangeEvent<HTMLSelectElement>) =>
    onChangeNumberPerPage(Number(e.target.value));

  return (
    <div
      className={cx(
        'tw-flex',
        isMobile ? 'tw-flex-col tw-space-y-4' : 'tw-items-center tw-justify-between tw-flex-wrap tw-gap-x-2 tw-gap-y-6'
      )}
    >
      <div className="tw-flex tw-items-center">
        <div className="tw-text-sm">Results on page</div>
        <select
          onChange={handleChangeNumberPerPage}
          value={numPerPage}
          className="tw-mx-2 tw-block tw-px-3 tw-py-1 tw-text-sm tw-rounded-md tw-w-16 tw-bg-neutral-light tw-border-1 tw-border-neutral-grey-3 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-ring-0 focus:tw-outline-none focus:tw-shadow-input"
        >
          {numPerPageOptionsList.map((num) => (
            <option key={num} value={num}>
              {num}
            </option>
          ))}
        </select>
        <div className="tw-text-sm">of {numberOfItems}</div>
      </div>
      <Pagination
        currentPage={page}
        setCurrentPage={onChangePage}
        totalPages={totalOfPages}
        edgePageCount={1}
        middlePagesSiblingCount={1}
        className="tw-flex tw-items-center"
        truncableText="..."
        truncableClassName="tw-text-sm"
      >
        <Pagination.PrevButton className={`${page === 0 ? 'tw-text-neutral-grey-3' : ''} tw-font-semibold tw-text-sm`}>
          Prev
        </Pagination.PrevButton>

        <div className="tw-flex tw-items-center tw-justify-center">
          <Pagination.PageButton
            activeClassName="tw-border tw-border-neutral-grey-3 tw-rounded-md"
            className="tw-text-sm tw-px-3 tw-py-1 tw-mx-2 tw-cursor-pointer"
          />
        </div>

        <Pagination.NextButton
          className={`${page === totalOfPages - 1 ? 'tw-text-neutral-grey-3' : ''} tw-font-semibold tw-text-sm`}
        >
          Next
        </Pagination.NextButton>
      </Pagination>
    </div>
  );
};

export default PaginationControl;
