import React, { useContext } from 'react';

import useIsAdmin from 'hooks/useIsAdmin';
import useIsMember from 'hooks/useIsMember';
import { formatMoneyWithoutCurrencyLabel } from 'utility/currency';
import { CardExpenseRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';
import { formatDateTime } from 'utility/date';
import { capitalize } from 'utility/string';
import { APPROVAL_MODAL, APPROVAL_REQUEST_STATUS } from 'components/Approvals/constants';
import { ApprovalRequestsContext, FilterRequestsContext } from 'components/Approvals/ApprovalsContent/context';

const useApprovalsRow = ({ cardExpense }: { cardExpense: CardExpenseRequestDataType }) => {
  const { handleUpdateCardExpense, isUpdatingCardExpense } = useContext(FilterRequestsContext);
  const { setActiveModal, openModal, setSelectedRequests } = useContext(ApprovalRequestsContext);

  const { isAdmin } = useIsAdmin();
  const { isMember } = useIsMember();

  const { requester, transactionDate, amountRequested, receipt, status, merchantName, transactionId, fromLabel } =
    cardExpense || {};
  const formattedTransactionDate = transactionDate ? formatDateTime(transactionDate, 'MMM d, yyyy') : '-';
  const formattedAmount = formatMoneyWithoutCurrencyLabel(amountRequested);

  const handleStopClickRow = (e: React.MouseEvent<HTMLTableCellElement>) => {
    e.stopPropagation();
  };

  const handleApprove = () => {
    handleUpdateCardExpense([transactionId], APPROVAL_REQUEST_STATUS.approved);
  };
  const handleDecline = () => {
    setActiveModal(APPROVAL_MODAL.declineCardExpense);
    setSelectedRequests([cardExpense]);
    openModal();
  };

  const formattedRequesterName = capitalize(requester?.name) || '-';

  return {
    isAdmin,
    isMember,
    requester: requester?.name,
    formattedRequesterName,
    status,
    merchantName,
    currency: amountRequested.currency,
    formattedAmount,
    formattedTransactionDate,
    receipt,
    handleStopClickRow,
    fromLabel,
    transactionId,
    handleApprove,
    handleDecline,
    isUpdatingCardExpense,
  };
};
export default useApprovalsRow;
