import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Modal, FormFooter } from 'components/UI';
import { SpendManagement } from 'components/creditCards/components/cardsSettings';
import { EditSpendManagementModalProps } from './EditSpendManagementModal.types';
import { useEditSpendManagementModal } from './hooks';

const EditSpendManagementModal = ({
  isOpenModal,
  closeModal,
  handleUpdateSettings,
  isLoading,
  currencies,
  selectedCurrencies,
  merchants,
  selectedMerchants,
  merchantCategories,
  selectedMerchantCategories,
}: EditSpendManagementModalProps) => {
  const {
    handleCloseModal,
    form,
    handleSubmit,
    onSubmit,
    isMerchantCategoriesError,
    merchantCategoriesOptions,
    selectedMerchantCategoriesOptions,
    setSelectedMerchantCategoriesOptions,
  } = useEditSpendManagementModal({
    closeModal,
    handleUpdateSettings,
    currencies,
    selectedCurrencies,
    selectedMerchants,
    merchantCategories,
    selectedMerchantCategories,
  });

  return (
    <Modal show={isOpenModal} title="Edit Spend Settings" onClose={handleCloseModal}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-pt-4 tw-pb-4 tw-px-8">
            <SpendManagement
              merchantCategoriesOptions={merchantCategoriesOptions}
              selectedMerchantCategoriesOptions={selectedMerchantCategoriesOptions}
              setSelectedMerchantCategoriesOptions={setSelectedMerchantCategoriesOptions}
              currencies={currencies}
              merchants={merchants}
              isMerchantCategoriesError={isMerchantCategoriesError}
            />
          </div>

          <FormFooter
            submitButtonLabel="Update"
            cancelButtonLabel="Cancel"
            onCancel={handleCloseModal}
            loading={isLoading}
            rootClass="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-8"
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditSpendManagementModal;
