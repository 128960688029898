import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Modal, FormFooter } from 'components/UI';
import { BasisTheoryPinInputFields } from 'components/creditCards/components';
import { SetPinModalProps } from './SetPinModal.types';
import useSetPinModal from './hooks/useSetPinModal';

const SetPinModal = ({ show, onClose }: SetPinModalProps) => {
  const { form, handleSubmit, onSubmit, loading, pinRef, confirmPinRef, bt } = useSetPinModal({ onClose });

  if (!show) return null;

  return (
    <Modal show={show} onClose={onClose} title="Set PIN">
      <div className="tw-flex tw-flex-col tw-pt-4 tw-pb-4 tw-border-t tw-border-neutral-grey-4">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <BasisTheoryPinInputFields pinRef={pinRef} confirmPinRef={confirmPinRef} bt={bt} />
            <FormFooter
              submitButtonLabel="Save Changes"
              cancelButtonLabel="Cancel"
              onCancel={onClose}
              loading={loading}
              rootClass="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-8"
            />
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default SetPinModal;
