import { useHistory } from 'react-router-dom';
import { ampTrackEvent } from 'amplitude';
import { useEffect } from 'react';

const useCardRepayment = () => {
  const history = useHistory();
  const onNavigateToCards = () => history.push('/dashboard/cards/balances');
  const onNavigateBack = () => history.goBack();

  useEffect(() => {
    ampTrackEvent('payBalance: transferFunds: open page');
  }, []);

  return {
    onNavigateToCards,
    onNavigateBack,
  };
};

export default useCardRepayment;
