import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { BankAccountCountry, BankAccountCurrency } from 'types/bankAccount';
import { BankAccountConnectionProps } from '../BankAccountConnection.types';

const useBankAccountConnection = ({ currency, onlyCA }: Pick<BankAccountConnectionProps, 'currency' | 'onlyCA'>) => {
  const initialCountry =
    onlyCA || !currency || currency === BankAccountCurrency.CAD ? BankAccountCountry.CA : BankAccountCountry.US;

  const [country, setCountry] = useState(initialCountry);

  const form = useForm({
    defaultValues: {
      country,
    },
  });

  const { register, watch } = form;

  const watchCountry = watch('country');

  const countryOptions = [
    {
      label: 'Canada',
      value: BankAccountCountry.CA,
    },
    {
      label: 'United States',
      value: BankAccountCountry.US,
    },
  ].filter((option) => {
    if (onlyCA) return option.value === BankAccountCountry.CA;
    if (!currency) return true;

    if (currency === BankAccountCurrency.CAD) return option.value === BankAccountCountry.CA;
    if (currency === BankAccountCurrency.USD) return option.value === BankAccountCountry.US;
  });

  const defaultCurrencies =
    country === BankAccountCountry.US ? [BankAccountCurrency.USD] : [BankAccountCurrency.CAD, BankAccountCurrency.USD];

  const allowedCurrencies = currency ? [currency] : defaultCurrencies;

  const showCountrySwitcher = countryOptions.length > 1;

  useEffect(() => {
    if (watchCountry) setCountry(watchCountry);
  }, [watchCountry]);

  return {
    form,
    country,
    countryOptions,
    register,
    allowedCurrencies,
    showCountrySwitcher,
  };
};

export default useBankAccountConnection;
