import React from 'react';
import cx from 'classnames';

import styles from '../ReviewSelection.module.scss';
import CardRow from './CardRow';
import { CardMigrationSelectedCard } from 'components/CardMigrationV2/CardMigrationV2.types';

const CardRows = ({
  cards,
  toggleUpdateAddressForCardId,
}: {
  cards: CardMigrationSelectedCard[];
  toggleUpdateAddressForCardId: (cardId: string) => void;
}) => (
  <div
    className={cx(
      styles.cardMigrationReview,
      'tw-flex tw-flex-col tw-overflow-y-auto tw-gap-4 tw-bg-neutral-grey-5 tw-rounded-md tw-w-full'
    )}
  >
    {cards.map((card) => (
      <CardRow key={card.cardId} card={card} updateAddress={() => toggleUpdateAddressForCardId(card.cardId)} />
    ))}
  </div>
);

export default CardRows;
