import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { CURRENCY_CODES, paymentStatus } from 'constants/index';
import { formatDateTime, formatDate, generateDates } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { useSyncAccountCurrencyWithPaymentCurrency, useSyncOutstandingBalanceAmountWithPaymentAmount } from './hooks';
import { Loaders } from 'components/cards/Loader';

export const PayLocBalanceContext = React.createContext({});

export const PayLocBalanceContextProvider = ({
  isModal,
  hasLineOfCredit,
  lineOfCredit = {},
  lineOfCreditAccNum,
  fundInfo = {},
  bankAccounts = [],
  wallets = [],
  children,
  onFinish,
  finishButtonLabel,
  loading,
}) => {
  if (loading) return <Loaders.Light />;

  const { outstandingBalance = {} } = lineOfCredit || {};
  const locOutstandingBalance = outstandingBalance?.amount > 100_00 ? outstandingBalance?.amount : 100_00;
  const currentDate = new Date();
  const defaultCurrency = lineOfCredit.currency || CURRENCY_CODES.CAD;

  const DEFAULT_FORM_VALUES = {
    fromAccount: undefined,
    paymentDetails: 'oneTimePayment',
    paymentAmount: { amount: locOutstandingBalance, currency: defaultCurrency },
    currency: defaultCurrency,
    paymentDate: formatDateTime(currentDate),
  };

  const form = useForm({ defaultValues: DEFAULT_FORM_VALUES });
  const { watch, register, setValue, setError, clearErrors, formState } = form;

  const [payload, setPayload] = useState(DEFAULT_FORM_VALUES);
  const [step, setStep] = useState(0);

  // store POST response data here
  const [payment, setPayment] = useState({});

  const selectedPaymentDetail = payload.paymentDetails;
  const setSelectedPaymentDetail = (value) => {
    setPayload((state = {}) => ({ ...state, paymentDetails: value }));
  };

  // "From Account" Select dropdown options
  const bankAccountAndWallets = useMemo(() => [...bankAccounts, ...wallets], [bankAccounts, wallets]);
  const formattedFromAccountOptions = bankAccountAndWallets.reduce((options, option) => {
    if (defaultCurrency !== option.currency) return options;
    if (option.country === 'US') return options; // TODO: Remove filter after adding support for US-based accounts for LOC Payments
    const optionIsWallet = wallets.includes(option);
    const name = optionIsWallet
      ? `${option.displayName} (${option.availableBalance.currency} ${formatMoneyV2(option.availableBalance)})`
      : option.displayName;

    return [...options, { name, value: option.id }];
  }, []);

  const selectedAccount = bankAccountAndWallets.find((item) => item.id === payload.fromAccount);
  const formattedFromAccountOption = selectedAccount && selectedAccount.displayName;

  const formattedPaymentAmount = formatMoneyV2({ amount: payload.paymentAmount, currency: payload.currency });

  const formattedTransactionFees = formatMoneyV2({
    amount: 0,
    currency: payload.currency,
  });

  useSyncAccountCurrencyWithPaymentCurrency({ bankAccounts, payload, setPayload });

  useSyncOutstandingBalanceAmountWithPaymentAmount({
    selectedPaymentDetail,
    locOutstandingBalance,
    setPayload,
    payload,
  });

  const paymentFromWallet = !!wallets.find((w) => w.id === payload.fromAccount);
  const recipientAccount = `Line of Credit - ${lineOfCreditAccNum}`;
  const transferMethod = paymentFromWallet ? 'Internal Transfer' : 'Domestic Transfer';

  const { billingCycle, paymentSchedule, nextPayment } = fundInfo || {};
  const { previousEndDate } = billingCycle || {};
  const { status, dueDate: nextPaymentDueDate, modifiedPayment } = nextPayment || {};
  const formattedNextPaymentDueDate = formatDate(nextPaymentDueDate);
  const { dueDate: scheduledDueDate } = paymentSchedule || {};
  const showAmountDue =
    paymentStatus[status] === paymentStatus.UNPAID ||
    (paymentStatus[status] === paymentStatus.UNPAID &&
      currentDate <= new Date(scheduledDueDate) &&
      currentDate > new Date(previousEndDate));
  const formattedPaymentDateOptions = paymentFromWallet
    ? generateDates(currentDate, currentDate)
    : generateDates(currentDate, new Date(scheduledDueDate));

  const onResetForm = () => {
    setPayload(DEFAULT_FORM_VALUES);
    setStep(0);
  };

  return (
    <PayLocBalanceContext.Provider
      value={{
        isModal,
        step,
        setStep,

        payload,
        setPayload,

        form,
        watch,
        register,
        setValue,
        setError,
        clearErrors,
        formState,

        selectedPaymentDetail,
        setSelectedPaymentDetail,

        formattedPaymentAmount,
        formattedFromAccountOptions,
        formattedFromAccountOption,
        formattedTransactionFees,
        formattedPaymentDateOptions,

        hasLineOfCredit,
        lineOfCredit,
        lineOfCreditAccNum,
        showAmountDue,
        nextPaymentDueDate,
        formattedNextPaymentDueDate,
        modifiedPayment,
        wallets,
        paymentFromWallet,

        recipientAccount,
        transferMethod,

        payment,
        setPayment,

        onResetForm,
        onFinish,
        finishButtonLabel: finishButtonLabel || 'Finish',
      }}
    >
      {children}
    </PayLocBalanceContext.Provider>
  );
};
