import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { ampTrackEvent } from 'amplitude';
import { toast } from 'react-toastify';

import {
  UPDATE_CARD_RECURRING_PAYMENT,
  GET_CARD_RECURRING_PAYMENT,
  GET_CARD_RECURRING_PAYMENT_INFO,
} from 'graphql/recurringPayment';
import { useGetRecurringPaymentInfo } from 'components/payments/CardAutopay/hooks';
import { PAYMENT_TYPE_OPTIONS } from '../../NotEnabled/constants';

const useEnabled = () => {
  const history = useHistory();
  const onNavigateBack = () => history.goBack();

  const {
    currency,
    paymentType,
    fromAccountLabel,
    recurringPaymentId,
    fromAccount,
    loadingRecurringPaymentData,
    formattedNextPaymentDate,
  } = useGetRecurringPaymentInfo();
  const recurringPaymentType = PAYMENT_TYPE_OPTIONS.find(({ value }) => value === paymentType)?.label;

  const form = useForm();
  const { handleSubmit } = form;

  const [disableCardRecurringPayment, { loading: loadingDisableRecurringPayment }] = useMutation(
    UPDATE_CARD_RECURRING_PAYMENT,
    {
      refetchQueries: [{ query: GET_CARD_RECURRING_PAYMENT }, { query: GET_CARD_RECURRING_PAYMENT_INFO }],
      awaitRefetchQueries: true,
      fetchPolicy: 'network-only',
    }
  );

  const onDisableRecurringPayment = async () => {
    try {
      await disableCardRecurringPayment({
        variables: {
          recurringPaymentId,
          enabled: false,
          fromAccount,
        },
      });
      toast.success('Autopay successfully disabled');
      ampTrackEvent('onDisableRecurringPayment: success');
    } catch (error) {
      console.error(error);
      toast.error('Unable to disable autopay');
      ampTrackEvent('onDisableRecurringPayment: error');
    }
  };

  return {
    onNavigateBack,
    currency,
    recurringPaymentType,
    fromAccountLabel,
    form,
    handleSubmit,
    onDisableRecurringPayment,
    loadingDisableRecurringPayment,
    loadingRecurringPaymentData,
    formattedNextPaymentDate,
  };
};

export default useEnabled;
