import { ApolloClient, ApolloLink, HttpLink, from } from '@apollo/client';

import config from 'config';
import { authMiddleware, errorMiddleware } from './middleware';
import cache from './cache';

const httpLink = new HttpLink({ uri: config.graphUrl });

const apolloClient = new ApolloClient({
  link: from([
    /// handle auth error + auth header middleware
    errorMiddleware as unknown as ApolloLink,
    authMiddleware as unknown as ApolloLink,
    // http request middleware
    httpLink,
  ]),
  cache,
});

export default apolloClient;
