import React from 'react';

import Zap from 'components/svg/Zap';
import { FormFooter, Banner } from 'components/UI';
import { BannerSize } from 'components/UI/Banner';

import { TransactionResponseDetails } from './components';
import { useComplete } from './hooks';
import { CompleteProps } from './Complete.types';

import styles from '../Review/Review.module.scss';

const Complete = ({ onFinish, onResetSteps }: CompleteProps) => {
  const {
    transactions,
    isSubscriptionBanner,
    subscriptionBannerAction,
    isFromBankAccount,
    retryPayBalance,
    isLoading,
    hideTransactionLabels,
  } = useComplete();

  return (
    <>
      {isSubscriptionBanner && (
        <Banner
          icon={<Zap width={45} />}
          iconAlignment="center"
          title="Your money could be instantly available"
          message="Access up to 100% of your deposits instantly when you upgrade to Loop Plus."
          submitButtonLabel="Upgrade Now"
          size={BannerSize.sm}
          onSubmit={subscriptionBannerAction}
        />
      )}

      <ul className={styles.transactions}>
        {transactions.map((transaction) => (
          <li
            key={transaction.transactionGroupId || transaction.originalAmount.currency}
            className={styles.transactions__item}
          >
            <TransactionResponseDetails
              transaction={transaction}
              alignStatuses={isFromBankAccount}
              retryPayBalance={retryPayBalance}
              hideTransactionLabels={hideTransactionLabels}
            />
          </li>
        ))}
      </ul>

      <FormFooter
        submitButtonLabel="Pay Another Balance"
        cancelButtonLabel="Close"
        onCancel={onResetSteps}
        onSubmit={onFinish}
        loading={isLoading}
      />
    </>
  );
};

export default Complete;
