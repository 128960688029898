import { useQuery, useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';

import { GET_CREDIT_CARDS_IDS, GET_CREDIT_CARDS_FOR_LIST } from 'graphql/cards';
import { GET_BANK_ACCOUNTS } from 'graphql/integrations';
import { GET_CREDIT_PRODUCTS, GET_GLOBAL_CREDIT_LIMIT } from 'graphql/creditOffer';
import { useToggle } from 'hooks';
import { KYC_STATUS } from 'constants/index';
import { BankAccount } from 'types/bankAccount';
import config from 'config';

const useQuickActions = () => {
  const { data: creditCardIdsData, loading: creditCardIdLoading } = useQuery(GET_CREDIT_CARDS_IDS);
  const { data: bankAccountsData, loading: bankAccountsLoading } = useQuery(GET_BANK_ACCOUNTS);
  const { data: creditProductsData, loading: loadingCreditInfo } = useQuery(GET_CREDIT_PRODUCTS);
  const [getCreditCards, { data: creditCardData, loading: creditCardLoading }] =
    useLazyQuery(GET_CREDIT_CARDS_FOR_LIST);
  const [getGlobalCreditLimit, { data: globalCreditLimitData, loading: globalCreditLimitLoading }] =
    useLazyQuery(GET_GLOBAL_CREDIT_LIMIT);

  const kycAssessment = get(creditCardIdsData, 'me.account.kycAssessment.status') || false;
  const bankAccounts = get(bankAccountsData, 'bankInfo.bankAccounts') || [];
  const lineOfCredit = get(creditProductsData, 'me.account.lineOfCredit');
  const creditCards = get(creditCardData, 'me.account.creditCards') || [];
  const globalCreditLimit = get(globalCreditLimitData, 'me.account.globalCreditLimit') || false;

  const hasAtLeastOneBankAccountVerified = bankAccounts.some((ba: BankAccount) => ba.verified);
  const hasLineOfCredit = !!lineOfCredit;
  const isExistingLocUser = hasLineOfCredit && creditCards.length === 0;

  const loading = creditCardIdLoading || bankAccountsLoading || loadingCreditInfo;
  const allowCardCreation =
    kycAssessment === KYC_STATUS.APPROVED &&
    hasAtLeastOneBankAccountVerified &&
    !isExistingLocUser &&
    !config.cardApiDown;

  const { isOpen: show, open: openCreateCardModal, close: closeCreateCardModal } = useToggle();
  const handleCreateCardClick = () => {
    getCreditCards();
    getGlobalCreditLimit();
    openCreateCardModal();
  };

  const {
    isOpen: showAddFundsCurrencySelectorModal,
    open: openAddFundsCurrencySelectorModal,
    close: closeAddFundsCurrencySelectorModal,
  } = useToggle();

  const {
    isOpen: showConvertFundsCurrencySelectorModal,
    open: openConvertFundsCurrencySelectorModal,
    close: closeConvertFundsCurrencySelectorModal,
  } = useToggle();

  const history = useHistory();
  const navigateToSendPayments = () => history.push('/dashboard/payments/payees');

  const isLoadingCardsData = creditCardLoading || globalCreditLimitLoading;

  return {
    loading,
    allowCardCreation,
    bankAccounts,
    creditCards,
    globalCreditLimit,
    show,
    closeCreateCardModal,
    handleCreateCardClick,
    showAddFundsCurrencySelectorModal,
    openAddFundsCurrencySelectorModal,
    closeAddFundsCurrencySelectorModal,
    showConvertFundsCurrencySelectorModal,
    openConvertFundsCurrencySelectorModal,
    closeConvertFundsCurrencySelectorModal,
    navigateToSendPayments,
    isLoadingCardsData,
  };
};

export default useQuickActions;
