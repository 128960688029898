import React from 'react';

import { balancesOrder } from 'constants/index';
import { formatMoneyV2 } from 'utility/currency';
import CurrencyFlag from 'components/svg/CurrencyFlag';
import { ACCOUNT_BALANCES } from './constants';

const AccountBalances = () => {
  return (
    <div>
      <div className="tw-text-sm">Account Balances</div>
      <div className="tw-flex tw-mt-4">
        {balancesOrder.map((currency: string) => {
          const balance = ACCOUNT_BALANCES.find((b) => b.currency === currency);

          return (
            <div key={balance?.currency} className="tw-flex-grow">
              <div className="tw-flex tw-items-center">
                <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mr-2">
                  <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                    {<CurrencyFlag currency={balance?.currency} size={14} />}
                  </div>
                </div>
                <small>{`${formatMoneyV2(balance)} ${balance?.currency}`}</small>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AccountBalances;
