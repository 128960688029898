import { useCallback, useContext } from 'react';
import { toast } from 'react-toastify';

import { ApprovalRequestActionModalProps } from '../ApprovalRequestActionModal.types';
import { useMutation } from '@apollo/client';
import { GET_REIMBURSEMENT_REQUESTS, UPDATE_REIMBURSEMENT_REQUEST_STATUS } from 'graphql/reimbursementRequest';
import { APPROVAL_ACTION_TYPE, APPROVAL_REQUEST_STATUS } from 'components/Approvals/constants';
import { FilterRequestsContext } from 'components/Approvals/ApprovalsContent/context';
import useIsAdmin from 'hooks/useIsAdmin';
import useIsMember from 'hooks/useIsMember';
import { Currencies } from 'constants/currencies';
import { formatMoneyWithoutCurrencyLabel } from 'utility/currency';
import { formatDateTime } from 'utility/date';
import { capitalize } from 'utility/string';
import { useForm } from 'react-hook-form';

const useActionApprovalRequestModal = ({
  approvalRequest,
  onClose,
  actionType,
}: Pick<ApprovalRequestActionModalProps, 'approvalRequest' | 'onClose' | 'actionType'>) => {
  const { id } = approvalRequest || {};

  const { isAdmin } = useIsAdmin();
  const { isMember } = useIsMember();

  const { page, numPerPage, period, selectedMembers, selectedCurrencies } = useContext(FilterRequestsContext);
  const { requester, merchantName, description, transactionDate, amountRequested, taxAmount, originalAmount } =
    approvalRequest || {};

  const showAmountInCAD = originalAmount?.currency !== Currencies.CAD;
  const formattedTransactionDate = formatDateTime(transactionDate);
  const formattedAmountRequested = `${formatMoneyWithoutCurrencyLabel(amountRequested)} ${amountRequested?.currency}`;
  const formattedOriginalAmount = `${formatMoneyWithoutCurrencyLabel(originalAmount)} ${originalAmount?.currency}`;
  const formattedTaxAmount = `${formatMoneyWithoutCurrencyLabel(taxAmount)} ${taxAmount?.currency}`;
  const requesterName = capitalize(requester?.name);

  const form = useForm();
  const { handleSubmit, register, watch } = form;
  const declineReasonValue = watch('declineReason');

  const [updateReimbursement, { loading: isUpdateLoading }] = useMutation(UPDATE_REIMBURSEMENT_REQUEST_STATUS, {
    refetchQueries: [
      {
        query: GET_REIMBURSEMENT_REQUESTS,
        variables: {
          status: APPROVAL_REQUEST_STATUS.pending,
          page: `${page}`,
          numPerPage: `${numPerPage}`,
          startDate: period?.from,
          endDate: period?.to,
          members: selectedMembers.map((member) => member.id),
          currencies: selectedCurrencies,
        },
      },
    ],
    awaitRefetchQueries: true,
    fetchPolicy: 'network-only',
  });

  const handleDeclineRequest = useCallback(async () => {
    try {
      const result = await updateReimbursement({
        variables: {
          expenseReimbursementId: id,
          status: APPROVAL_REQUEST_STATUS.rejected,
          declineReason: declineReasonValue,
        },
      });

      if (result?.data?.updateReimbursementRequest?.status !== APPROVAL_REQUEST_STATUS.rejected) {
        throw new Error('Error declining reimbursement request - please try again');
      }

      toast.success('Expense Reimbursement successfully declined');
    } catch (err) {
      console.error(err);
      toast.error('Error declining reimbursement request - please try again');
    } finally {
      onClose();
    }
  }, [id, onClose, declineReasonValue]);

  const handleApproveRequest = useCallback(async () => {
    try {
      const result = await updateReimbursement({
        variables: { expenseReimbursementId: id, status: APPROVAL_REQUEST_STATUS.approved },
      });

      if (result?.data?.updateReimbursementRequest?.status !== APPROVAL_REQUEST_STATUS.approved) {
        throw new Error('Error approving reimbursement request - please try again');
      }

      toast.success('Expense Reimbursement successfully approved');
    } catch (err) {
      console.error(err);
      toast.error('Error approving reimbursement request  - please try again');
    } finally {
      onClose();
    }
  }, [id, onClose]);

  const handleCancelRequest = useCallback(async () => {
    try {
      const result = await updateReimbursement({
        variables: { expenseReimbursementId: id, status: APPROVAL_REQUEST_STATUS.cancelled },
      });

      if (result?.data?.updateReimbursementRequest?.status !== APPROVAL_REQUEST_STATUS.cancelled) {
        throw new Error('Error cancelling reimbursement request - please try again');
      }

      toast.success('Expense Reimbursement successfully cancelled');
    } catch (err) {
      console.error(err);
      toast.error('Error cancelling reimbursement request  - please try again');
    } finally {
      onClose();
    }
  }, [id, onClose]);

  const onSubmit = () => {
    if (actionType === APPROVAL_ACTION_TYPE.decline && declineReasonValue) {
      handleDeclineRequest();
    } else if (actionType === APPROVAL_ACTION_TYPE.approve) {
      handleApproveRequest();
    } else if (actionType === APPROVAL_ACTION_TYPE.cancel) {
      handleCancelRequest();
    }
  };

  return {
    isUpdateLoading,
    handleDeclineRequest,
    handleApproveRequest,
    handleCancelRequest,
    requesterName,
    merchantName,
    description,
    formattedTransactionDate,
    formattedAmountRequested,
    formattedTaxAmount,
    formattedOriginalAmount,
    showAmountInCAD,
    isAdmin,
    isMember,
    form,
    register,
    watch,
    handleSubmit,
    declineReasonValue,
    onSubmit,
  };
};

export default useActionApprovalRequestModal;
