import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { getNavItems } from './Navigation.utils';

const Navigation = () => {
  const items = getNavItems();

  const selectedItem = items.find(
    (item) => item.route === location.pathname || item?.subRoutes?.includes(location.pathname)
  );

  return (
    <div className="tw-flex tw-flex-wrap tw-mb-3 tw-justify-start">
      {items.map(({ label, route }) => {
        const isSelected = selectedItem?.label === label;

        return (
          <Link
            key={label}
            to={route}
            className={cx(
              'tw-items-center tw-flex tw-border tw-rounded-md hover:tw-text-primary-dark-green hover:tw-border-secondary-pastel-green-80 hover:tw-bg-secondary-light-green tw-m-2 tw-p-2',
              isSelected
                ? 'tw-bg-secondary-light-green tw-border-secondary-pastel-green-80 tw-text-primary-dark-green'
                : 'tw-border-neutral-grey-4 tw-neutral-grey-1'
            )}
          >
            <span className="tw-text-sm">{label}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default Navigation;
