import React, { useState, createContext, useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import {
  GET_ACCOUNTING_INTEGRATION_VENDOR_MAPPINGS,
  GET_ACCOUNTING_INTEGRATION_VENDORS_COUNT,
  SYNC_ACCOUNTING_INTEGRATION_VENDORS,
  UPDATE_ACCOUNTING_INTEGRATION_VENDOR_MAPPINGS,
} from 'graphql/accountingServices';
import { useDeepEffect } from 'hooks';
import { MerchantVendorMappingContextType, VendorMappingPayload, MappingStatusType } from '../AccountingServices.types';
import { PageData } from 'types/pagination';
import { BorrowerMerchant } from 'types/accounting';

export const MerchantVendorMappingContext = createContext<MerchantVendorMappingContextType>(
  {} as MerchantVendorMappingContextType
);

export const MerchantVendorMappingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [page, setPage] = useState<number>(0);
  const [numPerPage, setNumPerPage] = useState<number>(25);
  const [queryString, setQueryString] = useState('');
  const [mappingStatus, setMappingStatus] = useState<MappingStatusType>(MappingStatusType.all);
  const [lastRowSaved, setLastRowSaved] = useState({});

  const [getMappings, { data: mappings, loading: loadingMappings, error: loadingMappingsQueryError }] = useLazyQuery<{
    borrowerMerchants: { items: BorrowerMerchant[]; pageData: PageData };
  }>(GET_ACCOUNTING_INTEGRATION_VENDOR_MAPPINGS, {
    variables: {
      page: page.toString(),
      numPerPage: numPerPage.toString(),
      queryString: queryString,
      mappingStatus: mappingStatus,
    },
  });

  const noMappingsFound = mappings?.borrowerMerchants?.items?.length === 0;

  const { data: totalAccountingVendors } = useQuery(GET_ACCOUNTING_INTEGRATION_VENDORS_COUNT, {
    fetchPolicy: 'network-only',
  });
  const vendorsAvailable = totalAccountingVendors?.accountingIntegrationVendors?.pageData?.totalCount > 0;

  const [updateMapping, { loading: updatingMapping, error: updateMappingError }] = useMutation<{
    vendorMapping: (data: VendorMappingPayload) => {
      data: { updateMerchantAccountingIntegrationVendorMapping: { vendorId: string } };
    };
  }>(UPDATE_ACCOUNTING_INTEGRATION_VENDOR_MAPPINGS, {
    refetchQueries: [
      {
        query: GET_ACCOUNTING_INTEGRATION_VENDOR_MAPPINGS,
        variables: {
          page: page.toString(),
          numPerPage: numPerPage.toString(),
          queryString: queryString,
          mappingStatus: mappingStatus,
        },
        fetchPolicy: 'network-only',
      },
    ],
    awaitRefetchQueries: true,
  });

  const [syncVendors, { loading: isSyncingVendors, error: syncingError }] = useMutation<{
    syncAccountingIntegrationVendors: Boolean;
  }>(SYNC_ACCOUNTING_INTEGRATION_VENDORS, {
    refetchQueries: [
      { query: GET_ACCOUNTING_INTEGRATION_VENDORS_COUNT, fetchPolicy: 'network-only' },
      {
        query: GET_ACCOUNTING_INTEGRATION_VENDOR_MAPPINGS,
        variables: {
          page: page.toString(),
          numPerPage: numPerPage.toString(),
          queryString: queryString,
          mappingStatus: mappingStatus,
        },
        fetchPolicy: 'network-only',
      },
    ],
    awaitRefetchQueries: true,
    onCompleted(response) {
      toast[response.syncAccountingIntegrationVendors ? 'success' : 'warning'](
        response.syncAccountingIntegrationVendors
          ? 'Sync in progress. Please return to this the page or refresh in a few minutes to view your Vendors.'
          : 'No vendors were synced this time'
      );
    },
  });

  const [mappingRequest, setMappingRequest] = useState<VendorMappingPayload | null>(null);

  useDeepEffect(async () => {
    if (mappingRequest) {
      try {
        setLastRowSaved({ [mappingRequest.borrowerMerchantId]: true });
        await updateMapping({
          variables: mappingRequest,
        });
      } catch (err) {
        console.error(err);
        toast.error('Error updating vendor mapping - please try again');
      }
    }
  }, [mappingRequest]);

  useEffect(() => {
    if (!loadingMappingsQueryError?.message && !updateMappingError?.message && !syncingError?.message) return;

    const error = loadingMappingsQueryError || updateMappingError || syncingError;

    toast.error(`Error: ${error?.message}. Please try again`, { autoClose: false });
    console.error(error);
  }, [loadingMappingsQueryError, updateMappingError, syncingError]);

  return (
    <MerchantVendorMappingContext.Provider
      value={{
        mappings,
        getMappings,
        setMappingRequest,
        isUpdatingMapping: updatingMapping,
        isLoading: loadingMappings,
        page,
        setPage,
        numPerPage,
        setNumPerPage,
        syncVendors,
        isSyncingVendors,
        queryString,
        setQueryString,
        mappingStatus,
        setMappingStatus,
        vendorsAvailable,
        noMappingsFound,
        lastRowSaved,
        totalCount: mappings?.borrowerMerchants?.pageData.totalCount || 0,
      }}
    >
      {children}
    </MerchantVendorMappingContext.Provider>
  );
};
