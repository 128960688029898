import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const Payments1 = ({ size = '146', ...other }) => {
  const width = size;
  const height = (106 * width) / 146;

  return (
    <svg width={width} height={height} viewBox="0 0 122 106" fill="none" {...other}>
      <path
        d="M55.3679 38.5371H19.6045C16.6418 38.5371 14.24 40.9389 14.24 43.9016V65.3597C14.24 68.3224 16.6418 70.7242 19.6045 70.7242H55.3679C58.3306 70.7242 60.7324 68.3224 60.7324 65.3597V43.9016C60.7324 40.9389 58.3306 38.5371 55.3679 38.5371Z"
        fill="#E8F3FA"
      />
      <path
        d="M63.9539 74.0378C64.0636 73.9752 64.1793 74.1096 64.1011 74.2087L56.2503 84.1539C56.2213 84.1906 56.2175 84.2413 56.2407 84.282L62.5242 95.2847C62.5868 95.3944 62.4524 95.5101 62.3533 95.4318L52.4081 87.5811C52.3713 87.5521 52.3206 87.5483 52.28 87.5715L41.2773 93.855C41.1676 93.9176 41.0519 93.7832 41.1301 93.684L48.9809 83.7389C49.0099 83.7021 49.0137 83.6514 48.9905 83.6107L42.707 72.608C42.6444 72.4984 42.7788 72.3827 42.8779 72.4609L52.8231 80.3116C52.8599 80.3407 52.9106 80.3445 52.9513 80.3212L63.9539 74.0378Z"
        fill="#FFE8C3"
      />
      <path
        d="M24.4847 17.0806C24.5962 17.14 24.5441 17.3095 24.4185 17.2961L15.5434 16.349C15.4968 16.3441 15.4519 16.3679 15.4299 16.4092L11.2295 24.2845C11.17 24.3959 11.0005 24.3438 11.0139 24.2183L11.961 15.3432C11.966 15.2966 11.9422 15.2517 11.9008 15.2296L4.0255 11.0293C3.91409 10.9698 3.96619 10.8003 4.09174 10.8137L12.9668 11.7608C13.0134 11.7658 13.0583 11.7419 13.0804 11.7006L17.2808 3.82528C17.3402 3.71387 17.5097 3.76597 17.4963 3.89153L16.5492 12.7666C16.5443 12.8132 16.5681 12.8581 16.6094 12.8802L24.4847 17.0806Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M77.0275 87.4392L112.68 83.4371C113.437 83.3521 113.982 82.6693 113.897 81.912L111.742 62.7144C111.657 61.9571 110.975 61.4121 110.217 61.4971L74.5647 65.4992C73.8074 65.5843 73.2623 66.2671 73.3474 67.0244L75.5023 86.2219C75.5873 86.9792 76.2702 87.5243 77.0275 87.4392Z"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M95.8416 78.7036C99.6282 78.2785 102.353 74.8643 101.928 71.0777C101.503 67.2911 98.0889 64.566 94.3023 64.9911C90.5157 65.4162 87.7906 68.8304 88.2157 72.617C88.6407 76.4036 92.055 79.1287 95.8416 78.7036Z"
        fill={primaryDarkGreenColor}
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.3904 81.3227C98.177 80.8977 100.902 77.4835 100.477 73.6969C100.052 69.9103 96.6377 67.1852 92.8511 67.6102C89.0645 68.0353 86.3395 71.4495 86.7645 75.2361C87.1896 79.0227 90.6038 81.7478 94.3904 81.3227Z"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.358 68.1979C110.54 68.4021 108.715 67.8755 107.285 66.734C105.855 65.5925 104.937 63.9297 104.732 62.1113"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.9625 72.5076C74.8629 72.4065 75.7345 72.1291 76.5277 71.6912C77.3209 71.2533 78.02 70.6634 78.5852 69.9553C79.1504 69.2471 79.5706 68.4346 79.8217 67.5641C80.0729 66.6936 80.1502 65.7821 80.0491 64.8817"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.281 76.4257C111.463 76.6298 109.8 77.5479 108.659 78.978C107.517 80.4082 106.991 82.2332 107.195 84.0516"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.8855 80.7354C75.7859 80.6343 76.6973 80.7115 77.5679 80.9627C78.4384 81.2139 79.2509 81.6341 79.959 82.1993C80.6672 82.7645 81.257 83.4636 81.695 84.2568C82.1329 85.0499 82.4103 85.9216 82.5114 86.822"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.299 59.4446L120.355 45.5021C121.057 45.2059 121.387 44.3966 121.09 43.6944L113.583 25.8948C113.287 25.1927 112.477 24.8635 111.775 25.1597L78.7189 39.1023C78.0167 39.3984 77.6876 40.2077 77.9838 40.9099L85.4913 58.7095C85.7875 59.4117 86.5968 59.7408 87.299 59.4446Z"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M102.866 45.7349C106.377 44.254 108.023 40.2075 106.542 36.6966C105.061 33.1857 101.014 31.5401 97.5035 33.0209C93.9926 34.5017 92.3469 38.5483 93.8277 42.0591C95.3086 45.57 99.3551 47.2157 102.866 45.7349Z"
        fill={primaryDarkGreenColor}
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.217 48.6577C105.728 47.1769 107.374 43.1303 105.893 39.6195C104.412 36.1086 100.365 34.4629 96.8545 35.9437C93.3437 37.4246 91.698 41.4711 93.1788 44.982C94.6596 48.4929 98.7062 50.1385 102.217 48.6577Z"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.728 30.9804C114.042 31.6915 112.143 31.7038 110.448 31.0144C108.753 30.3251 107.401 28.9907 106.69 27.3047"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.128 45.9947C80.9629 45.6426 81.7201 45.1295 82.3567 44.4847C82.9932 43.8399 83.4965 43.0761 83.8378 42.2368C84.1791 41.3975 84.3518 40.4993 84.3459 39.5932C84.3401 38.6872 84.1559 37.7912 83.8038 36.9564"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.945 38.6078C117.259 39.3189 115.925 40.6707 115.235 42.3657C114.546 44.0607 114.558 45.9601 115.269 47.6461"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.3452 53.624C84.18 53.2719 85.076 53.0877 85.982 53.0818C86.888 53.076 87.7863 53.2487 88.6256 53.59C89.4649 53.9313 90.2287 54.4346 90.8735 55.0711C91.5183 55.7077 92.0314 56.4649 92.3835 57.2998"
        stroke="#4F5154"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.4358 24.33L64.2562 28.9571L68.8832 31.1367"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.1069 28.6504L80.741 23.0291C83.8657 21.9056 87.321 23.6256 88.4428 26.7455L88.8252 27.809"
        stroke="#4F5154"
        strokeWidth="0.574331"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.612 34.7783H21.8486C18.8859 34.7783 16.4841 37.1801 16.4841 40.1428V61.6009C16.4841 64.5636 18.8859 66.9654 21.8486 66.9654H57.612C60.5748 66.9654 62.9765 64.5636 62.9765 61.6009V40.1428C62.9765 37.1801 60.5748 34.7783 57.612 34.7783Z"
        stroke="#4F5154"
        strokeWidth="0.7"
        strokeLinejoin="round"
      />
      <path
        d="M57.0935 34.7789V31.4261C57.0932 30.6037 56.9114 29.7916 56.5612 29.0476C56.2109 28.3036 55.7007 27.6461 55.0671 27.122C54.4334 26.5979 53.6919 26.22 52.8954 26.0155C52.099 25.8109 51.2671 25.7847 50.4594 25.9386L21.0261 30.9623C19.7479 31.2059 18.5948 31.8878 17.7656 32.8906C16.9365 33.8934 16.4832 35.1541 16.4841 36.4553V41.9316"
        stroke="#4F5154"
        strokeWidth="0.7"
        strokeLinejoin="round"
      />
      <path
        d="M52.2475 54.0976C51.6094 54.0976 50.9856 53.9084 50.455 53.5539C49.9245 53.1994 49.5109 52.6955 49.2667 52.106C49.0225 51.5164 48.9586 50.8677 49.0831 50.2418C49.2076 49.616 49.5149 49.0411 49.9661 48.5899C50.4173 48.1387 50.9922 47.8314 51.6181 47.7069C52.2439 47.5824 52.8926 47.6463 53.4822 47.8905C54.0717 48.1347 54.5756 48.5482 54.9301 49.0788C55.2846 49.6094 55.4739 50.2332 55.4739 50.8713C55.4739 51.727 55.1339 52.5476 54.5289 53.1527C53.9238 53.7577 53.1032 54.0976 52.2475 54.0976Z"
        stroke="black"
        strokeWidth="0.7"
      />
    </svg>
  );
};

export default Payments1;
