import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

import { CREATE_CARD, GET_CREDIT_CARDS_FOR_LIST } from 'graphql/cards';
import { ampTrackEvent } from 'amplitude';
import { CreditCardContext } from 'context/CreditCard';
import { toast } from 'react-toastify';

const useCreateCreditCards = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { setCreditCard } = useContext(CreditCardContext);
  const [createCard, { loading: isSubmitting, error }] = useMutation(CREATE_CARD, {
    refetchQueries: [{ query: GET_CREDIT_CARDS_FOR_LIST }],
    awaitRefetchQueries: true,
  });

  const createCreditCard = async (cardFormData) => {
    const { virtual, nameOnCard, displayName, address, pin, refetch, contactId } = cardFormData;
    try {
      const response = await createCard({
        variables: { virtual, nameOnCard, displayName, address, pin, contactId },
      });

      if (response.data && response.data.createCreditCard) {
        refetch && refetch();
        ampTrackEvent('createVirtualCard: success');
        setCreditCard && setCreditCard(response.data.createCreditCard);
        setHasSubmitted(true);
      }
    } catch (err) {
      console.error(err);

      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        err.graphQLErrors.forEach((error) => {
          if (error.message.match(/\[.+\]/g)) {
            return;
          }
          toast.error(error.message);
        });
      } else {
        toast.error('Failed to create card. Please try again.');
      }

      ampTrackEvent('createVirtualCard: error');
      setHasSubmitted(false);
    }
  };

  return { createCreditCard, isSubmitting, hasSubmitted, setHasSubmitted, error };
};

export default useCreateCreditCards;
