import { get } from 'lodash';

import { paymentStatus } from 'constants/index';
import { decrypt } from 'utility/encryption';

class CreditCard {
  constructor(attributes) {
    for (const [key, value] of Object.entries(attributes || {})) {
      this[key] = value;
    }
  }

  cardNumber() {
    return decrypt(this.encryptedCardNumber);
  }

  cvc2() {
    return decrypt(this.encryptedCvc2);
  }

  expirationDate() {
    return decrypt(this.encryptedExpirationDate);
  }

  pin() {
    return decrypt(this.encryptedPin);
  }

  isPaymentDue() {
    if (!this.paymentDate) return false;

    return new Date(`${this.paymentDate} 00:00:00`) < new Date();
  }

  latestStatement() {
    if (!this.statements || this.statements.length === 0) return null;

    return this.statements[0];
  }

  isUnpaid() {
    const latestStatement = this.latestStatement();

    return get(latestStatement, 'payment.status') === paymentStatus.UNPAID.toUpperCase();
  }

  isDelinquent() {
    const latestStatement = this.latestStatement();

    return get(latestStatement, 'payment.status') === paymentStatus.MISSED.toUpperCase();
  }
}

export default CreditCard;
