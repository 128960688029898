import React from 'react';
import { isNull } from 'lodash';

import useSetPageTitle from 'hooks/useSetPageTitle';
import Layout from 'components/onboarding/Layout';
import Loading from 'components/onboarding/Loading';
import Progress from 'components/onboarding/Progress';
import InitialOwnership from 'components/onboarding/BusinessContacts/InitialOwnership';
import ContactsAndShareholders from 'components/onboarding/BusinessContacts/ContactsAndShareholders';
import useOnboardingRedirect from 'hooks/useOnboardingRedirect';
import { ONBOARDING_STEPS } from 'components/onboarding/constants';

const Content = ({ hasOwnership, children }) => (
  <div className="tw-w-full lg:tw-w-4/5 tw-flex tw-flex-col tw-items-center tw-flex-grow">
    <Progress currentStep={5} hasOwnership={hasOwnership} />
    <div className="tw-w-full lg:tw-w-2/5 tw-mt-16 tw-flex tw-flex-col">
      <h1 data-testid="business-contacts-title" className="tw-mb-4">
        {ONBOARDING_STEPS.businessContacts.title}
      </h1>
      {children}
    </div>
  </div>
);

const BusinessContacts = (props) => {
  const {
    loading,
    contacts,
    refetchContacts,
    isCorporation,
    isPartnership,
    hasCorporateOwnershipDocument,
    corporateOwnership,
  } = props;
  useSetPageTitle(ONBOARDING_STEPS.businessContacts.label);

  const { loading: isLoadingDetails } = useOnboardingRedirect();

  const isLoading = isLoadingDetails || loading;

  const hasOwnership = [...contacts].every((owner) => !isNull(owner.ownership));

  return (
    <Layout>
      <Content hasOwnership={hasOwnership}>
        {isLoading && <Loading />}

        {!isLoading &&
          (hasOwnership ? (
            <ContactsAndShareholders
              contacts={contacts}
              refetchContacts={refetchContacts}
              isCorporation={isCorporation}
              isPartnership={isPartnership}
              hasCorporateOwnershipDocument={hasCorporateOwnershipDocument}
              corporateOwnership={corporateOwnership}
            />
          ) : (
            <InitialOwnership contacts={contacts} refetchContacts={refetchContacts} />
          ))}
      </Content>
    </Layout>
  );
};

export default BusinessContacts;
