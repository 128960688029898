import React, { useContext, useEffect } from 'react';

import Button from 'components/Button';
import MappingTableHeader from '../MappingTableHeader';
import MappingRow from '../MappingRow';
import MappingRowSkeleton from '../MappingRowSkeleton';
import { MerchantVendorMappingContext } from 'components/settings/AccountingServices/contexts/MerchantVendorMappingContext';
import PaginationControl from 'components/UI/PaginationControl';
import { SearchInput } from 'components/UI';
import { Checkbox } from 'components/FormFields/v2';
import { MappingStatusType } from 'components/settings/AccountingServices/AccountingServices.types';

const MappingTable = ({ accountName }: { accountName: string }) => {
  const {
    isLoading,
    mappings,
    getMappings,
    setMappingRequest,
    isUpdatingMapping,
    page,
    numPerPage,
    totalCount,
    setPage,
    setNumPerPage,
    syncVendors,
    queryString,
    setQueryString,
    mappingStatus,
    setMappingStatus,
    isSyncingVendors,
    vendorsAvailable,
    noMappingsFound,
    lastRowSaved,
  } = useContext(MerchantVendorMappingContext);

  useEffect(() => {
    getMappings();
  }, []);

  return (
    <div className="tw-flex tw-flex-col">
      <h3 className="tw-font-semibold">Merchant Rules</h3>
      <small className="tw-pt-2 tw-text-neutral-grey-2">
        Map your Loop merchant names to your {accountName} vendors to automatically categorize your transactions. Your
        preferences will be saved for future syncs.
      </small>
      <small className="tw-pb-2 tw-text-neutral-grey-2">
        You can also change the {accountName} account, sales tax and date if you need to do so before posting.
      </small>
      <div className="tw-flex tw-flex-row tw-justify-between">
        <div className="tw-my-2 tw-w-2/5">
          <Button primary onClick={syncVendors} isFetching={isSyncingVendors}>
            Sync {accountName} Vendors
          </Button>
        </div>
        {vendorsAvailable && (
          <div className="tw-my-2 tw-flex">
            <Checkbox
              className="tw-relative tw-cursor-pointer tw-text-sm"
              label="Show only unmapped merchants"
              checked={mappingStatus === MappingStatusType.unmapped}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setMappingStatus(e.target.checked ? MappingStatusType.unmapped : MappingStatusType.all)
              }
            />
            <div className="tw-relative tw-pl-2">
              <SearchInput
                placeholder="Search by Merchant"
                value={queryString}
                onChange={(e) => setQueryString(e.currentTarget.value)}
              />
            </div>
          </div>
        )}
      </div>
      {vendorsAvailable && (
        <div>
          <table className="tw-mb-2 tw-w-full">
            <MappingTableHeader accountName={accountName} />
            {isLoading && <MappingRowSkeleton />}
            {!isLoading && noMappingsFound && <div>No merchants found</div>}
            {!isLoading && !noMappingsFound && (
              <tbody>
                {mappings?.borrowerMerchants?.items?.map((item) => (
                  <MappingRow
                    key={item.id}
                    borrowerMerchant={item}
                    setMappingRequest={setMappingRequest}
                    isUpdatingMapping={isUpdatingMapping}
                    lastRowSaved={lastRowSaved}
                  />
                ))}
              </tbody>
            )}
          </table>
          {!!mappings?.borrowerMerchants.pageData.totalCount && (
            <PaginationControl
              page={page}
              numPerPage={numPerPage}
              numberOfItems={totalCount}
              onChangePage={(val) => setPage(val)}
              onChangeNumberPerPage={(perPage) => setNumPerPage(perPage)}
              numPerPageOptionsList={[25, 50, 75, 100]}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MappingTable;
