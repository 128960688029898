import { PAYEE_TYPES } from 'components/payments/AddPayee/constants';

const { SUPPLIER, CRA, CREDIT_CARD } = PAYEE_TYPES;

export const PAYEE_OPTION_TITLE = {
  [SUPPLIER]: 'Domestic / International Business or Person',
  [CREDIT_CARD]: 'External Credit Card',
  [CRA]: 'Canada Revenue Agency',
};

export const PAYEE_OPTION_DESCRIPTION = {
  [SUPPLIER]:
    "Add a bank account for your supplier's location in Canada or send money to an account outside of Canada.",
  [CREDIT_CARD]:
    "Add a credit card held with any Canadian financial institution. The account must be held in the business or director's name and used for business.",
  [CRA]:
    'Make a payment on your government tax accounts including: Corporate Income Tax, Various Provincial Taxes, GST/HST/PST or payroll deductions.',
};

export const PAYEE_OPTION_SUB_DESCRIPTION = {
  [SUPPLIER]: 'Send payments in CAD, USD, GBP, EUR & 17+ more currencies',
  [CREDIT_CARD]: 'Send payments in CAD to Canadian credit cards',
  [CRA]: 'Send payments in CAD to Canadian Revenue Agency',
};
