import React from 'react';
import { BiCheck } from 'react-icons/bi';

import { CompleteProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { useComplete } from './hooks';
import { Footer } from 'components/Accounts/AccountsContent/components/AccountsModal/components';
import { ExchangeRateInfo } from 'components/Accounts/AccountsContent/components/AccountsModal/components/ConvertFunds/components/Steps/components';
import { Banner } from 'components/UI';
import { BannerSize } from 'components/UI/Banner';
import CoinSwap from 'components/svg/CoinSwap';
import { Loaders } from 'components/cards/Loader';

const Complete = ({ onFinish, onResetSteps }: CompleteProps) => {
  const {
    toAccountName,
    fromAccountName,
    chargedAmount,
    formattedOriginalAmount,
    originalAmountCurrency,
    transferMethod,
    transactionGroupId,
    formattedInitiatedAtDate,
    handleNewTransfer,
    rate,
    navigateToSubscriptions,
    showSavingsComponent,
    loading,
    formattedSavingsAmount,
  } = useComplete({
    onFinish,
    onResetSteps,
  });

  if (loading) return <Loaders.Light />;

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <div className="tw-p-2 tw-flex tw-justify-center tw-items-center tw-rounded-md tw-bg-secondary-light-green tw-mb-8">
          <div className="tw-mr-2 tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-semantic-success">
            <BiCheck className="tw-text-secondary-light-green" />
          </div>
          <small className="tw-text-semantic-success">Your transfer is complete.</small>
        </div>
        {showSavingsComponent && (
          <Banner
            icon={<CoinSwap width={40} />}
            iconAlignment="center"
            title={`You could have saved ${formattedSavingsAmount} on this conversion`}
            message="Access even lower FX Fees when you make payments with Loop Power."
            submitButtonLabel="Upgrade Now"
            size={BannerSize.sm}
            onSubmit={navigateToSubscriptions}
          />
        )}
        <div className="tw-flex tw-flex-col tw-py-2">
          <small>From Account</small>
          <p className="tw-mt-1">{fromAccountName}</p>
          <small className="tw-mt-4">To Account</small>
          <p className="tw-mt-1">{toAccountName}</p>
          <small className="tw-mt-4">Amount</small>
          <p className="tw-mt-1">
            {originalAmountCurrency} {formattedOriginalAmount}
          </p>
          <ExchangeRateInfo chargedAmount={chargedAmount} rate={rate} exchangeRateConfirmed />
          <small className="tw-mt-4">Method</small>
          <p className="tw-mt-1">{transferMethod}</p>
          <small className="tw-mt-4">Reference ID</small>
          <p className="tw-mt-1">{transactionGroupId}</p>
          <small className="tw-mt-4">Initiated at</small>
          <p className="tw-mt-1">{formattedInitiatedAtDate}</p>
        </div>
      </div>
      <Footer
        onNavigateBack={handleNewTransfer}
        onSubmit={onFinish}
        navigateBackLabel="Convert More Funds"
        submitLabel="Close"
      />
    </>
  );
};

export default Complete;
