import React from 'react';
import cx from 'classnames';
import { IoSearchOutline } from 'react-icons/io5';

import { SearchInputProps } from './SearchInput.types';

const SearchInput = ({ value, onChange, classNames = '', placeholder = 'Search' }: SearchInputProps) => (
  <div className={cx('tw-relative tw-flex-grow tw-y-4', classNames)}>
    <div className="tw-absolute tw-py-2.5 tw-pl-3 tw-flex tw-items-center tw-text-neutral-grey-1">
      <IoSearchOutline size={24} className="tw-mr-2" />
    </div>
    <input
      className="tw-block tw-px-3 tw-py-2 tw-pl-12 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input"
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  </div>
);

export default SearchInput;
