import React from 'react';

import { PADAgreementModal } from './components';
import { usePADAgreement } from './hooks';
import { PADAgreementProps } from './PADAgreement.types';

const PADAgreement = ({
  payeeName,
  payeeAddress,
  payeeEmail,
  payorBankName,
  payorBankAccountNumber,
  payorBankRoutingNumber,
  payorBankInstitutionNumber,
  payorBankTransitNumber,
  loading,
}: PADAgreementProps) => {
  const {
    payorEmail,
    payorBusinessName,
    payorRepresentativeName,
    signInDate,
    isShowPADModal,
    openPADModal,
    closePADModal,
    isUSBankAccount,
  } = usePADAgreement({ payorBankRoutingNumber });

  return (
    <div className="tw-text-sm tw-my-8">
      {isUSBankAccount ? (
        <>
          I, the Authorized Signer of the Payor, authorize <b>{payeeName}</b> to initiate ACH debit transactions using
          the Loop platform in accordance with the{' '}
          <span role="button" aria-label="Open PAD Agreement modal" className="text-underline" onClick={openPADModal}>
            Authorization Agreement for Preauthorized Payments via ACH Debit
          </span>
          .
        </>
      ) : (
        <>
          I, <b>{payorRepresentativeName}</b>, of the Payor, hereby agree to enter into this Pre-Authorized Debit (PAD)
          Agreement on behalf of the Payor and authorize Loop Payments Inc. on behalf of <b>{payeeName}</b> to debit the
          Payor’s deposit account in accordance with the{' '}
          <span role="button" aria-label="Open PAD Agreement modal" className="text-underline" onClick={openPADModal}>
            Pre-Authorized Debit Agreement
          </span>
          . I hereby agree to waive the right to receive pre-notification of the amount of the PAD.
        </>
      )}

      <PADAgreementModal
        show={isShowPADModal}
        onClose={closePADModal}
        signInDate={signInDate}
        payeeName={payeeName}
        payeeAddress={payeeAddress}
        payeeEmail={payeeEmail}
        payorBusinessName={payorBusinessName}
        payorRepresentativeName={payorRepresentativeName}
        payorEmail={payorEmail}
        payorBankName={payorBankName}
        payorBankAccountNumber={payorBankAccountNumber}
        payorBankRoutingNumber={payorBankRoutingNumber}
        payorBankInstitutionNumber={payorBankInstitutionNumber}
        payorBankTransitNumber={payorBankTransitNumber}
        loading={loading}
        isUSBankAccount={isUSBankAccount}
      />
    </div>
  );
};

export default PADAgreement;
