import React from 'react';

type ProductCommunicationCardProps = {
  title: string;
  titleColor: string;
  image: React.ReactNode | JSX.Element | string;
  backgroundColor?: string;
  backgroundColorOpacity?: number;
  subtitle?: string;
  onClick?: () => void;
};

const ProductCommunicationCard = ({
  title,
  titleColor,
  image,
  backgroundColor,
  backgroundColorOpacity,
  onClick,
  subtitle,
}: ProductCommunicationCardProps) => {
  let backgroundColorOpacityHex;
  if (backgroundColorOpacity && backgroundColorOpacity >= 1 && backgroundColorOpacity <= 255) {
    backgroundColorOpacityHex = backgroundColorOpacity.toString(16);
  }

  const containerStyle: React.CSSProperties = {
    backgroundColor: `${backgroundColor}${backgroundColorOpacityHex}`,
  };

  return (
    <div
      onClick={onClick}
      style={containerStyle}
      className={`tw-group tw-flex tw-flex-col tw-items-center tw-cursor-pointer tw-gap-y-3 tw-rounded-md tw-px-6 tw-py-4 tw-shadow-md hover:tw-shadow-lg
      tw-w-1/4`}
    >
      <div className="tw-flex tw-flex-row tw-h-full tw-justify-between tw-justify-content-center">
        <div className="tw-flex tw-flex-col tw-h-full tw-mr-4 tw-justify-center">
          <div className={`tw-text-m tw-font-semibold xl:tw-text-lg xl:tw-font-bold ${titleColor}`}>{title}</div>
          <div className={`tw-text-s tw-font-medium xl:tw-text-m xl:tw-font-medium ${titleColor}`}>{subtitle}</div>
        </div>
        <div
          className={`tw-transform group-hover:tw-rotate-2 group-hover:tw-scale-105 tw-transition tw-duration-1000 tw-ease-in-out
        xl:tw-flex tw-flex-row tw-justify-center tw-items-center tw-flex-grow tw-hidden xl:tw-w-3/5`}
        >
          {typeof image === 'string' ? (
            <img className="tw-object-contain tw-max-h-36 tw-min-h-20" src={image} alt="card image" />
          ) : (
            image
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCommunicationCard;
