import { CURRENCY_CODES, SUPPLIER_SPECIFIC_COUNTRIES } from 'constants/index';

const { CAD, USD, EUR, GBP, AUD, CNY, PHP, DKK, INR } = CURRENCY_CODES;
const { CA, US, EU, GB, AU, CN, PH, DK, IN } = SUPPLIER_SPECIFIC_COUNTRIES;

export const getSpecificCountryFromCurrency = (currency) =>
  ({
    [CAD]: CA,
    [USD]: US,
    [EUR]: EU,
    [GBP]: GB,
    [AUD]: AU,
    [CNY]: CN,
    [PHP]: PH,
    [DKK]: DK,
    [INR]: IN,
  }[currency]);
