import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

type WalletAndCashProps = {
  width?: number;
  height?: number;
};

const WalletAndCash = ({ width = 158, height = 140 }: WalletAndCashProps) => (
  <svg width={width} height={height} viewBox="0 0 158 140" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M67.2395 50.8975H21.34C17.5376 50.8975 14.4551 54.0697 14.4551 57.9827V86.3235C14.4551 90.2366 17.5376 93.4087 21.34 93.4087H67.2395C71.042 93.4087 74.1244 90.2366 74.1244 86.3235V57.9827C74.1244 54.0697 71.042 50.8975 67.2395 50.8975Z"
      fill="#E8F3FA"
    />
    <path
      d="M82.9438 97.6949C83.052 97.6314 83.1657 97.7673 83.0885 97.8679L72.8885 111.165C72.8598 111.202 72.856 111.254 72.8789 111.295L81.0414 126.004C81.103 126.115 80.9701 126.233 80.8726 126.153L67.9532 115.658C67.9171 115.629 67.8671 115.625 67.8269 115.649L53.5318 124.05C53.4236 124.113 53.3099 123.977 53.3871 123.877L63.5871 110.58C63.6158 110.542 63.6196 110.491 63.5967 110.45L55.4342 95.741C55.3726 95.6299 55.5055 95.5121 55.603 95.5914L68.5224 106.087C68.5585 106.116 68.6085 106.12 68.6487 106.096L82.9438 97.6949Z"
      fill="#FFE8C3"
    />
    <path
      d="M32.2967 22.6488C32.4072 22.7094 32.3568 22.8783 32.2322 22.8647L20.6372 21.5913C20.5908 21.5862 20.5463 21.61 20.5248 21.6515L15.0433 32.2277C14.9852 32.34 14.815 32.2862 14.8281 32.1597L16.0641 20.2408C16.0689 20.1941 16.0451 20.1487 16.004 20.1261L5.71509 14.4788C5.60456 14.4181 5.65501 14.2492 5.77956 14.2629L17.3746 15.5362C17.421 15.5413 17.4654 15.5175 17.4869 15.4761L22.9684 4.89984C23.0266 4.78756 23.1967 4.84137 23.1836 4.9679L21.9477 16.8867C21.9428 16.9335 21.9666 16.9789 22.0078 17.0015L32.2967 22.6488Z"
      fill={primaryDarkGreenColor}
    />
    <path
      d="M99.5698 115.484L145.327 110.199C146.299 110.086 146.998 109.185 146.889 108.184L144.124 82.8291C144.015 81.8288 143.138 81.109 142.166 81.2213L96.409 86.5071C95.437 86.6194 94.7375 87.5213 94.8466 88.5215L97.6124 113.877C97.7215 114.877 98.5979 115.597 99.5698 115.484Z"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      opacity="0.5"
      d="M123.715 103.948C128.575 103.386 132.073 98.877 131.527 93.8758C130.982 88.8746 126.6 85.2755 121.74 85.8369C116.88 86.3983 113.383 90.9076 113.928 95.9088C114.474 100.91 118.856 104.509 123.715 103.948Z"
      fill={primaryDarkGreenColor}
    />
    <path
      d="M121.854 107.407C126.714 106.846 130.211 102.337 129.666 97.3355C129.12 92.3343 124.738 88.7351 119.879 89.2965C115.019 89.8579 111.521 94.3673 112.067 99.3685C112.612 104.37 116.994 107.969 121.854 107.407Z"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M144.915 90.0731C142.581 90.3427 140.239 89.6472 138.404 88.1396C136.568 86.632 135.39 84.4358 135.128 82.0342"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95.6362 95.7653C96.7918 95.6318 97.9105 95.2654 98.9284 94.687C99.9464 94.1086 100.844 93.3295 101.569 92.3942C102.294 91.459 102.834 90.3858 103.156 89.2361C103.478 88.0863 103.578 86.8825 103.448 85.6933"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M146.099 100.94C143.766 101.209 141.631 102.422 140.166 104.311C138.701 106.199 138.026 108.61 138.288 111.011"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.8213 106.632C97.9769 106.499 99.1466 106.601 100.264 106.933C101.381 107.264 102.424 107.819 103.333 108.566C104.242 109.312 104.999 110.236 105.561 111.283C106.123 112.331 106.479 113.482 106.609 114.671"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M112.753 78.511L155.178 60.0963C156.079 59.7052 156.501 58.6363 156.121 57.7089L146.486 34.2C146.106 33.2726 145.067 32.8379 144.166 33.229L101.741 51.6437C100.84 52.0349 100.417 53.1038 100.797 54.0312L110.433 77.5401C110.813 78.4675 111.851 78.9022 112.753 78.511Z"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinejoin="round"
    />
    <path
      opacity="0.5"
      d="M132.731 60.4048C137.236 58.449 139.349 53.1045 137.448 48.4675C135.548 43.8305 130.354 41.657 125.848 43.6128C121.342 45.5686 119.23 50.9131 121.131 55.5501C123.031 60.1871 128.225 62.3606 132.731 60.4048Z"
      fill={primaryDarkGreenColor}
    />
    <path
      d="M131.898 64.2656C136.403 62.3098 138.516 56.9653 136.615 52.3283C134.715 47.6913 129.521 45.5177 125.015 47.4735C120.509 49.4293 118.397 54.7739 120.298 59.4109C122.198 64.0479 127.392 66.2214 131.898 64.2656Z"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M149.239 40.9164C147.075 41.8557 144.637 41.8718 142.462 40.9614C140.286 40.0509 138.551 38.2885 137.639 36.0617"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M103.548 60.7477C104.619 60.2826 105.591 59.6049 106.408 58.7534C107.225 57.9018 107.871 56.8929 108.309 55.7844C108.747 54.676 108.968 53.4895 108.961 52.2929C108.953 51.0963 108.717 49.9129 108.265 48.8103"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M153.368 50.9918C151.204 51.931 149.491 53.7163 148.607 55.955C147.722 58.1937 147.738 60.7023 148.65 62.9291"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M107.677 70.8235C108.748 70.3584 109.898 70.1151 111.061 70.1074C112.224 70.0997 113.377 70.3278 114.454 70.7786C115.531 71.2294 116.511 71.8941 117.339 72.7348C118.166 73.5755 118.825 74.5756 119.277 75.6782"
      stroke="#4F5154"
      strokeWidth="0.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.9753 32.1348L83.1779 38.246L89.1164 41.1247"
      stroke="#4F5154"
      strokeWidth="0.574331"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.2695 37.8419L104.335 30.4175C108.345 28.9337 112.78 31.2053 114.219 35.326L114.71 36.7307"
      stroke="#4F5154"
      strokeWidth="0.574331"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.1272 45.9331H25.2277C21.4253 45.9331 18.3428 49.1053 18.3428 53.0183V81.3591C18.3428 85.2722 21.4253 88.4443 25.2277 88.4443H71.1272C74.9297 88.4443 78.0121 85.2722 78.0121 81.3591V53.0183C78.0121 49.1053 74.9297 45.9331 71.1272 45.9331Z"
      stroke="#4F5154"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path
      d="M70.4617 45.9345V41.5062C70.4613 40.4201 70.2281 39.3475 69.7785 38.3649C69.329 37.3822 68.6742 36.5138 67.861 35.8216C67.0477 35.1293 66.0961 34.6303 65.0738 34.3601C64.0516 34.09 62.984 34.0553 61.9473 34.2586L24.172 40.8936C22.5316 41.2154 21.0516 42.1161 19.9875 43.4405C18.9233 44.765 18.3416 46.4301 18.3428 48.1486V55.3814"
      stroke="#4F5154"
      strokeWidth="0.7"
      strokeLinejoin="round"
    />
    <path
      d="M64.2423 71.5622C63.406 71.5622 62.5876 71.307 61.8906 70.8277C61.1934 70.3484 60.6486 69.6659 60.3264 68.8656C60.0043 68.0652 59.9198 67.1839 60.0842 66.3336C60.2485 65.4833 60.654 64.7036 61.2477 64.0926C61.8414 63.4817 62.5965 63.0668 63.4171 62.8989C64.2375 62.7309 65.0881 62.817 65.8616 63.1467C66.6352 63.4765 67.2981 64.0357 67.7652 64.7551C68.2324 65.4746 68.4823 66.3215 68.4823 67.1887C68.4823 68.3518 68.0332 69.4653 67.2369 70.2848C66.4409 71.1039 65.3635 71.5622 64.2423 71.5622Z"
      stroke="black"
      strokeWidth="0.7"
    />
  </svg>
);

export default WalletAndCash;
