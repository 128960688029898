import React from 'react';

import Loader from 'components/cards/Loader';
import { StepsProps } from '../../Steps.types';
import { FormFooter } from '../';
import { SkipConfirmationModal } from './components';
import { useConnectAccount } from './hooks';
import BankAccountConnection from 'components/BankAccountConnection';
import { BankAccountCurrency } from 'types/bankAccount';

const ConnectAccount = ({ onNextStep }: Pick<StepsProps, 'onNextStep'>) => {
  const {
    isLoading,
    isError,
    vendorName,
    formattedAmount,
    currency,
    formattedDueDate,
    onSkip,
    onSubmit,
    showSkipConfirmationModal,
    handleConfirmSkipping,
    closeSkipConfirmationModal,
    isBankAccountConnected,
    isBankAccountsConnectionError,
    redirectRoute,
    isManualForm,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  } = useConnectAccount({ onNextStep });

  if (isLoading) return <Loader />;

  if (isError) return <div>Something went wrong. Please try again later.</div>;

  return (
    <div className="tw-w-full md:tw-w-1/2 lg:tw-w-2/5 tw-flex tw-flex-col">
      <h2 className="tw-mb-4">Connect your Bank Account</h2>
      <p>
        Your vendor, <b>{vendorName}</b>, requested a {!isOngoing ? 'payment' : 'recurring payment'} (
        <b>
          {currency} {formattedAmount}
        </b>
        ){' '}
        {!isOngoing ? (
          <>
            due on <b>{formattedDueDate}</b>.
          </>
        ) : (
          <>
            with the following schedule:
            <ul>
              <li>
                - Frequency: <b>{frequencyLabel}</b>
              </li>
              <li>
                - Start date: <b>{formattedStartDate}</b>
              </li>
              {isStoppedByEndDate && formattedEndDate && (
                <li>
                  - End date: <b>{formattedEndDate}</b>
                </li>
              )}
              {isStoppedByNumberOfOccurrences && numberOfOccurrences && (
                <li>
                  - Number of payments: <b>{numberOfOccurrences}</b>
                </li>
              )}
              {isStoppedByCancel && <li>- The payment will stop upon cancellation by vendor</li>}
            </ul>
          </>
        )}
      </p>
      <p>To pay your vendor, please connect your bank account.</p>

      {currency && (
        <BankAccountConnection
          isConnected={isBankAccountConnected}
          currency={currency as unknown as BankAccountCurrency}
          onCancel={onSkip}
          onCancelLabel="Skip"
          onSubmit={onSubmit}
          isManualForm={isManualForm || isBankAccountsConnectionError}
          isManualFormInfo
          isPayor={true}
          flinksRedirectRoute={redirectRoute}
        />
      )}

      {!isManualForm && (
        <FormFooter
          isDisabled={isError || !isBankAccountConnected}
          cancelButtonLabel="Skip"
          submitButtonLabel="Continue"
          onCancel={onSkip}
          onSubmit={onSubmit}
        />
      )}
      <SkipConfirmationModal
        show={showSkipConfirmationModal}
        onClose={closeSkipConfirmationModal}
        onSubmit={handleConfirmSkipping}
      />
    </div>
  );
};

export default ConnectAccount;
