import React from 'react';

import useIsMobile from 'hooks/useIsMobile';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import OnboardingDashboardLayout from 'components/OnboardingDashboard/components/OnboardingDashboardLayout';

import PayeesDesktop from './desktop/payees/Payees';
import PayeesMobile from './mobile/Payees';

const Payees = (props) => {
  const isMobile = useIsMobile();
  const { isOnboardingPreview } = props;

  const Layout = isOnboardingPreview ? OnboardingDashboardLayout : DashboardLayout;
  const Component = isMobile ? PayeesMobile : PayeesDesktop;

  return (
    <Layout>
      <Component {...props} />
    </Layout>
  );
};

export default Payees;
