import React from 'react';

import { CreditUserView, PreFundedUserView } from './components';
import { useWhatsChanged } from './hooks';

const Changes = () => {
  const { isCreditUser } = useWhatsChanged();

  if (isCreditUser) {
    return <CreditUserView />;
  }

  return <PreFundedUserView />;
};

export default Changes;
