import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { ampTrackEvent } from 'amplitude';
import { toast } from 'react-toastify';

import { UPDATE_PAYEE, GET_PAYEES } from 'graphql/payees';
import { EMAIL_REGEX } from 'constants/regex';
import { PAYEE_RECORD_TYPES } from 'constants/index';
import { TextField, SubmitButton } from 'components/FormFields/v2';
import { Modal } from 'components/UI';
import { Loaders } from 'components/cards/Loader';

const EditPayee = ({ show, hide, payee, allowEditEmail = true }) => {
  const { displayName, email } = payee.record;

  const [updatePayee, { loading, error }] = useMutation(UPDATE_PAYEE, {
    refetchQueries: [{ query: GET_PAYEES }],
    awaitRefetchQueries: true,
  });

  const form = useForm({
    defaultValues: {
      displayName,
      email,
    },
  });

  const { register, reset, handleSubmit } = form;

  const handleSubmitForm = async (data) => {
    try {
      const response = await updatePayee({
        variables: { payeeId: payee.id, displayName: data.displayName, email: data.email || email || '' },
      });
      hide();
      toast.success('Payee successfully updated');
      if (response.data && response.data.updatePayee) {
        ampTrackEvent('updatePayee: success');
      }
      reset({});
    } catch (error) {
      ampTrackEvent('updatePayee: error');
      console.error(error);
    }
  };

  return (
    <Modal show={show} onClose={hide} title="Edit Payee Details">
      <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          {loading ? (
            <Loaders.Light />
          ) : (
            <div className="tw-border tw-px-8 tw-border-t-2 tw-border-neutral-grey-4 tw-py-8">
              <h3 className="tw-mb-8 tw-font-semibold tw-text-sm">Please enter new payee details</h3>
              <TextField
                rootClass="tw-mb-6"
                name="displayName"
                label="Payee Nickname"
                ref={register({ required: false })}
                defaultValue={displayName}
              />
              {payee.record.__typename === PAYEE_RECORD_TYPES.Supplier && allowEditEmail && (
                <TextField
                  rootClass="tw-mb-6"
                  name="email"
                  placeholder="hi@getloop.com"
                  label="E-mail address"
                  ref={register({ pattern: { value: EMAIL_REGEX, message: 'Invalid Email' } })}
                  defaultValue={email}
                />
              )}
            </div>
          )}
          <div className="tw-px-8 tw-py-4 tw-border-t-2 tw-border-neutral-grey-4 tw-flex tw-justify-end">
            <SubmitButton isFetching={loading}>{loading ? 'Saving...' : 'Save'}</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditPayee;
