import React from 'react';

import { Loaders } from 'components/cards/Loader';
import { InfoTooltipForModals } from 'components/InfoTooltip';
import { FormFooter } from '..';
import { useReview } from './hooks';
import { ReviewProps } from './Review.types';

const Review = ({ onPrevStep, onNextStep }: ReviewProps) => {
  const {
    payorName,
    accountName,
    formattedDueDate,
    formattedAmount,
    currency,
    invoiceNumber,
    handleSubmit,
    loading,
    formattedPaymentRequestFee,
    error,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
    frequencyLabel,
    formattedEndDate,
    numberOfOccurrences,
  } = useReview({ onNextStep, onPrevStep });

  if (loading)
    return (
      <div className="tw-text-center tw-m-12">
        <Loaders.Spinner />
      </div>
    );

  return (
    <>
      <div className="tw-px-8 tw-pt-8 tw-mb-8">
        <div className="tw-text-sm tw-text-neutral-grey-2">Please confirm payment request details</div>
        <div className="tw-flex tw-gap-8 tw-py-8 tw-pb-4">
          <div className="tw-flex tw-flex-col tw-space-y-8 tw-w-1/2">
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">Payor</div>
              <p className="tw-mt-1">{payorName}</p>
            </div>
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">Deposit Account</div>
              <p className="tw-mt-1">{accountName}</p>
            </div>
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">Amount</div>
              <p className="tw-mt-1">{`${currency} ${formattedAmount}`}</p>
            </div>
            <div>
              <div className="tw-text-sm tw-text-neutral-grey-1">
                Fee
                <InfoTooltipForModals
                  message="Fees will not be charged if your customer does not approve the payment request."
                  onlyIcon
                />
              </div>

              <p className="tw-mt-1">{`${currency} ${formattedPaymentRequestFee}`}</p>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-space-y-8 tw-w-1/2">
            {!isOngoing ? (
              <div>
                <div className="tw-text-sm tw-text-neutral-grey-1">Due Date</div>
                <p className="tw-mt-1">{formattedDueDate}</p>
              </div>
            ) : (
              <>
                <div>
                  <div className="tw-text-sm tw-text-neutral-grey-1">Frequency</div>
                  <p className="tw-mt-1">{frequencyLabel}</p>
                </div>
                <div>
                  <div className="tw-text-sm tw-text-neutral-grey-1">Start Date</div>
                  <p className="tw-mt-1">{formattedDueDate}</p>
                </div>
                {isStoppedByEndDate && formattedEndDate && (
                  <div>
                    <div className="tw-text-sm tw-text-neutral-grey-1">End Date</div>
                    <p className="tw-mt-1">{formattedEndDate}</p>
                  </div>
                )}
                {isStoppedByNumberOfOccurrences && numberOfOccurrences && (
                  <div>
                    <div className="tw-text-sm tw-text-neutral-grey-1">Number of Payments</div>
                    <p className="tw-mt-1">{numberOfOccurrences}</p>
                  </div>
                )}
                {isStoppedByCancel && <div>These payments will stop upon cancellation</div>}
              </>
            )}

            {invoiceNumber && (
              <div>
                <div className="tw-text-sm tw-text-neutral-grey-1">Invoice Number</div>
                <p className="tw-mt-1">{invoiceNumber}</p>
              </div>
            )}
          </div>
        </div>

        <div className="tw-text-xs tw-text-neutral-grey-2 tw-mb-4">
          By clicking on the confirm button, an email will be sent to your Payor to settle the invoice.
        </div>
        {error?.message && <div className="tw-text-semantic-error tw-text-xs">{error.message}</div>}
      </div>
      <FormFooter
        onCancel={onPrevStep}
        onSubmit={handleSubmit}
        cancelButtonLabel="Edit details"
        submitButtonLabel="Send Request to Payor"
        loading={loading}
        isDisabled={!!error?.message}
      />
    </>
  );
};

export default Review;
