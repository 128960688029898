import React, { useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { uploadFile } from 'utility/activeStorage';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { themeConfig } from 'styles/tailwind/config';
import { ADD_DOCUMENT, GET_DOCUMENTS } from 'graphql/document';
import CircularProgress from 'components/CircularProgress';
import Layout from 'components/onboarding/Layout';
import Loading from 'components/onboarding/Loading';
import FileUploadForm from './FileUploadForm';

const FileUploadProgress = ({ progress, currentFileNumber, totalFileNumber }) => (
  <div className="tw-flex tw-flex-col tw-justify-center tw-flex-grow">
    <CircularProgress size={250} value={progress} pathColor={themeConfig.colors.primary['dark-green']} clockWise>
      <p>{`Uploading file ${currentFileNumber} of ${totalFileNumber}`}</p>
      <strong className="tw-text-primary-dark-green">{`${progress}%`}</strong>
    </CircularProgress>
  </div>
);

const Content = (props) => {
  const { refetch } = props;
  const [getDocuments] = useLazyQuery(GET_DOCUMENTS, { fetchPolicy: 'network-only' });
  const [addDocument] = useMutation(ADD_DOCUMENT);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentFileNumber, setCurrentFileNumber] = useState(1);
  const [totalFileNumber, setTotalFileNumber] = useState();
  const history = useHistory();

  const updateUploadProgress = ({ total, loaded }) => {
    setProgress(parseInt((loaded * 100) / total));
  };

  const uploadFileAndAddDocument = async ({ file, category, contactId }) => {
    const blob = await uploadFile(file, updateUploadProgress);
    await addDocument({ variables: { blobSignedId: blob.signed_id, category, contactId } });
  };

  const onSubmit = async (filesByCategory) => {
    setTotalFileNumber(Object.values(filesByCategory).reduce((sum, files) => sum + files.length, 0));
    setIsSubmitting(true);

    try {
      for (const [category, files] of Object.entries(filesByCategory)) {
        for (const file of files) {
          setProgress(0);
          const keySplit = category.split('+');
          const categoryKey = keySplit[0];
          const contactId = keySplit[1] || null;
          await uploadFileAndAddDocument({ file: file, category: categoryKey, contactId: contactId });

          const nextFileNumber = currentFileNumber + 1;
          if (nextFileNumber <= files.length) setCurrentFileNumber(currentFileNumber + 1);
        }
      }

      await getDocuments();
      await refetch();

      history.push('/onboarding/dashboard/home');
    } catch (err) {
      console.error(err);
      setIsSubmitting(false);
    }
  };

  return isSubmitting ? (
    <FileUploadProgress progress={progress} totalFileNumber={totalFileNumber} currentFileNumber={currentFileNumber} />
  ) : (
    <FileUploadForm onSubmit={onSubmit} {...props} />
  );
};

const AdditionalDetails = ({ loading, ...contentProps }) => {
  useSetPageTitle('Additional details');

  return <Layout>{loading ? <Loading /> : <Content {...contentProps} />}</Layout>;
};

export default AdditionalDetails;
