import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { CoreExternalAccountAgreement } from 'types/coreExternalAccount';
import { CORE_EXTERNAL_ACCOUNT_AGREEMENTS } from 'graphql/wallets';

const useExternalAccountAgreements = (externalWalletAccountId?: string) => {
  const [agreements, setAgreements] = useState<CoreExternalAccountAgreement[]>([]);

  const [getExternalAccountAgreements, { error, loading }] = useLazyQuery<{
    coreExternalAccountAgreements: CoreExternalAccountAgreement[];
  }>(CORE_EXTERNAL_ACCOUNT_AGREEMENTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAgreements(data.coreExternalAccountAgreements);
    },
  });

  useEffect(() => {
    if (!externalWalletAccountId) return;

    getExternalAccountAgreements({ variables: { externalWalletAccountId } });
  }, [externalWalletAccountId]);

  return {
    agreements,
    error,
    loading,
  };
};

export default useExternalAccountAgreements;
