import React from 'react';

import useCardExpenseDetail from './hooks/useCardExpenseDetail';
import { AttributeRow } from 'components/Approvals/ApprovalsContent/components/ApprovalRequestDetailOverlay/components';
import { CardExpenseDetailProps } from './CardExpenseDetail.types';
import { CurrencyBadge } from '../../../UI';
import { TooltipIcon } from 'utility/transactions';
import CardSendFunds from 'components/svg/CardSendFunds';
import { formatMoneyWithoutCurrencyLabel } from 'utility/currency';
import { ConfirmUnlinkDialog, NoteReceiptForm } from 'components/Transactions/components/TransactionDetail/components';
import { BiUnlink } from 'react-icons/bi';

const CardExpenseDetail = ({ header, cardExpense }: CardExpenseDetailProps) => {
  const {
    formattedTransactionDate,
    formattedAmountRequested,
    formattedDateApproved,
    currency,
    status,
    merchantName,
    fromLabel,
    receipt,
    description,
    declineReason,
    isOpenUnlinkDialog,
    openUnlinkDialog,
    closeUnlinkDialog,
    isMember,
    selectedTab,
  } = useCardExpenseDetail({
    cardExpense,
  });

  const isCompleted = selectedTab === 'Completed';

  return (
    <div className="tw-bg-neutral-grey-3.5 tw-p-6 lg:tw-bg-neutral-light lg:tw-h-full">
      <div className="tw-h-28 tw-border-b tw-border-neutral-grey-4">
        {header}
        <div className="tw-flex tw-justify-between tw-items-start">
          <div className="tw-flex tw-flex-col">
            <span className={`tw-text-2xl tw-font-semibold`}>{formattedAmountRequested}</span>
            <div className="tw-text-sm tw-mt-2 tw-text-neutral-grey-2">{formattedTransactionDate}</div>
          </div>
          <TooltipIcon IconComponent={CardSendFunds} tooltipText="Credit" size={27} />
        </div>
      </div>
      <div className="tw-mt-6 lg:tw-mt-0 tw-rounded-md tw-bg-neutral-light tw-p-2 lg:tw-p-0 tw-overflow-y-scroll tw-overflow-x-hidden tw-scrollbar-hide tw-h-96 lg:tw-h-4/5">
        <AttributeRow label="Status" value={status} />
        <AttributeRow label="From" value={fromLabel} />
        <AttributeRow label="To" value={merchantName} />
        <AttributeRow label="Amount" value={formattedAmountRequested} />
        <AttributeRow label="Currency" value={<CurrencyBadge currency={currency} />} />
        {description && <AttributeRow label="Note" value={description} />}
        {declineReason && <AttributeRow label="Decline Reason" value={declineReason} />}
        {formattedDateApproved && <AttributeRow label="Date Approved" value={formattedDateApproved} />}
        {receipt && (
          <div className="tw-flex tw-flex-col">
            <div className="tw-my-3 tw-border-t tw-border-b tw-border-neutral-grey-4 tw-py-3">
              <a
                className="tw-font-semibold tw-text-sm tw-text-primary-dark-green"
                href={receipt}
                target="_blank"
                rel="noreferrer"
              >
                View Receipt
              </a>
            </div>
            {isMember && !isCompleted && (
              <button type="button" onClick={openUnlinkDialog} className="tw-text-sm tw-mb-4">
                <div className="tw-flex tw-items-center tw-cursor-pointer tw-text-semantic-error hover:tw-text-semantic-error">
                  <BiUnlink size={22} className="w-mr-1" />
                  <span className="tw-font-semibold">Unlink this receipt</span>
                </div>
              </button>
            )}
          </div>
        )}
        {isMember && !isCompleted && (
          <>
            <ConfirmUnlinkDialog
              transaction={cardExpense}
              openUnlinkDialog={isOpenUnlinkDialog}
              closeUnlinkDialog={closeUnlinkDialog}
            />

            {!receipt ? (
              <NoteReceiptForm
                transaction={cardExpense}
                formattedAmount={formatMoneyWithoutCurrencyLabel(cardExpense.amountRequested)}
                overwriteReceipt={!!receipt}
              />
            ) : (
              <NoteReceiptForm
                transaction={cardExpense}
                formattedAmount={formatMoneyWithoutCurrencyLabel(cardExpense.amountRequested)}
                overwriteReceipt={!receipt}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CardExpenseDetail;
