import React from 'react';

import { useQuery } from '@apollo/client';
import { GET_GIFT_OFFERS } from 'graphql/rewards';
import { Loaders } from 'components/cards/Loader';
import Carousel from 'components/Carousel';
import useWindowDimensions from 'hooks/useWindowDimensions';
import config from 'config';

import { GiftOffer } from './GiftOffer';

export const GiftsList = () => {
  const { data: giftOffersData, loading: loadingGiftOffers, error: errorGifts } = useQuery(GET_GIFT_OFFERS);
  const { giftOffers } = giftOffersData || { giftOffers: [] };

  const { width } = useWindowDimensions();

  const getSlidesPerView = () => {
    if (width < 2000) {
      return 3;
    }
    return 4;
  };

  if (loadingGiftOffers) return <Loaders.Light />;
  if (errorGifts) {
    console.error(errorGifts);
    return 'Error loading Gifts';
  }

  if (giftOffers.length > 0)
    return (
      <div className="carousel-container tw-max-w-screen-xl">
        <Carousel slidesPerView={getSlidesPerView()} spaceBetween={1} alignment="left">
          {giftOffers?.map((gift) => {
            return <GiftOffer key={gift.internalId} gift={gift} />;
          })}
        </Carousel>
      </div>
    );
  else {
    return (
      <div>
        You are not currently able to redeem Loop Points. Please pay any overdue statement amounts to re-enable point
        redemption. If you have any questions or believe this to be in error please contact our team at{' '}
        <a
          className="tw-font-semibold tw-text-primary-dark-green"
          target="_blank"
          href={`mailto:${config.supportEmail}`}
          rel="noreferrer"
        >
          {config.supportEmail}
        </a>
        .
      </div>
    );
  }
};
