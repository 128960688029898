import { useContext } from 'react';

import { CardMigrationContext } from 'components/CardMigrationV2/context/CardMigrationContext';

const useReviewSelection = () => {
  const { cards, onPrevStep, goToUpdateAddressStep, onNextStep, setSelectedCard } = useContext(CardMigrationContext);

  const toggleUpdateAddressForCardId = (cardId: string | null) => {
    if (cardId) {
      const cardToUpdateAddress = cards.find((card) => card.cardId === cardId);
      setSelectedCard(cardToUpdateAddress!);
      goToUpdateAddressStep();
      return;
    }

    setSelectedCard(null);
    goToUpdateAddressStep();
  };

  // show only active physical cards
  const physicalCards = cards.filter((card) => !card.isVirtual && card.migrate);

  return {
    cards: physicalCards,
    onPrevStep,
    onNextStep,

    toggleUpdateAddressForCardId,
  };
};

export default useReviewSelection;
