import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import useIsMobile from 'hooks/useIsMobile';
import { AccountingSection } from 'components/settings/Integrations/components';
import SettingsLayout from 'containers/settings/SettingsLayout';
import { SettingsContext } from 'context/Settings';
import { Loaders } from 'components/cards/Loader';
import { GET_QBO_COMPANY_INFO } from 'graphql/accountingServices';
import { validAccountingIntegrations } from 'components/settings/Integrations/constants';

const Content = () => {
  const history = useHistory();
  const isMobile = useIsMobile();

  if (isMobile) history.push('/dashboard/home');
  const { externalAccounts, loadingIntegrations, refetchIntegrationsRef } = useContext(SettingsContext);

  const { data: companyData, refetch } = useQuery(GET_QBO_COMPANY_INFO);
  const { accountingIntegrationCompanyInfo } = companyData || {
    accountingIntegrationCompanyInfo: { name: '', lastPushedAt: '' },
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tw-container-md tw-flex tw-flex-col tw-justify-around tw-w-full">
      {!loadingIntegrations && (
        <AccountingSection
          externalAccounts={externalAccounts.filter((ea) => validAccountingIntegrations().includes(ea.name))}
          reload={refetchIntegrationsRef.current}
          companyInfo={accountingIntegrationCompanyInfo}
        />
      )}
      {loadingIntegrations && <Loaders.Light />}
    </div>
  );
};

export const AccountingIntegrations = () => (
  <SettingsLayout>
    <Content />
  </SettingsLayout>
);
