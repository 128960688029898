import React from 'react';

import cx from 'classnames';

import Button from 'components/Button';
import { SubmitButton } from 'components/FormFields/v2';
import { FooterProps } from './Footer.types';

const FormFooter = ({
  onNavigateBack,
  onSubmit,
  navigateBackLabel,
  submitLabel,
  loading,
  loadingRate,
  isDisabled,
}: FooterProps) => (
  <div
    className={cx(
      'tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-py-4 tw-flex',
      onNavigateBack ? 'tw-justify-between' : 'tw-justify-end'
    )}
  >
    {onNavigateBack && (
      <Button isDisabled={loading} secondary onClick={onNavigateBack}>
        {navigateBackLabel}
      </Button>
    )}
    <SubmitButton isDisabled={loading || loadingRate || isDisabled} onClick={onSubmit}>
      {loading ? 'Submitting...' : submitLabel}
    </SubmitButton>
  </div>
);

export default FormFooter;
