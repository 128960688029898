import React from 'react';

import { SearchDropdownV2 } from 'components/UI';
import { Option } from 'components/UI/SearchDropdown/v2/SearchDropdown.types';
import { useCardsSearch } from './hooks';

const CardsSearch = () => {
  const {
    optionsList,
    selectedCard,
    onSelectedCardsChange,
    isLoading,
    isError,
    handleCustomOptionLabel,
    handleCustomSelectedOptionLabel,
  } = useCardsSearch();

  if (isError) return null;

  return (
    <div className="tw-flex tw-w-full">
      <SearchDropdownV2
        optionsList={optionsList}
        selectedOptions={selectedCard}
        onSelectOptions={onSelectedCardsChange as (option: Option | Option[] | null) => void}
        placeholder="Search Cards"
        rootClass="tw-w-full"
        showSearchIcon
        showSelectedOptionsBadges
        isLoading={isLoading}
        customOptionLabel={handleCustomOptionLabel}
        customSelectedOptionsBadgeLabel={handleCustomSelectedOptionLabel}
      />
    </div>
  );
};

export default CardsSearch;
