import React, { useState } from 'react';

import Modal from '../../Modal';
import ModalV2 from '../../Modal/v2';
import ConnectShopifyCard from '../../cards/ConnectShopify';

const Shopify = (props) => {
  const { isOnboarding, isPreApproval, layout } = props;
  const [showConnectShopify, setShowConnectShopify] = useState(false);
  const hideConnectShopify = () => setShowConnectShopify(false);

  const ModalComponent = isPreApproval ? ModalV2 : Modal;

  return (
    <>
      <div className="d-flex align-items-center justify-content-center" onClick={() => setShowConnectShopify(true)}>
        <img
          style={{ width: '62%', marginTop: '-12px' }}
          src="https://loop-public.s3.ca-central-1.amazonaws.com/shopify_logo.png"
        />
      </div>

      <ModalComponent show={showConnectShopify} onClose={hideConnectShopify}>
        <ConnectShopifyCard
          onCancel={hideConnectShopify}
          onSuccess={hideConnectShopify}
          isOnboarding={isOnboarding}
          isPreApproval={isPreApproval}
          layout={layout}
        />
      </ModalComponent>
    </>
  );
};

export default Shopify;
