import React from 'react';

import { Accounts, Amounts } from './components';

import { TransactionProps } from './Transactions.types';

const Transactions = ({ transactions, singleTransaction, isMinDuePayment }: TransactionProps) => {
  if (!transactions?.length) return null;

  return (
    <div className="tw-flex xl:tw-flex-col tw-gap-2 xl:tw-gap-6">
      <Amounts transactions={transactions} />
      <Accounts transactions={transactions} singleTransaction={singleTransaction} isMinDuePayment={isMinDuePayment} />
    </div>
  );
};

export default Transactions;
