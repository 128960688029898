import React from 'react';
import { useHistory } from 'react-router-dom';

import { Banner } from 'components/UI';
import { BannerType } from 'components/UI/Banner/Banner';
import WaitingListSuccess from 'components/svg/WaitingListSuccess';
import { useIsMobile } from 'hooks';

const RecentTransactions = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const handleClick = () => {
    if (isMobile) return;

    history.push('/onboarding/dashboard/settings/bank-accounts-settings');
  };

  const bannerMessage = (
    <>
      <div className="tw-mb-2">
        While your account is being verified, you can continue setting up your Loop account from the <b>Settings</b>{' '}
        page:
      </div>
      <ul className="tw-list-disc tw-ml-4">
        <li>Connect your bank account to Loop</li>
        <li>Link addition sales channels</li>
        <li>Connect to QuickBooks or Xero</li>
      </ul>
    </>
  );

  return (
    <>
      <span className="tw-text-sm tw-text-neutral-grey-1 tw-bg-neutral-grey-4 tw-p-2">Recent Transactions</span>
      <Banner
        type={BannerType.secondary}
        title="We're Verifying Your Account"
        message={bannerMessage}
        submitButton={!isMobile}
        submitButtonLabel="Continue Your Account Set-up"
        onSubmit={handleClick}
        icon={<WaitingListSuccess width={100} height={92} />}
        iconAlignment="center"
        classNames="tw-mt-8 tw-mb-6"
      />
      <div className="tw-text-sm">You have no recent transactions.</div>
    </>
  );
};

export default RecentTransactions;
