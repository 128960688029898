import React from 'react';
import { useHistory } from 'react-router-dom';

import { Loaders } from '../cards/Loader';
import useIsMobile from '../../hooks/useIsMobile';
import DashboardLayout from '../dashboard/DashboardLayout';
import DesktopLayout from './DesktopLayout';

const LineOfCredit = (props) => {
  const { loading, ...otherProps } = props;

  const history = useHistory();
  const isMobile = useIsMobile();

  if (isMobile) history.push('/dashboard/home');

  return (
    <DashboardLayout title="Line of Credit">
      {loading ? <Loaders.Light /> : <DesktopLayout {...otherProps} />}
    </DashboardLayout>
  );
};

export default LineOfCredit;
