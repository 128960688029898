import { useMemo, useCallback, useContext } from 'react';

import { useIsMobile, useToggle } from 'hooks';
import { ampTrackEvent } from 'amplitude';
import useIsContactPermission from 'hooks/useIsContactPermission';
import { PayorsContext } from 'components/Invoices/contexts/PayorsContext';
import { PayorItemProps } from '../PayorItem.types';

const usePayorItem = ({ payorId, openEditModal, openDeleteModal, openAddPaymentRequestModal }: PayorItemProps) => {
  const { payors } = useContext(PayorsContext);

  const { managePayors: isAllowedToManagePayors, requestPayments: isAllowedToRequestPayments } =
    useIsContactPermission();

  const { isOpen: showDetails, toggle: toggleShowDetails } = useToggle();

  const payor = useMemo(() => payors.find(({ id }) => id === payorId), [payors, payorId])!;

  const initials = useMemo(() => {
    if (!payor?.name) return '';

    const [firstName, lastName] = payor.name.trim().split(' ');

    return `${firstName[0]}${lastName?.[0] || ''}`.toUpperCase();
  }, [payor?.name]);

  const handleOpenEditModal = useCallback(() => {
    openEditModal(payorId);
  }, [openEditModal, payorId]);

  const handleOpenDeleteModal = useCallback(() => {
    openDeleteModal(payorId);
  }, [openEditModal, payorId]);

  const handleOpenAddPaymentRequestModal = useCallback(() => {
    // TODO: temporary code for getting feature statistics
    ampTrackEvent('invoice: open request payment modal');

    openAddPaymentRequestModal(payorId);
  }, [openAddPaymentRequestModal, payorId]);

  const isMobile = useIsMobile();

  return {
    payor,
    isMobile,
    showDetails,
    toggleShowDetails,
    handleOpenEditModal,
    handleOpenDeleteModal,
    handleOpenAddPaymentRequestModal,
    isAllowedToManagePayors,
    isAllowedToRequestPayments,
    initials,
  };
};

export default usePayorItem;
