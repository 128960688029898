import { useContext, useEffect, useMemo } from 'react';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { get, pickBy, identity } from 'lodash';

import { ADD_PAYMENT_REQUEST, GET_PAYMENT_REQUESTS, GET_PAYMENT_REQUEST_FEE } from 'graphql/invoicing';
import { PaymentRequestCurrency } from 'types/invoicing';
import { PayorsContext } from 'components/Invoices/contexts/PayorsContext';
import { AddPaymentRequestContext } from 'components/Invoices/components/Payors/components/PayorsList/contexts/AddPaymentRequestContext';
import { ReviewProps } from '../Review.types';
import { formatDateTime } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { REQUESTS_RECURRING_TYPE, REQUESTS_RECURRING_STOP_TYPE } from 'components/Invoices/constants';
import { getFrequencyLabelByValue } from 'components/Invoices/Invoices.utils';

const useReview = ({ onNextStep, onPrevStep }: ReviewProps) => {
  const { payors, wallets } = useContext(PayorsContext);
  const { paymentRequestInfo, setPaymentRequestInfo, error, setError } = useContext(AddPaymentRequestContext);

  const {
    payorId,
    dueDate,
    invoiceNumber,
    recurringType,
    frequency,
    recurringStopType,
    endDate,
    numberOfOccurrences,
    accountId,
  } = paymentRequestInfo || {};

  const { amount, currency } = get(
    paymentRequestInfo,
    'amount',
    {} as { amount?: number; currency?: PaymentRequestCurrency }
  );

  const {
    data: paymentRequestFeeData,
    loading: loadingPaymentRequestFee,
    error: paymentRequestFeeError,
  } = useQuery(GET_PAYMENT_REQUEST_FEE, {
    variables: { amount: { amount, currency } },
  });

  const [addPaymentRequest, { loading }] = useMutation(ADD_PAYMENT_REQUEST, {
    refetchQueries: [{ query: GET_PAYMENT_REQUESTS }],
  });

  const { paymentRequestFee } = paymentRequestFeeData || {};

  const formattedPaymentRequestFee =
    !paymentRequestFeeError && paymentRequestFee ? formatMoneyV2(paymentRequestFee) : 0;

  const payorName = useMemo(() => payors.find(({ id }) => id === payorId)?.name, [payors, payorId]);

  const accountName = useMemo(() => wallets.find(({ id }) => id === accountId)?.name, [wallets, accountId]);

  const formattedDueDate = dueDate && formatDateTime(new Date(dueDate));
  const formattedAmount = amount && currency ? formatMoneyV2({ amount, currency }) : 0;

  const isOngoing = recurringType === REQUESTS_RECURRING_TYPE.ongoing.value;

  const isStoppedByEndDate = recurringStopType === REQUESTS_RECURRING_STOP_TYPE.endDate.value;
  const isStoppedByNumberOfOccurrences = recurringStopType === REQUESTS_RECURRING_STOP_TYPE.numberOfOccurrences.value;
  const isStoppedByCancel = recurringStopType === REQUESTS_RECURRING_STOP_TYPE.byCancel.value;

  const formattedEndDate = endDate && formatDateTime(new Date(endDate));
  const frequencyLabel = getFrequencyLabelByValue(frequency);

  const handleSubmit = async () => {
    if (!paymentRequestInfo) return;

    setError(null);

    try {
      const paymentSchedule = pickBy(
        {
          frequency: recurringType === REQUESTS_RECURRING_TYPE.once.value ? null : frequency,
          endDate: recurringType === REQUESTS_RECURRING_TYPE.once.value ? null : endDate,
          numberOfOccurrences:
            recurringType === REQUESTS_RECURRING_TYPE.once.value ? null : Number(numberOfOccurrences),
        },
        identity
      );

      const payload = {
        ...paymentRequestInfo,
        ...paymentSchedule,
      };

      const res = await addPaymentRequest({
        variables: payload,
      });

      if (!res.data?.addPaymentRequest) throw new Error('Error adding Payment Request - please try again');

      const fee = get(res, 'data.addPaymentRequest.fee');
      setPaymentRequestInfo({ ...paymentRequestInfo, fee });

      onNextStep();
    } catch (err) {
      console.error(err);
      toast.error('Error adding Payment Request - please try again');
      setError(err as ApolloError);
      onPrevStep();
    }
  };

  useEffect(() => {
    if (paymentRequestFeeError) {
      console.error('Error calculating fee: ', paymentRequestFeeError);
      setError({ message: 'Error calculating fee' } as ApolloError);
    }
  }, [paymentRequestFeeError]);

  return {
    payorName,
    accountName,
    formattedDueDate,
    formattedAmount,
    currency,
    invoiceNumber,
    handleSubmit,
    loading: loading || loadingPaymentRequestFee,
    formattedPaymentRequestFee,
    error,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
    frequencyLabel,
    formattedEndDate,
    numberOfOccurrences,
  };
};

export default useReview;
