import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { SCOPE, TRANSACTION_PRODUCT_TYPES } from 'constants/index';
import { GET_CREDIT_CARDS_IDS } from 'graphql/cards';

import { TransactionsContext } from 'context/Transactions';
import CardAndCoins from 'components/svg/CardAndCoins';
import Button from 'components/Button';
import PermissionChecker from 'components/PermissionChecker';

// This component is inherited from old transaction componnets.
// im gonna keep working on it splitting it in smaller files, optimizing queries and data.
const NoTransactionsBanner = () => {
  const history = useHistory();
  const { productType } = useContext(TransactionsContext);
  const { data: creditCardIds, loading: creditCardIdLoading } = useQuery(GET_CREDIT_CARDS_IDS);

  const creditCards = get(creditCardIds, 'me.account.creditCards') || [];

  const onGoToCards = () => history.push('/dashboard/cards/list');
  const onGoToAccounts = () => history.push('/dashboard/accounts');
  const onGoToLOC = () => history.push('/dashboard/line-of-credit');

  if (creditCardIdLoading) return '';

  switch (productType) {
    case TRANSACTION_PRODUCT_TYPES.wallet:
      return <AccountBanner onClick={onGoToAccounts} />;
    case TRANSACTION_PRODUCT_TYPES.lineOfCredit:
      return <LOCBanner onClick={onGoToLOC} />;
    default:
      return <CardsBanner creditCards={creditCards} onClick={onGoToCards} />;
  }
};

const AccountBanner = ({ onClick }) => (
  <Wrapper description="Start spending with your Loop Accounts and your transactions will appear here!">
    <Button primary className="tw-py-3 tw-px-12 tw-font-semibold" onClick={onClick}>
      Start Spending
    </Button>
  </Wrapper>
);

const LOCBanner = ({ onClick }) => (
  <Wrapper description="Start spending with your Line of credit and your transactions will appear here!">
    <Button primary className="tw-py-3 tw-px-12 tw-font-semibold" onClick={onClick}>
      Start Spending
    </Button>
  </Wrapper>
);

const CardsBanner = ({ creditCards, onClick }) => {
  const hasCreditCard = creditCards.length >= 1;

  return hasCreditCard ? <AtLeastOneCardBanner onClick={onClick} /> : <NoCardBanner onClick={onClick} />;
};

const NoCardBanner = ({ onClick }) => (
  <Wrapper description="Create a card and start spending! Once you make transactions on the card, you can track them here.">
    <PermissionChecker scope={SCOPE.manageCards}>
      <Button primary className="tw-py-3 tw-px-12 tw-font-semibold" onClick={onClick}>
        Create Card
      </Button>
    </PermissionChecker>
  </Wrapper>
);

const AtLeastOneCardBanner = ({ onClick }) => (
  <Wrapper description="Start spending with your Loop Card and your transactions will appear here!">
    <Button primary className="tw-py-3 tw-px-12 tw-font-semibold" onClick={onClick}>
      Start Spending
    </Button>
  </Wrapper>
);

export const EmptyTransactions = () => <Wrapper description="No Transactions found for this Filter."></Wrapper>;

const Wrapper = ({ description, children }) => (
  <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-bg-neutral-light tw-rounded-md tw-pt-6 tw-pb-4 lg:tw-pt-0 lg:tw-pb-0">
    <CardAndCoins height="300" width="100%" />
    <div className="tw-text-center tw-mt-6 tw-w-4/5 lg:tw-w-1/3">
      <p className="tw-my-8" data-testid="description">
        {description}
      </p>
      {children}
    </div>
  </div>
);

export default NoTransactionsBanner;
