import React from 'react';
import { Link } from 'react-router-dom';

import { Loaders } from './cards/Loader';
import { formatDateTime } from '../utility/date';
import { formatTransactionTableAmount } from '../utility/currency';
import { formatTransactionName, mapRecentTransactionsByDate } from '../utility/transactions';
import TransactionType from './TransactionType';

const RecentTransactionsMobile = ({ transactions, accountNames = [], loadingTransactions }) => {
  const transactionsByDate = mapRecentTransactionsByDate(transactions);

  return (
    <>
      <div className="tw-flex tw-justify-between tw-mt-6">
        <small className="tw-text-neutral-grey-1">Transactions</small>
        <Link to="/dashboard/transactions">
          <div className="tw-flex tw-items-center">
            <small className="tw-font-semibold tw-text-primary-dark-green tw-mr-2">See all transactions</small>
          </div>
        </Link>
      </div>
      <div className="tw-flex tw-justify-center tw-mt-4 tw-bg-neutral-light tw-rounded-md">
        {loadingTransactions && (
          <div className="tw-p-10">
            <Loaders.Spinner />
          </div>
        )}
        {!loadingTransactions &&
          (transactions.length > 0 ? (
            <TransactionsTable transactionsByDate={transactionsByDate} accountNames={accountNames} />
          ) : (
            <EmptyState />
          ))}
      </div>
    </>
  );
};

const TransactionsTable = ({ transactionsByDate, accountNames }) => {
  const today = formatDateTime(new Date());

  return (
    <table className="tw-table tw-table-auto tw-w-full">
      {Object.keys(transactionsByDate).map((date) => (
        <TableSection
          key={date}
          date={date}
          transactions={transactionsByDate[date]}
          isToday={today === date}
          accountNames={accountNames}
        />
      ))}
    </table>
  );
};

const TableSection = ({ date, transactions, isToday, accountNames }) => (
  <tbody>
    <tr>
      <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal" colSpan={5}>
        {isToday ? 'Today' : date}
      </th>
    </tr>
    {transactions.map((transaction) => {
      const accountName = accountNames.find((name) => name === transaction.to);

      return <TransactionRow key={transaction.transactionId} transaction={transaction} accountName={accountName} />;
    })}
  </tbody>
);

const TransactionRow = ({ transaction, accountName }) => {
  const formattedAmount = formatTransactionTableAmount({ accountName, transaction });
  const isNegative = formattedAmount.includes('-');

  return (
    <div className="tw-flex tw-justify-between tw-py-2">
      <div className="tw-flex tw-items-start">
        <div className="tw-mx-4">
          <TransactionType transaction={transaction} />
        </div>
        <div>
          <div className="tw-flex">
            <small className="tw-text-neutral-grey-2 tw-mr-1">To</small>
            <small>{formatTransactionName(transaction.to)}</small>
          </div>
          <div className="tw-flex">
            <small className="tw-text-neutral-grey-2 tw-mr-1">from</small>
            <small>{formatTransactionName(transaction.from)}</small>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-items-start">
        <div className={`tw-pr-4 tw-text-sm ${isNegative ? '' : 'tw-text-semantic-success'}`}>
          {formattedAmount} {transaction.amount.currency}
        </div>
      </div>
    </div>
  );
};

const EmptyState = () => <small>You have no recent transactions.</small>;

export default RecentTransactionsMobile;
