import React from 'react';

import styles from './Loader.module.scss';

const FullScreenLoader = () => {
  return (
    <div
      className={`tw-bg-neutral-light rounded p-5 d-flex align-items-center flex-column tw-text-neutral-dark ${styles.screen}`}
      data-testid="load"
    >
      <div className="spinner-border tw-min-h-8 tw-min-w-8 m-5" role="status"></div>
      <div className="text-center font-weight-1 mb-5">Loading...</div>
    </div>
  );
};

const LightLoader = () => {
  return (
    <div
      className="tw-bg-neutral-light rounded p-5 d-flex align-items-center flex-column tw-text-neutral-dark"
      data-testid="load"
    >
      <div className="spinner-border m-5" role="status"></div>
      <div className="text-center font-weight-1 mb-5">Loading...</div>
    </div>
  );
};

const DarkLoader = ({ message = 'Loading...' }) => {
  return (
    <div
      className="tw-bg-neutral-dark rounded-xl p-5 d-flex align-items-center flex-column tw-text-neutral-light"
      data-testid="load"
    >
      <div className="spinner-border text-white m-5" role="status"></div>
      <div className="text-center text-white font-weight-1 mb-5">{message}</div>
    </div>
  );
};

const SmallLoader = ({ customClass = 'm-5' }) => {
  return <div className={`spinner-border ${customClass}`} role="status"></div>;
};

const Spinner = ({ customClass = 'spinner-border tw-border-2 tw-h-4 tw-w-4 tw-ml-2' }) => (
  <svg className={customClass} viewBox="0 0 24 24"></svg>
);

export const Loaders = {
  FullScreen: FullScreenLoader,
  Light: LightLoader,
  Dark: DarkLoader,
  Small: SmallLoader,
  Spinner,
};

export default LightLoader;
