import React, { useContext } from 'react';
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';

import { AddressCategory } from 'types/creditCard';
import { DetailsCard } from 'components/UI';
import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { CardAddress } from './components';

const { OA, CA, US, RP, DEFAULT } = AddressCategory;

const CardAddressesSection = () => {
  const { creditCardDetails } = useContext(CardDetailsContext);

  const addresses = creditCardDetails?.addresses;

  const CAAddress = addresses ? addresses.find((address) => address.category === CA || address.category === OA) : null;
  const USAddress = addresses ? addresses.find((address) => address.category === US || address.category === RP) : null;
  const defaultAddress = addresses ? addresses.find((address) => address.category === DEFAULT) : null;

  return (
    <DetailsCard label="Saved Addresses" LabelIcon={HiOutlineBuildingOffice2}>
      <div className="tw-space-y-4">
        <CardAddress label="CA" address={CAAddress} />
        <CardAddress label="US" address={USAddress} />
        <CardAddress label="Business" address={defaultAddress} />
      </div>
    </DetailsCard>
  );
};

export default CardAddressesSection;
