import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import KYCStatusContainer from 'containers/onboarding/KYCStatusDetails';
import ContactConsentStatusContainer from 'containers/onboarding/ContactConsentStatusDetails';
import ArrowRight from 'components/svg/ArrowRight';
import { HookDropZone, AddNextFile, DropZoneError, AddedFile } from 'components/uploader/HookDropzone';
import Button from 'components/Button';
import { getAdditionalDocumentsTitle, getAdditionalDocumentsWarningText } from './FileUploadForm.util';

const CategoryUploader = ({
  categoryValue,
  category,
  files,
  errors,
  onAddCategoryFiles,
  onRemoveCategoryFile,
  isMissingDocuments,
}) => {
  const handleFilesAdded = (files) => onAddCategoryFiles({ files, category });
  const handleRemoveFile = (file) => onRemoveCategoryFile({ file, category });
  const { additionalValue, name: categoryName } = category || {};
  const categoryTitle = getAdditionalDocumentsTitle(categoryValue, additionalValue, categoryName);
  const documentWarningText = getAdditionalDocumentsWarningText(categoryValue);
  return (
    <div className="tw-mb-8">
      {isMissingDocuments && (
        <>
          {documentWarningText && (
            <div className="tw-rounded-md tw-bg-secondary-pastel-yellow-50 tw-p-6 tw-mb-4">{documentWarningText}</div>
          )}
          <div className="tw-flex tw-items-center tw-mb-4 tw-font-bold">{categoryTitle}</div>
        </>
      )}

      {files?.length < 1 && <HookDropZone onFilesAdded={handleFilesAdded} />}
      {files?.length >= 1 && (
        <>
          {files.map((file) => (
            <AddedFile key={file.path} file={file} onRemoveFile={handleRemoveFile} />
          ))}
          <AddNextFile onFilesAdded={handleFilesAdded} />
        </>
      )}
      {errors && errors.map((error) => <DropZoneError key={error.message} errors={[error]} />)}
    </div>
  );
};

const FileUploadForm = ({
  categories,
  status,
  onSubmit,
  contactsNeedConsent,
  corporateShareholdersNeedConsent,
  isMissingDocuments,
}) => {
  const [filesByCategory, setFilesByCategory] = useState(
    categories.reduce((obj, category) => Object.assign(obj, { [category.key]: [] }), {})
  );
  const [errorsByCategory, setErrorsByCategory] = useState(
    categories.reduce((obj, category) => Object.assign(obj, { [category.key]: [] }), {})
  );

  const handleFilesAddedToCategory = ({ files, category }) => {
    const newFiles = [...filesByCategory[category.key], ...files];
    setFilesByCategory({ ...filesByCategory, [category.key]: newFiles });
    setErrorsByCategory({ ...errorsByCategory, [category.key]: [] });
  };

  const handleRemoveFileFromCategory = ({ file, category }) => {
    const files = filesByCategory[category.key].filter((f) => f.path !== file.path);
    setFilesByCategory({ ...filesByCategory, [category.key]: files });
  };

  const getRequiredCategoriesWithoutFiles = () => {
    return Object.keys(filesByCategory)
      .filter((cat) => cat !== 'OTHERS')
      .filter((cat) => filesByCategory[cat].length === 0);
  };

  const handleSubmit = () => {
    const emptyCategories = getRequiredCategoriesWithoutFiles();

    if (emptyCategories.length === 0) {
      onSubmit(filesByCategory);
    } else {
      setErrorsByCategory({
        ...errorsByCategory,
        ...emptyCategories.reduce(
          (obj, cat) => Object.assign(obj, { [cat]: [{ type: 'required', message: 'Upload required documents' }] }),
          {}
        ),
      });
    }
  };

  const onlyOthersCategory = categories.length === 1 && categories[0].value === 'OTHERS';

  return (
    <div className="tw-w-full lg:tw-w-3/6 tw-max-w-2xl tw-mt-16 tw-flex tw-flex-col tw-flex-grow">
      <h1 data-testid="onboarding-status-title" className="tw-mb-4">
        Complete Your Verification
      </h1>
      <KYCStatusContainer status={status} />
      <br></br>
      <ContactConsentStatusContainer
        contacts={contactsNeedConsent}
        corporateShareholders={corporateShareholdersNeedConsent}
      />
      <br></br>
      <div className="tw-mt-12">
        <div className="tw-flex tw-items-center tw-mb-4">
          <div className="tw-rounded-full tw-w-max tw-p-0.5">
            <div className="tw-flex tw-justify-center tw-items-center tw-rounded-full tw-w-6 tw-h-6 tw-border-2 tw-border-primary-dark-green tw-bg-primary-light-green">
              <strong className="tw-text-primary-dark-green">2</strong>
            </div>
          </div>
          <div>
            <div className="tw-ml-8">
              {!isMissingDocuments || onlyOthersCategory
                ? 'Our team is currently reviewing your application. If any additional information is required, you will be notified via email.'
                : 'Additional Verification Documents'}
              {!isMissingDocuments && (
                <div>
                  Return to this page to upload more documents or view document currently under review in{' '}
                  <Link
                    to="/onboarding/dashboard/settings/documents"
                    target="_blank"
                    className="tw-font-bold tw-text-primary-dark-green hover:tw-text-primary-dark-green"
                  >
                    Settings
                  </Link>
                  .
                </div>
              )}
            </div>
          </div>
        </div>
        {categories.map((category, index) => (
          <CategoryUploader
            key={`${category.key}-${index}}`}
            categoryValue={category.value}
            category={category}
            files={filesByCategory[category.key]}
            errors={errorsByCategory[category.key]}
            onAddCategoryFiles={handleFilesAddedToCategory}
            onRemoveCategoryFile={handleRemoveFileFromCategory}
            isMissingDocuments={isMissingDocuments}
          />
        ))}
      </div>
      <div className="tw-flex tw-justify-end">
        <Button primary onClick={handleSubmit}>
          <div className="tw-flex tw-justify-center tw-items-center">
            <p className="tw-text-neutral-light tw-mr-2">Submit for Review</p>
            <ArrowRight />
          </div>
        </Button>
      </div>
    </div>
  );
};

export default FileUploadForm;
