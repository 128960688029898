import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { TabIndexContext } from 'context/TabIndex';
import { GET_PAYEE_PAYMENTS_HOME } from 'graphql/payees';
import TopThreePayees from './TopThreePayees';
import Button from 'components/Button';
import AddPayeeModal from 'components/payments/AddPayeeModal';

const PayeesContent = ({ payees, wallets, lineOfCredit, loadingProducts }) => {
  const { setDefaultIndex } = useContext(TabIndexContext);

  const [showAddPayeeModal, setShowAddPayeeModal] = useState(false);
  const onShowAddPayeeModal = () => setShowAddPayeeModal(true);
  const onCloseAddPayeeModal = () => setShowAddPayeeModal(false);

  const { loading, data } = useQuery(GET_PAYEE_PAYMENTS_HOME);
  const payeePayments = _.get(data, 'payeePayments') || [];
  const hasPayeePayments = payeePayments.length > 0;

  const history = useHistory();
  const navigateToPayeePayments = () => {
    history.push('/dashboard/payments/payees');
    setDefaultIndex(1);
  };

  return (
    <div className="tw-flex tw-p-4 tw-gap-x-4">
      <div className="tw-flex tw-flex-col tw-w-2/3">
        <small>
          {hasPayeePayments ? 'Quick Send' : 'Save time and money when you make your first payment with Loop'}
        </small>
        <TopThreePayees
          payeePayments={payeePayments}
          payees={payees}
          loading={loading}
          wallets={wallets}
          lineOfCredit={lineOfCredit}
          loadingProducts={loadingProducts}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-w-1/3 tw-min-w-max">
        <Button
          className="tw-text-sm tw-font-bold tw-text-primary-dark-green tw-self-end tw-flex-nowrap"
          onClick={onShowAddPayeeModal}
        >
          Add payee
        </Button>
        {!loading && hasPayeePayments && (
          <Button
            className="tw-text-sm tw-font-bold tw-text-primary-dark-green tw-self-end tw-flex-nowrap tw-mt-2"
            onClick={navigateToPayeePayments}
          >
            View recent payments
          </Button>
        )}
      </div>
      <AddPayeeModal show={showAddPayeeModal} onClose={onCloseAddPayeeModal} />
    </div>
  );
};

export default PayeesContent;
