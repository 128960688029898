import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { getCountries } from 'utility/countries';
import { EU_COUNTRY_CODES, SUPPLIER_SPECIFIC_COUNTRIES, CURRENCY_CODES } from 'constants/index';
import { getSpecificCountryFromCurrency } from '../../../utils';

const { MXN } = CURRENCY_CODES;
const { EU, MX } = SUPPLIER_SPECIFIC_COUNTRIES;

const useCountrySelect = () => {
  const [countryOptions, setCountryOptions] = useState(getCountries());
  const [isSpecificCountrySelected, setIsSpecificCountrySelected] = useState(false);

  const { register, watch, setValue } = useFormContext();

  const currency = watch('bankAccount.currency');
  const basedOnCurrencyCountry = watch('basedOnCurrencyCountry');

  const specificCountry = getSpecificCountryFromCurrency(currency);

  const { name, shortName, code: specificCountryCode } = specificCountry || {};
  const specificCountryName = shortName || name;

  const showSpecificCountryFields = !!specificCountry;

  const showAdditionalEUCountiesSelector = specificCountryCode === EU.code;

  const basedOnCurrencyCountryOptions = [
    { label: `Inside ${specificCountryName}`, value: specificCountryCode, defaultChecked: true },
    { label: `Outside ${specificCountryName}`, value: '' },
  ];

  useEffect(() => {
    setValue('basedOnCurrencyCountry', specificCountryCode);
  }, []);

  useEffect(() => {
    setIsSpecificCountrySelected(!!basedOnCurrencyCountry && specificCountryCode === basedOnCurrencyCountry);
  }, [basedOnCurrencyCountry, specificCountryCode]);

  useEffect(() => {
    let filteredCountries = getCountries();

    if (!isSpecificCountrySelected) {
      filteredCountries = filteredCountries.filter(({ value }) => {
        if (specificCountryCode === EU.code) return !EU_COUNTRY_CODES.includes(value);

        return value !== specificCountryCode;
      });
    }

    if (isSpecificCountrySelected && showAdditionalEUCountiesSelector) {
      filteredCountries = filteredCountries.filter(({ value }) => EU_COUNTRY_CODES.includes(value));
    }

    // disable local MX suppliers
    if (currency === MXN) {
      filteredCountries = filteredCountries.filter(({ value }) => value !== MX.code);
    }

    setCountryOptions(filteredCountries);
  }, [specificCountryCode, isSpecificCountrySelected, showAdditionalEUCountiesSelector, currency]);

  return {
    specificCountryCode,
    basedOnCurrencyCountryOptions,
    countryOptions,
    showSpecificCountryFields,
    showAdditionalEUCountiesSelector,
    isSpecificCountrySelected,
    register,
  };
};

export default useCountrySelect;
