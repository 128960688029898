import React from 'react';
import { PiCreditCardLight } from 'react-icons/pi';

import { useSpendManagement } from './hooks';
import { DetailsCard } from 'components/UI';
import { SectionSkeleton } from '..';
import { EditSpendManagementModal } from './components';

const SpendManagement = () => {
  const {
    isLoading,
    isError,
    isOpenModal,
    openModal,
    closeModal,
    handleUpdateSettings,
    isUpdatingProcessorSettings,
    currencies,
    selectedCurrencies,
    merchants,
    selectedMerchants,
    merchantCategories,
    selectedMerchantCategories,
    selectedMerchantCategoriesCount,
    formattedAllowedCurrencies,
    formattedAllowedMerchants,
  } = useSpendManagement();

  if (isLoading) return <SectionSkeleton />;

  return (
    <>
      <DetailsCard
        label="Spend Settings"
        LabelIcon={PiCreditCardLight}
        action={isError ? undefined : openModal}
        actionLabel="Edit"
      >
        {!isError ? (
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div className="tw-flex tw-items-start tw-justify-between tw-w-full tw-gap-2">
              <div className="tw-flex tw-flex-col tw-gap-1">Merchant Categories</div>
              <div className="tw-font-bold">
                {formattedAllowedMerchants
                  ? 'No'
                  : `${selectedMerchantCategoriesCount} ${
                      selectedMerchantCategoriesCount === 1 ? 'Category' : 'Categories'
                    }`}
              </div>
            </div>
            <div className="tw-flex tw-items-start tw-justify-between tw-w-full tw-gap-2">
              <div className="tw-flex tw-flex-col tw-gap-1">Currencies</div>
              <div className="tw-font-bold">{formattedAllowedCurrencies}</div>
            </div>
            <div className="tw-flex tw-items-start tw-justify-between tw-w-full tw-gap-2">
              <div className="tw-flex tw-flex-col tw-gap-1">Merchant Locked</div>
              <div className="tw-font-bold">{formattedAllowedMerchants ? formattedAllowedMerchants : 'No'}</div>
            </div>
          </div>
        ) : (
          <div className="tw-text-neutral-grey-2">Can't load credit card spending settings</div>
        )}
      </DetailsCard>

      {isOpenModal && (
        <EditSpendManagementModal
          isOpenModal={isOpenModal}
          closeModal={closeModal}
          handleUpdateSettings={handleUpdateSettings}
          currencies={currencies}
          selectedCurrencies={selectedCurrencies}
          merchants={merchants}
          selectedMerchants={selectedMerchants}
          merchantCategories={merchantCategories}
          selectedMerchantCategories={selectedMerchantCategories}
          isLoading={isUpdatingProcessorSettings}
        ></EditSpendManagementModal>
      )}
    </>
  );
};

export default SpendManagement;
