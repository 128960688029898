import React, { useState } from 'react';

import { InitialContactType } from './SetUserInfoForm/SetUserInfoForm.types';
import SetUserRoleForm from './SetUserRoleForm';
import SetUserInfoForm from './SetUserInfoForm';
import SetUserPermissionsForm from './SetUserPermissionsForm';
import { InternalContactRole, TeamMember } from 'types/user';

export const Steps = ({
  initialContact,
  onSuccess,
  allMembers,
  currentStep,
  setCurrentStep,
}: {
  initialContact: InitialContactType;
  onSuccess: () => void;
  allMembers: TeamMember[];
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [role, setRole] = useState(InternalContactRole.member);
  const [memberId, setMemberId] = useState('');
  const handleBack = () => {
    setCurrentStep(1);
  };
  const [firstName, setFirstName] = useState(initialContact?.firstName || '');
  const [lastName, setLastName] = useState(initialContact?.lastName || '');
  const [email, setEmail] = useState(initialContact?.email || '');
  const userData = { firstName, lastName, email, contactId: initialContact?.contactId };

  switch (currentStep) {
    case 0:
      return (
        <SetUserInfoForm
          initialContact={initialContact}
          setCurrentStep={setCurrentStep}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setEmail={setEmail}
        />
      );
    case 1:
      return (
        <SetUserRoleForm
          setCurrentStep={setCurrentStep}
          onSuccess={onSuccess}
          userData={userData}
          setMemberId={setMemberId}
          setRole={setRole}
        />
      );
    case 2:
      return (
        <SetUserPermissionsForm
          memberId={memberId}
          role={role}
          onSuccess={onSuccess}
          allMembers={allMembers}
          handleBack={handleBack}
        />
      );
    default:
      return null;
  }
};
