export const PASSWORD_MIN_LENGTH = 8;

export const SPEC_SYMBOLS = '!@#$%^&*';

export const REGEX_LETTER = /[a-zA-Z]/;

export const REGEX_NUM = /\d/;

export const REGEX_SPEC_SYMBOLS = new RegExp(`[${SPEC_SYMBOLS}]`);

export const PASSWORD_WARNING = {
  LENGTH: `At least ${PASSWORD_MIN_LENGTH} characters`,
  LETTER: 'One letter',
  DIGIT: 'One number',
  SPEC: 'One special character',
};
