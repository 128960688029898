import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const InfoTipEmail = (props) => {
  return (
    <svg width="75" height="72" viewBox="0 0 75 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26.1017 50.7218C22.6017 44.2219 16.9493 24.45 21.7493 17.25C26.6106 9.95792 35.8065 14.792 39.1217 18.8839C37.3462 12.3032 35.4429 -2.85903 47.1017 0.472044C59.7017 4.07204 50.8517 26.9721 44.8517 37.9721L38.8517 49.2221L34.3517 48.4721L31.3517 52.2221L26.1017 50.7218Z"
        fill="#EED9F7"
      />
      <g clipPath="url(#clip0_2504_12771)">
        <path
          d="M43.7722 37.0356L30.082 36.5031C29.8171 36.4928 29.5534 36.5437 29.3113 36.6517C29.0693 36.7597 28.8553 36.9219 28.686 37.1258L19.9364 47.6687C19.6003 48.0714 19.3835 48.56 19.3104 49.0793C19.2374 49.5987 19.311 50.1282 19.523 50.6079L24.8112 62.4622C25.4439 63.8807 27.1218 64.5111 28.5589 63.87L50.0702 54.2739C51.5073 53.6328 52.159 51.9632 51.5263 50.5447L46.2381 38.6904C46.0224 38.212 45.6771 37.8034 45.2413 37.511C44.8056 37.2186 44.2967 37.0539 43.7722 37.0356V37.0356Z"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.5552 50.9146L35.3751 48.1613C35.0107 48.0716 34.6268 48.1059 34.2841 48.2587C33.9414 48.4116 33.6594 48.6744 33.4827 49.0055L28.0619 59.1644"
          stroke="#4F5154"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M38.8132 48.748L44.5957 38.2383" stroke="#4F5154" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.938 49.238L31.8713 51.9987" stroke="#4F5154" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <path
        d="M63.6464 12.576C63.8287 12.453 64.0471 12.6714 63.9241 12.8537L57.4908 22.3883C57.4452 22.4559 57.4452 22.5444 57.4908 22.612L63.9241 32.1466C64.0471 32.3288 63.8287 32.5472 63.6464 32.4242L54.1119 25.9909C54.0443 25.9453 53.9557 25.9453 53.8881 25.9909L44.3536 32.4242C44.1713 32.5472 43.9529 32.3288 44.0759 32.1466L50.5092 22.612C50.5548 22.5444 50.5548 22.4559 50.5092 22.3882L44.0759 12.8537C43.9529 12.6714 44.1713 12.453 44.3536 12.576L53.8881 19.0093C53.9557 19.0549 54.0443 19.0549 54.1119 19.0093L63.6464 12.576Z"
        fill={primaryDarkGreenColor}
      />
      <path
        d="M14.1647 11.582C14.3469 11.4591 14.5653 11.6774 14.4423 11.8597L11.716 15.9003C11.6704 15.9679 11.6704 16.0564 11.716 16.124L14.4423 20.1646C14.5653 20.3469 14.3469 20.5653 14.1647 20.4423L10.1241 17.716C10.0565 17.6704 9.96794 17.6704 9.90034 17.716L5.85974 20.4423C5.67749 20.5653 5.45911 20.3469 5.58208 20.1646L8.3084 16.124C8.35402 16.0564 8.35402 15.9679 8.3084 15.9003L5.58208 11.8597C5.45911 11.6774 5.67749 11.4591 5.85974 11.582L9.90034 14.3084C9.96794 14.354 10.0565 14.354 10.1241 14.3084L14.1647 11.582Z"
        fill={primaryDarkGreenColor}
      />
      <defs>
        <clipPath id="clip0_2504_12771">
          <rect width="35.9999" height="35.9999" fill="white" transform="translate(10.5 38.666) rotate(-24.0415)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoTipEmail;
