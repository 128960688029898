import React from 'react';

const SalesChannelButton = ({ channel, connectedPlatforms }) =>
  channel.testId === 'rutter-buttons' ? (
    React.cloneElement(channel.component, { connectedChannels: connectedPlatforms, from: 'onboarding' })
  ) : (
    <div
      data-testid={channel.testId}
      className="tw-mr-4 tw-mb-4 tw-flex tw-justify-center tw-shadow-xs-2 tw-items-center tw-cursor-pointer tw-w-32 tw-h-14 tw-rounded-md tw-border tw-border-neutral-grey-4 tw-bg-neutral-grey-4"
    >
      {channel.component}
    </div>
  );

export default SalesChannelButton;
