import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryGreen = theme.colors.primary['dark-green'];
const iconBackground = theme.colors.neutral['light'];

const SuccessCheckmark = ({ size = '20', ...other }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...other}>
      <path
        d="M20.161 10C20.161 15.0939 15.9397 19.25 10.6992 19.25C5.45862 19.25 1.2373 15.0939 1.2373 10C1.2373 4.90611 5.45862 0.75 10.6992 0.75C15.9397 0.75 20.161 4.90611 20.161 10Z"
        fill={iconBackground}
        stroke={primaryGreen}
        strokeWidth="1.5"
      />
      <g clipPath="url(#clip0_2749:32919)">
        <path
          d="M15.5278 8.15885L10.2776 13.7589C10.1402 13.9032 9.94593 13.9907 9.73744 13.9995H9.70427C9.50525 13.9995 9.31572 13.9295 9.17356 13.7982L6.84224 11.6895C6.54372 11.4182 6.53898 10.9764 6.83277 10.7007C7.12655 10.4251 7.60513 10.4207 7.90366 10.692L9.65689 12.2801L14.3811 7.2401C14.656 6.94698 15.1345 6.91635 15.452 7.1701C15.7695 7.42385 15.8027 7.86573 15.5278 8.15885Z"
          fill={primaryGreen}
        />
      </g>
      <defs>
        <clipPath id="clip0_2749:32919">
          <rect width="9.09784" height="7" fill="white" transform="translate(6.61438 7)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SuccessCheckmark;
