import React from 'react';
import { useQuery } from '@apollo/client';
import qs from 'query-string';

import { PASSWORD_RESET_EMAIL } from '../graphql/user';
import ResetPassword from '../components/auth/ResetPassword';

const ResetPasswordContainer = (props) => {
  const { token } = qs.parse(props.location.search);

  const { loading, data, error } = useQuery(PASSWORD_RESET_EMAIL, { variables: { token } });
  const email = data && data.passwordResetEmail && data.passwordResetEmail.email;

  return <ResetPassword isLoading={loading} fetchError={error} email={email} token={token} />;
};

export default ResetPasswordContainer;
