import { useContext } from 'react';

import { AuthContext } from 'context/Auth';

const useHasCreditCard = () => {
  const { me, meLoading } = useContext(AuthContext);

  if (me?.internalContact?.role !== 'member') {
    return { isLoading: meLoading, hasCreditCard: true };
  }

  const hasCreditCard = !!(me && me?.account?.creditCards && me.account?.creditCards?.length > 0);

  return { isLoading: meLoading, hasCreditCard };
};

export default useHasCreditCard;
