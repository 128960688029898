import React from 'react';

const Remove = (props) => {
  return (
    <svg width="120" height="118" viewBox="0 0 120 118" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.5"
        d="M105.625 55.5C105.625 32.5195 86.9805 13.875 64 13.875C41.0195 13.875 22.375 32.5195 22.375 55.5C22.375 78.4805 41.0195 97.125 64 97.125C86.9805 97.125 105.625 78.4805 105.625 55.5Z"
        fill="#F14E1B"
      />
      <path
        d="M97.625 62.5C97.625 39.5195 78.9805 20.875 56 20.875C33.0195 20.875 14.375 39.5195 14.375 62.5C14.375 85.4805 33.0195 104.125 56 104.125C78.9805 104.125 97.625 85.4805 97.625 62.5Z"
        stroke="#4F5154"
        strokeMiterlimit="10"
      />
      <path d="M73.3438 62.5H38.6562" stroke="#4F5154" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Remove;
