import React from 'react';

import { theme } from 'styles/tailwind/themes';

const primaryDarkGreenColor = theme.colors.primary['dark-green'];

const InfoTip1 = ({ size = '75', ...other }) => {
  const width = size;
  const height = (94 * width) / 75;

  return (
    <svg width={width} height={height} viewBox="0 0 75 94" fill="none" {...other}>
      <path
        d="M60.2812 21.7505H37.7188C36.6174 21.7536 35.5621 22.1925 34.7833 22.9713C34.0045 23.7501 33.5656 24.8054 33.5625 25.9067V40.1567C33.5656 41.2581 34.0045 42.3134 34.7833 43.0922C35.5621 43.871 36.6174 44.3099 37.7188 44.313H40.6875V50.2505L47.6433 44.451C47.7501 44.3618 47.8849 44.313 48.024 44.313H60.2812C61.3826 44.3099 62.4379 43.871 63.2167 43.0922C63.9955 42.3134 64.4344 41.2581 64.4375 40.1567V25.9067C64.4344 24.8054 63.9955 23.7501 63.2167 22.9713C62.4379 22.1925 61.3826 21.7536 60.2812 21.7505Z"
        fill="#FFE8C3"
      />
      <path
        d="M56.7188 25.0005H34.1562C33.0549 25.0036 31.9996 25.4425 31.2208 26.2213C30.442 27.0001 30.0031 28.0554 30 29.1567V43.4067C30.0031 44.5081 30.442 45.5634 31.2208 46.3422C31.9996 47.121 33.0549 47.5599 34.1562 47.563H37.125V53.5005L44.0808 47.701C44.1876 47.6118 44.3224 47.563 44.4615 47.563H56.7188C57.8201 47.5599 58.8754 47.121 59.6542 46.3422C60.433 45.5634 60.8719 44.5081 60.875 43.4067V29.1567C60.8719 28.0554 60.433 27.0001 59.6542 26.2213C58.8754 25.4425 57.8201 25.0036 56.7188 25.0005V25.0005Z"
        stroke="#4F5154"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 54H26.5C27.4274 54.0026 28.3162 54.3722 28.972 55.028C29.6278 55.6838 29.9974 56.5726 30 57.5V69.5C29.9974 70.4274 29.6278 71.3162 28.972 71.972C28.3162 72.6278 27.4274 72.9974 26.5 73H24V78L18.1425 73.1162C18.0526 73.0411 17.9391 73 17.8219 73H7.5C6.57255 72.9974 5.68385 72.6278 5.02804 71.972C4.37224 71.3162 4.00264 70.4274 4 69.5V57.5C4.00264 56.5726 4.37224 55.6838 5.02804 55.028C5.68385 54.3722 6.57255 54.0026 7.5 54Z"
        fill="#EED9F7"
      />
      <path
        d="M28.0264 7.53607C28.2087 7.4131 28.427 7.63147 28.3041 7.81372L24.2593 13.8084C24.2137 13.876 24.2137 13.9645 24.2593 14.0321L28.3041 20.0267C28.427 20.209 28.2087 20.4273 28.0264 20.3044L22.0318 16.2596C21.9642 16.214 21.8757 16.214 21.8081 16.2596L15.8134 20.3044C15.6312 20.4273 15.4128 20.209 15.5358 20.0267L19.5805 14.0321C19.6261 13.9645 19.6261 13.876 19.5805 13.8083L15.5358 7.81372C15.4128 7.63147 15.6312 7.4131 15.8134 7.53607L21.8081 11.5808C21.8757 11.6264 21.9642 11.6264 22.0318 11.5808L28.0264 7.53607Z"
        fill="#EEA530"
      />
      <path
        d="M14.4342 34.6259C14.6353 34.7146 14.5669 35.0157 14.3471 35.0088L9.47523 34.8546C9.39372 34.852 9.31881 34.8992 9.28591 34.9738L7.31928 39.4338C7.23058 39.635 6.92943 39.5665 6.93638 39.3468L7.09055 34.4749C7.09312 34.3934 7.04596 34.3185 6.97134 34.2856L2.51133 32.3189C2.31016 32.2302 2.3786 31.9291 2.59835 31.936L7.47026 32.0902C7.55177 32.0928 7.62668 32.0456 7.65958 31.971L9.62621 27.511C9.71491 27.3098 10.0161 27.3783 10.0091 27.598L9.85495 32.4699C9.85237 32.5514 9.89954 32.6263 9.97415 32.6592L14.4342 34.6259Z"
        fill="#8F5CCF"
      />
      <path
        d="M63.6464 63.076C63.8287 62.953 64.0471 63.1714 63.9241 63.3537L57.4908 72.8883C57.4452 72.9559 57.4452 73.0444 57.4908 73.112L63.9241 82.6466C64.0471 82.8288 63.8287 83.0472 63.6464 82.9242L54.1119 76.4909C54.0443 76.4453 53.9557 76.4453 53.8881 76.4909L44.3536 82.9242C44.1713 83.0472 43.9529 82.8288 44.0759 82.6466L50.5092 73.112C50.5548 73.0444 50.5548 72.9559 50.5092 72.8882L44.0759 63.3537C43.9529 63.1714 44.1713 62.953 44.3536 63.076L53.8881 69.5093C53.9557 69.5549 54.0443 69.5549 54.1119 69.5093L63.6464 63.076Z"
        fill={primaryDarkGreenColor}
      />
    </svg>
  );
};

export default InfoTip1;
