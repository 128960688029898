import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';

import { ampTrackEvent } from '../../amplitude';
import Close from '../svg/Close';
import { TextField as TextFieldV1 } from '../FormFields';
import { TextField as TextFieldV2, SubmitButton } from '../FormFields/v2';
import config from '../../config';
import HowToAmazon from './HowToAmazon';
import Button from 'components/Button';

const V1 = (props) => {
  const { isSubmitting, onSubmit, form, serverError, onCancel, sellerId } = props;
  const { register, handleSubmit } = form;

  return (
    <div className="bg-white rounded p-5 shadow-sm overflow-hidden">
      <h6 className="mb-4">Connect your Amazon Seller account</h6>
      <HowToAmazon />

      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextFieldV1
              type="text"
              name="sellerId"
              placeholder="A3AHGB38CBLYOH"
              className="form-control mt-2"
              label="Seller ID"
              readOnly={sellerId}
              ref={register({ required: true })}
            />
            <br />

            <TextFieldV1
              type="text"
              name="mwsAuthToken"
              placeholder="amzn.mws.467b3332-bce4-11ea-b3de-0242ac130004"
              className="form-control"
              label="MWS Auth Token"
              ref={register({ required: true })}
            />
          </div>

          <div className="mt-4 overflow-hidden">
            {serverError ? <div className="text-danger my-2 small float-left">{serverError}</div> : null}
          </div>

          <div className="row">
            <div className="col-12 col-lg-4 order-lg-2 offset-lg-4">
              <Button type="submit" className="btn btn-success btn-block mt-4" disabled={isSubmitting}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </div>

            <div className="col-12 col-lg-4">
              <Button type="button" className="btn btn-outline-primary btn-block mt-4" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const V2 = (props) => {
  const { isSubmitting, onSubmit, form, serverError, onCancel } = props;
  const { register, handleSubmit } = form;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-rounded-md tw-bg-neutral-light">
          <div className="tw-flex tw-justify-between tw-px-8 tw-py-4 tw-border-b tw-border-neutral-grey-4">
            <p>Connect sales channel</p>
            <Close className="tw-cursor-pointer" onClick={onCancel} />
          </div>
          <div className="tw-px-8 tw-py-8">
            <img className="tw-w-24 tw-mb-4" src="https://loop-public.s3.ca-central-1.amazonaws.com/amazon_logo.png" />
            <h3 className="tw-mb-4">Connect your Amazon Seller account</h3>

            <ol className="tw-list-decimal tw-pl-5 tw-mb-8">
              <li>
                Login into Seller Central and go to{' '}
                <a href="https://sellercentral.amazon.ca/apps/manage">
                  <strong className="tw-text-primary-dark-green">Manage your apps</strong>
                </a>{' '}
                page.
              </li>
              <li>
                Click <strong>Authorize new developer</strong> and enter these details: <br />
                <p className="tw-pl-4">
                  Developer’s Name: <strong>Lending Loop</strong>
                </p>
                <p className="tw-pl-4">
                  Developer’s ID: <strong>374024750460</strong>
                </p>
              </li>
              <li>Click Next</li>
              <li>
                Agree to <strong>provide Lending Loop access</strong> by checking the box
              </li>
              <li>Click Next</li>
              <li>
                Find your <strong>Seller ID</strong> and <strong>MWS Auth Token</strong> and enter it here:
              </li>
            </ol>

            <TextFieldV2
              type="text"
              name="sellerId"
              placeholder="A3AHGB38CBLYOH"
              label="Seller ID"
              ref={register({ required: true })}
            />
            <TextFieldV2
              type="text"
              name="mwsAuthToken"
              placeholder="amzn.mws.467b3332-bce4-11ea-b3de-0242ac130004"
              rootClass="tw-mt-4"
              label="MWS Auth Token"
              ref={register({ required: true })}
            />

            {serverError ? <small className="tw-text-semantic-error tw-my-2 small">{serverError}</small> : null}

            <SubmitButton className="tw-mt-12" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </SubmitButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const ConnectAmazon = (props) => {
  const { onCancel, onSuccess, sellerId, isPreApproval, layout } = props;
  const [isSubmitting, setIsSubmitting] = useState();
  const [serverError, setServerError] = useState(false);

  const form = useForm({
    defaultValues: { sellerId: sellerId },
  });
  const { setError, ...formProps } = form;

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setServerError(false);
    ampTrackEvent('Clicked: Connect Amazon Marketplace - Integrations');

    try {
      await axios.post(`${config.coreAppUrl}/borrowers/integrations`, {
        ...data,
      });
      return onSuccess && onSuccess();
    } catch (err) {
      const errors = (err.response && err.response.data) || {};
      const errorFields = Object.keys(errors);

      // Set Server error
      errorFields.forEach((fieldName) => {
        setError(fieldName, { type: 'manual', message: errors[fieldName][0] });
      });

      if (!errorFields.length) {
        setServerError('Something went wrong!');
      }
      setIsSubmitting(false);
    }
  };

  const Layout = isPreApproval || layout === 'V2' ? V2 : V1;

  return (
    <Layout
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      form={formProps}
      serverError={serverError}
      onCancel={onCancel}
      sellerId={sellerId}
    />
  );
};

export default ConnectAmazon;
