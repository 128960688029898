import React from 'react';
import { TermContent } from '../../KeyTerms.types';

const TermBox = ({ graphic, title, content }: TermContent) => {
  return (
    <div className="tw-flex tw-flex-row tw-gap-4 tw-items-center tw-bg-primary-light-green tw-px-4 tw-py-4 tw-rounded">
      {graphic}
      <div>
        <span className="tw-font-bold tw-block">{title}</span>
        {typeof content === 'string' ? <small>{content}</small> : content}
      </div>
    </div>
  );
};
export default TermBox;
